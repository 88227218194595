export type ComboItem = {
  id?: number
  newId?: number
  title: string
  value: string
}

export type NumberType = 'Number'
export type StringType = 'String'
export type SwitchType = 'Switch'
export type DateType = 'Date'
export type ComboType = 'Combo'
export type DocumentType = 'Document'
export type PhoneType = 'Phone'
export type InstructionType = 'Instruction'
export type VideoConferenceLink = 'VideoConferenceLink'

type FileValue = {
  id: string | number
  name?: string
  lastModified?: number
}

export type AdditionalTypes =
  | NumberType
  | StringType
  | SwitchType
  | DateType
  | ComboType
  | DocumentType
  | PhoneType
  | InstructionType
  | VideoConferenceLink
export type AdditionalValue = number | string | boolean | FileValue

export const AdditionalTypesValues: AdditionalTypes[] = [
  'String',
  'Phone',
  'Number',
  'Switch',
  'Date',
  'Combo',
  'Document',
  'Instruction',
  'VideoConferenceLink'
]

export type Additional = {
  id?: number
  newId?: number
  shortName: string
  label: string
  qrRegexp?: string
  qrRegexpGroupNumber?: number
  description?: string
  type?: AdditionalTypes
  comboItems?: ComboItem[]
  isMandatory?: boolean
  isUnique?: boolean
  populateForScan?: boolean
  minContentLength?: number
  maxContentLength?: number
  validationRegexp?: string
  validationErrorMessage?: string
  acceptedFileTypes?: string
  availableForServices?: (number | string)[] | null
  memorizeValue?: boolean
  isAllServices?: boolean
  displayCopyButton?: boolean
  displayByParticipantNumber?: boolean
  minParticipantNumber?: number
  maxParticipantNumber?: number
  delayForConfirmation?: boolean
  confirmationDelayInSec?: number
  displayBasingOnWaitingTime?: boolean
  minWaitingTimeInMin?: number
  maxWaitingTimeInMin?: number
  visibilityAndValidationExpression?: string
  stopJoinProcessAfterDisplay?: boolean
}

export type FilledAdditional = Additional & {
  value?: AdditionalValue
}

export type AdditionalValues = Record<string, AdditionalValue>

export type FieldWorkerMessage = { visible?: boolean; valid?: boolean; invalidMessage?: string }

export type ExpressionValidations = Record<string, FieldWorkerMessage>
