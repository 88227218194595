import { ServiceModel } from 'shared/models'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { ServiceList } from './list'

type Props = {
  services: ServiceModel[]
  brandId?: string
  freeTimeslotsProgressively?: boolean
  requestParticipantsNumber?: boolean
  maxSimultaneous?: number
  onServicesChanged: (services: ServiceModel[]) => void
  onAddService: () => void
  shopId?: string
}

export function ServiceListDnd(props: Props) {
  return (
    <DndProvider backend={HTML5Backend} key="service-list">
      <ServiceList {...props} />
    </DndProvider>
  )
}
