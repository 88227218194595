import { CheckpointHostEditModel, LineModel, ServiceModel } from 'shared/models'

export enum PermissionError {
  CantClose = 'cantClose',
  CantOpen = 'cantOpen'
}

export type PermissionErrorToText = { [key in PermissionError]: string }

export type HostEditFormProps = {
  data: CheckpointHostEditModel
  canGiveUpdateServicePointStartAndEndHours: boolean
  onBack: () => void
  onApply: (data: CheckpointHostEditModel) => void
}

export type HostEditProps = {
  data: CheckpointHostEditModel
  line: LineModel
  onHostChange: (val: CheckpointHostEditModel) => void
  onBack: () => void
  canOpenCheckpoints?: boolean
  canCloseCheckpoints?: boolean
  services?: ServiceModel[]
  preferToStop?: boolean
}

export type HostInfoProps = {
  data: CheckpointHostEditModel
  displayTime?: boolean
}

export type StateCardProps = {
  title: string
  description?: string
  isActive?: boolean
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
}
