import { useState, useEffect } from 'react'
import { LoginForm } from './login-form'
import { loginByTemporaryCode } from 'shared/auth'
import { index } from 'pages/nav'
import { extractStoredUrl } from 'shared/utils/history'
import { invalidatePlaceList } from 'store/actions/places-action-creators'
import { storeUserProfile } from 'store/actions/user-action-creators'
import { useTranslation } from 'shared/i18n/translate'
import { user } from 'shared/api'
import { useHistory } from 'react-router'
import { useAppDispatch } from 'store'
import { LoginWrapper } from './styled'

function LoginByTemporaryCode() {
  const [isFetching, setIsFetching] = useState(false)

  const history = useHistory()
  const dispatch = useAppDispatch()
  const { tr } = useTranslation()

  useEffect(() => {
    window.document.title = tr.common.title
    dispatch(invalidatePlaceList())
  }, [])

  async function handleSubmit(code: string) {
    setIsFetching(true)

    try {
      await loginByTemporaryCode(code)

      const profile = await user.storeProfile()

      dispatch(storeUserProfile(profile))

      history.push(extractStoredUrl() || index())
    } catch (error: any) {
      setIsFetching(false)
    }
  }

  return (
    <LoginWrapper>
      <LoginForm onSubmit={handleSubmit} disabled={isFetching} />
    </LoginWrapper>
  )
}

export default LoginByTemporaryCode
