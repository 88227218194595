import { useEffect } from 'react'
import Tile, { TileContainer } from 'features/tile'
import * as Nav from 'pages/nav'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import TodayIcon from '@mui/icons-material/Today'
import EventIcon from '@mui/icons-material/EventNote'
import EventsFlowIcon from '@mui/icons-material/Dvr'
import { useTranslation } from 'shared/i18n/translate'
import Container from 'shared/ui-kit/container'
import { useAppDispatch } from 'store'

function Reports() {
  const dispatch = useAppDispatch()
  const { tr, locale } = useTranslation()

  useEffect(() => {
    const crumb = getCrumbs(tr.breadcrumbs)
    dispatch(setBreadcrumbs([crumb.home(), crumb.reports()]))
  }, [locale])

  const { journal, dailyStats, eventsFlow } = tr.reports

  return (
    <Container>
      <TileContainer>
        <Tile title={dailyStats} iconClass={TodayIcon} linkTo={Nav.dailyStats()} />
        <Tile title={journal} iconClass={EventIcon} linkTo={Nav.journal()} />
        <Tile title={eventsFlow} iconClass={EventsFlowIcon} linkTo={Nav.eventsFlow()} />
      </TileContainer>
    </Container>
  )
}

export default Reports
