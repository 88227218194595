import { TextField } from 'shared/ui-kit/text-field'
import { useTranslation } from 'shared/i18n/translate'
import uniqueId from 'lodash/uniqueId'
import { PositionCustomer } from 'shared/models'
import { calculateImprovedLevenshteinDistance } from 'shared/utils/levenshtein-distance'
import { isNameValid } from 'shared/utils/string-test'

type Params = {
  handleCustomerPropertyChange<T extends keyof PositionCustomer>(propertyName: T, value: PositionCustomer[T]): void
  customer?: PositionCustomer
  maximumCharactersParticipantNameUpdate?: number
  initialCustomerFullName?: string
  lineParticipantsFirstNameCaptionTemplate?: string
}

function getFirstNameField(props: Params, autofocus = false) {
  const { tr } = useTranslation()

  const { firstName, maximumCharactersParticipantNameUpdateExceeded } = tr.lineMonitoring
  const customerFirstName = props.customer?.firstName || ''
  const customerLastName = props.customer?.lastName || ''
  const customerFullName = [customerFirstName, customerLastName].filter(Boolean).join(' ')
  const maximumCharactersParticipantNameUpdate = props.maximumCharactersParticipantNameUpdate || 9999999

  const isNoManyChangedCharacters = () => {
    if (!props.initialCustomerFullName || maximumCharactersParticipantNameUpdate === 0) {
      return true
    }

    return (
      calculateImprovedLevenshteinDistance(props.initialCustomerFullName, customerFullName) <=
      maximumCharactersParticipantNameUpdate
    )
  }

  const isValid = () => {
    return (
      Boolean((customerFirstName || '').trim().length) && isNameValid(customerFirstName) && isNoManyChangedCharacters()
    )
  }

  const view = (
    <TextField
      autocomplete={uniqueId('autocomplete')}
      label={props.lineParticipantsFirstNameCaptionTemplate || firstName}
      placeholder={firstName}
      autofocus={autofocus}
      value={customerFirstName}
      onChange={(val) => props.handleCustomerPropertyChange('firstName', val)}
      attention={!isValid()}
      invalid={!isNoManyChangedCharacters()}
      message={
        !isNoManyChangedCharacters()
          ? maximumCharactersParticipantNameUpdateExceeded(maximumCharactersParticipantNameUpdate)
          : ''
      }
    />
  )

  return { view, isValid }
}

export { getFirstNameField }
