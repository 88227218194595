import { useTranslation } from 'shared/i18n/translate'
import { ActionFormActions, ActionList } from '../styled'
import { monitoring as monitoringApi } from 'shared/api'
import { Fragment, useEffect } from 'react'
import { Button, Divider, Typography } from '@mui/material'
import { DuplicateLine } from 'shared/models/entities/get-duplicate-form'
import { DuplicatePositionActionProps } from './types'
import PositionActionComponent from '../../components/position-action'
import DuplicateIcon from '@mui/icons-material/CopyAll'
import { useQuery } from 'react-query'

function DuplicatePositionAction(props: DuplicatePositionActionProps) {
  const { tr } = useTranslation()

  const { data: res, isLoading } = useQuery(
    ['duplicateForm', props.placeId, props.lineId, props.positionId],
    () =>
      monitoringApi.getDuplicateForm({ placeId: props.placeId, lineId: props.lineId, positionId: props.positionId }),
    { enabled: props.selectLineMode }
  )

  useEffect(() => {
    if (props.selectLineMode && res?.data?.lines?.length === 1 && res?.data.lines[0].state !== 'closed') {
      handleSelectLine(res?.data.lines[0])
    }
  }, [res?.data])

  function handleSelectLine(line: DuplicateLine) {
    return () => {
      if (res?.data) {
        props.onDuplicate(line, res.data.position)
      }
    }
  }

  return (
    <>
      {!isLoading && props.selectLineMode && (
        <>
          <Typography variant="body1" fontWeight={500} fontSize="18px">
            {tr.positionActions.duplicate}
          </Typography>
          <ActionList $isCardView={props.isCardView}>
            {res?.data?.lines?.map((line) => (
              <Fragment key={line.id}>
                {!props.isCardView && <Divider />}
                <PositionActionComponent
                  isCardView={props.isCardView}
                  disabled={line.state === 'closed'}
                  onSubmit={handleSelectLine(line)}
                >
                  {line.name}
                </PositionActionComponent>
              </Fragment>
            ))}
            {!props.isCardView && <Divider />}
          </ActionList>
          <ActionFormActions $marginTop={props.isCardView ? '1.5rem' : '1rem'}>
            <Button variant="contained" color="primary" size="small" onClick={props.onBack}>
              {tr.positionActions.backButton}
            </Button>
          </ActionFormActions>
        </>
      )}
      {!isLoading && !props.selectLineMode && (
        <>
          {!props.isCardView && <Divider />}
          <PositionActionComponent
            isCardView={props.isCardView}
            onSubmit={props.onSelectLineMode}
            icon={<DuplicateIcon fontSize="small" />}
          >
            {tr.positionActions.duplicate}
          </PositionActionComponent>
        </>
      )}
    </>
  )
}

export { DuplicatePositionAction }
