import axios from 'axios'
import { CaptchaAPI } from 'shared/models'

const captcha: CaptchaAPI = {
  getNew(config) {
    return axios.get('captcha', config)
  },
  renew(id, config) {
    return axios.get(`captcha/${id}/renew`, config)
  },
  validate(id, value, config) {
    return axios.post('captcha/validate', { id, value }, config)
  }
}

export default captcha
