import styled from '@emotion/styled'

const BarStyled = styled.div`
  display: block;
  padding: 0 16px;
  position: sticky;
  width: 100%;
  z-index: 1100;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  box-shadow:
    0 1px 6px rgba(0, 0, 0, 0.12),
    0 1px 4px rgba(0, 0, 0, 0.12);
  min-height: 64px;
  align-items: center;
  justify-content: center;
  background: #fff;
  top: 64px;
  left: 0;
  white-space: nowrap;
`

const SideBarStyled = styled.div`
  position: sticky;
  top: 6rem;
  margin-right: 1rem;
  width: 290px;
`

const RootStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  margin-top: 2rem;
`

const LargeContainer = styled.div`
  display: flex;
  width: 800px;
  align-items: start;
`

const FilterItem = styled.div`
  padding: 0.5rem 1rem;
`

const FilterItemSmall = styled.div`
  padding: 0.5rem;
`

const FilterActions = styled.div<{ $isSmall?: boolean }>`
  padding: ${(props) => (props.$isSmall ? '0.5rem' : '0.5rem 1rem 1rem')};
  display: flex;
  gap: 0.5rem;
`

const PositionStatIndicators = styled.div`
  margin-top: 0.5rem;
  display: flex;
  gap: 0 0.75rem;
  flex-wrap: wrap;
  align-items: center;
`

const StatIndicator = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-top: 0.5rem;
`

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
  padding: 0 0.5rem 0.5rem;
  margin-top: -1.5rem;
`

const MoreInfoWrapper = styled.div`
  border-top: solid 1px rgba(0, 0, 0, 0.08);
  padding: 1rem;
  white-space: pre-wrap;
  overflow: auto;
`

const ActionInitiator = styled.div`
  right: 1rem;
  top: 0.5rem;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const EventContent = styled.div`
  padding: 1rem 2rem 0 1rem;
  margin-top: 0.5rem;
`

export {
  BarStyled,
  RootStyled,
  SideBarStyled,
  LargeContainer,
  FilterItem,
  FilterActions,
  FilterItemSmall,
  PositionStatIndicators,
  ActionsWrapper,
  MoreInfoWrapper,
  StatIndicator,
  ActionInitiator,
  EventContent
}
