import { SmallServiceInfo } from 'shared/models'
import * as Yup from 'yup'

function getValidationConfig(services: SmallServiceInfo[] | undefined) {
  return Yup.object().shape({
    numberOfStaff: Yup.string().required(),
    startHour_unixtime: Yup.number().required(),
    endHour_unixtime: Yup.number().required(),
    pauseStartHour_unixtime: Yup.number().when('serviceWithPause', {
      is: true,
      then: (schema) => schema.required()
    }),
    pauseEndHour_unixtime: Yup.number().when('serviceWithPause', {
      is: true,
      then: (schema) => schema.required()
    }),
    servicesSelected: Yup.array().test({
      test: (value) => {
        if (services?.length) {
          return (value?.length || 0) > 0
        }

        return true
      }
    })
  }) as any
}

export { getValidationConfig }
