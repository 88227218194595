import { DateFormatter } from 'shared/i18n/translation-context'
import { Translations } from 'shared/i18n/translation-types'
import { AdditionalTypes, AdditionalValue, ComboItem } from 'shared/models'
import { isStringDate } from 'shared/utils/string-test'

type Params = {
  type: AdditionalTypes
  value: AdditionalValue
  tr: Translations
  dateFormatter: DateFormatter
  items?: ComboItem[]
}

export function getAdditionalValue({ tr, type, value, dateFormatter, items }: Params): string {
  const isDateString = typeof value === 'string' && isStringDate(value)

  switch (type) {
    case 'String':
      return String(value)
    case 'Number':
      return String(value)
    case 'Switch':
      return tr.common.booleanValue(Boolean(value))
    case 'Date':
      return isDateString ? value : dateFormatter(String(value), 'date')
    case 'Combo':
      return items?.find((item) => item.value === String(value))?.title || String(value)
    case 'Document':
      return typeof value === 'object' ? value.name || tr.common.download : String(value)
    default:
      return String(value)
  }
}
