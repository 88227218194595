import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { editLineStaffManagement, createLineStaffManagement, checkpoints } from 'pages/nav'
import { DatePicker } from 'features/date'
import PageLoader from 'shared/ui-kit/page-loader'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { staffManagement } from 'shared/api'
import { showMessage, MessageTypes } from 'store/actions/main-layout-action-creators'
import { useTimeZoneFilter } from 'features/time-zone-filter'
import Uploader from 'shared/utils/file-uploader'
import { CloudUploadOutlined as UploadIcon, Add as AddIcon } from '@mui/icons-material'
import { Toolbar, AppBar, useTheme, IconButton, Tooltip } from '@mui/material'
import { useHistory, useParams } from 'react-router'
import { GetResponseType, StaffManagementListItemModel } from 'shared/models'
import Container from 'shared/ui-kit/container'
import { StorageItem } from 'shared/utils/storage'
import { StaffManagmentList } from './dumb/staff-managment-list'
import TIME_HELPER from 'shared/utils/time'
import { WarningNotification } from 'shared/ui-kit/warning-notification'
import { Link } from 'react-router-dom'
import debounce from 'lodash/debounce'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { useTitle } from 'shared/hooks/useTitle'
import { useAppDispatch, useAppSelector } from 'store'

type ParentsType = GetResponseType<StaffManagementListItemModel>['parents']

type Filter = {
  startDate: number
  endDate: number
}

function StaffManagement() {
  const dispatch = useAppDispatch()
  const { manageAppointments } = useAppSelector(getPermissionsSelector)
  const { tr, locale } = useTranslation()
  const history = useHistory<{ managementId?: number | string }>()
  const { lineId, placeId } = useParams<{ placeId: string; lineId: string }>()
  const { location } = history

  useTimeZoneFilter(placeId)

  const theme = useTheme()

  const { current: storageFilter } = useRef(
    new StorageItem<{ startDate: number; endDate: number }>('staffManagmentFilter')
  )

  const uploader = useRef<Uploader>()

  const [filter, setFilter] = useState<Filter>({
    startDate: storageFilter.value?.startDate || Date.now(),
    endDate: storageFilter.value?.endDate || Date.now() + TIME_HELPER.weekMS
  })

  const [data, setData] = useState<StaffManagementListItemModel[] | null>(null)
  const [parents, setParents] = useState<ParentsType | null>(null)

  useTitle({ lineName: parents?.line?.name })

  useEffect(() => {
    fetchData(filter)

    uploader.current = new Uploader({
      request: staffManagement.uploadStaffManagement,
      processForm: (data) => {
        const form = new FormData(data)

        form.append('lineId', lineId)

        return form
      },
      onSuccess: () => {
        dispatch(showMessage(tr.staffManagement.uploadSuccess, MessageTypes.Success))
        setFilter((curr) => {
          fetchData(curr)
          return curr
        })
      },
      onFail: () => {
        dispatch(showMessage(tr.staffManagement.uploadError, MessageTypes.Error))
      },
      accept: '.xls, .xlsx'
    })

    return () => {
      uploader.current?.destroy()
    }
  }, [])

  useEffect(() => {
    if (!parents?.shop || !parents?.line) {
      return
    }

    const { shop, line } = parents
    const crumb = getCrumbs(tr.breadcrumbs)

    dispatch(
      setBreadcrumbs([
        crumb.home(),
        crumb.places(),
        crumb.place([placeId], shop.name),
        crumb.lines([placeId]),
        crumb.line([placeId, lineId], line.name),
        crumb.staffManagement([placeId, lineId])
      ])
    )
  }, [parents, locale])

  const setAndStoreFilter = debounce((filter: Filter) => {
    setFilter(filter)

    if (!filter.endDate || !filter.startDate) {
      return
    }

    if (filter.endDate < 0 || filter.startDate < 0) {
      return
    }

    storageFilter.value = filter
    fetchData(filter)
  }, 400)

  function handleRefresh() {
    fetchData(filter)
  }

  function fetchData(filter: Filter) {
    setData(null)

    staffManagement
      .getStaffManagement({
        startDate: filter.startDate,
        endDate: filter.endDate,
        lineId,
        shopId: placeId
      })
      .then((response) => {
        setData(response.data.items)
        setParents(response.parents)
      })
  }

  const isLoading = data === null
  const { addRecord, upload } = tr.staffManagement

  return (
    <>
      <AppBar position="sticky" color="inherit" elevation={2} style={{ top: theme.spacing(8), borderRadius: 0 }}>
        <Toolbar>
          <Container style={{ padding: '0rem' }}>
            <div style={{ display: 'flex', gap: '2.5rem', alignItems: 'center' }}>
              {manageAppointments && (
                <Tooltip arrow title={addRecord}>
                  <IconButton onClick={() => history.push(createLineStaffManagement(placeId, lineId))}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
              <DatePicker
                label={tr.reportsFilter.startDate}
                style={{ flex: 1 }}
                onChange={(val) => {
                  setAndStoreFilter({ startDate: val, endDate: filter.endDate < val ? val : filter.endDate })
                }}
                value={filter.startDate}
              />
              <DatePicker
                label={tr.reportsFilter.endDate}
                style={{ flex: 1 }}
                onChange={(val) => {
                  setAndStoreFilter({ startDate: filter.startDate > val ? val : filter.startDate, endDate: val })
                }}
                value={filter.endDate}
              />
              {manageAppointments && (
                <Tooltip arrow title={upload}>
                  <IconButton onClick={() => uploader.current?.openDialog()}>
                    <UploadIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </Container>
        </Toolbar>
      </AppBar>
      <Container>
        <WarningNotification isDisplay={true} style={{ marginBottom: '1rem' }}>
          {tr.staffManagement.attentionTodayMessage}{' '}
          <Link to={checkpoints(placeId, lineId)} style={{ color: '#db214c' }}>
            {tr.staffManagement.attentionTodayAction}
          </Link>
        </WarningNotification>
        {isLoading && <PageLoader />}
        {!isLoading && (
          <StaffManagmentList
            collection={data}
            autofocusId={location.state?.managementId}
            onItemClick={(data) => {
              history.push(editLineStaffManagement(placeId, lineId, data.id as string))
            }}
            lineId={Number(lineId)}
            shopId={Number(placeId)}
            onRefresh={handleRefresh}
          />
        )}
      </Container>
    </>
  )
}

export default StaffManagement
