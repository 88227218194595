import { Color } from 'shared/ui-kit/color-picker/color-picker'
import find from 'lodash/find'

export type Category = { color: string; category: number }

export const lineCategory = {
  getCategoryList(): Color[] {
    return [
      { color: '#E91E63', category: 1 },
      { color: '#9C27B0', category: 2 },
      { color: '#3F51B5', category: 3 },
      { color: '#00BCD4', category: 4 },
      { color: '#CDDC39', category: 5 },
      { color: '#009688', category: 6 },
      { color: '#9E9E9E', category: null }
    ]
  },

  getColor(category?: number | null | undefined) {
    if (!category) {
      return '#9E9E9E'
    }

    const list = this.getCategoryList()

    return (find(list, { category }) as Category).color
  }
}
