import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StoreProfileResponse, TimeZoneModel } from 'shared/models'
import i18n, { AppLocale } from 'shared/i18n'

type ProfileState = {
  appLocale: AppLocale
  timeZone: TimeZoneModel
  loaded: boolean
  userProfile: StoreProfileResponse | null
  groupShops: boolean
  singleShop: boolean
  notificationDeclinedDialog: boolean
}

const initialState: ProfileState = {
  appLocale: i18n.getLocaleByBrowserLang(),
  timeZone: { id: 'UTC', offset: 0, name: 'UTC' },
  loaded: false,
  userProfile: null,
  groupShops: true,
  singleShop: false,
  notificationDeclinedDialog: false
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setUserProfile(state, { payload }: PayloadAction<StoreProfileResponse>) {
      const shops = payload.counters?.shops || 0

      state.groupShops = !shops || shops > 3
      state.singleShop = shops === 1 && !payload.permissions?.canAddAndDeleteShops
      state.loaded = true
      state.userProfile = payload
      state.appLocale = i18n.languageTagToAppLocale(payload.interfaceLocale)
    },
    setTimeZone(state, action: PayloadAction<TimeZoneModel>) {
      state.timeZone = action.payload
    },
    clearProfile(state) {
      state.loaded = false
      state.userProfile = null
    },
    setInsecurePassword(state, action: PayloadAction<boolean>) {
      if (state.userProfile) {
        state.userProfile.insecurePassword = action.payload
      }
    },
    openNotificationDeclinedDialog(state) {
      state.notificationDeclinedDialog = true
    },
    closeNotificationDeclinedDialog(state) {
      state.notificationDeclinedDialog = false
    }
  }
})

export const {
  setTimeZone,
  clearProfile,
  setUserProfile,
  setInsecurePassword,
  openNotificationDeclinedDialog,
  closeNotificationDeclinedDialog
} = profileSlice.actions
export default profileSlice.reducer
