import Dialog from '@mui/material/Dialog'
import { SuggestionDialogContent } from './suggestion-dialog-content'
import { SuggestionDialogProps } from './types'

function SuggestionDialog(props: SuggestionDialogProps) {
  return (
    <Dialog
      maxWidth="md"
      open={Boolean(props.data)}
      BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.36)', backdropFilter: 'blur(16px)' } }}
    >
      {Boolean(props.data) && (
        <SuggestionDialogContent
          shopId={props.shopId}
          lineId={props.lineId}
          checkpointId={props.checkpointId}
          data={props.data}
          timeToReach={props.timeToReach}
          requestCommentsOnRemove={props.requestCommentsOnRemove}
          onWait={props.onWait}
          onStart={props.onStart}
          onRemove={props.onRemove}
          onClose={props.onWait}
        />
      )}
    </Dialog>
  )
}

export { SuggestionDialog }
