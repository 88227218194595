import { PositionType } from 'shared/models'

const ee2ActionToPositionAction: Record<string, PositionType> = {
  Appointment: PositionType.Appointment,
  Smartphone: PositionType.MobileApp,
  Screen: PositionType.ScreenCall,
  Break: PositionType.Break,
  PaperTicket: PositionType.PaperTicket,
  Web: PositionType.WebFrame
}

export { ee2ActionToPositionAction }
