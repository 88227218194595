import { unicodeCategories } from 'shared/utils/regex-helper'

export function isPositiveNumber(val: string) {
  return /^\d+(\.)?(\d+)?$/.test(val)
}

export function isPositiveInteger(val: string) {
  return /^[1-9]\d*$/.test(val)
}

export function isPositiveNullInteger(val: string) {
  return /^(([1-9]{1}[0-9]*)|[0])$/.test(val)
}

export function isPhoneNumber(val: string) {
  return /^[+]?\d{0,19}$/.test(val)
}

export function isEmail(val = '') {
  const lower = val.toLowerCase()
  return /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(lower)
}

export function isMultipleEmail(val = '') {
  const lower = val.toLowerCase()
  return /^([a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})(;)?)*$/.test(lower)
}

export function isValidPassword(val: string) {
  const symbolsRegex = new RegExp(
    `^(?=.*[${unicodeCategories.Ll}])(?=.*[${unicodeCategories.Lu}])(?=.*\\d)(?=.*[$!"#$%&'()*+,-./:;<=>?@[\\]^_\`{|}~])[${unicodeCategories.Lu}${unicodeCategories.Ll}\\d$!"#$%&'()*+,-./:;<=>?@[\\]^_\`{|}~]{8,}$`,
    'g'
  )

  return symbolsRegex.test(val)
}

export function isNameValid(val: string) {
  return /^(\p{L}|\p{N}|[!-@]| )*(\p{L}{2,})(\p{L}|\p{N}|[!-@]| )*/gu.test(val)
}

export function isSenderIdValid(val: string) {
  return /^([a-zA-Z0-9]{3,11})$/gm.test(val)
}

export function isURL(val: string) {
  return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/.test(
    val
  )
}

export function isStringDate(val: string) {
  return /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(
    val
  )
}

export function isDouble(val: string) {
  return /^[0-9]*[.]?[0-9]*$/.test(val)
}
