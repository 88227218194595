import { Unsubscribe } from 'nanoevents'
import { useEffect, useRef, useState } from 'react'
import * as SuggestionTimer from '../suggestion-timer'
import * as SuggestionData from '../suggestion-dialog-data'
import { LineModel, PositionModel, PositionState } from 'shared/models'
import { isVisible } from '../visibility'
import { openWindowOnCenter } from '../suggestion-blank-dialog'
import { checkpointMonitoringDialog } from 'pages/nav'

function useSuggestionDialog(
  shopId: string,
  lineId: string,
  checkpointId: string,
  line?: LineModel | null,
  positions?: PositionModel[]
) {
  const [overTimePosition, setOverTimePosition] = useState<PositionModel | null>(null)
  const [dialodIsBlocked, setDialodIsBlocked] = useState(false)

  const unbindSuggestionTimeout = useRef<Unsubscribe>()
  const unbindSuggestionData = useRef<Unsubscribe>()
  const blankDialogOpened = useRef(false)

  const [dialog, setDialog] = useState<Window | null>(null)

  useEffect(() => {
    initSuggestionDialog()

    return () => {
      closeSuggestionDialog()
      unbindSuggestionTimeout.current?.()
      unbindSuggestionData.current?.()
    }
  }, [])

  useEffect(() => {
    const overTimePosition = positions?.find(positionNotInServiceYet)

    const setPositionFinishedAfterArrived = line?.setPositionFinishedAfterArrived ?? true

    if (!suggestionDialogIsOpened() && line?.timeToReach && overTimePosition && !setPositionFinishedAfterArrived) {
      openSuggestionDialog(overTimePosition)
    } else if (suggestionDialogIsOpened() && !overTimePosition) {
      closeSuggestionDialog()
    }
  }, [positions])

  function positionNotInServiceYet(position?: PositionModel) {
    return (
      position &&
      line?.timeToReach &&
      position.state === PositionState.CalledToCheckpoint &&
      (position.timeFromCall || 0) / 1000 > line.timeToReach
    )
  }

  function suggestionDialogIsOpened() {
    return Boolean(overTimePosition || blankDialogOpened.current || dialog)
  }

  function initSuggestionDialog() {
    window.onbeforeunload = () => {
      closeSuggestionDialog()
    }

    unbindSuggestionTimeout.current = SuggestionTimer.onTimeout((positionId) => {
      if (!suggestionDialogIsOpened() && positions) {
        const overTimePosition = positions.find((x) => x.id === positionId)

        if (positionNotInServiceYet(overTimePosition)) {
          openSuggestionDialog(overTimePosition)
        }
      }
    })

    unbindSuggestionData.current = SuggestionData.onPositionRemove(() => {
      blankDialogOpened.current = false
    })
  }

  function openSuggestionDialog(overTimePosition?: PositionModel) {
    if (overTimePosition && !SuggestionTimer.shouldPositionIgnore(overTimePosition)) {
      if (isVisible()) {
        setOverTimePosition(overTimePosition)
      } else {
        blankDialogOpened.current = true

        SuggestionData.putPosition({
          position: overTimePosition,
          timeToReach: line?.timeToReach,
          requestCommentsOnRemove: line?.requestCommentsOnRemove
        })

        const url = '#' + checkpointMonitoringDialog(shopId, lineId, checkpointId)

        const dialog = openWindowOnCenter({ url, w: 650, h: 448 })

        setDialog(dialog)

        if (!dialog) {
          blankDialogOpened.current = false
          setDialodIsBlocked(true)
          setOverTimePosition(overTimePosition)
        }
      }
    }
  }

  function closeSuggestionDialog() {
    if (dialog || overTimePosition) {
      dialog?.close?.()
      setOverTimePosition(null)
      setDialog(null)
    }
  }

  return { dialodIsBlocked, overTimePosition, setOverTimePosition }
}

export { useSuggestionDialog }
