import { useRef, useEffect } from 'react'
import ListItem, { ListItemProps } from './list-item'
import { addTestAttr } from 'shared/utils/test-attr'
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd'
import type { Identifier, XYCoord } from 'dnd-core'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { getEmptyImage } from 'react-dnd-html5-backend'

interface DragItem {
  index: number
  id: string
  type: string
}

export function DragListItem<T>(
  props: ListItemProps<T> & {
    index: number
    moveCard: (dragIndex: number, hoverIndex: number) => void
  }
) {
  const dragRef = useRef<HTMLDivElement>(null)

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'box',
    collect(monitor) {
      return { handlerId: monitor.getHandlerId() }
    },
    hover(item: DragItem, monitor) {
      if (!dragRef.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = props.index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = dragRef.current?.getBoundingClientRect()

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      const clientOffset = monitor.getClientOffset()

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      props.moveCard(dragIndex, hoverIndex)
      item.index = hoverIndex
    }
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'box',
    item: () => {
      return { id: props.id, index: props.index }
    },
    collect: (monitor: DragSourceMonitor) => ({ isDragging: monitor.isDragging() })
  })

  drag(drop(dragRef))

  useEffect(() => {
    preview(getEmptyImage())
  }, [])

  const opacity = isDragging ? 0 : 1

  return (
    <ListItem
      style={{ opacity }}
      ref={dragRef}
      itemStyle={props.itemStyle}
      {...addTestAttr('ListItem-Id-' + props.id)}
      key={props.id}
      id={props.id}
      primaryText={props.primaryText}
      secondaryText={props.secondaryText}
      tertiaryText={props.tertiaryText}
      onSelect={props.onSelect}
      dnd={true}
      avatar={props.avatar}
      avatarOnTop={props.avatarOnTop}
      controlsOnBottom={props.controlsOnBottom}
      controls={[
        ...(props.controls || []),
        <DragIndicatorIcon style={{ opacity: '0.2', cursor: 'move', zIndex: -1 }} data-handler-id={handlerId} />
      ]}
      entity={props.entity}
      handleData={props.handleData}
      notActive={props.notActive}
      selected={props.selected}
    />
  )
}
