import { useState } from 'react'
import { ItemWithTooltip } from './item-with-tooltip'
import { PositionStatProps } from './types'
import { PositionStatIconWrapper, PositionStatWrapper } from './styled'

function PositionStat(props: PositionStatProps) {
  const { icon: Icon, className, style, iconStyle, children, tooltipContent } = props

  const [open, setOpen] = useState(false)

  function handleClose() {
    setOpen(false)
  }

  function handleOpen() {
    setOpen(true)
  }

  return (
    <PositionStatWrapper onMouseEnter={handleOpen} onMouseLeave={handleClose} style={style} className={className}>
      {Icon && (
        <ItemWithTooltip open={open} title={tooltipContent}>
          <PositionStatIconWrapper>
            <Icon style={{ opacity: 0.28, ...iconStyle }} />
          </PositionStatIconWrapper>
        </ItemWithTooltip>
      )}
      {children}
    </PositionStatWrapper>
  )
}

export default PositionStat
