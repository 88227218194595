import { useState, useEffect } from 'react'
import { PlaceEdit } from './dumb/edit'
import * as Nav from 'pages/nav'
import { useTranslation } from 'shared/i18n/translate'
import { useTimeZoneFilter } from 'features/time-zone-filter'
import Container from 'shared/ui-kit/container'
import { place as placeApi, brand as brandAPI, translations as translationsApi } from 'shared/api'
import { setBreadcrumbs, showMessage, MessageTypes } from 'store/actions/main-layout-action-creators'
import { invalidatePlaceList } from 'store/actions/places-action-creators'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import CircularProgress from '@mui/material/CircularProgress'
import { useHistory, useParams } from 'react-router'
import { CenterContainer } from 'shared/ui-kit/center-container'
import { countries } from 'shared/countries'
import { getTimeZonesSelector } from 'store/selectors/timeZoneSelectors'
import { getIsAdminSelector, getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { useTitle } from 'shared/hooks/useTitle'
import { useAppDispatch, useAppSelector } from 'store'
import { useQuery } from 'react-query'
import { sortCountries } from './utils/countries-sort'

function PlaceEditPage() {
  const [operation, setOperation] = useState(false)

  const { placeId } = useParams<{ placeId: string }>()
  const history = useHistory()

  const dispatch = useAppDispatch()
  const isAdmin = useAppSelector(getIsAdminSelector)
  const timeZones = useAppSelector(getTimeZonesSelector)
  const { canAddAndDeleteShops } = useAppSelector(getPermissionsSelector)

  const { tr, locale } = useTranslation()

  const { data: place, isFetching } = useQuery(['place', placeId], ({ signal }) =>
    placeApi.getPlace(placeId, { signal })
  )
  const { data: brands } = useQuery(['brands'], ({ signal }) => brandAPI.getBrandList({ signal }))
  const { data: countriesValue } = useQuery(['countries', locale], () => countries.getCountries(locale))
  const { data: translations } = useQuery(['translations'], ({ signal }) =>
    translationsApi.getTranslationConfig({}, { signal })
  )

  useTitle({ placeName: place?.displayName || place?.name })
  useTimeZoneFilter(placeId)

  useEffect(setCrumbs, [place, locale])

  function setCrumbs() {
    const crumb = getCrumbs(tr.breadcrumbs)

    const crumbs = !placeId
      ? [crumb.home(), crumb.places(), crumb.createPlace()]
      : [
          crumb.home(),
          crumb.places(),
          crumb.place([placeId], place?.displayName || place?.name || tr.breadcrumbs.place),
          crumb.editPlace([placeId])
        ]

    dispatch(setBreadcrumbs(crumbs))
  }

  async function handleDelete(id: string | number) {
    setOperation(true)

    placeApi
      .deletePlace(id)
      .then(() => {
        dispatch(invalidatePlaceList())
        dispatch(showMessage(tr.placeOperationMessages.deleteSuccess, MessageTypes.Success))
        history.push(Nav.places())
      })
      .catch(() => {
        dispatch(showMessage(tr.placeOperationMessages.deleteError, MessageTypes.Error))
        setOperation(false)
      })
  }

  async function handleDuplicate(id: string | number) {
    setOperation(true)

    placeApi
      .duplicatePlace(id)
      .then((data) => {
        dispatch(invalidatePlaceList())
        dispatch(showMessage(tr.placeOperationMessages.duplicateSuccess, MessageTypes.Success))
        history.push(Nav.place(String(data.id)))
      })
      .catch(() => {
        dispatch(showMessage(tr.placeOperationMessages.duplicateError, MessageTypes.Error))
        setOperation(false)
      })
  }

  async function handleSave(data) {
    setOperation(true)
    const msg = tr.placeOperationMessages

    if (placeId) {
      placeApi
        .updatePlace(data)
        .then(() => {
          dispatch(invalidatePlaceList())
          dispatch(showMessage(msg.updateSuccess, MessageTypes.Success))
          history.push(Nav.place(placeId))
        })
        .catch(() => {
          dispatch(showMessage(msg.updateError, MessageTypes.Error))
        })
    } else {
      placeApi
        .createPlace(data)
        .then(() => {
          dispatch(invalidatePlaceList())
          dispatch(showMessage(msg.createSuccess, MessageTypes.Success))
          history.push(Nav.places())
        })
        .catch(() => {
          dispatch(showMessage(msg.createError, MessageTypes.Error))
        })
    }
  }

  if (isFetching || operation) {
    return (
      <CenterContainer>
        <CircularProgress color="secondary" />
      </CenterContainer>
    )
  }

  if (!place) {
    return null
  }

  return (
    <Container>
      <PlaceEdit
        data={place}
        brands={brands?.data || []}
        timeZones={timeZones || []}
        onSave={handleSave}
        onDelete={handleDelete}
        isAdmin={isAdmin}
        countries={(countriesValue || []).sort(sortCountries)}
        canDuplicatePlace={canAddAndDeleteShops || false}
        onDuplicate={handleDuplicate}
        languages={translations?.data?.languages || []}
      />
    </Container>
  )
}

export default PlaceEditPage
