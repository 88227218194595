import { useEffect, useState } from 'react'
import { removePosition, getPosition } from './suggestion-dialog-data'
import { SuggestionDialogContent } from './suggestion-dialog-content'
import { AppBar } from 'shared/ui-kit/app-bar'
import { Title } from 'shared/ui-kit/app-bar'
import { useTranslation } from 'shared/i18n/translate'
import { useParams } from 'react-router'

export function openWindowOnCenter({ url, w, h }: { url: string; w: number; h: number }) {
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width

  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height

  const systemZoom = width / window.screen.availWidth
  const left = (width - w) / 2 / systemZoom + dualScreenLeft
  const top = (height - h) / 2 / systemZoom + dualScreenTop

  let dialog: Window | null = null

  try {
    dialog = window.open(
      url,
      '_blank',
      `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    )
  } catch (err) {
    console.info(err)
  }

  if (dialog?.focus) {
    dialog.focus()
  }

  return dialog
}

function SuggestionBlankDialog() {
  const [data] = useState(getPosition())
  const { tr } = useTranslation()
  const { lineId, placeId, checkpointId } = useParams<{ lineId: string; placeId: string; checkpointId: string }>()

  function beforeUnload() {
    removePosition()
  }

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload, false)
  }, [])

  function close() {
    removePosition()
    window.close()
  }

  if (!data) {
    return null
  }

  return (
    <>
      <AppBar>
        <Title>{tr.common.appName}</Title>
      </AppBar>
      <SuggestionDialogContent
        lineId={lineId}
        shopId={placeId}
        getCheckpointId={checkpointId}
        data={data.position}
        timeToReach={data.timeToReach}
        requestCommentsOnRemove={data.requestCommentsOnRemove}
        onWait={close}
        onStart={close}
        onRemove={close}
      />
    </>
  )
}

export default SuggestionBlankDialog
