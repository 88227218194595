import { Form, FormGroup } from 'shared/ui-kit/form'
import { TextField } from 'shared/ui-kit/text-field'
import Toggle from 'shared/ui-kit/toggle-wrap'
import { useTranslation } from 'shared/i18n/translate'
import { isPositiveNumber } from 'shared/utils/string-test'
import { Controller } from 'react-hook-form'
import { GroupProps } from '../types'
import { WithHelper } from 'features/element-with-helper'
import { Card } from '@mui/material'
import TextFieldMUI from '@mui/material/TextField'

function AppointmentForNextDayGroup({ control, setValue, trigger, watch }: GroupProps) {
  const { tr } = useTranslation()

  const {
    timePlaceholder,
    timeHint,
    allowFutureAppointments,
    limitInDays,
    limitInDaysPlaceholder,
    sendMesage,
    sendMesageSms,
    messageDaysBefore,
    messageSmsDaysBefore,
    attachIcsFile,
    authoriseAppointmentOnWebPortal,
    blindCopyEmails,
    blindCopyEmailsPlaceholder,
    visibleInWebPortal,
    confirmEmailWithValidationCode,
    progressivelyBooking,
    daysLimitForAllTimeslotsAvailables,
    allowMultipleAppointmentsOnSameDay,
    limitInDaysForFutureDateAdmin,
    retainTimeslotWhenAppointmentCancelled,
    minDurationToFreeTimeslot,
    maxDurationToFreeTimeslot,
    acceptTimeslotsOverlapForSameCustomer
  } = tr.lineEdit

  return (
    <Card>
      <Form>
        <FormGroup>
          <Controller
            name="allowFutureAppointments"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={allowFutureAppointments}
                withHelper={true}
                tag="line"
                tooltipKey="allowFutureAppointments"
                onToggle={(_, val) => {
                  f.onChange(val)

                  setValue('visibleInWebPortal', false)
                  setValue('confirmEmailWithValidationCode', false)
                  setValue('attachIcsFile', false)
                  setValue('authoriseAppointmentOnWebPortal', false)
                  setValue('limitInDays', 0)
                  setValue('sendFollowUpEmailBefore', false)
                  setValue('sendFollowUpSmsBefore', false)

                  if (!val) {
                    setValue('freeTimeslotsProgressively', false)
                  }

                  trigger()
                }}
              />
            )}
          />
        </FormGroup>
        {watch('allowFutureAppointments') && (
          <>
            <FormGroup>
              <WithHelper tooltipKey="limitInDays" tag="line">
                <Controller
                  name="limitInDays"
                  control={control}
                  render={({ field: f, formState }) => (
                    <TextField
                      attention={!!formState.errors.limitInDays}
                      label={limitInDays}
                      placeholder={limitInDaysPlaceholder}
                      value={f.value}
                      onChange={(val) => {
                        f.onChange(val)
                        trigger()
                      }}
                      test={isPositiveNumber}
                      hint={tr.time.daysHint}
                      fullWidth
                    />
                  )}
                />
              </WithHelper>
            </FormGroup>
            <FormGroup>
              <Controller
                name="freeTimeslotsProgressively"
                control={control}
                render={({ field: f }) => (
                  <Toggle
                    toggled={f.value}
                    label={progressivelyBooking}
                    withHelper={true}
                    tag="line"
                    tooltipKey="freeTimeslotsProgressively"
                    onToggle={(_, val) => {
                      f.onChange(val)
                      setValue('timeslotsFreeAllBeforeDays', 0)

                      const newServices = (watch('services') || []).map((service) => ({
                        ...service,
                        progressivelyBooking: false
                      }))
                      setValue('services', newServices)
                      trigger()
                    }}
                  />
                )}
              />
            </FormGroup>
            {watch('freeTimeslotsProgressively') && (
              <FormGroup>
                <WithHelper tooltipKey="timeslotsFreeAllBeforeDays" tag="line">
                  <Controller
                    name="timeslotsFreeAllBeforeDays"
                    control={control}
                    render={({ field: f, formState }) => (
                      <TextField
                        fullWidth
                        attention={!!formState.errors.timeslotsFreeAllBeforeDays}
                        label={daysLimitForAllTimeslotsAvailables}
                        placeholder={daysLimitForAllTimeslotsAvailables}
                        value={f.value}
                        onChange={f.onChange}
                        test={isPositiveNumber}
                        hint={tr.time.daysHint}
                      />
                    )}
                  />
                </WithHelper>
              </FormGroup>
            )}
            <FormGroup>
              <Controller
                name="allowMultipleAppointmentsOnSameDay"
                control={control}
                render={({ field: f }) => (
                  <Toggle
                    toggled={f.value}
                    label={allowMultipleAppointmentsOnSameDay}
                    onToggle={(_, val) => f.onChange(val)}
                    withHelper={true}
                    tag="line"
                    tooltipKey="allowMultipleAppointmentsOnSameDay"
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="acceptTimeslotsOverlapForSameCustomer"
                control={control}
                render={({ field: f }) => (
                  <Toggle
                    toggled={f.value}
                    label={acceptTimeslotsOverlapForSameCustomer}
                    onToggle={(_, val) => f.onChange(val)}
                    withHelper={true}
                    tag="line"
                    tooltipKey="acceptTimeslotsOverlapForSameCustomer"
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="visibleInWebPortal"
                control={control}
                render={({ field: f }) => (
                  <Toggle
                    toggled={f.value}
                    label={visibleInWebPortal}
                    onToggle={(_, val) => f.onChange(val)}
                    withHelper={true}
                    tag="line"
                    tooltipKey="visibleInWebPortal"
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="confirmEmailWithValidationCode"
                control={control}
                render={({ field: f }) => (
                  <Toggle
                    toggled={f.value}
                    label={confirmEmailWithValidationCode}
                    onToggle={(_, val) => f.onChange(val)}
                    withHelper={true}
                    tag="line"
                    tooltipKey="confirmEmailWithValidationCode"
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="attachIcsFile"
                control={control}
                render={({ field: f }) => (
                  <Toggle
                    toggled={f.value}
                    label={attachIcsFile}
                    onToggle={(_, val) => f.onChange(val)}
                    withHelper={true}
                    tag="line"
                    tooltipKey="attachIcsFile"
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="authoriseAppointmentOnWebPortal"
                control={control}
                render={({ field: f }) => (
                  <Toggle
                    toggled={f.value}
                    label={authoriseAppointmentOnWebPortal}
                    onToggle={(_, val) => f.onChange(val)}
                    withHelper={true}
                    tag="line"
                    tooltipKey="authoriseAppointmentOnWebPortal"
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <WithHelper tooltipKey="limitInDaysForFutureDateAdmin" tag="line">
                <Controller
                  name="limitInDaysForFutureDateAdmin"
                  control={control}
                  render={({ field: f }) => (
                    <TextField
                      label={limitInDaysForFutureDateAdmin}
                      placeholder={limitInDaysPlaceholder}
                      value={f.value}
                      onChange={f.onChange}
                      test={isPositiveNumber}
                      hint={tr.time.daysHint}
                      fullWidth
                    />
                  )}
                />
              </WithHelper>
            </FormGroup>
            <FormGroup>
              <Controller
                name="sendFollowUpEmailBefore"
                control={control}
                render={({ field: f }) => (
                  <Toggle
                    toggled={f.value}
                    label={sendMesage}
                    onToggle={(_, val) => {
                      f.onChange(val)
                      setValue('sendFollowUpEmailBeforeDays', 0)
                      trigger()
                    }}
                    withHelper={true}
                    tag="line"
                    tooltipKey="sendFollowUpEmailBefore"
                  />
                )}
              />
            </FormGroup>
            {watch('sendFollowUpEmailBefore') && (
              <FormGroup>
                <WithHelper tooltipKey="sendFollowUpEmailBeforeDays" tag="line">
                  <Controller
                    name="sendFollowUpEmailBeforeDays"
                    control={control}
                    render={({ field: f, formState }) => (
                      <TextField
                        attention={!!formState.errors.sendFollowUpEmailBeforeDays}
                        label={messageDaysBefore}
                        placeholder={limitInDaysPlaceholder}
                        value={String(f.value)}
                        onChange={f.onChange}
                        test={isPositiveNumber}
                        hint={tr.time.daysHint}
                        fullWidth
                      />
                    )}
                  />
                </WithHelper>
              </FormGroup>
            )}
            <FormGroup>
              <Controller
                name="sendFollowUpSmsBefore"
                control={control}
                render={({ field: f }) => (
                  <Toggle
                    toggled={f.value}
                    label={sendMesageSms}
                    onToggle={(_, val) => {
                      f.onChange(val)
                      setValue('sendFollowUpSmsBeforeDays', 0)
                      trigger()
                    }}
                    withHelper={true}
                    tag="line"
                    tooltipKey="sendFollowUpSmsBefore"
                  />
                )}
              />
            </FormGroup>
            {watch('sendFollowUpSmsBefore') && (
              <FormGroup>
                <WithHelper tooltipKey="sendFollowUpSmsBeforeDays" tag="line">
                  <Controller
                    name="sendFollowUpSmsBeforeDays"
                    control={control}
                    render={({ field: f, formState }) => (
                      <TextField
                        attention={!!formState.errors.sendFollowUpSmsBeforeDays}
                        label={messageSmsDaysBefore}
                        placeholder={limitInDaysPlaceholder}
                        value={f.value}
                        onChange={f.onChange}
                        test={isPositiveNumber}
                        hint={tr.time.daysHint}
                        fullWidth
                      />
                    )}
                  />
                </WithHelper>
              </FormGroup>
            )}
            <FormGroup>
              <Controller
                name="retainTimeslotWhenAppointmentCancelled"
                control={control}
                render={({ field: f }) => (
                  <Toggle
                    toggled={f.value}
                    label={retainTimeslotWhenAppointmentCancelled}
                    onToggle={(_, val) => {
                      f.onChange(val)
                      trigger()
                    }}
                    withHelper={true}
                    tag="line"
                    tooltipKey="retainTimeslotWhenAppointmentCancelled"
                  />
                )}
              />
            </FormGroup>
            {watch('retainTimeslotWhenAppointmentCancelled') && (
              <>
                <FormGroup>
                  <WithHelper tooltipKey="minDurationToFreeTimeslot" tag="line">
                    <Controller
                      name="minDurationToFreeTimeslot"
                      control={control}
                      render={({ field: f, formState }) => (
                        <TextField
                          attention={!!formState.errors.minDurationToFreeTimeslot}
                          label={minDurationToFreeTimeslot}
                          placeholder={timePlaceholder}
                          value={f.value}
                          onChange={(val) => {
                            f.onChange(val)
                            trigger()
                          }}
                          test={isPositiveNumber}
                          hint={timeHint}
                          fullWidth
                        />
                      )}
                    />
                  </WithHelper>
                </FormGroup>
                <FormGroup>
                  <WithHelper tooltipKey="maxDurationToFreeTimeslot" tag="line">
                    <Controller
                      name="maxDurationToFreeTimeslot"
                      control={control}
                      render={({ field: f, formState }) => (
                        <TextField
                          attention={!!formState.errors.maxDurationToFreeTimeslot}
                          label={maxDurationToFreeTimeslot}
                          placeholder={timePlaceholder}
                          value={f.value}
                          onChange={(val) => {
                            f.onChange(val)
                            trigger()
                          }}
                          test={isPositiveNumber}
                          hint={timeHint}
                          fullWidth
                        />
                      )}
                    />
                  </WithHelper>
                </FormGroup>
              </>
            )}
            <FormGroup>
              <WithHelper tooltipKey="blindCopyEmails" tag="line">
                <Controller
                  name="blindCopyEmails"
                  control={control}
                  render={({ field: f, fieldState }) => (
                    <TextFieldMUI
                      label={blindCopyEmails}
                      placeholder={blindCopyEmailsPlaceholder}
                      value={f.value}
                      onChange={f.onChange}
                      multiline
                      error={!!fieldState.error}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      maxRows={15}
                    />
                  )}
                />
              </WithHelper>
            </FormGroup>
          </>
        )}
      </Form>
    </Card>
  )
}

export { AppointmentForNextDayGroup }
