import { CSSProperties } from 'react'

type Props = {
  text: string
  faded?: boolean
  style?: CSSProperties
}

function TimerPartComponent(props: Props) {
  const { text, faded = false, style } = props

  return <div style={{ opacity: faded ? 0.28 : 'unset', display: 'inline-block', ...style }}>{text}</div>
}

export const TimerPart = TimerPartComponent
export default TimerPartComponent
