import { CircularProgress, Dialog, Typography } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import { lazy, Suspense, useEffect, useRef, useState } from 'react'
import { Error as ErrorIcon } from '@mui/icons-material'
import { monitoring } from 'shared/api'
import { PositionCustomer } from 'shared/models'
import { QRCenterWrapper } from './styled'
import { OnResultFunction } from 'shared/utils/qr-reader/types'

const QrReader = lazy(() => import('shared/utils/qr-reader'))

const videoContainerStyle = { width: '100%', overflow: 'hidden', position: 'relative', paddingTop: 0 }

type Props = {
  open: boolean
  lineId: number
  placeId: number
  onClose: () => void
  onSuccess: (data: PositionCustomer) => void
}

function QRScanPersonalInfoModal({ onClose, onSuccess, open, lineId, placeId }: Props) {
  const { tr } = useTranslation()

  const timeout = useRef<NodeJS.Timer>()
  const qrFormatMessageRef = useRef<HTMLDivElement>(null)
  const lastScanCode = useRef<string>('')

  const [loading, setLoading] = useState(false)

  const handleQrResult: OnResultFunction = (text) => {
    if (text && !loading && text !== lastScanCode.current) {
      lastScanCode.current = text
      decodeFromServer(text, lineId, placeId)
    }
  }

  useEffect(() => {
    setLoading(false)
  }, [open])

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current as any)
    }
  }, [])

  async function decodeFromServer(code: string, lineId: number, placeId: number) {
    setLoading(true)
    try {
      const { data } = await monitoring.decodeCustomerData({ code, placeId, lineId })
      onSuccess(data)
    } catch (err) {
      showCodeError()
    } finally {
      setLoading(false)
      lastScanCode.current = ''
    }
  }

  function showCodeError() {
    if (qrFormatMessageRef.current) {
      qrFormatMessageRef.current.style.opacity = '1'
      timeout.current = setTimeout(() => {
        if (qrFormatMessageRef.current) {
          qrFormatMessageRef.current.style.opacity = '0'
        }
      }, 5000)
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <QRCenterWrapper style={{ opacity: loading ? 1 : 0 }}>
        <CircularProgress />
      </QRCenterWrapper>
      <QRCenterWrapper ref={qrFormatMessageRef} style={{ opacity: 0 }}>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '1rem' }}>
          <ErrorIcon fontSize="large" color="error" style={{ width: '82px', height: '82px' }} />
          <Typography color="error" variant="h6">
            {tr.qr.scanError}
          </Typography>
        </div>
      </QRCenterWrapper>
      <Suspense fallback={null}>
        <QrReader onResult={handleQrResult} videoContainerStyle={videoContainerStyle} />
      </Suspense>
      <div style={{ padding: '2rem' }}>
        <div style={{ textAlign: 'center', fontSize: '24px' }}>{tr.lineMonitoring.qrTitle}</div>
      </div>
    </Dialog>
  )
}

export { QRScanPersonalInfoModal }
