import { forwardRef, CSSProperties, ReactNode } from 'react'
import css from './card.module.scss'
import { injectTestAttr } from 'shared/utils/test-attr'

type Props = {
  style?: CSSProperties
  paperStyle?: CSSProperties
  id?: string
  children?: ReactNode
  className?: string
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

const Card = forwardRef((props: Props, ref: React.LegacyRef<HTMLElement>) => (
  <section
    onClick={props.onClick}
    className={css.root}
    style={props.style}
    id={props.id}
    ref={ref}
    {...injectTestAttr(props)}
  >
    <div className={`${css.paper} ${props.className}`} style={props.paperStyle}>
      {props.children}
    </div>
  </section>
))

export default Card
