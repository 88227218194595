import { useTranslation } from 'shared/i18n/translate'
import { JournalActionModel } from 'shared/models'
import Text from 'shared/ui-kit/text'
import { getServiceDurationTime } from '../utils/getActionText'

function RenderFinishService({ action }: { action: JournalActionModel }) {
  const { tr } = useTranslation()

  return (
    <div style={{ margin: '0.5rem 0', display: 'flex', gap: '0.5rem', flexDirection: 'column' }}>
      <div style={{ flex: 1 }}>
        <Text type="caption">
          {tr.journal.finishService([action.operator?.firstName, action.operator?.lastName].filter(Boolean).join(' '))}
        </Text>
      </div>
      {!!action.standardServiceDuration && (
        <div style={{ flex: 1 }}>
          <Text type="caption">{tr.journal.standardServiceDuration}</Text>
          <Text type="body-1">{getServiceDurationTime(action.standardServiceDuration, tr)}</Text>
        </div>
      )}
      {!!action.realServiceDuration && (
        <div style={{ flex: 1 }}>
          <Text type="caption">{tr.journal.realServiceDuration}</Text>
          <Text type="body-1">{getServiceDurationTime(action.realServiceDuration, tr)}</Text>
        </div>
      )}
    </div>
  )
}

export { RenderFinishService }
