import { Form } from 'shared/ui-kit/form'
import { Button, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import { useState } from 'react'
import { place as placeApi } from 'shared/api'
import { MessageTypes, showMessage } from 'store/actions/main-layout-action-creators'
import { useAppDispatch } from 'store'
import { DeleteDialogProps } from './types'

function DeleteDialog({ template, onClose }: DeleteDialogProps) {
  const { tr } = useTranslation()
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)

  async function handleDelete() {
    try {
      setLoading(true)
      await placeApi.deleteExportTemplate(template.value)
      dispatch(showMessage(tr.exportData.successDeletedMessage, MessageTypes.Success))
      onClose(true)
    } catch (err) {
      setLoading(false)
    }
  }

  function handleCloseDialog() {
    onClose()
  }

  return (
    <Form>
      <DialogTitle>{template.text}</DialogTitle>
      <DialogContent>{tr.exportData.requestDeleteTemplate}</DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" size="small" onClick={handleDelete} disabled={loading}>
          {tr.exportData.yes}
        </Button>
        <Button variant="outlined" size="small" onClick={handleCloseDialog} disabled={loading}>
          {tr.exportData.no}
        </Button>
      </DialogActions>
    </Form>
  )
}

export { DeleteDialog }
