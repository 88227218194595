import { TextField } from 'shared/ui-kit/text-field'
import Button from '@mui/material/Button'
import Card from 'shared/ui-kit/card'
import { useTranslation } from 'shared/i18n/translate'
import { addTestAttr } from 'shared/utils/test-attr'
import { Controller, useForm } from 'react-hook-form'
import { useLocation } from 'react-router'
import { ResetButtons, ResetForm, ResetText } from '../styled'

export type ResetForm = { email: string }

type Props = {
  onSubmit: (data: ResetForm) => void
  onCancel: () => void
}

function ResetPasswordComponent(props: Props) {
  const { tr } = useTranslation()
  const { login: translation } = tr
  const { onSubmit, onCancel } = props

  const { search } = useLocation()

  const params = new URLSearchParams(search)

  const { control, handleSubmit, formState } = useForm<ResetForm>({
    defaultValues: { email: params.get('email') || '' }
  })

  return (
    <Card>
      <ResetForm onSubmit={handleSubmit(onSubmit)}>
        <ResetText>{translation.forgotMessage}</ResetText>
        <div>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{ required: true, pattern: /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/ }}
            render={({ field: f }) => (
              <TextField
                value={f.value}
                placeholder={translation.email}
                onChange={f.onChange}
                {...addTestAttr('ResetPassword-TextField')}
              />
            )}
          />
        </div>
        <ResetButtons>
          <Button
            variant="contained"
            color="primary"
            disabled={!formState.isValid}
            type="submit"
            {...addTestAttr('ResetPassword-Submit')}
          >
            {translation.sendInstruction}
          </Button>
          <Button {...addTestAttr('ResetPassword-Cancel')} onClick={onCancel} style={{ marginLeft: 8, height: 36 }}>
            {translation.cancel}
          </Button>
        </ResetButtons>
      </ResetForm>
    </Card>
  )
}

export default ResetPasswordComponent
export const ResetPassword = ResetPasswordComponent
