import { ChipListSelectBaseProps } from '../types'
import { ChipListMultipleSelect } from './chip-list-select-multiple'

type Props<T, V> = ChipListSelectBaseProps<T, V, false>

function ChipListSingleSelectComponent<T, V>(props: Props<T, V>) {
  const { value, onChange } = props

  function onChangeHandler(valueFromMultiple: (T | V)[] = []) {
    onChange?.(valueFromMultiple.length ? valueFromMultiple[valueFromMultiple.length - 1] : undefined)
  }

  return (
    <ChipListMultipleSelect
      {...props}
      value={value === undefined || value === null ? [] : [value]}
      onChange={onChangeHandler}
    />
  )
}

export default ChipListSingleSelectComponent
export const ChipListSingleSelect = ChipListSingleSelectComponent
