import axios from 'axios'
import { CustomerAPI } from 'shared/models'
import { getUrl } from './helpers'

const CancelToken = axios.CancelToken

const customer: CustomerAPI = {
  getCustomer(data, config) {
    return axios.get('reports/getCustomers', { params: data, ...config })
  },
  downloadCustomers(data) {
    location.href = getUrl('report/downloadCustomers', data)
  }
}

export default customer
