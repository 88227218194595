import styled from '@emotion/styled'
import { Avatar } from '@mui/material'

const TitleStyled = styled.section`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 0 32px;
  padding: 0;
  letter-spacing: 0;
  font-size: 22px;
  font-weight: 600;
  color: #ffffff;
  min-width: 64px;
  transition: margin 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    margin: 0 0 0 0.5rem;
  }
`

const AvatarStyled = styled(Avatar)`
  background-color: rgba(255, 255, 255, 0.3);
`

const TimezoneZone = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 42px;
`

const TimezoneName = styled.div`
  color: #fff;
  margin-right: 1rem;
  font-weight: 200;
  font-size: 14px;
`

const UserStyled = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-right: 2rem;
  height: 42px;

  @media screen and (max-width: 768px) {
    padding-right: 1.5rem;
  }
`

const UserName = styled.div`
  color: #fff;
  margin-right: 1rem;
  font-size: 15px;
  line-height: 16px;
  display: block;

  @media screen and (max-width: 576px) {
    display: none;
  }
`

const UserPopover = styled.div`
  padding: 1rem 0.5rem;
`

const UserPopoverUser = styled.div`
  padding: 0 8px;
  margin-bottom: 0.5rem;
`

const UserPopoverName = styled.div`
  font-weight: 500;
  opacity: 0.86;
`

const UserButtonsWrap = styled.div`
  display: flex;
  white-space: nowrap;
`

export {
  TitleStyled,
  AvatarStyled,
  TimezoneZone,
  TimezoneName,
  UserStyled,
  UserName,
  UserPopover,
  UserPopoverUser,
  UserPopoverName,
  UserButtonsWrap
}
