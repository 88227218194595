import { Optional } from 'shared/types'
import { LastUpdateModel } from './last-update.model'

export enum AdvertisementTypes {
  IMAGE = 'Image',
  TEXT = 'Text',
  LINK = 'Link',
  VIDEO = 'Video',
  PDF = 'Pdf'
}

export type AdvertisementFile = {
  id: string
  name?: string
}

export type AdvertisementSchedule = {
  startHour_unixtime: number | null
  endHour_unixtime: number | null
  id?: number
}

export interface AdvertisementModel {
  id: string | number
  campaignId: string | number
  name: string
  type: AdvertisementTypes
  image?: AdvertisementFile
  video?: AdvertisementFile
  pdf?: AdvertisementFile
  text?: string
  link: string
  withLink: boolean
  afterBookingIsFinished?: boolean
  afterServiceIsFinished?: boolean
  minimumWaitingTime?: number
  maximumDistanceToDisplay?: number
  afterCallToServicePoint?: boolean
  afterServiceStarted?: boolean
  allLanguages: boolean
  languages?: any
  displayOrder: number
  lastUpdate?: LastUpdateModel
  infoPad?: boolean
  acceptationPad?: boolean
  paperTicket?: boolean
  webFrame?: boolean
  smartphone?: boolean
  orientation?: 'Any' | 'Horizontal' | 'Vertical'
  specificHours?: boolean
  startDate?: number | null
  endDate?: number | null
  durationTime?: number | null
  useDurationTimeForVideo?: boolean
  videoAccelerationFactor?: number | null
  displaySchedule?: AdvertisementSchedule[]
  specificHoursIntervals?: boolean
  volume?: number
}

export interface AdvertisementsOrderModel {
  campaignId: string | number
  items: { advertisementId: string | number }[]
}

export type AdvertisementModelEdit = Optional<AdvertisementModel, 'id'>
