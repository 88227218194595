function CheckpointStatusIndicator({ isStart }: { isStart: boolean }) {
  return (
    <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div
        style={{
          width: '12px',
          height: '12px',
          backgroundColor: isStart ? '#4caf50' : '#c5c5c5',
          borderRadius: '50%',
          opacity: '0.83'
        }}
      />
    </div>
  )
}

export default CheckpointStatusIndicator
export { CheckpointStatusIndicator }
