import Dropdown from 'shared/ui-kit/dropdown'
import { Controller } from 'react-hook-form'
import { ComboControlProps } from '.'

export function ComboControl<T>(props: ComboControlProps<T>) {
  return (
    <div style={{ margin: '0 0 0.5rem' }}>
      <Controller
        name={props.name}
        control={props.control}
        render={({ field: f, formState }) => (
          <Dropdown
            value={f.value}
            data={props.data}
            fullWidth
            label={props.label}
            dataConverter={props.dataConverter}
            shrink
            allowClear={!props.isMandatory}
            attention={(props.isMandatory && !f.value) || !!formState.errors[props.name]?.message}
            onChange={f.onChange}
            formHalperText={formState.errors[props.name]?.message as string}
          />
        )}
      />
    </div>
  )
}
