import axios from 'axios'
import { HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr'
import { countRetryPolicy } from 'shared/utils/signal-r'
import { CheckpointMonitoringModel, DiscreteMonitoringModel, MonitoringAPI, LineMonitoringModel } from 'shared/models'
import { diff_match_patch } from 'diff-match-patch'
import { monitoringHubErrorHandler } from '.'

const monitoring: MonitoringAPI = {
  getCodeForMobilePosition({ shopId, lineId, positionId }, config) {
    return axios.get('/GetCodeForMobilePosition', { params: { shopId, lineId, positionId }, ...config })
  },
  getDiscreteLineMonitoring(lineId, config) {
    return axios.get('getDiscreteLineMonitoring', { params: { lineId }, ...config })
  },
  getLineMonitoring(data, config) {
    return axios.get('getLineMonitoring', { params: data, ...config })
  },
  monitoringPositionsChanged(subscription, onDataChange, onLineChange) {
    if (process.env.APP_FAKES === 'true') {
      return {
        stop: () => Promise.resolve({})
      }
    }

    const { lineId, placeId } = subscription

    const connection = new HubConnectionBuilder()
      .withUrl('/MonitoringHub', { transport: HttpTransportType.WebSockets | HttpTransportType.LongPolling })
      .withAutomaticReconnect(countRetryPolicy())
      .build()

    const dmp = new diff_match_patch()

    const processUpdate = (content) => {
      const data = JSON.parse(content.data) as LineMonitoringModel
      subscription.jsonData = content.data
      subscription.hash = content.hash
      onDataChange(data)
    }

    function processLineUpdate() {
      onLineChange?.()
    }

    const processPatch = (content) => {
      if (!subscription.jsonData || !subscription.hash || subscription.hash !== content.previousHash) {
        connection.invoke('RequestFullMonitoring')
        return
      }

      const patches = dmp.patch_fromText(content.patch)
      const [newJsonData] = dmp.patch_apply(patches, subscription.jsonData)

      processUpdate({ data: newJsonData, hash: content.hash })
    }

    connection.onreconnected(() => {
      connection.invoke('LineSubscribe', placeId, lineId)
    })

    connection.onclose((error) => {
      if (error) {
        setTimeout(
          () => {
            connection.start().then(() => {
              connection.on('MonitoringError', monitoringHubErrorHandler(connection))
              connection.on('MonitoringUpdate', processUpdate)
              connection.on('MonitoringPatch', processPatch)
              connection.on('LineUpdate', processLineUpdate)
              connection.invoke('LineSubscribe', placeId, lineId)
            })
          },
          1000 + Math.random() * 30000
        )
      }
    })

    connection
      .start()
      .then(() => {
        connection.on('MonitoringError', monitoringHubErrorHandler(connection))
        connection.on('MonitoringUpdate', processUpdate)
        connection.on('MonitoringPatch', processPatch)
        connection.on('LineUpdate', processLineUpdate)
        connection.invoke('LineSubscribe', placeId, lineId)
      })
      .catch((error) => console.warn(error))

    return {
      stop: () => {
        connection.off('MonitoringError', monitoringHubErrorHandler(connection))
        connection.off('MonitoringUpdate', processUpdate)
        connection.off('MonitoringPatch', processPatch)
        connection.off('LineUpdate', processLineUpdate)
        return connection.stop()
      }
    }
  },
  discretePositionsChanged(subscription, onDataChange, onLineChange) {
    if (process.env.APP_FAKES === 'true') {
      return {
        stop: () => Promise.resolve({})
      }
    }

    const { placeId, lineId } = subscription

    const connection = new HubConnectionBuilder()
      .withUrl('/MonitoringHub', { transport: HttpTransportType.WebSockets | HttpTransportType.LongPolling })
      .withAutomaticReconnect(countRetryPolicy())
      .build()

    const dmp = new diff_match_patch()

    function processLineUpdate() {
      onLineChange?.()
    }

    function processUpdate(content) {
      const data = JSON.parse(content.data) as DiscreteMonitoringModel
      subscription.jsonData = content.data
      subscription.hash = content.hash
      onDataChange(data)
    }

    function processPatch(content) {
      if (!subscription.jsonData || !subscription.hash || subscription.hash !== content.previousHash) {
        connection.invoke('RequestFullMonitoring')
        return
      }

      const patches = dmp.patch_fromText(content.patch)
      const [newJsonData] = dmp.patch_apply(patches, subscription.jsonData)

      processUpdate({ data: newJsonData, hash: content.hash })
    }

    connection.onreconnected(() => {
      connection.invoke('DiscreteLineSubscribe', placeId, lineId)
    })

    connection.onclose((error) => {
      if (error) {
        setTimeout(
          () => {
            connection.start().then(() => {
              connection.on('MonitoringError', monitoringHubErrorHandler(connection))
              connection.on('MonitoringUpdate', processUpdate)
              connection.on('MonitoringPatch', processPatch)
              connection.on('LineUpdate', processLineUpdate)
              connection.invoke('DiscreteLineSubscribe', placeId, lineId)
            })
          },
          1000 + Math.random() * 30000
        )
      }
    })

    connection
      .start()
      .then(() => {
        connection.on('MonitoringError', monitoringHubErrorHandler(connection))
        connection.on('MonitoringUpdate', processUpdate)
        connection.on('MonitoringPatch', processPatch)
        connection.on('LineUpdate', processLineUpdate)
        connection.invoke('DiscreteLineSubscribe', placeId, lineId)
      })
      .catch((error) => console.warn(error))

    return {
      stop: async () => {
        connection.off('MonitoringError', monitoringHubErrorHandler(connection))
        connection.off('MonitoringUpdate', processUpdate)
        connection.off('MonitoringPatch', processPatch)
        connection.off('LineUpdate', processLineUpdate)
        return connection.stop()
      }
    }
  },
  getCheckpointMonitoring(data, config) {
    return axios.get('getCheckpointMonitoring', { params: data, ...config })
  },
  checkpointMonitoringPositionsChanged(subscription, onDataChange) {
    if (process.env.APP_FAKES === 'true') {
      return {
        stop: () => Promise.resolve({})
      }
    }

    const { placeId, lineId, checkpointId, serviceIds, isTemporaryHelp, maxTimeDuration } = subscription

    const connection = new HubConnectionBuilder()
      .withUrl('/MonitoringHub', { transport: HttpTransportType.WebSockets | HttpTransportType.LongPolling })
      .withAutomaticReconnect(countRetryPolicy())
      .build()

    const dmp = new diff_match_patch()

    const processUpdate = (content) => {
      const data = JSON.parse(content.data) as CheckpointMonitoringModel
      subscription.jsonData = content.data
      subscription.hash = content.hash
      onDataChange(data)
    }

    const processPatch = (content) => {
      if (!subscription.jsonData || !subscription.hash || subscription.hash !== content.previousHash) {
        connection.invoke('RequestFullMonitoring')
        return
      }

      const patches = dmp.patch_fromText(content.patch)
      const [newJsonData] = dmp.patch_apply(patches, subscription.jsonData)

      processUpdate({ data: newJsonData, hash: content.hash })
    }

    const params = {
      placeId,
      lineId,
      checkpointId,
      serviceIds: serviceIds?.join(','),
      isTemporaryHelp,
      maxTimeDurationInSec: maxTimeDuration
    }

    connection.onreconnected(() => {
      connection.invoke('CheckpointSubscribe', params)
    })

    connection.onclose((error) => {
      if (error) {
        setTimeout(
          () => {
            connection.start().then(() => {
              connection.on('MonitoringError', monitoringHubErrorHandler(connection))
              connection.on('MonitoringUpdate', processUpdate)
              connection.on('MonitoringPatch', processPatch)
              connection.invoke('CheckpointSubscribe', params)
            })
          },
          1000 + Math.random() * 30000
        )
      }
    })

    connection
      .start()
      .then(() => {
        connection.on('MonitoringError', monitoringHubErrorHandler(connection))
        connection.on('MonitoringUpdate', processUpdate)
        connection.on('MonitoringPatch', processPatch)
        connection.invoke('CheckpointSubscribe', params)
      })
      .catch(console.warn)

    return {
      stop: () => {
        connection.off('MonitoringError', monitoringHubErrorHandler(connection))
        connection.off('MonitoringUpdate', processUpdate)
        connection.off('MonitoringPatch', processPatch)
        return connection.stop()
      }
    }
  },
  addPosition(data, config) {
    return axios.post('addPosition', data, config)
  },
  changePositionState(data, config) {
    return axios.post('changePositionState', data, config)
  },
  transferPosition(data, config) {
    return axios.post('transferPosition', data, config)
  },
  validatePosition(data, config) {
    return axios.post('validatePosition', data, config)
  },
  changeProgressionTags(data, config) {
    return axios.put('changeProgressionTags', data, config)
  },
  getTimeslots(params, config) {
    return axios.get('management/position/timeslots', { params, ...config })
  },
  createPosition(data, config) {
    return axios.post('management/position/editForm', data, config)
  },
  editPosition(data, config) {
    return axios.post('management/position/editForm', data, config)
  },
  getPositionEditForm(params, config) {
    return axios.get('management/position/editForm', { params, ...config })
  },
  monitoringLineStatisticsQuery(params, config) {
    return axios.get('monitoring/monitoringLineStatisticsQuery', { params, ...config })
  },
  getPositionUsers(params, config) {
    return axios.get('management/position/editForm/searchUsers', { params, ...config })
  },
  monitoringLog(data, config) {
    return axios.post('monitoring/log', data, config)
  },
  startDelay(data, config) {
    return axios.post('monitoring/createLineDelay', data, config)
  },
  getDuplicateForm(params, config) {
    return axios.get('management/position/duplicate', { params, ...config })
  },
  createAcceptation(data, config) {
    return axios.post('createAcceptation', data, config)
  },
  cancelAcceptation(data, config) {
    return axios.post('cancelAcceptation', data, config)
  },
  decodeCustomerData(data, config) {
    return axios.post('decodeCustomerData', data, config)
  },
  tryToRestorePosition(data, config) {
    return axios.put('tryToRestorePosition', data, config)
  },
  removeAllPositions(data, config) {
    return axios.post('removeAllPositions', data, config)
  },
  callAllPositions(data, config) {
    return axios.post('callAllPositions', data, config)
  }
}

export default monitoring
