import styled from '@emotion/styled'
import { ControlLabel } from '../control-label'

const AlertMessage = styled.div`
  font-size: 12px;
  margin: 4px 0 0;
  color: #ef5350;
`

const InfoMessage = styled.div`
  font-size: 12px;
  margin: 4px 0 0;
  color: rgba(0, 0, 0, 0.54);
`

const Root = styled.div`
  display: block;
`

const ControlWrapper = styled.div`
  font-size: 0;
  position: relative;
  overflow: hidden;
`

const Message = styled.div<{ invalid?: boolean }>`
  font-size: 12px;
  margin: 4px 0 0;
  color: rgba(0, 0, 0, 0.6);

  ${(props) => props.invalid && `color: #ef5350`};
`

const Attention = styled.div`
  background: #db214d;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-top: 4px;
  margin-left: 4px;
`

const Title = styled.div`
  display: flex;
`

const Hint = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  display: flex;
  color: rgba(0, 0, 0, 0.54);
`

const HintIndent = styled.div`
  visibility: hidden;
`

const HintText = styled.div`
  margin: 0 0 0 8px;
`

const InputWrapper = styled.div<{ invalid?: boolean; focused?: boolean }>`
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: inline-flex;
  align-items: center;
  width: 100%;

  &:hover::before {
    border-bottom: 2px solid rgba(0, 0, 0, 0.87);

    ${(props) => props.invalid && `border-bottom: 1px solid #ef5350`};
  }

  &::before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    left: 0;
    bottom: 0;
    content: '';
    position: absolute;
    right: 0;
    -webkit-transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    pointer-events: none;

    ${(props) => props.invalid && `border-bottom: 1px solid #ef5350`};
  }

  &::after {
    border-bottom: 2px solid #db214d;
    left: 0;
    bottom: 0;
    content: '';
    position: absolute;
    right: 0;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    pointer-events: none;
    transform: ${(props) => (props.focused ? 'scaleX(1) translateX(0)' : undefined)};
  }
`

const Control = styled.input`
  appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;

  display: block;
  box-sizing: border-box;
  min-height: 32px;
  margin: 0;
  padding: 0;
  line-height: 32px;
  border: 0;
  background: transparent;
  width: 100%;
  outline: 0;
  text-overflow: ellipsis;
  border-radius: 0;
  position: relative;

  &::-ms-clear {
    display: none;
  }
  &[disabled] {
    border-style: dotted;
    color: rgba(0, 0, 0, 0.38);
    &::placeholder {
      color: transparent;
    }
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.38);
    &:first-letter {
      text-transform: uppercase;
    }
  }
`

const Label = styled(ControlLabel)<{ invalid?: boolean; focused?: boolean }>`
  ${(props) => props.invalid && `color: #ef5350`};
  ${(props) => props.focused && `color: #db214d`};
`

const EndAdornment = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`

const MessageWrapper = styled.div`
  margin-top: 8px;
`

export {
  AlertMessage,
  InfoMessage,
  Root,
  ControlWrapper,
  Message,
  Attention,
  Title,
  Hint,
  HintIndent,
  HintText,
  Control,
  InputWrapper,
  Label,
  EndAdornment,
  MessageWrapper
}
