import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import LoadMore from 'shared/ui-kit/load-more'
import { JournalItem } from './journal-item'
import { line, journal } from 'shared/api'
import { customers, journal as journalPath } from 'pages/nav'
import EmptyScreen from 'shared/ui-kit/empty-screen'
import TimerIcon from '@mui/icons-material/Timer'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import JournalsFilterLargeLayout from './journals-filter-large-layout'
import JournalsFilterSmallLayout from './journals-filter-small-layout'
import EmptyIcon from '@mui/icons-material/Storage'
import Container from 'shared/ui-kit/container'
import { useHistory, useLocation } from 'react-router'
import { useDataProvider } from 'features/isolated-data-provider'
import { LineModelSimplified } from 'shared/models'
import { useAppDispatch } from 'store'
import { useMediaQuery } from 'react-responsive'
import { useInfiniteQuery } from 'react-query'
import { CircularProgress } from '@mui/material'
import { defaultFilters, today, tomorrow } from './utils/default-filters'
import { storageKey } from './utils/storage-key'

function Journal() {
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })

  const { tr } = useTranslation()
  const history = useHistory()
  const location = useLocation()

  const dispatch = useAppDispatch()

  const shopRequestsCount = useRef(0)
  const hasBreadcrumbs = useRef(false)

  const { places } = useDataProvider()

  const [lines, setLines] = useState<LineModelSimplified[]>([])

  const storageFilters = sessionStorage.getItem(storageKey)
  const parcedStorageFilters = storageFilters ? JSON.parse(storageFilters) : undefined

  const [filter, setFilters] = useState<any>(parcedStorageFilters || defaultFilters)

  const {
    data: journalItems,
    remove,
    refetch,
    isFetching,
    isFetched,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  } = useInfiniteQuery(
    ['getJournal', filter],
    ({ signal, pageParam }) =>
      journal.getJournal(
        {
          shopId: filter.shopId,
          lineId: filter.lineId,
          startDate: filter.startDate,
          endDate: filter.endDate,
          term: filter.term,
          actions: filter.actions?.reduce((f, s) => `${f},${s}`),
          lastPositionId: pageParam
        },
        { signal }
      ),
    {
      enabled: false,
      getNextPageParam: (lastPage) => lastPage?.data?.[lastPage.data.length - 1]?.positionId ?? undefined,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false
    }
  )

  useEffect(syncFilters, [filter])

  function syncFilters() {
    sessionStorage.setItem(storageKey, JSON.stringify(filter))
  }

  useEffect(() => {
    remove()

    const queryParams = new URLSearchParams(location.search)
    const isToday = queryParams.get('today') === 'true'
    const isRemovedPositions = queryParams.get('removedPositions') === 'true'
    const dateRaw = queryParams.get('date')
    const date = dateRaw && !Number.isNaN(Number(dateRaw)) ? Number(dateRaw) : undefined
    const endDate = date ? date + 1000 * 60 * 60 * 24 : undefined
    const term = queryParams.get('term')
    const shopId = queryParams.get('shopId')
    const lineId = queryParams.get('lineId')

    const isFastSearch = !!date && !!term

    setFilters((curr) => {
      const filters = {
        ...curr,
        term: term || curr.term,
        startDate: isToday ? today : date || curr.startDate,
        endDate: isToday ? tomorrow : endDate || curr.endDate,
        shopId: shopId || isFastSearch ? null : curr.shopId,
        lineId: lineId || isFastSearch ? null : curr.lineId,
        actions: isRemovedPositions ? ['leaveLine', 'removed', 'finishService'] : undefined
      }

      if (filters.shopId && places.find((el) => String(el.id) === String(filters.shopId))) {
        fetchLines(filters.shopId, () => {
          if (term || isToday) {
            refetch()
          }
        })
      } else if (isFastSearch) {
        setTimeout(() => refetch(), 1)
      }

      return filters
    })
  }, [])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const shopId = queryParams.get('shopId') || null
    const lineId = queryParams.get('lineId') || null

    if (shopId && lineId && !hasBreadcrumbs.current) {
      const place = places && places.find((x) => String(x.id) === String(shopId))
      const line = lines && lines.find((x) => String(x.id) === String(lineId))

      if (place && line) {
        dispatch(setBreadcrumbs(getBreadcrumbs()))
      }
    }

    if ((!shopId || !lineId) && !hasBreadcrumbs.current) {
      dispatch(setBreadcrumbs(getBreadcrumbs()))
    }
  }, [places, lines])

  function getBreadcrumbs() {
    const crumb = getCrumbs(tr.breadcrumbs)

    const queryParams = new URLSearchParams(location.search)
    const shopId = queryParams.get('shopId') || null
    const lineId = queryParams.get('lineId') || null

    if (shopId && lineId) {
      const place = (places || []).find((x) => String(x.id) === String(shopId))
      const line = (lines || []).find((x) => String(x.id) === String(lineId))

      const placeName = place ? place.name : ''
      const lineName = line ? line.name : ''

      if (placeName && lineName) {
        hasBreadcrumbs.current = true
      }

      return [
        crumb.home(),
        crumb.places(),
        crumb.place([shopId], placeName),
        crumb.lines([shopId]),
        crumb.line([shopId, lineId], lineName),
        crumb.reports(),
        crumb.journal([])
      ]
    }

    return [crumb.home(), crumb.reports(), crumb.journal([])]
  }

  function fetchLines(shopId, callback) {
    if (!shopId) {
      setFilters((curr) => ({ ...curr, lineId: null }))
      setLines([])
      callback?.()
      return
    }

    line.getLineListSimplified(shopId).then((response) => {
      setFilters((curr) => ({
        ...curr,
        lineId: response.data.find((x) => String(x.id) === String(curr.lineId))?.id || null
      }))
      setLines(response.data)
      callback?.()
    })
  }

  const queryParams = new URLSearchParams(location.search)

  const JournalsLayout = isMobile ? JournalsFilterSmallLayout : JournalsFilterLargeLayout

  if (!places?.length && shopRequestsCount.current > 0) {
    return (
      <Container>
        <EmptyScreen text={tr.journal.noPlaceAvailable} />
      </Container>
    )
  }

  return (
    <Container>
      <JournalsLayout
        shopId={queryParams.get('shopId') || undefined}
        lineId={queryParams.get('lineId') || undefined}
        filter={filter}
        lines={[{ name: tr.journal.allLines, id: null }, ...lines]}
        shops={[{ name: tr.journal.allPlaces, id: null }, ...places]}
        onFilterChange={(prop, value, callback) => {
          remove()
          setFilters((curr) => ({ ...curr, [prop]: value }))

          if (prop === 'shopId') {
            fetchLines(value, callback)
          } else {
            callback?.()
          }
        }}
        isToday={queryParams.get('today') === 'true'}
        onSearch={() => {
          remove()
          refetch()
        }}
      >
        {!!isFetching && !isFetched && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
          </div>
        )}
        {!isFetching && !journalItems?.pages?.length && (
          <EmptyScreen text={isFetched ? tr.journal.emptyMessage : tr.journal.emptyTermMessage} iconClass={TimerIcon} />
        )}
        {journalItems?.pages?.[0]?.data?.length === 0 && (
          <EmptyScreen iconClass={EmptyIcon} text={tr.common.noDataFound} />
        )}
        {(!isFetching || !!isFetched) &&
          journalItems?.pages.map((page, pageIndex) => (
            <div key={pageIndex}>
              {page.data.map((el, eventIndex) => (
                <JournalItem
                  key={`${pageIndex}-${eventIndex}`}
                  data={el}
                  onCustomerClick={(id) => {
                    history.push(`${customers()}?${new URLSearchParams({ customerId: id } as any).toString()}`)
                  }}
                  onOpenOriginalPosition={(originalPositionId) => {
                    const params = new URLSearchParams({ term: originalPositionId, shopId: el.shopId } as any)
                    window.open(`#${journalPath()}?${params}`, '_blank')
                  }}
                />
              ))}
            </div>
          ))}
        {hasNextPage && !!journalItems?.pages?.length && (
          <LoadMore onClick={fetchNextPage} loading={isFetchingNextPage} />
        )}
      </JournalsLayout>
    </Container>
  )
}

export default Journal
