import { ReactNode } from 'react'
import * as Sentry from '@sentry/react'
import { ServerMessageErrorView } from './server-error-message'
import { ErrorBoundaryWrapper } from './server-error-message/styled'
import { browserTracingIntegration, dedupeIntegration } from '@sentry/react'

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.SENTRY_ENV,
  release: `${__APP_NAME__}@${__APP_VERSION__}-${process.env.SENTRY_ENV}`,
  autoSessionTracking: false,
  sendClientReports: false,
  enabled: location.hostname !== 'localhost' && process.env.NODE_ENV === 'production',
  integrations: [dedupeIntegration(), browserTracingIntegration()],
  ignoreErrors: [
    'Network Error',
    'connection being closed',
    'handshake error',
    'Server timeout',
    'dialog blocked',
    'Not Found',
    'WebSocket closed',
    'no access',
    'code 500',
    'code 503',
    'code 400',
    'code 401',
    'code 403',
    'code 404',
    "reading 'status'",
    'Request aborted',
    'Failed to register a ServiceWorker',
    'Permission not granted',
    'Failed to start the audio device',
    'negotiation',
    "code '404",
    'Status code',
    'Connection',
    'NetworkError',
    'occurred invoking',
    'dynamically imported',
    'on the server',
    'timeout of',
    'timeout exceeded',
    'NotAllowedError',
    'Importing a module script failed'
  ]
})

type Props = { children: ReactNode }

export default function SentryWrapper({ children }: Props) {
  function handleSubmit(resetError: () => void) {
    return () => {
      location.replace('/')
      resetError()
    }
  }

  return (
    <Sentry.ErrorBoundary
      fallback={({ resetError }) => (
        <ErrorBoundaryWrapper>
          <ServerMessageErrorView onSubmit={handleSubmit(resetError)} />
        </ErrorBoundaryWrapper>
      )}
      showDialog={false}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}
