import { AdditionalValues, LineModel, ServiceModel, PlaceModel } from '.'

export enum PositionType {
  Unknown = 'unknown',
  MobileApp = 'mobileApp',
  PaperTicket = 'paperTicket',
  ScreenCall = 'screenCall',
  Appointment = 'appointment',
  Break = 'break',
  WebFrame = 'webFrame'
}

export enum DenialServiceTypes {
  NoActiveCheckpointsWithSuitableService = 'NoActiveCheckpointsWithSuitableService',
  PositionOverbookingNoSuitableServicePoints = 'PositionOverbookingNoSuitableServicePoints',
  Unknown = 'Unknown'
}

export type mapDenialServiceTypes<T> = { [P in DenialServiceTypes]: T }

export enum SMSActionType {
  WelcomeSMS = 'welcomeSMS',
  CallSMS = 'callSMS'
}

export enum PositionActionType {
  CreateAppointment = 'createAppointment',
  UpdateAppointment = 'updateAppointment',
  JoinLine = 'joinLine',
  ReJoinLine = 'rejoinLine',
  UpdatePosition = 'updatePosition',
  InPlace = 'inPlace',
  WelcomeSMS = 'welcomeSMS',
  CallSMS = 'callSMS',
  MoveToCheckpoint = 'moveToCheckpoint',
  EnterToCheckpointZone = 'enterToCheckpointZone',
  ExitFromCheckpointZone = 'exitFromCheckpointZone',
  AcceptationExpired = 'acceptationExpired',
  AcceptationRefuse = 'acceptationRefuse',
  AcceptationRequested = 'acceptationRequested',
  AcceptationAccept = 'acceptationAccept',
  AcceptationCancelled = 'acceptationCancelled',
  TakeExtraTime = 'takeExtraTime',
  LeaveLine = 'leaveLine',
  Removed = 'removed',
  FailedJoin = 'failedJoin',
  CallToCheckpoint = 'callToCheckpoint',
  StartService = 'startService',
  FinishService = 'finishService',
  GenerateMobileCode = 'generateMobileCode',
  CallPhoneNumber = 'callPhoneNumber',
  PositionProgression = 'positionProgression',
  SendPushNotification = 'sendPushNotification',
  SurveyRequestSent = 'surveyRequestSent',
  SurveyLinkClicked = 'surveyLinkClicked',
  UpdateSmsStatus = 'updateSmsStatus',
  ValidForService = 'validForService',
  StayInLineNotificationSent = 'stayInLineNotificationSent',
  StayInLineConfirmationReceived = 'stayInLineConfirmationReceived',
  SurveyNotificationAborted = 'surveyNotificationAborted',
  TransferPosition = 'transferPosition'
}

export enum PositionState {
  Joined = 'joined',
  CalledToCheckpoint = 'calledToCheckpoint',
  MovingToCheckpoint = 'movingToCheckpoint',
  NearCheckpoint = 'nearCheckpoint',
  InService = 'inService',
  InLineHere = 'inLineHere',
  InLine = 'inLine',
  ValidForService = 'validForService',
  InLineExtraTime = 'inLineExtraTime',
  ServiceStarted = 'serviceStarted',
  ManualFinished = 'manualFinished',
  AutoFinished = 'autoFinished',
  Leaved = 'leaved',
  Removed = 'removed',
  TakeInCharge = 'takeInCharge'
}

export enum PositionAction {
  Join = 'join',
  SetInLineHere = 'setInLineHere',
  ValidateForService = 'validateForService',
  CallToCheckpoint = 'callToCheckpoint',
  SetNearCheckpoint = 'setNearCheckpoint',
  StartService = 'startService',
  FinishService = 'finishService',
  Remove = 'remove'
}

export type AcceptationState = 'requested' | 'accepted' | 'refused' | 'expired'

export enum TimeLeftOrOverState {
  Normal = 'Normal',
  Alarm = 'Alarm',
  Over = 'Over'
}

export interface CheckpointPositionModel {
  id: string | number
  lineId?: string | number
  beaconId?: string | number
  description?: string
  name?: string
}

export type SmsStatus = 'sent' | 'error' | 'received'

export type SmsItem = {
  status: SmsStatus
  statusDetails: string
}

export type DeviceType = 'Terminal' | 'Admin' | 'iOS' | 'Android' | 'Rdv'

export type TimeslotModel = {
  id: number
  from_unixtime: number
  to_unixtime: number
}

export type PositionEditTimeslotModel = {
  id: number
  startTimeUtc: number
  endTimeUtc: number
}

export interface PositionCustomer {
  id?: string | number
  firstName?: string
  lastName?: string
  companyName?: string
  phoneNumber?: string
  email?: string
  language?: string
  deviceType?: DeviceType
}

export interface SearchCustomer {
  id: string | number
  firstName: string
  lastName: string
  email: string
}

export interface EditPositionModel {
  id?: string | number
  type?: PositionType
  state?: PositionState
  customer: PositionCustomer
  personsQuantity?: number
  assignedCheckpoint?: CheckpointPositionModel
  timeSlot?: { id: number }
  services: ServiceModel[]
  customCheckpoint?: CheckpointPositionModel
  priority?: boolean
  sortOrderIndex?: number
  additionals?: AdditionalValues
  staffComment?: string
  customServiceDuration?: number
  checkListSelectedItems?: string[]
  updatePersonalInfoReason?: string
  deleteOriginalPosition?: boolean
  originalPositionLineId?: string | number
  originalPositionPlaceId?: string | number
  originalPositionId?: string | number
}

export interface EditPositionDataModel {
  position: EditPositionModel
  availableServices: (string | number)[]
  availableCheckpoints: (string | number)[]
  currentMaxPersonsInGroup?: number
}

export interface EditPositionConfigModel {
  checkpoints: CheckpointPositionModel[]
  line: LineModel & { personalDataReadFromQRCode: boolean }
  shop: PlaceModel
  options: {
    displayServicePoints?: boolean
    supportSearchCustomerByPhone?: boolean
    displayAddAndStartServiceButton?: boolean
    canCreateAnonymousPosition?: boolean
  }
}

export interface BasicPositionModel extends DiscretePositionModel {
  timeSlot_from?: number
  assignedCheckpointId?: number
  customCheckpointId?: number
}

export interface DiscretePositionModel {
  id: string | number
  type: PositionType
  state: PositionState
  customer: {
    deviceType?: DeviceType
    fullName?: string
    language: string
    phoneNumber?: string
    email?: string
  }
  callPhoneNumber?: string
  initialServiceDuration?: number
  waitingDelay?: number
  expectedServiceDuration?: number
  reservationCode?: string
  serviceIds: (string | number)[]
  sms?: { welcome?: SmsItem; call?: SmsItem; reminder?: SmsItem }
  asap?: boolean | undefined
  distance?: number
  staffComment?: string
  additionals?: AdditionalValues
  joinTime?: number
  callTime?: number
  movingTime?: number
  serviceStartedTime?: number
  offlineTime?: number
  unreadChatMessagesCount?: number
  personsQuantity?: number
  operatorFullName?: string
  priority?: boolean
  progressionTags?: string
  progressionTagsComment?: string
  progressionTagsImages?: { id: string; name: string }[]
  inPlaceSince?: number
  validForServiceSince?: number
  denialOfServiceReason?: DenialServiceTypes
  isValid?: boolean
  expectedCallTime?: number
  expectedServiceStartTime?: number
  acceptationState?: AcceptationState
  readonly?: boolean
  lastStayInLineNotificationSentTime?: number
  lastStayInLineConfirmationReceivedTime?: number
  lastVisitDate?: number
  visitsCount?: number
  checkpointZoneEnterTime?: number
}

export interface PositionModel {
  id: string | number
  type: PositionType
  assignedCheckpoint?: CheckpointPositionModel
  state: PositionState
  offlineTime_unixtime?: number
  additionals?: AdditionalValues
  customer?: PositionCustomer
  callPhoneNumber?: string
  reservationCode?: string
  personsQuantity?: number
  unreadChatMessagesCount?: number
  timeSlot?: TimeslotModel
  joinTime_unixtime?: number
  callTime_unixtime?: number
  serviceStartedTime_unixtime?: number
  services?: ServiceModel[]
  movingTime_unixtime?: number
  expectedServiceTime?: number
  waitingToCallTime?: number
  waitingServiceTime?: number
  postponedCall?: boolean | undefined
  asap: boolean | undefined
  distance?: number
  timeFromCall?: number
  timeFromServiceStart?: number
  expectedServiceFinishTime_unixtime?: number
  expectedCallTime_unixtime?: number
  expectedServiceStartTime_unixtime?: number
  staffComment?: string
  customCheckpoint?: CheckpointPositionModel
  customServiceDuration?: number
  lineId?: string | number
  placeId?: string | number
  joinedDuration?: number
  operator?: { firstName: string; lastName: string }
  sms?: { welcome?: SmsItem; call?: SmsItem; reminder?: SmsItem }
  timeLeftOrOver?: number
  timeLeftOrOverState?: TimeLeftOrOverState
  plannedServiceDuration?: number
  priority: boolean
  progressionTags?: string
  progressionTagsComment?: string
  progressionTagsImages?: { id: string; name: string }[]
  inPlaceSince?: number
  validForServiceSince?: number
  sortOrderIndex?: number
  waitingDelay?: number
  updatePersonalInfoReason?: string
  denialOfServiceReason?: DenialServiceTypes
  isValid?: boolean
  acceptationState?: AcceptationState
  readonly?: boolean
  lastStayInLineNotificationSentTime?: number
  lastStayInLineConfirmationReceivedTime?: number
  lastVisitDate?: number
  visitsCount?: number
  checkpointZoneEnterTime?: number
}
