import { ReactNode } from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'shared/i18n/translate'

type Props = {
  message?: ReactNode
}

function NoOptionsMessage({ message }: Props) {
  const { tr } = useTranslation()

  return (
    <Typography color="textSecondary" style={{ fontSize: '1rem', padding: '0.5rem' }}>
      {message || tr.common.noOptionsFound}
    </Typography>
  )
}

export default NoOptionsMessage
