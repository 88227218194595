import { useEffect, useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import BroadcastAlertsForm from './broadcast-alerts-form'
import { getBroadcastAlertsConfig, resetBroadcastAlertsConfig } from 'store/actions/broadcast-alerts-action-creators'
import { broadcastAlerts } from 'shared/api'
import { showMessage, MessageTypes } from 'store/actions/main-layout-action-creators'
import { useTimeZoneFilter } from 'features/time-zone-filter'
import produce from 'immer'
import Container from 'shared/ui-kit/container'
import { useParams } from 'react-router'
import { getBroadcastAlertsConfigSelector } from 'store/selectors/broadcastAlertsSelectors'
import { useTitle } from 'shared/hooks/useTitle'
import { useAppDispatch, useAppSelector } from 'store'

function BroadcastAlerts() {
  const config = useAppSelector(getBroadcastAlertsConfigSelector)
  const dispatch = useAppDispatch()
  const { placeId, lineId } = useParams<{ placeId: string; lineId: string }>()
  const { tr, locale } = useTranslation()

  const [processing, setProcessing] = useState(false)

  useTimeZoneFilter(placeId)

  useTitle({ lineName: config?.parents?.line?.name })

  useEffect(() => {
    dispatch(getBroadcastAlertsConfig({ lineId }))

    return () => {
      dispatch(resetBroadcastAlertsConfig())
    }
  }, [])

  useEffect(() => {
    if (!config || !config.parents) {
      return
    }

    const { shop, line } = config.parents
    const crumb = getCrumbs(tr.breadcrumbs)
    dispatch(
      setBreadcrumbs([
        crumb.home(),
        crumb.places(),
        crumb.place([String(shop.id)], shop.name),
        crumb.lines([String(shop.id)]),
        crumb.line([String(shop.id), String(line.id)], line.name),
        crumb.broadcastAlerts([String(shop.id), String(line.id)])
      ])
    )
  }, [config, locale])

  function handleSave(data) {
    setProcessing(true)

    const d = produce(data, (draft) => {
      draft.shopId = placeId
      draft.lineId = lineId
    })

    broadcastAlerts
      .sendBroadcastAlert(d)
      .then(() => {
        dispatch(showMessage('The alert was successfully sent', MessageTypes.Success))
      })
      .catch(() => {
        dispatch(showMessage('Error occurred during the alert send', MessageTypes.Error))
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  if (!config) {
    return null
  }

  return (
    <Container>
      <BroadcastAlertsForm config={config.data} onSave={handleSave} processing={processing} />
    </Container>
  )
}

export default BroadcastAlerts
