import { CSSProperties, useState, useEffect } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { CircularProgress, Autocomplete, IconButton, TextField, Button } from '@mui/material'
import { ShowChildren } from './show-children'
import { place as placeApi } from 'shared/api'
import { Add as AddIcon, Warning as WarningIcon } from '@mui/icons-material'
import { PlaceModelSimplified } from 'shared/models'

type PlaceSelectProps = {
  filterItem?: (item: PlaceModelSimplified) => boolean
  onChange?: (item: PlaceModelSimplified) => void
  style?: CSSProperties
  label?: string
  placeholder?: string
}

function PlaceSelect(porps: PlaceSelectProps) {
  const { filterItem, onChange, style, label, placeholder } = porps
  const [placeList, setPlaceList] = useState<PlaceModelSimplified[] | null>(null)
  const [value, setValue] = useState<PlaceModelSimplified | null>(null)
  const { tr } = useTranslation()

  function fetchPlaceList() {
    placeApi.getPlaceListSimplified().then((resp) => {
      setPlaceList(resp.data)
    })
  }

  const allOptions = placeList ? (filterItem ? placeList.filter(filterItem) : placeList) : []

  useEffect(() => {
    allOptions.length && !value && setValue(allOptions[0])
  }, [allOptions])

  return (
    <ShowChildren
      style={style}
      renderButton={(setShow) => (
        <Button
          variant="outlined"
          size="small"
          startIcon={<AddIcon />}
          onClick={() => {
            setShow(true)
            fetchPlaceList()
          }}
        >
          {tr.tagEdit.addPlace}
        </Button>
      )}
    >
      {!placeList && <CircularProgress size="24px" />}
      {placeList && (
        <>
          {!allOptions.length && (
            <Button variant="outlined" size="small" disabled startIcon={<WarningIcon />}>
              {tr.tagEdit.noMorePlaceToAdd}
            </Button>
          )}
          {!!allOptions.length && (
            <div style={{ display: 'flex', gap: '1rem', alignItems: 'flex-end' }}>
              <Autocomplete
                style={{ flex: '1' }}
                value={value}
                fullWidth
                filterSelectedOptions
                onChange={(ev, val) => setValue(val)}
                options={allOptions}
                getOptionLabel={(opt) => opt.name}
                isOptionEqualToValue={(opt, val) => opt.id === val.id}
                noOptionsText={tr.tagEdit.noParticipant}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" label={label} placeholder={placeholder} />
                )}
              />
              <IconButton
                disabled={!value}
                size="small"
                onClick={() => {
                  if (value) {
                    onChange?.(value)
                    setValue(null)
                  }
                }}
              >
                <AddIcon />
              </IconButton>
            </div>
          )}
        </>
      )}
    </ShowChildren>
  )
}

export { PlaceSelect }
