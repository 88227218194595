import { useState, useRef } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import Text from 'shared/ui-kit/text'
import { Form, FormGroup } from 'shared/ui-kit/form'
import { Divider, Chip, Stack } from '@mui/material'
import {
  Phone as PhoneIcon,
  Email as EmailIcon,
  Place as ServiceIcon,
  Schedule as TimeIcon,
  EventNote as JournalIcon,
  Print as PrintIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  CopyAll as DuplicateIcon,
  EventAvailable as NewBookingIcon,
  CheckCircle as ValidateIcon
} from '@mui/icons-material'
import { useReactToPrint } from 'react-to-print'
import { AppointmentTextSummaryPrint } from './components/appointment-summary-print'
import { appointments } from 'shared/api'
import Link from 'shared/ui-kit/link'
import * as nav from 'pages/nav'
import { AppointmentState, AppointmentStatus } from 'shared/models'
import { AppointmentAction } from './components/appointment-action'
import { ProgressionTags } from 'features/position-actions/actions'
import { AdditionalItemView } from 'features/additionals-view'
import { AppointmentProps } from './types'
import {
  CommonInfo,
  CommonSection,
  SmallInfo,
  UserInfo,
  DesktopRoot,
  MobileRoot,
  AppointmentInfo,
  AppointmentStateInfo,
  LeftInfoBlock,
  CustomerInfo,
  ContactsWrapper,
  ContactInfo,
  Contact,
  Root
} from './styled'

function Appointment({
  data,
  disabled,
  hideLineName,
  hidePlaceName,
  onConfirm,
  onEdit,
  onRemove,
  softRefetch
}: AppointmentProps) {
  const [progressionMode, setProgressionMode] = useState(false)
  const [printSummary, setPrintSummary] = useState<string | null>(null)
  const [isProcessing, setIsProcessing] = useState(false)

  const printRef = useRef<any>()

  const { dateFormatter, tr } = useTranslation()
  const handlePrint = useReactToPrint({ content: () => printRef.current })

  function handleGetPrintSummary() {
    if (printSummary) {
      handlePrint()
    } else {
      appointments.getAppointmentPrintTemplate({ token: data.id }).then((resp) => {
        setPrintSummary(resp.data.recap)
        setTimeout(() => handlePrint(), 0)
      })
    }
  }

  function handleCreateAnotherPosition() {
    window.open(
      `#${nav.appointmentPlaceList()}/?placeId=${data.placeId}&lineId=${data.lineId}&positionId=${data.positionId}`,
      '_blank'
    )
  }

  function handleJournalOpen() {
    window.open(data.positionId ? '#' + nav.journal() + `?term=p${data.positionId}` : '#' + nav.journal(), '_blank')
  }

  function handleRemove() {
    onRemove?.(data.id)
  }

  async function handleDuplicate() {
    try {
      setIsProcessing(true)

      const res = await appointments.getAppointmentDuplicationForm({
        id: data.id,
        lineId: data.lineId,
        placeId: data.placeId
      })

      if (res.data) {
        const services = res.data.services?.map?.((el) => el.id)?.join(';')

        const params = new URLSearchParams()
        params.set('customerId', String(res.data.customer?.id))

        if (services) {
          params.set('services', services)
        }

        if (res.data.participantsNumber) {
          params.set('participantsNumber', String(res.data.participantsNumber))
        }

        if (res.data.additionals) {
          params.set('additionals', JSON.stringify(res.data.additionals))
        }

        window.open(
          `#${nav.createAppointmentLine(data.placeId as string, data.lineId as string)}?${params.toString()}`,
          '_blank'
        )
      }
    } finally {
      setIsProcessing(false)
    }
  }

  function handleValidate() {
    onConfirm?.(data.id)
  }

  function handleEdit() {
    onEdit?.(data.id, data.placeId, data.lineId)
  }

  function handleProgressionForm(value?: boolean) {
    return () => {
      setProgressionMode(value || false)
    }
  }

  function handleProgressionFinish() {
    setProgressionMode(false)
    softRefetch?.()
  }

  const servicesNames = data.services.map((s) => s.name).join(', ')
  const disabledCard = data.appointmentState === AppointmentState.Deleted || isProcessing
  const hasComments = !!(data?.comments && data?.comments.length)
  const hasStaffComments = !!(data?.staffComments && data?.staffComments.length)

  const additionals = Object.entries(data?.additionals || {}).filter((additional) => {
    return !!data?.additionalLabels?.find((f) => f.shortName === additional[0]) && !!additional[1]
  })

  const customParticipantsQuestion =
    data?.services?.length === 1 ? data.services[0]?.customParticipantsQuestion : undefined

  return (
    <Root $disabled={disabledCard}>
      <Form>
        <DesktopRoot>
          <AppointmentInfo>
            <LeftInfoBlock>
              {!!data.appointmentState && (
                <AppointmentStateInfo $isDeleted={data.appointmentState === AppointmentState.Deleted}>
                  {tr.appointment.appointmentStateTranslations[data.appointmentState] || ''}
                </AppointmentStateInfo>
              )}
              {!!data.customer && (
                <CustomerInfo>
                  {[data.customer.companyName, `${data.customer.firstName} ${data.customer.lastName}`]
                    .filter(Boolean)
                    .join(' - ')}
                </CustomerInfo>
              )}
              {!!data.customer && (
                <ContactsWrapper>
                  {!!data.customer.email && (
                    <ContactInfo>
                      <EmailIcon style={{ fontSize: '1rem', fill: '#0000008a' }} />
                      <Contact
                        href={`mailto:${data.customer.email}`}
                        $disabled={disabledCard}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {data.customer.email}
                      </Contact>
                    </ContactInfo>
                  )}
                  {!!data.customer.phoneNumber && (
                    <ContactInfo>
                      <PhoneIcon style={{ fontSize: '1rem', fill: '#0000008a' }} />
                      <Contact
                        href={`tel:${data.customer.phoneNumber}`}
                        $disabled={disabledCard}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {data.customer.phoneNumber}
                      </Contact>
                    </ContactInfo>
                  )}
                </ContactsWrapper>
              )}
            </LeftInfoBlock>
            <div style={{ textAlign: 'end' }}>
              <Text type="body-1" style={{ color: disabledCard ? '#00000061' : undefined }}>
                {tr.appointment.appointmentStatusTranslations[data.status] || ''}
              </Text>
            </div>
          </AppointmentInfo>
          <CommonInfo>
            <UserInfo>
              <CommonSection>
                <Text type="caption">{tr.appointment.date}</Text>
                <Text type="body-1" style={{ color: disabledCard ? '#00000061' : undefined }}>
                  {dateFormatter(data.time?.startTime, { weekday: 'short', day: 'numeric', month: 'long' })},{' '}
                  {dateFormatter(data.time?.startTime, 'time')}
                </Text>
              </CommonSection>
              {!!data.participantsNumber && (
                <CommonSection>
                  <Text type="caption">
                    {customParticipantsQuestion ||
                      data.lineParticipantsCaptionTemplate ||
                      tr.appointmentCreateFromService.numberParticipants}
                  </Text>
                  <Text type="body-1" style={{ color: disabledCard ? '#00000061' : undefined }}>
                    {data.participantsNumber}{' '}
                    {tr.getParticipantFormFromTemplates(
                      data.participantsNumber,
                      data.lineParticipantTermInSingularTemplate,
                      data.lineParticipantTermInPluralTemplate
                    )}
                  </Text>
                </CommonSection>
              )}
              {data.serviceDuration && (
                <CommonSection>
                  <Text type="caption">{tr.appointment.serviceDuration}</Text>
                  <Text type="body-1" style={{ color: disabledCard ? '#00000061' : undefined }}>
                    {tr.time.hmin(Math.floor(data.serviceDuration / 60))}
                  </Text>
                </CommonSection>
              )}
            </UserInfo>
            <UserInfo>
              {!!servicesNames && (
                <CommonSection>
                  <Text type="caption">{tr.appointment.service}</Text>
                  <Text type="body-1" style={{ color: disabledCard ? '#00000061' : undefined }}>
                    {servicesNames}
                  </Text>
                </CommonSection>
              )}
              {!!data.code && (
                <CommonSection>
                  <Text type="caption" style={{ color: disabledCard ? '#00000061' : undefined }}>
                    {tr.appointment.reservationNumber}
                  </Text>
                  <Text type="body-1" style={{ color: disabledCard ? '#00000061' : undefined }}>
                    {data.code}
                  </Text>
                </CommonSection>
              )}
              {!!(data.placeName && !hidePlaceName) && (
                <CommonSection>
                  <Text type="caption">{tr.appointment.place}</Text>
                  <Text type="body-1" style={{ color: disabledCard ? '#00000061' : undefined }}>
                    {data.placeName}
                  </Text>
                </CommonSection>
              )}
              {!!(data.lineName && !hideLineName) && (
                <CommonSection>
                  <Text type="caption">{tr.appointment.line}</Text>
                  <Text type="body-1" style={{ color: disabledCard ? '#00000061' : undefined }}>
                    {data.lineName}
                  </Text>
                </CommonSection>
              )}
            </UserInfo>
          </CommonInfo>
          {!!data.progressionTags && (
            <div style={{ display: 'flex' }}>
              <UserInfo>
                <Text type="caption">{tr.appointment.progressionTags}</Text>
                <Stack direction="row" style={{ flexWrap: 'wrap', gap: '0.5rem' }}>
                  {data.progressionTags
                    ?.split(';')
                    ?.map((tag, i) => <Chip label={tag} key={i} variant="outlined" size="small" />)}
                </Stack>
              </UserInfo>
              {!!data.progressionTagsComment && (
                <UserInfo>
                  <Text type="caption">{tr.appointment.progressionComment}</Text>
                  <Text type="body-1" style={{ color: disabledCard ? '#00000061' : undefined }}>
                    {data.progressionTagsComment}
                  </Text>
                </UserInfo>
              )}
            </div>
          )}
        </DesktopRoot>
        <MobileRoot>
          <FormGroup>
            <Text type="body-1" style={{ color: disabledCard ? '#00000061' : undefined }}>
              {[
                data?.customer?.firstName &&
                  data?.customer?.lastName &&
                  `${data.customer.firstName} ${data.customer.lastName}`,
                data?.participantsNumber && tr.common.personsCount(data.participantsNumber)
              ]
                .filter(Boolean)
                .join(' · ')}
            </Text>
          </FormGroup>
          <div style={{ margin: '0 1rem 0.5rem' }}>
            {!!data.customer.phoneNumber && (
              <SmallInfo>
                <PhoneIcon style={{ opacity: 0.28, marginRight: 8 }} />
                <Link
                  href={`tel:${data.customer.phoneNumber}`}
                  style={{ fontSize: 14, marginTop: 3, color: disabledCard ? '#00000061' : undefined }}
                >
                  {data.customer.phoneNumber}
                </Link>
              </SmallInfo>
            )}
            {!!data.customer.email && (
              <SmallInfo>
                <EmailIcon style={{ opacity: 0.28, marginRight: 8 }} />
                <Link
                  href={`mailto:${data.customer.email}`}
                  style={{ fontSize: 14, marginTop: 3, color: disabledCard ? '#00000061' : undefined }}
                >
                  {data.customer.email}
                </Link>
              </SmallInfo>
            )}
          </div>
          <div style={{ margin: '0 1rem 0.5rem' }}>
            {!!servicesNames && (
              <SmallInfo>
                <ServiceIcon style={{ opacity: 0.28, marginRight: 8 }} />
                <Text type="body-1" style={{ marginTop: 3 }}>
                  {servicesNames}
                </Text>
              </SmallInfo>
            )}
            <SmallInfo>
              <TimeIcon style={{ opacity: 0.28, marginRight: 8 }} />
              <Text type="body-1" style={{ marginTop: 3 }}>
                {`${dateFormatter(data.time?.startTime, { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })} - ${dateFormatter(
                  data.time?.endTime,
                  { hour: '2-digit', minute: '2-digit' }
                )}`}
              </Text>
            </SmallInfo>
          </div>
        </MobileRoot>
        <AppointmentTextSummaryPrint ref={printRef} data={printSummary || ''} />
      </Form>
      {!!(hasComments || hasStaffComments || additionals.length > 0) && (
        <>
          <Divider />
          <Form title={tr.appointment.details} expandable>
            {additionals.map((additional, index) => (
              <FormGroup key={`${additional[1]}-${index}`}>
                <AdditionalItemView
                  label={data?.additionalLabels?.find((f) => f.shortName === additional[0])?.label || ''}
                  value={additional[1]}
                />
              </FormGroup>
            ))}
            {hasComments && (
              <FormGroup>
                <Text type="caption">{tr.appointment.comments}</Text>
                <Text type="body-1">{data.comments}</Text>
              </FormGroup>
            )}
            {hasStaffComments && (
              <FormGroup>
                <Text type="caption">{tr.appointment.staffComments}</Text>
                <Text type="body-1">{data.staffComments}</Text>
              </FormGroup>
            )}
          </Form>
        </>
      )}
      <Divider />
      <Form title={tr.appointment.actionMenu} expandable>
        {!!data.enableProgressionTags && !!data.lineProgressionTags && (
          <ProgressionTags
            shopId={Number(data.placeId)}
            lineId={Number(data.lineId)}
            positionId={Number(data.positionId)}
            appointmentToken={data.id}
            tags={data.lineProgressionTags || ''}
            selectedTags={data.progressionTags || ''}
            comment={data.progressionTagsComment || ''}
            style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingBottom: '1rem' }}
            onFinishChanging={handleProgressionFinish}
            allowMultipleProgressionTags={data.allowMultipleProgressionTags}
            withPhoto={data.allowProgressionPhoto}
            images={data.progressionImages || []}
            iconIntoContainer
            progressionMode={progressionMode}
            onProgressionMode={handleProgressionForm(!progressionMode)}
            onBack={handleProgressionForm(false)}
            formMode
          />
        )}
        {!!data.canUpdate && !data.appointmentState && (
          <AppointmentAction
            disabled={disabled || isProcessing}
            onSubmit={handleEdit}
            icon={<EditIcon fontSize="small" />}
          >
            {tr.appointment.edit}
          </AppointmentAction>
        )}
        {![AppointmentStatus.Confirmed, AppointmentStatus.Completed, AppointmentStatus.Cancelled].includes(
          data.status
        ) &&
          !data.appointmentState && (
            <AppointmentAction
              disabled={disabled || isProcessing}
              onSubmit={handleValidate}
              icon={<ValidateIcon fontSize="small" />}
            >
              {tr.appointment.validate}
            </AppointmentAction>
          )}
        <AppointmentAction
          disabled={disabled || isProcessing}
          onSubmit={handleDuplicate}
          icon={<DuplicateIcon fontSize="small" />}
        >
          {tr.appointment.duplicate}
        </AppointmentAction>
        {!!data.canUpdate && !data.appointmentState && (
          <AppointmentAction
            disabled={!data.canUpdate || disabled || isProcessing}
            confirmation={true}
            confirmationMessage={tr.entityEdit.confirmDelete}
            onSubmit={handleRemove}
            icon={<DeleteIcon fontSize="small" />}
          >
            {tr.entityEdit.deleteButton}
          </AppointmentAction>
        )}
        {!!data.canPrint && !data.appointmentState && (
          <AppointmentAction
            disabled={disabled || isProcessing}
            onSubmit={handleGetPrintSummary}
            icon={<PrintIcon fontSize="small" />}
          >
            {tr.appointment.print}
          </AppointmentAction>
        )}
        <AppointmentAction
          disabled={disabled || isProcessing}
          onSubmit={handleJournalOpen}
          icon={<JournalIcon fontSize="small" />}
        >
          {tr.reports.journal}
        </AppointmentAction>
        {!!data.showButtonCreatePosition && (
          <AppointmentAction
            disabled={disabled || isProcessing}
            onSubmit={handleCreateAnotherPosition}
            icon={<NewBookingIcon fontSize="small" />}
          >
            {tr.positionActions.newBooking}
          </AppointmentAction>
        )}
      </Form>
    </Root>
  )
}

export default Appointment
