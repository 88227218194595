import { CSSProperties, useMemo } from 'react'
import { PositionState } from 'shared/models'
import CalledIcon from '@mui/icons-material/NotificationsActive'
import JoinedIcon from '@mui/icons-material/ArrowRight'
import InServiceIcon from '@mui/icons-material/Build'
import ErrorIcon from '@mui/icons-material/Error'

type Porps = {
  style?: CSSProperties
  state: PositionState
}

const PositionStateMap = {
  [PositionState.CalledToCheckpoint]: CalledIcon,
  [PositionState.Joined]: JoinedIcon,
  [PositionState.InService]: InServiceIcon,
  Unknown: ErrorIcon
}

function PositionStateIconComponent({ state, style }: Porps) {
  const Icon = useMemo(() => PositionStateMap[state] || PositionStateMap.Unknown, [state])

  return <Icon style={style} />
}

export default PositionStateIconComponent
export const PositionStateIcon = PositionStateIconComponent
