import FileUploader from 'features/file-uploader'
import { Controller } from 'react-hook-form'
import { ControlProps } from '.'
import { file } from 'shared/api'

export function DocumentControl(props: ControlProps) {
  const supportedFileTypes = props.acceptedFileTypes || '.doc, .pdf, .png, .jpg'

  function handleRequest(data: FormData, params: object, onUploadProgress?: (ev: any) => void) {
    return file.upload(data, { shortName: props.name, lineId: props.lineId, placeId: props.placeId }, onUploadProgress)
  }

  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field: f, formState }) => (
        <FileUploader
          id="image"
          label={props.label}
          file={f.value}
          onChange={f.onChange}
          attention={!!formState.errors[props.name]?.message}
          buttonText={formState.errors[props.name]?.message as string}
          margin
          notOutlined
          accept={supportedFileTypes}
          maxFileSize={props.maxContentLength}
          message={supportedFileTypes}
          request={handleRequest}
        />
      )}
    />
  )
}
