import { Form, FormGroup } from 'shared/ui-kit/form'
import { useTranslation } from 'shared/i18n/translate'
import { Controller } from 'react-hook-form'
import { GroupProps } from '../types'
import { WithHelper } from 'features/element-with-helper'
import { addTestAttr } from 'shared/utils/test-attr'
import { Button, TextField } from '@mui/material'
import { useState } from 'react'
import { LineDuplicationDialog } from '../../components/duplication-dialog'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import Card from 'shared/ui-kit/card'

function AdvancedSettingsGroup({ control, onDelete, canDuplicateLine, shopId, lineId, onDuplicate }: GroupProps) {
  const { tr } = useTranslation()

  const [isConfirmDelete, setConfirmDelete] = useState(false)
  const [isDuplicateDialog, setDuplicateDialog] = useState(false)

  function changeConfirmDelete(val: boolean) {
    return () => setConfirmDelete(val)
  }

  function handleDuplicate(targetPlaceId: string | number) {
    setDuplicateDialog(false)

    if (shopId && lineId) {
      onDuplicate({ placeId: shopId, lineId, targetPlaceId })
    }
  }

  function changeDuplicate(val: boolean) {
    return () => setDuplicateDialog(val)
  }

  const { featureFlags } = tr.lineEdit

  return (
    <Card>
      <Form>
        <FormGroup>
          <WithHelper tooltipKey="featureFlags" tag="line">
            <Controller
              name="featureFlags"
              control={control}
              render={({ field: f }) => (
                <TextField
                  fullWidth
                  {...addTestAttr('LineEdit-FeatureFlags')}
                  label={featureFlags}
                  placeholder={featureFlags}
                  InputLabelProps={{ shrink: true }}
                  value={f.value}
                  onChange={f.onChange}
                  multiline
                  maxRows={30}
                />
              )}
            />
          </WithHelper>
        </FormGroup>
        {!!lineId && !!canDuplicateLine && (
          <FormGroup>
            <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
              <div style={{ margin: '0.5rem 0', flex: 1 }}>{tr.lineEdit.duplicateLine}</div>
              <Button variant="outlined" size="small" onClick={changeDuplicate(true)} startIcon={<ContentCopyIcon />}>
                {tr.entityEdit.duplicateButton}
              </Button>
            </div>
          </FormGroup>
        )}
        {!!lineId && (
          <FormGroup>
            {!isConfirmDelete && (
              <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                <div style={{ margin: '0.5rem 0', flex: 1 }}>{tr.lineEdit.deleteLine}</div>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={changeConfirmDelete(true)}
                  startIcon={<DeleteIcon />}
                >
                  {tr.entityEdit.deleteButton}
                </Button>
              </div>
            )}
            {isConfirmDelete && (
              <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                <div style={{ margin: '0.5rem 0', flex: 1 }}>{tr.entityEdit.confirmDelete}</div>
                <div style={{ display: 'flex', gap: '0.5rem' }}>
                  <Button variant="contained" color="error" size="small" onClick={onDelete}>
                    {tr.entityEdit.yes}
                  </Button>
                  <Button variant="outlined" size="small" onClick={changeConfirmDelete(false)}>
                    {tr.entityEdit.no}
                  </Button>
                </div>
              </div>
            )}
          </FormGroup>
        )}
        <LineDuplicationDialog
          onClose={changeDuplicate(false)}
          defaultShopId={shopId || ''}
          onDuplicate={handleDuplicate}
          open={isDuplicateDialog}
        />
      </Form>
    </Card>
  )
}

export { AdvancedSettingsGroup }
