import { Typography } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import { WeekdayElement } from 'shared/models'
import { ScheduleElement, ScheduleFlex, ScheduleName, WeekdaysContainer } from './styled'

type Props = {
  schedule: WeekdayElement[]
}

function ScheduleView(props: Props) {
  const { tr, dateFormatter } = useTranslation()

  const weekdays = [
    { id: 1, name: tr.weekdays.Monday },
    { id: 2, name: tr.weekdays.Tuesday },
    { id: 3, name: tr.weekdays.Wednesday },
    { id: 4, name: tr.weekdays.Thursday },
    { id: 5, name: tr.weekdays.Friday },
    { id: 6, name: tr.weekdays.Saturday },
    { id: 0, name: tr.weekdays.Sunday }
  ]

  return (
    <WeekdaysContainer>
      {weekdays.map((day) => {
        const isToday = day.id === new Date().getDay()
        const foundedSchedule = props.schedule.find((s) => (s.weekdays || []).includes(day.id))

        return (
          <ScheduleElement key={day.id} isToday={isToday}>
            <ScheduleFlex>
              <ScheduleName>{day.name}</ScheduleName>
              {foundedSchedule && (
                <div style={{ textAlign: 'right' }}>
                  {!!foundedSchedule.openingHour_unixtime && foundedSchedule.closingHour_unixtime && (
                    <Typography variant="body1" lineHeight="12px">
                      {dateFormatter(foundedSchedule.openingHour_unixtime, 'time')} -{' '}
                      {dateFormatter(foundedSchedule.closingHour_unixtime, 'time')}
                    </Typography>
                  )}
                  {!!foundedSchedule.serviceWithPause &&
                    !!foundedSchedule.pauseStartHour_unixtime &&
                    !!foundedSchedule.pauseEndHour_unixtime && (
                      <Typography variant="caption" color="GrayText">
                        {tr.lineEdit.pause} {dateFormatter(foundedSchedule.pauseStartHour_unixtime, 'time')} -{' '}
                        {dateFormatter(foundedSchedule.pauseEndHour_unixtime, 'time')}
                      </Typography>
                    )}
                </div>
              )}
              {!foundedSchedule && (
                <Typography variant="body1" lineHeight="12px">
                  {tr.lineEdit.closed}
                </Typography>
              )}
            </ScheduleFlex>
          </ScheduleElement>
        )
      })}
    </WeekdaysContainer>
  )
}

export { ScheduleView }
