import { useMemo } from 'react'
import { TerminalType } from 'shared/models'
import {
  Dvr as InfopadIcon,
  Keyboard as KioskIcon,
  Help as UnknownIcon,
  Grading as CheckIcon
} from '@mui/icons-material'
import { TerminalIconProps } from 'pages/terminal/types'

function TerminalIcon(props: TerminalIconProps) {
  const Icon = useMemo(() => {
    switch (props.type) {
      case TerminalType.InfoPad:
        return InfopadIcon
      case TerminalType.AcceptationScreen:
        return CheckIcon
      case TerminalType.Kiosk:
        return KioskIcon
      case TerminalType.WebKiosk:
        return KioskIcon
      default:
        return UnknownIcon
    }
  }, [props.type])

  return <Icon />
}

export { TerminalIcon }
