import axios from 'axios'
import { showMessage, MessageTypes } from 'store/actions/main-layout-action-creators'
import i18n, { AppLocale } from 'shared/i18n'
import * as Nav from 'pages/nav'
import { store } from 'store'
import { setViewPermissionBlocked } from 'store/reducers/view-permission-reducer'
import { clearProfile } from 'store/reducers/user-reducer'
import { HubConnection } from '@microsoft/signalr'

function combine(data, ...funcs) {
  return funcs.reduce((prev, curr) => curr(prev), data)
}

axios.interceptors.response.use(successDataExtractor, (response) =>
  combine(response, netError, unauthorized, serverError, typingErrors, notFound, errorThrow)
)

axios.defaults.baseURL = process.env.APP_API
axios.defaults.headers.common['Accept'] = `application/vnd.jefile.bo.web.1+json`
axios.defaults.headers.common['X-Jefile-Version'] = __APP_VERSION__
axios.defaults.withCredentials = true

function successDataExtractor(response) {
  return Promise.resolve(response.data)
}

function errorThrow(response) {
  return Promise.reject(response)
}

function netError(error) {
  if (error.isAxiosError && error.response === undefined && error?.code !== 'ERR_CANCELED') {
    const locale = store.getState().user.appLocale || AppLocale.En

    i18n.getTranslationAsync(locale).then((tr) => {
      const serverErrorMessage = tr.serverResponseMessage.networkFail
      store.dispatch(showMessage(serverErrorMessage, MessageTypes.Error))
    })
  }

  return error
}

function unauthorized(error) {
  const showCaptcha = error?.response?.data?.showCaptcha

  if (error.response && error.response.status === 401 && !showCaptcha) {
    store.dispatch(clearProfile())
    window.location.replace(`#${Nav.login()}`)
  }

  return error
}

function notFound(error) {
  if (error.response && error.response.status === 404) {
    const locale = store.getState().user.appLocale || AppLocale.En

    i18n.getTranslationAsync(locale).then((tr) => {
      const serverErrorMessage = tr.serverResponseMessage.ressourceWasNotFound
      store.dispatch(showMessage(serverErrorMessage(''), MessageTypes.Error))
    })
  }

  return error
}

function serverError(error) {
  if (error.response && error.response.status === 500) {
    const locale = store.getState().user.appLocale || AppLocale.En

    i18n.getTranslationAsync(locale).then((tr) => {
      const serverErrorMessage = tr.serverResponseMessage.error
      store.dispatch(showMessage(serverErrorMessage, MessageTypes.Error))
    })
  }

  return error
}

function typingErrors(error) {
  if (!error.config?.errorHandle && error.config?.errorHandle !== undefined) {
    return error
  }

  if (
    error.response &&
    error.response.status < 500 &&
    error.response.status >= 400 &&
    error.response.status !== 401 &&
    error.response.status !== 404
  ) {
    const locale = store.getState().user.appLocale || AppLocale.En

    const deprecatedType = error.response.data?.exceptionMessage?.type
    const type = error.response.data?.type
    const firstErrorType = error.response.data?.length ? error.response.data?.[0]?.type : undefined
    const data = error.response.data?.data?.exceptionMessage ?? error.response.data?.data
    const trueType = deprecatedType || type || firstErrorType

    if (trueType === 'jeFile.global.noPermissionError') {
      store.dispatch(setViewPermissionBlocked(true))
      return error
    }

    const errorString = String(trueType)
    const errorElements = errorString.split('.')

    i18n.getTranslationAsync(locale).then((tr) => {
      const serverTypedError = tr.serverTypedError
      const serverErrorMessage = tr.serverResponseMessage.error

      const typedErrorMessage =
        serverTypedError[errorElements[0]] &&
        serverTypedError[errorElements[0]][errorElements[1]] &&
        serverTypedError[errorElements[0]][errorElements[1]][errorElements[2]]

      if (typeof typedErrorMessage === 'function') {
        store.dispatch(showMessage(typedErrorMessage(data) || serverErrorMessage, MessageTypes.Error))
      } else {
        store.dispatch(showMessage(typedErrorMessage || serverErrorMessage, MessageTypes.Error))
      }
    })
  }

  return error
}

export function monitoringHubErrorHandler(connection: HubConnection) {
  return (data: any) => {
    connection.stop()

    if (data?.type || data?.Type) {
      const locale = store.getState().user.appLocale || AppLocale.En

      const type = data?.type || data?.Type || ''
      const errorElements = String(type).split('.')

      i18n.getTranslationAsync(locale).then((tr) => {
        const serverTypedError = tr.serverTypedError
        const serverErrorMessage = tr.serverResponseMessage.error

        const typedErrorMessage =
          serverTypedError[errorElements[0]] &&
          serverTypedError[errorElements[0]][errorElements[1]] &&
          serverTypedError[errorElements[0]][errorElements[1]][errorElements[2]]

        if (typedErrorMessage && typeof typedErrorMessage === 'function') {
          store.dispatch(
            showMessage(typedErrorMessage(data?.data || data?.Data) || serverErrorMessage, MessageTypes.Error)
          )
        } else {
          store.dispatch(showMessage(typedErrorMessage || serverErrorMessage, MessageTypes.Error))
        }
      })
    }
  }
}

export { default as user } from './user'
export { default as place } from './place'
export { default as line } from './line'
export { default as checkpoint } from './checkpoint'
export { default as zone } from './zone'
export { default as beacon } from './beacon'
export { default as campaign } from './campaign'
export { default as advertisement } from './advertisement'
export { default as file } from './file'
export { default as brand } from './brand'
export { default as journal } from './journal'
export { default as dailyStats } from './daily-stats'
export { default as monitoring } from './monitoring'
export { default as customers } from './customers'
export { default as terminal } from './terminal'
export { default as messageTemplates } from './message-templates'
export { default as staffManagement } from './staff-management'
export { default as appointments } from './appointments'
export { default as broadcastAlerts } from './broadcast-alerts'
export { default as counter } from './counter'
export { default as translations } from './translations'
export { default as appointmentPlaceList } from './appointmentPlaceList'
export { default as meta } from './meta'
export { default as tag } from './tags'
export { default as captcha } from './captcha'
export { default as notification } from './notification'
