import { useEffect, useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { DaysOff } from './dumb/days-off'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import * as Nav from 'pages/nav'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import Container from 'shared/ui-kit/container'
import { useHistory, useParams } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { CenterContainer } from 'shared/ui-kit/center-container'
import { useTitle } from 'shared/hooks/useTitle'
import { useDataProvider } from 'features/isolated-data-provider'
import { ParentPlaceModel, DaysOffModel } from 'shared/models'
import { place as placeApi } from 'shared/api'
import { useAppDispatch } from 'store'
import { useTimeZoneFilter } from 'features/time-zone-filter'

function DaysOffPage() {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { placeId } = useParams<{ placeId: string }>()
  const { tr } = useTranslation()
  const [data, setData] = useState<DaysOffModel>()
  const [processing, setProcessing] = useState(false)

  const { getPlace } = useDataProvider()

  useTitle({ placeName: getPlace(placeId)?.name })

  useTimeZoneFilter(placeId)

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData() {
    try {
      const res = await placeApi.getDaysOff({ shopId: placeId })
      setData(res.data)
      dispatch(setBreadcrumbs(getBreadcrumbs(res.parents)))
    } catch (err) {
      history.push(Nav.index())
    }
  }

  function getBreadcrumbs(parents: ParentPlaceModel) {
    const crumb = getCrumbs(tr.breadcrumbs)
    const shopName = parents.shop?.name || ''

    return [crumb.home(), crumb.places(), crumb.place([placeId], shopName), crumb.daysOff([placeId])]
  }

  async function handleSave(data: DaysOffModel) {
    setProcessing(true)

    try {
      await placeApi.updateDaysOff(data)
      history.push(Nav.place(placeId))
    } catch (err) {
      console.warn(err)
      setProcessing(false)
    }
  }

  if (!data) {
    return (
      <CenterContainer>
        <CircularProgress color="secondary" />
      </CenterContainer>
    )
  }

  return (
    <Container>
      <DaysOff data={data} onSave={handleSave} processing={processing} />
    </Container>
  )
}

export default DaysOffPage
