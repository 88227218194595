import { useEffect, useMemo, useState } from 'react'
import { createCheckpoint, lineMonitoring, discreteLineMonitoring, lineMonitoringState } from 'pages/nav'
import { checkpoint as checkpointApi, line as lineApi } from 'shared/api'
import { useTranslation } from 'shared/i18n/translate'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { useTimeZoneFilter } from 'features/time-zone-filter'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { CheckpointModel, CheckpointStatus, LineModel, ParentPlaceAndLineModel } from 'shared/models'
import { WarningNotification } from 'shared/ui-kit/warning-notification'
import CheckpointList from './checkpoint-list-dumb'
import EmptyScreen from 'shared/ui-kit/empty-screen'
import { CheckpointDisableDialog } from './components/checkpoint-disable-dialog'
import {
  ToggleOff,
  Add as AddIcon,
  Link as LinkIcon,
  MoreVert as MoreIcon,
  People as PeopleIcon,
  ShoppingBasket as BasketIcon,
  DonutLarge as DonutLargeIcon,
  ViewModule as LineStatisticsIcon
} from '@mui/icons-material'
import { Menu, AppBar, Tooltip, Toolbar, MenuItem, IconButton, Divider } from '@mui/material'
import { NavLink, useHistory, useLocation, useParams } from 'react-router-dom'
import produce from 'immer'
import Container from 'shared/ui-kit/container'
import PageLoader from 'shared/ui-kit/page-loader'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { useTitle } from 'shared/hooks/useTitle'
import { useDataProvider } from 'features/isolated-data-provider'
import { isCheckpointStarted } from './utils'
import { useAppDispatch, useAppSelector } from 'store'
import Card from 'shared/ui-kit/card'
import EditListIcon from '@mui/icons-material/List'
import { ListItemIcon } from './components/list-item-icon'
import CloseIcon from '@mui/icons-material/Close'
import SuccessIcon from '@mui/icons-material/Done'
import { addTestAttr } from 'shared/utils/test-attr'

function SmartCheckpointList() {
  const { getPlace } = useDataProvider()

  const { placeId, lineId } = useParams<{ placeId: string; lineId?: string }>()

  useTimeZoneFilter(placeId)

  const dispatch = useAppDispatch()

  const {
    canCloseCheckpoints,
    canOpenCheckpoints,
    canUpdateShops,
    accessPlaceLineMonitoring,
    displayReducedInterface
  } = useAppSelector(getPermissionsSelector)

  const { tr, locale } = useTranslation()

  const { replace } = useHistory()
  const location = useLocation<{ fromCheckpointId?: string; navTo?: string }>()

  const [data, setData] = useState<CheckpointModel[] | null>(null)
  const [parents, setParents] = useState<Partial<ParentPlaceAndLineModel> | null>(null)
  const [line, setLine] = useState<LineModel | null>(null)
  const [otherButtonEl, setOtherButtonEl] = useState<null | HTMLElement>(null)
  const [showDisableAllDialog, setShowDisableAllDialog] = useState(false)

  const [orderChangeMode, setOrderChangeMode] = useState(false)

  const [checkpointsOrderDraft, setCheckpointsOrderDraft] = useState<CheckpointModel[] | null>(null)

  useTitle({ placeName: getPlace(placeId)?.name, lineName: line?.name })

  const isDiscrete = useMemo(
    () => line?.lineCalculationAlgorithmMode === 'discrete',
    [line?.lineCalculationAlgorithmMode]
  )

  function handleDataChange(data: CheckpointModel[]) {
    setData(data)
  }

  useEffect(() => {
    checkpointApi.getCheckpointList(placeId, lineId).then((response) => {
      if (response.data?.length === 1 && !canUpdateShops && lineId) {
        const lineMonitoringPath = isDiscrete
          ? discreteLineMonitoring(placeId, lineId)
          : lineMonitoring(placeId, lineId)
        replace(lineMonitoringPath)
      }

      handleDataChange(response.data)
      setParents(response.parents)
    })

    if (lineId) {
      lineApi.getLine(lineId, placeId, { headers: { 'X-Form-Action': 'View' } }).then((response) => {
        setLine(response.data || null)
      })
    }
  }, [])

  useEffect(() => {
    if (!parents?.shop) {
      return
    }

    const crumb = getCrumbs(tr.breadcrumbs)

    const tail = parents.line
      ? [
          crumb.lines([parents.shop.id as string]),
          crumb.line([parents.shop.id as string, parents.line.id as string], parents.line.name),
          crumb.checkpoints([parents.shop.id as string, parents.line.id as string])
        ]
      : [crumb.checkpoints([parents.shop.id as string])]

    dispatch(
      setBreadcrumbs([
        crumb.home(),
        crumb.places(),
        crumb.place([parents.shop.id as string], parents.shop.name),
        ...tail
      ])
    )
  }, [parents, locale])

  function handleAllCheckpointClose() {
    setShowDisableAllDialog(true)
  }

  function closeMoreMenu() {
    setOtherButtonEl(null)
  }

  function handleCloseAllCheckpointsDecline() {
    setShowDisableAllDialog(false)
  }

  function handleCloseAllCheckpoints() {
    if (!data) {
      return
    }

    data.forEach((checkpoint) => {
      if (!isCheckpointStarted(checkpoint)) {
        return
      }

      checkpointApi.finishCheckpoint(checkpoint.id).then(() => {
        setData((prev) => {
          if (!prev) {
            return prev
          }

          const updated = produce(prev, (draft) => {
            const toChange = draft.find((c) => c.id === checkpoint.id)
            if (toChange) {
              toChange.active = false
              toChange.status = CheckpointStatus.finished
            }
          })

          return updated
        })
      })
    })

    setShowDisableAllDialog(false)
  }

  function handleChangeListOrder() {
    setOrderChangeMode(true)
    closeMoreMenu()
  }

  function changeListClose() {
    setOrderChangeMode(false)
  }

  function handleDropEnd(items: CheckpointModel[]) {
    setCheckpointsOrderDraft(items)
  }

  async function handleSaveOrders() {
    if (checkpointsOrderDraft && lineId) {
      await checkpointApi.updateCheckpointListOrder({
        placeId,
        lineId,
        items: checkpointsOrderDraft.map((el, index) => ({ checkpointId: el.id, order: index }))
      })

      setData(checkpointsOrderDraft)
      changeListClose()
      setCheckpointsOrderDraft(null)
    } else {
      changeListClose()
      setCheckpointsOrderDraft(null)
    }
  }

  const enabledCheckpoints = (data || []).filter((checkpoint) => isCheckpointStarted(checkpoint))

  if (data === null) {
    return <PageLoader />
  }

  return (
    <>
      {!!accessPlaceLineMonitoring && (
        <AppBar position="sticky" color="inherit" elevation={2} enableColorOnDark style={{ top: 64, borderRadius: 0 }}>
          <Toolbar>
            <Container style={{ padding: 0 }}>
              <div style={{ display: 'flex', width: '100%', height: 64, alignItems: 'center', gap: '0.5rem' }}>
                {!!canUpdateShops && lineId && (
                  <NavLink to={createCheckpoint(placeId, lineId)}>
                    <Tooltip title={tr.checkpointList.addButton}>
                      <IconButton id="addButton">
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </NavLink>
                )}
                <span style={{ flex: 1 }} />
                {!!orderChangeMode && (
                  <>
                    <Divider orientation="vertical" style={{ height: '48px' }} />

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.25rem' }}>
                      <IconButton onClick={changeListClose}>
                        <CloseIcon />
                      </IconButton>
                      <IconButton onClick={handleSaveOrders}>
                        <SuccessIcon />
                      </IconButton>
                    </div>
                  </>
                )}
                {!orderChangeMode && accessPlaceLineMonitoring && (
                  <>
                    <Tooltip title={tr.checkpointList.other}>
                      <IconButton onClick={(event) => setOtherButtonEl(event.currentTarget)}>
                        <MoreIcon />
                      </IconButton>
                    </Tooltip>
                    <Menu anchorEl={otherButtonEl} open={!!otherButtonEl} onClose={closeMoreMenu} elevation={2}>
                      {!!lineId &&
                        !isDiscrete &&
                        !displayReducedInterface &&
                        line?.flags?.displayStatesMonitoring === true && (
                          <MenuItem component={NavLink} to={lineMonitoringState(placeId, lineId)}>
                            <ListItemIcon>
                              <LineStatisticsIcon fontSize="small" />
                            </ListItemIcon>
                            {tr.checkpointList.lineStateMonitoring}
                            <LinkIcon color="disabled" fontSize="small" style={{ marginLeft: '0.5rem' }} />
                          </MenuItem>
                        )}
                      {!!lineId && (
                        <MenuItem
                          component={NavLink}
                          to={isDiscrete ? discreteLineMonitoring(placeId, lineId) : lineMonitoring(placeId, lineId)}
                        >
                          <ListItemIcon>{isDiscrete ? <DonutLargeIcon /> : <PeopleIcon />}</ListItemIcon>
                          {tr.checkpointList.lineMonitoring}
                          <LinkIcon color="disabled" fontSize="small" style={{ marginLeft: '0.5rem' }} />
                        </MenuItem>
                      )}
                      {!!lineId && <Divider />}
                      {!!lineId && (
                        <MenuItem onClick={handleChangeListOrder} disabled={(data || []).length < 2}>
                          <ListItemIcon>
                            <EditListIcon fontSize="small" />
                          </ListItemIcon>
                          {tr.common.cahngeListOrder}
                        </MenuItem>
                      )}
                      {canCloseCheckpoints && (data?.length || 0) > 1 && (
                        <MenuItem onClick={handleAllCheckpointClose} disabled={enabledCheckpoints.length === 0}>
                          <ListItemIcon>
                            <ToggleOff fontSize="small" />
                          </ListItemIcon>
                          {tr.checkpointList.сloseAllServicePoints}
                        </MenuItem>
                      )}
                    </Menu>
                  </>
                )}
              </div>
            </Container>
          </Toolbar>
        </AppBar>
      )}
      <Container>
        {!orderChangeMode && (
          <WarningNotification style={{ marginBottom: '1rem' }} isDisplay={isDiscrete && data.length > 1 && !!lineId}>
            {tr.checkpointList.discreteLineMessage}
          </WarningNotification>
        )}
        {!data.length && <EmptyScreen text={tr.checkpointList.empty} iconClass={BasketIcon} />}
        {!!data.length && (
          <Card style={{ maxWidth: 'inherit' }} paperStyle={{ overflow: 'hidden' }} {...addTestAttr('CheckpointList')}>
            <CheckpointList
              data={data || []}
              showEditHostIcon={canOpenCheckpoints || canCloseCheckpoints || false}
              showLine={!!lineId}
              isDiscrete={isDiscrete}
              checkpointId={location.state?.fromCheckpointId}
              orderChangeMode={orderChangeMode}
              onDropEnd={handleDropEnd}
            />
          </Card>
        )}
        <CheckpointDisableDialog
          enabledCount={enabledCheckpoints.length}
          open={showDisableAllDialog}
          onSubmit={handleCloseAllCheckpoints}
          onDecline={handleCloseAllCheckpointsDecline}
        />
      </Container>
    </>
  )
}

export default SmartCheckpointList
