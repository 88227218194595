import styled from '@emotion/styled'

const ResetPasswordWrapper = styled.section`
  background: #fafafa;
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ResetForm = styled.form`
  padding: 16px;
  max-width: 600px;
  box-sizing: border-box;
`

const ResetButtons = styled.div`
  margin: 24px 0 0;
  display: flex;
`

const ResetText = styled.div`
  margin: 0 0 24px;
  font-weight: 300;
`

export { ResetPasswordWrapper, ResetForm, ResetButtons, ResetText }
