import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'shared/i18n/translate'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { useTimeZoneFilter } from 'features/time-zone-filter'
import MonitoringPositionEdit from 'features/monitoring-position-edit/monitoring-position-edit'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { discreteLineMonitoring } from 'pages/nav'
import { useAppDispatch } from 'store'

function LineMonitoringEdit() {
  const { tr } = useTranslation()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { checkpoint, lineId, placeId, positionId } = useParams<{
    placeId: string
    lineId: string
    positionId: string
    checkpoint: string
  }>()
  useTimeZoneFilter(placeId)

  function handleBreadcrumbsChange({ parents }) {
    const crumb = getCrumbs(tr.breadcrumbs)
    const shopId = parents.shop.id
    const shopName = parents.shop.name
    const lineId = parents.line.id
    const lineName = parents.line.name

    dispatch(
      setBreadcrumbs([
        crumb.home(),
        crumb.places(),
        crumb.place([shopId], shopName),
        crumb.lines([shopId]),
        crumb.line([shopId, lineId], lineName),
        crumb.discreteLineMonitoring([shopId, lineId]),
        positionId
          ? crumb.lineMonitoringEdit([shopId, lineId, positionId])
          : crumb.lineMonitoringCreate([shopId, lineId])
      ])
    )
  }

  function handleSubmit(term) {
    const params = new URLSearchParams()

    if (term) {
      params.set('term', term)
    }

    history.push({
      pathname: discreteLineMonitoring(placeId, lineId),
      search: String(params)
    })
  }

  return (
    <MonitoringPositionEdit
      onBreadcrumbsChange={handleBreadcrumbsChange}
      onSubmit={handleSubmit}
      lineId={lineId}
      placeId={placeId}
      positionId={positionId}
      checkpointId={checkpoint}
    />
  )
}

export default LineMonitoringEdit
