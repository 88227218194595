import { useEffect, useMemo, useRef, useState } from 'react'
import { CheckpointModel, LineModel, PositionModel, PositionState } from 'shared/models'
import { MonitoringPositions } from '../styled'
import { MonitoringPosition } from './position'
import { useParams } from 'react-router'

type Props = {
  positions: PositionModel[]
  line: LineModel
  checkpoints: CheckpointModel[]
  waitingPositionIds: (string | number)[]
  setWaitingPositionIds: (waitingPositionIds: (string | number)[]) => void
  onRemove: (positionId?: string | number) => void
  onStateChange: (positionId: string | number, newState: PositionState) => void
}

function LineMonitoringPositions(props: Props) {
  const { lineId, placeId, positionId } = useParams<{ lineId: string; placeId: string; positionId?: string }>()
  const [selectedId, setSelectedId] = useState<number | string | undefined>(positionId)
  const focusedPosition = useRef<HTMLElement | null>(null)
  const scrolled = useRef<boolean>(false)

  function positionRefFunc(positionId: string | number) {
    return (x: HTMLElement | null) => {
      if (selectedId === positionId) {
        focusedPosition.current = x
      }
    }
  }

  function handleSelect(positionId?: string | number) {
    return () => {
      setSelectedId(positionId === selectedId ? undefined : positionId)
    }
  }

  function handleClose() {
    setSelectedId(undefined)
  }

  useEffect(() => {
    if (focusedPosition.current && positionId && !scrolled.current) {
      scrolled.current = true
      const y = focusedPosition.current.getBoundingClientRect().y
      window.scroll(0, y - 160)
    }
  }, [focusedPosition.current, positionId, props.positions])

  return useMemo(
    () => (
      <MonitoringPositions>
        {props.positions.map((position) => (
          <MonitoringPosition
            checkpoints={props.checkpoints}
            line={props.line}
            lineId={lineId}
            placeId={placeId}
            onRemove={props.onRemove}
            position={position}
            key={position.id}
            selected={selectedId === position.id}
            onSelect={handleSelect(position.id)}
            onClose={handleClose}
            setWaitingPositionIds={props.setWaitingPositionIds}
            waitingPositionIds={props.waitingPositionIds}
            ref={positionRefFunc(position.id)}
            onStateChange={props.onStateChange}
          />
        ))}
      </MonitoringPositions>
    ),
    [selectedId, props.line, props.checkpoints, props.positions, props.waitingPositionIds]
  )
}

export { LineMonitoringPositions }
