import { CheckpointModel, CheckpointStatus, PositionState } from 'shared/models'
import { InactiveFilters } from './types'

function isCheckpointStarted(checkpoint: CheckpointModel) {
  return checkpoint.status === CheckpointStatus.starting || checkpoint.status === CheckpointStatus.started
}

const inWorkStatuses = [
  PositionState.ServiceStarted,
  PositionState.InService,
  PositionState.CalledToCheckpoint,
  PositionState.NearCheckpoint,
  PositionState.MovingToCheckpoint,
  PositionState.AutoFinished,
  PositionState.ManualFinished
]

function getInactiveFiltersStorageKey(placeId: string, lineId: string, checkpointId: string) {
  return `checkpointInactiveFilters-${placeId}-${lineId}-${checkpointId}`
}

function setInactiveFiltersToStorage(
  inactiveFilter: InactiveFilters,
  shopId: string,
  lineId: string,
  checkpointId: string
) {
  if (inactiveFilter?.helpIsEnabled) {
    localStorage.setItem(getInactiveFiltersStorageKey(shopId, lineId, checkpointId), JSON.stringify(inactiveFilter))
  } else {
    localStorage.removeItem(getInactiveFiltersStorageKey(shopId, lineId, checkpointId))
  }
}

function getInactiveFiltersFromStorage(placeId: string, lineId: string, checkpointId: string): InactiveFilters | null {
  try {
    const value = localStorage.getItem(getInactiveFiltersStorageKey(placeId, lineId, checkpointId))
    const json = value ? JSON.parse(value) : null
    return json ? { ...json, startedAfterReload: true } : null
  } catch (err) {
    return null
  }
}

function getStorageServiceKey(checkpointId: string | number) {
  return `SERVICE_POINT_SERVICES_${checkpointId}`
}

function getStorageAllServiceKey(checkpointId: string | number) {
  return `SERVICE_POINT_ALL_SERVICES_${checkpointId}`
}

function getDefaultInactiveFilters(lineId?: string | number): InactiveFilters {
  return { maxTimeDuration: 0, serviceIds: [], lineId: Number(lineId), helpIsEnabled: false }
}

export {
  isCheckpointStarted,
  inWorkStatuses,
  getInactiveFiltersStorageKey,
  getInactiveFiltersFromStorage,
  getStorageServiceKey,
  getStorageAllServiceKey,
  setInactiveFiltersToStorage,
  getDefaultInactiveFilters
}
