import styled from '@emotion/styled'

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  padding: 0.5rem;
  margin: -0.5rem -0.5rem 0.5rem;
`

const AdditionalTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1;
`

const AdditionalActions = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: 1rem;
`

const MoreSettingsContainer = styled.div`
  padding: 1rem;
  border-left: solid 2px #db214d;
`

const ChecklistContainer = styled.div`
  border-radius: 4px;
  padding: 0.5rem;
  transition: all 0.32s ease-out 0s;
`

const ChecklistTitle = styled.div`
  display: flex;
  gap: 0.5rem;
`

const ChecklistAction = styled(AdditionalActions)`
  margin-left: 0.5rem;
`

const ImpactContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
`

const AccordionTitle = styled.div`
  margin-top: -2rem;
  position: absolute;
  font-size: 14px;
  opacity: 0.88;
`

const Accordion = styled.div`
  padding: 2rem 0 0.5rem 1rem;
  margin: 1rem 0 2rem;
  border-left: solid 2px #db214d;
`

const AccordionActions = styled.div`
  margin-top: 1rem;
`

const CustomDurationContainer = styled.div`
  display: grid;
  border-left: solid 2px #db214d;
  padding: 2rem 1rem 0rem;
  margin: 1rem 0 2rem;
  gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(110px, auto));
`

const CustomDurationTitle = styled.div`
  margin-top: -2rem;
  position: absolute;
  font-size: 14px;
  opacity: 0.88;
`

const ErrorMessage = styled.div`
  font-size: 12px;
  color: #ef5350;
  margin: 0 0 0.5rem;
`

export {
  ItemContainer,
  AdditionalTitle,
  AdditionalActions,
  MoreSettingsContainer,
  ChecklistContainer,
  ChecklistTitle,
  ChecklistAction,
  ImpactContainer,
  AccordionTitle,
  Accordion,
  AccordionActions,
  CustomDurationContainer,
  CustomDurationTitle,
  ErrorMessage
}
