import Dropdown from 'shared/ui-kit/dropdown'
import { useTranslation } from 'shared/i18n/translate'
import produce from 'immer'

type Props = {
  brands: any[]
  onChange?: (brandId: number | string) => void
  brandId?: number | string | null
}

function BrandFilter({ brands, onChange, brandId }: Props) {
  const { tr } = useTranslation()

  const { allBrands } = tr.reportsFilter

  const dropdownBrands =
    brands?.length > 1
      ? produce(brands, (draft) => {
          draft.unshift({ id: '-1', name: allBrands })
        })
      : brands

  function handleBrandChange(id?: number | string) {
    const brandId = id === '-1' ? (null as any) : id

    onChange?.(brandId)
  }

  return (
    <>
      {dropdownBrands.length > 1 && (
        <Dropdown
          style={{ maxWidth: '150px', width: '100%', minWidth: 0 }}
          value={brandId || '-1'}
          data={dropdownBrands}
          dataConverter={(item) => ({ value: item.id, text: item.name })}
          onChange={handleBrandChange}
          label={tr.common.brand}
        />
      )}
    </>
  )
}

export { BrandFilter }
