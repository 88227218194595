import { useState } from 'react'
import { Form, FormGroup } from 'shared/ui-kit/form'
import { TextField } from 'shared/ui-kit/text-field'
import { DatePicker } from 'features/date'
import { useTranslation } from 'shared/i18n/translate'
import { isPositiveNumber } from 'shared/utils/string-test'
import Card from 'shared/ui-kit/card'
import Button from '@mui/material/Button'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Text from 'shared/ui-kit/text'
import { BeaconEditFormProps } from './types'
import { beaconEditvalidationSchema } from './validation'
import { ActionText, ActionsWrapper, ButtonWithTextWrapper, ConfirmationActions } from 'shared/ui-kit/action-wrapper'
import { Divider } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

function BeaconEditForm(props: BeaconEditFormProps) {
  const { tr, dateFormatter } = useTranslation()
  const [confirmDelete, setConfirmDelete] = useState(false)

  const { control, handleSubmit, formState } = useForm<any>({
    resolver: yupResolver(beaconEditvalidationSchema),
    defaultValues: props.data,
    mode: 'onTouched'
  })

  function handleTryDelete() {
    setConfirmDelete(true)
  }

  function handleCancelDelete() {
    setConfirmDelete(false)
  }

  function handleConfirmDelete() {
    props.onDelete(props.data.id)
  }

  return (
    <form autoComplete="off" onSubmit={handleSubmit(props.onSave)}>
      <Card>
        <FormGroup>
          <Controller
            name="name"
            control={control}
            render={({ field: f }) => (
              <TextField
                value={f.value || ''}
                onChange={f.onChange}
                onBlur={f.onBlur}
                label={tr.beaconEdit.name}
                placeholder={tr.beaconEdit.namePlaceholder}
                attention={!f.value}
                message={`${f.value ? f.value.length : 0}/100 ${tr.entityEdit.chars}`}
                fullWidth
              />
            )}
          />
        </FormGroup>
        {props.creation && (
          <FormGroup>
            <Controller
              name="major"
              control={control}
              render={({ field: f }) => (
                <TextField
                  value={f.value || ''}
                  type="number"
                  onChange={f.onChange}
                  onBlur={f.onBlur}
                  label={tr.beaconEdit.major}
                  placeholder={tr.beaconEdit.majorPlaceholder}
                  attention={!f.value}
                  test={isPositiveNumber}
                  fullWidth
                />
              )}
            />
          </FormGroup>
        )}
        {props.creation && (
          <FormGroup>
            <Controller
              name="minor"
              control={control}
              render={({ field: f }) => (
                <TextField
                  value={f.value || ''}
                  type="number"
                  onChange={f.onChange}
                  onBlur={f.onBlur}
                  label={tr.beaconEdit.minor}
                  placeholder={tr.beaconEdit.minorPlaceholder}
                  attention={!f.value}
                  test={isPositiveNumber}
                  fullWidth
                />
              )}
            />
          </FormGroup>
        )}
        <FormGroup>
          <Controller
            name="lastChargeDate"
            control={control}
            render={({ field: f }) => (
              <DatePicker
                value={f.value}
                onChange={(val) => {
                  f.onChange(val ? val : null)
                }}
                onBlur={f.onBlur}
                label={tr.beaconEdit.lastChargeDate}
                placeholder={tr.beaconEdit.lastChargeDatePlaceholder}
                style={{ width: '100%' }}
                allowClear
                shrink
              />
            )}
          />
        </FormGroup>
        {!props.creation && (
          <>
            <Divider />
            <Form title={tr.entityEdit.advancedGroup} expandable>
              <FormGroup>
                {!confirmDelete && (
                  <ButtonWithTextWrapper>
                    <ActionText>{tr.beaconEdit.deleteTitle}</ActionText>
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={handleTryDelete}
                      startIcon={<DeleteIcon />}
                      disabled={props.processing}
                    >
                      {tr.entityEdit.deleteButton}
                    </Button>
                  </ButtonWithTextWrapper>
                )}
                {confirmDelete && (
                  <ButtonWithTextWrapper>
                    <ActionText>{tr.entityEdit.confirmDelete}</ActionText>
                    <ConfirmationActions>
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        disabled={props.processing}
                        onClick={handleConfirmDelete}
                      >
                        {tr.entityEdit.yes}
                      </Button>
                      <Button variant="outlined" size="small" disabled={props.processing} onClick={handleCancelDelete}>
                        {tr.entityEdit.no}
                      </Button>
                    </ConfirmationActions>
                  </ButtonWithTextWrapper>
                )}
              </FormGroup>
            </Form>
          </>
        )}
      </Card>
      <ActionsWrapper>
        <Button
          disabled={!formState.isValid || props.processing}
          variant="contained"
          color="primary"
          size="small"
          type="submit"
        >
          {props.data.id ? tr.entityEdit.updateButton : tr.entityEdit.createButton}
        </Button>
        {props.data.lastUpdate?.date && props.data.lastUpdate.user && (
          <Text type="caption">
            {tr.entityView.lastUpdate(
              props.data.lastUpdate.user?.firstName,
              props.data.lastUpdate.user?.secondName,
              dateFormatter(props.data.lastUpdate.date, 'dateTime')
            )}
          </Text>
        )}
      </ActionsWrapper>
    </form>
  )
}

export { BeaconEditForm }
