import { ISOCountry } from './types'

const ISOCountries = async (locale: string) => {
  if (locale === 'ru') {
    const res = await import('./iso-countries.ru').then((res) => res.ISOCountriesRU.countries)
    return res
  }
  if (locale === 'en') {
    const res = await import('./iso-countries.en').then((res) => res.ISOCountriesEN.countries)
    return res
  }
  if (locale === 'fr') {
    const res = await import('./iso-countries.fr').then((res) => res.ISOCountriesFR.countries)
    return res
  }
  return
}

export const countries = {
  async getCountries(locale: string) {
    return await ISOCountries(locale)
  },
  getCountryName(countries: ISOCountry[], ISO?: string) {
    if (ISO) {
      const country = countries?.filter((country) => country.code === ISO)[0]
      return country?.title || ''
    }
    return
  }
}
export * from './types'
