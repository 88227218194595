import { CountersModel } from 'shared/models'
import { RootState } from 'store'

export function getUserSelector(state: RootState) {
  return state.user.userProfile
}

export function getUserStoreSelector(state: RootState) {
  return state.user
}

export function getUserLoadedSelector(state: RootState) {
  return state.user.loaded
}

export function getCountersSelector(state: RootState) {
  const defaultCounters: CountersModel = { campaigns: 0, shops: 0 }

  return state.user.userProfile?.counters || defaultCounters
}

export function getGroupPlacesSelector(state: RootState) {
  return state.user.groupShops
}

export function getSinglePlaceSelector(state: RootState) {
  return state.user.singleShop
}

export function getUserLocaleSelector(state: RootState) {
  return state.user?.appLocale
}
