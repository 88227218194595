import { Suspense, lazy, useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import Text from 'shared/ui-kit/text'
import { Form } from 'shared/ui-kit/form'
import { Button, Chip, FormLabel, IconButton, Dialog, Divider, Typography, TextField } from '@mui/material'
import PositionAction from 'features/position-actions/components/position-action'
import { monitoring, appointments } from 'shared/api'
import { showMessage, MessageTypes } from 'store/actions/main-layout-action-creators'
import { useAppDispatch } from 'store'
import { ProgressionFormValue, ProgressionTagsFormProps } from './types'
import FileUploader from 'features/file-uploader'
import { QRDescription, QRReaderWrapper, QRTextInfo, QRTitle } from 'features/position-edit/styled'
import { QrCodeScanner as QrCodeScannerIcon } from '@mui/icons-material'
import { videoContainerStyle } from 'features/position-edit/utils'
import { OnResultFunction } from 'shared/utils/qr-reader/types'
import ProgressionIcon from '@mui/icons-material/Bookmark'
import { ActionFormActions } from '../styled'

const QrReader = lazy(() => import('shared/utils/qr-reader'))

function ProgressionTags(props: ProgressionTagsFormProps) {
  const { tr } = useTranslation()
  const dispatch = useAppDispatch()

  const tagsList = (props.tags || '').split(';').filter(Boolean)
  const value = (props.selectedTags || '').split(';').filter(Boolean)

  const [qrScanerIsOpened, setQrScanerIsOpened] = useState(false)

  const [data, setData] = useState<ProgressionFormValue>({
    shopId: props.shopId,
    lineId: props.lineId,
    positionId: props.positionId,
    appointmentToken: props.appointmentToken,
    value,
    comment: props.comment || '',
    images: props.images || []
  })

  const [loading, setLoading] = useState(false)

  function handleChangeData<T extends keyof ProgressionFormValue>(key: T) {
    return (value: ProgressionFormValue[T]) => {
      setData((current) => ({ ...current, [key]: value }))
    }
  }

  function handleTagClick(tag: string) {
    return () => {
      const tagToRemove = data.value.find((dataTag) => dataTag === tag)

      if (props.allowMultipleProgressionTags) {
        handleChangeData('value')(
          tagToRemove ? data.value.filter((dataTag) => dataTag !== tagToRemove) : [...data.value, tag]
        )
      } else {
        handleChangeData('value')(tagToRemove ? [] : [tag])
      }
    }
  }

  async function handleChangeProgressionTags() {
    try {
      setLoading(true)

      if (data.appointmentToken) {
        await appointments.updateAppointmentProgressionTags(data)
      } else {
        await monitoring.changeProgressionTags(data)
      }

      dispatch(showMessage(tr.serverResponseMessage.success, MessageTypes.Success))

      props.onFinishChanging?.()
      props.onFinish?.()
    } catch (err) {
      dispatch(showMessage(tr.serverResponseMessage.error, MessageTypes.Error))
    } finally {
      setLoading(false)
    }
  }

  function handleOpenQRScanner() {
    setQrScanerIsOpened(true)
  }

  function handleCloseQRScanner() {
    setQrScanerIsOpened(false)
  }

  const handleQRResult: OnResultFunction = (text) => {
    if (text) {
      setData((current) => ({ ...current, comment: ((current.comment || '').trim() + ' ' + text).trim() }))
      handleCloseQRScanner()
    }
  }

  return (
    <>
      {(!props.progressionMode || !!props.formMode) && (
        <>
          {!props.isCardView && <Divider />}
          <PositionAction
            isCardView={props.isCardView}
            disabled={false}
            confirmation={false}
            confirmationMessage=""
            onSubmit={() => props.onProgressionMode?.()}
            style={props.style}
            icon={!props.withoutIcon && <ProgressionIcon fontSize="small" />}
            iconIntoContainer={props.iconIntoContainer}
          >
            {props.monitoringPositionMenuItemCaptions?.progression || tr.lineEdit.progressionTags.title}
          </PositionAction>
        </>
      )}
      {!!props.progressionMode && (
        <Form>
          {!props.formMode && (
            <Typography variant="body1" fontWeight={500} fontSize="18px">
              {props.monitoringPositionMenuItemCaptions?.progression || tr.lineEdit.progressionTags.title}
            </Typography>
          )}
          <div style={props.style}>
            {!props.isBreak && !!tagsList.length && (
              <div style={{ marginTop: '1rem' }}>
                <Text type="caption" style={{ margin: '0 0 4px' }}>
                  {tr.lineEdit.progressionTags.title}
                </Text>
                {tagsList.map((tag, index, array) => (
                  <Chip
                    label={tag}
                    key={index}
                    style={{ marginRight: index === array.length - 1 ? '' : '0.5rem', marginBottom: '0.5rem' }}
                    color={data.value.find((dataTag) => dataTag === tag) ? 'primary' : 'default'}
                    onClick={handleTagClick(tag)}
                  />
                ))}
              </div>
            )}
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}>
              <TextField
                label={tr.finishServiceForm.comments}
                value={data.comment}
                placeholder={tr.finishServiceForm.comments}
                onChange={(ev) => handleChangeData('comment')(ev.target.value)}
                fullWidth
                multiline
                InputLabelProps={{ shrink: true }}
                maxRows={15}
              />
              <IconButton onClick={handleOpenQRScanner}>
                <QrCodeScannerIcon fontSize="small" />
              </IconButton>
            </div>
            {!!props.withPhoto && (
              <div>
                <FormLabel style={{ fontSize: '12px' }}>{tr.common.photo}</FormLabel>
                {(data.images || []).map((image) => (
                  <FileUploader
                    key={image?.id}
                    id={`id-${image?.id}`}
                    file={image}
                    onChange={() => setData({ ...data, images: (data.images || []).filter((i) => i.id !== image.id) })}
                    multiple
                  />
                ))}
                <FileUploader
                  onChange={(image) => setData((curr) => ({ ...curr, images: [...(curr.images || []), image] }))}
                  imageMaxSizeMB={0.5}
                  imageMaxWidthOrHeight={1920}
                  accept=".doc, .pdf, .png, .jpg"
                  multiple
                />
              </div>
            )}
            <ActionFormActions $marginTop={props.isCardView ? '2rem' : '1rem'}>
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={handleChangeProgressionTags}
                disabled={loading}
              >
                {tr.finishServiceForm.confirm}
              </Button>
              {!!props.onBack && (
                <Button variant="text" size="small" onClick={props.onBack}>
                  {tr.positionActions.backButton}
                </Button>
              )}
            </ActionFormActions>
          </div>
        </Form>
      )}
      <Dialog open={qrScanerIsOpened} onClose={handleCloseQRScanner}>
        <QRReaderWrapper>
          <Suspense fallback={null}>
            <QrReader onResult={handleQRResult} videoContainerStyle={videoContainerStyle} />
          </Suspense>
        </QRReaderWrapper>
        <QRTextInfo>
          <QRTitle>{tr.lineMonitoring.qrTitle}</QRTitle>
          <QRDescription>{tr.lineMonitoring.qrDescription}</QRDescription>
        </QRTextInfo>
      </Dialog>
    </>
  )
}

export { ProgressionTags }
