import Card from 'shared/ui-kit/card'
import { useTranslation } from 'shared/i18n/translate'
import Text from 'shared/ui-kit/text'
import Timer from 'shared/ui-kit/timer'
import { Container, Stat, StatGroupDivider, Stats, StatsGroup, StatValue, Title } from './styled'
import { DailyStatsItemProps } from './types'

function DailyStatsItem(props: DailyStatsItemProps) {
  const { tr, dateFormatter } = useTranslation()

  function handleCustomersClick() {
    props.onCustomersClick(props.data.id)
  }

  return (
    <Card>
      <Container>
        <Title>{dateFormatter(props.data.date, { day: 'numeric', month: 'long' }, 'UTC')}</Title>
        <Stats>
          <StatsGroup>
            <Stat>
              <StatValue>{props.data.servedUsers}</StatValue>
              <Text type="caption" preLine={true}>
                {tr.dailyStatsList.servedUsers}
              </Text>
            </Stat>
            <Stat>
              <StatValue>{props.data.cancelledUsers}</StatValue>
              <Text type="caption" preLine={true}>
                {tr.dailyStatsList.cancelledUsers}
              </Text>
            </Stat>
            <Stat $active onClick={handleCustomersClick}>
              <StatValue>{props.data.totalUsers}</StatValue>
              <Text type="caption" preLine={true}>
                {tr.dailyStatsList.totalUsers}
              </Text>
            </Stat>
          </StatsGroup>
          <StatGroupDivider />
          <StatsGroup>
            <Stat>
              <StatValue>
                <Timer ms={props.data.promisedWaitingTime} />
              </StatValue>
              <Text type="caption" preLine={true}>
                {tr.dailyStatsList.promisedWaitingTime}
              </Text>
            </Stat>
            <Stat>
              <StatValue>
                <Timer ms={props.data.realWaitingTime} />
              </StatValue>
              <Text type="caption" preLine={true}>
                {tr.dailyStatsList.realWaitingTime}
              </Text>
            </Stat>
          </StatsGroup>
        </Stats>
      </Container>
    </Card>
  )
}

export { DailyStatsItem }
