/**
 * Shows any number as a number with two decimals (1 => 1.0)
 * @param {Number} value
 */
export function displayNumAsDouble(value: number | string) {
  if (Number.isInteger(value)) {
    return Number(value).toFixed(1)
  } else {
    return value
  }
}
