import imageCompression from 'browser-image-compression'

export type File = globalThis.File & { id?: string | number }

type FileUploaderProps = {
  request: (data: FormData) => Promise<number>
  onUpload?: (file: File) => void
  onSuccess?: (file: File) => void
  onFail?: (file: File, error: any) => void
  onAllComplete?: () => void
  onLoadingChange?: (isLoading: boolean) => void
  inputName?: string
  processForm?: (data: HTMLFormElement) => FormData
  onInvalid?: (reason: string) => void
  maxFileSize?: number
  imageMaxSizeMB?: number
  imageMaxWidthOrHeight?: number
  multiple?: boolean
}

type FileUploaderConstructor = FileUploaderProps & {
  accept?: string
  maxFileSize?: number
  onInvalid?: (reason: string) => void
}

class FileUploader {
  _props: FileUploaderProps
  _formContainer: FormContainer
  _reqs: Map<any, any>
  _canceled: number[]
  _maxFileSize: number

  constructor(d: FileUploaderConstructor) {
    this._props = {
      request: d.request,
      onUpload: d.onUpload,
      onSuccess: d.onSuccess,
      onFail: d.onFail,
      onInvalid: d.onInvalid,
      onAllComplete: d.onAllComplete,
      inputName: d.inputName,
      processForm: d.processForm,
      maxFileSize: d.maxFileSize,
      imageMaxSizeMB: d.imageMaxSizeMB,
      imageMaxWidthOrHeight: d.imageMaxWidthOrHeight,
      onLoadingChange: d.onLoadingChange
    }
    this._formContainer = new FormContainer(d.inputName || '', this._upload, this._multipleUpload, d.accept, d.multiple)
    this._reqs = new Map()
    this._canceled = []
    this._maxFileSize = d.maxFileSize || 10 * 1024 * 1024
  }

  openDialog = () => {
    this._formContainer.click()
  }

  destroy() {
    this._formContainer.remove()
  }

  cancel(lastModified: number) {
    if (this._reqs) {
      this._canceled.push(lastModified)
      this._reqs.get(lastModified).abort()
    }
  }

  _multipleUpload = async (files: FileList) => {
    if (files) {
      this._multipleUploadProcess(files, 0)
    }
  }

  _multipleUploadProcess = async (files: FileList, i: number) => {
    if (files[i]) {
      const onNext = () => {
        this._multipleUploadProcess(files, i + 1)
      }

      if (files[i + 1]) {
        this._upload(files[i], onNext)
      } else {
        this._upload(files[i])
      }
    }
  }

  _upload = async (file: File, onNext?: () => void) => {
    if (file.size > this._maxFileSize) {
      this._props.onInvalid?.('size')
      return
    }

    let result: File = file

    this._props.onLoadingChange?.(true)

    if (this._props.imageMaxSizeMB || this._props.imageMaxWidthOrHeight) {
      try {
        const output = await imageCompression(file, {
          maxSizeMB: this._props.imageMaxSizeMB,
          maxWidthOrHeight: this._props.imageMaxWidthOrHeight,
          useWebWorker: true
        })

        result = new File([output], file.name, { lastModified: file.lastModified, type: file.type })
      } catch (err) {
        console.warn(err)
        this._props.onLoadingChange?.(false)
      }
    }

    const f: File = { ...result, id: undefined, name: file.name, lastModified: file.lastModified }

    let formData: FormData

    if (this._props.processForm) {
      formData = this._props.processForm(this._formContainer.getForm())
    } else {
      formData = new FormData(this._formContainer.getForm())
    }

    formData.set('file', result)

    const req = this._props.request(formData)

    req
      .then((id) => {
        f.id = id
        this._props.onLoadingChange?.(false)
        this._props.onSuccess?.(f)

        if (onNext) {
          onNext?.()
        } else {
          this._clear(f)
        }
      })
      .catch((error) => {
        this._props.onLoadingChange?.(false)

        if (this._canceled.indexOf(f.lastModified) < 0) {
          this._props.onFail?.(result, error)
        }
        this._clear(f)
      })

    this._reqs.set(f.lastModified, req)

    if (typeof this._props.onUpload === 'function') {
      this._props.onUpload(f)
    }
  }

  _clear = (f: File) => {
    this._reqs.delete(f.lastModified)

    if (!this._reqs.size) {
      this._formContainer.clearInput()

      if (typeof this._props.onAllComplete === 'function') {
        this._props.onAllComplete()
      }
    }
  }
}

class FormContainer {
  _props: {
    _upload: (file: File) => void
    _multipleUpload: (files: FileList) => void
  }
  _form: HTMLFormElement
  _input: HTMLInputElement

  _multiple = false

  constructor(
    inputName: string,
    _upload: (file: globalThis.File) => void,
    _multipleUpload: (files: FileList) => void,
    accept?: string,
    multiple?: boolean
  ) {
    this._props = { _upload, _multipleUpload }
    this._form = this._createForm()
    this._input = this._createInput(inputName, accept, multiple)
    this._multiple = multiple || false
    this._form.appendChild(this._input)

    document.body.appendChild(this._form)

    this._handleChange = this._handleChange.bind(this)
    this._input.addEventListener('change', this._handleChange, false)
  }

  click() {
    this._input.click()
  }

  remove() {
    this._input.removeEventListener('change', this._handleChange)
    this._form.remove()
  }

  clearInput() {
    this._input.value = ''
  }

  getForm() {
    return this._form
  }

  _createForm() {
    const form = document.createElement('form')
    form.hidden = true

    return form
  }

  _createInput(inputName: string, accept?: string, multiple?: boolean) {
    const input = document.createElement('input')
    input.type = 'file'
    input.name = inputName
    input.multiple = multiple || false
    if (accept) input.accept = accept

    return input
  }

  _handleChange() {
    if (this._input.files && !this._multiple) {
      this._props._upload(this._input.files[0])
    } else {
      const files = this._input.files
      if (files) {
        this._props._multipleUpload(files)
      }
    }
  }
}

export default FileUploader
