import { useState, CSSProperties, useEffect, ReactNode } from 'react'
import css from './warning-notification.module.scss'
import { IconButton } from '@mui/material'
import NavigationClose from '@mui/icons-material/Close'
import Card from 'shared/ui-kit/card'

type Props = {
  isDisplay: boolean
  style?: CSSProperties
  children: ReactNode
}

function WarningNotificationComponent(props: Props) {
  const { isDisplay, style, children } = props
  const [show, setShow] = useState(isDisplay)

  useEffect(() => {
    setShow(isDisplay)
  }, [isDisplay])

  if (!show) {
    return null
  }

  return (
    <Card className={css.rootContainer} style={style}>
      <div className={css.content}>{children}</div>
      <div className={css.actions}>
        <IconButton onClick={() => setShow(false)}>
          <NavigationClose />
        </IconButton>
      </div>
    </Card>
  )
}

export const WarningNotification = WarningNotificationComponent
export default WarningNotificationComponent
