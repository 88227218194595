import { AlertMessage, InfoMessage } from './styled'
import { TextFieldMessageProps, TextFieldMessageTypes } from './types'

function TextFieldMessage({ type, displayMessage, children }: TextFieldMessageProps) {
  const display = displayMessage !== undefined ? displayMessage : true

  if (!display) {
    return null
  }

  switch (type) {
    case TextFieldMessageTypes.error:
      return <AlertMessage>{children}</AlertMessage>
    case TextFieldMessageTypes.info:
      return <InfoMessage>{children}</InfoMessage>
    case TextFieldMessageTypes.custom:
      return <div>{children}</div>
    default:
      return <div>{children}</div>
  }
}

TextFieldMessage.displayName = 'TextFieldMessage'

export { TextFieldMessage }
