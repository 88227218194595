import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { Card, CircularProgress } from '@mui/material'
import { monitoring as monitoringApi, line as lineApi, checkpoint as checkpointApi } from 'shared/api'
import {
  BasicPositionModel,
  CheckpointModel,
  LineInactiveState,
  LineModel,
  LineMonitoringModel,
  ParentPlaceAndLineModel,
  PositionModel,
  PositionState,
  PositionType,
  TimeslotModel
} from 'shared/models'
import { useTimeZoneFilter } from 'features/time-zone-filter'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import debounce from 'lodash/debounce'
import { storeUrl } from 'shared/utils/history'
import { CenterContainer } from 'shared/ui-kit/center-container'
import { MonitoringRoot, MonitoringBar, NotActiveContainer, InactiveWrapper } from './styled'
import { filterPositionsByType, filterPositionsByStatus } from 'shared/utils/positions_filters'
import { filterPositionsByService, filterPositionsByTerm } from 'shared/utils/positions_filters'
import { filterPositionsByQueueType, PositionQueueType } from 'shared/utils/positions_filters'
import { simplifyPositionToFullPositionConvert } from 'features/position/position-converter'
import Container from 'shared/ui-kit/container'
import { MobileStatisctics } from './components/mobile-statistics'
import { InactiveReason } from './components/inactive-reason'
import { MonitoringFilters, defaultFilters } from './utils'
import { LineMonitoringBar } from './components/bar'
import { PoisitionIsNotFound } from './components/not-found-position'
import { called } from './utils'
import { useLocation, useParams } from 'react-router'
import { useAppDispatch } from 'store'
import { LineMonitoringPositions } from './components/positions'
import { FilterByParticipants } from './components/filter-by-participants'
import { PersonFilter } from './components/types'
import { hideTopBar, showTopBar } from 'store/reducers/main-layout-reducer'
import { OverchargedQuadraticOccupancyProgress } from './components/overcharged-progress'
import { isUnreachPosition } from 'features/position-dumb/helpers'

function LineMonitoring() {
  const [filtersByParticipants, setFiltersByParticipants] = useState<PersonFilter[]>([])

  const [nowTime, setNowTime] = useState<number>(Date.now())
  const [lastUpdateDate, setLastUpdateDate] = useState<number>(Date.now())
  const [serverTime, setServerTime] = useState<number>(Date.now())

  const { lineId, placeId } = useParams<{ lineId: string; placeId: string }>()
  const storageFilters = sessionStorage.getItem(`MONITORING_filters_${lineId}`)
  const filterParsed: MonitoringFilters | null = storageFilters ? JSON.parse(storageFilters) : null

  const { tr, locale } = useTranslation()

  useEffect(() => {
    const interval = setInterval(() => {
      setNowTime(Date.now())
    }, 20000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  useTimeZoneFilter(placeId)

  const dispatch = useAppDispatch()

  const location = useLocation()

  const [monitoring, setMonitoring] = useState<LineMonitoringModel>()
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState<MonitoringFilters>(filterParsed || defaultFilters)
  const [termValue, setTermValue] = useState<string>(filterParsed?.termToFilter || defaultFilters?.termToFilter || '')
  const [line, setLine] = useState<LineModel>()
  const [checkpoints, setCheckpoints] = useState<CheckpointModel[]>()
  const [lineCheckpoints, setLineCheckpoints] = useState<CheckpointModel[]>([])
  const [positionQueueTypeValues, setPositionQueueTypeValues] = useState<any[]>([])
  const [smallScreen, setSmallScreen] = useState(false)
  const [waitingPositionIds, setWaitingPositionIds] = useState<(number | string)[]>([])

  const [searchActive, setSearchActive] = useState(false)

  const barRef = useRef<HTMLDivElement>(null)
  const connect = useRef<any>()
  const breakpoint = useRef<number>(0)

  const debounceTermChange = useCallback(
    debounce((value) => setFilter((draft) => ({ ...draft, termToFilter: value })), 300),
    []
  )

  function handleTermChange(value: string) {
    setTermValue(value)
    debounceTermChange(value)
  }

  const uniqueTypesFromPositions: PositionType[] = useMemo(
    () =>
      (monitoring?.positions || []).reduce((prev, acc) => {
        if (prev.indexOf(acc.type) === -1) {
          return [...prev, acc.type]
        }

        return prev
      }, [] as PositionType[]),
    [monitoring]
  )

  const modesAvailableForFiltering = useMemo(
    () =>
      !monitoring
        ? []
        : Object.values(PositionType).filter(
            (ptv) => !!uniqueTypesFromPositions.find((utfp) => utfp.toLowerCase() === ptv.toLowerCase())
          ),
    [uniqueTypesFromPositions]
  )

  useEffect(() => {
    resizeHandler()
  }, [loading])

  const resizeHandler = useCallback(
    debounce(() => {
      if (!barRef.current) {
        return
      }

      setSmallScreen((smallScreen) => {
        if (barRef.current && barRef.current.clientWidth < barRef.current.scrollWidth && !smallScreen) {
          breakpoint.current = barRef.current.scrollWidth
          return true
        }

        if (barRef.current && barRef.current.clientWidth > breakpoint.current && smallScreen) {
          breakpoint.current = barRef.current.scrollWidth
          return false
        }

        return smallScreen
      })
    }, 100),
    []
  )

  useEffect(() => {
    sessionStorage.setItem(`MONITORING_filters_${lineId}`, JSON.stringify(filter))
  }, [filter])

  useEffect(() => {
    window.addEventListener('resize', resizeHandler)
    storeUrl(location.pathname)

    return () => {
      window.removeEventListener('resize', resizeHandler)
      window.document.title = tr.common.title
      connect.current?.stop?.()
    }
  }, [])

  useEffect(() => {
    initData()
  }, [locale])

  function initData() {
    setLoading(true)

    Promise.all([
      lineApi.getLine(lineId, placeId),
      checkpointApi.getCheckpointList(placeId),
      monitoringApi.getLineMonitoring({ placeId, lineId })
    ])
      .then(([line, checkpoint, monitoring]) => {
        positionQueueFilterCalculate(line.data)
        setLastUpdateDate(new Date().getTime())
        setServerTime(monitoring.data.serverNow || new Date().getTime())
        setMonitoring(monitoring.data)
        setLine(line.data)
        setCheckpoints(checkpoint?.data || [])
        setLineCheckpoints(checkpoint?.data?.filter((c) => String(c.lineId) === String(lineId)) || [])
        setLoading(false)
        handleBreadcrumbs(line?.parents)
      })
      .finally(connectDataUpdate)
  }

  function connectDataUpdate() {
    if (!placeId || !lineId) {
      return
    }

    connect.current = monitoringApi.monitoringPositionsChanged(
      { placeId, lineId },
      (mtr) => {
        setWaitingPositionIds([])
        setLastUpdateDate(new Date().getTime())
        setServerTime(mtr.serverNow || new Date().getTime())

        if (mtr.positions) {
          const joinedPositionsCount = getPositionsByState(mtr.positions, PositionState.Joined).length
          const otherPositionsCount = mtr.positions.length - joinedPositionsCount
          const lineName = line?.name || ''

          document.title =
            mtr.waitingTime < 1440
              ? `${otherPositionsCount}/${joinedPositionsCount}/${mtr.waitingTime}' ${lineName}`
              : `${otherPositionsCount}/${joinedPositionsCount}' ${lineName}`
        }

        setMonitoring(mtr)
      },
      () => {
        Promise.all([lineApi.getLine(lineId, placeId), checkpointApi.getCheckpointList(placeId, lineId)]).then(
          ([line, checkpoint]) => {
            positionQueueFilterCalculate(line.data)
            setLine(line.data)
            setCheckpoints(checkpoint.data || [])
            setLineCheckpoints(checkpoint?.data?.filter((c) => String(c.lineId) === String(lineId)) || [])
          }
        )
      }
    )
  }

  function positionQueueFilterCalculate(line: LineModel) {
    let positionQueueTypeValues = Object.values(PositionQueueType)

    if (line && !line.displayInPlace) {
      positionQueueTypeValues = positionQueueTypeValues.filter(
        (x) => x !== PositionQueueType.inLineHere && x !== PositionQueueType.queue
      )
    }

    if (line && !line.displayPositionValidate) {
      positionQueueTypeValues = positionQueueTypeValues.filter(
        (x) => x !== PositionQueueType.isValid && x !== PositionQueueType.isNotValid
      )
    }

    setPositionQueueTypeValues(positionQueueTypeValues)
  }

  function handleBreadcrumbs(parents?: ParentPlaceAndLineModel) {
    if (!parents) {
      return
    }

    const crumb = getCrumbs(tr.breadcrumbs)

    const { shop, line } = parents
    const placeId = shop?.id
    const shopName = shop?.name
    const lineId = line?.id
    const lineName = line?.name

    dispatch(
      setBreadcrumbs([
        crumb.home(),
        crumb.places(),
        crumb.place([String(placeId)], shopName),
        crumb.lines([String(placeId)]),
        crumb.line([String(placeId), String(lineId)], lineName),
        crumb.lineMonitoring([String(placeId), String(lineId)])
      ])
    )
  }

  function filterPositions(positions: PositionModel[]) {
    const prefiltered = filterPositionsByQueueType(positions, filter.positionQueueTypeSelected, positionQueueTypeValues)
    const filteredOne = filterPositionsByStatus(prefiltered, filter.positionStatusesSelected)
    const filteredTwo = filterPositionsByService(filteredOne, filter.positionServicesSelected)
    const filteredThree = filterPositionsByTerm(filteredTwo, filter.termToFilter)

    const filteredFour = filterPositionsByType(
      filteredThree,
      filter.positionTypeSelected.filter((pts) => modesAvailableForFiltering.find((st) => st === pts))
    )

    return filteredFour
  }

  function getPositionsByState(positions: BasicPositionModel[], state: PositionState) {
    if (!positions || !state || !positions.length) {
      return []
    }

    return positions.filter((position) => position.state === state)
  }

  const handleRemove = useCallback((positionId?: string | number) => {
    setMonitoring((draft) => {
      if (!draft) {
        return draft
      }

      const newMonitoring = { ...draft }

      newMonitoring.positions = (newMonitoring.positions || []).filter((dataPosition) => dataPosition.id !== positionId)

      return newMonitoring
    })
  }, [])

  const handleStateChange = useCallback((positionId: string | number, newState: PositionState) => {
    setMonitoring((draft) => {
      if (!draft) {
        return draft
      }

      const newPositions = [...(draft?.positions || [])]

      newPositions.forEach((p) => {
        if (p.id === positionId) {
          p.state = newState
        }
      })

      return { ...draft, positions: newPositions }
    })

    called.push(positionId)
  }, [])

  function handleSearchInputBlur() {
    setSearchActive(false)
    dispatch(showTopBar())
  }

  function handleSearchInputFocus() {
    if (smallScreen) {
      setSearchActive(true)
      dispatch(hideTopBar())
    }
  }

  const convertedPositions = useMemo(() => {
    const timeDiff = Date.now() - lastUpdateDate

    return (monitoring?.positions || []).map((position) => {
      const timeSlot: TimeslotModel | undefined = position.timeSlot_from
        ? ({ from_unixtime: position.timeSlot_from } as TimeslotModel)
        : undefined

      return simplifyPositionToFullPositionConvert(
        position,
        line?.services || [],
        serverTime + timeDiff,
        tr,
        timeSlot,
        checkpoints
      )
    })
  }, [monitoring?.positions, lastUpdateDate, serverTime, nowTime])

  const filteredPositions = useMemo(() => {
    const isPersonFilter = (filtersByParticipants || []).filter((el) => el.isSelected)

    if (isPersonFilter?.length) {
      return (convertedPositions || []).filter((position) => {
        return isPersonFilter
          .map(
            (f) =>
              f.personCount === (position.personsQuantity || 1) ||
              (f.andMore && f.personCount < (position.personsQuantity || 0))
          )
          .some((el) => !!el)
      })
    }

    return filterPositions(convertedPositions)
      .slice()
      .sort((a, b) => (a.sortOrderIndex || 0) - (b.sortOrderIndex || 0))
  }, [convertedPositions, filter, filtersByParticipants])

  const positionsIsNotFound = useMemo(
    () => filteredPositions.length === 0 && !!filter.termToFilter,
    [filteredPositions]
  )

  const inactiveMonitoring = useMemo(
    () => !!monitoring && monitoring.lineIsActive === false && !monitoring.positions?.length,
    [monitoring]
  )

  const filtersByParticipantsIsSelected = (filtersByParticipants || []).filter((el) => el.isSelected).length > 0

  const simplifyMode = searchActive && smallScreen

  const isOccupancyOvercharged = monitoring?.overchargedQuadraticOccupancyPercent !== undefined

  const waitingStatuses = [
    PositionState.InLineHere,
    PositionState.ValidForService,
    PositionState.Joined,
    PositionState.InLine
  ]

  const waitingInPlacePositionsCount =
    convertedPositions?.filter((p) => !!p.inPlaceSince && waitingStatuses.includes(p.state) && !isUnreachPosition(p))
      ?.length || 0

  if (loading && !monitoring) {
    return (
      <CenterContainer>
        <CircularProgress color="secondary" />
      </CenterContainer>
    )
  }

  if (!monitoring || !line) {
    return null
  }

  return (
    <MonitoringRoot>
      <MonitoringBar $smallScreen={smallScreen} $top={simplifyMode ? 0 : 64} ref={barRef}>
        <LineMonitoringBar
          smallScreen={smallScreen}
          shopId={placeId}
          lineId={lineId}
          checkpoints={lineCheckpoints}
          modesAvailableForFiltering={modesAvailableForFiltering}
          positionQueueTypeValues={positionQueueTypeValues}
          filters={filter}
          filtersInactive={filtersByParticipantsIsSelected}
          termValue={termValue}
          setFilters={setFilter}
          handleTermChange={handleTermChange}
          line={line}
          onSearchInputBlur={handleSearchInputBlur}
          onSearchInputFocus={handleSearchInputFocus}
          simplifyMode={simplifyMode}
          positions={convertedPositions}
          waitingTime={monitoring.waitingTime}
          calledPositionsCount={monitoring.calledPositionsCount}
          finishing={monitoring.finishing}
          impossiblePositionsCount={monitoring.impossiblePositionsCount}
          inServicePositionsCount={monitoring.inServicePositionsCount}
          overcharged={monitoring.overcharged}
          registeredPositionsCount={monitoring.registeredPositionsCount}
          waitingInPlacePositionsCount={waitingInPlacePositionsCount}
          waitingPositionsCount={monitoring.waitingPositionsCount}
        />
      </MonitoringBar>
      {!smallScreen && monitoring.overchargedQuadraticOccupancyPercent !== undefined && (
        <Container style={{ padding: '0.5rem' }}>
          <Card style={{ padding: '0.5rem 1rem' }}>
            <OverchargedQuadraticOccupancyProgress
              quadraticOccupancyPercent={monitoring.overchargedQuadraticOccupancyPercent}
            />
          </Card>
        </Container>
      )}
      {!simplifyMode && !inactiveMonitoring && smallScreen && (
        <Container style={{ padding: '0.5rem' }}>
          <MobileStatisctics
            waitingTime={monitoring.waitingTime}
            calledPositionsCount={monitoring.calledPositionsCount}
            finishing={monitoring.finishing}
            impossiblePositionsCount={monitoring.impossiblePositionsCount}
            inServicePositionsCount={monitoring.inServicePositionsCount}
            overcharged={monitoring.overcharged}
            overchargedQuadraticOccupancyPercent={monitoring.overchargedQuadraticOccupancyPercent}
            registeredPositionsCount={monitoring.registeredPositionsCount}
            waitingInPlacePositionsCount={waitingInPlacePositionsCount}
            waitingPositionsCount={monitoring.waitingPositionsCount}
          />
        </Container>
      )}
      {!simplifyMode && !inactiveMonitoring && !!line?.displayFilterByParticipantsNumber && (
        <FilterByParticipants
          positions={monitoring?.positions || []}
          displayFilterByParticipantsUpperLimit={line?.displayFilterByParticipantsUpperLimit}
          displayFilterByParticipantsResetInSeconds={line?.displayFilterByParticipantsResetInSeconds}
          onChange={setFiltersByParticipants}
        />
      )}
      <Container
        style={{
          paddingTop: '0.5rem',
          marginTop: simplifyMode || (!smallScreen && !isOccupancyOvercharged) ? '0.5rem' : undefined
        }}
      >
        {inactiveMonitoring && (
          <NotActiveContainer>
            <div>{tr.lineMonitoring.close}</div>
            {monitoring.inactiveReason && (
              <InactiveWrapper>
                <InactiveReason
                  reason={monitoring.inactiveReason as LineInactiveState}
                  isSingleCheckpoint={lineCheckpoints.length === 1}
                  shopId={placeId}
                  lineId={lineId}
                  checkpointId={lineCheckpoints[0].id}
                />
              </InactiveWrapper>
            )}
          </NotActiveContainer>
        )}
        {!inactiveMonitoring && positionsIsNotFound && (
          <PoisitionIsNotFound term={filter.termToFilter} shopId={placeId} lineId={lineId} />
        )}
        {!inactiveMonitoring && !positionsIsNotFound && (
          <LineMonitoringPositions
            positions={filteredPositions}
            line={line}
            checkpoints={lineCheckpoints || []}
            onRemove={handleRemove}
            onStateChange={handleStateChange}
            setWaitingPositionIds={setWaitingPositionIds}
            waitingPositionIds={waitingPositionIds}
          />
        )}
      </Container>
    </MonitoringRoot>
  )
}

export default LineMonitoring
