import { useEffect } from 'react'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { HomeTiles } from './components/home-tiles'
import { HomePlaces } from './components/home-places'
import { HomePlace } from './components/home-place'
import { useTranslation } from 'shared/i18n/translate'
import Container from 'shared/ui-kit/container'
import { getGroupPlacesSelector, getSinglePlaceSelector } from 'store/selectors/userSelectors'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { useAppDispatch, useAppSelector } from 'store'

function Home() {
  const isMultiplePlaces = useAppSelector(getGroupPlacesSelector)
  const isSinglePlace = useAppSelector(getSinglePlaceSelector)
  const permissions = useAppSelector(getPermissionsSelector)
  const dispatch = useAppDispatch()
  const { tr } = useTranslation()

  useEffect(() => {
    const crumb = getCrumbs(tr.breadcrumbs)
    dispatch(setBreadcrumbs([crumb.home()]))
  }, [tr])

  const homePageSkip =
    isSinglePlace &&
    !permissions.manageReports &&
    !permissions.manageCampaigns &&
    !permissions.canManageUserAccounts &&
    !permissions.manageMessagesTemplates &&
    !permissions.manageTranslations

  return (
    <Container>
      {homePageSkip && <HomePlace />}
      {!homePageSkip && !isMultiplePlaces && <HomePlaces />}
      {!homePageSkip && <HomeTiles />}
    </Container>
  )
}

export default Home
