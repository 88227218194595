import { useState, useEffect } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { place as placeApi, line as lineAPI } from 'shared/api'
import ExportDataForm from './export-data/form'
import { useDataProvider } from 'features/isolated-data-provider'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { CenterContainer } from 'shared/ui-kit/center-container'
import { CircularProgress } from '@mui/material'
import { useParams } from 'react-router'
import { TemplateModel, LineModelSimplified } from 'shared/models'
import Container from 'shared/ui-kit/container'
import EmptyScreen from 'shared/ui-kit/empty-screen'
import { useTitle } from 'shared/hooks/useTitle'
import { useAppDispatch } from 'store'
import { useTimeZoneFilter } from 'features/time-zone-filter'

function SmartExportData() {
  const { getPlace } = useDataProvider()
  const [templates, setTemplates] = useState<TemplateModel[] | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [lines, setLines] = useState<LineModelSimplified[]>([])
  const [linesIsFetching, setLineIsFetching] = useState(true)
  const { tr } = useTranslation()
  const dispatch = useAppDispatch()
  const { placeId } = useParams<{ placeId: string }>()

  const shop = getPlace(placeId)

  useTitle({ placeName: getPlace(placeId)?.name })

  useTimeZoneFilter(placeId)

  function getBreadcrumbs() {
    const crumb = getCrumbs(tr.breadcrumbs)

    return [crumb.home(), crumb.places(), crumb.place([placeId], shop?.name), crumb.exportData([placeId])]
  }

  useEffect(() => {
    dispatch(setBreadcrumbs(getBreadcrumbs()))
  }, [shop])

  useEffect(() => {
    getTemplates()
    getLines()
  }, [])

  function handleRefetch() {
    getTemplates()
    getLines()
  }

  async function getLines() {
    try {
      setLineIsFetching(true)
      const res = await lineAPI.getLineList(placeId)
      setLines(res.data)
    } finally {
      setLineIsFetching(false)
    }
  }

  async function getTemplates() {
    setLoading(true)
    try {
      const templates = await placeApi.getExportTemplates({ placeId })
      setTemplates(templates)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  async function handleExport(data) {
    const exportData = { ...data, shopId: placeId }
    await placeApi.exportDataFile(exportData)
  }

  if (!lines || loading || linesIsFetching) {
    return (
      <CenterContainer>
        <CircularProgress color="secondary" />
      </CenterContainer>
    )
  }

  if (!templates || templates.length < 1) {
    return <EmptyScreen text={tr.exportData.templatesNotFound} />
  }

  return (
    <Container>
      <ExportDataForm
        lines={lines}
        templates={templates}
        onExport={handleExport}
        placeId={placeId}
        onRefetch={handleRefetch}
      />
    </Container>
  )
}

export default SmartExportData
