import { CSSProperties } from 'react'
import { Theme, useTheme } from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning'

type Props = {
  message: string
  color?: keyof Pick<Theme['palette'], 'primary' | 'secondary' | 'warning' | 'error'>
}

function AttentionMessage({ color = 'primary', message }: Props) {
  const { palette } = useTheme()

  const styleColor = palette[color].main

  const style: CSSProperties = {
    border: 'solid 2px #db214c',
    borderColor: styleColor,
    padding: '1rem',
    borderRadius: '4px',
    marginBottom: '1rem',
    color: styleColor,
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center'
  }

  const style2: CSSProperties = { marginLeft: '1rem' }

  return (
    <div style={style}>
      <WarningIcon />
      <div style={style2}>{message}</div>
    </div>
  )
}

export { AttentionMessage }
