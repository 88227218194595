import { Divider } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import PositionActionComponent from '../../components/position-action'
import AppointmentsIcon from '@mui/icons-material/EventAvailable'
import { BookingActionProps } from './types'

function NewBooking(props: BookingActionProps) {
  const { tr } = useTranslation()

  return (
    <>
      {!props.isCardView && <Divider />}
      <PositionActionComponent
        isCardView={props.isCardView}
        onSubmit={props.onSubmit}
        icon={<AppointmentsIcon fontSize="small" />}
      >
        {tr.positionActions.newBooking}
      </PositionActionComponent>
    </>
  )
}

export { NewBooking }
