import { Form, FormGroup } from 'shared/ui-kit/form'
import { TextField } from 'shared/ui-kit/text-field'
import Toggle from 'shared/ui-kit/toggle-wrap'
import { useTranslation } from 'shared/i18n/translate'
import { isPositiveInteger, isPositiveNumber } from 'shared/utils/string-test'
import { Controller } from 'react-hook-form'
import { GroupProps } from '../types'
import { WithHelper } from 'features/element-with-helper'
import Card from 'shared/ui-kit/card'

function MobileAppGroup({ control, setValue, trigger, watch }: GroupProps) {
  const { tr } = useTranslation()

  const {
    suggestOtherPlaces,
    displayWaitingTimeEstimationMobile,
    displayTotalPeopleInLineCount,
    displayPeopleBeforeMeCount,
    calculateApproachByGPS,
    nearServicePointDistance,
    nearServicePointDistanceHint,
    nearServicePointDistancePlaceholder,
    bluetoothRequired,
    displayReservationQRCode,
    beforeTheCall,
    beforeTheCallTime
  } = tr.lineEdit

  return (
    <Card>
      <Form>
        <FormGroup>
          <Controller
            name="displayWaitingTimeEstimationMobile"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={displayWaitingTimeEstimationMobile}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="displayWaitingTimeEstimationMobile"
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="displayTotalPeopleInLineCount"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={displayTotalPeopleInLineCount}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="displayTotalPeopleInLineCount"
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="beforeTheCall"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={beforeTheCall}
                onToggle={(_, val) => {
                  f.onChange(val)
                  setValue('beforeTheCallTime', 10)
                  trigger()
                }}
                withHelper={true}
                tag="line"
                tooltipKey="beforeTheCall"
              />
            )}
          />
        </FormGroup>
        {watch('beforeTheCall') && (
          <FormGroup>
            <WithHelper tooltipKey="beforeTheCallTime" tag="line">
              <Controller
                name="beforeTheCallTime"
                control={control}
                render={({ field: f, formState }) => (
                  <TextField
                    label={beforeTheCallTime}
                    placeholder={'0 min'}
                    value={f.value}
                    onChange={(val: any) => {
                      if (val !== '' && val !== undefined) {
                        f.onChange(val)
                      } else {
                        f.onChange(null)
                      }
                    }}
                    test={isPositiveInteger}
                    hint={() => ' min'}
                    attention={!!formState.errors.beforeTheCallTime}
                    fullWidth
                  />
                )}
              />
            </WithHelper>
          </FormGroup>
        )}
        <FormGroup>
          <Controller
            name="bluetoothRequired"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={bluetoothRequired}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="bluetoothRequired"
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="suggestOtherPlaces"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={suggestOtherPlaces}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="suggestOtherPlaces"
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="displayPeopleBeforeMeCount"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={displayPeopleBeforeMeCount}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="displayPeopleBeforeMeCount"
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="displayReservationQRCode"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={displayReservationQRCode}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="displayReservationQRCode"
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="calculateApproachByGPS"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={calculateApproachByGPS}
                onToggle={(_, val) => {
                  f.onChange(val)
                  setValue('nearServicePointDistance', 50)
                  trigger()
                }}
                withHelper={true}
                tag="line"
                tooltipKey="calculateApproachByGPS"
              />
            )}
          />
        </FormGroup>
        {watch('calculateApproachByGPS') && (
          <FormGroup>
            <WithHelper tooltipKey="nearServicePointDistance" tag="line">
              <Controller
                name="nearServicePointDistance"
                control={control}
                render={({ field: f }) => (
                  <TextField
                    label={nearServicePointDistance}
                    placeholder={nearServicePointDistancePlaceholder}
                    value={f.value}
                    onChange={f.onChange}
                    test={isPositiveNumber}
                    hint={nearServicePointDistanceHint}
                    fullWidth
                  />
                )}
              />
            </WithHelper>
          </FormGroup>
        )}
      </Form>
    </Card>
  )
}

export { MobileAppGroup }
