import Toggle from 'shared/ui-kit/toggle-wrap'
import { List } from 'shared/ui-kit/list'
import { useTranslation } from 'shared/i18n/translate'
import { CounterModel } from 'shared/models'
import { CounterListProps } from '../types'

function CounterList(props: CounterListProps) {
  const { tr, dateFormatter } = useTranslation()
  const { counters } = tr
  const { data, onSelect, onCounterActiveChange } = props

  function getSecondaryText(item: CounterModel) {
    const { serialNumber, lastSignalDate, type } = item
    const time = dateFormatter(lastSignalDate, 'dateTime')
    const { listLastSignalDate } = counters

    const text = [
      serialNumber && `${serialNumber}`,
      type && `, ${counters[type.toLowerCase()]}`,
      lastSignalDate && `, ${listLastSignalDate(time)}`
    ]

    return text.filter(Boolean).join('')
  }

  return (
    <List
      data={data}
      onSelect={onSelect}
      dataConverter={(item, i) => ({
        id: item.id,
        primaryText: item.name,
        secondaryText: getSecondaryText(item),
        controls: [
          <Toggle
            style={{ marginRight: 8 }}
            toggled={item.isActive}
            disabled={item.readonly}
            onToggle={() => onCounterActiveChange({ item, index: i })}
          />
        ]
      })}
    />
  )
}

export { CounterList }
