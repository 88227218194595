import styled from '@emotion/styled'

const QRContainer = styled.div<{ fullScreen?: boolean }>`
  position: fixed;
  right: 0;
  width: 100%;
  max-height: 400px;
  z-index: 999999;
  bottom: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow:
    0 0 6px rgb(0 0 0 / 12%),
    0 0 4px rgb(0 0 0 / 12%);
  border-radius: 0.5rem 0.5rem 0 0;
  overflow: hidden;
  transition: all 0.1s ease-in-out;
  transform: ${(props) => (props.fullScreen ? 'translateY(0)' : 'translateY(300px)')};

  @media (min-width: 650px) {
    width: 250px;
    max-height: 250px;
    border-radius: 0;
    transform: none;
  }
`

const QRWrapper = styled.div`
  width: 100%;

  @media (min-width: 650px) {
    width: 250px;
    height: 250px;
  }
`

const QRActions = styled.div`
  position: absolute;
  right: 4px;
  top: 4px;
  background: #ffffff40;
  border-radius: 1rem;
`

const QrMask = styled.div`
  position: absolute;
  z-index: 9999999;
  background: #ffffff40;
  border-radius: 0.5rem;
  padding: 0.5rem;
`

const ErrorMask = styled.div`
  position: absolute;
  z-index: 9999999;
  height: 140px;
  width: 140px;
  margin-top: -4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #ffffffd9;
  transition: all 0.1s ease-in-out;
`

const QRCenterWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffffad;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.16s;
`

export { QRContainer, QRWrapper, QRActions, QrMask, ErrorMask, QRCenterWrapper }
