import { CSSProperties } from 'react'
import { ThemeOptions } from '@mui/material/styles'
import { createTheme } from '@mui/material/styles'

export const primaryColor = '#db214d'

type PickerTheme = {
  overrides: {
    MuiPickersDay: {
      dayDisabled: CSSProperties
    }
  }
}

const muiThemeSettings: ThemeOptions & PickerTheme = {
  palette: {
    primary: {
      main: primaryColor
    },
    secondary: {
      main: primaryColor
    }
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          marginTop: 0
        }
      },
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#000000'
        }
      }
    },
    MuiButton: {
      defaultProps: {
        color: 'inherit',
        disableElevation: true
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 8
        },
        label: {
          paddingTop: 4,
          paddingBottom: 4
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&::before': {
            borderColor: 'rgba(0, 0, 0, .12)'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '0.5rem'
        },
        elevation1: {
          boxShadow: '0px 1px 1px 0px rgb(0 0 0 / 8%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard'
      }
    }
  },
  overrides: {
    MuiPickersDay: {
      dayDisabled: {
        color: 'rgb(0 0 0 / 11%)'
      }
    }
  }
}

const muiTheme = createTheme(muiThemeSettings)

export { muiTheme }
