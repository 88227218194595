import { Form } from 'shared/ui-kit/form'
import { Button, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import { useState } from 'react'
import { place as placeApi } from 'shared/api'
import { useAppDispatch } from 'store'
import { MessageTypes, showMessage } from 'store/actions/main-layout-action-creators'
import { RenameDialogProps } from './types'

export function RenameDialog({ template, onClose }: RenameDialogProps) {
  const { tr } = useTranslation()
  const dispatch = useAppDispatch()

  const [name, setName] = useState(template.text)
  const [loading, setLoading] = useState(false)

  function handleNameChange(ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setName(ev.target.value)
  }

  async function handleRenameReport() {
    try {
      setLoading(true)
      await placeApi.renameExportTemplate(template.value, name)
      dispatch(showMessage(tr.serverResponseMessage.success, MessageTypes.Success))
      onClose(true)
    } catch (err) {
      setLoading(false)
    }
  }

  function handleCloseDialog() {
    onClose()
  }

  return (
    <Form>
      <DialogTitle>{template.text}</DialogTitle>
      <DialogContent>
        <TextField fullWidth value={name} onChange={handleNameChange} label={tr.exportData.name} autoFocus />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={handleRenameReport}
          disabled={!name || loading}
        >
          {tr.exportData.save}
        </Button>
        <Button variant="outlined" size="small" onClick={handleCloseDialog} disabled={loading}>
          {tr.exportData.cancel}
        </Button>
      </DialogActions>
    </Form>
  )
}
