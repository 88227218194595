import LanguageSelectMultiple from './language-select-multiple'
import LanguageSelectSingle from './language-select-single'

export type MapValue<M> = M extends true ? string[] : string

export type LanguageSelectBaseProps<M extends boolean> = {
  languages: string[]
  ensureValue?: boolean
  hideSingle?: boolean
  title?: string
  value?: MapValue<M>
  onChange: (value: MapValue<M>) => void
}

type Props<M extends boolean> = {
  multiple?: M
} & LanguageSelectBaseProps<M>

function LanguageSelectComponent<M extends boolean>(props: Props<M>) {
  const { multiple = false, onChange } = props

  if (multiple) {
    return (
      <LanguageSelectMultiple
        {...props}
        value={props.value as string[]}
        onChange={(value) => onChange(value as MapValue<M>)}
      />
    )
  }

  return (
    <LanguageSelectSingle
      {...props}
      value={props.value as string}
      onChange={(value) => onChange(value as MapValue<M>)}
    />
  )
}

export default LanguageSelectComponent
export const LanguageSelect = LanguageSelectComponent
