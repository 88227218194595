import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, deleteToken, isSupported } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyA8FwWftXRV4HG1JYF0xUgn98AVNsGvIyQ',
  authDomain: 'jefile-90277.firebaseapp.com',
  databaseURL: 'https://jefile-90277.firebaseio.com',
  projectId: 'jefile-90277',
  storageBucket: 'jefile-90277.appspot.com',
  messagingSenderId: '312908282820',
  appId: '1:312908282820:web:fd69fee3f628b441904291',
  measurementId: 'G-E622723MCX'
}

let messaging

export async function getTokenAsync() {
  if (!messaging) {
    return Promise.reject()
  }

  return getToken(messaging, {
    vapidKey: 'BK84W9hhSo12-czmllSlqO8h9soPQMGt0JTxM2kw20GW4iedRiuaYxS9rZo58u0WoH7fW6qR4DzM1s_RzLXR6O8'
  })
}

export async function deleteTokenAsync() {
  if (!messaging) {
    return Promise.reject()
  }

  return deleteToken(messaging)
}

export async function prepare() {
  return new Promise((resolve, reject) => {
    if (!isPushNotificationSupported()) {
      return
    }

    isSupported()
      .then((res) => {
        if (!res) {
          return reject()
        }

        askUserPermission()
          .then(() => {
            const firebaseApp = initializeApp(firebaseConfig)
            messaging = getMessaging(firebaseApp)

            resolve(undefined)
          })
          .catch(reject)
      })
      .catch(reject)
  })
}

export function isPushNotificationSupported() {
  return 'serviceWorker' in navigator && 'PushManager' in window
}

export async function askUserPermission() {
  return new Promise((resolve, reject) => {
    const permissionResult = Notification.requestPermission((result) => {
      resolve(result)
    })

    if (permissionResult) {
      permissionResult.then(resolve, reject)
    }
  }).then((permissionResult) => {
    if (permissionResult !== 'granted') {
      throw new Error('Permission not granted.')
    }
  })
}
