let visible = false

export function isVisible() {
  return visible && document.visibilityState !== 'hidden'
}

window.addEventListener('blur', () => {
  visible = false
})

window.addEventListener('focus', () => {
  visible = true
})
