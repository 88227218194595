import { Form, FormGroup } from 'shared/ui-kit/form'
import { TextField } from 'shared/ui-kit/text-field'
import Dropdown from 'shared/ui-kit/dropdown'
import { useTranslation } from 'shared/i18n/translate'
import { AddList } from 'shared/ui-kit/add-list'
import Toggle from 'shared/ui-kit/toggle-wrap'
import { isValidPassword } from 'shared/utils/string-test'
import { TextFieldMessageTypes, TextFieldMessage } from 'shared/ui-kit/text-field'
import Card from 'shared/ui-kit/card'
import { Divider, Button, IconButton } from '@mui/material'
import { ShowHideToggle } from './components/show-hide-toggle'
import { QrGenerageDialog } from './components/qr-generage-dialog'
import { CanSeePositionType, LineModelSimplified, UserModel } from 'shared/models'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Accordion, AccordionTitle, PlaceListWrapper, PlaceName, lineListStyles } from './styled'
import { UserEditFormProps } from './types'
import { getUserEditValidationSchema } from './validation'
import { useAppSelector } from 'store'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { RemoveCircleOutline as RemoveIcon } from '@mui/icons-material'
import { addTestAttr } from 'shared/utils/test-attr'
import { userDuplicationDataConverter } from '../utils'
import { ActionText, ActionsWrapper, ButtonWithTextWrapper, ConfirmationActions } from 'shared/ui-kit/action-wrapper'
import DeleteIcon from '@mui/icons-material/Delete'
import QRIcon from '@mui/icons-material/QrCode'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DataArrayIcon from '@mui/icons-material/DataArray'
import { TemporaryCodeDialog } from './components/temporary-code-dialog'

function UserEdit(props: UserEditFormProps) {
  const { tr } = useTranslation()

  const permissions = useAppSelector(getPermissionsSelector)

  const [isConfirmDelete, setConfirmDelete] = useState(false)
  const [isConfirmDuplicate, setConfirmDuplicate] = useState(false)
  const [openQrDialog, setOpenQrDialog] = useState(false)
  const [openTemporaryAuthCodeDialog, setTemporaryAuthCodeDialog] = useState(false)
  const [currentPasswordShow, setCurrentPasswordShow] = useState(false)
  const [newPasswordShow, setNewPasswordShow] = useState(false)
  const [passwordShow, setPasswordShow] = useState(false)
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false)

  const { insecurePassword, isNew, isSelf } = props

  const { control, handleSubmit, formState, watch, trigger, setValue, getValues } = useForm<UserModel>({
    resolver: yupResolver(getUserEditValidationSchema({ insecurePassword, isNew, isSelf }) as any),
    defaultValues: props.data,
    mode: 'onChange'
  })

  useEffect(() => {
    trigger()
  }, [])

  function getLinesByShopId(shopId: number) {
    if (!shopId || !props.shops.find((x) => String(x.id) === String(shopId))) {
      return []
    }

    const lines = props.lines[shopId]

    if (!lines) {
      props.onRequestLines(shopId)
    }

    return (lines || []).map((line) => ({ ...line, id: Number(line.id) }))
  }

  function getLines(shopIds: number[] = []) {
    let lines: LineModelSimplified[] = []

    shopIds.forEach((sid) => {
      lines = lines.concat(getLinesByShopId(sid) || [])
    })

    return lines
  }

  function renderPasswordChange() {
    const {
      currentPassword,
      newPassword,
      password,
      confirmPassword,
      passwordPlaceholder,
      ruleSecurePasswordInputMessage
    } = tr.userEdit

    return (
      <>
        {!!props.isNew && (
          <FormGroup>
            <Controller
              name="generatePasswordAutomatically"
              control={control}
              render={({ field: f }) => (
                <Toggle
                  toggled={f.value}
                  disabled={!permissions.canCloseCheckpoints}
                  label={tr.userEdit.generatePasswordAutomatically}
                  onToggle={(_, val) => {
                    f.onChange(val)
                    setValue('newPassword', undefined)
                    setValue('password', undefined)
                    setValue('confirmPassword', undefined)
                    trigger()
                  }}
                />
              )}
            />
          </FormGroup>
        )}
        {props.isSelf && !watch('generatePasswordAutomatically') && (
          <FormGroup>
            <Controller
              name="currentPassword"
              control={control}
              render={({ field: f, formState }) => (
                <TextField
                  autocomplete="current-password"
                  id="current-password-input"
                  type={currentPasswordShow ? 'text' : 'password'}
                  label={currentPassword}
                  placeholder={passwordPlaceholder}
                  value={f.value}
                  onChange={(val) => {
                    f.onChange(val)
                    trigger()
                  }}
                  attention={!!formState.errors.currentPassword}
                  message={`${f.value?.length || 0}/16 ${tr.entityEdit.chars}`}
                  test={(v) => v.length < 17}
                  endAdornment={
                    <ShowHideToggle isVisible={currentPasswordShow} onToggle={(val) => setCurrentPasswordShow(val)} />
                  }
                  {...addTestAttr('UserEdit-CurrentPassword')}
                />
              )}
            />
          </FormGroup>
        )}
        {!props.isNew && !watch('generatePasswordAutomatically') && (
          <FormGroup>
            <Controller
              name="newPassword"
              control={control}
              render={({ field: f, formState }) => (
                <TextField
                  autocomplete="new-password"
                  type={newPasswordShow ? 'text' : 'password'}
                  label={newPassword}
                  placeholder={passwordPlaceholder}
                  value={f.value}
                  onChange={(val) => {
                    f.onChange(val)
                    trigger()
                  }}
                  attention={!!formState.errors.newPassword}
                  message={`${f.value?.length || 0}/16 ${tr.entityEdit.chars}`}
                  test={(v) => v.length < 17}
                  endAdornment={
                    <ShowHideToggle isVisible={newPasswordShow} onToggle={(val) => setNewPasswordShow(val)} />
                  }
                  {...addTestAttr('UserEdit-NewPassword')}
                >
                  <TextFieldMessage check={isValidPassword} type={TextFieldMessageTypes.error}>
                    {ruleSecurePasswordInputMessage}
                  </TextFieldMessage>
                </TextField>
              )}
            />
          </FormGroup>
        )}
        {props.isNew && !watch('generatePasswordAutomatically') && (
          <FormGroup>
            <Controller
              name="password"
              control={control}
              render={({ field: f, formState }) => (
                <TextField
                  autocomplete="password"
                  type={passwordShow ? 'text' : 'password'}
                  label={password}
                  placeholder={passwordPlaceholder}
                  value={f.value}
                  onChange={(val) => {
                    f.onChange(val)
                    trigger()
                  }}
                  attention={!!formState.errors.password}
                  message={`${f.value?.length || 0}/16 ${tr.entityEdit.chars}`}
                  test={(v) => v.length < 17}
                  endAdornment={<ShowHideToggle isVisible={passwordShow} onToggle={(val) => setPasswordShow(val)} />}
                  {...addTestAttr('UserEdit-Password')}
                >
                  <TextFieldMessage type={TextFieldMessageTypes.error} check={isValidPassword}>
                    {ruleSecurePasswordInputMessage}
                  </TextFieldMessage>
                </TextField>
              )}
            />
          </FormGroup>
        )}
        {!watch('generatePasswordAutomatically') && (
          <FormGroup>
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field: f, formState }) => (
                <TextField
                  autocomplete="new-password"
                  type={confirmPasswordShow ? 'text' : 'password'}
                  label={confirmPassword}
                  placeholder={passwordPlaceholder}
                  value={f.value}
                  onChange={(val) => {
                    f.onChange(val)
                    trigger()
                  }}
                  attention={!!formState.errors.confirmPassword}
                  message={`${f.value?.length || 0}/16 ${tr.entityEdit.chars}`}
                  test={(v) => v.length < 17}
                  endAdornment={
                    <ShowHideToggle isVisible={confirmPasswordShow} onToggle={(val) => setConfirmPasswordShow(val)} />
                  }
                  {...addTestAttr('UserEdit-ConfirmPassword')}
                />
              )}
            />
          </FormGroup>
        )}
      </>
    )
  }

  const {
    firstName,
    firstNamePlaceholder,
    secondName,
    secondNamePlaceholder,
    email,
    emailPlaceholder,
    description,
    descriptionPlaceholder,
    interfaceLanguage,
    shops,
    changePassword,
    rights,
    isAdmin,
    isActive,
    viewProfile,
    canUpdateParticipantsName,
    canUpdateParticipantsValues,
    userDuplicate,
    confirmDuplicate
  } = tr.userEdit

  const canUpdateParticipantsNameSelectValue = [
    { value: 'Any', text: canUpdateParticipantsValues.Any },
    { value: 'LimitedLinesOption', text: canUpdateParticipantsValues.LimitedLinesOption },
    { value: 'None', text: canUpdateParticipantsValues.None }
  ]

  function handleCopy() {
    const values = getValues()
    props.onCopy?.(userDuplicationDataConverter(values))
  }

  function handleTryDelete() {
    setConfirmDelete(true)
  }

  function handleCancelDelete() {
    setConfirmDelete(false)
  }

  function handleConfirmDelete() {
    props.onDelete?.(props.data.id)
  }

  const foundedProfile = props.data.profiles?.find((p) => p?.id === watch('profileId'))
  const qrGenerateAvailable = foundedProfile && foundedProfile.displayReducedInterface === true

  const selectedShops = (props.shops || []).filter(
    (shop) => !!watch('shops')?.find((id) => String(id.id) === String(shop?.id))
  )

  return (
    <>
      <Card>
        <form onSubmit={handleSubmit((data) => props.onSave({ ...data, email: (data.email || '').trim() }))}>
          {props.insecurePassword && renderPasswordChange()}
          {!props.insecurePassword && (
            <div>
              <FormGroup>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field: f, formState }) => (
                    <TextField
                      autocomplete="firstname"
                      label={firstName}
                      placeholder={firstNamePlaceholder}
                      autofocus={true}
                      value={f.value}
                      onChange={f.onChange}
                      attention={!!formState.errors.firstName}
                      message={`${f.value?.length || 0}/100 ${tr.entityEdit.chars}`}
                      test={(v) => v.length < 101}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Controller
                  name="secondName"
                  control={control}
                  render={({ field: f, formState }) => (
                    <TextField
                      autocomplete="lastname"
                      label={secondName}
                      placeholder={secondNamePlaceholder}
                      value={f.value}
                      onChange={f.onChange}
                      attention={!!formState.errors.secondName}
                      message={`${f.value?.length || 0}/100 ${tr.entityEdit.chars}`}
                      test={(v) => v.length < 101}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: f, formState }) => (
                    <TextField
                      autocomplete="email"
                      label={email}
                      placeholder={emailPlaceholder}
                      value={f.value}
                      onChange={f.onChange}
                      attention={!!formState.errors.email}
                      message={`${f.value?.length || 0}/200 ${tr.entityEdit.chars}`}
                      test={(v) => v.length < 201}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Controller
                  name="interfaceLocale"
                  control={control}
                  render={({ field: f }) => (
                    <Dropdown
                      value={f.value}
                      data={props.languages || []}
                      label={interfaceLanguage}
                      dataConverter={(item) => ({ value: item.locale, text: item.name })}
                      onChange={f.onChange}
                    />
                  )}
                />
              </FormGroup>
              {props.canEditRights && (
                <FormGroup>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field: f }) => (
                      <TextField
                        autocomplete="description"
                        id="description"
                        label={description}
                        placeholder={descriptionPlaceholder}
                        value={f.value}
                        onChange={f.onChange}
                      />
                    )}
                  />
                </FormGroup>
              )}
              <Divider />
              <Form
                title={!props.isNew ? changePassword : undefined}
                expandable={!props.isNew}
                {...addTestAttr('UserEdit-ChangePasswordFormHeader')}
              >
                {renderPasswordChange()}
              </Form>
              {props.canEditRights && (
                <>
                  <Divider />
                  <Form
                    title={rights}
                    expandable
                    attention={!watch('profileId') && !watch('isAdmin')}
                    {...addTestAttr('UserEdit-RightsFormHeader')}
                  >
                    {props.canManageUserAccounts && (
                      <FormGroup>
                        <Controller
                          name="isActive"
                          control={control}
                          render={({ field: f }) => (
                            <Toggle toggled={f.value} label={isActive} onToggle={(_, val) => f.onChange(val)} />
                          )}
                        />
                      </FormGroup>
                    )}
                    {props.canPromoteToAdmin && (
                      <FormGroup>
                        <Controller
                          name="isAdmin"
                          control={control}
                          render={({ field: f }) => (
                            <Toggle
                              toggled={f.value}
                              label={isAdmin}
                              onToggle={(_, val) => {
                                f.onChange(val)

                                setValue('profileId', null)
                                setValue('shops', [])

                                trigger()
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                    )}
                    {!watch('isAdmin') && props.canPromoteToAdmin && (
                      <FormGroup>
                        <Controller
                          name="canUpdateParticipantsName"
                          control={control}
                          render={({ field: f }) => (
                            <Dropdown
                              fullWidth
                              value={f.value}
                              data={canUpdateParticipantsNameSelectValue}
                              label={canUpdateParticipantsName}
                              dataConverter={(item) => ({ value: item.value, text: item.text })}
                              onChange={f.onChange}
                            />
                          )}
                        />
                      </FormGroup>
                    )}
                    {!watch('isAdmin') && props.canEditRights && (
                      <FormGroup>
                        <Controller
                          name="profileId"
                          control={control}
                          render={({ field: f, formState }) => (
                            <Dropdown
                              fullWidth
                              value={f.value}
                              data={props.data.profiles || []}
                              label={viewProfile}
                              attention={!!formState.errors.profileId}
                              dataConverter={(item) => ({ value: item.id, text: item.name })}
                              onChange={f.onChange}
                            />
                          )}
                        />
                      </FormGroup>
                    )}
                    {!watch('isAdmin') && props.canManageUserAccounts && (
                      <FormGroup style={{ marginTop: '2rem' }}>
                        <Accordion>
                          <AccordionTitle>{tr.userEdit.basicRights}</AccordionTitle>
                          <Controller
                            name="canOpenCheckpoints"
                            control={control}
                            render={({ field: f }) => (
                              <Toggle
                                toggled={f.value}
                                disabled={!permissions.canOpenCheckpoints}
                                label={tr.userEdit.canOpenCheckpoints}
                                onToggle={(_, val) => f.onChange(val)}
                              />
                            )}
                          />
                          <Controller
                            name="canCloseCheckpoints"
                            control={control}
                            render={({ field: f }) => (
                              <Toggle
                                toggled={f.value}
                                disabled={!permissions.canCloseCheckpoints}
                                label={tr.userEdit.canCloseCheckpoints}
                                onToggle={(_, val) => f.onChange(val)}
                              />
                            )}
                          />
                          <Controller
                            name="canViewPersonalInfo"
                            control={control}
                            render={({ field: f }) => (
                              <Toggle
                                toggled={f.value}
                                disabled={!permissions.canViewPersonalInfo}
                                label={tr.userEdit.canViewPersonalInfo}
                                onToggle={(_, val) => f.onChange(val)}
                              />
                            )}
                          />
                          <Controller
                            name="viewPositions"
                            control={control}
                            render={({ field: f }) => (
                              <Dropdown
                                value={f.value}
                                data={Object.entries(tr.userEdit.canSeePositionsVariants).map(([value, text]) => ({
                                  value,
                                  text
                                }))}
                                label={tr.userEdit.canSeePositions}
                                dataConverter={(item) => ({ text: item.text, value: item.value as CanSeePositionType })}
                                onChange={f.onChange}
                                disabled={
                                  permissions.viewPositions === 'None' || permissions.viewPositions === 'Suggested'
                                }
                              />
                            )}
                          />
                          <Controller
                            name="canAddAndEditPositions"
                            control={control}
                            render={({ field: f }) => (
                              <Toggle
                                toggled={f.value}
                                label={tr.userEdit.canAddAndEditPositions}
                                onToggle={(_, val) => f.onChange(val)}
                                disabled={!permissions.canAddAndEditPositions}
                              />
                            )}
                          />
                          <Controller
                            name="canDeletePositions"
                            control={control}
                            render={({ field: f }) => (
                              <Toggle
                                toggled={f.value}
                                label={tr.userEdit.canDeletePositions}
                                onToggle={(_, val) => f.onChange(val)}
                                disabled={!permissions.canDeletePositions}
                              />
                            )}
                          />
                          <Controller
                            name="canLoginFromMultipleDevices"
                            control={control}
                            render={({ field: f }) => (
                              <Toggle
                                toggled={f.value}
                                label={tr.userEdit.canLoginFromMultipleDevices}
                                onToggle={(_, val) => f.onChange(val)}
                              />
                            )}
                          />
                        </Accordion>
                        <Accordion>
                          <AccordionTitle>{tr.userEdit.advancedRights}</AccordionTitle>
                          <Controller
                            name="canAccessPlaceLineMonitoring"
                            control={control}
                            render={({ field: f }) => (
                              <Toggle
                                toggled={f.value}
                                label={tr.userEdit.canAccessPlaceLineMonitoring}
                                onToggle={(_, val) => f.onChange(val)}
                                disabled={!permissions.accessPlaceLineMonitoring}
                              />
                            )}
                          />
                          <Controller
                            name="manageAppointments"
                            control={control}
                            render={({ field: f }) => (
                              <Toggle
                                toggled={f.value}
                                label={tr.userEdit.manageAppointments}
                                onToggle={(_, val) => f.onChange(val)}
                                disabled={!permissions.manageAppointments}
                              />
                            )}
                          />
                          <Controller
                            name="canValidatePositions"
                            control={control}
                            render={({ field: f }) => (
                              <Toggle
                                toggled={f.value}
                                label={tr.userEdit.canValidatePositions}
                                onToggle={(_, val) => f.onChange(val)}
                                disabled={!permissions.canValidatePositions}
                              />
                            )}
                          />
                          <Controller
                            name="canCallInvalidPositions"
                            control={control}
                            render={({ field: f }) => (
                              <Toggle
                                toggled={f.value}
                                label={tr.userEdit.canCallInvalidPositions}
                                onToggle={(_, val) => f.onChange(val)}
                                disabled={!permissions.canCallInvalidPositions}
                              />
                            )}
                          />
                          <Controller
                            name="canAssignCustomServicePoints"
                            control={control}
                            render={({ field: f }) => (
                              <Toggle
                                toggled={f.value}
                                label={tr.userEdit.canAssignCustomServicePoints}
                                onToggle={(_, val) => f.onChange(val)}
                                disabled={!permissions.canAssignCustomServicePoints}
                              />
                            )}
                          />
                          <Controller
                            name="manageReports"
                            control={control}
                            render={({ field: f }) => (
                              <Toggle
                                toggled={f.value}
                                label={tr.userEdit.manageReports}
                                onToggle={(_, val) => f.onChange(val)}
                                disabled={!permissions.manageReports}
                              />
                            )}
                          />
                          <Controller
                            name="manageCampaigns"
                            control={control}
                            render={({ field: f }) => (
                              <Toggle
                                toggled={f.value}
                                label={tr.userEdit.manageCampaigns}
                                onToggle={(_, val) => f.onChange(val)}
                                disabled={!permissions.manageCampaigns}
                              />
                            )}
                          />
                          <Controller
                            name="manageTerminals"
                            control={control}
                            render={({ field: f }) => (
                              <Toggle
                                toggled={f.value}
                                label={tr.userEdit.manageTerminals}
                                onToggle={(_, val) => f.onChange(val)}
                                disabled={!permissions.manageTerminals}
                              />
                            )}
                          />
                          <Controller
                            name="manageMessagesTemplates"
                            control={control}
                            render={({ field: f }) => (
                              <Toggle
                                toggled={f.value}
                                label={tr.userEdit.manageMessagesTemplates}
                                onToggle={(_, val) => f.onChange(val)}
                                disabled={!permissions.manageMessagesTemplates}
                              />
                            )}
                          />
                          <Controller
                            name="manageTranslations"
                            control={control}
                            render={({ field: f }) => (
                              <Toggle
                                toggled={f.value}
                                label={tr.userEdit.manageTranslations}
                                onToggle={(_, val) => f.onChange(val)}
                                disabled={!permissions.manageTranslations}
                              />
                            )}
                          />
                          <Controller
                            name="canManageUserAccounts"
                            control={control}
                            render={({ field: f }) => (
                              <Toggle
                                toggled={f.value}
                                label={tr.userEdit.canManageUserAccounts}
                                onToggle={(_, val) => f.onChange(val)}
                                disabled={!permissions.canManageUserAccounts}
                              />
                            )}
                          />
                          <Controller
                            name="canAddAndDeleteShops"
                            control={control}
                            render={({ field: f }) => (
                              <Toggle
                                toggled={f.value}
                                label={tr.userEdit.canAddAndDeleteShops}
                                onToggle={(_, val) => f.onChange(val)}
                                disabled={!permissions.canAddAndDeleteShops}
                              />
                            )}
                          />
                          <Controller
                            name="canUpdateShops"
                            control={control}
                            render={({ field: f }) => (
                              <Toggle
                                toggled={f.value}
                                label={tr.userEdit.canUpdateShops}
                                onToggle={(_, val) => f.onChange(val)}
                                disabled={!permissions.canUpdateShops}
                              />
                            )}
                          />
                          <Controller
                            name="canCreateTemporaryAuthCode"
                            control={control}
                            render={({ field: f }) => (
                              <Toggle
                                toggled={f.value}
                                label={tr.userEdit.canCreateTemporaryAuthCode}
                                onToggle={(_, val) => f.onChange(val)}
                              />
                            )}
                          />
                        </Accordion>
                      </FormGroup>
                    )}
                  </Form>
                </>
              )}
              {props.canEditShops && !watch('isAdmin') && (
                <>
                  <Divider />
                  <Form
                    title={shops}
                    expandable
                    attention={!!formState?.errors?.shops}
                    {...addTestAttr('UserEdit-ShopsFormHeader')}
                  >
                    <FormGroup>
                      <Controller
                        name="shops"
                        control={control}
                        render={({ field: f }) => (
                          <AddList
                            label={tr.userEdit.shops}
                            emptyMessage={tr.common.emptyShopMessage}
                            useAutocomplete
                            selectedIds={(f.value || []).map((el) => Number(el.id))}
                            source={(props.shops || []).map((el) => ({ ...el, id: Number(el.id) }))}
                            addButtonText={tr.userEdit.addShopButton}
                            onChange={(ids) => {
                              f.onChange(
                                ids.map((id) => {
                                  return (
                                    (f.value || []).find((shop) => String(shop.id) === String(id)) || {
                                      id,
                                      allLines: true,
                                      lineIds: []
                                    }
                                  )
                                })
                              )
                            }}
                            renderSelectedItem={({ id, name }) => {
                              const foundedShopValue = (f.value || []).find((shop) => String(shop.id) === String(id))

                              return (
                                <div key={id}>
                                  <PlaceListWrapper>
                                    <PlaceName>{name}</PlaceName>
                                    <IconButton
                                      color="primary"
                                      onClick={() =>
                                        f.onChange((f.value || []).filter((shop) => String(shop.id) !== String(id)))
                                      }
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                  </PlaceListWrapper>
                                  <div style={{ padding: '0 1rem 0.5rem' }}>
                                    <Toggle
                                      toggled={foundedShopValue?.allLines || false}
                                      label={tr.userEdit.allLinesAvailable}
                                      style={{ marginBottom: '0.5rem' }}
                                      onToggle={(_, val) => {
                                        f.onChange(
                                          (f.value || []).map((shop) => {
                                            return String(shop.id) !== String(id)
                                              ? shop
                                              : { id: shop.id, allLines: val, lineIds: [] }
                                          })
                                        )
                                      }}
                                    />
                                    {!!foundedShopValue && !foundedShopValue.allLines && (
                                      <AddList
                                        label={tr.common.lines}
                                        emptyMessage={tr.common.emptyLineMessage}
                                        selectedIds={(foundedShopValue?.lineIds || []).filter((lid) =>
                                          getLines(f.value.map((shop) => shop.id))
                                            .map((l) => l.id)
                                            .includes(lid)
                                        )}
                                        source={getLinesByShopId(id)}
                                        style={lineListStyles}
                                        dropdownDataConverter={({ id, name }) => ({ value: id, text: name })}
                                        listDataConverter={({ id, name }) => ({ id, primaryText: name })}
                                        addButtonText={tr.userEdit.addLineButton}
                                        onChange={(val) => {
                                          f.onChange(
                                            (f.value || []).map((shop) => {
                                              return String(shop.id) !== String(id) ? shop : { ...shop, lineIds: val }
                                            })
                                          )
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              )
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Form>
                </>
              )}
            </div>
          )}
          {!props.isNew && !props.isSelf && (
            <>
              <Divider />
              <Form title={tr.entityEdit.advancedGroup} expandable>
                <FormGroup>
                  {!!permissions.canCreateTemporaryAuthCode && !props.isSelf && !props.insecurePassword && (
                    <ButtonWithTextWrapper>
                      <ActionText>{tr.userEdit.loginByTemporaryAuthCode}</ActionText>
                      <Button
                        onClick={() => setTemporaryAuthCodeDialog(true)}
                        variant="outlined"
                        size="small"
                        startIcon={<DataArrayIcon />}
                        {...addTestAttr('UserEdit-GenerateTemporaryAuthCode')}
                      >
                        {tr.userEdit.loginByTemporaryAuthCodeButton}
                      </Button>
                    </ButtonWithTextWrapper>
                  )}
                </FormGroup>
                <FormGroup>
                  {props.canPromoteToAdmin && !props.isSelf && !props.insecurePassword && (
                    <ButtonWithTextWrapper>
                      <ActionText>{tr.userEdit.loginByQrCode}</ActionText>
                      <Button
                        onClick={() => setOpenQrDialog(true)}
                        variant="outlined"
                        size="small"
                        disabled={!qrGenerateAvailable}
                        startIcon={<QRIcon />}
                        {...addTestAttr('UserEdit-GenerateQrButton')}
                      >
                        {tr.userEdit.generateQRButton}
                      </Button>
                    </ButtonWithTextWrapper>
                  )}
                </FormGroup>
                {!!props.canManageUserAccounts && (
                  <FormGroup>
                    {!isConfirmDuplicate && (
                      <ButtonWithTextWrapper>
                        <ActionText>{userDuplicate}</ActionText>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => setConfirmDuplicate(true)}
                          startIcon={<ContentCopyIcon />}
                          {...addTestAttr('PlaceEdit-DuplicateButton')}
                        >
                          {tr.entityEdit.duplicateButton}
                        </Button>
                      </ButtonWithTextWrapper>
                    )}
                    {isConfirmDuplicate && (
                      <ButtonWithTextWrapper>
                        <ActionText>{confirmDuplicate}</ActionText>
                        <ConfirmationActions>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={handleCopy}
                            {...addTestAttr('PlaceEdit-Yes')}
                          >
                            {tr.entityEdit.yes}
                          </Button>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => setConfirmDuplicate(false)}
                            {...addTestAttr('PlaceEdit-No')}
                          >
                            {tr.entityEdit.no}
                          </Button>
                        </ConfirmationActions>
                      </ButtonWithTextWrapper>
                    )}
                  </FormGroup>
                )}
                <FormGroup>
                  {!isConfirmDelete && (
                    <ButtonWithTextWrapper>
                      <ActionText>{tr.userEdit.deleteTitle}</ActionText>
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={handleTryDelete}
                        startIcon={<DeleteIcon />}
                        {...addTestAttr('UserEdit-DeleteButton')}
                      >
                        {tr.entityEdit.deleteButton}
                      </Button>
                    </ButtonWithTextWrapper>
                  )}
                  {isConfirmDelete && (
                    <ButtonWithTextWrapper>
                      <ActionText>{tr.entityEdit.confirmDelete}</ActionText>
                      <ConfirmationActions>
                        <Button
                          variant="contained"
                          color="error"
                          size="small"
                          onClick={handleConfirmDelete}
                          {...addTestAttr('UserEdit-Yes')}
                        >
                          {tr.entityEdit.yes}
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={handleCancelDelete}
                          {...addTestAttr('UserEdit-No')}
                        >
                          {tr.entityEdit.no}
                        </Button>
                      </ConfirmationActions>
                    </ButtonWithTextWrapper>
                  )}
                </FormGroup>
              </Form>
            </>
          )}
          {props.data.id && !props.isSelf && (
            <QrGenerageDialog
              open={openQrDialog}
              userId={props.data.id}
              onClose={() => setOpenQrDialog(false)}
              shops={selectedShops}
              firstName={watch('firstName')}
              secondName={watch('secondName')}
            />
          )}
          {props.data.id && !props.isSelf && (
            <TemporaryCodeDialog
              open={openTemporaryAuthCodeDialog}
              userId={props.data.id}
              onClose={() => setTemporaryAuthCodeDialog(false)}
              firstName={watch('firstName')}
              secondName={watch('secondName')}
            />
          )}
        </form>
      </Card>
      <ActionsWrapper>
        <Button
          disabled={!formState.isValid}
          onClick={handleSubmit(props.onSave)}
          variant="contained"
          color="primary"
          size="small"
          {...addTestAttr('UserEdit-SaveButton')}
        >
          {props.data.id ? tr.entityEdit.updateButton : tr.entityEdit.createButton}
        </Button>
      </ActionsWrapper>
    </>
  )
}

export { UserEdit }
