import styled from '@emotion/styled'

const ChecklistContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`

const CheckListTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
`

const CheckboxItem = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0.5rem;
`

const CheckboxLabelContainer = styled.div`
  flex: 1;
`

const CheckboxLabel = styled.div`
  font-size: 14px;
`

const CheckboxImpactDescription = styled.div`
  font-size: 12px;
  opacity: 0.72;
`

const CheckListItems = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`

const RequiredIndicator = styled.div`
  width: 6px;
  height: 6px;
  background-color: #ef5350;
  border-radius: 100%;
  position: absolute;
  right: -3px;
  top: -3px;
`

export {
  ChecklistContainer,
  CheckboxItem,
  CheckboxLabel,
  CheckboxImpactDescription,
  CheckboxLabelContainer,
  CheckListTitle,
  CheckListItems,
  RequiredIndicator
}
