import { useEffect, useState } from 'react'
import { FormGroup } from 'shared/ui-kit/form'
import { TextField } from 'shared/ui-kit/text-field'
import uniqueId from 'lodash/uniqueId'
import { PositionCustomer } from 'shared/models'
import { useTranslation } from 'shared/i18n/translate'
import { UserItem, UserSearchValue } from './styled'

export function getUserName(user: PositionCustomer) {
  if (!user) {
    return 'No name'
  }

  return user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.firstName || user.lastName
}

type Props = {
  users: PositionCustomer[]
  value?: PositionCustomer | null
  searchAvailable?: boolean
  onUserSearch: (term: string | null) => void
  onUserSelect: (user: PositionCustomer | null | undefined) => void
}

export function UserSearch(props: Props) {
  const { tr } = useTranslation()
  const [term, setTerm] = useState('')

  useEffect(() => {
    props.onUserSearch(props.searchAvailable ? '' : null)
  }, [])

  function getUsers() {
    if (props.value && props.users?.length) {
      return props.users.filter((x) => x.id !== props.value?.id)
    }

    return props.users
  }

  function handleTermChange(val: string) {
    setTerm(val)
    props.onUserSearch(val)
  }

  function handleCurrentUserSelect() {
    props.onUserSelect(props.value)
  }

  function handleUserSelect(x: PositionCustomer) {
    return () => props.onUserSelect(x)
  }

  return (
    <>
      <FormGroup>
        <TextField
          autofocus
          value={term}
          autocomplete={uniqueId('autocomplete')}
          placeholder={tr.lineMonitoring.operatorSearchPlaceholder}
          onChange={handleTermChange}
        />
      </FormGroup>
      <FormGroup style={{ marginLeft: 0, marginRight: 0 }}>
        {props.value?.id && (
          <UserSearchValue onClick={handleCurrentUserSelect}>{getUserName(props.value)}</UserSearchValue>
        )}
        {getUsers().map((x) => (
          <UserItem key={x.id} onClick={handleUserSelect(x)}>
            {getUserName(x)}
          </UserItem>
        ))}
      </FormGroup>
    </>
  )
}
