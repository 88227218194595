import { useState, useEffect } from 'react'
import { zone as zoneAPI } from 'shared/api'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { useTranslation } from 'shared/i18n/translate'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { editZone, createZone } from 'pages/nav'
import { CircularProgress, IconButton, Toolbar, AppBar } from '@mui/material'
import Add from '@mui/icons-material/Add'
import { ZoneModel } from 'shared/models'
import { CenterContainer } from 'shared/ui-kit/center-container'
import { ZoneList } from './dumb/zone-list'
import Container from 'shared/ui-kit/container'
import { useHistory, useParams } from 'react-router'
import { useDataProvider } from 'features/isolated-data-provider'
import { useTitle } from 'shared/hooks/useTitle'
import { useAppDispatch } from 'store'
import Card from 'shared/ui-kit/card'
import { useTimeZoneFilter } from 'features/time-zone-filter'

function SmartZoneListComponent() {
  const history = useHistory()
  const { placeId } = useParams<{ placeId: string }>()

  const [list, setList] = useState<ZoneModel[] | null>(null)

  const { tr } = useTranslation()
  const dispatch = useAppDispatch()
  const { getPlace } = useDataProvider()

  useTitle({ placeName: getPlace(placeId)?.name })
  useTimeZoneFilter(placeId)

  function handleSelect(data) {
    history.push(editZone(placeId, data.id))
  }

  useEffect(() => {
    zoneAPI.getZoneList(placeId).then((response) => {
      const { data, parents } = response

      setList(data)

      if (!parents?.shop) {
        return
      }

      const { shop } = parents

      const crumb = getCrumbs(tr.breadcrumbs)

      dispatch(
        setBreadcrumbs([crumb.home(), crumb.places(), crumb.place([placeId], shop.name), crumb.zones([placeId])])
      )
    })
  }, [])

  if (!list) {
    return (
      <CenterContainer>
        <CircularProgress color="secondary" />
      </CenterContainer>
    )
  }

  return (
    <>
      <AppBar position="sticky" color="inherit" elevation={2} style={{ top: '4rem', borderRadius: 0 }}>
        <Toolbar>
          <Container style={{ padding: '0 0.5rem' }}>
            <IconButton onClick={() => history.push(createZone(placeId))}>
              <Add />
            </IconButton>
          </Container>
        </Toolbar>
      </AppBar>
      <Container>
        <Card paperStyle={{ overflow: 'hidden' }}>
          <ZoneList data={list} onSelect={(data) => handleSelect(data)} />
        </Card>
      </Container>
    </>
  )
}

export default SmartZoneListComponent
