import { useState } from 'react'
import { Form, FormGroup } from 'shared/ui-kit/form'
import { useTranslation } from 'shared/i18n/translate'
import Dropdown from 'shared/ui-kit/dropdown'
import Button from '@mui/material/Button'
import { CloudDownloadOutlined as DownloadIcon, MoreVert as MoreIcon } from '@mui/icons-material'
import { DatePicker } from 'features/date'
import Chip from 'shared/ui-kit/chip/chip'
import { ControlLabel } from 'shared/ui-kit/control-label'
import get from 'lodash/get'
import { TextField } from 'shared/ui-kit/text-field'
import { LineModelSimplified } from 'shared/models'
import { Card, IconButton, MenuItem, Popover } from '@mui/material'
import { MenuTitle, MenuWrapper, SettingsButtonContainer } from '../styled'
import { RenameDialog } from './rename-dialog'
import { CopyDialog } from './copy-dialog'
import { DeleteDialog } from './delete-dialog'
import { useDataProvider } from 'features/isolated-data-provider'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { useAppSelector } from 'store'
import { ExportDataFormProps } from './types'
import { ActionsWrapper } from 'shared/ui-kit/action-wrapper'

function ExportDataForm(props: ExportDataFormProps) {
  const [state, setState] = useState(getInitialState())
  const [moreAnchor, setMoreAnchor] = useState<Element | null>(null)
  const { tr } = useTranslation()

  const [renameDialog, setRenameDialog] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [copyDialog, setCopyDialog] = useState(false)

  const { places } = useDataProvider()
  const { canUpdateShops } = useAppSelector(getPermissionsSelector)

  const shopsWithoutCurrent = places.filter((place) => String(place.id) !== String(props.placeId))

  function getInitialState() {
    const endDate = new Date()
    const startDate = new Date()

    const template = props.templates.length ? props.templates[0] : null

    let line = props.lines.length === 1 ? get(props.lines, [0, 'id']) : -1

    if (template?.forceLineChoice && props.lines.length) {
      line = get(props.lines, [0, 'id'])
    }

    return { line, startDate: +startDate, endDate: +endDate, template: template, search: '' }
  }

  function renderLines() {
    if (props.lines.length < 2) {
      return null
    }

    const lines = [
      !state.template?.forceLineChoice ? { id: -1, name: tr.exportData.allLines } : undefined,
      ...props.lines
    ].filter(Boolean) as LineModelSimplified[]

    return (
      <FormGroup>
        <Dropdown
          style={{ width: '100%' }}
          label={tr.exportData.line}
          value={state.line}
          data={lines}
          attention={!lines.find((el) => el.id === state.line)}
          dataConverter={(item) => ({ value: item.id, text: item.name })}
          onChange={(val) => setState({ ...state, line: val })}
        />
      </FormGroup>
    )
  }

  function handleExport() {
    const data: any = { ...state }

    if (data.template?.value) {
      data.template = data.template.value
    }

    if (data.line === -1) {
      delete data.line
    }

    props.onExport(data)
  }

  function openMoreMenu(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setMoreAnchor(ev.currentTarget)
  }

  function closeMoreMenu() {
    setMoreAnchor(null)
  }

  function openRenameDialog() {
    closeMoreMenu()
    setRenameDialog(true)
  }

  function closeRenameDialog(withRefresh?: boolean) {
    setRenameDialog(false)

    if (withRefresh) {
      props.onRefetch?.()
    }
  }

  function openDeleteDialog() {
    closeMoreMenu()
    setDeleteDialog(true)
  }

  function closeDeleteDialog(withRefresh?: boolean) {
    setDeleteDialog(false)

    if (withRefresh) {
      props.onRefetch?.()
    }
  }

  function openCopyDialog() {
    closeMoreMenu()
    setCopyDialog(true)
  }

  function closeCopyDialog() {
    setCopyDialog(false)
  }

  const valid =
    !!state.line &&
    !![!state.template?.forceLineChoice && { id: -1, name: tr.exportData.allLines }, ...props.lines]
      .filter(Boolean)
      .find((el) => el && el.id === state.line)

  if (renameDialog && state.template) {
    return (
      <Card>
        <RenameDialog template={state.template} onClose={closeRenameDialog} />
      </Card>
    )
  }

  if (copyDialog && state.template) {
    return (
      <Card>
        <CopyDialog template={state.template} onClose={closeCopyDialog} places={shopsWithoutCurrent} />
      </Card>
    )
  }

  if (deleteDialog && state.template) {
    return (
      <Card>
        <DeleteDialog template={state.template} onClose={closeDeleteDialog} />
      </Card>
    )
  }

  return (
    <>
      <Card>
        <Form>
          <SettingsButtonContainer>
            <IconButton onClick={openMoreMenu}>
              <MoreIcon />
            </IconButton>
          </SettingsButtonContainer>
          {!!canUpdateShops && (
            <Popover open={!!moreAnchor} anchorEl={moreAnchor} onClose={closeMoreMenu}>
              <MenuTitle>{state.template?.text}</MenuTitle>
              <MenuWrapper>
                <MenuItem onClick={openRenameDialog}>{tr.exportData.renameMenu}</MenuItem>
                <MenuItem onClick={openDeleteDialog}>{tr.exportData.deleteMenu}</MenuItem>
                {shopsWithoutCurrent?.length && <MenuItem onClick={openCopyDialog}>{tr.exportData.copyMenu}</MenuItem>}
              </MenuWrapper>
            </Popover>
          )}
          {Boolean(props.templates.length) && (
            <FormGroup>
              <ControlLabel style={{ marginBottom: 12 }}>{tr.exportData.exportTemplates}</ControlLabel>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {props.templates.map((x) => (
                  <div style={{ marginRight: 8, marginBottom: 8 }} key={x.value}>
                    <Chip
                      active={x.value === state.template?.value}
                      onClick={() => setState({ ...state, template: x })}
                    >
                      <div style={{ margin: '0 12px' }}>{x.text}</div>
                    </Chip>
                  </div>
                ))}
              </div>
            </FormGroup>
          )}
          {renderLines()}
          <FormGroup style={{ display: 'flex', flexWrap: 'wrap' }}>
            <DatePicker
              label={!state.template?.hideUpperLimitDate ? tr.exportData.startDate : tr.exportData.date}
              onChange={(val) => {
                setState({ ...state, startDate: val, endDate: state.endDate < val ? val : state.endDate })
              }}
              value={state.startDate}
              style={{ marginRight: '4px', flex: 1 }}
            />

            {!state.template?.hideUpperLimitDate && (
              <DatePicker
                label={tr.exportData.endDate}
                onChange={(val) => {
                  setState({ ...state, startDate: state.startDate > val ? val : state.startDate, endDate: val })
                }}
                value={state.endDate}
                style={{ marginLeft: '4px', flex: 1 }}
              />
            )}
          </FormGroup>
          <FormGroup>
            {state.template?.displaySearchString && (
              <TextField
                label={tr.exportData.search}
                placeholder={tr.exportData.search}
                value={state.search}
                onChange={(val) => setState({ ...state, search: val })}
              />
            )}
          </FormGroup>
        </Form>
      </Card>
      <ActionsWrapper>
        <Button
          disabled={!valid}
          onClick={handleExport}
          variant="contained"
          color="primary"
          size="small"
          startIcon={<DownloadIcon />}
        >
          {tr.exportData.exportButton}
        </Button>
      </ActionsWrapper>
    </>
  )
}

export default ExportDataForm
