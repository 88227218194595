import { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { checkpointMonitoring, lineMonitoring, index } from 'pages/nav'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { useTranslation } from 'shared/i18n/translate'
import Container from 'shared/ui-kit/container'
import Card from 'shared/ui-kit/card'
import { ActionContainer, DelayContent, DelayItems, FieldContainer } from './styled'
import { Chip, Button } from '@mui/material'
import TimePicker from 'features/time/time-picker-timestamp'
import { TextField } from 'shared/ui-kit/text-field'
import { Title } from 'shared/ui-kit/title'
import { line, monitoring } from 'shared/api'
import { useAppDispatch } from 'store'

function LineDelay() {
  const { checkpointId, lineId, placeId } = useParams<{ placeId: string; lineId: string; checkpointId?: string }>()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { tr } = useTranslation()
  const [selectedDelay, setDelay] = useState<number>()
  const [comment, setComment] = useState('')
  const [isCustomDelay, setIsCustomDelay] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    getLine()
  }, [])

  async function getLine() {
    try {
      const res = await line.getLine(lineId, placeId)
      const crumb = getCrumbs(tr.breadcrumbs)

      dispatch(
        setBreadcrumbs(
          [
            crumb.home(),
            crumb.places(),
            crumb.place([placeId], res?.parents?.shop?.name || 'place'),
            crumb.lines([placeId]),
            crumb.line([placeId, lineId], res?.parents?.line?.name || 'Line'),
            !checkpointId && crumb.lineMonitoring([placeId, lineId]),
            !checkpointId && crumb.lineDelay([placeId, lineId]),
            !!checkpointId && crumb.checkpoints([placeId, lineId]),
            !!checkpointId && crumb.checkpointMonitoring([placeId, lineId, checkpointId]),
            !!checkpointId && crumb.checkpointDelay([placeId, lineId, checkpointId])
          ].filter(Boolean) as any
        )
      )
    } catch (error) {
      history.push(index())
    }
  }

  async function handleCreateDelay() {
    try {
      setIsFetching(true)
      await monitoring.startDelay({ lineId, placeId, checkpointId, delay: (selectedDelay as number) * 60 })

      history.push(checkpointId ? checkpointMonitoring(placeId, lineId, checkpointId) : lineMonitoring(placeId, lineId))
    } finally {
      setIsFetching(false)
    }
  }

  function handleDelaySelect(delay) {
    return () => {
      setDelay(delay)
      setIsCustomDelay(false)
    }
  }

  function changeIsCustomDelay() {
    setIsCustomDelay(true)
  }

  function handleTime(time: number | null) {
    if (time) {
      const selectedValue = new Date(time)
      const delayInMinutes = selectedValue.getUTCHours() * 60 + selectedValue.getUTCMinutes()
      setDelay(delayInMinutes)
    } else {
      setDelay(undefined)
    }
  }

  function handleCancel() {
    history.goBack()
  }

  return (
    <Container>
      <Title title={!checkpointId ? tr.breadcrumbs.lineDelay : tr.breadcrumbs.checkpointDelay} />
      <Card>
        <DelayContent>
          <DelayItems>
            {[5, 10, 30, 60].map((delay) => (
              <Chip
                key={delay}
                label={`${tr.time.min(delay)}`}
                color={!isCustomDelay && selectedDelay === delay ? 'primary' : 'default'}
                onClick={handleDelaySelect(delay)}
              />
            ))}
            <Chip
              label={tr.lineDelay.custom}
              color={isCustomDelay ? 'primary' : 'default'}
              onClick={changeIsCustomDelay}
            />
          </DelayItems>
          {isCustomDelay && (
            <FieldContainer>
              <TimePicker
                offset={false}
                label={tr.lineDelay.time}
                onChange={handleTime}
                value={selectedDelay ? selectedDelay * 60000 : undefined}
              />
            </FieldContainer>
          )}
          <FieldContainer>
            <TextField
              placeholder={tr.lineDelay.comment}
              label={tr.lineDelay.comment}
              value={comment}
              onChange={setComment}
            />
          </FieldContainer>
          <ActionContainer>
            <Button
              variant="contained"
              color="primary"
              disabled={!selectedDelay || isFetching}
              onClick={handleCreateDelay}
              size="small"
            >
              {tr.lineDelay.start}
            </Button>
            <Button variant="text" disabled={isFetching} onClick={handleCancel} size="small">
              {tr.lineDelay.cancel}
            </Button>
          </ActionContainer>
        </DelayContent>
      </Card>
    </Container>
  )
}

export { LineDelay }
