import { EventFlowAction } from 'shared/models'

const checkpointActions = [
  EventFlowAction.CallToCheckpoint,
  EventFlowAction.FinishCheckpoint,
  EventFlowAction.FinishService,
  EventFlowAction.MoveToCheckpoint,
  EventFlowAction.Removed,
  EventFlowAction.StartService,
  EventFlowAction.StartCheckpoint,
  EventFlowAction.EnterToCheckpointZone,
  EventFlowAction.ExitFromCheckpointZone
]

export { checkpointActions }
