import LanguageSelectMultiple from './language-select-multiple'
import { LanguageSelectBaseProps } from './language-select'

type Props = LanguageSelectBaseProps<false>

function LanguageSelectSingleComponent(props: Props) {
  const { value, ensureValue = false, languages = [], onChange, hideSingle = false, title } = props

  return (
    <LanguageSelectMultiple
      value={value ? [value] : []}
      ensureValue={ensureValue}
      languages={languages}
      hideSingle={hideSingle}
      onChange={(values) => onChange(values[0])}
      title={title}
    />
  )
}

export default LanguageSelectSingleComponent
export const LanguageSelectSingle = LanguageSelectSingleComponent
