import { createRoot } from 'react-dom/client'
import { App } from 'app'
import { startMocking } from 'shared/mocks'

const node = document.querySelector('main')!
const root = createRoot(node)

startMocking().then(() => {
  root.render(<App />)
})
