import Divider from '@mui/material/Divider'
import { useTranslation } from 'shared/i18n/translate'
import { FinishAndPauseActionProps } from './types'
import PositionActionComponent from '../../components/position-action'
import StopIcon from '@mui/icons-material/Stop'

function FinishAndPause(props: FinishAndPauseActionProps) {
  const { tr } = useTranslation()

  return (
    <>
      {!props.isCardView && <Divider />}
      <PositionActionComponent
        isCardView={props.isCardView}
        onSubmit={props.onSubmit}
        disabled={props.disabled}
        icon={<StopIcon fontSize="small" />}
      >
        {tr.positionActions.finishServiceAndPause}
      </PositionActionComponent>
    </>
  )
}

export { FinishAndPause }
