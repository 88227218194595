import { IconButton } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

type Props = {
  isVisible: boolean
  onToggle: (toggled: boolean) => void
}

function ShowHideToggleComponent({ isVisible, onToggle }: Props) {
  return (
    <IconButton size="small" onClick={() => onToggle(!isVisible)}>
      {!isVisible && <Visibility />}
      {isVisible && <VisibilityOff />}
    </IconButton>
  )
}

export const ShowHideToggle = ShowHideToggleComponent
