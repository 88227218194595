import { useMemo } from 'react'
import { CheckpointStatus, CheckpointStatusMap } from 'shared/models'
import { useTranslation } from 'shared/i18n/translate'
import { Typography } from '@mui/material'
import { HostTime, HostWrapper } from '../styled'
import { HostInfoProps } from '../types'

function HostInfo({ data, displayTime }: HostInfoProps) {
  const { dateFormatter, tr } = useTranslation()

  const info = useMemo(() => {
    if (!data.status || !data.statusChange?.date) {
      return ''
    }

    const statusText: CheckpointStatusMap<typeof tr.checkpointStatus.starting> = {
      [CheckpointStatus.starting]: tr.checkpointStatus.starting,
      [CheckpointStatus.started]: tr.checkpointStatus.started,
      [CheckpointStatus.paused]: tr.checkpointStatus.paused,
      [CheckpointStatus.finishing]: tr.checkpointStatus.finishing,
      [CheckpointStatus.finished]: tr.checkpointStatus.finished
    }

    if (!statusText[data.status]) {
      return tr.checkpointStatus.unknown
    }

    return statusText[data.status](
      data.statusChange?.date ? dateFormatter(data.statusChange.date) : '',
      data.statusChange?.user?.firstName,
      data.statusChange?.user?.secondName
    )
  }, [data.status, data.statusChange])

  return (
    <HostWrapper>
      <div style={{ flex: 1 }}>
        <Typography lineHeight="21px" variant="h6">
          {data.name}
        </Typography>
        <Typography color="GrayText" variant="caption">
          {info}
        </Typography>
      </div>
      {displayTime && (
        <HostTime>
          {!data.isAnyHourOpened && (
            <Typography lineHeight="18px" variant="h6" color="GrayText">
              {data.startTime_unixtime ? dateFormatter(data.startTime_unixtime, 'time') : '_____'} -{' '}
              {data.closingTime_unixtime ? dateFormatter(data.closingTime_unixtime, 'time') : '_____'}
            </Typography>
          )}
          {data.isAnyHourOpened && (
            <Typography lineHeight="18px" variant="h6" color="GrayText">
              ----------
            </Typography>
          )}
          {data.serviceWithPause && (
            <Typography variant="caption" color="GrayText">
              {tr.checkpointMonitoring.pause}{' '}
              {data.pauseStartHour_unixtime ? dateFormatter(data.pauseStartHour_unixtime, 'time') : '_____'} -{' '}
              {data.pauseEndHour_unixtime ? dateFormatter(data.pauseEndHour_unixtime, 'time') : '_____'}
            </Typography>
          )}
        </HostTime>
      )}
    </HostWrapper>
  )
}

export default HostInfo
