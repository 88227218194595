import { Toolbar } from '@mui/material'
import styled from '@emotion/styled'

const ProgressContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: -2rem;
  bottom: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.83);
  cursor: auto;
`

const PositionActionsWrapper = styled.div`
  margin: 0 1rem 1rem;
  position: relative;
`

const FlexCenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 0;
`

const PositionActionStyled = styled.div<{ $disabled?: boolean }>`
  padding: 1rem 0.5rem;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.12);
  }

  ${(props) =>
    !!props.$disabled && {
      pointerEvents: 'none',
      opacity: 0.18
    }}
`

const MobilePositionActionStyled = styled.div<{ $disabled?: boolean }>`
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  cursor: pointer;
  border-radius: 1rem;

  &:hover {
    background: rgba(0, 0, 0, 0.12);
  }

  ${(props) =>
    !!props.$disabled && {
      pointerEvents: 'none',
      opacity: 0.48
    }}
`

const PositionActionContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`

const PositionActionIcon = styled.div`
  display: flex;
  position: absolute;
  left: -2rem;
  opacity: 0.72;
`

const DialogTitleWrapper = styled.div`
  flex: 1;
  gap: 1rem;
`

const DialogToolbar = styled(Toolbar)`
  position: sticky;
  top: 0;
  background-color: #fafafa;
  z-index: 1;
  padding: 0.5rem 1rem 0.5rem 1.5rem;
`

const DialogContentWrapper = styled.div`
  background-color: #fafafa;
  min-height: 100%;
  overflow: auto;
`

export {
  ProgressContainer,
  PositionActionsWrapper,
  FlexCenterWrapper,
  PositionActionStyled,
  PositionActionContentWrapper,
  PositionActionIcon,
  MobilePositionActionStyled,
  DialogTitleWrapper,
  DialogToolbar,
  DialogContentWrapper
}
