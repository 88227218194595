import { Link } from '@mui/material'
import styled from '@emotion/styled'

const SettingsButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
  box-sizing: border-box;
  margin-bottom: -56px;
`

const MenuTitle = styled.div`
  padding: 0.5rem 1rem;
  width: 172px;
  font-size: 14px;
  font-weight: 500;
  border-bottom: solid 1px rgb(0 0 0 / 8%);
  color: rgba(0, 0, 0, 0.54);
`

const MenuWrapper = styled.div`
  margin: 0.5rem 0;
`

const LogoImage = styled.div<{ $url: string }>`
  width: 56px;
  height: 56px;
  border-radius: 1rem;
  background-image: url(${(props) => props.$url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 0px 4px,
    rgba(0, 0, 0, 0.16) 0px 0px 4px;
  margin-right: 0.5rem;
  background-color: #f1f1f1;
`

const SettingsLink = styled(Link)`
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  gap: 1rem;
`

const ListBarWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`

export { SettingsButtonContainer, MenuTitle, MenuWrapper, LogoImage, SettingsLink, TitleContainer, ListBarWrapper }
