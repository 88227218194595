import { useState } from 'react'
import { Button, TextField, Typography } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import { PriorityFormProps } from './types'
import { ActionFormActions } from '../styled'

function PriorityForm(props: PriorityFormProps) {
  const { tr } = useTranslation()
  const [comment, setComment] = useState('')

  function handleSubmit(ev: React.FormEvent<HTMLFormElement>) {
    ev.preventDefault()
    props.onAccept(comment)
  }

  function handleChange(ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setComment(ev.target.value)
  }

  return (
    <>
      <Typography variant="body1" fontWeight={500} fontSize="18px">
        {tr.positionActions.priorityCallForm.title}
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          style={{ marginTop: '0.5rem' }}
          fullWidth
          value={comment}
          label={tr.positionActions.priorityCallForm.comment}
          placeholder={tr.positionActions.priorityCallForm.comment}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
          autoFocus
        />
        <ActionFormActions $marginTop={props.isCardView ? '2rem' : '1rem'}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="small"
            disabled={!comment || comment.length < 3}
          >
            {tr.positionActions.priorityCallForm.accept}
          </Button>
          <Button onClick={props.onCancel} size="small">
            {tr.positionActions.priorityCallForm.cancel}
          </Button>
        </ActionFormActions>
      </form>
    </>
  )
}

export { PriorityForm }
