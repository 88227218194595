import React, { Component } from 'react'
import * as Nav from 'pages/nav'
import { connect } from 'react-redux'
import translate from 'shared/i18n/translate'
import { getIsolatedLineList } from 'store/actions/places-action-creators'
import AppointmentEditForm from './appointment-edit-form'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import dataProvider from 'features/isolated-data-provider'
import { setBreadcrumbs, showMessage, MessageTypes } from 'store/actions/main-layout-action-creators'
import Container from 'shared/ui-kit/container'
import { appointments } from 'shared/api'
import PageLoader from 'shared/ui-kit/page-loader'
import { AppointmentTextSummaryPrint } from './components/appointment-summary-print'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print'
import { CenterContainer } from 'shared/ui-kit/center-container'
import timeZoneFilter from 'features/time-zone-filter'

class SmartAppointmentEdit extends Component {
  state = {
    data: null,
    errors: [],
    loading: true,
    lineFetching: false,
    printSummary: null
  }

  printRef = React.createRef()

  componentDidUpdate(_, prevState) {
    if (this.state.printSummary && !prevState.printSummary && this.print) {
      this.print()
      this.createSuccess()
    }
  }

  componentDidMount() {
    if (this.shopId && this.lineId) {
      this.handleRequestLines(this.shopId, this.setBreadcrumbs)
    } else {
      this.setBreadcrumbs()
    }

    if (this.isNew) {
      this.setState({ loading: false })

      return
    }

    this.setState({ loading: true })

    const params = new URLSearchParams(this.props.location.search)

    appointments
      .getAppointmentEditForm({
        id: this.appointmentId,
        shopId: this.shopId || params.get('shopId') || '',
        lineId: this.lineId || params.get('lineId') || ''
      })
      .then((resp) => {
        this.setState({ loading: false, data: resp.data })
      })
  }

  get lineId() {
    return this.props.match.params.lineId
  }

  get shopId() {
    return this.props.match.params.placeId
  }

  get appointmentId() {
    return this.props.match.params.appointmentId
  }

  get isNew() {
    return !this.props.match.params.appointmentId
  }

  get queryParams() {
    return new URLSearchParams(this.props.location.search)
  }

  get customServiceDurationQuery() {
    const customServiceDuration = this.queryParams.get('customServiceDuration')
    return customServiceDuration ? Number(customServiceDuration) : undefined
  }

  setBreadcrumbs() {
    const shop = this.props.getPlace(this.shopId)
    const crumb = getCrumbs(this.props.translation.breadcrumbs)
    const lines = shop?.lines || []
    const currentLine = lines.find((line) => line.id == this.lineId)

    const fromScheduler = this.queryParams.get('scheduler') === 'true'

    const crumbs =
      this.shopId && this.lineId
        ? [
            crumb.home(),
            crumb.places(),
            crumb.place([this.shopId], shop?.name || 'place'),
            crumb.lines([this.shopId]),
            crumb.line([this.shopId, this.lineId], currentLine?.name || 'line'),
            fromScheduler
              ? crumb.appointmentScheduler([this.shopId, this.lineId])
              : crumb.appointmentsLine([this.shopId, this.lineId]),
            this.isNew ? crumb.newAppointment() : crumb.editAppointment()
          ]
        : [crumb.home(), crumb.appointments(), this.isNew ? crumb.newAppointment() : crumb.editAppointment()]

    this.props.dispatch(setBreadcrumbs(crumbs))
  }

  handleCreate = (opts) => {
    appointments
      .createAppointment(opts.data, { errorHandle: true })
      .then((resp) => {
        if (opts.shouldPrint) {
          this.print = opts.handlePrint

          appointments
            .getAppointmentPrintTemplate({ token: resp.data?.token })
            .then((resp) => this.setState({ printSummary: resp.data.recap }))
        } else {
          this.createSuccess({ token: resp.data?.token, lineId: opts.data?.lineId, shopId: opts.data?.placeId })
        }
      })
      .catch((error) => {
        this.handleRequestError(error, this.props.translation.appointmentOperationMessages.createError)
      })
      .finally(opts.cb)
  }

  createSuccess = (options = { token: null, lineId: null, shopId: null }) => {
    this.props.dispatch(
      showMessage(this.props.translation.appointmentOperationMessages.createSuccess, MessageTypes.Success)
    )
    this.navigateBack(options)
  }

  handleSave = (data, cb) => {
    appointments
      .updateAppointment(data)
      .then(() => {
        this.props.dispatch(
          showMessage(this.props.translation.appointmentOperationMessages.updateSuccess, MessageTypes.Success)
        )
        this.navigateBack({ token: data.id, lineId: data.lineId, shopId: data.placeId })
      })
      .finally(cb)
  }

  handleDelete = (data, cb) => {
    const msg = this.props.translation.appointmentOperationMessages

    appointments
      .deleteAppointment(data)
      .then(() => {
        this.props.dispatch(showMessage(msg.deleteSuccess, MessageTypes.Success))
        this.navigateBack()
      })
      .catch(() => this.props.dispatch(showMessage(msg.deleteError, MessageTypes.Error)))
      .finally(cb)
  }

  handleRequestError = (error) => {
    const errorJson = error?.response?.data || { type: 'unknown' }
    this.handleInvalidStateError(errorJson)
  }

  handleInvalidStateError = ({ type }) => {
    if (type === 'jeFile.appointment.timeSlotIsNotFree') {
      this.setState((curr) => ({ errors: [...curr.errors, 'TimeSlotIsNotFree'] }))
    }

    const { appointmentAlreadyCreated } = this.props.translation.appointmentOperationMessages

    if (type === 'jeFile.appointment.appointmentAlreadyCreated') {
      this.props.dispatch(showMessage(appointmentAlreadyCreated, MessageTypes.Error))
      return
    }
  }

  handleRequestLines = (shopId, callback) => {
    if (this.state.lineFetching || this.lineFetching) {
      return
    }

    this.lineFetching = true

    this.setState({ lineFetching: true }, () => {
      this.props.dispatch(
        getIsolatedLineList(shopId, () => {
          this.lineFetching = false
          this.setState({ lineFetching: false }, callback)
        })
      )
    })
  }

  getAdditionals = () => {
    try {
      const additionals = this.queryParams.get('additionals')
        ? JSON.parse(this.queryParams.get('additionals'))
        : undefined

      return additionals
    } catch (e) {
      return undefined
    }
  }

  navigateBack = ({ lineId = this.lineId, shopId = this.shopId } = {}) => {
    if (this.lineId && this.shopId && this.queryParams.get('scheduler') === 'true') {
      this.props.history.push(Nav.lineAppointmentScheduler(shopId, lineId))
      return
    }

    this.props.history.push(shopId && lineId ? Nav.appointmentsLine(shopId, lineId) : Nav.appointments())
  }

  render() {
    if (this.state.loading) {
      return (
        <CenterContainer>
          <PageLoader />
        </CenterContainer>
      )
    }

    const places = this.props.getPlaces()

    const placeId = this.shopId || this.queryParams.get('originalPlaceId') || undefined
    const lineId = this.lineId || this.queryParams.get('originalLineId') || undefined

    const servicesFromQuery = (this.queryParams.get('services') || '').split(';').filter(Boolean).map(Number)

    const additionals = this.getAdditionals()

    return (
      <Container>
        <AppointmentTextSummaryPrint ref={this.printRef} data={this.state.printSummary} />
        <ReactToPrint content={() => this.printRef.current}>
          <PrintContextConsumer>
            {({ handlePrint }) => (
              <AppointmentEditForm
                data={this.state.data}
                shops={places}
                placeId={placeId ? +placeId : undefined}
                lineId={lineId ? +lineId : undefined}
                participantsNumber={this.queryParams.get('participantsNumber') || undefined}
                serviceIds={servicesFromQuery?.length > 0 ? servicesFromQuery : undefined}
                additionals={additionals}
                customServiceDuration={this.customServiceDurationQuery}
                customerId={this.queryParams.get('customerId') || undefined}
                positionId={this.queryParams.get('positionId') || undefined}
                defaultStartTime={this.queryParams.get('startTime') || undefined}
                onRequestLines={this.handleRequestLines}
                onCreate={(data, shouldPrint, cb) => {
                  this.handleCreate({ data, shouldPrint, handlePrint, cb })
                }}
                onUpdate={this.handleSave}
                onDelete={this.handleDelete}
                errors={this.state.errors}
                errorResolved={(resolvedError) => {
                  this.setState((curr) => ({ errors: curr.errors.filter((e) => e !== resolvedError) }))
                }}
              />
            )}
          </PrintContextConsumer>
        </ReactToPrint>
      </Container>
    )
  }
}

export default translate(connect()(dataProvider(timeZoneFilter(SmartAppointmentEdit))))
