import { useState, useEffect } from 'react'
import { LoginForm } from './components/login-form'
import { login, loginQR } from 'shared/auth'
import { index, line, lines } from 'pages/nav'
import { extractStoredUrl } from 'shared/utils/history'
import { invalidatePlaceList } from 'store/actions/places-action-creators'
import { storeUserProfile } from 'store/actions/user-action-creators'
import { useTranslation } from 'shared/i18n/translate'
import { user } from 'shared/api'
import { useHistory, useLocation } from 'react-router'
import { useAppDispatch } from 'store'
import { LoginWrapper } from './styled'

function Login() {
  const [firstRequest, setFirstRequest] = useState(false)
  const [request, setRequest] = useState(false)
  const [showCaptcha, setShowCaptcha] = useState(false)

  const { search } = useLocation()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { tr } = useTranslation()

  useEffect(() => {
    window.document.title = tr.common.title
    dispatch(invalidatePlaceList())

    const params = new URLSearchParams(search)
    const code = params.get('code')

    if (code) {
      loginByQRCode(code)
    }
  }, [])

  async function loginByQRCode(code) {
    const params = new URLSearchParams(search)
    const shopId = params.get('shopId')
    const lineId = params.get('lineId')

    setFirstRequest(true)

    try {
      await loginQR(code)

      const profile = await user.storeProfile()

      dispatch(storeUserProfile(profile))

      if (shopId && lineId) {
        history.push(line(shopId, lineId))
      } else if (shopId) {
        history.push(lines(shopId))
      } else {
        history.push(extractStoredUrl() || index())
      }
    } catch (error: any) {
      setFirstRequest(false)
    }
  }

  async function handleSubmit(name, password, captchaId, captchaValue) {
    setRequest(true)
    setShowCaptcha(false)

    try {
      await login(name, password, captchaId, captchaValue)

      const profile = await user.storeProfile()

      dispatch(storeUserProfile(profile))

      history.push(extractStoredUrl() || index())
    } catch (error: any) {
      setRequest(false)

      if (error?.response?.data?.showCaptcha) {
        setShowCaptcha(true)
      }
    }
  }

  if (firstRequest) {
    return null
  }

  return (
    <LoginWrapper>
      <LoginForm onSubmit={handleSubmit} disabled={request} showCaptcha={showCaptcha} />
    </LoginWrapper>
  )
}

export default Login
