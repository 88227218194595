import { BasicPositionModel } from '.'

export enum LineInactiveState {
  NoActiveCheckpoints = 'noActiveCheckpoints',
  OperatingTimeIsOver = 'operatingTimeIsOver',
  Overcharged = 'overcharged',
  ReservationTimeNotCame = 'reservationTimeNotCame'
}

export interface CheckpointMonitoringModel {
  registeredPositionsCount: number
  waitingTime: number
  suggestedPositionId?: string | number
  showButtonCreatePosition?: boolean
  lineIsActive?: boolean
  inactiveReason: LineInactiveState
  positions: BasicPositionModel[]
  serverNow: number
}
