import { ReactNode, ReactElement } from 'react'
import { ViewPermisseonDeny } from './components/view-permisseon-deny'
import { setViewPermissionBlocked } from 'store/reducers/view-permission-reducer'
import { getViewPermissionsBlocked } from 'store/selectors/permissionSelectors'
import { ViewContainer } from './styled'
import { useAppDispatch, useAppSelector } from 'store'

type Props = {
  children?: ReactNode
}

function ViewPermissionWrapperComponent({ children }: Props) {
  const dispacth = useAppDispatch()
  const blocked = useAppSelector(getViewPermissionsBlocked)

  function handleSubmit() {
    dispacth(setViewPermissionBlocked(false))
    location.replace('/')
  }

  if (!blocked) {
    return children ? (children as ReactElement) : null
  }

  return (
    <ViewContainer>
      <ViewPermisseonDeny onSubmit={handleSubmit} />
    </ViewContainer>
  )
}

export default ViewPermissionWrapperComponent
export const ViewPermissionWrapper = ViewPermissionWrapperComponent
