import { CSSProperties } from 'react'
import { CircularProgress } from '@mui/material'
import css from './page-loader.module.scss'

type Props = {
  style?: CSSProperties
}

function PageLoader(props: Props) {
  return (
    <div className={css.root} style={props.style}>
      <CircularProgress color="primary" />
    </div>
  )
}

export default PageLoader
