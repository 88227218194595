import { Button, Fade, Paper, Popper, Typography } from '@mui/material'
import { SatisfactionDialogActions, SatisfactionDialogTitle } from './styled'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { AppLocale } from 'shared/i18n'

const storageKey = 'jefile_whatsNewLastVersion'

const whatsnewLink: Record<AppLocale, string> = {
  en: 'https://jefile.fr/en/whats-new',
  fr: 'https://jefile.fr/nouveautes'
}

function WhatsNewDialog({
  children,
  version,
  onWhatsNewDialogChanged
}: {
  children?: ReactNode
  version?: string
  onWhatsNewDialogChanged?: (open: boolean) => void
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const messageRef = useRef<HTMLDivElement>(null)

  const { locale, tr } = useTranslation()

  useEffect(() => {
    checkToOpen()
  }, [])

  function checkToOpen() {
    const lastVersion = localStorage.getItem(storageKey)
    const [lastMajor, lastMinor] = (lastVersion || '').split('.')
    const [major, minor] = (version || '').split('.')

    if (messageRef.current && (!lastVersion || major > lastMajor || minor > lastMinor)) {
      setTimeout(() => {
        handleDialogOpen()
      }, 1000)
    }
  }

  function handleDialogOpen() {
    if (messageRef.current) {
      onWhatsNewDialogChanged?.(true)
      setAnchorEl(messageRef.current)
    }
  }

  function closePopover() {
    setAnchorEl(null)
    onWhatsNewDialogChanged?.(false)
    localStorage.setItem(storageKey, version || '')
  }

  return (
    <>
      <div
        ref={messageRef}
        onClick={handleDialogOpen}
        style={{ cursor: 'pointer', margin: !children ? '2rem 0 0 0.5rem' : 0 }}
      >
        {children}
      </div>
      <Popper sx={{ zIndex: 1200 }} open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom-start" transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={160}>
            <Paper style={{ padding: '1rem' }}>
              <SatisfactionDialogTitle>
                <Typography variant="body1">{tr.whatsNewDialog.dialogTitle}</Typography>
                <Typography variant="body2" marginTop="0.25rem">
                  {tr.whatsNewDialog.dislogDescription}
                </Typography>
              </SatisfactionDialogTitle>
              <SatisfactionDialogActions style={{ justifyContent: 'flex-start' }}>
                <a href={whatsnewLink[locale] || whatsnewLink.en} target="_blank" rel="noreferrer">
                  <Button variant="contained" size="small" color="primary" onClick={closePopover}>
                    {tr.whatsNewDialog.whatsNewButton}
                  </Button>
                </a>
                <Button variant="text" size="small" onClick={closePopover}>
                  {tr.whatsNewDialog.closeButton}
                </Button>
              </SatisfactionDialogActions>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  )
}

export { WhatsNewDialog }
