import styled from '@emotion/styled'

const NoParticipant = styled.div`
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.03);
  border: 2px dashed rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  color: #828282;
  margin-bottom: 16px;
`

const HotkeyItem = styled.span`
  color: #db214d;
  margin-right: 0.5rem;
`

const TitleName = styled.div`
  padding: 0.5rem 0 1rem 0;
  font-size: 21px;
  color: #5f5f5f;
  display: none;

  @media screen and (max-width: 800px) {
    & {
      display: block;
    }
  }
`

const GroupTitle = styled.div`
  padding: 0.5rem 0 1rem 0;
  font-size: 18px;
  color: #5f5f5f;
`

const MonitoringRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
`

const MonitoringBarContainer = styled.div<{ $smallScreen?: boolean }>`
  min-width: 640px;

  ${(props) => props.$smallScreen && { minWidth: 0, flex: 1 }}
`

const MonitoringBar = styled.div<{ $smallScreen?: boolean }>`
  position: sticky;
  width: 100%;
  z-index: 1100;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  box-shadow:
    0 1px 6px rgba(0, 0, 0, 0.12),
    0 1px 4px rgba(0, 0, 0, 0.12);
  display: flex;
  min-height: 64px;
  align-items: center;
  justify-content: center;
  background: #fff;
  top: 64px;
  left: 0;
  white-space: nowrap;

  ${(props) => props.$smallScreen && { padding: '0 0.5rem' }}
`

const BarContentWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

const InactiveMessage = styled.div`
  font-size: 1.7rem;
  color: #00000052;
  text-align: center;
  margin-top: 2rem;
`

const BarRightPart = styled.div`
  display: flex;
  align-items: center;
`

export {
  NoParticipant,
  HotkeyItem,
  TitleName,
  GroupTitle,
  MonitoringRoot,
  MonitoringBar,
  MonitoringBarContainer,
  BarContentWrapper,
  InactiveMessage,
  BarRightPart
}
