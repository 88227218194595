import { Divider } from '@mui/material'
import { PositionState } from 'shared/models'
import PositionActionComponent from '../../components/position-action'
import { useTranslation } from 'shared/i18n/translate'
import TransferIcon from '@mui/icons-material/Redo'
import { TransferActionProps } from './types'

export function Transfer(props: TransferActionProps) {
  const { tr } = useTranslation()

  const canTransfer = [
    PositionState.CalledToCheckpoint,
    PositionState.InService,
    PositionState.MovingToCheckpoint,
    PositionState.NearCheckpoint,
    PositionState.ServiceStarted
  ].includes(props.positionState)

  function handleSubmit() {
    props.onTransfer?.()
  }

  return (
    <>
      {!props.isCardView && <Divider />}
      <PositionActionComponent
        isCardView={props.isCardView}
        confirmation={false}
        confirmationMessage=""
        onSubmit={handleSubmit}
        disabled={!canTransfer || !!props.disabled}
        icon={<TransferIcon fontSize="small" />}
      >
        {tr.positionActions.transfer}
      </PositionActionComponent>
    </>
  )
}
