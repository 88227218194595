import Card from 'shared/ui-kit/card'
import Text from 'shared/ui-kit/text'
import { Form } from 'shared/ui-kit/form'
import Divider from '@mui/material/Divider'
import { ProgressBar, ProgressBarPart } from 'shared/ui-kit/progress-bar'
import { checkpointHost, discreteLineMonitoringEdit } from 'pages/nav'
import { convertPositionToStatePersons, PositionStateToColor } from '../utils'
import { LineMode } from 'shared/models/entities/line.model'
import { LineSlotPositons } from './line-slot-positions'
import { useTranslation } from 'shared/i18n/translate'
import { useHistory } from 'react-router'
import { InternalService, PositionModel, PositionState, TimeslotModel } from 'shared/models'
import { LineSlotProps } from '../types'
import { Container, Stat, StatValue, Stats, Title } from './styled'

export function LineSlot(props: LineSlotProps) {
  const { tr, dateFormatter } = useTranslation()
  const history = useHistory()

  const { data, line, checkpoints, shopId, lineId, serverNow, onToggle, onRemove, onStateChange } = props
  const { selectedPositionId, selectPositionChange, selected } = props

  const positionGroups = convertPositionToStatePersons(data.positions)

  const modes = line?.modes || []

  const timeslot: TimeslotModel = {
    id: data.id,
    from_unixtime: data.from,
    to_unixtime: data.from + (line?.serviceTime || 0) * 1000
  }

  function handleExpandSlot() {
    onToggle(data.id)
  }

  function handleDeletePosition(position: PositionModel) {
    onRemove(data, position)
  }

  function handleStateChange(position: PositionModel, newState: PositionState) {
    onStateChange(data, position, newState)
  }

  function handleUpdatePosition(position: PositionModel) {
    if (position.services?.find((s) => String(s.id) === String(InternalService.Break))) {
      history.push(
        checkpointHost(
          String(shopId),
          String(lineId),
          String(position.customCheckpoint?.id || position.assignedCheckpoint?.id)
        )
      )
    } else {
      history.push(discreteLineMonitoringEdit(String(shopId), String(lineId), String(position.id)))
    }
  }

  return (
    <Card paperStyle={{ overflow: 'hidden' }}>
      <Container>
        <div style={{ display: 'flex' }}>
          <Title style={{ flex: 1 }}>
            {dateFormatter(timeslot.from_unixtime, 'time')} - {dateFormatter(timeslot.to_unixtime, 'time')},{' '}
            {tr.monitoring.timeslotState[data.state]}
          </Title>
          {data.personsCount !== undefined && data.fullCapacity !== undefined && (
            <Title>{tr.monitoring.reservationCapacity(data.personsCount, data.fullCapacity)}</Title>
          )}
        </div>
        <div style={{ marginTop: 16 }}>
          <ProgressBar total={data.fullCapacity} progress={data.personsCount || 0}>
            {positionGroups.map((g, i) => {
              return <ProgressBarPart key={i} value={g.value} color={PositionStateToColor[g.name]} />
            })}
          </ProgressBar>
        </div>
        <Stats>
          {modes.includes(LineMode.ScreenCall) && (
            <Stat>
              <StatValue>{data.peopleWithScreenCall || 0}</StatValue>
              <Text type="caption" preLine={true}>
                {tr.monitoring.peopleScreen}
              </Text>
            </Stat>
          )}
          {modes.includes(LineMode.MobileApp) && (
            <Stat>
              <StatValue>{data.peopleWithMobileApp || 0}</StatValue>
              <Text type="caption" preLine={true}>
                {tr.monitoring.peopleWithMobileApp}
              </Text>
            </Stat>
          )}
          {modes.includes(LineMode.PaperTicket) && (
            <Stat>
              <StatValue>{data.peopleWithPaperTicket || 0}</StatValue>
              <Text type="caption" preLine={true}>
                {tr.monitoring.peopleWithPaperTicket}
              </Text>
            </Stat>
          )}
          {modes.includes(LineMode.WebFrame) && (
            <Stat>
              <StatValue>{data.peopleWithWebView || 0}</StatValue>
              <Text type="caption" preLine={true}>
                {tr.monitoring.peopleWithWebView}
              </Text>
            </Stat>
          )}
          {line?.allowFutureAppointments && (
            <Stat>
              <StatValue>{data.personsWithRdv || 0}</StatValue>
              <Text type="caption" preLine={true}>
                {tr.monitoring.appointments}
              </Text>
            </Stat>
          )}
          {data.personsCount !== undefined && data.personsCount !== null && (
            <Stat>
              <StatValue>{data.personsCount || 0}</StatValue>
              <Text type="caption" preLine={true}>
                {tr.monitoring.peopleTotal}
              </Text>
            </Stat>
          )}
        </Stats>
      </Container>
      {!!data?.positions?.length && (
        <div>
          <Divider />
          <Form title={tr.monitoring.moreInfo} expandable expanded={selected} onToggle={handleExpandSlot}>
            <LineSlotPositons
              positions={data?.positions}
              line={line}
              serverNow={serverNow}
              timeslot={timeslot}
              selectedPositionId={selectedPositionId}
              shopId={shopId}
              lineId={lineId}
              checkpoints={checkpoints}
              positionClickHandler={selectPositionChange}
              onRemove={handleDeletePosition}
              onStateChange={handleStateChange}
              onUpdate={handleUpdatePosition}
              onDuplicatePosition={props.onDuplicatePosition}
              onClose={props.onCloseActions}
            />
          </Form>
        </div>
      )}
    </Card>
  )
}
