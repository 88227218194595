import { Controller } from 'react-hook-form'
import { ControlProps } from '.'
import { IntlPhoneInput } from 'features/intl-phone-input'

export function PhoneControl(props: ControlProps) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field: f, fieldState }) => (
        <IntlPhoneInput
          label={props.label}
          placeholder={props.label}
          value={f.value}
          onChange={f.onChange}
          fullWidth
          attention={(props.isMandatory && !f.value) || !!fieldState.error}
          helperText={fieldState.error?.message}
        />
      )}
    />
  )
}
