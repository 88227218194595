import { AdditionalTypes, AdditionalTypesValues, ComboItem } from 'shared/models'
import { TextField } from 'shared/ui-kit/text-field'
import { InputTranslation } from 'features/input-translation'
import { IconButton, Typography } from '@mui/material'
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Delete as RemoveIcon,
  Add as AddIcon
} from '@mui/icons-material'
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd'
import { useEffect, useRef, useState } from 'react'
import type { Identifier, XYCoord } from 'dnd-core'
import { useTranslation } from 'shared/i18n/translate'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { Button } from '@mui/material'
import {
  ItemContainer,
  MoreSettingsContainer,
  AdditionalActions,
  AdditionalTitle,
  Accordion,
  AccordionTitle,
  AccordionActions,
  ErrorMessage
} from './styled'
import { isPositiveNumber } from 'shared/utils/string-test'
import Toggle from 'shared/ui-kit/toggle-wrap/toggle'
import { WithHelper } from 'features/element-with-helper'
import Dropdown from 'shared/ui-kit/dropdown'
import { ComboList } from './combo-list'
import { ToggleList } from 'shared/ui-kit/toggle-list'
import { DragItem, ItemListProps, ValidationType } from './types'
import { validationByType } from './utils/validation-types'
import Editor from '@monaco-editor/react'
import TextFieldMUI from '@mui/material/TextField'

export function AdditionalDndItem({
  index,
  additional,
  id,
  onChange,
  onRemove,
  moveCard,
  brandId,
  services,
  additionals,
  placeId,
  requestParticipantsNumber,
  asapSupported
}: ItemListProps) {
  const { tr } = useTranslation()

  const [moreSettingsDialog, setMoreSettingsDialog] = useState(false)
  const [validationType, setValidationType] = useState<ValidationType>(
    !additional.validationRegexp
      ? 'none'
      : validationByType.find((el) => el.regexp === additional.validationRegexp)?.id || 'regexp'
  )

  const {
    additionalFieldName,
    shortName,
    description,
    fieldType,
    listOfValues,
    addComboItem,
    isMandatory,
    valueIsUnique,
    provideToCopy,
    populateValueFromScan,
    extractScanValueRegularExpression,
    extractScanValueRegularExpressionMtchGroup,
    minimumContentLength,
    maximumContentLength,
    validationRegularExpression,
    validationRegularExpressionMessage,
    acceptedFileTypes,
    acceptedFileTypesDesc,
    memorizeValue,
    additionalKeysUniqueError,
    delayForConfirmation,
    confirmationDelayInSec,
    secondsHint,
    displayBasingOnWaitingTime,
    minWaitingTimeInMin,
    maxWaitingTimeInMin,
    visibilityAndValidationExpression,
    stopJoinProcessAfterDisplay
  } = tr.lineEdit

  useEffect(() => {
    const newValidationRegexp = validationByType.find((el) => el.id === validationType)

    const currentRegexp = additional.validationRegexp || ''

    if (validationType !== 'regexp' && !!newValidationRegexp && currentRegexp !== newValidationRegexp.regexp) {
      const newValue = { ...additional }
      newValue.validationRegexp = newValidationRegexp.regexp
      onChange(newValue)
    }
  }, [validationType])

  useEffect(() => {
    if (!additional.confirmationDelayInSec && !!additional.delayForConfirmation) {
      onChange({ ...additional, delayForConfirmation: false })
    }

    if (!!additional.confirmationDelayInSec && !additional.delayForConfirmation) {
      onChange({ ...additional, delayForConfirmation: true })
    }
  }, [moreSettingsDialog])

  const { chars, deleteButton } = tr.entityEdit

  const dragRef = useRef<HTMLDivElement>(null)

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'additionals',
    collect(monitor) {
      return { handlerId: monitor.getHandlerId() }
    },
    hover(item: DragItem, monitor) {
      if (!dragRef.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = dragRef.current?.getBoundingClientRect()

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      const clientOffset = monitor.getClientOffset()

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveCard(dragIndex, hoverIndex)
      item.index = hoverIndex
    }
  })

  function handleMoreSettings() {
    setMoreSettingsDialog((curr) => !curr)
  }

  function handleShortNameChange(value: string) {
    const newValue = { ...additional }
    newValue.shortName = value
    onChange(newValue)
  }

  function handleLabelChange(value: string) {
    const newValue = { ...additional }
    newValue.label = value
    onChange(newValue)
  }

  function handleDescriptionChange(value: string) {
    const newValue = { ...additional }
    newValue.description = value
    onChange(newValue)
  }

  function handleQrRegexpChange(value: string) {
    const newValue = { ...additional }
    newValue.qrRegexp = value
    onChange(newValue)
  }

  function handleQrRegexpGroupNumberChange(value: string) {
    const newValue = { ...additional }
    newValue.qrRegexpGroupNumber = value && value !== '' ? Number(value) : undefined
    onChange(newValue)
  }

  function handleMaxContentLengthChange(value: string) {
    const newValue = { ...additional }
    newValue.maxContentLength = value && value !== '' ? Number(value) : undefined
    onChange(newValue)
  }

  function handleMinContentLengthChange(value: string) {
    const newValue = { ...additional }
    newValue.minContentLength = value && value !== '' ? Number(value) : undefined
    onChange(newValue)
  }

  function handleValidationRegexpChange(value: string) {
    const newValue = { ...additional }
    newValue.validationRegexp = value
    onChange(newValue)
  }

  function handleValidationErrorMessageChange(value: string) {
    const newValue = { ...additional }
    newValue.validationErrorMessage = value
    onChange(newValue)
  }

  function handleIsMandatoryChange() {
    const newValue = { ...additional }
    newValue.isMandatory = !newValue.isMandatory
    onChange(newValue)
  }

  function handleDelayForConfirmationChange() {
    const newValue = { ...additional }
    newValue.delayForConfirmation = !newValue.delayForConfirmation

    if (!newValue.delayForConfirmation) {
      newValue.confirmationDelayInSec = undefined
    }

    onChange(newValue)
  }

  function handleStopJoinProcessAfterDisplayChange(_, value: boolean) {
    const newValue = { ...additional }
    newValue.stopJoinProcessAfterDisplay = value
    onChange(newValue)
  }

  function handleConfirmationDelayInSec(value: string) {
    const newValue = { ...additional }
    newValue.confirmationDelayInSec = value && value !== '' ? Number(value) : undefined
    onChange(newValue)
  }

  function handleDisplayBasingOnWaitingTimeChange(_, value: boolean) {
    const newValue = { ...additional }
    newValue.displayBasingOnWaitingTime = value
    onChange(newValue)
  }

  function handleMinWaitingTimeChange(val: string) {
    const newValue = { ...additional }
    newValue.minWaitingTimeInMin = val && val !== '' ? Number(val) : undefined
    onChange(newValue)
  }

  function handleMaxWaitingTimeChange(val: string) {
    const newValue = { ...additional }
    newValue.maxWaitingTimeInMin = val && val !== '' ? Number(val) : undefined
    onChange(newValue)
  }

  function handleIsUniqueChange() {
    const newValue = { ...additional }
    newValue.isUnique = !newValue.isUnique
    onChange(newValue)
  }

  function handleProvideToCopyChange() {
    const newValue = { ...additional }
    newValue.displayCopyButton = !newValue.displayCopyButton
    onChange(newValue)
  }

  function handlePopulateForScanChange() {
    const newValue = { ...additional }
    newValue.populateForScan = !newValue.populateForScan
    newValue.qrRegexp = ''
    newValue.qrRegexpGroupNumber = undefined

    onChange(newValue)
  }

  function handleAcceptedFileTypesChange(value: string) {
    const newValue = { ...additional }
    newValue.acceptedFileTypes = value
    onChange(newValue)
  }

  function onAddComboItem() {
    const newValue = { ...additional }
    const newComboItems = [...(newValue.comboItems || []), { title: '', value: '', newId: Date.now() }]
    newValue.comboItems = newComboItems
    onChange(newValue)
  }

  function handlememorizeValueChange() {
    const newValue = { ...additional }
    newValue.memorizeValue = !newValue.memorizeValue
    onChange(newValue)
  }
  function handleAllServiceChange(val: boolean) {
    const newValue = { ...additional }
    newValue.availableForServices = val ? null : services.map((x) => x.id || '')
    onChange(newValue)
  }

  function handleDisplayByParticipantNumber(val: boolean) {
    const newValue = { ...additional }
    newValue.displayByParticipantNumber = val
    onChange(newValue)
  }

  function handleMinParticipantNumberChange(val: string) {
    const newValue = { ...additional }
    newValue.minParticipantNumber = val && val !== '' ? Number(val) : undefined
    onChange(newValue)
  }

  function handleMaxParticipantNumberChange(val: string) {
    const newValue = { ...additional }
    newValue.maxParticipantNumber = val && val !== '' ? Number(val) : undefined
    onChange(newValue)
  }

  function handleVisibilityAndValidationExpressionChange(val?: string) {
    const newValue = { ...additional }
    newValue.visibilityAndValidationExpression = val || ''
    onChange(newValue)
  }

  function handleServiceChange(val: (number | string)[]) {
    const newValue = { ...additional }
    newValue.availableForServices = (val || []).filter(Boolean)
    onChange(newValue)
  }

  function handleComboChange(list: ComboItem[]) {
    const newValue = { ...additional }
    newValue.comboItems = list
    onChange(newValue)
  }

  function handleAdditionalTypeChange(value?: AdditionalTypes) {
    const newValue = { ...additional }
    newValue.type = value
    onChange(newValue)
  }

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'additionals',
    item: () => {
      return { id, index }
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging()
    })
  })

  preview(drop(dragRef))

  const opacity = isDragging ? 0 : 1

  const keyDuplicated = (additionals || []).filter((el) => el.shortName === additional.shortName).length > 1

  const comboItemsInvalid = (additional.comboItems || []).filter((item) => !item.value || !item.title)?.length > 0
  const comboInvalid = comboItemsInvalid && additional.type === 'Combo'

  return (
    <>
      <ItemContainer style={{ opacity, background: comboInvalid ? '#ff5b4f16' : '#fff' }} ref={dragRef}>
        <AdditionalTitle>
          <InputTranslation
            inputValue={additional.label}
            setInputValue={handleLabelChange}
            brandId={brandId}
            placeId={placeId}
          >
            <TextField
              style={{ marginTop: '1rem', marginRight: '4px' }}
              placeholder={additionalFieldName}
              label={additionalFieldName}
              value={additional.label}
              onChange={handleLabelChange}
              message={`${additional.label ? additional.label.length : 0}/100 ${chars}`}
              test={(v) => v.length < 101}
              invalid={!additional.label}
            />
          </InputTranslation>
          <TextField
            placeholder={shortName}
            label={shortName}
            value={additional.shortName}
            onChange={handleShortNameChange}
            message={`${additional.shortName ? additional.shortName.length : 0}/100 ${chars}`}
            test={(v) => v.length < 101}
            invalid={keyDuplicated || !additional.shortName}
          />
        </AdditionalTitle>
        <AdditionalActions>
          <div ref={drag}>
            <DragIndicatorIcon style={{ opacity: '0.2', cursor: 'move' }} data-handler-id={handlerId} />
          </div>
          <IconButton size="small" onClick={handleMoreSettings}>
            {moreSettingsDialog ? <KeyboardArrowUpIcon fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small" />}
          </IconButton>
        </AdditionalActions>
      </ItemContainer>
      {keyDuplicated && <ErrorMessage>* {additionalKeysUniqueError}</ErrorMessage>}
      {moreSettingsDialog && !isDragging && (
        <MoreSettingsContainer>
          <InputTranslation
            inputValue={additional.description || ''}
            setInputValue={handleDescriptionChange}
            brandId={brandId}
            placeId={placeId}
          >
            <TextFieldMUI
              label={description}
              placeholder={description}
              value={additional.description || ''}
              onChange={(ev) => handleDescriptionChange(ev.target.value)}
              multiline
              fullWidth
              InputLabelProps={{ shrink: true }}
              helperText={`${additional.description ? additional.description.length : 0}/1000 ${chars}`}
              maxRows={15}
            />
          </InputTranslation>
          <WithHelper tooltipKey="additionalType" tag="line">
            <Dropdown
              style={{ marginTop: '0.5rem', marginBottom: '1rem' }}
              ensureValue
              label={fieldType}
              value={additional.type}
              data={AdditionalTypesValues}
              dataConverter={(item) => ({ value: item, text: tr.additionalTypes[item] })}
              onChange={handleAdditionalTypeChange}
              fullWidth
            />
          </WithHelper>
          {['Combo'].includes(additional?.type || '') && (
            <Accordion style={{ margin: '1rem 0' }}>
              <AccordionTitle>{listOfValues}</AccordionTitle>
              <ComboList
                additionalId={additional.id || additional.newId || ''}
                items={additional.comboItems || []}
                onChange={handleComboChange}
                brandId={brandId}
                placeId={placeId}
              />
              <AccordionActions>
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<AddIcon fontSize="small" />}
                  onClick={onAddComboItem}
                >
                  {addComboItem}
                </Button>
              </AccordionActions>
            </Accordion>
          )}
          {['Instruction'].includes(additional?.type || '') && (
            <>
              <Toggle
                toggled={additional.stopJoinProcessAfterDisplay}
                label={stopJoinProcessAfterDisplay}
                onToggle={handleStopJoinProcessAfterDisplayChange}
                withHelper={true}
                tag="line"
                tooltipKey="stopJoinProcessAfterDisplay"
                style={{ marginBottom: '0.5rem' }}
              />
              <Toggle
                toggled={additional.delayForConfirmation}
                label={delayForConfirmation}
                onToggle={handleDelayForConfirmationChange}
                withHelper={true}
                tag="line"
                tooltipKey="delayForConfirmation"
                style={{ marginBottom: '0.5rem' }}
              />
              {additional.delayForConfirmation && (
                <TextField
                  placeholder={confirmationDelayInSec}
                  label={confirmationDelayInSec}
                  value={String(additional.confirmationDelayInSec || '')}
                  type="number"
                  onChange={handleConfirmationDelayInSec}
                  test={isPositiveNumber}
                  hint={secondsHint}
                />
              )}
              {asapSupported && (
                <>
                  <Toggle
                    toggled={additional.displayBasingOnWaitingTime}
                    label={displayBasingOnWaitingTime}
                    onToggle={handleDisplayBasingOnWaitingTimeChange}
                    withHelper={true}
                    tag="line"
                    tooltipKey="displayBasingOnWaitingTime"
                    style={{ marginBottom: '0.5rem' }}
                  />
                  {additional.displayBasingOnWaitingTime && (
                    <div style={{ display: 'flex', gap: '1rem' }}>
                      <TextField
                        label={minWaitingTimeInMin}
                        placeholder={minWaitingTimeInMin}
                        value={String(additional.minWaitingTimeInMin || '')}
                        onChange={handleMinWaitingTimeChange}
                        test={isPositiveNumber}
                        hint={tr.lineEdit.timeHint}
                        fullWidth
                      />
                      <TextField
                        label={maxWaitingTimeInMin}
                        placeholder={maxWaitingTimeInMin}
                        value={String(additional.maxWaitingTimeInMin || '')}
                        onChange={handleMaxWaitingTimeChange}
                        test={isPositiveNumber}
                        hint={tr.lineEdit.timeHint}
                        fullWidth
                      />
                    </div>
                  )}
                </>
              )}
            </>
          )}
          {['Number', 'String', 'Date', 'Combo', 'Phone', 'Document'].includes(additional?.type || '') && (
            <Toggle
              toggled={additional.isMandatory}
              label={isMandatory}
              onToggle={handleIsMandatoryChange}
              withHelper={true}
              tag="line"
              tooltipKey="additionalIsMandatory"
              style={{ marginBottom: '0.5rem' }}
            />
          )}
          {['Number', 'String', 'Date', 'Combo', 'Phone'].includes(additional?.type || '') && (
            <Toggle
              toggled={additional.displayCopyButton}
              label={provideToCopy}
              onToggle={handleProvideToCopyChange}
              withHelper
              tag="line"
              tooltipKey="provideToCopy"
              style={{ marginBottom: '0.5rem' }}
            />
          )}
          {['String'].includes(additional?.type || '') && (
            <Toggle
              toggled={additional.isUnique}
              label={valueIsUnique}
              onToggle={handleIsUniqueChange}
              withHelper={true}
              tag="line"
              tooltipKey="additionalIsUnique"
              style={{ marginBottom: '0.5rem' }}
            />
          )}
          {['Number', 'String', 'Date', 'Combo', 'Phone', 'Switch'].includes(additional?.type || '') && (
            <Toggle
              toggled={additional.populateForScan}
              label={populateValueFromScan}
              onToggle={handlePopulateForScanChange}
              withHelper={true}
              tag="line"
              tooltipKey="additionalPopulateForScan"
              style={{ marginBottom: '0.5rem' }}
            />
          )}
          {['Document'].includes(additional?.type || '') && (
            <TextField
              placeholder={acceptedFileTypes}
              label={acceptedFileTypes}
              value={additional.acceptedFileTypes || ''}
              onChange={handleAcceptedFileTypesChange}
              message={acceptedFileTypesDesc}
              test={(v) => v.length < 80}
            />
          )}
          {!!additional.populateForScan && (
            <TextField
              placeholder={extractScanValueRegularExpression}
              label={extractScanValueRegularExpression}
              value={additional.qrRegexp || ''}
              onChange={handleQrRegexpChange}
              test={(v) => v.length < 80}
            />
          )}
          {!!additional.populateForScan && (
            <TextField
              placeholder={extractScanValueRegularExpressionMtchGroup}
              label={extractScanValueRegularExpressionMtchGroup}
              value={String(additional.qrRegexpGroupNumber ?? '')}
              type="number"
              onChange={handleQrRegexpGroupNumberChange}
              test={isPositiveNumber}
            />
          )}
          {['String'].includes(additional?.type || '') && (
            <TextField
              placeholder={minimumContentLength}
              label={minimumContentLength}
              value={String(additional.minContentLength || '')}
              type="number"
              onChange={handleMinContentLengthChange}
              test={isPositiveNumber}
            />
          )}
          {['String', 'Document'].includes(additional?.type || '') && (
            <TextField
              placeholder={maximumContentLength}
              label={maximumContentLength}
              value={String(additional.maxContentLength || '')}
              type="number"
              onChange={handleMaxContentLengthChange}
              test={isPositiveNumber}
              message={additional.type === 'Document' ? 'bytes' : undefined}
            />
          )}
          {['String'].includes(additional?.type || '') && (
            <>
              <WithHelper tooltipKey="additionalValidationType" tag="line">
                <Dropdown
                  style={{ marginTop: '0.5rem', marginBottom: '1rem' }}
                  ensureValue
                  label={tr.lineEdit.validationType}
                  value={validationType}
                  data={validationByType}
                  dataConverter={(item) => ({ value: item.id, text: tr.lineEdit.validationTypes[item.id] })}
                  onChange={setValidationType}
                  fullWidth
                />
              </WithHelper>
              {validationType === 'regexp' && (
                <TextField
                  placeholder={validationRegularExpression}
                  label={validationRegularExpression}
                  value={additional.validationRegexp || ''}
                  onChange={handleValidationRegexpChange}
                  test={(v) => v.length < 80}
                />
              )}
              {validationType !== 'none' && (
                <TextField
                  placeholder={validationRegularExpressionMessage}
                  label={validationRegularExpressionMessage}
                  value={additional.validationErrorMessage || ''}
                  onChange={handleValidationErrorMessageChange}
                  test={(v) => v.length < 80}
                />
              )}
            </>
          )}
          {['Number', 'String', 'Switch', 'Date', 'Combo', 'Phone'].includes(additional?.type || '') && (
            <Toggle
              toggled={additional.memorizeValue || false}
              label={memorizeValue}
              onToggle={handlememorizeValueChange}
              withHelper={true}
              tag="line"
              tooltipKey="memorizeValue"
            />
          )}
          {['Number', 'String', 'Switch', 'Date', 'Combo', 'Phone', 'Document', 'Instruction'].includes(
            additional?.type || ''
          ) &&
            !!requestParticipantsNumber && (
              <Toggle
                toggled={additional.displayByParticipantNumber}
                label={tr.lineEdit.basedByParticipants}
                onToggle={(_, val) => handleDisplayByParticipantNumber(val)}
                withHelper={true}
                tag="line"
                tooltipKey="additionalDisplayByParticipantNumber"
              />
            )}
          {additional.displayByParticipantNumber && (
            <div style={{ display: 'flex', gap: '1rem', marginTop: '0.5rem' }}>
              <TextField
                placeholder={tr.lineEdit.minimumParticipants}
                label={tr.lineEdit.minimumParticipants}
                value={String(additional.minParticipantNumber || '')}
                type="number"
                onChange={handleMinParticipantNumberChange}
                test={isPositiveNumber}
                fullWidth
              />
              <TextField
                placeholder={tr.lineEdit.maximumParticipants}
                label={tr.lineEdit.maximumParticipants}
                value={String(additional.maxParticipantNumber || '')}
                type="number"
                onChange={handleMaxParticipantNumberChange}
                test={isPositiveNumber}
                fullWidth
              />
            </div>
          )}
          {!!services?.length && (
            <Toggle
              toggled={!additional.availableForServices}
              label={tr.checkpointMonitoring.allServices}
              onToggle={(_, val) => handleAllServiceChange(val)}
              withHelper={true}
              tag="line"
              tooltipKey="additionalAllServices"
            />
          )}
          {!!services?.length && !!additional.availableForServices && (
            <ToggleList
              attention={false}
              label={tr.checkpointMonitoring.services}
              list={services}
              value={additional.availableForServices || []}
              labelExtractor={(item) => (item ? item.displayName || item.name : '')}
              onChange={handleServiceChange}
              compare={(valueItem, listItem) => String(valueItem) === String(listItem.id)}
              valueExtractor={(item) => item.id || ''}
            />
          )}
          <div style={{ margin: '1rem 0' }}>
            <Typography variant="caption" color="GrayText">
              {visibilityAndValidationExpression}
            </Typography>
            <Editor
              height="120px"
              language="javascript"
              onChange={handleVisibilityAndValidationExpressionChange}
              value={additional.visibilityAndValidationExpression || ''}
            />
          </div>
          <div style={{ marginTop: '1.5rem' }}>
            <Button variant="outlined" size="small" startIcon={<RemoveIcon fontSize="small" />} onClick={onRemove}>
              {deleteButton}
            </Button>
          </div>
        </MoreSettingsContainer>
      )}
    </>
  )
}
