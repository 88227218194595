import { useEffect, useState } from 'react'
import { TranslationEdit } from './dumb/translation-edit'
import { index, translations } from 'pages/nav'
import { useTranslation } from 'shared/i18n/translate'
import { getIsolatedBrandList } from 'store/actions/brand-action-creators'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { showMessage, MessageTypes } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import CircularProgress from '@mui/material/CircularProgress'
import { CenterContainer } from 'shared/ui-kit/center-container'
import Container from 'shared/ui-kit/container'
import { getBrandListSelector } from 'store/selectors/brandListSelectors'
import { TextTranslateModel } from 'shared/models'
import { useHistory, useParams } from 'react-router'
import { translations as translationsApi } from 'shared/api'
import { useAppDispatch, useAppSelector } from 'store'

function SmartTranslationEdit() {
  const params = useParams<{ translationId?: string }>()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { tr } = useTranslation()
  const brands = useAppSelector(getBrandListSelector)
  const [data, setData] = useState<TextTranslateModel>()
  const [config, setConfig] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [processing, setProcessing] = useState(false)

  const isNew = !params.translationId

  useEffect(() => {
    if (!brands) {
      dispatch(getIsolatedBrandList())
    }

    fetchData()
    initBreadcrumbs()
  }, [])

  async function fetchData() {
    setLoading(true)

    try {
      const [config, res] = await Promise.all([
        translationsApi.getTranslationConfig(),
        translationsApi.getTranslation(params.translationId)
      ])

      setConfig(config.data)
      setData(res.data as TextTranslateModel)
      setLoading(false)
    } catch (err) {
      history.push(index())
    }
  }

  function initBreadcrumbs() {
    const crumb = getCrumbs(tr.breadcrumbs)

    dispatch(
      setBreadcrumbs(
        params.translationId
          ? [crumb.home(), crumb.translations(), crumb.editTranslation([params.translationId])]
          : [crumb.home(), crumb.translations(), crumb.newTranslation()]
      )
    )
  }

  async function handleSave(data: TextTranslateModel) {
    setProcessing(true)

    if (isNew) {
      try {
        await translationsApi.createTranslation(data)
        dispatch(showMessage(tr.translationOperationMessages.createSuccess, MessageTypes.Success))
        history.push(translations())
      } catch (err) {
        dispatch(showMessage(tr.translationOperationMessages.createError, MessageTypes.Error))
        setProcessing(false)
      }
    } else {
      try {
        await translationsApi.updateTranslation(data)
        dispatch(showMessage(tr.translationOperationMessages.updateSuccess, MessageTypes.Success))
        history.push(translations())
      } catch (err) {
        dispatch(showMessage(tr.translationOperationMessages.updateError, MessageTypes.Error))
        setProcessing(false)
      }
    }
  }

  async function handleDelete(id: string | number) {
    setProcessing(true)

    try {
      await translationsApi.deleteTranslation(id)
      dispatch(showMessage(tr.translationOperationMessages.deleteSuccess, MessageTypes.Success))
      history.push(translations())
    } catch (err) {
      dispatch(showMessage(tr.translationOperationMessages.deleteError, MessageTypes.Error))
      setProcessing(false)
    }
  }

  if (loading) {
    return (
      <CenterContainer>
        <CircularProgress color="secondary" />
      </CenterContainer>
    )
  }

  if (!brands || !config || !data) {
    return null
  }

  return (
    <Container>
      <TranslationEdit
        data={data}
        brands={brands}
        config={config}
        creation={isNew}
        onSave={handleSave}
        onDelete={handleDelete}
        processing={processing}
      />
    </Container>
  )
}

export default SmartTranslationEdit
