import styled from '@emotion/styled'

const PersonsCountStyled = styled.div`
  color: #404040;
`

const PersonsContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
`

export { PersonsCountStyled, PersonsContainer }
