import styled from '@emotion/styled'
import { InactiveReason } from './utils/inactive-reason'

const SearchContainer = styled.div`
  padding: 1rem 1rem 0.5rem;
`

const MonitoringContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  padding: 0 0.5rem 1rem;
  border-bottom: solid 1px #e4e4e4;
`

const InactiveText = styled.div`
  font-size: 1.5rem;
  color: #00000052;
  margin: 1rem;
  text-align: center;
`

const InactiveReasonStyled = styled(InactiveReason)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.3rem;
  font-size: 1rem;
`

const Progressions = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`

const StatWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
`

const StatElement = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`

const ListAvatarImage = styled.div<{ $url: string }>`
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 1rem;
  background-image: url(${(props) => props.$url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
  background-color: #f1f1f1;
`

export {
  SearchContainer,
  MonitoringContainer,
  InactiveText,
  InactiveReasonStyled,
  Progressions,
  StatWrapper,
  StatElement,
  ListAvatarImage
}
