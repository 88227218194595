import { useMemo, useState } from 'react'
import { Form, FormGroup } from 'shared/ui-kit/form'
import { TextField } from 'shared/ui-kit/text-field'
import { DatePicker } from 'features/date'
import { useTranslation } from 'shared/i18n/translate'
import { isPositiveNumber, isPositiveInteger } from 'shared/utils/string-test'
import Dropdown from 'shared/ui-kit/dropdown'
import TimePickerTimestamp from 'features/time/time-picker-timestamp'
import { displayNumAsDouble } from 'shared/utils/formatters'
import Card from 'shared/ui-kit/card'
import Toggle from 'shared/ui-kit/toggle-wrap'
import { ActionOnRegistrationPlateReading, AdjustmentApplyMode, CounterModel } from 'shared/models'
import { Button, Divider } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { CounterEditProps } from '../types'
import { ActionText, ActionsWrapper, ButtonWithTextWrapper, ConfirmationActions } from 'shared/ui-kit/action-wrapper'
import DeleteIcon from '@mui/icons-material/Delete'
import TextFieldMUI from '@mui/material/TextField'

function CounterEdit(props: CounterEditProps) {
  const { tr } = useTranslation()
  const [isConfirmDelete, setConfirmDelete] = useState(false)

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(),
        comments: Yup.string().max(500),
        serialNumber: Yup.string().required(),
        alarmOutputName: Yup.string().when('type', {
          is: 'registrationPlatesReadingCamera',
          then: (schema) =>
            schema.when('sendOpenGateSignalInCaseOfPositiveIssue', {
              is: true,
              then: (schema) => schema.required()
            })
        })
      }),
    []
  )

  const { control, handleSubmit, formState, watch, trigger } = useForm<CounterModel>({
    resolver: yupResolver(validationSchema as any),
    defaultValues: props.data,
    mode: 'onChange'
  })

  function handleTryDelete() {
    setConfirmDelete(true)
  }

  function handleCancelDelete() {
    setConfirmDelete(false)
  }

  function handleConfirmDelete() {
    props.onDelete(props.data.id)
  }

  const isValid = !Object.keys(formState.errors).length

  return (
    <form onSubmit={handleSubmit(props.onSave)}>
      <Card>
        <Form>
          <FormGroup>
            <Controller
              name="name"
              control={control}
              render={({ field: f }) => (
                <TextField
                  label={tr.counters.name}
                  placeholder={tr.counters.name}
                  autofocus={true}
                  value={f.value}
                  onChange={f.onChange}
                  attention={!f.value}
                  message={`${f.value?.length || 0}/100 ${tr.entityEdit.chars}`}
                  test={(v) => v.length < 101}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              name="type"
              control={control}
              render={({ field: f }) => (
                <Dropdown
                  ensureValue
                  style={{ width: '100%' }}
                  label={tr.counters.type}
                  value={f.value}
                  data={[
                    'infraredBidirectional',
                    'probeRequestsRecorder',
                    'infraredMonodirectional',
                    'camera3d',
                    'registrationPlatesReadingCamera'
                  ]}
                  dataConverter={(item) => ({
                    value: item,
                    text: tr.counters[item.toLowerCase()]
                  })}
                  onChange={(val) => {
                    f.onChange(val)
                    trigger()
                  }}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              name="serialNumber"
              control={control}
              render={({ field: f }) => (
                <TextField
                  label={tr.counters.serialNumber}
                  placeholder={tr.counters.serialNumber}
                  value={f.value}
                  onChange={f.onChange}
                  attention={!f.value}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              name="macAddress"
              control={control}
              render={({ field: f }) => (
                <TextField
                  label={tr.counters.macAddress}
                  placeholder={tr.counters.macAddress}
                  value={f.value}
                  onChange={f.onChange}
                />
              )}
            />
          </FormGroup>
          {watch('type') !== 'probeRequestsRecorder' && (
            <FormGroup>
              <Controller
                name="supply"
                control={control}
                render={({ field: f }) => (
                  <Dropdown
                    ensureValue
                    label={tr.counters.supply}
                    value={f.value}
                    data={['electricity', 'battery']}
                    style={{ width: '100%' }}
                    dataConverter={(item) => ({ value: item, text: tr.counters[item] })}
                    onChange={f.onChange}
                  />
                )}
              />
            </FormGroup>
          )}
          <FormGroup>
            <Controller
              name="dataTransmission"
              control={control}
              render={({ field: f }) => (
                <Dropdown
                  ensureValue
                  label={tr.counters.dataTransmission}
                  value={f.value}
                  data={['ethernet', '4G']}
                  style={{ width: '100%' }}
                  dataConverter={(item) => ({ value: item, text: tr.counters[item] })}
                  onChange={f.onChange}
                />
              )}
            />
          </FormGroup>
          <FormGroup style={{ gap: '8px', display: 'flex' }}>
            <Controller
              name="startHour_unixtime"
              control={control}
              render={({ field: f }) => (
                <TimePickerTimestamp
                  style={{ flex: 1 }}
                  label={tr.counters.startHour}
                  value={f.value}
                  onChange={f.onChange}
                  offset
                />
              )}
            />
            <Controller
              name="endHour_unixtime"
              control={control}
              render={({ field: f }) => (
                <TimePickerTimestamp
                  style={{ flex: 1 }}
                  label={tr.counters.endHour}
                  value={f.value}
                  onChange={f.onChange}
                  offset
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              name="noDataAlertTime"
              control={control}
              render={({ field: f }) => (
                <TextField
                  label={tr.counters.noDataAlertTime}
                  placeholder={tr.time.minutesPlaceholder}
                  value={f.value}
                  onChange={f.onChange}
                  test={isPositiveNumber}
                  hint={tr.time.minutesHint}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              name="comments"
              control={control}
              render={({ field: f, fieldState }) => (
                <TextFieldMUI
                  label={tr.counters.comments}
                  placeholder={tr.counters.comments}
                  value={f.value}
                  onChange={f.onChange}
                  multiline
                  fullWidth
                  error={!!fieldState.error}
                  helperText={`${f.value?.length || 0}/500 ${tr.entityEdit.chars}`}
                  InputLabelProps={{ shrink: true }}
                  maxRows={15}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              name="displayOrder"
              control={control}
              render={({ field: f }) => (
                <TextField
                  label={tr.counters.displayOrder}
                  value={f.value}
                  onChange={f.onChange}
                  test={(val) => isPositiveInteger(val) || val === '0'}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              name="installDate"
              control={control}
              render={({ field: f }) => (
                <DatePicker
                  label={tr.counters.installDate}
                  value={f.value}
                  onChange={f.onChange}
                  style={{ width: '100%' }}
                />
              )}
            />
          </FormGroup>
          {watch('supply') === 'battery' && (
            <FormGroup>
              <Controller
                name="lastChargeDate"
                control={control}
                render={({ field: f }) => (
                  <DatePicker
                    label={tr.counters.lastChargeDate}
                    value={f.value}
                    onChange={f.onChange}
                    style={{ width: '100%' }}
                  />
                )}
              />
            </FormGroup>
          )}
          {watch('type') === 'registrationPlatesReadingCamera' && (
            <div>
              <FormGroup>
                <Controller
                  name="actionOnRegistrationPlateReading"
                  control={control}
                  render={({ field: f }) => (
                    <Dropdown
                      ensureValue
                      label={tr.counters.actionOnRegistrationPlateReading}
                      value={f.value}
                      data={[
                        ActionOnRegistrationPlateReading.None,
                        ActionOnRegistrationPlateReading.ForceInPlace,
                        ActionOnRegistrationPlateReading.StartService,
                        ActionOnRegistrationPlateReading.FinishService
                      ]}
                      style={{ width: '100%' }}
                      dataConverter={(item) => ({
                        value: item,
                        text: tr.counters.actionsOnRegistrationPlateReading[item]
                      })}
                      onChange={f.onChange}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Controller
                  name="sendOpenGateSignalInCaseOfPositiveIssue"
                  control={control}
                  render={({ field: f }) => (
                    <Toggle
                      toggled={f.value}
                      label={tr.counters.sendOpenGateSignalInCaseOfPositiveIssue}
                      onToggle={(_, val) => {
                        f.onChange(val)
                        trigger()
                      }}
                    />
                  )}
                />
              </FormGroup>
              {!!watch('sendOpenGateSignalInCaseOfPositiveIssue') && (
                <FormGroup>
                  <Controller
                    name="alarmOutputName"
                    control={control}
                    render={({ field: f, fieldState }) => (
                      <TextField
                        label={tr.counters.alarmOutputName}
                        value={f.value}
                        onChange={f.onChange}
                        attention={!!fieldState.error}
                      />
                    )}
                  />
                </FormGroup>
              )}

              <FormGroup>
                <Controller
                  name="alertOperatorInCaseOfError"
                  control={control}
                  render={({ field: f }) => (
                    <Toggle
                      toggled={f.value}
                      label={tr.counters.alertOperatorInCaseOfError}
                      onToggle={(_, val) => f.onChange(val)}
                    />
                  )}
                />
              </FormGroup>
            </div>
          )}
          {watch('type') !== 'probeRequestsRecorder' && watch('type') !== 'registrationPlatesReadingCamera' && (
            <>
              <Divider />
              <Form title={tr.counters.countGaugeGroup} expandable>
                <FormGroup>
                  <Controller
                    name="realTimeCount"
                    control={control}
                    render={({ field: f }) => (
                      <Toggle
                        toggled={f.value}
                        label={tr.counters.realTimeCount}
                        onToggle={(_, val) => f.onChange(val)}
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup>
                  <Controller
                    name="entryCount"
                    control={control}
                    render={({ field: f }) => (
                      <Toggle toggled={f.value} label={tr.counters.entryCount} onToggle={(_, val) => f.onChange(val)} />
                    )}
                  />
                </FormGroup>
                <FormGroup>
                  <Controller
                    name="exitCount"
                    control={control}
                    render={({ field: f }) => (
                      <Toggle toggled={f.value} label={tr.counters.exitCount} onToggle={(_, val) => f.onChange(val)} />
                    )}
                  />
                </FormGroup>
                <FormGroup>
                  <Controller
                    name="adjustmentApplyMode"
                    control={control}
                    render={({ field: f }) => (
                      <Dropdown
                        ensureValue
                        label={tr.counters.adjustmentApply}
                        value={f.value}
                        data={[
                          AdjustmentApplyMode.None,
                          AdjustmentApplyMode.Entries,
                          AdjustmentApplyMode.Exits,
                          AdjustmentApplyMode.Difference
                        ]}
                        style={{ width: '100%' }}
                        dataConverter={(value) => ({ value, text: tr.counters.adjustmentApplyModes[value] })}
                        onChange={f.onChange}
                      />
                    )}
                  />
                </FormGroup>
                {watch('adjustmentApplyMode') !== AdjustmentApplyMode.None && (
                  <FormGroup>
                    <Controller
                      name="adjustmentRatio"
                      control={control}
                      defaultValue={1.0}
                      render={({ field: f }) => (
                        <TextField
                          label={tr.counters.adjustmentRatio}
                          placeholder={tr.counters.adjustmentRatio}
                          value={displayNumAsDouble(f.value)}
                          onChange={f.onChange}
                          test={(v) => isPositiveNumber(v)}
                        />
                      )}
                    />
                  </FormGroup>
                )}
              </Form>
            </>
          )}
        </Form>
        {!props.creation && (
          <>
            <Divider />
            <Form title={tr.entityEdit.advancedGroup} expandable>
              <FormGroup>
                {!isConfirmDelete && (
                  <ButtonWithTextWrapper>
                    <ActionText>{tr.counters.deleteTitle}</ActionText>
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={handleTryDelete}
                      startIcon={<DeleteIcon />}
                      disabled={props.processing}
                    >
                      {tr.entityEdit.deleteButton}
                    </Button>
                  </ButtonWithTextWrapper>
                )}
                {isConfirmDelete && (
                  <ButtonWithTextWrapper>
                    <ActionText>{tr.entityEdit.confirmDelete}</ActionText>
                    <ConfirmationActions>
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        disabled={props.processing}
                        onClick={handleConfirmDelete}
                      >
                        {tr.entityEdit.yes}
                      </Button>
                      <Button variant="outlined" size="small" disabled={props.processing} onClick={handleCancelDelete}>
                        {tr.entityEdit.no}
                      </Button>
                    </ConfirmationActions>
                  </ButtonWithTextWrapper>
                )}
              </FormGroup>
            </Form>
          </>
        )}
      </Card>
      <ActionsWrapper>
        <Button disabled={!isValid || props.processing} variant="contained" color="primary" size="small" type="submit">
          {props.data.id ? tr.entityEdit.updateButton : tr.entityEdit.createButton}
        </Button>
      </ActionsWrapper>
    </form>
  )
}

export { CounterEdit }
