import rawAllCountries from './raw_countries'

const allCountryCodes = {}

function addCountryCode(iso2, dialCode, priority) {
  if (!(dialCode in allCountryCodes)) {
    allCountryCodes[dialCode] = []
  }
  const index = priority || 0
  allCountryCodes[dialCode][index] = iso2
}

export const allCountries = [].concat(
  ...rawAllCountries.map((country) => {
    const [name, regions, iso2, dialCode, format, priority, areaCodes] = country

    const countryItem = {
      name,
      regions,
      iso2,
      dialCode,
      priority,
      format: format ? `+${new Array(String(dialCode).length).fill('.').join('')} ${format}` : undefined,
      hasAreaCodes: areaCodes
    }

    const areaItems = []

    if (countryItem.hasAreaCodes) {
      areaCodes.forEach((areaCode) => {
        const areaItem = {
          ...countryItem,
          regions,
          dialCode: `${dialCode}${areaCode}`,
          isAreaCode: true
        }

        areaItems.push(areaItem)

        addCountryCode(iso2, areaItem.dialCode)
      })
    }

    addCountryCode(countryItem.iso2, countryItem.dialCode, countryItem.hasAreaCodes)

    return areaItems.length > 0 ? [countryItem, ...areaItems] : [countryItem]
  })
)

function validatePhone(phone = '', canBeEmpty = false) {
  const rawNumber = phone.replace(/[^\d]/g, '')

  if (rawNumber.length === 0 && canBeEmpty) return true

  let findCountries = []

  const findFourSym = allCountries.filter((el) => el.dialCode === rawNumber.slice(0, 4))
  const findThreeSym = allCountries.filter((el) => el.dialCode === rawNumber.slice(0, 3))
  const findTwoSym = allCountries.filter((el) => el.dialCode === rawNumber.slice(0, 2))
  const findOneSym = allCountries.filter((el) => el.dialCode === rawNumber.slice(0, 1))

  if (findFourSym.length) findCountries = findFourSym
  else if (findThreeSym.length) findCountries = findThreeSym
  else if (findTwoSym.length) findCountries = findTwoSym
  else if (findOneSym.length) findCountries = findOneSym

  if (!findCountries.length) return false

  const countryWithFormats = findCountries.filter((el) => !!el.format)

  if (!countryWithFormats.length) return rawNumber.length >= 11

  const format = countryWithFormats[0].format
  const countOfSym = format?.replace(/[^.]/g, '')?.length || 11

  return rawNumber.length === countOfSym
}

export default { allCountries, allCountryCodes }
export { validatePhone }
