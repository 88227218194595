import { useEffect, useState } from 'react'
import BeaconList from './beacon-list/beacon-list'
import { beacon, createBeacon } from 'pages/nav'
import { useTranslation } from 'shared/i18n/translate'
import { useTimeZoneFilter } from 'features/time-zone-filter'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import Add from '@mui/icons-material/Add'
import Card from 'shared/ui-kit/card'
import Container from 'shared/ui-kit/container'
import EmptyScreen from 'shared/ui-kit/empty-screen'
import { useHistory, useParams } from 'react-router'
import { beacon as beaconApi } from 'shared/api'
import { BeaconModel, GetResponseType } from 'shared/models'
import PageLoader from 'shared/ui-kit/page-loader'
import { AppBar, IconButton, Toolbar } from '@mui/material'
import { useDataProvider } from 'features/isolated-data-provider'
import { useTitle } from 'shared/hooks/useTitle'
import { useAppDispatch } from 'store'

type ParentsType = GetResponseType<BeaconModel>['parents']

function SmartBeaconList() {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { placeId } = useParams<{ placeId: string }>()
  const { tr, locale } = useTranslation()
  const [data, setData] = useState<BeaconModel[] | null>(null)
  const [parents, setParents] = useState<ParentsType | null>(null)
  const [disabledBeacons, setDisabledBeacons] = useState<{
    [key: BeaconModel['id']]: boolean
  }>({})

  const { getPlace } = useDataProvider()

  useTitle({ placeName: getPlace(placeId)?.name })

  useTimeZoneFilter(placeId)

  const isLoading = data === null
  const isEmpty = data?.length === 0

  function replaceBeacon(collection: BeaconModel[], newBeacon: BeaconModel) {
    const replaceIndex = collection.findIndex((b) => b.id === newBeacon.id)
    const copy = [...collection]

    if (replaceIndex !== -1) {
      copy[replaceIndex] = newBeacon
    }

    return copy
  }

  function toggleBeacon(toggledBeacon: BeaconModel) {
    const changedBeacon: BeaconModel = { ...toggledBeacon, active: !toggledBeacon.active }

    setData((prevState) => (prevState ? replaceBeacon(prevState, changedBeacon) : prevState))

    setDisabledBeacons((prevState) => ({ ...prevState, [toggledBeacon.id]: true }))

    beaconApi
      .setActiveState(changedBeacon)
      .catch(() => {
        setData((prevState) => (prevState ? replaceBeacon(prevState, toggledBeacon) : prevState))
      })
      .finally(() => {
        setDisabledBeacons((prevState) => ({ ...prevState, [toggledBeacon.id]: false }))
      })
  }

  useEffect(() => {
    beaconApi.getBeaconList(placeId).then((response) => {
      setData(response.data)
      setParents(response.parents)
    })
  }, [])

  useEffect(() => {
    if (!parents?.shop) {
      return
    }

    const { shop } = parents
    const crumb = getCrumbs(tr.breadcrumbs)

    dispatch(
      setBreadcrumbs([
        crumb.home(),
        crumb.places(),
        crumb.place([shop.id as string], shop.name),
        crumb.beacons([shop.id as string])
      ])
    )
  }, [parents, locale])

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <>
      <AppBar position="sticky" color="inherit" elevation={2} style={{ top: '4rem', borderRadius: 0 }}>
        <Toolbar>
          <Container style={{ padding: '0 0.5rem' }}>
            <IconButton onClick={() => history.push(createBeacon(placeId))}>
              <Add />
            </IconButton>
          </Container>
        </Toolbar>
      </AppBar>
      <Container>
        {isEmpty && <EmptyScreen text={tr.beaconList.empty} />}
        {!isEmpty && (
          <Card paperStyle={{ overflow: 'hidden' }}>
            <BeaconList
              data={data}
              onSelect={(data) => {
                history.push(beacon(placeId, String(data.id)))
              }}
              isDisabled={(item) => !!disabledBeacons[item.id]}
              onToggle={(data) => toggleBeacon(data)}
            />
          </Card>
        )}
      </Container>
    </>
  )
}

export default SmartBeaconList
