import styled from '@emotion/styled'

const ScheduleElement = styled.div<{ isToday?: boolean }>`
  height: 48px;
  display: flex;
  align-items: center;
  border-left: solid 3px ${(props) => (props.isToday ? '#db214d' : '#db214d00')};
`

const WeekdaysContainer = styled.div`
  padding: 0.5rem 0;
  border-top: solid 1px #e4e4e4;
`

const ScheduleFlex = styled.div`
  padding: 0 1rem 0 14px;
  flex: 1;
  display: flex;
`

const ScheduleName = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

export { ScheduleElement, WeekdaysContainer, ScheduleFlex, ScheduleName }
