import { useEffect, useState } from 'react'
import * as Nav from 'pages/nav'
import { useTranslation } from 'shared/i18n/translate'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { counter } from 'shared/api'
import { useTimeZoneFilter } from 'features/time-zone-filter'
import { useHistory, useParams } from 'react-router'
import produce from 'immer'
import { CounterModel, GetResponseType } from 'shared/models'
import { CounterList } from './dumb/counter-list'
import PageLoader from 'shared/ui-kit/page-loader'
import Card from 'shared/ui-kit/card'
import Container from 'shared/ui-kit/container'
import AddIcon from '@mui/icons-material/Add'
import { AppBar, IconButton, Toolbar } from '@mui/material'
import { useDataProvider } from 'features/isolated-data-provider'
import { useTitle } from 'shared/hooks/useTitle'
import { useAppDispatch } from 'store'

function SmartCounterList() {
  const [items, setItems] = useState<GetResponseType<CounterModel[]>>()
  const [loading, setLoading] = useState(true)

  const { placeId } = useParams<{ placeId: string }>()
  const history = useHistory()
  const { tr, locale } = useTranslation()
  const dispatch = useAppDispatch()

  const { getPlace } = useDataProvider()
  useTitle({ placeName: getPlace(placeId)?.name })

  useTimeZoneFilter(placeId)

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (!items || !items.parents) {
      return
    }

    const crumb = getCrumbs(tr.breadcrumbs)
    const shop = items.parents.shop
    const crumbs = [
      crumb.home(),
      crumb.places(),
      crumb.place([String(shop?.id)], shop?.name),
      crumb.counters([String(shop?.id)])
    ]

    dispatch(setBreadcrumbs(crumbs))
  }, [items, locale])

  async function fetchData() {
    setLoading(true)

    try {
      const res = await counter.getCounterList({ shopId: placeId })
      setItems(res)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  function handleCounterActiveChange(data) {
    setItems(
      produce((prev: any) => {
        if (!prev) {
          return
        }

        prev.data[data.index].readonly = true
      })
    )

    counter.toggleCounter({ placeId, counterId: data.item.id, isActive: !data.item.isActive }).then(() => {
      setItems(
        produce((prev: any) => {
          if (!prev) {
            return
          }

          const item = prev.data[data.index]
          item.readonly = false
          item.isActive = !item.isActive
        })
      )
    })
  }

  if (loading) {
    return <PageLoader />
  }

  if (items === null) {
    return null
  }

  return (
    <>
      <AppBar position="sticky" color="inherit" elevation={2} style={{ top: '4rem', borderRadius: 0 }}>
        <Toolbar>
          <Container style={{ padding: '0 0.5rem' }}>
            <IconButton onClick={() => history.push(Nav.createCounter(placeId))}>
              <AddIcon />
            </IconButton>
          </Container>
        </Toolbar>
      </AppBar>
      <Container>
        <Card paperStyle={{ overflow: 'hidden' }}>
          <CounterList
            data={items?.data || []}
            onSelect={(data) => {
              history.push(Nav.counter(placeId, String(data.id)))
            }}
            onCounterActiveChange={handleCounterActiveChange}
          />
        </Card>
      </Container>
    </>
  )
}

export default SmartCounterList
