import { useTranslation } from 'shared/i18n/translate'
import { CheckpointStatus } from 'shared/models'
import { useMemo } from 'react'

export function statusTextMap() {
  const { tr } = useTranslation()

  const statusTextMap = useMemo(
    () => ({
      [CheckpointStatus.starting]: tr.checkpointStatus.starting,
      [CheckpointStatus.started]: tr.checkpointStatus.started,
      [CheckpointStatus.paused]: tr.checkpointStatus.paused,
      [CheckpointStatus.finishing]: tr.checkpointStatus.finishing,
      [CheckpointStatus.finished]: tr.checkpointStatus.finished
    }),
    [tr]
  )

  return statusTextMap
}
