import { FormGroup } from 'shared/ui-kit/form'
import styled from '@emotion/styled'

const GridCards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(148px, 1fr));
  grid-gap: 1rem;
  margin: 1rem 0;
`

const DelayTitleWrap = styled.div`
  display: flex;
`

const DelayChips = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
`

const FormGroupLine = styled(FormGroup)`
  display: flex;
  gap: 1rem;
  align-items: center;
`

const DelayControl = styled.div<{ disabled?: boolean }>`
  cursor: ${(props) => (props.disabled ? 'unset' : 'pointer')};
  font-size: 18px;
  line-height: 20px;
  background: ${(props) => (props.disabled ? '#d4d4d4' : '#db214d')};
  color: #fff;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 4px;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'unset')};
`

const HostWrapper = styled.div`
  display: flex;
  gap: 1rem;
`

const HostTime = styled.div`
  text-align: right;
`

const UserItem = styled.div`
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 0 1px;
  transition: background 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  font-weight: 300;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
`

const HostActions = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`

const CustomDelayWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`

export {
  GridCards,
  DelayTitleWrap,
  DelayChips,
  FormGroupLine,
  DelayControl,
  HostWrapper,
  HostTime,
  UserItem,
  HostActions,
  CustomDelayWrapper
}
