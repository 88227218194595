import { useEffect, useMemo, useRef, useState } from 'react'
import { Card, Radio, Button, RadioGroup, FormControlLabel, Typography, TextField } from '@mui/material'
import { FormActionGroup, FormGroup, Form } from 'shared/ui-kit/form'
import { useTranslation } from 'shared/i18n/translate'
import { AttentionMessage } from 'shared/ui-kit/attention-message/attention-message'
import { ToggleList } from 'shared/ui-kit/toggle-list'
import { LineModel, LineModelSimplified, LinesStatisticsModel, ServiceModel } from 'shared/models'
import TimePicker from 'features/time/time-picker-timestamp'
import Toggle from 'shared/ui-kit/toggle-wrap/toggle'
import Autocomplete from 'shared/ui-kit/autocomplete'
import { line as lineApi } from 'shared/api'
import {
  ClosedServicepointShowPositionsFormProps,
  ClosedServicepointShowPositionsReason,
  LineWithStats
} from '../types'
import { getStorageAllServiceKey, getStorageServiceKey } from '../utils'

export function ClosedServicepointShowPositionsForm(props: ClosedServicepointShowPositionsFormProps) {
  const [line, setLine] = useState<LineModel | undefined>(props.defaultLine)
  const [linesStatistics, setLinesStatistics] = useState<LinesStatisticsModel[]>([])
  const [lines, setLines] = useState<LineModelSimplified[]>([])
  const [lineStatsIsLoaded, setLineStatsIsLoaded] = useState(false)

  const linesWithStats: LineWithStats[] = useMemo(() => {
    return (linesStatistics || []).map((stat) => {
      const line = (lines || []).find((l) => String(l.id) === String(stat.lineId))

      return {
        ...stat,
        lineParticipantTermInPluralTemplate: line?.lineParticipantTermInPluralTemplate,
        lineParticipantTermInSingularTemplate: line?.lineParticipantTermInSingularTemplate
      }
    })
  }, [linesStatistics, lines])

  const lineStatConnect = useRef<{ stop: () => void } | null>(null)

  useEffect(() => {
    linesStatisticsSubsctibe(props.shopId)
    fetchLines(props.shopId)

    return () => {
      lineStatConnect.current?.stop()
    }
  }, [])

  async function fetchLines(placeId: string | number) {
    const { data: lines } = await lineApi.getLineListSimplified(placeId)
    setLines(lines)
  }

  function linesStatisticsSubsctibe(placeId: string | number) {
    lineStatConnect.current = lineApi.linesStatisticsChanged({ placeId }, (linesStatistics) => {
      setLinesStatistics(linesStatistics)
      setLineStatsIsLoaded(true)
    })
  }

  const servicesToDisplay = (line?.services ?? []).filter((s) => !s.isPrivate)

  const initialServices = useMemo(() => {
    const storageString = localStorage.getItem(getStorageServiceKey(props.checkpointId)) || ''
    const keys = storageString.split(';')

    const services = (servicesToDisplay || []).filter((service) => keys.includes(String(service.id)))

    return services || []
  }, [line])

  const initialAllService = localStorage.getItem(getStorageAllServiceKey(props.checkpointId)) === 'true' ? true : false

  const [enabledServices, setEnabledServices] = useState<ServiceModel[]>(initialServices)
  const [serviceDuration, setServiceDuration] = useState<number | null>(3600000)
  const [allService, setAllService] = useState<boolean>(initialAllService)

  function handleAllService(_, value: boolean) {
    setAllService(value)
  }

  useEffect(() => {
    if (props.checkpointId) {
      localStorage.setItem(getStorageServiceKey(props.checkpointId), enabledServices.map((el) => el.id).join(';'))
      localStorage.setItem(getStorageAllServiceKey(props.checkpointId), String(allService))
    }
  }, [enabledServices, allService])

  const { tr } = useTranslation()
  const translation = tr.closedServicepointShowPositionsForm

  const values = [
    ClosedServicepointShowPositionsReason.PriorityServiceNeeded,
    ClosedServicepointShowPositionsReason.TooManyVisitors,
    ClosedServicepointShowPositionsReason.HaveSomeFreeTime,
    ClosedServicepointShowPositionsReason.ExpertConsultation
  ]
  const [reason, setReason] = useState<string>('')
  const [comment, setComment] = useState<string>('')
  const [lineId, setLineId] = useState<number>(props.lineId)

  const valueToTranslate = {
    [ClosedServicepointShowPositionsReason.PriorityServiceNeeded]: translation.priorityService(
      tr.getParticipantFormFromTemplates(
        1,
        line?.lineParticipantTermInSingularTemplate,
        line?.lineParticipantTermInPluralTemplate
      )
    ),
    [ClosedServicepointShowPositionsReason.TooManyVisitors]: translation.numberOfVisitors(
      tr.getParticipantFormFromTemplates(
        10,
        line?.lineParticipantTermInSingularTemplate,
        line?.lineParticipantTermInPluralTemplate
      )
    ),
    [ClosedServicepointShowPositionsReason.HaveSomeFreeTime]: translation.opportunityAcceptVisitor(
      tr.getParticipantFormFromTemplates(
        10,
        line?.lineParticipantTermInSingularTemplate,
        line?.lineParticipantTermInPluralTemplate
      )
    ),
    [ClosedServicepointShowPositionsReason.ExpertConsultation]: translation.specialistConsultation(
      tr.getParticipantFormFromTemplates(
        1,
        line?.lineParticipantTermInSingularTemplate,
        line?.lineParticipantTermInPluralTemplate
      )
    )
  }

  const serviceDurationFormatted = serviceDuration
    ? Math.floor(new Date(serviceDuration).setFullYear(1970, 0, 1) / 1000)
    : undefined

  const availableLines = linesWithStats?.filter((line) => line.state !== 'closed' && line.persons > 0)
  const lineIsSelected = !!lineId && !!availableLines.find((el) => el.lineId === lineId)

  async function getLine(id: string | number) {
    if (id) {
      const res = await lineApi.getLine(id, props.shopId)
      setLine(res.data)
    }
  }

  function handleLineIdChange(id?: number | string | null) {
    if (!id && id !== 0) {
      return
    }

    setEnabledServices([])
    setLineId(Number(id))
    setLine(undefined)
    getLine(id)
  }

  return (
    <div>
      <AttentionMessage color="warning" message={translation.formDescription} />
      <Card style={{ overflow: 'unset' }}>
        <Form
          title={translation.formTitle(
            tr.getParticipantFormFromTemplates(
              1,
              line?.lineParticipantTermInSingularTemplate,
              line?.lineParticipantTermInPluralTemplate
            )
          )}
          attention={!reason}
        >
          <FormGroup>
            <RadioGroup value={reason} onChange={(event) => setReason(event.target.value)} row>
              {values.map((x) => (
                <FormControlLabel
                  key={x}
                  value={x}
                  control={<Radio color="primary" />}
                  label={<div style={{ fontSize: '14px', marginLeft: '-4px' }}>{valueToTranslate[x]}</div>}
                  labelPlacement="end"
                />
              ))}
            </RadioGroup>
          </FormGroup>
          <FormGroup>
            <TextField
              label={translation.comment}
              placeholder={translation.comment}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              multiline
              fullWidth
              InputLabelProps={{ shrink: true }}
              maxRows={15}
            />
          </FormGroup>
          <FormGroup>
            <TimePicker
              fullWidth
              attention={!serviceDuration}
              label={translation.serviceDuration}
              value={serviceDuration || undefined}
              onChange={setServiceDuration}
            />
          </FormGroup>
          {lineStatsIsLoaded && (
            <FormGroup>
              <Autocomplete
                fullWidth
                ensureValue
                title={tr.common.line}
                value={lineId}
                list={availableLines}
                style={{ textOverflow: 'ellipsis' }}
                dataConverter={(el: LineWithStats) => {
                  const persons = `${el.persons || 0} ${tr.getParticipantFormFromTemplates(
                    el.persons || 0,
                    el.lineParticipantTermInSingularTemplate,
                    el.lineParticipantTermInPluralTemplate
                  )}`

                  return { text: `${el.lineName}, ${persons}`, value: el.lineId }
                }}
                onChange={handleLineIdChange}
              />
              {availableLines?.length < 1 && (
                <Typography variant="caption" marginTop="4px" color="#db214d">
                  {tr.checkpointMonitoring.noLinesNeedsHelp}
                </Typography>
              )}
            </FormGroup>
          )}
          {lineIsSelected && servicesToDisplay?.length > 0 && (
            <>
              <FormGroup>
                <Toggle toggled={allService} label={tr.checkpointMonitoring.allServices} onToggle={handleAllService} />
              </FormGroup>
              {!allService && (
                <FormGroup>
                  <ToggleList
                    attention={enabledServices.length < 1}
                    label={tr.checkpointMonitoring.services}
                    list={servicesToDisplay}
                    value={enabledServices}
                    labelExtractor={(item) => (item ? item.name : '')}
                    onChange={setEnabledServices}
                    compare={(valueItem, listItem) => valueItem.id === listItem.id}
                  />
                </FormGroup>
              )}
            </>
          )}
          <FormActionGroup style={{ gap: '0.5rem' }}>
            <Button
              onClick={() =>
                props.onSubmit(
                  reason,
                  comment,
                  allService
                    ? servicesToDisplay.map((el) => el.id as number | string)
                    : enabledServices.map((el) => el.id as number | string),
                  serviceDurationFormatted || 0,
                  lineId
                )
              }
              variant="contained"
              color="primary"
              size="small"
              disabled={
                !lineIsSelected ||
                !reason ||
                (Boolean(servicesToDisplay?.length) && enabledServices.length < 1 && !allService) ||
                !serviceDuration
              }
            >
              {translation.start}
            </Button>
            <Button variant="text" color="primary" size="small" onClick={props.onCancel}>
              {tr.checkpointDisableDialog.cancel}
            </Button>
          </FormActionGroup>
        </Form>
      </Card>
    </div>
  )
}
