import ColorLabel from './color-label'
import { ControlLabel } from '../control-label'

type Props = {
  title: string
  color?: string
}

function Color(props: Props) {
  return (
    <>
      <ControlLabel>{props.title}</ControlLabel>
      <ColorLabel readonly={true} color={props.color} />
    </>
  )
}

export default Color
