import { Translations } from 'shared/i18n/translation-types'
import { LineMonitoringModel, PositionState, PositionType } from 'shared/models'
import { sendNotification } from 'shared/utils/notification'
import { PositionQueueType } from 'shared/utils/positions_filters'

export type MonitoringFilters = {
  positionStatusesSelected: PositionState[]
  positionServicesSelected: (number | string)[]
  positionQueueTypeSelected: PositionQueueType[]
  positionTypeSelected: PositionType[]
  termToFilter: string
}

const defaultFilters: MonitoringFilters = {
  positionStatusesSelected: [],
  positionServicesSelected: [],
  positionQueueTypeSelected: [],
  positionTypeSelected: [],
  termToFilter: ''
}

const doctitle = document.title

function changeTitleNotify(title) {
  const marker = setInterval(() => {
    if (doctitle === document.title) {
      document.title = title
    } else {
      document.title = doctitle
    }
  }, 1000)

  function offTitle() {
    clearInterval(marker)
    document.removeEventListener('mousemove', offTitle)
  }

  document.addEventListener('mousemove', offTitle)
}

const called: (string | number)[] = []

function notificationControl(
  resp: LineMonitoringModel,
  backstate: LineMonitoringModel,
  tr: Translations,
  lineName: string
) {
  if (!resp || !backstate) {
    return
  }

  resp.positions.forEach((el) => {
    if (el.state === 'calledToCheckpoint') {
      let find = false
      // if position was called
      backstate.positions.forEach((cl) => {
        if (el.id === cl.id) find = true
        // If the status of the position changed to calledToCheckpoint
        if (el.id === cl.id && el.state !== cl.state && called.indexOf(el.id) === -1) {
          changeTitleNotify(tr.checkpointMonitoring.newCallLine(lineName))
          sendNotification(tr.checkpointMonitoring.newCallLine(lineName), `${lineName}`)
        }
      })
      if (find === false && called.indexOf(el.id) === -1) {
        changeTitleNotify(tr.checkpointMonitoring.newCallLine(lineName))
        sendNotification(tr.checkpointMonitoring.newCallLine(lineName), lineName)
      }
    } else {
      let find = false

      backstate.positions.forEach((cl) => {
        if (el.id === cl.id) {
          find = true
        }
      })

      if (find === false && called.indexOf(el.id) === -1)
        sendNotification(`${tr.checkpointMonitoring.newPosition} ${lineName}`, lineName)
    }
  })
}

const waitingStatuses = [
  PositionState.InLineHere,
  PositionState.ValidForService,
  PositionState.Joined,
  PositionState.InLine
]

export { defaultFilters, notificationControl, called, waitingStatuses }
