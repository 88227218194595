import { AppointmentStatus, PositionState } from 'shared/models'

const PositionStateColors = {
  [PositionState.InService]: '#21db84',
  [PositionState.ValidForService]: '#5b94d6',
  [PositionState.NearCheckpoint]: '#fdd672',
  [PositionState.CalledToCheckpoint]: '#fdd672',
  [PositionState.MovingToCheckpoint]: '#fdd672',
  [PositionState.Joined]: '#5b94d6',
  [PositionState.InLineHere]: '#5b94d6'
}

const AppointmentStateColors = {
  [AppointmentStatus.Cancelled]: '#f2f2f2',
  [AppointmentStatus.Confirmed]: '#2a82c8',
  [AppointmentStatus.Completed]: '#f2f2f2',
  [AppointmentStatus.InProgress]: '#21db84',
  [AppointmentStatus.NoShow]: '#f2f2f2',
  [AppointmentStatus.TransformedToPosition]: '#2a82c8',
  [AppointmentStatus.ToBeCancelled]: '#f44336',
  [AppointmentStatus.Idle]: '#b0dbf4'
}

export { AppointmentStateColors, PositionStateColors }
export default PositionStateColors
