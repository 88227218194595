import { useTranslation } from 'shared/i18n/translate'
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material'
import { VersionDialogProps } from './types'

function VersionDialog({ open, version, onDecline, onSubmit }: VersionDialogProps) {
  const { tr } = useTranslation()

  return (
    <Dialog open={open} onClose={onDecline} PaperProps={{ style: { borderRadius: '0.5rem' } }}>
      <DialogTitle>{tr.version.newVersionAlert}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {tr.version.newVersionAvailable(version)}
          <br />
          {tr.version.refreshPageAccept}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDecline} color="primary">
          {tr.version.no}
        </Button>
        <Button onClick={onSubmit} color="primary" autoFocus>
          {tr.version.yes}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { VersionDialog }
