import { UserModel } from '.'
import { ServiceModel } from '.'

export enum CheckpointStatus {
  starting = 'starting',
  started = 'started',
  paused = 'paused',
  finished = 'finished',
  finishing = 'finishing'
}

export type CheckpointStatusMap<T> = { [key in CheckpointStatus]: T }

export interface CheckpointModel {
  id: number | string
  active: boolean
  lineId: number | string
  lineName: string
  brandId?: string
  name: string
  description: string
  beaconId: number | string
  statusChange?: {
    user: UserModel
    date: number
  }
  status: CheckpointStatus
  capacities?: any
  daysOffCapacities?: any
  isDiscrete?: boolean
  isAnyHourOpened: boolean
  operatingTo: number | null
  operatingFrom: number | null
  reason?: string
  dinnerStartTime?: number
  dinnerStopTime?: number
  hostName?: string
}

export type CapacityObject = {
  from_unixtime: number
  capacity: string
  overbooking: string
  stock: string
}

export type CheckpointOpenState = 'LineHours' | 'WithDelay' | 'AtHours' | 'Always'

export interface SingleCheckpointModel {
  id?: number | string
  lineId: number | string
  beaconId: number | string
  lastUpdate?: {
    date: number
    user?: { firstName: string; secondName: string }
  }
  name: string
  description?: string
  brandId?: string
  capacities?: CapacityObject[]
  specialCapacity?: boolean
}

export interface FullCheckpointModel extends CheckpointModel {
  enabledServices: ServiceModel[]
  services: ServiceModel[]
  hostName: string
  serviceWithPause?: boolean
  pauseEndHour_unixtime?: number
  pauseStartHour_unixtime?: number
  startTime_unixtime?: number
  closingTime_unixtime?: number
  isAllServicesEnabled?: boolean
}

export interface CheckpointHostEditModel {
  id: number | string
  active: boolean
  currentTime: number
  name: string
  status: CheckpointStatus
  statusChange?: {
    user: UserModel
    date: number
  }
  personsComing: number
  personsServed: number
  personsInService: number
  minServiceDurationForOpeningServicePoint?: number
  maxBreakDuration?: number
  hostName: string
  isAnyHourOpened: boolean
  isAllServicesEnabled: boolean
  serviceWithPause: boolean
  closingTime_unixtime?: number
  startTime_unixtime?: number
  pauseStartHour_unixtime?: number
  pauseEndHour_unixtime?: number
  enabledServiceIds: (string | number)[]
  requestCommentOnClosingServicePoint?: boolean
  servicePointCanAlwaysBeOpen?: boolean
  reason?: string | null
}
