import { useState } from 'react'
import { ConfirmItem } from './confirm-item'
import { PositionActionProps } from './types'
import {
  MobilePositionActionStyled,
  PositionActionContentWrapper,
  PositionActionIcon,
  PositionActionStyled
} from '../styled'
import { Button } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import {
  CardView,
  CardViewConfirmationText,
  CardViewConfirmationWrapper,
  CardViewContent,
  PositionActionIntoContainerIcon,
  PositionActionIntoContainerWrapper
} from './styled'

function PositionAction({
  confirmation = false,
  confirmationMessage = '',
  disabled = false,
  onSubmit,
  style,
  children,
  icon,
  isCardView,
  iconIntoContainer
}: PositionActionProps) {
  const { tr } = useTranslation()

  const [confirmMode, setConfirmMode] = useState(false)

  function handleSubmit() {
    if (confirmation && confirmationMessage) {
      setConfirmMode(!confirmMode)
    } else {
      onSubmit?.()
    }
  }

  function handleCacnel() {
    setConfirmMode(!confirmMode)
  }

  function handleConfirm() {
    setConfirmMode(!confirmMode)
    onSubmit?.()
  }

  return (
    <>
      {!isCardView && (
        <PositionActionStyled onClick={handleSubmit} style={style} $disabled={disabled}>
          <ConfirmItem
            open={confirmMode}
            onSubmit={handleConfirm}
            onCancel={handleCacnel}
            message={confirmationMessage}
          >
            {!iconIntoContainer ? (
              <PositionActionContentWrapper>
                <PositionActionIcon>{icon}</PositionActionIcon> {children}
              </PositionActionContentWrapper>
            ) : (
              <PositionActionIntoContainerWrapper>
                <PositionActionIntoContainerIcon>{icon}</PositionActionIntoContainerIcon> {children}
              </PositionActionIntoContainerWrapper>
            )}
          </ConfirmItem>
        </PositionActionStyled>
      )}
      {!!isCardView && (
        <>
          {!confirmMode && (
            <MobilePositionActionStyled $disabled={disabled}>
              <CardView elevation={disabled ? 0 : 1} onClick={handleSubmit}>
                <CardViewContent>
                  {!!icon && <div style={{ minWidth: 20 }}>{icon}</div>}
                  <div>{children}</div>
                </CardViewContent>
              </CardView>
            </MobilePositionActionStyled>
          )}
          {confirmMode && (
            <CardViewConfirmationWrapper>
              <CardViewConfirmationText>{confirmationMessage}</CardViewConfirmationText>
              <Button color="primary" size="small" variant="contained" onClick={handleConfirm}>
                {tr.positionActions.yes}
              </Button>
              <Button color="primary" size="small" variant="outlined" onClick={handleCacnel}>
                {tr.positionActions.no}
              </Button>
            </CardViewConfirmationWrapper>
          )}
        </>
      )}
    </>
  )
}

export default PositionAction
