import axios from 'axios'
import { CounterAPI } from 'shared/models'

const counter: CounterAPI = {
  getCounterList(data, config) {
    return axios.get('getCounterList', { params: data, ...config })
  },
  getCounter(id, placeId, config) {
    return axios.get('getCounter', { params: { id, shopId: placeId }, ...config })
  },
  toggleCounter(data, config) {
    return axios.get('toggleCounter', { params: data, ...config })
  },
  createCounter(data, config) {
    return axios.post('createCounter', data, config)
  },
  updateCounter(data, config) {
    return axios.put('updateCounter', data, config)
  },
  deleteCounter(id, config) {
    return axios.delete('deleteCounter', { data: id, headers: { 'Content-Type': 'application/json' }, ...config })
  }
}

export default counter
