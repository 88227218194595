import styled from '@emotion/styled'

const Container = styled.div`
  padding: 1.5rem;
`

const Title = styled.div`
  font-size: 14px;
`

const Stat = styled.div`
  flex: 1;
  border-radius: 4px;
  padding: 4px;
  margin: -4px;
`

const Stats = styled.div`
  display: flex;
  margin: 1.5rem 0 0;
`

const StatValue = styled.div`
  font-size: 24px;
`

export { Container, Title, Stat, Stats, StatValue }
