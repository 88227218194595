import { TextField } from 'shared/ui-kit/text-field'
import { useTranslation } from 'shared/i18n/translate'
import uniqueId from 'lodash/uniqueId'
import { PositionCustomer } from 'shared/models'

type Params = {
  handleCustomerPropertyChange<T extends keyof PositionCustomer>(propertyName: T, value: PositionCustomer[T]): void
  customer?: PositionCustomer
}

function getCompanyName(props: Params) {
  const { tr } = useTranslation()
  const { companyName } = tr.lineMonitoring
  const customerCompanyName = props.customer?.companyName || ''

  const isValid = () => Boolean((customerCompanyName || '').trim().length)

  const view = (
    <TextField
      autocomplete={uniqueId('autocomplete')}
      label={companyName}
      placeholder={companyName}
      autofocus={true}
      value={customerCompanyName}
      onChange={(val) => props.handleCustomerPropertyChange('companyName', val)}
      attention={!isValid()}
    />
  )

  return { view, isValid }
}

export { getCompanyName }
