import { Chip, CircularProgress, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { TagModel } from 'shared/models'
import { useState, useMemo, useRef, useEffect } from 'react'
import { tag as tagApi } from 'shared/api'
import { useDebounce } from 'shared/hooks/useDebounce'
import { TagSearchComponentProps } from '../types'

function TagSearchComponent(props: TagSearchComponentProps) {
  const [options, setOptions] = useState<TagModel[]>([])
  const [loading, setLoading] = useState(false)
  const [term, setTerm] = useState('')

  const cclRef = useRef<any>()

  const { value = [], onChange, label, noOptionsText, noTermText, placeholder } = props

  const allOptions = useMemo(() => {
    return options.concat(value)
  }, [value, options])

  function fetchOptions(term: string) {
    cclRef.current?.()
    tagApi
      .tagSearch(term, (cclFn) => {
        cclRef.current = cclFn
      })
      .then((resp) => {
        setOptions(resp)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  function getName(tag: TagModel) {
    return [tag.name.base, tag.name.translated].filter(Boolean).join('/')
  }

  const [dataDebounce] = useDebounce([term], 500)

  useEffect(() => {
    if (term !== '') {
      fetchOptions(term)
    }
  }, [dataDebounce])

  return (
    <div style={{ width: props.fullWidth ? '100%' : 'unset' }}>
      <Autocomplete
        fullWidth={props.fullWidth}
        multiple
        loading={loading}
        loadingText={
          <div style={{ display: 'flex' }}>
            <CircularProgress style={{ margin: 'auto' }} />
          </div>
        }
        inputValue={term}
        noOptionsText={term ? noOptionsText : noTermText}
        filterSelectedOptions
        options={allOptions}
        value={value}
        onChange={(ev, val) => {
          onChange?.(val)
          setTerm('')
        }}
        getOptionLabel={getName}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip variant="outlined" label={getName(option)} {...getTagProps({ index })} key={option.id || index} />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth={props.fullWidth}
            onChange={(ev) => {
              setLoading(true)
              setTerm(ev.target.value)
            }}
            variant="standard"
            label={label}
            placeholder={placeholder}
          />
        )}
      />
    </div>
  )
}

export default TagSearchComponent
export { TagSearchComponent as TagSearch }
