import { Chip } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import { CheckListItem, ServiceModel } from 'shared/models'
import {
  CheckboxImpactDescription,
  CheckboxLabel,
  ChecklistContainer,
  CheckListItems,
  RequiredIndicator
} from './styled'
import Text from 'shared/ui-kit/text'

type Props = {
  services: ServiceModel[]
  checkListSelectedItems: string[]
  onCheckElement: (shortName: string, value: boolean) => void
}

export function Checklist({ services, checkListSelectedItems, onCheckElement }: Props) {
  const { tr } = useTranslation()

  const checklistElements =
    services?.reduce((acc: CheckListItem[], curr) => {
      if (curr.checkList?.length) {
        const filteredDuplicateCheckElements = curr.checkList
          .filter((el) => !!el.shortName)
          .filter((el) => acc.findIndex((accEl) => accEl.shortName === el.shortName) === -1)
        acc = [...acc, ...filteredDuplicateCheckElements]
      }

      return acc
    }, []) || []

  function handleCheckElement(shortName: string, value: boolean) {
    return () => {
      onCheckElement(shortName, value)
    }
  }

  if (checklistElements.length < 1) return null

  return (
    <ChecklistContainer>
      <Text type="caption" style={{ margin: '0 0 4px' }}>
        {tr.lineEdit.supportCheckList}
      </Text>
      <CheckListItems>
        {checklistElements.map((el) => {
          const commentIsViewed = el.impactOnServiceType === 'Precentage' || el.impactOnServiceType === 'Value'
          const impactUnit =
            el.impactOnServiceType === 'Precentage'
              ? `${el.impactOnServiceValue} %`
              : tr.time.seconds(el.impactOnServiceValue || 0)
          const checked = checkListSelectedItems?.includes(el.shortName) || false

          return (
            <Chip
              label={
                <>
                  {el.mandatory && !checked && <RequiredIndicator />}
                  <CheckboxLabel>{el.name}</CheckboxLabel>
                  {commentIsViewed && (
                    <CheckboxImpactDescription>
                      {el.impactOnServiceValue && el.impactOnServiceValue > 0 ? '+' : ''}
                      {impactUnit}
                    </CheckboxImpactDescription>
                  )}
                </>
              }
              style={{ height: '42px' }}
              key={el.shortName}
              color={checked ? 'primary' : 'default'}
              onClick={handleCheckElement(el.shortName, !checked)}
            />
          )
        })}
      </CheckListItems>
    </ChecklistContainer>
  )
}
