import { useState } from 'react'
import { PositionActions } from './position-actions'
import { monitoring } from 'shared/api'
import CircularProgress from '@mui/material/CircularProgress'
import * as nav from 'pages/nav'
import { showMessage, MessageTypes } from 'store/actions/main-layout-action-creators'
import { useTranslation } from 'shared/i18n/translate'
import { PositionModel, PositionState } from 'shared/models'
import { DuplicateLine } from 'shared/models/entities/get-duplicate-form'
import { useAppDispatch } from 'store'
import {
  DialogContentWrapper,
  DialogTitleWrapper,
  DialogToolbar,
  PositionActionsWrapper,
  ProgressContainer
} from './styled'
import { ChangeStateData, PositionActionsStaticProps, PositionValidateData } from './types'
import { useMediaQuery } from 'react-responsive'
import { Dialog, DialogContent, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { getNameOrNumber } from 'features/position-dumb/helpers'
import { getPositionStatusText } from 'features/position-dumb/utils/mapper'

function PositionActionsStatic(props: PositionActionsStaticProps) {
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' })
  const [loading, setLoading] = useState(false)
  const { tr, timeZoneOffset } = useTranslation()
  const dispatch = useAppDispatch()

  async function handleChangeState(data: ChangeStateData, state: PositionState) {
    setLoading(true)

    try {
      await monitoring.changePositionState({ ...data, newState: state })

      dispatch(showMessage(tr.serverResponseMessage.success, MessageTypes.Success))

      if (state === PositionState.Removed && props.onRemove) {
        props.onRemove(props.positionId)
        props.onClose?.()
        return
      }

      props.onStateChange?.(state)

      if (state === PositionState.AutoFinished || state === PositionState.ManualFinished) {
        props.onFinish?.(props.positionId)
      }

      if (
        state === PositionState.AutoFinished ||
        state === PositionState.ManualFinished ||
        props.closeAfterActionIsFinished
      ) {
        props.onClose?.()
      }

      if (state === PositionState.CalledToCheckpoint) {
        props.onCalledToCheckpoint?.(props.positionId)
      }

      if (state === PositionState.ServiceStarted) {
        props.onServiceStarted?.(props.positionId)
      }
    } finally {
      setLoading(false)
    }
  }

  async function handlePositionValidate(data: PositionValidateData) {
    setLoading(true)

    try {
      await monitoring.validatePosition(data)
      props.onValidatePosition?.()
      dispatch(showMessage(tr.serverResponseMessage.success, MessageTypes.Success))
    } finally {
      setLoading(false)
    }
  }

  function handleCallByPhone() {
    if (!props.positionId) {
      return
    }

    const { shopId: placeId = '', lineId = '', positionId } = props

    monitoring.monitoringLog({ placeId, lineId, positionId, action: 'CallPhoneNumber' })
  }

  function handleHistoryPosition() {
    window.open('#' + nav.journal() + `?term=p${props.positionId}&date=${new Date().setUTCHours(0, 0, 0, 0)}`, '_blank')
  }

  function handleDuplicate(line: DuplicateLine, position: PositionModel) {
    const { shopId: placeId } = props

    if (position && line && placeId) {
      props.onDuplicatePosition?.({ placeId, line, position })
    }
  }

  function handleOpen() {
    if (!props.opened) {
      props.onOpen?.()
    }
  }

  function actionsRender(isMobile?: boolean) {
    return (
      <PositionActionsWrapper>
        <PositionActions
          position={props.position}
          style={props.style}
          positionId={props.positionId}
          customerId={props.customerId}
          shopId={props.shopId}
          lineId={props.lineId}
          supportMobile={props.supportMobile}
          positionState={props.positionState}
          changeState={handleChangeState}
          historyPosition={handleHistoryPosition}
          phoneNumber={String(props.phoneNumber || '')}
          checkpoints={props.checkpoints || []}
          checkpointIsDisabled={props.checkpointIsDisabled}
          customCheckpointId={props.customCheckpointId}
          addNewAppointment={props.showButtonCreatePosition}
          displayServiceSurvey={props.displayServiceSurvey}
          sendSurveyLinkAfterPositionServed={props.sendSurveyLinkAfterPositionServed}
          sendSurveyLinkByEmail={props.sendSurveyLinkByEmail}
          sendSurveyLinkBySms={props.sendSurveyLinkBySms}
          surveyTags={props.surveyTags}
          finishServiceFormMode={props.finishServiceFormMode}
          onSurvay={props.onSurvay}
          onUpdate={props.onUpdate}
          disabledUpdate={props.disabledUpdate}
          displayHistory={props.displayHistory ?? true}
          displayHasApproach={props.displayHasApproach ?? true}
          displayInLineHere={props.displayInLineHere}
          displayPositionValidate={props.displayPositionValidate}
          displayRemoveConfirm={props.displayRemoveConfirm}
          sequentiallyPerformingActionsOnPosition={props.sequentiallyPerformingActionsOnPosition}
          pauseMode={props.pauseMode}
          onPause={props.onPause}
          requestCommentsOnRemove={props.requestCommentsOnRemove}
          setPositionFinishedAfterArrived={props.setPositionFinishedAfterArrived ?? true}
          displayCallByPhone={props.displayCallByPhone}
          displayConvertToMobile={props.displayConvertToMobile}
          handlePositionValidate={handlePositionValidate}
          onCallByPhone={handleCallByPhone}
          progressionTags={props.progressionTags}
          allowMultipleProgressionTags={props.allowMultipleProgressionTags}
          requestCommentsOnPriorityCall={props.requestCommentsOnPriorityCall}
          duplicatePositionMenuItem={props.duplicatePositionMenuItem}
          onDuplicate={handleDuplicate}
          generalConditionsAcceptation={props.generalConditionsAcceptation}
          monitoringPositionMenuItemCaptions={props.monitoringPositionMenuItemCaptions}
          allowProgressionPhoto={props.allowProgressionPhoto}
          readonly={props.readonly}
          lineParticipantTermInSingularTemplate={props.lineParticipantTermInSingularTemplate}
          onClose={props.onClose}
          positionTransferEnabled={props.positionTransferEnabled}
          supportAutoCall={props.supportAutoCall}
          defaultPhoneCountryCode={props.defaultPhoneCountryCode}
          suggestNextOperationOnPosition={props.suggestNextOperationOnPosition}
          opened={props.opened}
          onOpen={handleOpen}
          isMobile={isMobile}
          closeAfterActionIsFinished={props.closeAfterActionIsFinished}
        />
        {loading && (
          <ProgressContainer>
            <CircularProgress />
          </ProgressContainer>
        )}
      </PositionActionsWrapper>
    )
  }

  if (!props.opened && (!props.suggestNextOperationOnPosition || !!isMobile)) {
    return null
  }

  return (
    <>
      {isMobile ? (
        <Dialog open onClose={props.onClose} fullScreen>
          <DialogContentWrapper>
            <DialogToolbar>
              <DialogTitleWrapper>
                <Typography variant="h6" lineHeight="28px">
                  {getNameOrNumber(props.position, tr) || ''}
                </Typography>
                <Typography variant="body2" fontSize="12px" style={{ opacity: 0.5 }}>
                  {getPositionStatusText(props.position, tr, timeZoneOffset, false, false)}
                </Typography>
              </DialogTitleWrapper>
              <IconButton size="small" onClick={props.onClose}>
                <CloseIcon fontSize="large" />
              </IconButton>
            </DialogToolbar>
            <DialogContent style={{ padding: '1rem 0.5rem' }}>{actionsRender(true)}</DialogContent>
          </DialogContentWrapper>
        </Dialog>
      ) : (
        actionsRender()
      )}
    </>
  )
}

export default PositionActionsStatic
