import { CSSProperties } from 'react'
import TextField from '@mui/material/TextField'
import { IconButton } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

type Props = {
  isDisabled?: boolean
  menuIsOpen?: boolean
  selectProps: any
  fullWidth?: boolean
  style?: CSSProperties
  isShrink?: boolean
  inputStyles?: CSSProperties
  autoFocus?: boolean
  allowClear?: boolean
  onClear?: () => void
}

export default function Control(props: Props) {
  const { isDisabled, menuIsOpen, selectProps, fullWidth, style, isShrink, inputStyles, allowClear } = props

  function handleClear(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    ev.stopPropagation()
    props.onClear?.()
  }

  return (
    <TextField
      InputProps={{
        endAdornment: allowClear ? (
          <IconButton size="small" onClick={handleClear} disabled={!selectProps.value?.label}>
            <ClearIcon fontSize="small" />
          </IconButton>
        ) : undefined
      }}
      inputProps={{ style: { textOverflow: 'ellipsis', ...inputStyles } }}
      InputLabelProps={{ shrink: isShrink }}
      disabled={isDisabled}
      value={menuIsOpen ? selectProps.inputValue : selectProps.value?.label || ''}
      autoComplete="off"
      fullWidth={fullWidth}
      placeholder={selectProps.placeholder}
      label={selectProps.placeholder}
      onFocus={selectProps.onMenuOpen}
      onClick={selectProps.onMenuOpen}
      onBlur={selectProps.onMenuClose}
      autoFocus={props.autoFocus}
      style={{ minWidth: '150px', color: '#F00', ...style }}
      onChange={(ev) => {
        selectProps.onMenuOpen()
        selectProps.onInputChange(ev.target.value)
      }}
      onKeyDown={(ev) => {
        if (ev.key === 'Backspace') {
          selectProps.onMenuOpen()
        }
      }}
    />
  )
}
