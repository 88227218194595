import { useTranslation } from 'shared/i18n/translate'
import { ActionReason, JournalActionModel, PositionActionType } from 'shared/models'
import Timeline from 'shared/ui-kit/timeline'
import { getActionText } from './utils/getActionText'
import { JournalItemActionsProps } from './types'
import { SurveyReason } from './description-views/survey-reason'
import { PreviousPositionReceivedSurveyRequest } from './description-views/previous-position-received-survey-request'
import { SmsStatusCost } from './description-views/sms-status-cost'
import { Reason } from './description-views/reason'
import { RenderUnderText } from './description-views/render-under-text'
import { RenderFinishService } from './description-views/render-finish-service'
import { RenderPositionProgression } from './description-views/render-position-progression'
import { getTimelineDate } from './utils/getTimelineDate'

function JournalItemActions({ data }: JournalItemActionsProps) {
  const { dateFormatter, tr, locale, timeZoneId } = useTranslation()

  function getTimelineDescription(action: JournalActionModel) {
    const renderPositionProgression = [PositionActionType.PositionProgression].includes(action.type) && (
      <RenderPositionProgression action={action} />
    )

    const renderFinishService = [PositionActionType.FinishService].includes(action.type) && (
      <RenderFinishService action={action} />
    )

    const renderUnderText = [
      PositionActionType.CreateAppointment,
      PositionActionType.UpdateAppointment,
      PositionActionType.UpdatePosition,
      PositionActionType.JoinLine,
      PositionActionType.ReJoinLine,
      PositionActionType.CallToCheckpoint,
      PositionActionType.FailedJoin
    ].includes(action.type) && (
      <RenderUnderText
        action={action}
        expectedServiceStartTime={data.expectedServiceStartTime}
        promisedWaitingTime={data.promisedWaitingTime}
        realWaitingTime={data.realWaitingTime}
      />
    )

    const reason = !!action.reason && <Reason reason={action.reason as ActionReason} comment={action.comment} />

    const smsStatusCost = [PositionActionType.UpdateSmsStatus].includes(action.type) &&
      !!action.smsUpdate?.creditCost && <SmsStatusCost creditCost={action.smsUpdate.creditCost} />

    const surveyWithOriginalPositionId = [PositionActionType.SurveyNotificationAborted].includes(action.type) &&
      !!data.originalPositionId && <PreviousPositionReceivedSurveyRequest />

    const surveyReason = [PositionActionType.SurveyNotificationAborted].includes(action.type) && !!action.reason && (
      <SurveyReason reason={action.reason} />
    )

    return (
      surveyReason ||
      surveyWithOriginalPositionId ||
      smsStatusCost ||
      renderUnderText ||
      reason ||
      renderPositionProgression ||
      renderFinishService ||
      undefined
    )
  }

  return (
    <Timeline
      data={data.actions}
      itemConverter={(action, prevAction) => ({
        time: dateFormatter(action.dateTime, { hour: 'numeric', minute: '2-digit', second: '2-digit' }),
        date: getTimelineDate(action, prevAction, locale, timeZoneId),
        text: getActionText(action, tr, data),
        description: getTimelineDescription(action)
      })}
    />
  )
}

export { JournalItemActions }
