import { Form, FormGroup } from 'shared/ui-kit/form'
import Toggle from 'shared/ui-kit/toggle-wrap'
import { useTranslation } from 'shared/i18n/translate'
import { Controller } from 'react-hook-form'
import { GroupProps } from '../types'
import { LineMode } from 'shared/models'
import Card from 'shared/ui-kit/card'

function ModesGroup({ control, trigger }: GroupProps) {
  const { tr } = useTranslation()

  function handleModeChange(v: LineMode[], change: (val: LineMode[]) => void, mode: LineMode, value: boolean) {
    const d = v || []

    const hasMode = d.find((x) => x === mode)

    let modes = [...d]

    if (!hasMode && value) {
      modes.push(mode)
    }

    if (!value) {
      modes = d.filter((x) => x !== mode)
    }

    change(modes)
    trigger()
  }

  return (
    <Card>
      <Form>
        <Controller
          name="modes"
          control={control}
          render={({ field: f }) => (
            <>
              <FormGroup>
                <Toggle
                  toggled={!!(f.value || []).find((x) => x === LineMode.MobileApp)}
                  label={tr.lineModes.mobileApp}
                  onToggle={(_, val) => handleModeChange(f.value, f.onChange, LineMode.MobileApp, val)}
                  withHelper={true}
                  tag="line"
                  tooltipKey="mobileApp"
                />
              </FormGroup>
              <FormGroup>
                <Toggle
                  toggled={!!(f.value || []).find((x) => x === LineMode.WebFrame)}
                  label={tr.lineModes.webFrame}
                  onToggle={(_, val) => handleModeChange(f.value, f.onChange, LineMode.WebFrame, val)}
                  withHelper={true}
                  tag="line"
                  tooltipKey="webFrame"
                />
              </FormGroup>
              <FormGroup>
                <Toggle
                  toggled={!!(f.value || []).find((x) => x === LineMode.PaperTicket)}
                  label={tr.lineModes.paperTicket}
                  onToggle={(_, val) => handleModeChange(f.value, f.onChange, LineMode.PaperTicket, val)}
                  withHelper={true}
                  tag="line"
                  tooltipKey="paperTicket"
                />
              </FormGroup>
              <FormGroup>
                <Toggle
                  toggled={!!(f.value || []).find((x) => x === LineMode.ScreenCall)}
                  label={tr.lineModes.screenCall}
                  onToggle={(_, val) => handleModeChange(f.value, f.onChange, LineMode.ScreenCall, val)}
                  withHelper={true}
                  tag="line"
                  tooltipKey="screenCall"
                />
              </FormGroup>
            </>
          )}
        />
      </Form>
    </Card>
  )
}

export { ModesGroup }
