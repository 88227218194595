import Tile, { TileContainer } from 'features/tile'
import * as Nav from 'pages/nav'
import { useTranslation } from 'shared/i18n/translate'
import { addTestAttr } from 'shared/utils/test-attr'
import {
  Language as LanguageIcon,
  Message as TemplateIcon,
  People as PeopleIcon,
  Assignment as AssignmentIcon,
  Domain as ShopIcon,
  BusinessCenter as WorkIcon,
  EventAvailable as AppointmentsIcon
} from '@mui/icons-material'
import { TagMaterial } from 'shared/ui-kit/icons/tag'
import { getGroupPlacesSelector, getCountersSelector } from 'store/selectors/userSelectors'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { useAppSelector } from 'store'

function HomeTiles() {
  const { tr } = useTranslation()
  const isMultiplePlaces = useAppSelector(getGroupPlacesSelector)
  const counters = useAppSelector(getCountersSelector)
  const permissions = useAppSelector(getPermissionsSelector)

  const {
    canManageUserAccounts,
    canPromoteToAdmin,
    manageAppointments,
    manageCampaigns,
    manageReports,
    manageTranslations
  } = permissions

  const { reports, campaigns, users, places, messageTemplates, translations, appointments, tags } = tr.home

  return (
    <TileContainer>
      {isMultiplePlaces && (
        <Tile
          {...addTestAttr('HomeTiles-Shops')}
          title={places(counters.shops)}
          count={counters.shops}
          iconClass={ShopIcon}
          linkTo={Nav.places()}
        />
      )}
      {manageAppointments && (
        <Tile
          {...addTestAttr('HomeTiles-Appointments')}
          title={appointments}
          iconClass={AppointmentsIcon}
          linkTo={Nav.appointments()}
        />
      )}
      {manageReports && (
        <Tile {...addTestAttr('HomeTiles-Reports')} title={reports} iconClass={AssignmentIcon} linkTo={Nav.reports()} />
      )}
      {canManageUserAccounts && (
        <Tile {...addTestAttr('HomeTiles-Users')} title={users} iconClass={PeopleIcon} linkTo={Nav.users()} />
      )}
      {manageCampaigns && (
        <Tile
          {...addTestAttr('HomeTiles-Campaigns')}
          title={campaigns(counters.campaigns)}
          count={counters.campaigns}
          iconClass={WorkIcon}
          linkTo={Nav.campaigns()}
        />
      )}
      {canPromoteToAdmin && (
        <Tile
          {...addTestAttr('HomeTiles-MessageTemplates')}
          title={messageTemplates}
          iconClass={TemplateIcon}
          linkTo={Nav.messageTemplates()}
        />
      )}
      {manageTranslations && (
        <Tile
          {...addTestAttr('HomeTiles-Translations')}
          title={translations}
          iconClass={LanguageIcon}
          linkTo={Nav.translations()}
        />
      )}
      {canPromoteToAdmin && (
        <Tile {...addTestAttr('HomeTiles-Tags')} title={tags} iconClass={TagMaterial} linkTo={Nav.tags()} />
      )}
    </TileContainer>
  )
}

export { HomeTiles }
