import { CheckpointHostEditModel } from 'shared/models'
import TimeHelper from 'shared/utils/time'
import dayjs from 'dayjs'
import { Translations } from 'shared/i18n/translation-types'
import { PermissionError, PermissionErrorToText } from './types'

function getIsChangeReasonRequired(data?: CheckpointHostEditModel) {
  if (!data) {
    return false
  }

  const { isAnyHourOpened = false, closingTime_unixtime, currentTime = Date.now(), active = false } = data

  if (!data.requestCommentOnClosingServicePoint) {
    return false
  }

  if (!active) {
    return false
  }

  if (isAnyHourOpened) {
    return true
  }

  if (!closingTime_unixtime) {
    return false
  }

  return closingTime_unixtime - currentTime > TimeHelper.minMS * 10
}

function getHoursWithToday(value?: number | null) {
  if (value === null) {
    return undefined
  }

  if (!value) {
    return value
  }

  const wrapDate = new Date(value)
  const nowDate = new Date()

  return value && dayjs(wrapDate).isValid()
    ? wrapDate.setFullYear(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate())
    : value
}

const delayTimes: number[] = [5, 10, 15, 30, 60]

function getErrorToText(tr: Translations): PermissionErrorToText {
  return {
    [PermissionError.CantClose]: tr.checkpointMonitoring.noRightsToCloseServicePoint,
    [PermissionError.CantOpen]: tr.checkpointMonitoring.noRightsToOpenServicePoint
  }
}

export { getIsChangeReasonRequired, getHoursWithToday, delayTimes, getErrorToText }
