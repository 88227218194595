import { SVGProps } from 'react'

function LineCongirmation(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="24" {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 9C13.176 9 12.48 9.496 12.168 10.2H9.832C9.52 9.496 8.824 9 8 9C7.176 9 6.48 9.496 6.168 10.2H3.832C3.52 9.496 2.824 9 2 9C0.896 9 0 9.896 0 11C0 12.104 0.896 13 2 13C2.824 13 3.52 12.504 3.832 11.8H6.168C6.48 12.504 7.176 13 8 13C8.824 13 9.52 12.504 9.832 11.8H12.168C12.48 12.504 13.176 13 14 13C15.104 13 16 12.104 16 11C16 9.896 15.104 9 14 9Z"
        fill={props.color}
      />
      <path
        d="M8.07826 8L5 4.99576L6.22609 3.79915L8.06957 5.5983L11.7565 2L13 3.19661L8.07826 8Z"
        fill={props.color}
      />
    </svg>
  )
}

export { LineCongirmation }
export default LineCongirmation
