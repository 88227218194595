import * as Nav from 'pages/nav'
import { Breadcrumbs } from 'shared/i18n/translation-types'
import { BkmbKeys, ConditionalCrumb, NavKeys } from './types'

function create(res: Breadcrumbs) {
  return <N extends NavKeys, B extends BkmbKeys>(navKeys: N, trkey: B) => {
    const fn: ConditionalCrumb<N, B> = (params, title) => {
      return {
        id: trkey,
        title: title || res[trkey],
        url: (Nav[navKeys] as any)(...(params || []))
      }
    }

    return fn
  }
}

export const getCrumbs = (res: Breadcrumbs) => {
  const createCrumb = create(res)

  return {
    home: createCrumb('index', 'home'),
    place: createCrumb('place', 'place'),
    places: createCrumb('places', 'places'),
    createPlace: createCrumb('createPlace', 'createPlace'),
    editPlace: createCrumb('editPlace', 'editPlace'),
    beacons: createCrumb('beacons', 'beacons'),
    beacon: createCrumb('beacon', 'beacon'),
    newBeacon: createCrumb('createBeacon', 'newBeacon'),
    editBeacon: createCrumb('editBeacon', 'editBeacon'),
    lines: createCrumb('lines', 'lines'),
    line: createCrumb('line', 'line'),
    newLine: createCrumb('createLine', 'newLine'),
    editLine: createCrumb('editLine', 'editLine'),
    checkpoints: createCrumb('checkpoints', 'checkpoints'),
    newCheckpoint: createCrumb('createCheckpoint', 'newCheckpoint'),
    editCheckpoint: createCrumb('editCheckpoint', 'editCheckpoint'),
    userSettings: createCrumb('users', 'userSettings'),
    users: createCrumb('users', 'users'),
    newUser: createCrumb('createUser', 'newUser'),
    editUser: createCrumb('editUser', 'editUser'),
    zones: createCrumb('zones', 'zones'),
    newZone: createCrumb('createZone', 'newZone'),
    editZone: createCrumb('editZone', 'editZone'),
    campaigns: createCrumb('campaigns', 'campaigns'),
    campaign: createCrumb('campaign', 'campaign'),
    newCampaign: createCrumb('createCampaign', 'newCampaign'),
    editCampaign: createCrumb('editCampaign', 'editCampaign'),
    duplicateCampaign: createCrumb('editCampaign', 'duplicateCampaign'),
    advertisements: createCrumb('advertisements', 'advertisements'),
    newAdvertisement: createCrumb('createAdvertisement', 'newAdvertisement'),
    editAdvertisement: createCrumb('editAdvertisement', 'editAdvertisement'),
    reports: createCrumb('reports', 'reports'),
    journal: createCrumb('journal', 'journal'),
    eventsFlow: createCrumb('eventsFlow', 'eventsFlow'),
    dailyStats: createCrumb('dailyStats', 'dailyStats'),
    customers: createCrumb('customers', 'customers'),
    dailyStatsCustomers: createCrumb('dailyStatsCustomers', 'dailyStatsCustomers'),
    terminals: createCrumb('terminals', 'terminals'),
    terminal: createCrumb('terminal', 'terminal'),
    newTerminal: createCrumb('createTerminal', 'newTerminal'),
    editTerminal: createCrumb('editTerminal', 'editTerminal'),
    messageTemplates: createCrumb('messageTemplates', 'messageTemplates'),
    messageTemplatesGlobal: createCrumb('messageTemplates', 'messageTemplatesGlobal'),
    messageTemplatesPlace: createCrumb('placeMessageTemplates', 'messageTemplatesPlace'),
    discreteLineMonitoring: createCrumb('discreteLineMonitoring', 'discreteLineMonitoring'),
    schedulerMonitoring: createCrumb('lineScheduler', 'schedulerMonitoring'),
    appointmentScheduler: createCrumb('lineAppointmentScheduler', 'appointmentScheduler'),
    lineMonitoring: createCrumb('lineMonitoring', 'lineMonitoring'),
    lineMonitoringCreate: createCrumb('lineMonitoringCreate', 'lineMonitoringCreate'),
    lineMonitoringEdit: createCrumb('lineMonitoringEdit', 'lineMonitoringEdit'),
    daysOff: createCrumb('daysOff', 'daysOff'),
    staffManagement: createCrumb('lineStaffManagement', 'staffManagement'),
    editStaffManagement: createCrumb('editLineStaffManagement', 'editStaffManagement'),
    newStaffManagement: createCrumb('createLineStaffManagement', 'newStaffManagement'),
    appointments: createCrumb('appointments', 'appointments'),
    appointmentsToCancel: createCrumb('appointmentsToCancelLine', 'appointmentsToCancel'),
    appointmentsLine: createCrumb('appointmentsLine', 'appointments'),
    newAppointment: createCrumb('createAppointment', 'newAppointment'),
    editAppointment: createCrumb('editAppointment', 'editAppointment'),
    broadcastAlerts: createCrumb('broadcastAlerts', 'broadcastAlerts'),
    exportData: createCrumb('exportData', 'exportData'),
    counters: createCrumb('counters', 'counters'),
    counter: createCrumb('counter', 'counter'),
    newCounter: createCrumb('createCounter', 'newCounter'),
    editCounter: createCrumb('editCounter', 'editCounter'),
    translations: createCrumb('translations', 'translations'),
    newTranslation: createCrumb('createTranslation', 'newTranslation'),
    editTranslation: createCrumb('editTranslation', 'editTranslation'),
    edithost: createCrumb('checkpointHost', 'edithost'),
    checkpointMonitoring: createCrumb('checkpointMonitoring', 'lineMonitoring'),
    checkpointMonitoringCreate: createCrumb('checkpointMonitoringCreate', 'lineMonitoringCreate'),
    checkpointMonitoringEdit: createCrumb('checkpointMonitoringEdit', 'lineMonitoringEdit'),
    appointmentPlaceList: createCrumb('appointmentPlaceList', 'appointmentPlaceList'),
    appointment: createCrumb('viewAppointment', 'appointment'),
    lineStateMonitoring: createCrumb('lineMonitoringState', 'lineStateMonitoring'),
    tags: createCrumb('tags', 'tags'),
    createTag: createCrumb('createTag', 'newTag'),
    editTag: createCrumb('editTag', 'newTag'),
    lineDelay: createCrumb('lineDelay', 'lineDelay'),
    checkpointDelay: createCrumb('checkpointDelay', 'checkpointDelay')
  }
}
