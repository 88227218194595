type StorageItemOptions<T> = {
  dataExtractor: (data: string) => T
}

class StorageItem<T> {
  dataExtractor: StorageItemOptions<T>['dataExtractor'] = (data) => JSON.parse(data)

  constructor(
    public key: string,
    options?: StorageItemOptions<T>
  ) {
    if (options) {
      this.dataExtractor = options.dataExtractor
    }
  }

  get value() {
    const value = sessionStorage.getItem(this.key)

    if (value === 'undefined' || value === 'null' || value === '' || value === undefined || value === null) {
      return undefined
    }

    try {
      return this.dataExtractor(value) as T
    } catch {
      console.warn('Cant extract value from localStarage')
      return undefined
    }
  }

  set value(value) {
    sessionStorage.setItem(this.key, JSON.stringify(value))
  }
}

export { StorageItem }
