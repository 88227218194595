import { List } from 'shared/ui-kit/list'
import { useTranslation } from 'shared/i18n/translate'
import Card from 'shared/ui-kit/card'
import { Button, Divider } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { FormActionGroup } from 'shared/ui-kit/form'
import { createPlace, place } from 'pages/nav'
import { useDataProvider } from 'features/isolated-data-provider'
import { useHistory } from 'react-router-dom'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { PlaceModelSimplified } from 'shared/models'
import { useAppSelector } from 'store'

function HomePlaces() {
  const { tr } = useTranslation()
  const history = useHistory()
  const { places } = useDataProvider()
  const { canAddAndDeleteShops } = useAppSelector(getPermissionsSelector)

  function handlePlaceSelect(data: PlaceModelSimplified) {
    history.push(place(String(data.id)))
  }

  function hangleCreatePlace() {
    history.push(createPlace())
  }

  return (
    <Card paperStyle={{ overflow: 'hidden' }}>
      <List
        data={places}
        onSelect={handlePlaceSelect}
        dataConverter={(item) => ({ id: item.id, primaryText: item.name, secondaryText: item.description })}
      />
      {canAddAndDeleteShops && (
        <>
          <Divider />
          <FormActionGroup>
            <Button startIcon={<AddIcon />} onClick={hangleCreatePlace}>
              {tr.placeList.addButton}
            </Button>
          </FormActionGroup>
        </>
      )}
    </Card>
  )
}

export { HomePlaces }
