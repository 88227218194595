import { CSSProperties, useMemo } from 'react'
import { PositionType } from 'shared/models'
import TicketIcon from '@mui/icons-material/Receipt'
import PhoneIcon from '@mui/icons-material/PhoneAndroid'
import ErrorIcon from '@mui/icons-material/Error'
import AppointmentIcon from '@mui/icons-material/Event'
import TvIcon from '@mui/icons-material/Tv'
import WebIcon from '@mui/icons-material/Web'
import ServiceIcon from '@mui/icons-material/Settings'

const PositionTypesMap = {
  [PositionType.PaperTicket]: TicketIcon,
  [PositionType.MobileApp]: PhoneIcon,
  [PositionType.ScreenCall]: TvIcon,
  [PositionType.Appointment]: AppointmentIcon,
  [PositionType.WebFrame]: WebIcon,
  [PositionType.Break]: ServiceIcon,
  Unknown: ErrorIcon
}

type Props = {
  type?: PositionType
  style?: CSSProperties
}

function PositionTypeIconComponent({ type, style }: Props) {
  const Icon = useMemo(
    () => (type ? PositionTypesMap[type] || PositionTypesMap.Unknown : PositionTypesMap.Unknown),
    [type]
  )

  if (!Icon) {
    return null
  }

  return <Icon style={style} />
}

export default PositionTypeIconComponent
export const PositionTypeIcon = PositionTypeIconComponent
