import { SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { OnDuplicatePositionType } from 'features/monitoring/position-card'
import {
  CheckpointModel,
  CheckpointStatus,
  LineModel,
  LinesStatisticsModel,
  PositionModel,
  PositionState,
  ServiceModel
} from 'shared/models'
import { BeaconModel, SingleCheckpointModel } from 'shared/models'

export type HotkeyControlProps = {
  positions: PositionModel[]
  suggestedPositionId?: string | number
  checkpointId: string | number
  shopId: string | number
  lineId: string | number
  surveyCallIndex?: string | number
  startLoading?: () => void
  finishLoading?: () => void
  setSelectedId?: (id: number | string) => void
  openServiceSurvey?: (id: number | string) => void
  navigateCreatePage?: () => void
  displayServiceSurvey: boolean
}

export type HotkeyControlHandler = () => void

export type CheckpointMonitoringPositionsProps = {
  line: LineModel
  positions: PositionModel[]
  shopId: string | number
  lineId: string | number
  checkpointId: string | number
  onDuplicatePosition?: OnDuplicatePositionType
  onPause?: () => void
  startLoading?: () => void
  finishLoading?: () => void
  helpIsEnabled?: boolean
  showTemporaryHelpForm?: () => void
  onCancelHelpTemporary?: () => void
  onStateChange?: (id: string | number) => (newState: PositionState) => void
  defaultSelectedId?: string | number
  dialodIsBlocked?: boolean
  isFinished?: boolean
  suggestedPositionId?: string | number
  showButtonCreatePosition?: boolean
  checkpoint: CheckpointModel
  checkpoints: CheckpointModel[]
}

export type CheckpointMonitoringBarProps = {
  line: LineModel
  onPause?: () => void
  checkpointStatus?: CheckpointStatus
  lineIsActive?: boolean
  waitingTime?: number
  registeredPositionsCount?: number
}

export type CheckpointBarActionsProps = {
  line: LineModel
  onPauseMode?: () => void
  actions: {
    create?: boolean
    suspendService?: boolean
  }
  actionsDisabled: {
    create?: boolean
    checkpointDelay?: boolean
    suspendService?: boolean
  }
  waitingTime?: number
  registeredPositionsCount?: number
}

export type CheckpointDisableDialogProps = {
  onDecline: () => void
  onSubmit: () => void
  open: boolean
  enabledCount: number
}

export type CheckpointInactiveReasonProps = {
  reason: string
  shopId: string | number
  lineId: string | number
  checkpointId: string
}

export type CheckpintPauseActionProps = {
  onClose: () => void
  services: ServiceModel[]
}

export type CheckpointStatProps = {
  template?: string
  persons?: number
  min: number
}

export type ClosedServicepointShowPositionsFormProps = {
  onSubmit: (
    reason: string,
    comment: string,
    services: (string | number)[],
    serviceDuration: number,
    lineId: number
  ) => void
  checkpointId: string | number
  lineId: number
  shopId: string | number
  defaultLine?: LineModel
  onCancel: () => void
}

export enum ClosedServicepointShowPositionsReason {
  PriorityServiceNeeded = 'PriorityServiceNeeded',
  TooManyVisitors = 'TooManyVisitors',
  HaveSomeFreeTime = 'HaveSomeFreeTime',
  ExpertConsultation = 'ExpertConsultation'
}

export type HotkeysDialogProps = {
  open: boolean
  onClose: () => void
}

export type SuggestionDialogProps = {
  data?: any
  shopId: number | string
  lineId: number | string
  checkpointId: number | string
  timeToReach?: number
  requestCommentsOnRemove?: boolean
  onWait?: () => void
  onStart?: () => void
  onRemove?: () => void
}

export type CheckpointEditProps = {
  data: SingleCheckpointModel
  beacons: BeaconModel[]
  discreteLine?: boolean
  onDelete: (id: string | number) => void
  onSave: (data: SingleCheckpointModel) => void
  placeId?: string
  showBeacons?: boolean
}

export type CheckpointListComponentProps = {
  showLine: boolean
  showEditHostIcon: boolean
  isDiscrete: boolean
  data: CheckpointModel[]
  checkpointId?: string | number
  orderChangeMode?: boolean
  onDropEnd?: (items: CheckpointModel[]) => void
}

export type GetMenuProps = {
  shopId: string
  lineId: string
  id: string
  showEditHost?: boolean
  showMonitoring?: boolean
  canUpdateShops?: boolean
  isStart?: boolean
}

export type MenuItem = {
  id: string
  title: string
  handle: () => void
  Icon: OverridableComponent<SvgIconTypeMap<any, 'svg'>>
  testAttr: any
}

export type CheckpointItemProps = {
  data: CheckpointModel
  showLine?: boolean
  isDiscreteLine?: boolean
  checkpointId?: string | number
  showEditHostIcon?: boolean
  refDep?: (el: HTMLAnchorElement) => void
}

export type InactiveFilters = {
  maxTimeDuration: number
  serviceIds: (string | number)[]
  lineId: number
  helpIsEnabled: boolean
  startedAfterReload?: boolean
}

export type CheckpointMonitoringRouterParams = {
  placeId: string
  lineId: string
  checkpointId: string
  positionId?: string
}

export type LineWithStats = LinesStatisticsModel & {
  lineParticipantTermInPluralTemplate?: string
  lineParticipantTermInSingularTemplate?: string
}
