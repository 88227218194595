import axios from 'axios'
import { BroadcastAlertsAPI } from 'shared/models'

const broadcastAlerts: BroadcastAlertsAPI = {
  getBroadcastAlertsConfig(data, config) {
    return axios.get('getBroadcastAlertsConfig', { params: data, ...config })
  },
  sendBroadcastAlert(data, config) {
    return axios.post('sendBroadcastAlert', data, config)
  }
}

export default broadcastAlerts
