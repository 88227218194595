import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { MessageTemplatesForm } from './message-templates-form'
import { messageTemplates } from 'shared/api'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { showMessage, MessageTypes } from 'store/actions/main-layout-action-creators'
import CircularProgress from '@mui/material/CircularProgress'
import { CenterContainer } from 'shared/ui-kit/center-container'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import Container from 'shared/ui-kit/container'
import { useAppDispatch } from 'store'
import { useParams } from 'react-router'
import { MessageTemplatesModel } from 'shared/models'
import { useTimeZoneFilter } from 'features/time-zone-filter'
import { useDataProvider } from 'features/isolated-data-provider'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { MessageTemplatesFilters } from './message-templates-filters'
import { Box } from '@mui/material'
import { getMessegeTemplateKey } from './utils'

function MessageTemplates() {
  const queryClient = useQueryClient()
  const { tr } = useTranslation()
  const dispatch = useAppDispatch()
  const { lineId, placeId } = useParams<{ placeId?: string; lineId?: string }>()

  const [language, setLanguage] = useState<string | undefined>(undefined)
  const [messageType, setMessageType] = useState('')

  const queryKey = getMessegeTemplateKey({ messageType, language, shopId: placeId, lineId })

  const { data: configRes, isLoading: isConfigLoading } = useQuery(
    ['getMessageTemplatesConfig', placeId, lineId],
    () => messageTemplates.getMessageTemplatesConfig(lineId, placeId),
    { refetchOnReconnect: false, refetchOnWindowFocus: false, retry: false }
  )

  const { mutateAsync: updateMessageTemplate, isLoading: isUpdating } = useMutation((data: any) =>
    messageTemplates.updateMessageTemplate(data)
  )

  const { data: res, isLoading } = useQuery(
    queryKey,
    () => messageTemplates.getMessageTemplate({ language, messageType, lineId, shopId: placeId }),
    { refetchOnReconnect: false, refetchOnWindowFocus: false, enabled: !!language && !!messageType, retry: false }
  )

  const { getPlace } = useDataProvider()

  useTimeZoneFilter(placeId)

  useEffect(initBreadcrumbs, [res?.parents, tr])

  useEffect(() => {
    if (configRes?.data) {
      const newLang = language || configRes.data.languages?.[0]?.value || ''
      const messageType = configRes.data.messageTypes?.[0]?.items?.[0]?.value

      setLanguage(newLang)
      setMessageType(messageType)
    }
  }, [configRes])

  function initBreadcrumbs() {
    const crumb = getCrumbs(tr.breadcrumbs)

    if (res?.parents && !!placeId) {
      if (placeId && lineId && res.parents.shop && res.parents.line) {
        dispatch(
          setBreadcrumbs([
            crumb.home(),
            crumb.places(),
            crumb.place([res.parents.shop.id as string], res.parents.shop.name),
            crumb.lines([res.parents.shop.id as string]),
            crumb.line([res.parents.shop.id as string, res.parents.line.id as string], res.parents.line.name),
            crumb.messageTemplates()
          ])
        )
      } else if (placeId && res.parents.shop) {
        dispatch(
          setBreadcrumbs([
            crumb.home(),
            crumb.places(),
            crumb.place([res.parents.shop.id as string], res.parents.shop.name),
            crumb.messageTemplatesPlace([res.parents.shop.id as string])
          ])
        )
      } else {
        dispatch(setBreadcrumbs([crumb.home(), crumb.messageTemplatesGlobal()]))
      }
    } else if (!placeId) {
      dispatch(setBreadcrumbs([crumb.home(), crumb.messageTemplatesGlobal()]))
    }
  }

  function handleMessageTypeChange(messageType: string) {
    setMessageType(messageType)
  }

  function handleLanguageChange(language: string) {
    setLanguage(language)
  }

  async function handleSave(data: MessageTemplatesModel) {
    try {
      await updateMessageTemplate({ ...data, shopId: placeId, lineId, language, messageType })
      dispatch(showMessage(tr.messageTemplates.success, MessageTypes.Success))
      queryClient.invalidateQueries(queryKey)
    } catch (e) {
      console.log(e)
      dispatch(showMessage(tr.messageTemplates.error, MessageTypes.Error))
    }
  }

  const config = useMemo(() => {
    if (configRes?.data) {
      const shop = getPlace(placeId || '')
      const data = configRes?.data

      if (shop?.supportedLanguages) {
        const supportedLanguagesList = shop.supportedLanguages.split(';')
        data.languages = data.languages.filter((x) => supportedLanguagesList.includes(x.value))
      }

      return data
    }

    return null
  }, [configRes])

  if (isConfigLoading) {
    return (
      <CenterContainer>
        <CircularProgress />
      </CenterContainer>
    )
  }

  if (!config) {
    return null
  }

  return (
    <Container containerStyle={{ maxWidth: '1100px' }}>
      <MessageTemplatesFilters
        config={config}
        language={language || ''}
        messageType={messageType}
        onMessageTypeChange={handleMessageTypeChange}
        onLanguageChange={handleLanguageChange}
      >
        {!!isLoading && (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        {!isLoading && !!res?.data && (
          <MessageTemplatesForm
            config={config}
            data={res.data}
            parentMessage={res.parentMessage}
            onSave={handleSave}
            isFetching={isUpdating}
          />
        )}
      </MessageTemplatesFilters>
    </Container>
  )
}

export { MessageTemplates }
