import { useState, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { tag as tagApi } from 'shared/api'
import { List, AppBar, Toolbar, IconButton, ListItemText, ListSubheader, ListItemButton } from '@mui/material'
import { Add } from '@mui/icons-material'
import { TagListItemModel } from 'shared/models'
import PageLoader from 'shared/ui-kit/page-loader'
import EmptyScreen from 'shared/ui-kit/empty-screen'
import { NavLink, RouteComponentProps, useHistory, useLocation } from 'react-router-dom'
import { editTag, createTag } from 'pages/nav'
import Container from 'shared/ui-kit/container'
import { StorageItem } from 'shared/utils/storage'
import { SearchInput } from 'features/search-input'
import { useAppDispatch } from 'store'
import Card from 'shared/ui-kit/card'

type Props = RouteComponentProps

function Tags(props: Props) {
  const { tr } = useTranslation()
  const dispatch = useAppDispatch()
  const { search: querySearch } = useLocation()
  const history = useHistory()
  const { current: tagsTerm } = useRef(new StorageItem<string>('tagsTerm'))

  const [search, setSearch] = useState(new URLSearchParams(querySearch).get('term') || tagsTerm.value || '')
  const [tagItems, setTagItems] = useState<TagListItemModel[] | null>(null)

  useEffect(() => {
    setCrumbs()
    fetchData()
  }, [])

  useEffect(() => {
    const params = new URLSearchParams()

    if (search) {
      params.set('term', search)
    }

    tagsTerm.value = search
    history.replace({ search: params.toString() })
  }, [search])

  const filteredTags = useMemo(() => {
    if (!tagItems) {
      return []
    }

    if (!search) {
      return tagItems
    }

    return tagItems.filter((ti) => {
      const { translated = '', base } = ti.tag.name
      return (translated || base).toLowerCase().includes(search.toLowerCase())
    })
  }, [search, tagItems])

  function fetchData() {
    tagApi.getTagItemsList().then((response) => {
      setTagItems(response)
    })
  }

  function setCrumbs() {
    const crumb = getCrumbs(tr.breadcrumbs)
    const crumbs = [crumb.home(), crumb.tags()]
    dispatch(setBreadcrumbs(crumbs))
  }

  return (
    <>
      <AppBar position="sticky" color="inherit" elevation={2} style={{ top: '4rem', borderRadius: 0 }}>
        <Toolbar>
          <Container style={{ padding: '0 0.5rem' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '1rem' }}>
              <IconButton onClick={() => props.history.push(createTag())}>
                <Add />
              </IconButton>
              <SearchInput
                value={search}
                style={{ flex: 1 }}
                onChange={(ev) => setSearch(ev.target.value)}
                placeholder={tr.common.search}
                autoFocus
                onClear={() => setSearch('')}
              />
            </div>
          </Container>
        </Toolbar>
      </AppBar>
      <Container>
        {!tagItems && <PageLoader />}
        {tagItems && !tagItems.length && <EmptyScreen text={tr.common.noData} />}
        {tagItems && !!tagItems.length && (
          <Card paperStyle={{ overflow: 'hidden' }}>
            <List subheader={<ListSubheader>{tr.tagsList.tags}</ListSubheader>}>
              {filteredTags.map((ti) => (
                <ListItemButton component={NavLink} to={editTag(String(ti.tag.id))} key={ti.tag.id}>
                  <ListItemText
                    primary={ti.tag.name.translated || ti.tag.name.base}
                    secondary={tr.tagsList.linesCount(ti.linesCount)}
                  />
                </ListItemButton>
              ))}
            </List>
          </Card>
        )}
      </Container>
    </>
  )
}

export default Tags
