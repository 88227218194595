import { useState } from 'react'
import { FilledAdditional, PositionModel, PositionState, PositionType, TimeLeftOrOverState } from 'shared/models'
import { useTranslation } from 'shared/i18n/translate'
import { Avatar, Button, Tooltip, Chip } from '@mui/material'
import { Photo as PhotoIcon, LocalOffer as LocalOfferIcon } from '@mui/icons-material'
import { NotificationImportant as NotificationImportantIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material'
import { WhereToVote as IsHereIcon, ContactMail as StuffCommentIcon } from '@mui/icons-material'
import { Chat as ChatIcon, Lock as PrivateIcon, Warning as WarningIcon } from '@mui/icons-material'
import { CodeIcon } from 'shared/ui-kit/icons'
import { PositionStat } from 'features/position-stat'
import PositionTypeIcon from './components/position-type-icon'
import PositionStateColors from './utils/position-state-color'
import PositionDurations from './components/position-durations'
import PositionServices from './components/position-services'
import CallTimeStat from './components/call-time-stat'
import ColorDuration from './utils/color-duration'
import { isPositionInLineHere, isUnreachPosition as isUnreachPositionHelper } from './helpers'
import { getDeviceTypeIcon, getNameOrNumberWithPhone, handleClickStopEvents } from './helpers'
import { MobileInfoStats } from './components/position-mobile-info-stats'
import { getPositionStatusText } from './utils/mapper'
import { Language } from './components/language-position-icon'
import { TimeslotOrAsapStat } from './components/timeslot-or-asap-position-stat'
import { monitoring } from 'shared/api'
import { MessageTypes, showMessage } from 'store/actions/main-layout-action-creators'
import { useMediaQuery } from 'react-responsive'
import { PositionComponentProps } from './types'
import { useParams } from 'react-router'
import { getHiddenNumber } from 'features/material-ui-phoneinput/components/format-number'
import { AdditionalItemView } from 'features/additionals-view'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { PersonsCount } from 'features/position/persons-count'
import { useAppDispatch, useAppSelector } from 'store'
import { acceptationStateColors, acceptationStateIcons } from './utils/acceptation-utils'
import ProgressBarComponent from 'shared/ui-kit/progress-bar/progress-bar'
import { TimeLeftOrOverStateColorAndIcons } from './components/time-left-stat'
import {
  ActionsConent,
  ActionsRoot,
  AdditionalRow,
  DeviceTypeIconContainer,
  InfoContainer,
  InfoRow,
  InfoWithIcon,
  LastVisit,
  PreRow
} from './styled'
import { PositionHeaderTitle, PositionPrimary, PositionRoot } from './styled'
import { PositionSecondary, PositionStateType, PositionTags, PositionTile } from './styled'
import { PositionUnDataContainer, ProgressionTagContainer, ProgressionTagsComment } from './styled'
import LineCongirmation from 'shared/ui-kit/icons/line-confirmation'
import LineCongirmationRequest from 'shared/ui-kit/icons/line-confirmation-request'

function Position(props: PositionComponentProps) {
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' })
  const dispatch = useAppDispatch()
  const { viewPositions, canViewPersonalInfo } = useAppSelector(getPermissionsSelector)
  const { checkpointId, lineId, placeId } = useParams<{ checkpointId: string; lineId: string; placeId: string }>()
  const { tr, timeZoneOffset, dateFormatter } = useTranslation()

  const [loading, setLoading] = useState(false)

  const data: PositionModel = {
    ...props.data,
    asap: props.data.asap === undefined ? !props.data.timeSlot : props.data.asap,
    postponedCall: props.data.postponedCall === undefined ? !!props.data.timeSlot : props.data.postponedCall
  }

  function handleCallPosition() {
    if (!checkpointId || !placeId || !lineId) {
      return
    }

    const positionId = data.id
    const newState = PositionState.CalledToCheckpoint

    setLoading(true)

    monitoring
      .changePositionState({ checkpointId, shopId: placeId, lineId, positionId, newState })
      .then(() => dispatch(showMessage(tr.serverResponseMessage.success, MessageTypes.Success)))
      .catch(() => dispatch(showMessage(tr.serverResponseMessage.error, MessageTypes.Error)))
      .finally(() => setLoading(false))
  }

  function handleClick() {
    props.onClick?.(data)
  }

  const positionHeader = canViewPersonalInfo
    ? getNameOrNumberWithPhone(data, tr) || tr.customer.hidden
    : ['**************', getHiddenNumber(data.customer?.phoneNumber)].filter(Boolean).join(', ')

  const isUnreachPosition = isUnreachPositionHelper(data)

  const filledAdditionals: FilledAdditional[] = (props.additionals || [])
    .map((additional) => {
      const shortName = (additional.shortName || '').toLowerCase()
      const values = Object.entries(data.additionals || {})
      const value = values.find((el) => (el[0] || '').toLowerCase() === shortName)?.[1]

      return { ...additional, value }
    })
    .filter((el) => !!el.value)

  const notShowData =
    viewPositions === 'Suggested' &&
    props.isCheckpointMonitroing === true &&
    [PositionState.InLineHere, PositionState.ValidForService, PositionState.Joined, PositionState.InLine].includes(
      data.state
    )

  const positionInPlaceShow = [
    PositionState.InLineHere,
    PositionState.ValidForService,
    PositionState.Joined,
    PositionState.InLine,
    PositionState.CalledToCheckpoint,
    PositionState.MovingToCheckpoint,
    PositionState.NearCheckpoint
  ].includes(data.state)

  const reduced = props.reducedMobile && isMobile
  const progressTimeFromCall = (data.timeFromCall || 0) / 1000
  const progressColor = progressTimeFromCall < (props.maxTimeToReach || 0) ? ColorDuration.high : ColorDuration.extreme
  const serviceOverState = TimeLeftOrOverStateColorAndIcons[data.timeLeftOrOverState || TimeLeftOrOverState.Normal]
  const serviceProtressTotal = data.plannedServiceDuration || 0
  const serviceProgress = serviceProtressTotal - (data.timeLeftOrOver || 0)
  const serviceProgressColor = serviceOverState.color
  const isProgress =
    data.type === PositionType.MobileApp || data.type === PositionType.WebFrame || data.sms?.call?.status === 'received'
  const avatarColor = isUnreachPosition
    ? ColorDuration.extreme
    : props.readonly
      ? undefined
      : PositionStateColors[data.state]
  const isPrivate =
    data.customCheckpoint?.id &&
    [PositionState.InLineHere, PositionState.Joined, PositionState.InLine, PositionState.ValidForService].includes(
      data.state
    )

  if (notShowData && data.type !== PositionType.Break) {
    return (
      <PositionUnDataContainer>
        <div style={{ flex: 1 }}>
          {props.suggested
            ? tr.monitoring.canCallNextPosition(
                tr.getParticipantFormFromTemplates(
                  1,
                  props?.lineParticipantTermInSingularTemplate,
                  props?.lineParticipantTermInPluralTemplate
                )
              )
            : tr.monitoring.canNotCallPosition(
                tr.getParticipantFormFromTemplates(
                  1,
                  props?.lineParticipantTermInSingularTemplate,
                  props?.lineParticipantTermInPluralTemplate
                )
              )}
        </div>
        {props.suggested && (
          <Button disabled={loading} onClick={handleCallPosition} variant="outlined">
            {tr.positionActions.requestToApproach}
          </Button>
        )}
      </PositionUnDataContainer>
    )
  }

  return (
    <>
      {isProgress &&
        (data.state === PositionState.CalledToCheckpoint || data.state === PositionState.MovingToCheckpoint) &&
        !!props.maxTimeToReach && (
          <ProgressBarComponent
            total={props.maxTimeToReach}
            progress={progressTimeFromCall}
            color={progressColor}
            style={{ height: '2px' }}
          />
        )}
      {isProgress && data.state === PositionState.InService && (
        <ProgressBarComponent
          total={serviceProtressTotal}
          progress={data.timeLeftOrOverState === TimeLeftOrOverState.Over ? serviceProtressTotal : serviceProgress}
          color={serviceProgressColor}
          style={{ height: '2px' }}
        />
      )}
      <PositionRoot onClick={handleClick} disabled={props.waitingUpdate}>
        <PositionStateType>
          <Avatar style={{ backgroundColor: avatarColor }}>
            <PositionTypeIcon type={data.type} />
          </Avatar>
          {isPrivate && (
            <Avatar style={{ marginTop: 8 }}>
              <PrivateIcon />
            </Avatar>
          )}
          {!!data.customer?.deviceType && !!getDeviceTypeIcon(data.customer?.deviceType) && (
            <DeviceTypeIconContainer>{getDeviceTypeIcon(data.customer?.deviceType)}</DeviceTypeIconContainer>
          )}
        </PositionStateType>
        <PositionTile>
          <PositionPrimary>
            <PositionHeaderTitle>{positionHeader}</PositionHeaderTitle>
            {data.customer?.language && <Language language={data.customer.language} />}
          </PositionPrimary>
          <PositionSecondary>
            {isUnreachPosition
              ? data.customCheckpoint?.id && data.customCheckpoint?.name
                ? `${tr.monitoring.positionCannotBeServed}, ${data.customCheckpoint.name}`
                : tr.monitoring.positionCannotBeServed
              : getPositionStatusText(
                  data,
                  tr,
                  timeZoneOffset,
                  props.supportAutoCall,
                  props.displayServicePoints,
                  props.displayServiceDuration,
                  props.isOnlyOneServicePoint
                )}
          </PositionSecondary>
          {!reduced && (
            <InfoContainer>
              {data.priority && (
                <InfoRow>
                  <InfoWithIcon
                    icon={NotificationImportantIcon}
                    iconStyle={
                      [PositionState.InLineHere, PositionState.Joined, PositionState.ValidForService].includes(
                        data.state
                      )
                        ? { fill: '#ff3d00', opacity: 1 }
                        : {}
                    }
                  >
                    {tr.lineMonitoring.priority}
                  </InfoWithIcon>
                </InfoRow>
              )}
              <PreRow $isFlexible>
                <InfoRow>
                  {!!data.unreadChatMessagesCount && (
                    <InfoWithIcon icon={ChatIcon}>
                      {data.unreadChatMessagesCount > 9 ? '9+' : data.unreadChatMessagesCount}
                    </InfoWithIcon>
                  )}
                  {!props.onlyASAP && (
                    <TimeslotOrAsapStat data={data} displayTimeslot={props.displayTimeslot !== false} />
                  )}
                  {isPositionInLineHere(data) && positionInPlaceShow && (
                    <InfoWithIcon
                      iconStyle={{
                        color: isUnreachPosition ? ColorDuration.extreme : PositionStateColors[data.state],
                        opacity: 1
                      }}
                      icon={IsHereIcon}
                    >
                      {tr.positionState.inLineHere}
                    </InfoWithIcon>
                  )}
                  {data.isValid && (
                    <InfoWithIcon iconStyle={{ color: '#4caf50', opacity: 1 }} icon={CheckCircleIcon}>
                      {tr.position.isValid}
                    </InfoWithIcon>
                  )}
                  {!!data.reservationCode && (
                    <InfoWithIcon icon={CodeIcon} tooltipContent={tr.position.tooltips.reservationCode}>
                      {data.reservationCode}
                    </InfoWithIcon>
                  )}
                  {props.displayPeopleInPosition !== false && !props.onlyOneUser && !!data.personsQuantity && (
                    <Tooltip
                      arrow
                      title={tr.getParticipantFormFromTemplates(
                        data.personsQuantity,
                        props.lineParticipantTermInSingularTemplate,
                        props.lineParticipantTermInPluralTemplate
                      )}
                    >
                      <div>
                        <PersonsCount>{data.personsQuantity}</PersonsCount>
                      </div>
                    </Tooltip>
                  )}
                  {data.lastStayInLineConfirmationReceivedTime ? (
                    <Tooltip arrow title={dateFormatter(data.lastStayInLineConfirmationReceivedTime, 'dateTime')}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <LineCongirmation width={24} height={24} color="#4caf50" />
                      </div>
                    </Tooltip>
                  ) : data.lastStayInLineNotificationSentTime ? (
                    <Tooltip arrow title={dateFormatter(data.lastStayInLineNotificationSentTime, 'dateTime')}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <LineCongirmationRequest width={24} height={24} color="#ffb74d" />
                      </div>
                    </Tooltip>
                  ) : null}
                  {data.type === PositionType.MobileApp && <MobileInfoStats data={data} />}
                  {(data.state === PositionState.CalledToCheckpoint || data.state === PositionState.NearCheckpoint) &&
                    !!props.timeToReach &&
                    !!props.maxTimeToReach && (
                      <div>
                        <CallTimeStat
                          timeToReach={props.timeToReach}
                          maxTimeToReach={props.maxTimeToReach}
                          timeFromCall={data.timeFromCall}
                        />
                      </div>
                    )}
                  {!!data.acceptationState && (
                    <InfoWithIcon
                      iconStyle={{ color: acceptationStateColors[data.acceptationState], opacity: 1 }}
                      icon={acceptationStateIcons[data.acceptationState]}
                      tooltipContent={props.monitoringPositionMenuItemCaptions?.generalConditionsAcceptation}
                    >
                      {tr.position.acceptationStateText[data.acceptationState]}
                    </InfoWithIcon>
                  )}
                </InfoRow>
                <InfoRow>
                  <PositionDurations
                    data={isUnreachPosition ? { ...data, waitingToCallTime: undefined } : data}
                    displayWaitingTimeEstimation={props.displayWaitingTimeEstimation}
                    displayServiceDuration={props.displayServiceDuration !== false}
                  />
                </InfoRow>
              </PreRow>
              {!!data.progressionTags && (
                <InfoRow style={{ gap: '1rem' }}>
                  <PositionStat tooltipContent="" icon={LocalOfferIcon}>
                    <ProgressionTagContainer>
                      <PositionTags>
                        {data.progressionTags.split(';').map((tag, i) => (
                          <Chip label={tag} variant="outlined" size="small" key={i} />
                        ))}
                      </PositionTags>
                      {!!data.progressionTagsComment && (
                        <ProgressionTagsComment>{data.progressionTagsComment}</ProgressionTagsComment>
                      )}
                    </ProgressionTagContainer>
                  </PositionStat>
                  {!!data.progressionTagsImages?.length && (
                    <PositionStat tooltipContent="" icon={PhotoIcon}>
                      <div>{data.progressionTagsImages?.length}</div>
                    </PositionStat>
                  )}
                </InfoRow>
              )}
              {!!data.services?.length && (
                <InfoRow>
                  <PositionServices services={data.services} />
                </InfoRow>
              )}
              {!!data.staffComment && (
                <InfoRow>
                  <InfoWithIcon tooltipContent={tr.position.tooltips.staffComment} icon={StuffCommentIcon}>
                    {data.staffComment}
                  </InfoWithIcon>
                </InfoRow>
              )}
              {!!filledAdditionals?.length && (
                <AdditionalRow>
                  {filledAdditionals.map((additional, key) => (
                    <AdditionalItemView
                      key={key}
                      label={additional.label}
                      value={additional.value}
                      type={additional.type}
                      items={additional.comboItems}
                      displayCopyButton={additional.displayCopyButton}
                    />
                  ))}
                </AdditionalRow>
              )}
              {!!data.denialOfServiceReason && (
                <InfoRow style={{ marginTop: '1rem' }}>
                  <InfoWithIcon icon={WarningIcon} iconStyle={{ color: ColorDuration.extreme, opacity: 1 }}>
                    {tr.denialOfServiceReasons[data.denialOfServiceReason]}
                  </InfoWithIcon>
                </InfoRow>
              )}
              {!!data.visitsCount && !!data.lastVisitDate && (
                <LastVisit>
                  {tr.position.visitCount(data.visitsCount, dateFormatter(data.lastVisitDate, 'dateTime'))}
                </LastVisit>
              )}
            </InfoContainer>
          )}
        </PositionTile>
      </PositionRoot>
      {!!props.children && (
        <ActionsRoot>
          <ActionsConent onClick={handleClickStopEvents}>{props.children}</ActionsConent>
        </ActionsRoot>
      )}
    </>
  )
}

export { Position }
