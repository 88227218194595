import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import Dropdown from 'shared/ui-kit/dropdown'
import { TextField } from 'shared/ui-kit/text-field'
import { monitoring } from 'shared/api'
import { Action, AllPositionActionsDialogProps } from './types'
import { ConfirmationActions, ConfirmationTitle, ConfirmationWrapper } from '../styled'

const timeToReadyByAction: Record<Action, number> = {
  call: 5,
  remove: 10
}

function AllPositionActionsDialog({ onClose, open, lineId, placeId }: AllPositionActionsDialogProps) {
  const { tr } = useTranslation()

  const [now, setNow] = useState(Date.now())

  const interval = useRef<NodeJS.Timeout>()

  const [action, setAction] = useState<Action>('call')
  const [comment, setComment] = useState('')
  const [reason, setReason] = useState('')

  const [isConfirmation, setIsConfirmation] = useState(false)
  const [processing, setProcessing] = useState(false)

  const initTimerTime = useMemo(() => Date.now(), [isConfirmation])

  useEffect(() => {
    clearInterval(interval.current)

    if (open && isConfirmation) {
      setNow(Date.now())

      interval.current = setInterval(() => {
        const newValue = Date.now()
        setNow(newValue)

        if (newValue - initTimerTime > timeToReadyByAction[action] * 1000) {
          clearInterval(interval.current)
        }
      }, 1000)
    }

    return () => {
      clearInterval(interval.current)
    }
  }, [open, initTimerTime])

  function handleClose() {
    setIsConfirmation(false)
    setComment('')
    setReason('')
    onClose()
  }

  function handleApplyClick() {
    setIsConfirmation(true)
  }

  function handleCancelClick() {
    setIsConfirmation(false)
  }

  function handleCommentChanged(val: string) {
    setComment(val)
  }

  function handleReasonChanged(val: string) {
    setReason(val)
  }

  function handleActionChanged(val: string) {
    setAction(val as Action)
  }

  async function handleConfirm() {
    if (action === 'remove') {
      try {
        setProcessing(true)
        await monitoring.removeAllPositions({ lineId, placeId, comment: reason })
        handleClose()
        setProcessing(false)
      } catch (e) {
        console.error(e)
      }
    }

    if (action === 'call') {
      try {
        setProcessing(true)
        await monitoring.callAllPositions({ lineId, placeId, comment })
        handleClose()
        setProcessing(false)
      } catch (e) {
        console.error(e)
      }
    }
  }

  const timeToReady = (timeToReadyByAction[action] || 0) - Math.floor((now - initTimerTime) / 1000)

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{tr.lineMonitoring.allPositionsTitle}</DialogTitle>
      <DialogContent style={{ padding: '0 1.5rem' }}>
        <div style={{ margin: '0 0 1rem' }}>
          <Dropdown
            ensureValue
            label={tr.lineMonitoring.allPositionsAction}
            value={action}
            data={['call', 'remove']}
            disabled={isConfirmation}
            dataConverter={(item) => ({ value: item, text: tr.lineMonitoring.allPositionsActionItems[item] || item })}
            fullWidth
            onChange={handleActionChanged}
          />
        </div>
        {action === 'remove' && (
          <div style={{ margin: '1rem 0 0' }}>
            <TextField
              label={tr.lineMonitoring.allPositionsReason}
              placeholder={tr.lineMonitoring.allPositionsReason}
              value={reason}
              disabled={isConfirmation}
              onChange={handleReasonChanged}
              test={(v) => v.length < 101}
              attention={!reason.length}
              message={`${reason.length}/100 ${tr.entityEdit.chars}`}
              fullWidth
            />
          </div>
        )}
        {action === 'call' && (
          <div style={{ margin: '1rem 0 0' }}>
            <TextField
              label={tr.lineMonitoring.allPositionsComment}
              placeholder={tr.lineMonitoring.allPositionsComment}
              value={comment}
              disabled={isConfirmation}
              onChange={handleCommentChanged}
              test={(v) => v.length < 101}
              message={`${comment.length}/100 ${tr.entityEdit.chars}`}
              fullWidth
            />
          </div>
        )}
      </DialogContent>
      <DialogActions style={{ padding: '1rem' }}>
        {!isConfirmation && (
          <Button
            onClick={handleApplyClick}
            fullWidth
            variant="contained"
            color="primary"
            disabled={action === 'remove' && !reason}
            disableElevation
            size="small"
          >
            {tr.lineMonitoring.allPositionsApplyButton}
          </Button>
        )}
        {!!isConfirmation && (
          <ConfirmationWrapper>
            <ConfirmationTitle>
              {action === 'call' && tr.lineMonitoring.doYouWantToCallAllPositions}
              {action === 'remove' && tr.lineMonitoring.doYouWantToDeleteAllPosotions}
            </ConfirmationTitle>
            <ConfirmationActions>
              <LoadingButton
                onClick={handleConfirm}
                style={{ width: '82px' }}
                loadingIndicator={
                  <span style={{ fontSize: '12px' }}>
                    {timeToReady < 1 ? 0 : timeToReady} {tr.time.secShort}
                  </span>
                }
                loading={timeToReady > 0}
                variant="contained"
                color="primary"
                disableElevation
                size="small"
                disabled={processing}
              >
                {tr.entityEdit.yes}
              </LoadingButton>
              <Button
                style={{ width: '82px' }}
                variant="outlined"
                size="small"
                onClick={handleCancelClick}
                disabled={processing}
              >
                {tr.entityEdit.no}
              </Button>
            </ConfirmationActions>
          </ConfirmationWrapper>
        )}
      </DialogActions>
    </Dialog>
  )
}

export { AllPositionActionsDialog }
