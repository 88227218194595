import { Card, Divider, Typography, Button, Tooltip, Box } from '@mui/material'
import { useState } from 'react'
import PositionStateColors from 'features/position-dumb/utils/position-state-color'
import PositionShortInfo from './position-short-info'
import { CheckpointStatus, PositionState, PositionType } from 'shared/models'
import { getTimeFromSec } from 'shared/utils/time'
import { useTranslation } from 'shared/i18n/translate'
import TimeLeftStat from 'features/position-dumb/components/time-left-stat'
import CallTimeStat from 'features/position-dumb/components/call-time-stat'
import { CheckpointContent } from './components/checkpoint-content'
import { CheckpointStatisticProps } from './types'

function CheckpointStatistic(props: CheckpointStatisticProps) {
  const { tr } = useTranslation()

  const [actionsMode, setActionMode] = useState(false)
  const [hovered, setHovered] = useState(false)

  const position = props.position
  const checkpoint = props.checkpoint

  function handleClick() {
    setActionMode(!actionsMode)
  }

  function handleMouseEnter() {
    setHovered(true)
  }

  function handleMouseLeave() {
    setHovered(false)
  }

  const isInactive = checkpoint.status === CheckpointStatus.finished || checkpoint.status === CheckpointStatus.finishing

  const isPositionReady =
    !!position && position.state !== PositionState.Joined && position.state !== PositionState.InLineHere

  const lostLime = Date.now() - (checkpoint.lastActionTime || Date.now())
  const freeLimitPassed = lostLime > 1000 * 60 * 5
  const hhmmLostTime = getTimeFromSec(Math.abs(lostLime / 1000))

  const checkpointName = `${checkpoint.name} - ${checkpoint.hostName || tr.checkpointStatistic.undef}`

  const isRealPosition =
    !!position &&
    position.type !== PositionType.Break &&
    ![CheckpointStatus.finished, CheckpointStatus.finishing].includes(checkpoint.status)

  const maped = (position?.services || []).reduce(
    (prev, curr) => {
      const { duration, name } = curr

      return {
        name: prev.name + prev.name ? `,${name}` : name,
        duration: prev.duration + duration * (position?.personsQuantity || 1)
      }
    },
    { name: '', duration: 0 }
  )

  if (position && position.type === PositionType.Break && !maped.name.length && position.staffComment) {
    maped.name = position.staffComment
  }

  const customServiceInfo = {
    [PositionState.InService]: () => {
      return (
        <TimeLeftStat timeLeftOrOver={position?.timeLeftOrOver} timeLeftOrOverState={position?.timeLeftOrOverState} />
      )
    },

    [PositionState.CalledToCheckpoint]: () => {
      return (
        <CallTimeStat
          timeToReach={props.timeToReach ?? 0}
          maxTimeToReach={props.maxTimeToReach ?? 0}
          timeFromCall={position?.timeFromCall}
        />
      )
    }
  }

  return (
    <Card
      elevation={hovered ? 4 : 2}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      style={{ ...props.style, display: 'flex', flexDirection: 'column', borderRadius: 8, cursor: 'pointer' }}
    >
      <Box
        flex="0 0 auto"
        height="1rem"
        style={{ backgroundColor: isRealPosition ? PositionStateColors[position.state] : 'lightgray' }}
      />
      <Box padding="1rem">
        <Typography
          variant="subtitle2"
          style={{ lineHeight: '1.2', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
        >
          {checkpointName}
        </Typography>
      </Box>
      <Box flex="1" />
      {actionsMode && (
        <Box display="flex" justifyContent="center" padding="1rem" gap="1rem">
          <Button onClick={props.onCheckpointSettingsClick} size="small" color="primary">
            {tr.checkpointStatistic.settings}
          </Button>
          <Button onClick={props.onCheckpointMonitoringClick} size="small" color="primary">
            {tr.checkpointStatistic.monitoring}
          </Button>
        </Box>
      )}
      {!actionsMode && isInactive && (
        <Box
          flex="1 1 100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontSize="14px"
          padding="1rem"
          color="gray"
        >
          {tr.checkpointStatistic.unavailable}
        </Box>
      )}
      {!actionsMode && !isInactive && (
        <>
          {!isPositionReady && (
            <CheckpointContent
              accent={freeLimitPassed}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flex: '1 1 100%',
                color: freeLimitPassed ? '#FF3B30' : undefined,
                padding: '1rem'
              }}
            >
              {lostLime >= 0 ? tr.checkpointStatistic.available : tr.checkpointStatistic.willStarting}
              <Box fontWeight="bold" marginLeft="4px">
                {!!hhmmLostTime.hour && `${hhmmLostTime.hour} ${tr.checkpointStatistic.hour}`} {hhmmLostTime.minute}{' '}
                {tr.checkpointStatistic.min}
              </Box>
            </CheckpointContent>
          )}
          {isPositionReady && (
            <CheckpointContent
              style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}
            >
              <PositionShortInfo position={position} style={{ padding: '12px 8px' }} />
              <Divider />
              <Box display="flex" padding="8px" fontSize="12px" lineHeight="20px" alignItems="center">
                <Tooltip title={maped.name}>
                  <Box flex="1" textOverflow="ellipsis" marginRight="8px" overflow="hidden" whiteSpace="nowrap">
                    {maped.name}
                  </Box>
                </Tooltip>
                <Box fontWeight="bold">{customServiceInfo[position.state]?.() || ''}</Box>
              </Box>
            </CheckpointContent>
          )}
        </>
      )}
    </Card>
  )
}

export { CheckpointStatistic }
