import { createNanoEvents } from 'nanoevents'
import { PositionModel } from 'shared/models'

const emitter = createNanoEvents()
const DataKey = 'SuggestionDialogData'

type SuggestionData = {
  position: PositionModel
  timeToReach?: number
  requestCommentsOnRemove?: boolean
}

export function putPosition(data: SuggestionData) {
  localStorage.setItem(DataKey, JSON.stringify(data))
}

export function getPosition() {
  const data = localStorage.getItem(DataKey)

  if (data) {
    return JSON.parse(data) as SuggestionData | null
  }

  return null
}

export function removePosition() {
  localStorage.removeItem(DataKey)
}

export function onPositionRemove(cb: () => void) {
  removePosition()

  return emitter.on('remove', cb)
}

window.addEventListener('storage', (ev) => {
  if (ev.key === DataKey && !ev.newValue && ev.oldValue) {
    emitter.emit('remove')
  }
})
