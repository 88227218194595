import { Controller } from 'react-hook-form'
import { DatePicker } from 'features/date'
import { ControlProps } from '.'

export function DateControl(props: ControlProps) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field: f, formState }) => (
        <DatePicker
          value={f.value}
          onChange={f.onChange}
          onBlur={f.onBlur}
          label={props.label}
          placeholder={props.label}
          attention={(props.isMandatory && !f.value) || !!formState.errors[props.name]?.message}
          style={{ width: '100%' }}
          allowClear
          shrink
          message={formState.errors[props.name]?.message as string}
        />
      )}
    />
  )
}
