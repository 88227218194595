import { createElement, useEffect, useRef } from 'react'
import { getPlaceList, getTimeZones } from 'store/actions/places-action-creators'
import { CenterContainer } from 'shared/ui-kit/center-container'
import { CircularProgress } from '@mui/material'
import { getTimeZonesSelector } from 'store/selectors/timeZoneSelectors'
import { getPlacesSelector } from 'store/selectors/placesSelectors'
import { useAppDispatch, useAppSelector } from 'store'

const invalidateIntervalMs = 3600000 // 1 hour in ms

function DataProvider<CP = any>(WrappedComponent) {
  return (props: CP) => {
    const lastUpdateDate = useRef(Date.now())
    const dispatch = useAppDispatch()
    const timeZones = useAppSelector(getTimeZonesSelector)
    const places = useAppSelector(getPlacesSelector)

    function handleWindowFocus() {
      if (Date.now() - lastUpdateDate.current > invalidateIntervalMs) {
        dispatch(getPlaceList())
        lastUpdateDate.current = Date.now()
      }
    }

    useEffect(() => {
      addEventListener('focus', handleWindowFocus)

      return () => {
        removeEventListener('focus', handleWindowFocus)
      }
    }, [])

    useEffect(() => {
      if (!timeZones) {
        dispatch(getTimeZones())
      }

      if (!places) {
        dispatch(getPlaceList())
      }
    }, [places])

    if (!timeZones || !places) {
      return (
        <CenterContainer>
          <CircularProgress />
        </CenterContainer>
      )
    }

    return createElement(WrappedComponent, {
      ...props,
      getTimeZones: () => timeZones,
      getPlaces: () => places,
      getPlace: (placeId: string | number) => places?.find((x) => String(x.id) === String(placeId))
    })
  }
}

function useDataProvider() {
  const timeZones = useAppSelector(getTimeZonesSelector) || []
  const places = useAppSelector(getPlacesSelector) || []

  function getPlace(placeId: string | number) {
    return places?.find((x) => String(x.id) === String(placeId))
  }

  return { places, timeZones, getPlace }
}

export { useDataProvider }
export default DataProvider
