import { LastUpdateModel } from './last-update.model'

export interface PlaceModel {
  id: number
  name: string
  displayName?: string
  category?: number
  shortName?: string
  description: string
  address?: string
  zipCode?: string
  country?: string
  city?: string
  latitude: number
  longitude: number
  lineAuthorisationRadius: string
  brandId: string | number
  lineCount: number
  zoneCount: number
  beaconCount: number
  counterCount?: number
  checkpointCount: number
  daysOffCount?: number
  notificationEmail?: string
  reportSubscribersEmail?: string
  smsProviders?: string[]
  timeZoneId: string
  daysOff?: any
  monitoringId?: string
  allowFutureAppointments: boolean
  supportedLanguages?: string
  searchableWithRdv?: boolean
  monitoringActionShowButtonCreatePosition?: boolean
  showDataExport: boolean
  showCounters?: boolean
  showZones?: boolean
  showBeacons?: boolean
  showDaysOff?: boolean
  defaultPhoneCountryCode: string
  expeditionDefault?: boolean
  expeditionEmail?: string
  expeditionFrom?: string
  expeditionHost?: string
  expeditionPassword?: string
  expeditionPort?: number
  expeditionEnableSsl?: boolean
  lastUpdate?: LastUpdateModel
  appointmentLink?: string
  webViewLink?: string
  smsExpeditorLogin: string
  smsExpeditorPassword: string
  generalConditionsUrl?: string
  personalDataAnonymisationDelay?: number
  smsProvider: string
  customThemeName?: string
  image?: {
    id: string
    name: string
  }
  terminalCount: number
  externalCode?: string
  website?: string
  logoUrl?: string
  speech?: {
    callMode: SpeechCallProvider
    apiRegion: string
    apiKey: string
  }
}

export interface PlaceModelSimplified {
  id: number
  name: string
  description: string
  timeZoneId: string
  city?: string
  country?: string
  defaultPhoneCountryCode?: string
  supportedLanguages?: string
}

export interface PlaceListElementModel {
  name: string
  address: string
  id: string | number
  lines: {
    name: string
    id: string | number
    shortName: string
    nearestDate?: string | number
  }[]
}

export enum SpeechCallProvider {
  None = 'None',
  Yandex = 'Yandex',
  Azure = 'Azure'
}
