import axios from 'axios'
import { DaityStatsAPI } from 'shared/models'

const dailyStats: DaityStatsAPI = {
  getDailyStatsList(data, config) {
    return axios.get('reports/getDailyStatsList', { params: data, ...config })
  },
  getDailyStatsCustomers(data, config) {
    return axios.get('reports/getDailyStatsCustomers', { params: data, ...config })
  }
}

export default dailyStats
