import { Divider } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import { PositionAction, PositionState } from 'shared/models'
import PositionActionComponent from '../../components/position-action'
import { FinishServiceProps } from './types'
import StopIcon from '@mui/icons-material/Stop'

function FinishService(props: FinishServiceProps) {
  const { tr } = useTranslation()

  function handleSubmit() {
    props.handleChangeState(isTakeInCharge ? PositionState.TakeInCharge : PositionState.ManualFinished, {
      skipServiceSelect: true
    })
  }

  const isTakeInCharge =
    !!props.canSetPositionFinishedAfterArrived &&
    ![PositionState.ServiceStarted, PositionState.InService].includes(props.position.state)

  const confirmationMessage = isTakeInCharge
    ? tr.positionActions.confirmTakeInCharge(props.lineParticipantTermInSingularTemplate)
    : ''

  const enabled =
    props.posiblesStates.includes(PositionAction.FinishService) ||
    (props.posiblesStates.includes(PositionAction.SetNearCheckpoint) && isTakeInCharge)

  return (
    <>
      {!props.isCardView && <Divider />}
      <PositionActionComponent
        isCardView={props.isCardView}
        confirmation={Boolean(confirmationMessage)}
        confirmationMessage={confirmationMessage}
        onSubmit={handleSubmit}
        disabled={!enabled || !!props.disabled}
        icon={<StopIcon fontSize="small" />}
      >
        {isTakeInCharge
          ? props.monitoringPositionMenuItemCaptions?.takeInCharge || tr.positionActions.takeInCharge
          : props.monitoringPositionMenuItemCaptions?.endService || tr.positionActions.finishService}
      </PositionActionComponent>
    </>
  )
}

export { FinishService }
