import axios from 'axios'
import { getUrl } from './helpers'
import { PlaceAPI } from 'shared/models'

const place: PlaceAPI = {
  getPlaceListSimplified(config) {
    return axios.get('getShopListSimplified', config)
  },
  getPlace(id, config) {
    return axios.get('getShop', { params: { id }, ...config })
  },
  createPlace(data, config) {
    return axios.post('createShop', data, config)
  },
  updatePlace(data, config) {
    return axios.put('updateShop', data, config)
  },
  deletePlace(id, config) {
    return axios.delete('deleteShop', { data: id, headers: { 'Content-Type': 'application/json' }, ...config })
  },
  getTimeZones(config) {
    return axios.get('getTimeZones', config)
  },
  getDaysOff(data, config) {
    return axios.get('getShopDaysOff', { params: data, ...config })
  },
  updateDaysOff(data, config) {
    return axios.put('updateShopDaysOff', data, config)
  },
  exportDataFile(data) {
    window.open(getUrl('reports/exportDataFile', data), '_blank')
  },
  getExportTemplates(data, config) {
    return axios.get('getExportTemplates', { params: data, ...config })
  },
  copyExportTemplateToAnotherPlace(id, placeId, config) {
    return axios.put('copyExportTemplateToOtherSite', { id, placeId }, config)
  },
  deleteExportTemplate(id, config) {
    return axios.delete(`deleteExportTemplate/${id}`, config)
  },
  renameExportTemplate(id, name, config) {
    return axios.put('renameExportTemplate', { id, name }, config)
  },
  duplicatePlace(id, config) {
    return axios.post('duplicateShop', { id }, config)
  },
  sendTestEmail(data, config) {
    return axios.post('sendTestEmail', data, config)
  }
}

export default place
