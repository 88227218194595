import { ReactNode } from 'react'

type Props = {
  color: string
  value: number
  children?: ReactNode
}

function ProgressBarPartComponent(props: Props) {
  return (
    <div
      style={{
        background: props.color || 'transparent',
        flex: `${props.value || 0} 1 auto`,
        height: '100%',
        minWidth: 24
      }}
    >
      {props.children}
    </div>
  )
}

export default ProgressBarPartComponent
export const ProgressBarPart = ProgressBarPartComponent
