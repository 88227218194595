import { Route, Switch, Redirect } from 'react-router-dom'
import MainLayout from 'features/main-layout'
import Login from 'pages/login'
import LoginByTemporaryCode from 'pages/login-by-temporary-code'
import ResetPassword from 'pages/reset-password'
import * as Nav from 'pages/nav'
import MainRouting from 'pages/backoffice-routing'
import storeUser from 'features/store-user'
import SuggestionBlankDialog from 'pages/checkpoint/suggestion-blank-dialog'
import SuggestionFinishBlankDialog from 'pages/checkpoint/suggestion-finish-blank-dialog'
import { ViewPermissionWrapper } from 'features/view-permission-wrapper'
import { getUserLoadedSelector } from 'store/selectors/userSelectors'
import { useAppSelector } from 'store'

function Routing() {
  const userLoaded = useAppSelector(getUserLoadedSelector)

  if (!userLoaded) {
    return (
      <Switch>
        <Route path={Nav.login()} component={Login} />
        <Route path={Nav.loginByTemporaryCode()} component={LoginByTemporaryCode} />
        <Route path={Nav.resetPassword()} component={ResetPassword} />
        <Redirect to={Nav.login()} />
      </Switch>
    )
  }

  return (
    <Switch>
      <Route
        exact
        path={Nav.checkpointMonitoringDialog(':placeId', ':lineId', ':checkpointId')}
        component={SuggestionBlankDialog}
      />
      <Route
        exact
        path={Nav.checkpointMonitoringFinishDialog(':placeId', ':lineId', ':checkpointId')}
        component={SuggestionFinishBlankDialog}
      />
      <ViewPermissionWrapper>
        <MainLayout>
          <MainRouting />
        </MainLayout>
      </ViewPermissionWrapper>
    </Switch>
  )
}

export default storeUser(Routing)
