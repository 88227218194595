import { useEffect, useState } from 'react'
import { injectTestAttr } from 'shared/utils/test-attr'
import {
  ControlWrapper,
  Root,
  Message,
  Attention,
  Title,
  Hint,
  HintIndent,
  HintText,
  Control,
  InputWrapper,
  Label,
  EndAdornment
} from './styled'
import { TextFieldProps } from './types'

function Input<T extends string>(props: TextFieldProps<T>) {
  const [value, setValue] = useState(props.value ?? props.defaultValue ?? '')
  const [focused, setFocused] = useState(false)

  useEffect(() => {
    setValue(props.value || '')
  }, [props.value])

  function handleFocus(ev) {
    setFocused(true)
    props.onFocus?.(ev)
  }

  function handleBlur(ev) {
    setFocused(false)
    props.onBlur?.(ev)
  }

  function handleChange(ev) {
    const value = ev.currentTarget.value

    if (value && props.test && !props.test(value)) {
      return
    }

    setValue(value)

    props.onChange?.(value)
  }

  return (
    <Root style={props.style} className={props.className}>
      <Title>
        <Label invalid={props.invalid} focused={focused}>
          {props.label || ''}
        </Label>
        {props.attention && <Attention />}
      </Title>
      <ControlWrapper>
        {!!value && !!props.hint && (
          <Hint>
            <HintIndent>{value}</HintIndent>
            <HintText>{props.hint(value)}</HintText>
          </Hint>
        )}
        <InputWrapper invalid={props.invalid} focused={focused}>
          <Control
            {...injectTestAttr(props)}
            autoComplete={props.autocomplete}
            id={props.id}
            onClick={props.onClick}
            value={value}
            placeholder={props.placeholder}
            disabled={props.disabled}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            autoFocus={props.autoFocus || props.autofocus}
            type={props.type || 'text'}
            onSelect={props.onSelect}
          />
          <EndAdornment>{props.endAdornment}</EndAdornment>
        </InputWrapper>
      </ControlWrapper>
      {!!props.message?.length && <Message invalid={props.invalid}>{props.message}</Message>}
    </Root>
  )
}

export { Input }
