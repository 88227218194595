import { ReactNode } from 'react'
import { FormActionGroup } from '../form'
import { useTranslation } from 'shared/i18n/translate'
import css from './entity-actions.module.scss'
import Text from '../text'
import { LastInfoUserModel } from 'shared/models'

type Props = {
  updateData?: { date: number; user?: LastInfoUserModel }
  showButtons: boolean
  buttons: ReactNode
}

function EntityActionsComponent(props: Props) {
  const { tr, dateFormatter } = useTranslation()
  const { lastUpdate } = tr.entityView
  const { updateData, showButtons, buttons } = props
  const { date, user } = updateData || {}

  return (
    <FormActionGroup>
      {showButtons ? buttons : <div style={{ marginLeft: -8 }} />}
      {updateData && user && date && (
        <div className={css.lastUpdate}>
          <Text type="body-3">{lastUpdate(user?.firstName, user?.secondName, dateFormatter(date, 'dateTime'))}</Text>
        </div>
      )}
    </FormActionGroup>
  )
}

export default EntityActionsComponent
export const EntityActions = EntityActionsComponent
