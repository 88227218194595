import { ListItemIcon as ListItemIconMaterial, ListItemIconProps } from '@mui/material'

function ListItemIcon(props: ListItemIconProps) {
  return (
    <ListItemIconMaterial style={{ minWidth: '36px' }} {...props}>
      {props.children}
    </ListItemIconMaterial>
  )
}

export { ListItemIcon }
