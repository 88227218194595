import { Form, FormGroup } from 'shared/ui-kit/form'
import { TextField } from 'shared/ui-kit/text-field'
import TextFieldMUI from '@mui/material/TextField'
import { useTranslation } from 'shared/i18n/translate'
import { isPositiveNumber, isPositiveInteger } from 'shared/utils/string-test'
import Dropdown from 'shared/ui-kit/dropdown'
import Toggle from 'shared/ui-kit/toggle-wrap'
import Card from 'shared/ui-kit/card'
import { Divider, Button } from '@mui/material'
import { addTestAttr } from 'shared/utils/test-attr'
import Autocomplete from 'shared/ui-kit/autocomplete'
import { InputTranslation } from 'features/input-translation'
import FileUploader from 'features/file-uploader'
import { useEffect, useMemo, useState } from 'react'
import { PlaceModel, SpeechCallProvider } from 'shared/models'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import uniqueId from 'lodash/uniqueId'
import { ChipListSelect } from 'shared/ui-kit/selects'
import { place as placeApi } from 'shared/api'
import { useAppDispatch } from 'store'
import { MessageTypes, showMessage } from 'store/actions/main-layout-action-creators'
import { PlaceEditProps } from './types'
import { ActionsWrapper, ConfirmationActions, ActionText, ButtonWithTextWrapper } from 'shared/ui-kit/action-wrapper'
import Text from 'shared/ui-kit/text'
import DeleteIcon from '@mui/icons-material/Delete'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

function PlaceEdit(props: PlaceEditProps) {
  const { tr, dateFormatter } = useTranslation()
  const [isConfirmDuplicate, setConfirmDuplicate] = useState(false)
  const [isConfirmDelete, setConfirmDelete] = useState(false)

  const dispatch = useAppDispatch()

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required().max(200),
        shortName: Yup.string().max(20),
        description: Yup.string().max(2000),
        brandId: Yup.number().required(),
        externalCode: Yup.string().max(100),
        monitoringId: Yup.string().max(200),
        customThemeName: Yup.string().max(200),
        lineAuthorisationRadius: Yup.string().required(),
        latitude: Yup.string()
          .required()
          .matches(/^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,9})?))$/),
        longitude: Yup.string()
          .required()
          .matches(/^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,9})?))$/)
      }),
    []
  )

  const { control, handleSubmit, formState, watch, setValue } = useForm<PlaceModel>({
    resolver: yupResolver(validationSchema as any),
    defaultValues: props.data,
    mode: 'onChange'
  })

  useEffect(() => {
    const expeditionDefault = !props.data.expeditionHost && !props.data.expeditionFrom

    if (props.data.expeditionDefault === undefined) {
      setValue('expeditionDefault', expeditionDefault)
    }
  }, [])

  async function sendTestEmail() {
    try {
      await placeApi.sendTestEmail({
        shopId: props.data.id,
        email: watch('expeditionEmail'),
        enableSsl: watch('expeditionEnableSsl'),
        from: watch('expeditionFrom'),
        host: watch('expeditionHost'),
        password: watch('expeditionPassword'),
        port: watch('expeditionPort')
      })

      dispatch(showMessage(tr.placeEdit.sendTestEmailSuccess, MessageTypes.Success))
    } catch (e) {
      console.log(e)
    }
  }

  function handleTryDelete() {
    setConfirmDelete(true)
  }

  function handleCancelDelete() {
    setConfirmDelete(false)
  }

  function handleConfirmDelete() {
    props.onDelete(props.data.id || '')
  }

  return (
    <>
      <Card>
        <form onSubmit={handleSubmit(props.onSave)} autoComplete="off">
          <div>
            {props.brands.length > 0 && (
              <FormGroup>
                <Controller
                  name="brandId"
                  control={control}
                  render={({ field: f, fieldState }) => (
                    <Dropdown
                      {...addTestAttr('PlaceEdit-BrandId')}
                      ensureValue
                      attention={!!fieldState.error}
                      label={tr.placeEdit.brand}
                      value={f.value}
                      data={props.brands}
                      dataConverter={(item) => ({ value: item.id, text: item.name })}
                      onChange={f.onChange}
                    />
                  )}
                />
              </FormGroup>
            )}
            <FormGroup>
              <Controller
                name="name"
                control={control}
                render={({ field: f, fieldState }) => (
                  <InputTranslation
                    inputValue={f.value}
                    setInputValue={f.onChange}
                    brandId={watch('brandId')}
                    placeId={props.data.id}
                  >
                    <TextField
                      {...addTestAttr('PlaceEdit-Name')}
                      label={tr.placeEdit.name}
                      placeholder={tr.placeEdit.namePlaceholder}
                      autofocus={true}
                      value={f.value}
                      onChange={f.onChange}
                      attention={!!fieldState.error}
                      message={`${f.value?.length || 0}/200 ${tr.entityEdit.chars}`}
                      test={(v) => v.length < 201}
                    />
                  </InputTranslation>
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="shortName"
                control={control}
                render={({ field: f, fieldState }) => (
                  <TextField
                    {...addTestAttr('PlaceEdit-ShortName')}
                    label={tr.placeEdit.shortName}
                    placeholder={tr.placeEdit.shortNamePlaceholder}
                    value={f.value}
                    onChange={f.onChange}
                    test={(v) => v.length < 21}
                    attention={!!fieldState.error}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="description"
                control={control}
                render={({ field: f, fieldState }) => (
                  <InputTranslation
                    inputValue={f.value}
                    setInputValue={f.onChange}
                    brandId={watch('brandId')}
                    placeId={props.data.id}
                  >
                    <TextFieldMUI
                      {...addTestAttr('PlaceEdit-Description')}
                      label={tr.placeEdit.description}
                      placeholder={tr.placeEdit.descriptionPlaceholder}
                      value={f.value}
                      onChange={f.onChange}
                      helperText={`${f.value?.length || 0}/2000 ${tr.entityEdit.chars}`}
                      error={!!fieldState.error}
                      multiline
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      maxRows={15}
                    />
                  </InputTranslation>
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="image"
                control={control}
                render={({ field: f }) => (
                  <FileUploader
                    {...addTestAttr('PlaceEdit-Image')}
                    label={tr.placeEdit.image}
                    file={f.value}
                    onChange={f.onChange}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="externalCode"
                control={control}
                render={({ field: f }) => (
                  <TextField
                    {...addTestAttr('PlaceEdit-ExternalCode')}
                    label={tr.placeEdit.externalCode}
                    placeholder={tr.placeEdit.externalCode}
                    value={f.value}
                    onChange={f.onChange}
                    message={`${f.value?.length || 0}/100 ${tr.entityEdit.chars}`}
                    test={(v) => v.length < 101}
                  />
                )}
              />
            </FormGroup>
            {props.isAdmin && (
              <FormGroup>
                <Controller
                  name="customThemeName"
                  control={control}
                  render={({ field: f }) => (
                    <TextField
                      {...addTestAttr('PlaceEdit-Theme')}
                      label={tr.placeEdit.theme}
                      placeholder={tr.placeEdit.theme}
                      value={f.value}
                      onChange={f.onChange}
                      message={`${f.value?.length || 0}/200 ${tr.entityEdit.chars}`}
                      test={(v) => v.length < 201}
                    />
                  )}
                />
              </FormGroup>
            )}
            {watch('monitoringId') !== undefined && (
              <FormGroup>
                <Controller
                  name="monitoringId"
                  control={control}
                  render={({ field: f, fieldState }) => (
                    <TextField
                      {...addTestAttr('PlaceEdit-MonitoringId')}
                      label={tr.placeEdit.monitoringId}
                      placeholder={tr.placeEdit.monitoringId}
                      value={f.value}
                      onChange={f.onChange}
                      attention={!!fieldState.error}
                      message={`${f.value?.length || 0}/200 ${tr.entityEdit.chars}`}
                      test={(v) => v.length < 201}
                    />
                  )}
                />
              </FormGroup>
            )}
            <Divider />
            {props.isAdmin && props.data.smsProviders && (
              <FormGroup>
                <Controller
                  name="smsProvider"
                  control={control}
                  render={({ field: f, fieldState }) => (
                    <Dropdown
                      {...addTestAttr('PlaceEdit-SmsProvider')}
                      ensureValue
                      attention={!!fieldState.error}
                      label={tr.placeEdit.smsProvider}
                      value={f.value}
                      data={props.data.smsProviders || []}
                      dataConverter={(item) => ({ value: item, text: item })}
                      onChange={f.onChange}
                    />
                  )}
                />
              </FormGroup>
            )}
            {props.isAdmin && props.data.smsProviders && watch('smsProvider') !== 'None' && (
              <FormGroup>
                <Controller
                  name="smsExpeditorLogin"
                  control={control}
                  render={({ field: f }) => (
                    <TextField
                      {...addTestAttr('PlaceEdit-SmsExpeditorLogin')}
                      label={tr.placeEdit.smsExpeditorLogin}
                      placeholder={tr.placeEdit.smsExpeditorLogin}
                      value={f.value}
                      autocomplete="sms-expeditor-login"
                      onChange={f.onChange}
                    />
                  )}
                />
              </FormGroup>
            )}
            {props.isAdmin && props.data.smsProviders && watch('smsProvider') !== 'None' && (
              <FormGroup>
                <Controller
                  name="smsExpeditorPassword"
                  control={control}
                  render={({ field: f }) => (
                    <TextField
                      {...addTestAttr('PlaceEdit-SmsExpeditorPassword')}
                      label={tr.placeEdit.smsExpeditorPassword}
                      placeholder={tr.placeEdit.smsExpeditorPassword}
                      value={f.value}
                      type="password"
                      autocomplete="new-password"
                      onChange={f.onChange}
                    />
                  )}
                />
              </FormGroup>
            )}
            <Divider />
            <FormGroup>
              <Controller
                name="speech.callMode"
                control={control}
                defaultValue={SpeechCallProvider.None}
                render={({ field: f }) => (
                  <Dropdown
                    ensureValue
                    label={tr.placeEdit.speechCallProvider}
                    value={f.value}
                    data={Object.values(SpeechCallProvider)}
                    dataConverter={(item) => ({ value: item, text: tr.placeEdit.speechProvider[item] || item })}
                    onChange={f.onChange}
                  />
                )}
              />
            </FormGroup>
            {watch('speech.callMode') !== 'None' && (
              <FormGroup>
                <Controller
                  name="speech.apiKey"
                  control={control}
                  render={({ field: f }) => (
                    <TextField
                      label={tr.placeEdit.speechProviderKey}
                      placeholder={tr.placeEdit.speechProviderKey}
                      value={f.value}
                      onChange={f.onChange}
                      {...addTestAttr('TerminalEdit-SpeechProviderKey')}
                    />
                  )}
                />
              </FormGroup>
            )}
            {watch('speech.callMode') === 'Azure' && (
              <FormGroup>
                <Controller
                  name="speech.apiRegion"
                  control={control}
                  render={({ field: f }) => (
                    <TextField
                      label={tr.placeEdit.speechProviderRegion}
                      placeholder={tr.placeEdit.speechProviderRegion}
                      value={f.value}
                      onChange={f.onChange}
                      {...addTestAttr('TerminalEdit-SpeechProviderRegion')}
                    />
                  )}
                />
              </FormGroup>
            )}
            <Divider />
            {props.timeZones.length && (
              <FormGroup>
                <Controller
                  name="timeZoneId"
                  control={control}
                  render={({ field: f }) => (
                    <Dropdown
                      {...addTestAttr('PlaceEdit-TimeZone')}
                      ensureValue
                      label={tr.placeEdit.timeZone}
                      value={f.value}
                      data={props.timeZones}
                      dataConverter={(item) => ({
                        value: item.id,
                        text: item.name
                      })}
                      onChange={f.onChange}
                    />
                  )}
                />
              </FormGroup>
            )}
            <FormGroup>
              <Controller
                name="supportedLanguages"
                control={control}
                render={({ field: f }) => (
                  <>
                    <TextField
                      {...addTestAttr('PlaceEdit-SupportedLanguages')}
                      label={tr.placeEdit.supportedLanguages}
                      placeholder={tr.placeEdit.supportedLanguages}
                      value={f.value}
                      onChange={f.onChange}
                      disabled
                    />
                    <ChipListSelect
                      multiple
                      list={props.languages || []}
                      value={(f.value || '')?.split(';')}
                      onChange={(val) => f.onChange(val.filter(Boolean).join(';'))}
                      compare={(valueItem, listItem) => valueItem === listItem.value}
                      dataConverter={(item) => item.value}
                      labelExtractor={(item) => (item ? item.name : '')}
                    />
                  </>
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="defaultPhoneCountryCode"
                control={control}
                render={({ field: f }) => (
                  <TextField
                    {...addTestAttr('PlaceEdit-DefaultPhoneCountryCode')}
                    label={tr.lineEdit.defaultPhoneCountryCode}
                    placeholder={tr.lineEdit.defaultPhoneCountryCode}
                    value={f.value}
                    onChange={f.onChange}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="website"
                control={control}
                render={({ field: f }) => (
                  <TextField
                    {...addTestAttr('PlaceEdit-Website')}
                    label={tr.lineEdit.website}
                    placeholder={tr.lineEdit.website}
                    value={f.value}
                    onChange={f.onChange}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="logoUrl"
                control={control}
                render={({ field: f }) => (
                  <TextField
                    {...addTestAttr('PlaceEdit-Website')}
                    label={tr.lineEdit.logoUrl}
                    placeholder={tr.lineEdit.logoUrl}
                    value={f.value}
                    onChange={f.onChange}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="generalConditionsUrl"
                control={control}
                defaultValue=""
                render={({ field: f }) => (
                  <TextField
                    {...addTestAttr('PlaceEdit-GeneralConditionsUrl')}
                    label={tr.lineEdit.generalConditionsUrl}
                    placeholder={tr.lineEdit.generalConditionsUrl}
                    value={f.value}
                    onChange={f.onChange}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="personalDataAnonymisationDelay"
                control={control}
                render={({ field: f, fieldState }) => (
                  <TextField
                    {...addTestAttr('PlaceEdit-PersonalDataAnonymisationDelay')}
                    label={tr.placeEdit.personalDataAnonymisationDelay}
                    placeholder={tr.placeEdit.personalDataAnonymisationDelay}
                    value={f.value}
                    onChange={(val) => f.onChange(val ? Number(val) : undefined)}
                    attention={!!fieldState.error}
                    test={isPositiveInteger}
                    type="number"
                    hint={tr.placeEdit.personalDataAnonymisationDelayHint}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="searchableWithRdv"
                control={control}
                render={({ field: f }) => (
                  <Toggle
                    {...addTestAttr('PlaceEdit-SearchableWithRdv')}
                    toggled={f.value}
                    label={tr.placeEdit.searchable}
                    onToggle={(_, val) => f.onChange(val)}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="monitoringActionShowButtonCreatePosition"
                control={control}
                render={({ field: f }) => (
                  <Toggle
                    {...addTestAttr('PlaceEdit-MonitoringActionShowButtonCreatePosition')}
                    toggled={f.value}
                    label={tr.placeEdit.creationOfNewAppointment}
                    onToggle={(_, val) => f.onChange(val)}
                  />
                )}
              />
            </FormGroup>
            <Divider />
            <Form title={tr.placeEdit.features} expandable>
              <FormGroup>
                <Controller
                  name="showDataExport"
                  control={control}
                  render={({ field: f }) => (
                    <Toggle
                      {...addTestAttr('PlaceEdit-ShowDataExport')}
                      toggled={f.value}
                      label={tr.placeEdit.showDataExport}
                      onToggle={(_, val) => f.onChange(val)}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Controller
                  name="showCounters"
                  control={control}
                  render={({ field: f }) => (
                    <Toggle
                      {...addTestAttr('PlaceEdit-ShowCounters')}
                      toggled={f.value}
                      label={tr.placeEdit.showCounters}
                      onToggle={(_, val) => f.onChange(val)}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Controller
                  name="showZones"
                  control={control}
                  render={({ field: f }) => (
                    <Toggle
                      {...addTestAttr('PlaceEdit-ShowZones')}
                      toggled={f.value}
                      label={tr.placeEdit.showZones}
                      onToggle={(_, val) => f.onChange(val)}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Controller
                  name="showBeacons"
                  control={control}
                  render={({ field: f }) => (
                    <Toggle
                      {...addTestAttr('PlaceEdit-ShowBeacons')}
                      toggled={f.value}
                      label={tr.placeEdit.showBeacons}
                      onToggle={(_, val) => f.onChange(val)}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Controller
                  name="showDaysOff"
                  control={control}
                  render={({ field: f }) => (
                    <Toggle
                      {...addTestAttr('PlaceEdit-ShowDaysOff')}
                      toggled={f.value}
                      label={tr.placeEdit.showDaysOff}
                      onToggle={(_, val) => f.onChange(val)}
                    />
                  )}
                />
              </FormGroup>
            </Form>
            <Divider />
            <Form
              title={tr.placeEdit.location}
              expandable
              attention={
                !!formState.errors.latitude ||
                !!formState.errors.longitude ||
                !!formState.errors.lineAuthorisationRadius
              }
              headerProps={addTestAttr('PlaceEdit-LocationFormHeader')}
            >
              <FormGroup>
                <Controller
                  name="address"
                  control={control}
                  render={({ field: f }) => (
                    <TextField
                      {...addTestAttr('PlaceEdit-Address')}
                      label={tr.placeEdit.address}
                      placeholder={tr.placeEdit.addressPlaceholder}
                      value={f.value}
                      onChange={f.onChange}
                      message={`${f.value?.length || 0}/400 ${tr.entityEdit.chars}`}
                      test={(v) => v.length < 401}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Controller
                  name="zipCode"
                  control={control}
                  render={({ field: f }) => (
                    <TextField
                      {...addTestAttr('PlaceEdit-ZipCode')}
                      label={tr.placeEdit.zipCode}
                      placeholder={tr.placeEdit.zipCodePlaceholder}
                      value={f.value}
                      onChange={f.onChange}
                      message={`${f.value?.length || 0}/20 ${tr.entityEdit.chars}`}
                      test={(v) => v.length < 21}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Controller
                  name="city"
                  control={control}
                  render={({ field: f }) => (
                    <TextField
                      {...addTestAttr('PlaceEdit-City')}
                      label={tr.placeEdit.city}
                      placeholder={tr.placeEdit.cityPlaceholder}
                      value={f.value}
                      onChange={f.onChange}
                      message={`${f.value?.length || 0}/50 ${tr.entityEdit.chars}`}
                      test={(v) => v.length < 51}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Controller
                  name="country"
                  control={control}
                  render={({ field: f }) => (
                    <Autocomplete
                      {...addTestAttr('PlaceEdit-Country')}
                      fullWidth
                      title={tr.placeEdit.country}
                      value={f.value || ''}
                      isShrink
                      inputStyles={{ fontWeight: '300' }}
                      list={props.countries}
                      dataConverter={(el) => ({ value: el.code, text: el.title })}
                      onChange={f.onChange}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Controller
                  name="latitude"
                  control={control}
                  render={({ field: f, fieldState }) => (
                    <TextField
                      {...addTestAttr('PlaceEdit-Latitude')}
                      label={tr.placeEdit.latitude}
                      placeholder={tr.placeEdit.latitudePlaceholder}
                      value={f.value}
                      onChange={f.onChange}
                      attention={!!fieldState.error}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Controller
                  name="longitude"
                  control={control}
                  render={({ field: f, fieldState }) => (
                    <TextField
                      {...addTestAttr('PlaceEdit-Longitude')}
                      label={tr.placeEdit.longitude}
                      placeholder={tr.placeEdit.longitudePlaceholder}
                      value={f.value}
                      onChange={f.onChange}
                      attention={!!fieldState.error}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Controller
                  name="lineAuthorisationRadius"
                  control={control}
                  render={({ field: f, fieldState }) => (
                    <TextField
                      {...addTestAttr('PlaceEdit-LineAuthorisationRadius')}
                      label={tr.placeEdit.lineAuthorisationRadius}
                      placeholder={tr.placeEdit.lineAuthorisationRadiusPlaceholder}
                      value={f.value}
                      onChange={f.onChange}
                      attention={!!fieldState.error}
                      test={isPositiveNumber}
                      hint={tr.placeEdit.lineAuthorisationRadiusHint}
                    />
                  )}
                />
              </FormGroup>
            </Form>
            <Divider />
            <Form title={tr.placeEdit.expedition} expandable>
              <FormGroup>
                <Controller
                  name="expeditionDefault"
                  control={control}
                  render={({ field: f }) => (
                    <Toggle
                      toggled={f.value !== false}
                      label={tr.placeEdit.defaultSettings}
                      onToggle={(_, val) => {
                        f.onChange(val)

                        if (val === true) {
                          setValue('expeditionFrom', '')
                          setValue('expeditionEmail', '')
                          setValue('expeditionHost', '')
                          setValue('expeditionPassword', '')
                          setValue('expeditionPort', undefined)
                          setValue('expeditionEnableSsl', false)
                        }
                      }}
                    />
                  )}
                />
              </FormGroup>
              {watch('expeditionDefault') === false && (
                <>
                  <FormGroup>
                    <Controller
                      name="expeditionFrom"
                      control={control}
                      render={({ field: f }) => (
                        <TextField
                          label={tr.placeEdit.expeditionFrom}
                          placeholder={tr.placeEdit.expeditionFromPlaceholder}
                          value={f.value}
                          onChange={f.onChange}
                          message={`${f.value?.length || 0}/100 ${tr.entityEdit.chars}`}
                          test={(v) => v.length < 101}
                        />
                      )}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Controller
                      name="expeditionHost"
                      control={control}
                      render={({ field: f }) => (
                        <TextField
                          label={tr.placeEdit.expeditionHost}
                          placeholder={tr.placeEdit.expeditionHostPlaceholder}
                          value={f.value}
                          onChange={f.onChange}
                          autocomplete={uniqueId('new-host')}
                          message={`${f.value?.length || 0}/100 ${tr.entityEdit.chars}`}
                          test={(v) => v.length < 101}
                        />
                      )}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Controller
                      name="expeditionEmail"
                      control={control}
                      render={({ field: f }) => (
                        <TextField
                          label={tr.placeEdit.expeditionEmail}
                          placeholder={tr.placeEdit.expeditionEmailPlaceholder}
                          value={f.value}
                          autocomplete={uniqueId('new-email')}
                          onChange={f.onChange}
                          message={`${f.value?.length || 0}/100 ${tr.entityEdit.chars}`}
                          test={(v) => v.length < 101}
                        />
                      )}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Controller
                      name="expeditionPassword"
                      control={control}
                      render={({ field: f }) => (
                        <TextField
                          label={tr.placeEdit.expeditionPassword}
                          placeholder={tr.placeEdit.expeditionPasswordPlaceholder}
                          value={f.value}
                          autocomplete={'new-password'}
                          onChange={f.onChange}
                          type="password"
                          message={`${f.value?.length || 0}/50 ${tr.entityEdit.chars}`}
                          test={(v) => v.length < 51}
                        />
                      )}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Controller
                      name="expeditionPort"
                      control={control}
                      render={({ field: f }) => (
                        <TextField
                          label={tr.placeEdit.expeditionPort}
                          placeholder={tr.placeEdit.expeditionPortPlaceholder}
                          value={f.value}
                          type="number"
                          autocomplete={uniqueId('new-port')}
                          onChange={f.onChange}
                          test={(v) => v.length < 101}
                        />
                      )}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Controller
                      name="expeditionEnableSsl"
                      control={control}
                      render={({ field: f }) => (
                        <Toggle
                          toggled={f.value}
                          label={tr.placeEdit.expeditionEnableSsl}
                          onToggle={(_, val) => f.onChange(val)}
                        />
                      )}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Button
                      variant="outlined"
                      size="small"
                      disabled={!watch('expeditionFrom') || !watch('expeditionHost')}
                      onClick={sendTestEmail}
                    >
                      {tr.placeEdit.sendTestEmail}
                    </Button>
                  </FormGroup>
                </>
              )}
            </Form>
            <Divider />
          </div>
          {!!props.data.id && (
            <Form title={tr.entityEdit.advancedGroup} expandable>
              <FormGroup>
                {!isConfirmDuplicate && (
                  <ButtonWithTextWrapper>
                    <ActionText>{tr.lineEdit.duplicateLine}</ActionText>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => setConfirmDuplicate(true)}
                      startIcon={<ContentCopyIcon />}
                      {...addTestAttr('PlaceEdit-DuplicateButton')}
                    >
                      {tr.entityEdit.duplicateButton}
                    </Button>
                  </ButtonWithTextWrapper>
                )}
                {isConfirmDuplicate && (
                  <ButtonWithTextWrapper>
                    <ActionText>{tr.placeEdit.confirmDuplicate}</ActionText>
                    <ConfirmationActions>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => props.onDuplicate(props.data.id || '')}
                        {...addTestAttr('PlaceEdit-Yes')}
                      >
                        {tr.entityEdit.yes}
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => setConfirmDuplicate(false)}
                        {...addTestAttr('PlaceEdit-No')}
                      >
                        {tr.entityEdit.no}
                      </Button>
                    </ConfirmationActions>
                  </ButtonWithTextWrapper>
                )}
              </FormGroup>
              <FormGroup>
                {!isConfirmDelete && (
                  <ButtonWithTextWrapper>
                    <ActionText>{tr.placeEdit.deleteTitle}</ActionText>
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={handleTryDelete}
                      startIcon={<DeleteIcon />}
                      {...addTestAttr('PlaceEdit-DeleteButton')}
                    >
                      {tr.entityEdit.deleteButton}
                    </Button>
                  </ButtonWithTextWrapper>
                )}
                {isConfirmDelete && (
                  <ButtonWithTextWrapper>
                    <ActionText>{tr.entityEdit.confirmDelete}</ActionText>
                    <ConfirmationActions>
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={handleConfirmDelete}
                        {...addTestAttr('PlaceEdit-Yes')}
                      >
                        {tr.entityEdit.yes}
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={handleCancelDelete}
                        {...addTestAttr('PlaceEdit-No')}
                      >
                        {tr.entityEdit.no}
                      </Button>
                    </ConfirmationActions>
                  </ButtonWithTextWrapper>
                )}
              </FormGroup>
            </Form>
          )}
        </form>
      </Card>
      <ActionsWrapper>
        <Button
          disabled={!!Object.keys(formState.errors).length}
          onClick={handleSubmit(props.onSave)}
          variant="contained"
          color="primary"
          size="small"
          {...addTestAttr('PlaceEdit-SaveButton')}
        >
          {props.data.id ? tr.entityEdit.updateButton : tr.entityEdit.createButton}
        </Button>
        {props.data.lastUpdate?.date && props.data.lastUpdate.user && (
          <Text type="caption">
            {tr.entityView.lastUpdate(
              props.data.lastUpdate.user?.firstName,
              props.data.lastUpdate.user?.secondName,
              dateFormatter(props.data.lastUpdate.date, 'dateTime')
            )}
          </Text>
        )}
      </ActionsWrapper>
    </>
  )
}

export { PlaceEdit }
