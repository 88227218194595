import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import { useAppDispatch, useAppSelector } from 'store'
import { closeNotificationDeclinedDialog } from 'store/reducers/user-reducer'
import { getNotificationDeclinedDialog } from 'store/selectors/notificationSelectors'

function NotificationDeclinedDialog() {
  const { tr } = useTranslation()

  const open = useAppSelector(getNotificationDeclinedDialog)
  const dispatch = useAppDispatch()

  function handleClose() {
    dispatch(closeNotificationDeclinedDialog())
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{tr.notifications.notificationsWasRejectedDialogTitle}</DialogTitle>
      <DialogContent>
        <Typography>{tr.notifications.notificationsWasRejectedDialogText}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" size="small" disableElevation>
          {tr.notifications.closeButton}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { NotificationDeclinedDialog }
