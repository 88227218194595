import { Component } from 'react'
import { connect } from 'react-redux'
import { TimeZone } from 'shared/ui-kit/app-bar'
import dataProvider from 'features/isolated-data-provider'
import { getLocalTimeZone, setLocalTimeZone, ensureLocalTimeZoneId } from 'shared/utils/date'
import uniq from 'lodash/uniq'
import { RootState } from 'store'

class SmartTimeZonesComponent extends Component<any> {
  state = {
    timeZones: [],
    shops: null,
    currentPlaceId: null,
    currentShopTimeZone: null
  }

  constructor(props) {
    super(props)

    const localTimeZoneFromId = props.getTimeZones().find((z) => z.id === getLocalTimeZone().id)
    const localTimeZoneFromOffiset = props.getTimeZones().find((z) => z.offset === getLocalTimeZone().offset)
    setLocalTimeZone(localTimeZoneFromId || localTimeZoneFromOffiset)
  }

  componentDidMount() {
    const localTimeZone: any =
      this.state.timeZones
        .filter(Boolean)
        .find((x: any) => x?.id === Intl.DateTimeFormat().resolvedOptions().timeZone) || this.state.timeZones[0]

    this.props.onChange(localTimeZone)
  }

  componentDidUpdate(_, prevState) {
    const { currentPlaceId, currentShopTimeZone, timeZones } = this.state
    const newShopSelected = currentPlaceId && currentPlaceId !== prevState.currentPlaceId

    if (newShopSelected) {
      this.props.onChange(currentShopTimeZone)
      return
    }

    const timeZonesChangeAndCurrentNotFound =
      timeZones.length !== prevState.timeZones.length &&
      !timeZones.filter(Boolean).find((x: any) => x?.id === this.props.currentTimeZoneId)

    if (timeZonesChangeAndCurrentNotFound) {
      const localTimeZone = (timeZones || [])
        .filter(Boolean)
        .find((x: any) => x?.id === Intl.DateTimeFormat().resolvedOptions().timeZone)

      this.props.onChange(localTimeZone || timeZones[0])
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.shops !== props.shops || state.currentPlaceId !== props.currentPlaceId) {
      const localTimeZoneId = ensureLocalTimeZoneId(props.getTimeZones())
      let timeZonesIds
      let currentPlaceTimeZoneId

      if (props.currentPlaceId) {
        currentPlaceTimeZoneId = props.getPlace(props.currentPlaceId)?.timeZoneId || localTimeZoneId

        if (currentPlaceTimeZoneId === localTimeZoneId) {
          timeZonesIds = [currentPlaceTimeZoneId]
        } else {
          timeZonesIds = [localTimeZoneId, currentPlaceTimeZoneId]
        }
      } else {
        const placeTimeZonesIds = props.getPlaces().map((x) => x.timeZoneId) || []
        timeZonesIds = [localTimeZoneId, ...placeTimeZonesIds]
      }

      return {
        currentShopTimeZone:
          currentPlaceTimeZoneId && props.getTimeZones().find((z) => z.id === currentPlaceTimeZoneId),
        currentPlaceId: props.currentPlaceId,
        shops: props.shops,
        timeZones: uniq(timeZonesIds).map((x) => props.getTimeZones().find((z) => z.id === x))
      }
    }

    return null
  }

  render() {
    if (!this.props.canPromoteToAdmin) {
      return null
    }

    return (
      <TimeZone timeZone={this.props.user.timeZone} timeZones={this.state.timeZones} onChange={this.props.onChange} />
    )
  }
}

const stateToProps = (state: RootState) => ({
  currentPlaceId: state.places?.currentPlaceId,
  currentTimeZoneId: state.user?.timeZone?.id,
  canPromoteToAdmin: state.user.userProfile?.permissions?.canPromoteToAdmin
})

export const SmartTimeZones = connect<any, any, any, any>(stateToProps)(dataProvider(SmartTimeZonesComponent))
