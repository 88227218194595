import { CSSProperties, ReactNode } from 'react'
import css from './chip.module.scss'
import { withMods } from 'shared/utils/cssm'

const mod = withMods(css)

type Props = {
  active?: boolean
  style?: CSSProperties
  children?: ReactNode
  onClick?: () => void
  secondText?: string
}

function Chip({ active, style, onClick, children, secondText }: Props) {
  return (
    <div className={mod.root({ active })} style={style} onClick={onClick}>
      {children}
      {secondText && <div style={{ fontSize: '11px', lineHeight: '0px', marginBottom: '8px' }}>{secondText}</div>}
    </div>
  )
}

export default Chip
