import { PositionStateColors } from 'features/position-dumb'
import { useTranslation } from 'shared/i18n/translate'
import Card from 'shared/ui-kit/card'
import { WhereToVote as IsHereIcon } from '@mui/icons-material'
import TimeIcon from '@mui/icons-material/AccessTime'
import { IconValue } from 'shared/ui-kit/icons'
import { StatsContainerMobile, QuantityContainer, WaitingLine, LineStatMobile, StatsIcon, StatsItem } from '../styled'
import { PersonsCount } from 'features/position/persons-count'
import { OverchargedQuadraticOccupancyProgress } from './overcharged-progress'
import { Box } from '@mui/material'
import { MobileStatiscticsProps } from './types'

function MobileStatisctics(props: MobileStatiscticsProps) {
  const { tr } = useTranslation()

  const {
    registeredPositionsCount,
    waitingPositionsCount,
    calledPositionsCount,
    inServicePositionsCount,
    impossiblePositionsCount,
    overcharged,
    finishing,
    overchargedQuadraticOccupancyPercent,
    waitingTime,
    waitingInPlacePositionsCount
  } = props

  const waitingPersonsWithoutInPlace = (waitingPositionsCount || 0) - (waitingInPlacePositionsCount || 0)

  const lineIsFull = (finishing === true && waitingTime > 1440) || overcharged

  return (
    <Card style={{ margin: 0 }}>
      <StatsContainerMobile>
        <LineStatMobile>
          <PersonsCount style={{ margin: '0 0.5rem 0 0' }}>{registeredPositionsCount} </PersonsCount>
          {(lineIsFull || waitingTime < 1440) && (
            <IconValue icon={<TimeIcon style={{ color: 'rgba(0,0,0,.28)' }} />}>
              <WaitingLine>{lineIsFull ? tr.lineMonitoring.lineFull : tr.time.hmin(waitingTime)}</WaitingLine>
            </IconValue>
          )}
        </LineStatMobile>
        <QuantityContainer>
          {!!inServicePositionsCount && (
            <StatsItem>
              <StatsIcon $background={PositionStateColors['inService']} />
              {inServicePositionsCount}
            </StatsItem>
          )}
          {!!calledPositionsCount && (
            <StatsItem>
              <StatsIcon $background={PositionStateColors['calledToCheckpoint']} />
              {calledPositionsCount}
            </StatsItem>
          )}
          {!!waitingInPlacePositionsCount && (
            <IconValue icon={<IsHereIcon style={{ color: PositionStateColors['joined'], height: '21px' }} />}>
              <div style={{ color: 'rgba(0, 0, 0, 0.54)' }}>{waitingInPlacePositionsCount}</div>
            </IconValue>
          )}
          {!!waitingPersonsWithoutInPlace && (
            <StatsItem>
              <StatsIcon $background={PositionStateColors['joined']} />
              {waitingPersonsWithoutInPlace}
            </StatsItem>
          )}
          {!!impossiblePositionsCount && (
            <StatsItem>
              <StatsIcon $background="#f44336" />
              {impossiblePositionsCount}
            </StatsItem>
          )}
        </QuantityContainer>
      </StatsContainerMobile>
      {overchargedQuadraticOccupancyPercent !== undefined && (
        <Box sx={{ margin: '-0.5rem 1rem 0.5rem' }}>
          <OverchargedQuadraticOccupancyProgress quadraticOccupancyPercent={overchargedQuadraticOccupancyPercent} />
        </Box>
      )}
    </Card>
  )
}

export { MobileStatisctics }
