import { useMemo } from 'react'
import { PositionStat } from 'features/position-stat'
import BasketIcon from '@mui/icons-material/ShoppingBasket'
import { useTranslation } from 'shared/i18n/translate'
import { ServiceModel, ServiceSmallModel } from 'shared/models'

type Props = {
  services?: ServiceModel[] | ServiceSmallModel[] | { name: string }[]
}

function PositionServicesComponent({ services = [] }: Props) {
  const { tr } = useTranslation()

  const serviceNames = useMemo(() => services.map((service) => service.name).join(', '), [services])

  if (!serviceNames) {
    return null
  }

  return (
    <PositionStat icon={BasketIcon} tooltipContent={tr.position.tooltips.selectedServices}>
      {serviceNames}
    </PositionStat>
  )
}

export default PositionServicesComponent
export const PositionServices = PositionServicesComponent
