import { CSSProperties } from 'react'

const IconStyle = {
  width: '20px',
  height: '20px',
  boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  borderRadius: '50%'
}

type Props = {
  language: string
  style?: CSSProperties
  className?: string
  useDefautStyle?: boolean
}

function LanguageIconComponent({ language, style, className, useDefautStyle = true }: Props) {
  if (!language) {
    return <span style={style} className={className}></span>
  }

  return (
    <div style={useDefautStyle ? { ...IconStyle, ...style } : style} className={className}>
      <img src={`flags-rounded/${language}.svg`} />
    </div>
  )
}

export default LanguageIconComponent
export const Language = LanguageIconComponent
