import { Form, FormGroup } from 'shared/ui-kit/form'
import { TextField } from 'shared/ui-kit/text-field'
import { DatePicker } from 'features/date'
import { useTranslation } from 'shared/i18n/translate'
import { isPositiveNullInteger } from 'shared/utils/string-test'
import TimePickerTimestamp from 'features/time/time-picker-timestamp'
import Toggle from 'shared/ui-kit/toggle-wrap'
import Card from 'shared/ui-kit/card'
import { Divider, Button } from '@mui/material'
import { ChipListSelect } from 'shared/ui-kit/selects'
import { ToggleList } from 'shared/ui-kit/toggle-list'
import { StaffManagementModel } from 'shared/models'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { StaffManagementEditProps } from './types'
import { getValidationConfig } from './validations'
import { getDaySchedule } from '../utils'
import { ActionText, ActionsWrapper, ButtonWithTextWrapper, ConfirmationActions } from 'shared/ui-kit/action-wrapper'
import Text from 'shared/ui-kit/text'
import DeleteIcon from '@mui/icons-material/Delete'

function StaffManagementEdit(props: StaffManagementEditProps) {
  const { tr, timeZoneOffset, dateFormatter } = useTranslation()
  const [isConfirmDelete, setConfirmDelete] = useState(false)

  const { control, handleSubmit, watch, setValue, formState, trigger } = useForm<StaffManagementModel>({
    resolver: yupResolver(getValidationConfig(props.data.services)),
    defaultValues: props.data,
    mode: 'onChange'
  })

  useEffect(() => {
    setValue('applyToServicePoints', (props.data.servicePointsSelected?.length || 0) > 0)
    trigger()
  }, [])

  function handleTryDelete() {
    setConfirmDelete(true)
  }

  function handleCancelDelete() {
    setConfirmDelete(false)
  }

  function handleConfirmDelete() {
    props.onDelete(props.data.id)
  }

  const {
    startDate,
    startHour,
    endHour,
    numberOfStaff,
    comments,
    commentsPlaceholder,
    numberOfStaffHint,
    numberOfStaffPlaceholder,
    servicePause,
    pauseStart,
    pauseEnd,
    checkpoints,
    applyToServicePoints,
    services
  } = tr.staffManagementEdit

  const offset = timeZoneOffset
  const localoffset = new Date().getTimezoneOffset() * 1000 * 60

  const pauseStartHour_unixtime = watch('pauseStartHour_unixtime') || 0
  const pauseEndHour_unixtime = watch('pauseEndHour_unixtime') || 0
  const startHour_unixtime = watch('startHour_unixtime') || 0
  const endHour_unixtime = watch('endHour_unixtime') || 0

  const pauseStartWithoutDate = new Date(pauseStartHour_unixtime + offset + localoffset).setFullYear(1970, 0, 1)
  const pauseEndWithoutDate = new Date(pauseEndHour_unixtime + offset + localoffset).setFullYear(1970, 0, 1)
  const startWithoutDate = new Date(startHour_unixtime + offset + localoffset).setFullYear(1970, 0, 1)
  const endWithoutDate = new Date(endHour_unixtime + offset + localoffset).setFullYear(1970, 0, 1)

  const pauseIsValid = pauseStartWithoutDate <= pauseEndWithoutDate || !watch('serviceWithPause')
  const startIsValid = startWithoutDate <= endWithoutDate

  const isValid = !!Object.keys(formState.errors).length || !pauseIsValid || !startIsValid

  return (
    <form onSubmit={handleSubmit(props.onSave)}>
      <Card>
        <Form>
          <FormGroup>
            <Controller
              name="startDate"
              control={control}
              render={({ field: f }) => (
                <DatePicker
                  value={f.value}
                  onChange={(val) => {
                    f.onChange(val ? val : null)

                    const schedule = getDaySchedule(props.data.schedule, val)

                    if (props.isNew && schedule) {
                      setValue('startHour_unixtime', schedule.openingHour_unixtime || 0)
                      setValue('endHour_unixtime', schedule.closingHour_unixtime || 0)
                      setValue('serviceWithPause', schedule.serviceWithPause)
                      setValue('pauseStartHour_unixtime', schedule.pauseStartHour_unixtime)
                      setValue('pauseEndHour_unixtime', schedule.pauseEndHour_unixtime)
                    }
                  }}
                  disabled={props.isProcessing}
                  onBlur={f.onBlur}
                  label={startDate}
                  placeholder={startDate}
                  style={{ width: '100%' }}
                  shrink
                />
              )}
            />
          </FormGroup>
          {(props.data.services?.length || 0) > 0 && (
            <FormGroup>
              <Controller
                name="servicesSelected"
                control={control}
                render={({ field: f }) => (
                  <ToggleList
                    attention={!f.value?.length}
                    label={services}
                    list={props.data.services || []}
                    value={f.value || []}
                    onChange={(val) => {
                      f.onChange(val ? val : [])
                    }}
                    disabled={props.isProcessing}
                    compare={(valueItem, listItem) => valueItem.id === listItem.id}
                    labelExtractor={(item) => (item ? item.name : '')}
                  />
                )}
              />
            </FormGroup>
          )}
          <Divider />
          <FormGroup>
            <Controller
              name="numberOfStaff"
              control={control}
              render={({ field: f, formState }) => (
                <TextField
                  label={numberOfStaff}
                  placeholder={numberOfStaffPlaceholder}
                  type="number"
                  value={f.value}
                  onChange={f.onChange}
                  test={isPositiveNullInteger}
                  hint={numberOfStaffHint}
                  disabled={props.isProcessing}
                  attention={!!formState.errors.numberOfStaff}
                />
              )}
            />
          </FormGroup>
          <FormGroup style={{ display: 'flex', gap: 16 }}>
            <Controller
              name="startHour_unixtime"
              control={control}
              render={({ field: f, formState }) => (
                <TimePickerTimestamp
                  style={{ flex: 1 }}
                  attention={!!formState.errors.startHour_unixtime}
                  label={startHour}
                  value={f.value}
                  onChange={f.onChange}
                  disabled={props.isProcessing}
                  offset
                />
              )}
            />
            <Controller
              name="endHour_unixtime"
              control={control}
              render={({ field: f, formState }) => {
                return (
                  <TimePickerTimestamp
                    style={{ flex: 1 }}
                    attention={!!formState.errors.endHour_unixtime || !startIsValid}
                    label={endHour}
                    value={f.value}
                    onChange={f.onChange}
                    disabled={props.isProcessing}
                    offset
                  />
                )
              }}
            />
          </FormGroup>
          <Divider />
          <FormGroup>
            <Controller
              name="serviceWithPause"
              control={control}
              render={({ field: f }) => (
                <Toggle
                  toggled={f.value || false}
                  label={servicePause}
                  disabled={props.isProcessing}
                  onToggle={() => {
                    f.onChange(!f.value)
                    trigger()
                  }}
                />
              )}
            />
          </FormGroup>
          {watch('serviceWithPause') && (
            <FormGroup style={{ display: 'flex', gap: 16 }}>
              <Controller
                name="pauseStartHour_unixtime"
                control={control}
                render={({ field: f, formState }) => (
                  <TimePickerTimestamp
                    label={pauseStart}
                    style={{ flex: 1 }}
                    value={f.value}
                    disabled={props.isProcessing}
                    onChange={(val: any) => {
                      f.onChange(isNaN(val) ? undefined : val || undefined)
                    }}
                    attention={!!formState.errors.pauseStartHour_unixtime}
                    offset
                  />
                )}
              />
              <Controller
                name="pauseEndHour_unixtime"
                control={control}
                render={({ field: f, formState }) => {
                  return (
                    <TimePickerTimestamp
                      label={pauseEnd}
                      style={{ flex: 1 }}
                      value={f.value}
                      disabled={props.isProcessing}
                      onChange={(val: any) => {
                        f.onChange(isNaN(val) ? undefined : val || undefined)
                      }}
                      attention={!!formState.errors.pauseEndHour_unixtime || !pauseIsValid}
                      offset
                    />
                  )
                }}
              />
            </FormGroup>
          )}
          <Divider />
          {(props.data.servicePoints?.length || 0) > 0 && (
            <>
              <FormGroup>
                <Controller
                  name="applyToServicePoints"
                  control={control}
                  render={({ field: f }) => (
                    <Toggle
                      toggled={f.value || false}
                      label={applyToServicePoints}
                      disabled={props.isProcessing}
                      onToggle={async () => {
                        f.onChange(!f.value)
                        setValue('servicePointsSelected', [])
                      }}
                    />
                  )}
                />
              </FormGroup>
              {watch('applyToServicePoints') && (
                <FormGroup>
                  <Controller
                    name="servicePointsSelected"
                    control={control}
                    render={({ field: f }) => (
                      <ChipListSelect
                        multiple
                        autoSelect={false}
                        label={checkpoints}
                        list={props.data.servicePoints || []}
                        value={f.value || []}
                        onChange={f.onChange}
                        disabled={props.isProcessing}
                        compare={(valueItem, listItem) => valueItem.id === listItem.id}
                        labelExtractor={(item) => (item ? item.name : '')}
                      />
                    )}
                  />
                </FormGroup>
              )}
              <Divider />
            </>
          )}
          <FormGroup>
            <Controller
              name="comments"
              control={control}
              render={({ field: f }) => (
                <TextField
                  label={comments}
                  placeholder={commentsPlaceholder}
                  value={f.value || ''}
                  disabled={props.isProcessing}
                  onChange={f.onChange}
                />
              )}
            />
          </FormGroup>
        </Form>
        {!!props.data.id && (
          <>
            <Divider />
            <Form title={tr.entityEdit.advancedGroup} expandable>
              <FormGroup>
                {!isConfirmDelete && (
                  <ButtonWithTextWrapper>
                    <ActionText>{tr.staffManagementEdit.deleteTitle}</ActionText>
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={handleTryDelete}
                      disabled={props.isProcessing}
                      startIcon={<DeleteIcon />}
                    >
                      {tr.entityEdit.deleteButton}
                    </Button>
                  </ButtonWithTextWrapper>
                )}
                {isConfirmDelete && (
                  <ButtonWithTextWrapper>
                    <ActionText>{tr.entityEdit.confirmDelete}</ActionText>
                    <ConfirmationActions>
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={handleConfirmDelete}
                        disabled={props.isProcessing}
                      >
                        {tr.entityEdit.yes}
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={handleCancelDelete}
                        disabled={props.isProcessing}
                      >
                        {tr.entityEdit.no}
                      </Button>
                    </ConfirmationActions>
                  </ButtonWithTextWrapper>
                )}
              </FormGroup>
            </Form>
          </>
        )}
      </Card>
      <ActionsWrapper>
        <Button disabled={isValid || props.isProcessing} type="submit" variant="contained" color="primary" size="small">
          {props.data.id ? tr.entityEdit.updateButton : tr.entityEdit.createButton}
        </Button>
        {props.data.lastUpdate?.date && props.data.lastUpdate.user && (
          <Text type="caption">
            {tr.entityView.lastUpdate(
              props.data.lastUpdate.user?.firstName,
              props.data.lastUpdate.user?.secondName,
              dateFormatter(props.data.lastUpdate.date, 'dateTime')
            )}
          </Text>
        )}
      </ActionsWrapper>
    </form>
  )
}

export default StaffManagementEdit
