import Card from 'shared/ui-kit/card'
import { useTranslation } from 'shared/i18n/translate'
import { ProgressBar, ProgressBarPart } from 'shared/ui-kit/progress-bar'
import capitalize from 'lodash/capitalize'
import { convertPositionToStatePersons, PositionStateToColor } from '../utils'
import { Container, Title } from './styled'
import { DiscretePositionModel } from 'shared/models'
import { LineSummaryProps } from '../types'

function LineSummaryComponent(props: LineSummaryProps) {
  const { data } = props
  const { summary, slots = [] } = data
  const { tr } = useTranslation()
  const { lineReservationCapacity, lineState } = tr.monitoring

  const positions = slots.reduce((prev, curr) => {
    const { positions = [] } = curr
    return [...prev, ...positions]
  }, [] as DiscretePositionModel[])

  const positionGroups = convertPositionToStatePersons(positions)

  return (
    <Card>
      <Container>
        <div style={{ display: 'flex' }}>
          <Title style={{ flex: 1 }}>{capitalize(lineState[summary.state](summary.stateTime))}</Title>
          {summary.personsCount !== undefined && summary.fullCapacity !== undefined && (
            <Title>{lineReservationCapacity(summary.personsCount, summary.fullCapacity)}</Title>
          )}
        </div>
        <div style={{ marginTop: '1rem' }}>
          <ProgressBar total={summary.fullCapacity} progress={summary.personsCount}>
            {positionGroups.map((g, i) => (
              <ProgressBarPart key={i} value={g.value} color={PositionStateToColor[g.name]} />
            ))}
          </ProgressBar>
        </div>
      </Container>
    </Card>
  )
}

export default LineSummaryComponent
export const LineSummary = LineSummaryComponent
