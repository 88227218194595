import { useState } from 'react'
import Card from 'shared/ui-kit/card'
import { useTranslation } from 'shared/i18n/translate'
import { PositionActionType, PositionModel } from 'shared/models'
import Link from 'shared/ui-kit/link'
import { Avatar, Box, IconButton, ListItemIcon, Typography } from '@mui/material'
import { PositionTypeIcon } from 'features/position-dumb'
import PositionServices from 'features/position-dumb/components/position-services'
import * as nav from 'pages/nav'
import {
  NotificationImportant as NotificationImportantIcon,
  SupervisorAccount as SupervisorAccountIcon,
  ContactMail as StuffCommentIcon,
  Message as CommentIcon,
  Description as AdditionalsIcon,
  Person as PersonIcon,
  Phone as PhoneIcon,
  Email as EmailIcon,
  EventRepeat as AddBookingIcon,
  CopyAll as OriginalPositionIcon,
  Code as IdIcon,
  CalendarMonth as AppointmentIcon,
  Schedule as TodayIcon,
  MoreVert as CreateIcon,
  Person as PositionIcon
} from '@mui/icons-material'
import { PositionStat } from 'features/position-stat'
import { monitoring } from 'shared/api'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { setDuplicatePosition } from 'features/position-actions/utils/duplicateStorage'
import { useAppSelector } from 'store'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { JournalItemProps } from './types'
import {
  AdditionalRow,
  AvatarWrapper,
  JournalItemStatWrapper,
  JournalItemTopInfo,
  LineName,
  PositionId,
  ShopName
} from './styled'
import { JournalItemActions } from './journal-item-actions'

function JournalItem(props: JournalItemProps) {
  const { manageAppointments, canAddAndEditPositions } = useAppSelector(getPermissionsSelector)
  const { tr } = useTranslation()

  const [restoreAnchorEl, setRestoreAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [isFetching, setIsFetching] = useState(false)

  function handleOpenOriginalPosition(originalPositionId: number | string) {
    return () => {
      props.onOpenOriginalPosition?.(originalPositionId)
    }
  }

  function handleCustomerClick() {
    if (props.data?.customer?.id) {
      props.onCustomerClick?.(props.data.customer.id)
    }
  }

  function handleRestorePosition(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setRestoreAnchorEl(ev.currentTarget)
  }

  function handleCloseRestoreDialog() {
    setRestoreAnchorEl(null)
  }

  function handleOpenAppointmentPlaceList() {
    window.open(
      `#${nav.appointmentPlaceList()}/?placeId=${d.shopId}&lineId=${d.lineId}&positionId=${d.positionId}`,
      '_blank'
    )
  }

  async function handleRestorePositionToday() {
    setIsFetching(true)

    try {
      const res = await monitoring.tryToRestorePosition({
        placeId: props.data.shopId as string,
        lineId: props.data.lineId as string,
        positionId: props.data.positionId as number
      })

      if (res?.isSuccessfull === true) {
        window.open(
          `#${nav.lineMonitoringPosition(String(res.value?.placeId), String(res.value?.lineId), String(res.value?.positionId))}`,
          '_blank'
        )
      }

      setIsFetching(false)
      setRestoreAnchorEl(null)
    } catch (err) {
      setIsFetching(false)
    }
  }

  function handleCreateAppointment() {
    window.open(
      `#${nav.createAppointmentLine(props.data.shopId as string, props.data.lineId as string)}/?positionId=${props.data.positionId}`,
      '_blank'
    )

    setRestoreAnchorEl(null)
  }

  function handleCreatePosition() {
    if (!props.data) {
      return
    }

    const additionals = (props.data.additionalFields || []).reduce((acc, val) => {
      if (!val?.shortName || !val?.value) {
        return acc
      }

      return { ...acc, [val.shortName]: val.value }
    }, {})

    const position = {
      id: props.data.positionId,
      customer: props.data.customer,
      personsQuantity: props.data.participantsNumber,
      additionals
    } as PositionModel

    setDuplicatePosition(props.data.shopId as string, {
      position,
      back: { lineId: props.data.lineId as string, placeId: props.data.shopId as string }
    })

    if (props.data.lineCalculationAlgorithmMode === 'discrete') {
      window.open(
        `#${nav.discreteLineMonitoringCreate(props.data.shopId as string, props.data.lineId as string)}?duplicate&fromJournal`,
        '_blank'
      )
    } else {
      window.open(
        `#${nav.lineMonitoringCreate(props.data.shopId as string, props.data.lineId as string)}?duplicate&fromJournal`,
        '_blank'
      )
    }

    setRestoreAnchorEl(null)
  }

  const d = props.data

  const { firstName, lastName, id, phoneNumber, email } = d.customer || {}

  const name = !!firstName || !!lastName ? [firstName, lastName].filter(Boolean).join(' ') : id

  const removedOrFinishedTime = (d?.actions || []).reduce((acc, curr) => {
    const isFinished = [
      PositionActionType.Removed,
      PositionActionType.LeaveLine,
      PositionActionType.FinishService
    ].includes(curr?.type)

    if (!!curr?.dateTime && curr?.dateTime > acc && isFinished) {
      return curr?.dateTime
    }

    return acc
  }, 0)

  const canCreateAppointment = !!d.allowFutureAppointments && !!manageAppointments
  const canCreatePosition = !!d.lineCalculationAlgorithmMode && !!canAddAndEditPositions
  const canBeRestored = canCreatePosition && Date.now() - removedOrFinishedTime < 1000 * 60 * 60 * 24
  const canCreatePositionOrAppointment = canCreatePosition || canCreateAppointment

  return (
    <Card>
      <div style={{ padding: '1rem' }}>
        <div style={{ display: 'flex ', gap: '0.5rem', alignItems: 'flex-start' }}>
          <JournalItemTopInfo>
            <AvatarWrapper>
              <Avatar>
                <PositionTypeIcon type={d.positionType} />
              </Avatar>
              <PositionId>{d.positionId}</PositionId>
            </AvatarWrapper>
            <div>
              <ShopName>{d.shop}</ShopName>
              <LineName>{d.line}</LineName>
            </div>
          </JournalItemTopInfo>
          {!!d.showButtonCreatePosition && !!d.shopId && !!d.lineId && (
            <IconButton onClick={handleOpenAppointmentPlaceList} size="small">
              <AddBookingIcon fontSize="small" />
            </IconButton>
          )}
          {canCreatePositionOrAppointment && (
            <IconButton onClick={handleRestorePosition} size="small">
              <CreateIcon fontSize="small" />
            </IconButton>
          )}
        </div>
        <div style={{ marginTop: 8, marginLeft: 48, fontSize: '14px' }}>
          <PositionStat icon={PersonIcon}>
            <div>
              <Link onClick={handleCustomerClick}>{name}</Link>
              <div style={{ display: 'flex', columnGap: '1rem', flexWrap: 'wrap', marginTop: 2 }}>
                {!!d.platform && (
                  <div style={{ fontSize: '12px', opacity: 0.72 }}>{[tr.platform[d.platform]].filter(Boolean)}</div>
                )}
                {!!phoneNumber && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <PhoneIcon style={{ opacity: 0.28, marginRight: 4, width: 16, height: 16 }} />
                    <div style={{ fontSize: 12, opacity: 0.72 }}>{phoneNumber}</div>
                  </div>
                )}
                {!!email && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <EmailIcon style={{ opacity: 0.28, marginRight: 4, width: 16, height: 16 }} />
                    <div style={{ fontSize: 12, opacity: 0.72 }}>{email}</div>
                  </div>
                )}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IdIcon style={{ opacity: 0.28, marginRight: 4, width: 16, height: 16 }} />
                  <div style={{ fontSize: 12, opacity: 0.72 }}>{id}</div>
                </div>
              </div>
            </div>
          </PositionStat>
        </div>
        {!!d.originalPositionId && (
          <JournalItemStatWrapper>
            <PositionStat icon={OriginalPositionIcon}>
              {tr.journal.originalPosition}
              <Link
                onClick={handleOpenOriginalPosition(d.originalPositionId)}
                style={{ cursor: 'pointer', marginLeft: '0.25rem' }}
              >
                {d.originalPositionId}
              </Link>
            </PositionStat>
          </JournalItemStatWrapper>
        )}
        {!!d.services && (
          <JournalItemStatWrapper>
            <PositionServices services={(d.services || []).map((x) => ({ name: x?.trim() }))} />
          </JournalItemStatWrapper>
        )}
        {!!d.participantsNumber && (
          <JournalItemStatWrapper>
            <PositionStat icon={SupervisorAccountIcon}>{d.participantsNumber}</PositionStat>
          </JournalItemStatWrapper>
        )}
        {!!d.comment && (
          <JournalItemStatWrapper>
            <PositionStat icon={CommentIcon}>{d.comment}</PositionStat>
          </JournalItemStatWrapper>
        )}
        {!!d.staffComment && (
          <JournalItemStatWrapper>
            <PositionStat icon={StuffCommentIcon}>{d.staffComment}</PositionStat>
          </JournalItemStatWrapper>
        )}
        {!!d.priority && (
          <JournalItemStatWrapper>
            <PositionStat icon={NotificationImportantIcon} iconStyle={{}}>
              {tr.lineMonitoring.priority}
            </PositionStat>
          </JournalItemStatWrapper>
        )}
        {!!d.additionalFields && (
          <JournalItemStatWrapper>
            <AdditionalRow>
              {d.additionalFields.map((additional, key) => (
                <PositionStat key={key} icon={AdditionalsIcon}>
                  <div>
                    <Typography variant="caption">{additional.label}</Typography>
                    <Typography variant="body2">{additional.value}</Typography>
                  </div>
                </PositionStat>
              ))}
            </AdditionalRow>
          </JournalItemStatWrapper>
        )}
        <Box marginTop="0.5rem">
          <JournalItemActions data={d} />
        </Box>
      </div>
      <Menu anchorEl={restoreAnchorEl} open={!!restoreAnchorEl} onClose={handleCloseRestoreDialog} elevation={2}>
        {!!canBeRestored && (
          <MenuItem onClick={handleRestorePositionToday} disabled={isFetching}>
            <ListItemIcon>
              <TodayIcon fontSize="small" />
            </ListItemIcon>
            {tr.journal.actions.tryToRestorePosition}
          </MenuItem>
        )}
        {!!canCreatePosition && (
          <MenuItem onClick={handleCreatePosition} disabled={isFetching}>
            <ListItemIcon>
              <PositionIcon fontSize="small" />
            </ListItemIcon>
            {tr.journal.actions.createNewPosition}
          </MenuItem>
        )}
        {!!canCreateAppointment && (
          <MenuItem onClick={handleCreateAppointment} disabled={isFetching}>
            <ListItemIcon>
              <AppointmentIcon fontSize="small" />
            </ListItemIcon>
            {tr.journal.actions.createNewAppointment}
          </MenuItem>
        )}
      </Menu>
    </Card>
  )
}

export { JournalItem }
