import { Box, LinearProgress, Typography } from '@mui/material'

function OverchargedQuadraticOccupancyProgress({ quadraticOccupancyPercent }: { quadraticOccupancyPercent: number }) {
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress
            variant="determinate"
            value={Math.min(quadraticOccupancyPercent, 100)}
            style={{ width: '100%', height: '3px', borderRadius: '2px' }}
            color="success"
          />
        </Box>
        <Typography variant="caption" color="text.secondary">{`${Math.round(quadraticOccupancyPercent)}%`}</Typography>
      </Box>
    </>
  )
}

export { OverchargedQuadraticOccupancyProgress }
