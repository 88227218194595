import { Form } from 'shared/ui-kit/form'
import { Button, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import { useState } from 'react'
import { place as placeApi } from 'shared/api'
import { MessageTypes, showMessage } from 'store/actions/main-layout-action-creators'
import { useAppDispatch } from 'store'
import Autocomplete from 'shared/ui-kit/autocomplete'
import { CopyDialogProps } from './types'

function CopyDialog({ template, onClose, places }: CopyDialogProps) {
  const { tr } = useTranslation()

  const dispatch = useAppDispatch()

  const [shop, setShop] = useState<string | number | undefined>(places[0].id)
  const [loading, setLoading] = useState(false)

  function handleShopChange(shopId?: string | number | null) {
    if (!shopId) {
      setShop(undefined)
    } else {
      setShop(shopId)
    }
  }

  async function handleCopyReport() {
    if (!shop) {
      return
    }

    try {
      setLoading(true)
      await placeApi.copyExportTemplateToAnotherPlace(template.value, shop)
      dispatch(showMessage(tr.exportData.successCopiedMessage, MessageTypes.Success))
      onClose()
    } catch (err) {
      setLoading(false)
    }
  }

  const sortedPlaces = (places || []).sort((a, b) => a?.name?.localeCompare(b?.name))

  return (
    <Form>
      <DialogTitle>{template.text}</DialogTitle>
      <DialogContent style={{ overflowY: 'visible' }}>
        <Autocomplete
          title={tr.exportData.place}
          value={shop || null}
          list={sortedPlaces}
          dataConverter={(item) => ({ value: item.id, text: item.name })}
          onChange={handleShopChange}
          fullWidth
          isShrink
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="small" color="primary" onClick={handleCopyReport} disabled={!shop || loading}>
          {tr.exportData.copy}
        </Button>
        <Button variant="outlined" size="small" onClick={onClose} disabled={loading}>
          {tr.exportData.cancel}
        </Button>
      </DialogActions>
    </Form>
  )
}

export { CopyDialog }
