import { useTranslation } from 'shared/i18n/translate'
import { JournalActionModel } from 'shared/models'
import Text from 'shared/ui-kit/text'
import { PositionStat } from 'features/position-stat'
import { getHours } from '../utils/get-hours'
import { SupervisorAccount as SupervisorAccountIcon } from '@mui/icons-material'

function RenderUnderText({
  action,
  promisedWaitingTime,
  realWaitingTime,
  expectedServiceStartTime
}: {
  action: JournalActionModel
  promisedWaitingTime?: number
  realWaitingTime?: number
  expectedServiceStartTime?: number
}) {
  const { tr, dateFormatter } = useTranslation()

  const { customer } = action
  const { firstName, lastName, id } = customer || {}
  const name = firstName && lastName ? `${firstName} ${lastName}` : id

  return (
    <>
      {action.reason && (
        <div style={{ margin: '0.5rem 0' }}>
          {action.comment
            ? tr.journal.actionReasonsWithComment[action.reason]?.(action.comment)
            : tr.journal.actionReasons[action.reason]}
        </div>
      )}
      {action.type === 'failedJoin' && !action.reason && (
        <div style={{ margin: '0.5rem 0' }}>{tr.journal.failedJoinReason}</div>
      )}
      <div
        style={{
          marginTop: '0.5rem',
          marginBottom: '0.5rem',
          zoom: 0.72,
          background: action.type === 'failedJoin' ? '#fbd1d1' : '#f1f1f1',
          padding: '1rem',
          borderRadius: '4px'
        }}
      >
        {!!(action.timeSlotStart || action.code || (action.services && action.services.length)) && (
          <div style={{ display: 'flex' }}>
            {!!action.asap && (
              <div style={{ flex: 1 }}>
                <Text type="caption">{tr.monitoring.lineJoinMode}</Text>
                <Text type="body-1">{tr.lineMonitoring.asap}</Text>
              </div>
            )}
            {!!(action.timeSlotStart && !action.asap) && (
              <div style={{ flex: 1 }}>
                <Text type="caption">{tr.appointment.date}</Text>
                <Text type="body-1">
                  {dateFormatter(action.timeSlotStart, { day: 'numeric', month: 'long', year: 'numeric' })}{' '}
                  {dateFormatter(action.timeSlotStart, { hour: 'numeric', minute: '2-digit' })}
                </Text>
              </div>
            )}
            {!!action.code && (
              <div>
                <Text type="caption">{tr.appointment.reservationNumber}</Text>
                <Text type="body-1">{action.code}</Text>
              </div>
            )}
          </div>
        )}
        {(action.estimatedWaitingTime !== undefined ||
          action.realWaitingTime !== undefined ||
          action.distanceToPlace !== undefined) && (
          <div style={{ display: 'flex', gap: '1rem', marginTop: '0.5rem' }}>
            {action.estimatedWaitingTime !== undefined && (
              <div>
                <Text type="caption">{tr.monitoring.estimatedWaitingTime}</Text>
                <Text type="body-1">{tr.time.hmin(Math.floor(action.estimatedWaitingTime / 60000))}</Text>
              </div>
            )}
            {action.realWaitingTime !== undefined && (
              <div>
                <Text type="caption">{tr.monitoring.realWaitingTime}</Text>
                <Text type="body-1">{tr.time.hmin(Math.floor(action.realWaitingTime / 60000))}</Text>
              </div>
            )}
            {action.distanceToPlace !== undefined && (
              <div>
                <Text type="caption">{tr.monitoring.distanceToThePlace}</Text>
                <Text type="body-1">
                  {tr.placeView.lineAuthorisationRadiusValue(Math.floor(action.distanceToPlace))}
                </Text>
              </div>
            )}
          </div>
        )}
        {!!(expectedServiceStartTime && !action.asap && action.type === 'failedJoin') && (
          <div style={{ flex: 1, marginTop: '0.5rem' }}>
            <Text type="caption">{tr.appointment.expectedTime}</Text>
            <Text type="body-1">{dateFormatter(expectedServiceStartTime, { hour: 'numeric', minute: '2-digit' })}</Text>
          </div>
        )}
        {!!action.customer && (
          <>
            {(!!action.participantsNumber || !!name) && (
              <div style={{ display: 'flex', gap: '1.5rem', marginTop: '0.5rem', alignItems: 'center' }}>
                {!!name && (
                  <div>
                    <Text type="caption">{tr.appointment.name}</Text>
                    <Text type="body-1">{name}</Text>
                  </div>
                )}
                {!!action.participantsNumber && (
                  <div style={{ fontSize: '16px', color: '#000' }}>
                    <PositionStat icon={SupervisorAccountIcon}>{action.participantsNumber}</PositionStat>
                  </div>
                )}
              </div>
            )}
            {!!(action.customer?.phoneNumber || action.customer?.email) && (
              <div style={{ display: 'flex', gap: '0.5rem', marginTop: '0.5rem' }}>
                {!!action.customer.phoneNumber && (
                  <div style={{ flex: 1 }}>
                    <Text type="caption">{tr.appointment.phone}</Text>
                    <Text type="body-1">{action.customer.phoneNumber}</Text>
                  </div>
                )}
                {!!action.customer.email && (
                  <div style={{ flex: 1 }}>
                    <Text type="caption">{tr.appointment.email}</Text>
                    <Text type="body-1">{action.customer.email}</Text>
                  </div>
                )}
              </div>
            )}
          </>
        )}
        {!!(action.services && action.services.length && action.services.filter(Boolean).length) && (
          <div style={{ marginTop: '0.5rem' }}>
            <Text type="caption">{tr.appointment.service}</Text>
            <Text type="body-1">{action.services?.join(', ')}</Text>
          </div>
        )}
        {!!action.comment && (
          <div style={{ marginTop: '0.5rem' }}>
            <Text type="caption">{tr.appointment.comments}</Text>
            <Text type="body-1">{action.comment}</Text>
          </div>
        )}
        {!!action.staffComment && (
          <div style={{ marginTop: '0.5rem' }}>
            <Text type="caption">{tr.appointment.staffComments}</Text>
            <Text type="body-1">{action.staffComment}</Text>
          </div>
        )}
        {!!action.updateNameComment && (
          <div style={{ marginTop: '0.5rem' }}>
            <Text type="caption">{tr.appointment.updateNameComment}</Text>
            <Text type="body-1">{action.updateNameComment}</Text>
          </div>
        )}
        {!!((action.type === 'joinLine' || action.type === 'updatePosition') && promisedWaitingTime) && (
          <div style={{ marginTop: '0.5rem' }}>
            <Text type="caption">{tr.journal.promisedWaitingTime}</Text>
            <Text type="body-1">{getHours(promisedWaitingTime, tr)}</Text>
          </div>
        )}
        {!!(action.type === 'callToCheckpoint') && (
          <>
            {!!realWaitingTime && (
              <div style={{ marginTop: '0.5rem' }}>
                <Text type="caption">{tr.journal.realWaitingTime}</Text>
                <Text type="body-1">{getHours(realWaitingTime, tr)}</Text>
              </div>
            )}
            {!!promisedWaitingTime && (
              <div style={{ marginTop: '0.5rem' }}>
                <Text type="caption">{tr.journal.promisedWaitingTime}</Text>
                <Text type="body-1">{getHours(promisedWaitingTime, tr)}</Text>
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}

export { RenderUnderText }
