import { useTranslation } from 'shared/i18n/translate'
import Autocomplete from 'shared/ui-kit/autocomplete'
import { DatePicker } from 'features/date'
import { Button } from '@mui/material'
import {
  Search as SearchIcon,
  Add as AddIcon,
  CloudDownloadOutlined as CloudDownloadOutlinedIcon
} from '@mui/icons-material'
import Link from 'shared/ui-kit/link'
import Toggle from 'shared/ui-kit/toggle-wrap'
import { FilterProps } from './types'
import { FormEvent } from 'react'
import { AppointmentStatus } from 'shared/models'
import { SearchInput } from 'features/search-input'
import Card from 'shared/ui-kit/card'
import { ActionsWrapper } from 'shared/ui-kit/action-wrapper'
import { AppointmentFiltersRoot, AppointmentFiltersSideBar } from './styled'

const appointmentStatuses = Object.values(AppointmentStatus)

export default function AppointmentsFilterLargeLayout(props: FilterProps) {
  const { tr } = useTranslation()

  function handleSearch(e: FormEvent) {
    e.preventDefault()
    props.onSearch?.()
  }

  function renderFilter() {
    const { shop, line } = tr.common
    const { hideInactive } = tr.appointment
    const { shopId, lineId } = props
    const hasShopId = shopId || shopId === 0
    const hasLineId = lineId || lineId === 0

    return (
      <form onSubmit={handleSearch}>
        <div style={{ padding: '1rem 1rem 0.5rem' }}>
          <SearchInput
            value={props.filter.term}
            fullWidth
            placeholder={tr.reportsFilter.search}
            autoFocus
            onChange={(ev) => props.onFilterChange('term', ev.target.value)}
            onClear={() => props.onFilterChange('term', '')}
          />
        </div>
        {!hasShopId && !props.shopFetching && (
          <div style={{ padding: '0.5rem 1rem' }}>
            <Autocomplete
              fullWidth
              ensureValue
              title={shop}
              value={props.filter.shopId}
              list={props.shops}
              style={{ textOverflow: 'ellipsis' }}
              dataConverter={(el) => ({ text: el.name, value: el.id })}
              onChange={(shopId) => {
                props.onFilterChange('shopId', shopId)
              }}
            />
          </div>
        )}
        {!hasLineId && !props.shopFetching && !props.lineFetching && (
          <div style={{ padding: '0.5rem 1rem' }}>
            <Autocomplete
              fullWidth
              ensureValue
              title={line}
              value={props.filter.lineId}
              list={props.lines}
              style={{ textOverflow: 'ellipsis' }}
              dataConverter={(el) => ({ text: el.name, value: el.id })}
              onChange={(lineId) => {
                props.onFilterChange('lineId', lineId)
              }}
            />
          </div>
        )}
        {!props.cancelMode && (
          <div style={{ padding: '0.5rem 1rem' }}>
            <Autocomplete
              fullWidth
              ensureValue
              title={tr.appointment.status}
              value={props.filter.status || 'all'}
              list={[
                { text: tr.reportsFilter.allShops, value: 'all' },
                ...appointmentStatuses.map((status) => ({
                  text: tr.appointment.appointmentStatusTranslations[status],
                  value: status
                }))
              ]}
              style={{ textOverflow: 'ellipsis' }}
              dataConverter={(el) => el}
              onChange={(status) => {
                if (status === 'all') {
                  props.onFilterChange('status', undefined)
                } else {
                  props.onFilterChange('status', status)
                }
              }}
            />
          </div>
        )}
        <div style={{ padding: '0.5rem 1rem' }}>
          <DatePicker
            label={tr.reportsFilter.startDate}
            style={{ marginBottom: 0, width: '100%' }}
            onChange={(date) => props.onFilterChange('startDate', date)}
            value={props.filter.startDate}
          />
        </div>
        <div style={{ padding: '0.5rem 1rem' }}>
          <DatePicker
            label={tr.reportsFilter.endDate}
            style={{ marginBottom: 0, width: '100%' }}
            onChange={(date) => props.onFilterChange('endDate', date)}
            value={props.filter.endDate}
          />
        </div>
        {!props.cancelMode && (
          <div style={{ padding: '0.5rem 1rem' }}>
            <Toggle
              toggled={props.filter.onlyActive}
              label={hideInactive}
              onToggle={(_, val) => {
                props.onFilterChange('onlyActive', val)
              }}
            />
          </div>
        )}
        <div style={{ padding: '0.5rem 1rem 1rem' }}>
          {(props.filter.shopId || props.filter.shopId === 0) && (props.filter.lineId || props.filter.lineId === 0) && (
            <Link onClick={props.onDownload} style={{ display: 'inline-flex', alignItems: 'center', fontSize: '14px' }}>
              <CloudDownloadOutlinedIcon fontSize="small" style={{ margin: '0 8px 0 1px' }} />
              {tr.appointments.download}
            </Link>
          )}
        </div>
      </form>
    )
  }

  return (
    <AppointmentFiltersRoot>
      <div style={{ display: 'flex', width: 800, alignItems: 'start' }}>
        <AppointmentFiltersSideBar>
          {!props.cancelMode && (
            <Card paperStyle={{ padding: '1rem', overflow: 'unset' }}>
              {!props.shopId && !props.lineId && (
                <Button
                  style={{ marginBottom: '0.5rem', justifyContent: 'flex-start' }}
                  startIcon={<SearchIcon />}
                  onClick={props.onCreateFromService}
                  variant="contained"
                  color="primary"
                  size="small"
                  fullWidth
                  disableElevation
                >
                  {tr.appointments.searchTimeslotsFromService}
                </Button>
              )}
              <Button
                style={{ justifyContent: 'flex-start' }}
                startIcon={<AddIcon />}
                onClick={props.onCreate}
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                disableElevation
              >
                {tr.appointments.add}
              </Button>
            </Card>
          )}
          <Card>{renderFilter()}</Card>
          <ActionsWrapper>
            <Button
              variant="contained"
              size="small"
              color="primary"
              disabled={!props.filter.lineId || !props.filter.shopId}
              onClick={handleSearch}
            >
              {tr.common.search}
            </Button>
          </ActionsWrapper>
        </AppointmentFiltersSideBar>
        <div style={{ flex: 1 }}>{props.children}</div>
      </div>
    </AppointmentFiltersRoot>
  )
}
