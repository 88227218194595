import { CSSProperties } from 'react'
import { Button, Typography } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'

type Props = {
  style?: CSSProperties
  onSubmit: () => void
}

function ServerMessageErrorViewComponent(props: Props) {
  const { style, onSubmit } = props
  const { tr } = useTranslation()
  const { serverMessageErrorView: translation } = tr

  return (
    <div style={{ margin: 'auto', textAlign: 'center', ...style }}>
      <Typography variant="h4" component="h4">
        {translation.oops}
      </Typography>
      <Typography variant="body1" component="p">
        {translation.errorInApplication}
      </Typography>
      <Button variant="text" color="primary" onClick={() => onSubmit?.()} fullWidth>
        {translation.reloadPage}
      </Button>
    </div>
  )
}

export default ServerMessageErrorViewComponent
export const ServerMessageErrorView = ServerMessageErrorViewComponent
