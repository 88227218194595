import { Form, FormGroup } from 'shared/ui-kit/form'
import Toggle from 'shared/ui-kit/toggle-wrap'
import { useTranslation } from 'shared/i18n/translate'
import { Controller } from 'react-hook-form'
import { GroupProps } from '../types'
import { Card } from '@mui/material'

function PersonalInfoGroup({ control }: GroupProps) {
  const { tr } = useTranslation()

  const { requestPersonalInfo } = tr.lineEdit

  return (
    <Card>
      <Form>
        <FormGroup>
          <Controller
            name="requestPersonalInfo.firstName"
            control={control}
            render={({ field: f }) => (
              <Toggle toggled={f.value} label={requestPersonalInfo.firstName} onToggle={(_, val) => f.onChange(val)} />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="requestPersonalInfo.lastName"
            control={control}
            render={({ field: f }) => (
              <Toggle toggled={f.value} label={requestPersonalInfo.lastName} onToggle={(_, val) => f.onChange(val)} />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="requestPersonalInfo.companyName"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={requestPersonalInfo.companyName}
                onToggle={(_, val) => f.onChange(val)}
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="requestPersonalInfo.phoneNumber"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={requestPersonalInfo.phoneNumber}
                onToggle={(_, val) => f.onChange(val)}
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="requestPersonalInfo.email"
            control={control}
            render={({ field: f }) => (
              <Toggle toggled={f.value} label={requestPersonalInfo.email} onToggle={(_, val) => f.onChange(val)} />
            )}
          />
        </FormGroup>
      </Form>
    </Card>
  )
}

export { PersonalInfoGroup }
