import { useTranslation } from 'shared/i18n/translate'
import { HotkeyItem } from '../styled'
import { Dialog } from '@mui/material'
import { HotkeysDialogProps } from '../types'

function HotkeysDialog(props: HotkeysDialogProps) {
  const { tr } = useTranslation()

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <div style={{ padding: '2rem' }}>
        <div style={{ fontSize: '19px' }}>{tr.helper.hotkey.title}</div>
        <div style={{ marginTop: '0.5rem' }}>
          <div>
            <HotkeyItem>Ctrl-Enter</HotkeyItem>
            {tr.helper.hotkey.callPosition}
          </div>
          <div>
            <HotkeyItem>Ctrl-Enter</HotkeyItem>
            {tr.helper.hotkey.startService}
          </div>
          <div>
            <HotkeyItem>Ctrl-Backspace</HotkeyItem>
            {tr.helper.hotkey.endService}
          </div>
          <div>
            <HotkeyItem>+</HotkeyItem>
            {tr.helper.hotkey.addPosition}
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export { HotkeysDialog }
