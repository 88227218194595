import { useTranslation } from 'shared/i18n/translate'
import { Tooltip } from '@mui/material'
import { AccessTime as TimeIcon } from '@mui/icons-material'
import { PersonsCount } from 'features/position/persons-count'
import { CheckpointStatProps } from '../types'

function CheckpointStat(props: CheckpointStatProps) {
  const { tr } = useTranslation()

  return (
    <div style={{ marginLeft: '0.5rem', height: 64, display: 'flex', alignItems: 'center' }}>
      <Tooltip arrow title={props.template || tr.position.tooltips.numberParticipants}>
        <div>
          <PersonsCount style={{ margin: '0 1rem', cursor: 'default' }}>{props.persons || 0}</PersonsCount>
        </div>
      </Tooltip>
      {props.min < 1440 && (
        <>
          <TimeIcon style={{ color: 'rgba(0,0,0,.28)' }} />
          <div style={{ color: '#404040', margin: '0 12px 0 0.5rem', cursor: 'default' }}>
            {tr.time.hmin(props.min)}
          </div>
        </>
      )}
    </div>
  )
}

export { CheckpointStat }
