import { PositionModel, PositionState, PositionType } from 'shared/models'
import { isPositionInLineHere, isUnreachPosition, isValidPosition } from 'features/position-dumb/helpers'
import { removeDiacritics } from './string'

export type PositionQueueType =
  | 'queue'
  | 'inLineHere'
  | 'isNotValid'
  | 'isValid'
  | 'unreach'
  | 'priority'
  | 'onlyLocked'

type SchedulePosition = {
  headerText?: string
  text: string
  startDate: Date
  endDate: Date
  checkpointId?: number | string
  state?: PositionState
  rawPosition?: any
  serviceIds?: (string | number)[]
}

export const PositionQueueType: Record<PositionQueueType, PositionQueueType> = {
  queue: 'queue',
  inLineHere: 'inLineHere',
  isNotValid: 'isNotValid',
  isValid: 'isValid',
  unreach: 'unreach',
  priority: 'priority',
  onlyLocked: 'onlyLocked'
}

export const PositionStatusesFilter = [PositionState.Joined, PositionState.CalledToCheckpoint, PositionState.InService]

export function filterPositionsByType(positions: PositionModel[] = [], positionTypesSelected: PositionType[] = []) {
  if (!positions.length) {
    return positions
  }

  if (!positionTypesSelected.length) {
    return positions
  }

  return positions.filter((position) => !!positionTypesSelected.find((pts) => pts === position.type))
}

export function filterPositionsByStatus(
  positions: PositionModel[] = [],
  positionStatusesSelected: PositionState[] = []
) {
  if (!positions.length) {
    return positions
  }

  if (!positionStatusesSelected.length) {
    return positions
  }

  const fixedStatuses = positionStatusesSelected.includes(PositionState.Joined)
    ? [...positionStatusesSelected, PositionState.InLineHere]
    : positionStatusesSelected

  return positions.filter((position) => !!fixedStatuses.find((pss) => pss === position.state))
}

export function filterPositionsByService(
  positions: PositionModel[] = [],
  positionServicesSelected: (string | number)[] = []
) {
  if (!positions.length) {
    return positions
  }

  if (!positionServicesSelected.length) {
    return positions
  }

  return positions.filter(
    (position) => !!position.services?.find((s) => positionServicesSelected.find((pss) => pss === s.id))
  )
}

export function filterPositionsByTerm(positions: PositionModel[] = [], term = '') {
  if (!positions.length) {
    return positions
  }

  if (!term) {
    return positions
  }

  return positions.filter((position) => {
    const { customer = { id: 0, language: 'en' }, reservationCode = '', additionals = {} } = position

    const additionalValues = Object.values(additionals)

    const { id = '', email = '', firstName = '', lastName = '', phoneNumber = '', companyName = '' } = customer
    const [I, O] = firstName.split(' ')
    const F = lastName
    const nameVariants = [F, I, O, F, I, F, O, I, F].filter(Boolean).join(' ')

    const searchData = [
      position.id,
      id,
      companyName,
      email,
      firstName,
      lastName,
      firstName,
      nameVariants,
      phoneNumber.replace(/-/g, ''),
      reservationCode,
      ...additionalValues
    ]
      .filter(Boolean)
      .join('|')
      .toLowerCase()

    const searchString = removeDiacritics(term.trim().toLowerCase())

    return removeDiacritics(searchData).includes(searchString)
  })
}

export function filterPositionsByTermScheduler(positions: SchedulePosition[] = [], term = '') {
  if (!positions.length) {
    return positions
  }

  if (!term) {
    return positions
  }

  return positions.filter((position) => {
    const { customer = { id: 0, language: 'en' }, reservationCode = '', additionals = {} } = position.rawPosition

    const additionalValues = Object.values(additionals)

    const { email = '', firstName = '', lastName = '', phoneNumber = '', companyName = '' } = customer
    const [I, O] = firstName.split(' ')
    const F = lastName
    const nameVariants = [F, I, O, F, I, F, O, I, F].filter(Boolean).join(' ')

    const search = [
      companyName,
      email,
      firstName,
      lastName,
      firstName,
      nameVariants,
      phoneNumber.replace(/-/g, ''),
      reservationCode,
      ...additionalValues
    ]
      .filter(Boolean)
      .join('|')
      .toLowerCase()

    return search.includes(term.trim().toLowerCase())
  })
}

export function filterPositionsByQueueType(
  positions: PositionModel[] = [],
  queueTypeSelected: PositionQueueType[] = [],
  positionQueueTypeValues
) {
  if (!positions.length) {
    return positions
  }

  if (!queueTypeSelected.length) {
    return positions
  }

  if (queueTypeSelected.length === positionQueueTypeValues.length) {
    return positions
  }

  return positions.filter((position) => {
    let filtersPassed = false

    const isLock = !!position?.customCheckpoint?.id

    if (queueTypeSelected.includes('onlyLocked') && isLock) {
      return true
    }

    if (queueTypeSelected.includes('queue')) {
      filtersPassed = filtersPassed || !isPositionInLineHere(position)
    }
    if (queueTypeSelected.includes('inLineHere')) {
      filtersPassed = filtersPassed || isPositionInLineHere(position)
    }
    if (queueTypeSelected.includes('isValid')) {
      filtersPassed = filtersPassed || isValidPosition(position)
    }
    if (queueTypeSelected.includes('isNotValid')) {
      filtersPassed = filtersPassed || !isValidPosition(position)
    }
    if (queueTypeSelected.includes('unreach')) {
      filtersPassed = filtersPassed || isUnreachPosition(position)
    }
    if (queueTypeSelected.includes('priority')) {
      filtersPassed = filtersPassed || !!position.priority
    }

    return filtersPassed
  })
}
