import { SubBar } from 'shared/ui-kit/sub-bar'
import { MenuItem, IconButton, FormControl, ListItemIcon } from '@mui/material'
import { Menu, Badge, Button, Tooltip, Divider } from '@mui/material'
import { PlayArrow, Stop, RunningWithErrors, Settings as ChangeSettingsIcon } from '@mui/icons-material'
import { lineMonitoringState, journal, appointmentsLine, checkpointHost } from 'pages/nav'
import { lineScheduler, lineDelay } from 'pages/nav'
import { lineMonitoringCreate, checkpoints as navCheckpoints } from 'pages/nav'
import AddIcon from '@mui/icons-material/Add'
import TimeIcon from '@mui/icons-material/AccessTime'
import BasketIcon from '@mui/icons-material/ShoppingBasket'
import MoreIcon from '@mui/icons-material/MoreVert'
import NotificationsOn from '@mui/icons-material/Notifications'
import NotificationsOff from '@mui/icons-material/NotificationsOff'
import LineStatisticsIcon from '@mui/icons-material/ViewModule'
import FilterIcon from '@mui/icons-material/FilterList'
import AppointmentsIcon from '@mui/icons-material/EventAvailable'
import LinkIcon from '@mui/icons-material/Link'
import { NavLink, useHistory } from 'react-router-dom'
import { MultipleCheckSelect } from 'shared/ui-kit/multiple-check-select'
import ScheduleTodayIcon from '@mui/icons-material/ViewWeekRounded'
import EventIcon from '@mui/icons-material/EventNote'
import RemovedIcon from '@mui/icons-material/PlaylistRemove'
import { IconValue } from 'shared/ui-kit/icons'
import { ClearButtonWrapper, FiltersWrapper, LineStatWrapper, MonitoringBarContainer } from '../styled'
import { BarContainer, InactiveIndicator } from '../styled'
import { PositionQueueType, PositionStatusesFilter } from 'shared/utils/positions_filters'
import { PersonsCount } from 'features/position/persons-count'
import { SearchInput } from 'features/search-input'
import { MonitoringFilters, defaultFilters } from '../utils'
import { useTranslation } from 'shared/i18n/translate'
import { useMemo, useState } from 'react'
import { CheckpointStatus } from 'shared/models'
import { useAppSelector } from 'store'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { LineMonitoringBarProps } from './types'
import ChecklistIcon from '@mui/icons-material/Checklist'
import { AllPositionActionsDialog } from './all-position-actions-dialog'
import { useNotificationSubscription } from 'features/notifications/useNotificationSubscription'
import { NotificationRequestDialog } from 'features/notifications/notification-request-dialog'
import { NotificationKey } from 'shared/models/entities/notification.model'
import { Statisctics } from './statistics'

function LineMonitoringBar(props: LineMonitoringBarProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>()
  const [statAnchorEl, setStatAnchorEl] = useState<HTMLDivElement>()
  const [filterAnchorEl, setFilterAnchorEl] = useState<HTMLLIElement>()
  const [allPositionsDialogIsOpen, setAllPositionsDialogIsOpen] = useState(false)
  const [notificationsDialogIsOpen, setNotificationsDialog] = useState(false)

  const { notificationReqiestProcessing, isSubscribed, subscribeToNotifications, unsubscribeFromNotifications } =
    useNotificationSubscription({
      type: 'LineMonitoring',
      placeId: Number(props.shopId),
      lineId: Number(props.lineId)
    })

  const { manageAppointments, manageReports, canAddAndEditPositions } = useAppSelector(getPermissionsSelector)

  const { tr } = useTranslation()
  const history = useHistory()

  function handleReset() {
    props.setFilters(defaultFilters)
    props.handleTermChange('')
  }

  function handleMenuClose() {
    setFilterAnchorEl(undefined)
  }

  function handleCloseStats() {
    setStatAnchorEl(undefined)
  }

  function handleFilterChange<T extends keyof MonitoringFilters>(key: T) {
    return (value: MonitoringFilters[T]) => {
      const newFilters = { ...props.filters }
      newFilters[key] = value
      props.setFilters(newFilters)
    }
  }

  function handlePersonClick(ev: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (registeredPositionsCount) {
      setStatAnchorEl(ev.currentTarget)
    }
  }

  function handleClickToAllPositionsAction() {
    setAllPositionsDialogIsOpen(true)
  }

  function handleAllPositionDialogClose() {
    setAllPositionsDialogIsOpen(false)
  }

  function handleNotificationsDialogClose() {
    setNotificationsDialog(false)
  }

  function handleNotificationsSubscribe(keys: NotificationKey[]) {
    subscribeToNotifications(keys).then(() => {
      handleNotificationsDialogClose()
    })
  }

  const queueTypeTranslation = useMemo(
    () => ({
      [PositionQueueType.queue]: tr.lineMonitoring.offSite,
      [PositionQueueType.inLineHere]: tr.lineMonitoring.isHere,
      [PositionQueueType.isValid]: tr.lineMonitoring.isValid,
      [PositionQueueType.isNotValid]: tr.lineMonitoring.isNotValid,
      [PositionQueueType.priority]: tr.lineMonitoring.priority,
      [PositionQueueType.unreach]: tr.lineMonitoring.positionCannotBeServed,
      [PositionQueueType.onlyLocked]: tr.lineMonitoring.onlyLocked,
      unknown: 'TRANSLATION_ERROR'
    }),
    []
  )

  const filterBadgeCount =
    (props.filters.positionStatusesSelected?.length || 0) +
    (props.filters.positionQueueTypeSelected?.length || 0) +
    (props.filters.positionServicesSelected?.length || 0) +
    (props.filters.positionTypeSelected?.length || 0)

  const filtersIsEmpty = !(
    props.filters.positionStatusesSelected.length ||
    props.filters.positionServicesSelected.length ||
    props.filters.positionQueueTypeSelected.length ||
    props.filters.positionTypeSelected.length
  )

  const {
    waitingInPlacePositionsCount,
    registeredPositionsCount,
    waitingPositionsCount,
    calledPositionsCount,
    inServicePositionsCount,
    impossiblePositionsCount,
    overcharged,
    finishing,
    waitingTime
  } = props

  const isStart = props.checkpoints.some((c) =>
    [CheckpointStatus.starting, CheckpointStatus.started].includes(c?.status)
  )

  const isAddButtonDisabled = props.checkpoints.length === 0 || finishing
  const lineIsFull = (finishing === true && waitingTime > 1440) || overcharged
  const isSingleCheckpoint = props.checkpoints?.length === 1

  const addButtonTooltip = isAddButtonDisabled
    ? tr.lineMonitoring.impossibleAddTooltop
    : lineIsFull
      ? tr.lineMonitoring.lineIsFullAddTooltip(
          tr.getParticipantFormFromTemplates(
            10,
            props?.line?.lineParticipantTermInSingularTemplate,
            props?.line?.lineParticipantTermInPluralTemplate
          )
        )
      : tr.lineMonitoring.addTooltip

  return (
    <>
      <MonitoringBarContainer $smallScreen={props.smallScreen}>
        <SubBar smallScreen={props.smallScreen}>
          <BarContainer $isLargeSize={!!props.simplifyMode}>
            {!props.simplifyMode && canAddAndEditPositions && (
              <Tooltip arrow title={addButtonTooltip}>
                <div>
                  <IconButton
                    onClick={() => history.push(lineMonitoringCreate(props.shopId, props.lineId))}
                    style={{ marginRight: '4px' }}
                    disabled={isAddButtonDisabled}
                  >
                    <AddIcon />
                    {lineIsFull && !isAddButtonDisabled && <InactiveIndicator />}
                  </IconButton>
                </div>
              </Tooltip>
            )}
            <FormControl style={{ flex: 1 }}>
              <SearchInput
                style={{ margin: '0 0.5rem', flex: '1 1 auto', zoom: props.simplifyMode ? 1.3 : 1 }}
                value={props.termValue}
                placeholder={tr.lineMonitoring.search}
                onChange={(ev) => props.handleTermChange(ev.target.value)}
                onClear={() => props.handleTermChange('')}
                onFocus={props.onSearchInputFocus}
                onBlur={props.onSearchInputBlur}
              />
            </FormControl>
            {!props.smallScreen && (
              <LineStatWrapper>
                <Tooltip
                  arrow
                  title={tr.getParticipantFormFromTemplates(
                    registeredPositionsCount || 0,
                    props.line?.lineParticipantTermInSingularTemplate,
                    props.line?.lineParticipantTermInPluralTemplate
                  )}
                >
                  <div onClick={handlePersonClick}>
                    <PersonsCount style={{ margin: '0 0.5rem' }}>{registeredPositionsCount || 0}</PersonsCount>
                  </div>
                </Tooltip>
                {!!props.line.displayWaitingTimeEstimation && (lineIsFull || waitingTime < 1440) && (
                  <Tooltip arrow title={tr.lineMonitoring.waitingTimeTooltip}>
                    <div>
                      <IconValue
                        style={{ margin: '0 0.5rem 0 0' }}
                        icon={<TimeIcon style={{ color: 'rgba(0,0,0,.28)' }} />}
                      >
                        <div style={{ color: '#404040' }}>
                          {lineIsFull ? tr.lineMonitoring.lineFull : tr.time.hmin(waitingTime)}
                        </div>
                      </IconValue>
                    </div>
                  </Tooltip>
                )}
              </LineStatWrapper>
            )}
            {!props.simplifyMode && (
              <Tooltip arrow title={tr.lineMonitoring.other}>
                <div>
                  <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
                    <Badge
                      color="secondary"
                      badgeContent={filterBadgeCount}
                      max={100}
                      invisible={filtersIsEmpty || props.filtersInactive}
                    >
                      <MoreIcon />
                    </Badge>
                  </IconButton>
                </div>
              </Tooltip>
            )}
          </BarContainer>
        </SubBar>
      </MonitoringBarContainer>
      <Menu
        MenuListProps={{ style: { minWidth: '390px' } }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(undefined)}
        elevation={2}
      >
        {!!isSingleCheckpoint && !!isStart && (
          <MenuItem
            component={NavLink}
            to={checkpointHost(props.shopId, props.lineId, String(props.checkpoints?.[0].id))}
          >
            <ListItemIcon style={{ minWidth: '36px' }}>
              <ChangeSettingsIcon fontSize="small" />
            </ListItemIcon>
            {tr.lineMonitoring.changeCheckpointSettings}
          </MenuItem>
        )}
        {!!isSingleCheckpoint && !!isStart && (
          <MenuItem
            component={NavLink}
            to={checkpointHost(props.shopId, props.lineId, String(props.checkpoints?.[0].id), true)}
          >
            <ListItemIcon style={{ minWidth: '36px' }}>
              <Stop fontSize="small" />
            </ListItemIcon>
            {tr.lineMonitoring.stopLine}
          </MenuItem>
        )}
        {!!isSingleCheckpoint && !isStart && (
          <MenuItem
            component={NavLink}
            to={checkpointHost(props.shopId, props.lineId, String(props.checkpoints?.[0].id))}
          >
            <ListItemIcon style={{ minWidth: '36px' }}>
              <PlayArrow fontSize="small" />
            </ListItemIcon>
            {tr.lineMonitoring.startLine}
          </MenuItem>
        )}
        {!!isSingleCheckpoint && <Divider style={{ margin: '4px 0' }} />}
        {!props.simplifyMode && (
          <MenuItem onClick={(event) => setFilterAnchorEl(event.currentTarget)}>
            <ListItemIcon style={{ minWidth: '36px' }}>
              <Badge
                color="secondary"
                badgeContent={filterBadgeCount}
                max={100}
                invisible={filtersIsEmpty || props.filtersInactive}
              >
                <FilterIcon fontSize="small" />
              </Badge>
            </ListItemIcon>
            {tr.lineMonitoring.filters}
          </MenuItem>
        )}
        {!props.simplifyMode && <Divider style={{ margin: '4px 0' }} />}
        <MenuItem component={NavLink} to={lineDelay(props.shopId, props.lineId)} disabled={!isStart}>
          <ListItemIcon style={{ minWidth: '36px' }}>
            <RunningWithErrors fontSize="small" />
          </ListItemIcon>
          {tr.lineMonitoring.lineDelay}
          <LinkIcon color="disabled" fontSize="small" style={{ marginLeft: '0.5rem' }} />
        </MenuItem>
        {!isSingleCheckpoint && (
          <MenuItem component={NavLink} to={navCheckpoints(props.shopId, props.lineId)}>
            <ListItemIcon style={{ minWidth: '36px' }}>
              <BasketIcon fontSize="small" />
            </ListItemIcon>
            {tr.lineMonitoring.servicePoints}
            <LinkIcon color="disabled" fontSize="small" style={{ marginLeft: '0.5rem' }} />
          </MenuItem>
        )}
        {!isSingleCheckpoint && props?.line?.flags?.displayStatesMonitoring === true && (
          <MenuItem component={NavLink} to={lineMonitoringState(props.shopId, props.lineId)}>
            <ListItemIcon style={{ minWidth: '36px' }}>
              <LineStatisticsIcon fontSize="small" />
            </ListItemIcon>
            {tr.lineMonitoring.lineStateMonitoring}
            <LinkIcon color="disabled" fontSize="small" style={{ marginLeft: '0.5rem' }} />
          </MenuItem>
        )}
        {props?.line?.flags?.displayDailyScheduler === true && (
          <MenuItem component={NavLink} to={lineScheduler(props.shopId, props.lineId)}>
            <ListItemIcon style={{ minWidth: '36px' }}>
              <ScheduleTodayIcon fontSize="small" />
            </ListItemIcon>
            {tr.lineMonitoring.schedulerMonitoring}
            <LinkIcon color="disabled" fontSize="small" style={{ marginLeft: '0.5rem' }} />
          </MenuItem>
        )}
        {manageAppointments && !!props.line?.allowFutureAppointments && (
          <MenuItem component={NavLink} to={appointmentsLine(props.shopId, props.lineId)}>
            <ListItemIcon style={{ minWidth: '36px' }}>
              <AppointmentsIcon fontSize="small" />
            </ListItemIcon>
            {tr.lineMonitoring.appointments}
            <LinkIcon color="disabled" fontSize="small" style={{ marginLeft: '0.5rem' }} />
          </MenuItem>
        )}
        {manageReports && (
          <MenuItem
            component={NavLink}
            to={{
              pathname: journal(),
              search: new URLSearchParams({ shopId: props.shopId, lineId: props.lineId }).toString()
            }}
          >
            <ListItemIcon style={{ minWidth: '36px' }}>
              <EventIcon fontSize="small" />
            </ListItemIcon>
            {tr.lineMonitoring.journal}
            <LinkIcon color="disabled" fontSize="small" style={{ marginLeft: '0.5rem' }} />
          </MenuItem>
        )}
        {manageReports && (
          <MenuItem
            component={NavLink}
            to={{
              pathname: journal(),
              search: new URLSearchParams({
                shopId: props.shopId,
                lineId: props.lineId,
                today: 'true',
                removedPositions: 'true'
              }).toString()
            }}
          >
            <ListItemIcon style={{ minWidth: '36px' }}>
              <RemovedIcon fontSize="small" />
            </ListItemIcon>
            {tr.lineMonitoring.remove}
            <LinkIcon color="disabled" fontSize="small" style={{ marginLeft: '0.5rem' }} />
          </MenuItem>
        )}
        {!props.simplifyMode && <Divider style={{ margin: '4px 0' }} />}
        {!props.simplifyMode && (
          <MenuItem onClick={handleClickToAllPositionsAction}>
            <ListItemIcon style={{ minWidth: '36px' }}>
              <ChecklistIcon fontSize="small" />
            </ListItemIcon>
            {tr.lineMonitoring.allPositionsActions}
          </MenuItem>
        )}
        <Divider style={{ margin: '4px 0' }} />
        <MenuItem
          disabled={notificationReqiestProcessing}
          onClick={() => {
            if (isSubscribed) {
              unsubscribeFromNotifications()
            } else {
              setNotificationsDialog(true)
            }
          }}
        >
          <ListItemIcon style={{ minWidth: '36px' }}>
            {isSubscribed ? <NotificationsOff fontSize="small" /> : <NotificationsOn fontSize="small" />}
          </ListItemIcon>
          {isSubscribed ? tr.common.unsubscribeFromNotifications : tr.common.subscribeToNotifications}
        </MenuItem>
      </Menu>
      <Menu anchorEl={statAnchorEl} open={!!statAnchorEl} onClose={handleCloseStats} elevation={2}>
        <Statisctics
          calledPositionsCount={calledPositionsCount}
          impossiblePositionsCount={impossiblePositionsCount}
          inServicePositionsCount={inServicePositionsCount}
          waitingInPlacePositionsCount={waitingInPlacePositionsCount}
          waitingPositionsCount={waitingPositionsCount}
        />
      </Menu>
      <Menu
        anchorEl={filterAnchorEl}
        open={!!filterAnchorEl}
        onClose={handleMenuClose}
        elevation={2}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <FiltersWrapper>
          <MultipleCheckSelect
            fullWidth
            variant="outlined"
            style={{ marginBottom: 8 }}
            label={tr.lineMonitoring.status}
            list={PositionStatusesFilter}
            value={props.filters.positionStatusesSelected}
            onChange={handleFilterChange('positionStatusesSelected')}
            lableExtractor={(item) => tr.positionState[item]}
          />
          {!!props.line?.services.length && (
            <MultipleCheckSelect
              variant="outlined"
              style={{ marginBottom: 8 }}
              fullWidth
              label={tr.lineMonitoring.service}
              list={props.line.services}
              value={props.filters.positionServicesSelected}
              onChange={handleFilterChange('positionServicesSelected')}
              lableExtractor={(item) => item?.name}
              valueExtractor={(item) => item?.id || -1}
            />
          )}
          <MultipleCheckSelect
            variant="outlined"
            fullWidth
            style={{ marginBottom: 8 }}
            label={tr.lineMonitoring.positionType}
            list={props.modesAvailableForFiltering}
            value={props.filters.positionTypeSelected}
            onChange={handleFilterChange('positionTypeSelected')}
            lableExtractor={(item) => tr.lineMonitoring.positionTypes[item] || tr.lineMonitoring.positionTypes.unknown}
          />
          <MultipleCheckSelect
            variant="outlined"
            fullWidth
            style={{ marginBottom: 8 }}
            label={tr.lineMonitoring.otherCriterias}
            list={props.positionQueueTypeValues}
            value={props.filters.positionQueueTypeSelected}
            onChange={handleFilterChange('positionQueueTypeSelected')}
            lableExtractor={(item) => queueTypeTranslation[item] || queueTypeTranslation.unknown}
          />
        </FiltersWrapper>
        <ClearButtonWrapper>
          <Button size="small" color="primary" disabled={filtersIsEmpty} onClick={handleReset}>
            {tr.lineMonitoring.reset}
          </Button>
        </ClearButtonWrapper>
      </Menu>
      <AllPositionActionsDialog
        open={allPositionsDialogIsOpen}
        onClose={handleAllPositionDialogClose}
        lineId={props.lineId}
        placeId={props.shopId}
      />
      <NotificationRequestDialog
        open={notificationsDialogIsOpen}
        onClose={handleNotificationsDialogClose}
        availableKeys={
          props.line.displayPositionValidate ? ['PositionCreated', 'PositionValidated'] : ['PositionCreated']
        }
        disabled={notificationReqiestProcessing}
        onNotificationsSubscribe={handleNotificationsSubscribe}
      />
    </>
  )
}

export { LineMonitoringBar }
