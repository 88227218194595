import { ChipListMultipleSelect } from './components/chip-list-select-multiple'
import { ChipListSingleSelect } from './components/chip-list-select-single'
import { ChipListSelectBaseProps, ChipListSelectProps } from './types'

function ChipListSelectComponent<T, V, M extends boolean = false>(props: ChipListSelectProps<T, V, M>) {
  const { multiple = false } = props

  return multiple ? (
    <ChipListMultipleSelect {...(props as ChipListSelectBaseProps<T, V, true>)} />
  ) : (
    <ChipListSingleSelect {...(props as ChipListSelectBaseProps<T, V, false>)} />
  )
}

export default ChipListSelectComponent
export const ChipListSelect = ChipListSelectComponent
