import { useEffect, useState, useRef } from 'react'
import { Badge, Chip, Typography } from '@mui/material'
import Container from 'shared/ui-kit/container'
import ClearIcon from '@mui/icons-material/Clear'
import PersonIcon from '@mui/icons-material/Person'
import {
  FilterByParticipantsChipContent,
  FilterByParticipantsClearIconWrapper,
  FilterByParticipantsContent,
  FilterByParticipantsPersonIconWrapper,
  FilterByParticipantsWrapper
} from '../styled'
import { FilterByParticipantsProps, PersonFilter } from './types'

function FilterByParticipants(props: FilterByParticipantsProps) {
  const [filterByParticipants, setFilterByParticipants] = useState<PersonFilter[]>([])

  const filterTimeout = useRef<NodeJS.Timeout>()

  useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current)
    }
  }, [])

  useEffect(() => {
    const displayFiltersByParticipantsLimit = props.displayFilterByParticipantsUpperLimit || 8

    setFilterByParticipants((curr) => {
      if (!props.positions?.length) {
        return []
      }

      const personFilter: Record<number, PersonFilter> = {}

      ;(props.positions || []).forEach((position) => {
        const personsQuantity = position.personsQuantity || 1
        const quantity =
          personsQuantity >= displayFiltersByParticipantsLimit ? displayFiltersByParticipantsLimit : personsQuantity

        const currentFilter = curr.find((el) => el.personCount === personsQuantity)

        if (personFilter[quantity]) {
          personFilter[quantity].positionLength++
        } else {
          personFilter[quantity] = {
            personCount: quantity,
            positionLength: 1,
            isSelected: currentFilter?.isSelected,
            andMore: quantity === displayFiltersByParticipantsLimit
          }
        }
      })

      const objToArray = Object.values(personFilter) || []
      const sorted = objToArray.sort((a, b) => a.personCount - b.personCount)

      props.onChange(sorted)

      return sorted
    })
  }, [props.positions])

  function resetFilter() {
    setFilterByParticipants((curr) => {
      const result = curr.map((item) => ({ ...item, isSelected: false }))

      props.onChange(result)

      return result
    })
    clearTimeout(filterTimeout.current)
  }

  function selectFilter(personCount) {
    return () => {
      setFilterByParticipants((curr) => {
        const result = curr.map((item) =>
          item.personCount === personCount ? { ...item, isSelected: !item.isSelected } : item
        )

        props.onChange(result)

        return result
      })

      clearTimeout(filterTimeout.current)

      if (props.displayFilterByParticipantsResetInSeconds) {
        filterTimeout.current = setTimeout(resetFilter, props.displayFilterByParticipantsResetInSeconds * 1000)
      }
    }
  }

  const displayFiltersByParticipants = filterByParticipants?.length > 0
  const filtersByParticipantsIsSelected = (filterByParticipants || []).filter((el) => el.isSelected).length > 0

  return (
    <>
      {displayFiltersByParticipants && (
        <Container style={{ overflowX: 'auto', padding: '1rem 0.5rem', position: 'relative' }}>
          <FilterByParticipantsWrapper>
            {!filtersByParticipantsIsSelected && (
              <FilterByParticipantsPersonIconWrapper>
                <PersonIcon style={{ color: '#c1c1c1' }} />
              </FilterByParticipantsPersonIconWrapper>
            )}
            {filtersByParticipantsIsSelected && (
              <Chip
                style={{ borderRadius: '16px', position: 'absolute' }}
                label={
                  <FilterByParticipantsClearIconWrapper>
                    <ClearIcon fontSize="small" />
                  </FilterByParticipantsClearIconWrapper>
                }
                variant="filled"
                color="default"
                onClick={resetFilter}
              />
            )}
            <FilterByParticipantsContent $filterIsSelected={filtersByParticipantsIsSelected}>
              {filterByParticipants.map((num) => (
                <Badge badgeContent={num.positionLength} color="primary" key={num.personCount}>
                  <Chip
                    style={{ borderRadius: '16px' }}
                    label={
                      <FilterByParticipantsChipContent>
                        <Typography variant="body2" fontWeight={500}>
                          {num.andMore ? `${num.personCount}+` : num.personCount}
                        </Typography>
                      </FilterByParticipantsChipContent>
                    }
                    variant="filled"
                    color={num.isSelected ? 'primary' : 'default'}
                    onClick={selectFilter(num.personCount)}
                  />
                </Badge>
              ))}
            </FilterByParticipantsContent>
          </FilterByParticipantsWrapper>
        </Container>
      )}
    </>
  )
}

export { FilterByParticipants }
