import { useEffect, useState } from 'react'
import { useAppSelector } from 'store'
import { getNotificationSubscriptionsSelector } from 'store/selectors/notificationSelectors'
import { getTokenAsync, isPushNotificationSupported, prepare } from './firebase'
import { notification as notificationApi } from 'shared/api'

function useNotifications() {
  const [permissionIsAccepted, setPermissionIsAccepted] = useState(false)

  const subscriptions = useAppSelector(getNotificationSubscriptionsSelector) || []

  useEffect(() => {
    if (subscriptions.length) {
      setNotificationRequestAndUpdatePushToken()
    }
  }, [subscriptions])

  async function setNotificationRequestAndUpdatePushToken() {
    if (permissionIsAccepted) {
      return
    }

    if (!isPushNotificationSupported()) {
      return
    }

    try {
      await prepare()

      const token = await getTokenAsync()

      await notificationApi.updatePushToken({ token })

      setPermissionIsAccepted(true)
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  return { permissionIsAccepted, setNotificationRequestAndUpdatePushToken }
}

export { useNotifications }
