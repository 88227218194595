import { Button } from '@mui/material'
import { LineLayoutProps, LineSettingsGroup } from './types'
import {
  ActionsWrapper,
  Group,
  GroupLabel,
  GroupsWrapper,
  SmallIcon,
  MobileLayoutBar,
  MobileBarContent,
  MobileBarLabel,
  MobileBarActions
} from '../styled'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import InvalidIcon from '@mui/icons-material/Error'
import Text from 'shared/ui-kit/text'
import { useTranslation } from 'shared/i18n/translate'
import { Bar } from 'shared/ui-kit/bar'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { useState } from 'react'

function LineMobileLayout({
  formGroups,
  onChangeActiveGroup,
  activeGroup,
  formIsValid,
  isEdit,
  lastUpdateText,
  children,
  isLoading
}: LineLayoutProps) {
  const { tr } = useTranslation()

  const [isExpanded, setIsExpanded] = useState(false)

  function handleMenu() {
    setIsExpanded(!isExpanded)
  }

  const activeFormGroup = formGroups.find((el) => el.key === activeGroup)

  function handleGroupChange(key: LineSettingsGroup) {
    return () => {
      setIsExpanded(false)
      onChangeActiveGroup(key)()
    }
  }

  return (
    <>
      <Bar style={{ padding: '0' }}>
        <div style={{ width: '100%' }}>
          <MobileLayoutBar>
            <MobileBarContent onClick={handleMenu}>
              {isExpanded ? <ExpandLessIcon color="primary" /> : <ExpandMoreIcon color="primary" />}
              <MobileBarLabel>{activeFormGroup?.label}</MobileBarLabel>
            </MobileBarContent>
            <MobileBarActions>
              <ActionsWrapper>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={!formIsValid || isLoading}
                  type="submit"
                >
                  {isEdit ? tr.entityEdit.updateButton : tr.entityEdit.createButton}
                  {!formIsValid && (
                    <SmallIcon style={{ position: 'absolute', right: -8, top: -8 }}>
                      <InvalidIcon style={{ fontSize: '16px' }} color="primary" />
                    </SmallIcon>
                  )}
                </Button>
              </ActionsWrapper>
            </MobileBarActions>
          </MobileLayoutBar>
          {isExpanded && (
            <GroupsWrapper>
              {formGroups.map((group) => (
                <Group key={group.key} onClick={handleGroupChange(group.key)} style={{ padding: '0.16rem 0' }}>
                  <GroupLabel style={{ fontSize: '16px' }} isActive={group.key === activeGroup}>
                    {group.label}
                  </GroupLabel>
                  {!group.invalid && <SmallIcon />}
                  <SmallIcon>
                    {group.key === activeGroup && <ChevronRightIcon fontSize="small" color="primary" />}
                  </SmallIcon>
                  {group.invalid && (
                    <SmallIcon>
                      <InvalidIcon style={{ fontSize: '16px' }} color="primary" />
                    </SmallIcon>
                  )}
                </Group>
              ))}
              {!!lastUpdateText && (
                <Text type="caption" style={{ marginTop: '0.5rem' }}>
                  {lastUpdateText}
                </Text>
              )}
            </GroupsWrapper>
          )}
        </div>
      </Bar>
      <div style={{ width: '100%', padding: '1rem 0.5rem', boxSizing: 'border-box' }}>
        <div style={{ width: '100%' }}>{children}</div>
      </div>
    </>
  )
}

export { LineMobileLayout }
