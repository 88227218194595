type EventFlowData = any

function parseEventData(data: string) {
  let result: EventFlowData | undefined

  try {
    result = JSON.parse(data)
  } catch (err) {
    console.warn(err)
  }

  return result
}

export { parseEventData }
