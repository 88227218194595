import { Menu, MenuItem, IconButton, Tooltip, Divider } from '@mui/material'
import { NavLink, useHistory, useParams } from 'react-router-dom'
import { checkpointDelay, checkpointMonitoringCreate } from 'pages/nav'
import {
  Add as AddIcon,
  NotificationsOff,
  Help as HelpIcon,
  MoreVert as MoreIcon,
  Notifications as NotificationsOn,
  PauseOutlined as PauseServiceIcon,
  RunningWithErrors as RunningWithErrorsIcon,
  Link as LinkIcon
} from '@mui/icons-material'
import { ListItemIcon } from './list-item-icon'
import { useTranslation } from 'shared/i18n/translate'
import { useAppSelector } from 'store'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { HotkeysDialog } from './hotkeys-dialog'
import { useState, MouseEvent } from 'react'
import { CheckpointBarActionsProps } from '../types'
import { CheckpointStat } from './checkpoint-stat'
import { BarRightPart } from '../styled'
import { useNotificationSubscription } from 'features/notifications/useNotificationSubscription'
import { NotificationRequestDialog } from 'features/notifications/notification-request-dialog'
import { NotificationKey } from 'shared/models/entities/notification.model'

function CheckpointBarActions(props: CheckpointBarActionsProps) {
  const [isTutorial, setIsTutorial] = useState(false)
  const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLButtonElement>()

  const history = useHistory()
  const { tr } = useTranslation()

  const { canAddAndEditPositions } = useAppSelector(getPermissionsSelector)
  const { checkpointId, lineId, placeId } = useParams<{ lineId: string; placeId: string; checkpointId: string }>()
  const [notificationsDialogIsOpen, setNotificationsDialog] = useState(false)

  const { notificationReqiestProcessing, isSubscribed, subscribeToNotifications, unsubscribeFromNotifications } =
    useNotificationSubscription({
      type: 'CheckpointMonitoring',
      placeId: Number(placeId),
      lineId: Number(lineId),
      checkpointId: Number(checkpointId)
    })

  function handleAdd() {
    history.push(checkpointMonitoringCreate(placeId, lineId, checkpointId))
  }

  function openMore(ev: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) {
    setAnchorEl(ev.currentTarget)
  }

  function closeMore() {
    setAnchorEl(undefined)
  }

  function openTutorial() {
    setIsTutorial(true)
    closeMore()
  }

  function closeTutorial() {
    setIsTutorial(false)
  }

  function handlePause() {
    props.onPauseMode?.()
    closeMore()
  }

  function handleNotificationsDialogClose() {
    setNotificationsDialog(false)
  }

  function handleNotificationsSubscribe(keys: NotificationKey[]) {
    subscribeToNotifications(keys).then(() => {
      handleNotificationsDialogClose()
    })
  }

  const addButtonTooltip = props.actionsDisabled.create
    ? tr.lineMonitoring.impossibleAddTooltop
    : tr.lineMonitoring.addTooltip

  return (
    <>
      {(!!props.actions.create || !!canAddAndEditPositions) && (
        <Tooltip arrow title={addButtonTooltip}>
          <div>
            <IconButton disabled={!!props.actionsDisabled.create} onClick={handleAdd}>
              <AddIcon />
            </IconButton>
          </div>
        </Tooltip>
      )}
      <BarRightPart>
        <CheckpointStat
          persons={props.registeredPositionsCount}
          min={props.line?.displayWaitingTimeEstimation ? props.waitingTime || 0 : 0}
          template={tr.getParticipantFormFromTemplates(
            props.registeredPositionsCount || 0,
            props.line?.lineParticipantTermInSingularTemplate,
            props.line?.lineParticipantTermInPluralTemplate
          )}
        />
        <Tooltip arrow title={tr.lineMonitoring.other}>
          <div>
            <IconButton onClick={openMore}>
              <MoreIcon />
            </IconButton>
          </div>
        </Tooltip>
      </BarRightPart>
      <Menu
        MenuListProps={{ style: { minWidth: '300px' } }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={closeMore}
        elevation={2}
      >
        <MenuItem
          component={NavLink}
          to={checkpointDelay(placeId, lineId, checkpointId)}
          disabled={props.actionsDisabled.checkpointDelay}
        >
          <ListItemIcon>
            <RunningWithErrorsIcon fontSize="small" />
          </ListItemIcon>
          {tr.lineMonitoring.checkpointDelay}
          <LinkIcon color="disabled" fontSize="small" style={{ marginLeft: '0.5rem' }} />
        </MenuItem>
        {!!props.actions.suspendService && (
          <MenuItem disabled={props.actionsDisabled.suspendService} onClick={handlePause}>
            <ListItemIcon>
              <PauseServiceIcon fontSize="small" />
            </ListItemIcon>
            {tr.checkpointMonitoring.suspendService}
          </MenuItem>
        )}
        <MenuItem onClick={openTutorial}>
          <ListItemIcon>
            <HelpIcon fontSize="small" />
          </ListItemIcon>
          {tr.checkpointMonitoring.hotkeyHelp}
        </MenuItem>
        <Divider style={{ margin: '4px 0' }} />
        <MenuItem
          disabled={notificationReqiestProcessing}
          onClick={() => {
            if (isSubscribed) {
              unsubscribeFromNotifications()
            } else {
              setNotificationsDialog(true)
            }
          }}
        >
          <ListItemIcon style={{ minWidth: '36px' }}>
            {isSubscribed ? <NotificationsOff fontSize="small" /> : <NotificationsOn fontSize="small" />}
          </ListItemIcon>
          {isSubscribed ? tr.common.unsubscribeFromNotifications : tr.common.subscribeToNotifications}
        </MenuItem>
      </Menu>
      <HotkeysDialog open={isTutorial} onClose={closeTutorial} />
      <NotificationRequestDialog
        open={notificationsDialogIsOpen}
        onClose={handleNotificationsDialogClose}
        availableKeys={
          props.line.displayPositionValidate ? ['PositionCreated', 'PositionValidated'] : ['PositionCreated']
        }
        disabled={notificationReqiestProcessing}
        onNotificationsSubscribe={handleNotificationsSubscribe}
      />
    </>
  )
}

export { CheckpointBarActions }
