import { useEffect, useState } from 'react'
import { beacon as beaconApi } from 'shared/api'
import { useHistory, useParams } from 'react-router-dom'
import { useTimeZoneFilter } from 'features/time-zone-filter'
import { useTranslation } from 'shared/i18n/translate'
import { beacons, beacon } from 'pages/nav'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { showMessage, MessageTypes } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { BeaconModel, GetResponseType } from 'shared/models'
import Container from 'shared/ui-kit/container'
import { CircularProgress } from '@mui/material'
import { useDataProvider } from 'features/isolated-data-provider'
import { useTitle } from 'shared/hooks/useTitle'
import { BeaconEditForm } from './edit-form'
import { CenterContainer } from 'shared/ui-kit/center-container'
import { useAppDispatch } from 'store'

function SmartBeaconEdit() {
  const { beaconId, placeId } = useParams<{ beaconId: string; placeId: string }>()
  const { tr, locale } = useTranslation()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const [data, setData] = useState<BeaconModel | null>(null)
  const [parents, setParents] = useState<GetResponseType<BeaconModel>['parents'] | null>(null)
  const [processing, setProcessing] = useState(false)

  const { getPlace } = useDataProvider()

  useTitle({ placeName: getPlace(placeId)?.name })
  useTimeZoneFilter(placeId)

  function showOperationMessage(
    keyTr: keyof typeof tr.beaconOperationMessages,
    type: MessageTypes = MessageTypes.Info
  ) {
    dispatch(showMessage(tr.beaconOperationMessages[keyTr], type))
  }

  useEffect(() => {
    beaconApi.getBeacon(beaconId, placeId).then((response) => {
      setData(response.data)
      setParents(response.parents)
    })
  }, [])

  useEffect(() => {
    if (!parents?.shop || !data) {
      return
    }

    const crumb = getCrumbs(tr.breadcrumbs)
    const { shop } = parents

    const editTails = [
      crumb.beacon([String(shop.id), String(data.id)], data.name),
      crumb.editBeacon([String(shop.id), String(data.id)])
    ]
    const createTails = [crumb.newBeacon([shop.id as string])]

    dispatch(
      setBreadcrumbs([
        crumb.home(),
        crumb.places(),
        crumb.place([String(shop.id)], shop.name),
        crumb.beacons([String(shop.id)]),
        ...(beaconId ? editTails : createTails)
      ])
    )
  }, [data, parents, locale])

  function handleSave(data: BeaconModel) {
    !beaconId ? handleCreate(data) : handleUpdate(data)
  }

  function handleCreate(data: BeaconModel) {
    setProcessing(true)

    beaconApi
      .createBeacon({ ...data, shopId: Number(placeId) })
      .then(() => {
        showOperationMessage('createSuccess', MessageTypes.Success)
        history.push(beacons(placeId))
      })
      .catch(() => {
        showOperationMessage('createError', MessageTypes.Error)
        setProcessing(false)
      })
  }

  function handleUpdate(data: BeaconModel) {
    setProcessing(true)

    beaconApi
      .updateBeacon(data)
      .then(() => {
        showOperationMessage('updateSuccess', MessageTypes.Success)
        history.push(beacon(placeId, String(data.id)))
      })
      .catch(() => {
        showOperationMessage('updateError', MessageTypes.Error)
        setProcessing(false)
      })
  }

  function handleDelete(id: number) {
    setProcessing(true)

    beaconApi
      .deleteBeacon(id)
      .then(() => {
        showOperationMessage('deleteSuccess', MessageTypes.Success)
        history.push(beacons(placeId))
      })
      .catch(() => {
        showOperationMessage('deleteError', MessageTypes.Error)
        setProcessing(false)
      })
  }

  if (data === null) {
    return (
      <CenterContainer>
        <CircularProgress />
      </CenterContainer>
    )
  }

  return (
    <Container>
      <BeaconEditForm
        data={data}
        onSave={handleSave}
        onDelete={handleDelete}
        creation={!beaconId}
        processing={processing}
      />
    </Container>
  )
}

export default SmartBeaconEdit
