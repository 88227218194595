import { SvgIcon, SvgIconProps } from '@mui/material'

function JefileHandIcon() {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 416.4 414.7">
      <path
        d="M14.7,22l7.3,0c-0.3-4.2,0.3-7.9-0.7-10.4c-1.9-5-5.6-6.6-6.4-8.7c-0.7-1.2-1.4-0.9-2-0.6
	C9.7,3.9,10.6,4,8.1,5.4C7.4,6.1,6,7,4.7,7.8C3.7,8.4,2.8,8.9,2,9.2c0.1,1.3,0.8,1.2,2.1,0.7C4.9,9.5,5.6,9.1,6.2,9
	C7.2,8.8,8,8.4,8.5,8c2.4-0.2,2.1-2.4,2.4-2.4c0.1,0-0.3,1.2-0.3,1.7c0,0.2,1.1-1.2,1.1-1.2c0.1,0-0.8,1.4-0.9,1.5
	c0,0.1,1.7-1.2,1.7-1.1c-1.4,1.8-3.4,2.3-5.1,2.4c-0.7,0-3.5,0.9-3.8,1.6c-0.1,0.2-0.7,1.9,2.2,1c0.3-0.1,0-1.1-0.3-1.3
	c0.4,0,0.7,0.8,0.6,1.3c0.5,0,0.9,0,1.4,0c0.6,0,1.9-0.9,3.7-0.2c1.1,0.4,2.3,1.4,3.8,1.7c1.3,0.3,1.5-0.5,2.7-0.5
	c1.1-0.1,1.5,1.2,2,1.8c-0.1,0.7-1.2-2.9-3.5-0.7C16.1,16.3,15.1,20.6,14.7,22z"
      />
    </svg>
  )
}

function JefileHandIconMaterial(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M14.7,22l7.3,0c-0.3-4.2,0.3-7.9-0.7-10.4c-1.9-5-5.6-6.6-6.4-8.7c-0.7-1.2-1.4-0.9-2-0.6
	C9.7,3.9,10.6,4,8.1,5.4C7.4,6.1,6,7,4.7,7.8C3.7,8.4,2.8,8.9,2,9.2c0.1,1.3,0.8,1.2,2.1,0.7C4.9,9.5,5.6,9.1,6.2,9
	C7.2,8.8,8,8.4,8.5,8c2.4-0.2,2.1-2.4,2.4-2.4c0.1,0-0.3,1.2-0.3,1.7c0,0.2,1.1-1.2,1.1-1.2c0.1,0-0.8,1.4-0.9,1.5
	c0,0.1,1.7-1.2,1.7-1.1c-1.4,1.8-3.4,2.3-5.1,2.4c-0.7,0-3.5,0.9-3.8,1.6c-0.1,0.2-0.7,1.9,2.2,1c0.3-0.1,0-1.1-0.3-1.3
	c0.4,0,0.7,0.8,0.6,1.3c0.5,0,0.9,0,1.4,0c0.6,0,1.9-0.9,3.7-0.2c1.1,0.4,2.3,1.4,3.8,1.7c1.3,0.3,1.5-0.5,2.7-0.5
	c1.1-0.1,1.5,1.2,2,1.8c-0.1,0.7-1.2-2.9-3.5-0.7C16.1,16.3,15.1,20.6,14.7,22z"
      />
    </SvgIcon>
  )
}

export default JefileHandIcon
export { JefileHandIcon, JefileHandIconMaterial }
