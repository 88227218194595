import { useState, useEffect } from 'react'
import { Form, FormGroup } from 'shared/ui-kit/form'
import Card from 'shared/ui-kit/card'
import { useTranslation } from 'shared/i18n/translate'
import Toggle from 'shared/ui-kit/toggle-wrap'
import { DatePicker } from 'features/date'
import { Button, IconButton } from '@mui/material'
import { RemoveCircleOutline as RemoveIcon, Add as AddIcon } from '@mui/icons-material'
import produce from 'immer'
import { DaysOffModel } from 'shared/models'
import { ActionsWrapper } from 'shared/ui-kit/action-wrapper'
import { DaysOffProps } from './types'

function DaysOff(props: DaysOffProps) {
  const { onSave } = props
  const { tr } = useTranslation()
  const [data, setData] = useState<DaysOffModel>({ daysOff: [], shopId: 0 })

  useEffect(() => {
    setData(props.data)
  }, [])

  function handleSave() {
    onSave(data)
  }

  function handleAddDay() {
    setData(
      produce(data, (draft) => {
        draft.daysOff.push({ repeat: true, date: Date.now() })
      })
    )
  }

  function handleRemoveDay(i) {
    setData(
      produce(data, (draft) => {
        draft.daysOff.splice(i, 1)
      })
    )
  }

  function handleRepeatChange(i, val) {
    setData(
      produce(data, (draft) => {
        draft.daysOff[i].repeat = val
      })
    )
  }

  function handleDateChange(i, val) {
    setData(
      produce(data, (draft) => {
        draft.daysOff[i].date = val
      })
    )
  }

  if (!data) {
    return null
  }

  return (
    <>
      <Card>
        <Form>
          {data.daysOff.map((day, i) => (
            <FormGroup style={{ display: 'flex', alignItems: 'center' }} key={i + day.date}>
              <DatePicker value={day.date} onChange={(val) => handleDateChange(i, +val)} style={{ marginTop: 0 }} />
              <div style={{ flex: 1, marginLeft: 16, marginRight: 16 }}>
                <Toggle
                  toggled={day.repeat}
                  label={tr.placeEdit.repeatDay}
                  onToggle={(_, val) => handleRepeatChange(i, val)}
                />
              </div>
              <IconButton onClick={() => handleRemoveDay(i)}>
                <RemoveIcon />
              </IconButton>
            </FormGroup>
          ))}
          <FormGroup>
            <Button size="small" variant="outlined" startIcon={<AddIcon />} onClick={handleAddDay}>
              {tr.placeEdit.addDay}
            </Button>
          </FormGroup>
        </Form>
      </Card>
      <ActionsWrapper>
        <Button disabled={props.processing} variant="contained" color="primary" size="small" onClick={handleSave}>
          {tr.entityEdit.updateButton}
        </Button>
      </ActionsWrapper>
    </>
  )
}

export { DaysOff }
