import { Component } from 'react'
import ImageIcon from '@mui/icons-material/Image'
import ClearIcon from '@mui/icons-material/HighlightOff'
import UploadIcon from '@mui/icons-material/CloudUpload'
import { ControlLabel } from 'shared/ui-kit/control-label'
import Uploader, { File } from 'shared/utils/file-uploader'
import { file } from 'shared/api'
import translate from 'shared/i18n/translate'
import { Attention, FileContainer, ImageName, Label, ErrorMessage, Message } from './styled'
import { convertFileSize } from './convert-file-size'
import { CircularProgress } from '@mui/material'
import { FileUploaderProps, FileUploaderState } from './types'

class FileUploader extends Component<FileUploaderProps, FileUploaderState> {
  uploader

  constructor(props) {
    super(props)

    this.uploader = new Uploader({
      request: this.handleRequest.bind(this),
      processForm: this.props.processForm,
      onSuccess: this.handleMapUploadSuccess.bind(this),
      onFail: this.handleMapUploadFail.bind(this),
      accept: this.props.accept,
      onInvalid: this.onInvalid,
      maxFileSize: this.props.maxFileSize,
      imageMaxSizeMB: this.props.imageMaxSizeMB,
      imageMaxWidthOrHeight: this.props.imageMaxWidthOrHeight,
      multiple: this.props.multiple,
      onLoadingChange: this.handleLoading.bind(this)
    })
  }

  state: FileUploaderState = {
    file: this.props.file,
    invalid: false,
    invalidMessage: '',
    progress: 0,
    isLoading: false
  }

  handleLoading(isLoading: boolean) {
    this.setState({ isLoading })
  }

  handleRequest(formData: FormData) {
    const request = this.props.request || file.upload

    return request(formData, {}, (ev) => this.setState({ progress: Math.floor((100 / ev.total) * ev.loaded) }))
  }

  componentWillUnmount() {
    this.uploader.destroy()
  }

  onInvalid = (reason: string) => {
    const maxFileSize = this.props.maxFileSize || 10 * 1024 * 1024
    const fileSizeError = this.props.translation.fileUploader.fileSizeIsOver(
      convertFileSize(maxFileSize, this.props.translation)
    )
    const fileTypeError = this.props.translation.fileUploader.typeIsIncorrect

    const invalidMessage = reason === 'size' ? fileSizeError : fileTypeError

    this.setState({ invalid: true, invalidMessage })
  }

  handleMapUploadSuccess(file: File) {
    if (this.state.invalid) {
      this.setState({ invalid: false })
    }

    this.props.onChange?.(file)

    if (!this.props.multiple) {
      this.setState({ file })
    }
  }

  handleMapUploadFail(file: File, error: any) {
    if (this.state.invalid) {
      this.setState({ invalid: false })
    }

    this.props.onError?.(file, error)
  }

  handleClearFile = (ev: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    ev.stopPropagation()

    if (!this.props.multiple) {
      this.setState({ file: undefined })
    }

    this.props.onChange?.(null)
  }

  handleDownloadFile = () => {
    const id = (this.state.file as any)?.id

    if (id) {
      file.download(id)
    }
  }

  render() {
    const { label, attention, buttonText, translation, margin, notOutlined } = this.props
    const file = this.state.file

    return (
      <section style={{ margin: margin ? '0.5rem 0' : undefined }}>
        <Label $notOutlined={notOutlined}>
          <ControlLabel>{label}</ControlLabel>
          {attention && <Attention />}
        </Label>
        {file ? (
          <FileContainer $notOutlined={notOutlined} onClick={this.handleDownloadFile}>
            <ImageIcon />
            <ImageName>{file.name || translation.common.download}</ImageName>
            <ClearIcon onClick={this.handleClearFile} />
          </FileContainer>
        ) : (
          <FileContainer
            $notOutlined={notOutlined}
            style={{ pointerEvents: this.state.isLoading ? 'none' : 'auto' }}
            onClick={this.state.isLoading ? () => {} : this.uploader.openDialog}
          >
            <UploadIcon style={{ opacity: this.state.isLoading ? 0.5 : 1 }} />
            <ImageName style={{ opacity: this.state.isLoading ? 0.5 : 1 }}>
              {buttonText || translation.fileUploader.uploadButton}
            </ImageName>
            {this.state.isLoading && !this.props.multiple && (
              <CircularProgress variant={'determinate'} value={this.state.progress} size={21} />
            )}
            {this.state.isLoading && !!this.props.multiple && <CircularProgress size={21} />}
          </FileContainer>
        )}
        {this.state.invalid && <ErrorMessage>{this.state.invalidMessage}</ErrorMessage>}
        {!this.state.invalid && !!this.props.message && <Message>{this.props.message}</Message>}
      </section>
    )
  }
}

export default translate(FileUploader)
