import * as Yup from 'yup'
import { isValidPassword } from 'shared/utils/string-test'
import { UserShop } from 'shared/models'

type UserEditValidationParameters = {
  isNew?: boolean
  isSelf?: boolean
  insecurePassword?: boolean
}

function getUserEditValidationSchema(props: UserEditValidationParameters) {
  return Yup.object().shape({
    firstName: Yup.string().required(),
    secondName: Yup.string().required(),
    email: Yup.string().required().email(),
    profileId: Yup.string()
      .nullable()
      .test({ test: (value, context) => context.parent.isAdmin || !!value }),
    shops: Yup.array().test({
      test: (value, context) => {
        const isAdmin = !!context.parent.isAdmin

        if (isAdmin) {
          return true
        }

        if (!value?.length) {
          return false
        }

        if (
          (value || []).filter((shop: UserShop) => {
            return !shop.allLines && !shop?.lineIds?.length
          })?.length
        ) {
          return false
        }

        return true
      }
    }),
    password: Yup.string().test({
      test: (value, context) => {
        if (context.parent.generatePasswordAutomatically) {
          return true
        }

        return !props.isNew || (!!value && isValidPassword(value))
      }
    }),
    currentPassword: Yup.string().test({
      test: (value, context) => {
        if (context.parent.generatePasswordAutomatically) {
          return true
        }

        if (props.isNew || !props.isSelf) {
          return true
        }

        return props.insecurePassword
          ? !!value
          : (!context.parent.newPassword && !context.parent.confirmPassword && !props.insecurePassword) || !!value
      }
    }),
    newPassword: Yup.string().test({
      test: (value, context) => {
        if (context.parent.generatePasswordAutomatically) {
          return true
        }

        if (props.isNew) {
          return true
        }

        return (
          (!value && !context.parent.confirmPassword && !context.parent.currentPassword && !props.insecurePassword) ||
          (!!value && isValidPassword(value) && context.parent.currentPassword !== value)
        )
      }
    }),
    confirmPassword: Yup.string().test({
      test: (value, context) => {
        if (context.parent.generatePasswordAutomatically) {
          return true
        }

        if (props.isNew) {
          return !!confirm && context.parent.password === value
        }

        return (
          (!context.parent.newPassword && !value && !props.insecurePassword) || value === context.parent.newPassword
        )
      }
    })
  })
}

export { getUserEditValidationSchema }
