import { ReactNode } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { Button } from '@mui/material'

type Props = {
  onSubmit: () => void
  onCancel: () => void
  open: boolean
  children: ReactNode
  message: string
}

function ConfirmItem({ onSubmit, onCancel, open, children, message }: Props) {
  const { tr } = useTranslation()
  const { yes, no } = tr.positionActions

  return (
    <div style={{ color: '#000', padding: '0 0.5rem', display: 'flex', alignItems: 'center', gap: '1rem' }}>
      {open && (
        <div style={{ flex: 1, display: 'flex', alignItems: 'center', gap: '1rem', justifyContent: 'space-between' }}>
          <div>{message}</div>
          <div>
            <Button color="primary" onClick={onSubmit}>
              {yes}
            </Button>
            <Button color="primary" style={{ marginLeft: 4 }} onClick={onCancel}>
              {no}
            </Button>
          </div>
        </div>
      )}
      {!open && children}
    </div>
  )
}

export default ConfirmItem
