import { useTranslation } from 'shared/i18n/translate'
import { useAppSelector } from 'store'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { NavLink } from 'react-router-dom'
import { Button, CircularProgress, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { JournalModel } from 'shared/models'
import { journal as journalApi } from 'shared/api'
import { customers, journal } from 'pages/nav'
import { useHistory } from 'react-router'
import { JournalItem } from 'pages/journal/journal-item'
import SearchIcon from '@mui/icons-material/Search'
import { PoisitionIsNotFoundProps } from './types'
import { CenterProgressWrapper, JournalTitleWrapper, PositionsJounal, RightButtonWrapper } from '../styled'

let abort: AbortController

function PoisitionIsNotFound({ lineId, shopId, term }: PoisitionIsNotFoundProps) {
  const { tr } = useTranslation()
  const { manageReports } = useAppSelector(getPermissionsSelector)

  const [isSearched, setIsSearched] = useState(false)
  const [isFetching, setFetching] = useState(false)
  const [positionsInJournal, setPositionsInJournal] = useState<JournalModel[]>()

  const history = useHistory()

  function handleSearchInJournal() {
    getJournal()
  }

  async function getJournal() {
    try {
      setFetching(true)
      const abort = new AbortController()
      const res = await journalApi.getJournal(
        {
          shopId,
          lineId,
          term,
          skip: 0,
          startDate: new Date().setUTCHours(0, 0, 0, 0),
          endDate: new Date().setSeconds(0, 0)
        },
        { signal: abort.signal }
      )
      setPositionsInJournal(res.data)
    } catch (err) {
      setPositionsInJournal(undefined)
    } finally {
      setFetching(false)
      setIsSearched(true)
    }
  }

  function handleCustomerClick(id: string | number) {
    const params = new URLSearchParams({ customerId: id as string })
    history.push(`${customers()}?${params}`)
  }

  function hadleOpenOriginalPosition(item: JournalModel) {
    return (originalPositionId: string | number) => {
      const params = new URLSearchParams({ term: originalPositionId as string, shopId: item.shopId as string })
      window.open(`#${journal()}?${params}`, '_blank')
    }
  }

  useEffect(() => {
    setIsSearched(false)
    setFetching(false)
    abort?.abort()
    setPositionsInJournal(undefined)
  }, [term])

  return (
    <div style={{ padding: '1rem' }}>
      <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
        <SearchIcon style={{ color: 'GrayText', fontSize: '52px' }} />
        <Typography variant="h6" color="GrayText">
          {tr.lineMonitoring.noActivePositionFound}
        </Typography>
      </div>
      <RightButtonWrapper>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleSearchInJournal}
          disabled={!!isFetching}
        >
          {tr.lineMonitoring.search}
        </Button>
      </RightButtonWrapper>
      {isFetching && (
        <CenterProgressWrapper>
          <CircularProgress />
        </CenterProgressWrapper>
      )}
      {!!isSearched && !isFetching && (
        <PositionsJounal>
          <JournalTitleWrapper>
            <Typography variant="h6">{tr.reports.journal}</Typography>
            {!!isSearched && !isFetching && manageReports && (
              <NavLink
                style={{ textDecoration: 'none' }}
                to={{ pathname: journal(), search: new URLSearchParams({ term, shopId, lineId }).toString() }}
              >
                <Button color="primary" size="small">
                  {tr.common.searchForAnotherDay}
                </Button>
              </NavLink>
            )}
          </JournalTitleWrapper>
          {positionsInJournal?.map((item, index) => (
            <JournalItem
              key={index}
              data={item}
              onCustomerClick={!manageReports ? undefined : handleCustomerClick}
              onOpenOriginalPosition={!manageReports ? undefined : hadleOpenOriginalPosition(item)}
            />
          ))}
          {!positionsInJournal?.length && (
            <Typography variant="body1" textAlign="center" marginTop="2rem" color="GrayText">
              {tr.lineMonitoring.journalPositionsNotFound}
            </Typography>
          )}
        </PositionsJounal>
      )}
    </div>
  )
}

export { PoisitionIsNotFound }
