import { NotificationKey, NotificationType } from 'shared/models/entities/notification.model'
import { useAppDispatch, useAppSelector } from 'store'
import { getNotificationSubscriptionsSelector } from 'store/selectors/notificationSelectors'
import { notification as notificationApi } from 'shared/api'
import { getAndStoreUserProfile } from 'store/actions/user-action-creators'
import { useNotifications } from './useNotifications'
import { useState } from 'react'
import { openNotificationDeclinedDialog } from 'store/reducers/user-reducer'

function useNotificationSubscription(data: {
  type: NotificationType
  placeId?: number
  lineId?: number
  checkpointId?: number
}) {
  const [notificationReqiestProcessing, setProcessing] = useState(false)

  const dispatch = useAppDispatch()
  const subscriptions = useAppSelector(getNotificationSubscriptionsSelector) || []

  const { permissionIsAccepted, setNotificationRequestAndUpdatePushToken } = useNotifications()

  const isSubscribed =
    !!permissionIsAccepted &&
    subscriptions.find((s) => {
      if (s.type !== data.type) {
        return false
      }

      if (s.placeId !== data.placeId) {
        return false
      }

      if (s.lineId !== data.lineId) {
        return false
      }

      if (s.checkpointId !== data.checkpointId) {
        return false
      }

      return true
    })

  async function subscribeToNotifications(keys: NotificationKey[]) {
    setProcessing(true)

    try {
      await setNotificationRequestAndUpdatePushToken()
    } catch (e) {
      dispatch(openNotificationDeclinedDialog())
      setProcessing(false)
      return
    }

    try {
      await notificationApi.notificationSubscribe({
        type: data.type,
        keys,
        placeId: data.placeId,
        lineId: data.lineId,
        checkpointId: data.checkpointId
      })

      dispatch(getAndStoreUserProfile())
    } finally {
      setProcessing(false)
    }
  }

  async function unsubscribeFromNotifications() {
    setProcessing(true)

    try {
      await notificationApi.notificationUnsubscribe({
        type: data.type,
        placeId: data.placeId,
        lineId: data.lineId,
        checkpointId: data.checkpointId
      })

      dispatch(getAndStoreUserProfile())
    } finally {
      setProcessing(false)
    }
  }

  return { notificationReqiestProcessing, isSubscribed, subscribeToNotifications, unsubscribeFromNotifications }
}

export { useNotificationSubscription }
