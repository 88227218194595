import { Form, FormGroup } from 'shared/ui-kit/form'
import { TextField } from 'shared/ui-kit/text-field'
import { useTranslation } from 'shared/i18n/translate'
import { isPositiveNumber } from 'shared/utils/string-test'
import { Controller } from 'react-hook-form'
import { GroupProps } from '../types'
import { WithHelper } from 'features/element-with-helper'
import Card from 'shared/ui-kit/card'

function DurationsGroup({ control, trigger, watch }: GroupProps) {
  const { tr } = useTranslation()

  const {
    removeUnalertedPositionAfter,
    timeToSetFreeStockPlaces,
    timeHint,
    minTimeToFreePlaceAfterEarlyAcceptance,
    serviceTime,
    timeToReach,
    timeToReachWithAutoCall,
    maxTimeToReach,
    timePlaceholder,
    secondsPlaceholder,
    secondsHint,
    timeToReachDescription
  } = tr.lineEdit

  const autoCallIsEnabled = !!watch('supportAutoCall')

  return (
    <Card>
      <Form>
        <FormGroup>
          <WithHelper tooltipKey="serviceTime" tag="line">
            <Controller
              name="serviceTime"
              control={control}
              render={({ field: f, formState }) => (
                <TextField
                  label={serviceTime}
                  placeholder={secondsPlaceholder}
                  value={f.value}
                  onChange={(val) => {
                    f.onChange(val)
                    trigger()
                  }}
                  attention={!!formState.errors.serviceTime}
                  test={isPositiveNumber}
                  hint={secondsHint}
                  fullWidth
                />
              )}
            />
          </WithHelper>
        </FormGroup>
        <FormGroup>
          <WithHelper tooltipKey="timeToReach" tag="line">
            <Controller
              name="timeToReach"
              control={control}
              render={({ field: f, formState }) => (
                <TextField
                  label={autoCallIsEnabled ? timeToReachWithAutoCall : timeToReach}
                  placeholder={secondsPlaceholder}
                  value={f.value === undefined ? '' : String(f.value)}
                  onChange={f.onChange}
                  attention={!!formState.errors.timeToReach}
                  test={isPositiveNumber}
                  hint={secondsHint}
                  fullWidth
                  message={
                    autoCallIsEnabled && !!watch('serviceTime') && !!f.value
                      ? timeToReachDescription(
                          watch('checkpointCount') !== 1,
                          Math.ceil((f.value || 0) / (watch('serviceTime') || 0)),
                          watch('participantTermInSingularTemplate'),
                          watch('participantTermInPluralTemplate')
                        )
                      : undefined
                  }
                />
              )}
            />
          </WithHelper>
        </FormGroup>
        <FormGroup>
          <WithHelper tooltipKey="maxTimeToReach" tag="line">
            <Controller
              name="maxTimeToReach"
              control={control}
              render={({ field: f, formState }) => (
                <TextField
                  label={maxTimeToReach}
                  placeholder={secondsPlaceholder}
                  value={f.value}
                  onChange={f.onChange}
                  attention={!!formState.errors.maxTimeToReach}
                  test={isPositiveNumber}
                  hint={secondsHint}
                  fullWidth
                />
              )}
            />
          </WithHelper>
        </FormGroup>
        <FormGroup>
          <WithHelper tooltipKey="removeUnalertedPosition" tag="line">
            <Controller
              name="removeUnalertedPosition"
              control={control}
              render={({ field: f }) => (
                <TextField
                  label={removeUnalertedPositionAfter}
                  placeholder={removeUnalertedPositionAfter}
                  value={f.value}
                  onChange={f.onChange}
                  test={isPositiveNumber}
                  hint={timeHint}
                  fullWidth
                />
              )}
            />
          </WithHelper>
        </FormGroup>
        {watch('lineCalculationAlgorithmMode') !== 'normal' && (
          <FormGroup>
            <WithHelper tooltipKey="minTimeToFreePlaceAfterEarlyAcceptance" tag="line">
              <Controller
                name="minTimeToFreePlaceAfterEarlyAcceptance"
                control={control}
                render={({ field: f, formState }) => (
                  <TextField
                    label={minTimeToFreePlaceAfterEarlyAcceptance}
                    placeholder={secondsPlaceholder}
                    value={f.value}
                    onChange={f.onChange}
                    attention={!!formState.errors.minTimeToFreePlaceAfterEarlyAcceptance}
                    test={isPositiveNumber}
                    hint={secondsHint}
                    fullWidth
                  />
                )}
              />
            </WithHelper>
          </FormGroup>
        )}
        {watch('lineCalculationAlgorithmMode') !== 'normal' && (
          <FormGroup>
            <WithHelper tooltipKey="timeToSetFreeStockPlaces" tag="line">
              <Controller
                name="timeToSetFreeStockPlaces"
                control={control}
                render={({ field: f }) => (
                  <TextField
                    label={timeToSetFreeStockPlaces}
                    placeholder={timePlaceholder}
                    value={f.value}
                    onChange={f.onChange}
                    test={isPositiveNumber}
                    hint={timeHint}
                    fullWidth
                  />
                )}
              />
            </WithHelper>
          </FormGroup>
        )}
      </Form>
    </Card>
  )
}

export { DurationsGroup }
