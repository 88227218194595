import { Chip, Divider, IconButton, Typography } from '@mui/material'
import { FormGroup } from 'shared/ui-kit/form'
import TimePickerTimestamp from 'features/time/time-picker-timestamp'
import Toggle from 'shared/ui-kit/toggle-wrap/toggle'
import { useTranslation } from 'shared/i18n/translate'
import { Fragment } from 'react'
import { WeekdayElement } from 'shared/models'
import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material'
import { CloseButtonContainer, TimesContainer, WeekdaysSchedulerActions, WeekdaysWrapper } from './styled'

type Props = {
  value: WeekdayElement[]
  onChange: (val: WeekdayElement[]) => void
}

function WeekdaysScheduler({ value, onChange }: Props) {
  const { tr } = useTranslation()

  const weekdays = [
    { id: 1, name: tr.weekdaysShort.Monday },
    { id: 2, name: tr.weekdaysShort.Tuesday },
    { id: 3, name: tr.weekdaysShort.Wednesday },
    { id: 4, name: tr.weekdaysShort.Thursday },
    { id: 5, name: tr.weekdaysShort.Friday },
    { id: 6, name: tr.weekdaysShort.Saturday },
    { id: 0, name: tr.weekdaysShort.Sunday }
  ]

  function handleItemChange<K extends keyof WeekdayElement>(index: number, key: K, v: WeekdayElement[K]) {
    const newValue = [...value]

    if (newValue?.[index]) {
      newValue[index][key] = v
    }

    onChange(newValue)
  }

  function handleWeekdayChange(index: number, entry: WeekdayElement, dayId: number) {
    return () => {
      const prevWeekdays = entry.weekdays || []
      const newWeekdays = prevWeekdays.includes(dayId)
        ? prevWeekdays.filter((d) => d !== dayId)
        : [...prevWeekdays, dayId]

      let newValue = [...value]

      if (newValue?.[index]) {
        newValue[index].weekdays = newWeekdays
      }

      const canAddItems =
        weekdays.filter((day) => !newValue || !newValue.find((e) => e.weekdays.includes(day.id))).length > 0

      if (!canAddItems) {
        newValue = newValue?.filter((el) => el.weekdays?.length > 0)
      }

      onChange(newValue)
    }
  }

  function handleDeleteSchedule(index: number) {
    return () => {
      const items = value
      onChange([...items.slice(0, index), ...items.slice(index + 1)])
    }
  }

  function handleAddSchedule() {
    onChange([...value, { weekdays: [], nonStopService: false, serviceWithPause: false }])
  }

  const { serviceWithPause, pauseStartHour, pauseEndHour, nonStopService, openingHour, closingHour } = tr.lineEdit

  const notFilledWeekdays = weekdays.filter((day) => !value || !value.find((e) => e.weekdays.includes(day.id)))

  const canAddItems =
    notFilledWeekdays.length > 0 && value.filter((v) => !v.weekdays?.length).length < notFilledWeekdays.length

  return (
    <>
      {!value.length && (
        <FormGroup>
          <Typography variant="h6" color="GrayText" textAlign="center" style={{ opacity: 0.64 }}>
            {tr.lineEdit.emptyScheduler}
          </Typography>
        </FormGroup>
      )}
      {value.map((entry, index) => (
        <Fragment key={index}>
          {index !== 0 && <Divider />}
          <CloseButtonContainer>
            <IconButton size="small" onClick={handleDeleteSchedule(index)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </CloseButtonContainer>
          <FormGroup>
            <WeekdaysWrapper>
              {weekdays.map((day) => (
                <Chip
                  key={day.id}
                  style={{ borderRadius: '16px' }}
                  color={entry.weekdays.includes(day.id) ? 'primary' : 'default'}
                  label={day.name}
                  disabled={!!value?.find((el, i) => i !== index && (el.weekdays || []).includes(day.id))}
                  onClick={handleWeekdayChange(index, entry, day.id)}
                  clickable
                />
              ))}
            </WeekdaysWrapper>
          </FormGroup>
          <FormGroup>
            <Toggle
              toggled={entry.nonStopService}
              label={nonStopService}
              withHelper={true}
              tag="line"
              tooltipKey="nonStopService"
              onToggle={(_, val) => handleItemChange(index, 'nonStopService', val)}
            />
            {!entry.nonStopService && (
              <TimesContainer>
                <TimePickerTimestamp
                  fullWidth
                  label={openingHour}
                  value={entry.openingHour_unixtime}
                  onChange={(val) => handleItemChange(index, 'openingHour_unixtime', val || undefined)}
                  attention={false}
                  offset
                />
                <TimePickerTimestamp
                  fullWidth
                  label={closingHour}
                  value={entry.closingHour_unixtime}
                  onChange={(val) => handleItemChange(index, 'closingHour_unixtime', val || undefined)}
                  attention={false}
                  offset
                />
              </TimesContainer>
            )}
          </FormGroup>
          <FormGroup>
            <Toggle
              toggled={entry.serviceWithPause}
              label={serviceWithPause}
              withHelper={true}
              tag="line"
              tooltipKey="serviceWithPause"
              onToggle={(_, val) => handleItemChange(index, 'serviceWithPause', val)}
            />
            {!!entry.serviceWithPause && (
              <TimesContainer>
                <TimePickerTimestamp
                  fullWidth
                  label={pauseStartHour}
                  value={entry.pauseStartHour_unixtime}
                  onChange={(val) => handleItemChange(index, 'pauseStartHour_unixtime', val || undefined)}
                  attention={false}
                  offset
                />
                <TimePickerTimestamp
                  fullWidth
                  label={pauseEndHour}
                  value={entry.pauseEndHour_unixtime}
                  onChange={(val) => handleItemChange(index, 'pauseEndHour_unixtime', val || undefined)}
                  attention={false}
                  offset
                />
              </TimesContainer>
            )}
          </FormGroup>
        </Fragment>
      ))}
      {canAddItems && (
        <>
          <Divider />
          <WeekdaysSchedulerActions>
            <IconButton size="small" onClick={handleAddSchedule}>
              <AddIcon fontSize="small" />
            </IconButton>
          </WeekdaysSchedulerActions>
        </>
      )}
    </>
  )
}

export { WeekdaysScheduler }
