import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { CircularProgress } from '@mui/material'
import {
  CheckpointMonitoringModel,
  CheckpointMonitoringPositionsChangedReq,
  CheckpointStatus,
  LineModel,
  ParentPlaceLineCheckpoint,
  TimeslotModel
} from 'shared/models'
import { monitoring as monitoringApi, checkpoint as checkpointApi, line as lineApi } from 'shared/api'
import { lineMonitoringCreate, discreteLineMonitoringCreate } from 'pages/nav'
import { useTimeZoneFilter } from 'features/time-zone-filter'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { SuggestionDialog } from './suggestion-dialog'
import { CheckpointInactiveReason } from './components/checkpoint-inactive-reason'
import { CenterContainer } from 'shared/ui-kit/center-container'
import { ClosedServicepointShowPositionsForm } from './components/closed-servicepoint-show-positions-form'
import { setDuplicatePosition } from 'features/position-actions/utils/duplicateStorage'
import { MonitoringRoot, InactiveMessage } from './styled'
import { CheckpintPauseAction } from './components/checkpoint-pause-action'
import Container from 'shared/ui-kit/container'
import { CheckpointMonitoringBar } from './checkpoint-monitoring/monitoring-bar'
import { CheckpointMonitoringPositions } from './checkpoint-monitoring/monitoring-pisitions'
import {
  getDefaultInactiveFilters,
  getInactiveFiltersFromStorage,
  inWorkStatuses,
  setInactiveFiltersToStorage
} from './utils'
import { CheckpointMonitoringRouterParams, InactiveFilters } from './types'
import { useHistory, useParams, useRouteMatch } from 'react-router'
import { useAppDispatch } from 'store'
import { useSuggestionDialog } from './hooks/use-suggestion-dialog'
import { simplifyPositionToFullPositionConvert } from 'features/position/position-converter'
import { useQuery } from 'react-query'
import { SuggestionFinishDialog } from './suggestion-finish-dialog'
import { useSuggestionFinishDialog } from './hooks/use-suggestion-finish-dialog'

function CheckpointMonitoring() {
  const { checkpointId, lineId, placeId, positionId } = useParams<CheckpointMonitoringRouterParams>()
  const match = useRouteMatch()
  const history = useHistory()
  const { tr } = useTranslation()
  const dispatch = useAppDispatch()

  const [nowTime, setNowTime] = useState<number>(Date.now())
  const [lastUpdateDate, setLastUpdateDate] = useState<number>(Date.now())
  const [serverTime, setServerTime] = useState<number>(Date.now())

  useEffect(() => {
    const interval = setInterval(() => {
      setNowTime(Date.now())
    }, 20000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  useTimeZoneFilter(placeId)

  const defaultInactiveFilters = getDefaultInactiveFilters(lineId)

  const [loading, setLoading] = useState(true)
  const [temporaryHelpFormIsOpened, setTemporaryHelpFormIsOpened] = useState(false)
  const [inactiveFilter, setInactiveFilter] = useState<InactiveFilters>(
    getInactiveFiltersFromStorage(placeId, lineId, checkpointId) || defaultInactiveFilters
  )
  const [pauseMode, setPauseMode] = useState(false)
  const { data: checkpoints } = useQuery(['getCheckpoints', placeId], () => checkpointApi.getCheckpointList(placeId))
  const [line, setLine] = useState<LineModel | null>(null)
  const [monitoring, setMonitoring] = useState<CheckpointMonitoringModel | null>(null)

  const checkpoint = useMemo(() => checkpoints?.data?.find((c) => String(c.id) === String(checkpointId)), [checkpoints])

  const isFinished = useMemo(() => {
    if (inactiveFilter.helpIsEnabled) {
      return false
    }

    return checkpoint?.status === CheckpointStatus.finished
  }, [checkpoint])

  const convertedPositions = useMemo(() => {
    const timeDiff = Date.now() - lastUpdateDate

    return (monitoring?.positions || []).map((position) => {
      const timeSlot: TimeslotModel | undefined = position.timeSlot_from
        ? ({ from_unixtime: position.timeSlot_from } as TimeslotModel)
        : undefined

      return simplifyPositionToFullPositionConvert(
        position,
        line?.services || [],
        serverTime + timeDiff,
        tr,
        timeSlot,
        checkpoints?.data
      )
    })
  }, [monitoring?.positions, lastUpdateDate, serverTime, nowTime])

  const { dialodIsBlocked, overTimePosition, setOverTimePosition } = useSuggestionDialog(
    placeId,
    lineId,
    checkpointId,
    line,
    convertedPositions
  )

  const { closeToFinishPosition, finishDialodIsBlocked, toFinishPosition } = useSuggestionFinishDialog(
    line?.showSuggestionToFinishDialog || false,
    line?.showSuggestionToFinishDialogBeforeInSec || 0,
    placeId,
    lineId,
    checkpointId,
    convertedPositions
  )

  const prevDocTitle = useRef(tr.common.title)

  const connect = useRef<{ stop: () => Promise<unknown> } | null>(null)
  const unmount = useRef(false)

  useEffect(() => {
    if (monitoring && line && checkpoint) {
      const lineName = line?.name || ''
      const pos1 =
        monitoring?.positions?.length - monitoring?.positions?.filter?.((el) => el.state === 'joined')?.length
      const pos2 = monitoring?.positions?.filter?.((el) => el.state === 'joined')?.length
      const doctitle =
        monitoring.waitingTime < 1440
          ? `${pos1}/${pos2}/${monitoring.waitingTime}' ${lineName.slice(0, 4)}, ${checkpoint?.name}`
          : `${pos1}/${pos2}' ${lineName.slice(0, 4)}, ${checkpoint?.name}`

      document.title = doctitle
      prevDocTitle.current = doctitle
    }
  }, [line, monitoring, checkpoint])

  useEffect(() => {
    fetchData()

    return () => {
      unmount.current = true
      connect.current?.stop()
      restoreTitle()
    }
  }, [])

  useEffect(() => {
    setInactiveFiltersToStorage(inactiveFilter, placeId, lineId, checkpointId)
  }, [inactiveFilter, placeId, lineId, checkpointId])

  async function fetchData() {
    const helpIsEnabled = inactiveFilter?.helpIsEnabled || false
    const inactiveLineId = helpIsEnabled ? inactiveFilter?.lineId : null

    fetchLine(placeId || '', inactiveLineId || lineId || '')

    try {
      const { data, parents } = await monitoringApi.getCheckpointMonitoring({ checkpointId })

      if (unmount.current === true) {
        return
      }

      setCrumbs(parents)

      const helpIsEnabled = inactiveFilter?.helpIsEnabled || false
      setMonitoring(!helpIsEnabled ? data : { ...data, positions: [] })
      setLastUpdateDate(new Date().getTime())
      setServerTime(data.serverNow || new Date().getTime())
      setLoading(false)

      connectDataUpdate()

      if (!helpIsEnabled) {
        newData(null, data)
      }
    } catch (err) {
      setLoading(false)
    }
  }

  async function fetchLine(placeId: string | number, lineId: string | number, callback?: () => void) {
    try {
      const res = await lineApi.getLine(lineId, placeId, { headers: { 'X-Form-Action': 'View' } })
      setLine(res.data)
    } finally {
      callback?.()
    }
  }

  function setCrumbs(parents?: ParentPlaceLineCheckpoint) {
    if (parents) {
      const crumb = getCrumbs(tr.breadcrumbs)

      const shopName = parents?.shop?.name
      const lineId = parents?.line?.id
      const lineName = parents?.line?.name
      const checkpointId = parents?.checkpoint?.id
      const checkpointName = parents?.checkpoint?.name

      dispatch(
        setBreadcrumbs([
          crumb.home(),
          crumb.places(),
          crumb.place([placeId], shopName),
          crumb.lines([placeId]),
          crumb.line([placeId, String(lineId)], lineName),
          crumb.checkpoints([placeId, String(lineId)]),
          crumb.checkpoints([placeId, String(lineId)], checkpointName),
          crumb.checkpointMonitoring([placeId, String(lineId), String(checkpointId)])
        ])
      )
    }
  }

  function handleDuplicatePosition({ placeId, line, position }) {
    setDuplicatePosition(placeId, { position, back: { placeId, lineId, link: match?.url || '' } })

    if (line.isDiscrete) {
      history.push(`${discreteLineMonitoringCreate(placeId, line.id)}?duplicate`)
    } else {
      history.push(`${lineMonitoringCreate(placeId, line.id)}?duplicate`)
    }
  }

  function connectDataUpdate(config?: { force?: boolean }) {
    setInactiveFilter((curr) => {
      const { maxTimeDuration, serviceIds, lineId: inactiveLineId, helpIsEnabled } = curr

      if (!config?.force && isFinished && !helpIsEnabled && (!maxTimeDuration || serviceIds.length < 1)) {
        return curr
      }

      const params: CheckpointMonitoringPositionsChangedReq = helpIsEnabled
        ? {
            placeId,
            checkpointId,
            lineId: inactiveLineId || lineId,
            isTemporaryHelp: true,
            maxTimeDuration,
            serviceIds
          }
        : { placeId, checkpointId, lineId }

      connectToSocketUpdate(params)

      return curr
    })
  }

  function connectToSocketUpdate(params: CheckpointMonitoringPositionsChangedReq) {
    if (connect.current) {
      connect.current.stop()
    }

    connect.current = monitoringApi.checkpointMonitoringPositionsChanged(params, (res) => {
      if (unmount.current === true) {
        restoreTitle()
        return
      }

      setMonitoring((curr) => {
        newData(curr ? { ...curr } : null, res)
        return res
      })
      setLastUpdateDate(new Date().getTime())
      setServerTime(res.serverNow || new Date().getTime())
    })
  }

  function newData(prevstate: CheckpointMonitoringModel | null, resp: CheckpointMonitoringModel) {
    if (unmount.current === true) {
      restoreTitle()
      return
    }

    tryToCancelTemporaryHelp(prevstate, resp)
  }

  function tryToCancelTemporaryHelp(prev: CheckpointMonitoringModel | null, resp: CheckpointMonitoringModel) {
    setInactiveFilter((curr) => {
      if (curr.helpIsEnabled) {
        const startedAfterReload = curr.startedAfterReload || false

        const backstatePositionsInWorkLength =
          prev?.positions?.filter((p) => inWorkStatuses.find((s) => s === p.state))?.length || 0
        const positionsInWorkLength = resp?.positions?.filter((p) => inWorkStatuses.find((s) => s === p.state))?.length

        const canCalcelAfterRefresh = positionsInWorkLength === 0 && startedAfterReload
        const canCalcelAfterService = backstatePositionsInWorkLength > 0 && positionsInWorkLength === 0

        if (canCalcelAfterRefresh || canCalcelAfterService) {
          refreshAfterCancelTemporary()
          return defaultInactiveFilters
        }
      }

      return curr
    })
  }

  function restoreTitle() {
    window.document.title = tr.common.title
  }

  function handlePause(pauseMode: boolean) {
    return () => setPauseMode(pauseMode)
  }

  function handleStateChange(positionId: string | number) {
    return (newState) => {
      setMonitoring((curr) => {
        if (curr?.positions) {
          return {
            ...curr,
            positions: curr.positions.map((dataPosition) =>
              dataPosition.id === positionId ? { ...dataPosition, state: newState } : dataPosition
            )
          }
        }

        return curr
      })
    }
  }

  function handleRemoveOverTimePosition() {
    setOverTimePosition(null)
    setMonitoring((curr) =>
      curr ? { ...curr, positions: (curr.positions || []).filter((p) => p.id !== overTimePosition?.id) } : null
    )
  }

  function handleWait() {
    setOverTimePosition(null)
  }

  function handleLineIdChange(lineId: number, cb: () => void) {
    setLine((line) => {
      if (String(lineId) === String(line?.id)) {
        cb?.()
        return line
      }

      fetchLine(placeId, lineId, cb)

      return line
    })
  }

  function temporaryHelpFormSubmit(
    reason: string,
    comment: string,
    serviceIds: (string | number)[],
    maxTimeDuration: number,
    lineId: number
  ) {
    const inactiveFilter = { maxTimeDuration, serviceIds, lineId, helpIsEnabled: true }

    checkpointApi.openHideCheckpoint(checkpointId, reason, comment, lineId)

    setTemporaryHelpFormIsOpened(false)
    setInactiveFilter(inactiveFilter)
    setMonitoring((curr) => (curr ? { ...curr, positions: [] } : null))

    handleLineIdChange(inactiveFilter.lineId, () => connectDataUpdate())

    window.scrollTo({ top: 0 })
  }

  function onCancelHelpTemporary() {
    setInactiveFilter(defaultInactiveFilters)
    refreshAfterCancelTemporary()
  }

  function refreshAfterCancelTemporary() {
    setMonitoring((curr) => (curr ? { ...curr, positions: [] } : null))
    handleLineIdChange(defaultInactiveFilters.lineId, () => connectDataUpdate({ force: true }))
  }

  function changeLoading(value: boolean) {
    return () => setLoading(value)
  }

  function changeTemporaryHelpFormIsOpened(value: boolean) {
    return () => setTemporaryHelpFormIsOpened(value)
  }

  const isNotActive = !!monitoring?.lineIsActive === false && monitoring?.positions?.length === 0

  return (
    <>
      {loading && (
        <MonitoringRoot>
          <CenterContainer>
            <CircularProgress color="secondary" />
          </CenterContainer>
        </MonitoringRoot>
      )}
      {!loading && !!monitoring && checkpoint && !!line && (
        <MonitoringRoot>
          <CheckpointMonitoringBar
            line={line}
            onPause={handlePause(true)}
            checkpointStatus={checkpoint?.status}
            lineIsActive={monitoring.lineIsActive}
            waitingTime={monitoring.waitingTime}
            registeredPositionsCount={monitoring.registeredPositionsCount}
          />
          <Container>
            {!!pauseMode && <CheckpintPauseAction services={line?.services || []} onClose={handlePause(false)} />}
            {!pauseMode && isNotActive && (
              <InactiveMessage>
                {tr.checkpointMonitoring.closed}
                {monitoring.inactiveReason && (
                  <CheckpointInactiveReason
                    reason={monitoring.inactiveReason}
                    lineId={lineId}
                    shopId={placeId}
                    checkpointId={checkpointId}
                  />
                )}
              </InactiveMessage>
            )}
            {!pauseMode && !isNotActive && temporaryHelpFormIsOpened && (
              <ClosedServicepointShowPositionsForm
                lineId={Number(lineId)}
                shopId={placeId}
                checkpointId={checkpointId}
                defaultLine={line}
                onCancel={changeTemporaryHelpFormIsOpened(false)}
                onSubmit={temporaryHelpFormSubmit}
              />
            )}
            {!pauseMode && !isNotActive && !temporaryHelpFormIsOpened && (
              <>
                <CheckpointMonitoringPositions
                  positions={convertedPositions}
                  line={line}
                  shopId={placeId}
                  lineId={lineId}
                  checkpointId={checkpointId}
                  startLoading={changeLoading(true)}
                  finishLoading={changeLoading(false)}
                  onDuplicatePosition={handleDuplicatePosition}
                  onPause={handlePause(true)}
                  helpIsEnabled={inactiveFilter?.helpIsEnabled || false}
                  showTemporaryHelpForm={changeTemporaryHelpFormIsOpened(true)}
                  onCancelHelpTemporary={onCancelHelpTemporary}
                  onStateChange={handleStateChange}
                  defaultSelectedId={positionId}
                  dialodIsBlocked={dialodIsBlocked || finishDialodIsBlocked}
                  isFinished={isFinished}
                  suggestedPositionId={monitoring.suggestedPositionId}
                  showButtonCreatePosition={monitoring.showButtonCreatePosition}
                  checkpoint={checkpoint}
                  checkpoints={(checkpoints?.data || []).filter((el) => String(el.lineId) === String(lineId))}
                />
                <SuggestionDialog
                  shopId={placeId}
                  lineId={lineId}
                  checkpointId={checkpointId}
                  data={overTimePosition}
                  timeToReach={line?.timeToReach}
                  requestCommentsOnRemove={line?.requestCommentsOnRemove}
                  onWait={handleWait}
                  onRemove={handleRemoveOverTimePosition}
                />
                <SuggestionFinishDialog data={toFinishPosition} onClose={closeToFinishPosition} />
              </>
            )}
          </Container>
        </MonitoringRoot>
      )}
    </>
  )
}

export default CheckpointMonitoring
