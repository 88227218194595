import { TimeFormats } from './translation-context'
import isString from 'lodash/isString'
import isObject from 'lodash/isObject'

const addZero = (n: number) => (n < 10 ? '0' : '') + n

export default (
  _,
  locale: string,
  date: number | string | Date | null,
  format: TimeFormats | Intl.DateTimeFormatOptions = 'date',
  timeZone = 'UTC'
) => {
  const formats: { [key in TimeFormats]: Intl.DateTimeFormatOptions } = {
    time: { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' },
    date: { year: 'numeric', month: '2-digit', day: '2-digit' },
    dateTime: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: '2-digit',
      hourCycle: 'h23'
    }
  }

  const tryParseUNIXFromString = (date) => {
    return Number.isSafeInteger(Number.parseInt(date)) ? Number.parseInt(date) : date
  }

  if (isString(date)) {
    if (isNaN(new Date(tryParseUNIXFromString(date)) as any)) {
      return date
    }

    date = new Date(tryParseUNIXFromString(date))
  }

  const f: Intl.DateTimeFormatOptions = isObject(format) ? format : formats[format]

  f.timeZone = timeZone

  return new Intl.DateTimeFormat(locale, f).format(date || 0)
}

export function convertDateWithOffset(timestamp: number, offset: number = 0) {
  const d = new Date(timestamp)
  const localTime = d.getTime()
  const localOffset = d.getTimezoneOffset() * 60000
  const utc = localTime + localOffset
  const convertedDate = new Date(utc + offset)
  return convertedDate
}

export const addOffsetToStamp = (timestamp: number, offset = 0) => {
  const d = new Date(timestamp)
  const localTime = d.getTime()
  const localOffset = d.getTimezoneOffset() * 60000
  const utc = localTime + localOffset
  const nd = new Date(utc + offset)

  return nd
}

export const offsetToStamp = (timestamp: number, offset = 0) => {
  const d = new Date(timestamp)
  const localTime = d.getTime()
  const localOffset = d.getTimezoneOffset() * 60000
  const utc = localTime - localOffset
  const nd = new Date(utc - offset)

  return nd.getTime()
}

export const unixtimeToObject = (timestamp: number, offset = 0) => {
  const stamp = timestamp

  const d = new Date(stamp)
  const localTime = d.getTime()
  const localOffset = d.getTimezoneOffset() * 60000
  const utc = localTime + localOffset
  const nd = new Date(utc + offset)

  const hh = addZero(nd.getHours())
  const mm = addZero(nd.getMinutes())

  return `${hh}:${mm}`
}
