import { useState, useEffect, useMemo, useRef } from 'react'
import { List } from 'shared/ui-kit/list'
import { editUser, createUser } from 'pages/nav'
import { useTranslation } from 'shared/i18n/translate'
import { SubBar } from 'shared/ui-kit/sub-bar'
import Toggle from 'shared/ui-kit/toggle-wrap'
import { user } from 'shared/api'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { Badge, Button, Card, CircularProgress, IconButton, Menu } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import { UserModel } from 'shared/models'
import { useHistory, useLocation } from 'react-router-dom'
import Container from 'shared/ui-kit/container'
import { useDebounce } from 'shared/hooks/useDebounce'
import { CenterContainer } from 'shared/ui-kit/center-container'
import { StorageItem } from 'shared/utils/storage'
import { SearchInput } from 'features/search-input'
import { Bar, ClearButtonWrapper, FiltersWrapper, SearchToggle } from './styled'
import { useAppDispatch } from 'store'
import { addTestAttr } from 'shared/utils/test-attr'
import FilterIcon from '@mui/icons-material/FilterList'
import { MultipleCheckSelect } from 'shared/ui-kit/multiple-check-select'
import { useDataProvider } from 'features/isolated-data-provider'
import { useQuery } from 'react-query'

function SmartUserList() {
  const { data: profiles } = useQuery('profiles', () => user.getProfileList(), { refetchOnMount: false, retry: false })

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>()

  const history = useHistory()

  const { search } = useLocation()

  const params = useMemo(() => new URLSearchParams(search), [])

  const { current: usersFilter } = useRef(
    new StorageItem<{
      term: string
      hideInactive: boolean
      shopsFilter: string[]
      profilesFilter: string[]
    }>('usersFilter')
  )

  const [hideInactive, setHideInactive] = useState(usersFilter.value?.hideInactive ?? true)
  const [term, setTerm] = useState(new URLSearchParams(search).get('term') || usersFilter.value?.term || '')
  const [list, setList] = useState<UserModel[] | null>(null)

  const { places } = useDataProvider()

  const [profilesFilter, setProfilesFilter] = useState<string[]>(
    new URLSearchParams(search).getAll('profiles').length
      ? new URLSearchParams(search).getAll('profiles')
      : usersFilter.value?.profilesFilter || []
  )
  const [shopsFilter, setShopsFilter] = useState<string[]>(
    new URLSearchParams(search).getAll('shops').length
      ? new URLSearchParams(search).getAll('shops')
      : usersFilter.value?.shopsFilter || []
  )

  const { tr, locale } = useTranslation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    params.delete('profiles')
    params.delete('shops')

    if (term) {
      params.set('term', term)
    } else {
      params.delete('term')
    }

    if (profilesFilter?.length) {
      profilesFilter.map((profile) => params.append('profiles', profile))
    }

    if (shopsFilter?.length) {
      shopsFilter.map((shop) => params.append('shops', shop))
    }

    usersFilter.value = { term, hideInactive, shopsFilter, profilesFilter }
    history.replace({ search: params.toString() })
  }, [term, hideInactive, shopsFilter, profilesFilter])

  async function fetchList() {
    if (list) {
      setList(null)
    }

    try {
      const { data } = await user.getUserList({ term, hideInactive })
      setList(data)
    } catch (err) {
      setList([])
    }
  }

  const [dataDebounce] = useDebounce([term, hideInactive], 500)

  useEffect(() => {
    fetchList()
  }, [dataDebounce])

  useEffect(() => {
    const crumb = getCrumbs(tr.breadcrumbs)
    dispatch(setBreadcrumbs([crumb.home(), crumb.users()]))
  }, [locale])

  function handleClear() {
    setTerm('')
  }

  function handleCreate() {
    history.push(createUser())
  }

  function handleSelect(data: UserModel) {
    history.push(editUser(String(data.id)))
  }

  function handleChangeTerm(ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setTerm(ev.target.value)
  }

  function handleToggle(_, val: boolean) {
    setHideInactive(!val)
  }

  function handleMenuOpen(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setAnchorEl(event.currentTarget)
  }

  function handleMenuClose() {
    setAnchorEl(undefined)
  }

  const dataConverter = ({ id, firstName, secondName, email, description }) => ({
    id,
    primaryText: `${firstName} ${secondName}`,
    secondaryText: email,
    tertiaryText: description
  })

  function handleReset() {
    setProfilesFilter([])
    setShopsFilter([])
  }

  const listFiltered = useMemo(() => {
    if (!list) {
      return []
    }

    return list
      .filter((item) =>
        profilesFilter.length ? profilesFilter.map((item) => String(item)).includes(String(item.profileId || '')) : true
      )
      .filter((item) => {
        if (!shopsFilter.length) {
          return true
        }

        return shopsFilter.filter((s) => item.shops.map((item) => String(item.id)).includes(String(s))).length > 0
      })
  }, [list, profilesFilter, shopsFilter])

  const filterBadgeCount = Number(!!profilesFilter.length) + Number(!!shopsFilter.length)

  const filtersIsEmpty = filterBadgeCount === 0

  return (
    <>
      <Bar>
        <div style={{ width: '100%', maxWidth: '640px' }}>
          <SubBar style={{ display: 'flex', gap: '0.5rem', padding: '0.5rem 0' }}>
            <IconButton onClick={handleCreate}>
              <AddIcon />
            </IconButton>
            <SearchInput
              fullWidth
              value={term}
              onChange={handleChangeTerm}
              placeholder={tr.userList.search}
              autoFocus
              onClear={handleClear}
              {...addTestAttr('UserList-Term')}
            />
            <Badge color="secondary" badgeContent={filterBadgeCount} max={100} invisible={filtersIsEmpty}>
              <IconButton size="small" onClick={handleMenuOpen}>
                <FilterIcon />
              </IconButton>
            </Badge>
            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleMenuClose}>
              <FiltersWrapper>
                {!!places.length && places.length > 1 && (
                  <MultipleCheckSelect
                    fullWidth
                    variant="outlined"
                    style={{ marginBottom: 8 }}
                    label={tr.userList.shopsFilter}
                    list={places}
                    value={shopsFilter}
                    valueExtractor={(item) => String(item.id)}
                    onChange={(value: string[]) => setShopsFilter(value)}
                    lableExtractor={(item) => item?.name}
                  />
                )}
                {!!profiles?.length && (
                  <MultipleCheckSelect
                    fullWidth
                    variant="outlined"
                    style={{ marginBottom: 8 }}
                    label={tr.userList.profilesFilter}
                    list={profiles}
                    value={profilesFilter}
                    valueExtractor={(item) => String(item.id)}
                    onChange={(value: string[]) => setProfilesFilter(value)}
                    lableExtractor={(item) => item?.name}
                  />
                )}
                <SearchToggle>
                  <Toggle fullWidth toggled={!hideInactive} label={tr.userList.withInactive} onToggle={handleToggle} />
                </SearchToggle>
                <ClearButtonWrapper>
                  <Button size="small" color="primary" disabled={filtersIsEmpty} onClick={handleReset}>
                    {tr.userList.reset}
                  </Button>
                </ClearButtonWrapper>
              </FiltersWrapper>
            </Menu>
          </SubBar>
        </div>
      </Bar>
      <Container>
        {!list && (
          <CenterContainer>
            <CircularProgress color="secondary" />
          </CenterContainer>
        )}
        {list && (
          <Card>
            <List data={listFiltered} onSelect={handleSelect} dataConverter={dataConverter} />
          </Card>
        )}
      </Container>
    </>
  )
}

export { SmartUserList }
