import { useState } from 'react'
import { Form, FormGroup } from 'shared/ui-kit/form'
import { TextField } from 'shared/ui-kit/text-field'
import Dropdown from 'shared/ui-kit/dropdown'
import { useTranslation } from 'shared/i18n/translate'
import { isPositiveNumber } from 'shared/utils/string-test'
import TimePickerTimestamp from 'features/time/time-picker-timestamp'
import Toggle from 'shared/ui-kit/toggle-wrap'
import Card from 'shared/ui-kit/card'
import { addTestAttr } from 'shared/utils/test-attr'
import { useStateWithValidations } from 'shared/hooks/useStateWithValidations'
import { CapacityObject, SingleCheckpointModel } from 'shared/models'
import { Add as AddIcon, RemoveCircleOutline as RemoveIcon } from '@mui/icons-material'
import { Button, IconButton, Divider } from '@mui/material'
import { InputTranslation } from 'features/input-translation'
import { CheckpointEditProps } from '../types'
import DeleteIcon from '@mui/icons-material/Delete'
import { ActionsWrapper, ConfirmationActions, ActionText, ButtonWithTextWrapper } from 'shared/ui-kit/action-wrapper'

function CheckpointEdit(props: CheckpointEditProps) {
  const [deleteConfirm, setDeleteConfirmField] = useState<boolean>(false)

  const { data, setProperty, validations, setData } = useStateWithValidations<SingleCheckpointModel>(props.data, {
    name: (value) => !!value
  })

  const { tr } = useTranslation()

  function handleTryDelete() {
    setDeleteConfirmField(true)
  }

  function handleCancelDelete() {
    setDeleteConfirmField(false)
  }

  function handleSave() {
    props.onSave(data)
  }

  function handleConfirmDelete() {
    if (!data.id) return
    props.onDelete(data.id)
  }

  function handleChangeCapacities<K extends keyof CapacityObject>(index: number, field: K, val: CapacityObject[K]) {
    setData((curr) => {
      const newData = { ...curr }

      if (!newData.capacities || !newData.capacities[index]) {
        return curr
      }

      newData.capacities[index][field] = val
      return newData
    })
  }

  function handleAddCapacities() {
    setData((curr) => {
      const newData = { ...curr }
      const capacity = { from_unixtime: 0, capacity: '', overbooking: '', stock: '' }

      if (newData.capacities) {
        newData.capacities.push(capacity)
      } else {
        newData.capacities = [capacity]
      }

      return newData
    })
  }

  function handleRemoveCapacities(index: number) {
    setData((curr) => {
      const newData = { ...curr }
      newData.capacities?.splice(index, 1)

      return newData
    })
  }

  return (
    <>
      <Card paperStyle={{ overflow: 'hidden' }}>
        <div>
          <Form>
            <FormGroup>
              <InputTranslation
                inputValue={data.name}
                setInputValue={(val) => setProperty('name', val)}
                brandId={data.brandId}
                placeId={props.placeId}
              >
                <TextField
                  {...addTestAttr('CheckpointEdit-Name')}
                  label={tr.checkpointEdit.name}
                  placeholder={tr.checkpointEdit.namePlaceholder}
                  autofocus={true}
                  value={data.name}
                  onChange={(val) => setProperty('name', val)}
                  attention={!validations.props.name}
                  message={`${data.name ? data.name.length : 0}/20 ${tr.entityEdit.chars}`}
                  test={(v) => v.length < 21}
                />
              </InputTranslation>
            </FormGroup>
            <FormGroup>
              <InputTranslation
                inputValue={data.description || ''}
                setInputValue={(val) => setProperty('description', val)}
                brandId={data.brandId}
                placeId={props.placeId}
              >
                <TextField
                  {...addTestAttr('CheckpointEdit-Description')}
                  label={tr.checkpointEdit.description}
                  placeholder={tr.checkpointEdit.descriptionPlaceholder}
                  value={data.description}
                  onChange={(val) => setProperty('description', val)}
                  message={`${data.description ? data.description.length : 0}/100 ${tr.entityEdit.chars}`}
                  test={(v) => v.length < 101}
                />
              </InputTranslation>
            </FormGroup>
            {!!props.showBeacons && (
              <FormGroup>
                <Dropdown
                  label={tr.checkpointEdit.beacon}
                  value={data.beaconId}
                  data={props.beacons}
                  dataConverter={(item) => ({
                    value: item.id,
                    text: item.name,
                    secondary: `${item.major}/${item.minor}`
                  })}
                  allowClear
                  shrink
                  onChange={(val) => setProperty('beaconId', val)}
                />
              </FormGroup>
            )}
            {props.discreteLine && (
              <FormGroup>
                <Toggle
                  toggled={data.specialCapacity}
                  label={tr.checkpointEdit.specialCapacity}
                  onToggle={() => setProperty('specialCapacity', !data.specialCapacity)}
                />
              </FormGroup>
            )}
          </Form>
          {!!data.specialCapacity && props.discreteLine && (
            <div>
              <Divider />
              <Form title={tr.checkpointEdit.capacities} expandable>
                <FormGroup style={{ marginTop: 0 }}>
                  {data.capacities?.map((c, i) => (
                    <div style={{ display: 'flex', gap: '1rem' }} key={i}>
                      <TimePickerTimestamp
                        label={tr.checkpointEdit.from}
                        style={{ width: '150px' }}
                        value={c.from_unixtime}
                        onChange={(val) => {
                          if (val) {
                            handleChangeCapacities(i, 'from_unixtime', val)
                          }
                        }}
                        offset
                      />
                      <TextField
                        label={tr.checkpointEdit.capacity}
                        style={{ flex: 2 }}
                        placeholder={tr.checkpointEdit.capacity}
                        value={c.capacity}
                        onChange={(val) => handleChangeCapacities(i, 'capacity', val)}
                        test={isPositiveNumber}
                      />
                      <TextField
                        label={tr.checkpointEdit.overbooking}
                        style={{ flex: 2 }}
                        placeholder={tr.checkpointEdit.overbooking}
                        value={c.overbooking}
                        onChange={(val) => handleChangeCapacities(i, 'overbooking', val)}
                        test={isPositiveNumber}
                      />
                      <TextField
                        label={tr.checkpointEdit.stock}
                        style={{ flex: 1 }}
                        placeholder={tr.checkpointEdit.stock}
                        value={c.stock}
                        onChange={(val) => handleChangeCapacities(i, 'stock', val)}
                        test={isPositiveNumber}
                      />
                      <div>
                        <IconButton size="small" onClick={() => handleRemoveCapacities(i)}>
                          <RemoveIcon />
                        </IconButton>
                      </div>
                    </div>
                  ))}
                  <Button
                    style={{ marginTop: '0.5rem' }}
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={handleAddCapacities}
                  >
                    {tr.checkpointEdit.addCapacity}
                  </Button>
                </FormGroup>
              </Form>
              <Divider />
            </div>
          )}
          {!!props.data.id && (
            <>
              <Divider />
              <Form title={tr.entityEdit.advancedGroup} expandable>
                <FormGroup>
                  {!deleteConfirm && (
                    <ButtonWithTextWrapper>
                      <ActionText>{tr.checkpointEdit.deleteTitle}</ActionText>
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={handleTryDelete}
                        startIcon={<DeleteIcon />}
                      >
                        {tr.entityEdit.deleteButton}
                      </Button>
                    </ButtonWithTextWrapper>
                  )}
                  {deleteConfirm && (
                    <ButtonWithTextWrapper>
                      <ActionText>{tr.entityEdit.confirmDelete}</ActionText>
                      <ConfirmationActions>
                        <Button variant="contained" color="error" size="small" onClick={handleConfirmDelete}>
                          {tr.entityEdit.yes}
                        </Button>
                        <Button variant="outlined" size="small" onClick={handleCancelDelete}>
                          {tr.entityEdit.no}
                        </Button>
                      </ConfirmationActions>
                    </ButtonWithTextWrapper>
                  )}
                </FormGroup>
              </Form>
            </>
          )}
        </div>
      </Card>
      <ActionsWrapper>
        <Button
          {...addTestAttr('CheckpointEdit-SaveButton')}
          disabled={!validations.formValid}
          onClick={handleSave}
          variant="contained"
          color="primary"
          size="small"
        >
          {data.id ? tr.entityEdit.updateButton : tr.entityEdit.createButton}
        </Button>
      </ActionsWrapper>
    </>
  )
}

export default CheckpointEdit
