import { CheckpointPositionModel, PositionState } from 'shared/models'
import { ChipListSelect } from 'shared/ui-kit/selects'

const statuses = [
  PositionState.InLine,
  PositionState.ValidForService,
  PositionState.CalledToCheckpoint,
  PositionState.MovingToCheckpoint,
  PositionState.InService
]

type Props = {
  positionStatus?: PositionState
  onChange?: (value: CheckpointPositionModel | null) => void
  list: CheckpointPositionModel[]
  value: CheckpointPositionModel | null
  anyLabel?: string
  strict?: boolean
  isDisabledItem?: (item: CheckpointPositionModel) => boolean
  label?: string
  attention?: boolean
}

function CheckpointSelect(props: Props) {
  const status = statuses.find((s) => s === props.positionStatus)
  const anyItem: CheckpointPositionModel = { id: null, name: props.anyLabel || 'Any' } as any

  function handleOnChange(value: CheckpointPositionModel | null | undefined) {
    props.onChange?.(value?.id ? value : null)
  }

  if (!props.list?.length) {
    return null
  }

  const hasAnyValue = !props.strict && (status === PositionState.InLine || !status)
  const list = hasAnyValue ? [anyItem, ...props.list] : props.list
  const value = hasAnyValue ? props.value || anyItem : props.value

  return (
    <ChipListSelect
      attention={props.attention}
      ensureValue={!props.strict}
      label={props.label}
      value={value}
      list={list}
      isDisabled={props.isDisabledItem}
      compare={(valueItem, listItem) => valueItem?.id === listItem.id}
      labelExtractor={(item) => <div style={{ fontSize: '16px' }}>{item && item.name}</div>}
      onChange={handleOnChange}
    />
  )
}

export default CheckpointSelect
