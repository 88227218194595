import { useEffect, useState } from 'react'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { useTranslation } from 'shared/i18n/translate'
import Card from 'shared/ui-kit/card'
import Container from 'shared/ui-kit/container'
import Divider from '@mui/material/Divider'
import * as Nav from 'pages/nav'
import { appointmentPlaceList } from 'shared/api'
import PageLoader from 'shared/ui-kit/page-loader'
import { useHistory, useLocation } from 'react-router'
import { PlaceListElementModel } from 'shared/models'
import {
  ListAddress,
  ListDescriptionContent,
  ListItem,
  ListLine,
  ListLineDescription,
  ListLineName,
  ListName,
  ListTitle,
  NoAvailablePlace
} from './styled'
import { CenterContainer } from 'shared/ui-kit/center-container'
import { useAppDispatch } from 'store'

function AppointmentPlaceList() {
  const [data, setData] = useState<PlaceListElementModel[]>([])
  const [loading, setLoading] = useState(false)
  const { tr, locale, dateFormatter } = useTranslation()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    setLoading(true)

    appointmentPlaceList
      .getAppointmentPlaceList()
      .then((res) => {
        setData(res)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    const crumb = getCrumbs(tr.breadcrumbs)

    dispatch(setBreadcrumbs([crumb.home(), crumb.appointmentPlaceList()]))
  }, [locale])

  function onCreateAppointment(placeId, lineId) {
    const queryParams = new URLSearchParams(location.search)

    const positionId = queryParams.get('positionId')
    const originalPlaceId = queryParams.get('placeId')
    const originalLineId = queryParams.get('lineId')

    const search = new URLSearchParams()

    if (positionId) {
      search.set('positionId', positionId)
    }

    if (originalPlaceId) {
      search.set('originalPlaceId', originalPlaceId)
    }

    if (originalLineId) {
      search.set('originalLineId', originalLineId)
    }

    history.push(`${Nav.createAppointmentLine(placeId, lineId)}/?${search}`)
  }

  if (loading) {
    return (
      <CenterContainer>
        <PageLoader />
      </CenterContainer>
    )
  }

  return (
    <Container>
      {data.length === 0 && <NoAvailablePlace>{tr.appointmentPlaceList.noAvailablePlace}</NoAvailablePlace>}
      {data.map((place, index) => (
        <Card key={index} style={{ marginBottom: '2rem' }}>
          <ListTitle>
            <ListName>{place.name}</ListName>
            <ListAddress>{place.address}</ListAddress>
          </ListTitle>
          <div>
            {place.lines.map((line, index) => (
              <ListItem
                key={index}
                onClick={() => onCreateAppointment(place.id, line.id)}
                style={{ pointerEvents: line.nearestDate ? undefined : 'none' }}
              >
                <Divider />
                <ListLine>
                  <ListLineName>{line.name}</ListLineName>
                  <ListLineDescription>
                    {line.nearestDate && (
                      <ListDescriptionContent style={{ width: '6rem' }}>
                        {dateFormatter(new Date(line.nearestDate), 'dateTime')}
                      </ListDescriptionContent>
                    )}
                    {!line.nearestDate && (
                      <ListDescriptionContent>{tr.appointmentPlaceList.noAvailableTime}</ListDescriptionContent>
                    )}
                  </ListLineDescription>
                </ListLine>
              </ListItem>
            ))}
          </div>
        </Card>
      ))}
    </Container>
  )
}

export default AppointmentPlaceList
