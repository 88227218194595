import { CheckpointsStatistics, CheckpointStatus, PositionState, PositionType } from 'shared/models'
import { isProblemCheckpointPosition } from './helpers'
import { Translations } from 'shared/i18n/translation-types'

const CHECKPOINT_STATISTIC_FILTER = {
  CALLED: 'called',
  IN_SERVICE: 'inService',
  FREE: 'free',
  CLOSED: 'closed',
  PAUSED: 'paused',
  PROBLEMS: 'problems'
}

const checkpointStatisticFilters = [
  CHECKPOINT_STATISTIC_FILTER.CALLED,
  CHECKPOINT_STATISTIC_FILTER.IN_SERVICE,
  CHECKPOINT_STATISTIC_FILTER.FREE,
  CHECKPOINT_STATISTIC_FILTER.CLOSED,
  CHECKPOINT_STATISTIC_FILTER.PAUSED,
  CHECKPOINT_STATISTIC_FILTER.PROBLEMS
]

const isCSCalled = (checkpointStatistic: CheckpointsStatistics) => {
  return checkpointStatistic.position && checkpointStatistic.position.state === PositionState.CalledToCheckpoint
}

const isCSPaused = (checkpointStatistic: CheckpointsStatistics) => {
  return checkpointStatistic.position && checkpointStatistic.position.type === PositionType.Break
}

const isCSInService = (checkpointStatistic: CheckpointsStatistics) => {
  return (
    !isCSPaused(checkpointStatistic) &&
    checkpointStatistic.position &&
    checkpointStatistic.position.state === PositionState.InService
  )
}

const isCSFree = (checkpointStatistic: CheckpointsStatistics) => {
  return (
    (!checkpointStatistic.position ||
      (checkpointStatistic.position && checkpointStatistic.position.state === PositionState.Joined)) &&
    !isCSClosed(checkpointStatistic)
  )
}

const isCSClosed = (checkpointStatistic: CheckpointsStatistics) => {
  return checkpointStatistic.checkpoint.status === CheckpointStatus.finished
}

const checkpointStatisticFilterAction = {
  [CHECKPOINT_STATISTIC_FILTER.CALLED]: (checkpointStatistic) => isCSCalled(checkpointStatistic),
  [CHECKPOINT_STATISTIC_FILTER.IN_SERVICE]: (checkpointStatistic) => isCSInService(checkpointStatistic),
  [CHECKPOINT_STATISTIC_FILTER.FREE]: (checkpointStatistic) => isCSFree(checkpointStatistic),
  [CHECKPOINT_STATISTIC_FILTER.CLOSED]: (checkpointStatistic) => isCSClosed(checkpointStatistic),
  [CHECKPOINT_STATISTIC_FILTER.PAUSED]: (checkpointStatistic) => isCSPaused(checkpointStatistic),
  [CHECKPOINT_STATISTIC_FILTER.PROBLEMS]: (checkpointStatistic) =>
    !isCSClosed(checkpointStatistic) &&
    isProblemCheckpointPosition(checkpointStatistic.position, checkpointStatistic.checkpoint)
}

function filterCheckpointsStatisticsByTerm(term: string, checkpointsStatistics: CheckpointsStatistics[]) {
  if (!term) {
    return checkpointsStatistics
  }

  const filtered = checkpointsStatistics.filter((item) => {
    const { checkpoint, position } = item

    const stringToSearch = [
      checkpoint.name,
      checkpoint.hostName,
      position &&
        position.state !== PositionState.Joined &&
        position.customer &&
        `${position.customer.firstName} ${position.customer.lastName}`
    ]
      .filter(Boolean)
      .join(' ')
      .toLowerCase()

    if (stringToSearch.includes(term.toLowerCase())) {
      return true
    }

    return false
  })

  return filtered
}

function filterLabelExtractor(tr: Translations) {
  return (val: string) => {
    const checkpointStatisticFilterTranslation = {
      [CHECKPOINT_STATISTIC_FILTER.CALLED]: tr.lineMonitoringStatistics.called,
      [CHECKPOINT_STATISTIC_FILTER.IN_SERVICE]: tr.lineMonitoringStatistics.inService,
      [CHECKPOINT_STATISTIC_FILTER.FREE]: tr.lineMonitoringStatistics.available,
      [CHECKPOINT_STATISTIC_FILTER.CLOSED]: tr.lineMonitoringStatistics.closed,
      [CHECKPOINT_STATISTIC_FILTER.PAUSED]: tr.lineMonitoringStatistics.serviceInPause,
      [CHECKPOINT_STATISTIC_FILTER.PROBLEMS]: tr.lineMonitoringStatistics.withIssues
    }

    return checkpointStatisticFilterTranslation[val] || ''
  }
}

export {
  checkpointStatisticFilters,
  checkpointStatisticFilterAction,
  CHECKPOINT_STATISTIC_FILTER,
  filterCheckpointsStatisticsByTerm,
  filterLabelExtractor
}
