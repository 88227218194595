import { useState } from 'react'
import { Button, TextField, Typography, IconButton, DialogTitle, CircularProgress } from '@mui/material'
import css from './suggestion-dialog.module.scss'
import {
  Close as CloseIcon,
  TimerOutlined as TimerOutlinedIcon,
  BeenhereOutlined as BeenhereOutlinedIcon,
  HighlightOffOutlined as HighlightOffOutlinedIcon
} from '@mui/icons-material'
import { getNameOrNumber } from 'features/position-dumb/helpers'
import { monitoring } from 'shared/api'
import { PositionState } from 'shared/models'
import { MessageTypes, showMessage } from 'store/actions/main-layout-action-creators'
import { withMods } from 'shared/utils/cssm'
import { useTranslation } from 'shared/i18n/translate'
import * as SuggestionTimer from './suggestion-timer'
import { useAppDispatch } from 'store'

const mod = withMods(css)

function RemoveForm(props) {
  const { tr } = useTranslation()
  const [comment, setComment] = useState('')

  function handleChange(ev) {
    setComment(ev.target.value)
  }

  function handleSubmit() {
    props.onRemove(comment)
  }

  return (
    <div>
      <TextField
        multiline
        value={comment}
        InputLabelProps={{ shrink: true }}
        style={{ marginBottom: 12 }}
        fullWidth
        placeholder={tr.positionActions.removeForm.removeReason}
        onChange={handleChange}
        disabled={Boolean(props.requestTrigger)}
        maxRows={15}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={Boolean(!comment?.length || props.requestTrigger)}
      >
        {tr.positionActions.removeForm.remove}
      </Button>
      <Button onClick={props.onCancel} style={{ marginLeft: 8 }} disabled={Boolean(props.requestTrigger)}>
        {tr.positionActions.removeForm.declineRemove}
      </Button>
    </div>
  )
}

function Actions(props) {
  const { tr } = useTranslation()

  function renderIcon(Icon, color, trigger) {
    return (
      <div className={css.icon}>
        {props.requestTrigger === trigger ? (
          <CircularProgress size={20} style={{ padding: 2, color: 'rgba(0,0,0,.36)' }} thickness={4.1} />
        ) : (
          <Icon style={{ color: props.requestTrigger ? 'rgba(0,0,0,.36)' : color }} />
        )}
      </div>
    )
  }

  return (
    <>
      <div style={{ margin: '24px 24px 8px', opacity: 0.48 }}>{tr.checkpointMonitoringSuggestions.whatToDo}</div>
      <div style={{ margin: '0 0 16px' }}>
        <div className={mod.item({ disabled: Boolean(props.requestTrigger) })} onClick={props.onWait}>
          {renderIcon(TimerOutlinedIcon, 'hsl(43deg 100% 49%)', 'wait')}
          <div>{tr.checkpointMonitoringSuggestions.wait(tr.time.hmin(Math.floor(props.timeToReach / 60)))}</div>
        </div>
        <div className={mod.item({ disabled: Boolean(props.requestTrigger) })} onClick={props.onStart}>
          {renderIcon(BeenhereOutlinedIcon, 'hsl(152deg 60% 49%)', 'start')}
          <div>{tr.checkpointMonitoringSuggestions.start}</div>
        </div>
        <div className={mod.item({ disabled: Boolean(props.requestTrigger) })} onClick={props.onRemove}>
          {renderIcon(HighlightOffOutlinedIcon, '#db214d', 'remove')}
          <div>{tr.checkpointMonitoringSuggestions.remove}</div>
        </div>
      </div>
    </>
  )
}

function SuggestionDialogContent(props) {
  const { tr } = useTranslation()
  const dispatch = useAppDispatch()

  const [requestTrigger, setRequestTrigger] = useState<'remove' | 'start' | null>(null)
  const [showRemoveForm, setShowRemoveForm] = useState(false)

  function toggleRemoveForm() {
    setShowRemoveForm(!showRemoveForm)
  }

  function handleWait() {
    SuggestionTimer.putOffPosition(props.data, props.timeToReach)
    props.onWait()
  }

  function handleStart() {
    setRequestTrigger('start')

    monitoring
      .changePositionState(getData(PositionState.ServiceStarted))
      .then(() => {
        props.onStart()
      })
      .catch(() => {
        dispatch(showMessage(tr.serverResponseMessage.error, MessageTypes.Error))
        setRequestTrigger(null)
      })
  }

  function callRemove(comment?: string) {
    setRequestTrigger('remove')

    monitoring
      .changePositionState(getData(PositionState.Removed, comment))
      .then(() => {
        props.onRemove()
      })
      .catch(() => {
        dispatch(showMessage(tr.serverResponseMessage.error, MessageTypes.Error))
        setRequestTrigger(null)
      })
  }

  function handleRemove() {
    if (props.requestCommentsOnRemove) {
      toggleRemoveForm()
    } else {
      callRemove()
    }
  }

  const getData = (newState: PositionState, comment: string = '') => ({
    newState,
    positionId: props.data.id,
    shopId: props.shopId,
    lineId: props.lineId,
    checkpointId: props.checkpointId,
    survey: null,
    comment,
    referer: 'PopupDialog'
  })

  return (
    <>
      <DialogTitle style={{ display: 'flex', alignItems: 'start ' }}>
        <Typography variant="h6">{tr.checkpointMonitoringSuggestions.title}</Typography>
        {Boolean(props.onClose) && (
          <IconButton
            aria-label="close"
            onClick={props.onClose}
            style={{ margin: '-8px -16px 0 8px', color: 'rgba(0, 0, 0, .36)' }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <div style={{ margin: '8px 24px 0px', opacity: 1 }}>{getNameOrNumber(props.data, tr)}</div>
      <div style={{ margin: '4px 24px 0px', opacity: 0.48, fontSize: 14 }}>
        {tr.checkpointMonitoringSuggestions.timeFromCall(tr.time.hmin(Math.floor(props.data.timeFromCall / 60000)))}
      </div>
      {showRemoveForm ? (
        <div style={{ margin: 24 }}>
          <RemoveForm requestTrigger={requestTrigger} onRemove={callRemove} onCancel={toggleRemoveForm} />
        </div>
      ) : (
        <Actions
          timeToReach={props.timeToReach}
          requestTrigger={requestTrigger}
          onWait={handleWait}
          onStart={handleStart}
          onRemove={handleRemove}
        />
      )}
    </>
  )
}

export { SuggestionDialogContent }
