import { useEffect, useState } from 'react'
import { Form, FormGroup, SubGroup } from 'shared/ui-kit/form'
import { TextField } from 'shared/ui-kit/text-field'
import { useTranslation } from 'shared/i18n/translate'
import { AddList } from 'shared/ui-kit/add-list'
import Toggle from 'shared/ui-kit/toggle-wrap'
import { isPositiveNumber, isPositiveInteger, isPositiveNullInteger } from 'shared/utils/string-test'
import TimePickerTimestamp from 'features/time/time-picker-timestamp'
import { RadioButtonGroup } from 'shared/ui-kit/radio-button'
import Text from 'shared/ui-kit/text'
import { Radio, Button, Divider, IconButton, FormControlLabel } from '@mui/material'
import Card from 'shared/ui-kit/card'
import { addTestAttr } from 'shared/utils/test-attr'
import { RemoveCircleOutline as RemoveIcon } from '@mui/icons-material'
import { LineModelSimplified, TerminalModelEdit, TerminalType } from 'shared/models'
import { CallMode } from 'shared/models'
import Dropdown from 'shared/ui-kit/dropdown'
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChipListSelect } from 'shared/ui-kit/selects'
import { ControlLabel } from 'shared/ui-kit/control-label'
import FileUploader from 'features/file-uploader'
import { TerminalEditProps } from '../types'
import { terminalEditValidationSchema } from '../validation'
import { ActionText, ActionsWrapper, ButtonWithTextWrapper, ConfirmationActions } from 'shared/ui-kit/action-wrapper'
import DeleteIcon from '@mui/icons-material/Delete'
import RestartIcon from '@mui/icons-material/RestartAlt'
import { terminal } from 'shared/api'
import { useAppDispatch } from 'store'
import { MessageTypes, showMessage } from 'store/actions/main-layout-action-creators'
import { availableLanguages } from './available-languages'

function TerminalEdit(props: TerminalEditProps) {
  const [isConfirmDelete, setConfirmDelete] = useState(false)

  const { tr } = useTranslation()
  const dispatch = useAppDispatch()

  const { control, handleSubmit, formState, watch, trigger, setValue } = useForm<TerminalModelEdit>({
    resolver: yupResolver(terminalEditValidationSchema as any),
    defaultValues: props.data,
    mode: 'onChange'
  })

  useEffect(() => {
    trigger()
  }, [])

  function handleTryDelete() {
    setConfirmDelete(true)
  }

  function handleCancelDelete() {
    setConfirmDelete(false)
  }

  function handleConfirmDelete() {
    props.onDelete(props.data.id || '')
  }

  function getPlaceByLineId(lineId: string | number) {
    const shopIds = Object.keys(props.lines) || []
    const shopId = shopIds.find((sid) => props.lines[sid] && props.lines[sid].find((line) => line.id === lineId))

    return props.shops.find((shop) => String(shop.id) === String(shopId))
  }

  function getLinesByShopId(shopId: string | number) {
    if (!shopId || !props.shops.find((x) => String(x.id) === String(shopId))) {
      return []
    }

    const lines = props.lines[shopId]

    if (!lines) {
      props.onRequestLines(shopId)
    }

    return lines || []
  }

  function getLines(shopIds: (string | number)[] = []) {
    let lines: LineModelSimplified[] = []

    shopIds.forEach((sid) => {
      lines = lines.concat(getLinesByShopId(sid) || [])
    })

    return lines
  }

  function handleDisplayPositions(f: ControllerRenderProps<TerminalModelEdit, 'displayPositions'>) {
    return (_, val: boolean) => {
      f.onChange(val)

      if (!watch('callMode')) {
        setValue('callMode', CallMode.Bell)
      }
      if (!watch('repeatAlarm')) {
        setValue('repeatAlarm', 0)
      }
      if (!watch('repeatAlarmSec')) {
        setValue('repeatAlarmSec', 60)
      }
      if (!watch('waitingPositionsCount')) {
        setValue('waitingPositionsCount', 0)
      }
      if (!watch('waitingTimeFilter')) {
        setValue('waitingTimeFilter', 60)
      }

      trigger()
    }
  }

  async function handleRestart() {
    if (!props.data.id) {
      return
    }

    try {
      await terminal.reloadTerminal(props.data.id)
      dispatch(showMessage(tr.terminalEdit.restartSuccessMessage, MessageTypes.Success))
    } catch (e) {
      console.log(e)
    }
  }

  function handleShopIdsChange(val: (string | number)[]) {
    const shopIds = watch('shopIds')
    const lineIds = watch('lineIds')

    const currentShopsIds = shopIds || []
    const newShopsIds = val
    const lostIds = currentShopsIds.filter((cid) => !newShopsIds.includes(cid))

    const allLinesIdsLostShops = lostIds
      .map((lid) => props.lines[lid])
      .reduce((a, b) => a.concat(b), [])
      .map((el) => el.id)

    const newLinesIds = (lineIds || []).filter((cli) => !allLinesIdsLostShops.includes(cli))

    setValue('shopIds', val)
    setValue('lineIds', newLinesIds)
    trigger()
  }

  const isValid = !Object.keys(formState.errors).length

  return (
    <form onSubmit={handleSubmit(props.onSave)}>
      <Card>
        {props.disableChangeType === true && (
          <FormGroup>
            <Text type="caption">{tr.terminalEdit.type}</Text>
            <Text type="body-1">
              {watch('type') === TerminalType.Kiosk && tr.terminalView.kiosk}
              {watch('type') === TerminalType.InfoPad && tr.terminalView.infopad}
              {watch('type') === TerminalType.WebKiosk && tr.terminalView.webKiosk}
              {watch('type') === TerminalType.AcceptationScreen && tr.terminalView.acceptationScreen}
            </Text>
          </FormGroup>
        )}
        {props.disableChangeType !== true && (
          <FormGroup>
            <Controller
              name="type"
              control={control}
              render={({ field: f }) => (
                <RadioButtonGroup
                  label={tr.terminalEdit.type}
                  name="type"
                  onChange={(val) => {
                    f.onChange(val)
                    setValue('displayPositions', val === TerminalType.InfoPad)
                    trigger()
                  }}
                  value={f.value}
                  ensureValue={TerminalType.InfoPad}
                  row
                  {...addTestAttr('TerminalEdit-Type')}
                >
                  <FormControlLabel
                    value={TerminalType.InfoPad}
                    control={<Radio size="small" id="radioButtonInfopad" />}
                    label={tr.terminalEdit.infopad}
                  />
                  <FormControlLabel
                    value={TerminalType.Kiosk}
                    control={<Radio size="small" id="radioButtonKiosk" />}
                    label={tr.terminalEdit.kiosk}
                  />
                  <FormControlLabel
                    value={TerminalType.WebKiosk}
                    control={<Radio size="small" id="radioButtonWebKiosk" />}
                    label={tr.terminalEdit.webKiosk}
                  />
                  <FormControlLabel
                    value={TerminalType.AcceptationScreen}
                    control={<Radio size="small" id="radioButtonWebKiosk" />}
                    label={tr.terminalEdit.acceptationScreen}
                  />
                </RadioButtonGroup>
              )}
            />
          </FormGroup>
        )}
        <FormGroup>
          <Controller
            name="name"
            control={control}
            render={({ field: f, formState }) => (
              <TextField
                label={tr.terminalEdit.name}
                placeholder={tr.terminalEdit.namePlaceholder}
                autofocus={true}
                value={f.value}
                onChange={f.onChange}
                attention={!!formState.errors.name}
                message={`${f.value?.length || 0}/100 ${tr.entityEdit.chars}`}
                test={(v) => v.length < 101}
                {...addTestAttr('TerminalEdit-Name')}
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="shortName"
            control={control}
            render={({ field: f }) => (
              <TextField
                label={tr.terminalEdit.shortName}
                placeholder={tr.terminalEdit.shortNamePlaceholder}
                value={f.value}
                onChange={f.onChange}
                test={(v) => v.length < 21}
                {...addTestAttr('TerminalEdit-ShortName')}
              />
            )}
          />
        </FormGroup>
        {watch('type') === TerminalType.Kiosk && (
          <FormGroup>
            <Controller
              name="displayName"
              control={control}
              render={({ field: f, formState }) => (
                <TextField
                  label={tr.terminalEdit.displayName}
                  placeholder={tr.terminalEdit.displayNamePlaceholder}
                  value={f.value}
                  onChange={f.onChange}
                  attention={!!formState.errors.displayName}
                  message={`${f.value?.length || 0}/100 ${tr.entityEdit.chars}`}
                  test={(v) => v.length < 101}
                  {...addTestAttr('TerminalEdit-DisplayName')}
                />
              )}
            />
          </FormGroup>
        )}
        {watch('type') === TerminalType.InfoPad && (
          <>
            <FormGroup>
              <Controller
                name="zoomFactor"
                control={control}
                defaultValue={1}
                render={({ field: f, formState }) => (
                  <TextField
                    label={tr.terminalEdit.zoomFactor}
                    placeholder={tr.terminalEdit.zoomFactor}
                    value={f.value}
                    onChange={f.onChange}
                    attention={!!formState.errors.zoomFactor}
                    test={isPositiveNumber}
                    {...addTestAttr('TerminalEdit-ZoomFactor')}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="forcedRefreshTime_unixtime"
                control={control}
                render={({ field: f }) => (
                  <TimePickerTimestamp
                    style={{ width: '100%' }}
                    label={tr.terminalEdit.forcedRefreshInHours}
                    value={f.value}
                    onChange={(val: any) => f.onChange(isNaN(val) ? undefined : val || undefined)}
                    offset
                    {...addTestAttr('TerminalEdit-ForcedRefreshTime')}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="socket"
                control={control}
                render={({ field: f }) => (
                  <Toggle
                    toggled={f.value}
                    label={tr.terminalEdit.socket}
                    onToggle={(_, val) => f.onChange(val)}
                    {...addTestAttr('TerminalEdit-Socket')}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="displayAds"
                control={control}
                defaultValue={true}
                render={({ field: f }) => (
                  <Toggle
                    toggled={f.value}
                    label={tr.terminalEdit.displayAds}
                    onToggle={(_, val) => f.onChange(val)}
                    {...addTestAttr('TerminalEdit-DisplayAds')}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="displayPositions"
                control={control}
                defaultValue={true}
                render={({ field: f }) => (
                  <Toggle
                    toggled={f.value}
                    label={tr.terminalEdit.displayPositions}
                    onToggle={handleDisplayPositions(f)}
                    {...addTestAttr('TerminalEdit-DisplayPositions')}
                  />
                )}
              />
            </FormGroup>
            {!!watch('displayPositions') && (
              <SubGroup>
                <FormGroup>
                  <Controller
                    name="callMode"
                    control={control}
                    render={({ field: f }) => (
                      <Dropdown
                        ensureValue
                        label={tr.terminalEdit.callMode}
                        value={f.value}
                        data={Object.values(CallMode)}
                        dataConverter={(item) => ({ value: item, text: tr.terminalEdit.callModes[item] })}
                        onChange={f.onChange}
                        {...addTestAttr('TerminalEdit-CallMode')}
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup>
                  <Controller
                    name="repeatAlarm"
                    control={control}
                    render={({ field: f, formState }) => (
                      <TextField
                        label={tr.terminalEdit.repeatAlarm}
                        placeholder={tr.terminalEdit.repeatAlarm}
                        value={f.value}
                        onChange={f.onChange}
                        attention={!!formState.errors.repeatAlarm}
                        test={(val) => val === '0' || isPositiveInteger(val)}
                        {...addTestAttr('TerminalEdit-RepeatAlarm')}
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup>
                  <Controller
                    name="repeatAlarmSec"
                    control={control}
                    render={({ field: f, formState }) => (
                      <TextField
                        label={tr.terminalEdit.repeatAlarmSec}
                        placeholder={tr.terminalEdit.repeatAlarmSec}
                        value={f.value}
                        onChange={f.onChange}
                        attention={!!formState.errors.repeatAlarmSec}
                        test={isPositiveInteger}
                        hint={tr.terminalEdit.secondsHint}
                        {...addTestAttr('TerminalEdit-RepeatAlarmSec')}
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup>
                  <Controller
                    name="waitingPositionsCount"
                    control={control}
                    render={({ field: f, formState }) => (
                      <TextField
                        label={tr.terminalEdit.waitingPositionsCount}
                        placeholder={tr.terminalEdit.waitingPositionsCount}
                        value={f.value}
                        onChange={f.onChange}
                        attention={!!formState.errors.waitingPositionsCount}
                        test={isPositiveNullInteger}
                        {...addTestAttr('TerminalEdit-WaitingPositionsCount')}
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup>
                  <Controller
                    name="waitingTimeFilter"
                    control={control}
                    render={({ field: f, formState }) => (
                      <TextField
                        label={tr.terminalEdit.waitingTimeFilter}
                        placeholder={tr.terminalEdit.waitingTimeFilter}
                        value={f.value}
                        onChange={f.onChange}
                        attention={!!formState.errors.waitingTimeFilter}
                        test={isPositiveInteger}
                        hint={tr.terminalEdit.secondsHint}
                        {...addTestAttr('TerminalEdit-WaitingTimeFilter')}
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup>
                  <Controller
                    name="displayFullName"
                    control={control}
                    render={({ field: f }) => (
                      <Toggle
                        toggled={f.value}
                        label={tr.terminalEdit.displayFullName}
                        onToggle={(_, val) => f.onChange(val)}
                        {...addTestAttr('TerminalEdit-DisplayFullName')}
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup>
                  <Controller
                    name="displayReservationCodeOnLastName"
                    control={control}
                    render={({ field: f }) => (
                      <Toggle
                        toggled={f.value}
                        label={tr.terminalEdit.displayReservationCodeOnLastName}
                        onToggle={(_, val) => f.onChange(val)}
                        {...addTestAttr('TerminalEdit-DisplayReservationCodeOnLastName')}
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup>
                  <Controller
                    name="combineAllPositions"
                    control={control}
                    render={({ field: f }) => (
                      <Toggle
                        label={tr.terminalEdit.combinePositions}
                        toggled={f.value}
                        onToggle={(_, val) => f.onChange(val)}
                        {...addTestAttr('TerminalEdit-CombineAllPositions')}
                      />
                    )}
                  />
                </FormGroup>
                {watch('combineAllPositions') && (
                  <FormGroup>
                    <Controller
                      name="combineGroupName"
                      control={control}
                      render={({ field: f }) => (
                        <TextField
                          label={tr.terminalEdit.combinedGroupName}
                          placeholder={tr.terminalEdit.combinedGroupName}
                          value={f.value}
                          onChange={f.onChange}
                          {...addTestAttr('TerminalEdit-CombineGroupName')}
                        />
                      )}
                    />
                  </FormGroup>
                )}
              </SubGroup>
            )}
          </>
        )}
        {[TerminalType.Kiosk, TerminalType.WebKiosk].includes(watch('type')) && (
          <FormGroup>
            <Controller
              name="reservationsStartHour_unixtime"
              control={control}
              render={({ field: f }) => (
                <TimePickerTimestamp
                  style={{ width: '100%' }}
                  label={tr.terminalEdit.reservationsStartHour}
                  value={f.value}
                  onChange={(val: any) => f.onChange(isNaN(val) ? undefined : val || undefined)}
                  offset
                  {...addTestAttr('TerminalEdit-ReservationsStartHour')}
                />
              )}
            />
          </FormGroup>
        )}
        {watch('type') === TerminalType.Kiosk && (
          <>
            <Divider />
            <Form title={tr.terminalEdit.hardware} expandable>
              <FormGroup>
                <Controller
                  name="hasThermalPrinter"
                  control={control}
                  render={({ field: f }) => (
                    <Toggle
                      toggled={f.value}
                      label={tr.terminalEdit.thermalPrinter}
                      onToggle={(_, val) => f.onChange(val)}
                      {...addTestAttr('TerminalEdit-HasThermalPrinter')}
                    />
                  )}
                />
              </FormGroup>
              {watch('hasThermalPrinter') && (
                <FormGroup>
                  <Controller
                    name="maxTickets"
                    control={control}
                    render={({ field: f }) => (
                      <TextField
                        label={tr.terminalEdit.maxTickets}
                        placeholder={tr.terminalEdit.maxTicketsPlaceholder}
                        value={f.value}
                        onChange={f.onChange}
                        test={isPositiveNumber}
                        {...addTestAttr('TerminalEdit-MaxTickets')}
                      />
                    )}
                  />
                </FormGroup>
              )}
              {watch('hasThermalPrinter') && (
                <FormGroup>
                  <Controller
                    name="minTicketsLevelNotification"
                    control={control}
                    render={({ field: f }) => (
                      <TextField
                        label={tr.terminalEdit.minTicketsLevelNotification}
                        placeholder={tr.terminalEdit.minTicketsLevelNotificationPlaceholder}
                        value={f.value}
                        onChange={f.onChange}
                        test={isPositiveNumber}
                        {...addTestAttr('TerminalEdit-MinTicketsLevelNotification')}
                      />
                    )}
                  />
                </FormGroup>
              )}
            </Form>
          </>
        )}
        {watch('type') === TerminalType.WebKiosk && (
          <>
            <FormGroup>
              <Controller
                name="canDeclarePresent"
                control={control}
                render={({ field: f }) => (
                  <Toggle
                    toggled={f.value}
                    label={tr.terminalEdit.canDeclarePresent}
                    onToggle={(_, val) => f.onChange(val)}
                    {...addTestAttr('TerminalEdit-CanDeclarePresent')}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="displayLineStateAndCallInfo"
                control={control}
                render={({ field: f }) => (
                  <Toggle
                    toggled={f.value}
                    label={tr.terminalEdit.displayLineStateAndCallInfo}
                    onToggle={(_, val) => f.onChange(val)}
                    {...addTestAttr('TerminalEdit-DisplayLineStateAndCallInfo')}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="displayWelcomeScreen"
                control={control}
                render={({ field: f }) => (
                  <Toggle
                    toggled={f.value}
                    label={tr.terminalEdit.displayWelcomeScreen}
                    onToggle={(_, val) => f.onChange(val)}
                    {...addTestAttr('TerminalEdit-DisplayWelcomeScreen')}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="showGeneralUseConditions"
                control={control}
                render={({ field: f }) => (
                  <Toggle
                    toggled={f.value}
                    label={tr.terminalEdit.showGeneralUseConditions}
                    onToggle={(_, val) => f.onChange(val)}
                    {...addTestAttr('TerminalEdit-ShowGeneralUseConditions')}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="isLastNameRequired"
                control={control}
                render={({ field: f }) => (
                  <Toggle
                    toggled={f.value}
                    label={tr.terminalEdit.isLastNameRequired}
                    onToggle={(_, val) => f.onChange(val)}
                    {...addTestAttr('TerminalEdit-IsLastNameRequired')}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="isPhoneNumberRequired"
                control={control}
                render={({ field: f }) => (
                  <Toggle
                    toggled={f.value}
                    label={tr.terminalEdit.isPhoneNumberRequired}
                    onToggle={(_, val) => f.onChange(val)}
                    {...addTestAttr('TerminalEdit-IsPhoneNumberRequired')}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="onlyAsap"
                control={control}
                render={({ field: f }) => (
                  <Toggle
                    toggled={f.value}
                    label={tr.terminalEdit.onlyAsap}
                    onToggle={(_, val) => f.onChange(val)}
                    {...addTestAttr('TerminalEdit-OnlyAsap')}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="minimizePinCode"
                control={control}
                defaultValue={''}
                render={({ field: f }) => (
                  <TextField
                    label={tr.terminalEdit.minimizePinCode}
                    placeholder={tr.terminalEdit.minimizePinCode}
                    value={f.value}
                    onChange={f.onChange}
                    test={isPositiveNumber}
                    {...addTestAttr('TerminalEdit-MinimizePinCode')}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="qrCodeLink"
                control={control}
                defaultValue={''}
                render={({ field: f }) => (
                  <TextField
                    label={tr.terminalEdit.qrCodeLink}
                    placeholder={tr.terminalEdit.qrCodeLink}
                    value={f.value}
                    onChange={f.onChange}
                    {...addTestAttr('TerminalEdit-QrCodeLink')}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="logoFile"
                control={control}
                render={({ field: f }) => (
                  <FileUploader id="image" label={tr.terminalEdit.logo} file={f.value} onChange={f.onChange} />
                )}
              />
            </FormGroup>
          </>
        )}
        {[TerminalType.WebKiosk, TerminalType.AcceptationScreen].includes(watch('type')) && (
          <>
            <FormGroup>
              <Controller
                name="themeName"
                control={control}
                render={({ field: f }) => (
                  <TextField
                    label={tr.terminalEdit.themeName}
                    placeholder={tr.terminalEdit.themeName}
                    value={f.value}
                    onChange={f.onChange}
                    {...addTestAttr('TerminalEdit-ThemeName')}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="languages"
                control={control}
                render={({ field: f }) => (
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                    <ControlLabel>{tr.terminalEdit.languages}</ControlLabel>
                    <ChipListSelect
                      multiple
                      list={
                        watch('type') === TerminalType.WebKiosk
                          ? availableLanguages
                          : availableLanguages.filter((el) => ['en', 'fr'].includes(el.locale))
                      }
                      value={(f.value || '')?.split(';')}
                      onChange={(val) => f.onChange(val.filter(Boolean).join(';'))}
                      compare={(valueItem, listItem) => valueItem === listItem.locale}
                      dataConverter={(item) => item.locale}
                      labelExtractor={(item) => (item ? item.name : '')}
                      {...addTestAttr('TerminalEdit-Languages')}
                    />
                  </div>
                )}
              />
            </FormGroup>
          </>
        )}
        {watch('type') === TerminalType.WebKiosk && (
          <>
            <FormGroup>
              <Controller
                name="layoutServices"
                control={control}
                render={({ field: f }) => (
                  <Dropdown
                    ensureValue
                    style={{ width: '100%' }}
                    label={tr.terminalEdit.layoutServices}
                    value={f.value}
                    data={['defaultValue', 'viniValue', 'airTahitiPresentation']}
                    dataConverter={(item) => ({ value: item, text: tr.terminalEdit.layoutServicesMode[item] })}
                    onChange={f.onChange}
                    {...addTestAttr('TerminalEdit-LayoutServices')}
                  />
                )}
              />
            </FormGroup>
          </>
        )}
        <Divider />
        <FormGroup>
          <Controller
            name="shopIds"
            control={control}
            render={({ field: f, formState }) => (
              <AddList
                label={tr.terminalEdit.shops}
                emptyMessage={tr.common.emptyShopMessage}
                attention={!!formState.errors.shopIds}
                useAutocomplete
                selectedIds={f.value || []}
                source={props.shops}
                addButtonText={tr.terminalEdit.addShopButton}
                onChange={handleShopIdsChange}
                renderSelectedItem={(item) => {
                  const { id, name } = item

                  return (
                    <div key={id}>
                      <div style={{ display: 'flex', alignItems: 'center', padding: '8px 0' }}>
                        <div style={{ flex: '1 1 auto', textTransform: 'uppercase' }}>{name}</div>
                        <div>
                          <IconButton
                            color="primary"
                            onClick={() => handleShopIdsChange((f.value || []).filter((sid) => sid !== id))}
                          >
                            <RemoveIcon />
                          </IconButton>
                        </div>
                      </div>
                      {!!getLinesByShopId(id)?.length && (
                        <AddList
                          label={tr.terminalEdit.lines}
                          emptyMessage={tr.common.emptyLineMessage}
                          attention={!!formState.errors.lineIds}
                          selectedIds={(watch('lineIds') || []).filter((lid) =>
                            getLines(f.value)
                              .map((l) => l.id)
                              .includes(lid)
                          )}
                          source={getLinesByShopId(id)}
                          style={{ paddingLeft: '1rem', borderLeft: '4px solid #db214d', marginBottom: '1rem' }}
                          dropdownDataConverter={(item) => ({
                            value: item.id,
                            text: `${getPlaceByLineId(item.id)?.name || 'ERROR'} - ${item.name}`
                          })}
                          listDataConverter={(item) => ({
                            id: item.id,
                            primaryText: `${getPlaceByLineId(item.id)?.name || 'ERROR'} - ${item.name}`
                          })}
                          addButtonText={tr.terminalEdit.addLineButton}
                          onChange={(val) => {
                            setValue('lineIds', val)
                            trigger()
                          }}
                        />
                      )}
                    </div>
                  )
                }}
              />
            )}
          />
        </FormGroup>
        {!!props.data.id && (
          <>
            <Divider />
            <Form title={tr.entityEdit.advancedGroup} expandable>
              <FormGroup>
                <ButtonWithTextWrapper>
                  <ActionText>{tr.terminalEdit.restartTerminal}</ActionText>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleRestart}
                    startIcon={<RestartIcon />}
                    disabled={props.processing}
                  >
                    {tr.terminalEdit.restartButton}
                  </Button>
                </ButtonWithTextWrapper>
              </FormGroup>
              <FormGroup>
                {!isConfirmDelete && (
                  <ButtonWithTextWrapper>
                    <ActionText>{tr.terminalEdit.deleteTitle}</ActionText>
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={handleTryDelete}
                      startIcon={<DeleteIcon />}
                      disabled={props.processing}
                    >
                      {tr.entityEdit.deleteButton}
                    </Button>
                  </ButtonWithTextWrapper>
                )}
                {isConfirmDelete && (
                  <ButtonWithTextWrapper>
                    <ActionText>{tr.entityEdit.confirmDelete}</ActionText>
                    <ConfirmationActions>
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        disabled={props.processing}
                        onClick={handleConfirmDelete}
                      >
                        {tr.entityEdit.yes}
                      </Button>
                      <Button variant="outlined" size="small" disabled={props.processing} onClick={handleCancelDelete}>
                        {tr.entityEdit.no}
                      </Button>
                    </ConfirmationActions>
                  </ButtonWithTextWrapper>
                )}
              </FormGroup>
            </Form>
          </>
        )}
      </Card>
      <ActionsWrapper>
        <Button disabled={!isValid || props.processing} variant="contained" color="primary" size="small" type="submit">
          {props.data.id ? tr.entityEdit.updateButton : tr.entityEdit.createButton}
        </Button>
      </ActionsWrapper>
    </form>
  )
}

export { TerminalEdit }
