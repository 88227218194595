import { Button, CircularProgress, Typography } from '@mui/material'
import { PersonOff as PersonOffIcon, Person as PersonIcon } from '@mui/icons-material'
import { journal as journalApi } from 'shared/api'
import { MonitoringMessage } from 'features/monitoring/monitoring-message'
import { getActionText } from 'pages/journal/utils/getActionText'
import { JournalModel, PositionActionType } from 'shared/models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { NavLink } from 'react-router-dom'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { journal } from 'pages/nav'
import { useAppSelector } from 'store'
import { JournalPositionMessageProps } from '../types'

export function JournalPositionMessage({ positionId, shopId }: JournalPositionMessageProps) {
  const { tr, dateFormatter } = useTranslation()
  const [positionInJournal, setPositionInJournal] = useState<JournalModel>()
  const [loading, setLoading] = useState(false)
  const { manageReports } = useAppSelector(getPermissionsSelector)

  useEffect(() => {
    if (positionId) {
      getJournal()
    } else {
      setPositionInJournal(undefined)
    }
  }, [positionId])

  async function getJournal() {
    try {
      setLoading(true)
      const res = await journalApi.getJournal({ shopId, term: `p${positionId}`, skip: 0 })
      if (res?.data?.length > 0) {
        setPositionInJournal(res.data[0])
      }
    } catch (err) {
      setPositionInJournal(undefined)
    } finally {
      setLoading(false)
    }
  }

  const positionInJournalActions = (positionInJournal?.actions || []).filter((action) =>
    [
      PositionActionType.JoinLine,
      PositionActionType.FailedJoin,
      PositionActionType.InPlace,
      PositionActionType.CallToCheckpoint,
      PositionActionType.StartService,
      PositionActionType.FinishService,
      PositionActionType.LeaveLine,
      PositionActionType.Removed
    ].includes(action.type)
  )

  const isFeaturePosition = !!positionInJournal?.date && !positionInJournalActions.length

  const lastAction = positionInJournalActions?.length
    ? positionInJournalActions[positionInJournalActions.length - 1]
    : undefined

  const customerDisplayName = positionInJournal?.customer
    ? [
        positionInJournal.customer.companyName,
        [positionInJournal.customer.firstName, positionInJournal.customer.lastName].filter(Boolean).join(' ')
      ]
        .filter(Boolean)
        .join(' - ')
    : undefined

  if (loading) {
    return (
      <MonitoringMessage icon={null}>
        <CircularProgress />
      </MonitoringMessage>
    )
  }

  if (!positionInJournal) {
    return (
      <MonitoringMessage icon={PersonOffIcon}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <div>{tr.lineMonitoring.noPositionFound}</div>
        </div>
      </MonitoringMessage>
    )
  }

  if (isFeaturePosition && positionInJournal.date) {
    return (
      <MonitoringMessage icon={PersonIcon}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          {Boolean(customerDisplayName) && <Typography variant="body1">{customerDisplayName}</Typography>}
          {tr.lineMonitoring.positionIsBooked(
            String(positionInJournal?.positionId || ''),
            dateFormatter(positionInJournal.date, 'dateTime')
          )}
          {Boolean(positionInJournal?.line) && <Typography variant="body2">{positionInJournal.line}</Typography>}
        </div>
        {manageReports && (
          <div style={{ marginTop: '1rem' }}>
            <NavLink
              style={{ textDecoration: 'none' }}
              to={{ pathname: journal(), search: new URLSearchParams({ term: positionId, shopId }).toString() }}
            >
              <Button color="primary">{tr.lineMonitoring.journal}</Button>
            </NavLink>
          </div>
        )}
      </MonitoringMessage>
    )
  }

  return (
    <MonitoringMessage icon={PersonIcon}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {Boolean(customerDisplayName) && <Typography variant="body1">{customerDisplayName}</Typography>}
        {lastAction && (
          <div style={{ lineHeight: '32px' }}>
            <div>{dateFormatter(lastAction.dateTime, 'dateTime')}</div>
            <div>{getActionText(lastAction, tr, positionInJournal)}</div>
          </div>
        )}
        {Boolean(positionInJournal?.line) && <Typography variant="body2">{positionInJournal.line}</Typography>}
      </div>
      {manageReports && (
        <div style={{ marginTop: '1rem' }}>
          <NavLink
            style={{ textDecoration: 'none' }}
            to={{ pathname: journal(), search: new URLSearchParams({ term: positionId, shopId }).toString() }}
          >
            <Button color="primary">{tr.lineMonitoring.journal}</Button>
          </NavLink>
        </div>
      )}
    </MonitoringMessage>
  )
}
