import { IconButton, Typography } from '@mui/material'
import { Delete as RemoveIcon } from '@mui/icons-material'
import { CSSProperties, ReactNode } from 'react'

type Props<T> = {
  item: T
  getTitle: (item: T) => ReactNode
  onRemove?: (item: T) => void
  style?: CSSProperties
}

function TreeItem<T>(props: Props<T>) {
  const { item, getTitle, onRemove, style } = props

  return (
    <div style={{ display: 'flex', alignItems: 'center', ...style }}>
      <Typography variant="subtitle1" component="span" style={{ flex: 1 }}>
        {getTitle(item)}
      </Typography>
      <IconButton color="primary" onClick={() => onRemove?.(item)}>
        <RemoveIcon />
      </IconButton>
    </div>
  )
}

export { TreeItem }
