import { LineModelSimplified } from 'shared/models'

const data: LineModelSimplified[] = [
  {
    id: 1,
    name: 'Auchan Luxenburg',
    lineCalculationAlgorithmMode: 'discrete',
    category: 0,
    allowFutureAppointments: true,
    lineParticipantTermInPluralTemplate: 'persons',
    lineParticipantTermInSingularTemplate: 'person',
    image: { id: '1', name: 'img.png' }
  },
  {
    id: 2,
    name: 'Kitchen expert',
    lineCalculationAlgorithmMode: 'normal',
    category: 5,
    lineParticipantTermInPluralTemplate: 'persons',
    lineParticipantTermInSingularTemplate: 'person',
    allowFutureAppointments: true
  },
  {
    id: 3,
    name: 'Legal entities and individual entrepreneurs',
    lineCalculationAlgorithmMode: 'normal',
    category: 5,
    lineParticipantTermInPluralTemplate: 'persons',
    lineParticipantTermInSingularTemplate: 'person',
    allowFutureAppointments: true,
    image: { id: '1', name: 'img.png' }
  }
]

export default data
