import { defaultDiacriticsRemovableMap } from 'shared/utils/regex-helper'

export function removeDiacritics(val: string) {
  const changes = defaultDiacriticsRemovableMap

  if (!val || typeof val !== 'string') return val

  for (let i = 0; i < changes.length; i++) {
    val = val.replace(changes[i].letters, changes[i].base)
  }
  return val
}
