import axios from 'axios'
import { UserAPI } from '../models'

const user: UserAPI = {
  login(email, password, captchaId, userEnteredCaptchaCode, config) {
    return axios.post('login', { email, password, captchaId, userEnteredCaptchaCode }, config)
  },
  loginQR(code, config) {
    return axios.post('loginQR', { code }, config)
  },
  getUserQRToken(userId, config) {
    return axios.get('getUserQRToken', { params: { userId }, ...config })
  },
  logout(config) {
    return axios.post('logout', undefined, config)
  },
  resetPassword(email, config) {
    return axios.post('resetPassword', { email }, config)
  },
  storeProfile(tkn, config) {
    return axios.get('getCurrentUserProfile', { params: { tkn }, ...config })
  },
  getUserList(data, config) {
    return axios.get('getUserList', { params: data, ...config })
  },
  getUser(id, config) {
    return axios.get('getUser', { params: { id }, ...config })
  },
  createUser(data, config) {
    return axios.post('createUser', data, config)
  },
  updateUser(data, config) {
    return axios.put('updateUser', data, config)
  },
  deleteUser(id, config) {
    return axios.delete('deleteUser', { data: id, headers: { 'Content-Type': 'application/json' }, ...config })
  },
  getProfileList() {
    return axios.get('getProfileList')
  },
  getUserTemporaryCode(userId, config) {
    return axios.get('getUserTemporaryCode', { params: { userId }, ...config })
  },
  loginByTemporaryCode(code, config) {
    return axios.post('loginByTemporaryCode', code, config)
  }
}

export default user
