import { useEffect, useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { Form, FormGroup } from 'shared/ui-kit/form'
import { TextField } from 'shared/ui-kit/text-field'
import { ChipListSelect } from 'shared/ui-kit/selects'
import { ServiceSmallModel } from 'shared/models'

type Props = {
  list: ServiceSmallModel[]
  attention?: boolean
  isDisabledItem?: (value: ServiceSmallModel) => boolean
  title?: string
  disabled?: boolean
  ensureValue?: boolean
  ignoreDisable?: boolean
  disableClickable?: boolean
  participantsNumber?: number
} & (MultipleProps | SingleProps)

type MultipleProps = {
  multiple: true
  value: ServiceSmallModel[]
  onChange?: (value: ServiceSmallModel[]) => void
}

type SingleProps = {
  multiple?: false
  value: ServiceSmallModel
  onChange?: (value: ServiceSmallModel | undefined) => void
}

function ServiceSelect(props: Props) {
  const { tr } = useTranslation()

  const [isOverloaded, setIsOverloaded] = useState(false)
  const [isMany, setIsMany] = useState(false)
  const [search, setSearch] = useState('')

  useEffect(() => {
    const isOverloadedCalculated = props.list?.length >= 20
    const isManyCalculated = props.list?.length >= 10

    if (isMany !== isManyCalculated) {
      setIsMany(isManyCalculated)
    }

    if (isOverloaded !== isOverloadedCalculated) {
      setIsOverloaded(isOverloadedCalculated)
    }
  }, [props.list])

  function isSelected(service) {
    if (props.multiple) {
      return (props.value || [])?.find((v) => v.id === service.id)
    } else {
      return (props.value || {}).id === service.id
    }
  }

  function handleChange(value: ServiceSmallModel | ServiceSmallModel[] | undefined) {
    if (props.multiple) {
      props.onChange?.(value as ServiceSmallModel[])
    } else {
      props.onChange?.(value as ServiceSmallModel)
    }
  }

  function renderServicesChipList(list: ServiceSmallModel[] = []) {
    return (
      <ChipListSelect
        attention={props.attention}
        multiple={props.multiple}
        ensureValue={props.ensureValue}
        disabled={props.disabled}
        label={!isMany ? props.title : undefined}
        value={props.value}
        list={list}
        compare={(valueItem, listItem) => valueItem.id === listItem.id}
        isDisabled={(item) => (props.ignoreDisable ? false : props.isDisabledItem?.(item) || false)}
        labelExtractor={(item) => {
          const participants = props.participantsNumber || 1
          const custom = item?.customDurationPerParticipantNumber || []
          const foundCustomDuration = custom.filter((el) => el.participantNumber === props.participantsNumber)
          const initialDuration = (item.duration || 0) * participants
          const duration = foundCustomDuration?.[0]?.duration || initialDuration

          return (
            <div style={{ textAlign: 'center' }}>
              <div style={{ fontSize: '16px', whiteSpace: 'break-spaces', wordBreak: 'break-word', textAlign: 'left' }}>
                {item && item.name}
              </div>
              <div style={{ fontSize: '12px', marginTop: '4px', opacity: 0.64 }}>
                {item && tr.time.hmin(Math.floor(duration / 60))}
              </div>
            </div>
          )
        }}
        onChange={handleChange}
        disableClickable={props.disableClickable}
      />
    )
  }

  if (isMany) {
    const filteredList = isOverloaded
      ? props.list?.filter(
          (service) => service.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 || isSelected(service)
        )
      : props.list || []

    const sortedList = props.isDisabledItem
      ? filteredList.sort((a, b) => Number(props.isDisabledItem?.(a)) - Number(props.isDisabledItem?.(b)))
      : filteredList

    return (
      <Form expandable attention={props.attention} title={props.title} expanded>
        {isOverloaded && (
          <FormGroup style={{ margin: '0.5rem 1rem' }}>
            <TextField disabled={props.disabled} placeholder={tr.common.search} value={search} onChange={setSearch} />
          </FormGroup>
        )}
        <FormGroup>{renderServicesChipList(sortedList)}</FormGroup>
      </Form>
    )
  }

  return <FormGroup>{renderServicesChipList(props.list)}</FormGroup>
}

export default ServiceSelect
