import { useTheme } from '@mui/material'
import { Warning as AttentionIcon } from '@mui/icons-material'
import { ReactNode } from 'react'

type Props = {
  text: string
  type?: 'error' | 'default'
  icon?: ReactNode
}

export function InFormMessage(props: Props) {
  const { text, type = 'default', icon = <AttentionIcon color="inherit" /> } = props
  const theme = useTheme()

  const bgColor = type === 'error' ? theme.palette.error.main : theme.palette.grey[200]

  return (
    <div
      style={{
        padding: '1rem',
        backgroundColor: bgColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: theme.palette.getContrastText(bgColor),
        gap: '0.5rem'
      }}
    >
      {text}
      {icon}
    </div>
  )
}
