type Props = {
  width: number
  height: number
  color: string
}

export function ScanIcon({ height, width, color }: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 1000 1000" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_25_2)">
        <path d="M960 738L960 963" stroke={color} strokeWidth="18" strokeLinecap="round" />
        <path d="M733 964L959 964" stroke={color} strokeWidth="18" strokeLinecap="round" />
        <path d="M733 35L958 35" stroke={color} strokeWidth="18" strokeLinecap="round" />
        <path d="M959 262L959 36" stroke={color} strokeWidth="18" strokeLinecap="round" />
        <path d="M40 261.5L40 36.5" stroke={color} strokeWidth="18" strokeLinecap="round" />
        <path d="M267 35.5L41 35.5" stroke={color} strokeWidth="18" strokeLinecap="round" />
        <path d="M266.5 963L41.5 963" stroke={color} strokeWidth="18" strokeLinecap="round" />
        <path d="M40.5 736L40.5 962" stroke={color} strokeWidth="18" strokeLinecap="round" />
      </g>
    </svg>
  )
}
