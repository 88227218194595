import { useState, useRef, useEffect, useCallback } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import LineSummary from './components/line-stats'
import { useTimeZoneFilter } from 'features/time-zone-filter'
import debounce from 'lodash/debounce'
import { LineSlot } from './components/line-slot'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { monitoring as monitoringApi, line as lineApi, checkpoint as checkpointApi } from 'shared/api'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { CircularProgress, Typography, Button } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import { DonutLarge as DonutLargeIcon } from '@mui/icons-material'
import { PersonOff as PersonOffIcon, Search as SearchIcon } from '@mui/icons-material'
import { checkpointHost, discreteLineMonitoringCreate, journal } from 'pages/nav'
import { lineMonitoringCreate, discreteLineMonitoringEdit } from 'pages/nav'
import { filterPositionsByTerm, filterPositionsByType, filterPositionsByStatus } from 'shared/utils/positions_filters'
import { filterPositionsByQueueType, filterPositionsByService } from 'shared/utils/positions_filters'
import { PositionQueueType } from 'shared/utils/positions_filters'
import {
  CheckpointModel,
  LineModel,
  DiscreteMonitoringModel,
  ParentPlaceAndLineModel,
  PositionModel,
  PositionType,
  InternalService,
  LineMode
} from 'shared/models'
import { storeUrl } from 'shared/utils/history'
import { NavLink, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import { OnDuplicatePositionType, PositionCard } from 'features/monitoring/position-card'
import { simplifyPositionToFullPositionConvert } from 'features/position/position-converter'
import { MonitoringMessage } from 'features/monitoring/monitoring-message'
import { AppTitle } from 'shared/hooks/useTitle'
import { setDuplicatePosition } from 'features/position-actions/utils/duplicateStorage'
import { JournalPositionMessage } from './components/journal-position-message'
import { getModesAvailableForFiltering, readyToServiceStates } from './utils'
import { ColumnWrapper } from './styled'
import { LoadingCenter, ShowPositionContainer, HidePositionContainer } from './styled'
import { MonitoringRoot, MonitoringBar, MonitoringBarContainer } from './styled'
import Container from 'shared/ui-kit/container'
import { defaultFilters } from './utils'
import { DiscreteMonitoringBar } from './components/bar'
import { useAppDispatch, useAppSelector } from 'store'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { ValidateState } from 'features/qr-scanner/qr-scanner'
import { DiscreteMonitoringFilters } from './types'
import { hideTopBar, showTopBar } from 'store/reducers/main-layout-reducer'

function LineMonitoring() {
  const [nowTime, setNowTime] = useState<number>(Date.now())
  const [lastUpdateDate, setLastUpdateDate] = useState<number>(Date.now())
  const [serverTime, setServerTime] = useState<number>(Date.now())

  const { tr } = useTranslation()
  const { lineId, placeId } = useParams<{ placeId: string; lineId: string }>()
  const match = useRouteMatch()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { displayReducedInterface, manageReports } = useAppSelector(getPermissionsSelector)

  useTimeZoneFilter(placeId)

  const [monitoring, setMonitoring] = useState<DiscreteMonitoringModel>()
  const [selectedId, setSelectedId] = useState<string | number | null>(null)
  const [loading, setLoading] = useState(true)
  const [filter, setFilter] = useState<DiscreteMonitoringFilters>(defaultFilters)
  const [line, setLine] = useState<LineModel>()
  const [positions, setPositions] = useState<PositionModel[]>([])
  const [checkpoints, setCheckpoints] = useState<CheckpointModel[]>([])
  const [selectedPositionId, setSelectedPositionId] = useState<string | number>()
  const [modesAvailableForFiltering, setModesAvailableForFiltering] = useState<PositionType[]>([])
  const [smallScreen, setSmallScreen] = useState(false)
  const [showAllPositions, setShowAllPositions] = useState(false)

  const [searchActive, setSearchActive] = useState(false)

  const connect = useRef<any>()
  const termRef = useRef<HTMLInputElement>()
  const bar = useRef<HTMLDivElement>(null)
  const breakpoint = useRef(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setNowTime(Date.now())
    }, 20000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', resizeHandler, false)
    initMonitoring()

    return () => {
      window.removeEventListener('resize', resizeHandler, false)
      connect.current?.stop?.()
    }
  }, [])

  useEffect(() => {
    resizeHandler()
  }, [loading])

  const resizeHandler = useCallback(
    debounce(() => {
      setSmallScreen((curr) => {
        if (!bar.current) {
          return curr
        }

        if (bar.current?.clientWidth < bar.current?.scrollWidth && !curr) {
          breakpoint.current = bar.current?.scrollWidth
          return true
        }

        if (bar.current?.clientWidth > breakpoint.current && curr) {
          return false
        }

        return curr
      })
    }, 100),
    []
  )

  function initMonitoring() {
    storeUrl(location.pathname)
    setQueryTerm()
    getLineInfo(() => fetchData(connectDataUpdate))
  }

  function handleBreadcrumbs(parents: ParentPlaceAndLineModel) {
    const crumbs = getCrumbs(tr.breadcrumbs)

    dispatch(
      setBreadcrumbs([
        crumbs.home(),
        crumbs.places(),
        crumbs.place([placeId], parents?.shop.name),
        crumbs.lines([placeId]),
        crumbs.line([placeId, lineId], parents?.line.name),
        crumbs.discreteLineMonitoring([placeId, lineId])
      ])
    )
  }

  function getLineInfo(cb?: () => void) {
    Promise.all([lineApi.getLine(lineId, placeId), checkpointApi.getCheckpointList(placeId, lineId)])
      .then(([line, checkpoints]) => {
        setCheckpoints(checkpoints?.data || [])
        setLine(line?.data)
      })
      .finally(cb)
  }

  function handleData(monitoring: DiscreteMonitoringModel) {
    const modesAvailableForFiltering = getModesAvailableForFiltering(monitoring)

    setLastUpdateDate(new Date().getTime())
    setServerTime(monitoring.serverNow || new Date().getTime())
    setMonitoring(monitoring)
    setModesAvailableForFiltering(modesAvailableForFiltering)
    setLoading(false)
  }

  useEffect(() => {
    const positions = filterPositions(monitoring?.slots || [], filter.termValue, filter.positionId)
    setPositions(positions)
  }, [monitoring, filter.termValue, filter.positionId, serverTime, lastUpdateDate, nowTime])

  function handleSelectPosition(positionId: string): ValidateState {
    if (!positionId) {
      return 'notFound'
    }

    const selectedElement = monitoring?.slots?.filter?.((slot) => {
      return slot.positions.filter((position) => String(position.id) === String(positionId)).length !== 0
    })

    if (selectedElement?.length !== 0) {
      setSelectedId(positionId)
      handleTermIdChange(positionId)

      return getPositionQRValidate(positionId)
    }

    handleTermIdChange(positionId)

    return 'findingInJournal'
  }

  function getPositionQRValidate(positionId: string | number) {
    let position: any = null

    monitoring?.slots?.forEach((slot) => {
      slot.positions.forEach((pos) => {
        if (String(pos.id) === String(positionId)) {
          position = pos
        }
      })
    })

    if (!position) {
      return 'notFound'
    }

    const from_unixtime = position.timeSlot?.from_unixtime || 0
    const to_unixtime = from_unixtime + (line?.serviceTime || 0) * 1000

    if (position.asap && readyToServiceStates.includes(position.state)) {
      return 'readyToService'
    }

    if (!position.asap && from_unixtime < Date.now() + 180000 && to_unixtime > Date.now() - 180000) {
      return 'readyToService'
    }

    return 'timeDeviation'
  }

  function handleToggle(id: number) {
    setSelectedId(id === selectedId ? null : id)
  }

  function fetchData(cb?: () => void) {
    monitoringApi.getDiscreteLineMonitoring(lineId).then((resp) => {
      handleData(resp.data)
      handleBreadcrumbs(resp.parents)
      cb?.()
    })
  }

  function connectDataUpdate() {
    connect.current = monitoringApi.discretePositionsChanged({ placeId, lineId }, handleData, getLineInfo)
  }

  function setQueryTerm() {
    if (location?.search) {
      const search = new URLSearchParams(location.search)
      const term = search.get('term')

      if (term) {
        handleTermChange(term)
      }
    }
  }

  function handleRemove(rSlot, rPosition) {
    if (!monitoring) {
      return
    }

    const m = { ...monitoring }
    const slots = m.slots || []
    const slotToChange = slots.find((slot) => slot.id === rSlot.id)

    if (slotToChange) {
      slotToChange.positions = slotToChange.positions.filter((position) => position.id !== rPosition.id)
    }

    m.slots = slots

    setMonitoring(m)
  }

  function selectPositionChange(position: PositionModel) {
    setSelectedPositionId(selectedPositionId === position.id ? undefined : position.id)
  }

  function closeActions() {
    setSelectedPositionId(undefined)
  }

  function onStateChange() {
    fetchData()
  }

  function setTermSearch(term: string) {
    const search = location.search
    const params = new URLSearchParams(search)

    if (term) {
      params.set('term', term)
    } else {
      params.delete('term')
    }

    history.replace({ pathname: location.pathname, search: String(params) })
  }

  function handleTermChangeInput(ev) {
    if (ev.target.value === '') {
      handleClearTerm()
      return
    }

    setTermSearch(ev.target.value)
    handleTermChangeDebounce(ev.target.value)
  }

  function handleTermIdChange(positionId) {
    setFilter((curr) => ({ ...curr, positionId, termValue: '' }))
  }

  function handleClearPositionId() {
    setFilter((curr) => ({ ...curr, termValue: '', positionId: null }))
  }

  function handleTermChange(termValue: string) {
    setTermSearch(termValue)

    if (termRef.current) {
      termRef.current.value = termValue
    }

    setFilter((curr) => ({ ...curr, termValue }))
  }

  function handleSearchInputBlur() {
    setSearchActive(false)
    dispatch(showTopBar())
  }

  function handleSearchInputFocus() {
    if (smallScreen) {
      setSearchActive(true)
      dispatch(hideTopBar())
    }
  }

  const handleTermChangeDebounce = useCallback(debounce(handleTermChange, 800), [])

  function handleClearTerm() {
    termRef.current?.focus()
    setTermSearch('')

    handleTermChangeDebounce.cancel()

    if (termRef?.current) {
      termRef.current.value = ''
    }

    setFilter((curr) => ({ ...curr, termValue: '' }))
  }

  function handleUpdatePosition(position: PositionModel) {
    return () => {
      if (position.services?.find((s) => String(s.id) === String(InternalService.Break))) {
        history.push(
          checkpointHost(placeId, lineId, String(position.customCheckpoint?.id || position.assignedCheckpoint?.id))
        )
      } else {
        history.push(discreteLineMonitoringEdit(placeId, lineId, String(position.id)))
      }
    }
  }

  function changeAllPositions(showAllPositions: boolean) {
    return () => {
      setShowAllPositions(showAllPositions)
    }
  }

  function filterPositions(slots, term, positionId) {
    const timeDiff = Date.now() - lastUpdateDate

    let positions: PositionModel[] = []

    slots.forEach((slot) => {
      if (!slot.positions?.length) {
        return
      }

      const timeslot = { id: slot.from, from_unixtime: slot.from, to_unixtime: slot.from }

      const positionsForFilter = slot.positions.map((position) => {
        return simplifyPositionToFullPositionConvert(
          position,
          line?.services || [],
          serverTime + timeDiff,
          tr,
          timeslot,
          checkpoints
        )
      })

      const filtered = filterPositionsByTerm(positionsForFilter, term)
        .map((position) => ({ ...position, slotId: slot.id }))
        .filter((position) => !positionId || String(positionId) === String(position.id))

      positions = [...positions, ...filtered]
    })

    return positions
  }

  const handleDuplicatePosition: OnDuplicatePositionType = (params) => {
    const data = { position: params.position, back: { placeId, lineId, link: match.url } }

    setDuplicatePosition(placeId, data)

    if (params.line.isDiscrete) {
      history.push(`${discreteLineMonitoringCreate(placeId, String(params.line.id))}?duplicate`)
    } else {
      history.push(`${lineMonitoringCreate(placeId, String(params.line.id))}?duplicate`)
    }
  }

  function handleFiltersChange(filter: DiscreteMonitoringFilters) {
    setFilter(filter)
  }

  function filterPositionsCriterial(positions) {
    const types = Object.values(PositionQueueType)
    const modes = filter.positionTypeSelected.filter((pts) => modesAvailableForFiltering.find((st) => st === pts))
    const first = filterPositionsByQueueType(positions, filter.positionQueueTypeSelected, types)
    const second = filterPositionsByStatus(first, filter.positionStatusesSelected)
    const third = filterPositionsByService(second, filter.positionServicesSelected)
    const fourth = filterPositionsByTerm(third, filter.termValue)
    const filtredPositions = filterPositionsByType(fourth, modes)

    return filtredPositions
  }

  if (loading && !monitoring) {
    return (
      <LoadingCenter>
        <CircularProgress />
      </LoadingCenter>
    )
  }

  if (!monitoring || !line) {
    return null
  }

  const supportMobile = !!(line?.modes || []).find((el) => el === LineMode.MobileApp)
  const registeredPositionsCount = monitoring.registeredPositionsCount || 0

  const filtersIsSelected =
    !!filter.positionQueueTypeSelected?.length ||
    !!filter.positionServicesSelected?.length ||
    !!filter.positionStatusesSelected?.length ||
    !!filter.positionTypeSelected?.length

  const isTerm = !!filter.termValue || !!filter.positionId
  const displayFullSlotMonitoring = !displayReducedInterface && !isTerm && !filtersIsSelected
  const displayPostionsMonitoring = !!showAllPositions || isTerm || filtersIsSelected
  const displayAddMessage = !!displayReducedInterface && !isTerm && registeredPositionsCount === 0
  const displaySearchMessage = !!displayReducedInterface && !isTerm && registeredPositionsCount > 0 && !showAllPositions
  const displayHidePositionsButton =
    !!displayReducedInterface && !isTerm && registeredPositionsCount > 0 && !!showAllPositions
  const displayNotFoundMessage = !!filter.termValue && !positions.length
  const filteredPositions = !filtersIsSelected && !isTerm ? positions : filterPositionsCriterial(positions)

  const simplifyMode = searchActive && smallScreen

  return (
    <MonitoringRoot>
      <MonitoringBar $smallScreen={smallScreen} $top={simplifyMode ? 0 : 64} ref={bar}>
        <MonitoringBarContainer $smallScreen={smallScreen}>
          <DiscreteMonitoringBar
            checkpoints={checkpoints}
            filter={filter}
            handleClearPositionId={handleClearPositionId}
            handleClearTerm={handleClearTerm}
            handleSelectPosition={handleSelectPosition}
            handleTermChange={handleTermChange}
            handleTermChangeInput={handleTermChangeInput}
            line={line}
            lineId={lineId}
            smallScreen={smallScreen}
            modesAvailableForFiltering={modesAvailableForFiltering}
            monitoring={monitoring}
            shopId={placeId}
            setFilters={handleFiltersChange}
            termRef={termRef}
            onSearchInputBlur={handleSearchInputBlur}
            onSearchInputFocus={handleSearchInputFocus}
            simplifyMode={simplifyMode}
          />
        </MonitoringBarContainer>
      </MonitoringBar>
      <Container>
        {monitoring.lineIsActive === false && (
          <MonitoringMessage icon={DonutLargeIcon}>{tr.monitoring.notActive}</MonitoringMessage>
        )}
        {monitoring.lineIsActive !== false && (
          <>
            {!simplifyMode && <AppTitle lineName={line?.name} />}
            {!simplifyMode && <LineSummary data={monitoring} />}
            {displayHidePositionsButton && (
              <HidePositionContainer>
                <Button color="primary" onClick={changeAllPositions(false)}>
                  {tr.lineMonitoring.hideAllPositions}
                </Button>
              </HidePositionContainer>
            )}
            {displaySearchMessage && (
              <MonitoringMessage icon={SearchIcon}>
                <ShowPositionContainer>
                  <div>{tr.lineMonitoring.canSearchPositionMesssage}</div>
                  <Button color="primary" onClick={changeAllPositions(true)}>
                    {tr.lineMonitoring.showAllPositions}
                  </Button>
                </ShowPositionContainer>
              </MonitoringMessage>
            )}
            {displayAddMessage && (
              <MonitoringMessage icon={AddIcon}>{tr.lineMonitoring.canAddPositionMessage}</MonitoringMessage>
            )}
            {!!filter.positionId && !positions?.length && (
              <JournalPositionMessage positionId={String(filter.positionId)} shopId={placeId} />
            )}
            {displayNotFoundMessage && (
              <MonitoringMessage icon={PersonOffIcon}>
                <ColumnWrapper>
                  <div>{tr.lineMonitoring.noPositionFound}</div>
                  <Typography variant="body2">
                    {manageReports
                      ? tr.lineMonitoring.noActivePositionFound
                      : tr.lineMonitoring.noActivePositionFoundWithoutJournalAccess}
                  </Typography>
                  {manageReports && (
                    <NavLink
                      style={{ textDecoration: 'none', marginTop: '1rem' }}
                      to={{
                        pathname: journal(),
                        search: new URLSearchParams({
                          term: filter?.termValue || '',
                          shopId: placeId,
                          lineId
                        }).toString()
                      }}
                    >
                      <Button color="primary">{tr.lineMonitoring.search}</Button>
                    </NavLink>
                  )}
                </ColumnWrapper>
              </MonitoringMessage>
            )}
            {displayPostionsMonitoring && (
              <ColumnWrapper>
                {filteredPositions.map((position, i) => (
                  <PositionCard
                    key={i}
                    position={position}
                    shopId={placeId}
                    lineId={lineId}
                    line={line}
                    checkpoints={checkpoints}
                    onClick={() => selectPositionChange(position)}
                    onUpdate={handleUpdatePosition(position)}
                    params={{
                      selectedId: selectedPositionId,
                      supportMobile,
                      displayPeopleInPosition: line.requestParticipantsNumber
                    }}
                    onDuplicatePosition={handleDuplicatePosition}
                    onStateChange={onStateChange}
                    onActionsClose={closeActions}
                  />
                ))}
              </ColumnWrapper>
            )}
            {displayFullSlotMonitoring &&
              monitoring.slots.map((slot, i) => (
                <LineSlot
                  data={slot}
                  checkpoints={checkpoints}
                  line={line || {}}
                  shopId={placeId}
                  lineId={lineId}
                  key={i}
                  onToggle={handleToggle}
                  selectedPositionId={selectedPositionId}
                  selected={slot.id === selectedId}
                  onRemove={handleRemove}
                  onStateChange={onStateChange}
                  selectPositionChange={selectPositionChange}
                  serverNow={serverTime + (Date.now() - lastUpdateDate)}
                  onDuplicatePosition={handleDuplicatePosition}
                  onCloseActions={closeActions}
                />
              ))}
          </>
        )}
      </Container>
    </MonitoringRoot>
  )
}

export default LineMonitoring
