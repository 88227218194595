import { useEffect } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { List } from 'shared/ui-kit/list'
import Card from 'shared/ui-kit/card'
import { customers } from 'pages/nav'
import PageLoader from 'shared/ui-kit/page-loader'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import Container from 'shared/ui-kit/container'
import { useAppDispatch } from 'store'
import { useQuery } from 'react-query'
import { dailyStats as dailyStatsApi } from 'shared/api'
import { useLocation } from 'react-router'
import { DailyStatsCustomerModel } from 'shared/models'

function DailyStatsCustomers() {
  const { tr, dateFormatter } = useTranslation()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const { data: res, isLoading } = useQuery(
    ['dailyCustomers', params.get('dailyStatsId'), params.get('brandId'), params.get('shopId')],
    ({ signal }) =>
      dailyStatsApi.getDailyStatsCustomers(
        { dailyStatsId: params.get('dailyStatsId'), brandId: params.get('brandId'), shopId: params.get('shopId') },
        { signal }
      ),
    {
      enabled: !!params.get('dailyStatsId')
    }
  )

  useEffect(() => {
    const crumb = getCrumbs(tr.breadcrumbs)
    dispatch(setBreadcrumbs([crumb.home(), crumb.reports(), crumb.dailyStats(), crumb.dailyStatsCustomers()]))
  }, [])

  function handleSelect(data: DailyStatsCustomerModel) {
    const params = new URLSearchParams({ customerId: String(data.id) })
    const url = `${customers()}?${params}`

    window.open('#' + url, '_blank')
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <Container>
      <Card>
        <List
          data={res?.data || []}
          dataConverter={(item) => {
            const usage = tr.dailyStatsCustomers.applicationUsage(
              item.numberOfUses,
              dateFormatter(item.dateOfFirstUse, { year: 'numeric', month: 'long', day: '2-digit' })
            )
            const appInfo = tr.dailyStatsCustomers.applicationInfo(item.appVersion || '', item.language || '')
            const secondaryText = `${usage}${tr.platform[item.platform] ? ', ' + tr.platform[item.platform] : ''}${
              appInfo ? ', ' + appInfo : ''
            }`

            return {
              id: item.id,
              primaryText: tr.dailyStatsCustomers.customerTitle(item.name, item.phoneNumber, item.email),
              secondaryText: <div>{secondaryText}</div>
            }
          }}
          onSelect={handleSelect}
        />
      </Card>
    </Container>
  )
}

export { DailyStatsCustomers }
