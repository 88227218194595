import styled from '@emotion/styled'

const CenterProgress = styled.div`
  display: flex;
  padding: 1rem;
  justify-content: center;
`

const ActionList = styled.div<{ $isCardView?: boolean }>`
  display: ${(props) => (props.$isCardView ? 'flex' : undefined)};
  flex-direction: column;
  gap: 1rem;
  margin-top: ${(props) => (props.$isCardView ? '1rem' : '0.5rem')};
`

const ActionFormActions = styled.div<{ $marginTop?: string }>`
  display: flex;
  gap: 0.5rem;
  margin-top: ${(props) => props.$marginTop || '1rem'};
`

export { CenterProgress, ActionList, ActionFormActions }
