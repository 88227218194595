import styled from '@emotion/styled'

const AccordionTitle = styled.div`
  margin-top: -1.5rem;
  position: absolute;
  font-size: 14px;
  opacity: 0.64;
`

const Accordion = styled.div`
  padding: 1.5rem 0 0.5rem 1rem;
  margin: 1rem 0 2rem;
  border-left: solid 2px #db214d;
`

const PlaceName = styled.div`
  flex: 1 1 auto;
  text-transform: uppercase;
`

const PlaceListWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
`

const CopyIconWrapper = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
`

const SecLeftWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.25rem;
  margin-bottom: -0.5rem;
`

const TemporaryCodeViewWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem;
  flex-direction: column;
  gap: 0.5rem;
  box-sizing: border-box;
`

const lineListStyles = { paddingLeft: '1rem', borderLeft: '4px solid #db214d', marginBottom: '1rem' }

export {
  AccordionTitle,
  Accordion,
  PlaceName,
  PlaceListWrapper,
  lineListStyles,
  CopyIconWrapper,
  SecLeftWrapper,
  TemporaryCodeViewWrapper
}
