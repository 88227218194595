import { useAppDispatch, useAppSelector } from 'store'
import { removeMessage } from 'store/actions/main-layout-action-creators'
import { getMessagesSelector } from 'store/selectors/messagesSelectors'
import Notifer from './notifer'

export function Snackbar() {
  const dispatch = useAppDispatch()
  const messages = useAppSelector(getMessagesSelector)

  function handleClose(stamp: number) {
    dispatch(removeMessage(stamp))
  }

  return <Notifer messages={messages} onClose={handleClose} />
}
