import styled from '@emotion/styled'
import { Tooltip } from '@mui/material'

export const TooltipStyled = styled(Tooltip)`
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  opacity: 6%;

  &:hover {
    opacity: 100%;
  }

  &:focus-visible {
    opacity: 100%;
  }
`

export const HelperContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`
