import { ReactNode } from 'react'
import Typography from '@mui/material/Typography'

type Props = {
  children: ReactNode
}

export default function SingleValue(props: Props) {
  return <Typography {...props} />
}
