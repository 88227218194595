import { Button, Dialog, DialogContent, DialogTitle, Divider } from '@mui/material'
import Autocomplete from 'shared/ui-kit/autocomplete'
import { FormActionGroup } from 'shared/ui-kit/form'
import { useTranslation } from 'shared/i18n/translate'
import { useState } from 'react'
import { useDataProvider } from 'features/isolated-data-provider'
import { LineDuplicationDialogProps } from '../types'

export function LineDuplicationDialog(props: LineDuplicationDialogProps) {
  const [placeId, setPlaceId] = useState<string | number>(props.defaultShopId)
  const { places } = useDataProvider()
  const { tr } = useTranslation()

  function handleDuplicateLine() {
    props.onDuplicate(placeId)
  }

  return (
    <Dialog
      open={props.open || false}
      onClose={props.onClose}
      fullWidth
      PaperProps={{ style: { overflow: 'visible' } }}
    >
      <DialogTitle>{tr.lineEdit.duplicateLine}</DialogTitle>
      <DialogContent style={{ overflow: 'unset' }}>
        <Autocomplete
          title={tr.lineEdit.duplicatePlaceTarget}
          fullWidth
          list={places}
          value={placeId}
          dataConverter={(item) => ({ value: String(item.id), text: item.name })}
          onChange={setPlaceId as any}
        />
      </DialogContent>
      <Divider />
      <FormActionGroup>
        <Button size="small" onClick={handleDuplicateLine} disabled={!placeId || !places?.length}>
          {tr.lineEdit.duplicateButton}
        </Button>
      </FormActionGroup>
    </Dialog>
  )
}
