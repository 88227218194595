import { useEffect, useMemo, useState, useRef } from 'react'
import { TerminalList } from './dumb/terminal-list'
import { createTerminal } from 'pages/nav'
import { useTranslation } from 'shared/i18n/translate'
import { useHistory, useLocation, useParams } from 'react-router'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { terminal as terminalAPI } from 'shared/api'
import { Add as AddIcon } from '@mui/icons-material'
import { removeDiacritics } from 'shared/utils/string'
import Container from 'shared/ui-kit/container'
import { ParentPlaceModel, TerminalSmallModel } from 'shared/models'
import PageLoader from 'shared/ui-kit/page-loader'
import EmptyScreen from 'shared/ui-kit/empty-screen'
import { AppBar, Toolbar, Tooltip, IconButton, useTheme } from '@mui/material'
import { StorageItem } from 'shared/utils/storage'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { SearchInput } from 'features/search-input'
import { useTitle } from 'shared/hooks/useTitle'
import { useDataProvider } from 'features/isolated-data-provider'
import { useAppDispatch, useAppSelector } from 'store'
import Card from 'shared/ui-kit/card'
import { useTimeZoneFilter } from 'features/time-zone-filter'

function SmartTerminalList() {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const { canUpdateShops } = useAppSelector(getPermissionsSelector)
  const { tr } = useTranslation()
  const { placeId } = useParams<{ placeId: string }>()
  const { push } = useHistory<{ terminalId?: number | string }>()

  useTimeZoneFilter(placeId)

  const [term, setTerm] = useState<string>('')
  const [data, setData] = useState<TerminalSmallModel[] | null>(null)
  const [busyTerminals, setBusyTerminals] = useState<{ [key: TerminalSmallModel['id']]: boolean }>({})

  const history = useHistory()
  const { search } = useLocation()

  const { current: terminalsTerm } = useRef(new StorageItem<string>('terminalsTerm'))

  const params = useMemo(() => new URLSearchParams(search), [])

  const { getPlace } = useDataProvider()

  useTitle({ placeName: getPlace(placeId)?.name })

  useEffect(() => {
    const initTerm = new URLSearchParams(search).get('term')

    if (initTerm) {
      setTerm(initTerm)
    } else if (terminalsTerm.value) {
      setTerm(terminalsTerm.value)
    }

    fetchTerminalList()
  }, [])

  useEffect(() => {
    if (term) {
      params.set('term', term)
    } else {
      params.delete('term')
    }

    terminalsTerm.value = term

    history.replace({ search: params.toString() })
  }, [term])

  async function fetchTerminalList() {
    try {
      const res = await terminalAPI.getTerminalList({ placeId })
      setData(res.data)
      setCrumbs(res.parents)
    } catch (err) {
      console.warn(err)
    }
  }

  const filtered = useMemo(() => {
    if (!data) {
      return []
    }

    if (!term) {
      return data
    }

    return data.filter((terminal) => {
      return removeDiacritics(terminal.name).toLowerCase().includes(removeDiacritics(term).toLowerCase())
    })
  }, [term, data])

  function setCrumbs(parents: ParentPlaceModel) {
    const crumb = getCrumbs(tr.breadcrumbs)

    dispatch(
      setBreadcrumbs([
        crumb.home(),
        crumb.places(),
        crumb.place([placeId], String(parents?.shop?.name || '')),
        crumb.terminals([placeId])
      ])
    )
  }

  function replaceTerminal(collection: TerminalSmallModel[], newItem: TerminalSmallModel) {
    const replaceIndex = collection.findIndex((b) => b.id === newItem.id)
    const copy = [...collection]

    if (replaceIndex !== -1) {
      copy[replaceIndex] = newItem
    }

    return copy
  }

  function toggleTerminal(toggleItem: TerminalSmallModel) {
    const toggled: TerminalSmallModel = { ...toggleItem, enabled: !toggleItem.enabled }

    setBusyTerminals((prev) => ({ ...prev, [toggled.id]: true }))
    setData((prevState) => (prevState ? replaceTerminal(prevState, toggled) : prevState))

    terminalAPI[toggleItem.enabled ? 'disableTerminal' : 'enableTerminal'](toggled.id)
      .catch(() => setData((prevState) => (prevState ? replaceTerminal(prevState, toggleItem) : prevState)))
      .finally(() => setBusyTerminals((prev) => ({ ...prev, [toggled.id]: false })))
  }

  const isEmpty = data?.length === 0 || filtered.length === 0

  return (
    <>
      <AppBar position="sticky" color="inherit" elevation={2} style={{ top: theme.spacing(8), borderRadius: 0 }}>
        <Toolbar>
          <Container style={{ padding: 'unset' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2) }}>
              {canUpdateShops && (
                <Tooltip arrow title={tr.terminalList.addButton}>
                  <IconButton onClick={() => push(createTerminal(placeId))}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
              <SearchInput
                style={{ flex: 1 }}
                value={term}
                onChange={(event) => setTerm(event.target.value)}
                placeholder={tr.common.search}
                autoFocus
                onClear={() => setTerm('')}
              />
            </div>
          </Container>
        </Toolbar>
      </AppBar>
      {data === null && <PageLoader />}
      {data !== null && (
        <Container>
          {isEmpty && <EmptyScreen text={tr.common.noData} />}
          {!isEmpty && (
            <Card paperStyle={{ overflow: 'hidden' }}>
              <TerminalList
                data={filtered}
                onDisable={(data) => toggleTerminal(data)}
                onEnable={(data) => toggleTerminal(data)}
                isTerminalDisabled={(data) => busyTerminals[data.id]}
                shopId={placeId}
              />
            </Card>
          )}
        </Container>
      )}
    </>
  )
}

export default SmartTerminalList
