import { useTranslation } from 'shared/i18n/translate'
import { useEffect } from 'react'

type UseTitleParams = {
  lineName?: string
  placeName?: string
}

export function useTitle(params: UseTitleParams) {
  const { tr } = useTranslation()

  useEffect(() => {
    if (params.lineName) {
      window.document.title = `${params.lineName}, ${tr.common.appName}`
    } else if (params.placeName) {
      window.document.title = `${params.placeName}, ${tr.common.appName}`
    }

    return () => {
      window.document.title = tr.common.title
    }
  }, [params])
}

export function AppTitle(props: UseTitleParams) {
  useTitle(props)
  return null
}
