export function index() {
  return '/'
}

export function login() {
  return '/login'
}

export function loginByTemporaryCode() {
  return '/loginByTemporaryCode'
}

export function resetPassword() {
  return '/resetPassword'
}

export function messageTemplates() {
  return `/templates`
}

export function places() {
  return '/shops'
}

export function place(placeId: string) {
  return `${places()}/${placeId}`
}

export function placeMessageTemplates(placeId: string) {
  return `${place(placeId)}/templates`
}

export function createPlace() {
  return `${places()}/create`
}

export function editPlace(placeId: string) {
  return `${place(placeId)}/edit`
}

export function daysOff(placeId: string) {
  return `${place(placeId)}/daysOff`
}

export function exportData(placeId: string) {
  return `${place(placeId)}/exportData`
}

export function lines(placeId: string) {
  return `${place(placeId)}/lines`
}

export function line(placeId: string, lineId: string) {
  return `${lines(placeId)}/${lineId}`
}

export function createLine(placeId: string) {
  return `${lines(placeId)}/create`
}

export function editLine(placeId: string, lineId: string) {
  return `${line(placeId, lineId)}/edit`
}

export function createCheckpoint(placeId: string, lineId: string) {
  return `${checkpoints(placeId, lineId)}/create`
}

export function editCheckpoint(placeId: string, lineId: string, checkpointId: string) {
  return `${checkpoints(placeId, lineId)}/${checkpointId}/edit`
}

export function checkpointHost(placeId: string, lineId: string, checkpointId: string, stopAction?: boolean) {
  if (stopAction) {
    return `${checkpoints(placeId, lineId)}/${checkpointId}/host?stop=true`
  }

  return `${checkpoints(placeId, lineId)}/${checkpointId}/host`
}

export function checkpointMonitoring(placeId: string, lineId: string, checkpointId: string) {
  return `${checkpoints(placeId, lineId)}/${checkpointId}/monitoring`
}

export function checkpointMonitoringDialog(placeId: string, lineId: string, checkpointId: string) {
  return `${checkpointMonitoring(placeId, lineId, checkpointId)}/dialog`
}

export function checkpointMonitoringFinishDialog(placeId: string, lineId: string, checkpointId: string) {
  return `${checkpointMonitoring(placeId, lineId, checkpointId)}/finishDialog`
}

export function checkpointMonitoringPosition(
  placeId: string,
  lineId: string,
  checkpointId: string,
  positionId: string
) {
  return `${checkpointMonitoring(placeId, lineId, checkpointId)}/${positionId}`
}

export function checkpointMonitoringCreate(placeId: string, lineId: string, checkpointId: string) {
  return `${checkpointMonitoring(placeId, lineId, checkpointId)}/create`
}

export function checkpointMonitoringEdit(placeId: string, lineId: string, checkpointId: string, positionId: string) {
  return `${checkpointMonitoring(placeId, lineId, checkpointId)}/${positionId}/edit`
}

export function checkpoints(placeId: string, lineId?: string) {
  return lineId ? `${lines(placeId)}/${lineId}/checkpoints` : `${place(placeId)}/checkpoints`
}

export function createLineStaffManagement(placeId: string, lineId: string) {
  return `${lineStaffManagement(placeId, lineId)}/create`
}

export function editLineStaffManagement(placeId: string, lineId: string, managementId: string) {
  return `${lineStaffManagement(placeId, lineId)}/${managementId}`
}

export function lineStaffManagement(placeId: string, lineId: string) {
  return `${lines(placeId)}/${lineId}/staffManagement`
}

export function broadcastAlerts(placeId: string, lineId: string) {
  return `${lines(placeId)}/${lineId}/broadcastAlerts`
}

export function zones(placeId: string) {
  return `${place(placeId)}/zones`
}

export function editZone(placeId: string, zoneId: string) {
  return `${zones(placeId)}/${zoneId}/edit`
}

export function createZone(placeId: string) {
  return `${zones(placeId)}/create`
}

export function beacons(placeId: string) {
  return `${place(placeId)}/beacons`
}

export function beacon(placeId: string, beaconId: string) {
  return `${beacons(placeId)}/${beaconId}`
}

export function createBeacon(placeId: string) {
  return `${beacons(placeId)}/create`
}

export function editBeacon(placeId: string, beaconId: string) {
  return `${beacon(placeId, beaconId)}/edit`
}

export function counters(placeId: string) {
  return `${place(placeId)}/counters`
}

export function counter(placeId: string, counterId: string) {
  return `${counters(placeId)}/${counterId}`
}

export function createCounter(placeId: string) {
  return `${counters(placeId)}/create`
}

export function editCounter(placeId: string, counterId: string) {
  return `${counter(placeId, counterId)}/edit`
}

export function reports() {
  return '/reports'
}

export function appointmentsLine(placeId: string, lineId: string) {
  return `${lines(placeId)}/${lineId}/appointments`
}

export function appointmentsToCancelLine(placeId: string, lineId: string) {
  return `${lines(placeId)}/${lineId}/appointmentsToCancel`
}

export function viewAppointmentLine(placeId: string, lineId: string, appointmentId: string) {
  return `${appointmentsLine(placeId, lineId)}/${appointmentId}`
}

export function createAppointmentLine(placeId: string, lineId: string) {
  return `${appointmentsLine(placeId, lineId)}/create`
}

export function appointments() {
  return `/appointments`
}

export function createAppointment() {
  return `${appointments()}/create`
}

export function createAppointmentFromService() {
  return `${appointments()}/create-from-service`
}

export function editAppointment(appointmentId: string) {
  return `${appointments()}/${appointmentId}/edit`
}

export function viewAppointment(appointmentId: string) {
  return `${appointments()}/${appointmentId}`
}

export function journal() {
  return `${reports()}/journal`
}

export function eventsFlow() {
  return `${reports()}/eventsFlow`
}

export function dailyStats() {
  return `${reports()}/dailyStats`
}

export function dailyStatsCustomers() {
  return `${reports()}/dailyStats/customers`
}

export function customers() {
  return `${reports()}/customers`
}

export function users() {
  return '/users'
}

export function createUser() {
  return `${users()}/create`
}

export function editUser(userId: string) {
  return `${users()}/${userId}/edit`
}

export function campaigns() {
  return `/campaigns`
}

export function campaign(campaignId: string) {
  return `${campaigns()}/${campaignId}`
}

export function editCampaign(campaignId: string, mode: string) {
  return `${campaign(campaignId)}/${mode}`
}

export function createCampaign() {
  return `${campaigns()}/create`
}

export function terminals(placeId: string) {
  return `/${placeId}/terminals`
}

export function terminal(terminalId: string, placeId: string) {
  return `${terminals(placeId)}/${terminalId}`
}

export function editTerminal(terminalId: string, placeId: string) {
  return `${terminal(terminalId, placeId)}/edit`
}

export function createTerminal(placeId: string) {
  return `${terminals(placeId)}/create`
}

export function advertisements(campaignId: string) {
  return `${campaign(campaignId)}/advertisements`
}

export function editAdvertisement(campaignId: string, advertisementId: string) {
  return `${advertisements(campaignId)}/${advertisementId}/edit`
}

export function createAdvertisement(campaignId: string) {
  return `${advertisements(campaignId)}/create`
}

export function lineMessageTemplates(placeId: string, lineId: string) {
  return `${lines(placeId)}/${lineId}/templates`
}

export function discreteLineMonitoring(placeId: string, lineId: string) {
  return `${lines(placeId)}/${lineId}/monitoring`
}

export function lineScheduler(placeId: string, lineId: string) {
  return `${lines(placeId)}/${lineId}/scheduler`
}

export function lineAppointmentScheduler(placeId: string, lineId: string) {
  return `${lines(placeId)}/${lineId}/appointmentScheduler`
}

export function discreteLineMonitoringCreate(placeId: string, lineId: string) {
  return `${discreteLineMonitoring(placeId, lineId)}/create`
}

export function discreteLineMonitoringEdit(placeId: string, lineId: string, positionId: string) {
  return `${discreteLineMonitoring(placeId, lineId)}/${positionId}/edit`
}

export function discreteLineMonitoringPosition(placeId: string, lineId: string, positionId: string) {
  return `${discreteLineMonitoring(placeId, lineId)}/${positionId}`
}

export function lineMonitoring(placeId: string, lineId: string) {
  return `${lines(placeId)}/${lineId}/lineMonitoring`
}

export function lineMonitoringState(placeId: string, lineId: string) {
  return `${lines(placeId)}/${lineId}/lineMonitoringState`
}

export function lineMonitoringPosition(placeId: string, lineId: string, positionId: string) {
  return `${lineMonitoring(placeId, lineId)}/${positionId}`
}

export function lineMonitoringEdit(placeId: string, lineId: string, positionId: string) {
  return `${lineMonitoring(placeId, lineId)}/${positionId}/edit`
}

export function lineMonitoringCreate(placeId: string, lineId: string) {
  return `${lineMonitoring(placeId, lineId)}/create`
}

export function translations() {
  return '/translations'
}

export function createTranslation() {
  return '/translations/create'
}

export function editTranslation(translationId: string) {
  return `/translations/${translationId}`
}

export function appointmentPlaceList() {
  return `/appointmentPlaceList`
}

export function tags() {
  return '/tags'
}

export function createTag() {
  return `${tags()}/create`
}

export function editTag(tagId: string) {
  return `${tags()}/${tagId}/edit`
}

export function lineDelay(placeId: string, lineId: string) {
  return `${lineMonitoring(placeId, lineId)}/delay`
}

export function checkpointDelay(placeId: string, lineId: string, checkpointId: string) {
  return `${checkpointMonitoring(placeId, lineId, checkpointId)}/delay`
}
