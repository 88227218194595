const availableLanguages: {
  locale: string
  name: string
}[] = [
  {
    locale: 'en',
    name: 'English'
  },
  {
    locale: 'fr',
    name: 'Français'
  },
  {
    locale: 'es',
    name: 'Español'
  },
  {
    locale: 'it',
    name: 'Italiano'
  },
  {
    locale: 'de',
    name: 'Deutsch'
  },
  {
    locale: 'ru',
    name: 'Русский'
  },
  {
    locale: 'pl',
    name: 'Polski'
  },
  {
    locale: 'ro',
    name: 'Română'
  },
  {
    locale: 'nl',
    name: 'Nederlands'
  },
  {
    locale: 'ty',
    name: 'Reo Tahiti'
  }
]

export { availableLanguages }
