import { LineInactiveState } from 'shared/models'
import { useTranslation } from 'shared/i18n/translate'
import { NavLink } from 'react-router-dom'
import { Button } from '@mui/material'
import { checkpoints, editLine, checkpointHost } from 'pages/nav'
import { useAppSelector } from 'store'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { CheckpointInactiveReasonProps } from '../types'

function CheckpointInactiveReason({ reason, shopId, lineId, checkpointId }: CheckpointInactiveReasonProps) {
  const { tr } = useTranslation()

  const { canOpenCheckpoints, canCloseCheckpoints } = useAppSelector(getPermissionsSelector)

  const inactiveReasonsTranslation = tr.lineMonitoring.inactiveReasons

  return (
    <div style={{ marginTop: '1rem' }}>
      {canOpenCheckpoints && reason === LineInactiveState.NoActiveCheckpoints && (
        <div>
          <div>{inactiveReasonsTranslation.checkpointClosed.message}.</div>
          <NavLink
            style={{ textDecoration: 'none' }}
            to={checkpointHost(String(shopId), String(lineId), String(checkpointId))}
          >
            <Button style={{ marginTop: '1rem' }} variant="contained" color="primary">
              {inactiveReasonsTranslation.checkpointClosed.action}
            </Button>
          </NavLink>
        </div>
      )}
      {canCloseCheckpoints && reason === LineInactiveState.OperatingTimeIsOver && (
        <div>
          <div>{inactiveReasonsTranslation.operatingTimeIsOver.message}.</div>
          <NavLink style={{ textDecoration: 'none' }} to={checkpoints(String(shopId), String(lineId))}>
            <Button style={{ marginTop: '1rem' }} variant="contained" color="primary">
              {inactiveReasonsTranslation.operatingTimeIsOver.action}
            </Button>
          </NavLink>
        </div>
      )}
      {canCloseCheckpoints && reason === LineInactiveState.Overcharged && (
        <div>
          <div>{inactiveReasonsTranslation.overcharged.message}.</div>
          <NavLink style={{ textDecoration: 'none' }} to={checkpoints(String(shopId), String(lineId))}>
            <Button style={{ marginTop: '1rem' }} variant="contained" color="primary">
              {inactiveReasonsTranslation.overcharged.action}
            </Button>
          </NavLink>
        </div>
      )}
      {canCloseCheckpoints && reason === LineInactiveState.ReservationTimeNotCame && (
        <div>
          <div>{inactiveReasonsTranslation.reservationTimeNotCame.message}</div>
          <NavLink style={{ textDecoration: 'none' }} to={editLine(String(shopId), String(lineId))}>
            <Button style={{ marginTop: '1rem' }} variant="contained" color="primary">
              {inactiveReasonsTranslation.reservationTimeNotCame.action}
            </Button>
          </NavLink>
        </div>
      )}
    </div>
  )
}

export { CheckpointInactiveReason }
