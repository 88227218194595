import { createNanoEvents } from 'nanoevents'

const emitter = createNanoEvents()
const TimersKey = 'PositionTimers'
const timeoutIds: Record<string, NodeJS.Timeout> = {}

function getPositionTimers() {
  const positionTimersFromStorage = localStorage.getItem(TimersKey)

  return positionTimersFromStorage ? JSON.parse(positionTimersFromStorage) || {} : {}
}

function positionTimersIsNotValid() {
  const timers = getPositionTimers()

  return Object.keys(timers).some((x) => {
    return !timers[x] || !timers[x].timeout || !timers[x].timestamp
  })
}

if (positionTimersIsNotValid()) {
  localStorage.removeItem(TimersKey)
}

function updatePositionTimers(timers) {
  localStorage.setItem(TimersKey, JSON.stringify(timers))
}

export function shouldPositionIgnore(position) {
  const timers = getPositionTimers()

  if (timers[position.id]) {
    if (timers[position.id].timestamp + timers[position.id].timeout * 1000 < Date.now()) {
      delete timers[position.id]
      updatePositionTimers(timers)
      return false
    }
    return true
  }

  return false
}

export function putOffPosition(position, timeout) {
  const timers = getPositionTimers()
  timers[position.id] = {
    timestamp: Date.now(),
    timeout: timeout
  }

  updatePositionTimers(timers)
  setPositionTimeout(position.id, timeout)
}

export function onTimeout(cb) {
  return emitter.on('timeout', cb)
}

function setPositionTimeout(positionId, timeout) {
  if (!timeoutIds[positionId]) {
    timeoutIds[positionId] = setTimeout(() => {
      delete timeoutIds[positionId]
      emitter.emit('timeout', positionId)
    }, timeout * 1000)
  }
}

window.addEventListener('storage', (ev) => {
  if (ev.key === TimersKey && ev.newValue) {
    const timers = JSON.parse(ev.newValue)
    Object.keys(timers).forEach((id) => setPositionTimeout(id, timers[id].timeout))
  }
})
