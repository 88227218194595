import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { getGroupPlacesSelector, getSinglePlaceSelector, getUserSelector } from 'store/selectors/userSelectors'
import { BreadcrumbsContainer } from './styled'
import { useAppSelector } from 'store'
import { Breadcrumb } from 'features/breadcrumbs/breadcrumb'
import { useMemo } from 'react'
import { getBreadcrumbsSelector } from 'store/selectors/breadcrumbsSelectors'

function Breadcrumbs() {
  const permissions = useAppSelector(getPermissionsSelector)
  const user = useAppSelector(getUserSelector)
  const isMultiplePlaces = useAppSelector(getGroupPlacesSelector)
  const isSinglePlace = useAppSelector(getSinglePlaceSelector)
  const breadcrumbs = useAppSelector(getBreadcrumbsSelector)

  const insecurePass = useMemo(() => user?.insecurePassword, [breadcrumbs])

  const singleShop = useMemo(
    () =>
      isSinglePlace &&
      !permissions.manageReports &&
      !permissions.manageCampaigns &&
      !permissions.manageTerminals &&
      !permissions.canManageUserAccounts &&
      !permissions.manageMessagesTemplates &&
      !permissions.manageTranslations,
    [breadcrumbs]
  )

  const crumbs = useMemo(
    () =>
      (breadcrumbs || []).filter?.((c) => {
        const hideShop = singleShop && c.id === 'place'
        const hideShops = (singleShop || !isMultiplePlaces) && c.id === 'places'

        return !hideShop && !hideShops
      }),
    [breadcrumbs]
  )

  if (insecurePass) {
    return <BreadcrumbsContainer />
  }

  return <Breadcrumb crumbs={crumbs} />
}

export { Breadcrumbs }
