import { useState, createElement, Children } from 'react'
import { Input } from './input'
import { TextFieldMessage } from './text-field-message'
import { TextFieldProps } from './types'
import { MessageWrapper } from './styled'

function TextField<T>(props: TextFieldProps<T>) {
  const [errorMessages, setErrorMessages] = useState<any>(null)

  const messageChildren: any = []
  const infoMessagesToShow: any = []

  if (props.children) {
    Children.forEach(props.children, (child) => {
      if (child.type.displayName === TextFieldMessage.displayName) {
        messageChildren.push(child)

        if (child.props.type === 'info') {
          infoMessagesToShow.push(child)
        }
      }
    })
  }

  function onChange(value: T) {
    const errorMessagesToShow: any[] = []

    messageChildren.forEach((messageChild: any, index) => {
      const children = createElement(TextFieldMessage, {
        ...messageChild.props,
        displayMessage: value ? !messageChild.props.check(value) : false,
        key: index
      })

      if (children.props.type === 'error') {
        errorMessagesToShow.push(children)
      }
    })

    setErrorMessages(errorMessagesToShow)
    props.onChange?.(value)
  }

  return (
    <section style={props.fullWidth ? { ...props.style, width: '100%' } : props.style} id={props.id}>
      <Input {...props} onChange={onChange} />
      <MessageWrapper>{errorMessages}</MessageWrapper>
      <MessageWrapper>{infoMessagesToShow}</MessageWrapper>
    </section>
  )
}

export default TextField
export { TextField }
