import { Translations } from 'shared/i18n/translation-types'
import { JournalActionModel, JournalModel, PositionActionType, SMSActionType } from 'shared/models'

export function getServiceDurationTime(timeValue: number, translation: Translations) {
  const tr = translation.journal
  const hoursValue = Math.floor(timeValue / 60 / 60)
  const minutesValue = Math.floor(timeValue / 60 - hoursValue * 60)
  const secondsValue = timeValue % 60

  const result: string[] = []

  if (hoursValue) {
    result.push(tr.hours(hoursValue))
  }
  if (minutesValue) {
    result.push(tr.time(minutesValue))
  }
  if (secondsValue) {
    result.push(tr.seconds(secondsValue))
  }
  return result.join(' ')
}

export function getActionText(action: JournalActionModel, translation: Translations, d: JournalModel) {
  const actionType = action.type

  const tr = translation.journal

  const operatorName = [action.operator?.firstName, action.operator?.lastName].filter(Boolean).join(' ')

  const callLateMs = action.callLateMs
  const timeType = callLateMs > 60000 ? 'late' : callLateMs < -60000 ? 'inAdvance' : 'onTime'

  const smsUpdate = { ...action.smsUpdate }

  if (smsUpdate?.statusDetails) {
    const newStatus =
      tr.updateSmsStatusses[smsUpdate?.statusDetails.toLowerCase().replace('[', '').replace(']', '')] ||
      smsUpdate?.statusDetails
    smsUpdate.statusDetails = newStatus
  }

  const map = {
    [PositionActionType.CreateAppointment]: tr.createAppointment(operatorName, action.actionInitiator || ''),
    [PositionActionType.UpdateAppointment]: tr.updateAppointment(operatorName, action.actionInitiator || ''),
    [PositionActionType.UpdatePosition]: tr.updatePosition(operatorName),
    [PositionActionType.InPlace]: tr.inPlace(operatorName),
    [PositionActionType.JoinLine]: tr.joinLine(operatorName, action.actionInitiator || ''),
    [PositionActionType.ReJoinLine]: tr.rejoinLine(operatorName),
    [PositionActionType.FailedJoin]: tr.failedJoin,
    [PositionActionType.WelcomeSMS]: tr.smsCall(
      SMSActionType.WelcomeSMS,
      action?.sms?.welcome?.status || 'error',
      action?.sms?.welcome?.statusDetails || ''
    ),
    [PositionActionType.CallToCheckpoint]: tr.callToCheckpoint(d.assignedCheckpoint.name, operatorName, {
      timeType,
      callLateMs,
      actionInitiator: action.actionInitiator
    }),
    [PositionActionType.CallSMS]: tr.smsCall(
      SMSActionType.CallSMS,
      action?.sms?.call?.status || 'error',
      action?.sms?.call?.statusDetails || ''
    ),
    [PositionActionType.MoveToCheckpoint]: tr.moveToCheckpoint,
    [PositionActionType.EnterToCheckpointZone]: tr.enterToCheckpointZone(operatorName),
    [PositionActionType.ExitFromCheckpointZone]: tr.exitFromCheckpointZone,
    [PositionActionType.GenerateMobileCode]: tr.generateMobileCode,
    [PositionActionType.StartService]: tr.startService(
      operatorName,
      getServiceDurationTime(action.waitingForServiceStartedDuration || 0, translation),
      action.actionInitiator
    ),
    [PositionActionType.FinishService]: tr.finishService(
      operatorName,
      getServiceDurationTime(action.realServiceDuration || 0, translation),
      action.actionInitiator
    ),
    [PositionActionType.TakeExtraTime]: tr.takeExtraTime,
    [PositionActionType.LeaveLine]: tr.leaveLine,
    [PositionActionType.Removed]: tr.removed(operatorName, action.actionInitiator),
    [PositionActionType.CallPhoneNumber]: tr.callPhoneNumber(operatorName, action.customer?.phoneNumber),
    [PositionActionType.PositionProgression]: tr.positionProgression(operatorName),
    [PositionActionType.SendPushNotification]: tr.sendPushNotification,
    [PositionActionType.AcceptationExpired]: tr.acceptationExpired(operatorName),
    [PositionActionType.AcceptationAccept]: tr.acceptationAccept(operatorName),
    [PositionActionType.AcceptationRefuse]: tr.acceptationRefuse(operatorName),
    [PositionActionType.AcceptationRequested]: tr.acceptationRequested(operatorName),
    [PositionActionType.AcceptationCancelled]: tr.acceptationCancelled(operatorName),
    [PositionActionType.SurveyRequestSent]: tr.surveyRequestSent(
      action.surveySentToEmail,
      action.surveySentToPhoneNumber
    ),
    [PositionActionType.SurveyLinkClicked]: tr.surveyLinkClicked(),
    [PositionActionType.UpdateSmsStatus]: tr.updateSmsStatus(smsUpdate),
    [PositionActionType.ValidForService]: tr.validForService(operatorName),
    [PositionActionType.StayInLineNotificationSent]: tr.stayInLineNotificationSent(),
    [PositionActionType.StayInLineConfirmationReceived]: tr.stayInLineConfirmationReceived(),
    [PositionActionType.SurveyNotificationAborted]: tr.surveyNotificationAborted(),
    [PositionActionType.TransferPosition]: tr.transferPosition(operatorName, action.checkpointName)
  }

  return map[actionType] || tr.unexpectedEvent(actionType)
}
