import { useTranslation } from 'shared/i18n/translate'

export function timeText(
  operatingFrom: number | null,
  operatingTo: number | null,
  dinnerFrom?: number,
  dinnerTo?: number
) {
  const { dateFormatter } = useTranslation()
  const isOperating = !!(operatingTo && operatingFrom)
  const hasDinner = !!(dinnerFrom && dinnerTo)

  let timing = ''

  if (isOperating) {
    if (hasDinner) {
      timing = ` — ${dateFormatter(operatingFrom, 'time')} - ${dateFormatter(dinnerFrom, 'time')} x ${dateFormatter(
        dinnerTo,
        'time'
      )} - ${dateFormatter(operatingTo, 'time')}`
    } else {
      timing = ` — ${dateFormatter(operatingFrom, 'time')} - ${dateFormatter(operatingTo, 'time')}`
    }
  }

  return timing
}
