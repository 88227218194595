export type ImpactOnServiceType = 'Precentage' | 'Value' | 'None'

export enum InternalService {
  'Break' = 911000000001,
  'LineDelay' = 911000000002
}

export type CheckListItem = {
  id?: number
  newId?: number
  name: string
  shortName: string
  mandatory: boolean
  impactOnServiceType: ImpactOnServiceType
  impactOnServiceValue?: number
}

export interface ServiceModel extends ServiceSmallModel {
  newId?: number
  isPrivate?: boolean
  supportProgressivelyBooking?: boolean
  shortName?: string
  description?: string
  supportCustomParticipantsNumber?: boolean
  customParticipantsNumber?: number
  supportCustomParticipantsQuestion?: boolean
  customParticipantsQuestion?: string
  supportCustomDurationPerParticipantNumber?: boolean
  customDurationPerParticipantNumber?: {
    participantNumber: number
    duration: number
  }[]
  supportCheckList?: boolean
  checkList?: CheckListItem[]
  priority?: number
  displayName?: string
}

export interface ServiceSmallModel {
  id?: string | number
  name: string
  duration: number
}
