import axios from 'axios'
import { BeaconAPI } from 'shared/models'

const beacon: BeaconAPI = {
  getBeaconList(placeId, config) {
    return axios.get('getBeaconList', { params: { shopId: placeId }, ...config })
  },
  getCheckpointBeaconList(placeId, checkpointId, config) {
    return axios.get('getCheckpointBeaconList', { params: { shopId: placeId, checkpointId }, ...config })
  },
  getBeacon(id, placeId, config) {
    return axios.get('getBeacon', { params: { id, shopId: placeId }, ...config })
  },
  createBeacon(data, config) {
    return axios.post('createBeacon', data, config)
  },
  updateBeacon(data, config) {
    return axios.put('updateBeacon', data, config)
  },
  deleteBeacon(id, config) {
    return axios.delete('deleteBeacon', { data: id, headers: { 'Content-Type': 'application/json' }, ...config })
  },
  setActiveState(data, config) {
    return axios.post('setBeaconActiveState', data, config)
  }
}

export default beacon
