import { ReactNode, CSSProperties, forwardRef, Ref, ReactElement } from 'react'
import css from './list-item.module.scss'
import { withMods } from 'shared/utils/cssm'
import { addTestAttr, injectTestAttr } from 'shared/utils/test-attr'

const mod = withMods(css)

export type ListItemProps<T> = {
  id?: number | string
  primaryText: string | ReactNode
  secondaryText?: string | ReactNode
  tertiaryText?: string | ReactNode
  selected?: boolean
  notActive?: boolean
  controlsOnBottom?: boolean
  controls?: ReactNode[]
  entity?: T
  onSelect?: (el: any) => void
  avatar?: ReactNode
  handleData?: (el: T) => any
  itemStyle?: CSSProperties
  avatarOnTop?: boolean
  style?: CSSProperties
  stopControlsEvents?: boolean
  dnd?: boolean
}

function EntityItem<T>(props: ListItemProps<T>, ref: Ref<HTMLDivElement>) {
  const { avatar, secondaryText, tertiaryText, controls, primaryText } = props
  const { notActive, itemStyle, selected, avatarOnTop } = props
  const { onSelect, handleData, entity, id, controlsOnBottom } = props

  function handleSelect() {
    if (props.dnd) {
      return
    }

    const el = handleData && entity ? handleData(entity) : { id: id || 0 }

    if (el) {
      onSelect?.(el)
    }
  }

  return (
    <div
      ref={ref}
      {...injectTestAttr(props)}
      className={mod.root({ notActive, selected, avatarOnTop })}
      style={{ ...itemStyle, ...props.style }}
    >
      {!!avatar && <div className={mod.avatar({ onTop: avatarOnTop })}>{avatar}</div>}
      <div {...addTestAttr('ListItem-Text')} className={props.dnd ? css.textDnd : css.text} onClick={handleSelect}>
        <div className={css.primary}>{primaryText}</div>
        {!!secondaryText && <div className={css.secondary}>{secondaryText}</div>}
        {!!tertiaryText && <div className={css.tertiary}>{tertiaryText}</div>}
      </div>
      {!!controls && (
        <div
          className={mod.controls({ onBottom: controlsOnBottom })}
          style={{ pointerEvents: props.stopControlsEvents ? 'none' : 'unset' }}
        >
          {controls.map((control, i) => (
            <div key={i}>{control}</div>
          ))}
        </div>
      )}
      <div className={css.bg} />
    </div>
  )
}

export default forwardRef(EntityItem) as <T>(p: ListItemProps<T> & { ref?: Ref<HTMLDivElement> }) => ReactElement
