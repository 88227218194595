import { Divider } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import PositionActionComponent from '../../components/position-action'
import { HistoryActionProps } from './types'
import HistoryIcon from '@mui/icons-material/History'

function History(props: HistoryActionProps) {
  const { tr } = useTranslation()

  return (
    <>
      {!props.isCardView && <Divider />}
      <PositionActionComponent
        isCardView={props.isCardView}
        onSubmit={props.onSubmit}
        icon={<HistoryIcon fontSize="small" />}
      >
        {tr.positionActions.history}
      </PositionActionComponent>
    </>
  )
}

export { History }
