import { CSSProperties, ReactNode } from 'react'
import { withMods } from 'shared/utils/cssm'
import css from './sub-bar.module.scss'

type Props = {
  smallScreen?: boolean
  children?: ReactNode
  style?: CSSProperties
}

const mod = withMods(css)

function BarComponent(props: Props) {
  const { smallScreen = false, children } = props

  return (
    <section className={mod.root({ smallScreen: smallScreen })} style={props.style}>
      {children}
    </section>
  )
}

type SubBarControlProps = {
  title?: string
  children?: ReactNode
}

function SubBarControl(props: SubBarControlProps) {
  const { children, title } = props

  return (
    <div className={css.control}>
      <div className={css.title}>{title}</div>
      {children}
    </div>
  )
}

export default BarComponent
export { BarComponent as SubBar, SubBarControl }
