import { useCallback, useEffect, useState } from 'react'
import { TranslationList } from './dumb/translation-list'
import { editTranslation, createTranslation } from 'pages/nav'
import { useTranslation } from 'shared/i18n/translate'
import { getIsolatedBrandList } from 'store/actions/brand-action-creators'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { IconButton, AppBar, Toolbar, TextField, InputAdornment } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import Card from 'shared/ui-kit/card'
import { addTestAttr } from 'shared/utils/test-attr'
import Container from 'shared/ui-kit/container'
import PageLoader from 'shared/ui-kit/page-loader'
import { TextTranslateModel } from 'shared/models'
import { useHistory, useLocation } from 'react-router'
import { translations } from 'shared/api'
import { getBrandListSelector } from 'store/selectors/brandListSelectors'
import { BarContent } from './styled'
import { useAppDispatch, useAppSelector } from 'store'
import debounce from 'lodash/debounce'
import { BrandFilter } from 'features/filters'
import { Clear as ClearIcon } from '@mui/icons-material'
import { SubBar } from 'shared/ui-kit/sub-bar'
import { Bar } from 'shared/ui-kit/bar'

function TranslationListSmart() {
  const history = useHistory()
  const location = useLocation()

  const [list, setList] = useState<TextTranslateModel[] | null>(null)
  const [brandId, setBrandId] = useState<string | number | null>(
    new URLSearchParams(location.search).get('brand') || null
  )
  const [term, setTerm] = useState<string>(new URLSearchParams(location.search).get('term') || '')
  const { tr } = useTranslation()
  const brands = useAppSelector(getBrandListSelector)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const crumb = getCrumbs(tr.breadcrumbs)
    dispatch(setBreadcrumbs([crumb.home(), crumb.translations()]))

    getTranslationList(term, brandId)

    if (!brands) {
      dispatch(getIsolatedBrandList())
    }
  }, [])

  useEffect(() => {
    const params = new URLSearchParams()
    if (term) {
      params.set('term', term)
    }
    if (brandId) {
      params.set('brand', String(brandId))
    }

    history.replace({ search: params.toString() })
  }, [term, brandId])

  async function getTranslationList(term: string, brandId: string | number | null) {
    try {
      const res = await translations.getTranslationList({ term, brandId })
      setList(res.data)
    } catch (err) {
      console.warn(err)
    }
  }

  const getTranslationListDebounce = useCallback(debounce(getTranslationList, 300), [])

  function handleCreateTrnslation() {
    history.push(createTranslation())
  }

  function handleEditTrnslation(data: TextTranslateModel) {
    history.push(editTranslation(String(data.id)))
  }

  function handleChangeTerm(ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setTerm(ev.target.value)
    getTranslationListDebounce(ev.target.value, brandId)
  }

  function handleTermClear() {
    setTerm('')
    getTranslationList('', brandId)
  }

  function handleBrandChange(brandId: string | number) {
    setBrandId(brandId)
    getTranslationList(term, brandId)
  }

  if (!brands || list === null) {
    return <PageLoader />
  }

  return (
    <>
      <Bar>
        <div style={{ width: '100%', maxWidth: '640px' }}>
          <SubBar style={{ display: 'flex', gap: '0.5rem', padding: '0.5rem 0' }}>
            <IconButton {...addTestAttr('TranslationList-AddButton')} onClick={handleCreateTrnslation}>
              <AddIcon />
            </IconButton>
            {brands && <BrandFilter brandId={brandId} brands={brands} onChange={handleBrandChange} />}
            <TextField
              style={{ flex: 1, minWidth: '130px' }}
              value={term}
              onChange={handleChangeTerm}
              placeholder={tr.userList.search}
              label={tr.userList.search}
              autoFocus
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton disabled={!term} onMouseDown={handleTermClear}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </SubBar>
        </div>
      </Bar>
      <Container>
        <Card>
          <TranslationList data={list} brands={brands} onSelect={handleEditTrnslation} />
        </Card>
      </Container>
    </>
  )
}

export default TranslationListSmart
