import axios from 'axios'
import { StaffManagementAPI } from 'shared/models'

const staffMananement: StaffManagementAPI = {
  getStaffManagement(data, config) {
    return axios.get('getStaffManagement', { params: data, ...config })
  },
  getStaffManagementRecord(data, config) {
    return axios.get('getStaffManagementRecord', { params: data, ...config })
  },
  uploadStaffManagement(data, config) {
    return axios.post('uploadStaffManagement', data, config)
  },
  createStaffManagementRecord(data, config) {
    return axios.post('createStaffManagementRecord', data, config)
  },
  updateStaffManagementRecord(data, config) {
    return axios.put('updateStaffManagementRecord', data, config)
  },
  deleteStaffManagementRecord(id, config) {
    return axios.delete('deleteStaffManagementRecord', {
      data: id,
      headers: { 'Content-Type': 'application/json' },
      ...config
    })
  },
  duplicateStaffManagements(data, config) {
    return axios.post('duplicateStaffManagements', data, config)
  }
}

export default staffMananement
