import { IconButton } from '@mui/material'
import styled from '@emotion/styled'

const FormWrapper = styled.div`
  padding: 1rem;
`

const StepLabelText = styled.div`
  padding-left: 16;
`

const QRWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const QRIconButton = styled(IconButton)`
  position: absolute;
  margin-top: -2rem;
`

const AdditionalsWrapper = styled.div`
  background: #f5f5f5;
  border-radius: 4px;
  padding: 0.5rem;
  margin: 1rem 0;
`

const AdditionalsQRWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const QRReaderWrapper = styled.div``

const QRTextInfo = styled.div`
  padding: 2rem;
`

const QRTitle = styled.div`
  text-align: center;
  font-size: 24px;
`

const QRDescription = styled.div`
  margin-top: 1rem;
  font-size: 14px;
  text-align: center;
`

const Additionals = styled.div`
  padding: 1rem;
`

const ErrorMessageWrapper = styled.div`
  margin: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const UserItem = styled.div`
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 0 1px;
  transition: background 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  font-weight: 300;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
`

const UserSearchValue = styled.div`
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 0 1px;
  transition: background 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  background: rgba(0, 0, 0, 0.04);
  font-weight: 300;

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }
`

const PositionTypeSelectWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`

const TypeIconWrapper = styled.div`
  opacity: 0.81;
  margin-right: 0.5rem;
  transition: all 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  display: flex;
`

const PositionTypeItem = styled.div<{ $active?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #dadada;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  flex: 1;
  min-width: 200px;
  box-sizing: border-box;
  transition: all 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

  &:hover {
    background: hsl(0, 0%, 98%);
  }

  ${(props) => props.$active && { color: '#db214d', border: '1px solid #db214d' }}
`

const AnonumousPositionSwitchContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0.5rem 0 -0.5rem;
`

export {
  FormWrapper,
  StepLabelText,
  QRWrapper,
  QRIconButton,
  AdditionalsWrapper,
  AdditionalsQRWrapper,
  QRReaderWrapper,
  QRTextInfo,
  QRTitle,
  QRDescription,
  Additionals,
  ErrorMessageWrapper,
  UserItem,
  UserSearchValue,
  PositionTypeSelectWrapper,
  TypeIconWrapper,
  PositionTypeItem,
  AnonumousPositionSwitchContainer
}
