import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { useTranslation } from 'shared/i18n/translate'
import { editCampaign, advertisements as advertisementsLink, place } from 'pages/nav'
import { campaign as campaignApi } from 'shared/api'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { TileContainer, Tile } from 'features/tile'
import { Container } from 'shared/ui-kit/container'
import { CampaignView } from 'pages/campaign/dumb/campaign-view'
import { Button, CircularProgress } from '@mui/material'
import { BrightnessAuto as AdIcon, Edit as ModeEdit } from '@mui/icons-material'
import Card from 'shared/ui-kit/card'
import EntityActions from 'shared/ui-kit/entity-actions'
import { useAppDispatch } from 'store'
import { useQuery } from 'react-query'
import { useDataProvider } from 'features/isolated-data-provider'

function SmartCampaignView() {
  const history = useHistory()
  const { campaignId } = useParams<{ campaignId: string }>()
  const dispatch = useAppDispatch()
  const { tr, locale } = useTranslation()
  const { places } = useDataProvider()

  const { data: res, isLoading } = useQuery(['campaign', campaignId], ({ signal }) =>
    campaignApi.getCampaign(campaignId, { signal })
  )

  useEffect(() => {
    setCrumbs()
  }, [res, locale])

  function setCrumbs() {
    if (res?.data) {
      const crumb = getCrumbs(tr.breadcrumbs)
      dispatch(setBreadcrumbs([crumb.home(), crumb.campaigns(), crumb.campaign([campaignId], res.data.name)]))
    }
  }

  return (
    <Container>
      {isLoading && (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress color="secondary" />
        </div>
      )}
      {!isLoading && !!res?.data && (
        <>
          <Card>
            <CampaignView
              data={res.data}
              shops={places}
              onShopSelect={(data) => history.push(place(String(data.id)))}
            />
            <EntityActions
              showButtons
              buttons={
                <Button onClick={() => history.push(editCampaign(campaignId, 'edit'))} startIcon={<ModeEdit />}>
                  {tr.entityView.editButton}
                </Button>
              }
              updateData={res.data?.lastUpdate}
            />
          </Card>
          <TileContainer>
            <Tile
              id={'advertisements'}
              title={tr.campaignView.advertisements(res.data.advertisementCount)}
              count={res.data?.advertisementCount}
              iconClass={AdIcon}
              linkTo={advertisementsLink(campaignId)}
            />
          </TileContainer>
        </>
      )}
    </Container>
  )
}

export default SmartCampaignView
