import { CSSProperties, useMemo } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { PositionStat } from 'features/position-stat'
import { CallSmsIcon } from 'shared/ui-kit/icons'
import FeedbackIcon from '@mui/icons-material/Feedback'
import ServiceDurationIcon from '@mui/icons-material/Build'
import ArrowRight from '@mui/icons-material/ArrowForward'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmptyRounded'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import SmsIcon from '@mui/icons-material/Sms'
import { PositionType, PositionState } from 'shared/models'
import TimeHelper, { msToMin } from 'shared/utils/time'
import isNumber from 'lodash/isNumber'
import TimeLeftStat from './time-left-stat'
import SentimentDissatisfied from '@mui/icons-material/SentimentDissatisfied'
import ColorDuration from '../utils/color-duration'
import { isUnreachPosition } from '../helpers'
import { PositionModel, SmsStatus } from 'shared/models'
import { useAppSelector } from 'store'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { PositionDurationsWrapper } from '../styled'

type Props = {
  data: PositionModel
  displayWaitingTimeEstimation?: boolean
  displayServiceDuration?: boolean
  className?: string
  style?: CSSProperties
}

function PositionDurationsComponent({
  data,
  displayWaitingTimeEstimation,
  displayServiceDuration,
  className,
  style
}: Props) {
  const { tr } = useTranslation()

  if (!data) {
    return null
  }

  const {
    expectedServiceTime,
    plannedServiceDuration,
    timeLeftOrOver,
    timeLeftOrOverState,
    timeFromServiceStart,
    joinedDuration,
    state: positionState,
    waitingToCallTime: waitingToCallTimeMs,
    type,
    waitingDelay
  } = data

  const { displayReducedInterface } = useAppSelector(getPermissionsSelector)

  const { displayWaitingToCallTime, waitingToCallTimeMin } = useMemo(() => {
    return {
      displayWaitingToCallTime:
        displayWaitingTimeEstimation && isNumber(waitingToCallTimeMs) && waitingToCallTimeMs < TimeHelper.dayMS,
      waitingToCallTimeMin: Math.floor(msToMin(waitingToCallTimeMs))
    }
  }, [waitingToCallTimeMs])

  const { displayJoinedDuration, joinedDurationMin } = useMemo(() => {
    return {
      displayJoinedDuration:
        isNumber(joinedDuration) &&
        type !== PositionType.Appointment &&
        [PositionState.Joined, PositionState.InLineHere, PositionState.ValidForService].includes(positionState),
      joinedDurationMin: Math.floor(msToMin(joinedDuration))
    }
  }, [positionState, type, joinedDuration])

  const { displayTimeFromServiceStart, timeFromServiceStartMin } = useMemo(() => {
    return {
      displayTimeFromServiceStart:
        isNumber(timeFromServiceStart) && timeFromServiceStart >= 0 && positionState === PositionState.InService,
      timeFromServiceStartMin: Math.floor(msToMin(timeFromServiceStart))
    }
  }, [positionState, timeFromServiceStart])

  const { displayWaitingDelay, waitingDelayMin } = useMemo(() => {
    return {
      displayWaitingDelay: isNumber(waitingDelay),
      waitingDelayMin: Math.floor(msToMin(waitingDelay))
    }
  }, [waitingDelay])

  const { displayPlannedServiceDuration, plannedServiceDurationMin } = useMemo(() => {
    return {
      displayPlannedServiceDuration:
        !!displayServiceDuration && isNumber(plannedServiceDuration) && plannedServiceDuration >= 0,
      plannedServiceDurationMin: Math.floor(msToMin(plannedServiceDuration))
    }
  }, [plannedServiceDuration])

  const { displayExpectedServiceTime, expectedServiceTimeMin } = useMemo(() => {
    return {
      displayExpectedServiceTime: isNumber(expectedServiceTime) && expectedServiceTime >= 0,
      expectedServiceTimeMin: Math.floor(msToMin(expectedServiceTime))
    }
  }, [expectedServiceTime, plannedServiceDuration])

  function getSMSColor(status: SmsStatus) {
    switch (status) {
      case 'error':
        return ColorDuration.extreme
      case 'sent':
        return ColorDuration.high
      case 'received':
        return ColorDuration.normal
      default:
        return ColorDuration.high
    }
  }

  return (
    <PositionDurationsWrapper className={className} style={style}>
      {!!displayJoinedDuration && (
        <PositionStat tooltipContent={tr.position.tooltips.timeInLine} icon={ArrowRight}>
          {tr.time.hmin(joinedDurationMin)}
        </PositionStat>
      )}
      {!!displayWaitingToCallTime && (
        <PositionStat tooltipContent={tr.position.tooltips.estimatedWaitTimeRemaining} icon={HourglassEmptyIcon}>
          {waitingToCallTimeMin === 0 ? `< ${tr.time.min(1)}` : tr.time.hmin(waitingToCallTimeMin)}
        </PositionStat>
      )}
      {!!displayTimeFromServiceStart && (
        <PositionStat tooltipContent={tr.position.tooltips.timeSinceServiceStart} icon={ArrowRight}>
          {tr.time.hmin(timeFromServiceStartMin)}
        </PositionStat>
      )}
      {!!displayWaitingDelay && !isUnreachPosition(data) && (
        <PositionStat
          tooltipContent={tr.position.tooltips.serviceStartLateEstimation}
          icon={SentimentDissatisfied}
          iconStyle={{ color: ColorDuration.extreme, opacity: 1 }}
        >
          {tr.time.hmin(waitingDelayMin)}
        </PositionStat>
      )}
      {!!displayPlannedServiceDuration && (
        <PositionStat tooltipContent={tr.position.tooltips.serviceDuration} icon={ServiceDurationIcon}>
          {tr.time.hmin(plannedServiceDurationMin)}
        </PositionStat>
      )}
      {positionState === PositionState.InService && (
        <TimeLeftStat timeLeftOrOver={timeLeftOrOver} timeLeftOrOverState={timeLeftOrOverState} />
      )}
      {!displayReducedInterface && displayExpectedServiceTime && (
        <PositionStat tooltipContent={tr.position.tooltips.estimatedTimeToEndService} icon={TrendingUpIcon}>
          {tr.time.hmin(expectedServiceTimeMin)}
        </PositionStat>
      )}
      {!!data.sms?.welcome && (
        <PositionStat
          tooltipContent={data.sms.welcome.statusDetails}
          icon={SmsIcon}
          iconStyle={{ fill: getSMSColor(data.sms.welcome.status), opacity: 1 }}
        >
          {tr.position.sms.welcome}
        </PositionStat>
      )}
      {!!data.sms?.reminder && (
        <PositionStat
          tooltipContent={data.sms.reminder.statusDetails}
          icon={FeedbackIcon}
          iconStyle={{ fill: getSMSColor(data.sms.reminder.status), opacity: 1 }}
        >
          {tr.position.sms.reminder}
        </PositionStat>
      )}
      {!!data.sms?.call && (
        <PositionStat
          tooltipContent={data.sms.call.statusDetails}
          icon={CallSmsIcon}
          iconStyle={{ fill: getSMSColor(data.sms.call.status), opacity: 1 }}
        >
          {tr.position.sms.call}
        </PositionStat>
      )}
    </PositionDurationsWrapper>
  )
}

export default PositionDurationsComponent
export const PositionDurations = PositionDurationsComponent
