import { ReactNode, CSSProperties } from 'react'
import ListItem from './list-item'
import { addTestAttr, injectTestAttr } from 'shared/utils/test-attr'

type ListData = {
  id: number | string
  primaryText: string | ReactNode
  secondaryText?: string | ReactNode
  tertiaryText?: string
  notActive?: boolean
  avatar?: any
  avatarOnTop?: boolean
  controlsOnBottom?: any
  controls?: any
  bottomParent?: React.ReactNode
  showBottomParent?: boolean
}

type Props<T> = {
  data: T[]
  dataConverter: (el: T, i: number) => ListData
  itemStyle?: CSSProperties
  style?: CSSProperties
  onSelect: (el: any, i: number) => void
  handleData?: (el: T) => any
  selectedId?: number | string
}

export default function List<T>(props: Props<T>) {
  const { style, data = [] } = props

  function dataConverter(entity) {
    return {
      id: entity.id,
      primaryText: entity.primaryText,
      secondaryText: entity.secondaryText,
      tertiaryText: entity.tertiaryText
    }
  }

  return (
    <div style={style} {...injectTestAttr(props)}>
      {data.map((entity, i) => {
        const d = (props.dataConverter || dataConverter)(entity, i)

        return (
          <div key={d.id}>
            <ListItem
              itemStyle={props.itemStyle}
              {...addTestAttr('ListItem-Id-' + d.id)}
              id={d.id}
              primaryText={d.primaryText}
              secondaryText={d.secondaryText}
              tertiaryText={d.tertiaryText}
              onSelect={(data) => props.onSelect(data, i)}
              avatar={d.avatar}
              avatarOnTop={d.avatarOnTop}
              controlsOnBottom={d.controlsOnBottom}
              controls={d.controls}
              entity={entity}
              handleData={props.handleData}
              notActive={d.notActive}
              selected={d.id === props.selectedId}
            />
            {!!d.showBottomParent && !!d.bottomParent && d.bottomParent}
          </div>
        )
      })}
    </div>
  )
}
