import { useTranslation } from 'shared/i18n/translate'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { PositionType } from 'shared/models'
import { Translations } from 'shared/i18n/translation-types'
import AddIcon from '@mui/icons-material/Add'
import { PositionTypeItem, PositionTypeSelectWrapper, TypeIconWrapper } from './styled'

export function getPositionTypeMap(tr: Translations) {
  return {
    [PositionType.ScreenCall]: {
      icon: <AddIcon />,
      name: tr.position.addPartcipant
    },
    [PositionType.Break]: {
      icon: <SettingsOutlinedIcon />,
      name: tr.position.technicalBreak
    }
  }
}

type Props = {
  onChange: (value: string) => void
  value?: string
  types: string[]
  onlyBreak?: boolean
}

function PositionTypeSelect(props: Props) {
  const { tr } = useTranslation()

  function handleSelect(type: PositionType) {
    return () => props.onChange(type)
  }

  const displayBreak = !!props.types.filter((el) => el === PositionType.Break).length

  return (
    <PositionTypeSelectWrapper>
      {!props.onlyBreak && (
        <PositionTypeItem
          $active={props.value === PositionType.ScreenCall}
          onClick={handleSelect(PositionType.ScreenCall)}
        >
          <TypeIconWrapper>
            <AddIcon fontSize="small" />
          </TypeIconWrapper>
          <div>{tr.position.addPartcipant}</div>
        </PositionTypeItem>
      )}
      {displayBreak && (
        <PositionTypeItem $active={props.value === PositionType.Break} onClick={handleSelect(PositionType.Break)}>
          <TypeIconWrapper>
            <SettingsOutlinedIcon fontSize="small" />
          </TypeIconWrapper>
          <div>{tr.position.technicalBreak}</div>
        </PositionTypeItem>
      )}
    </PositionTypeSelectWrapper>
  )
}

export default PositionTypeSelect
