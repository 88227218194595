import { TerminalType } from 'shared/models'
import * as Yup from 'yup'

const terminalEditValidationSchema = Yup.object().shape({
  name: Yup.string().required(),
  displayName: Yup.string().when('type', { is: TerminalType.Kiosk, then: (schema) => schema.required() }),
  repeatAlarm: Yup.string().when('displayPositions', {
    is: true,
    then: (schema) => schema.required().matches(/^[0-9]\d*$/)
  }),
  repeatAlarmSec: Yup.string().when('displayPositions', { is: true, then: (schema) => schema.required() }),
  waitingPositionsCount: Yup.string().when('displayPositions', { is: true, then: (schema) => schema.required() }),
  waitingTimeFilter: Yup.string().when('displayPositions', { is: true, then: (schema) => schema.required() }),
  zoomFactor: Yup.string().when('type', { is: TerminalType.InfoPad, then: (schema) => schema.required() }),
  shopIds: Yup.array().required().min(1),
  lineIds: Yup.array().required().min(1)
})

export { terminalEditValidationSchema }
