import { CSSProperties } from 'react'
import css from './style.module.scss'
import { injectTestAttr } from 'shared/utils/test-attr'

type Props = {
  title: string
  style?: CSSProperties
}

export function Title({ title, style, ...props }: Props) {
  return (
    <div className={css.root} style={style} {...injectTestAttr(props)}>
      {title}
    </div>
  )
}
