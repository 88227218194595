async function startMocking() {
  if (process.env.APP_FAKES === 'true') {
    const { worker } = await import('./setup-worker')

    await worker.start({
      onUnhandledRequest: ({ method, url }) => {
        if (url.pathname.startsWith('/api')) {
          console.warn(`Unhandled ${method} request to ${url}`)
        }
      }
    })
  }
}

export { startMocking }
