import { Button, CircularProgress, Divider, Typography } from '@mui/material'
import { Fragment, useEffect, useState } from 'react'
import { TerminalSmallModel, TerminalType } from 'shared/models'
import { ActionFormActions, ActionList, CenterProgress } from '../styled'
import { useTranslation } from 'shared/i18n/translate'
import { monitoring, terminal } from 'shared/api'
import { AcceptationPositionActionProps } from './types'
import PositionActionComponent from '../../components/position-action'
import AssignmentLateIcon from '@mui/icons-material/AssignmentTurnedIn'

function AcceptationPositionAction(props: AcceptationPositionActionProps) {
  const [isFetching, setIsFetching] = useState(false)
  const [acceptationLoading, setAcceptationLoading] = useState(false)
  const [acceptationTerminals, setAcceptationTerminals] = useState<TerminalSmallModel[]>([])

  const { tr } = useTranslation()

  useEffect(() => {
    if (props.acceptationMode) {
      getTerminals()
    }
  }, [props.acceptationMode])

  async function getTerminals() {
    const { lineId, placeId, positionId } = props

    setIsFetching(true)

    const data = await terminal.getTerminalList({ lineId, placeId, type: TerminalType.AcceptationScreen })
    const terminals = (data?.data || []).filter((terminal) => terminal.enabled !== false)

    setIsFetching(false)

    if (!terminals.length) {
      props.onBack?.()
      return
    }

    if (terminals.length === 1) {
      try {
        setAcceptationLoading(true)

        await monitoring.createAcceptation({ lineId, placeId, positionId, terminalId: terminals[0].id })

        setAcceptationLoading(false)
        props.onFinish?.()
      } catch (err) {
        setAcceptationLoading(false)
        props.onBack?.()
      }
    } else {
      setAcceptationTerminals(terminals)
    }
  }

  async function handleAcceptationCreate() {
    props.onAcceptationMode?.()
  }

  function handleAcceptationSelectTerminal(terminalId: string | number) {
    return async () => {
      setAcceptationLoading(true)

      const { lineId, placeId, positionId } = props

      try {
        await monitoring.createAcceptation({ lineId, placeId, positionId, terminalId })
        props.onFinish?.()
        setAcceptationLoading(false)
      } catch (err) {
        setAcceptationLoading(false)
      }
    }
  }

  async function handleAcceptationCancel() {
    setAcceptationLoading(true)

    const { lineId, placeId, positionId } = props

    try {
      await monitoring.cancelAcceptation({ lineId, placeId, positionId })
      props.onFinish?.()
      setAcceptationLoading(false)
    } catch (err) {
      setAcceptationLoading(false)
    }
  }

  if (!['Optional', 'Mandatory'].includes(props.generalConditionsAcceptation)) {
    return null
  }

  if (acceptationLoading || isFetching) {
    return (
      <CenterProgress>
        <CircularProgress />
      </CenterProgress>
    )
  }

  return (
    <>
      {!!props.acceptationMode && (
        <>
          <Typography variant="body1" fontWeight={500} fontSize="18px">
            {props.generalConditionsAcceptationCaption}
          </Typography>
          <ActionList $isCardView={props.isCardView}>
            {acceptationTerminals?.length > 0 &&
              acceptationTerminals.map((terminal, i) => (
                <Fragment key={i}>
                  {!props.isCardView && <Divider />}
                  <PositionActionComponent
                    isCardView={props.isCardView}
                    key={terminal.id}
                    onSubmit={handleAcceptationSelectTerminal(terminal.id)}
                  >
                    {terminal.name}
                  </PositionActionComponent>
                </Fragment>
              ))}
          </ActionList>
          <ActionFormActions $marginTop={props.isCardView ? '1.5rem' : '1rem'}>
            <Button variant="contained" color="primary" size="small" onClick={props.onBack}>
              {tr.positionActions.backButton}
            </Button>
          </ActionFormActions>
        </>
      )}
      {!props.acceptationMode && (
        <>
          {!props.isCardView && <Divider />}
          <PositionActionComponent
            isCardView={props.isCardView}
            onSubmit={props.acceptationState !== 'requested' ? handleAcceptationCreate : handleAcceptationCancel}
            disabled={props.acceptationState === 'accepted'}
            icon={<AssignmentLateIcon width={20} height={20} style={{ color: '#000' }} />}
          >
            {props.acceptationState !== 'requested'
              ? props.generalConditionsAcceptationCaption
              : tr.positionActions.acceptationCancel}
          </PositionActionComponent>
        </>
      )}
    </>
  )
}

export { AcceptationPositionAction }
