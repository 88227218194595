import { Divider } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import PositionActionComponent from '../../components/position-action'
import { OtherOptionsActionProps } from './types'

function OtherOptions(props: OtherOptionsActionProps) {
  const { tr } = useTranslation()

  return (
    <>
      {!props.isCardView && <Divider />}
      <PositionActionComponent isCardView={props.isCardView} onSubmit={props.onSubmit}>
        {tr.positionActions.openOtherActions}
      </PositionActionComponent>
    </>
  )
}

export { OtherOptions }
