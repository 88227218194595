import { useEffect, lazy, Suspense } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import Home from 'pages/home'
import Reports from 'pages/reports'
import Journal from 'pages/journal'
import EventsFlow from 'pages/events-flow/events-flow'
import DailyStats from 'pages/daily-stats'
import Customers from 'pages/customers'
import { DailyStatsCustomers } from 'pages/daily-stats-customers'
import { PlaceView, PlaceEdit, PlaceList, DaysOff, DataExport } from 'pages/place'
import { LineList, LineView, LineEdit } from 'pages/line'
import { CheckpointList, CheckpointEdit, CheckpointHost, CheckpointMonitoring } from 'pages/checkpoint'
import { StaffManagementList, StaffManagementEdit } from 'pages/staff-management'
import { ZoneList, ZoneEdit } from 'pages/zone'
import { BeaconList, BeaconEdit, BeaconView } from 'pages/beacon'
import { CounterList, CounterEdit, CounterView } from 'pages/counter'
import { UserList, UserEdit } from 'pages/user'
import { CampaignList, CampaignView, CampaignEdit } from 'pages/campaign'
import { TerminalList, TerminalView, TerminalEdit } from 'pages/terminal'
import { AdvertisementList, AdvertisementEdit } from 'pages/advertisement'
import { MessageTemplates } from 'pages/message-templates'
import DiscreteMonitoring from 'pages/discrete-monitoring'
import LineMonitoring from 'pages/line-monitoring'
import BroadcastAlerts from 'pages/broadcast-alerts'
import { Appointments, Appointment } from 'pages/appointments'
import TranslationList from 'pages/translations/translation-list'
import TranslationEdit from 'pages/translations/translation-edit'
import * as Nav from 'pages/nav'
import AppointmentPlaceList from 'pages/appointment-place-list'
import AppointmentView from 'pages/appointments/appointment-view'
import LineMonitoringEdit from 'pages/line-monitoring/line-monitoring-edit'
import DiscreteMonitoringEdit from 'pages/discrete-monitoring/discrete-line-monitoring-edit'
import CheckpointMonitoringEdit from 'pages/checkpoint/checkpoint-monitoring-edit'
import { LineMonitoringStatistics } from 'pages/line-monitoring-statistics'
import * as Sentry from '@sentry/react'
import { AppointmentFromService } from 'pages/appointments/appointment-from-service/appointment-from-service'
import Tags from 'pages/tags/tags'
import TagEdit from 'pages/tags/tag-edit'
import { LineDelay } from 'pages/line-delay'
import { getUserSelector } from 'store/selectors/userSelectors'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { WRoute } from 'features/render-router-component'
import { useAppSelector } from 'store'

const SchedulerMonitoring = lazy(() => import('pages/scheduler-monitoring/scheduler'))
const SchedulerAppointment = lazy(() => import('pages/scheduler-monitoring/scheduler-appointment'))

function MainRouting() {
  const user = useAppSelector(getUserSelector)
  const permissions = useAppSelector(getPermissionsSelector)

  useEffect(() => {
    if (user) {
      Sentry.setUser({ id: String(user.id), email: user.email, username: `${user.firstName} ${user.secondName}` })
    }

    return () => {
      Sentry.setUser(null)
    }
  }, [])

  const {
    manageTerminals,
    manageCampaigns,
    manageMessagesTemplates,
    accessBeacons,
    accessZones,
    accessDaysOff,
    accessAvailabilities,
    accessBroadcastAlerts,
    accessCounters,
    accessPlaceLineMonitoring,
    canManageUserAccounts,
    manageAppointments,
    canPromoteToAdmin,
    manageReports,
    manageTranslations,
    canUpdateShops: canUpdatePlace
  } = permissions

  if (user?.insecurePassword) {
    return (
      <Switch>
        <WRoute exact path={Nav.editUser(':userId')} component={UserEdit} action="Edit" />
        <Redirect to={Nav.editUser(String(user?.id))} />
      </Switch>
    )
  }

  return (
    <Switch>
      <WRoute exact path={Nav.index()} component={Home} />

      {/* Place */}
      {manageReports && <WRoute exact path={Nav.exportData(':placeId')} component={DataExport} />}
      {canUpdatePlace && <WRoute exact path={Nav.createPlace()} component={PlaceEdit} action="Edit" />}
      {canUpdatePlace && <WRoute exact path={Nav.editPlace(':placeId')} component={PlaceEdit} action="Edit" />}
      <WRoute exact path={Nav.place(':placeId')} component={PlaceView} action="View" />
      <WRoute exact path={Nav.places()} component={PlaceList} />

      {/* Line */}
      {canUpdatePlace && <WRoute exact path={Nav.createLine(':placeId')} component={LineEdit} action="Edit" />}
      {canUpdatePlace && <WRoute exact path={Nav.editLine(':placeId', ':lineId')} component={LineEdit} action="Edit" />}
      <WRoute exact path={Nav.line(':placeId', ':lineId')} component={LineView} action={'View'} />
      <WRoute exact path={Nav.lines(':placeId')} component={LineList} />

      {/* MessageTemplates pages */}
      {canPromoteToAdmin && <WRoute exact path={Nav.messageTemplates()} component={MessageTemplates} />}
      {manageMessagesTemplates && (
        <WRoute exact path={Nav.placeMessageTemplates(':placeId')} component={MessageTemplates} />
      )}
      {manageMessagesTemplates && (
        <WRoute exact path={Nav.lineMessageTemplates(':placeId', ':lineId')} component={MessageTemplates} />
      )}

      {/* Appointments */}
      {manageAppointments && <WRoute exact path={Nav.appointmentPlaceList()} component={AppointmentPlaceList} />}
      {manageAppointments && <WRoute exact path={Nav.createAppointment()} component={Appointment} action="Edit" />}
      {manageAppointments && (
        <WRoute exact path={Nav.createAppointmentLine(':placeId', ':lineId')} component={Appointment} action="Edit" />
      )}
      {manageAppointments && (
        <WRoute exact path={Nav.createAppointmentFromService()} component={AppointmentFromService} />
      )}
      {manageAppointments && (
        <WRoute exact path={Nav.viewAppointment(':appointmentId')} component={AppointmentView} action="View" />
      )}
      {manageAppointments && (
        <WRoute exact path={Nav.editAppointment(':appointmentId')} component={Appointment} action="Edit" />
      )}
      {manageAppointments && <WRoute exact path={Nav.appointments()} component={Appointments} />}
      {manageAppointments && (
        <WRoute exact path={Nav.appointmentsLine(':placeId', ':lineId')} component={Appointments} />
      )}
      {manageAppointments && (
        <WRoute
          exact
          path={Nav.appointmentsToCancelLine(':placeId', ':lineId')}
          render={() => <Appointments cancelMode={true} />}
        />
      )}
      {manageAppointments && (
        <WRoute
          exact
          path={Nav.viewAppointmentLine(':placeId', ':lineId', ':appointmentId')}
          component={AppointmentView}
          action="View"
        />
      )}

      {/* Reports */}
      {manageReports && <WRoute exact path={Nav.dailyStatsCustomers()} component={DailyStatsCustomers} />}
      {manageReports && <WRoute exact path={Nav.customers()} component={Customers} />}
      {manageReports && <WRoute exact path={Nav.dailyStats()} component={DailyStats} />}
      {manageReports && <WRoute exact path={Nav.journal()} component={Journal} />}
      {canPromoteToAdmin && <WRoute exact path={Nav.eventsFlow()} component={EventsFlow} />}
      {manageReports && <WRoute exact path={Nav.reports()} component={Reports} />}

      {/* Terminals */}
      {manageTerminals && <WRoute exact path={Nav.createTerminal(':placeId')} component={TerminalEdit} action="Edit" />}
      {manageTerminals && (
        <WRoute exact path={Nav.editTerminal(':terminalId', ':placeId')} component={TerminalEdit} action="Edit" />
      )}
      {manageTerminals && (
        <WRoute exact path={Nav.terminal(':terminalId', ':placeId')} component={TerminalView} action="View" />
      )}
      {manageTerminals && <WRoute exact path={Nav.terminals(':placeId')} component={TerminalList} />}

      {/* Campaigns */}
      {manageCampaigns && (
        <WRoute exact path={Nav.createAdvertisement(':campaignId')} component={AdvertisementEdit} action="Edit" />
      )}
      {manageCampaigns && (
        <WRoute
          exact
          path={Nav.editAdvertisement(':campaignId', ':advertisementId')}
          component={AdvertisementEdit}
          action="Edit"
        />
      )}
      {manageCampaigns && <WRoute exact path={Nav.advertisements(':campaignId')} component={AdvertisementList} />}
      {manageCampaigns && <WRoute exact path={Nav.createCampaign()} component={CampaignEdit} action="Edit" />}
      {manageCampaigns && (
        <WRoute exact path={Nav.editCampaign(':campaignId', ':mode')} component={CampaignEdit} action="Edit" />
      )}
      {manageCampaigns && <WRoute exact path={Nav.campaign(':campaignId')} component={CampaignView} action="View" />}
      {manageCampaigns && <WRoute exact path={Nav.campaigns()} component={CampaignList} />}

      {/* Users */}
      {canManageUserAccounts && <WRoute exact path={Nav.createUser()} component={UserEdit} action="Edit" />}
      <WRoute exact path={Nav.editUser(':userId')} component={UserEdit} action="Edit" />
      {canManageUserAccounts && <WRoute exact path={Nav.users()} component={UserList} />}

      {/* Counters */}
      {accessCounters && <WRoute exact path={Nav.createCounter(':placeId')} component={CounterEdit} action="Edit" />}
      {accessCounters && (
        <WRoute exact path={Nav.editCounter(':placeId', ':counterId')} component={CounterEdit} action="Edit" />
      )}
      {accessCounters && (
        <WRoute exact path={Nav.counter(':placeId', ':counterId')} component={CounterView} action="View" />
      )}
      {accessCounters && <WRoute exact path={Nav.counters(':placeId')} component={CounterList} />}

      {/* Beacons */}
      {accessBeacons && <WRoute exact path={Nav.createBeacon(':placeId')} component={BeaconEdit} action="Edit" />}
      {accessBeacons && (
        <WRoute exact path={Nav.editBeacon(':placeId', ':beaconId')} component={BeaconEdit} action="Edit" />
      )}
      {accessBeacons && (
        <WRoute exact path={Nav.beacon(':placeId', ':beaconId')} component={BeaconView} action="View" />
      )}
      {accessBeacons && <WRoute exact path={Nav.beacons(':placeId')} component={BeaconList} />}

      {/* Zones */}
      {accessZones && <WRoute exact path={Nav.createZone(':placeId')} component={ZoneEdit} action="Edit" />}
      {accessZones && <WRoute exact path={Nav.editZone(':placeId', ':zoneId')} component={ZoneEdit} action="Edit" />}
      {accessZones && <WRoute exact path={Nav.zones(':placeId')} component={ZoneList} />}

      {/* Availabilities */}
      {accessAvailabilities && (
        <WRoute
          exact
          path={Nav.createLineStaffManagement(':placeId', ':lineId')}
          component={StaffManagementEdit}
          action="Edit"
        />
      )}
      {accessAvailabilities && (
        <WRoute
          exact
          path={Nav.editLineStaffManagement(':placeId', ':lineId', ':managementId')}
          component={StaffManagementEdit}
          action="Edit"
        />
      )}
      {accessAvailabilities && (
        <WRoute exact path={Nav.lineStaffManagement(':placeId', ':lineId')} component={StaffManagementList} />
      )}

      {/* Checkpoints */}
      <WRoute
        exact
        path={Nav.checkpointHost(':placeId', ':lineId', ':checkpointId')}
        component={CheckpointHost}
        action="Edit"
      />
      <WRoute exact path={Nav.lineDelay(':placeId', ':lineId')} component={LineDelay} action="View" />
      <WRoute
        exact
        path={Nav.checkpointDelay(':placeId', ':lineId', ':checkpointId')}
        component={LineDelay}
        action="View"
      />
      <WRoute
        exact
        path={Nav.checkpointMonitoring(':placeId', ':lineId', ':checkpointId')}
        component={CheckpointMonitoring}
      />
      <WRoute
        exact
        path={Nav.checkpointMonitoringCreate(':placeId', ':lineId', ':checkpointId')}
        component={CheckpointMonitoringEdit}
        action="Edit"
      />
      <WRoute
        exact
        path={Nav.checkpointMonitoringEdit(':placeId', ':lineId', ':checkpointId', ':positionId')}
        component={CheckpointMonitoringEdit}
        action="Edit"
      />
      <WRoute
        exact
        path={Nav.checkpointMonitoringPosition(':placeId', ':lineId', ':checkpointId', ':positionId')}
        component={CheckpointMonitoring}
      />
      {canUpdatePlace && (
        <WRoute exact path={Nav.createCheckpoint(':placeId', ':lineId')} component={CheckpointEdit} action="Edit" />
      )}
      <WRoute
        exact
        path={Nav.editCheckpoint(':placeId', ':lineId', ':checkpointId')}
        component={CheckpointEdit}
        action="Edit"
      />
      <WRoute exact path={Nav.checkpoints(':placeId', ':lineId')} component={CheckpointList} />
      <WRoute exact path={Nav.checkpoints(':placeId')} component={CheckpointList} />

      {/* Monitorings */}
      {accessPlaceLineMonitoring && (
        <WRoute
          exact
          path={Nav.discreteLineMonitoringCreate(':placeId', ':lineId')}
          component={DiscreteMonitoringEdit}
        />
      )}
      {accessPlaceLineMonitoring && (
        <WRoute
          exact
          path={Nav.discreteLineMonitoringEdit(':placeId', ':lineId', ':positionId')}
          component={DiscreteMonitoringEdit}
        />
      )}
      {accessPlaceLineMonitoring && (
        <WRoute
          exact
          path={Nav.lineMonitoringCreate(':placeId', ':lineId')}
          component={LineMonitoringEdit}
          action="Edit"
        />
      )}
      {accessPlaceLineMonitoring && (
        <WRoute
          exact
          path={Nav.lineMonitoringEdit(':placeId', ':lineId', ':positionId')}
          component={LineMonitoringEdit}
        />
      )}
      {accessPlaceLineMonitoring && (
        <WRoute
          exact
          path={Nav.lineMonitoringPosition(':placeId', ':lineId', ':positionId')}
          component={LineMonitoring}
          action="View"
        />
      )}
      {accessPlaceLineMonitoring && (
        <WRoute exact path={Nav.lineMonitoring(':placeId', ':lineId')} component={LineMonitoring} action="View" />
      )}
      {accessPlaceLineMonitoring && (
        <WRoute
          exact
          path={Nav.discreteLineMonitoring(':placeId', ':lineId')}
          component={DiscreteMonitoring}
          action="View"
        />
      )}
      {accessPlaceLineMonitoring && (
        <WRoute
          exact
          path={Nav.lineScheduler(':placeId', ':lineId')}
          action="View"
          render={() => (
            <Suspense fallback={null}>
              <SchedulerMonitoring />
            </Suspense>
          )}
        />
      )}
      {manageAppointments && (
        <WRoute
          exact
          path={Nav.lineAppointmentScheduler(':placeId', ':lineId')}
          render={() => (
            <Suspense fallback={null}>
              <SchedulerAppointment />
            </Suspense>
          )}
        />
      )}
      {accessPlaceLineMonitoring && (
        <WRoute
          exact
          path={Nav.discreteLineMonitoringPosition(':placeId', ':lineId', ':positionId')}
          component={DiscreteMonitoring}
          action="View"
        />
      )}
      {accessPlaceLineMonitoring && (
        <WRoute exact path={Nav.lineMonitoringState(':placeId', ':lineId')} component={LineMonitoringStatistics} />
      )}

      {/* DaysOff | BroadcastAlerts */}
      {accessDaysOff && <WRoute exact path={Nav.daysOff(':placeId')} component={DaysOff} />}
      {accessBroadcastAlerts && (
        <WRoute exact path={Nav.broadcastAlerts(':placeId', ':lineId')} component={BroadcastAlerts} />
      )}

      {/* Translations */}
      {manageTranslations && <WRoute exact path={Nav.createTranslation()} component={TranslationEdit} action="Edit" />}
      {manageTranslations && (
        <WRoute exact path={Nav.editTranslation(':translationId')} component={TranslationEdit} action="Edit" />
      )}
      {manageTranslations && <WRoute exact path={Nav.translations()} component={TranslationList} />}

      {/* Tags */}
      {canPromoteToAdmin && <WRoute exact path={Nav.tags()} component={Tags} action="View" />}
      {canPromoteToAdmin && <WRoute exact path={Nav.createTag()} component={TagEdit} action="None" />}
      {canPromoteToAdmin && <WRoute exact path={Nav.editTag(':tagId')} component={TagEdit} action="Edit" />}

      <Redirect to={Nav.index()} />
    </Switch>
  )
}

export default MainRouting
