import { useState } from 'react'
import Card from 'shared/ui-kit/card'
import { addTestAttr } from 'shared/utils/test-attr'
import { useTranslation } from 'shared/i18n/translate'
import {
  LoginFormStyled,
  LoginField,
  LinkWrapper,
  LoginLogo,
  LinkStyled,
  SymbolButton,
  IconButtonStyled,
  KeyboardGrid,
  KeyboardWrapper,
  CodeGrid,
  CodeItem
} from './styled'
import { login } from 'pages/nav'
import { LoginFormProps } from './types'
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined'

function LoginForm({ onSubmit, disabled }: LoginFormProps) {
  const [code, setCode] = useState('')

  const { tr } = useTranslation()

  function setSymbol(value: string) {
    return () => {
      setCode((curr) => {
        const newValue = curr + value

        if (newValue.length > 6) {
          return value
        }

        if (newValue.length === 6) {
          onSubmit(newValue)
        }

        return curr + value
      })
    }
  }

  return (
    <Card>
      <LoginFormStyled>
        <LoginLogo />
        <LoginField>
          <CodeGrid>
            {Array(6)
              .fill(0)
              .map((_, i) => (
                <CodeItem key={i}>{code[i] || ' '}</CodeItem>
              ))}
          </CodeGrid>
        </LoginField>
        <KeyboardWrapper>
          <KeyboardGrid>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, undefined, 0].map((n, i) =>
              n === undefined ? (
                <div key={i} />
              ) : (
                <SymbolButton disabled={disabled} key={i} onClick={setSymbol(n.toString())}>
                  {n}
                </SymbolButton>
              )
            )}
            <IconButtonStyled size="small" disabled={disabled} onClick={() => setCode((curr) => curr.slice(0, -1))}>
              <BackspaceOutlinedIcon fontSize="small" style={{ color: '#0c3660' }} />
            </IconButtonStyled>
          </KeyboardGrid>
        </KeyboardWrapper>
        <LinkWrapper>
          <LinkStyled to={login()} {...addTestAttr('LoginForm-LoginByEmail')}>
            {tr.login.loginByEmail}
          </LinkStyled>
        </LinkWrapper>
      </LoginFormStyled>
    </Card>
  )
}

export { LoginForm }
