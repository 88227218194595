import { useEffect, useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { appointments } from 'shared/api'
import { ServiceLineGroup, PosiblePlaceLine } from 'shared/models'
import PageLoader from 'shared/ui-kit/page-loader'
import Container from 'shared/ui-kit/container'
import { AppointmentFromServiceFormSearch } from './appointment-from-service-form-search'
import { AppointmentFromServiceFormSearchResult } from './appointment-from-service-form-search-result'
import { createAppointmentLine } from 'pages/nav'
import uniq from 'lodash/uniq'
import { Card } from '@mui/material'
import { CenterContainer } from 'shared/ui-kit/center-container'
import { useAppDispatch } from 'store'
import { AppointmentFromServiceProps } from '../types'

function AppointmentFromService(props: AppointmentFromServiceProps) {
  const { match, history } = props
  const { appointmentId } = match.params
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMesage] = useState<null | string>(null)
  const [fetchSearchData, setFetchSearchData] = useState(false)
  const [data, setData] = useState<ServiceLineGroup[] | undefined>()
  const [searchData, setSearchData] = useState<PosiblePlaceLine[] | undefined>()
  const [customServiceDuration, setCustomServiceDuration] = useState<number>()

  const queryParams = new URLSearchParams(location.search)
  const placeIdQuery = queryParams.get('placeId') || queryParams.get('shopId') || undefined
  const lineIdQuery = queryParams.get('lineId')

  const { tr } = useTranslation()
  const { breadcrumbs, appointmentCreateFromService } = tr

  function setCrumbs(isNew: boolean) {
    const crumb = getCrumbs(breadcrumbs)

    dispatch(
      setBreadcrumbs([
        crumb.home(),
        crumb.appointments(),
        isNew ? crumb.newAppointment() : crumb.editAppointment([appointmentId])
      ])
    )
  }

  useEffect(() => {
    setCrumbs(!appointmentId)
    setLoading(true)

    appointments
      .getAppointmentPossibleServiceList({
        shopId: placeIdQuery,
        lineId: lineIdQuery
      })
      .then((resp) => setData(resp.data))
      .finally(() => setLoading(false))
  }, [])

  if (loading) {
    return (
      <CenterContainer>
        <PageLoader />
      </CenterContainer>
    )
  }

  return (
    <Container>
      <AppointmentFromServiceFormSearch
        disabled={fetchSearchData}
        data={data}
        onFilterChange={({ services, participantsNumber, customServiceDuration }) => {
          setCustomServiceDuration(customServiceDuration)

          if (!participantsNumber || !services?.length) {
            setSearchData(undefined)
            return
          }

          setFetchSearchData(true)

          const servicesIds = services.map((s) => s.id)

          const linesIds = services.reduce((prev: any[], curr) => {
            return [...prev, ...curr.lineIds]
          }, [])

          const shopIds = services.reduce((prev: any[], curr) => {
            return [...prev, ...curr.placeIds]
          }, [])

          appointments
            .getAppointmentPossiblePlaceLineList({
              participantsNumber: participantsNumber,
              servicesIds: servicesIds,
              shopIds: uniq(shopIds),
              lineIds: uniq(linesIds),
              customServiceDuration
            })
            .then((resp) => {
              setSearchData(resp.data)
              setErrorMesage(null)
            })
            .catch(() => setErrorMesage(appointmentCreateFromService.errorGettingTimeslots))
            .finally(() => setFetchSearchData(false))
        }}
      />
      {fetchSearchData && <PageLoader style={{ margin: '8px 0' }} />}
      {!fetchSearchData && errorMessage && <div>{errorMessage}</div>}
      {!fetchSearchData && searchData && !searchData.length && (
        <Card variant="outlined" style={{ padding: '1rem', textAlign: 'center' }}>
          {appointmentCreateFromService.noBookingSlotAvailable}
        </Card>
      )}
      {!fetchSearchData && (
        <Card>
          <AppointmentFromServiceFormSearchResult
            data={searchData}
            onSelect={(data) => {
              const params: any = {
                servicesIds: data.servicesIds[0],
                timeslotId: data.timeslotId,
                participantsNumber: String(data.participantsNumber || 1)
              }

              if (customServiceDuration) {
                params.customServiceDuration = String(customServiceDuration)
              }

              const searchParams = new URLSearchParams(params)

              history.push(`${createAppointmentLine(data.shopId, data.lineId)}?${searchParams}`)
            }}
          />
        </Card>
      )}
    </Container>
  )
}

export { AppointmentFromService }
