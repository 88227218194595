import { List } from 'shared/ui-kit/list'
import IconButton from '@mui/material/IconButton'
import Remove from '@mui/icons-material/RemoveCircleOutline'
import Text from 'shared/ui-kit/text'
import { useTranslation } from 'shared/i18n/translate'
import { AddControl } from './add-counters-control'
import { CounterModel, ZoneCounters } from 'shared/models'

type Props = {
  selectedCounters: ZoneCounters[]
  counters: CounterModel[]
  onChange: (value: ZoneCounters[]) => void
}

function AddCounters(props: Props) {
  const { selectedCounters = [], counters = [], onChange } = props
  const { tr } = useTranslation()

  const filteredSource = counters.filter((x) => !selectedCounters.some((s) => s.counterId === x.id))

  function handleRemove(id: number | string) {
    onChange(selectedCounters.filter((x) => x.counterId !== id))
  }

  return (
    <section>
      <List
        onSelect={() => {}}
        data={selectedCounters}
        dataConverter={(counter: any) => {
          const controls = [
            <IconButton onClick={() => handleRemove(counter.counterId)}>
              <Remove />
            </IconButton>
          ]

          const item = counters.find((x) => x.id === counter.counterId)

          if (!item) {
            return { id: 'error', primaryText: 'cant find counter' }
          }

          return {
            id: item.id,
            primaryText: (
              <div>
                {item.name}
                <Text size={2} fade={2} style={{ display: 'inline-block', marginLeft: 16 }}>
                  {item.serialNumber}
                </Text>
                <Text size={2} fade={2} style={{ display: 'inline-block', marginLeft: 16 }}>
                  {tr.zoneEdit[counter.type]}
                </Text>
              </div>
            ),
            controls
          }
        }}
      />
      {!!filteredSource.length && (
        <AddControl
          style={{ margin: 16 }}
          source={filteredSource}
          onChange={(data) => {
            const inCollection = counters.find((c) => c.id === data.counterId)

            if (inCollection) {
              onChange([...selectedCounters, data])
            }
          }}
        />
      )}
    </section>
  )
}

export { AddCounters }
