import { Divider } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import { PositionAction, PositionState } from 'shared/models'
import PositionActionComponent from '../../components/position-action'
import { NearCheckpointProps } from './types'
import HowToRegIcon from '@mui/icons-material/HowToReg'

function NearCheckpoint(props: NearCheckpointProps) {
  const { tr } = useTranslation()

  function handleSubmit() {
    props.handleChangeState(PositionState.NearCheckpoint, { skipServiceSelect: true, skipForms: true })
  }

  return (
    <>
      {!props.isCardView && <Divider />}
      <PositionActionComponent
        isCardView={props.isCardView}
        confirmation={false}
        confirmationMessage={''}
        onSubmit={handleSubmit}
        disabled={!props.posiblesStates.includes(PositionAction.SetNearCheckpoint)}
        icon={<HowToRegIcon fontSize="small" />}
      >
        {props.monitoringPositionMenuItemCaptions?.arrivedAfterCall || tr.positionActions.hasApproached}
      </PositionActionComponent>
    </>
  )
}

export { NearCheckpoint }
