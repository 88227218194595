import { useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { addTestAttr } from 'shared/utils/test-attr'
import { Person, ExitToApp, SettingsApplications as Settings } from '@mui/icons-material'
import { Popover, Button } from '@mui/material'
import { AvatarStyled } from './styled'
import Text from '../text'
import { UserStyled, UserName, UserPopover, UserPopoverUser, UserPopoverName, UserButtonsWrap } from './styled'
import { UserProps } from './types'

function User({ profile, onSettingsTap, onExitTap }: UserProps) {
  const { tr } = useTranslation()
  const { firstName, secondName, email } = profile

  const [anchorElement, setAnchorElement] = useState<HTMLDivElement>()

  function handleSetAnchor(ev: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    setAnchorElement(ev.currentTarget)
  }

  function handleClosePopover() {
    setAnchorElement(undefined)
  }

  function handleSettings() {
    setAnchorElement(undefined)
    onSettingsTap?.()
  }

  return (
    <div>
      <UserStyled onClick={handleSetAnchor} {...addTestAttr('AppBar-User')}>
        <UserName>
          {firstName} {secondName}
        </UserName>
        <AvatarStyled sx={{ width: 36, height: 36 }}>
          <Person fontSize="small" />
        </AvatarStyled>
      </UserStyled>
      <Popover
        open={!!anchorElement}
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClose={handleClosePopover}
        elevation={1}
      >
        <UserPopover>
          <UserPopoverUser>
            <UserPopoverName>
              {firstName} {secondName}
            </UserPopoverName>
            <Text type="body-2">{email}</Text>
          </UserPopoverUser>
          <UserButtonsWrap>
            <Button onClick={handleSettings} {...addTestAttr('AppBar-SettingsBtn')}>
              <Settings style={{ marginRight: '8px' }} />
              {tr.userInAppBar.settingsButton}
            </Button>
            <Button onClick={onExitTap} {...addTestAttr('AppBar-ExitBtn')}>
              <ExitToApp style={{ marginRight: '8px' }} />
              {tr.userInAppBar.exitButton}
            </Button>
          </UserButtonsWrap>
        </UserPopover>
      </Popover>
    </div>
  )
}

export { User }
