import { CSSProperties, ReactNode } from 'react'
import css from './form-group.module.scss'
import { withMods } from 'shared/utils/cssm'

const mod = withMods(css)

type FormGroupProps = {
  className?: string
  style?: CSSProperties
  children?: ReactNode
}

function FormGroup(props: FormGroupProps) {
  return (
    <section className={mod.root().add(props.className)} style={props.style}>
      {props.children}
    </section>
  )
}

export default FormGroup
