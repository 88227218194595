import { PositionStateColors } from 'features/position-dumb'
import { WhereToVote } from '@mui/icons-material'
import { IconValue } from 'shared/ui-kit/icons'
import { StatsIcon, StatsItem, StatsWrapper, StatsContainer } from '../styled'
import { StatiscticsProps } from './types'

function Statisctics({
  waitingPositionsCount,
  calledPositionsCount,
  inServicePositionsCount,
  impossiblePositionsCount,
  waitingInPlacePositionsCount
}: StatiscticsProps) {
  const waitingPersonsWithoutInPlace = (waitingPositionsCount || 0) - (waitingInPlacePositionsCount || 0)

  return (
    <StatsWrapper>
      <StatsContainer>
        {!!inServicePositionsCount && (
          <StatsItem style={{ alignItems: 'center' }}>
            <StatsIcon $background={PositionStateColors['inService']} />
            {inServicePositionsCount}
          </StatsItem>
        )}
        {!!calledPositionsCount && (
          <StatsItem style={{ alignItems: 'center' }}>
            <StatsIcon $background={PositionStateColors['calledToCheckpoint']} />
            {calledPositionsCount}
          </StatsItem>
        )}
        {!!waitingInPlacePositionsCount && (
          <IconValue
            icon={
              <WhereToVote style={{ color: PositionStateColors['joined'], height: '21px', alignItems: 'center' }} />
            }
          >
            <>{waitingInPlacePositionsCount}</>
          </IconValue>
        )}
        {!!waitingPersonsWithoutInPlace && (
          <StatsItem style={{ alignItems: 'center' }}>
            <StatsIcon $background={PositionStateColors['joined']} />
            {waitingPersonsWithoutInPlace}
          </StatsItem>
        )}
        {!!impossiblePositionsCount && (
          <StatsItem>
            <StatsIcon $background={'#f44336'} />
            {impossiblePositionsCount}
          </StatsItem>
        )}
      </StatsContainer>
    </StatsWrapper>
  )
}

export { Statisctics }
