import { AdditionalTypes, ComboType } from 'shared/models'
import {
  ControlProps,
  ComboControlProps,
  StringControl,
  DateControl,
  SwitchControl,
  NumberControl,
  ComboControl,
  DocumentControl,
  PhoneControl
} from './controls'

type AdditionalControlProps<T extends AdditionalTypes, D> = T extends ComboType ? ComboControlProps<D> : ControlProps

type Props<T extends AdditionalTypes, D> = {
  type: T
  controlProps: AdditionalControlProps<T, D>
}

export function AdditionalControl<T extends AdditionalTypes, D>(props: Props<T, D>) {
  switch (props.type) {
    case 'String':
      return <StringControl {...props.controlProps} />
    case 'Number':
      return <NumberControl {...props.controlProps} />
    case 'Date':
      return <DateControl {...props.controlProps} />
    case 'Switch':
      return <SwitchControl {...props.controlProps} />
    case 'Combo':
      return <ComboControl {...(props.controlProps as ComboControlProps<D>)} />
    case 'Document':
      return <DocumentControl {...props.controlProps} />
    case 'Phone':
      return <PhoneControl {...props.controlProps} />
    default:
      return <></>
  }
}
