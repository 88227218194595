import { ActionInitiators } from './journal.model'

export type GetEventsFlowRequestModel = {
  shopId?: string | number
  lineId?: string | number
  startDate?: number
  endDate?: number
  operatorIds?: (string | number)[]
  actions?: string[]
  customerId?: string | number
  positionId?: string | number
  lastEventId?: string | number
}

export type GetEventsFlowResponseModel = {
  events: EventsFlowModel[]
}

export type EventsFlowModel = {
  id?: number | string
  time: number
  action: EventFlowAction
  customerId?: number
  place?: { id?: number; name: string }
  line?: { id?: number; name: string }
  checkpoint?: { id?: number; name: string }
  positionId?: number
  serviceId?: number
  terminalId?: number
  positionType: string
  priority?: boolean
  deviceType?: string
  estimatedWaitingTimeWhenJoin?: number
  personesAheadWhenJoin?: number
  participantsNumber?: number
  latitude?: number
  longitude?: number
  distanceToStore?: number
  timeSlotStart?: number
  timeSlotLength?: number
  totalTimeInLine?: number
  maxApproachTime?: number
  approachTime?: number
  expectedServiceTime?: number
  serviceTime?: number
  waitingTime?: number
  manualAction?: boolean
  data?: string
  sessionId?: string
  alertTimeout?: number
  countOpenedCheckpoints?: number
  reservationCode?: string
  personsArrived?: number
  personsExpected?: number
  freePlaces?: number
  customerLastRequestTime?: number
  joinTime?: number
  alertMessage?: string
  actionInitiator?: ActionInitiators
  alertMessageId?: number
  alertDistributionType?: string
  source?: string
  reason?: string
  comment?: string
  staffComment?: string
  timeSlotFullCapacity?: number
  timeSlotFreePlaces?: number
  bLESignalForce?: number
  clientLatitude?: number
  clientLongitude?: number
  delay?: number
  operatorId?: number | string
  checkpointHostName?: string
  activeCheckpoints?: number
  customerInfo?: string
  customDuration?: number
  additionalFields?: string
  actionOperatorComments?: string
  progressionTags?: string
  progressionTagsImagesData?: string
  originalPositionId?: number
  assignedCheckpointStartTime?: number
  services?: {
    id: string | number
    name: string
    duration?: number
    priority?: number
  }[]
  operator?: {
    lastName?: string
    firstName?: string
  }
}

export enum EventFlowAction {
  AppointmentRequest = 'AppointmentRequest',
  CallPhoneNumber = 'CallPhoneNumber',
  CallToCheckpoint = 'CallToCheckpoint',
  CancelTimeSlotLock = 'CancelTimeSlotLock',
  CheckpointFinished = 'CheckpointFinished',
  ConfirmAppointment = 'ConfirmAppointment',
  CreateAppointment = 'CreateAppointment',
  CreateTimeSlotLock = 'CreateTimeSlotLock',
  CustomerStartup = 'CustomerStartup',
  EnterToCheckpointZone = 'EnterToCheckpointZone',
  ExitFromCheckpointZone = 'ExitFromCheckpointZone',
  FailedJoin = 'FailedJoin',
  FinishCheckpoint = 'FinishCheckpoint',
  FinishService = 'FinishService',
  InPlace = 'InPlace',
  JoinLine = 'JoinLine',
  LeaveLine = 'LeaveLine',
  LineDelay = 'LineDelay',
  MoveToCheckpoint = 'MoveToCheckpoint',
  NotFoundMobileConvertCode = 'NotFoundMobileConvertCode',
  RejoinLine = 'RejoinLine',
  RemoveAppointment = 'RemoveAppointment',
  Removed = 'Removed',
  StartCheckpoint = 'StartCheckpoint',
  StartService = 'StartService',
  StartTerminal = 'StartTerminal',
  Sync = 'Sync',
  UnconfirmAppointment = 'UnconfirmAppointment',
  UpdateAppointment = 'UpdateAppointment',
  UpdateAppointmentProgressionTags = 'UpdateAppointmentProgressionTags',
  UpdateCheckpoint = 'UpdateCheckpoint',
  UpdateLanguage = 'UpdateLanguage',
  UpdatePosition = 'UpdatePosition',
  UpdateSmsStatus = 'UpdateSmsStatus',
  LicensePlate_PositionNotFound = 'LicensePlate_PositionNotFound',
  LicensePlate_PositionStateNotSupported = 'LicensePlate_PositionStateNotSupported',
  LicensePlate_ForceInPlace = 'LicensePlate_ForceInPlace',
  LicensePlate_StartService = 'LicensePlate_StartService',
  LicensePlate_FinishService = 'LicensePlate_FinishService'
}
