import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DataResponseWrapper, LineModel, PlaceModelSimplified } from 'shared/models'
import isEqual from 'lodash/isEqual'

type PlacesState = {
  fetching: boolean
  loaded: boolean
  items: null | (PlaceModelSimplified & { lines?: LineModel[] })[]
  currentPlaceId: null | number | string
}

const initialState: PlacesState = {
  fetching: false,
  loaded: false,
  items: null,
  currentPlaceId: null
}

const placesSlice = createSlice({
  name: 'places',
  initialState,
  reducers: {
    getPlaceListRequest: (state) => {
      state.loaded = false
      state.fetching = true
    },
    setPlaceList: (state, { payload }: PayloadAction<{ data: PlaceModelSimplified[] }>) => {
      if (!isEqual(state.items, payload.data)) {
        state.fetching = false
        state.loaded = true
        state.items = (payload?.data || []).map((place) => {
          const foundedPlace = (state.items || []).find((p) => p.id === place.id)
          if (foundedPlace?.lines) {
            return { ...place, lines: foundedPlace?.lines }
          } else {
            return place
          }
        })
      }
    },
    setLineListToPlace: (state, { payload }: PayloadAction<DataResponseWrapper<LineModel[], any>>) => {
      state.items?.forEach((item) => {
        if (String(item.id) === String(payload.parents?.shop?.id)) {
          item.lines = payload.data
        }
      })
    },
    invalidatePlaceList: (state) => {
      state.fetching = false
      state.loaded = false
      state.items = null
    },
    setCurrentPlace: (state, { payload }: PayloadAction<number | string | null>) => {
      state.currentPlaceId = payload
    }
  }
})

export const { getPlaceListRequest, invalidatePlaceList, setCurrentPlace, setLineListToPlace, setPlaceList } =
  placesSlice.actions
export default placesSlice.reducer
