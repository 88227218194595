import { useEffect, useRef, useState, useCallback } from 'react'
import { SubBar } from 'shared/ui-kit/sub-bar'
import { MonitoringBar, MonitoringBarContainer, BarContentWrapper } from '../styled'
import { CheckpointBarActions } from '../components/checkoint-bar-actions'
import debounce from 'lodash/debounce'
import { CheckpointStatus, PositionType } from 'shared/models'
import { getSupportedPositionTypesFromLine } from 'features/position-edit/supported-position-types-from-line'
import { CheckpointMonitoringBarProps } from '../types'

function CheckpointMonitoringBar({
  line,
  checkpointStatus,
  lineIsActive,
  onPause,
  registeredPositionsCount,
  waitingTime
}: CheckpointMonitoringBarProps) {
  const [smallScreen, setSmallScreen] = useState(false)

  const monitoringRef = useRef<HTMLDivElement>(null)
  const initScrollWidth = useRef<number>(0)

  useEffect(() => {
    window.addEventListener('resize', resizeHandlerDebounce, false)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandlerDebounce, false)
    }
  }, [])

  const resizeHandlerDebounce = useCallback(debounce(resizeHandler, 300), [])

  function resizeHandler() {
    setSmallScreen((curr) => {
      if (!monitoringRef.current) {
        return curr
      }

      if (monitoringRef.current.clientWidth < monitoringRef.current.scrollWidth && !curr) {
        initScrollWidth.current = monitoringRef.current.scrollWidth
        return true
      }

      if (monitoringRef.current.clientWidth > initScrollWidth.current && curr) {
        initScrollWidth.current = monitoringRef.current.scrollWidth
        return false
      }

      return curr
    })
  }

  const privateServices = line?.services?.filter?.((s) => !!s.isPrivate) || []
  const create = !!getSupportedPositionTypesFromLine(line || {})?.find((el) => el === PositionType.Break)
  const createDisabled =
    !checkpointStatus ||
    [CheckpointStatus.finishing, CheckpointStatus.finished].includes(checkpointStatus) ||
    lineIsActive === false
  const isFinished = checkpointStatus === CheckpointStatus.finished

  return (
    <MonitoringBar $smallScreen={smallScreen} ref={monitoringRef}>
      <MonitoringBarContainer $smallScreen={smallScreen}>
        <SubBar>
          <BarContentWrapper>
            <CheckpointBarActions
              actions={{ create, suspendService: privateServices.length > 0 }}
              actionsDisabled={{ create: createDisabled, checkpointDelay: isFinished, suspendService: isFinished }}
              onPauseMode={onPause}
              registeredPositionsCount={registeredPositionsCount}
              waitingTime={waitingTime}
              line={line}
            />
          </BarContentWrapper>
        </SubBar>
      </MonitoringBarContainer>
    </MonitoringBar>
  )
}

export { CheckpointMonitoringBar }
