import { CSSProperties, useState } from 'react'
import Button from '@mui/material/Button'
import Dropdown from 'shared/ui-kit/dropdown'
import Add from '@mui/icons-material/Add'
import { useTranslation } from 'shared/i18n/translate'
import { CounterModel, CounterZoneType, ZoneCounters } from 'shared/models'

type AddControlProps = {
  source: CounterModel[]
  onChange: (value: ZoneCounters) => void
  style?: CSSProperties
}

function AddControl(props: AddControlProps) {
  const { source, onChange, style } = props
  const { tr } = useTranslation()

  const [selectedCounterId, setSelectedCounterId] = useState<string | number | undefined>()
  const [selectedType, setSelectedType] = useState<CounterZoneType>('entry')

  const types: { text: string; value: CounterZoneType }[] = [
    { text: tr.zoneEdit.entry, value: 'entry' },
    { text: tr.zoneEdit.exit, value: 'exit' },
    { text: tr.zoneEdit.entryAndExit, value: 'entryAndExit' }
  ]

  function handleAddItem() {
    if (!selectedCounterId) {
      return
    }

    onChange({ counterId: selectedCounterId, type: selectedType })
    setSelectedCounterId(undefined)
  }

  return (
    <div style={{ display: 'flex', gap: 16, ...style }}>
      <Dropdown
        ensureValue
        style={{ flex: 2 }}
        value={selectedCounterId}
        onChange={(v) => setSelectedCounterId(v)}
        data={source}
        dataConverter={(item: any) => ({ value: item.id, text: item.name })}
      />
      <Dropdown
        ensureValue
        style={{ flex: 1 }}
        value={selectedType}
        data={types}
        onChange={(v) => setSelectedType(v)}
        dataConverter={(item) => item}
      />
      <Button startIcon={<Add />} onClick={handleAddItem}>
        {tr.zoneEdit.addBeaconButton}
      </Button>
    </div>
  )
}

export { AddControl }
