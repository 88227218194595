import styled from '@emotion/styled'

const PositionActionNotActive = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;
  cursor: default;
  margin-top: 0.5rem;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
`

const TimerWrapper = styled.span`
  color: #00000061;
  margin-left: 0.5rem;
  font-size: 14px;
`

const RenewLinkWrapper = styled.div`
  cursor: pointer;
  color: #db214d;
  margin-right: 1rem;
`

const CodeWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

const MobileCode = styled.span`
  color: #db214d;
  font-size: 21px;
`

export { PositionActionNotActive, TimerWrapper, RenewLinkWrapper, CodeWrapper, MobileCode }
