import { Position } from 'features/position-dumb'
import PositionActionsStatic from 'features/position-actions/position-actions-static'
import { LineMode, PositionModel, PositionState } from 'shared/models'
import { simplifyPositionToFullPositionConvert } from 'features/position/position-converter'
import { LineSlotPositonsProps } from '../types'
import { useTranslation } from 'shared/i18n/translate'

export function LineSlotPositons({
  positions,
  line,
  serverNow,
  selectedPositionId,
  positionClickHandler,
  lineId,
  shopId,
  checkpoints = [],
  onRemove,
  onStateChange,
  onUpdate,
  timeslot,
  showButtonCreatePosition,
  onDuplicatePosition,
  onClose
}: LineSlotPositonsProps) {
  const { tr } = useTranslation()

  function handleRemove(position: PositionModel) {
    return () => onRemove?.(position)
  }

  function handleUpdate(position: PositionModel) {
    return () => onUpdate?.(position)
  }

  function handleStateChange(position: PositionModel) {
    return (newState: PositionState) => onStateChange?.(position, newState)
  }

  return (
    <div>
      {positions.map((discretePosition, index) => {
        const position = simplifyPositionToFullPositionConvert(
          discretePosition,
          line.services || [],
          serverNow || Date.now(),
          tr,
          timeslot
        )
        const positionId = position.id
        const state = position.state
        const customerId = position.customer && position.customer.id
        const phoneNumber = position.customer && position.customer.phoneNumber

        const selected = String(positionId) === String(selectedPositionId)

        const supportMobile = !!(line.modes || []).find((el) => el === LineMode.MobileApp)

        return (
          <Position
            lineParticipantTermInPluralTemplate={line?.lineParticipantTermInPluralTemplate}
            lineParticipantTermInSingularTemplate={line?.lineParticipantTermInSingularTemplate}
            data={position}
            readonly={position.readonly}
            onClick={positionClickHandler}
            additionals={line.additionals || []}
            reducedMobile={selected}
            displayPeopleInPosition={line.requestParticipantsNumber}
            displayServiceDuration={line.displayServiceDuration || false}
            displayWaitingTimeEstimation={line.displayWaitingTimeEstimation || false}
            monitoringPositionMenuItemCaptions={line.monitoringPositionMenuItemCaptions}
            key={index}
            isOnlyOneServicePoint={checkpoints?.length < 2}
          >
            <PositionActionsStatic
              opened={selected}
              onOpen={() => positionClickHandler(position)}
              supportMobile={supportMobile}
              position={position}
              readonly={position.readonly}
              style={{ width: '100%' }}
              positionId={positionId}
              customerId={customerId}
              shopId={shopId}
              lineId={lineId}
              positionState={state}
              showButtonCreatePosition={showButtonCreatePosition || false}
              phoneNumber={position?.callPhoneNumber || phoneNumber}
              checkpoints={checkpoints}
              customCheckpointId={position.customCheckpoint ? position.customCheckpoint.id : undefined}
              onStateChange={handleStateChange(position)}
              onRemove={handleRemove(position)}
              onUpdate={handleUpdate(position)}
              displayServiceSurvey={line?.displayServiceSurvey}
              sendSurveyLinkAfterPositionServed={line?.sendSurveyLinkAfterPositionServed}
              sendSurveyLinkByEmail={line?.sendSurveyLinkByEmail}
              sendSurveyLinkBySms={line?.sendSurveyLinkBySms}
              surveyTags={line?.surveyTags}
              displayInLineHere={line?.displayInPlace}
              displayPositionValidate={line?.displayPositionValidate}
              displayHistory={line?.displayHistory}
              displayHasApproach={line?.displayApproach}
              displayRemoveConfirm={line?.displayRemoveConfirm}
              sequentiallyPerformingActionsOnPosition={line?.sequentiallyPerformingActionsOnPosition}
              requestCommentsOnRemove={line?.requestCommentsOnRemove}
              setPositionFinishedAfterArrived={line?.setPositionFinishedAfterArrived}
              displayCallByPhone={line?.displayCallByPhone}
              displayConvertToMobile={line?.displayConvertToMobile}
              requestCommentsOnPriorityCall={line?.requestCommentsOnPriorityCall}
              duplicatePositionMenuItem={line?.duplicatePositionMenuItem}
              onDuplicatePosition={onDuplicatePosition}
              generalConditionsAcceptation={line.generalConditionsAcceptation}
              monitoringPositionMenuItemCaptions={line.monitoringPositionMenuItemCaptions}
              allowProgressionPhoto={line?.allowProgressionPhoto}
              onClose={onClose}
              lineParticipantTermInSingularTemplate={line?.lineParticipantTermInSingularTemplate}
              positionTransferEnabled={line?.flags?.positionTransferEnabled}
              supportAutoCall={line?.supportAutoCall}
              suggestNextOperationOnPosition={line?.flags?.suggestNextOperationOnPosition}
              closeAfterActionIsFinished
            />
          </Position>
        )
      })}
    </div>
  )
}
