import { Divider } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import PositionActionComponent from '../../components/position-action'
import { EditActionProps } from './types'
import EditIcon from '@mui/icons-material/Edit'

function Edit(props: EditActionProps) {
  const { tr } = useTranslation()

  return (
    <>
      {!props.isCardView && <Divider />}
      <PositionActionComponent
        isCardView={props.isCardView}
        onSubmit={props.onSubmit}
        disabled={props.disabled}
        icon={<EditIcon fontSize="small" />}
      >
        {tr.positionActions.edit}
      </PositionActionComponent>
    </>
  )
}

export { Edit }
