import { useCallback, useEffect, useState, useRef, DependencyList } from 'react'
import debounce from 'lodash/debounce'

export function useDebouncedCallback<T extends (...args: any[]) => any>(
  callback: T,
  delay = 0,
  options?: any
): T & any {
  return useCallback(debounce(callback, delay, options), [callback, delay, options])
}

export function useDebounce<T extends DependencyList>(values: T, delay = 0, options?: any): [T, () => void] {
  const previousValue = useRef(values)
  const [current, setCurrent] = useState(values)
  const debouncedCallback = useDebouncedCallback((values: T) => setCurrent(values), delay, options)

  function cancel() {
    debouncedCallback.cancel()
  }

  useEffect(() => {
    if (values !== previousValue.current) {
      debouncedCallback(values)
      previousValue.current = values
      return debouncedCallback.cancel
    }
  }, values)

  return [current, cancel]
}
