import { IntlPhoneInput } from 'features/intl-phone-input'
import { validatePhone } from 'features/material-ui-phoneinput'
import { useTranslation } from 'shared/i18n/translate'
import { uniqueId } from 'lodash'
import { PositionCustomer, PositionType } from 'shared/models'

type Params = {
  handleCustomerPropertyChange<T extends keyof PositionCustomer>(propertyName: T, value: PositionCustomer[T]): void
  customer?: PositionCustomer
  defaultPhoneCountryCode?: string
  type?: string
}

function getPhoneField(props: Params) {
  const { tr } = useTranslation()

  const { phoneNumber } = tr.lineMonitoring
  const phoneNumberData = props.customer?.phoneNumber || ''
  const mode = (props?.type || '')?.toLowerCase()

  const validators = {
    [PositionType.ScreenCall.toLowerCase()]: () => validatePhone(phoneNumberData, true),
    [PositionType.Appointment.toLowerCase()]: () => validatePhone(phoneNumberData, true),
    common: () => validatePhone(phoneNumberData)
  }

  const isValid = validators[mode] || validators.common

  const view = (
    <IntlPhoneInput
      key="phone"
      autoComplete={uniqueId('autocomplete')}
      label={phoneNumber}
      placeholder={phoneNumber}
      type="tel"
      attention={!isValid()}
      value={phoneNumberData || ''}
      defaultCountry={props.defaultPhoneCountryCode}
      fullWidth
      onChange={(value) => props.handleCustomerPropertyChange('phoneNumber', value)}
    />
  )

  return { view, isValid }
}

export { getPhoneField }
