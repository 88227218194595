import { List, ListItemText, ListSubheader, ListItemButton } from '@mui/material'
import { PosiblePlaceLine } from 'shared/models'
import { useTranslation } from 'shared/i18n/translate'

type SelectValue = {
  shopId: any
  lineId: any
  servicesIds: any[]
  timeslotId: any
  participantsNumber: number
}

type Props = {
  data?: PosiblePlaceLine[] | undefined
  onSelect: (value: SelectValue) => void
}

function AppointmentFromServiceFormSearchResultComponent(props: Props) {
  const { data = [], onSelect } = props
  const { tr, dateFormatter } = useTranslation()

  if (!data?.length) {
    return null
  }

  return (
    <List subheader={<ListSubheader>{tr.appointmentCreateFromService.timeslot}</ListSubheader>}>
      {data.map((sd, index) => {
        const services = sd.services.length ? sd.services.map((s) => s.name).join(',') : ''

        return (
          <ListItemButton
            key={index}
            onClick={() => {
              if (sd.bookingUnavailable) {
                return
              }

              onSelect({
                shopId: sd.shop.id,
                lineId: sd.line.id,
                servicesIds: sd.services.map((s) => s.id),
                timeslotId: sd.nearestDate.id,
                participantsNumber: sd.participantsNumber
              })
            }}
          >
            <ListItemText
              primary={`${sd.shop.name} • ${sd.line.name}`}
              secondary={
                <>
                  <span>{`${services}`}</span>
                  <span>{` • `}</span>
                  {sd.bookingUnavailable && (
                    <span style={{ fontWeight: 500 }}>
                      {sd.bookingUnavailableReason || 'NO_TIMESLOT_TEMPLATE_MESSAGE'}
                    </span>
                  )}
                  {!sd.bookingUnavailable && (
                    <span style={{ fontWeight: 500 }}>{`${dateFormatter(sd.nearestDate.startTime, 'dateTime')}`}</span>
                  )}
                </>
              }
            />
          </ListItemButton>
        )
      })}
    </List>
  )
}

export const AppointmentFromServiceFormSearchResult = AppointmentFromServiceFormSearchResultComponent
