import { Button } from '@mui/material'
import { CheckpointStatus, InternalService, PositionModel } from 'shared/models'
import { HotkeyControl } from '../hotkey-control'
import { NoParticipant, GroupTitle, TitleName, InactiveMessage } from '../styled'
import { inWorkStatuses } from '../utils'
import { useTranslation } from 'shared/i18n/translate'
import Card from 'shared/ui-kit/card'
import { Position } from 'features/position-dumb'
import PositionActionsStatic from 'features/position-actions/position-actions-static'
import { AttentionMessage } from 'shared/ui-kit/attention-message/attention-message'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { checkpointMonitoringEdit, checkpointMonitoringCreate, checkpointHost } from 'pages/nav'
import { CheckpointMonitoringPositionsProps } from '../types'

function CheckpointMonitoringPositions({
  line,
  shopId,
  lineId,
  checkpointId,
  onDuplicatePosition,
  onPause,
  finishLoading,
  startLoading,
  helpIsEnabled,
  showTemporaryHelpForm,
  onCancelHelpTemporary,
  onStateChange,
  defaultSelectedId,
  dialodIsBlocked,
  isFinished,
  positions,
  suggestedPositionId,
  showButtonCreatePosition,
  checkpoint,
  checkpoints
}: CheckpointMonitoringPositionsProps) {
  const { tr } = useTranslation()
  const history = useHistory()

  const [selectedId, setSelectedId] = useState<string | number | undefined>(defaultSelectedId)
  const [surveyCallIndex, setSurveyCallIndex] = useState<string | number>()

  const inWorkGroup = positions.filter((p) => inWorkStatuses.find((s) => s === p.state))
  const suggestPosition = positions.find(
    (p) => p.id === suggestedPositionId && !inWorkStatuses.find((s) => s === p.state)
  )
  const otherGroup = positions.filter(
    (p) => !(suggestPosition ? [suggestPosition, ...inWorkGroup] : inWorkGroup).find((e) => p.id === e.id)
  )

  function handlePositionClick({ id }: PositionModel) {
    setSelectedId((curr) => (id === curr ? undefined : id))
    setSurveyCallIndex(undefined)
  }

  function handlePositionClose() {
    setSelectedId(undefined)
  }

  function handleSurvay(surveyCallIndex: string | number) {
    return () => setSurveyCallIndex(surveyCallIndex)
  }

  function handlePositionUpdate(position: PositionModel) {
    return () => {
      if (position.services?.find((s) => String(s.id) === String(InternalService.Break))) {
        history.push(checkpointHost(String(shopId), String(lineId), String(checkpointId)))
      } else {
        history.push(
          checkpointMonitoringEdit(String(shopId), String(lineId), String(checkpointId), String(position.id))
        )
      }
    }
  }

  function handleAdd() {
    history.push(checkpointMonitoringCreate(String(shopId), String(lineId), String(checkpointId)))
  }

  function renderPosition(position: PositionModel) {
    return (
      <Card key={position?.id} paperStyle={{ overflow: 'hidden' }}>
        <Position
          data={position}
          readonly={position.readonly}
          onClick={handlePositionClick}
          onlyASAP={line.asapMode && !line.manageAppointments}
          onlyOneUser={!line.requestParticipantsNumber}
          supportAutoCall={line?.supportAutoCall}
          timeToReach={line.timeToReach}
          maxTimeToReach={line.maxTimeToReach}
          displayServicePoints={line.displayServicePoints}
          additionals={line.additionals || []}
          suggested={position.id === suggestedPositionId}
          isCheckpointMonitroing
          reducedMobile={String(position.id) === String(selectedId)}
          displayServiceDuration={line.displayServiceDuration || false}
          displayWaitingTimeEstimation={line.displayWaitingTimeEstimation || false}
          monitoringPositionMenuItemCaptions={line.monitoringPositionMenuItemCaptions}
          isOnlyOneServicePoint
        >
          <PositionActionsStatic
            opened={String(position.id) === String(selectedId)}
            onOpen={() => handlePositionClick(position)}
            pauseMode={!!(line?.services?.filter?.((s) => !!s.isPrivate) || []).length}
            onPause={onPause}
            position={position}
            readonly={position.readonly}
            style={{ width: '100%', marginTop: '0.5rem' }}
            displayServiceSurvey={line.displayServiceSurvey}
            sendSurveyLinkAfterPositionServed={line.sendSurveyLinkAfterPositionServed}
            sendSurveyLinkByEmail={line.sendSurveyLinkByEmail}
            sendSurveyLinkBySms={line.sendSurveyLinkBySms}
            showButtonCreatePosition={showButtonCreatePosition || false}
            finishServiceFormMode={position.id === surveyCallIndex}
            progressionTags={line.progressionTags}
            allowMultipleProgressionTags={line.allowMultipleProgressionTags}
            surveyTags={line.surveyTags}
            positionId={position.id}
            customerId={position.customer?.id}
            shopId={shopId}
            lineId={line.id}
            positionState={position.state}
            checkpoints={checkpoints}
            customCheckpointId={checkpoint?.id}
            checkpointIsDisabled={checkpoint?.status === CheckpointStatus.finished}
            onSurvay={handleSurvay(position.id)}
            onStateChange={onStateChange?.(position.id)}
            phoneNumber={position?.callPhoneNumber || position.customer?.phoneNumber}
            onUpdate={handlePositionUpdate(position)}
            displayHistory={line.displayHistory}
            displayHasApproach={line.displayApproach}
            displayRemoveConfirm={line.displayRemoveConfirm}
            requestCommentsOnRemove={line.requestCommentsOnRemove}
            sequentiallyPerformingActionsOnPosition={line.sequentiallyPerformingActionsOnPosition}
            setPositionFinishedAfterArrived={line.setPositionFinishedAfterArrived}
            displayCallByPhone={line.displayCallByPhone}
            displayConvertToMobile={line.displayConvertToMobile}
            requestCommentsOnPriorityCall={line.requestCommentsOnPriorityCall}
            duplicatePositionMenuItem={line.duplicatePositionMenuItem}
            onDuplicatePosition={onDuplicatePosition}
            generalConditionsAcceptation={line.generalConditionsAcceptation}
            monitoringPositionMenuItemCaptions={line.monitoringPositionMenuItemCaptions}
            allowProgressionPhoto={line.allowProgressionPhoto}
            onClose={handlePositionClose}
            displayPositionValidate={line.displayPositionValidate}
            lineParticipantTermInSingularTemplate={line?.lineParticipantTermInSingularTemplate}
            positionTransferEnabled={line?.flags?.positionTransferEnabled}
            supportAutoCall={line?.supportAutoCall}
            suggestNextOperationOnPosition={line?.flags?.suggestNextOperationOnPosition}
          />
        </Position>
      </Card>
    )
  }

  const displayOtherPositions = (!isFinished || !!helpIsEnabled) && !!otherGroup.length
  const displayNextPositions = !isFinished || !!helpIsEnabled || !!suggestPosition

  return (
    <>
      {!!dialodIsBlocked && <AttentionMessage color="error" message={tr.checkpointMonitoring.dialodIsBlockedMessage} />}
      {isFinished && !helpIsEnabled && (
        <InactiveMessage>
          {tr.checkpointMonitoring.closed}
          {!inWorkGroup.length && (
            <Button color="primary" style={{ marginTop: '1rem' }} fullWidth onClick={showTemporaryHelpForm}>
              {tr.checkpointMonitoring.displayParticipants(
                tr.getParticipantFormFromTemplates(
                  10,
                  line?.lineParticipantTermInSingularTemplate,
                  line?.lineParticipantTermInPluralTemplate
                )
              )}
            </Button>
          )}
        </InactiveMessage>
      )}
      {!!helpIsEnabled && (
        <>
          <AttentionMessage color="primary" message={tr.closedServicepointShowPositionsForm.formDescription} />
          <Button
            color="primary"
            variant="contained"
            size="small"
            fullWidth
            style={{ marginBottom: '2rem' }}
            onClick={onCancelHelpTemporary}
          >
            {tr.checkpointMonitoring.cancelTemporaryHelp}
          </Button>
        </>
      )}
      {(!isFinished || !!helpIsEnabled || !!inWorkGroup.length) && (
        <>
          <TitleName>{[line.name, checkpoint?.name].filter(Boolean).join(' - ')}</TitleName>
          {!!inWorkGroup.length && (
            <div>
              <GroupTitle>{tr.checkpointMonitoring.inWork}</GroupTitle>
              {inWorkGroup.map(renderPosition)}
            </div>
          )}
          {displayNextPositions && (
            <div>
              <GroupTitle>
                {tr.checkpointMonitoring.nextParticipantToCall(
                  tr.getParticipantFormFromTemplates(
                    1,
                    line?.lineParticipantTermInSingularTemplate,
                    line?.lineParticipantTermInPluralTemplate
                  )
                )}
              </GroupTitle>
              {!!suggestPosition && renderPosition(suggestPosition)}
              {!suggestPosition && (
                <>
                  <NoParticipant>
                    {tr.checkpointMonitoring.noParticipant(
                      tr.getParticipantFormFromTemplates(
                        1,
                        line?.lineParticipantTermInSingularTemplate,
                        line?.lineParticipantTermInPluralTemplate
                      )
                    )}
                  </NoParticipant>
                  {!inWorkGroup.length && !helpIsEnabled && (
                    <Button
                      color="primary"
                      size="small"
                      fullWidth
                      onClick={showTemporaryHelpForm}
                      style={{ marginBottom: '1rem' }}
                    >
                      {tr.checkpointMonitoring.displayParticipants(
                        tr.getParticipantFormFromTemplates(
                          10,
                          line?.lineParticipantTermInSingularTemplate,
                          line?.lineParticipantTermInPluralTemplate
                        )
                      )}
                    </Button>
                  )}
                </>
              )}
            </div>
          )}
          {displayOtherPositions && (
            <>
              <GroupTitle>
                {tr.checkpointMonitoring.otherParticipants(
                  tr.getParticipantFormFromTemplates(
                    20,
                    line?.lineParticipantTermInSingularTemplate,
                    line?.lineParticipantTermInPluralTemplate
                  )
                )}
              </GroupTitle>
              {otherGroup.map(renderPosition)}
            </>
          )}
          <HotkeyControl
            positions={positions}
            checkpointId={checkpoint?.id || checkpointId}
            suggestedPositionId={suggestedPositionId}
            surveyCallIndex={surveyCallIndex}
            startLoading={startLoading}
            finishLoading={finishLoading}
            setSelectedId={setSelectedId}
            openServiceSurvey={(id) => {
              setSelectedId(id)
              setSurveyCallIndex(id)
            }}
            navigateCreatePage={handleAdd}
            displayServiceSurvey={line.displayServiceSurvey}
            shopId={shopId}
            lineId={lineId}
          />
        </>
      )}
    </>
  )
}

export { CheckpointMonitoringPositions }
