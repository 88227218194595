import { PositionAction } from 'shared/models'
import { ActionItem } from '../types'

function getOverValue(actions: ActionItem[], value: PositionAction) {
  const index = actions.findIndex((a) => a.action === value)

  if (index >= 0) {
    return actions
      .slice(index + 1)
      .filter((a) => a.active)
      .filter((a) => !a.unstaged)
      .map((a) => a.action)
  }

  return []
}

export { getOverValue }
