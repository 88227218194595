import styled from '@emotion/styled'

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const BarWrapper = styled.div`
  display: flex;
  width: 100%;
`

const BarContent = styled.div<{ $isLargeSize?: boolean }>`
  height: ${(props) => (!props.$isLargeSize ? 64 : 72)}px;
  display: flex;
  align-items: center;
  gap: 4px;
  align-items: center;
`

const MenuWrapper = styled.div`
  padding: 0 0.5rem;
  max-width: 280px;
`

const SearchWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`

const PositionCode = styled.div`
  display: flex;
  align-items: center;
  margin: 0 1rem;
  flex: 1;
  background: #f2f1f1;
  padding: 4px 4px 4px 12px;
  border-radius: 4px;
`

const StatsWrapper = styled.div`
  height: 64;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const StatsMin = styled.div`
  color: #404040;
  margin: 0 8px 0 4px;
`

const IconWrapper = styled.div`
  height: 64;
  display: flex;
  align-items: center;
`

const FilterMenuWrapper = styled.div`
  margin: 4px 12px;
  display: flex;
  flex-direction: column;
  min-width: 170px;
  max-width: 200px;
`

const LoadingCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 4rem);
`

const ShowPositionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

const HidePositionContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`

const MonitoringRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
`

const MonitoringBarContainer = styled.div<{ $smallScreen?: boolean }>`
  min-width: 640px;

  ${(props) => props.$smallScreen && { minWidth: 0, flex: 1 }}
`

const MonitoringBar = styled.div<{ $smallScreen?: boolean; $top?: number }>`
  position: sticky;
  width: 100%;
  z-index: 1100;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  box-shadow:
    0 1px 6px rgba(0, 0, 0, 0.12),
    0 1px 4px rgba(0, 0, 0, 0.12);
  display: flex;
  min-height: 64px;
  align-items: center;
  justify-content: center;
  background: #fff;
  top: ${({ $top }) => `${$top || 64}px`};
  left: 0;
  white-space: nowrap;

  ${(props) => props.$smallScreen && { padding: '0 0.5rem' }}
`

export {
  ColumnWrapper,
  BarWrapper,
  BarContent,
  MenuWrapper,
  SearchWrapper,
  PositionCode,
  StatsWrapper,
  StatsMin,
  IconWrapper,
  FilterMenuWrapper,
  LoadingCenter,
  ShowPositionContainer,
  HidePositionContainer,
  MonitoringRoot,
  MonitoringBarContainer,
  MonitoringBar
}
