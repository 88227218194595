import styled from '@emotion/styled'

const Bar = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1100;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  box-shadow:
    0 1px 6px rgba(0, 0, 0, 0.12),
    0 1px 4px rgba(0, 0, 0, 0.12);
  display: flex;
  min-height: 3rem;
  align-items: center;
  justify-content: center;
  background: #fff;
  top: 4rem;
  left: 0;
  white-space: nowrap;

  @media screen and (max-width: 720px) {
    padding: 0 1rem;
  }
`

export { Bar }
