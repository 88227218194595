import { PositionModel, PositionState, PositionType } from 'shared/models'
import TimeHelper from 'shared/utils/time'

function isProblemCheckpointPosition(position: PositionModel, checkpoint) {
  if (!position) {
    return false
  }

  const nowMs = Date.now()

  const maxOverLimit = TimeHelper.minMS * 5
  const attentionByPositionState = {
    [PositionState.InService]: (position) => {
      if (position.type === PositionType.Break) {
        return false
      }

      const { serviceStartedTime_unixtime = nowMs, services = [], personsQuantity = 1 } = position

      const servicesDuration = services.reduce((prev, curr) => {
        return prev + curr.duration * personsQuantity
      }, 0)

      const lostTimeMs = serviceStartedTime_unixtime + servicesDuration * 1000 + maxOverLimit - nowMs

      const isLostTime = lostTimeMs < 0
      return isLostTime
    },
    [PositionState.CalledToCheckpoint]: (position: PositionModel) => {
      const { callTime_unixtime = nowMs } = position
      const isLostTime = callTime_unixtime + maxOverLimit - nowMs < 0
      return isLostTime
    },
    [PositionState.Joined]: (position: PositionModel, checkpoint) => {
      const { lastActionTime = nowMs } = checkpoint
      const isLostTime = lastActionTime + maxOverLimit - nowMs < 0
      return isLostTime
    },
    [PositionState.InLineHere]: (position: PositionModel, checkpoint) => {
      const { lastActionTime = nowMs } = checkpoint
      const isLostTime = lastActionTime + maxOverLimit - nowMs < 0
      return isLostTime
    },
    default: () => false
  }
  return (attentionByPositionState[position.state] || attentionByPositionState.default)(position, checkpoint)
}

export { isProblemCheckpointPosition }
