import axios from 'axios'
import { CheckpointAPI } from 'shared/models'

const checkpoint: CheckpointAPI = {
  getCheckpointList(shopId, lineId, config) {
    return axios.get('getCheckpointList', { params: { shopId, lineId }, ...config })
  },
  getCheckpoint(id, placeId, lineId, config) {
    return axios.get('getCheckpoint', { params: { id, lineId, placeId }, ...config })
  },
  createCheckpoint(data, config) {
    return axios.post('createCheckpoint', data, config)
  },
  updateCheckpoint(data, config) {
    return axios.put('updateCheckpoint', data, config)
  },
  deleteCheckpoint(id, config) {
    return axios.delete('deleteCheckpoint', { data: id, headers: { 'Content-Type': 'application/json' }, ...config })
  },
  getCheckpointHost(id, config) {
    return axios.get('getCheckpointHost', { params: { id }, ...config })
  },
  finishCheckpoint(id, config) {
    return axios.post('finishCheckpoint', id, { headers: { 'Content-Type': 'application/json' }, ...config })
  },
  updateCheckpointHost(data, config) {
    return axios.put('updateCheckpointHost', data, config)
  },
  createServicePosition(data, config) {
    return axios.post('monitoring/servicePoint/pause', data, config)
  },
  openHideCheckpoint(checkpointId, reason, comment, lineId, config) {
    return axios.post('openHideCheckpoint', { checkpointId, reason, comment, lineId }, config)
  },
  updateCheckpointListOrder(data, config) {
    return axios.put('updateCheckpointListOrder', data, config)
  }
}

export default checkpoint
