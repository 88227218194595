import get from 'lodash/get'

const secMS = 1000
const minMS = secMS * 60
const hourMS = minMS * 60
const dayMS = hourMS * 24
const weekMS = dayMS * 7
const yearMS = dayMS * 365.25

export function getTimeFromSec(seconds: number) {
  const ms = Math.max(seconds, 0) * 1000
  const date = new Date(ms)
  return { hour: date.getUTCHours(), minute: date.getUTCMinutes() }
}

export default { secMS, minMS, hourMS, dayMS, weekMS, yearMS }

export function groupByTimePeriod<T>(
  array: T[],
  timestampKey: keyof T | string,
  period: 'day' | 'week' | 'month' | 'year',
  timezoneOffset = 0
) {
  const objPeriod: { [id: number]: T[] } = {}

  for (let i = 0; i < array.length; i++) {
    const value = get(array[i], timestampKey)

    if (!(typeof value === 'string' || typeof value === 'number')) return {}

    const d = new Date(new Date(value).getTime() + timezoneOffset)
    let groupKey = 0

    switch (period) {
      case 'day':
        groupKey = Math.floor(d.getTime() / dayMS) * dayMS
        break
      case 'week':
        groupKey = Math.floor(d.getTime() / weekMS) * weekMS
        break
      case 'month':
        groupKey = (d.getFullYear() - 1970) * 12 + d.getMonth()
        break
      case 'year':
        groupKey = d.getFullYear()
        break
    }

    objPeriod[groupKey] = objPeriod[groupKey] ? [...objPeriod[groupKey], array[i]] : [array[i]]
  }
  return objPeriod
}

export function msToMin(ms?: number): number {
  if (ms === undefined || ms === null) return 0
  const mins = ms / minMS
  return mins
}

export function isSameDay(date1: Date | number | string, date2: Date | number | string) {
  const dateOne = new Date(date1)
  const dateTwo = new Date(date2)
  return (
    dateOne.getDate() === dateTwo.getDate() &&
    dateOne.getMonth() === dateTwo.getMonth() &&
    dateOne.getFullYear() === dateTwo.getFullYear()
  )
}
