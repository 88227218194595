import { FormState, UseFormWatch } from 'react-hook-form'
import { Translations } from 'shared/i18n/translation-types'
import { LineMode, LineModel } from 'shared/models'
import { GroupModel } from './types'

function getLineEditGroups(
  formState: FormState<LineModel>,
  tr: Translations,
  watch: UseFormWatch<LineModel>
): GroupModel[] {
  return [
    {
      key: 'parameters',
      label: tr.lineEdit.parameters,
      invalid:
        !!formState.errors.name ||
        !!formState.errors.description ||
        !!formState.errors.maxSimultaneous ||
        !!formState.errors.smsSenderId ||
        !!formState.errors.surveyLink ||
        !!formState.errors.shortName
    },
    { key: 'personalInfo', label: tr.lineEdit.personalInfo },
    { key: 'modes', label: tr.lineEdit.modes },
    !!watch('modes').find((el) => [LineMode.MobileApp, LineMode.WebFrame].includes(el)) && {
      key: 'mobileApp',
      label: tr.lineEdit.mobileAppGroup,
      invalid: !!formState.errors.beforeTheCallTime
    },
    { key: 'services', label: tr.lineEdit.services, invalid: !!formState.errors.services },
    { key: 'additionalFields', label: tr.lineEdit.additionalFields, invalid: !!formState.errors.additionals },
    {
      key: 'progressionTags',
      label: tr.lineEdit.progressionTags.title,
      invalid: !!formState.errors.progressionTags || !!formState.errors.progressionParticipantEmails
    },
    {
      key: 'appointmentForNextDay',
      label: tr.lineEdit.appointmentForNextDay,
      invalid:
        !!formState.errors.timeslotsFreeAllBeforeDays ||
        !!formState.errors.limitInDays ||
        !!formState.errors.sendFollowUpSmsBeforeDays ||
        !!formState.errors.sendFollowUpEmailBeforeDays ||
        !!formState.errors.minDurationToFreeTimeslot ||
        !!formState.errors.maxDurationToFreeTimeslot ||
        !!formState.errors.blindCopyEmails
    },
    {
      key: 'openingHours',
      label: tr.lineEdit.openingHours,
      invalid: !!formState.errors.schedule
    },
    {
      key: 'durations',
      label: tr.lineEdit.durations,
      invalid:
        !!formState.errors.serviceTime ||
        !!formState.errors.timeToReach ||
        !!formState.errors.maxTimeToReach ||
        !!formState.errors.minTimeToFreePlaceAfterEarlyAcceptance
    },
    { key: 'monitoring', label: tr.lineEdit.monitoringGroup },
    { key: 'operationsMenu', label: tr.lineEdit.operationsMenu },
    {
      key: 'advanced',
      label: tr.lineEdit.advancedSettings
    }
  ].filter(Boolean) as GroupModel[]
}

export { getLineEditGroups }
