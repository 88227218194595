import styled from '@emotion/styled'

const ProgressionImageLink = styled.div`
  cursor: pointer;
  color: #0052b4;
  font-size: 14px;
`

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 1rem;
`

const PositionId = styled.div`
  font-size: 10px;
  font-weight: 500;
  cursor: default;
  margin-top: 0.5rem;
`

const AdditionalRow = styled.div`
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem 1rem;
`

const JournalItemTopInfo = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`

const ShopName = styled.div`
  font-size: 16px;
  line-height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
`

const LineName = styled.div`
  font-size: 12px;
  margin-top: 4px;
  line-height: 1;
  text-overflow: ellipsis;
  overflow: hidden;
`

const JournalItemStatWrapper = styled.div`
  margin-top: 4px;
  margin-left: 48px;
  font-size: 14px;
`

export {
  ProgressionImageLink,
  AvatarWrapper,
  PositionId,
  AdditionalRow,
  JournalItemTopInfo,
  ShopName,
  LineName,
  JournalItemStatWrapper
}
