import { useContext } from 'react'
import { ITranslationContextProps, TranslateContext } from 'shared/i18n/translation-context'

// Legacy component wrapper
function translate(Component) {
  return function ComponentWithTranslation(props) {
    return (
      <TranslateContext.Consumer>
        {(value) => (
          <Component
            {...props}
            locale={value?.locale}
            tr={value?.tr}
            translation={value?.tr}
            dateFormatter={value?.dateFormatter}
            timeZoneId={value?.timeZoneId}
            timeZoneOffset={value?.timeZoneOffset}
          />
        )}
      </TranslateContext.Consumer>
    )
  }
}

function useTranslation() {
  return useContext(TranslateContext) as ITranslationContextProps
}

export default translate
export { useTranslation }
