import { Divider } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import PositionActionComponent from '../../components/position-action'
import { CallToPhoneProps } from './types'
import PhoneIcon from '@mui/icons-material/Phone'

function CallToPhone(props: CallToPhoneProps) {
  const { tr } = useTranslation()

  function handleSubmit() {
    window.open(`tel:${props.phoneNumber}`)
    props.onSubmit()
  }

  return (
    <>
      {!props.isCardView && <Divider />}
      <PositionActionComponent
        isCardView={props.isCardView}
        onSubmit={handleSubmit}
        icon={<PhoneIcon fontSize="small" />}
      >
        {tr.positionActions.call} {props.phoneNumber}
      </PositionActionComponent>
    </>
  )
}

export { CallToPhone }
