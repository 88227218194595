import axios from 'axios'
import { MessageTemplatesAPI } from 'shared/models'

const messageTemplates: MessageTemplatesAPI = {
  getMessageTemplatesConfig(lineId, placeId, config) {
    return axios.get('getMessageTemplatesConfig', { params: { lineId, shopId: placeId }, ...config })
  },
  getMessageTemplate(data, config) {
    return axios.get('getMessageTemplate', { params: data, ...config })
  },
  updateMessageTemplate(data, config) {
    return axios.post('updateMessageTemplate', data, config)
  }
}

export default messageTemplates
