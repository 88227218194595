import css from './load-more.module.scss'
import LoadIcon from '@mui/icons-material/Cached'
import { useTranslation } from 'shared/i18n/translate'

type Props = {
  onClick: () => void
  loading?: boolean
}

function LoadMore(props: Props) {
  const { tr } = useTranslation()
  const { onClick, loading = false } = props
  const iconClasses = [css.icon, loading && css.spin].filter(Boolean).join(' ')

  return (
    <div className={css.root} onClick={onClick}>
      <LoadIcon className={iconClasses} />
      <div className={css.text}>{tr.entityList.loadMore}</div>
    </div>
  )
}

export default LoadMore
