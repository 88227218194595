import { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { place as placeApi } from 'shared/api'
import { useHistory, useLocation } from 'react-router'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { PlaceModelSimplified } from 'shared/models'
import { PlaceList } from './dumb/list'
import { place, createPlace } from 'pages/nav'
import { AppBar, IconButton, Toolbar } from '@mui/material'
import { Add as AddIcon, DomainDisabled as PlaceIcon } from '@mui/icons-material'
import EmptyScreen from 'shared/ui-kit/empty-screen'
import Container from 'shared/ui-kit/container'
import PageLoader from 'shared/ui-kit/page-loader'
import { StorageItem } from 'shared/utils/storage'
import { countries } from 'shared/countries'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { SearchInput } from 'features/search-input'
import { useAppDispatch, useAppSelector } from 'store'
import { useQuery } from 'react-query'
import { ListBarWrapper } from './styled'
import { placesFilter } from './utils/filter'

function PlaceListPage() {
  const { canAddAndDeleteShops } = useAppSelector(getPermissionsSelector)
  const { tr, locale } = useTranslation()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { search } = useLocation()

  const placesTerm = useMemo(() => new StorageItem<string>('placesTerm'), [])
  const params = useMemo(() => new URLSearchParams(search), [])

  const [term, setTerm] = useState<string>(new URLSearchParams(search).get('term') || placesTerm.value || '')

  const { data, isLoading } = useQuery(['places'], ({ signal }) => placeApi.getPlaceListSimplified({ signal }))
  const { data: countriesValue } = useQuery(['countries', locale], () => countries.getCountries(locale))

  useEffect(() => {
    if (term) {
      params.set('term', term)
    } else {
      params.delete('term')
    }

    placesTerm.value = term

    history.replace({ search: params.toString() })
  }, [term])

  useEffect(setCrumbs, [locale])

  function setCrumbs() {
    const crumb = getCrumbs(tr.breadcrumbs)
    dispatch(setBreadcrumbs([crumb.home(), crumb.places()]))
  }

  function handleCreate() {
    history.push(createPlace())
  }

  function handleTermChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setTerm(event.target.value)
  }

  function handleTermClear() {
    setTerm('')
  }

  function handleSelect(data: PlaceModelSimplified) {
    history.push(place(String(data.id)))
  }

  if (isLoading) {
    return <PageLoader />
  }

  const filteredPlaces = placesFilter(term, data?.data || [], countriesValue || [])

  return (
    <>
      <AppBar position="sticky" color="inherit" elevation={2} style={{ top: '4rem', borderRadius: 0 }}>
        <Toolbar>
          <Container style={{ padding: 'unset' }}>
            <ListBarWrapper>
              {canAddAndDeleteShops && (
                <IconButton onClick={handleCreate}>
                  <AddIcon />
                </IconButton>
              )}
              <SearchInput
                fullWidth
                autoFocus
                value={term}
                onChange={handleTermChange}
                placeholder={tr.common.search}
                onClear={handleTermClear}
              />
            </ListBarWrapper>
          </Container>
        </Toolbar>
      </AppBar>
      <Container>
        {!!filteredPlaces.length && (
          <PlaceList data={filteredPlaces} onSelect={handleSelect} countriesValue={countriesValue || []} />
        )}
        {!filteredPlaces.length && <EmptyScreen iconClass={PlaceIcon} text={tr.placeList.noPlaceFound} />}
      </Container>
    </>
  )
}

export default PlaceListPage
