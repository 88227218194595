import { useEffect, useRef, useState } from 'react'
import { Form, FormGroup } from 'shared/ui-kit/form'
import { useTranslation } from 'shared/i18n/translate'
import Card from 'shared/ui-kit/card'
import { Button, Tooltip, IconButton, Menu, MenuItem, Typography, Box } from '@mui/material'
import { Code as CodeIcon, HelpOutline as HelpIcon } from '@mui/icons-material'
import { addTestAttr } from 'shared/utils/test-attr'
import { TooltipStyled } from 'features/element-with-helper/styled'
import { PreMessage } from './styled'
import TextField from '@mui/material/TextField'
import { MessageTemplatesFormProps } from './types'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { useAppSelector } from 'store'

function MessageTemplatesForm(props: MessageTemplatesFormProps) {
  const { canUpdateShops } = useAppSelector(getPermissionsSelector)
  const { tr } = useTranslation()

  const [data, setData] = useState(props.data)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [parentMessageCard, setParentMessageCard] = useState(!props.data?.text && !!props.parentMessage)

  const select = useRef<{ start?: number; end?: number }>({})

  useEffect(() => {
    setData(props.data)
    setParentMessageCard(!props.data?.text && !!props.parentMessage)
  }, [props.data])

  function handleTextChange(ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const newData = { ...data }
    newData.text = ev.target.value
    setData(newData)
  }

  function handleTextSelect(ev: any) {
    select.current = { start: ev.target.selectionStart, end: ev.target.selectionEnd }
  }

  function handleMenuItemClick(keyword: string) {
    return () => {
      let text = data?.text || ''
      const selectValue = select.current
      const proKeyword = `{{${keyword}}}`

      if (text.length === 0) {
        text = `${proKeyword} `

        if (!select.current) {
          select.current = {}
        }

        select.current.start = text.length
        select.current.end = text.length
      } else {
        if (select && (selectValue.start || selectValue.start === 0)) {
          const beforeInsert = text.substring(0, selectValue.start)
          const afterInsert = text.substring(selectValue.end || 0, text.length)
          text = `${beforeInsert}${proKeyword} ${afterInsert}`
          select.current.start = `${beforeInsert}${proKeyword} `.length
          select.current.end = `${beforeInsert}${proKeyword} `.length
        } else {
          text = `${text}${proKeyword} `
          select.current.start = text.length
          select.current.end = text.length
        }
      }

      const newData = { ...data }
      newData.text = text
      setData(newData)
    }
  }

  function handleGlobalTemplateApply() {
    const newData = { ...data }
    newData.text = props.parentMessage?.text || ''
    setData(newData)
    setParentMessageCard(false)
  }

  return (
    <>
      <Card>
        <Form>
          <FormGroup style={{ display: 'flex', alignItems: 'flex-end', position: 'relative' }}>
            <TextField
              style={{ flex: 1 }}
              label={tr.messageTemplates.text}
              placeholder={tr.messageTemplates.textPlaceholder}
              value={data.text || ''}
              onChange={handleTextChange}
              onSelect={handleTextSelect}
              maxRows={15}
              fullWidth
              InputLabelProps={{ shrink: true }}
              multiline
              disabled={props.isFetching}
              {...addTestAttr('MessageTemplates-Text')}
            />
            <Box position="sticky" bottom={0}>
              <Tooltip title={tr.messageTemplates.keywords} placement="top">
                <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
                  <CodeIcon />
                </IconButton>
              </Tooltip>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} elevation={2}>
                {Object.keys(props.config.keywords).map((keyword: any) => (
                  <MenuItem
                    key={keyword.key || keyword}
                    value={keyword.key || keyword}
                    style={{ lineHeight: '32px', minHeight: 32 }}
                    onClick={handleMenuItemClick(keyword.key || keyword)}
                  >
                    <Box flex={1}>{keyword.key || keyword}</Box>
                    {props.config.keywords[keyword] && (
                      <TooltipStyled
                        arrow
                        title={props.config.keywords[keyword]}
                        leaveTouchDelay={3000}
                        enterTouchDelay={0}
                      >
                        <HelpIcon fontSize="small" style={{ opacity: 0.56, marginLeft: '0.5rem' }} />
                      </TooltipStyled>
                    )}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </FormGroup>
        </Form>
      </Card>
      {!!canUpdateShops && (
        <Box display="flex" gap="0.5rem" marginBottom="1rem">
          <Button
            size="small"
            color="primary"
            variant="contained"
            disabled={props.isFetching}
            onClick={() => props.onSave(data)}
            {...addTestAttr('MessageTemplates-SaveButton')}
          >
            {tr.messageTemplates.save}
          </Button>
          {props.parentMessage && (
            <Button
              size="small"
              variant="outlined"
              disabled={props.isFetching}
              onClick={() => {
                setParentMessageCard((curr) => !curr)
              }}
              {...addTestAttr('MessageTemplates-Global')}
            >
              {tr.messageTemplates.parentMessage}
            </Button>
          )}
        </Box>
      )}
      {parentMessageCard && props.parentMessage && (
        <Card>
          <FormGroup>
            <Box fontSize="12px" sx={{ opacity: '0.56' }}>
              {tr.messageTemplates.parentMessage}
            </Box>
            <PreMessage>{props.parentMessage.text}</PreMessage>
          </FormGroup>
          <Box padding="0 1rem 1rem">
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={handleGlobalTemplateApply}
              disabled={props.isFetching}
              {...addTestAttr('MessageTemplates-ApplyGlobal')}
            >
              {tr.messageTemplates.applyGlobal}
            </Button>
          </Box>
        </Card>
      )}
      {!!props.data?.description && (
        <Card>
          <FormGroup>
            <Typography variant="subtitle2">{tr.messageTemplates.description}</Typography>
          </FormGroup>
          <FormGroup>
            <Typography variant="body2">{props.data.description}</Typography>
          </FormGroup>
        </Card>
      )}
    </>
  )
}

export { MessageTemplatesForm }
