import { Card } from '@mui/material'
import { Position } from 'features/position-dumb'
import PositionActionsStatic from 'features/position-actions/position-actions-static'
import { setDuplicatePosition } from 'features/position-actions/utils/duplicateStorage'
import { lineMonitoringCreate, lineMonitoringEdit, discreteLineMonitoringCreate, checkpointHost } from 'pages/nav'
import { forwardRef, useMemo } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { CheckpointModel, InternalService, LineModel, PositionModel, PositionState, PositionType } from 'shared/models'

type Props = {
  waitingPositionIds: (string | number)[]
  position: PositionModel
  selected?: boolean
  line: LineModel
  placeId: string
  lineId: string
  checkpoints: CheckpointModel[]
  setWaitingPositionIds: (waitingPositionIds: (string | number)[]) => void
  onRemove: (positionId?: string | number) => void
  onStateChange: (positionId: string | number, newState: PositionState) => void
  onSelect: () => void
  onClose: () => void
}

const MonitoringPosition = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const history = useHistory()
  const match = useRouteMatch()

  function handleValidatePosition() {
    props.onClose()
    props.setWaitingPositionIds([...props.waitingPositionIds, props.position.id])
  }

  function handleDuplicatePosition({ placeId, line, position }) {
    const data = { position, back: { placeId, lineId: props.lineId, link: match.url } }
    setDuplicatePosition(placeId, data)

    if (line.isDiscrete) {
      history.push(`${discreteLineMonitoringCreate(placeId, line.id)}?duplicate`)
    } else {
      history.push(`${lineMonitoringCreate(placeId, line.id)}?duplicate`)
    }
  }

  function handleStateChange(newState: PositionState) {
    props.onStateChange(props.position.id, newState)
  }

  function handlePositionUpdate() {
    if (props.position.services?.find((s) => String(s.id) === String(InternalService.Break))) {
      history.push(
        checkpointHost(
          props.placeId,
          props.lineId,
          String(props.position.customCheckpoint?.id || props.position.assignedCheckpoint?.id)
        )
      )
    } else {
      history.push(lineMonitoringEdit(props.placeId, props.lineId, String(props.position.id)))
    }
  }

  const onlyASAP = useMemo(() => !!props.line && props.line.asapMode && !props.line.manageAppointments, [props.line])

  const waitingUpdate = useMemo(
    () => !!props.waitingPositionIds.find((el) => el === props.position.id),
    [props.waitingPositionIds]
  )

  const defaultTypes = useMemo(
    () =>
      [!!props.line.allowFutureAppointments && PositionType.Appointment, PositionType.Break].filter(
        Boolean
      ) as PositionType[],
    [props.line]
  )

  const positionTypesValues = useMemo(() => Object.values(PositionType), [])

  const modesConverted = useMemo(
    () =>
      (props.line?.modes || [])
        .map((m) => positionTypesValues.find((pt) => pt.toLowerCase() === m.toLowerCase()))
        .filter(Boolean) as PositionType[],
    [props.line]
  )

  const supportMobile = useMemo(
    () => !![...modesConverted, ...defaultTypes].find((el) => el === PositionType.MobileApp),
    [modesConverted]
  )

  return (
    <Card ref={ref} key={props.position.id}>
      <Position
        key={props.position.id}
        data={props.position}
        readonly={props.position.readonly}
        onClick={props.onSelect}
        onlyASAP={onlyASAP}
        onlyOneUser={!props.line.requestParticipantsNumber}
        supportAutoCall={props.line.supportAutoCall}
        timeToReach={props.line.timeToReach}
        maxTimeToReach={props.line.maxTimeToReach}
        displayServicePoints={props.line.displayServicePoints}
        lineParticipantTermInPluralTemplate={props.line?.lineParticipantTermInPluralTemplate}
        lineParticipantTermInSingularTemplate={props.line?.lineParticipantTermInSingularTemplate}
        monitoringPositionMenuItemCaptions={props.line.monitoringPositionMenuItemCaptions}
        additionals={props.line.additionals || []}
        waitingUpdate={waitingUpdate}
        reducedMobile={props.selected}
        displayServiceDuration={props.line.displayServiceDuration}
        displayWaitingTimeEstimation={props.line.displayWaitingTimeEstimation || false}
        isOnlyOneServicePoint={props.checkpoints?.length < 2}
      >
        <PositionActionsStatic
          opened={props.selected}
          onOpen={props.onSelect}
          position={props.position}
          readonly={props.position.readonly}
          positionId={props.position.id}
          customerId={props.position.customer?.id}
          shopId={props.placeId}
          lineId={props.lineId}
          supportMobile={supportMobile}
          positionState={props.position.state}
          checkpoints={props.checkpoints}
          customCheckpointId={props.position.customCheckpoint?.id}
          phoneNumber={props.position?.callPhoneNumber || props.position.customer?.phoneNumber}
          displayServiceSurvey={props.line.displayServiceSurvey}
          sendSurveyLinkAfterPositionServed={props.line.sendSurveyLinkAfterPositionServed}
          sendSurveyLinkByEmail={props.line.sendSurveyLinkByEmail}
          sendSurveyLinkBySms={props.line.sendSurveyLinkBySms}
          surveyTags={props.line.surveyTags}
          progressionTags={props.line.progressionTags}
          allowMultipleProgressionTags={props.line.allowMultipleProgressionTags}
          showButtonCreatePosition={props.line.showButtonCreatePosition || false}
          displayInLineHere={props.line.displayInPlace}
          displayPositionValidate={props.line.displayPositionValidate}
          displayHistory={props.line.displayHistory}
          displayHasApproach={props.line.displayApproach}
          displayRemoveConfirm={props.line.displayRemoveConfirm}
          sequentiallyPerformingActionsOnPosition={props.line.sequentiallyPerformingActionsOnPosition}
          requestCommentsOnRemove={props.line.requestCommentsOnRemove}
          setPositionFinishedAfterArrived={props.line.setPositionFinishedAfterArrived}
          displayCallByPhone={props.line.displayCallByPhone}
          displayConvertToMobile={props.line.displayConvertToMobile}
          requestCommentsOnPriorityCall={props.line.requestCommentsOnPriorityCall}
          duplicatePositionMenuItem={props.line.duplicatePositionMenuItem}
          generalConditionsAcceptation={props.line.generalConditionsAcceptation}
          monitoringPositionMenuItemCaptions={props.line.monitoringPositionMenuItemCaptions}
          allowProgressionPhoto={props.line.allowProgressionPhoto}
          onValidatePosition={handleValidatePosition}
          onStateChange={handleStateChange}
          onUpdate={handlePositionUpdate}
          onDuplicatePosition={handleDuplicatePosition}
          onRemove={props.onRemove}
          onClose={props.onClose}
          lineParticipantTermInSingularTemplate={props.line?.lineParticipantTermInSingularTemplate}
          positionTransferEnabled={props.line?.flags?.positionTransferEnabled}
          supportAutoCall={props.line?.supportAutoCall}
          suggestNextOperationOnPosition={props.line?.flags?.suggestNextOperationOnPosition}
          closeAfterActionIsFinished
        />
      </Position>
    </Card>
  )
})

export { MonitoringPosition }
