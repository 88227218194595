import { useTranslation } from 'shared/i18n/translate'
import Card from 'shared/ui-kit/card'
import { Form, FormGroup } from 'shared/ui-kit/form'
import Text from 'shared/ui-kit/text'
import { List } from 'shared/ui-kit/list'
import css from './customer.module.scss'
import trim from 'lodash/trim'
import { ChevronLeft as LeftIcon, ChevronRight as RightIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import { Accordion, Typography, AccordionSummary } from '@mui/material'
import { CustomerProps } from './types'

function Customer(props: CustomerProps) {
  const { tr, dateFormatter } = useTranslation()

  function getDate(date) {
    return dateFormatter(date, { year: 'numeric', month: 'long', day: '2-digit' })
  }

  function getTime(time) {
    return dateFormatter(time, { hour: 'numeric', minute: '2-digit' })
  }

  function handleUsesSelect(data) {
    props.onUsesSelect(data.id)
  }

  const d = props.data

  const {
    customer,
    applicationUsage,
    email,
    phone,
    device,
    application,
    age,
    applicationUsageValue,
    customerValue,
    lastUses
  } = tr.customer

  return (
    <div>
      <div className={css.customerWrap}>
        {!!props.prevId && (
          <div className={`${css.navButton} ${css.navButton_left}`} onClick={() => props.onNav(props.prevId)}>
            <LeftIcon className={css.navIcon} />
          </div>
        )}
        <Card style={{ flex: 1 }}>
          <Form>
            <FormGroup>
              <Text type="caption">{customer}</Text>
              <Text type="body-1">{customerValue(d.id, d.name ? trim(d.name) : '')}</Text>
            </FormGroup>
            <FormGroup>
              <Text type="caption">{applicationUsage}</Text>
              <Text type="body-1">
                {applicationUsageValue(d.numberOfUses, getDate(d.dateOfFirstUse), getDate(d.dateOfLastUse))}
              </Text>
            </FormGroup>
            {!!trim(d.email) && (
              <FormGroup>
                <Text type="caption">{email}</Text>
                <Text type="body-1">{d.email}</Text>
              </FormGroup>
            )}
            {!!trim(d.phone) && (
              <FormGroup>
                <Text type="caption">{phone}</Text>
                <Text type="body-1">{d.phone}</Text>
              </FormGroup>
            )}
            {!!trim(d.deviceInfo) && (
              <FormGroup>
                <Text type="caption">{device}</Text>
                <Text type="body-1">{d.deviceInfo}</Text>
              </FormGroup>
            )}
            {!!trim(d.applicationInfo) && (
              <FormGroup>
                <Text type="caption">{application}</Text>
                <Text type="body-1">{d.applicationInfo}</Text>
              </FormGroup>
            )}
          </Form>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{lastUses}</Typography>
            </AccordionSummary>
            <List
              style={{ width: '100%' }}
              data={d.lastUses}
              dataConverter={(item) => ({
                id: item.id,
                primaryText: [item.shopName, item.lineName, item.checkpointName].filter(Boolean).join(', '),
                secondaryText: getDate(item.date),
                tertiaryText: item.serviceName
              })}
              onSelect={handleUsesSelect}
            />
          </Accordion>
        </Card>
        {!!props.nextId && (
          <div className={`${css.navButton} ${css.navButton_right}`} onClick={() => props.onNav(props.nextId)}>
            <RightIcon className={css.navIcon} />
          </div>
        )}
      </div>
    </div>
  )
}

export default Customer
