import { AdvertisementTypes } from 'shared/models'
import * as Yup from 'yup'

function getAdvertisementFormValidation() {
  return Yup.object().shape({
    name: Yup.string().required(),
    link: Yup.string().when('withLink', {
      is: true,
      then: (schema) => schema.required()
    }),
    text: Yup.string().when('type', {
      is: AdvertisementTypes.TEXT,
      then: (schema) => schema.required().max(1000)
    }),
    pdf: Yup.object().when('type', {
      is: AdvertisementTypes.PDF,
      then: (schema) => schema.required()
    }),
    image: Yup.object().when('type', {
      is: AdvertisementTypes.IMAGE,
      then: (schema) => schema.required()
    }),
    video: Yup.object().when('type', {
      is: AdvertisementTypes.VIDEO,
      then: (schema) =>
        schema.when('withLink', {
          is: true,
          then: (schema) => schema,
          otherwise: (schema) => schema.required()
        })
    }),
    orientation: Yup.string().when('infoPad', {
      is: true,
      then: (schema) => schema.required()
    }),
    startDate: Yup.number().when('specificHours', {
      is: true,
      then: (schema) => schema.required()
    }),
    endDate: Yup.number().test({
      test: (value, context) => {
        if (!context.parent.specificHours) {
          return true
        }

        if (!value) {
          return false
        }

        if (!!context.parent.startDate && context.parent.startDate >= value) {
          return false
        }

        return true
      }
    }),
    displaySchedule: Yup.array()
      .nullable()
      .test({
        test: (value, context) => {
          if (!context.parent.specificHoursIntervals) {
            return true
          }

          if (!value?.length) {
            return true
          }

          return !(value || []).map?.((el) => !el.startHour_unixtime || !el.endHour_unixtime).some((el) => el === true)
        }
      })
  })
}

export { getAdvertisementFormValidation }
