import { Form, FormGroup } from 'shared/ui-kit/form'
import Toggle from 'shared/ui-kit/toggle-wrap'
import { useTranslation } from 'shared/i18n/translate'
import { Controller } from 'react-hook-form'
import { GroupProps } from '../types'
import Dropdown from 'shared/ui-kit/dropdown'
import Card from 'shared/ui-kit/card'

function OperationsMenuGroup({ control, watch }: GroupProps) {
  const { tr } = useTranslation()

  const {
    displayInPlace,
    displayPositionValidate,
    displayApproach,
    setArrivedAfterCallFinished,
    displayHistory,
    displayCallByPhone,
    displayConvertToMobile,
    displayDuplicate,
    generalConditionsAcceptation
  } = tr.lineEdit

  return (
    <Card>
      <Form>
        <FormGroup>
          <Controller
            name="displayCallByPhone"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={displayCallByPhone}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="displayCallByPhone"
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="displayInPlace"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={displayInPlace}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="displayInPlace"
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="generalConditionsAcceptation"
            defaultValue="No"
            control={control}
            render={({ field: f }) => (
              <Dropdown
                ensureValue
                label={
                  watch('monitoringPositionMenuItemCaptions')?.generalConditionsAcceptation ||
                  tr.lineEdit.generalConditionsAcceptationTemplateIsNotFilled
                }
                value={f.value}
                data={['No', 'Optional', 'Mandatory']}
                dataConverter={(item) => ({ value: item, text: generalConditionsAcceptation[item] })}
                fullWidth
                onChange={f.onChange}
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="displayPositionValidate"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={displayPositionValidate}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="displayPositionValidate"
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="displayApproach"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={displayApproach}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="displayApproach"
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="setPositionFinishedAfterArrived"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={setArrivedAfterCallFinished}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="setPositionFinishedAfterArrived"
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="displayHistory"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={displayHistory}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="displayHistory"
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="displayConvertToMobile"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={displayConvertToMobile}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="displayConvertToMobile"
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="duplicatePositionMenuItem"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={displayDuplicate}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="duplicatePositionMenuItem"
              />
            )}
          />
        </FormGroup>
      </Form>
    </Card>
  )
}

export { OperationsMenuGroup }
