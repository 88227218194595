import { useEffect } from 'react'
import { CampaignList } from 'pages/campaign/dumb/campaign-list'
import { campaign, createCampaign } from 'pages/nav'
import { useTranslation } from 'shared/i18n/translate'
import { useHistory } from 'react-router'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import Add from '@mui/icons-material/Add'
import Card from 'shared/ui-kit/card'
import Container from 'shared/ui-kit/container'
import EmptyScreen from 'shared/ui-kit/empty-screen'
import { CampaignModel } from 'shared/models'
import { campaign as campaignApi } from 'shared/api'
import PageLoader from 'shared/ui-kit/page-loader'
import { AppBar, IconButton, Toolbar } from '@mui/material'
import { useAppDispatch } from 'store'
import { useQuery } from 'react-query'

export const campaignListKey = ['campaigns']

function SmartCampaignList() {
  const dispatch = useAppDispatch()
  const { tr, locale } = useTranslation()
  const { push } = useHistory()

  const { data: res, isLoading } = useQuery(['campaigns'], ({ signal }) => campaignApi.getCampaignList({ signal }))

  useEffect(setCrumbs, [locale])

  function setCrumbs() {
    const crumb = getCrumbs(tr.breadcrumbs)
    dispatch(setBreadcrumbs([crumb.home(), crumb.campaigns()]))
  }

  function handleAdd() {
    push(createCampaign())
  }

  function handleSelect(data: CampaignModel) {
    push(campaign(String(data.id)))
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <>
      <AppBar position="sticky" color="inherit" elevation={2} style={{ top: '4rem', borderRadius: 0 }}>
        <Toolbar>
          <Container style={{ padding: 'unset' }}>
            <IconButton onClick={handleAdd}>
              <Add />
            </IconButton>
          </Container>
        </Toolbar>
      </AppBar>
      <Container>
        {res?.data?.length !== 0 && (
          <Card paperStyle={{ overflow: 'hidden' }}>
            <CampaignList data={res?.data || []} onSelect={handleSelect} />
          </Card>
        )}
        {res?.data?.length === 0 && <EmptyScreen text={tr.common.noData} />}
      </Container>
    </>
  )
}

export default SmartCampaignList
