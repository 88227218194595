import { NavLink } from 'react-router-dom'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import ChevronIcon from '@mui/icons-material/ChevronRight'

const BreadcrumbsContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 0 2rem;
  min-width: 0;
  box-sizing: border-box;

  @media (max-width: 768px) {
    & {
      padding: 0 1rem;
    }
  }
`

const ShedowCrumbStyled = css`
  opacity: 0.4;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: flex-end;
  margin-left: 2rem;
`

const CrumbsContainer = styled.div<{ $shadow?: boolean }>`
  display: flex;
  white-space: nowrap;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(props) => props.$shadow && ShedowCrumbStyled}
`

const Root = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  overflow: hidden;
`

const Tail = styled.div`
  color: rgba(255, 255, 255, 0.6);
  display: inline-block;
  vertical-align: middle;
  line-height: 24px;
`

const CrumbEl = styled.div`
  display: inline-block;
  vertical-align: middle;
`

const Breadcrumbs = styled.div<{ $collapsed?: boolean }>`
  flex: 1;
  position: relative;
  height: 24px;
  width: 100%;
  padding: ${(props) => (props.$collapsed ? '0 0.5rem' : '0 1rem')};
  overflow: hidden;
  text-overflow: ellipsis;
`

const CrumbLink = styled(NavLink)`
  color: #fff;
  cursor: pointer;
  vertical-align: middle;
`

const More = styled.div`
  margin-left: 0rem;
`

const ChevronIconStyled = styled(ChevronIcon)`
  vertical-align: middle;
  fill: #fff;
`

export { CrumbsContainer, Root, Tail, CrumbEl, CrumbLink, More, Breadcrumbs, ChevronIconStyled, BreadcrumbsContainer }
