import { CSSProperties, useEffect, useState } from 'react'
import { offsetToStamp } from 'shared/i18n/date-formatter'
import { useTranslation } from 'shared/i18n/translate'
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers'
import AttentionPoint from 'shared/ui-kit/attention-point'
import { getValue } from './utils/get-value'
import dayjs, { Dayjs } from 'dayjs'

interface ITimePickerProps {
  offset?: boolean
  onChange?: (data: number | null) => void
  value?: number
  fullWidth?: boolean
  id?: string
  style?: CSSProperties
  disabled?: boolean
  label?: string
  attention?: boolean
  autoComplete?: string
  error?: boolean
}

function TimePicker({
  attention,
  disabled,
  fullWidth,
  id,
  label,
  offset,
  onChange,
  style,
  value,
  autoComplete,
  ...props
}: ITimePickerProps) {
  const { timeZoneOffset } = useTranslation()
  const [val, setValue] = useState<Dayjs | null>(null)

  useEffect(() => {
    setValue(dayjs(getValue(value, offset ? timeZoneOffset : 0)))
  }, [timeZoneOffset, value])

  function handleChange(date: dayjs.Dayjs | null) {
    setValue(date)

    const dateU = date?.toDate()

    onChange?.(dateU ? offsetToStamp(dateU.getTime(), offset ? timeZoneOffset : 0) : null)
  }

  return (
    <MuiTimePicker
      ampm={false}
      disabled={disabled}
      label={
        <span style={{ display: 'flex' }}>
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{label}</div>
          {attention && <AttentionPoint />}
        </span>
      }
      value={val}
      onChange={handleChange}
      slotProps={{
        textField: {
          id,
          autoComplete,
          style,
          fullWidth,
          InputLabelProps: { shrink: true },
          error: props.error
        }
      }}
    />
  )
}

export default TimePicker
