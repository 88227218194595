import { TextField } from 'shared/ui-kit/text-field'
import { Controller } from 'react-hook-form'
import { ControlProps } from '.'

export function StringControl(props: ControlProps) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field: f, formState }) => (
        <TextField
          value={f.value || ''}
          onChange={f.onChange}
          onBlur={f.onBlur}
          label={props.label}
          placeholder={props.label}
          attention={(props.isMandatory && !f.value) || !!formState.errors[props.name]?.message}
          message={formState.errors[props.name]?.message as string}
          fullWidth
        />
      )}
    />
  )
}
