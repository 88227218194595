import { useEffect, useRef, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'shared/i18n/translate'
import { terminals } from 'pages/nav'
import { TerminalEdit } from './dumb/terminal-edit'
import { line as lineApi, terminal as terminalApi } from 'shared/api'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { showMessage, MessageTypes } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import Container from 'shared/ui-kit/container'
import { LineModelSimplified, ParentPlaceModel, PlaceModel, TerminalModel, TerminalModelEdit } from 'shared/models'
import PageLoader from 'shared/ui-kit/page-loader'
import { useDataProvider } from 'features/isolated-data-provider'
import { useTitle } from 'shared/hooks/useTitle'
import { useAppDispatch } from 'store'
import { useTimeZoneFilter } from 'features/time-zone-filter'

function SmartTerminalEdit() {
  const { tr } = useTranslation()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const params = useParams<{ terminalId: string; placeId: string }>()

  useTimeZoneFilter(params.placeId)

  const spikeRef = useRef<{ [key: PlaceModel['id']]: boolean }>({})

  const [lines, setLines] = useState<{ [key: PlaceModel['id']]: LineModelSimplified[] }>({})
  const [data, setData] = useState<TerminalModelEdit | null>(null)
  const [processing, setProcessing] = useState(false)

  const { getPlace, places } = useDataProvider()

  useTitle({ placeName: getPlace(params.placeId)?.name })

  useEffect(() => {
    fetchTerminal()
  }, [])

  function showOperationMessage(
    keyTr: keyof typeof tr.terminalOperationMessages,
    type: MessageTypes = MessageTypes.Info
  ) {
    dispatch(showMessage(tr.terminalOperationMessages[keyTr], type))
  }

  async function fetchTerminal() {
    try {
      const [terminal] = await Promise.all([terminalApi.getTerminal(params.placeId, params.terminalId)])
      setData(terminal.data)
      setCrumbs(terminal.data, terminal.parents)
    } catch (err) {
      showOperationMessage('getError', MessageTypes.Error)
    }
  }

  function setCrumbs(data: TerminalModel, parents: ParentPlaceModel) {
    const crumb = getCrumbs(tr.breadcrumbs)
    const { id } = data

    const editLail = [
      crumb.terminal([String(id), params.placeId], data.name),
      crumb.editTerminal([String(id), params.placeId])
    ]
    const createLail = [crumb.newTerminal([params.placeId])]

    dispatch(
      setBreadcrumbs(
        [
          crumb.home(),
          crumb.places(),
          crumb.place([params.placeId], String(parents?.shop?.name || '')),
          crumb.terminals([params.placeId])
        ].concat((id ? editLail : createLail) as any)
      )
    )
  }

  function fetchLineList(placeId: string | number, callback?: () => void) {
    lineApi.getLineListSimplified(placeId).then((response) => {
      setLines((prev) => ({ ...prev, [placeId]: response.data }))
      callback?.()
    })
  }

  function handleSave(data: TerminalModelEdit) {
    setProcessing(true)

    if (data.id) {
      terminalApi
        .updateTerminal(data as TerminalModel)
        .then(() => {
          showOperationMessage('updateSuccess', MessageTypes.Success)
          history.push(terminals(params.placeId))
        })
        .catch(() => {
          showOperationMessage('updateError', MessageTypes.Error)
          setProcessing(false)
        })
    } else {
      terminalApi
        .createTerminal(data)
        .then(() => {
          showOperationMessage('createSuccess', MessageTypes.Success)
          history.push(terminals(params.placeId))
        })
        .catch(() => {
          showOperationMessage('createError', MessageTypes.Error)
          setProcessing(false)
        })
    }
  }

  function handleDelete(id: TerminalModel['id']) {
    setProcessing(true)

    terminalApi
      .deleteTerminal(id)
      .then(() => {
        showOperationMessage('deleteSuccess', MessageTypes.Success)
        history.push(terminals(params.placeId))
      })
      .catch(() => {
        showOperationMessage('deleteError', MessageTypes.Error)
        setProcessing(false)
      })
  }

  function handleRequestLines(placeId: string | number) {
    if (!spikeRef.current[placeId]) {
      fetchLineList(placeId, () => {
        spikeRef.current[placeId] = false
      })
      spikeRef.current[placeId] = true
    }
  }

  if (data === null) {
    return <PageLoader />
  }

  return (
    <Container>
      <TerminalEdit
        data={data}
        shops={places}
        lines={lines}
        onSave={handleSave}
        onDelete={handleDelete}
        onRequestLines={handleRequestLines}
        disableChangeType={!!params.terminalId}
        processing={processing}
      />
    </Container>
  )
}

export default SmartTerminalEdit
