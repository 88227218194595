import React, { useEffect, useState } from 'react'
import { LineList } from './dumb/line-list'
import { line, createLine } from 'pages/nav'
import { useTranslation } from 'shared/i18n/translate'
import { useTimeZoneFilter } from 'features/time-zone-filter'
import { useDataProvider } from 'features/isolated-data-provider'
import { CircularProgress, Divider, ListItemIcon, Menu, MenuItem } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { MoreVert as MoreIcon } from '@mui/icons-material'
import Card from 'shared/ui-kit/card'
import { line as lineAPI } from 'shared/api'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import Toggle from 'shared/ui-kit/toggle-wrap'
import Container from 'shared/ui-kit/container'
import { CenterContainer } from 'shared/ui-kit/center-container'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import SuccessIcon from '@mui/icons-material/Done'
import EditListIcon from '@mui/icons-material/List'
import { AppBar, Toolbar } from '@mui/material'
import { AppTitle } from 'shared/hooks/useTitle'
import { useHistory, useParams } from 'react-router'
import { LineModelSimplified, LinesStatisticsModel } from 'shared/models'
import { useAppDispatch, useAppSelector } from 'store'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { storageShowStatsKey } from './utils'

function SmartLineList() {
  const { placeId } = useParams<{ placeId: string }>()

  const { getPlace } = useDataProvider()

  const { canUpdateShops, accessPlaceLineMonitoring } = useAppSelector(getPermissionsSelector)
  const dispatch = useAppDispatch()

  const { tr } = useTranslation()
  const history = useHistory()

  useTimeZoneFilter(placeId)

  const [lines, setLines] = useState<LineModelSimplified[]>([])
  const [lineDraft, setLineDraft] = useState<LineModelSimplified[] | null>(null)
  const [lineStatistic, setLineStatistic] = useState<LinesStatisticsModel[]>([])
  const [loading, setLoading] = useState(false)
  const [autoUpdate, setAutoUpdate] = useState(false)
  const [editMode, setEditMode] = useState(false)

  const [menuTarget, setMenuTarget] = useState<null | HTMLElement>(null)

  const lineStatsSocket = React.useRef<{ stop: () => void }>({ stop: () => Promise.resolve() })

  useEffect(() => {
    const crumb = getCrumbs(tr.breadcrumbs)
    const shopName = getPlace(placeId)?.name

    dispatch(setBreadcrumbs([crumb.home(), crumb.places(), crumb.place([placeId], shopName), crumb.lines([placeId])]))

    fetchData()

    if (localStorage.getItem(storageShowStatsKey) === 'true') {
      handleAutoUpdate(null, true)
    }

    return () => {
      lineStatsSocket.current?.stop?.()
    }
  }, [])

  function fetchData() {
    setLoading(true)

    lineAPI
      .getLineListSimplified(placeId)
      .then((resp) => {
        setLines(resp.data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  function loadLineStats() {
    lineStatsSocket.current = lineAPI.linesStatisticsChanged({ placeId }, (data) => {
      setLineStatistic(data || [])
    })
  }

  async function handleSaveOrders() {
    if (!lineDraft) {
      setEditMode(false)
      return
    }

    await lineAPI.updateLinesOrder({
      shopId: placeId,
      items: (lineDraft || []).map((el, index) => ({ lineId: el.id, order: index }))
    })

    setLines(lineDraft || [])
    setLineDraft(null)
    setEditMode(false)
  }

  function handleAutoUpdate(_, val: boolean) {
    setAutoUpdate(val)

    localStorage.setItem(storageShowStatsKey, String(val))

    if (val) {
      loadLineStats()
    } else {
      lineStatsSocket.current?.stop?.()
      setLineStatistic([])
    }
  }

  function handleSelectLine(data: LineModelSimplified) {
    history.push(line(placeId, String(data.id)))
  }

  function handleMoreIconClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setMenuTarget(event.currentTarget)
  }

  function closeMoreMenu() {
    setMenuTarget(null)
  }

  function handleChangeListOrder() {
    setEditMode(true)
    closeMoreMenu()
  }

  return (
    <>
      <AppTitle placeName={getPlace(placeId)?.name} />
      <AppBar position="sticky" color="inherit" elevation={2} style={{ top: '4rem', borderRadius: 0 }}>
        <Toolbar>
          <Container style={{ padding: 'unset' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              {Boolean(canUpdateShops === true) && (
                <IconButton onClick={() => history.push(createLine(placeId))}>
                  <AddIcon />
                </IconButton>
              )}
              <div style={{ flex: 1 }} />
              {!editMode && (
                <IconButton onClick={handleMoreIconClick}>
                  <MoreIcon />
                </IconButton>
              )}
              <Menu anchorEl={menuTarget} open={!!menuTarget} onClose={closeMoreMenu} elevation={2}>
                {!!canUpdateShops && (
                  <MenuItem onClick={handleChangeListOrder} disabled={(lines || []).length < 2}>
                    <ListItemIcon>
                      <EditListIcon fontSize="small" />
                    </ListItemIcon>
                    {tr.common.cahngeListOrder}
                  </MenuItem>
                )}
                {!!canUpdateShops && <Divider style={{ margin: '0.5rem 0' }} />}
                <div style={{ padding: '0 0.5rem 0 1rem' }}>
                  <Toggle
                    toggled={autoUpdate}
                    style={{ gap: '1rem' }}
                    onToggle={handleAutoUpdate}
                    label={tr.lineList.displayWaitingData}
                  />
                </div>
              </Menu>
              {!!editMode && (
                <>
                  <Divider orientation="vertical" style={{ height: '48px' }} />
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.25rem' }}>
                    <IconButton onClick={() => setEditMode(false)}>
                      <CloseIcon />
                    </IconButton>
                    {editMode && (
                      <IconButton onClick={handleSaveOrders}>
                        <SuccessIcon />
                      </IconButton>
                    )}
                  </div>
                </>
              )}
            </div>
          </Container>
        </Toolbar>
      </AppBar>
      <Container>
        {!loading && !!lines && (
          <Card style={{ maxWidth: 'inherit' }} paperStyle={{ overflow: 'hidden' }}>
            <LineList
              data={lines}
              statistic={lineStatistic}
              onSelect={handleSelectLine}
              editMode={editMode}
              showMonitoring={autoUpdate && accessPlaceLineMonitoring}
              onDropEnd={setLineDraft}
              placeId={placeId}
            />
          </Card>
        )}
        {!!loading && (
          <CenterContainer>
            <CircularProgress color="primary" />
          </CenterContainer>
        )}
      </Container>
    </>
  )
}

export default SmartLineList
