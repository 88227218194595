import { CSSProperties } from 'react'

type Props = {
  style?: CSSProperties
  className?: string
  children?: JSX.Element
  icon?: JSX.Element
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

function IconValue({ style, className, children, icon, onClick }: Props) {
  return (
    <div
      onClick={onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        ...style
      }}
      className={className}
    >
      {icon}
      <span style={{ width: '4px' }} />
      {children}
    </div>
  )
}

export { IconValue }
export default IconValue
