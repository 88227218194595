import Autocomplete from 'shared/ui-kit/autocomplete'
import { DatePicker } from 'features/date'
import { Card } from '@mui/material'
import { Button } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import { LayoutProps } from './types'
import { TextField } from 'shared/ui-kit/text-field'
import { FilterItem, LargeContainer, RootStyled, SideBarStyled } from './styled'
import { isPositiveInteger } from 'shared/utils/string-test'
import MUIAutocomplete from '@mui/material/Autocomplete'
import MUITextField from '@mui/material/TextField'
import { EventFlowAction } from 'shared/models'
import { ActionsWrapper } from 'shared/ui-kit/action-wrapper'

function EventFlowLargeLayout(props: LayoutProps) {
  const { tr } = useTranslation()

  function onSearch(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    props.handleSubmit()
  }

  const { shop, line } = tr.common

  return (
    <RootStyled>
      <LargeContainer>
        <SideBarStyled>
          <Card style={{ overflow: 'unset' }}>
            <form onSubmit={onSearch}>
              <FilterItem>
                <Autocomplete
                  fullWidth
                  title={shop}
                  value={props.filter.shopId || null}
                  list={props.shops || []}
                  style={{ textOverflow: 'ellipsis' }}
                  dataConverter={(el) => ({ text: el.name, value: el.id })}
                  onChange={(shopId) => props.onShopChenge(shopId || undefined)}
                />
              </FilterItem>
              {!!props.lines?.length && (
                <FilterItem>
                  <Autocomplete
                    fullWidth
                    title={line}
                    value={props.filter.lineId || null}
                    list={props.lines}
                    style={{ textOverflow: 'ellipsis' }}
                    dataConverter={(el) => ({ text: el.name, value: el.id })}
                    onChange={(lineId) => props.onFilterChange('lineId', lineId || undefined)}
                  />
                </FilterItem>
              )}
              <FilterItem>
                <DatePicker
                  label={tr.reportsFilter.startDate}
                  style={{ marginBottom: 0, width: '100%' }}
                  onChange={(date) => {
                    const isStartDateMoreEndDate = date > (props.filter.endDate || 0)
                    if (isStartDateMoreEndDate) {
                      props.onFilterChange('startDate', date)
                      props.onFilterChange('endDate', date)
                    } else {
                      props.onFilterChange('startDate', date)
                    }
                  }}
                  value={props.filter.startDate}
                />
              </FilterItem>
              <FilterItem>
                <DatePicker
                  label={tr.reportsFilter.endDate}
                  style={{ marginBottom: 0, width: '100%' }}
                  onChange={(date) => {
                    const isEndDateLessStartDate = date < (props.filter.startDate || 0)

                    if (isEndDateLessStartDate) {
                      props.onFilterChange('endDate', date)
                      props.onFilterChange('startDate', date)
                    } else {
                      props.onFilterChange('endDate', date)
                    }
                  }}
                  value={props.filter.endDate}
                />
              </FilterItem>
              {!!props.users?.length && (
                <FilterItem>
                  <MUIAutocomplete
                    multiple
                    options={props.users || []}
                    value={(props.filter.operatorIds || []).map((el) =>
                      (props.users || []).find((user) => user.id === el)
                    )}
                    getOptionLabel={(item) =>
                      [item?.firstName || '', item?.secondName || '', `(${item?.id})`].join(' ')
                    }
                    onChange={(_, value) =>
                      props.onFilterChange('operatorIds', value.map((el) => el?.id) as (string | number)[])
                    }
                    size="small"
                    renderInput={(params) => (
                      <MUITextField
                        {...params}
                        variant="standard"
                        label={tr.reportsFilter.users}
                        placeholder={tr.reportsFilter.users}
                      />
                    )}
                  />
                </FilterItem>
              )}
              <FilterItem>
                <MUIAutocomplete
                  multiple
                  options={((Object.values(EventFlowAction) || []) as string[]).sort((a, b) => {
                    const first = tr.eventFlowActionName[a] || ''
                    const second = tr.eventFlowActionName[b] || ''

                    if (first > second) {
                      return 1
                    }
                    if (first < second) {
                      return -1
                    }
                    return 0
                  })}
                  value={props.filter.actions || []}
                  getOptionLabel={(item) => tr.eventFlowActionName[item]}
                  onChange={(_, value) => props.onFilterChange('actions', value)}
                  size="small"
                  renderInput={(params) => (
                    <MUITextField
                      {...params}
                      variant="standard"
                      label={tr.reportsFilter.actions}
                      placeholder={tr.reportsFilter.actions}
                    />
                  )}
                />
              </FilterItem>
              <FilterItem>
                <TextField
                  label={tr.reportsFilter.customerId}
                  placeholder={tr.reportsFilter.customerId}
                  value={props.filter.customerId || ''}
                  onChange={(el) => props.onFilterChange('customerId', el)}
                  test={isPositiveInteger}
                />
              </FilterItem>
              <FilterItem>
                <TextField
                  label={tr.reportsFilter.positionId}
                  placeholder={tr.reportsFilter.positionId}
                  value={props.filter.positionId || ''}
                  onChange={(el) => props.onFilterChange('positionId', el)}
                  test={isPositiveInteger}
                />
              </FilterItem>
            </form>
          </Card>
          <ActionsWrapper>
            <Button size="small" variant="contained" color="primary" onClick={props.handleSubmit}>
              {tr.journal.search}
            </Button>
            <Button size="small" variant="text" onClick={props.onClear}>
              {tr.lineMonitoring.reset}
            </Button>
          </ActionsWrapper>
        </SideBarStyled>
        <div style={{ flex: 1, maxWidth: '494px', marginBottom: '1rem' }}>{props.children}</div>
      </LargeContainer>
    </RootStyled>
  )
}

export { EventFlowLargeLayout }
