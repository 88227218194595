import { Fragment, ReactNode, useEffect, useRef, useState } from 'react'
import { PositionState, PositionAction, PositionType, InternalService } from 'shared/models'
import * as Nav from 'pages/nav'
import { formatNumber, getHiddenNumber } from 'features/material-ui-phoneinput/components/format-number'
import {
  RequestToApproach,
  StartService,
  NearCheckpoint,
  SetInLineHere,
  FinishService,
  ValidateForService,
  Transfer,
  Remove,
  DuplicatePositionAction,
  AcceptationPositionAction,
  FinishAndPause,
  History,
  Edit,
  ConvertToMobile,
  CallToPhone,
  OtherOptions,
  NewBooking,
  ProgressionTags,
  RemoveConfirmForm,
  FinishServiceForm,
  TransferForm,
  PriorityForm,
  ServiceSelect,
  ConvertToMobileForm
} from './actions'
import { getActionsFromPositionState } from './utils/get-actions-from-position-state'
import { useAppSelector } from 'store'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { Action, ActionOptions, ChangeStateData, PositionActionsProps } from './types'
import { FinishServiceFormValue } from './actions/finish'

function PositionActions(props: PositionActionsProps) {
  const defaultCheckpoint = props.customCheckpointId
    ? props.customCheckpointId
    : props.checkpoints?.length === 1
      ? props.checkpoints[0].id
      : null

  const permissions = useAppSelector(getPermissionsSelector)

  const positionNextType = useRef<PositionState | null>(null)
  const priorityCallComment = useRef<string>('')
  const phoneNumber = useRef<string>(props.phoneNumber || '')
  const email = useRef<string | null>(null)
  const survey = useRef<any>(null)

  const [checkpointId, setCheckpointId] = useState<string | number | null>(defaultCheckpoint)
  const [openOtherAction, setOpenOtherAction] = useState(false)
  const [activeAction, setActiveAction] = useState<Action>(null)
  const [activeActionOptions, setActiveActionOptions] = useState<ActionOptions | undefined>(undefined)

  useEffect(() => {
    if (props.finishServiceFormMode) {
      setActiveAction('finishService')
    }
  }, [props.finishServiceFormMode])

  useEffect(() => {
    if (!props.opened) {
      setActiveAction(null)
      setOpenOtherAction(false)
    }
  }, [props.opened])

  const isBreak = props.position.type === PositionType.Break

  const isInternalLineDelayService = !!props.position?.services?.find(
    (service) => Number(service.id) === InternalService.LineDelay
  )

  const isInternalBreakService = !!props.position?.services?.find(
    (service) => Number(service.id) === InternalService.Break
  )

  const isInternalService = isInternalLineDelayService || isInternalBreakService

  const isReadyToBeCalled =
    !!props.position.isValid || !!permissions.canCallInvalidPositions || !props.displayPositionValidate

  const mandatoryAcceptationNotValid =
    props.position?.acceptationState !== 'accepted' &&
    props.generalConditionsAcceptation === 'Mandatory' &&
    props.position.type !== PositionType.Break

  const actions = !isBreak
    ? [
        { action: PositionAction.Join, active: true },
        { action: PositionAction.SetInLineHere, active: !!props.displayInLineHere },
        { action: PositionAction.ValidateForService, active: !!props.displayPositionValidate },
        { action: PositionAction.CallToCheckpoint, active: true },
        { action: PositionAction.SetNearCheckpoint, active: !!props.displayHasApproach },
        { action: PositionAction.StartService, active: !props.setPositionFinishedAfterArrived },
        { action: PositionAction.FinishService, active: true }
      ]
    : [
        { action: PositionAction.StartService, active: true },
        { action: PositionAction.FinishService, active: true }
      ]

  function getActionsFromStatus(status: PositionState) {
    return getActionsFromPositionState(actions, status, props.readonly, props.sequentiallyPerformingActionsOnPosition)
  }

  function handleDuplicate(line, position) {
    props.onDuplicate(line, position)
  }

  function changeActiveAction(action: typeof activeAction) {
    return () => {
      setActiveAction(action)
      props.onOpen?.()
    }
  }

  function getData(comment: string = priorityCallComment.current, cId = checkpointId): ChangeStateData {
    return {
      positionId: props.positionId,
      shopId: props.shopId,
      lineId: props.lineId,
      checkpointId: cId as string,
      survey: survey.current,
      phoneNumber: phoneNumber.current,
      email: email.current,
      comment
    }
  }

  function fromPositionActions(positionState, skipForms = false, skipServiceSelect = false) {
    if (skipForms) {
      changeState()
      return
    }

    if (skipServiceSelect) {
      finishFormCheck(changeState)
      return
    }

    switch (positionState) {
      case PositionState.Joined: {
        checkpointSelectCheck(() => finishFormCheck(changeState))
        break
      }
      case PositionState.InLineHere: {
        checkpointSelectCheck(() => finishFormCheck(changeState))
        break
      }
      case PositionState.ValidForService: {
        checkpointSelectCheck(() => finishFormCheck(changeState))
        break
      }
      case PositionState.CalledToCheckpoint: {
        checkpointSelectCheck(() => finishFormCheck(changeState))
        break
      }
      case PositionState.MovingToCheckpoint: {
        checkpointSelectCheck(() => finishFormCheck(changeState))
        break
      }
      case PositionState.NearCheckpoint: {
        checkpointSelectCheck(() => finishFormCheck(changeState))
        break
      }
      default: {
        finishFormCheck(changeState)
      }
    }
  }

  function changeState(clearCheckpointId = false, checkpointId?: string | number) {
    props.changeState?.(getData(undefined, checkpointId), positionNextType.current as PositionState)

    if (props.isMobile) {
      props.onClose?.()
    }

    if (clearCheckpointId) {
      setCheckpointId(defaultCheckpoint)
    }
  }

  function checkpointSelectCheck(decline) {
    const manyCheckpoints = props.checkpoints?.length > 1

    if (manyCheckpoints && !checkpointId && permissions.canAssignCustomServicePoints) {
      setActiveAction('serviceSelection')
      props.onOpen?.()
    } else {
      decline?.()
    }
  }

  function finishFormCheck(decline) {
    const isFinalState = positionNextType.current === PositionState.ManualFinished

    const needFinishForm =
      !!props.displayServiceSurvey ||
      (!!props.sendSurveyLinkAfterPositionServed && (!!props.sendSurveyLinkBySms || !!props.sendSurveyLinkByEmail))

    if (isFinalState && needFinishForm) {
      setActiveAction('finishService')
      props.onOpen?.()
    } else {
      decline?.()
    }
  }

  function checkPriorityCallAndChangeState(newState: PositionState, options) {
    if (!props.requestCommentsOnPriorityCall || props.position.type === PositionType.Break) {
      handleChangeState(newState, options)
      return
    }

    setActiveActionOptions(options || undefined)
    setActiveAction('priorityAccept')
    positionNextType.current = newState
    props.onOpen?.()
  }

  function confirmPriorityCall(comment: string) {
    priorityCallComment.current = comment
    setActiveAction(null)
    setActiveActionOptions(undefined)
    handleChangeState(positionNextType.current as PositionState, activeActionOptions)
  }

  function handleChangeState(
    newState: PositionState,
    options: any = { skipSurvay: false, skipForms: false, skipServiceSelect: false },
    callback?: () => void
  ) {
    if (newState === PositionState.ManualFinished && props.onSurvay && !options.skipSurvay) {
      props.onSurvay()
    }

    positionNextType.current = newState
    fromPositionActions(props.positionState, options.skipForms, options.skipServiceSelect)
    callback?.()
  }

  function handleRemovePosition(newState: PositionState) {
    return (removeComment: string = '') => {
      positionNextType.current = newState
      props.changeState?.(getData(removeComment), newState)
    }
  }

  function handleСheckpointSelect(checkpoint) {
    setCheckpointId(checkpoint?.id || undefined)
    setActiveAction(null)
    finishFormCheck(() => changeState(true, checkpoint?.id || undefined))
  }

  function handleSurveySelect(data) {
    survey.current = { comment: data.comment, satisfactionLevel: data.satisfactionLevel, surveyTags: data.surveyTags }
    email.current = data.email
    phoneNumber.current = data.phoneNumber

    props.changeState?.(getData(), PositionState.ManualFinished)
  }

  function handleHistoryPosition() {
    props.historyPosition?.()
  }

  function handleNewBooking() {
    window.open(
      `#${Nav.appointmentPlaceList()}/?placeId=${props.shopId}&lineId=${props.lineId}&positionId=${props.positionId}`,
      '_blank'
    )
  }

  function handleOpenOtherOptions() {
    setOpenOtherAction(true)
  }

  function handlePositionValidate(checkListSelectedItems: string[] = []) {
    props.handlePositionValidate({
      positionId: Number(props.positionId),
      lineId: Number(props.lineId),
      shopId: Number(props.shopId),
      checkListSelectedItems
    })
  }

  function handleFinishConfirm(data: FinishServiceFormValue) {
    handleSurveySelect({ ...data, surveyTags: data.surveyTags.join(';') })
  }

  function handleFinishAndPause() {
    handleChangeState(PositionState.ManualFinished, { skipSurvay: true }, props.onPause)
  }

  if (!props.positionId && props.positionId !== 0) {
    return null
  }

  const canConvertPosition =
    permissions.canAddAndEditPositions &&
    props.supportMobile &&
    props.position.type !== PositionType.MobileApp &&
    props.displayConvertToMobile

  const generalActions = {
    finishAndPause: !!props.pauseMode && !!props.supportAutoCall
  }

  const serviceIsStarted = props.positionState === PositionState.InService

  const otherActionsAvailable = {
    transfer: !!props.positionTransferEnabled,
    history: !!props.displayHistory && permissions.manageReports,
    newAppointment: props.addNewAppointment && permissions.manageAppointments,
    convertToMobile: props.displayConvertToMobile && canConvertPosition,
    duplicate: permissions.canAddAndEditPositions && props.duplicatePositionMenuItem && !isBreak,
    edit:
      !(isInternalService && !!serviceIsStarted) &&
      !(isInternalBreakService && !permissions.canOpenCheckpoints) &&
      permissions.canAddAndEditPositions &&
      props.onUpdate
  }

  const otherMenuAvailable =
    !isBreak && !openOtherAction && Object.values(otherActionsAvailable).filter(Boolean).length > 1
  const singleOtherActions = Object.values(otherActionsAvailable).filter(Boolean).length === 1
  const displayOtherActions = !isBreak && (openOtherAction || singleOtherActions)

  const isHiddenPhone = !permissions.canViewPersonalInfo

  const phoneNumberToCall =
    props.phoneNumber && props.displayCallByPhone
      ? isHiddenPhone
        ? getHiddenNumber(props.phoneNumber)
        : formatNumber(props.phoneNumber)
      : undefined

  const mapper = {
    [PositionAction.SetInLineHere]: (
      <SetInLineHere
        isCardView={props.isMobile}
        handleChangeState={handleChangeState}
        disabled={
          !!props.readonly ||
          !getActionsFromStatus(props.positionState).includes(PositionAction.SetInLineHere) ||
          mandatoryAcceptationNotValid
        }
        monitoringPositionMenuItemCaptions={props.monitoringPositionMenuItemCaptions}
      />
    ),
    [PositionAction.ValidateForService]: (
      <ValidateForService
        isCardView={props.isMobile}
        position={props.position}
        disabled={!!props.readonly}
        monitoringPositionMenuItemCaptions={props.monitoringPositionMenuItemCaptions}
        onPositionValidate={handlePositionValidate}
        onValidateMode={changeActiveAction('validateForService')}
        onBack={changeActiveAction(null)}
        onFinish={props.isMobile ? props.onClose : undefined}
      />
    ),
    [PositionAction.CallToCheckpoint]: (
      <RequestToApproach
        isCardView={props.isMobile}
        position={props.position}
        posiblesStates={getActionsFromStatus(props.positionState)}
        handleChangeState={checkPriorityCallAndChangeState}
        disabled={!!props.readonly || mandatoryAcceptationNotValid || !isReadyToBeCalled}
        monitoringPositionMenuItemCaptions={props.monitoringPositionMenuItemCaptions}
      />
    ),
    [PositionAction.SetNearCheckpoint]: (
      <NearCheckpoint
        isCardView={props.isMobile}
        position={props.position}
        posiblesStates={getActionsFromStatus(props.positionState)}
        handleChangeState={checkPriorityCallAndChangeState}
        monitoringPositionMenuItemCaptions={props.monitoringPositionMenuItemCaptions}
      />
    ),
    [PositionAction.StartService]: (
      <StartService
        isCardView={props.isMobile}
        position={props.position}
        posiblesStates={getActionsFromStatus(props.positionState)}
        handleChangeState={checkPriorityCallAndChangeState}
        disabled={mandatoryAcceptationNotValid || !isReadyToBeCalled}
        monitoringPositionMenuItemCaptions={props.monitoringPositionMenuItemCaptions}
      />
    ),
    [PositionAction.FinishService]: (
      <FinishService
        isCardView={props.isMobile}
        position={props.position}
        posiblesStates={getActionsFromStatus(props.positionState)}
        handleChangeState={handleChangeState}
        disabled={mandatoryAcceptationNotValid || !isReadyToBeCalled}
        canSetPositionFinishedAfterArrived={!!props.setPositionFinishedAfterArrived}
        monitoringPositionMenuItemCaptions={props.monitoringPositionMenuItemCaptions}
        lineParticipantTermInSingularTemplate={props.lineParticipantTermInSingularTemplate}
      />
    ),
    unknown: null
  }

  const suggestNextOperationMapper: Record<PositionState, ReactNode | null> = {
    joined: !isReadyToBeCalled ? mapper['validateForService'] : mapper['callToCheckpoint'],
    inLine: !isReadyToBeCalled ? mapper['validateForService'] : mapper['callToCheckpoint'],
    inLineExtraTime: !isReadyToBeCalled ? mapper['validateForService'] : mapper['callToCheckpoint'],
    inLineHere: !isReadyToBeCalled ? mapper['validateForService'] : mapper['callToCheckpoint'],
    validForService: mapper['callToCheckpoint'],
    calledToCheckpoint: props.displayHasApproach ? mapper['setNearCheckpoint'] : mapper['startService'],
    movingToCheckpoint: props.displayHasApproach ? mapper['setNearCheckpoint'] : mapper['startService'],
    nearCheckpoint: !props.setPositionFinishedAfterArrived ? mapper['startService'] : null,
    takeInCharge: null,
    serviceStarted: mapper['finishService'],
    inService: mapper['finishService'],
    removed: null,
    autoFinished: null,
    leaved: null,
    manualFinished: null
  }

  if (activeAction === 'finishService') {
    return (
      <FinishServiceForm
        tags={props.surveyTags || ''}
        onConfirm={handleFinishConfirm}
        isBreak={isBreak}
        defaultPhoneCountryCode={props.defaultPhoneCountryCode}
        email={props.position?.customer?.email}
        phoneNumber={props.position?.callPhoneNumber || props.position?.customer?.phoneNumber}
        sendSurveyLinkByEmail={props.sendSurveyLinkByEmail && !isBreak}
        sendSurveyLinkBySms={props.sendSurveyLinkBySms && !isBreak}
        displayServiceSurvey={props.displayServiceSurvey || false}
        positionState={props.position.state}
        canSetPositionFinishedAfterArrived={!!props.setPositionFinishedAfterArrived}
        monitoringPositionMenuItemCaptions={props.monitoringPositionMenuItemCaptions}
        onBack={changeActiveAction(null)}
        isCardView={props.isMobile}
      />
    )
  }

  if (activeAction === 'serviceSelection') {
    return (
      <ServiceSelect
        checkpoints={props.checkpoints || []}
        isCardView={props.isMobile}
        onСheckpointSelect={handleСheckpointSelect}
        onBack={changeActiveAction(null)}
      />
    )
  }

  if (activeAction === 'remove') {
    return (
      <RemoveConfirmForm
        isCardView={props.isMobile}
        onSubmit={handleRemovePosition(PositionState.Removed)}
        onBack={changeActiveAction(null)}
      />
    )
  }

  if (activeAction === 'priorityAccept') {
    return (
      <PriorityForm isCardView={props.isMobile} onAccept={confirmPriorityCall} onCancel={changeActiveAction(null)} />
    )
  }

  if (activeAction === 'convertToMobile') {
    return (
      <ConvertToMobileForm
        lineId={props.lineId}
        positionId={props.positionId}
        shopId={props.shopId}
        positionType={props.position.type}
        isCardView={props.isMobile}
        supportMobile={props.supportMobile}
        onBack={changeActiveAction(null)}
      />
    )
  }

  if (activeAction === 'validateForService') {
    return (
      <ValidateForService
        isCardView={props.isMobile}
        position={props.position}
        disabled={!!props.readonly}
        monitoringPositionMenuItemCaptions={props.monitoringPositionMenuItemCaptions}
        onPositionValidate={handlePositionValidate}
        validateMode
        onValidateMode={changeActiveAction('validateForService')}
        onBack={changeActiveAction(null)}
      />
    )
  }

  if (activeAction === 'transfer') {
    return (
      <TransferForm
        placeId={props.shopId}
        lineId={props.lineId}
        positionId={props.positionId}
        checkpoints={props.checkpoints || []}
        onFinish={props.onClose}
        onBack={changeActiveAction(null)}
        isCardView={props.isMobile}
      />
    )
  }

  if (activeAction === 'duplicate') {
    return (
      <DuplicatePositionAction
        isCardView={props.isMobile}
        onDuplicate={handleDuplicate}
        placeId={props.shopId}
        lineId={props.lineId}
        positionId={props.positionId}
        selectLineMode
        onSelectLineMode={changeActiveAction('duplicate')}
        onBack={changeActiveAction(null)}
      />
    )
  }

  if (activeAction === 'acceptation' && props.generalConditionsAcceptation) {
    return (
      <AcceptationPositionAction
        isCardView={props.isMobile}
        placeId={props.shopId}
        lineId={props.lineId}
        positionId={props.positionId}
        generalConditionsAcceptation={props.generalConditionsAcceptation}
        acceptationState={props.position?.acceptationState}
        generalConditionsAcceptationCaption={props.monitoringPositionMenuItemCaptions?.generalConditionsAcceptation}
        acceptationMode
        onAcceptationMode={changeActiveAction('acceptation')}
        onBack={changeActiveAction(null)}
        onFinish={!!props.isMobile || !!props.closeAfterActionIsFinished ? props.onClose : changeActiveAction(null)}
      />
    )
  }

  if (activeAction === 'progressionTags') {
    return (
      <ProgressionTags
        isCardView={props.isMobile}
        shopId={Number(props.shopId)}
        lineId={Number(props.lineId)}
        positionId={Number(props.positionId)}
        isBreak={isBreak}
        selectedTags={props?.position?.progressionTags || ''}
        comment={props?.position?.progressionTagsComment || ''}
        tags={props.progressionTags || ''}
        allowMultipleProgressionTags={props.allowMultipleProgressionTags || false}
        withPhoto={props.allowProgressionPhoto}
        images={props?.position?.progressionTagsImages || []}
        monitoringPositionMenuItemCaptions={props.monitoringPositionMenuItemCaptions}
        progressionMode
        onProgressionMode={changeActiveAction('progressionTags')}
        onBack={changeActiveAction(null)}
        onFinish={!!props.isMobile || !!props.closeAfterActionIsFinished ? props.onClose : changeActiveAction(null)}
      />
    )
  }

  if (props.suggestNextOperationOnPosition && !props.opened) {
    if (props.readonly) {
      return null
    }

    return suggestNextOperationMapper[props.positionState]
  }

  return (
    <div style={props.isMobile ? { display: 'grid', gap: '1rem' } : props.style}>
      {phoneNumberToCall && (
        <CallToPhone isCardView={props.isMobile} onSubmit={props.onCallByPhone} phoneNumber={phoneNumberToCall} />
      )}
      {!isBreak && !!props.generalConditionsAcceptation && (
        <AcceptationPositionAction
          isCardView={props.isMobile}
          placeId={props.shopId}
          lineId={props.lineId}
          positionId={props.positionId}
          generalConditionsAcceptation={props.generalConditionsAcceptation}
          acceptationState={props.position?.acceptationState}
          generalConditionsAcceptationCaption={props.monitoringPositionMenuItemCaptions?.generalConditionsAcceptation}
          onAcceptationMode={changeActiveAction('acceptation')}
          onBack={changeActiveAction(null)}
        />
      )}
      {!isBreak && !!props.progressionTags && (
        <ProgressionTags
          isCardView={props.isMobile}
          shopId={Number(props.shopId)}
          lineId={Number(props.lineId)}
          positionId={Number(props.positionId)}
          isBreak={isBreak}
          selectedTags={props?.position?.progressionTags || ''}
          comment={props?.position?.progressionTagsComment || ''}
          tags={props.progressionTags || ''}
          allowMultipleProgressionTags={props.allowMultipleProgressionTags || false}
          withPhoto={props.allowProgressionPhoto}
          images={props?.position?.progressionTagsImages || []}
          monitoringPositionMenuItemCaptions={props.monitoringPositionMenuItemCaptions}
          onProgressionMode={changeActiveAction('progressionTags')}
          onBack={changeActiveAction(null)}
        />
      )}
      {actions.map((a, index) =>
        a.active ? <Fragment key={index}>{mapper[a.action] || mapper.unknown}</Fragment> : null
      )}
      {generalActions.finishAndPause && (
        <FinishAndPause
          isCardView={props.isMobile}
          disabled={!getActionsFromStatus(props.positionState).includes(PositionAction.FinishService)}
          onSubmit={handleFinishAndPause}
        />
      )}
      {!(isInternalService && !!serviceIsStarted) && !!permissions.canDeletePositions && (
        <Remove
          isCardView={props.isMobile}
          displayRemoveConfirm={props.displayRemoveConfirm}
          requestCommentsOnRemove={props.requestCommentsOnRemove}
          onConfirmMassageOpen={changeActiveAction('remove')}
          onRemove={handleRemovePosition(PositionState.Removed)}
        />
      )}
      {displayOtherActions && (
        <>
          {otherActionsAvailable.edit && !!props.onUpdate && (
            <Edit
              isCardView={props.isMobile}
              disabled={props.disabledUpdate || serviceIsStarted}
              onSubmit={props.onUpdate}
            />
          )}
          {otherActionsAvailable.convertToMobile && (
            <ConvertToMobile
              isCardView={props.isMobile}
              supportMobile={props.supportMobile}
              positionType={props.position.type}
              onClick={changeActiveAction('convertToMobile')}
            />
          )}
          {otherActionsAvailable.transfer && (
            <Transfer
              isCardView={props.isMobile}
              positionState={props.position.state}
              disabled={mandatoryAcceptationNotValid}
              onTransfer={changeActiveAction('transfer')}
            />
          )}
          {otherActionsAvailable.duplicate && (
            <DuplicatePositionAction
              isCardView={props.isMobile}
              onDuplicate={handleDuplicate}
              placeId={props.shopId}
              lineId={props.lineId}
              positionId={props.positionId}
              onSelectLineMode={changeActiveAction('duplicate')}
              onBack={changeActiveAction(null)}
            />
          )}
          {otherActionsAvailable.newAppointment && (
            <NewBooking isCardView={props.isMobile} onSubmit={handleNewBooking} />
          )}
          {otherActionsAvailable.history && <History isCardView={props.isMobile} onSubmit={handleHistoryPosition} />}
        </>
      )}
      {otherMenuAvailable && <OtherOptions isCardView={props.isMobile} onSubmit={handleOpenOtherOptions} />}
    </div>
  )
}

export { PositionActions }
