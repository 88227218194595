import { CSSProperties } from 'react'
import { Button, Typography } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'

type Props = {
  style?: CSSProperties
  onSubmit: () => void
}

function ViewPermisseonDenyComponent(props: Props) {
  const { style, onSubmit } = props
  const { tr } = useTranslation()
  const { viewPermissionDeny: translation } = tr

  return (
    <div style={{ margin: 'auto', textAlign: 'center', ...style }}>
      <Typography variant="h4" component="h4">
        {translation.accessLimited}
      </Typography>
      <Typography style={{ marginTop: 24 }} variant="body1" component="p">
        {translation.accessLimitedMessage}
      </Typography>
      <Button variant="text" color="primary" onClick={() => onSubmit?.()} style={{ marginTop: 8 }} fullWidth>
        {translation.back}
      </Button>
    </div>
  )
}

export default ViewPermisseonDenyComponent
export const ViewPermisseonDeny = ViewPermisseonDenyComponent
