import { List, ListDnd } from 'shared/ui-kit/list'
import Avatar from '@mui/material/Avatar'
import ImageIcon from '@mui/icons-material/Image'
import TextIcon from '@mui/icons-material/TextFormat'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import PdfIcon from '@mui/icons-material/PictureAsPdf'
import LinkIcon from '@mui/icons-material/Link'
import { AdvertisementModel, AdvertisementTypes } from 'shared/models'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const typeView = {
  [AdvertisementTypes.IMAGE]: {
    icon: <ImageIcon />
  },
  [AdvertisementTypes.TEXT]: {
    icon: <TextIcon />
  },
  [AdvertisementTypes.LINK]: {
    icon: <LinkIcon />
  },
  [AdvertisementTypes.VIDEO]: {
    icon: <PlayCircleIcon />
  },
  [AdvertisementTypes.PDF]: {
    icon: <PdfIcon />
  }
}

type Props = {
  data: AdvertisementModel[]
  onSelect: (data: AdvertisementModel) => void
  editMode?: boolean
  onDropEnd?: (ads) => void
}

function AdvertisementList({ data, onSelect, editMode, onDropEnd }: Props) {
  const dataConverter = ({ name, type, id }: AdvertisementModel) => ({
    id,
    primaryText: name,
    avatar: <Avatar>{typeView[type].icon}</Avatar>
  })

  return (
    <DndProvider backend={HTML5Backend}>
      {!editMode && <List data={data} onSelect={onSelect} dataConverter={dataConverter} />}
      {editMode && <ListDnd data={data} onDropEnd={onDropEnd} dataConverter={dataConverter} />}
    </DndProvider>
  )
}

export { AdvertisementList }
