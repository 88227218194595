import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useTranslation } from 'shared/i18n/translate'
import { checkpoints } from 'pages/nav'
import { checkpoint as checkpointApi, line as lineApi, beacon as beaconApi, place as placeApi } from 'shared/api'
import { BeaconModel, SingleCheckpointModel, GetResponseType, LineModelSimplified } from 'shared/models'
import { useTimeZoneFilter } from 'features/time-zone-filter'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { showMessage, MessageTypes } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import CheckpointEdit from './checkpoint-edit/checkpoint-edit'
import PageLoader from 'shared/ui-kit/page-loader'
import Container from 'shared/ui-kit/container'
import { useAppDispatch } from 'store'
import { useQuery } from 'react-query'

type ParentsType = GetResponseType<SingleCheckpointModel>['parents']

function SmartCheckpointEdit() {
  const { checkpointId, lineId, placeId } = useParams<{
    lineId: string
    placeId: string
    checkpointId: string
  }>()

  const history = useHistory<{
    navTo?: string
    fromCheckpointId?: string | number
  }>()

  const { data: shop, isFetching: shopFetching } = useQuery(['shop', placeId], ({ signal }) =>
    placeApi.getPlace(placeId, { signal })
  )

  const { tr, locale } = useTranslation()
  const [data, setData] = useState<SingleCheckpointModel | null>(null)
  const [line, setLine] = useState<LineModelSimplified | null>(null)
  const [beacons, setBeacons] = useState<BeaconModel[] | null>(null)
  const [parents, setParents] = useState<ParentsType | null>(null)
  const dispatch = useAppDispatch()

  useTimeZoneFilter(placeId)

  const isLoading = data === null || line === null || beacons === null

  useEffect(() => {
    Promise.all([
      checkpointApi.getCheckpoint(checkpointId, placeId, lineId),
      beaconApi.getCheckpointBeaconList(placeId, checkpointId),
      lineApi.getLineListSimplified(placeId)
    ])
      .then(([checkpointResp, beaconResp, linesResp]) => {
        setData(checkpointResp.data)
        setParents(checkpointResp.parents)
        setBeacons(beaconResp.data)
        setLine(linesResp.data.find((l) => l.id == lineId) || null)
      })
      .catch(() => showOperationMessage('getError', MessageTypes.Error))
  }, [])

  useEffect(() => {
    if (!parents?.shop || !parents.line || !data) {
      return
    }

    const { shop, line, checkpoint } = parents
    const checkpointId = data.id as string
    const shopId = shop.id as string
    const lineId = line.id as string

    const crumb = getCrumbs(tr.breadcrumbs)

    dispatch(
      setBreadcrumbs([
        crumb.home(),
        crumb.places(),
        crumb.place([shopId], shop.name),
        crumb.lines([shopId]),
        crumb.line([shopId, lineId], line.name),
        crumb.checkpoints([shopId, lineId]),
        checkpointId
          ? crumb.editCheckpoint([shopId, lineId, checkpointId], checkpoint?.name)
          : crumb.newCheckpoint([shopId, lineId])
      ])
    )
  }, [parents, data, locale])

  function showOperationMessage(
    keyTr: keyof typeof tr.checkpointOperationMessages,
    type: MessageTypes = MessageTypes.Info,
    callback?: () => void
  ) {
    dispatch(showMessage(tr.checkpointOperationMessages[keyTr], type))
    callback?.()
  }

  function navBack() {
    history.push({
      pathname: history.location.state?.navTo ? history.location.state.navTo : checkpoints(placeId, lineId),
      state: {
        fromCheckpointId: checkpointId
      }
    })
  }

  function handleSave(data: SingleCheckpointModel) {
    if (!checkpointId) {
      checkpointApi
        .createCheckpoint({ ...data, lineId })
        .then(() => showOperationMessage('createSuccess', MessageTypes.Success, navBack))
        .catch(() => showOperationMessage('createError', MessageTypes.Error))
    } else {
      checkpointApi
        .updateCheckpoint(data)
        .then(() => showOperationMessage('updateSuccess', MessageTypes.Success, navBack))
        .catch(() => showOperationMessage('updateError', MessageTypes.Error))
    }
  }

  function handleDelete(id: SingleCheckpointModel['id']) {
    if (!id) {
      return
    }

    checkpointApi
      .deleteCheckpoint(id)
      .then(() => showOperationMessage('deleteSuccess', MessageTypes.Success, navBack))
      .catch(() => showOperationMessage('deleteError', MessageTypes.Error))
  }

  const showBeacons = !!shop?.showBeacons

  if (isLoading || shopFetching) {
    return <PageLoader />
  }

  return (
    <Container>
      <CheckpointEdit
        data={data}
        beacons={beacons || []}
        onSave={handleSave}
        onDelete={handleDelete}
        discreteLine={line.lineCalculationAlgorithmMode === 'discrete'}
        placeId={placeId}
        showBeacons={showBeacons}
      />
    </Container>
  )
}

export default SmartCheckpointEdit
