import { isEmail, isSenderIdValid, isURL } from 'shared/utils/string-test'
import * as Yup from 'yup'
import { Additional, LineMode, ServiceMode } from 'shared/models'

function getLineEditValidations() {
  return Yup.object().shape({
    name: Yup.string().required().max(100),
    displayShortName: Yup.string().max(100),
    shortName: Yup.string().required().max(100),
    description: Yup.string().max(2000),
    maxSimultaneous: Yup.number().when('requestParticipantsNumber', {
      is: true,
      then: (schema) => schema.required()
    }),
    smsSenderId: Yup.string().test({
      test: (val, context) => {
        return isSenderIdValid(val || '') || (!context.parent.sendSMSWhenJoined && !context.parent.sendSMSWhenCalled)
      }
    }),
    surveyLink: Yup.string().when('sendSurveyLinkAfterPositionServed', {
      is: true,
      then: (schema) => schema.test((val) => isURL(val || ''))
    }),
    beforeTheCallTime: Yup.number()
      .nullable()
      .test({
        test: (value, context) => {
          if (!context.parent.beforeTheCall) {
            return true
          }

          const isMobileModeEnabled = !!context.parent.modes?.find?.((el) =>
            [LineMode.MobileApp, LineMode.WebFrame].includes(el)
          )

          if (!isMobileModeEnabled) {
            return true
          }

          return !!value
        }
      }),
    services: Yup.array()
      .nullable()
      .test({
        test: (val, context) => {
          const isSimpleList = context.parent.lineServicesMode === ServiceMode.SimpleList
          const isServicesCorrect =
            !!val?.length && val.every((service) => service.name && service.name.length > 0 && service.duration > 9)

          return isSimpleList ? isServicesCorrect : true
        }
      }),
    additionals: Yup.array()
      .nullable()
      .test({
        test: (val) => {
          const isAdditionalsCorrect =
            !!val?.length &&
            val.every((additional: Additional) => {
              const comboItemsInvalid =
                (additional.comboItems || []).filter((item) => !item.value || !item.title)?.length > 0

              return additional.shortName && additional.label && !(comboItemsInvalid && additional.type === 'Combo')
            })

          const keys = (val || []).map((el) => el.shortName)
          const keysIsUnique = new Set(keys).size === keys.length

          return (isAdditionalsCorrect && keysIsUnique) || !val || !val.length
        }
      }),
    progressionTags: Yup.string().when('enableProgressionTags', {
      is: true,
      then: (schema) => schema.required().max(500)
    }),
    timeslotsFreeAllBeforeDays: Yup.number().test({
      test: (val, context) => {
        if (!context.parent.freeTimeslotsProgressively) {
          return true
        }
        if (!context.parent.allowFutureAppointments) {
          return true
        }
        return !!val && !!context.parent.limitInDays && +val > 0 && +val < +context.parent.limitInDays
      }
    }),
    limitInDays: Yup.number().test({
      test: (val, context) => {
        if (!context.parent.allowFutureAppointments) {
          return true
        }

        return !!val && +val > 0
      }
    }),
    sendFollowUpEmailBeforeDays: Yup.number().test({
      test: (val, context) => {
        if (!context.parent.sendFollowUpEmailBefore || !context.parent.allowFutureAppointments) {
          return true
        }

        return !!val
      }
    }),
    sendFollowUpSmsBeforeDays: Yup.number().test({
      test: (val, context) => {
        if (!context.parent.sendFollowUpSmsBefore || !context.parent.allowFutureAppointments) {
          return true
        }

        return !!val
      }
    }),
    minDurationToFreeTimeslot: Yup.number().test({
      test: (val, context) => {
        if (!context.parent.retainTimeslotWhenAppointmentCancelled || !context.parent.allowFutureAppointments) {
          return true
        }

        if (!val) {
          return false
        }

        return true
      }
    }),
    maxDurationToFreeTimeslot: Yup.number().test({
      test: (val, context) => {
        const {
          minDurationToFreeTimeslot = 0,
          retainTimeslotWhenAppointmentCancelled = false,
          allowFutureAppointments
        } = context.parent

        if (!retainTimeslotWhenAppointmentCancelled || !allowFutureAppointments) {
          return true
        }

        if (!val) {
          return false
        }

        return +val > +minDurationToFreeTimeslot
      }
    }),
    schedule: Yup.array().test({
      test: (val) => {
        if (!val || !val.length) {
          return false
        }

        const elementsWithError = (val || []).filter(
          (unit) =>
            !unit.weekdays?.length ||
            (!unit.nonStopService && (!unit.openingHour_unixtime || !unit.closingHour_unixtime)) ||
            (!!unit.serviceWithPause && (!unit.pauseStartHour_unixtime || !unit.pauseEndHour_unixtime))
        )

        return !elementsWithError.length
      }
    }),
    serviceTime: Yup.number().min(10),
    timeToReach: Yup.number().min(30),
    maxTimeToReach: Yup.number().min(30),
    minTimeToFreePlaceAfterEarlyAcceptance: Yup.number().test({
      test: (val, context) => {
        if (context.parent.lineCalculationAlgorithmMode === 'normal') {
          return true
        }

        if (val === undefined) {
          return false
        }

        const serviceTime = context.parent.serviceTime || 0

        return Boolean((val === 0 && val <= serviceTime) || (!!val && val <= serviceTime))
      }
    }),
    progressionParticipantEmails: Yup.string()
      .nullable()
      .when('enableProgressionTags', {
        is: true,
        then: (schema) =>
          schema.when('allowSendProgressionReport', {
            is: true,
            then: (schema) =>
              schema.test({
                test: (value) => {
                  const emails = (value || '').split(';')
                  const isEmails = !emails.map((email) => isEmail(email)).some((el) => !el)

                  return isEmails
                }
              })
          })
      }),
    blindCopyEmails: Yup.string()
      .nullable()
      .test({
        test: (value) => {
          const emails = (value || '').split(';')
          const isEmails = !emails.map((email) => isEmail(email)).some((el) => !el)

          return isEmails || !value
        }
      })
  })
}

export { getLineEditValidations }
