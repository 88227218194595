import { removeDiacritics } from 'shared/utils/string'
import { countries, ISOCountry } from 'shared/countries'
import { PlaceModelSimplified } from 'shared/models'

function placesFilter(term: string, collection: PlaceModelSimplified[], countriesValue: ISOCountry[]) {
  if (!collection.length) {
    return []
  }

  if (!term) {
    return collection
  }

  return collection.filter((shop) => {
    const shopCountryName = countries.getCountryName(countriesValue, shop.country)
    const valDia = removeDiacritics(term.toLowerCase())
    const str = String(shop.name + shop.city + shopCountryName + shop.description).toLowerCase()
    return removeDiacritics(str).indexOf(valDia) > -1
  })
}

export { placesFilter }
