import { useEffect, useState } from 'react'
import { removePosition, getPosition } from './suggestion-dialog-data'
import AppBar, { Title } from 'shared/ui-kit/app-bar'
import { useTranslation } from 'shared/i18n/translate'
import SuggestionFinishDialogContent from './suggestion-finish-dialog-content'
import { IconButton } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'

export const openWindowOnCenter = ({ url, w, h }) => {
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height

  const systemZoom = width / window.screen.availWidth
  const left = (width - w) / 2 / systemZoom + dualScreenLeft
  const top = (height - h) / 2 / systemZoom + dualScreenTop

  let dialog: any = null

  try {
    dialog = window.open(
      url,
      '_blank',
      `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    )
  } catch (err) {
    console.info(err)
  }

  if (dialog?.focus) {
    dialog.focus()
  }

  return dialog
}

function SuggestionBlankDialog() {
  const { tr } = useTranslation()
  const [data] = useState(getPosition())

  function beforeUnload() {
    removePosition()
  }

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload, false)
  }, [])

  if (!data) {
    return null
  }

  return (
    <>
      <AppBar>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <Title>{tr.common.appName}</Title>
          <IconButton onClick={window.close} style={{ marginRight: '2rem', color: '#FFF' }}>
            <CloseIcon />
          </IconButton>
        </div>
      </AppBar>
      <SuggestionFinishDialogContent data={data.position} />
    </>
  )
}

export default SuggestionBlankDialog
