import { IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { lineMonitoringCreate } from 'pages/nav'
import { useHistory } from 'react-router'
import { LineListControlsProps } from '../../types'

export function LineListControls({
  shopId,
  lineId,
  handleMonitoringClick,
  monitoringIsOpened,
  lineNotActive,
  peopleCount = 0
}: LineListControlsProps) {
  const history = useHistory()

  function handleAddPosition() {
    history.push(lineMonitoringCreate(shopId, lineId))
  }

  return (
    <>
      <IconButton onClick={handleAddPosition} key="addPosition" disabled={lineNotActive}>
        <AddIcon />
      </IconButton>
      <IconButton
        onClick={handleMonitoringClick}
        key="monitoringPosition"
        disabled={peopleCount === 0 && !monitoringIsOpened}
      >
        {!monitoringIsOpened ? <ArrowDownIcon /> : <ArrowUpIcon />}
      </IconButton>
    </>
  )
}
