import { useEffect, useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import Container from 'shared/ui-kit/container'
import { useHistory, useParams } from 'react-router-dom'
import { tag as tagApi } from 'shared/api'
import { TagEditFormModel } from 'shared/models'
import { Form } from 'shared/ui-kit/form/form'
import { FormGroup } from 'shared/ui-kit/form'
import { Button, Divider, TextField } from '@mui/material'
import PageLoader from 'shared/ui-kit/page-loader'
import { tags } from 'pages/nav'
import { PlaceLineTreeSelect } from './components/place-line-tree-select'
import { useAppDispatch } from 'store'
import Card from 'shared/ui-kit/card'
import { ActionText, ActionsWrapper, ButtonWithTextWrapper, ConfirmationActions } from 'shared/ui-kit/action-wrapper'
import DeleteIcon from '@mui/icons-material/Delete'

function TagEdit() {
  const { tr } = useTranslation()
  const dispatch = useAppDispatch()

  const { tagId } = useParams<{ tagId: string }>()
  const history = useHistory()

  const [data, setData] = useState<TagEditFormModel['data'] | null>(null)
  const [pending, setPending] = useState(false)
  const [isConfirmDelete, setConfirmDelete] = useState(false)

  const hasName = !!data?.tag?.name
  const linesIsValid = !data?.tagLinesItems.some((tl) => tl.lines.length === 0)

  useEffect(() => {
    fetchData().then((form: TagEditFormModel) => setCrumbs(form.data.tag))
  }, [])

  async function fetchData() {
    return await tagApi.getTagEditForm(tagId).then((resp) => {
      setData(resp.data)
      return resp
    })
  }

  function setCrumbs(tag?: TagEditFormModel['data']['tag']) {
    const crumb = getCrumbs(tr.breadcrumbs)
    const crumbs = [
      crumb.home(),
      crumb.tags(),
      tag?.id ? crumb.editTag([String(tag.id)], tag.name.translated || tag.name.base) : crumb.createTag()
    ]
    dispatch(setBreadcrumbs(crumbs))
  }

  function handleTryDelete() {
    setConfirmDelete(true)
  }

  function handleCancelDelete() {
    setConfirmDelete(false)
  }

  function handleConfirmDelete() {
    deleteTag().then(() => goToTagList())
  }

  async function updateTag() {
    if (data) {
      setPending(true)

      return tagApi.updateTag(data).then((resp) => {
        setPending(false)
        return resp
      })
    }
  }

  async function createTag() {
    if (data) {
      setPending(true)

      return tagApi.createTag(data).then((resp) => {
        setPending(false)
        return resp
      })
    }
  }

  function handleSave() {
    if (!tagId) {
      createTag().then(() => goToTagList())
    } else {
      updateTag().then(() => goToTagList())
    }
  }

  async function deleteTag() {
    if (data?.tag?.id) {
      setPending(true)

      return tagApi.removeTag(data.tag.id).then((resp) => {
        setPending(false)
        return resp
      })
    }
  }

  function goToTagList() {
    history.push(tags())
  }

  return (
    <Container>
      {!data && <PageLoader />}
      {!!data && (
        <>
          <Card>
            <Form>
              <FormGroup>
                <TextField
                  fullWidth
                  autoFocus
                  disabled={pending}
                  label={tr.tagEdit.tagName}
                  value={data?.tag?.name.base || ''}
                  onChange={(event) => {
                    setData({
                      tagLinesItems: data.tagLinesItems,
                      tag: { ...data.tag, name: { ...data.tag?.name, base: event.target.value } }
                    })
                  }}
                />
              </FormGroup>
              <FormGroup>
                <PlaceLineTreeSelect
                  attention={!linesIsValid}
                  selectedData={data.tagLinesItems}
                  onChange={(res) => {
                    setData({ tag: data.tag, tagLinesItems: res })
                  }}
                />
              </FormGroup>
            </Form>
            {!!tagId && (
              <>
                <Divider />
                <Form title={tr.entityEdit.advancedGroup} expandable>
                  <FormGroup>
                    {!isConfirmDelete && (
                      <ButtonWithTextWrapper>
                        <ActionText>{tr.tagEdit.deleteTitle}</ActionText>
                        <Button
                          variant="contained"
                          color="error"
                          size="small"
                          onClick={handleTryDelete}
                          startIcon={<DeleteIcon />}
                          disabled={pending}
                        >
                          {tr.entityEdit.deleteButton}
                        </Button>
                      </ButtonWithTextWrapper>
                    )}
                    {isConfirmDelete && (
                      <ButtonWithTextWrapper>
                        <ActionText>{tr.entityEdit.confirmDelete}</ActionText>
                        <ConfirmationActions>
                          <Button
                            variant="contained"
                            color="error"
                            size="small"
                            disabled={pending}
                            onClick={handleConfirmDelete}
                          >
                            {tr.entityEdit.yes}
                          </Button>
                          <Button variant="outlined" size="small" disabled={pending} onClick={handleCancelDelete}>
                            {tr.entityEdit.no}
                          </Button>
                        </ConfirmationActions>
                      </ButtonWithTextWrapper>
                    )}
                  </FormGroup>
                </Form>
              </>
            )}
          </Card>
          <ActionsWrapper>
            <Button
              disabled={pending || !hasName || !linesIsValid}
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSave}
            >
              {tagId ? tr.entityEdit.updateButton : tr.entityEdit.createButton}
            </Button>
          </ActionsWrapper>
        </>
      )}
    </Container>
  )
}

export default TagEdit
