import { List } from 'shared/ui-kit/list'
import Avatar from '@mui/material/Avatar'
import { useTranslation } from 'shared/i18n/translate'
import PlayIcon from '@mui/icons-material/PlayCircleOutline'
import HourglassIcon from '@mui/icons-material/HourglassEmpty'
import CheckIcon from '@mui/icons-material/Check'
import ErrorIcon from '@mui/icons-material/ErrorOutline'
import { CampaignModel, CampaignStatuses } from 'shared/models'

const statusView = {
  [CampaignStatuses.WAITING]: {
    icon: <HourglassIcon />,
    color: '#FDD835'
  },
  [CampaignStatuses.RUNNING]: {
    icon: <PlayIcon />,
    color: '#7CB342'
  },
  [CampaignStatuses.PASSED]: {
    icon: <CheckIcon />,
    color: ''
  }
}

type Props = {
  data: CampaignModel[]
  onSelect: (campaign: CampaignModel) => void
}

function CampaignList({ data, onSelect }: Props) {
  const { tr, dateFormatter } = useTranslation()

  return (
    <List
      data={data}
      onSelect={(e) => onSelect(e)}
      dataConverter={(item) => {
        const avatar = (
          <Avatar
            style={{
              backgroundColor: statusView[item.status]?.color
            }}
          >
            {statusView[item.status]?.icon || <ErrorIcon />}
          </Avatar>
        )

        return {
          id: item.id,
          primaryText: item.name,
          secondaryText: `${dateFormatter(item.startDate)} - ${dateFormatter(
            item.endDate
          )}, ${tr.campaignList.shopsCount(item.shopIds.length)}`,
          avatar
        }
      }}
    />
  )
}

export { CampaignList }
