import { useMemo } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import NotificationsIcon from '@mui/icons-material/NotificationsNone'
import NotificationsIconFilled from '@mui/icons-material/Notifications'
import { PositionStat } from 'features/position-stat'
import ColorDuration from 'features/position-dumb/utils/color-duration'

type Props = {
  timeToReach: number
  maxTimeToReach: number
  timeFromCall?: number
  className?: string
}

function CallTimeStat({ timeToReach, maxTimeToReach, timeFromCall, className }: Props) {
  const { tr } = useTranslation()

  const isOverTime = useMemo(
    () => timeToReach !== undefined && timeToReach < (timeFromCall || 0) / 1000,
    [timeToReach, timeFromCall]
  )

  const iconStyle = useMemo(() => {
    if (!isOverTime) {
      return {}
    }

    return {
      color:
        maxTimeToReach !== undefined && maxTimeToReach < (timeFromCall || 0) / 1000
          ? ColorDuration.extreme
          : ColorDuration.high,
      opacity: 1
    }
  }, [isOverTime, maxTimeToReach, timeFromCall])

  if (!timeFromCall) {
    return null
  }

  return (
    <PositionStat
      className={className}
      tooltipContent={tr.position.tooltips.timeSinceCall}
      iconStyle={iconStyle}
      icon={isOverTime ? NotificationsIconFilled : NotificationsIcon}
    >
      {tr.time.hmin(Math.floor(timeFromCall / 60000))}
    </PositionStat>
  )
}

export default CallTimeStat
export { CallTimeStat }
