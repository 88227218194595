import styled from '@emotion/styled'
import { Card } from '@mui/material'

const CardViewConfirmationWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 48px;
  padding: 0.75rem 0rem 0.75rem 0.5rem;
  gap: 0.5rem;
`

const CardViewConfirmationText = styled.span`
  margin: auto auto auto 0;
`

const CardView = styled(Card)<{ $disabled?: boolean }>`
  height: 100%;
  display: flex;
  background-color: ${(props) => (props.$disabled ? '#dbdbdb' : '#FFF')};
`

const CardViewContent = styled.div`
  display: flex;
  gap: 1rem;
  min-height: 48px;
  padding: 0.75rem;
`

const PositionActionIntoContainerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const PositionActionIntoContainerIcon = styled.div`
  opacity: 0.72;
  display: flex;
`

export {
  CardViewConfirmationWrapper,
  CardViewConfirmationText,
  CardView,
  CardViewContent,
  PositionActionIntoContainerWrapper,
  PositionActionIntoContainerIcon
}
