import { useEffect, useRef, useState } from 'react'
import css from './date-picker.module.scss'
import { DateTimePicker as MuiDatePicker } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import { DateTimePickerProps, DateSelect } from './types'
import { isPositiveNumber } from 'shared/utils/string-test'

function DateTimePicker(props: DateTimePickerProps) {
  const {
    disablePast,
    disableFuture,
    style,
    attention,
    shouldDisableDate,
    placeholder,
    onChange,
    value: initValue,
    disabled,
    label,
    autoComplete
  } = props

  const [value, setValue] = useState<Dayjs | null>(null)
  const currValue = useRef<Date | null>(null)

  function getValue() {
    if (!initValue) {
      return null
    }

    const isNumberDate = typeof initValue === 'string' && isPositiveNumber(initValue)
    const convertedValue = isNumberDate ? Number(initValue) : initValue
    const localDateOffset = new Date(convertedValue || '').getTimezoneOffset() * 60000
    const v = initValue ? new Date(convertedValue).getTime() : new Date().getTime()
    return new Date(v + localDateOffset)
  }

  useEffect(() => {
    const convertedValue = getValue()

    if (currValue.current !== convertedValue) {
      currValue.current = convertedValue
      const val = convertedValue ? dayjs(convertedValue) : null
      setValue(val)
    }
  }, [initValue])

  function handleBlur(ev: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) {
    const value = getValue()
    setValue(value ? dayjs(value) : null)
    props.onBlur?.(ev)
  }

  function handleChange(date: dayjs.Dayjs | null) {
    setValue(date)

    if (!date?.toDate() || !date?.isValid()) {
      if (props.allowClear) {
        onChange(undefined as any)
      }
      return
    }

    const rawDate = date.toDate()
    const localDateOffset = new Date(rawDate || '').getTimezoneOffset() * 60000
    const localOffset = localDateOffset
    const dateUnOffset = new Date(rawDate.getTime() - localOffset)

    const result: DateSelect<undefined> = Date.UTC(
      dateUnOffset.getUTCFullYear(),
      dateUnOffset.getUTCMonth(),
      dateUnOffset.getUTCDate(),
      dateUnOffset.getUTCHours(),
      dateUnOffset.getUTCMinutes()
    )

    onChange(result)
  }

  const labelView = (label || attention) && (
    <div style={{ display: 'flex' }}>
      {label} {attention && <div className={css.attention} />}
    </div>
  )

  return (
    <MuiDatePicker
      disabled={disabled}
      label={labelView}
      format="DD.MM.YYYY HH:mm"
      shouldDisableDate={(day) => {
        if (!day?.toDate) {
          return false
        }

        return shouldDisableDate?.(day?.toDate()) || false
      }}
      views={['year', 'month', 'day', 'hours', 'minutes']}
      disablePast={disablePast}
      disableFuture={disableFuture}
      value={value}
      slotProps={{
        textField: {
          placeholder,
          autoComplete,
          style,
          fullWidth: true,
          onBlur: handleBlur,
          InputLabelProps: { shrink: props.shrink },
          helperText: props.message,
          FormHelperTextProps: { style: { color: props.messageColor || '#00000042' } }
        }
      }}
      onChange={handleChange}
    />
  )
}

export default DateTimePicker
