import { useEffect, useMemo, CSSProperties } from 'react'
import Select from 'react-select'
import Option from './components/option'
import SingleValue from './components/single-value'
import Placeholder from './components/placeholder'
import Menu from './components/menu'
import NoOptionsMessage from './components/no-options-message'
import ControlComponent from './components/control'

type Value = number | string | null

type Props = {
  title?: string
  dataConverter: (element: any) => { value: Value; text: string }
  list: any[]
  onChange?: (value: Value) => void
  value: Value
  disabled?: boolean
  style?: CSSProperties
  styles?: CSSProperties
  fullWidth?: boolean
  ensureValue?: boolean
  isShrink?: boolean
  inputStyles?: CSSProperties
  autoFocus?: boolean
  noOptionsMessage?: string
}

export function Autocomplete(props: Props) {
  const { title, dataConverter, list, onChange, value } = props
  const { disabled, style, styles, fullWidth, ensureValue, isShrink, inputStyles } = props

  const defaultValue = { value: null, text: '' }

  useEffect(() => {
    if (list.length < 1 && value === null) {
      return
    }

    if (ensureValue) {
      const inCollection = list.find((i) => dataConverter(i).value === value)

      if (!inCollection) {
        const first = list.length > 0 ? list[0] : null
        const data = first ? dataConverter(first) : defaultValue
        onChange?.(data.value)
      }
    }
  }, [list])

  function onChangeWrapper(item) {
    onChange?.(item.value)
  }

  function handleClear() {
    onChange?.(null)
  }

  const suggestions = useMemo(() => {
    return list.map((suggestion) => ({
      value: dataConverter(suggestion).value,
      label: dataConverter(suggestion).text || ''
    }))
  }, [list])

  function Control(controlProps) {
    return (
      <ControlComponent
        autoFocus={props.autoFocus}
        {...controlProps}
        style={style}
        fullWidth={fullWidth}
        isShrink={isShrink}
        inputStyles={inputStyles}
        allowClear={!ensureValue}
        onClear={handleClear}
      />
    )
  }

  function MenuWrap(controlProps) {
    return <Menu {...controlProps} style={style} fullWidth={fullWidth} elevation={2} />
  }

  const components = useMemo(
    () => ({
      Control,
      Menu: MenuWrap,
      NoOptionsMessage: () => <NoOptionsMessage message={props.noOptionsMessage} />,
      Option,
      Placeholder,
      SingleValue
    }),
    [style, fullWidth]
  )

  const selectValue = useMemo(() => {
    return suggestions.find((s) => s.value === value) || defaultValue
  }, [suggestions, value])

  return (
    <Select
      isDisabled={disabled}
      placeholder={title}
      options={suggestions}
      value={selectValue}
      isClearable={!ensureValue}
      components={components}
      onChange={onChangeWrapper}
      styles={{ container: (provided) => ({ ...provided, ...styles }) }}
    />
  )
}
