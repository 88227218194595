import { createContext, ReactNode, useContext, useRef, useState } from 'react'
import axios from 'axios'

type HelperContextType = {
  helpers: { [key: string]: any }
  getTagHelpers: (tag: string, locale: string) => void
}

const HelperContext = createContext<HelperContextType>({
  helpers: {},
  getTagHelpers: () => {
    console.log('Empty function')
  }
})

export function HelperProvider({ children }: { children: ReactNode }) {
  const [helpers, setHelpers] = useState({})
  const loading = useRef({})

  async function getTagHelpers(tag: string, locale: string) {
    if (!tag || !locale) {
      return
    }

    if (helpers[`${tag}-${locale}`] || loading.current[`${tag}-${locale}`]) {
      return
    }

    try {
      loading.current[`${tag}-${locale}`] = true
      const data = await axios.get(`/docs/${tag}_${locale}.json`, { baseURL: '' })

      if (data) {
        setHelpers({ ...helpers, [`${tag}-${locale}`]: data })
      }
    } catch (err) {
      console.log('File not found, check the file key', err)
    } finally {
      loading.current[`${tag}-${locale}`] = false
    }
  }

  return <HelperContext.Provider value={{ helpers, getTagHelpers }}>{children}</HelperContext.Provider>
}

export function useHeplers() {
  const { helpers, getTagHelpers } = useContext(HelperContext)

  return { helpers, getTagHelpers }
}
