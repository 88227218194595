import { useEffect, createElement } from 'react'
import { setCurrentPlace } from 'store/actions/places-action-creators'
import { useAppDispatch } from 'store'

function TimeZoneFilter(WrappedComponent: any) {
  return function TimeZoneFilterComponent(props: any) {
    useTimeZoneFilter(props.match.params.placeId)

    return createElement(WrappedComponent, props)
  }
}

export function useTimeZoneFilter(placeId?: string | number) {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (placeId) {
      dispatch(setCurrentPlace(placeId))
    }

    return () => {
      dispatch(setCurrentPlace(null))
    }
  }, [])

  return
}

export default TimeZoneFilter
