import Toggle from 'shared/ui-kit/toggle-wrap/toggle'
import { Controller } from 'react-hook-form'
import { ControlProps } from '.'

export function SwitchControl(props: ControlProps) {
  return (
    <div style={{ margin: '0.5rem 0' }}>
      <Controller
        name={props.name}
        control={props.control}
        defaultValue={false}
        render={({ field: f }) => <Toggle label={props.label} onToggle={f.onChange} toggled={f.value} />}
      />
    </div>
  )
}
