import { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import { TextField } from 'shared/ui-kit/text-field'
import Card from 'shared/ui-kit/card'
import { addTestAttr } from 'shared/utils/test-attr'
import { useTranslation } from 'shared/i18n/translate'
import { Captcha } from 'features/captcha/captcha'
import { useLocation } from 'react-router'
import {
  LoginActions,
  LoginFormStyled,
  LoginField,
  LinkWrapper,
  LoginLogo,
  LinkStyled,
  CaptchContainer
} from '../styled'
import { loginByTemporaryCode, resetPassword } from 'pages/nav'

type Props = {
  onSubmit: (login: string, password: string, captchaId?: string, userEnteredCaptchaCode?: string) => void
  disabled: boolean
  showCaptcha?: boolean
}

function LoginForm({ onSubmit, disabled, showCaptcha }: Props) {
  const { search } = useLocation()

  const params = new URLSearchParams(search)

  const [login, setLogin] = useState(params.get('email') || '')
  const [password, setPassword] = useState('')
  const [captchaId, setCaptchaId] = useState<string | undefined>()
  const [captchaValue, setCaptchaValue] = useState<string>()

  const { tr } = useTranslation()
  const { logIn, email, password: pass, forgot } = tr.login

  function handleSubmit(ev: React.FormEvent<HTMLFormElement>) {
    ev.preventDefault()

    if (login && password && !disabled) {
      onSubmit(login, password, captchaId, captchaValue)
    }
  }

  useEffect(() => {
    if (showCaptcha === false && captchaValue !== '') {
      setCaptchaValue('')
    }
  }, [showCaptcha])

  return (
    <Card>
      <form onSubmit={handleSubmit}>
        <LoginFormStyled>
          <LoginLogo />
          <LoginField>
            <TextField
              {...addTestAttr('LoginForm-Email')}
              autocomplete="username"
              value={login}
              placeholder={email}
              onChange={(value) => setLogin(value)}
              disabled={disabled}
            />
          </LoginField>
          <LoginField>
            <TextField
              {...addTestAttr('LoginForm-Password')}
              autocomplete="current-password"
              value={password}
              placeholder={pass}
              type="password"
              onChange={(value) => setPassword(value)}
              disabled={disabled}
              id="JeFileBackofficeLogin2"
            />
          </LoginField>
          {showCaptcha && (
            <div>
              <CaptchContainer>
                <Captcha handleCaptchaId={setCaptchaId} />
              </CaptchContainer>
              <LoginField style={{ marginTop: '0.5rem' }}>
                <TextField
                  value={captchaValue}
                  label="Code"
                  onChange={setCaptchaValue}
                  id="JeFileBackofficeLoginInput"
                />
              </LoginField>
            </div>
          )}
          <LoginActions>
            <Button
              {...addTestAttr('LoginForm-Submit')}
              variant="contained"
              color="primary"
              type="submit"
              disabled={!(password && login) || disabled || (showCaptcha && !(password && login && captchaValue))}
            >
              {logIn}
            </Button>
          </LoginActions>
          <LinkWrapper style={{ marginTop: '2rem' }}>
            <LinkStyled to={resetPassword() + `?email=${login}`} {...addTestAttr('LoginForm-ResetPassword')}>
              {forgot}
            </LinkStyled>
          </LinkWrapper>
          <LinkWrapper>
            <LinkStyled to={loginByTemporaryCode()} {...addTestAttr('LoginForm-LoginByTemporaryCode')}>
              {tr.login.loginByTemporaryCode}
            </LinkStyled>
          </LinkWrapper>
        </LoginFormStyled>
      </form>
    </Card>
  )
}

export { LoginForm }
