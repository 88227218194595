import Text from 'shared/ui-kit/text'
import { Form, FormGroup } from 'shared/ui-kit/form'
import { useTranslation } from 'shared/i18n/translate'
import Divider from '@mui/material/Divider'
import { addTestAttr } from 'shared/utils/test-attr'
import { PlaceViewProps } from './types'

function PlaceView(props: PlaceViewProps) {
  const { brands, timeZones, data, canUpdateShops } = props
  const { tr } = useTranslation()

  function renderBrand() {
    if (!brands) {
      return null
    }

    const brand = brands.find((b) => b.id === data.brandId)

    if (!brand) {
      return null
    }

    return (
      <FormGroup>
        <Text type="caption">{tr.placeView.brand}</Text>
        <Text type="body-1">{brand.name}</Text>
      </FormGroup>
    )
  }

  function renderTimeZone() {
    if (!timeZones) {
      return null
    }

    const timeZone = timeZones.filter(Boolean).find((x) => x?.id === data.timeZoneId)

    if (!timeZone) {
      return null
    }

    return (
      <FormGroup>
        <Text type="caption">{tr.placeView.timeZone}</Text>
        <Text type="body-1">{timeZone.name}</Text>
      </FormGroup>
    )
  }

  function hasText(value) {
    return Boolean(value && value.length)
  }

  const address: string[] = []

  if (props.countryName) {
    address.push(props.countryName)
  }

  if (data.city) {
    address.push(data.city)
  }
  if (data.address) {
    address.push(data.address)
  }

  if (data.zipCode) {
    address.push(data.zipCode)
  }

  return (
    <div>
      <Form>
        {renderBrand()}
        <FormGroup>
          <Text type="caption">{tr.placeView.name}</Text>
          <Text type="body-1" {...addTestAttr('PlaceView-Name')}>
            {data.name}
          </Text>
        </FormGroup>
        {!!data.description && (
          <FormGroup>
            <Text type="caption">{tr.placeView.description}</Text>
            <Text type="body-1" {...addTestAttr('PlaceView-Description')}>
              {`${data.description.split('\n')[0]}...`}
            </Text>
          </FormGroup>
        )}
      </Form>
      <Divider />
      <Form title={tr.placeView.location} expandable headerProps={addTestAttr('PlaceView-LocationFormHeader')}>
        {hasText(data.address) && (
          <FormGroup>
            <Text type="caption">{tr.placeView.address}</Text>
            <Text type="body-1">{address.join(', ')}</Text>
          </FormGroup>
        )}
        <FormGroup>
          <Text type="caption">{tr.placeView.gpsPosition}</Text>
          <Text type="body-1" {...addTestAttr('PlaceView-GpsPosition')}>{`${data.latitude}, ${data.longitude}`}</Text>
        </FormGroup>
        {Boolean(data.lineAuthorisationRadius) && (
          <FormGroup>
            <Text type="caption">{tr.placeView.lineAuthorisationRadius}</Text>
            <Text type="body-1" {...addTestAttr('PlaceView-LineAuthorisationRadius')}>
              {data.lineAuthorisationRadius}
            </Text>
          </FormGroup>
        )}
      </Form>
      {canUpdateShops && <Divider />}
      {canUpdateShops && (
        <Form title={tr.placeView.moreInfo} expandable>
          {data.shortName && (
            <FormGroup>
              <Text type="caption">{tr.placeView.shortName}</Text>
              <Text type="body-1">{data.shortName}</Text>
            </FormGroup>
          )}
          {data.monitoringId && (
            <FormGroup>
              <Text type="caption">{'GUID'}</Text>
              <Text type="body-1">{data.monitoringId}</Text>
            </FormGroup>
          )}
          {data.notificationEmail && (
            <FormGroup>
              <Text type="caption">{tr.placeView.notificationEmail}</Text>
              <Text type="body-1">{data.notificationEmail}</Text>
            </FormGroup>
          )}
          {data.reportSubscribersEmail && (
            <FormGroup>
              <Text type="caption">{tr.placeView.reportSubscribersEmail}</Text>
              <Text type="body-1">{data.reportSubscribersEmail}</Text>
            </FormGroup>
          )}
          {renderTimeZone()}
          {hasText(data.supportedLanguages) && (
            <FormGroup>
              <Text type="caption">{tr.placeView.supportedLanguages}</Text>
              <Text type="body-1">{data.supportedLanguages}</Text>
            </FormGroup>
          )}

          {hasText(data.expeditionEmail) && (
            <FormGroup>
              <Text type="caption">{tr.placeView.expeditionEmail}</Text>
              <Text type="body-1">{data.expeditionEmail}</Text>
            </FormGroup>
          )}
          {data.appointmentLink && (
            <Form>
              <FormGroup>
                <Text type="caption">{tr.placeView.appointmentLink}</Text>
                <a href={data.appointmentLink} style={{ fontSize: '13px' }}>
                  {data.appointmentLink}
                </a>
              </FormGroup>
            </Form>
          )}
          {data.webViewLink && (
            <Form>
              <FormGroup>
                <Text type="caption">{tr.placeView.webViewLink}</Text>
                <a href={data.webViewLink} style={{ fontSize: '13px' }}>
                  {data.webViewLink}
                </a>
              </FormGroup>
            </Form>
          )}
        </Form>
      )}
      {canUpdateShops && <Divider />}
    </div>
  )
}

export { PlaceView }
