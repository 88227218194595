import { Translations } from 'shared/i18n/translation-types'
import {
  BasicPositionModel,
  CheckpointModel,
  DiscretePositionModel,
  PositionModel,
  ServiceModel,
  TimeLeftOrOverState,
  TimeslotModel
} from 'shared/models'

export function simplifyPositionToFullPositionConvert(
  position: DiscretePositionModel | BasicPositionModel,
  services: ServiceModel[],
  serverNow: number,
  tr: Translations,
  timeSlot?: TimeslotModel,
  checkpoints?: CheckpointModel[]
): PositionModel {
  const serverDiffTime = Date.now() - serverNow

  const calculatedWaitingDelay =
    position.expectedCallTime && timeSlot?.from_unixtime ? position.expectedCallTime - timeSlot.from_unixtime : 0

  const waitingDelay = position.waitingDelay !== undefined ? position.waitingDelay : calculatedWaitingDelay

  const timeFromCall = position.callTime ? Date.now() - position.callTime + serverDiffTime : undefined
  const timeFromServiceStart = position.serviceStartedTime
    ? Date.now() - position.serviceStartedTime + serverDiffTime
    : undefined

  const joinedDuration = position.joinTime ? Date.now() - position.joinTime + serverDiffTime : undefined

  const waitingToCallTime = position.expectedCallTime
    ? position.expectedCallTime - Date.now() + serverDiffTime
    : undefined

  const timeLeftOrOver =
    position.serviceStartedTime && position.initialServiceDuration
      ? Date.now() - position.serviceStartedTime + serverDiffTime - position.initialServiceDuration
      : undefined

  const timeLeftOrOverState: TimeLeftOrOverState | undefined = !timeLeftOrOver
    ? undefined
    : timeLeftOrOver < -120000
      ? TimeLeftOrOverState.Normal
      : timeLeftOrOver < 0
        ? TimeLeftOrOverState.Alarm
        : TimeLeftOrOverState.Over

  const customer = position.customer
    ? {
        id: 0,
        firstName: position.customer.fullName,
        language: position.customer.language,
        phoneNumber: position.customer.phoneNumber,
        deviceType: position.customer.deviceType,
        email: position.customer.email
      }
    : undefined

  const operator = position.operatorFullName
    ? {
        lastName: position.operatorFullName,
        firstName: ' '
      }
    : undefined

  const assignedCheckpointId = (position as BasicPositionModel)?.assignedCheckpointId
  const customCheckpointId = (position as BasicPositionModel)?.customCheckpointId

  const assignedCheckpointFound = assignedCheckpointId
    ? checkpoints?.find((el) => String(el.id) === String(assignedCheckpointId))
    : undefined
  const customCheckpointFound = customCheckpointId
    ? checkpoints?.find((el) => String(el.id) === String(customCheckpointId))
    : undefined

  const assignedCheckpoint = assignedCheckpointId ? assignedCheckpointFound : undefined
  const customCheckpoint = customCheckpointId ? customCheckpointFound : undefined

  const expectedServiceStartFinishTime_unixtime = position.expectedServiceStartTime
    ? position.expectedServiceStartTime + (position?.expectedServiceDuration || position?.initialServiceDuration || 0)
    : undefined

  const expectedServiceFinishTime_unixtime = position.serviceStartedTime
    ? position.serviceStartedTime + (position?.expectedServiceDuration || position?.initialServiceDuration || 0)
    : expectedServiceStartFinishTime_unixtime

  const expectedServiceTime =
    !!position?.expectedServiceDuration && !!timeFromServiceStart
      ? position.expectedServiceDuration - timeFromServiceStart
      : undefined

  const internalServices = Object.entries(tr.internalServices || {}).map(([id, name]) => {
    return { name, id, duration: 0, isPrivate: true } as ServiceModel
  })

  return {
    id: position.id,
    type: position.type,
    state: position.state,
    offlineTime_unixtime: position.offlineTime,
    additionals: position.additionals,
    personsQuantity: position.personsQuantity || 1,
    asap: position.asap,
    priority: position.priority || false,
    customer,
    callPhoneNumber: position.callPhoneNumber,
    reservationCode: position.reservationCode,
    unreadChatMessagesCount: position.unreadChatMessagesCount,
    timeSlot,
    joinTime_unixtime: position.joinTime,
    callTime_unixtime: position.callTime,
    services: [...(services || []), ...internalServices].filter((service) =>
      position.serviceIds?.includes(Number(service.id))
    ),
    movingTime_unixtime: position.movingTime,
    expectedCallTime_unixtime: position.expectedCallTime,
    expectedServiceStartTime_unixtime: position.expectedServiceStartTime,
    staffComment: position.staffComment,
    operator,
    sms: position.sms,
    isValid: position.isValid,
    validForServiceSince: position.validForServiceSince,
    inPlaceSince: position.inPlaceSince,
    progressionTags: position.progressionTags,
    progressionTagsComment: position.progressionTagsComment,
    progressionTagsImages: position.progressionTagsImages,
    denialOfServiceReason: position.denialOfServiceReason,
    distance: position.distance,
    plannedServiceDuration: position.initialServiceDuration,
    timeFromCall,
    timeFromServiceStart,
    joinedDuration,
    waitingDelay: waitingDelay > 300000 ? waitingDelay : undefined,
    waitingToCallTime,
    timeLeftOrOver: timeLeftOrOver ? Math.abs(timeLeftOrOver) : undefined,
    timeLeftOrOverState,
    assignedCheckpoint,
    customCheckpoint,
    serviceStartedTime_unixtime: position.serviceStartedTime,
    expectedServiceFinishTime_unixtime,
    acceptationState: position.acceptationState,
    expectedServiceTime,
    readonly: position.readonly,
    lastStayInLineNotificationSentTime: position.lastStayInLineNotificationSentTime,
    lastStayInLineConfirmationReceivedTime: position.lastStayInLineConfirmationReceivedTime,
    lastVisitDate: position.lastVisitDate,
    visitsCount: position.visitsCount,
    checkpointZoneEnterTime: position.checkpointZoneEnterTime
  }
}
