import { useEffect, useMemo, useState, ReactNode } from 'react'
import { ITranslationContextProps, TranslateContext } from 'shared/i18n/translation-context'
import dateFormatter from 'shared/i18n/date-formatter'
import { Translations } from 'shared/i18n/translation-types'
import i18n from 'shared/i18n'
import { useAppSelector } from 'store'
import { getTimeZoneSelector } from 'store/selectors/timeZoneSelectors'
import { getUserLocaleSelector } from 'store/selectors/userSelectors'

type Props = { children?: ReactNode }

function TranslationProvider(props: Props) {
  const [translation, setTranslation] = useState<Translations>()
  const locale = useAppSelector(getUserLocaleSelector)
  const timeZone = useAppSelector(getTimeZoneSelector)

  useEffect(() => {
    i18n.getTranslationAsync(locale).then(setTranslation)
  }, [locale])

  const context: ITranslationContextProps = useMemo(() => {
    return {
      locale,
      tr: translation as Translations,
      dateFormatter: (date = null, format, componentZone) =>
        dateFormatter(timeZone?.offset || 0, locale, date, format, componentZone || timeZone?.id || 'Etc/GMT'),
      timeZoneId: timeZone?.id || 'Etc/GMT',
      timeZoneOffset: timeZone?.offset || 0
    }
  }, [locale, timeZone, translation])

  return <TranslateContext.Provider value={context}>{translation ? props.children : null}</TranslateContext.Provider>
}

export { TranslationProvider }
