import { Button } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import { checkpoints } from 'pages/nav'
import { LineInactiveState } from 'shared/models'
import { NavLink } from 'react-router-dom'
import { InactiveReasonProps } from '../../types'

export function InactiveReason({ reason, lineId, shopId, className, style }: InactiveReasonProps) {
  const { tr } = useTranslation()

  const inactiveReasonsTr = tr.lineMonitoring.inactiveReasons

  const inactiveMessageMap: Record<LineInactiveState, { message: string; action: string }> = {
    noActiveCheckpoints: inactiveReasonsTr.noActiveCheckpoints,
    operatingTimeIsOver: inactiveReasonsTr.operatingTimeIsOver,
    overcharged: inactiveReasonsTr.overcharged,
    reservationTimeNotCame: inactiveReasonsTr.reservationTimeNotCame
  }

  const inactiveMessage = inactiveMessageMap[reason] || { message: '', action: '' }

  return (
    <div className={className} style={style}>
      <div>{inactiveMessage.message}</div>

      {(reason === LineInactiveState.Overcharged ||
        reason === LineInactiveState.OperatingTimeIsOver ||
        reason === LineInactiveState.NoActiveCheckpoints) && (
        <NavLink style={{ textDecoration: 'none' }} to={checkpoints(shopId, lineId)}>
          <Button variant="contained" color="primary">
            {inactiveMessage.action}
          </Button>
        </NavLink>
      )}
    </div>
  )
}
