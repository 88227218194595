import { DeviceType } from 'shared/models'

type EventFlowCustomer = {
  Id?: string | null
  FirstName?: string | null
  LastName?: string | null
  Email?: string | null
  CompanyName?: string | null
  DeviceType?: DeviceType | null
  PhoneNumber?: string | null
  Language?: string | null
}

function parseCustomerInfo(data: string) {
  let result: EventFlowCustomer | undefined

  try {
    result = JSON.parse(data)
  } catch (err) {
    console.warn(err)
  }

  return result
}

export { parseCustomerInfo }
