import { WeekdayElement } from 'shared/models'

function getDaySchedule(schedule?: WeekdayElement[], date?: number) {
  return (schedule || []).find((unit) => unit.weekdays.includes(new Date(date || new Date()).getDay()))
}

function getFirstAvailableDateAfterSelectedDate(schedule: WeekdayElement[], selectedDate?: number) {
  const date = new Date(selectedDate || new Date())
  let count = 0
  let availableDateIsNotFound = false

  while (!schedule.find((unit) => unit.weekdays.includes(date.getDay()))) {
    count++
    date.setDate(date.getDate() + 1)

    if (count >= 6) {
      availableDateIsNotFound = true
      break
    }
  }

  return availableDateIsNotFound ? null : date.getTime()
}

export { getDaySchedule, getFirstAvailableDateAfterSelectedDate }
