import { LastUpdateModel } from './last-update.model'
import { Optional } from 'shared/types'

export enum CampaignStatuses {
  WAITING = 'waiting',
  RUNNING = 'running',
  PASSED = 'passed'
}

export interface CampaignModel {
  id: string | number
  name: string
  startDate: number
  endDate: number
  shopIds: (string | number)[]
  status: CampaignStatuses
  advertisementCount: number
  displayDuration?: number
  lastUpdate?: LastUpdateModel
}

export type CampaignModelEdit = Optional<CampaignModel, 'id'>
