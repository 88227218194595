import { useState } from 'react'
import { Form, FormGroup } from 'shared/ui-kit/form'
import { useTranslation } from 'shared/i18n/translate'
import Text from 'shared/ui-kit/text'
import Dropdown from 'shared/ui-kit/dropdown'
import { RadioButtonGroup } from 'shared/ui-kit/radio-button'
import { Button, Radio, FormControlLabel, Card, TextField } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import { ControlLabel } from 'shared/ui-kit/control-label'
import TimePickerTimestamp from 'features/time/time-picker-timestamp'
import produce from 'immer'
import { BroadcastAlertsFormProps } from './types'
import { ActionsWrapper } from 'shared/ui-kit/action-wrapper'

function BroadcastAlertsForm(props: BroadcastAlertsFormProps) {
  const { tr, dateFormatter } = useTranslation()

  function getInitialState() {
    const data: any = {
      reservationStartHour_unixtime: props.config.reservationStartHour_unixtime,
      reservationEndHour_unixtime: props.config.reservationEndHour_unixtime,
      alertType: props.config.standardMessages.length ? 'standard' : 'custom',
      afterAlert: 'deleteReservationsAndBlockTimeranges'
    }

    return { data, standardAlertType: true }
  }

  const [state, setState] = useState(getInitialState())

  function handleChange(field, value) {
    setState(
      produce(state, (draft) => {
        draft.data[field] = value
      })
    )
  }

  function handleSave() {
    props.onSave(state.data)
  }

  const d = state.data
  const t = tr.broadcastAlerts

  const hasStandard = Boolean(props.config.standardMessages.length)

  return (
    <>
      <Card>
        <Form>
          {hasStandard && (
            <FormGroup>
              <RadioButtonGroup
                label={t.alertType}
                name="alertType"
                onChange={(val) => handleChange('alertType', val)}
                value={d.alertType}
                ensureValue="standard"
                row
              >
                <FormControlLabel
                  value="standard"
                  control={<Radio id={'radioButtonStandart'} />}
                  label={t.alertTypeStandard}
                />
                <FormControlLabel
                  value="custom"
                  control={<Radio id={'radioButtonCustom'} />}
                  label={t.alertTypeCustom}
                />
              </RadioButtonGroup>
            </FormGroup>
          )}
          {d.alertType === 'standard' && (
            <FormGroup>
              <Dropdown
                ensureValue
                value={d.standardMessageId}
                data={props.config.standardMessages}
                label={t.standartAlert}
                dataConverter={(item: any) => ({ value: item.id, text: item.name })}
                onChange={(val) => handleChange('standardMessageId', val)}
              />
            </FormGroup>
          )}
          {d.alertType === 'custom' && (
            <FormGroup>
              <TextField
                label={t.customAlertType}
                placeholder={t.customAlertTypePlaceholder}
                value={d.customAlertText}
                onChange={(ev) => handleChange('customAlertText', ev.target.value)}
                helperText={`${d.customAlertText ? d.customAlertText.length : 0}/160 ${tr.entityEdit.chars}`}
                fullWidth
                InputLabelProps={{ shrink: true }}
                multiline
                maxRows={15}
              />
            </FormGroup>
          )}
          <FormGroup>
            <ControlLabel>{t.timeRange}</ControlLabel>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <TimePickerTimestamp
                value={d.reservationStartHour_unixtime}
                onChange={(val) => handleChange('reservationStartHour_unixtime', val)}
                offset
              />
              <div style={{ lineHeight: '48px', margin: '-7px 8px', fontWeight: 300 }}>{t.timeRangeDelimiter}</div>
              <TimePickerTimestamp
                value={d.reservationEndHour_unixtime}
                onChange={(val) => handleChange('reservationEndHour_unixtime', val)}
                offset
              />
            </div>
          </FormGroup>
          <FormGroup>
            <RadioButtonGroup
              label={t.afterAlert}
              name="afterAlert"
              onChange={(val) => handleChange('afterAlert', val)}
              value={d.afterAlert}
              ensureValue="deleteReservationsAndBlockTimeranges"
              vertical
            >
              <FormControlLabel
                value="deleteReservationsAndBlockTimeranges"
                control={<Radio id={'radioButton1'} />}
                label={t.deleteReservationsAndBlockTimeranges}
              />
              <FormControlLabel
                value="keepReservationsAndBlockTimeranges"
                control={<Radio id={'radioButton2'} />}
                label={t.keepReservationsAndBlockTimeranges}
              />
              <FormControlLabel
                value="keepReservationsAndKeepTimeranges"
                control={<Radio id={'radioButton3'} />}
                label={t.keepReservationsAndKeepTimeranges}
              />
            </RadioButtonGroup>
          </FormGroup>
        </Form>
      </Card>
      <ActionsWrapper>
        <Button
          startIcon={<SendIcon />}
          disabled={props.processing}
          variant="contained"
          color="primary"
          size="small"
          onClick={handleSave}
        >
          {t.send}
        </Button>
        {props.config.lastAlertSent?.date && props.config.lastAlertSent.user && (
          <Text type="caption">
            {tr.broadcastAlerts.lastSent(
              props.config.lastAlertSent.user?.firstName,
              props.config.lastAlertSent.user?.secondName,
              dateFormatter(props.config.lastAlertSent.date, 'dateTime')
            )}
          </Text>
        )}
      </ActionsWrapper>
    </>
  )
}

export default BroadcastAlertsForm
