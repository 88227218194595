import { useEffect, useState } from 'react'
import { CounterEdit } from './dumb/counter-edit'
import { counters, counter } from 'pages/nav'
import { useTranslation } from 'shared/i18n/translate'
import { useTimeZoneFilter } from 'features/time-zone-filter'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { showMessage, MessageTypes } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import Container from 'shared/ui-kit/container'
import PageLoader from 'shared/ui-kit/page-loader'
import { useHistory, useParams } from 'react-router'
import { counter as counterAPI } from 'shared/api'
import { CounterModel, GetResponseType } from 'shared/models'
import { useDataProvider } from 'features/isolated-data-provider'
import { useTitle } from 'shared/hooks/useTitle'
import { useAppDispatch } from 'store'

function SmartCounterEdit() {
  const dispatch = useAppDispatch()
  const { counterId, placeId } = useParams<{ placeId: string; counterId: string }>()
  const history = useHistory()
  const { tr, locale } = useTranslation()
  const [entity, setEntity] = useState<GetResponseType<CounterModel | undefined>>()
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)

  const { getPlace } = useDataProvider()

  useTitle({ placeName: getPlace(placeId)?.name })

  useTimeZoneFilter(placeId)

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (!entity) {
      return
    }

    const { data, parents } = entity

    if (!data || !parents) {
      return
    }

    const crumb = getCrumbs(tr.breadcrumbs)

    const baseCrumbs = [
      crumb.home(),
      crumb.places(),
      crumb.place([String(parents?.shop?.id)], parents?.shop?.name),
      crumb.counters([String(parents?.shop?.id)])
    ]

    const editCrumbs = [
      crumb.counter([String(parents?.shop?.id), String(data?.id)], data.name),
      crumb.editCounter([String(parents?.shop?.id), String(data?.id)])
    ]

    const createCrumbs = [crumb.newCounter([String(parents?.shop?.id)])]

    dispatch(setBreadcrumbs([...baseCrumbs, ...(data.id ? editCrumbs : createCrumbs)]))
  }, [entity, locale])

  async function fetchData() {
    setLoading(true)
    try {
      const res = await counterAPI.getCounter(counterId, placeId)
      setEntity(res)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  async function updateCounter(data: CounterModel) {
    const msg = tr.counterOperationMessages

    setProcessing(true)

    try {
      await counterAPI.updateCounter(data)
      dispatch(showMessage(msg.updateSuccess, MessageTypes.Success))
      history.push(counter(placeId, counterId))
    } catch (err) {
      dispatch(showMessage(msg.updateError, MessageTypes.Error))
      setProcessing(false)
    }
  }

  async function createCounter(data: CounterModel & { shopId?: string | number }) {
    const msg = tr.counterOperationMessages

    setProcessing(true)

    try {
      await counterAPI.createCounter(data)
      dispatch(showMessage(msg.createSuccess, MessageTypes.Success))
      history.push(counters(placeId))
    } catch (err) {
      dispatch(showMessage(msg.createError, MessageTypes.Error))
      setProcessing(false)
    }
  }

  async function handleDelete(id: string | number) {
    const msg = tr.counterOperationMessages

    setProcessing(true)

    try {
      await counterAPI.deleteCounter(id)
      dispatch(showMessage(msg.deleteSuccess, MessageTypes.Success))
      history.push(counters(placeId))
    } catch (err) {
      dispatch(showMessage(msg.deleteError, MessageTypes.Error))
      setProcessing(false)
    }
  }

  function handleSave(data: CounterModel) {
    if (!counterId) {
      createCounter({ ...data, shopId: placeId })
    } else {
      updateCounter(data)
    }
  }

  if (loading) {
    return <PageLoader />
  }

  if (!entity || !entity.data) {
    return null
  }

  return (
    <Container>
      <CounterEdit
        data={entity.data}
        onSave={handleSave}
        onDelete={handleDelete}
        creation={!counterId}
        processing={processing}
      />
    </Container>
  )
}

export default SmartCounterEdit
