import { useMemo, useState } from 'react'
import { Form, FormGroup } from 'shared/ui-kit/form'
import { TextField } from 'shared/ui-kit/text-field'
import { Divider, Button } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import { AddList } from 'shared/ui-kit/add-list'
import { AddCounters } from './components/add-counters'
import Text from 'shared/ui-kit/text'
import Card from 'shared/ui-kit/card'
import Toggle from 'shared/ui-kit/toggle-wrap'
import TimePickerTimestamp from 'features/time/time-picker-timestamp'
import { isPositiveNumber } from 'shared/utils/string-test'
import { ZoneModel } from 'shared/models'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { ZoneEditProps } from '../types'
import { ActionText, ActionsWrapper, ButtonWithTextWrapper, ConfirmationActions } from 'shared/ui-kit/action-wrapper'
import DeleteIcon from '@mui/icons-material/Delete'

function ZoneEdit(props: ZoneEditProps) {
  const { tr } = useTranslation()

  const [isConfirmDelete, setConfirmDelete] = useState(false)

  const validationSchema = useMemo(() => Yup.object().shape({ name: Yup.string().required() }), [])

  const { control, handleSubmit, formState, watch } = useForm<ZoneModel>({
    resolver: yupResolver(validationSchema as any),
    defaultValues: props.data,
    mode: 'onChange'
  })

  function handleTryDelete() {
    setConfirmDelete(true)
  }

  function handleCancelDelete() {
    setConfirmDelete(false)
  }

  function handleConfirmDelete() {
    props.onDelete(props.data.id)
  }

  const {
    name,
    namePlaceholder,
    description,
    descriptionPlaceholder,
    gaugeManagement,
    displayOnMobileApp,
    monitoringStartHour,
    monitoringEndHour,
    maxVisitorsNumber,
    triggerAnAlert,
    visitorsNumberAlertTriggerOn,
    visitorsNumberAlertTriggerOff,
    emailAlertReceivers,
    smsAlertReceivers,
    intervalRepeatAlert,
    beacons,
    counters,
    gaugeParameters,
    timeHint,
    gaugeLink
  } = tr.zoneEdit

  const isValid = !Object.keys(formState.errors).length

  return (
    <form onSubmit={handleSubmit(props.onSave)}>
      <Card>
        <FormGroup>
          <Controller
            name="name"
            control={control}
            render={({ field: f, formState }) => (
              <TextField
                label={name}
                placeholder={namePlaceholder}
                autofocus={true}
                value={f.value}
                onChange={f.onChange}
                attention={!!formState.errors.name}
                message={`${f.value?.length || 0}/100 ${tr.entityEdit.chars}`}
                test={(v) => v.length < 101}
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="description"
            control={control}
            render={({ field: f }) => (
              <TextField
                label={description}
                placeholder={descriptionPlaceholder}
                value={f.value}
                onChange={f.onChange}
                message={`${f.value?.length || 0}/200 ${tr.entityEdit.chars}`}
                test={(v) => v.length < 201}
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="isGaugeManagementEnabled"
            control={control}
            render={({ field: f }) => (
              <Toggle toggled={f.value} label={gaugeManagement} onToggle={(_, val) => f.onChange(val)} />
            )}
          />
        </FormGroup>
        {watch('isGaugeManagementEnabled') && (
          <>
            <Divider />
            <Form expandable title={gaugeParameters}>
              <FormGroup>
                <Controller
                  name="isDisplayOnMobileApp"
                  control={control}
                  render={({ field: f }) => (
                    <Toggle toggled={f.value} label={displayOnMobileApp} onToggle={(_, val) => f.onChange(val)} />
                  )}
                />
              </FormGroup>
              <FormGroup style={{ display: 'flex', gap: '1rem' }}>
                <Controller
                  name="monitoringStartHour_unixtime"
                  control={control}
                  render={({ field: f }) => (
                    <TimePickerTimestamp
                      style={{ flex: 1 }}
                      label={monitoringStartHour}
                      value={f.value}
                      onChange={f.onChange}
                      offset
                    />
                  )}
                />
                <Controller
                  name="monitoringEndHour_unixtime"
                  control={control}
                  render={({ field: f }) => (
                    <TimePickerTimestamp
                      style={{ flex: 1 }}
                      label={monitoringEndHour}
                      value={f.value}
                      onChange={f.onChange}
                      offset
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Controller
                  name="maxVisitorsNumber"
                  control={control}
                  render={({ field: f }) => (
                    <TextField
                      label={maxVisitorsNumber}
                      placeholder={maxVisitorsNumber}
                      value={f.value}
                      onChange={f.onChange}
                      test={isPositiveNumber}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Controller
                  name="isTriggerAnAlertEnabled"
                  control={control}
                  render={({ field: f }) => (
                    <Toggle toggled={f.value} label={triggerAnAlert} onToggle={(_, val) => f.onChange(val)} />
                  )}
                />
              </FormGroup>
              {watch('isTriggerAnAlertEnabled') && (
                <>
                  <FormGroup>
                    <Controller
                      name="visitorsNumberAlertTriggerOn"
                      control={control}
                      render={({ field: f }) => (
                        <TextField
                          label={visitorsNumberAlertTriggerOn}
                          placeholder={visitorsNumberAlertTriggerOn}
                          value={f.value}
                          onChange={f.onChange}
                          test={isPositiveNumber}
                        />
                      )}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Controller
                      name="visitorsNumberAlertTriggerOff"
                      control={control}
                      render={({ field: f }) => (
                        <TextField
                          label={visitorsNumberAlertTriggerOff}
                          placeholder={visitorsNumberAlertTriggerOff}
                          value={f.value}
                          onChange={f.onChange}
                          test={isPositiveNumber}
                        />
                      )}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Controller
                      name="emailAlertReceivers"
                      control={control}
                      render={({ field: f }) => (
                        <TextField
                          label={emailAlertReceivers}
                          placeholder={emailAlertReceivers}
                          value={f.value}
                          onChange={f.onChange}
                          message={`${f.value?.length || 0}/1000 ${tr.entityEdit.chars}`}
                          test={(v) => v.length < 1001}
                        />
                      )}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Controller
                      name="smsAlertReceivers"
                      control={control}
                      render={({ field: f }) => (
                        <TextField
                          label={smsAlertReceivers}
                          placeholder={smsAlertReceivers}
                          value={f.value}
                          onChange={f.onChange}
                          message={`${f.value?.length || 0}/1000 ${tr.entityEdit.chars}`}
                          test={(v) => v.length < 1001}
                        />
                      )}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Controller
                      name="intervalRepeatAlertMin"
                      control={control}
                      render={({ field: f }) => (
                        <TextField
                          label={intervalRepeatAlert}
                          placeholder={intervalRepeatAlert}
                          value={f.value}
                          onChange={(val) => f.onChange(+val)}
                          test={isPositiveNumber}
                          hint={timeHint}
                        />
                      )}
                    />
                  </FormGroup>
                </>
              )}
              {!!watch('gaugeLink') && (
                <Form>
                  <FormGroup>
                    <Text type="caption">{gaugeLink}</Text>
                    <a href={watch('gaugeLink')} style={{ fontSize: '13px' }}>
                      {watch('gaugeLink')}
                    </a>
                  </FormGroup>
                </Form>
              )}
            </Form>
          </>
        )}
        {props.showBeacon && (
          <>
            <Divider />
            <Form expandable title={beacons}>
              <FormGroup>
                <Controller
                  name="beaconIds"
                  control={control}
                  render={({ field: f }) => (
                    <AddList
                      selectedIds={f.value}
                      source={props.beacons}
                      addButtonText={tr.zoneEdit.addBeaconButton}
                      onChange={f.onChange}
                      dropdownDataConverter={(item) => ({
                        value: item.id,
                        text: item.name,
                        secondary: `${item.major}/${item.minor}`
                      })}
                      listDataConverter={(item) => ({
                        id: item.id,
                        primaryText: (
                          <div>
                            {item.name}
                            <Text size={2} fade={2} style={{ display: 'inline-block', marginLeft: 16 }}>
                              {item.major}/{item.minor}
                            </Text>
                          </div>
                        )
                      })}
                    />
                  )}
                />
              </FormGroup>
            </Form>
          </>
        )}
        {!!props.data.showCounters && (
          <>
            <Divider />
            <Form expandable title={counters}>
              <Controller
                name="counters"
                control={control}
                render={({ field: f }) => (
                  <AddCounters counters={props.counters} selectedCounters={f.value} onChange={f.onChange} />
                )}
              />
            </Form>
          </>
        )}
        {!!props.data.id && (
          <>
            <Divider />
            <Form title={tr.entityEdit.advancedGroup} expandable>
              <FormGroup>
                {!isConfirmDelete && (
                  <ButtonWithTextWrapper>
                    <ActionText>{tr.zoneEdit.deleteTitle}</ActionText>
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={handleTryDelete}
                      startIcon={<DeleteIcon />}
                      disabled={props.processing}
                    >
                      {tr.entityEdit.deleteButton}
                    </Button>
                  </ButtonWithTextWrapper>
                )}
                {isConfirmDelete && (
                  <ButtonWithTextWrapper>
                    <ActionText>{tr.entityEdit.confirmDelete}</ActionText>
                    <ConfirmationActions>
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        disabled={props.processing}
                        onClick={handleConfirmDelete}
                      >
                        {tr.entityEdit.yes}
                      </Button>
                      <Button variant="outlined" size="small" disabled={props.processing} onClick={handleCancelDelete}>
                        {tr.entityEdit.no}
                      </Button>
                    </ConfirmationActions>
                  </ButtonWithTextWrapper>
                )}
              </FormGroup>
            </Form>
          </>
        )}
      </Card>
      <ActionsWrapper>
        <Button disabled={!isValid || props.processing} variant="contained" color="primary" size="small" type="submit">
          {props.data.id ? tr.entityEdit.updateButton : tr.entityEdit.createButton}
        </Button>
      </ActionsWrapper>
    </form>
  )
}

export { ZoneEdit }
