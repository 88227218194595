import { CSSProperties, useState, useEffect } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { Button, TextField, IconButton, Autocomplete, CircularProgress } from '@mui/material'
import { ShowChildren } from './show-children'
import { line as lineApi } from 'shared/api'
import { Add as AddIcon, Warning as WarningIcon } from '@mui/icons-material'
import { LineModelSimplified } from 'shared/models'

type Props = {
  placeId: string | number
  filterItem?: (item: LineModelSimplified) => boolean
  onChange?: (item: LineModelSimplified) => void
  style?: CSSProperties
  label?: string
  placeholder?: string
}

function LineSelect(porps: Props) {
  const { placeId, filterItem, onChange, style, label, placeholder } = porps
  const [lineList, setLineList] = useState<LineModelSimplified[] | null>(null)
  const [value, setValue] = useState<LineModelSimplified | null>(null)
  const { tr } = useTranslation()

  function fetchLineList(placeId: string | number) {
    lineApi.getLineListSimplified(placeId).then((resp) => {
      setLineList(resp.data)
    })
  }

  const allOptions = lineList ? (filterItem ? lineList.filter(filterItem) : lineList) : []

  useEffect(() => {
    allOptions.length && !value && setValue(allOptions[0])
  }, [allOptions])

  return (
    <ShowChildren
      style={style}
      renderButton={(setShow) => (
        <Button
          variant="outlined"
          size="small"
          startIcon={<AddIcon />}
          onClick={() => {
            setShow(true)
            fetchLineList(placeId)
          }}
        >
          {tr.tagEdit.addLine}
        </Button>
      )}
    >
      {!lineList && <CircularProgress size="24px" />}
      {lineList && (
        <>
          {!allOptions.length && (
            <Button variant="outlined" size="small" disabled startIcon={<WarningIcon />}>
              {tr.tagEdit.noMoreLineToAdd}
            </Button>
          )}
          {!!allOptions.length && (
            <div style={{ display: 'flex', gap: '1rem', alignItems: 'flex-end' }}>
              <Autocomplete
                style={{ flex: '1' }}
                value={value}
                fullWidth
                filterSelectedOptions
                noOptionsText={tr.tagEdit.noParticipant}
                onChange={(ev, val) => setValue(val)}
                options={allOptions}
                getOptionLabel={(opt) => opt.name}
                isOptionEqualToValue={(opt, val) => opt.id === val.id}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" label={label} placeholder={placeholder} />
                )}
              />
              <IconButton
                disabled={!value}
                size="small"
                onClick={() => {
                  if (value) {
                    onChange?.(value)
                    setValue(null)
                  }
                }}
              >
                <AddIcon />
              </IconButton>
            </div>
          )}
        </>
      )}
    </ShowChildren>
  )
}

export { LineSelect }
