import { Divider } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import { PositionState } from 'shared/models'
import PositionActionComponent from '../../components/position-action'
import { SetInLineHereProps } from './types'
import { WhereToVote } from '@mui/icons-material'

export function SetInLineHere(props: SetInLineHereProps) {
  const { tr } = useTranslation()

  function handleSubmit() {
    props.handleChangeState(PositionState.InLineHere, { skipForms: true })
  }

  return (
    <>
      {!props.isCardView && <Divider />}
      <PositionActionComponent
        isCardView={props.isCardView}
        confirmation={true}
        confirmationMessage={
          props.checkpointIsDisabled
            ? tr.positionActions.requestToInLineHereConfirmCheckpointClosed
            : tr.positionActions.requestToInLineHereConfirm
        }
        onSubmit={handleSubmit}
        disabled={!!props.disabled}
        icon={<WhereToVote fontSize="small" />}
      >
        {props.monitoringPositionMenuItemCaptions?.isHere || tr.positionActions.requestToInLineHere}
      </PositionActionComponent>
    </>
  )
}
