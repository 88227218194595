import { Divider, Typography } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import { PositionState, PositionType } from 'shared/models'
import PositionActionComponent from '../../components/position-action'
import { ValidateForServiceActionProps } from './types'
import { useAppSelector } from 'store'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { Checklist } from 'features/checklist'
import { Button } from '@mui/material'
import { useState } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { ActionFormActions } from '../styled'

function ValidateForService(props: ValidateForServiceActionProps) {
  const { tr } = useTranslation()
  const { canValidatePositions } = useAppSelector(getPermissionsSelector)

  const [checkListSelectedItems, setCheckListSelectedItems] = useState<string[]>([])

  function handlePositionValidate() {
    const canShowChecklist =
      !!props.position?.services?.length &&
      !!props.position.services.filter((service) => !!service?.checkList?.length).length

    if (!props.validateMode && canShowChecklist) {
      props.onValidateMode?.()
      return
    } else {
      props.onFinish?.()
    }

    props.onPositionValidate?.(checkListSelectedItems)
  }

  function handleSubmit() {
    if (!props.validateMode) {
      props.onBack?.()
    } else {
      handlePositionValidate()
    }
  }

  function handleCheckElement(key: string, value: boolean) {
    let newValues = [...(checkListSelectedItems || [])]

    if (!value) {
      newValues = newValues.filter((x) => x !== key)
    } else if (value && !newValues.includes(key)) {
      newValues.push(key)
    }

    setCheckListSelectedItems(newValues)
  }

  const disabledCheckList = !!(props.position?.services || []).filter(
    (service) =>
      !!service?.checkList?.filter((item) => !!item.mandatory && !checkListSelectedItems.includes(item.shortName))
        .length
  ).length

  if (!canValidatePositions || props.position.type === PositionType.Break) {
    return null
  }

  return (
    <>
      {!props.validateMode && (
        <>
          {!props.isCardView && <Divider />}
          <PositionActionComponent
            isCardView={props.isCardView}
            confirmation={false}
            confirmationMessage=""
            onSubmit={handlePositionValidate}
            disabled={!!props.position.isValid || props.position.state === PositionState.InService || props.disabled}
            icon={<CheckCircleIcon fontSize="small" />}
          >
            {props.monitoringPositionMenuItemCaptions?.validForService || tr.positionActions.isValid}
          </PositionActionComponent>
        </>
      )}
      {!!props.validateMode && (
        <div>
          <Typography variant="body1" fontWeight={500} fontSize="18px">
            {props.monitoringPositionMenuItemCaptions?.validForService || tr.positionActions.isValid}
          </Typography>
          <form onSubmit={handleSubmit} style={{ marginTop: '0.5rem' }}>
            <Checklist
              services={props.position?.services || []}
              checkListSelectedItems={checkListSelectedItems || []}
              onCheckElement={handleCheckElement}
            />
            <ActionFormActions $marginTop={props.isCardView ? '2rem' : '1rem'}>
              <Button size="small" variant="contained" color="primary" type="submit" disabled={disabledCheckList}>
                {tr.finishServiceForm.confirm}
              </Button>
              <Button size="small" onClick={props.onBack}>
                {tr.entityEdit.cancelButton}
              </Button>
            </ActionFormActions>
          </form>
        </div>
      )}
    </>
  )
}

export { ValidateForService }
