import styled from '@emotion/styled'

const FileContainer = styled.div<{ $notOutlined?: boolean }>`
  display: flex;
  align-items: center;
  height: 36px;
  padding: ${(props) => (props.$notOutlined ? '0' : '0 8px')};
  border-radius: ${(props) => (props.$notOutlined ? 'none' : '2px')};
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  ${(props) => !props.$notOutlined && { border: '1px solid rgba(0, 0, 0, 0.15)' }}
  ${(props) => props.$notOutlined && { borderBottom: '1px solid rgba(0, 0, 0, 0.15)' }}
  &:hover {
    cursor: pointer;
    background: ${(props) => (props.$notOutlined ? 'unset' : 'rgba(153, 153, 153, 0.15)')};
  }
`

const Label = styled.div<{ $notOutlined?: boolean }>`
  margin: ${(props) => (props.$notOutlined ? '0' : '0 0 0.25rem')};
  display: flex;
`

const ImageName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 8px;
  flex: 1;
`

const Attention = styled.div`
  background: #ef5350;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-top: 4px;
  margin-left: 4px;
`

const ErrorMessage = styled.div`
  font-size: 12px;
  color: #ef5350;
  margin-top: 2px;
`

const Message = styled.div`
  font-size: 12px;
  color: #bdbdbd;
  margin-top: 2px;
`

export { FileContainer, Label, ImageName, Attention, ErrorMessage, Message }
