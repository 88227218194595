import css from './link.module.scss'
import { LinkProps } from './types'

function Link(props: LinkProps) {
  function handleClick() {
    props.onClick?.()
  }

  return (
    <a
      href={props.href}
      target={props.target}
      className={props.className ? `${css.root} ${props.className}` : css.root}
      style={props.style}
      onClick={handleClick}
    >
      {props.children}
    </a>
  )
}

export default Link
