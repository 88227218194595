import { useEffect, useState } from 'react'
import { Form, FormGroup } from 'shared/ui-kit/form'
import { useTranslation } from 'shared/i18n/translate'
import Card from 'shared/ui-kit/card'
import Dropdown from 'shared/ui-kit/dropdown'
import Text from 'shared/ui-kit/text'
import {
  Button,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItemText,
  Typography,
  Box
} from '@mui/material'
import { HelpOutline as HelpIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import { addTestAttr } from 'shared/utils/test-attr'
import { TooltipStyled } from 'features/element-with-helper/styled'
import { HelpContainer, MessageTemplateFormRoot } from './styled'
import { MessageTemplatesFiltersProps } from './types'

function MessageTemplatesFilters(props: MessageTemplatesFiltersProps) {
  const { tr } = useTranslation()

  const [expanded, setExpanded] = useState<number | false>(false)
  const [itemMessageTemplateSelected, setItemMessageTemplateSelected] = useState<string | null>(null)

  useEffect(() => {
    setItemMessageTemplateSelected(props.messageType || null)

    const selectedMessageTypeGroup = props.config.messageTypes.find((mt) =>
      mt.items.find((it) => it.value === props.messageType)
    )

    setExpanded(selectedMessageTypeGroup ? props.config.messageTypes.indexOf(selectedMessageTypeGroup) : false)
  }, [props.messageType])

  function handleHelpOpen() {
    window.open(`/docs/${tr.messageTemplates.helpLink}`, '_blank')
  }

  function handleAccordionItemClick(value: string) {
    return () => {
      setItemMessageTemplateSelected(value)
      props.onMessageTypeChange(value)
    }
  }

  return (
    <MessageTemplateFormRoot>
      <Card style={{ flex: 1, margin: 0 }}>
        <HelpContainer>
          <Button onClick={handleHelpOpen} endIcon={<HelpIcon />}>
            {tr.messageTemplates.help}
          </Button>
        </HelpContainer>
        <Form>
          <FormGroup>
            <Dropdown
              value={props.language}
              data={props.config.languages}
              label={tr.messageTemplates.language}
              dataConverter={(item) => ({ value: item.value, text: item.name })}
              onChange={props.onLanguageChange}
              {...addTestAttr('MessageTemplates-Language')}
            />
          </FormGroup>
          <FormGroup>
            <Text type="caption">{tr.messageTemplates.messageType}</Text>
            {props.config.messageTypes.map(({ name, items, description }, key) => (
              <Accordion
                key={key}
                expanded={expanded === key}
                onChange={(_, isExpanded: boolean) => setExpanded(isExpanded ? key : false)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ height: '4rem' }}>
                  <Typography style={{ fontSize: '13px', fontWeight: '100' }}>{name}</Typography>
                  {description && (
                    <TooltipStyled
                      arrow
                      title={description}
                      leaveTouchDelay={3000}
                      enterTouchDelay={0}
                      onTouchStart={(event) => event.persist()}
                      onTouchStartCapture={(event) => event.persist()}
                    >
                      <HelpIcon fontSize="small" style={{ opacity: 0.56, marginLeft: '0.5rem' }} />
                    </TooltipStyled>
                  )}
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: 'column', padding: '0' }}>
                  <List>
                    {items.map(({ value, name, description }, key) => (
                      <MenuItem
                        key={key}
                        selected={itemMessageTemplateSelected === value}
                        onClick={handleAccordionItemClick(value)}
                      >
                        <ListItemText primary={name} style={{ whiteSpace: 'normal' }} />
                        {description && (
                          <TooltipStyled
                            arrow
                            title={description}
                            leaveTouchDelay={3000}
                            enterTouchDelay={0}
                            onTouchStart={(event) => event.persist()}
                            onTouchStartCapture={(event) => event.persist()}
                          >
                            <HelpIcon fontSize="small" style={{ opacity: 0.56, marginLeft: '0.5rem' }} />
                          </TooltipStyled>
                        )}
                      </MenuItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}
          </FormGroup>
        </Form>
      </Card>
      <Box minWidth="340px" flex={1}>
        {props.children}
      </Box>
    </MessageTemplateFormRoot>
  )
}

export { MessageTemplatesFilters }
