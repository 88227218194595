import { useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import Autocomplete from 'shared/ui-kit/autocomplete'
import { DatePicker } from 'features/date'
import { IconButton } from '@mui/material'
import { Close as CloseIcon, FilterList as FilterIcon } from '@mui/icons-material'
import { Button } from '@mui/material'
import { LayoutProps } from './types'
import { BarStyled, FilterActions, FilterItemSmall } from './styled'
import { isPositiveInteger } from 'shared/utils/string-test'
import { TextField } from 'shared/ui-kit/text-field'
import MUIAutocomplete from '@mui/material/Autocomplete'
import MUITextField from '@mui/material/TextField'
import { EventFlowAction } from 'shared/models'

function EventFlowSmallLayout(props: LayoutProps) {
  const [open, setOpen] = useState(false)

  const { tr } = useTranslation()

  function onSearch(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    props.handleSubmit()
    setOpen(false)
  }

  const { shop, line } = tr.common

  return (
    <form onSubmit={onSearch} style={{ padding: '0 0 0.5rem', width: '100%', boxSizing: 'border-box' }}>
      <BarStyled>
        <div style={{ display: 'flex', height: 64, alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
          <FilterActions $isSmall>
            <Button type="submit" variant="contained" size="small" color="primary">
              {tr.journal.search}
            </Button>
          </FilterActions>
          <IconButton
            onClick={() => {
              window.scrollTo(0, 0)
              setOpen(!open)
            }}
          >
            {open ? <CloseIcon /> : <FilterIcon />}
          </IconButton>
        </div>
        {open && (
          <>
            <FilterItemSmall>
              <Autocomplete
                fullWidth
                title={shop}
                value={props.filter.shopId || null}
                list={props.shops || []}
                style={{ textOverflow: 'ellipsis' }}
                dataConverter={(el) => ({ text: el.name, value: el.id })}
                onChange={(shopId) => props.onShopChenge(shopId || undefined)}
              />
            </FilterItemSmall>
            {!!props.lines?.length && (
              <FilterItemSmall>
                <Autocomplete
                  fullWidth
                  title={line}
                  value={props.filter.lineId || null}
                  list={props.lines}
                  style={{ textOverflow: 'ellipsis' }}
                  dataConverter={(el) => ({ text: el.name, value: el.id })}
                  onChange={(lineId) => props.onFilterChange('lineId', lineId || undefined)}
                />
              </FilterItemSmall>
            )}
            <FilterItemSmall>
              <DatePicker
                label={tr.reportsFilter.startDate}
                style={{ marginBottom: 0, width: '100%' }}
                onChange={(date) => {
                  const isStartDateMoreEndDate = date > (props.filter.endDate || 0)

                  if (isStartDateMoreEndDate) {
                    props.onFilterChange('startDate', date)
                    props.onFilterChange('endDate', date)
                  } else {
                    props.onFilterChange('startDate', date)
                  }
                }}
                value={props.filter.startDate}
              />
            </FilterItemSmall>
            <FilterItemSmall>
              <DatePicker
                label={tr.reportsFilter.endDate}
                style={{ marginBottom: 0, width: '100%' }}
                onChange={(date) => {
                  const isEndDateLessStartDate = date < (props.filter.startDate || 0)

                  if (isEndDateLessStartDate) {
                    props.onFilterChange('endDate', date)
                    props.onFilterChange('startDate', date)
                  } else {
                    props.onFilterChange('endDate', date)
                  }
                }}
                value={props.filter.endDate}
              />
            </FilterItemSmall>
            {!!props.users?.length && (
              <FilterItemSmall>
                <MUIAutocomplete
                  multiple
                  options={props.users || []}
                  value={(props.filter.operatorIds || []).map((el) =>
                    (props.users || []).find((user) => user.id === el)
                  )}
                  getOptionLabel={(item) => [item?.firstName || '', item?.secondName || '', `(${item?.id})`].join(' ')}
                  onChange={(_, value) =>
                    props.onFilterChange('operatorIds', value.map((el) => el?.id) as (string | number)[])
                  }
                  size="small"
                  renderInput={(params) => (
                    <MUITextField
                      {...params}
                      variant="standard"
                      label={tr.reportsFilter.users}
                      placeholder={tr.reportsFilter.users}
                    />
                  )}
                />
              </FilterItemSmall>
            )}
            <FilterItemSmall>
              <MUIAutocomplete
                multiple
                options={((Object.values(EventFlowAction) || []) as string[]).sort((a, b) => {
                  const first = tr.eventFlowActionName[a] || ''
                  const second = tr.eventFlowActionName[b] || ''

                  if (first > second) {
                    return 1
                  }
                  if (first < second) {
                    return -1
                  }
                  return 0
                })}
                value={props.filter.actions || []}
                getOptionLabel={(item) => tr.eventFlowActionName[item]}
                onChange={(_, value) => props.onFilterChange('actions', value)}
                size="small"
                renderInput={(params) => (
                  <MUITextField
                    {...params}
                    variant="standard"
                    label={tr.reportsFilter.actions}
                    placeholder={tr.reportsFilter.actions}
                  />
                )}
              />
            </FilterItemSmall>
            <FilterItemSmall>
              <TextField
                label={tr.reportsFilter.customerId}
                placeholder={tr.reportsFilter.customerId}
                value={props.filter.customerId || ''}
                onChange={(el) => props.onFilterChange('customerId', el)}
                test={isPositiveInteger}
              />
            </FilterItemSmall>
            <FilterItemSmall>
              <TextField
                label={tr.reportsFilter.positionId}
                placeholder={tr.reportsFilter.positionId}
                value={props.filter.positionId || ''}
                onChange={(el) => props.onFilterChange('positionId', el)}
                test={isPositiveInteger}
              />
            </FilterItemSmall>
            <FilterActions style={{ padding: '1rem 0.5rem' }}>
              <Button type="submit" variant="contained" size="small" color="primary">
                {tr.journal.search}
              </Button>
              <Button size="small" variant="text" color="primary" onClick={props.onClear}>
                {tr.lineMonitoring.reset}
              </Button>
            </FilterActions>
          </>
        )}
      </BarStyled>
      <div style={{ marginTop: '1rem', padding: '0 0.5rem', marginBottom: '0.5rem' }}>{props.children}</div>
    </form>
  )
}

export { EventFlowSmallLayout }
