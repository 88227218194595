import { IconButton, Typography } from '@mui/material'
import { QRContainer, QRWrapper, QRActions, QrMask, ErrorMask } from './styled'
import { Close as CloseIcon, Error as ErrorIcon } from '@mui/icons-material'
import { ScanIcon } from './scan-icon'
import { useTranslation } from 'shared/i18n/translate'
import { useEffect, useRef, useState, lazy, Suspense } from 'react'
import { OnResultFunction } from 'shared/utils/qr-reader/types'

const QrReader = lazy(() => import('shared/utils/qr-reader'))

export type ValidateState = 'readyToService' | 'timeDeviation' | 'notFound' | 'findingInJournal'

type Props = {
  active: boolean
  onSelectPosition: (positionId: string) => ValidateState | undefined
  onClose: () => void
}

const errorVibrationInterval = [100, 30, 100, 30, 100]
const successVibrationInterval = [300]
const warningVibrationInterval = [100, 30, 600]

function QRScanner(props: Props) {
  const { tr } = useTranslation()
  const [fullScreen, setFullScreen] = useState(true)
  const lastScan = useRef('')

  const audioCompleteRef = useRef<any>()
  const audioScanErrorRef = useRef<any>()
  const audioTimeDeviation = useRef<any>()

  const qrFormatMessageRef = useRef<HTMLDivElement>(null)
  const notFoundMessageRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setFullScreen(true)
  }, [props.active])

  const handleQrResult: OnResultFunction = async (text) => {
    if (text) {
      const parts = text?.split('/') || []
      const fullCode = parts[parts.length - 1] || ''
      const codeParts = fullCode.split('-')

      if (codeParts.length === 4 && lastScan.current !== codeParts[2]) {
        const positionId = codeParts[2]
        lastScan.current = positionId
        const validateState = await props.onSelectPosition(positionId)

        if (validateState) {
          playValidateSound(validateState)
        }
      } else if (codeParts.length !== 4) {
        handleQRFormatError()
      }
    }
  }

  function handleQRFormatError() {
    audioScanErrorRef.current.play()
    navigator?.vibrate?.(errorVibrationInterval)

    if (qrFormatMessageRef.current) {
      qrFormatMessageRef.current.style.opacity = '1'
      setTimeout(() => {
        if (qrFormatMessageRef.current) {
          qrFormatMessageRef.current.style.opacity = '0'
        }
      }, 5000)
    }
  }

  function handleNotFound() {
    navigator?.vibrate?.(errorVibrationInterval)
    audioScanErrorRef.current.play()

    if (notFoundMessageRef.current) {
      notFoundMessageRef.current.style.opacity = '1'
      setTimeout(() => {
        if (notFoundMessageRef.current) {
          notFoundMessageRef.current.style.opacity = '0'
        }
      }, 5000)
    }
  }

  function playValidateSound(validateState: ValidateState) {
    switch (validateState) {
      case 'notFound':
        handleNotFound()
        break
      case 'readyToService':
        navigator?.vibrate?.(successVibrationInterval)
        audioCompleteRef.current.play()
        setFullScreen(false)
        break
      case 'timeDeviation':
        navigator?.vibrate?.(successVibrationInterval)
        audioTimeDeviation.current.play()
        setFullScreen(false)
        break
      case 'findingInJournal':
        navigator?.vibrate?.(warningVibrationInterval)
        audioScanErrorRef.current.play()
        setFullScreen(false)
        break
    }
  }

  function handleFullScreen() {
    setFullScreen((curr) => !curr)
    lastScan.current = ''
  }

  if (!props.active) {
    return null
  }

  return (
    <QRContainer fullScreen={fullScreen}>
      <ErrorMask ref={qrFormatMessageRef} style={{ opacity: '0' }}>
        <ErrorIcon fontSize="large" color="error" style={{ width: '82px', height: '82px' }} />
        <Typography color="error">{tr.qr.scanError}</Typography>
      </ErrorMask>
      <ErrorMask ref={notFoundMessageRef} style={{ opacity: '0' }}>
        <ErrorIcon fontSize="large" color="error" style={{ width: '82px', height: '82px' }} />
        <Typography color="error">{tr.qr.positionNotFound}</Typography>
      </ErrorMask>
      <audio src="/sounds/quack.wav" ref={audioCompleteRef} />
      <audio src="/sounds/scan_error.wav" ref={audioScanErrorRef} />
      <audio src="/sounds/time_deviation.wav" ref={audioTimeDeviation} />
      <QrMask onClick={handleFullScreen}>
        <ScanIcon width={150} height={150} color="#000" />
      </QrMask>
      <QRWrapper onClick={handleFullScreen}>
        <Suspense fallback={null}>
          <QrReader onResult={handleQrResult} />
        </Suspense>
      </QRWrapper>
      <QRActions onClick={handleFullScreen}>
        <IconButton size="small" onClick={props.onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </QRActions>
    </QRContainer>
  )
}

export { QRScanner }
