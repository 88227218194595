import { FC, CSSProperties, useState, useEffect } from 'react'
import { addOffsetToStamp, offsetToStamp } from 'shared/i18n/date-formatter'
import { TimePicker as ClickTimePicker } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import { useAppSelector } from 'store'
import { getTimeZoneSelector } from 'store/selectors/timeZoneSelectors'

interface ITimePickerProps {
  offset?: boolean
  onChange?: (data: number | null) => void
  value?: number
  fullWidth?: boolean
  id?: string
  style?: CSSProperties
  disabled?: boolean
  label?: string
  attention?: boolean
  canBeEmpty?: boolean
}

const TimePicker: FC<ITimePickerProps> = (props) => {
  const timeZone = useAppSelector(getTimeZoneSelector)

  const [val, setValue] = useState<Dayjs | null>(null)

  useEffect(() => {
    setValue(dayjs(getValue()))
  }, [props.value])

  function handleChange(date: dayjs.Dayjs | null) {
    setValue(date)

    const dateU = date?.toDate()
    const offset = props.offset ? timeZone.offset : 0
    props.onChange?.(dateU ? offsetToStamp(dateU.getTime(), offset) : null)
  }

  function getValue() {
    const offset = props.offset ? timeZone.offset : 0

    if (props.value === null || props.value === undefined) {
      return null
    }

    return addOffsetToStamp(props.value, offset)
  }

  return (
    <ClickTimePicker
      ampm={false}
      disabled={props.disabled}
      label={
        (props.label || props.attention) && (
          <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
            {props.label}
            {props.attention && (
              <div
                style={{
                  background: '#ef5350',
                  width: '6px',
                  height: '6px',
                  borderRadius: '50%',
                  marginTop: '4px',
                  marginLeft: '4px'
                }}
              />
            )}
          </div>
        )
      }
      slotProps={{
        textField: {
          style: props.style,
          id: props.id,
          fullWidth: props.fullWidth,
          error: props.canBeEmpty ? false : props.attention,
          InputLabelProps: { shrink: true }
        }
      }}
      value={val}
      onChange={handleChange}
    />
  )
}

export default TimePicker
