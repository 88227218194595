import { ServiceModel } from 'shared/models'
import { FormGroup } from 'shared/ui-kit/form'
import { Button } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import { useTranslation } from 'shared/i18n/translate'
import { ServiceDndItem } from './item'
import { reorder } from './utils/reorder'

type Props = {
  services: ServiceModel[]
  brandId?: string
  freeTimeslotsProgressively?: boolean
  onServicesChanged: (services: ServiceModel[]) => void
  onAddService: () => void
  requestParticipantsNumber?: boolean
  maxSimultaneous?: number
  shopId?: string
}

export function ServiceList(props: Props) {
  const { tr } = useTranslation()

  const { addService } = tr.lineEdit

  function handleServiceChange(i: number) {
    return (service: ServiceModel) => {
      const newServices = [...props.services]
      newServices[i] = service
      props.onServicesChanged(newServices)
    }
  }

  function handleRemoveService(i: number) {
    return () => {
      const newServices = [...props.services]
      newServices.splice(i, 1)
      props.onServicesChanged(newServices)
    }
  }

  function handleMoveCard(dragIndex: number, hoverIndex: number) {
    props.onServicesChanged(reorder(props.services, dragIndex, hoverIndex))
  }

  return (
    <>
      <FormGroup style={{ marginTop: 0 }}>
        {props.services.map((service, i) => (
          <ServiceDndItem
            handleRemoveService={handleRemoveService(i)}
            handleServiceChange={handleServiceChange(i)}
            id={service.id || service.newId || 'without_id'}
            brandId={props.brandId}
            index={i}
            service={service}
            freeTimeslotsProgressively={props.freeTimeslotsProgressively}
            key={service.id || service.newId || 'without_id'}
            moveCard={handleMoveCard}
            requestParticipantsNumber={props.requestParticipantsNumber}
            maxSimultaneous={props.maxSimultaneous}
            shopId={props.shopId}
          />
        ))}
      </FormGroup>
      <FormGroup>
        <div style={{ marginLeft: -8 }}>
          <Button startIcon={<AddIcon />} onClick={props.onAddService}>
            {addService}
          </Button>
        </div>
      </FormGroup>
    </>
  )
}
