import { useEffect, useState } from 'react'
import { editTerminal } from 'pages/nav'
import { TerminalInventory } from './dumb/terminal-inventory'
import { TerminalView } from './dumb/terminal-view'
import { useTranslation } from 'shared/i18n/translate'
import { terminal } from 'shared/api'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { Button, CircularProgress } from '@mui/material'
import Card from 'shared/ui-kit/card'
import EntityActions from 'shared/ui-kit/entity-actions'
import ModeEdit from '@mui/icons-material/Edit'
import { addTestAttr } from 'shared/utils/test-attr'
import { CenterContainer } from 'shared/ui-kit/center-container'
import { useHistory, useParams } from 'react-router'
import { ParentPlaceModel, TerminalModel } from 'shared/models'
import Container from 'shared/ui-kit/container'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { useTitle } from 'shared/hooks/useTitle'
import { useDataProvider } from 'features/isolated-data-provider'
import { useAppDispatch, useAppSelector } from 'store'
import { useTimeZoneFilter } from 'features/time-zone-filter'

function SmartTerminalView() {
  const { canUpdateShops } = useAppSelector(getPermissionsSelector)
  const dispatch = useAppDispatch()
  const { tr } = useTranslation()
  const history = useHistory()
  const params = useParams<{ terminalId: string; placeId: string }>()

  useTimeZoneFilter(params.placeId)

  const [entity, setTerminal] = useState<TerminalModel | null>(null)
  const [fetching, setFetching] = useState<boolean>(false)
  const [tickets, setTickets] = useState(0)

  const { getPlace } = useDataProvider()

  useTitle({ placeName: getPlace(params.placeId)?.name })

  useEffect(() => {
    fetchTerminal()
  }, [])

  async function fetchTerminal() {
    setFetching(true)

    try {
      const resp = await terminal.getTerminal(params.placeId, params.terminalId)

      if (!resp.data) {
        return
      }

      setTerminal(resp.data)
      setTickets(resp.data.tickets || 0)
      setCrumbs(resp.data, resp.parents)
    } finally {
      setFetching(false)
    }
  }

  function setCrumbs(data: TerminalModel, parents: ParentPlaceModel) {
    const crumb = getCrumbs(tr.breadcrumbs)

    dispatch(
      setBreadcrumbs([
        crumb.home(),
        crumb.places(),
        crumb.place([params.placeId], String(parents?.shop?.name || '')),
        crumb.terminals([params.placeId]),
        crumb.terminal([String(data?.id), params.placeId], data?.name)
      ])
    )
  }

  function handleTickets(quantity) {
    terminal.setTerminalTicketsQuantity({ terminalId: params.terminalId, quantity }).then(() => setTickets(quantity))
  }

  if (fetching || entity === null) {
    return (
      <CenterContainer>
        <CircularProgress color="secondary" />
      </CenterContainer>
    )
  }

  return (
    <Container>
      <Card>
        <TerminalView data={entity} />
        <EntityActions
          showButtons={!!canUpdateShops}
          buttons={
            <Button
              {...addTestAttr('TerminalView-EditButton')}
              onClick={() => {
                history.push(editTerminal(params.terminalId, params.placeId))
              }}
              startIcon={<ModeEdit />}
            >
              {tr.entityView.editButton}
            </Button>
          }
        />
      </Card>
      {(entity?.type === 'Kiosk' || entity?.type === 'WebKiosk') && (
        <div>
          {entity?.hasThermalPrinter && (
            <TerminalInventory
              onFull={() => handleTickets(entity?.maxTickets || 0)}
              onEmpty={() => handleTickets(0)}
              onSet={(data) => handleTickets(+data.quantity)}
              text={tr.terminalView.tickets(tickets, entity?.maxTickets)}
              warning={tickets < entity?.minTicketsLevelNotification}
            />
          )}
        </div>
      )}
    </Container>
  )
}

export default SmartTerminalView
