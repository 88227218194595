import { Form } from 'shared/ui-kit/form'
import { useTranslation } from 'shared/i18n/translate'
import { Controller } from 'react-hook-form'
import { GroupProps } from '../types'
import { EmptyMessage } from 'shared/ui-kit/empty-message'
import { AdditionalsListDnd } from 'features/additionals-list-dnd'
import Card from 'shared/ui-kit/card'
import { Additional } from 'shared/models'

const newAdditionalField: Additional = {
  shortName: '',
  label: '',
  qrRegexp: '',
  newId: Date.now(),
  isAllServices: true
}

function AdditionalFieldsGroup({ control, watch, brandId, shopId }: GroupProps) {
  const { tr } = useTranslation()

  const { additionalsEmpty } = tr.lineEdit

  return (
    <Card paperStyle={{ overflow: 'hidden' }}>
      <Form>
        {!watch('additionals')?.length && <EmptyMessage>{additionalsEmpty}</EmptyMessage>}
        <Controller
          name="additionals"
          control={control}
          render={({ field: f }) => (
            <AdditionalsListDnd
              additionals={f.value || []}
              brandId={brandId}
              onAdditionalsChange={f.onChange}
              placeId={shopId}
              onAddAdditional={() => f.onChange([...(f.value || []), { ...newAdditionalField, newId: -Date.now() }])}
              services={watch('services')}
              requestParticipantsNumber={watch('requestParticipantsNumber')}
              asapSupported={watch('asapMode')}
            />
          )}
        />
      </Form>
    </Card>
  )
}

export { AdditionalFieldsGroup }
