import axios from 'axios'
import { getUrl } from './helpers'
import { FileAPI } from '../models'

const file: FileAPI = {
  upload(formData, params, onUploadProgress, config) {
    return axios.post('uploadFile', formData, { params, onUploadProgress, ...config })
  },
  download(id) {
    window.open(getUrl('downloadFile', { id }), '_blank')
  }
}

export default file
