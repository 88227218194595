import { useEffect, useRef, useState } from 'react'
import { useTimeZoneFilter } from 'features/time-zone-filter'
import { useTranslation } from 'shared/i18n/translate'
import { useHistory, useLocation, useParams } from 'react-router'
import { checkpoint as checkpointApi, line as lineApi } from 'shared/api'
import { setBreadcrumbs, showMessage, MessageTypes } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { checkpoints as checkpointsNav } from 'pages/nav'
import isEqual from 'lodash/isEqual'
import { CheckpointHostEditModel, GetResponseType, LineModel } from 'shared/models'
import PageLoader from 'shared/ui-kit/page-loader'
import { HostEdit } from './checkpoint-host-edit/checkpoint-host-edit'
import Container from 'shared/ui-kit/container'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { useAppDispatch, useAppSelector } from 'store'

type ParentsType = GetResponseType<CheckpointHostEditModel>['parents']

function CheckpointHostEdit() {
  const dispatch = useAppDispatch()
  const { canOpenCheckpoints, canCloseCheckpoints } = useAppSelector(getPermissionsSelector)
  const { tr, locale } = useTranslation()
  const history = useHistory<{ navTo?: string; fromCheckpointId: string | number }>()
  const { checkpointId, lineId, placeId } = useParams<{ lineId: string; placeId: string; checkpointId: string }>()

  const location = useLocation()

  const params = new URLSearchParams(location.search)

  const preferToStop = params.get('stop') === 'true'

  const [data, setData] = useState<CheckpointHostEditModel | null>(null)
  const [line, setLine] = useState<LineModel | null>(null)
  const [parents, setParent] = useState<ParentsType | null>(null)
  const initDataRef = useRef<CheckpointHostEditModel | null>(null)

  useTimeZoneFilter(placeId)

  useEffect(fetchData, [])

  function fetchData() {
    Promise.all([
      checkpointApi.getCheckpointHost(checkpointId),
      lineApi.getLine(lineId, placeId, { headers: { 'X-Form-Action': 'View' } })
    ]).then(([host, line]) => {
      initDataRef.current = host.data.id ? host.data : null
      setData(host.data)
      setParent(host.parents)
      setLine(line.data)
    })
  }

  useEffect(() => {
    if (!parents?.shop || !parents?.line || !data) {
      return
    }

    const crumb = getCrumbs(tr.breadcrumbs)
    const { shop, line } = parents
    const lineId = line.id as string

    dispatch(
      setBreadcrumbs([
        crumb.home(),
        crumb.places(),
        crumb.place([placeId], shop.name),
        crumb.lines([placeId]),
        crumb.line([placeId, lineId], line.name),
        crumb.checkpoints([placeId, lineId]),
        crumb.checkpoints([placeId, lineId], data.name),
        crumb.edithost([placeId, lineId, checkpointId])
      ])
    )
  }, [data, parents, locale])

  function navToCheckpoints() {
    if (history.length !== 0) {
      return history.goBack()
    }

    const pathname = history.location.state?.navTo ? history.location.state.navTo : checkpointsNav(placeId, lineId)

    history.push({ pathname, state: { fromCheckpointId: checkpointId } })
  }

  function handleHostChange(val) {
    checkpointApi
      .updateCheckpointHost({ ...val, checkpointId: checkpointId })
      .then(() => {
        dispatch(showMessage(tr.checkpointMonitoring.updateCheckpointHostSuccessMessage, MessageTypes.Success))
        navToCheckpoints()
      })
      .catch(() => {
        dispatch(showMessage(tr.checkpointMonitoring.updateCheckpointHostFailureMessage, MessageTypes.Error))
      })
  }

  function handleSave(val: CheckpointHostEditModel) {
    if (initDataRef.current && isEqual(initDataRef.current, val)) {
      navToCheckpoints()
    } else {
      handleHostChange(val)
    }
  }

  if (data === null || line === null) {
    return <PageLoader />
  }

  return (
    <Container>
      <HostEdit
        data={data}
        line={line}
        onHostChange={handleSave}
        onBack={navToCheckpoints}
        canOpenCheckpoints={canOpenCheckpoints}
        canCloseCheckpoints={canCloseCheckpoints}
        services={(line?.services || []).filter((el) => !el.isPrivate)}
        preferToStop={preferToStop}
      />
    </Container>
  )
}

export default CheckpointHostEdit
