import { useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'shared/i18n/translate'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { lineMonitoring } from 'pages/nav'
import { useTimeZoneFilter } from 'features/time-zone-filter'
import MonitoringPositionEdit from 'features/monitoring-position-edit/monitoring-position-edit'
import { extractStoredUrl } from 'shared/utils/history'
import { useAppDispatch } from 'store'

function LineMonitoringEdit() {
  const { tr } = useTranslation()
  const dispatch = useAppDispatch()
  const { lineId, placeId, positionId } = useParams<{ placeId: string; lineId: string; positionId?: string }>()
  const history = useHistory()

  useTimeZoneFilter(placeId)

  const backUrl = useMemo(() => extractStoredUrl() || lineMonitoring(placeId, lineId), [])

  function handleSubmit() {
    history.push(backUrl)
  }

  function handleBreadcrumbsChange(data) {
    const crumb = getCrumbs(tr.breadcrumbs)

    const parents = data.parents
    const shopId = parents.shop.id
    const shopName = parents.shop.name
    const lineId = parents.line.id
    const lineName = parents.line.name

    dispatch(
      setBreadcrumbs([
        crumb.home(),
        crumb.places(),
        crumb.place([shopId], shopName),
        crumb.lines([shopId]),
        crumb.line([shopId, lineId], lineName),
        { id: 'lineMonitoring' as any, title: tr.breadcrumbs.lineMonitoring, url: backUrl },
        positionId
          ? crumb.lineMonitoringEdit([shopId, lineId, positionId])
          : crumb.lineMonitoringCreate([shopId, lineId])
      ])
    )
  }

  return (
    <MonitoringPositionEdit
      onBreadcrumbsChange={handleBreadcrumbsChange}
      onSubmit={handleSubmit}
      lineId={lineId}
      placeId={placeId}
      positionId={positionId}
    />
  )
}

export default LineMonitoringEdit
