import { useHotkeys } from 'react-hotkeys-hook'
import { monitoring as monitoringApi } from 'shared/api'
import { showMessage, MessageTypes } from 'store/actions/main-layout-action-creators'
import { useTranslation } from 'shared/i18n/translate'
import { PositionState } from 'shared/models'
import { useAppDispatch } from 'store'
import { HotkeyControlHandler, HotkeyControlProps } from './types'

export function HotkeyControl(props: HotkeyControlProps) {
  const { positions, surveyCallIndex, suggestedPositionId, checkpointId } = props
  const { startLoading, finishLoading } = props
  const { openServiceSurvey, setSelectedId, navigateCreatePage } = props

  if (positions?.length < 1) {
    return null
  }

  const { tr } = useTranslation()
  const { success, error } = tr.serverResponseMessage
  const dispatch = useAppDispatch()

  useHotkeys('ctrl+enter', (async () => {
    if (surveyCallIndex) {
      return
    }

    if (positions.filter((p) => p.state === 'inService').length > 0) {
      return
    }

    const calledPosition = positions.find((p) => p.state === PositionState.CalledToCheckpoint)

    const suggestPosition = positions.find((p) => p.id === suggestedPositionId)

    if (!calledPosition && !suggestPosition) {
      return
    }

    const element = calledPosition || suggestPosition
    const newState = calledPosition ? PositionState.ServiceStarted : PositionState.CalledToCheckpoint

    if (!element) {
      return
    }

    try {
      startLoading?.()

      await monitoringApi.changePositionState({
        positionId: element.id,
        shopId: props.shopId,
        lineId: props.lineId,
        checkpointId,
        newState
      })

      dispatch(showMessage(success, MessageTypes.Success))
      finishLoading?.()
      if (element) {
        setSelectedId?.(element.id)
      }
    } catch (err) {
      finishLoading?.()
      dispatch(showMessage(error, MessageTypes.Error))
    }
  }) as HotkeyControlHandler)

  useHotkeys('ctrl+backspace', (async () => {
    if (surveyCallIndex) {
      return
    }

    const elements = positions.filter((el) => el.state === PositionState.InService)

    if (elements.length < 1) {
      return
    }

    const element = elements[0]

    const data = {
      positionId: element.id,
      shopId: props.shopId,
      lineId: props.lineId,
      customerId: element.customer?.id,
      checkpointId
    }

    if (props.displayServiceSurvey) {
      openServiceSurvey?.(element.id)
      return
    }

    const newState = PositionState.ManualFinished

    try {
      startLoading?.()
      await monitoringApi.changePositionState({ ...data, newState })
      dispatch(showMessage(success, MessageTypes.Success))
      finishLoading?.()
    } catch (err) {
      finishLoading?.()
      dispatch(showMessage(error, MessageTypes.Error))
    }
  }) as HotkeyControlHandler)

  useHotkeys('shift+=', () => {
    navigateCreatePage?.()
  })

  return null
}
