import axios from 'axios'
import { AppointmentsAPI } from 'shared/models'
import { getUrl } from './helpers'

const appointments: AppointmentsAPI = {
  getAppointments(data, config) {
    return axios.get('reports/getAppointments', { params: data, ...config })
  },
  getAppointment(data, config) {
    return axios.get('reports/getAppointment', { params: data, ...config })
  },
  getAppointmentDuplicationForm(data, config) {
    return axios.get('reports/getAppointmentDuplicationForm', { params: data, ...config })
  },
  getAppointmentEditForm(data, config) {
    return axios.get('reports/getAppointmentEditForm', { params: data, ...config })
  },
  createAppointment(data, config) {
    return axios.post('reports/createAppointment', data, config)
  },
  updateAppointment(data, config) {
    return axios.put('reports/updateAppointment', data, config)
  },
  updateAppointmentTime(data, config) {
    return axios.post('reports/updateAppointmentTime', data, config)
  },
  deleteAppointment(id, config) {
    const data = JSON.stringify(id)

    return axios.delete('reports/deleteAppointment', {
      data,
      headers: { 'Content-Type': 'application/json' },
      ...config
    })
  },
  confirmAppointment(id, config) {
    return axios.post('reports/confirmAppointment', { id }, config)
  },
  downloadAppointments(data) {
    location.href = getUrl('report/downloadAppointments', data)
  },
  getAppointmentPrintTemplate(data, config) {
    return axios.get('appointments/print', { params: data, ...config })
  },
  getAppointmentPossibleServiceList(data, config) {
    return axios.get('getAppointmentPossibleServiceList', { params: data, ...config })
  },
  getAppointmentPossiblePlaceLineList(data, config) {
    return axios.post('getAppointmentPossiblePlaceLineList', data, config)
  },
  updateAppointmentProgressionTags(data, config) {
    return axios.put('reports/updateAppointmentProgressionTags', data, config)
  }
}

export default appointments
