import { CSSProperties, JSXElementConstructor } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { PositionStat } from 'features/position-stat'
import StopCircleIcon from '@mui/icons-material/Brightness1'
import { msToMin } from 'shared/utils/time'
import ColorDuration from '../utils/color-duration'
import { TimeLeftOrOverState } from 'shared/models'
import { Translations } from 'shared/i18n/translation-types'

type OverStateToViewData = {
  [key in TimeLeftOrOverState]: {
    color: string
    Icon: JSXElementConstructor<any>
    getTooltip: (tr: Translations) => string
  }
}

export const TimeLeftOrOverStateColorAndIcons: OverStateToViewData = {
  [TimeLeftOrOverState.Normal]: {
    color: ColorDuration.normal,
    Icon: StopCircleIcon,
    getTooltip: (translation) => translation.position.tooltips.remainingServiceTime
  },
  [TimeLeftOrOverState.Alarm]: {
    color: ColorDuration.high,
    Icon: StopCircleIcon,
    getTooltip: (translation) => translation.position.tooltips.serviceTimeSoonExceeded
  },
  [TimeLeftOrOverState.Over]: {
    color: ColorDuration.extreme,
    Icon: StopCircleIcon,
    getTooltip: (translation) => translation.position.tooltips.serviceTimeExceeded
  }
}

type Props = {
  timeLeftOrOver?: number
  timeLeftOrOverState?: TimeLeftOrOverState
  className?: string
  style?: CSSProperties
}

function TimeLeftStatComponent({ timeLeftOrOver, timeLeftOrOverState, className, style }: Props) {
  const { tr } = useTranslation()

  if (timeLeftOrOver === undefined || !timeLeftOrOverState) {
    return null
  }

  const { getTooltip, color, Icon } = TimeLeftOrOverStateColorAndIcons[timeLeftOrOverState]

  return (
    <PositionStat
      className={className}
      tooltipContent={getTooltip(tr)}
      style={style}
      iconStyle={{ color, opacity: 1 }}
      icon={Icon}
    >
      {tr.time.hmin(msToMin(timeLeftOrOver))}
    </PositionStat>
  )
}

export default TimeLeftStatComponent
export const TimeLeftStat = TimeLeftStatComponent
