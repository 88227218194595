import styled from '@emotion/styled'

const ItemStats = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const LastUpdate = styled.div`
  padding: 8px;
`

const CallendarWrapper = styled.div`
  margin: 0 -1rem -0.5rem;
  width: min-content;
`

export { ItemStats, LastUpdate, CallendarWrapper }
