import styled from '@emotion/styled'

const Container = styled.div`
  padding: 1.5rem;
`

const Stats = styled.div`
  display: flex;
  margin: 1.5rem 0 0;

  @media screen and (max-width: 640px) {
    display: block;
    text-align: center;
  }
`

const StatsGroup = styled.div`
  flex: 1;
  display: flex;
`

const StatValue = styled.div`
  font-size: 24px;
`

const Stat = styled.div<{ $active?: boolean }>`
  flex: 1;
  border-radius: 0.25rem;
  padding: 0.25rem;
  margin: -0.25rem;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

  &:hover {
    ${({ $active }) => $active && 'cursor: pointer; background: rgba(0, 0, 0, 0.06);'}
  }

  & + & {
    margin-left: 1rem;
  }
`

const StatTitle = styled.div`
  white-space: pre-line;
`

const Title = styled.div`
  font-size: 14px;
`

const StatGroupDivider = styled.div`
  width: 1px;
  background: rgba(0, 0, 0, 0.13);
  margin: 0 1.5rem 0 1rem;

  @media screen and (max-width: 640px) {
    width: 100%;
    height: 1px;
    margin: 1rem 0;
    padding: 0 32px;
    box-sizing: border-box;
  }
`

export { Container, Stat, StatGroupDivider, StatTitle, StatValue, Stats, StatsGroup, Title }
