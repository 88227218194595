import { CSSProperties, useMemo, ReactNode } from 'react'
import css from './progress-bar.module.scss'

type Props = {
  children?: ReactNode
  progress: number
  total: number
  style?: CSSProperties
  color?: string
}

function ProgressBarComponent(props: Props) {
  const { children, progress, total, style } = props

  const { fill, background } = useMemo(() => {
    const prsntFilled = total == 0 ? 100 : (progress / total) * 100
    const bg = prsntFilled <= 50 ? '#4487D4' : prsntFilled > 70 ? '#21DB84' : '#FFD362'

    return { fill: prsntFilled, background: props.color || bg }
  }, [progress, total])

  return (
    <div className={css.root} style={style}>
      <div className={css.progress} style={{ width: `${fill}%`, background }}>
        <div style={{ display: 'flex', height: '100%' }}>{children}</div>
      </div>
    </div>
  )
}

export default ProgressBarComponent
export const ProgressBar = ProgressBarComponent
