import { ReactNode } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useTranslation } from 'shared/i18n/translate'
import dayjs from 'dayjs'

import 'dayjs/locale/fr'
import 'dayjs/locale/en'

function PickersUtilsProvider(props: { children: ReactNode }) {
  const { locale } = useTranslation()

  dayjs.locale(locale)
  dayjs.Ls.en.weekStart = 1

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      {props.children}
    </LocalizationProvider>
  )
}

export { PickersUtilsProvider }
