import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

const AppointmentActionRoot = styled.div<{ $disabled?: boolean }>`
  padding: 16px 8px 16px 8px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  ${({ $disabled }) => $disabled && 'pointer-events: none;opacity: 0.18;'}
`

const AppointmentActionIcon = styled.div`
  opacity: 0.72;
  display: flex;
`

const pulse = keyframes`
  50% {
    opacity: 0.5;
  }
`

const AppointmentSkeletonElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 22px 16px 16px;
  box-sizing: border-box;
  animation: ${pulse} 1.2s ease infinite 0.2s;
  height: 64px;
  background: rgba(0, 0, 0, 0.08);
`

const AppointmentSkeletonContent = styled.div`
  background: rgba(0, 0, 0, 0.08);
  height: 16px;
  border-radius: 4px;
`

const SummaryRoot = styled.div`
  display: none;
  white-space: pre-line;

  @media print {
    & {
      display: block;
      font-family: Roboto, sans-serif;
      padding: 4px;
    }
  }
`

const SummaryHeader = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 24px;
`

const SummaryGroup = styled.div`
  margin: 0 0 1.5rem;
`

const SummaryTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 8px;
`

const SummaryText = styled.div`
  font-size: 20px;
  font-weight: 400;
`

export {
  AppointmentActionIcon,
  AppointmentActionRoot,
  AppointmentSkeletonElement,
  AppointmentSkeletonContent,
  SummaryRoot,
  SummaryHeader,
  SummaryGroup,
  SummaryTitle,
  SummaryText
}
