import { withMods } from 'shared/utils/cssm'
import css from './color-label.module.scss'
import { useTranslation } from 'shared/i18n/translate'

const mod = withMods(css)

type ColorLabelProps = {
  color?: string
  from?: string
  to?: string
  small?: boolean
  readonly?: boolean
  onClick?: (ev: any, params: any) => void
}

function ColorLabel(props: ColorLabelProps) {
  const { tr } = useTranslation()

  const { color, from, to, small, readonly, onClick } = props

  const isEmpty = !color && !(from && to)

  const style = { backgroundColor: color, backgroundImage: `linear-gradient(${from}, ${to})` }

  return (
    <div
      onClick={(ev) => {
        if (!readonly) {
          onClick?.(ev, { color, from, to })
        }
      }}
      className={mod.root({ empty: isEmpty, small, readonly })}
      style={isEmpty ? {} : style}
    >
      {isEmpty && tr.colorLabel.emptyPlaceholder}
    </div>
  )
}

export default ColorLabel
