import { useTranslation } from 'shared/i18n/translate'
import { msToTime } from 'shared/utils/date'
import TimerPart from './timer-part'

type Props = {
  ms: number
}

function TimerComponent(props: Props) {
  const { ms } = props
  const { tr } = useTranslation()
  const t = msToTime(ms)

  if (t.h < 100) {
    return (
      <div>
        <TimerPart text={t.hh + ':'} faded={t.hh === '00'} />
        <TimerPart text={t.mm} faded={t.hh === '00' && t.mm === '00'} />
        <TimerPart text={':' + t.ss} faded={t.ss === '00'} />
      </div>
    )
  }

  return (
    <div>
      {t.h} {tr.time.hourShort}
    </div>
  )
}

export const Timer = TimerComponent
export default TimerComponent
