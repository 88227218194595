import { CheckListItem } from 'shared/models'
import ChecklistItem from './checklist-item'
import { AccordionActions } from './styled'
import { Add as AddIcon } from '@mui/icons-material'
import { Button } from '@mui/material'
import { reorder } from './utils/reorder'
import { useTranslation } from 'shared/i18n/translate'

type Props = {
  checkList: CheckListItem[]
  setChecklist: (checklist: CheckListItem[]) => void
  brandId?: string
  shopId?: string
}

export function Checklist({ checkList, setChecklist, brandId, shopId }: Props) {
  const { tr } = useTranslation()

  const { addChecklistItem } = tr.lineEdit

  function handleMoveCard(dragIndex: number, hoverIndex: number) {
    setChecklist(reorder(checkList, dragIndex, hoverIndex))
  }

  function handleSetChecklist(index: number) {
    return (checklistItem: CheckListItem) => {
      const newChecklist = [...checkList]
      newChecklist[index] = checklistItem
      setChecklist(newChecklist)
    }
  }

  function handleDeleteItem(index: number) {
    return () => {
      const newChecklist = [...checkList]
      newChecklist.splice(index, 1)
      setChecklist(newChecklist)
    }
  }

  function handleCreateItem() {
    const newChecklist = [...checkList]
    newChecklist.push({ newId: Date.now(), impactOnServiceType: 'None', mandatory: true, name: '', shortName: '' })
    setChecklist(newChecklist)
  }

  return (
    <>
      {checkList?.map((checkItem, index) => (
        <ChecklistItem
          checkListItem={checkItem}
          id={checkItem.id || checkItem.newId || index}
          index={index}
          setChecklistItem={handleSetChecklist(index)}
          key={checkItem.id || checkItem.newId || index}
          moveCard={handleMoveCard}
          onDeleteItem={handleDeleteItem(index)}
          brandId={brandId}
          shopId={shopId}
        />
      ))}
      <AccordionActions>
        <Button variant="outlined" size="small" startIcon={<AddIcon fontSize="small" />} onClick={handleCreateItem}>
          {addChecklistItem}
        </Button>
      </AccordionActions>
    </>
  )
}
