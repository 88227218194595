import { Divider } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import { PositionAction, PositionState } from 'shared/models'
import PositionActionComponent from '../../components/position-action'
import TimeHelper from 'shared/utils/time'
import { RequestToApproachProps } from './types'
import CallIcon from '@mui/icons-material/Notifications'

export function RequestToApproach(props: RequestToApproachProps) {
  const { tr, dateFormatter } = useTranslation()

  const impossibleNext =
    ![
      PositionState.Joined,
      PositionState.InLine,
      PositionState.InLineHere,
      PositionState.InLineExtraTime,
      PositionState.ValidForService
    ].includes(props.position.state) && !props.posiblesStates.includes(PositionAction.CallToCheckpoint)

  function handleSubmit() {
    props.handleChangeState(PositionState.CalledToCheckpoint)
  }

  return (
    <>
      {!props.isCardView && <Divider />}
      <PositionActionComponent
        isCardView={props.isCardView}
        confirmation={(props.position?.expectedCallTime_unixtime || Date.now()) - Date.now() > TimeHelper.minMS * 10}
        confirmationMessage={tr.positionActions.requestToApproachConfirm(
          dateFormatter(props.position?.expectedCallTime_unixtime || Date.now(), 'time')
        )}
        onSubmit={handleSubmit}
        disabled={impossibleNext || !!props.disabled}
        icon={<CallIcon fontSize="small" />}
      >
        {props.monitoringPositionMenuItemCaptions?.requestToApproach || tr.positionActions.requestToApproach}
      </PositionActionComponent>
    </>
  )
}
