import { place as placeApi, line as lineApi } from 'shared/api'
import { Dispatch } from '@reduxjs/toolkit'
import { setTimeZones } from '../reducers/time-zones-reducer'
import {
  invalidatePlaceList,
  setCurrentPlace,
  setLineListToPlace,
  getPlaceListRequest,
  setPlaceList
} from '../reducers/places-reducer'

let timeZonesFetching = false
let placeListFetching = false

export function getPlaceList() {
  return async (dispatch: Dispatch) => {
    if (placeListFetching) {
      return
    }

    try {
      placeListFetching = true

      dispatch(getPlaceListRequest())

      const res = await placeApi.getPlaceListSimplified()

      dispatch(setPlaceList(res))
    } finally {
      placeListFetching = false
    }
  }
}

export function getIsolatedLineList(placeId: string | number, callback?: () => void) {
  return (dispatch: Dispatch) => {
    lineApi.getLineList(placeId).then((value) => {
      dispatch(setLineListToPlace(value))
      callback?.()
    })
  }
}

export function getTimeZones() {
  return async (dispatch: Dispatch) => {
    if (timeZonesFetching) {
      return
    }

    try {
      timeZonesFetching = true

      const res = await placeApi.getTimeZones()

      dispatch(setTimeZones(res.data))
    } finally {
      timeZonesFetching = false
    }
  }
}

export { setCurrentPlace, invalidatePlaceList }
