import { useEffect, useState } from 'react'
import { beacon as beaconApi } from 'shared/api'
import { editBeacon } from 'pages/nav'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'shared/i18n/translate'
import { useTimeZoneFilter } from 'features/time-zone-filter'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { BeaconModel, GetResponseType } from 'shared/models'
import { CircularProgress, Button, Divider } from '@mui/material'
import Card from 'shared/ui-kit/card'
import EntityActions from 'shared/ui-kit/entity-actions'
import ModeEdit from '@mui/icons-material/Edit'
import Container from 'shared/ui-kit/container'
import BeaconView from './beacon-view/index'
import { useDataProvider } from 'features/isolated-data-provider'
import { useTitle } from 'shared/hooks/useTitle'
import { useAppDispatch } from 'store'

type ParentsType = GetResponseType<BeaconModel>['parents']

function SmartBeaconView() {
  const dispatch = useAppDispatch()
  const { tr, locale } = useTranslation()
  const { beaconId, placeId } = useParams<{ beaconId: string; placeId: string }>()
  const history = useHistory()
  const [data, setData] = useState<BeaconModel | null>(null)
  const [parents, setParents] = useState<ParentsType | null>(null)

  const { getPlace } = useDataProvider()

  useTitle({ placeName: getPlace(placeId)?.name })

  useTimeZoneFilter(placeId)

  const isLoading = data === null

  useEffect(() => {
    beaconApi.getBeacon(beaconId, placeId).then((response) => {
      setData(response.data)
      setParents(response.parents)
    })
  }, [])

  useEffect(() => {
    if (!parents?.shop || !data) {
      return
    }

    const crumb = getCrumbs(tr.breadcrumbs)
    const { shop } = parents

    dispatch(
      setBreadcrumbs([
        crumb.home(),
        crumb.places(),
        crumb.place([String(shop.id)], shop.name),
        crumb.beacons([String(shop.id)]),
        crumb.beacon([String(shop.id), String(data.id)], data.name)
      ])
    )
  }, [data, parents, locale])

  return (
    <Container>
      {isLoading && (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress color="secondary" />
        </div>
      )}
      {!isLoading && data && (
        <Card>
          <BeaconView data={data} />
          <Divider />
          <EntityActions
            showButtons
            buttons={
              <Button onClick={() => history.push(editBeacon(placeId, beaconId))} startIcon={<ModeEdit />}>
                {tr.entityView.editButton}
              </Button>
            }
            updateData={data.lastUpdate}
          />
        </Card>
      )}
    </Container>
  )
}

export default SmartBeaconView
