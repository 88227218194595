import { useEffect, useState } from 'react'
import { zones } from 'pages/nav'
import { ZoneEdit } from './dumb/zone-edit'
import { useTranslation } from 'shared/i18n/translate'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { showMessage, MessageTypes } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import CircularProgress from '@mui/material/CircularProgress'
import { CenterContainer } from 'shared/ui-kit/center-container'
import { useHistory, useParams } from 'react-router'
import Container from 'shared/ui-kit/container'
import { zone, beacon, counter } from 'shared/api'
import { BeaconModel, CounterModel, GetResponseType, ZoneModel } from 'shared/models'
import { useDataProvider } from 'features/isolated-data-provider'
import { useTitle } from 'shared/hooks/useTitle'
import { useAppDispatch } from 'store'
import { useTimeZoneFilter } from 'features/time-zone-filter'

function SmartZoneEdit() {
  const dispatch = useAppDispatch()
  const { tr, locale } = useTranslation()
  const { placeId, zoneId } = useParams<{ zoneId: string; placeId: string }>()
  const history = useHistory()

  const { getPlace } = useDataProvider()

  useTitle({ placeName: getPlace(placeId)?.name })
  useTimeZoneFilter(placeId)

  const [entity, setEntity] = useState<GetResponseType<ZoneModel | undefined>>()
  const [beacons, setBeacons] = useState<GetResponseType<BeaconModel[]>>()
  const [counters, setCounters] = useState<GetResponseType<CounterModel[]>>()
  const [loading, setLoading] = useState<boolean>(false)
  const [processing, setProcessing] = useState<boolean>(false)

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (!entity) {
      return
    }

    const { data, parents } = entity

    if (!data || !parents) {
      return
    }

    const crumb = getCrumbs(tr.breadcrumbs)

    dispatch(
      setBreadcrumbs([
        crumb.home(),
        crumb.places(),
        crumb.place([String(parents.shop?.id)], parents.shop?.name),
        crumb.zones([String(parents.shop?.id)]),
        data.id
          ? crumb.editZone([String(parents.shop?.id), String(data.id)], data.name)
          : crumb.newZone([String(parents.shop?.id)])
      ])
    )
  }, [locale, entity])

  async function fetchData() {
    const { getError } = tr.zoneOperationMessages

    setLoading(true)

    try {
      const values = await Promise.all([
        zone.getZone(zoneId, placeId),
        beacon.getBeaconList(placeId),
        counter.getCounterList({ shopId: placeId })
      ])

      setEntity(values[0])
      setBeacons(values[1])
      setCounters(values[2])
      setLoading(false)
    } catch (err) {
      dispatch(showMessage(getError, MessageTypes.Error))
      setLoading(false)
    }
  }

  async function handleCreate(data) {
    const { createError, createSuccess } = tr.zoneOperationMessages

    setProcessing(true)

    try {
      await zone.createZone(data)

      dispatch(showMessage(createSuccess, MessageTypes.Success))
      history.push(zones(placeId))
    } catch (err) {
      dispatch(showMessage(createError, MessageTypes.Error))
      setProcessing(false)
    }
  }

  async function handleUpdate(data) {
    const { updateError, updateSuccess } = tr.zoneOperationMessages

    setProcessing(true)

    try {
      await zone.updateZone(data)

      dispatch(showMessage(updateSuccess, MessageTypes.Success))
      history.push(zones(placeId))
    } catch (err) {
      dispatch(showMessage(updateError, MessageTypes.Error))
      setProcessing(false)
    }
  }
  async function handleDelete(id: string | number) {
    const { deleteError, deleteSuccess } = tr.zoneOperationMessages

    setProcessing(true)

    try {
      await zone.deleteZone(id)

      dispatch(showMessage(deleteSuccess, MessageTypes.Success))
      history.push(zones(placeId))
    } catch (err) {
      dispatch(showMessage(deleteError, MessageTypes.Error))
      setProcessing(false)
    }
  }

  function handleSave(data: ZoneModel) {
    if (!zoneId) {
      handleCreate({ ...data, shopId: placeId })
    } else {
      handleUpdate(data)
    }
  }

  if (loading) {
    return (
      <CenterContainer>
        <CircularProgress color="secondary" />
      </CenterContainer>
    )
  }

  if (!entity?.data || !beacons || !counters) {
    return null
  }

  return (
    <Container>
      <ZoneEdit
        showBeacon={entity.data.showBeacons || false}
        data={entity.data}
        beacons={beacons.data}
        counters={counters.data}
        onSave={handleSave}
        onDelete={handleDelete}
        processing={processing}
      />
    </Container>
  )
}

export default SmartZoneEdit
