import axios from 'axios'
import { AdvertisementAPI } from '../models'

const advertisement: AdvertisementAPI = {
  getAdvertisementList(campaignId, config) {
    return axios.get('getAdvtList', { params: { campaignId }, ...config })
  },
  getAdvertisement(id, campaignId, config) {
    return axios.get('getAdvt', { params: { id, campaignId }, ...config })
  },
  createAdvertisement(data, config) {
    return axios.post('createAdvt', data, config)
  },
  updateAdvertisement(data, config) {
    return axios.put('updateAdvt', data, config)
  },
  deleteAdvertisement(id, config) {
    return axios.delete('deleteAdvt', { data: id, headers: { 'Content-Type': 'application/json' }, ...config })
  },
  updateAdvertisementsOrder(data, config) {
    return axios.post('updateAdvertisementsOrder', data, config)
  }
}

export default advertisement
