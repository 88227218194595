import { Snackbar } from '.'
import { SnackbarProvider } from 'notistack'

export function SnackbarMaterialProvider() {
  return (
    <SnackbarProvider hideIconVariant maxSnack={5} dense>
      <Snackbar />
    </SnackbarProvider>
  )
}
