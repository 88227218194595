import { ReactNode, useEffect } from 'react'
import InfoIcon from '@mui/icons-material/Info'
import { useTranslation } from 'shared/i18n/translate'
import { useHeplers } from 'shared/helper-docs'
import { HelperContainer, TooltipStyled } from './styled'

type TextFieldWithTooltipProps = {
  children: ReactNode
  tooltipKey: string
  tag: string
}

export function WithHelper(props: TextFieldWithTooltipProps) {
  const { helpers, getTagHelpers } = useHeplers()
  const { locale } = useTranslation()

  const helper = helpers[`${props.tag}-${locale}`]
  const tooltip = helper?.[`${props.tooltipKey}`]

  useEffect(() => {
    if (!helpers[`${props.tag}-${locale}`]) {
      getTagHelpers(props.tag, locale)
    }
  }, [])

  return (
    <HelperContainer>
      {props.children}
      {tooltip && (
        <TooltipStyled
          arrow
          title={tooltip}
          leaveTouchDelay={3000}
          enterTouchDelay={0}
          onTouchStart={(event) => {
            event.persist()
          }}
          onTouchStartCapture={(event) => {
            event.persist()
          }}
        >
          <InfoIcon fontSize="small" style={{ color: 'rgba(97, 97, 97, 0.92)' }} />
        </TooltipStyled>
      )}
    </HelperContainer>
  )
}
