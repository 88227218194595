import Dialog from '@mui/material/Dialog'
import SuggestionFinishDialogContent from './suggestion-finish-dialog-content'

type Props = {
  data?: any
  onClose?: () => void
}

export function SuggestionFinishDialog(props: Props) {
  return (
    <Dialog
      maxWidth="md"
      open={Boolean(props.data)}
      onClose={props.onClose}
      BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.36)', backdropFilter: 'blur(16px)' } }}
    >
      {Boolean(props.data) && <SuggestionFinishDialogContent data={props.data} onClose={props.onClose} />}
    </Dialog>
  )
}
