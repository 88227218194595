import { useEffect, useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import Toggle from 'shared/ui-kit/toggle-wrap'
import Info from '@mui/icons-material/Info'
import { Divider, ListSubheader, ListItemText, ListItemButton, ListItem, List } from '@mui/material'
import { TimeslotModel } from 'shared/models'

type Props = {
  config: {
    timeslots: TimeslotModel[]
    asapMode?: boolean
    displayWaitingTimeEstimation?: boolean
    manageAppointments?: boolean
  }
  value: number | null
  disabled?: boolean
  isFetching?: boolean
  asapNotPreferred?: boolean
  onTimeslotRequest?: () => void
  onChange?: (timeslotId: number | null) => void
  asapSelected: boolean
  setAsapSelected: (value: boolean) => void
}

function TimeslotSelect(props: Props) {
  const { tr, dateFormatter } = useTranslation()
  const [moreTimeslots, setMoreTimeslots] = useState(false)

  const isSupportAsapMode = props.config?.asapMode

  useEffect(() => {
    props.onTimeslotRequest?.()

    if ((props.value && isSupportAsapMode) || props.asapNotPreferred) {
      props.setAsapSelected(false)
    }
  }, [])

  function getFormattedTimeSlotValue(timeSlot) {
    const formatter = (time) => dateFormatter(time, 'time')
    const from = Date.now() > timeSlot.startTimeUtc ? Date.now() : timeSlot.startTimeUtc

    return formatter(from)
  }

  function handleToggleAsap() {
    props.setAsapSelected(!props.asapSelected)

    if (!props.asapSelected) {
      props.onChange?.(null)
    }
  }

  function handleChange(timeslotId: number) {
    return () => {
      props.onChange?.(timeslotId)
    }
  }

  function renderAsap() {
    const translation = tr.lineMonitoring
    const { timeslots = [], displayWaitingTimeEstimation } = props.config || {}

    return (
      <>
        <Toggle
          disabled={props.disabled}
          label={translation.asap}
          toggled={props.asapSelected}
          onToggle={handleToggleAsap}
        />
        {props.asapSelected && displayWaitingTimeEstimation !== false && (
          <div>
            <div style={{ color: '#595959' }}>{`${translation.nearFuture}: ${getFormattedTimeSlotValue(
              timeslots[0]
            )}`}</div>
            <div
              style={{ marginTop: 8, display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: -48 }}
            >
              <Info style={{ marginRight: 24 }} color="primary" />
              <div style={{ color: 'rgba(0, 0, 0, .54)', fontSize: '14px' }}>{translation.asapModeDescription}</div>
            </div>
          </div>
        )}
      </>
    )
  }

  function renderTimeslots() {
    const translation = tr.lineMonitoring
    const { timeslots = [] } = props.config || {}

    const timeslotsForRender = moreTimeslots ? timeslots : timeslots.slice(0, 5)

    if (!timeslots.length) {
      return null
    }

    return (
      <List
        subheader={
          <ListSubheader component="div" style={{ padding: 0, height: '28px', lineHeight: '28px' }}>
            {translation.choiceTimeSlot}
          </ListSubheader>
        }
      >
        {timeslotsForRender.map((timeslot, key) => (
          <div key={timeslot.id}>
            {key !== 0 && <Divider />}
            <ListItem disablePadding onClick={handleChange(timeslot.id)}>
              <ListItemButton selected={timeslot.id === props.value}>
                <ListItemText primary={getFormattedTimeSlotValue(timeslot)} />
              </ListItemButton>
            </ListItem>
          </div>
        ))}
        {timeslots > timeslotsForRender && (
          <div key="more_items">
            <Divider />
            <ListItem disablePadding onClick={() => setMoreTimeslots(true)}>
              <ListItemButton>
                <ListItemText primary={translation.moreTimeslots} />
              </ListItemButton>
            </ListItem>
          </div>
        )}
      </List>
    )
  }

  if (props.isFetching) {
    return null
  }

  const { timeslots = [], manageAppointments } = props.config || {}

  if (!timeslots.length) {
    return <div style={{ textAlign: 'center' }}>{tr.lineMonitoring.noTimeslots}</div>
  }

  const showTimeslotsByToggle = isSupportAsapMode && !props.asapSelected
  const showTimeslotsByMode = !isSupportAsapMode && timeslots.length && manageAppointments

  return (
    <div>
      {isSupportAsapMode && renderAsap()}
      {(showTimeslotsByToggle || showTimeslotsByMode) && renderTimeslots()}
    </div>
  )
}

export default TimeslotSelect
