import { AppLocale } from 'shared/i18n'
import { RequestPersonalInfo } from 'shared/models'
import { AppointmentFilters } from './types'
import TIME from 'shared/utils/time'

type Customer = {
  firstName?: string
  lastName?: string
  companyName?: string
}

function getLocalizedFullName(customer: Customer, locale: AppLocale, requestPersonalInfo?: RequestPersonalInfo) {
  const fullName = [
    requestPersonalInfo?.firstName && customer?.firstName,
    requestPersonalInfo?.lastName && customer?.lastName
  ]
    .filter(Boolean)
    .join(' ')

  const localized: Record<AppLocale | 'default', () => string> = {
    en: () => [requestPersonalInfo?.companyName && customer?.companyName, fullName].filter(Boolean).join(' - '),
    fr: () => [requestPersonalInfo?.companyName && customer?.companyName, fullName].filter(Boolean).join(' - '),
    default: () => [requestPersonalInfo?.companyName && customer?.companyName, fullName].filter(Boolean).join(' - ')
  }

  return (localized[locale] || localized.default)()
}

export const filtersStorageKey = 'appointments-list-filter'

export const defaultFilters: AppointmentFilters = {
  startDate: new Date().setUTCHours(0, 0, 0, 0),
  endDate: new Date().setUTCHours(0, 0, 0, 0) + TIME.dayMS * 2,
  term: '',
  shopId: null,
  lineId: null,
  onlyActive: true
}

export { getLocalizedFullName }
