import { PermissionsModel } from 'shared/models'
import { RootState } from 'store'

export function getPermissionsSelector(state: RootState) {
  return state.user.userProfile?.permissions || ({} as PermissionsModel)
}

export function getIsAdminSelector(state: RootState) {
  return state.user.userProfile?.permissions?.canPromoteToAdmin || false
}

export function getViewPermissionsBlocked(state: RootState) {
  return state.viewPermission.blocked
}
