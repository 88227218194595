import { useEffect, useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { addTestAttr } from 'shared/utils/test-attr'
import { line, checkpoint as checkpointApi } from 'shared/api'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { CheckpointStatus, LineModel, ParentPlaceAndLineModel } from 'shared/models'
import { PlayArrow as PlayIcon, Stop as StopIcon } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import {
  checkpoints,
  editLine,
  checkpointHost,
  lineMessageTemplates,
  discreteLineMonitoring,
  lineStaffManagement,
  broadcastAlerts,
  lineMonitoring,
  appointmentsLine,
  lineAppointmentScheduler,
  lineMonitoringState,
  lineScheduler,
  appointmentsToCancelLine
} from 'pages/nav'
import { LineView } from './dumb/line-view'
import Tile, { TileContainer } from 'features/tile'
import { useTimeZoneFilter } from 'features/time-zone-filter'
import { Button, CircularProgress } from '@mui/material'
import { EngineeringMaterial as StaffIcon } from 'shared/ui-kit/icons'
import {
  Send as SendIcon,
  Edit as ModeEdit,
  People as PeopleIcon,
  Message as TemplateIcon,
  ViewModule as AnalyticsIcon,
  ShoppingBasket as BasketIcon,
  DonutLarge as DonutLargeIcon,
  KeyboardArrowUp as ArrowUpIcon,
  EventAvailable as AppointmentsIcon,
  ErrorOutline as AppointmentsToCancelIcon,
  Today as AppointmentsSchedilerIcon,
  KeyboardArrowDown as ArrowDownIcon,
  ViewWeekRounded as ScheduleTodayIcon
} from '@mui/icons-material'
import EntityActions from 'shared/ui-kit/entity-actions'
import { Title } from 'shared/ui-kit/title'
import Container from 'shared/ui-kit/container'
import { AppTitle } from 'shared/hooks/useTitle'
import { getUrl } from 'shared/api/helpers'
import { LogoImage, LoadingContainer, MoreInfoBtn } from './styled'
import { Card } from '@mui/material'
import { titleStyles } from './utils'
import { useAppDispatch, useAppSelector } from 'store'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { useHistory, useParams } from 'react-router'
import { useQuery } from 'react-query'

function SmartLineView() {
  const { lineId, placeId } = useParams<{ lineId: string; placeId: string }>()
  const history = useHistory()

  const { data: res, isLoading } = useQuery(['line', lineId], ({ signal }) => line.getLine(lineId, placeId, { signal }))

  const [checkpointId, setCheckpointId] = useState<number | string>()
  const [checkpointStatus, setCheckpointStatus] = useState<CheckpointStatus>()
  const [settingsOpened, setSettingsOpened] = useState(false)

  useTimeZoneFilter(placeId)

  const {
    displayReducedInterface,
    accessPlaceLineMonitoring,
    manageAppointments,
    manageMessagesTemplates,
    accessAvailabilities,
    accessBroadcastAlerts,
    canOpenCheckpoints,
    canCloseCheckpoints,
    canUpdateShops
  } = useAppSelector(getPermissionsSelector)

  const dispatch = useAppDispatch()

  const { tr } = useTranslation()

  const singleCheckpoint = !!res?.data?.checkpointCount && res.data?.checkpointCount < 2 && displayReducedInterface

  useEffect(() => {
    if (res?.data && res.parents) {
      setCrumbs(res.data, res.parents)
      checkingAutoReplacePage()

      if (res.data.checkpointCount && res.data.checkpointCount < 2) {
        fetchCheckpoints()
      }
    }
  }, [res])

  function fetchCheckpoints() {
    checkpointApi.getCheckpointList(placeId, lineId).then((checkpointRes) => {
      if (checkpointRes.data?.length > 0 && res?.data) {
        checkingNavigateToMonitoring(res.data)
      }

      setCheckpointId(checkpointRes.data?.[0]?.id)
      setCheckpointStatus(checkpointRes.data?.[0]?.status)
    })
  }

  function checkingNavigateToMonitoring(data: LineModel) {
    const isDiscrete = data.lineCalculationAlgorithmMode === 'discrete'

    const noAccessOtherBlocks =
      !accessBroadcastAlerts && !accessAvailabilities && !manageMessagesTemplates && !manageAppointments
    const onlyDiscreteMonitoringAvailable = isDiscrete && accessPlaceLineMonitoring && noAccessOtherBlocks
    const onlyBasicMonitoringAvailable = !isDiscrete && accessPlaceLineMonitoring && noAccessOtherBlocks

    if (onlyDiscreteMonitoringAvailable) {
      history.push(discreteLineMonitoring(placeId, lineId))
    }

    if (onlyBasicMonitoringAvailable) {
      history.push(lineMonitoring(placeId, lineId))
    }
  }

  function checkingAutoReplacePage() {
    if (!res?.data) {
      return
    }

    const displayBlock = {
      discreteMonitoring: res?.data.lineCalculationAlgorithmMode === 'discrete' && accessPlaceLineMonitoring,
      singleMonitorings: res?.data.lineCalculationAlgorithmMode !== 'discrete' && accessPlaceLineMonitoring,
      appointments: !!res.data.allowFutureAppointments && manageAppointments,
      settings:
        manageMessagesTemplates ||
        (accessAvailabilities && !!res.data.allowFutureAppointments) ||
        (accessBroadcastAlerts && !!res.data.enableAlertsDistribution)
    }

    const monitoringAutoReplace =
      singleCheckpoint &&
      !displayBlock.settings &&
      !displayBlock.appointments &&
      !displayBlock.discreteMonitoring &&
      displayBlock.singleMonitorings

    if (!Object.values(displayBlock).find((e) => e === true) && !singleCheckpoint) {
      history.replace(checkpoints(placeId, lineId))
      return
    }

    if (monitoringAutoReplace) {
      history.replace(lineMonitoring(placeId, lineId))
      return
    }
  }

  function setCrumbs(data: LineModel, parents: ParentPlaceAndLineModel) {
    if (!data || !parents) {
      return
    }

    const crumb = getCrumbs(tr.breadcrumbs)

    dispatch(
      setBreadcrumbs([
        crumb.home(),
        crumb.places(),
        crumb.place([placeId], parents.shop.name),
        crumb.lines([placeId]),
        crumb.line([placeId, lineId], data.name)
      ])
    )
  }

  function handleEdit() {
    history.push(editLine(placeId, lineId))
  }

  function handleSettings() {
    setSettingsOpened(!settingsOpened)
  }

  function handleCheckpointHost() {
    if (!checkpointId) {
      return
    }

    history.push(checkpointHost(placeId, lineId, String(checkpointId)))
  }

  if (isLoading) {
    return (
      <LoadingContainer>
        <CircularProgress color="secondary" />
      </LoadingContainer>
    )
  }

  if (!res) {
    return null
  }

  const isStarted =
    !!checkpointStatus && [CheckpointStatus.starting, CheckpointStatus.started].includes(checkpointStatus)
  const isDiscrete = res.data.lineCalculationAlgorithmMode === 'discrete'

  const displayBlock = {
    discreteMonitoring: isDiscrete && accessPlaceLineMonitoring,
    singleMonitorings: !isDiscrete && accessPlaceLineMonitoring,
    appointments: res.data.allowFutureAppointments && manageAppointments,
    settings:
      manageMessagesTemplates ||
      (accessAvailabilities && res.data.allowFutureAppointments) ||
      (accessBroadcastAlerts && !!res.data.enableAlertsDistribution)
  }

  return (
    <Container>
      <AppTitle lineName={res.data?.name} />
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        {res.data.image?.id && <LogoImage $url={getUrl('downloadFile', { id: res.data.image.id })} />}
        <Title title={res.data.name} style={{ flex: 1, marginBottom: 0 }} {...addTestAttr('LineView-LineName')} />
        {!!singleCheckpoint && !!checkpointStatus && (
          <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            {tr.checkpointStatus[checkpointStatus]?.()}
            {(!!canOpenCheckpoints || !!canCloseCheckpoints) && (
              <Tooltip title={isStarted ? tr.checkpointList.stopServicePoint : tr.checkpointList.startServicePoint}>
                <IconButton onClick={handleCheckpointHost} size="small">
                  {isStarted ? <StopIcon /> : <PlayIcon />}
                </IconButton>
              </Tooltip>
            )}
          </div>
        )}
        {!displayReducedInterface && (
          <MoreInfoBtn {...addTestAttr('LineView-SettingsButton')} onClick={handleSettings}>
            {tr.entityView.settings}
            {settingsOpened ? <ArrowUpIcon fontSize="small" /> : <ArrowDownIcon fontSize="small" />}
          </MoreInfoBtn>
        )}
      </div>
      {settingsOpened && (
        <Card style={{ marginTop: '1rem' }}>
          <LineView data={res.data} />
          <EntityActions
            showButtons={!!canUpdateShops}
            buttons={
              <Button onClick={handleEdit} startIcon={<ModeEdit />} {...addTestAttr('LineView-EditButton')}>
                {tr.entityView.editButton}
              </Button>
            }
            updateData={res.data.lastUpdate}
          />
        </Card>
      )}
      <div style={{ marginTop: '2rem' }}>
        <TileContainer>
          {!singleCheckpoint && (
            <Tile
              {...addTestAttr('LineTiles-Checkpoints')}
              title={tr.lineView.checkpoints(res.data.checkpointCount || 0)}
              iconClass={BasketIcon}
              count={res.data.checkpointCount}
              linkTo={checkpoints(placeId, lineId)}
            />
          )}
          {displayBlock.singleMonitorings &&
            !displayReducedInterface &&
            res.data.flags?.displayDailyScheduler === true && (
              <Tile
                {...addTestAttr('LineTiles-Scheduler')}
                title={tr.lineView.schedulerToday}
                iconClass={ScheduleTodayIcon}
                linkTo={lineScheduler(placeId, lineId)}
              />
            )}
          {displayBlock.discreteMonitoring && (
            <Tile
              {...addTestAttr('LineTiles-DiscreteLineMonitoring')}
              title={tr.lineView.monitoring}
              iconClass={DonutLargeIcon}
              linkTo={discreteLineMonitoring(placeId, lineId)}
            />
          )}
          {displayBlock.singleMonitorings && (
            <Tile
              {...addTestAttr('LineTiles-LineMonitoring')}
              title={tr.lineView.lineMonitoring}
              iconClass={PeopleIcon}
              linkTo={lineMonitoring(placeId, lineId)}
            />
          )}
          {displayBlock.singleMonitorings &&
            !displayReducedInterface &&
            res.data.flags?.displayStatesMonitoring === true && (
              <Tile
                {...addTestAttr('LineTiles-CheckpointsStateMonitoring')}
                title={tr.lineView.lineStateMonitoring}
                iconClass={AnalyticsIcon}
                linkTo={lineMonitoringState(placeId, lineId)}
              />
            )}
        </TileContainer>
        {displayBlock.appointments && (
          <>
            <div style={titleStyles}>{tr.lineView.bookingGroup}</div>
            <TileContainer>
              <Tile
                {...addTestAttr('LineTiles-Appointments')}
                title={tr.home.appointments}
                iconClass={AppointmentsIcon}
                linkTo={appointmentsLine(placeId, lineId)}
              />
              <Tile
                {...addTestAttr('LineTiles-AppointmentsToCancel')}
                title={tr.home.appointmentsToCancel}
                iconClass={AppointmentsToCancelIcon}
                linkTo={appointmentsToCancelLine(placeId, lineId)}
                count={res.data.appointmentsToCancelCount}
              />
              {res.data.flags?.displayAppointmentsScheduler !== false && (
                <Tile
                  {...addTestAttr('LineTiles-AppointmentsScheduler')}
                  title={tr.lineView.appointmentScheduler}
                  iconClass={AppointmentsSchedilerIcon}
                  linkTo={lineAppointmentScheduler(placeId, lineId)}
                />
              )}
            </TileContainer>
          </>
        )}
        {displayBlock.settings && (
          <>
            <div style={titleStyles}>{tr.lineView.settingsGroup}</div>
            <TileContainer>
              {manageMessagesTemplates && (
                <Tile
                  title={tr.lineView.messageTemplates}
                  iconClass={TemplateIcon}
                  linkTo={lineMessageTemplates(placeId, lineId)}
                />
              )}
              {accessAvailabilities && res.data.allowFutureAppointments && (
                <Tile
                  title={tr.lineView.staffManagement}
                  iconClass={StaffIcon}
                  linkTo={lineStaffManagement(placeId, lineId)}
                />
              )}
              {accessBroadcastAlerts && !!res.data.enableAlertsDistribution && (
                <Tile
                  title={tr.lineView.broadcastAlerts}
                  iconClass={SendIcon}
                  linkTo={broadcastAlerts(placeId, lineId)}
                />
              )}
            </TileContainer>
          </>
        )}
      </div>
    </Container>
  )
}

export default SmartLineView
