import { useEffect, useState } from 'react'
import Popover from '@mui/material/Popover'
import ColorLabel from './color-label'
import { ControlLabel } from '../control-label'
import css from './color-picker.module.scss'

export type Color = {
  category: number | string | null
  color?: string
  from?: string
  to?: string
}

type Props = {
  colors: Color[]
  onChange: (data: Color) => void
  from?: string
  to?: string
  color?: string
  title?: string
}

function ColorPicker(props: Props) {
  const { color, from, to, colors = [], onChange, title } = props

  const [openState, setOpenState] = useState(false)
  const [colorState, setColorState] = useState(color)
  const [fromState, setFromState] = useState(from)
  const [toState, setToState] = useState(to)
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    setColorState(color)
    setFromState(from)
    setToState(to)
  }, [color, from, to])

  function handleVariantClick(data) {
    setOpenState(false)
    setColorState(data.color)
    setFromState(data.from)
    setToState(data.to)

    onChange(data)
  }

  function handleTouchTap(ev) {
    setOpenState(true)
    setAnchorEl(ev.currentTarget)
  }

  function renderVariants() {
    return (
      <div className={css.variants}>
        {colors.map((c, i) => (
          <div className={css.variant} key={i}>
            <ColorLabel small color={c.color} from={c.from} to={c.to} onClick={() => handleVariantClick(c)} />
          </div>
        ))}
      </div>
    )
  }

  return (
    <div>
      <div style={{ marginBottom: 12 }}>
        <ControlLabel>{title}</ControlLabel>
      </div>
      <ColorLabel onClick={(ev) => handleTouchTap(ev)} color={colorState} from={fromState} to={toState} />
      <Popover
        open={openState}
        anchorEl={anchorEl}
        onClose={() => setOpenState(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {renderVariants()}
      </Popover>
    </div>
  )
}

export default ColorPicker
