import { useTranslation } from 'shared/i18n/translate'
import { Box, IconButton, Typography } from '@mui/material'
import Card from 'shared/ui-kit/card'
import { useState } from 'react'
import { parseCustomerInfo } from './utils/parse-customer-info'
import UILink from 'shared/ui-kit/link'
import {
  Phone as PhoneIcon,
  Email as EmailIcon,
  Code as IdIcon,
  AccessTime as ClockIcon,
  NotificationImportant as NotificationImportantIcon,
  Place as CheckpointIcon,
  ShoppingBasket as ServiceIcon,
  KeyboardArrowDown as LessIcon,
  KeyboardArrowUp as MoreIcon,
  Build as ServiceDurationIcon,
  SupervisorAccount as PersonsIcon
} from '@mui/icons-material'
import { PositionStat } from 'features/position-stat'
import { customers, place, line, journal } from 'pages/nav'
import { useHistory } from 'react-router'
import { PositionTypeIcon } from 'features/position-dumb'
import { CodeIcon } from 'shared/ui-kit/icons'
import { parseEventData } from './utils/parse-event-data'
import { Link } from 'react-router-dom'
import {
  ActionInitiator,
  ActionsWrapper,
  EventContent,
  MoreInfoWrapper,
  PositionStatIndicators,
  StatIndicator
} from './styled'
import { checkpointActions } from './utils/checkpoint-actions'
import { ee2ActionToPositionAction } from './utils/ee2-action-to-position-action'
import { EventFlowItemProps } from './types'

function EventFlowItem({ data }: EventFlowItemProps) {
  const { tr, dateFormatter } = useTranslation()

  const history = useHistory()

  const [isMore, setIsMore] = useState(false)

  const customer = data?.customerInfo ? parseCustomerInfo(data.customerInfo) : undefined
  const eventData = data?.data ? parseEventData(data?.data || '') : undefined

  function handleMore() {
    setIsMore((curr) => !curr)
  }

  function handleCustomerClick() {
    if (data?.customerId) {
      const params = new URLSearchParams({ customerId: String(data.customerId) })
      history.push(`${customers()}?${params}`)
    }
  }

  return (
    <Card>
      <div style={{ position: 'relative' }}>
        <Typography fontSize="12px" color="GrayText" style={{ left: '1rem', top: '0.5rem', position: 'absolute' }}>
          {dateFormatter(data.time, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: '2-digit',
            second: '2-digit',
            hourCycle: 'h23'
          })}
        </Typography>
        <ActionInitiator>
          {data?.actionInitiator && (
            <Typography variant="body2" style={{ opacity: '0.86' }}>
              {data.actionInitiator}
            </Typography>
          )}
          {!!data.operator && (
            <Typography variant="caption" color="GrayText" fontSize="11px">
              {[data.operator.firstName, data.operator.lastName].join(' ')}
            </Typography>
          )}
        </ActionInitiator>
      </div>
      <EventContent>
        <Typography variant="subtitle1" color="#db214d" fontWeight="600">
          {tr.eventFlowActionName[data.action] || data.action || ''}
        </Typography>
        <div style={{ marginTop: '0.5rem' }}>
          {data.place?.id && (
            <>
              <Link to={place(String(data.place.id))} target="_blank" style={{ color: '#000' }}>
                <Typography variant="body2" lineHeight="11px" fontWeight="500">
                  {data.place.name}
                </Typography>
              </Link>
              {!!data.line?.id && (
                <Link
                  to={line(String(data.place?.id), String(data.line.id))}
                  target="_blank"
                  style={{ color: '#585858' }}
                >
                  <Typography variant="caption">{data.line.name}</Typography>
                </Link>
              )}
              {!!data.checkpoint && !customer && (
                <Box>
                  <Typography variant="caption">{data.checkpoint.name}</Typography>
                </Box>
              )}
            </>
          )}
        </div>
        {customer && (
          <div style={{ margin: '0.5rem 0' }}>
            <PositionStat>
              <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                {data.positionType && <PositionTypeIcon type={ee2ActionToPositionAction[data.positionType]} />}
                <div>
                  <UILink onClick={handleCustomerClick}>
                    {[
                      customer.CompanyName,
                      [customer.FirstName, customer.LastName].filter(Boolean).join(' ') || `${data?.customerId}`
                    ]
                      .filter(Boolean)
                      .join(' - ')}
                  </UILink>
                  <div style={{ display: 'flex', columnGap: '1rem', flexWrap: 'wrap', marginTop: 2 }}>
                    {Boolean(customer.PhoneNumber) && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <PhoneIcon style={{ opacity: 0.28, marginRight: 8, width: 16, height: 16 }} />
                        <div style={{ fontSize: 12, opacity: 0.72 }}>{customer.PhoneNumber}</div>
                      </div>
                    )}
                    {Boolean(customer.Email) && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <EmailIcon style={{ opacity: 0.28, marginRight: 8, width: 16, height: 16 }} />
                        <div style={{ fontSize: 12, opacity: 0.72 }}>{customer.Email}</div>
                      </div>
                    )}
                    {Boolean(data?.customerId) && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IdIcon style={{ opacity: 0.28, marginRight: 8, width: 16, height: 16 }} />
                        <div style={{ fontSize: 12, opacity: 0.72 }}>{data.customerId}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </PositionStat>
            <PositionStatIndicators>
              {!!data.checkpoint?.id && checkpointActions.includes(data.action) && (
                <StatIndicator>
                  <CheckpointIcon fontSize="small" opacity="0.28" />
                  <Typography variant="body2">{data.checkpoint.name}</Typography>
                </StatIndicator>
              )}
              {!!data.services?.length && (
                <StatIndicator>
                  <ServiceIcon fontSize="small" opacity="0.28" />
                  <Typography variant="body2">{data.services.map((el) => el.name).join(', ')}</Typography>
                </StatIndicator>
              )}
              {!!data.timeSlotStart && (
                <StatIndicator>
                  <ClockIcon fontSize="small" opacity="0.28" />
                  <Typography variant="body2">{dateFormatter(data.timeSlotStart, 'dateTime')}</Typography>
                </StatIndicator>
              )}
              {!!data.expectedServiceTime && (
                <StatIndicator>
                  <ServiceDurationIcon fontSize="small" opacity="0.28" />
                  <Typography variant="body2">{tr.time.hmin(data.expectedServiceTime / 60)}</Typography>
                </StatIndicator>
              )}
              {!!data.participantsNumber && (
                <StatIndicator>
                  <PersonsIcon fontSize="small" opacity="0.28" />
                  <Typography variant="body2">{data.participantsNumber}</Typography>
                </StatIndicator>
              )}
              {!!data.priority && (
                <StatIndicator>
                  <NotificationImportantIcon fontSize="small" opacity="0.28" />
                  <Typography variant="body2">{tr.lineMonitoring.priority}</Typography>
                </StatIndicator>
              )}
              {!!data.reservationCode && (
                <StatIndicator>
                  <CodeIcon fontSize="small" opacity="0.28" />
                  <Typography variant="body2">{data.reservationCode}</Typography>
                </StatIndicator>
              )}
            </PositionStatIndicators>
          </div>
        )}
      </EventContent>
      {!!eventData || !!data.positionId ? (
        <ActionsWrapper>
          <IconButton size="small" onClick={handleMore}>
            {!isMore ? <LessIcon fontSize="small" /> : <MoreIcon fontSize="small" />}
          </IconButton>
        </ActionsWrapper>
      ) : (
        <Box marginTop="0.75rem" />
      )}
      {isMore && (
        <MoreInfoWrapper>
          {!!data.positionId && (
            <div>
              <Typography variant="subtitle2">PositionId</Typography>
              <Link to={journal() + `?term=p${data.positionId}`} target="_blank">
                <Typography fontSize={12} color="#0052b4">
                  {data.positionId}
                </Typography>
              </Link>
            </div>
          )}
          {!!eventData && (
            <div>
              <Typography variant="subtitle2">Data</Typography>
              <Typography fontSize={12}>{JSON.stringify(eventData, null, 4)}</Typography>
            </div>
          )}
        </MoreInfoWrapper>
      )}
    </Card>
  )
}

export { EventFlowItem }
