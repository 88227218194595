import { Typography, IconButton, DialogTitle } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { useTranslation } from 'shared/i18n/translate'
import { getNameOrNumber } from 'features/position-dumb/helpers'
import { getPositionStatusText } from 'features/position-dumb/utils/mapper'
import PositionDurationsComponent from 'features/position-dumb/components/position-durations'

type Props = {
  data?: any
  onClose?: () => void
}

function SuggestionDialogContent(props: Props) {
  const { tr, timeZoneOffset } = useTranslation()

  return (
    <>
      <DialogTitle style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h6">{tr.checkpointMonitoringFinishSuggestions.title}</Typography>
        {props.onClose && (
          <IconButton
            aria-label="close"
            onClick={props.onClose}
            style={{ margin: '-8px -16px 0 8px', color: 'rgba(0, 0, 0, .36)' }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <div style={{ margin: '0 1.5rem 1.5rem' }}>
        <div>{getNameOrNumber(props.data, tr)}</div>
        <div style={{ marginTop: '0.25rem', opacity: 0.48, fontSize: 14 }}>
          {getPositionStatusText(props.data, tr, timeZoneOffset, false, false)}
        </div>
        <div style={{ marginTop: '1rem' }}>
          <PositionDurationsComponent data={props.data} />
        </div>
      </div>
    </>
  )
}

export default SuggestionDialogContent
