import { useEffect, useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { LineModel } from 'shared/models'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { getLineEditValidations } from './validation'
import { ParametersGroup } from './groups/parameters-group'
import { PersonalInfoGroup } from './groups/personal-info-group'
import { GroupProps, LineEditProps, LineSettingsGroup } from './types'
import { ModesGroup } from './groups/modes-group'
import { MobileAppGroup } from './groups/mobile-app-group'
import { ServicesGroup } from './groups/services-group'
import { AdditionalFieldsGroup } from './groups/additional-fields-group'
import { ProgressionTagsGroup } from './groups/progression-tags-group'
import { AppointmentForNextDayGroup } from './groups/appointment-for-next-day-group'
import { OpeningHoursGroup } from './groups/opening-hours-group'
import { DurationsGroup } from './groups/durations-group'
import { MonitoringGroup } from './groups/monitoring-group'
import { OperationsMenuGroup } from './groups/operations-menu-group'
import { AdvancedSettingsGroup } from './groups/advanced-settings-group'
import { getLineEditGroups } from './getFormGroups'
import { LineDesktopLayout } from './desktop-layout'
import { LineMobileLayout } from './mobile-layout'
import { useMediaQuery } from 'react-responsive'

function LineEdit({
  canPromoteToAdmin,
  data,
  onDelete,
  onDuplicate,
  onSave,
  shopId,
  canDuplicateLine,
  onBackToLine,
  isLoading,
  lineName
}: LineEditProps) {
  const { tr, dateFormatter } = useTranslation()

  const [activeTab, setActiveTab] = useState<LineSettingsGroup>('parameters')

  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })

  const { control, handleSubmit, formState, watch, trigger, setValue } = useForm<LineModel>({
    resolver: yupResolver(getLineEditValidations() as any),
    defaultValues: data,
    mode: 'onChange'
  })

  const formGroups = getLineEditGroups(formState, tr, watch)

  useEffect(() => {
    trigger()
  }, [])

  function handleDelete() {
    onDelete(data.id)
  }

  function handleActiveTab(tab: LineSettingsGroup) {
    return () => setActiveTab(tab)
  }

  const groupProps: GroupProps = {
    control,
    setValue,
    trigger,
    formState,
    watch,
    brandId: data.brandId,
    shopId,
    lineId: data.id,
    canPromoteToAdmin,
    onDelete: handleDelete,
    onDuplicate,
    canDuplicateLine
  }

  const Layout = isMobile ? LineMobileLayout : LineDesktopLayout

  return (
    <form onSubmit={handleSubmit(onSave)} style={{ width: '100%' }}>
      <Layout
        lineName={lineName}
        data={data}
        activeGroup={activeTab}
        onChangeActiveGroup={handleActiveTab}
        formGroups={formGroups}
        formIsValid={!!formState.isValid}
        onBackToLine={onBackToLine}
        isEdit={!!data.id}
        lastUpdateText={
          data.lastUpdate?.user
            ? tr.entityView.lastUpdate(
                data.lastUpdate.user.firstName,
                data.lastUpdate.user.secondName,
                dateFormatter(data.lastUpdate.date, 'dateTime')
              )
            : undefined
        }
        isLoading={isLoading}
      >
        {activeTab === 'parameters' && <ParametersGroup {...groupProps} />}
        {activeTab === 'personalInfo' && <PersonalInfoGroup {...groupProps} />}
        {activeTab === 'modes' && <ModesGroup {...groupProps} />}
        {activeTab === 'mobileApp' && <MobileAppGroup {...groupProps} />}
        {activeTab === 'services' && <ServicesGroup {...groupProps} />}
        {activeTab === 'additionalFields' && <AdditionalFieldsGroup {...groupProps} />}
        {activeTab === 'progressionTags' && <ProgressionTagsGroup {...groupProps} />}
        {activeTab === 'appointmentForNextDay' && <AppointmentForNextDayGroup {...groupProps} />}
        {activeTab === 'openingHours' && <OpeningHoursGroup {...groupProps} />}
        {activeTab === 'durations' && <DurationsGroup {...groupProps} />}
        {activeTab === 'monitoring' && <MonitoringGroup {...groupProps} />}
        {activeTab === 'operationsMenu' && <OperationsMenuGroup {...groupProps} />}
        {activeTab === 'advanced' && <AdvancedSettingsGroup {...groupProps} />}
      </Layout>
    </form>
  )
}

export { LineEdit }
