import { useEffect } from 'react'
import { ControlLabel } from '../control-label'
import { LanguageSelectBaseProps } from './language-select'
import { ISOlanguagesSupported } from 'shared/utils/iso-lang-supported'

const IconStyle = {
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  border: 'solid 1px #00000017'
}

type Props = LanguageSelectBaseProps<true>

function LanguageSelectMultipleComponent(props: Props) {
  const { value = [] as string[], languages = [], ensureValue = false, onChange, hideSingle = false, title } = props

  const languagesToView = languages.filter((l) => ISOlanguagesSupported.includes(l))

  function selectLanguage(lang) {
    onChange([lang, ...value])
  }

  function unselectLanguage(lang) {
    if (value.length === 1 && ensureValue) {
      return
    }

    onChange(value.filter((l) => l !== lang))
  }

  function isSelected(language) {
    return value.includes(language)
  }

  useEffect(() => {
    if (ensureValue && value.length === 0 && languagesToView.length !== 0) {
      selectLanguage(languagesToView[0])
    }
  }, [])

  if (!languagesToView.length) {
    return null
  }

  if (hideSingle && languages.length === 1 && ensureValue) {
    return null
  }

  return (
    <div>
      {title && <ControlLabel>{title}</ControlLabel>}
      <div style={{ display: 'flex', marginTop: 4 }}>
        {languages.map((lang) => {
          const islangSelected = isSelected(lang)
          const style = islangSelected ? {} : { filter: 'grayscale(70%)', opacity: 0.3 }

          return (
            <div
              style={{ marginRight: 8, cursor: 'pointer', ...style }}
              key={lang}
              onClick={() => (islangSelected ? unselectLanguage(lang) : selectLanguage(lang))}
            >
              <div style={IconStyle}>
                <img src={`flags-rounded/${lang}.svg`} />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default LanguageSelectMultipleComponent
export const LanguageSelectMultiple = LanguageSelectMultipleComponent
