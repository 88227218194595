import { useTranslation } from 'shared/i18n/translate'
import { Button } from '@mui/material'
import { ConfirmItemProps } from './types'

function ConfirmItem({ onSubmit, onCancel, open, children, message }: ConfirmItemProps) {
  const { tr } = useTranslation()
  const { yes, no } = tr.positionActions

  return (
    <div>
      {open && (
        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <span style={{ margin: 'auto auto auto 0' }}>{message}</span>
          <Button color="primary" size="small" variant="contained" onClick={onSubmit}>
            {yes}
          </Button>
          <Button color="primary" size="small" variant="outlined" onClick={onCancel}>
            {no}
          </Button>
        </div>
      )}
      {!open && children}
    </div>
  )
}

export { ConfirmItem }
