import { createElement, CSSProperties, ReactNode } from 'react'
import EmptyIcon from '@mui/icons-material/Storage'
import { IconWrap, MessageContainer, MessageText } from './styled'

const iconProps = {
  style: {
    width: 96,
    height: 96,
    verticalAlign: 'middle',
    color: 'rgba(0, 0, 0, .17)'
  }
}

type Props = {
  icon?: any
  children: ReactNode
  style?: CSSProperties
}

export function MonitoringMessage({ icon, children, style }: Props) {
  return (
    <MessageContainer style={style}>
      {icon !== null && <IconWrap>{createElement(icon || EmptyIcon, iconProps)}</IconWrap>}
      <MessageText>{children}</MessageText>
    </MessageContainer>
  )
}
