import { useTranslation } from 'shared/i18n/translate'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { checkpointMonitoring } from 'pages/nav'
import { useTimeZoneFilter } from 'features/time-zone-filter'
import MonitoringPositionEdit from 'features/monitoring-position-edit/monitoring-position-edit'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { useHistory, useParams } from 'react-router'
import { useAppDispatch } from 'store'

function CheckpointMonitoringEdit() {
  const params = useParams<{ placeId: string; lineId: string; checkpointId: string; positionId?: string }>()
  const history = useHistory()
  const { tr } = useTranslation()
  const dispatch = useAppDispatch()

  useTimeZoneFilter(params.placeId)

  function handleSubmit() {
    history.push(checkpointMonitoring(params.placeId, params.lineId, params.checkpointId))
  }

  function handleBreadcrumbsChange(data) {
    const crumb = getCrumbs(tr.breadcrumbs)
    const parents = data.parents
    const shopId = parents.shop.id
    const shopName = parents.shop.name
    const lineId = parents.line.id
    const lineName = parents.line.name
    const checkpoint = parents.checkpoint

    dispatch(
      setBreadcrumbs([
        crumb.home(),
        crumb.places(),
        crumb.place([shopId], shopName),
        crumb.lines([shopId]),
        crumb.line([shopId, lineId], lineName),
        crumb.checkpoints([shopId, lineId]),
        crumb.checkpoints([shopId, lineId], checkpoint ? checkpoint.name : null),
        crumb.checkpointMonitoring([shopId, lineId, params.checkpointId]),
        params.positionId
          ? crumb.checkpointMonitoringEdit([shopId, lineId, params.checkpointId, params.positionId])
          : crumb.checkpointMonitoringCreate([shopId, lineId, params.checkpointId])
      ])
    )
  }

  return (
    <MonitoringPositionEdit
      onBreadcrumbsChange={handleBreadcrumbsChange}
      onSubmit={handleSubmit}
      lineId={params.lineId}
      placeId={params.placeId}
      positionId={params.positionId}
      checkpointId={params.checkpointId}
    />
  )
}

export default CheckpointMonitoringEdit
