import { useTranslation } from 'shared/i18n/translate'
import { List } from 'shared/ui-kit/list'
import { ZoneModel } from 'shared/models'

type Props = {
  data: ZoneModel[]
  onSelect: (data: ZoneModel) => void
}

function ZoneList(props: Props) {
  const { data, onSelect } = props
  const { tr, dateFormatter } = useTranslation()

  const { lastAlertSent, lastCalculation, beaconsCount, countersCount } = tr.zoneList

  return (
    <List
      data={data}
      onSelect={(data) => onSelect(data)}
      dataConverter={(item: ZoneModel) => {
        const secText = [
          item.showBeacons && beaconsCount(item.beaconIds.length),
          item.showCounters && countersCount(item.counters.length),
          item.lastAlertSent && `${lastAlertSent.toLowerCase()} ${dateFormatter(item.lastAlertSent, 'dateTime')}`,
          item.lastCalculation && `${lastCalculation.toLowerCase()} ${dateFormatter(item.lastCalculation, 'dateTime')}`
        ]

        return { id: item.id, primaryText: item.name, secondaryText: secText.filter(Boolean).join(', ') }
      }}
    />
  )
}

export { ZoneList }
