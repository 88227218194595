import styled from '@emotion/styled'

const ItemContainer = styled.div<{ $link?: boolean }>`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  cursor: ${(props) => props.$link && 'pointer'};
`

const ItemLabel = styled.div`
  font-size: 11px;
`

const ItemValue = styled.div<{ $link?: boolean }>`
  flex: 1;
  font-size: 16px;
  color: ${(props) => props.$link && '#0052b4'};
`

const FieldContainer = styled.div`
  margin-left: 0.25rem;
`

const ItemSub = styled.div`
  display: flex;
  cursor: pointer;
  gap: 1rem;
  align-items: center;
`

export { ItemContainer, ItemLabel, ItemValue, FieldContainer, ItemSub }
