import { useTranslation } from 'shared/i18n/translate'
import { useEffect, useState } from 'react'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { useAppDispatch } from 'store'
import { setBreadcrumbs } from 'store/reducers/main-layout-reducer'
import { EventFlowSmallLayout } from './small-layout'
import { EventFlowLargeLayout } from './large-layout'
import { useMediaQuery } from 'react-responsive'
import { useDataProvider } from 'features/isolated-data-provider'
import { getIsolatedLineList } from 'store/actions/places-action-creators'
import { user } from 'shared/api'
import { GetEventsFlowRequestModel } from 'shared/models'
import { EventFlowItem } from './event-flow-item'
import { useQuery, useQueryClient, useInfiniteQuery } from 'react-query'
import { journal } from 'shared/api'
import { CircularProgress } from '@mui/material'
import EmptyScreen from 'shared/ui-kit/empty-screen'
import TimerIcon from '@mui/icons-material/Timer'
import LoadMore from 'shared/ui-kit/load-more'
import EmptyIcon from '@mui/icons-material/Storage'

const defaultFilters: GetEventsFlowRequestModel = {
  startDate: new Date().setUTCHours(0, 0, 0, 0) - 1000 * 60 * 60 * 24,
  endDate: new Date().setUTCHours(0, 0, 0, 0)
}

const storageKey = 'events-flow-filter'
const queryKey = 'getEventsFlow'

function EventsFlow() {
  const dispatch = useAppDispatch()
  const { tr } = useTranslation()

  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })

  const storageFilters = sessionStorage.getItem(storageKey)
  const parcedStorageFilters = storageFilters ? (JSON.parse(storageFilters) as GetEventsFlowRequestModel) : undefined

  const [filter, setFilter] = useState<GetEventsFlowRequestModel>(parcedStorageFilters || defaultFilters)

  const {
    data: eventFlow,
    remove,
    refetch,
    isFetching,
    isFetched,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  } = useInfiniteQuery(
    queryKey,
    ({ signal, pageParam }) => journal.getEventsFlow({ ...filter, lastEventId: pageParam }, { signal }),
    {
      enabled: false,
      getNextPageParam: (lastPage) => lastPage.events[lastPage.events.length - 1]?.id ?? undefined,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false
    }
  )

  const queryClient = useQueryClient()

  const { data: usersRes } = useQuery('getUserList', ({ signal }) =>
    user.getUserList({ hideInactive: true, term: '' }, { signal })
  )

  const { places, getPlace } = useDataProvider()

  useEffect(getInitialLineList, [places])

  useEffect(syncFilters, [filter])

  useEffect(changeBreadcrumbs, [])

  useEffect(() => {
    return () => {
      queryClient.resetQueries(queryKey)
    }
  }, [])

  function getInitialLineList() {
    if (filter.shopId) {
      const shop = getPlace(filter.shopId)

      if (shop && !shop?.lines) {
        dispatch(getIsolatedLineList(filter.shopId))
      }
    }
  }

  function syncFilters() {
    sessionStorage.setItem('events-flow-filter', JSON.stringify(filter))
  }

  function changeBreadcrumbs() {
    const crumbs = getCrumbs(tr.breadcrumbs)

    dispatch(setBreadcrumbs([crumbs.home(), crumbs.reports([]), crumbs.eventsFlow()]))
  }

  function handleSubmit() {
    remove()
    refetch()
  }

  function onFilterChange<K extends keyof GetEventsFlowRequestModel>(key: K, val: GetEventsFlowRequestModel[K]) {
    setFilter((curr) => {
      const newFilters = { ...curr }
      newFilters[key] = val
      return newFilters
    })
  }

  function handleClearFilters() {
    setFilter(defaultFilters)
    queryClient.resetQueries(queryKey)
  }

  function onShopChenge(shopId: string | number | undefined) {
    setFilter((curr) => ({ ...curr, shopId, lineId: undefined }))

    if (shopId) {
      dispatch(getIsolatedLineList(shopId))
    }
  }

  const Layout = !isMobile ? EventFlowLargeLayout : EventFlowSmallLayout

  const lines = filter.shopId ? getPlace(filter.shopId)?.lines : []

  return (
    <Layout
      filter={filter}
      handleSubmit={handleSubmit}
      onFilterChange={onFilterChange}
      onShopChenge={onShopChenge}
      lines={lines}
      shops={places}
      users={usersRes?.data || []}
      onClear={handleClearFilters}
    >
      <div style={{ marginBottom: '1rem' }}>
        {!isFetching && !eventFlow?.pages?.length && (
          <EmptyScreen
            text={isFetched ? tr.journal.emptyEventsFlowMessage : tr.journal.emptyTermMessage}
            iconClass={TimerIcon}
          />
        )}
        {(!isFetching || !!isFetched) &&
          eventFlow?.pages.map((page, pageIndex) => (
            <div key={pageIndex}>
              {page.events.map((el, eventIndex) => (
                <EventFlowItem data={el} key={`${pageIndex}-${eventIndex}`} />
              ))}
            </div>
          ))}
        {!!isFetching && !isFetched && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
          </div>
        )}
      </div>
      {eventFlow?.pages?.[0]?.events?.length === 0 && (
        <EmptyScreen iconClass={EmptyIcon} text={tr.common.noDataFound} />
      )}
      {hasNextPage && !!eventFlow?.pages?.length && <LoadMore onClick={fetchNextPage} loading={isFetchingNextPage} />}
    </Layout>
  )
}

export default EventsFlow
