import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GetBroadcastAlertConfigResponse } from 'shared/models'

type BroadcastAlertState = {
  fetching: boolean
  data: GetBroadcastAlertConfigResponse | null
}

const initialState: BroadcastAlertState = {
  fetching: false,
  data: null
}

const broadcastAlertSlice = createSlice({
  name: 'broadcastAlert',
  initialState,
  reducers: {
    setListRequest: (state) => {
      state.fetching = true
    },
    setListSuccess: (state, action: PayloadAction<GetBroadcastAlertConfigResponse>) => {
      state.fetching = false
      state.data = action.payload
    },
    resetList: (state) => {
      state.fetching = false
      state.data = null
    }
  }
})

export const { setListRequest, setListSuccess, resetList } = broadcastAlertSlice.actions
export default broadcastAlertSlice.reducer
