import { Button, Fade, Paper, Popper, Typography } from '@mui/material'
import { SatisfactionDialogActions, SatisfactionDialogTitle, SatisfactionSurveyInfo } from './styled'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { AppLocale } from 'shared/i18n'
import { useMediaQuery } from 'react-responsive'

const storageKey = 'jefile_satisfactionSurvey'

const surveyLink: Record<AppLocale, string> = {
  en: 'https://docs.google.com/forms/d/e/1FAIpQLSeAZxup8VLmf8bXfK8-mf868y6CZvh5bXl1RwFbTfNHs4QI1A/viewform',
  fr: 'https://docs.google.com/forms/d/e/1FAIpQLSdtqaYnU2gPN3e5glcQ5muM1R49RH_yZMmfrGka6MREhRA8_g/viewform'
}

function SatisfactionSurvey({ blockDialogOpening }: { blockDialogOpening?: boolean }) {
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null)
  const messageRef = useRef<HTMLSpanElement>(null)

  const { locale, tr } = useTranslation()

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const timeout = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (blockDialogOpening) {
      clearTimeout(timeout.current)
      setAnchorEl(null)
    } else {
      if (isMobile) {
        setAnchorEl(null)
      } else {
        checkToOpen()
      }
    }
  }, [isMobile, blockDialogOpening])

  function checkToOpen() {
    if (!blockDialogOpening && messageRef.current && localStorage.getItem(storageKey) !== 'true') {
      timeout.current = setTimeout(() => {
        handleDialogOpen()
      }, 1000)
    }
  }

  function handleDialogOpen() {
    if (messageRef.current) {
      setAnchorEl(messageRef.current)
    }
  }

  function closePopover() {
    setAnchorEl(null)
    localStorage.setItem(storageKey, 'true')
  }

  return (
    <>
      {!isMobile && (
        <SatisfactionSurveyInfo>
          <Typography variant="body2" color="#FFF" ref={messageRef} onClick={handleDialogOpen}>
            {tr.sutisfactionSurvey.message}
          </Typography>
        </SatisfactionSurveyInfo>
      )}
      {!isMobile && (
        <Popper sx={{ zIndex: 1200 }} open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom-start" transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={160}>
              <Paper style={{ padding: '1rem', marginTop: '1rem' }}>
                <SatisfactionDialogTitle>
                  <Typography>{tr.sutisfactionSurvey.dialogTitle}</Typography>
                </SatisfactionDialogTitle>
                <SatisfactionDialogActions>
                  <Button variant="outlined" size="small" onClick={closePopover}>
                    {tr.sutisfactionSurvey.no}
                  </Button>
                  <a href={surveyLink[locale] || surveyLink.en} target="_blank" rel="noreferrer">
                    <Button variant="contained" size="small" color="primary" onClick={closePopover}>
                      {tr.sutisfactionSurvey.yes}
                    </Button>
                  </a>
                </SatisfactionDialogActions>
              </Paper>
            </Fade>
          )}
        </Popper>
      )}
    </>
  )
}

export { SatisfactionSurvey }
