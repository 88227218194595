import { PositionStat } from 'features/position-stat'
import styled from '@emotion/styled'

const PositionRoot = styled.div<{ disabled?: boolean }>`
  display: flex;
  position: relative;
  cursor: pointer;

  ${(props) => props.disabled && { opacity: '0.4', pointerEvents: 'none' }}
`

const ActionsRoot = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
  margin-left: 56px;
`

const ActionsConent = styled.div`
  flex: 1;
`

const PositionStateType = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0 1rem 1rem;
`

const DeviceTypeIconContainer = styled.div`
  width: 24px;
  height: 24px;
  opacity: 0.28;
  margin-top: 8px;
`

const PositionTile = styled.div`
  padding: 1rem;
  position: relative;
  flex: 1;
  cursor: pointer;
  min-width: 0;
`

const PositionHeaderTitle = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`

const PositionPrimary = styled.div`
  display: flex;
  align-items: center;
`

const PositionSecondary = styled.div`
  font-size: 14px;
  line-height: 1rem;
  margin: 4px 0 0 0;
  color: rgba(0, 0, 0, 0.54);
`

const ProgressionTagContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const PositionTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`

const ProgressionTagsComment = styled.div`
  font-size: 12px;
  line-height: 10px;
  margin-bottom: 4px;
`

const AdditionalRow = styled.div`
  margin-top: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`

const PositionUnDataContainer = styled.div`
  position: relative;
  padding: 2rem;
  display: flex;
  gap: 2rem;
  align-items: center;
`

const InfoContainer = styled.div`
  margin-top: 0.5rem;
`

const InfoRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem 0.75rem;
`

const InfoWithIcon = styled(PositionStat)`
  display: flex;
  align-items: center;
`

const PositionDurationsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem 0.75rem;
`

const PreRow = styled.div<{ $isFlexible?: boolean }>`
  margin-bottom: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem 0.75rem;
  flex-direction: column;

  ${(props) => props.$isFlexible && 'flex-direction: row;'}
`

const LastVisit = styled.div`
  font-size: 11px;
  opacity: 0.56;
  margin: 0.75rem 0.25rem -0.25rem;
`

export {
  PositionRoot,
  PositionStateType,
  DeviceTypeIconContainer,
  PositionTile,
  PositionHeaderTitle,
  PositionPrimary,
  PositionSecondary,
  ProgressionTagContainer,
  PositionTags,
  ProgressionTagsComment,
  AdditionalRow,
  PositionUnDataContainer,
  InfoContainer,
  InfoRow,
  InfoWithIcon,
  PositionDurationsWrapper,
  PreRow,
  LastVisit,
  ActionsRoot,
  ActionsConent
}
