import { SvgIcon, SvgIconProps } from '@mui/material'

function TagSvgIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
      <path d="M20 10V8h-4V4h-2v4h-4V4H8v4H4v2h4v4H4v2h4v4h2v-4h4v4h2v-4h4v-2h-4v-4h4zm-6 4h-4v-4h4v4z" />
    </svg>
  )
}

function TagMaterial(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M20 10V8h-4V4h-2v4h-4V4H8v4H4v2h4v4H4v2h4v4h2v-4h4v4h2v-4h4v-2h-4v-4h4zm-6 4h-4v-4h4v4z" />
    </SvgIcon>
  )
}

export default TagSvgIcon
export { TagSvgIcon, TagMaterial }
