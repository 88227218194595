import { Additional } from 'shared/models'
import { FormGroup } from 'shared/ui-kit/form'
import { Button } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import { useTranslation } from 'shared/i18n/translate'
import { AdditionalDndItem } from './item'
import { reorder } from './utils/reorder'
import { ListProps } from './types'

export function AdditionalsList(props: ListProps) {
  const { tr } = useTranslation()

  const { addAdditional } = tr.lineEdit

  function handleAdditionalsChange(i: number) {
    return (additional: Additional) => {
      const newAdditionals = [...props.additionals]
      newAdditionals[i] = additional
      props.onAdditionalsChange(newAdditionals)
    }
  }

  function handleRemoveAdditional(i: number) {
    return () => {
      const newAdditionals = [...props.additionals]
      newAdditionals.splice(i, 1)
      props.onAdditionalsChange(newAdditionals)
    }
  }

  function handleMoveCard(dragIndex: number, hoverIndex: number) {
    props.onAdditionalsChange(reorder(props.additionals, dragIndex, hoverIndex))
  }

  const availableServices = (props.services || []).filter((s) => !s.isPrivate)

  return (
    <>
      <FormGroup style={{ marginTop: 0 }}>
        {props.additionals.map((additional, i) => (
          <AdditionalDndItem
            onRemove={handleRemoveAdditional(i)}
            onChange={handleAdditionalsChange(i)}
            id={additional.id || additional.newId || i}
            brandId={props.brandId}
            index={i}
            additional={additional}
            key={additional.id || additional.newId || i}
            moveCard={handleMoveCard}
            services={availableServices}
            additionals={props.additionals}
            placeId={props.placeId}
            requestParticipantsNumber={props.requestParticipantsNumber}
            asapSupported={props.asapSupported}
          />
        ))}
      </FormGroup>
      <FormGroup>
        <div style={{ marginLeft: -8 }}>
          <Button startIcon={<AddIcon />} onClick={props.onAddAdditional}>
            {addAdditional}
          </Button>
        </div>
      </FormGroup>
    </>
  )
}
