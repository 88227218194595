import { CSSProperties } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { AppLocale } from 'shared/i18n'
import JeFileLogoFr from './components/jefile-logo-fr'
import JeFileLogoEn from './components/jefile-logo-en'

const LocaleToLogoMapper = {
  [AppLocale.En]: JeFileLogoEn,
  [AppLocale.Fr]: JeFileLogoFr,
  unknown: JeFileLogoFr
}

type Props = {
  className?: string
  style?: CSSProperties
}

function JefileLogoMiltilanguage(props: Props) {
  const { className, style } = props
  const { locale } = useTranslation()

  const Logo = LocaleToLogoMapper[locale] || LocaleToLogoMapper.unknown

  return <Logo className={className} style={style}></Logo>
}

export default JefileLogoMiltilanguage
