import { useState, useEffect, FormEvent } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import Autocomplete from 'shared/ui-kit/autocomplete'
import { DatePicker } from 'features/date'
import { Menu, Button, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material'
import Link from 'shared/ui-kit/link'
import {
  CloudDownloadOutlined as CloudDownloadOutlinedIcon,
  Add as AddIcon,
  FilterList as FilterIcon,
  Search as SearchIcon,
  MoreVert as MoreIcon,
  Close as CloseIcon
} from '@mui/icons-material'
import Toggle from 'shared/ui-kit/toggle-wrap'
import { FilterProps } from './types'
import { AppointmentStatus } from 'shared/models'
import { SearchInput } from 'features/search-input'
import { AppointmentFiltersBar } from './styled'

const appointmentStatuses = Object.values(AppointmentStatus)

export default function AppointmentsFilterLargeLayout(props: FilterProps) {
  const { tr } = useTranslation()
  const [open, setOpen] = useState(true)
  const [actionsAnchorEl, setActionsAnchorEl] = useState<HTMLButtonElement | null>(null)

  useEffect(() => {
    if (!props.shopFetching && !props.lineFetching) {
      setOpen(false)
    }
  }, [props.shopFetching, props.lineFetching])

  function handleSearch(e: FormEvent) {
    e.preventDefault()
    props.onSearch?.()
  }

  function renderActions() {
    const showServicesSearch = !props.shopId && !props.lineId
    const translation = tr.appointments

    if (props.cancelMode) {
      return null
    }

    if (!showServicesSearch && !props.cancelMode) {
      return (
        <IconButton onClick={props.onCreate} style={{ marginRight: '0.5rem' }}>
          <AddIcon />
        </IconButton>
      )
    }

    return (
      <>
        <IconButton onClick={(event) => setActionsAnchorEl(event.currentTarget)}>
          <MoreIcon />
        </IconButton>
        <Menu
          id="actions-menu"
          keepMounted
          anchorEl={actionsAnchorEl}
          open={!!actionsAnchorEl}
          onClose={() => setActionsAnchorEl(null)}
          elevation={2}
        >
          <MenuItem
            onClick={() => {
              setActionsAnchorEl(null)
              props.onCreate() //only finish setState
            }}
          >
            <ListItemIcon>
              <AddIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={translation.add} />
          </MenuItem>
          {!props.shopId && !props.lineId && (
            <MenuItem
              onClick={() => {
                setActionsAnchorEl(null)
                props.onCreateFromService() //only finish setState
              }}
            >
              <ListItemIcon>
                <SearchIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={translation.searchTimeslotsFromService} />
            </MenuItem>
          )}
        </Menu>
      </>
    )
  }

  function renderFilter() {
    const { shop, line } = tr.common
    const { hideInactive } = tr.appointment
    const { shopId, lineId } = props
    const hasShopId = shopId || shopId === 0
    const hasLineId = lineId || lineId === 0

    return (
      <>
        {!hasShopId && (
          <div style={{ padding: '0.5rem' }}>
            <Autocomplete
              fullWidth
              ensureValue
              title={shop}
              value={props.filter.shopId}
              list={props.shops}
              style={{ textOverflow: 'ellipsis' }}
              dataConverter={(el) => ({ text: el.name, value: el.id })}
              onChange={(shopId) => {
                if (!props.shopFetching) props.onFilterChange('shopId', shopId)
              }}
            />
          </div>
        )}
        {!hasLineId && (
          <div style={{ padding: '0.5rem' }}>
            <Autocomplete
              fullWidth
              ensureValue
              title={line}
              value={props.filter.lineId}
              list={props.lines}
              style={{ textOverflow: 'ellipsis' }}
              dataConverter={(el) => ({ text: el.name, value: el.id })}
              onChange={(lineId) => {
                if (!props.shopFetching && !props.lineFetching) props.onFilterChange('lineId', lineId)
              }}
            />
          </div>
        )}
        {!props.cancelMode && (
          <div style={{ padding: '0.5rem' }}>
            <Autocomplete
              fullWidth
              ensureValue
              title={tr.appointment.status}
              value={props.filter.status || 'all'}
              list={[
                { text: tr.reportsFilter.allShops, value: 'all' },
                ...appointmentStatuses.map((status) => ({
                  text: tr.appointment.appointmentStatusTranslations[status],
                  value: status
                }))
              ]}
              style={{ textOverflow: 'ellipsis' }}
              dataConverter={(el) => el}
              onChange={(status) => {
                if (status === 'all') {
                  props.onFilterChange('status', undefined)
                } else {
                  props.onFilterChange('status', status)
                }
              }}
            />
          </div>
        )}
        <div style={{ padding: '0.5rem' }}>
          <DatePicker
            label={tr.reportsFilter.startDate}
            style={{ marginBottom: 0, width: '100%' }}
            onChange={(date) => props.onFilterChange('startDate', date)}
            value={props.filter.startDate}
          />
        </div>
        <div style={{ padding: '0.5rem' }}>
          <DatePicker
            label={tr.reportsFilter.endDate}
            style={{ marginBottom: 0, width: '100%' }}
            onChange={(date) => props.onFilterChange('endDate', date)}
            value={props.filter.endDate}
          />
        </div>
        {!props.cancelMode && (
          <div style={{ padding: '0.5rem' }}>
            <Toggle
              toggled={props.filter.onlyActive}
              label={hideInactive}
              onToggle={(_, val) => {
                props.onFilterChange('onlyActive', val)
              }}
            />
          </div>
        )}
        <div style={{ padding: '1rem 0.5rem' }}>
          {(props.filter.shopId || props.filter.shopId === 0) && (props.filter.lineId || props.filter.lineId === 0) && (
            <Link onClick={props.onDownload} style={{ display: 'inline-flex', alignItems: 'center' }}>
              <CloudDownloadOutlinedIcon style={{ margin: '0 8px 0 1px' }} />
              {tr.appointments.download}
            </Link>
          )}
        </div>
      </>
    )
  }

  return (
    <form onSubmit={handleSearch} style={{ padding: '4.5rem 0.5rem 0.5rem', width: '100%', boxSizing: 'border-box' }}>
      <AppointmentFiltersBar style={{ position: open ? 'absolute' : 'fixed' }}>
        <div style={{ display: 'flex', height: 64, alignItems: 'center', width: '100%' }}>
          {renderActions()}
          <div style={{ padding: '0.5rem', flex: 1 }}>
            <SearchInput
              value={props.filter.term}
              fullWidth
              placeholder={tr.reportsFilter.search}
              onChange={(ev) => props.onFilterChange('term', ev.target.value)}
              onClear={() => props.onFilterChange('term', '')}
            />
          </div>
          <div style={{ padding: '0 0.5rem' }}>
            <Button variant="contained" size="small" color="primary" type="submit">
              {tr.common.search}
            </Button>
          </div>
          <IconButton
            onClick={() => {
              window.scrollTo(0, 0)
              setOpen(!open)
            }}
          >
            {open ? <CloseIcon /> : <FilterIcon />}
          </IconButton>
        </div>
        {open && renderFilter()}
      </AppointmentFiltersBar>
      <div>{props.children}</div>
    </form>
  )
}
