import { List } from 'shared/ui-kit/list'
import { useTranslation } from 'shared/i18n/translate'
import Toggle from 'shared/ui-kit/toggle-wrap'
import { BeaconModel } from 'shared/models'

type Props = {
  data: BeaconModel[]
  onSelect: (data: BeaconModel) => void
  onToggle: (data: BeaconModel) => void
  isDisabled?: (data: BeaconModel) => boolean
}

export default function BeaconList(props: Props) {
  const { dateFormatter, tr } = useTranslation()
  const { data, onToggle, onSelect, isDisabled = () => false } = props
  const { beaconList } = tr

  function getSecondaryText(item: BeaconModel) {
    const major = item.major
    const minor = item.minor
    const lastSignalDate = item.lastSignalDate

    const time = lastSignalDate ? dateFormatter(lastSignalDate, 'dateTime') : null

    return `${major}/${minor}, ${beaconList.lastSignalDate(time || '')}`
  }

  return (
    <List
      data={data}
      onSelect={(data) => onSelect(data)}
      dataConverter={(item) => {
        return {
          id: item.id,
          primaryText: item.name,
          secondaryText: getSecondaryText(item),
          controls: [
            <div style={{ marginRight: 8 }}>
              <Toggle
                onToggle={(ev) => {
                  onToggle(item)
                  ev.stopPropagation()
                }}
                toggled={item.active}
                disabled={isDisabled(item)}
              />
            </div>
          ]
        }
      }}
    />
  )
}
