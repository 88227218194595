import { CSSProperties, ReactNode, forwardRef } from 'react'
import { PersonsCountStyled, PersonsContainer } from './styled'
import PersonIcon from '@mui/icons-material/Person'

type Props = { children: ReactNode; style?: CSSProperties }

export const PersonsCount = forwardRef<HTMLDivElement, Props>(({ children, style }, ref) => {
  return (
    <PersonsContainer style={style} ref={ref}>
      <PersonIcon style={{ color: '#c1c1c1' }} />
      <PersonsCountStyled>{children}</PersonsCountStyled>
    </PersonsContainer>
  )
})
