function getMessegeTemplateKey({
  messageType,
  language,
  shopId,
  lineId
}: {
  messageType: string
  language?: string
  shopId?: string | number
  lineId?: string | number
}) {
  return ['getMessageTemplate', messageType, language, shopId, lineId]
}

export { getMessegeTemplateKey }
