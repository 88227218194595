import { useTranslation } from 'shared/i18n/translate'
import {
  Edit as EditIcon,
  Visibility as VisibilityIcon,
  PlayArrow as PlayIcon,
  Stop as StopIcon
} from '@mui/icons-material'
import { checkpointHost, checkpointMonitoring, checkpoints, editCheckpoint } from 'pages/nav'
import { useHistory } from 'react-router'
import { addTestAttr } from 'shared/utils/test-attr'
import { GetMenuProps, MenuItem } from '../../types'

function getMenu({ shopId, lineId, id, showEditHost, showMonitoring, canUpdateShops, isStart }: GetMenuProps) {
  const { tr } = useTranslation()
  const { push } = useHistory()

  function hostEditHandleClick() {
    push({ pathname: checkpointHost(shopId, lineId, id), state: { navTo: checkpoints(shopId, lineId) } })
  }

  function handleStopClick() {
    push(checkpointHost(shopId, lineId, id, true))
  }

  function monitoringHandleClick() {
    push(checkpointMonitoring(shopId, lineId, id))
  }

  function settingsHandleClick() {
    push({ pathname: editCheckpoint(shopId, lineId, id), state: { navTo: checkpoints(shopId, lineId) } })
  }

  const menu: MenuItem[] = []

  if (canUpdateShops) {
    menu.push({
      id: 'edit',
      title: tr.checkpointList.edit,
      handle: settingsHandleClick,
      Icon: EditIcon,
      testAttr: addTestAttr('EditItemButton-Id-' + id)
    })
  }

  if (showEditHost) {
    menu.push({
      id: 'hostEdit',
      title: isStart ? tr.checkpointList.stopServicePoint : tr.checkpointList.startServicePoint,
      handle: isStart ? handleStopClick : hostEditHandleClick,
      Icon: isStart ? StopIcon : PlayIcon,
      testAttr: addTestAttr('HostEditItemButton-Id-' + id)
    })
  }

  if (showMonitoring) {
    menu.push({
      id: 'monitoring',
      title: tr.checkpointList.monitoring,
      handle: monitoringHandleClick,
      Icon: VisibilityIcon,
      testAttr: addTestAttr('MonitoringButton-Id-' + id)
    })
  }

  return menu
}

export default getMenu
