import { useState } from 'react'
import Text from 'shared/ui-kit/text'
import { Form } from 'shared/ui-kit/form'
import { useTranslation } from 'shared/i18n/translate'
import Button from '@mui/material/Button'
import Card from 'shared/ui-kit/card'
import { isPositiveNumber } from 'shared/utils/string-test'
import { TerminalInventoryProps } from '../types'
import { InventoryButtons, InventoryButtonsText, InventoryWarningIcon, InventoryInput, InventoryText } from '../styled'

function TerminalInventory(props: TerminalInventoryProps) {
  const { tr } = useTranslation()

  const [quantity, setQuantity] = useState('')

  function handleSet() {
    props.onSet({ quantity })
    setQuantity('')
  }

  function handleFull() {
    props.onFull({ quantity })
    setQuantity('')
  }

  function handleEmpty() {
    props.onEmpty({ quantity })
    setQuantity('')
  }

  function handleQuantityChange(value) {
    setQuantity(value)
  }

  return (
    <Card>
      <Form
        headerContent={() => (
          <InventoryText>
            <Text type="body-1">{props.text}</Text>
            {props.warning && <InventoryWarningIcon />}
          </InventoryText>
        )}
        expandable
      >
        <InventoryButtons>
          <Button onClick={handleFull}>{tr.terminalView.full}</Button>
          <Button onClick={handleEmpty}>{tr.terminalView.empty}</Button>
          <InventoryButtonsText>{tr.terminalView.setTo}</InventoryButtonsText>
          <InventoryInput
            placeholder={tr.terminalView.number}
            value={quantity}
            onChange={handleQuantityChange}
            test={isPositiveNumber}
          />
          <Button onClick={handleSet}>{tr.terminalView.apply}</Button>
        </InventoryButtons>
      </Form>
    </Card>
  )
}

export { TerminalInventory }
