import { JefileHandIconMaterial } from 'shared/ui-kit/icons'
import TimeIcon from '@mui/icons-material/AccessTime'
import { useTranslation } from 'shared/i18n/translate'
import { PositionModel } from 'shared/models'
import { isSameDay } from 'shared/utils/time'
import { InfoWithIcon } from '../styled'

type Props = {
  data: PositionModel
  displayTimeslot: boolean
}

function TimeslotOrAsapStatComponent({ data, displayTimeslot }: Props) {
  const { tr, dateFormatter } = useTranslation()

  const { asap, timeSlot } = data

  function getDate(unixtime: number) {
    return dateFormatter(unixtime, isSameDay(Date.now(), unixtime) ? 'time' : 'dateTime')
  }

  function getFormattedTimeSlotValue(data: PositionModel) {
    const { timeSlot } = data

    if (!timeSlot) {
      return 'NO_TIMESLOT'
    }

    const { from_unixtime } = timeSlot

    if (!from_unixtime) {
      return 'NO_TIMESLOT_FROM_TO'
    }

    return getDate(from_unixtime)
  }

  if (asap) {
    return (
      <InfoWithIcon tooltipContent={tr.position.tooltips.asap} icon={JefileHandIconMaterial}>
        {tr.lineMonitoring.asapAbbreviation}
      </InfoWithIcon>
    )
  }

  if (!displayTimeslot || !timeSlot) {
    return null
  }

  return (
    <InfoWithIcon tooltipContent={tr.position.tooltips.preAppointmentTime} icon={TimeIcon}>
      {getFormattedTimeSlotValue(data)}
    </InfoWithIcon>
  )
}

export default TimeslotOrAsapStatComponent
export const TimeslotOrAsapStat = TimeslotOrAsapStatComponent
