import { includes, isArray, isEqualWith, isObject, omitBy } from 'lodash'

const comparisonFunc = (a: any, b: any) => {
  if (isArray(a) || isArray(b)) {
    return
  }

  if (!isObject(a) || !isObject(b)) {
    return
  }

  if (!includes(a as any, undefined) && !includes(b as any, undefined)) {
    return
  }

  return isEqualWith(
    omitBy(a, (value) => value === undefined),
    omitBy(b, (value) => value === undefined),
    comparisonFunc
  )
}

export { comparisonFunc }
