import styled from '@emotion/styled'

const HelpContainer = styled.div`
  display: flex;
  margin-bottom: -50px;
  padding: 0.5rem;
  width: -webkit-fill-available;
  justify-content: flex-end;
`

const PreMessage = styled.pre`
  white-space: pre-wrap;
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 18px;
`

const MessageTemplateFormRoot = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  flex-wrap: wrap;
`

export { HelpContainer, PreMessage, MessageTemplateFormRoot }
