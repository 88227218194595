import axios from 'axios'
import { BrandAPI } from '../models'

const brand: BrandAPI = {
  getBrandList(config) {
    return axios.get('getBrandList', config)
  }
}

export default brand
