import Dialog from '@mui/material/Dialog'
import { useEffect, useRef, useState } from 'react'
import { user as userAPI } from 'shared/api'
import Loader from 'shared/ui-kit/page-loader'
import { TemporaryCodeDialogProps } from '../types'
import { LinearProgress, Typography } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import { SecLeftWrapper, TemporaryCodeViewWrapper } from '../styled'

export function TemporaryCodeDialog({ open, userId, onClose, firstName, secondName }: TemporaryCodeDialogProps) {
  const { tr } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState('')
  const [lastGeneratedDateTime, setLastGeneratedDateTime] = useState(0)
  const [now, setNow] = useState(Date.now())

  const intervalRef = useRef<NodeJS.Timeout>()

  const timeDiffInSec = Math.floor((now - lastGeneratedDateTime) / 1000)
  const secLeft = 60 - timeDiffInSec < 0 ? 0 : 60 - timeDiffInSec

  useEffect(() => {
    if (secLeft === 0 && open && userId) {
      setCode('')
      getUserTemporaryCode()
    }
  }, [secLeft, open])

  useEffect(() => {
    if (open) {
      setNow(Date.now())

      intervalRef.current = setInterval(() => {
        setNow(Date.now())
      }, 500)
    } else {
      clearInterval(intervalRef.current)
    }

    return () => clearInterval(intervalRef.current)
  }, [open])

  async function getUserTemporaryCode() {
    setLoading(true)

    try {
      const data = await userAPI.getUserTemporaryCode(userId)
      setLastGeneratedDateTime(Date.now())
      setNow(Date.now())
      setCode(data.code)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      onClose()
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <TemporaryCodeViewWrapper>
        <Typography variant="body2" style={{ opacity: 0.86 }}>
          {firstName} {secondName}
        </Typography>
        {loading && <Loader style={{ margin: '1rem 0' }} />}
        {!loading && !!code && (
          <div style={{ margin: '1rem 0' }}>
            <Typography variant="h4" letterSpacing={8}>
              {code}
            </Typography>
          </div>
        )}
        {!loading && !!secLeft && (
          <div style={{ width: '100%' }}>
            <LinearProgress variant="determinate" value={(secLeft / 60) * 100} />
            <SecLeftWrapper>
              <Typography variant="caption" style={{ opacity: 0.72 }}>
                {secLeft} {tr.time.secShort}
              </Typography>
            </SecLeftWrapper>
          </div>
        )}
      </TemporaryCodeViewWrapper>
    </Dialog>
  )
}
