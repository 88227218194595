import Autocomplete from 'shared/ui-kit/autocomplete'
import { DatePicker } from 'features/date'
import { Card } from '@mui/material'
import css from './journal-filter.module.scss'
import { Button } from '@mui/material'
import { SearchInput } from 'features/search-input'
import { lineMonitoring } from 'pages/nav'
import { useTranslation } from 'shared/i18n/translate'
import { useHistory } from 'react-router'
import { LayoutProps } from './types'
import { useTimeZoneFilter } from 'features/time-zone-filter'

function AppointmentsFilterLargeLayout(props: LayoutProps) {
  const { tr } = useTranslation()
  const history = useHistory()

  const { shop, line } = tr.common
  const { shopId, lineId } = props
  const hasShopId = shopId || shopId === 0
  const hasLineId = lineId || lineId === 0

  useTimeZoneFilter(shopId)

  function handleBackToLineMonitoring() {
    const { shopId, lineId } = props

    history.push(lineMonitoring(String(shopId), String(lineId)))
  }

  function onSearch(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    props.onSearch()
  }

  return (
    <div className={css.root}>
      <div style={{ display: 'flex', width: 800, alignItems: 'start' }}>
        <div className={css.sideBar}>
          <Card style={{ overflow: 'unset' }}>
            <form onSubmit={onSearch}>
              <div style={{ padding: '0.5rem 1rem' }}>
                <SearchInput
                  value={props.filter.term}
                  fullWidth
                  placeholder={tr.reportsFilter.search}
                  onChange={(ev) => props.onFilterChange('term', ev.target.value)}
                  autoFocus
                  onClear={() => props.onFilterChange('term', '')}
                />
              </div>
              {!hasShopId && (
                <div style={{ padding: '0.5rem 1rem' }}>
                  <Autocomplete
                    fullWidth
                    title={shop}
                    value={props.filter.shopId}
                    list={props.shops}
                    ensureValue={!props.filter.shopId}
                    style={{ textOverflow: 'ellipsis' }}
                    dataConverter={(el) => ({ text: el.name, value: el.id })}
                    onChange={(shopId) => props.onFilterChange('shopId', shopId)}
                  />
                </div>
              )}
              {!hasLineId && (
                <div style={{ padding: '0.5rem 1rem' }}>
                  <Autocomplete
                    fullWidth
                    title={line}
                    value={props.filter.lineId}
                    ensureValue={!props.filter.lineId}
                    list={props.lines}
                    style={{ textOverflow: 'ellipsis' }}
                    dataConverter={(el) => ({ text: el.name, value: el.id })}
                    onChange={(lineId) => props.onFilterChange('lineId', lineId)}
                  />
                </div>
              )}
              {!props.isToday && (
                <>
                  <div style={{ padding: '0.5rem 1rem' }}>
                    <DatePicker
                      label={tr.reportsFilter.startDate}
                      style={{ marginBottom: 0, width: '100%' }}
                      onChange={(date) => {
                        const isStartDateMoreEndDate = date > props.filter.endDate
                        if (isStartDateMoreEndDate) {
                          props.onFilterChange('startDate', date, () => props.onFilterChange('endDate', date))
                        } else {
                          props.onFilterChange('startDate', date)
                        }
                      }}
                      value={props.filter.startDate}
                    />
                  </div>
                  <div style={{ padding: '0.5rem 1rem' }}>
                    <DatePicker
                      label={tr.reportsFilter.endDate}
                      style={{ marginBottom: 0, width: '100%' }}
                      onChange={(date) => {
                        const isEndDateLessStartDate = date < props.filter.startDate
                        if (isEndDateLessStartDate) {
                          props.onFilterChange('endDate', date, () => {
                            props.onFilterChange('startDate', date)
                          })
                        } else {
                          props.onFilterChange('endDate', date)
                        }
                      }}
                      value={props.filter.endDate}
                    />
                  </div>
                </>
              )}
            </form>
          </Card>
          <div style={{ padding: '1rem 0' }}>
            <Button size="small" variant="contained" color="primary" onClick={props.onSearch}>
              {tr.journal.search}
            </Button>
            {Boolean(hasLineId && hasShopId) && (
              <Button size="small" style={{ marginLeft: '0.5rem' }} onClick={handleBackToLineMonitoring}>
                {tr.lineMonitoring.back}
              </Button>
            )}
          </div>
        </div>
        <div style={{ flex: 1, maxWidth: '494px' }}>{props.children}</div>
      </div>
    </div>
  )
}

export default AppointmentsFilterLargeLayout
