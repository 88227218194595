import { PositionModel } from 'shared/models'

const getSessionShorageKey = (placeId: string | number) => `duplicate_position_${placeId}`

export interface DuplicateStoreModel {
  position: PositionModel
  back: { placeId: string | number; lineId: string | number; link?: string }
}

function setDuplicatePosition(placeId: string | number, data: DuplicateStoreModel) {
  sessionStorage.setItem(getSessionShorageKey(placeId), JSON.stringify(data))
}

function getDuplicatePosition(placeId: string | number) {
  try {
    const data = sessionStorage.getItem(getSessionShorageKey(placeId))
    if (data) {
      return JSON.parse(data) as DuplicateStoreModel
    }
  } catch (err) {
    console.warn('Position parsing failed')
  }

  return null
}

export { getDuplicatePosition, setDuplicatePosition }
