import { IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import { CheckpointStatus } from 'shared/models'
import CheckpointStatusIndicator from './checkpoint-status-indicator'
import { timeText } from './time-text'
import { statusTextMap } from './status-text-map'
import { useHistory, useParams } from 'react-router-dom'
import { checkpointMonitoring, checkpoints } from 'pages/nav'
import getMenu from './item-menu'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { useAppSelector } from 'store'
import { CheckpointItemProps } from 'pages/checkpoint/types'

function CheckpointItem(props: CheckpointItemProps) {
  const { tr, dateFormatter } = useTranslation()
  const { push } = useHistory()
  const { lineId, placeId } = useParams<{ placeId: string; lineId: string }>()
  const { canUpdateShops } = useAppSelector(getPermissionsSelector)

  const { data, checkpointId, isDiscreteLine, refDep } = props
  const { showLine, showEditHostIcon } = props

  const { date, user } = data.statusChange || {}

  const statusMap = statusTextMap()

  const timing = timeText(data.operatingFrom, data.operatingTo, data.dinnerStartTime, data.dinnerStopTime)

  const time = date ? dateFormatter(date, 'dateTime') : ''
  const firstName = user ? user.firstName : ''
  const secondName = user ? user.secondName : ''
  const statusText = statusMap[data.status]?.(time, firstName, secondName)
  const secondaryText = statusText || tr.checkpointStatus.unknown
  const primaryParams = [
    !showLine && data.lineName && `${data.lineName}, `,
    data.name,
    data.isAnyHourOpened && ` — ${tr.checkpointList.allTime}`,
    !data.isAnyHourOpened && timing,
    !!data.hostName && ` — ${data.hostName}`
  ]

  const primaryText = primaryParams.filter(Boolean).join('')

  const isStart = [CheckpointStatus.starting, CheckpointStatus.started].includes(data.status)

  const showMonitoringAction = !data.isDiscrete && !isDiscreteLine

  function itemHandleClick() {
    if (showMonitoringAction && !canUpdateShops && !showEditHostIcon) {
      push({
        pathname: checkpointMonitoring(placeId, lineId, String(data.id)),
        state: { navTo: checkpoints(placeId, lineId) }
      })
    }
  }

  const menu = getMenu({
    lineId: lineId || String(data.lineId),
    shopId: placeId,
    id: String(data.id),
    showEditHost: showEditHostIcon,
    showMonitoring: showMonitoringAction,
    canUpdateShops: canUpdateShops,
    isStart
  })

  if (!canUpdateShops && !showEditHostIcon && showMonitoringAction) {
    return (
      <ListItemButton
        disableGutters
        key={data.id}
        onClick={itemHandleClick}
        ref={refDep as any}
        selected={data.id === checkpointId}
      >
        <ListItem disablePadding style={{ padding: 0 }}>
          <ListItemIcon style={{ display: 'flex', justifyContent: 'center' }}>
            <CheckpointStatusIndicator isStart={isStart} />
          </ListItemIcon>
          <ListItemText
            primary={primaryText}
            secondary={secondaryText}
            primaryTypographyProps={{ fontWeight: 'medium', variant: 'body2' }}
          />
        </ListItem>
      </ListItemButton>
    )
  }

  return (
    <ListItem component="a" disableGutters key={data.id} onClick={itemHandleClick} ref={refDep}>
      <ListItem disablePadding style={{ padding: 0 }}>
        <ListItemIcon style={{ display: 'flex', justifyContent: 'center' }}>
          <CheckpointStatusIndicator isStart={isStart} />
        </ListItemIcon>
        <ListItemText
          primary={primaryText}
          secondary={secondaryText}
          primaryTypographyProps={{ fontWeight: 'medium', variant: 'body2' }}
        />
      </ListItem>
      {Boolean(canUpdateShops || showEditHostIcon) && (
        <div style={{ margin: '0 1rem', minWidth: '110px', textAlign: 'end' }}>
          {menu.map(({ Icon, handle, id, title, testAttr }) => (
            <Tooltip key={id} title={title}>
              <IconButton
                {...testAttr}
                onClick={handle}
                sx={{
                  '& svg': { transition: '0.2s', transform: 'translateX(0)' },
                  '&:hover, &:focus': {
                    bgcolor: 'unset',
                    '& svg:first-of-type': {
                      transform: 'translateX(-4px)'
                    }
                  }
                }}
              >
                <Icon fontSize="small" />
              </IconButton>
            </Tooltip>
          ))}
        </div>
      )}
    </ListItem>
  )
}

export { CheckpointItem }
