import React, { Component } from 'react'
import Card from 'shared/ui-kit/card'
import translate from 'shared/i18n/translate'
import Text from 'shared/ui-kit/text'
import { Form, FormGroup, FormActionGroup } from 'shared/ui-kit/form'
import { Divider, Button } from '@mui/material'
import Dropdown from 'shared/ui-kit/dropdown'
import { TextField } from 'shared/ui-kit/text-field'
import {
  Done as DoneIcon,
  Print as PrintIcon,
  ArrowUpward as PrevIcon,
  ArrowDownward as NextIcon
} from '@mui/icons-material'
import { line as lineProvider, appointmentPlaceList, appointments } from 'shared/api'
import { List } from 'shared/ui-kit/list'
import debounce from 'lodash/debounce'
import range from 'lodash/range'
import { isNameValid } from 'shared/utils/string-test'
import produce from 'immer'
import LanguageSelect from 'shared/ui-kit/language-select'
import { DatePicker } from 'features/date'
import { convertDateWithOffset } from 'shared/i18n/date-formatter'
import Autocomplete from 'shared/ui-kit/autocomplete'
import ServiceSelect from 'features/position-edit/service-select-array-always'
import { ServiceMode } from 'shared/models'
import { ChipListSelect } from 'shared/ui-kit/selects'
import Toggle from 'shared/ui-kit/toggle-wrap'
import { AppointmentContactInfoPrint } from './components/appointment-summary-print'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print'
import PageLoader from 'shared/ui-kit/page-loader/page-loader'
import { isEmail } from 'shared/utils/string-test'
import { IntlPhoneInput } from 'features/intl-phone-input'
import { InFormMessage } from './components/in-form-message'
import { validatePhone } from 'features/material-ui-phoneinput'
import { AdditionalsForm } from 'features/additionals-form'
import dayjs from 'dayjs'
import DurationPicker from 'features/time/duration-picker-timestamp'
import { getLocalizedFullName } from './utils'
import CheckpointSelect from 'features/position-edit/checkpoint-select'
import { checkpoint as checkpointApi } from 'shared/api'
import TextFieldMUI from '@mui/material/TextField'

class AppointmentCreation extends Component {
  state = {
    data: this.props.data || {
      customServiceDuration: this.props.customServiceDuration,
      lineId: this.props.lineId,
      placeId: this.props.placeId,
      participantsNumber: this.props.participantsNumber ? +this.props.participantsNumber : null,
      serviceIds: this.props.serviceIds,
      customer: { email: '', firstName: '', lastName: '', phoneNumber: '' },
      additionals: this.props.additionals
    },
    skipCustomerStage: false,
    stage: 0,
    dates: {},
    customers: [],
    customer: {},
    phoneNumber: this.props.data ? this.props.data.customer.phoneNumber : '',
    shouldPrint: false,
    loading: false,
    confirmDeleteMode: false,
    timeSlotIsNotFreeError: false,
    additionalsValidations: false,
    phoneServerValidated: false,
    checkpoints: [],
    forceShowCustomerFields: true
  }

  printContactInfoRef = React.createRef()

  componentDidMount() {
    if (this.props.positionId && this.props.placeId && this.props.lineId) {
      appointmentPlaceList
        .getCustomerInformationByPositionId(this.props.positionId, this.props.placeId, this.props.lineId)
        .then((res) => {
          this.editState((draft) => {
            draft.data.customer = res
            draft.skipCustomerStage = true
            draft.phoneServerValidated = !!res.phoneNumber
          })
        })
    } else if (this.props.customerId) {
      appointmentPlaceList.getCustomerById(this.props.customerId).then((res) => {
        if (res) {
          this.editState((draft) => {
            draft.data.customer = res
            draft.forceShowCustomerFields = true
            draft.phoneServerValidated = !!res.phoneNumber
          })
        }
      })
    }

    if (this.state.data.placeId) {
      this.handleRequestCheckpoints(this.state.data.placeId)
      this.props.onRequestLines(this.state.data.placeId)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({ data: this.props.data })
    }

    if (this.props.errors && this.props.errors.length) {
      this.props.errors.forEach((error) => this.handleError(error))
    }
  }

  handleError(error) {
    switch (error) {
      case 'TimeSlotIsNotFree': {
        this.setState({ timeSlotIsNotFreeError: true }, () => {
          this.handlePropChange('time', null)
          this.handlePropChange('date', null)
          this.setTimeStage()
        })
        break
      }
      default: {
        console.warn('Cant resolve error: ' + error)
      }
    }

    this.props.errorResolved?.(error)
  }

  startRequest = (cb) => {
    this.setState({ loading: true }, cb)
  }

  endRequest = () => {
    this.setState({ loading: false })
  }

  editState = (fn, cb) => this.setState(produce(fn), cb)

  setStage(stage) {
    this.setState({ stage })
  }

  get isEdit() {
    return this.state.data.id !== undefined && this.state.data.id !== null
  }

  get hasSelectedServices() {
    return !!(this.state.data.serviceIds && this.state.data.serviceIds.length)
  }

  setTimeStage = (callback) => {
    lineProvider
      .getServiceDateTimes({
        token: this.state.data.id,
        lineId: this.state.data.lineId,
        servicesIds: this.state.data.serviceIds || [],
        participantsNumber: this.state.data.participantsNumber || 1,
        customServiceDuration: this.state.data.customServiceDuration
          ? Math.floor(this.state.data.customServiceDuration)
          : undefined,
        customCheckpointId: this.state.data.customCheckpointId
      })
      .then((resp) => {
        this.editState((draft) => {
          const times = resp.data.times || []

          draft.stage = 1
          draft.dates = {}

          let timeSetted = false
          const localTimezone = new Date().getTimezoneOffset() * 60000

          times.forEach((time) => {
            const convertedTime = convertDateWithOffset(time.startTime, this.props.timeZoneOffset).getTime()

            const dateNoTime = new Date(convertedTime).setHours(0, 0, 0, 0)

            if (draft.dates[dateNoTime]) {
              draft.dates[dateNoTime].times.push(time)
            } else {
              draft.dates[dateNoTime] = { date: dateNoTime, times: [time] }
            }

            if (this.state.data?.time?.id === time.id) {
              draft.data.date = dateNoTime - localTimezone
              timeSetted = true
            }

            if (!timeSetted && (!draft.data.date || dateNoTime <= draft.data.date)) {
              draft.data.date = dateNoTime - localTimezone
            }
          })

          if (this.props.defaultStartTime) {
            const defaultStartTime = new Date(Number(this.props.defaultStartTime))

            if (defaultStartTime && !Number.isNaN(defaultStartTime)) {
              draft.data.date = new Date(defaultStartTime).setHours(0, 0, 0, 0) - localTimezone

              const time = times.find(
                (time) => new Date(time.startTime).setSeconds(0, 0) === new Date(defaultStartTime).setSeconds(0, 0)
              )

              if (time?.startTime) {
                draft.data.time = time.startTime
              }
            }
          }
        }, callback)
      })
      .catch(callback)
  }

  handleRequestCheckpoints = (placeId) => {
    this.setState({ checkpoints: [] })

    checkpointApi.getCheckpointList(placeId).then((res) => {
      this.setState({ checkpoints: res.data || [] })
    })
  }

  renderServiceSelectList(services, line) {
    if (!line) {
      return null
    }

    const { service } = this.props.translation.appointment

    const isNoneServiceMode = line.lineServicesMode === ServiceMode.None

    if (!services || !services.length || isNoneServiceMode) {
      return null
    }

    const isMultipleSelection = !!line.multipleServicesSelection

    const value = this.state.data.serviceIds || []

    return (
      <ServiceSelect
        arrayAlways
        disabled={this.props.disabled}
        attention={!this.hasSelectedServices}
        multiple={isMultipleSelection}
        title={service}
        value={services.filter((el) => value.includes(el.id))}
        list={services}
        participantsNumber={this.state.data.participantsNumber}
        onChange={(value) => {
          if (!value) {
            return
          }

          this.handlePropChange(
            'serviceIds',
            value.map((s) => s.id)
          )
        }}
      />
    )
  }

  renderServiceStage() {
    const passed = this.state.stage > 0
    const inactive = this.state.stage !== 0

    const { shop, line, service, comments, staffComments, staffCommentsPlaceholder, next, lineAndService } =
      this.props.translation.appointment

    const { chars } = this.props.translation.entityEdit

    const services = this.getServices().filter((s) => !s.isPrivate) || []

    const { placeId, lineId } = this.state.data

    const lines = this.getLines(placeId) || []
    const currentLine = lines?.find?.((x) => x.id === lineId)
    const requestParticipants = !!currentLine?.requestParticipantsNumber

    const selecterServiceIds = this.state.data.serviceIds || []

    const selectedServices = services.filter((el) => selecterServiceIds.includes(el.id))
    const customParticipantsNumber =
      selectedServices.length === 1 ? selectedServices[0]?.customParticipantsNumber : undefined
    const customParticipantsQuestion =
      selectedServices.length === 1 ? selectedServices[0]?.customParticipantsQuestion : undefined

    const additionalFields = (currentLine?.additionals || []).filter(
      (field) => field.type !== 'Instruction' && field.type !== 'VideoConferenceLink'
    )
    const validateAdditionals = Boolean(!additionalFields.length || this.state.additionalsValidations)

    const servicesDuration = services.length
      ? (selectedServices || []).reduce((acc, curr) => {
          const participantsNumber = this.state.data.participantsNumber || 1

          const custom = curr.customDurationPerParticipantNumber || []
          const foundCustomDuration = custom.filter((el) => el.participantNumber === participantsNumber)

          if (!foundCustomDuration.length) {
            return acc + curr.duration * participantsNumber
          }

          return acc + foundCustomDuration[0].duration
        }, 0)
      : (currentLine?.serviceTime ?? 0)

    const durationServiceAndPersons = servicesDuration

    const customServiceDurationValue = this.state.data.customServiceDuration
      ? new Date(0).setUTCSeconds(this.state.data.customServiceDuration)
      : null

    const filteredCheckpoints = (this.state.checkpoints || []).filter(
      (checkpoint) => checkpoint.lineId === this.state.data.lineId
    )

    const displayCustomDuration = currentLine?.flags?.displayCustomDuration !== false

    const editForm = (
      <div>
        <FormGroup>
          <Autocomplete
            disabled={this.isEdit}
            ensureValue
            fullWidth
            title={shop}
            value={this.state.data.placeId}
            list={this.props.shops || []}
            dataConverter={(el) => ({ text: el.name, value: el.id })}
            onChange={(id) => {
              if (!id && id !== 0) {
                return
              }

              this.handleShopIdChange(id)
            }}
          />
        </FormGroup>
        <FormGroup>
          <Autocomplete
            disabled={this.isEdit}
            ensureValue
            fullWidth
            title={line}
            value={lineId}
            list={lines ? lines.filter((l) => l.allowFutureAppointments) : []}
            dataConverter={(el) => ({ text: el.name, value: el.id })}
            onChange={(id) => {
              if (!id && id !== 0) {
                return
              }

              this.handleLineIdChange(id)
            }}
          />
        </FormGroup>
        {this.renderServiceSelectList(services, currentLine)}
        {requestParticipants && (
          <FormGroup>
            <ChipListSelect
              attention={!this.state.data.participantsNumber}
              label={
                customParticipantsQuestion ||
                currentLine?.lineParticipantsCaptionTemplate ||
                this.props.translation.getParticipantFormFromTemplates(1)
              }
              list={range(1, customParticipantsNumber ? customParticipantsNumber + 1 : currentLine.maxSimultaneous + 1)}
              value={this.state.data.participantsNumber}
              onChange={(value) => {
                if (!value) {
                  return
                }

                this.handlePropChange('participantsNumber', value)
              }}
            />
          </FormGroup>
        )}
        <FormGroup>
          {filteredCheckpoints.length > 0 && (
            <CheckpointSelect
              strict={false}
              label={this.props.translation.lineMonitoring.servicePoint}
              anyLabel={this.props.translation.lineMonitoring.any}
              list={filteredCheckpoints}
              value={
                filteredCheckpoints.find((el) => String(el.id) === String(this.state.data.customCheckpointId)) || null
              }
              onChange={(value) => {
                this.handlePropChange('customCheckpointId', value?.id)
              }}
            />
          )}
        </FormGroup>
        {!!lineId &&
          !!(!requestParticipants || this.state.data.participantsNumber) &&
          !!(services?.length === 0 || selectedServices?.length > 0) &&
          displayCustomDuration && (
            <FormGroup style={{ display: 'flex', gap: '1rem' }}>
              <DurationPicker
                disabled
                style={{ flex: '1' }}
                label={this.props.translation.lineMonitoring.standardServiceDuration}
                value={new Date(0).setUTCSeconds(durationServiceAndPersons)}
              />
              <DurationPicker
                style={{ flex: '1' }}
                label={this.props.translation.lineMonitoring.customServiceDuration}
                value={customServiceDurationValue}
                canBeEmpty
                onChange={(valMs) => {
                  if (valMs) {
                    this.handlePropChange('customServiceDuration', new Date(valMs).setUTCFullYear(1970, 0, 1) / 1000)
                  } else {
                    this.handlePropChange('customServiceDuration', undefined)
                  }
                }}
              />
            </FormGroup>
          )}
        {!!additionalFields.length && (
          <div style={{ padding: '1.5rem 1rem', background: '#f5f5f5', margin: '1rem 0' }}>
            <AdditionalsForm
              fields={additionalFields}
              values={this.state.data.additionals || {}}
              onChange={(values) => this.handlePropChange('additionals', values)}
              onValidChange={(valid) => this.setState({ additionalsValidations: valid })}
              defaultPhoneCountryCode={
                this.props.shops.find((x) => String(x.id) === String(placeId))?.defaultPhoneCountryCode
              }
              lineId={lineId}
              placeId={placeId}
              personsQuantity={this.state.data.participantsNumber}
              selectedServiceIds={(selectedServices || []).map((s) => s.id)}
            />
          </div>
        )}
        <FormGroup>
          <TextFieldMUI
            label={staffComments}
            placeholder={staffCommentsPlaceholder}
            value={this.state.data.staffComments}
            onChange={(ev) => this.handlePropChange('staffComments', ev.target.value)}
            helperText={`${this.state.data.staffComments ? this.state.data.staffComments.length : 0}/1000 ${chars}`}
            multiline
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </FormGroup>
      </div>
    )

    const participantsNotSelected = requestParticipants && !this.state.data.participantsNumber

    const isNoneServiceMode = currentLine?.lineServicesMode === ServiceMode.None

    const nextButton = (
      <FormActionGroup>
        <Button
          onClick={() => this.startRequest(() => this.setTimeStage(() => this.endRequest()))}
          startIcon={<NextIcon />}
          disabled={
            !validateAdditionals ||
            this.state.loading ||
            participantsNotSelected ||
            !lineId ||
            !this.state.data.placeId ||
            (!this.state.data.serviceIds?.length && !isNoneServiceMode)
          }
        >
          {next}
        </Button>
      </FormActionGroup>
    )

    let viewForm = null

    if (passed) {
      const selectedShop = placeId ? this.props.shops?.find?.((x) => String(x.id) === String(placeId)) : null
      const selectedLine = lineId ? selectedShop.lines?.find?.((x) => String(x.id) === String(lineId)) : null

      viewForm = (
        <div>
          {!!selectedShop && (
            <FormGroup>
              <Text type="caption">{shop}</Text>
              <Text type="body-1">{selectedShop.name}</Text>
            </FormGroup>
          )}
          {!!selectedLine && (
            <FormGroup>
              <Text type="caption">{line}</Text>
              <Text type="body-1">{selectedLine.name}</Text>
            </FormGroup>
          )}
          {!!selectedServices?.length && (
            <FormGroup>
              <Text type="caption">{service}</Text>
              <Text type="body-1">{selectedServices.map((s) => s.name).join(', ')}</Text>
            </FormGroup>
          )}
          {!!requestParticipants && (
            <FormGroup>
              <Text type="caption">
                {customParticipantsQuestion ||
                  currentLine?.lineParticipantsCaptionTemplate ||
                  this.props.translation.getParticipantFormFromTemplates(1)}
              </Text>
              <Text type="body-1">{this.state.data.participantsNumber}</Text>
            </FormGroup>
          )}
          {!!filteredCheckpoints.find((el) => String(el.id) === String(this.state.data.customCheckpointId)) && (
            <FormGroup>
              <Text type="caption">{this.props.translation.lineMonitoring.servicePoint}</Text>
              <Text type="body-1">
                {filteredCheckpoints.find((el) => String(el.id) === String(this.state.data.customCheckpointId)).name}
              </Text>
            </FormGroup>
          )}
          {!!this.state.data.customServiceDuration && (
            <FormGroup>
              <Text type="caption">{this.props.translation.lineMonitoring.customServiceDuration}</Text>
              <Text type="body-1">
                {this.props.translation.time.hmin(Math.floor(this.state.data.customServiceDuration / 60))}
              </Text>
            </FormGroup>
          )}
          {Boolean(this.state.data.comments && this.state.data.comments.length) && (
            <FormGroup>
              <Text type="caption">{comments}</Text>
              <Text type="body-1">{this.state.data.comments}</Text>
            </FormGroup>
          )}
          {Boolean(this.state.data.staffComments && this.state.data.staffComments.length) && (
            <FormGroup>
              <Text type="caption">{staffComments}</Text>
              <Text type="body-1">{this.state.data.staffComments}</Text>
            </FormGroup>
          )}
        </div>
      )
    }

    return (
      <Form
        headerContent={() => <div style={{ fontSize: '18px', color: 'rgba(0, 0, 0, 0.87)' }}>{lineAndService}</div>}
      >
        <div style={{ marginTop: -8 }} />
        {!passed && !inactive && editForm}
        {!passed && !inactive && nextButton}
        {passed && viewForm}
      </Form>
    )
  }

  shouldDisableDateHandler = (day) => {
    const timeOffsetAdd = day
    const pickerDate = dayjs(timeOffsetAdd).format('YYYY-MM-DD')
    const dates = Object.keys(this.state.dates).map((date) => dayjs(+date).format('YYYY-MM-DD'))

    return !dates.some((date) => date === pickerDate)
  }

  renderTimeStage() {
    const inactive = this.state.stage !== 1
    const passed = this.state.stage > 1
    const hasTimeslots = !!Object.keys(this.state.dates).length

    const { appointment, serverTypedError } = this.props.translation
    const { date, hour, next, prev, dateAndTime, noBookingSlotAvailable } = appointment

    const localTimezone = new Date().getTimezoneOffset() * 60000

    const times =
      this.state.data.date && this.state.dates && this.state.dates[this.state.data.date + localTimezone]
        ? this.state.dates[this.state.data.date + localTimezone].times
        : []

    const editForm = (
      <div>
        <FormGroup>
          <div style={{ display: 'flex' }}>
            <DatePicker
              label={date}
              style={{ marginRight: '4px', flex: '1' }}
              placeholder={date}
              value={this.state.data.date}
              initialFocusedDate={this.state.data.date}
              onChange={(val) => this.handlePropChange('date', +val)}
              shouldDisableDate={this.shouldDisableDateHandler.bind(this)}
              disablePast
            />
            <Dropdown
              label={hour}
              ensureValue
              style={{ marginLeft: '4px', flex: '1' }}
              value={this.state.data?.time?.id}
              data={times}
              dataConverter={(item) => ({
                value: item.id,
                text: this.props.dateFormatter(item.startTime, 'time', this.props.timeZoneId, false)
              })}
              onChange={(val) => {
                this.handlePropChange(
                  'time',
                  times.find?.((x) => x.id === val)
                )
              }}
            />
          </div>
        </FormGroup>
      </div>
    )
    const nextButton = (
      <FormActionGroup>
        <Button
          disabled={!this.state.data.time || !this.state.data.time.id || !hasTimeslots}
          onClick={() =>
            this.setState({ timeSlotIsNotFreeError: false }, () =>
              this.setStage(this.isEdit || this.state.skipCustomerStage ? 3 : 2)
            )
          }
          startIcon={<NextIcon />}
        >
          {next}
        </Button>
      </FormActionGroup>
    )

    const prevButton = (
      <FormActionGroup>
        <Button onClick={() => this.setStage(0)} startIcon={<PrevIcon />}>
          {prev}
        </Button>
      </FormActionGroup>
    )

    const viewForm = (
      <div>
        <FormGroup>
          <Text type="caption">{date}</Text>
          <Text type="body-1">{this.props.dateFormatter(this.state.data.date, 'date', 'UTC')}</Text>
        </FormGroup>
        {this.state.data.time && (
          <FormGroup>
            <Text type="caption">{hour}</Text>
            <Text type="body-1">{this.props.dateFormatter(this.state.data.time.startTime, 'time')}</Text>
          </FormGroup>
        )}
      </div>
    )

    return (
      <Form
        headerContent={() => (
          <div
            style={{ fontSize: '18px', color: !!passed || !inactive ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.28)' }}
          >
            {dateAndTime}
          </div>
        )}
      >
        <div style={{ marginTop: -8 }} />
        {!passed && !inactive && this.state.timeSlotIsNotFreeError && (
          <InFormMessage text={serverTypedError.jeFile.appointment.timeSlotIsNotFree} />
        )}

        {!passed && !inactive && !hasTimeslots && <InFormMessage type="error" text={noBookingSlotAvailable} />}
        {!passed && !inactive && hasTimeslots && editForm}
        <div style={{ display: 'flex' }}>
          {!passed && !inactive && nextButton}
          {!inactive && prevButton}
        </div>
        {passed && viewForm}
      </Form>
    )
  }

  renderCustomerStage() {
    const inactive = this.state.stage !== 2
    const passed = this.state.stage > 2 || !!this.isEdit

    const { placeId, lineId } = this.state.data
    const lines = this.getLines(placeId) || []
    const currentLine = lines.find((x) => String(x.id) === String(lineId))

    const shop = this.props.shops.find((x) => String(x.id) === String(placeId))

    const {
      next,
      prev,
      name,
      firstName,
      lastName,
      email,
      emailPlaceholder,
      phone,
      phonePlaceholder,
      customer,
      newCustomer,
      companyName
    } = this.props.translation.appointment

    const hasValue = (value = '') => value.trim().length > 0

    let supportedLanguage = shop?.supportedLanguages?.split(';')

    const companyNameField = (
      <FormGroup key={'companyNameFieldFormGroup'}>
        <TextField
          attention={!hasValue(this.state.data.customer.companyName)}
          label={companyName}
          disabled={this.isEdit}
          placeholder={companyName}
          value={this.state.data.customer.companyName}
          onChange={(val) => this.handleCustomerPropChange('companyName', val)}
        />
      </FormGroup>
    )

    const firstNameField = (
      <FormGroup key={'firstNameFieldFormGroup'}>
        <TextField
          attention={!hasValue(this.state.data.customer.firstName) || !isNameValid(this.state.data.customer.firstName)}
          label={currentLine?.lineParticipantsFirstNameCaptionTemplate || firstName}
          disabled={this.isEdit}
          placeholder={firstName}
          value={this.state.data.customer.firstName}
          onChange={(val) => this.handleCustomerPropChange('firstName', val)}
        />
      </FormGroup>
    )

    const lastNameField = (
      <FormGroup key={'lastNameFieldFormGroup'}>
        <TextField
          attention={!hasValue(this.state.data.customer.lastName) || !isNameValid(this.state.data.customer.lastName)}
          label={currentLine?.lineParticipantsLastNameCaptionTemplate || lastName}
          disabled={this.isEdit}
          placeholder={lastName}
          value={this.state.data.customer.lastName}
          onChange={(val) => this.handleCustomerPropChange('lastName', val)}
        />
      </FormGroup>
    )

    const phoneReadOnlyField = (
      <FormGroup key={'phoneReadOnlyFieldFormGroup'}>
        <TextField
          disabled
          label={phone}
          value={this.state.data.customer.phoneNumber}
          onChange={this.handlePhoneChange}
        />
      </FormGroup>
    )

    const requestPersonalInfo = currentLine?.requestPersonalInfo || {
      companyName: false,
      email: false,
      firstName: true,
      lastName: true,
      phoneNumber: true
    }

    const validationsPersonalInfo = {
      email: !this.state.data.customer.email || isEmail(this.state.data.customer.email),
      firstName:
        !requestPersonalInfo.firstName ||
        (hasValue(this.state.data.customer.firstName) && isNameValid(this.state.data.customer.firstName)),
      lastName:
        !requestPersonalInfo.lastName ||
        (hasValue(this.state.data.customer.lastName) && isNameValid(this.state.data.customer.lastName)),
      phoneNumber:
        !this.state.data.customer.phoneNumber ||
        (validatePhone(this.state.data.customer.phoneNumber, true) && this.state.phoneServerValidated)
    }

    const customerInvalid =
      !validationsPersonalInfo.email ||
      !validationsPersonalInfo.phoneNumber ||
      !validationsPersonalInfo.firstName ||
      !validationsPersonalInfo.lastName

    const getLocalizedFLNames = (locale) => {
      const localized = {
        en: () =>
          [
            requestPersonalInfo?.companyName && companyNameField,
            requestPersonalInfo?.firstName && firstNameField,
            requestPersonalInfo?.lastName && lastNameField,
            this.isEdit && phoneReadOnlyField
          ].filter(Boolean),
        fr: () =>
          [
            requestPersonalInfo?.companyName && companyNameField,
            requestPersonalInfo?.firstName && firstNameField,
            requestPersonalInfo?.lastName && lastNameField,
            this.isEdit && phoneReadOnlyField
          ].filter(Boolean),
        default: () =>
          [
            requestPersonalInfo?.companyName && companyNameField,
            requestPersonalInfo?.firstName && firstNameField,
            requestPersonalInfo?.lastName && lastNameField,
            this.isEdit && phoneReadOnlyField
          ].filter(Boolean)
      }

      return (localized[locale] || localized.default)()
    }

    const editForm = (
      <div>
        {!this.isEdit && (
          <FormGroup>
            <form autoComplete="off">
              <IntlPhoneInput
                autoComplete={'off'}
                label={phone}
                placeholder={phonePlaceholder}
                value={this.state.data.customer.phoneNumber}
                defaultCountry={shop?.defaultPhoneCountryCode}
                onChange={this.handlePhoneChange}
                attention={
                  !validatePhone(this.state.data.customer.phoneNumber, true) ||
                  (!this.state.phoneServerValidated && !!this.state.data.customer.phoneNumber)
                }
                fullWidth
              />
            </form>
          </FormGroup>
        )}
        <div>
          <FormGroup style={{ marginLeft: 0, marginRight: 0 }}>
            <List
              data={this.state.customers}
              selectedId={this.state.data.customer.id || 0}
              onSelect={this.handleSelectCustomer}
              dataConverter={(item) => ({
                id: item.id ? item.id : 0,
                primaryText: item.id ? item.phoneNumber : newCustomer,
                secondaryText: getLocalizedFullName(item, this.props.locale, requestPersonalInfo),
                tertiaryText: item.id ? item.email : null
              })}
            />
          </FormGroup>
          {(!this.state.data.customer?.id || !!this.state.forceShowCustomerFields) && (
            <>
              {getLocalizedFLNames(this.props.locale)}
              {supportedLanguage?.length > 1 && (
                <FormGroup>
                  <LanguageSelect
                    ensureValue
                    disabled={this.isEdit}
                    title={this.props.translation.lineMonitoring.language}
                    languages={supportedLanguage}
                    value={this.state.data.customer.language}
                    onChange={(lang) => {
                      this.handleCustomerPropChange('language', lang)
                    }}
                  />
                </FormGroup>
              )}
              <FormGroup>
                <TextField
                  disabled={this.isEdit}
                  label={email}
                  placeholder={emailPlaceholder}
                  value={this.state.data.customer.email || ''}
                  onChange={(val) => {
                    const email = val.replace(/\s{1,}/g, '')
                    this.handleCustomerPropChange('email', email)
                  }}
                  attention={!!this.state.data.customer.email && !isEmail(this.state.data.customer.email)}
                />
              </FormGroup>
            </>
          )}
        </div>
      </div>
    )

    const viewForm = (
      <div>
        <FormGroup>
          <Text type="caption">{name}</Text>
          <Text type="body-1">
            {getLocalizedFullName(this.state.data.customer, this.props.locale, requestPersonalInfo)}
          </Text>
        </FormGroup>
        {this.state.data.customer?.phoneNumber && (
          <FormGroup>
            <Text type="caption">{phone}</Text>
            <Text type="body-1">{this.state.data.customer.phoneNumber}</Text>
          </FormGroup>
        )}
        {Boolean(this.state.data.customer.email && this.state.data.customer.email.length) && (
          <FormGroup>
            <Text type="caption">{email}</Text>
            <Text type="body-1">{this.state.data.customer.email}</Text>
          </FormGroup>
        )}
      </div>
    )

    return (
      <Form
        headerContent={() => (
          <div
            style={{
              fontSize: '18px',
              color: this.state.stage === 3 || !inactive ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.28)'
            }}
          >
            {customer}
          </div>
        )}
      >
        <div style={{ marginTop: -8 }} />
        {!passed && !inactive && editForm}
        <div style={{ display: 'flex' }}>
          {!passed && !inactive && (
            <FormActionGroup>
              <Button disabled={customerInvalid} onClick={() => this.setStage(3)} startIcon={<NextIcon />}>
                {next}
              </Button>
            </FormActionGroup>
          )}
          {!inactive && (
            <FormActionGroup>
              <Button onClick={() => this.setStage(1)} startIcon={<PrevIcon />}>
                {prev}
              </Button>
            </FormActionGroup>
          )}
        </div>
        {!!passed && viewForm}
      </Form>
    )
  }

  renderConfirmationStage() {
    const inactive = this.state.stage !== 3

    const { updateButton, deleteButton, cancelButton, confirmDelete } = this.props.translation.entityEdit

    const { confirmDeleteMode } = this.state

    if (confirmDeleteMode) {
      return (
        <Form>
          <FormActionGroup>
            <div style={{ margin: '0 8px 16px', width: '100%' }}>{confirmDelete}</div>
            <Button id="confirm" style={{ marginRight: 8 }} onClick={this.handleDelete}>
              {deleteButton}
            </Button>
            <Button id="cancel" onClick={() => this.setStaet({ confirmDeleteMode: false })}>
              {cancelButton}
            </Button>
          </FormActionGroup>
        </Form>
      )
    }

    if (this.isEdit) {
      return (
        <Form>
          {this.state.loading && <PageLoader style={{ margin: '8px 0' }} />}
          <FormActionGroup>
            <Button onClick={this.handleUpdate} style={{ marginRight: 8 }} disabled={inactive || this.state.loading}>
              {updateButton}
            </Button>
            <Button onClick={() => this.setState({ confirmDeleteMode: true })} disabled={this.state.loading}>
              {deleteButton}
            </Button>
          </FormActionGroup>
        </Form>
      )
    }

    return (
      <Form
        headerContent={() => (
          <div style={{ fontSize: '18px', color: !inactive ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.28)' }}>
            {this.props.translation.appointment.confirmation}
          </div>
        )}
      >
        <div style={{ marginTop: -8 }} />
        {!inactive && (
          <>
            <AppointmentContactInfoPrint
              ref={this.printContactInfoRef}
              tr={this.props.translation.appointment}
              data={this.state.data}
            />
            <FormActionGroup>
              <ReactToPrint content={() => this.printContactInfoRef.current}>
                <PrintContextConsumer>
                  {({ handlePrint }) => (
                    <Button disabled={this.state.loading} onClick={handlePrint} startIcon={<PrintIcon />}>
                      {this.props.translation.appointment.printContactInfo}
                    </Button>
                  )}
                </PrintContextConsumer>
              </ReactToPrint>
            </FormActionGroup>
            <FormGroup style={{ marginBottom: 0 }}>
              <Toggle
                toggled={this.state.shouldPrint}
                label={this.props.translation.appointment.shouldPrint}
                labelstyle={{
                  color: this.state.loading ? '#bdbdbd' : 'inherit'
                }}
                disabled={this.state.loading}
                onToggle={() => this.setState({ shouldPrint: !this.state.shouldPrint })}
              />
            </FormGroup>
            {this.state.loading && <PageLoader style={{ margin: '8px 0' }} />}
            <FormActionGroup>
              <Button onClick={this.handleCreate} startIcon={<DoneIcon />} disabled={this.state.loading}>
                {this.props.translation.appointment.create}
              </Button>
              <Button
                onClick={() => this.setStage(this.state.skipCustomerStage ? 1 : 2)}
                startIcon={<PrevIcon />}
                disabled={this.state.loading}
              >
                {this.props.translation.appointment.prev}
              </Button>
            </FormActionGroup>
          </>
        )}
      </Form>
    )
  }

  handlePrint = () => {
    if (this.state.printSummary) {
      this.print()
    } else {
      appointments
        .getAppointmentPrintTemplate({ token: this.props.data.id })
        .then((resp) => this.setState({ printSummary: resp.data.recap }))
    }
  }

  render() {
    return (
      <Card>
        {this.renderServiceStage()}
        <Divider />
        {this.renderTimeStage()}
        <Divider />
        {this.renderCustomerStage()}
        <Divider />
        {this.renderConfirmationStage()}
      </Card>
    )
  }

  handlePropChange(name, val, cb) {
    this.editState((draft) => {
      draft.data[name] = val
    }, cb)
  }

  handleCustomerPropChange(name, val) {
    this.editState((draft) => {
      draft.data.customer[name] = val
    })
  }

  handlePhoneChange = debounce((val) => {
    if (val === this.state.data?.customer?.phoneNumber) {
      return
    }

    const phoneIsValid = validatePhone(val)

    if (!phoneIsValid) {
      this.editState((draft) => {
        draft.data.customer.phoneNumber = val
        draft.customers = [{}]
      })
    } else {
      this.editState(
        (draft) => {
          draft.data.customer.phoneNumber = val
          draft.phoneServerValidated = false
        },
        () => {
          this.findByPhone(val)
        }
      )
    }
  })

  findByPhone = debounce((val) => {
    lineProvider
      .getCustomers({ phoneNumber: val })
      .then((resp) => {
        if (!resp) {
          return
        }

        this.editState((draft) => {
          draft.customers = [...(resp.data || []).slice(0, 2), {}]
          draft.phoneServerValidated = true
        })
      })
      .catch(() => {
        this.setState({ phoneServerValidated: false })
      })
  }, 1200)

  handleShopIdChange = (placeId) => {
    this.props.onRequestLines(placeId)
    this.handleRequestCheckpoints(placeId)

    this.editState((draft) => {
      draft.data.placeId = placeId
      draft.data.lineId = placeId === this.state.data.placeId ? this.state.data.lineId : null
    })
  }

  handleLineIdChange = (lineId) => {
    if (lineId === this.state.data.lineId) {
      return
    }

    this.editState((draft) => {
      draft.data.lineId = lineId
      draft.data.serviceIds = []
      draft.data.participantsNumber = null
    })
  }

  handleSelectCustomer = (_, i) => {
    this.editState((draft) => {
      if (this.state.customers[i]?.id) {
        draft.data.customer = this.state.customers[i]
        draft.customers = [this.state.customers[i], {}]
      } else {
        draft.data.customer = { ...draft.data.customer, id: undefined }
        draft.customers = []
      }
    })
  }

  getLines(placeId) {
    if (!placeId) {
      return null
    }

    const lines = this.props.shops.find((x) => String(x.id) === String(placeId))?.lines

    return lines || []
  }

  getServices() {
    const { placeId, lineId } = this.state.data

    if (!placeId || !lineId) {
      return []
    }

    const shop = this.props.shops.find((x) => String(x.id) === String(placeId))
    const line = shop?.lines?.find?.((x) => String(x.id) === String(lineId))
    const services = line?.services || []

    return services
  }

  getDataProcessed() {
    const { placeId, lineId } = this.state.data
    const lines = this.getLines(placeId)
    const currentLine = lines.find((x) => String(x.id) === String(lineId))
    const requestParticipants = !!currentLine?.requestParticipantsNumber
    const participantsNumber = requestParticipants ? this.state.data.participantsNumber : 1
    const additionalValues = {}

    ;(currentLine?.additionals || [])
      .filter((field) => field.type !== 'Instruction')
      .forEach((el) => {
        if (this.state?.data?.additionals && this.state.data.additionals[el.shortName]) {
          additionalValues[el.shortName] = this.state.data.additionals[el.shortName]
        }
      })

    return { ...this.state.data, participantsNumber, additionals: additionalValues }
  }

  handleCreate = () => {
    const dataToRequest = this.getDataProcessed()
    this.startRequest(() => this.props.onCreate(dataToRequest, this.state.shouldPrint, () => this.endRequest()))
  }

  handleUpdate = () => {
    const dataToRequest = this.getDataProcessed()
    this.startRequest(() => this.props.onUpdate(dataToRequest, () => this.endRequest()))
  }

  handleDelete = () => {
    this.startRequest(() => this.props.onDelete(this.state.data.id, () => this.endRequest()))
  }
}

export default translate(AppointmentCreation)
