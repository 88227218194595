import { Form, FormGroup } from 'shared/ui-kit/form'
import { TextField } from 'shared/ui-kit/text-field'
import { DatePicker } from 'features/date'
import { useTranslation } from 'shared/i18n/translate'
import { AddList } from 'shared/ui-kit/add-list'
import { RadioButtonGroup } from 'shared/ui-kit/radio-button'
import { CampaignModelEdit, CampaignStatuses } from 'shared/models'
import { isPositiveNumber } from 'shared/utils/string-test'
import Card from 'shared/ui-kit/card'
import { Divider, FormControlLabel, Radio, Button } from '@mui/material'
import { useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { ActionText, ActionsWrapper, ButtonWithTextWrapper, ConfirmationActions } from 'shared/ui-kit/action-wrapper'
import Text from 'shared/ui-kit/text'
import { CampaignEditProps } from '../types'
import DeleteIcon from '@mui/icons-material/Delete'

function CampaignEdit(props: CampaignEditProps) {
  const { tr, dateFormatter } = useTranslation()
  const [isConfirmDelete, setConfirmDelete] = useState(false)

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(),
        startDate: Yup.number().required(),
        endDate: Yup.number().required(),
        shopIds: Yup.array().min(1)
      }),
    []
  )

  const { control, handleSubmit, formState, watch } = useForm<CampaignModelEdit>({
    resolver: yupResolver(validationSchema as any),
    defaultValues: props.data,
    mode: 'onChange'
  })

  function onSubmit(data: CampaignModelEdit) {
    props.onSave(data)
  }

  function handleTryDelete() {
    setConfirmDelete(true)
  }

  function handleCancelDelete() {
    setConfirmDelete(false)
  }

  function handleConfirmDelete() {
    props.onDelete(props.data.id || '')
  }

  const {
    name,
    namePlaceholder,
    startDate,
    startDatePlaceholder,
    endDate,
    endDatePlaceholder,
    waiting,
    running,
    passed,
    status,
    advertisementsDisplayDuration,
    secondsHint,
    shops
  } = tr.campaignEdit

  const startHour_unixtime = watch('startDate') || 0
  const endHour_unixtime = watch('endDate') || 0
  const startWithoutTime = new Date(startHour_unixtime).setHours(0, 0, 0, 0)
  const endWithoutTime = new Date(endHour_unixtime).setHours(0, 0, 0, 0)

  const timeIsValid = startWithoutTime <= endWithoutTime

  const isValid = !Object.keys(formState.errors).length && timeIsValid

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <div>
          <Form>
            <FormGroup>
              <Controller
                name="name"
                control={control}
                render={({ field: f }) => (
                  <TextField
                    label={name}
                    placeholder={namePlaceholder}
                    autofocus={true}
                    value={f.value}
                    onChange={f.onChange}
                    attention={!f.value}
                    message={`${f.value?.length || 0}/100 ${tr.entityEdit.chars}`}
                    test={(v) => v.length < 101}
                  />
                )}
              />
            </FormGroup>
            <FormGroup style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
              <Controller
                name="startDate"
                control={control}
                render={({ field: f, formState }) => (
                  <DatePicker
                    style={{ flex: 1 }}
                    label={startDate}
                    placeholder={startDatePlaceholder}
                    value={f.value}
                    onChange={(val: any) => {
                      f.onChange(isNaN(val) ? undefined : val || undefined)
                    }}
                    attention={!!formState.errors.startDate}
                  />
                )}
              />
              <Controller
                name="endDate"
                control={control}
                render={({ field: f, formState }) => (
                  <DatePicker
                    style={{ flex: 1 }}
                    label={endDate}
                    placeholder={endDatePlaceholder}
                    value={f.value}
                    onChange={(val: any) => {
                      f.onChange(isNaN(val) ? undefined : val || undefined)
                    }}
                    attention={!!formState.errors.endDate || !timeIsValid}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="displayDuration"
                control={control}
                render={({ field: f }) => (
                  <TextField
                    label={advertisementsDisplayDuration}
                    placeholder={advertisementsDisplayDuration}
                    value={f.value}
                    onChange={f.onChange}
                    test={isPositiveNumber}
                    hint={secondsHint}
                  />
                )}
              />
            </FormGroup>

            <FormGroup>
              <Controller
                name="status"
                control={control}
                render={({ field: f }) => (
                  <RadioButtonGroup
                    label={status}
                    name="status"
                    onChange={f.onChange}
                    value={f.value}
                    ensureValue={CampaignStatuses.WAITING}
                    row
                  >
                    <FormControlLabel
                      value={CampaignStatuses.WAITING}
                      control={<Radio id={'radioButtonWaiting'} />}
                      label={waiting}
                    />
                    <FormControlLabel
                      value={CampaignStatuses.RUNNING}
                      control={<Radio id={'radioButtonRunning'} />}
                      label={running}
                    />
                    <FormControlLabel
                      value={CampaignStatuses.PASSED}
                      control={<Radio id={'radioButtonPassed'} />}
                      label={passed}
                    />
                  </RadioButtonGroup>
                )}
              />
            </FormGroup>
          </Form>
          <Divider />
          <Form title={shops} expandable expanded attention={!!formState.errors.shopIds}>
            <FormGroup style={{ marginTop: 0 }}>
              <Controller
                name="shopIds"
                control={control}
                render={({ field: f }) => (
                  <AddList
                    emptyMessage={tr.common.emptyShopMessage}
                    selectedIds={f.value || []}
                    source={props.shops}
                    addButtonText={tr.campaignEdit.addShopButton}
                    onChange={f.onChange}
                    useAutocomplete
                  />
                )}
              />
            </FormGroup>
          </Form>
        </div>
        {!!props.data.id && (
          <>
            <Divider />
            <Form title={tr.entityEdit.advancedGroup} expandable>
              <FormGroup>
                {!isConfirmDelete && (
                  <ButtonWithTextWrapper>
                    <ActionText>{tr.campaignEdit.deleteTitle}</ActionText>
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={handleTryDelete}
                      startIcon={<DeleteIcon />}
                      disabled={props.processing}
                    >
                      {tr.entityEdit.deleteButton}
                    </Button>
                  </ButtonWithTextWrapper>
                )}
                {isConfirmDelete && (
                  <ButtonWithTextWrapper>
                    <ActionText>{tr.entityEdit.confirmDelete}</ActionText>
                    <ConfirmationActions>
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        disabled={props.processing}
                        onClick={handleConfirmDelete}
                      >
                        {tr.entityEdit.yes}
                      </Button>
                      <Button variant="outlined" size="small" disabled={props.processing} onClick={handleCancelDelete}>
                        {tr.entityEdit.no}
                      </Button>
                    </ConfirmationActions>
                  </ButtonWithTextWrapper>
                )}
              </FormGroup>
            </Form>
          </>
        )}
      </Card>
      <ActionsWrapper>
        <Button disabled={!isValid || props.processing} variant="contained" color="primary" size="small" type="submit">
          {props.data.id ? tr.entityEdit.updateButton : tr.entityEdit.createButton}
        </Button>
        {props.data.lastUpdate?.date && props.data.lastUpdate.user && (
          <Text type="caption">
            {tr.entityView.lastUpdate(
              props.data.lastUpdate.user?.firstName,
              props.data.lastUpdate.user?.secondName,
              dateFormatter(props.data.lastUpdate.date, 'dateTime')
            )}
          </Text>
        )}
      </ActionsWrapper>
    </form>
  )
}

export { CampaignEdit }
