import { useTranslation } from 'shared/i18n/translate'
import { ActionReason } from 'shared/models'

function Reason({ reason, comment }: { comment?: string; reason: ActionReason }) {
  const { tr } = useTranslation()

  return (
    <div style={{ margin: '0.5rem 0' }}>
      {comment ? tr.journal.actionReasonsWithComment[reason]?.(comment) : tr.journal.actionReasons[reason]}
    </div>
  )
}

export { Reason }
