import { Typography } from '@mui/material'
import Card from 'shared/ui-kit/card'
import { injectTestAttr } from 'shared/utils/test-attr'
import { StateCardProps } from '../types'

function StateCard({ title, description, onClick, isActive, ...props }: StateCardProps) {
  const color = isActive ? '#FFF' : undefined
  const background = isActive ? '#db214d' : undefined

  return (
    <Card
      onClick={onClick}
      paperStyle={{ padding: '0.75rem', cursor: 'pointer', height: '100%', width: '100%', background, color }}
      style={{ margin: 0 }}
      {...injectTestAttr(props)}
    >
      <Typography variant="body1" fontWeight="500" style={{ fontSize: '14px', lineHeight: '16px' }}>
        {title}
      </Typography>
      {!!description && (
        <Typography
          display="block"
          marginTop="0.5rem"
          variant="caption"
          style={{ opacity: 0.64, fontSize: '10px', lineHeight: '12px' }}
        >
          {description}
        </Typography>
      )}
    </Card>
  )
}

export { StateCard }
