import { Tooltip } from '@mui/material'
import { MaybeTooltipProps } from './types'

function ItemWithTooltip(props: MaybeTooltipProps) {
  return !props.title ? (
    props.children
  ) : (
    <Tooltip open={props.open || false} title={props.title} disableTouchListener arrow>
      {props.children}
    </Tooltip>
  )
}

export { ItemWithTooltip }
