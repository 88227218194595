import { useState, useEffect } from 'react'

export type ValidateConfig<T> = {
  [key in keyof T]?: (value: T[key], state: T) => boolean
}

type ValueByKey<T> = { [key in keyof T]: T[key] }

type ValidState<T> = { [key in keyof T]?: boolean }

export function useStateWithValidations<T extends { [key: string]: any }>(
  initValue: T,
  validateConfig: ValidateConfig<T>
) {
  const [data, setData] = useState<T>(initValue)
  const [validState, setValidState] = useState<ValidState<T>>({})

  useEffect(() => {
    validateAll()
  }, [data])

  function validate<K extends keyof T>(key: K, value: ValueByKey<T>[K]) {
    setValidState((prev) => {
      const newValidations = { ...prev }
      if (validateConfig[key]) {
        newValidations[key] = validateConfig[key]?.(value, data)
      }

      return newValidations
    })
  }

  function validateAll() {
    setData((prev) => {
      for (const key in validateConfig) validate(key, prev[key])
      return prev
    })
  }

  function setProperty<K extends keyof T>(key: K, value: ValueByKey<T>[K]) {
    setData((prev) => ({ ...prev, [key]: value }))
  }

  return {
    data,
    setProperty,
    validations: {
      props: validState,
      formValid: !Object.values(validState).includes(false)
    },
    setData
  }
}
