import {
  Description as AdditionalsIcon,
  CloudDownload as DownloadIcon,
  ContentCopy as CopyIcon
} from '@mui/icons-material'
import { useTranslation } from 'shared/i18n/translate'
import { getAdditionalValue } from './utils/valueMapper'
import { ItemContainer, ItemLabel, ItemValue, FieldContainer, ItemSub } from './styled'
import { file } from 'shared/api'
import { isURL } from 'shared/utils/string-test'
import { Tooltip } from '@mui/material'
import { MessageTypes, showMessage } from 'store/actions/main-layout-action-creators'
import { useAppDispatch } from 'store'
import { AdditionalItemViewProps } from './types'

function AdditionalItemView({ label, type, value, items, displayCopyButton }: AdditionalItemViewProps) {
  const { dateFormatter, tr } = useTranslation()
  const dispatch = useAppDispatch()

  const fieldType = type ? type : typeof value === 'object' ? 'Document' : 'String'
  const isUrl = typeof value === 'string' && isURL(value)

  function handleClick(ev: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (isUrl) {
      ev.stopPropagation()
      window.open(value, '_blank')
    } else if (fieldType === 'Document') {
      if (typeof value === 'object' && value.id) {
        ev.stopPropagation()
        file.download(value.id)
      }
    }
  }

  function handleTextCopy() {
    if (value) {
      navigator.clipboard.writeText(String(value))
      dispatch(showMessage(tr.common.copied, MessageTypes.Success))
    }
  }

  if (!value) {
    return null
  }

  if (fieldType === 'Instruction') {
    return null
  }

  return (
    <ItemContainer $link={fieldType === 'Document' || isUrl} onClick={handleClick}>
      {fieldType !== 'Document' && <AdditionalsIcon style={{ color: '#c1c1c1' }} />}
      {fieldType === 'Document' && <DownloadIcon style={{ color: '#c1c1c1' }} />}
      <FieldContainer>
        <ItemLabel>{label}</ItemLabel>
        {!!displayCopyButton && fieldType !== 'Document' && !isUrl && (
          <Tooltip title={tr.common.copy} arrow>
            <ItemSub onClick={handleTextCopy}>
              <ItemValue $link={false}>
                {getAdditionalValue({ type: fieldType, value, tr, dateFormatter, items })}
              </ItemValue>
              <CopyIcon style={{ width: '14px', height: '14px' }} />
            </ItemSub>
          </Tooltip>
        )}
        {!displayCopyButton && fieldType !== 'Document' && !isUrl && (
          <ItemSub>
            <ItemValue $link={false}>
              {getAdditionalValue({ type: fieldType, value, tr, dateFormatter, items })}
            </ItemValue>
          </ItemSub>
        )}
        {(fieldType === 'Document' || isUrl) && (
          <ItemValue $link={true}>{getAdditionalValue({ type: fieldType, value, tr, dateFormatter, items })}</ItemValue>
        )}
      </FieldContainer>
    </ItemContainer>
  )
}

export { AdditionalItemView }
