import { useTranslation } from 'shared/i18n/translate'
import { ListDnd, List } from 'shared/ui-kit/list'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { lineDataConvertor } from './utils/line-converter'
import { useState } from 'react'
import { LineListProps } from '../types'

function LineList(props: LineListProps) {
  const { tr } = useTranslation()
  const [monitoringOpenedIds, setMonitoringIsOpenedIds] = useState<(string | number)[]>([])

  function handleMonitoringClick(positionId: string | number) {
    return () => {
      setMonitoringIsOpenedIds((curr) => {
        if (curr.find((el) => el === positionId)) {
          return curr.filter((el) => el !== positionId)
        }

        return [...curr, positionId]
      })
    }
  }

  return (
    <DndProvider backend={HTML5Backend}>
      {!props.editMode && (
        <List
          data={props.data}
          onSelect={props.onSelect}
          dataConverter={lineDataConvertor({
            statistic: props.statistic,
            tr,
            shopId: props.placeId,
            showControls: props.showMonitoring,
            handleMonitoringClick,
            monitoringOpenedIds
          })}
        />
      )}
      {props.editMode && (
        <ListDnd
          data={props.data}
          onDropEnd={props.onDropEnd}
          dataConverter={lineDataConvertor({ shopId: props.placeId, statistic: props.statistic, tr })}
        />
      )}
    </DndProvider>
  )
}

export { LineList }
