const TEST_ATTR = 'data-test'

export function addTestAttr(key: string) {
  return { [TEST_ATTR]: key }
}

export function getTestAttrSelector(key: string) {
  return `[${TEST_ATTR}="${key}"]`
}

export function injectTestAttr(props: any) {
  return { [TEST_ATTR]: props[TEST_ATTR] }
}

export function getTestAttr() {
  return TEST_ATTR
}
