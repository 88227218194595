import { useEffect, useRef, useState } from 'react'
import css from './date-picker.module.scss'
import { DatePicker as MuiDatePicker, PickersDayProps, PickersDay } from '@mui/x-date-pickers'
import isBetween from 'dayjs/plugin/isBetween'
import { CustomPickersDay } from './components/custom-day'
import dayjs, { Dayjs } from 'dayjs'
import { DatePickerProps, DateSelect } from './types'
import { isPositiveNumber } from 'shared/utils/string-test'

function DatePicker<W>(props: DatePickerProps<W>) {
  const {
    disablePast,
    disableFuture,
    style,
    attention,
    shouldDisableDate,
    placeholder,
    onChange,
    value: initValue,
    disabled,
    label
  } = props

  const [value, setValue] = useState<Dayjs | null>(null)
  const currValue = useRef<Date | null>(null)

  function getValue() {
    if (!initValue) {
      return null
    }

    const isNumberDate = typeof initValue === 'string' && isPositiveNumber(initValue)
    const convertedValue = isNumberDate ? Number(initValue) : initValue

    const localDateOffset = new Date(convertedValue || '').getTimezoneOffset() * 60000
    const v = initValue ? new Date(convertedValue).getTime() : new Date().getTime()
    return new Date(v + localDateOffset)
  }

  useEffect(() => {
    const convertedValue = getValue()

    if (currValue.current !== convertedValue) {
      currValue.current = convertedValue
      const val = convertedValue ? dayjs(convertedValue) : null
      setValue(val)
    }
  }, [initValue])

  function handleBlur(ev: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) {
    const value = getValue()
    setValue(value ? dayjs(value) : null)
    props.onBlur?.(ev)
  }

  function handleChange(date: dayjs.Dayjs | null) {
    setValue(date)

    if (!date?.toDate() || !date?.isValid()) {
      if (props.allowClear) {
        onChange(undefined as any)
      }
      return
    }

    const rawDate = date.toDate()

    const localDateOffset = new Date(rawDate || '').getTimezoneOffset() * 60000
    const localOffset = localDateOffset
    const dateUnOffset = new Date(rawDate.getTime() - localOffset)

    const result: DateSelect<undefined> = Date.UTC(
      dateUnOffset.getUTCFullYear(),
      dateUnOffset.getUTCMonth(),
      dateUnOffset.getUTCDate()
    )

    const startWeek = date.startOf('week').toDate()
    const endWeek = date.endOf('week').toDate()

    const weekResult: DateSelect<true> = {
      startDate: startWeek.getTime() - localOffset,
      endDate: endWeek.getTime() - localOffset,
      selectedDate: result
    }

    const cahngeResult = props.weekMode === undefined ? result : weekResult

    onChange(cahngeResult as DateSelect<W>)
  }

  function renderDay(date: Dayjs, selectedDate: Dayjs, pickersDayProps: PickersDayProps<Dayjs>): JSX.Element {
    if (!selectedDate || !date) {
      return <PickersDay {...pickersDayProps} />
    }

    if (!props.weekMode) {
      return <PickersDay {...pickersDayProps} />
    }

    const start = selectedDate.startOf('week')
    const end = selectedDate.endOf('week')

    dayjs.extend(isBetween)

    const dayIsBetween = date.isBetween(start, end, 'day', '[]')
    const isFirstDay = date.isSame(start, 'date')
    const isLastDay = date.isSame(end, 'date')

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    )
  }

  const labelView = (label || attention) && (
    <div style={{ display: 'flex' }}>
      {label} {attention && <div className={css.attention} />}
    </div>
  )

  return (
    <MuiDatePicker
      disabled={disabled}
      label={labelView}
      format="DD.MM.YYYY"
      shouldDisableDate={(day) => {
        if (!day?.toDate) {
          return false
        }

        return shouldDisableDate?.(day?.toDate()) || false
      }}
      views={['year', 'month', 'day']}
      disablePast={disablePast}
      disableFuture={disableFuture}
      value={value}
      slotProps={{
        textField: {
          style,
          onBlur: handleBlur,
          placeholder,
          InputLabelProps: { shrink: props.shrink },
          helperText: props.message,
          FormHelperTextProps: { style: { color: props.messageColor || '#00000042' } }
        }
      }}
      slots={{
        day: (props) => {
          if (!props.day?.toDate) {
            return <></>
          }

          return renderDay(props.day, !value ? dayjs() : value, props)
        }
      }}
      onChange={handleChange}
    />
  )
}

export default DatePicker
