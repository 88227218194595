import { useEffect, useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { staffManagement as staffManagementApi } from 'shared/api'
import { useHistory, useParams } from 'react-router'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { GetResponseType, StaffManagementModel, StaffManagementRecordModel } from 'shared/models'
import { lineStaffManagement } from 'pages/nav'
import { showMessage, MessageTypes } from 'store/actions/main-layout-action-creators'
import StaffManagementEditDumb from './dumb/staff-management-edit'
import PageLoader from 'shared/ui-kit/page-loader'
import Container from 'shared/ui-kit/container'
import { useTitle } from 'shared/hooks/useTitle'
import { useAppDispatch } from 'store'
import { getDaySchedule, getFirstAvailableDateAfterSelectedDate } from './utils'
import { useTimeZoneFilter } from 'features/time-zone-filter'

type ParentsType = GetResponseType<StaffManagementModel>['parents']

function StaffManagementEdit() {
  const { tr, locale } = useTranslation()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { lineId, managementId, placeId } = useParams<{ placeId: string; lineId: string; managementId: string }>()

  const [isProcessing, setIsProcessing] = useState(false)

  const [data, setData] = useState<StaffManagementRecordModel | null>(null)
  const [parents, setParents] = useState<ParentsType>()
  const isLoading = data === null
  const isNew = !managementId
  const backUrl = lineStaffManagement(placeId, lineId)

  useTitle({ lineName: parents?.line?.name })

  useTimeZoneFilter(placeId)

  function showOperationMessage(
    keyTr: keyof typeof tr.staffManagementOperationMessages,
    type: MessageTypes = MessageTypes.Info
  ) {
    dispatch(showMessage(tr.staffManagementOperationMessages[keyTr], type))
  }

  useEffect(() => {
    staffManagementApi
      .getStaffManagementRecord({ lineId, managementId, shopId: placeId })
      .then((response) => {
        if (!response) {
          return
        }

        const { data, parents } = response

        setParents(parents)

        const startDate =
          !isNew || !data.schedule
            ? data.startDate
            : getFirstAvailableDateAfterSelectedDate(data.schedule || [], data.startDate) || data.startDate

        const schedule = getDaySchedule(data.schedule, startDate)

        setData({
          ...data,
          startDate,
          startHour_unixtime: isNew
            ? schedule?.openingHour_unixtime || data.startHour_unixtime
            : data.startHour_unixtime,
          endHour_unixtime: isNew ? schedule?.closingHour_unixtime || data.endHour_unixtime : data.endHour_unixtime,
          serviceWithPause: isNew ? schedule?.serviceWithPause || data.serviceWithPause : data.serviceWithPause,
          pauseStartHour_unixtime: isNew
            ? schedule?.pauseStartHour_unixtime || data.pauseStartHour_unixtime
            : data.pauseStartHour_unixtime,
          pauseEndHour_unixtime: isNew
            ? schedule?.pauseEndHour_unixtime || data.pauseEndHour_unixtime
            : data.pauseEndHour_unixtime
        })
      })
      .catch(() => {
        showOperationMessage('getError', MessageTypes.Error)
      })
  }, [])

  useEffect(() => {
    const { shop, line } = parents || {}

    if (!shop || !line) {
      return
    }

    const crumb = getCrumbs(tr.breadcrumbs)

    dispatch(
      setBreadcrumbs([
        crumb.home(),
        crumb.places(),
        crumb.place([placeId], shop.name),
        crumb.lines([placeId]),
        crumb.line([placeId, lineId], line.name),
        crumb.staffManagement([placeId, lineId]),
        isNew ? crumb.newStaffManagement([placeId, lineId]) : crumb.editStaffManagement([placeId, lineId, managementId])
      ])
    )
  }, [parents, locale])

  function handleEditCreate(data: StaffManagementModel) {
    setIsProcessing(true)

    const apiMethod = isNew
      ? staffManagementApi.createStaffManagementRecord
      : staffManagementApi.updateStaffManagementRecord

    const body = isNew ? { ...data, lineId } : data

    const showSuccess = () => {
      showOperationMessage(isNew ? 'createSuccess' : 'updateSuccess', MessageTypes.Success)
      setIsProcessing(false)
    }

    const showFail = () => {
      showOperationMessage(isNew ? 'createError' : 'updateError', MessageTypes.Success)
      setIsProcessing(false)
    }

    apiMethod(body)
      .then((response) => {
        showSuccess()

        history.push({ pathname: backUrl, state: { managementId: response?.id || managementId } })
      })
      .catch(showFail)
  }

  function handleDelete(id: string | number) {
    staffManagementApi
      .deleteStaffManagementRecord(id)
      .then(() => {
        showOperationMessage('deleteSuccess', MessageTypes.Success)
        history.push(backUrl)
      })
      .catch(() => {
        showOperationMessage('deleteError', MessageTypes.Error)
      })
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <Container>
      <StaffManagementEditDumb
        data={data}
        onSave={handleEditCreate}
        onDelete={handleDelete}
        isNew={isNew}
        isProcessing={isProcessing}
      />
    </Container>
  )
}

export { StaffManagementEdit }
export default StaffManagementEdit
