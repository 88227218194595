import { forwardRef } from 'react'
import { SummaryGroup, SummaryHeader, SummaryRoot, SummaryText, SummaryTitle } from './styled'
import { ContactInfoPrintProps } from './types'

export const AppointmentTextSummaryPrint = forwardRef<HTMLDivElement, { data: string }>(({ data }, ref) => (
  <SummaryRoot ref={ref}>{data}</SummaryRoot>
))

export const AppointmentContactInfoPrint = forwardRef<HTMLDivElement, ContactInfoPrintProps>(({ data, tr }, ref) => {
  const name = ((data.customer.firstName || '') + ' ' + (data.customer.lastName || '')).trim()

  return (
    <SummaryRoot ref={ref}>
      <SummaryHeader>{tr.contactInfo}</SummaryHeader>
      {!!name && (
        <SummaryGroup>
          <SummaryTitle>{tr.name}</SummaryTitle>
          <SummaryText>{name}</SummaryText>
        </SummaryGroup>
      )}
      {!!data?.customer?.email && (
        <SummaryGroup>
          <SummaryTitle>{tr.email}</SummaryTitle>
          <SummaryText>{data.customer.email}</SummaryText>
        </SummaryGroup>
      )}
      {!!data?.customer?.phoneNumber && (
        <SummaryGroup>
          <SummaryTitle>{tr.phone}</SummaryTitle>
          <SummaryText>{data.customer.phoneNumber}</SummaryText>
        </SummaryGroup>
      )}
    </SummaryRoot>
  )
})
