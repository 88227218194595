import { CSSProperties, useEffect, useMemo, ReactNode } from 'react'
import RadioGroup from '@mui/material/RadioGroup'
import { ControlLabel } from '../control-label'

type Props = {
  ensureValue?: string
  value: string
  onChange: (value: string) => void
  name: string
  label: string
  children?: ReactNode
  vertical?: boolean
  row?: boolean
}

function RadioButtonGroupComponent(props: Props) {
  useEffect(() => {
    if (props.ensureValue && !props.value) {
      props.onChange(props.ensureValue)
    }
  }, [])

  const style: CSSProperties = useMemo(
    () => (props.vertical ? { marginTop: 12 } : { display: 'flex', marginTop: 12, whiteSpace: 'nowrap' }),
    [props.vertical]
  )

  function handleChange(_: React.ChangeEvent<HTMLInputElement>, value: string) {
    props.onChange(value)
  }

  return (
    <div>
      <ControlLabel>{props.label}</ControlLabel>
      <RadioGroup
        aria-label={props.name}
        onChange={handleChange}
        name={props.name}
        value={props.value}
        style={style}
        row={props.row ?? false}
      >
        {props.children}
      </RadioGroup>
    </div>
  )
}

export default RadioButtonGroupComponent
export const RadioButtonGroup = RadioButtonGroupComponent
