import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useTranslation } from 'shared/i18n/translate'
import { campaigns, campaign } from 'pages/nav'
import { campaign as campaignApi } from 'shared/api'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { setBreadcrumbs, showMessage, MessageTypes } from 'store/actions/main-layout-action-creators'
import { CampaignModel, CampaignModelEdit } from 'shared/models'
import { Container } from 'shared/ui-kit/container'
import { CampaignEdit } from 'pages/campaign/dumb/campaign-edit'
import PageLoader from 'shared/ui-kit/page-loader'
import { useAppDispatch } from 'store'
import { useDataProvider } from 'features/isolated-data-provider'
import { useQuery, useQueryClient } from 'react-query'
import { campaignListKey } from './campaign-list'

function SmartCampaignEdit() {
  const history = useHistory()
  const { campaignId, mode } = useParams<{ campaignId: string; mode: string }>()
  const dispatch = useAppDispatch()
  const { tr, locale } = useTranslation()
  const queryClient = useQueryClient()
  const { places } = useDataProvider()
  const [processing, setProcessing] = useState(false)

  const { data: res, isLoading } = useQuery(['campaign', campaignId], ({ signal }) =>
    campaignApi.getCampaign(campaignId, { signal })
  )

  function createCampaign(data: CampaignModelEdit) {
    setProcessing(true)

    campaignApi
      .createCampaign(data)
      .then(() => {
        dispatch(showMessage(tr.campaignOperationMessages.createSuccess, MessageTypes.Success))
        queryClient.invalidateQueries(campaignListKey)
        history.push(campaigns())
      })
      .catch(() => {
        dispatch(showMessage(tr.campaignOperationMessages.createError, MessageTypes.Error))
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  function updateCampaign(data: CampaignModel) {
    setProcessing(true)

    campaignApi
      .updateCampaign(data)
      .then(() => {
        dispatch(showMessage(tr.campaignOperationMessages.updateSuccess, MessageTypes.Success))
        queryClient.invalidateQueries(campaignListKey)
        history.push(campaign(String(data.id)))
      })
      .catch(() => {
        dispatch(showMessage(tr.campaignOperationMessages.updateError, MessageTypes.Error))
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  function deleteCampaign(id: string | number) {
    setProcessing(true)

    campaignApi
      .deleteCampaign(id)
      .then(() => {
        dispatch(showMessage(tr.campaignOperationMessages.deleteSuccess, MessageTypes.Success))
        queryClient.invalidateQueries(campaignListKey)
        history.push(campaigns())
      })
      .catch(() => {
        dispatch(showMessage(tr.campaignOperationMessages.deleteError, MessageTypes.Error))
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  function handleSave(data: CampaignModelEdit) {
    if (!campaignId || mode === 'duplicate') {
      createCampaign({ ...data, id: undefined })
    } else {
      updateCampaign(data as CampaignModel)
    }
  }

  useEffect(setCrumbs, [locale, res])

  function setCrumbs() {
    if (!res?.data) {
      return
    }

    const crumb = getCrumbs(tr.breadcrumbs)

    const createCrumbs = [crumb.home(), crumb.campaigns(), crumb.newCampaign()]
    const editCrumbs = [
      crumb.home(),
      crumb.campaigns(),
      crumb.campaign([String(res.data.id)], res.data.name),
      mode === 'duplicate' ? crumb.duplicateCampaign([campaignId, mode]) : crumb.editCampaign([campaignId, mode])
    ]

    dispatch(setBreadcrumbs(res.data.id ? editCrumbs : createCrumbs))
  }

  if (isLoading) {
    return (
      <Container>
        <PageLoader />
      </Container>
    )
  }

  if (!res?.data) {
    return null
  }

  return (
    <Container>
      <CampaignEdit
        data={res.data}
        shops={places}
        onSave={handleSave}
        onDelete={deleteCampaign}
        processing={processing}
      />
    </Container>
  )
}

export default SmartCampaignEdit
