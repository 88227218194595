import { CSSProperties, ReactNode } from 'react'

export type TextFieldProps<T> = {
  onChange?: (value: T) => void
  value?: T
  children?: any
  autocomplete?: string
  defaultValue?: string
  attention?: boolean
  type?: string
  autofocus?: boolean
  autoFocus?: boolean
  message?: string
  invalid?: boolean
  disabled?: boolean
  label?: string
  placeholder?: string
  style?: CSSProperties
  className?: string
  test?: (val: string) => boolean
  onSelect?: (ev: any) => void
  onFocus?: (ev: any) => void
  onBlur?: (ev: any) => void
  onClick?: (ev: any) => void
  id?: string
  fullWidth?: boolean
  hint?: any
  endAdornment?: ReactNode
}

export enum TextFieldMessageTypes {
  error = 'error',
  info = 'info',
  custom = 'custom'
}

export type TextFieldMessageProps = {
  type?: TextFieldMessageTypes
  displayMessage?: boolean
  children?: ReactNode
  check?: (val: string) => boolean
}
