import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { getValidationSchema } from './utils'
import { AdditionalControl } from './additionalControl'
import { AdditionalFieldWorker, AdditionalsFormProps, FormValues } from './types'
import { useTranslation } from 'shared/i18n/translate'
import { dataConverter } from './utils/data-converter'
import { ExpressionValidations } from 'shared/models'
import { isEqual, isEqualWith } from 'lodash'
import { initWorkers } from './utils/init-workers'
import { expressionValidationsFilter, filterAdditionalFields } from './utils/fields-filter'
import { comparisonFunc } from './utils/comparision-finc'

export function AdditionalsForm({
  fields,
  values: defaultValues,
  onChange,
  lineId,
  placeId,
  defaultPhoneCountryCode,
  personsQuantity,
  selectedServiceIds,
  onValidChange,
  syncValue
}: AdditionalsFormProps) {
  const { tr, locale } = useTranslation()

  const workersRef = useRef<AdditionalFieldWorker[]>([])

  const personQuantity = useRef(personsQuantity)
  const selectedServices = useRef(selectedServiceIds || [])

  const [, setChangedPropDate] = useState(Date.now())

  const [expressionValidations, setExpressionValidations] = useState<ExpressionValidations>({})

  const additionalFields = filterAdditionalFields(fields, personQuantity.current, selectedServices.current)

  const validationSchema = getValidationSchema(additionalFields, tr, expressionValidations)

  const { control, getValues, reset, trigger, formState, watch } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'onChange'
  })

  useEffect(() => {
    if (personsQuantity !== personQuantity.current || !isEqual(selectedServices.current, selectedServiceIds)) {
      personQuantity.current = personsQuantity
      selectedServices.current = selectedServiceIds || []

      setChangedPropDate(Date.now())

      const values = getValues()

      workersRef.current.forEach((w) =>
        w.worker?.postMessage({
          target: 'Backoffice',
          locale,
          personQuantity: personQuantity.current,
          selectedServiceIds: selectedServices.current,
          values
        })
      )
    }
  }, [personsQuantity, selectedServiceIds])

  useEffect(() => {
    trigger()
  }, [expressionValidations])

  useEffect(() => {
    onValidChange?.(formState.isValid)
  }, [formState.isValid])

  useEffect(() => {
    registerWorkersForFields()
    return () => terminateWorkers()
  }, [])

  function registerWorkersForFields() {
    workersRef.current = initWorkers(fields, (fieldShortName, e) => {
      const state = { valid: e?.data?.valid, visible: e?.data?.visible, invalidMessage: e?.data?.invalidMessage }

      setExpressionValidations((curr) =>
        isEqual(curr[fieldShortName], state) ? curr : { ...curr, [fieldShortName]: state }
      )
    })

    setTimeout(() => {
      const values = getValues()

      workersRef.current.forEach((w) =>
        w.worker?.postMessage({
          target: 'Backoffice',
          locale,
          personQuantity: personQuantity.current,
          selectedServiceIds: selectedServices.current,
          values
        })
      )
    }, 0)
  }

  function terminateWorkers() {
    workersRef.current.forEach((w) => w.worker.terminate())
  }

  useEffect(() => {
    const currentValues = getValues()

    if (syncValue && !isEqualWith(currentValues, defaultValues, comparisonFunc)) {
      reset(defaultValues)
    }
  }, [defaultValues])

  useEffect(() => {
    const subscription = watch((values) => {
      workersRef.current.forEach((w) =>
        w.worker?.postMessage({
          target: 'Backoffice',
          locale,
          personQuantity: personQuantity.current,
          selectedServiceIds: selectedServices.current,
          values
        })
      )
      onChange(values)
    })
    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <form>
      {additionalFields
        .filter(expressionValidationsFilter(expressionValidations))
        .map(
          ({
            shortName: name,
            type,
            label,
            isMandatory,
            comboItems: data,
            maxContentLength,
            acceptedFileTypes,
            id
          }) => (
            <AdditionalControl
              key={name}
              type={type || 'String'}
              controlProps={{
                control,
                label,
                name,
                isMandatory,
                data,
                dataConverter,
                maxContentLength,
                acceptedFileTypes,
                id,
                lineId,
                placeId,
                defaultPhoneCountryCode
              }}
            />
          )
        )}
    </form>
  )
}
