import { useEffect } from 'react'
import css from './dropdown.module.scss'
import Text from '../text'
import { addTestAttr, injectTestAttr } from 'shared/utils/test-attr'
import { InputLabel, MenuItem, FormHelperText, Select, FormControl, IconButton } from '@mui/material'
import { Close as CloseIcon, KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material'
import { DropdownProps } from './types'

function Dropdown<T, V extends string | number | readonly string[] | undefined>(props: DropdownProps<T, V>) {
  const { onChange, label, data, dataAccessor = (x, i) => x[i], dataConverter, value } = props
  const { attention, ensureValue, formHalperText } = props
  const { fullWidth, style, id, disabled } = props

  useEffect(() => {
    if (ensureValue && Boolean(data.length)) {
      const isValueInCollection = data.find((item) => String(dataConverter(item).value) === String(value))

      if (!isValueInCollection && dataAccessor) {
        const first = dataAccessor(data, 0)
        const dt = dataConverter(first)
        onChange?.(dt.value)
      }
    }
  }, [data])

  return (
    <FormControl
      variant="standard"
      disabled={disabled}
      style={{ minWidth: label ? '272px' : undefined, width: fullWidth ? '100%' : undefined, ...(style || {}) }}
      {...injectTestAttr(props)}
    >
      {label && (
        <InputLabel shrink={props.shrink}>
          <div style={{ display: 'flex', width: 'max-content' }}>
            {label}
            {attention && <div className={css.attention} />}
          </div>
        </InputLabel>
      )}
      <Select
        {...addTestAttr('Dropdown-Select')}
        MenuProps={addTestAttr('Dropdown-Menu')}
        value={value ? value : value === 0 ? 0 : ''}
        onChange={(event) => onChange?.(event.target.value as V)}
        inputProps={{ id }}
        IconComponent={(props) => (
          <KeyboardArrowDownIcon fontSize="small" {...props} style={{ position: 'relative', marginLeft: '-1.5rem' }} />
        )}
        endAdornment={
          props.allowClear &&
          props.value && (
            <IconButton size="small" onClick={() => props.onChange?.(undefined as V)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          )
        }
        autoFocus={props.autoFocus}
      >
        {data?.map((item, index) => {
          const data = dataConverter(item)
          return (
            <MenuItem key={index} value={data.value}>
              {data.text}
              {!!data.secondary && (
                <Text size={2} fade={2} className={css.secondaryText}>
                  {data.secondary}
                </Text>
              )}
            </MenuItem>
          )
        })}
      </Select>
      {formHalperText && <FormHelperText>{formHalperText}</FormHelperText>}
    </FormControl>
  )
}

export default Dropdown
