import { ChangeEvent } from 'react'
import Switch from '@mui/material/Switch'
import { CSSProperties } from 'react'
import { WithHelper } from 'features/element-with-helper'

type SwitchChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void

type Props = {
  onToggle?: SwitchChange
  toggled?: boolean
  label?: string
  disabled?: boolean
  labelstyle?: CSSProperties
  style?: CSSProperties
  fullWidth?: boolean
  withHelper?: boolean
  tooltipKey?: string
  tag?: string
}

function ToggleCompoent(props: Props) {
  const { onToggle, toggled, label, disabled, labelstyle, style, fullWidth, withHelper, tag, tooltipKey } = props

  function handleChange(event: ChangeEvent<HTMLInputElement>, checked: boolean) {
    onToggle?.(event, event.target.checked || checked)
  }

  function renderLabel() {
    if (!label) {
      return null
    }

    if (withHelper && tooltipKey && tag) {
      return (
        <div style={{ flex: 1 }}>
          <WithHelper tooltipKey={tooltipKey} tag={tag}>
            <div style={{ color: 'inherit', ...labelstyle }}>{label}</div>
          </WithHelper>
        </div>
      )
    }

    return <div style={{ flex: 1, color: 'inherit', ...labelstyle }}>{label}</div>
  }

  const switchProps = { ...props }

  delete switchProps.fullWidth
  delete switchProps.style
  delete switchProps.tooltipKey
  delete switchProps.withHelper
  delete switchProps.toggled

  return (
    <div style={{ display: 'flex', alignItems: 'center', width: fullWidth ? '100%' : undefined, ...style }}>
      {renderLabel()}
      <Switch {...switchProps} onChange={handleChange} checked={!!toggled} disabled={disabled} />
    </div>
  )
}

export default ToggleCompoent
export const Toggle = ToggleCompoent
