import styled from '@emotion/styled'

const PositionStatWrapper = styled.div`
  display: flex;
  align-items: center;
  line-height: 18px;
`

const PositionStatIconWrapper = styled.div`
  margin-right: 4px;
  display: inline-flex;
`

export { PositionStatWrapper, PositionStatIconWrapper }
