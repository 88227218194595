import { configureStore } from '@reduxjs/toolkit'
import mainLayout from './reducers/main-layout-reducer'
import user from './reducers/user-reducer'
import viewPermission from './reducers/view-permission-reducer'
import places from './reducers/places-reducer'
import brands from './reducers/brands-reducer'
import broadcastAlerts from './reducers/broadcast-alerts-reducer'
import timeZones from './reducers/time-zones-reducer'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

const store = configureStore({
  reducer: {
    mainLayout,
    user,
    places,
    brands,
    broadcastAlerts,
    timeZones,
    viewPermission
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export { store }
