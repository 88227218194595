import Text from 'shared/ui-kit/text'
import { Form, FormGroup } from 'shared/ui-kit/form'
import { useTranslation } from 'shared/i18n/translate'
import { displayNumAsDouble } from 'shared/utils/formatters'
import { unixtimeToObject } from 'shared/i18n/date-formatter'
import { CounterModel } from 'shared/models'
import Divider from '@mui/material/Divider'
import { AdjustmentApplyMode } from 'shared/models'

type CounterViewProps = {
  data: CounterModel
}

function CounterView(props: CounterViewProps) {
  const { tr, timeZoneOffset, dateFormatter } = useTranslation()
  const { common, counters: t, time } = tr
  const { minutes } = time
  const { booleanValue } = common

  const { data: d } = props

  return (
    <Form>
      <FormGroup>
        <Text type="caption">{t.name}</Text>
        <Text type="body-1">{d.name}</Text>
      </FormGroup>
      <FormGroup>
        <Text type="caption">{t.type}</Text>
        <Text type="body-1">{t[d.type.toLowerCase()]}</Text>
      </FormGroup>
      <FormGroup>
        <Text type="caption">{t.serialNumber}</Text>
        <Text type="body-1">{d.serialNumber}</Text>
      </FormGroup>
      <FormGroup>
        <Text type="caption">{t.macAddress}</Text>
        <Text type="body-1">{d.macAddress}</Text>
      </FormGroup>
      {d.type !== 'probeRequestsRecorder' && (
        <FormGroup>
          <Text type="caption">{t.supply}</Text>
          <Text type="body-1">{t[d.supply]}</Text>
        </FormGroup>
      )}
      <FormGroup>
        <Text type="caption">{t.dataTransmission}</Text>
        <Text type="body-1">{t[d.dataTransmission]}</Text>
      </FormGroup>
      {d.startHour_unixtime && (
        <FormGroup>
          <Text type="caption">{t.startHour}</Text>
          <Text type="body-1">{unixtimeToObject(d.startHour_unixtime, timeZoneOffset)}</Text>
        </FormGroup>
      )}
      {d.endHour_unixtime && (
        <FormGroup>
          <Text type="caption">{t.endHour}</Text>
          <Text type="body-1">{unixtimeToObject(d.endHour_unixtime, timeZoneOffset)}</Text>
        </FormGroup>
      )}
      <FormGroup>
        <Text type="caption">{t.noDataAlertTime}</Text>
        <Text type="body-1">{minutes(d.noDataAlertTime)}</Text>
      </FormGroup>
      {Boolean(d.comments) && (
        <FormGroup>
          <Text type="caption">{t.comments}</Text>
          <Text type="body-1">{d.comments}</Text>
        </FormGroup>
      )}
      <FormGroup>
        <Text type="caption">{t.isActive}</Text>
        <Text type="body-1">{booleanValue(d.isActive)}</Text>
      </FormGroup>
      <FormGroup>
        <Text type="caption">{t.displayOrder}</Text>
        <Text type="body-1">{d.displayOrder}</Text>
      </FormGroup>
      <FormGroup>
        <Text type="caption">{t.installDate}</Text>
        <Text type="body-1">{dateFormatter(d.installDate)}</Text>
      </FormGroup>
      {d.supply === 'battery' && (
        <FormGroup>
          <Text type="caption">{t.lastChargeDate}</Text>
          <Text type="body-1">{dateFormatter(d.lastChargeDate)}</Text>
        </FormGroup>
      )}
      <FormGroup>
        <Text type="caption">{t.lastSignalDate}</Text>
        <Text type="body-1">{dateFormatter(d.lastSignalDate)}</Text>
      </FormGroup>
      {d.type === 'registrationPlatesReadingCamera' && (
        <div>
          <FormGroup>
            <Text type="caption">{t.actionOnRegistrationPlateReading}</Text>
            <Text type="body-1">
              {t.actionsOnRegistrationPlateReading[d.actionOnRegistrationPlateReading ?? AdjustmentApplyMode.None]}
            </Text>
          </FormGroup>
          <FormGroup>
            <Text type="caption">{t.sendOpenGateSignalInCaseOfPositiveIssue}</Text>
            <Text type="body-1">{booleanValue(d.sendOpenGateSignalInCaseOfPositiveIssue)}</Text>
          </FormGroup>
          {!!d.sendOpenGateSignalInCaseOfPositiveIssue && (
            <FormGroup>
              <Text type="caption">{t.alarmOutputName}</Text>
              <Text type="body-1">{d.alarmOutputName}</Text>
            </FormGroup>
          )}
          <FormGroup>
            <Text type="caption">{t.alertOperatorInCaseOfError}</Text>
            <Text type="body-1">{booleanValue(d.alertOperatorInCaseOfError)}</Text>
          </FormGroup>
        </div>
      )}
      {d.type !== 'probeRequestsRecorder' && d.type !== 'registrationPlatesReadingCamera' && (
        <>
          <Divider />
          <Form title={t.countGaugeGroup} expandable>
            <FormGroup>
              <Text type="caption">{t.realTimeCount}</Text>
              <Text type="body-1">{booleanValue(d.realTimeCount)}</Text>
            </FormGroup>
            <FormGroup>
              <Text type="caption">{t.entryCount}</Text>
              <Text type="body-1">{booleanValue(d.entryCount)}</Text>
            </FormGroup>
            <FormGroup>
              <Text type="caption">{t.exitCount}</Text>
              <Text type="body-1">{booleanValue(d.exitCount)}</Text>
            </FormGroup>
            {d.adjustmentApplyMode && (
              <FormGroup>
                <Text type="caption">{t.adjustmentApply}</Text>
                <Text type="body-1">{t.adjustmentApplyModes[d.adjustmentApplyMode]}</Text>
              </FormGroup>
            )}
            {d.adjustmentApplyMode !== AdjustmentApplyMode.None && d.type !== 'probeRequestsRecorder' && (
              <FormGroup>
                <Text type="caption">{t.adjustmentRatio}</Text>
                <Text type="body-1">
                  {d.adjustmentRatio ? displayNumAsDouble(d.adjustmentRatio) : displayNumAsDouble(1)}
                </Text>
              </FormGroup>
            )}
          </Form>
        </>
      )}
    </Form>
  )
}

export { CounterView }
