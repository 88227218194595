import { ISOCountry } from 'shared/countries'

export function sortCountries(a: ISOCountry, b: ISOCountry) {
  if (a.title > b.title) {
    return 1
  }

  if (a.title < b.title) {
    return -1
  }

  return 0
}
