import { useEffect, useState } from 'react'
import { LineEdit } from './edit-form'
import { lines as linesNav, line as lineNav, createCheckpoint as createCheckpointNav } from 'pages/nav'
import { useTranslation } from 'shared/i18n/translate'
import { useTimeZoneFilter } from 'features/time-zone-filter'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { showMessage, MessageTypes } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { AppTitle } from 'shared/hooks/useTitle'
import { LineModel, ParentPlaceAndLineModel } from 'shared/models'
import { getIsAdminSelector, getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { useHistory, useParams } from 'react-router'
import { line as lineApi } from 'shared/api'
import { useAppDispatch, useAppSelector } from 'store'

function SmartLineEdit() {
  const canPromoteToAdmin = useAppSelector(getIsAdminSelector)
  const { canUpdateShops } = useAppSelector(getPermissionsSelector)
  const dispatch = useAppDispatch()
  const { tr } = useTranslation()
  const params = useParams<{ lineId?: string; placeId: string }>()
  const history = useHistory()

  useTimeZoneFilter(params.placeId)

  const [line, setLine] = useState<LineModel | null>(null)
  const [parents, setParents] = useState<ParentPlaceAndLineModel | null>(null)
  const [preSaveInfo, setPreSaveInfo] = useState<LineModel | null>(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchLine()
  }, [])

  async function fetchLine() {
    setLoading(true)

    try {
      const res = await lineApi.getLine(params.lineId, params.placeId)
      setLine(res.data)
      setParents(res.parents)
      initBreadcrumbs(res.parents)
      setLoading(false)
    } catch (err) {
      history.push(linesNav(params.placeId))
    }
  }

  function initBreadcrumbs(parents) {
    const crumb = getCrumbs(tr.breadcrumbs)
    const placeId = params.placeId
    const shopName = parents.shop?.name
    const lineId = params.lineId
    const lineName = parents.line?.name

    const baseCrumbs = [crumb.home(), crumb.places(), crumb.place([placeId], shopName), crumb.lines([placeId])]
    const createCrumbs = [crumb.newLine([placeId])]
    const editCrumbs = [crumb.line([placeId, lineId || ''], lineName), crumb.editLine([placeId, lineId || ''])]

    dispatch(setBreadcrumbs([...baseCrumbs, ...(lineId ? editCrumbs : createCrumbs)]))
  }

  async function handleSave(data: LineModel) {
    setPreSaveInfo(data)
    setLoading(true)

    if (params.lineId) {
      try {
        await lineApi.updateLine(data)
        dispatch(showMessage(tr.lineOperationMessages.updateSuccess, MessageTypes.Success))
        history.push(lineNav(params.placeId, params.lineId))
      } catch (err) {
        setLoading(false)
      } finally {
        setLoading(false)
      }
    } else {
      try {
        const res = await lineApi.createLine({ ...data, shopId: params.placeId })
        dispatch(showMessage(tr.lineOperationMessages.createSuccess, MessageTypes.Success))

        if (res?.lineId) {
          history.push(createCheckpointNav(params.placeId, res?.lineId))
        } else {
          history.push(linesNav(params.placeId))
        }
      } catch (err) {
        setLoading(false)
      } finally {
        setLoading(false)
      }
    }
  }

  async function handleDelete(id: string | number) {
    setPreSaveInfo(data)
    setLoading(true)

    try {
      await lineApi.deleteLine(id)
      dispatch(showMessage(tr.lineOperationMessages.deleteSuccess, MessageTypes.Success))
      history.push(linesNav(params.placeId))
    } catch (err) {
      setLoading(false)
    }
  }

  async function handleDuplicate(data: {
    placeId: string | number
    lineId: string | number
    targetPlaceId: string | number
  }) {
    setLoading(true)

    try {
      const { placeId, lineId } = await lineApi.duplicateLine(data)
      dispatch(showMessage(tr.lineOperationMessages.duplicateSuccess, MessageTypes.Success))

      if (placeId && lineId) {
        history.push(lineNav(String(placeId), String(lineId)))
      }
    } catch (err) {
      setLoading(false)
    }
  }

  function handleBackToLine() {
    history.push(lineNav(params.placeId, params.lineId || ''))
  }

  if (!line) {
    return null
  }

  const data = preSaveInfo ? preSaveInfo : line
  const internalData = data.additionals ? data : { ...data, additionals: [] }

  return (
    <>
      {!!parents?.line?.name && <AppTitle lineName={parents.line.name} />}
      <LineEdit
        lineName={parents?.line?.name}
        data={internalData}
        onSave={handleSave}
        onDelete={handleDelete}
        canPromoteToAdmin={canPromoteToAdmin}
        canDuplicateLine={canUpdateShops}
        shopId={params.placeId}
        onDuplicate={handleDuplicate}
        onBackToLine={handleBackToLine}
        isLoading={loading}
      />
    </>
  )
}

export default SmartLineEdit
