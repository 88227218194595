import { Form, FormGroup } from 'shared/ui-kit/form'
import Toggle from 'shared/ui-kit/toggle-wrap'
import { useTranslation } from 'shared/i18n/translate'
import { Controller } from 'react-hook-form'
import { GroupProps } from '../types'
import { Card } from '@mui/material'
import { WeekdaysScheduler } from 'features/weekdays-scheduler'

function OpeningHoursGroup({ control }: GroupProps) {
  const { tr } = useTranslation()

  const { closeAllServicePointsAtMidnight, requestCommentOnClosingServicePoint, servicePointCanAlwaysBeOpen } =
    tr.lineEdit

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <Card>
        <Form>
          <FormGroup>
            <Controller
              name="servicePointCanAlwaysBeOpen"
              control={control}
              render={({ field: f }) => (
                <Toggle
                  toggled={f.value}
                  label={servicePointCanAlwaysBeOpen}
                  onToggle={(_, val) => f.onChange(val)}
                  withHelper={true}
                  tag="line"
                  tooltipKey="servicePointCanAlwaysBeOpen"
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              name="requestCommentOnClosingServicePoint"
              control={control}
              render={({ field: f }) => (
                <Toggle
                  toggled={f.value}
                  label={requestCommentOnClosingServicePoint}
                  onToggle={(_, val) => f.onChange(val)}
                  withHelper={true}
                  tag="line"
                  tooltipKey="requestCommentOnClosingServicePoint"
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              name="closeAllServicePointsAtMidnight"
              control={control}
              render={({ field: f }) => (
                <Toggle
                  toggled={f.value}
                  label={closeAllServicePointsAtMidnight}
                  onToggle={(_, val) => f.onChange(val)}
                  withHelper={true}
                  tag="line"
                  tooltipKey="closeAllServicePointsAtMidnight"
                />
              )}
            />
          </FormGroup>
        </Form>
      </Card>
      <Card>
        <Controller
          name="schedule"
          control={control}
          render={({ field: f }) => <WeekdaysScheduler value={f.value || []} onChange={f.onChange} />}
        />
      </Card>
    </div>
  )
}

export { OpeningHoursGroup }
