const today = new Date().setUTCHours(0, 0, 0, 0)
const tomorrow = today + 1000 * 60 * 60 * 24 * 7
const lastWeek = today - 1000 * 60 * 60 * 24 * 7

const defaultFilters = {
  startDate: lastWeek,
  endDate: tomorrow,
  term: '',
  shopId: null,
  lineId: null
}

export { defaultFilters, today, tomorrow }
