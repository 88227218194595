import { AcceptationState } from 'shared/models'
import AssignmentIcon from '@mui/icons-material/Assignment'
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import { JSXElementConstructor } from 'react'

export const acceptationStateColors: Record<AcceptationState, string> = {
  accepted: '#4caf50',
  expired: '#f44336',
  refused: '#f44336',
  requested: '#c1c1c1'
}

export const acceptationStateIcons: Record<AcceptationState, JSXElementConstructor<any>> = {
  accepted: AssignmentTurnedInIcon,
  expired: AssignmentLateIcon,
  refused: AssignmentLateIcon,
  requested: AssignmentIcon
}
