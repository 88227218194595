import { ComboItem } from 'shared/models'
import { reorder } from './utils/reorder'
import { ComboListItem } from './combo-list-item'
import { ComboListProps } from './types'

export function ComboList({ items, brandId, onChange, additionalId, placeId }: ComboListProps) {
  function handleMoveCard(dragIndex: number, hoverIndex: number) {
    onChange(reorder(items, dragIndex, hoverIndex))
  }

  function handleChangeComboItem(index: number) {
    return (value: ComboItem) => {
      const newComboItems = [...(items || [])]
      newComboItems[index] = value
      onChange(newComboItems)
    }
  }

  function handleRemoveComboItem(index: number) {
    return () => {
      const newComboItems = [...(items || [])]
      newComboItems.splice(index, 1)
      onChange(newComboItems)
    }
  }

  return (
    <>
      {(items || []).map((comboItem, index) => (
        <ComboListItem
          key={comboItem.id || comboItem.newId || index}
          id={String(comboItem.id) || String(comboItem.newId) || `${index}`}
          index={index}
          item={comboItem}
          onChange={handleChangeComboItem(index)}
          onRemove={handleRemoveComboItem(index)}
          brandId={brandId}
          moveCard={handleMoveCard}
          additionalId={additionalId}
          placeId={placeId}
        />
      ))}
    </>
  )
}
