import { ReactNode } from 'react'
import Toggle from '../toggle-wrap'
import { ControlLabel } from '../control-label'
import IconButton from '@mui/material/IconButton'
import { ToggleOnOutlined as ToggleOnIcon, ToggleOffOutlined as ToggleOffIcon } from '@mui/icons-material'
import AttentionPoint from '../attention-point'

type Props<T, V> = {
  list: T[]
  labelExtractor: (item: T) => string
  valueExtractor?: (item: T) => V
  dataConverter?: (item: T) => any
  compare: (valueItem: V, listItem: T) => boolean
  value: V[]
  disabled?: boolean
  label?: ReactNode
  onChange: (value: V[]) => void
  attention: boolean
}

export default function ToggleList<T, V>(props: Props<T, V>) {
  const {
    list = [],
    value = [],
    labelExtractor = (item) => {
      return typeof item === 'string' ? item : 'GET_LABEL_ERROR'
    },
    dataConverter = (item) => item,
    compare = (valueItem, listItem) => (valueItem as any) === (listItem as any),
    disabled = false,
    onChange,
    label,
    attention,
    valueExtractor = (v) => v
  } = props

  const convertedList = list.map((item) => valueExtractor(dataConverter(item)))
  const isAllEnabled = list.length === value.length

  function isSelected(value, item) {
    return Boolean(value.find((valueItem) => compare(valueItem, item)))
  }

  function onToggleChangeHandler(toggleValue: boolean, item) {
    const newValue = toggleValue ? [...value, valueExtractor(item)] : value.filter((v) => !compare(v, item))
    onChangeHandler(newValue)
  }

  function onChangeHandler(value) {
    onChange?.(value)
  }

  function renderActionButton() {
    const Icon = isAllEnabled ? ToggleOnIcon : ToggleOffIcon
    return (
      <IconButton
        disabled={disabled}
        onClick={() => onChangeHandler(isAllEnabled ? [] : convertedList)}
        style={{ marginLeft: 4 }}
        size="small"
      >
        <Icon color="action" />
      </IconButton>
    )
  }

  function renderLabel() {
    if (!label) {
      return
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: -8 }}>
        <ControlLabel style={{ margin: 'unset' }}>{label}</ControlLabel>
        {renderActionButton()}
        {attention && <AttentionPoint style={{ alignSelf: 'start' }} />}
      </div>
    )
  }

  return (
    <div>
      {renderLabel()}
      {list.map((item, index) => {
        const dataItem = dataConverter(item)
        return (
          <Toggle
            disabled={disabled}
            key={index}
            toggled={isSelected(value, dataItem)}
            label={labelExtractor(item)}
            onToggle={(e, toggleValue) => onToggleChangeHandler(toggleValue, dataItem)}
          />
        )
      })}
    </div>
  )
}
