import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BkmbKeys, CrumbRes } from 'shared/utils/@breadcrumbs/types'

export enum MessageTypes {
  Default = 'default',
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info'
}

export type MessageModel = {
  type: MessageTypes
  text: string
  stamp: number
  duration?: number
}

export interface MainLayoutState {
  messages: MessageModel[]
  breadcrumbs: CrumbRes<BkmbKeys>[]
  hideTopBar: boolean
}

const initialState: MainLayoutState = {
  messages: [],
  breadcrumbs: [],
  hideTopBar: false
}

const mainLayoutSlice = createSlice({
  name: 'mainLayout',
  initialState,
  reducers: {
    setBreadcrumbs: (state, action: PayloadAction<CrumbRes<BkmbKeys>[]>) => {
      state.breadcrumbs = action.payload
    },
    showMessage: (state, action: PayloadAction<MessageModel>) => {
      state.messages.push(action.payload)
    },
    removeMessage: (state, action: PayloadAction<number>) => {
      state.messages = state.messages.filter((el) => el.stamp !== action.payload)
    },
    hideTopBar: (state) => {
      state.hideTopBar = true
    },
    showTopBar: (state) => {
      state.hideTopBar = false
    }
  }
})

export const { removeMessage, setBreadcrumbs, showMessage, hideTopBar, showTopBar } = mainLayoutSlice.actions

export default mainLayoutSlice.reducer
