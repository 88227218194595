import { List, ListItemButton, ListItemText, ListSubheader } from '@mui/material'
import css from './style.module.scss'
import Card from 'shared/ui-kit/card'
import { PlaceModelSimplified } from 'shared/models'
import { countries } from 'shared/countries'
import { GroupsType, PlaceListProps } from './types'

function PlaceList({ data, onSelect, countriesValue }: PlaceListProps) {
  function groupByCountry(items: PlaceModelSimplified[]) {
    const groupsDict: GroupsType = items.reduce((temp, item) => {
      const groupName = (item?.country || '#') + '/' + (item?.city || '#')

      if (temp[groupName]) {
        temp[groupName] = [...temp[groupName], item]
      } else {
        temp[groupName] = [item]
      }

      return temp
    }, {})

    const groups = Object.entries(groupsDict)
      .map((el) => ({
        country: el[0].split('/')[0],
        city: el[0].split('/')[1],
        places: el[1]
      }))
      .sort((a, b) => {
        if (a.country === '#') return 1
        if (b.country === '#') return -1
        if (a.country < b.country) return -1
        if (a.country > b.country) return 1
        return 0
      })
    return groups
  }

  const groupsShops = groupByCountry(data)

  return (
    <Card style={{ margin: 0 }}>
      <List disablePadding>
        {groupsShops.map((group, i) => (
          <li key={`section-${group.country}-${group.city}-${i}`}>
            <ul style={{ padding: 0 }}>
              <ListSubheader
                className={css.listSubheader}
                style={{ borderRadius: i === 0 ? '0.5rem 0.5rem 0 0' : undefined }}
              >
                {`${countries.getCountryName(countriesValue, group.country) || '#'}, ${group.city}`}
              </ListSubheader>
              {group.places.map((place, i) => (
                <ListItemButton key={`item-${group.country}-${place.name}-${i}`} onClick={() => onSelect(place)}>
                  <ListItemText primary={place.name} secondary={place.description} />
                </ListItemButton>
              ))}
            </ul>
          </li>
        ))}
      </List>
    </Card>
  )
}

export { PlaceList }
