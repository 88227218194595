import { Additional, FieldWorkerMessage } from 'shared/models'
import { AdditionalFieldWorker } from '../types'

function createWorker(
  fieldShortName: string,
  fieldExpression: string,
  onmessage: (fieldShortName: string, e: MessageEvent<FieldWorkerMessage>) => void
) {
  const codeStr = `self.onmessage=function(e){ const data = e?.data; const values = data?.values || {}; const locale = data?.locale || 'en'; const selectedServiceIds = data?.selectedServiceIds || []; const personQuantity = data?.personQuantity || 1; const target = data?.target; let valid; let visible; let invalidMessage; const getResult = (data) => { ${fieldExpression} }; getResult(); postMessage({ visible, valid, invalidMessage });}`

  let worker: Worker | undefined

  try {
    const blob = new Blob([codeStr], { type: 'application/javascript' })

    worker = new Worker(URL.createObjectURL(blob), {
      name: `additional-field_${fieldShortName}`,
      credentials: 'same-origin'
    })

    worker.onmessage = (e) => {
      onmessage(fieldShortName, e)
    }
  } catch (e) {
    console.log('Error creating blob', e)
  }

  return worker
}

function initWorkers(
  f: Additional[],
  onmessage: (fieldShortName: string, e: MessageEvent<FieldWorkerMessage>) => void
) {
  return f
    .filter((field) => !!field.visibilityAndValidationExpression && !!field.shortName)
    .map((field) => {
      const worker = createWorker(field.shortName, field.visibilityAndValidationExpression || '', onmessage)

      if (!worker) {
        return
      }

      return { field, worker }
    })
    .filter(Boolean) as AdditionalFieldWorker[]
}

export { initWorkers }
