import { useEffect, useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { getIsolatedBrandList } from 'store/actions/brand-action-creators'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import Container from 'shared/ui-kit/container'
import PageLoader from 'shared/ui-kit/page-loader'
import { useHistory, useLocation } from 'react-router'
import { getBrandListSelector } from 'store/selectors/brandListSelectors'
import { useAppDispatch, useAppSelector } from 'store'
import { useDataProvider } from 'features/isolated-data-provider'
import { customers as customersApi } from 'shared/api'
import { useQuery } from 'react-query'
import { Storage as EmptyIcon } from '@mui/icons-material'
import EmptyScreen from 'shared/ui-kit/empty-screen'
import Customer from './customer'
import { journal } from 'pages/nav'
import { BrandFilter, PlaceFilter } from 'features/filters'
import { Clear as ClearIcon } from '@mui/icons-material'
import { SubBar } from 'shared/ui-kit/sub-bar'
import { Bar } from 'shared/ui-kit/bar'
import { CustomerModel } from 'shared/models'

function Customers() {
  const history = useHistory()
  const location = useLocation()

  const [brandId, setBrandId] = useState<string | number | null>(
    new URLSearchParams(location.search).get('brandId') || null
  )
  const [shopId, setShopId] = useState<string | number | null>(
    new URLSearchParams(location.search).get('shopId') || null
  )
  const [term, setTerm] = useState<string>(new URLSearchParams(location.search).get('customerId') || '')
  const { tr } = useTranslation()
  const brands = useAppSelector(getBrandListSelector)
  const dispatch = useAppDispatch()

  const { places } = useDataProvider()

  const { data, isFetching } = useQuery(
    ['getCustomer', shopId, brandId, term],
    ({ signal }) => customersApi.getCustomer({ shopId, brandId, term }, { signal }),
    { enabled: term?.length > 2 }
  )

  useEffect(() => {
    const crumb = getCrumbs(tr.breadcrumbs)
    dispatch(setBreadcrumbs([crumb.home(), crumb.reports(), crumb.customers()]))

    if (!brands) {
      dispatch(getIsolatedBrandList())
    }
  }, [])

  useEffect(() => {
    const params = new URLSearchParams()
    if (term) {
      params.set('customerId', term)
    }
    if (brandId) {
      params.set('brandId', String(brandId))
    }
    if (shopId) {
      params.set('shopId', String(shopId))
    }

    history.replace({ search: params.toString() })
  }, [term, brandId, shopId])

  function handleChangeTerm(ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setTerm(ev.target.value)
  }

  function handleTermClear() {
    setTerm('')
  }

  function handleBrandChange(brandId: string | number | null) {
    setBrandId(brandId)
    setShopId(null)
  }

  function handleShopChange(shopId: string | number | null) {
    setShopId(shopId)
  }

  function handleNav(id) {
    setTerm(id || '')
  }

  function handleUsesSelect(item: CustomerModel) {
    return (id?: string) => {
      const res = item?.lastUses?.find((el) => el.id === id)

      if (res) {
        const positionId = res?.id
        const date = res.date
        window.open(`#${journal()}?term=p${positionId}&date=${date}`, '_blank')
      }
    }
  }

  if (!brands) {
    return <PageLoader />
  }

  return (
    <>
      {!!places && !!brands && (
        <Bar>
          <div style={{ width: '100%', maxWidth: '640px' }}>
            <SubBar style={{ display: 'flex', gap: '0.5rem', padding: '0.5rem 0' }}>
              {brands?.length > 1 && <BrandFilter brands={brands} brandId={brandId} onChange={handleBrandChange} />}
              {places && !brandId && <PlaceFilter places={places} placeId={shopId} onChange={handleShopChange} />}
              <TextField
                style={{ flex: 1, minWidth: '130px' }}
                value={term}
                onChange={handleChangeTerm}
                placeholder={tr.userList.search}
                label={tr.userList.search}
                autoFocus
                slotProps={{
                  inputLabel: { shrink: true },
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton disabled={!term} onMouseDown={handleTermClear}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                }}
              />
            </SubBar>
          </div>
        </Bar>
      )}
      <Container>
        {isFetching && <PageLoader />}
        {!isFetching && !data?.data?.length && <EmptyScreen iconClass={EmptyIcon} text={tr.common.noData} />}
        {!isFetching &&
          data?.data?.map?.((item) => (
            <Customer
              key={item.id}
              data={item}
              nextId={data.nextId}
              prevId={data.prevId}
              onNav={handleNav}
              onUsesSelect={handleUsesSelect(item)}
            />
          ))}
      </Container>
    </>
  )
}

export default Customers
