import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type ViewPermissionState = {
  blocked: boolean
}

const initialState: ViewPermissionState = {
  blocked: false
}

const viewPermissionSlice = createSlice({
  name: 'viewPermission',
  initialState,
  reducers: {
    setViewPermissionBlocked: (state, action: PayloadAction<boolean>) => {
      state.blocked = action.payload
    }
  }
})

export const { setViewPermissionBlocked } = viewPermissionSlice.actions
export default viewPermissionSlice.reducer
