import { Input, IconButton, InputAdornment, InputProps } from '@mui/material'
import { Clear as ClearIcon } from '@mui/icons-material'
import React from 'react'
import { injectTestAttr } from 'shared/utils/test-attr'

type Props = {
  onClear: () => void
  clearDisabled?: boolean
} & InputProps

export function SearchInput({ onClear, clearDisabled, ...props }: Props) {
  function handleClear(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault()
    onClear()
  }

  return (
    <Input
      inputProps={injectTestAttr(props)}
      endAdornment={
        <InputAdornment position="end">
          <IconButton disabled={clearDisabled !== undefined ? clearDisabled : !props.value} onMouseDown={handleClear}>
            <ClearIcon />
          </IconButton>
        </InputAdornment>
      }
      {...props}
    />
  )
}
