import axios from 'axios'
import { TranslationsAPI } from 'shared/models'

const translations: TranslationsAPI = {
  getTranslationConfig(data, config) {
    return axios.get('getTranslationConfig', { params: data, ...config })
  },
  getTranslationList(data, config) {
    return axios.get('getTranslationList', { params: data, ...config })
  },
  getTranslation(id, config) {
    return axios.get('getTranslation', { params: { id }, ...config })
  },
  getTranslationByBrandAndSourceText(data, config) {
    return axios.get('getTranslationByBrandAndSourceText', { params: data, ...config })
  },
  createTranslation(data, config) {
    return axios.post('createTranslation', data, config)
  },
  updateTranslation(data, config) {
    return axios.put('updateTranslation', data, config)
  },
  deleteTranslation(id, config) {
    return axios.delete('deleteTranslation', { data: id, headers: { 'Content-Type': 'application/json' }, ...config })
  }
}

export default translations
