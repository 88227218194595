import Card from 'shared/ui-kit/card'
import { useTranslation } from 'shared/i18n/translate'
import { ServiceLineGroup, AppointmentFromService } from 'shared/models'
import { useState } from 'react'
import { ChipListSelect } from 'shared/ui-kit/selects'
import { Form, FormGroup } from 'shared/ui-kit/form'
import range from 'lodash/range'
import Divider from '@mui/material/Divider'
import DurationPicker from 'features/time/duration-picker-timestamp'

type Props = {
  data?: ServiceLineGroup[] | undefined
  disabled?: boolean
  onFilterChange: (data: {
    services: AppointmentFromService[]
    participantsNumber: number | undefined
    customServiceDuration?: number
  }) => void
}

function AppointmentFromServiceFormSearch({ data = [], onFilterChange, disabled = false }: Props) {
  const { tr } = useTranslation()

  const [selectedTermServices, setSelectedTermServices] = useState<AppointmentFromService[]>([])
  const [participantsNumber, setParticipantsNumber] = useState<number | undefined>()
  const [customServiceDuration, setCustomServiceDuration] = useState<number | undefined>()

  const { appointmentCreateFromService } = tr

  const customServiceDurationValue = customServiceDuration
    ? new Date(0).setUTCSeconds(customServiceDuration)
    : undefined

  if (!data) {
    return null
  }

  return (
    <Card>
      <Form>
        {data.map((d) => {
          const selectedServicesInGroup = d.services.filter((s) =>
            selectedTermServices.find((sts) => sts.id === s.id && sts.lineName === d.lineName)
          )

          return (
            <FormGroup key={d.lineName}>
              <ChipListSelect
                multiple={false}
                attention={!selectedTermServices?.length}
                disabled={disabled}
                value={selectedServicesInGroup.length ? selectedServicesInGroup[0] : undefined}
                label={d.lineName}
                list={d.services}
                onChange={(service) => {
                  if (!service) {
                    return
                  }

                  setParticipantsNumber(undefined)
                  setSelectedTermServices([service])

                  if (service) {
                    onFilterChange({ services: [service], participantsNumber: undefined })
                  }
                }}
                compare={(valueItem, listItem) => valueItem?.id === listItem.id}
                labelExtractor={(item) => (
                  <div style={{ whiteSpace: 'break-spaces', wordBreak: 'break-all' }}>{!!item && item.name}</div>
                )}
              />
            </FormGroup>
          )
        })}
        {!!selectedTermServices.length && (
          <>
            <Divider />
            <FormGroup>
              <ChipListSelect
                attention={!participantsNumber}
                disabled={disabled}
                label={appointmentCreateFromService.numberParticipants}
                list={range(1, Math.max(...selectedTermServices.map((sts) => sts.maxParticipantsNumber)) + 1)}
                value={participantsNumber}
                onChange={(value) => {
                  setParticipantsNumber(value)
                  if (selectedTermServices.length && value) {
                    onFilterChange({
                      services: selectedTermServices,
                      participantsNumber: value,
                      customServiceDuration
                    })
                  }
                }}
              />
            </FormGroup>
            <FormGroup>
              <DurationPicker
                style={{ flex: '1' }}
                label={tr.lineMonitoring.customServiceDuration}
                value={customServiceDurationValue}
                canBeEmpty
                onChange={(valMs) => {
                  const value = valMs ? new Date(valMs).setUTCFullYear(1970, 0, 1) / 1000 : undefined

                  setCustomServiceDuration((curr) => {
                    if (curr !== value) {
                      onFilterChange({
                        services: selectedTermServices,
                        participantsNumber,
                        customServiceDuration: value ? Math.floor(value) : undefined
                      })
                    }

                    return value
                  })
                }}
              />
            </FormGroup>
          </>
        )}
      </Form>
    </Card>
  )
}

export { AppointmentFromServiceFormSearch }
