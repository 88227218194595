import { PositionAction, PositionState } from 'shared/models'
import { getOverValue } from './get-over-value'
import { ActionItem } from '../types'

function getActionsFromPositionState(
  actions: ActionItem[],
  status: PositionState,
  readonly?: boolean,
  sequentiallyPerformingActionsOnPosition?: boolean
) {
  if (readonly) {
    return []
  }

  const statusToActions = {
    [PositionState.Joined]: [...getOverValue(actions, PositionAction.Join)],
    [PositionState.InLineHere]: [...getOverValue(actions, PositionAction.SetInLineHere)],
    [PositionState.ValidForService]: [...getOverValue(actions, PositionAction.ValidateForService)],
    [PositionState.CalledToCheckpoint]: [...getOverValue(actions, PositionAction.CallToCheckpoint)],
    [PositionState.MovingToCheckpoint]: [...getOverValue(actions, PositionAction.CallToCheckpoint)],
    [PositionState.NearCheckpoint]: [...getOverValue(actions, PositionAction.SetNearCheckpoint)],
    [PositionState.InService]: [...getOverValue(actions, PositionAction.StartService)],
    [PositionState.ManualFinished]: [...getOverValue(actions, PositionAction.FinishService)]
  }

  if (sequentiallyPerformingActionsOnPosition) {
    return statusToActions[status] ? [statusToActions[status][0]] : []
  }

  return statusToActions[status] || []
}

export { getActionsFromPositionState }
