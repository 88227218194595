import { CSSProperties } from 'react'
import css from './attention-point.module.scss'
import { useTheme } from '@mui/material/styles'

type Props = {
  style?: CSSProperties
}

function AttentionPoint({ style }: Props) {
  const theme = useTheme()

  return <div style={{ backgroundColor: theme.palette.error.main, ...style }} className={css.attention} />
}

export { AttentionPoint }
export default AttentionPoint
