import { Button, Typography } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import { checkpointHost, checkpoints, editLine } from 'pages/nav'
import { NavLink } from 'react-router-dom'
import { LineInactiveState } from 'shared/models'
import { useAppSelector } from 'store'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { InactiveReasonProps } from './types'

function InactiveReason({ lineId, reason, shopId, isSingleCheckpoint, checkpointId }: InactiveReasonProps) {
  const { tr } = useTranslation()
  const { canUpdateShops } = useAppSelector(getPermissionsSelector)

  const inactiveReasonsTr = tr.lineMonitoring.inactiveReasons

  return (
    <div style={{ marginTop: '1.5rem' }}>
      {!isSingleCheckpoint && reason === LineInactiveState.NoActiveCheckpoints && (
        <>
          <Typography variant="h5">{inactiveReasonsTr.noActiveCheckpoints.message}</Typography>
          <NavLink style={{ textDecoration: 'none' }} to={checkpoints(shopId, lineId)}>
            <Button style={{ marginTop: '2rem' }} variant="contained" color="primary">
              {inactiveReasonsTr.noActiveCheckpoints.action}
            </Button>
          </NavLink>
        </>
      )}
      {!!isSingleCheckpoint && reason === LineInactiveState.NoActiveCheckpoints && (
        <>
          <Typography variant="h5">{inactiveReasonsTr.noActiveCheckpointsSingleCheckpoint.message}</Typography>
          <NavLink style={{ textDecoration: 'none' }} to={checkpointHost(shopId, lineId, String(checkpointId))}>
            <Button
              style={{ marginTop: inactiveReasonsTr.noActiveCheckpointsSingleCheckpoint.message ? '2rem' : '1rem' }}
              variant="contained"
              color="primary"
            >
              {inactiveReasonsTr.noActiveCheckpointsSingleCheckpoint.action}
            </Button>
          </NavLink>
        </>
      )}
      {!isSingleCheckpoint && reason === LineInactiveState.OperatingTimeIsOver && (
        <>
          <Typography variant="h5">{inactiveReasonsTr.operatingTimeIsOver.message}</Typography>
          <NavLink style={{ textDecoration: 'none' }} to={checkpoints(shopId, lineId)}>
            <Button style={{ marginTop: '2rem' }} variant="contained" color="primary">
              {inactiveReasonsTr.operatingTimeIsOver.action}
            </Button>
          </NavLink>
        </>
      )}
      {!!isSingleCheckpoint && reason === LineInactiveState.OperatingTimeIsOver && (
        <>
          <Typography variant="h5">{inactiveReasonsTr.operatingTimeIsOverSingleCheckpoint.message}</Typography>
          <NavLink style={{ textDecoration: 'none' }} to={checkpointHost(shopId, lineId, String(checkpointId))}>
            <Button style={{ marginTop: '2rem' }} variant="contained" color="primary">
              {inactiveReasonsTr.operatingTimeIsOverSingleCheckpoint.action}
            </Button>
          </NavLink>
        </>
      )}
      {!isSingleCheckpoint && reason === LineInactiveState.Overcharged && (
        <>
          <Typography variant="h5">{inactiveReasonsTr.overcharged.message}</Typography>
          <NavLink style={{ textDecoration: 'none' }} to={checkpoints(shopId, lineId)}>
            <Button style={{ marginTop: '2rem' }} variant="contained" color="primary">
              {inactiveReasonsTr.overcharged.action}
            </Button>
          </NavLink>
        </>
      )}
      {!!isSingleCheckpoint && reason === LineInactiveState.Overcharged && (
        <>
          <Typography variant="h5">{inactiveReasonsTr.overchargedSingleCheckpoint.message}</Typography>
          <NavLink style={{ textDecoration: 'none' }} to={checkpointHost(shopId, lineId, String(checkpointId))}>
            <Button style={{ marginTop: '2rem' }} variant="contained" color="primary">
              {inactiveReasonsTr.overchargedSingleCheckpoint.action}
            </Button>
          </NavLink>
        </>
      )}
      {reason === LineInactiveState.ReservationTimeNotCame && (
        <>
          <Typography variant="h5">{inactiveReasonsTr.reservationTimeNotCame.message}</Typography>
          {canUpdateShops && (
            <NavLink style={{ textDecoration: 'none' }} to={editLine(shopId, lineId)}>
              <Button style={{ marginTop: '2rem' }} variant="contained" color="primary">
                {inactiveReasonsTr.reservationTimeNotCame.action}
              </Button>
            </NavLink>
          )}
        </>
      )}
    </div>
  )
}

export { InactiveReason }
