import axios from 'axios'
import { MetaAPI } from '../models'

const meta: MetaAPI = {
  getAppVersion(config) {
    return axios.get('meta/version', config)
  }
}

export default meta
