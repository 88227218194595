import { Button, Typography } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import { useEffect, useState } from 'react'
import { useAppSelector } from 'store'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { PositionType } from 'shared/models'
import { monitoring } from 'shared/api'
import Timer from '@amplication/react-compound-timer'
import { ActionFormActions } from '../styled'
import { ConvertToMobileFormProps } from './types'
import { CodeWrapper, MobileCode, PositionActionNotActive, RenewLinkWrapper, TimerWrapper } from './styled'

function ConvertToMobileForm(props: ConvertToMobileFormProps) {
  const [convertToMobileLoading, setConvertToMobileLoading] = useState(false)
  const [convertToMobileCode, setConvertToMobileCode] = useState<number>()

  const { tr } = useTranslation()

  const { canAddAndEditPositions } = useAppSelector(getPermissionsSelector)

  useEffect(() => {
    getConvertCode()
  }, [])

  async function getConvertCode() {
    setConvertToMobileLoading(true)

    try {
      const code = await monitoring.getCodeForMobilePosition({
        shopId: props.shopId,
        lineId: props.lineId,
        positionId: props.positionId
      })

      setConvertToMobileLoading(false)
      setConvertToMobileCode(code)
    } catch (err) {
      setConvertToMobileLoading(false)
      props.onBack()
    }
  }

  if (!canAddAndEditPositions || !props.supportMobile) {
    return null
  }

  if (props.positionType === PositionType.MobileApp) {
    return null
  }

  return (
    <>
      <Typography variant="body1" fontWeight={500} fontSize="18px">
        {tr.positionActions.convertToMobile}
      </Typography>
      <div>
        <Typography variant="body1" marginTop="1rem">
          {tr.positionActions.convertToMobileCode}
        </Typography>
        <PositionActionNotActive>
          <CodeWrapper>
            {convertToMobileLoading && (
              <span style={{ opacity: '0.64' }}>{tr.positionActions.convertToMobileLoading}</span>
            )}
            {!convertToMobileLoading && (
              <>
                <MobileCode>
                  {convertToMobileCode
                    ?.toString()
                    .match(/.{1,2}/g)
                    ?.map((el, i) => (
                      <span key={i} style={{ marginRight: '4px' }}>
                        {el}
                      </span>
                    ))}
                </MobileCode>
                <TimerWrapper>
                  <Timer initialTime={30000} direction="backward" checkpoints={[{ time: 0, callback: props.onBack }]}>
                    <Timer.Seconds /> sec
                  </Timer>
                </TimerWrapper>
              </>
            )}
          </CodeWrapper>
          {!convertToMobileLoading && (
            <RenewLinkWrapper onClick={getConvertCode}>{tr.positionActions.convertToMobileRenew}</RenewLinkWrapper>
          )}
        </PositionActionNotActive>
      </div>
      <ActionFormActions $marginTop="1.5rem">
        <Button variant="contained" color="primary" size="small" onClick={props.onBack}>
          {tr.positionActions.backButton}
        </Button>
      </ActionFormActions>
    </>
  )
}

export { ConvertToMobileForm }
