import { Controller } from 'react-hook-form'
import { ControlProps } from '.'
import { TextField } from 'shared/ui-kit/text-field'

export function NumberControl(props: ControlProps) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field: f, formState }) => (
        <TextField
          value={f.value || ''}
          onChange={(value) => {
            f.onChange(value !== '' ? Number(value) : undefined)
          }}
          type="number"
          onBlur={f.onBlur}
          label={props.label}
          placeholder={props.label}
          attention={(props.isMandatory && f.value === undefined) || !!formState.errors[props.name]?.message}
          message={formState.errors[props.name]?.message as string}
          fullWidth
        />
      )}
    />
  )
}
