import styled from '@emotion/styled'

const DelayContent = styled.div`
  box-sizing: border-box;
  padding: 1rem;
`

const DelayItems = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
`

const FieldContainer = styled.div`
  margin: 1rem 0;
`

const ActionContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  gap: 0.5rem;
`

export { DelayContent, DelayItems, FieldContainer, ActionContainer }
