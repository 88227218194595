import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { NotificationKey } from 'shared/models/entities/notification.model'
import Toggle from 'shared/ui-kit/toggle-wrap'

function NotificationRequestDialog({
  onClose,
  open,
  availableKeys,
  onNotificationsSubscribe,
  disabled
}: {
  open: boolean
  availableKeys: NotificationKey[]
  onClose: () => void
  disabled?: boolean
  onNotificationsSubscribe: (keys: NotificationKey[]) => void
}) {
  const { tr } = useTranslation()

  const [notificationKeys, setNotificationKeys] = useState<NotificationKey[]>(availableKeys || [])

  function handleToggle(notificationKey: NotificationKey) {
    return () => {
      if (notificationKeys.includes(notificationKey)) {
        setNotificationKeys(notificationKeys.filter((key) => key !== notificationKey))
      } else {
        setNotificationKeys([...notificationKeys, notificationKey])
      }
    }
  }

  function handleSubscribe() {
    onNotificationsSubscribe(notificationKeys)
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>{tr.notifications.notificationRequestTitle}</DialogTitle>
      <DialogContent>
        {availableKeys.map((notificationKey) => (
          <Toggle
            key={notificationKey}
            toggled={notificationKeys.includes(notificationKey)}
            label={tr.notifications.notificationRequestKeys[notificationKey]}
            disabled={disabled}
            onToggle={handleToggle(notificationKey)}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="small"
          color="primary"
          disabled={!notificationKeys.length || disabled}
          onClick={handleSubscribe}
        >
          {tr.notifications.notificationRequestButton}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { NotificationRequestDialog }
