import { ReactNode, CSSProperties, useState, useEffect } from 'react'
import ListItem from './list-item'
import { injectTestAttr } from 'shared/utils/test-attr'
import { useDragLayer } from 'react-dnd'
import type { XYCoord } from 'dnd-core'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { DragListItem } from './drag-list-item'
import { ListDndContainer } from './styled'

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 999999,
  left: 0,
  top: 0,
  width: 'inherit',
  maxWidth: 'inherit',
  height: '100%'
}

function dataConverter(entity) {
  return {
    id: entity?.id,
    primaryText: entity?.primaryText,
    secondaryText: entity?.secondaryText,
    tertiaryText: entity?.tertiaryText
  }
}

function getItemStyles(initialOffset: XYCoord | null, currentOffset: XYCoord | null) {
  if (!initialOffset || !currentOffset) {
    return { display: 'none' }
  }

  const { x, y } = currentOffset
  const transform = `translate(${x}px, ${y}px)`

  return { transform, WebkitTransform: transform }
}

type ListData = {
  id: number | string
  primaryText: string | ReactNode
  secondaryText?: string | ReactNode
  tertiaryText?: string
  notActive?: boolean
  avatar?: any
  avatarOnTop?: boolean
  controlsOnBottom?: any
  controls?: any
}

type Props<T> = {
  data: T[]
  dataConverter: (el: T, i: number) => ListData
  itemStyle?: CSSProperties
  style?: CSSProperties
  onSelect?: (el: any, i: number) => void
  handleData?: (el: T) => any
  selectedId?: number | string
  onDropEnd?: (items: T[]) => void
}

const reorder = <T,>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

function ListDnd<T>(props: Props<T>) {
  const {
    isDragging: isDraggingPreview,
    item,
    initialOffset,
    currentOffset
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging()
  }))

  const [data, setData] = useState<T[]>(props.data || [])

  const draggingItem =
    !!isDraggingPreview && item?.index !== undefined
      ? (props.dataConverter || dataConverter)(data?.[item.index], item.index)
      : null

  useEffect(() => {
    setData(props.data)
  }, [props.data])

  useEffect(() => {
    if (!isDraggingPreview && data !== props.data) {
      props.onDropEnd?.(data)
    }
  }, [isDraggingPreview])

  function handleMoveCard(dragIndex: number, hoverIndex: number) {
    setData((prevCards) => reorder(prevCards, dragIndex, hoverIndex))
  }

  return (
    <ListDndContainer style={props.style} {...injectTestAttr(props)}>
      {draggingItem && (
        <div style={layerStyles}>
          <div style={getItemStyles(initialOffset, currentOffset)}>
            <ListItem
              itemStyle={{ background: '#FFFFFF81', ...props.itemStyle }}
              id={draggingItem.id}
              primaryText={draggingItem.primaryText}
              secondaryText={draggingItem.secondaryText}
              tertiaryText={draggingItem.tertiaryText}
              avatar={draggingItem.avatar}
              avatarOnTop={draggingItem.avatarOnTop}
              controlsOnBottom={draggingItem.controlsOnBottom}
              stopControlsEvents={true}
              controls={[...(draggingItem.controls || []), <DragIndicatorIcon style={{ opacity: '0.2' }} />]}
              entity={draggingItem}
              notActive={draggingItem.notActive}
            />
          </div>
        </div>
      )}
      {data.map((entity, i) => {
        const d = (props.dataConverter || dataConverter)(entity, i)

        return (
          <DragListItem
            itemStyle={props.itemStyle}
            key={d.id}
            primaryText={d.primaryText}
            secondaryText={d.secondaryText}
            tertiaryText={d.tertiaryText}
            onSelect={(data) => props.onSelect?.(data, i)}
            dnd={true}
            avatar={d.avatar}
            avatarOnTop={d.avatarOnTop}
            controlsOnBottom={d.controlsOnBottom}
            controls={d.controls}
            entity={entity}
            handleData={props.handleData}
            notActive={d.notActive}
            selected={d.id === props.selectedId}
            index={i}
            moveCard={handleMoveCard}
          />
        )
      })}
    </ListDndContainer>
  )
}

export default ListDnd
