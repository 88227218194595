import { useEffect, useState } from 'react'
import { Form, FormGroup } from 'shared/ui-kit/form'
import { TextField } from 'shared/ui-kit/text-field'
import { useTranslation } from 'shared/i18n/translate'
import { RadioButtonGroup } from 'shared/ui-kit/radio-button'
import FileUploader from 'features/file-uploader'
import { AdvertisementModel, AdvertisementTypes } from 'shared/models'
import Toggle from 'shared/ui-kit/toggle-wrap'
import { isDouble, isPositiveInteger } from 'shared/utils/string-test'
import Card from 'shared/ui-kit/card'
import { ChipListSelect } from 'shared/ui-kit/selects'
import {
  Tooltip,
  Divider,
  Radio,
  Button,
  FormControlLabel,
  ToggleButtonGroup,
  ToggleButton,
  Stack,
  Slider
} from '@mui/material'
import Dropdown from 'shared/ui-kit/dropdown'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { getAdvertisementFormValidation } from './validation'
import { DateTimePicker } from 'features/date'
import { ParametersGroup } from '../styeld'
import { DisplaySchedule } from './display-schedule'
import VolumeDown from '@mui/icons-material/VolumeDown'
import VolumeUp from '@mui/icons-material/VolumeUp'
import { ActionText, ActionsWrapper, ButtonWithTextWrapper, ConfirmationActions } from 'shared/ui-kit/action-wrapper'
import Text from 'shared/ui-kit/text'
import { AdvertisementEditFormProps } from '../types'
import DeleteIcon from '@mui/icons-material/Delete'
import TextFieldMUI from '@mui/material/TextField'

function AdvertisementEdit(props: AdvertisementEditFormProps) {
  const { tr, dateFormatter } = useTranslation()

  const [isConfirmDelete, setConfirmDelete] = useState(false)

  const { control, handleSubmit, watch, setValue, formState, trigger } = useForm<AdvertisementModel>({
    resolver: yupResolver(getAdvertisementFormValidation() as any),
    defaultValues: props.data,
    mode: 'onChange'
  })

  useEffect(() => {
    trigger()
  }, [])

  function handleTryDelete() {
    setConfirmDelete(true)
  }

  function handleCancelDelete() {
    setConfirmDelete(false)
  }

  function handleConfirmDelete() {
    props.onDelete(props.data.id || '')
  }

  const {
    name,
    namePlaceholder,
    text,
    textPlaceholder,
    image,
    acceptationPdf,
    typeImage,
    typeText,
    typePdf,
    video,
    typeVideo,
    typeLink,
    typeLinkTooltip,
    type,
    smartphone,
    paperTicket,
    infoPad,
    acceptationPad,
    afterBookingIsFinished,
    afterServiceIsFinished,
    minimumWaitingTime,
    minimumWaitingTimeHint,
    maximumDistanceToDisplay,
    maximumDistanceToDisplayHint,
    afterServiceStarted,
    afterCallToServicePoint,
    allLanguages,
    orientation,
    link,
    linkPlaceholder,
    withLink,
    specificHours,
    startDate,
    endDate,
    durationTimeHint,
    videoAccelerationFactor,
    durationTime,
    useDurationTimeForVideo,
    videoTypeFile,
    videoTypeLink,
    specificHoursIntervals
  } = tr.advertisementEdit

  const maxFileSizeImage = 10 * 1024 * 1024
  const maxFileSizeAcceptationPdf = 25 * 1024 * 1024
  const maxFileSizeVideo = 100 * 1024 * 1024

  const isValid = !Object.keys(formState.errors).length

  return (
    <form onSubmit={handleSubmit(props.onSave)}>
      <Card>
        <div>
          <Form>
            <FormGroup>
              <Controller
                name="name"
                control={control}
                render={({ field: f, formState }) => (
                  <TextField
                    label={name}
                    placeholder={namePlaceholder}
                    autofocus={true}
                    value={f.value}
                    onChange={f.onChange}
                    attention={!!formState.errors.name}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="type"
                control={control}
                defaultValue={AdvertisementTypes.IMAGE}
                render={({ field: f }) => (
                  <RadioButtonGroup
                    label={type}
                    name="type"
                    onChange={(val) => {
                      f.onChange(val)

                      setValue('webFrame', false)
                      setValue('paperTicket', false)
                      setValue('infoPad', false)
                      setValue('link', '')

                      if (val === AdvertisementTypes.LINK) {
                        setValue('smartphone', true)
                        setValue('withLink', true)
                      } else {
                        setValue('withLink', false)
                        setValue('smartphone', false)
                      }

                      trigger()
                    }}
                    value={f.value || ''}
                    ensureValue={AdvertisementTypes.TEXT}
                    row
                  >
                    <FormControlLabel
                      value={AdvertisementTypes.IMAGE}
                      control={<Radio size="small" id="radioButtonText" />}
                      label={typeImage}
                    />
                    <FormControlLabel
                      value={AdvertisementTypes.VIDEO}
                      control={<Radio size="small" id="radioButtonText" />}
                      label={typeVideo}
                    />
                    <FormControlLabel
                      value={AdvertisementTypes.TEXT}
                      control={<Radio size="small" id="radioButtonText" />}
                      label={typeText}
                    />
                    <FormControlLabel
                      value={AdvertisementTypes.PDF}
                      control={<Radio size="small" id="radioButtonText" />}
                      label={typePdf}
                    />
                    <Tooltip arrow title={typeLinkTooltip}>
                      <FormControlLabel
                        value={AdvertisementTypes.LINK}
                        control={<Radio size="small" id="radioButtonImage" />}
                        label={typeLink}
                      />
                    </Tooltip>
                  </RadioButtonGroup>
                )}
              />
            </FormGroup>
            {watch('type') === AdvertisementTypes.TEXT && (
              <FormGroup>
                <Controller
                  name="text"
                  control={control}
                  render={({ field: f, fieldState }) => (
                    <TextFieldMUI
                      label={text}
                      placeholder={textPlaceholder}
                      value={f.value}
                      onChange={f.onChange}
                      error={!!fieldState.error}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      helperText={`${f.value?.length || 0}/1000 ${tr.entityEdit.chars}`}
                      multiline
                      maxRows={15}
                    />
                  )}
                />
              </FormGroup>
            )}
            {watch('type') === AdvertisementTypes.VIDEO && (
              <FormGroup>
                <Controller
                  name="withLink"
                  control={control}
                  render={({ field: f }) => (
                    <ToggleButtonGroup
                      color="primary"
                      value={f.value ? 'Link' : 'File'}
                      exclusive
                      onChange={(_, v) => {
                        f.onChange(v === 'Link' ? true : false)
                        trigger()
                      }}
                      fullWidth
                    >
                      <ToggleButton size="small" value="File">
                        {videoTypeFile}
                      </ToggleButton>
                      <ToggleButton size="small" value="Link">
                        {videoTypeLink}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}
                />
              </FormGroup>
            )}
            {watch('type') === AdvertisementTypes.PDF && (
              <FormGroup>
                <Controller
                  name="pdf"
                  control={control}
                  render={({ field: f, formState }) => (
                    <FileUploader
                      label={acceptationPdf}
                      file={f.value}
                      maxFileSize={maxFileSizeAcceptationPdf}
                      onChange={(val) => f.onChange(val || undefined)}
                      attention={!!formState.errors.pdf}
                    />
                  )}
                />
              </FormGroup>
            )}
            {watch('type') === AdvertisementTypes.IMAGE && (
              <FormGroup>
                <Controller
                  name="image"
                  control={control}
                  render={({ field: f, formState }) => (
                    <FileUploader
                      label={image}
                      file={f.value}
                      maxFileSize={maxFileSizeImage}
                      onChange={(val) => f.onChange(val || undefined)}
                      attention={!!formState.errors.image}
                    />
                  )}
                />
              </FormGroup>
            )}
            {watch('type') === AdvertisementTypes.IMAGE && (
              <FormGroup>
                <Controller
                  name="withLink"
                  control={control}
                  render={({ field: f }) => (
                    <Toggle
                      toggled={f.value}
                      label={withLink}
                      onToggle={() => {
                        f.onChange(!f.value)
                        trigger()
                      }}
                    />
                  )}
                />
              </FormGroup>
            )}
            {!watch('withLink') && watch('type') === AdvertisementTypes.VIDEO && (
              <FormGroup>
                <Controller
                  name="video"
                  control={control}
                  render={({ field: f, formState }) => (
                    <FileUploader
                      label={video}
                      file={f.value}
                      maxFileSize={maxFileSizeVideo}
                      onChange={(val) => f.onChange(val || undefined)}
                      attention={!!formState.errors.video}
                    />
                  )}
                />
              </FormGroup>
            )}
            {Boolean(watch('withLink') || [AdvertisementTypes.LINK].includes(watch('type'))) && (
              <FormGroup>
                <Controller
                  name="link"
                  control={control}
                  render={({ field: f }) => (
                    <TextField
                      label={link}
                      placeholder={linkPlaceholder}
                      value={f.value}
                      onChange={f.onChange}
                      attention={!!formState.errors.link}
                    />
                  )}
                />
              </FormGroup>
            )}
            {watch('type') === AdvertisementTypes.VIDEO && (
              <FormGroup>
                <Controller
                  name="volume"
                  control={control}
                  defaultValue={0}
                  render={({ field: f }) => (
                    <Stack spacing={2} direction="row" alignItems="center">
                      <VolumeDown fontSize="small" />
                      <Slider
                        valueLabelDisplay="auto"
                        value={Math.floor((f.value || 0) * 100)}
                        onChange={(_, value) => {
                          if (typeof value === 'number') {
                            f.onChange(value / 100)
                          }
                        }}
                      />
                      <VolumeUp fontSize="small" />
                    </Stack>
                  )}
                />
              </FormGroup>
            )}
            {watch('type') === AdvertisementTypes.VIDEO && (
              <FormGroup>
                <Controller
                  name="videoAccelerationFactor"
                  control={control}
                  render={({ field: f }) => (
                    <TextField
                      label={videoAccelerationFactor}
                      placeholder="1.0"
                      test={isDouble}
                      value={f.value}
                      onChange={f.onChange}
                    />
                  )}
                />
              </FormGroup>
            )}
          </Form>
          <>
            <Divider />
            <Form>
              {watch('type') !== AdvertisementTypes.PDF && (
                <FormGroup>
                  <Controller
                    name="smartphone"
                    control={control}
                    render={({ field: f }) => (
                      <Toggle
                        toggled={f.value}
                        disabled={watch('type') === AdvertisementTypes.LINK}
                        label={smartphone}
                        onToggle={(_, val) => f.onChange(val)}
                      />
                    )}
                  />
                </FormGroup>
              )}
              {!!watch('smartphone') && (
                <ParametersGroup>
                  <FormGroup>
                    <Controller
                      name="afterBookingIsFinished"
                      control={control}
                      render={({ field: f }) => (
                        <Toggle
                          toggled={f.value}
                          label={afterBookingIsFinished}
                          onToggle={(_, val) => f.onChange(val)}
                        />
                      )}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Controller
                      name="afterCallToServicePoint"
                      control={control}
                      render={({ field: f }) => (
                        <Toggle
                          toggled={f.value}
                          label={afterCallToServicePoint}
                          onToggle={(_, val) => f.onChange(val)}
                        />
                      )}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Controller
                      name="afterServiceStarted"
                      control={control}
                      render={({ field: f }) => (
                        <Toggle toggled={f.value} label={afterServiceStarted} onToggle={(_, val) => f.onChange(val)} />
                      )}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Controller
                      name="afterServiceIsFinished"
                      control={control}
                      render={({ field: f }) => (
                        <Toggle
                          toggled={f.value}
                          label={afterServiceIsFinished}
                          onToggle={(_, val) => f.onChange(val)}
                        />
                      )}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Controller
                      name="maximumDistanceToDisplay"
                      control={control}
                      render={({ field: f }) => (
                        <TextField
                          label={maximumDistanceToDisplay}
                          test={isPositiveInteger}
                          hint={maximumDistanceToDisplayHint}
                          value={f.value}
                          onChange={f.onChange}
                        />
                      )}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Controller
                      name="minimumWaitingTime"
                      control={control}
                      defaultValue={10}
                      render={({ field: f }) => (
                        <TextField
                          label={minimumWaitingTime}
                          test={isPositiveInteger}
                          hint={minimumWaitingTimeHint}
                          value={f.value}
                          onChange={f.onChange}
                        />
                      )}
                    />
                  </FormGroup>
                </ParametersGroup>
              )}
              {[AdvertisementTypes.TEXT].includes(watch('type')) && (
                <FormGroup>
                  <Controller
                    name="paperTicket"
                    control={control}
                    render={({ field: f }) => (
                      <Toggle toggled={f.value} label={paperTicket} onToggle={(_, val) => f.onChange(val)} />
                    )}
                  />
                </FormGroup>
              )}
              {[AdvertisementTypes.PDF].includes(watch('type')) && (
                <FormGroup>
                  <Controller
                    name="acceptationPad"
                    control={control}
                    render={({ field: f }) => (
                      <Toggle toggled={f.value} label={acceptationPad} onToggle={(_, val) => f.onChange(val)} />
                    )}
                  />
                </FormGroup>
              )}
              {[
                AdvertisementTypes.IMAGE,
                AdvertisementTypes.VIDEO,
                AdvertisementTypes.TEXT,
                AdvertisementTypes.PDF
              ].includes(watch('type')) && (
                <FormGroup>
                  <Controller
                    name="infoPad"
                    control={control}
                    render={({ field: f }) => (
                      <Toggle toggled={f.value} label={infoPad} onToggle={(_, val) => f.onChange(val)} />
                    )}
                  />
                </FormGroup>
              )}
              {watch('infoPad') && (
                <ParametersGroup>
                  <FormGroup>
                    <Controller
                      name="orientation"
                      control={control}
                      defaultValue="Any"
                      render={({ field: f }) => (
                        <Dropdown
                          ensureValue
                          label={orientation}
                          value={f.value}
                          data={['Any', 'Horizontal', 'Vertical']}
                          onChange={f.onChange}
                          dataConverter={(el) => ({ value: el, text: tr.advertisementEdit.orientations[el] })}
                          attention={!!formState.errors.orientation}
                          fullWidth
                        />
                      )}
                    />
                  </FormGroup>
                </ParametersGroup>
              )}
            </Form>
          </>
          <Divider />
          <Form>
            {[
              AdvertisementTypes.IMAGE,
              AdvertisementTypes.VIDEO,
              AdvertisementTypes.TEXT,
              AdvertisementTypes.PDF
            ].includes(watch('type')) && (
              <FormGroup>
                <Controller
                  name="durationTime"
                  control={control}
                  render={({ field: f }) => (
                    <TextField
                      label={durationTime}
                      placeholder={durationTime}
                      test={isPositiveInteger}
                      hint={durationTimeHint}
                      value={f.value}
                      onChange={f.onChange}
                    />
                  )}
                />
              </FormGroup>
            )}
            {watch('type') === AdvertisementTypes.VIDEO && (
              <FormGroup>
                <Controller
                  name="useDurationTimeForVideo"
                  control={control}
                  render={({ field: f }) => (
                    <Toggle toggled={f.value} label={useDurationTimeForVideo} onToggle={(_, val) => f.onChange(val)} />
                  )}
                />
              </FormGroup>
            )}
            <FormGroup>
              <Controller
                name="allLanguages"
                defaultValue={true}
                control={control}
                render={({ field: f }) => (
                  <Toggle toggled={f.value} label={allLanguages} onToggle={(_, val) => f.onChange(val)} />
                )}
              />
            </FormGroup>
            {!watch('allLanguages') && (
              <FormGroup>
                <Controller
                  name="languages"
                  control={control}
                  render={({ field: f }) => (
                    <ChipListSelect
                      multiple
                      list={props.languages}
                      value={f.value}
                      onChange={f.onChange}
                      compare={(valueItem, listItem) => valueItem === listItem.value}
                      dataConverter={(item) => item.value}
                      labelExtractor={(item) => (item ? item.name : '')}
                    />
                  )}
                />
              </FormGroup>
            )}
            <FormGroup>
              <Controller
                name="specificHours"
                defaultValue={!!watch('startDate') || !!watch('endDate')}
                control={control}
                render={({ field: f }) => (
                  <Toggle
                    toggled={f.value}
                    label={specificHours}
                    onToggle={(_, val) => {
                      f.onChange(val)
                      setValue('startDate', undefined)
                      setValue('endDate', undefined)
                      trigger()
                    }}
                  />
                )}
              />
            </FormGroup>
            {watch('specificHours') && (
              <FormGroup style={{ display: 'flex', gap: '2rem' }}>
                <Controller
                  name="startDate"
                  control={control}
                  render={({ field: f, fieldState }) => (
                    <DateTimePicker
                      label={startDate}
                      id="specificHours_startDate"
                      autoComplete="specificHours_startDate"
                      allowClear
                      attention={!!fieldState?.error}
                      value={f.value || null}
                      onChange={(date) => {
                        f.onChange(date || null)
                        trigger()
                      }}
                    />
                  )}
                />
                <Controller
                  name="endDate"
                  control={control}
                  render={({ field: f, fieldState }) => (
                    <DateTimePicker
                      id="specificHours_endDate"
                      autoComplete="specificHours_endDate"
                      label={endDate}
                      allowClear
                      value={f.value || null}
                      attention={!!fieldState?.error}
                      onChange={(date) => {
                        f.onChange(date || null)
                        trigger()
                      }}
                    />
                  )}
                />
              </FormGroup>
            )}
            <FormGroup>
              <Controller
                name="specificHoursIntervals"
                defaultValue={!!watch('displaySchedule')?.length}
                control={control}
                render={({ field: f }) => (
                  <Toggle
                    toggled={f.value}
                    label={specificHoursIntervals}
                    onToggle={(_, val) => {
                      f.onChange(val)
                      setValue('displaySchedule', undefined)
                      trigger()
                    }}
                  />
                )}
              />
            </FormGroup>
            {watch('specificHoursIntervals') && (
              <Controller
                name="displaySchedule"
                control={control}
                render={({ field: f }) => <DisplaySchedule initValue={f.value} onChange={f.onChange} />}
              />
            )}
          </Form>
        </div>
        {!!props.data.id && (
          <>
            <Divider />
            <Form title={tr.entityEdit.advancedGroup} expandable>
              <FormGroup>
                {!isConfirmDelete && (
                  <ButtonWithTextWrapper>
                    <ActionText>{tr.advertisementEdit.deleteTitle}</ActionText>
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={handleTryDelete}
                      startIcon={<DeleteIcon />}
                      disabled={props.processing}
                    >
                      {tr.entityEdit.deleteButton}
                    </Button>
                  </ButtonWithTextWrapper>
                )}
                {isConfirmDelete && (
                  <ButtonWithTextWrapper>
                    <ActionText>{tr.entityEdit.confirmDelete}</ActionText>
                    <ConfirmationActions>
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        disabled={props.processing}
                        onClick={handleConfirmDelete}
                      >
                        {tr.entityEdit.yes}
                      </Button>
                      <Button variant="outlined" size="small" disabled={props.processing} onClick={handleCancelDelete}>
                        {tr.entityEdit.no}
                      </Button>
                    </ConfirmationActions>
                  </ButtonWithTextWrapper>
                )}
              </FormGroup>
            </Form>
          </>
        )}
      </Card>
      <ActionsWrapper>
        <Button disabled={!isValid || props.processing} variant="contained" color="primary" size="small" type="submit">
          {props.data.id ? tr.entityEdit.updateButton : tr.entityEdit.createButton}
        </Button>
        {props.data.lastUpdate?.date && props.data.lastUpdate.user && (
          <Text type="caption">
            {tr.entityView.lastUpdate(
              props.data.lastUpdate.user?.firstName,
              props.data.lastUpdate.user?.secondName,
              dateFormatter(props.data.lastUpdate.date, 'dateTime')
            )}
          </Text>
        )}
      </ActionsWrapper>
    </form>
  )
}

export { AdvertisementEdit }
