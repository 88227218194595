import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { AdditionalsList } from './list'
import { ListProps } from './types'

export function AdditionalsListDnd(props: ListProps) {
  return (
    <DndProvider backend={HTML5Backend} key="service-list">
      <AdditionalsList {...props} />
    </DndProvider>
  )
}
