import { Divider } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import PositionActionComponent from '../../components/position-action'
import { RemoveActionProps } from './types'
import DeleteIcon from '@mui/icons-material/Delete'

function Remove(props: RemoveActionProps) {
  const { tr } = useTranslation()

  function handleSubmit() {
    if (props.requestCommentsOnRemove) {
      props.onConfirmMassageOpen?.()
    } else {
      props.onRemove?.()
    }
  }

  return (
    <>
      {!props.isCardView && <Divider />}
      <PositionActionComponent
        isCardView={props.isCardView}
        confirmation={props.displayRemoveConfirm || false}
        confirmationMessage={tr.positionActions.removeConfirm}
        onSubmit={handleSubmit}
        icon={<DeleteIcon fontSize="small" />}
      >
        {tr.positionActions.remove}
      </PositionActionComponent>
    </>
  )
}

export { Remove }
