import styled from '@emotion/styled'

const ListItem = styled.div`
  cursor: pointer;

  &:hover {
    background: #f1f1f1;
  }
`

const NoAvailablePlace = styled.div`
  font-size: 30px;
  text-align: center;
  margin: 2rem;
  color: #00000050;
`

const ListTitle = styled.div`
  padding: 1rem;
`

const ListName = styled.div`
  font-size: 25px;
`

const ListAddress = styled.div`
  font-size: 14px;
`

const ListLine = styled.div`
  padding: 1rem;
  display: flex;
`

const ListLineName = styled.div`
  flex: 1;
`

const ListLineDescription = styled.div`
  font-size: 14px;
  margin-left: 1rem;
`

const ListDescriptionContent = styled.div`
  text-align: right;
  width: 7rem;
`

export {
  ListItem,
  NoAvailablePlace,
  ListTitle,
  ListName,
  ListAddress,
  ListLine,
  ListLineName,
  ListLineDescription,
  ListDescriptionContent
}
