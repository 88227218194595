import PositionStateColors from 'features/position-dumb/utils/position-state-color'
import { DiscretePositionModel, DiscreteMonitoringModel, PositionState, PositionType } from 'shared/models'
import { PositionQueueType } from 'shared/utils/positions_filters'
import { DiscreteMonitoringFilters, PositionStateWithPersons } from './types'

const PositionStateToOrder = {
  [PositionState.Joined]: 10,
  [PositionState.InLine]: 20,
  [PositionState.ValidForService]: 20,
  [PositionState.InLineExtraTime]: 20,
  [PositionState.InLineHere]: 30,
  [PositionState.NearCheckpoint]: 40,
  [PositionState.CalledToCheckpoint]: 50,
  [PositionState.MovingToCheckpoint]: 60,
  [PositionState.InService]: 70,
  [PositionState.ServiceStarted]: 70,
  [PositionState.Leaved]: 100,
  [PositionState.AutoFinished]: 100,
  [PositionState.Removed]: 100,
  default: Number.MAX_SAFE_INTEGER
}

export function convertPositionToStatePersons(positions: DiscretePositionModel[] = []): PositionStateWithPersons[] {
  const groups: PositionStateWithPersons[] = []

  positions.forEach((position) => {
    const { state, personsQuantity = 1 } = position
    const posIndex = groups.findIndex((g) => g.name === state)
    if (posIndex < 0) {
      const groupElement = { name: state, value: personsQuantity }
      groups.push(groupElement)
    } else {
      groups[posIndex].value += personsQuantity
    }
  })
  const sorted = groups.sort((a, b) => {
    return (
      (PositionStateToOrder[a.name] || PositionStateToOrder.default) -
      (PositionStateToOrder[b.name] || PositionStateToOrder.default)
    )
  })

  return sorted
}

export const PositionStateToColor = {
  [PositionState.Joined]: PositionStateColors.joined,
  [PositionState.CalledToCheckpoint]: PositionStateColors.calledToCheckpoint,
  [PositionState.MovingToCheckpoint]: PositionStateColors.movingToCheckpoint,
  [PositionState.ServiceStarted]: PositionStateColors.inService,
  [PositionState.InService]: PositionStateColors.inService,
  [PositionState.NearCheckpoint]: PositionStateColors.nearCheckpoint
}

export function getQueueTypeTranslations(tr) {
  return {
    [PositionQueueType.queue]: tr.lineMonitoring.offSite,
    [PositionQueueType.inLineHere]: tr.lineMonitoring.isHere,
    [PositionQueueType.isValid]: tr.lineMonitoring.isValid,
    [PositionQueueType.isNotValid]: tr.lineMonitoring.isNotValid,
    [PositionQueueType.priority]: tr.lineMonitoring.priority,
    [PositionQueueType.unreach]: tr.lineMonitoring.positionCannotBeServed,
    unknown: 'TRANSLATION_ERROR'
  }
}

export function getModesAvailableForFiltering(monitoring: DiscreteMonitoringModel) {
  const positions = (monitoring?.slots || []).reduce(
    (acc, curr) => [...acc, ...(curr.positions || [])],
    [] as DiscretePositionModel[]
  )

  const uniqueTypesFromPositions = positions.reduce((prev, acc) => {
    if (prev.indexOf(acc.type) === -1) {
      prev.push(acc.type)
    }
    return prev
  }, [] as PositionType[])

  return Object.values(PositionType).filter((ptv) => uniqueTypesFromPositions.find((utfp) => utfp === ptv))
}

export const readyToServiceStates = [
  PositionState.CalledToCheckpoint,
  PositionState.MovingToCheckpoint,
  PositionState.NearCheckpoint,
  PositionState.InService,
  PositionState.ValidForService,
  PositionState.ServiceStarted
]

export const defaultFilters: DiscreteMonitoringFilters = {
  termValue: '',
  positionId: null,
  positionStatusesSelected: [],
  positionServicesSelected: [],
  positionTypeSelected: [],
  positionQueueTypeSelected: []
}
