import axios from 'axios'
import { AppointmentPlaceListAPI } from '../models'

const appointmentPlaceList: AppointmentPlaceListAPI = {
  getAppointmentPlaceList(config) {
    return axios.get('getAppointmentPlaceList', config)
  },
  getCustomerById(customerId, config) {
    return axios.get(`appointment/getCustomer`, { params: { customerId }, ...config })
  },
  getCustomerInformationByPositionId(positionId, placeId, lineId, config) {
    return axios.get(`appointment/getCustomerInformation`, { params: { positionId, placeId, lineId }, ...config })
  }
}

export default appointmentPlaceList
