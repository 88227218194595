import { validatePhone } from 'features/material-ui-phoneinput'
import { Translations } from 'shared/i18n/translation-types'
import { Additional, ExpressionValidations } from 'shared/models'
import * as Yup from 'yup'

function yupValidationMapper(
  additionals: Additional[],
  tr: Translations,
  expressionValidations: ExpressionValidations
) {
  const { isRequired, leastCharacters, mostCharacters, typeError } = tr.common.validations

  const fields: { [key in string]: Yup.AnySchema } = {}

  additionals.forEach((field) => {
    const expressionValidation = expressionValidations[field.shortName]

    if (expressionValidation?.visible === false) {
      return
    }

    switch (field.type) {
      case 'String':
        fields[field.shortName] = Yup.string().transform((value) => value || undefined)

        if (field.validationRegexp) {
          fields[field.shortName] = (fields[field.shortName] as Yup.StringSchema).matches(
            new RegExp(field.validationRegexp),
            field.validationErrorMessage
          )
        }
        if (field.maxContentLength || field.maxContentLength === 0) {
          fields[field.shortName] = (fields[field.shortName] as Yup.StringSchema).max(
            field.maxContentLength,
            ({ max }) => mostCharacters(field.label, max)
          )
        }
        if (field.minContentLength || field.minContentLength === 0) {
          fields[field.shortName] = (fields[field.shortName] as Yup.StringSchema).min(
            field.minContentLength,
            ({ min }) => leastCharacters(field.label, min)
          )
        }

        break
      case 'Phone':
        fields[field.shortName] = Yup.string()
          .transform((value) => value || undefined)
          .test({ test: (val) => validatePhone(val, !field.isMandatory), message: '' })
        break
      case 'Combo':
        fields[field.shortName] = Yup.string().typeError(typeError)
        break
      case 'Number':
        fields[field.shortName] = Yup.number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .typeError(typeError)
        break
      case 'Date':
        fields[field.shortName] = Yup.number().typeError(typeError)
        break
      case 'Switch':
        fields[field.shortName] = Yup.boolean().typeError(typeError)
        break
      case 'Document':
        fields[field.shortName] = Yup.object().typeError(typeError)
        break
      default:
        fields[field.shortName] = Yup.string().typeError(typeError)
        break
    }

    if (expressionValidation?.valid !== undefined) {
      fields[field.shortName] = fields[field.shortName].test({
        test: () => expressionValidation.valid,
        message: expressionValidation.invalidMessage
      })
    }

    if (fields[field.shortName] && field.isMandatory && field.type !== 'Document' && field.type !== 'Phone') {
      fields[field.shortName] = fields[field.shortName].required(isRequired)
    }
  })

  return fields
}

function getValidationSchema(
  additionals: Additional[],
  tr: Translations,
  expressionValidations: ExpressionValidations
) {
  const fieldValitations = yupValidationMapper(additionals, tr, expressionValidations)

  return Yup.object().shape({ ...fieldValitations })
}

export { yupValidationMapper, getValidationSchema }
