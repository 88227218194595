import { CSSProperties } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { Typography, useTheme } from '@mui/material'
import { LineModelSimplified, PlaceModelSimplified } from 'shared/models'
import { LineSelect } from './line-select'
import { PlaceSelect } from './place-select'
import { TreeItem } from './tree-item'
import { produce } from 'immer'
import AttentionPoint from 'shared/ui-kit/attention-point'

type PlaceLines = { place: PlaceModelSimplified; lines: LineModelSimplified[] }

type Props = {
  style?: CSSProperties
  onChange: (data: PlaceLines[]) => void
  selectedData: PlaceLines[]
  attention?: boolean
}

function PlaceLineTreeSelect(props: Props) {
  const { onChange, style, selectedData, attention = false } = props
  const { tr } = useTranslation()
  const theme = useTheme()

  function changeLinesInPlace(placeId: number, lines: LineModelSimplified[]) {
    const newData = produce(selectedData, (draft) => {
      const shopToAdd = draft.find((sd) => sd.place.id === placeId)

      if (!shopToAdd) {
        return
      }

      shopToAdd.lines = lines
    })

    onChange(newData)
  }

  return (
    <div style={style}>
      <Typography style={{ display: 'flex' }} variant="caption" color="textSecondary">
        {tr.tagEdit.lines} {attention && <AttentionPoint />}
      </Typography>
      {selectedData.map((placeLines, idx) => {
        const { place, lines } = placeLines
        return (
          <div key={place.id}>
            <TreeItem
              item={place}
              getTitle={(item) => item.name}
              style={{ paddingTop: '0.5rem', paddingBottom: idx !== 0 ? '0.5rem' : 'unset' }}
              onRemove={(item) => onChange(selectedData.filter((sd) => sd.place.id !== item.id))}
            />
            <div
              style={{
                padding: '0 0 0.5rem 1rem',
                margin: '0 0 0.5rem 1rem',
                borderLeft: `4px solid ${theme.palette.primary.main}`
              }}
            >
              {lines.map((line) => (
                <TreeItem
                  key={line.id}
                  item={line}
                  getTitle={(item) => item.name}
                  style={{ padding: '8px 0' }}
                  onRemove={(item) =>
                    changeLinesInPlace(
                      place.id,
                      lines.filter((l) => l.id !== item.id)
                    )
                  }
                />
              ))}
              <LineSelect
                placeId={place.id}
                filterItem={(item) => lines.findIndex((l) => l.id === item.id) === -1}
                onChange={(val) => {
                  changeLinesInPlace(place.id, [...lines, val])
                }}
                label={tr.common.line}
              />
            </div>
          </div>
        )
      })}
      <PlaceSelect
        style={{ marginTop: '1rem' }}
        filterItem={(item) => selectedData.findIndex((pl) => pl.place.id === item.id) === -1}
        onChange={(val) => {
          onChange([...selectedData.filter((sd) => val.id !== sd.place.id), { place: val, lines: [] }])
        }}
        label={tr.common.shop}
      />
    </div>
  )
}

export { PlaceLineTreeSelect }
