import axios from 'axios'
import { CampaignAPI } from 'shared/models'

const campaign: CampaignAPI = {
  getCampaignList(config) {
    return axios.get('getCampaignList', config)
  },
  getCampaign(id, config) {
    return axios.get('getCampaign', { params: { id }, ...config })
  },
  createCampaign(data, config) {
    return axios.post('createCampaign', data, config)
  },
  updateCampaign(data, config) {
    return axios.put('updateCampaign', data, config)
  },
  deleteCampaign(id, config) {
    return axios.delete('deleteCampaign', { data: id, headers: { 'Content-Type': 'application/json' }, ...config })
  }
}

export default campaign
