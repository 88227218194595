import { ServiceSmallModel } from 'shared/models'
import ServiceSelect from './service-select'

type Props = {
  onChange: (value: any) => void
  multiple: boolean
  value: any
  list: ServiceSmallModel[]
  disableClickable?: boolean
  ignoreDisable?: boolean
  attention?: boolean
  isDisabledItem?: (value: ServiceSmallModel) => boolean
  title?: string
  participantsNumber?: number
}

function ServiceSelectArrayAlways(props: Props) {
  const { onChange, multiple, value } = props

  function handleChange(value) {
    return multiple ? onChange?.(value) : onChange?.(value ? [value] : value)
  }

  return (
    <ServiceSelect
      {...props}
      value={multiple ? value : value && value[0]}
      onChange={handleChange}
      participantsNumber={props.participantsNumber}
    />
  )
}

export default ServiceSelectArrayAlways
