import { useState, useEffect } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { Link, Popover, Typography } from '@mui/material'
import { VersionItemProps } from './types'
import { VersionInfo } from './styled'
import { WhatsNewDialog } from './whatsnew-dialog'
import { useMediaQuery } from 'react-responsive'

function VersionItem(props: VersionItemProps) {
  const { tr } = useTranslation()

  const [version, setVersion] = useState<string | undefined>(props.version)
  const [newVersion, setNewVersion] = useState<string | undefined>()
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLDivElement) | null>(null)

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  useEffect(() => {
    if (!version) {
      setVersion(props.version)
    } else if (props.version !== version) {
      setNewVersion(props.version)
    }
  }, [props.version])

  function handleVersionClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (!newVersion) {
      return
    }

    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  function handleVersionDialogClose() {
    setAnchorEl(null)
  }

  return (
    <>
      {!newVersion && (
        <WhatsNewDialog version={newVersion || version} onWhatsNewDialogChanged={props.onWhatsNewDialogChanged}>
          {!isMobile && <VersionInfo $isNew={false}>v.{version}</VersionInfo>}
        </WhatsNewDialog>
      )}
      {!!newVersion && (
        <VersionInfo onClick={handleVersionClick} $isNew={!!newVersion}>
          {[version, newVersion].filter(Boolean).join(' → ')}
        </VersionInfo>
      )}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleVersionDialogClose}
        anchorPosition={{ top: 0, left: 0 }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div style={{ padding: '8px' }}>
          <Typography variant="body1">{tr.version.newVersionAvailable(newVersion || '')}</Typography>
          <Typography variant="body2">
            {tr.version.refreshPageMessage}
            <Link style={{ cursor: 'pointer' }} color="primary" onClick={() => location.reload()}>
              {tr.version.refreshPageMessageLink}
            </Link>
          </Typography>
        </div>
      </Popover>
    </>
  )
}

export { VersionItem }
