import {
  MessageTypes,
  showMessage as showMessageAction,
  removeMessage,
  setBreadcrumbs
} from '../reducers/main-layout-reducer'

function showMessage(
  message: string,
  type = MessageTypes.Default,
  options?: {
    duration?: number
  }
) {
  return (dispatch) => {
    dispatch(
      showMessageAction({
        type,
        text: message,
        stamp: Date.now(),
        duration: options?.duration
      })
    )
  }
}

export { setBreadcrumbs, showMessage, removeMessage, MessageTypes }
