import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'
import { store } from 'store'
import Routing from 'pages'
import { ThemeProvider } from '@mui/material/styles'
import { muiTheme } from './theme'
import { HashRouter as Router } from 'react-router-dom'
import { SentryWrapper } from 'features/sentry-wrapper'
import { SnackbarMaterialProvider } from 'features/notifer/snackbar-material-provider'
import { PickersUtilsProvider } from './providers/pickers-utils-provider'
import { TranslationProvider } from './providers/translation-provider'
import { HelperProvider } from 'shared/helper-docs'
import './index.scss'
import { NotificationDeclinedDialog } from 'features/notifications/notification-reject-dialog'
import config from 'devextreme/core/config'
import { licenseKey } from './devextreme-license'

config({ licenseKey })

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, refetchOnReconnect: false } }
})

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <TranslationProvider>
          <SentryWrapper>
            <ThemeProvider theme={muiTheme}>
              <PickersUtilsProvider>
                <HelperProvider>
                  <Router>
                    <Routing />
                    <SnackbarMaterialProvider />
                    <NotificationDeclinedDialog />
                  </Router>
                </HelperProvider>
              </PickersUtilsProvider>
            </ThemeProvider>
          </SentryWrapper>
        </TranslationProvider>
      </Provider>
    </QueryClientProvider>
  )
}

export { App }
