import { IRetryPolicy, RetryContext } from '@microsoft/signalr'

export function countRetryPolicy(countReconnect = 1000, maxMsReconnect = 9000): IRetryPolicy {
  return {
    nextRetryDelayInMilliseconds: (retryContext: RetryContext) => {
      if (retryContext.previousRetryCount < countReconnect) {
        return 1000 + Math.random() * maxMsReconnect
      } else {
        return null
      }
    }
  }
}
