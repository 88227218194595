import Text from 'shared/ui-kit/text'
import { Form, FormGroup } from 'shared/ui-kit/form'
import { useTranslation } from 'shared/i18n/translate'
import { BeaconModel } from 'shared/models'

type Props = {
  data: BeaconModel
}

export default function BeaconView({ data }: Props) {
  const { dateFormatter, tr } = useTranslation()

  const d = data
  const { name, major, minor, installDate, lastChargeDate, lastSignalDate } = tr.beaconView

  return (
    <Form>
      <FormGroup>
        <Text type="caption">{name}</Text>
        <Text type="body-1">{d.name}</Text>
      </FormGroup>
      <FormGroup>
        <Text type="caption">{major}</Text>
        <Text type="body-1">{d.major}</Text>
      </FormGroup>
      <FormGroup>
        <Text type="caption">{minor}</Text>
        <Text type="body-1">{d.minor}</Text>
      </FormGroup>
      <FormGroup>
        <Text type="caption">{installDate}</Text>
        <Text type="body-1">{dateFormatter(d.installDate)}</Text>
      </FormGroup>
      <FormGroup>
        <Text type="caption">{lastChargeDate}</Text>
        <Text type="body-1">{dateFormatter(d.lastChargeDate)}</Text>
      </FormGroup>
      <FormGroup>
        <Text type="caption">{lastSignalDate}</Text>
        <Text type="body-1">{dateFormatter(d.lastSignalDate || null)}</Text>
      </FormGroup>
    </Form>
  )
}
