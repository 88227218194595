import { useEffect, useState } from 'react'
import { captcha } from 'shared/api'
import Cached from '@mui/icons-material/Cached'

const captchaSettings = {
  captchaEnabled: true
}

type Props = {
  handleCaptchaId: (captchaId: string | undefined) => void
}

export function Captcha({ handleCaptchaId }: Props) {
  const [id, setId] = useState<string | undefined>(undefined)
  const [imageBase64, setImageBase64] = useState<string | null>(null)

  useEffect(() => {
    if (captchaSettings.captchaEnabled) {
      captcha.getNew().then((newCaptchaItem) => {
        handleCaptchaId(newCaptchaItem?.id)
        setId(newCaptchaItem?.id)
        setImageBase64(newCaptchaItem?.imageBase64)
      })
    }
  }, [])

  function reloadImage() {
    if (!id) {
      return
    }

    captcha.renew(id).then((newCaptchaItem) => {
      setId(newCaptchaItem?.id)
      handleCaptchaId(newCaptchaItem?.id)
      setImageBase64(newCaptchaItem?.imageBase64)
    })
  }

  if (!captchaSettings.captchaEnabled || !imageBase64) {
    return null
  }

  return (
    <div>
      <img src={`data:image/jpeg;charset=utf-8;base64, ${imageBase64}`}></img>
      <Cached onClick={reloadImage} style={{ verticalAlign: 'top', marginLeft: '5px', cursor: 'pointer' }} />
    </div>
  )
}
