import { Additional } from './additional.model'
import { LastUpdateModel } from './last-update.model'
import { ServiceModel } from './service.model'
import { TagModel } from './tag.model'

export enum SuggestedClientsStrategy {
  SamePositions = 'SamePositions',
  DifferentPositions = 'DifferentPositions'
}

export enum LineMode {
  MobileApp = 'mobileApp',
  ScreenCall = 'screenCall',
  PaperTicket = 'paperTicket',
  WebFrame = 'webFrame'
}

export enum ServiceMode {
  None = 'none',
  SimpleList = 'simpleList'
}

export enum JoinStrategy {
  OnlyOnePosition = 'onlyOnePosition',
  OnlyOnePositionPerService = 'onlyOnePositionPerService',
  MultiplePositions = 'multiplePositions'
}

export type GeneralConditionsAcceptation = 'No' | 'Optional' | 'Mandatory'

export type MonitoringPositionMenuItemCaptions = {
  generalConditionsAcceptation?: string
  isHere?: string
  validForService?: string
  progression?: string
  arrivedAfterCall?: string
  takeInCharge?: string
  startService?: string
  endService?: string
  requestToApproach?: string
}

export interface LineModelSimplified {
  id: string | number
  name: string
  lineCalculationAlgorithmMode: string
  category: number
  allowFutureAppointments: boolean
  lineParticipantTermInPluralTemplate?: string
  lineParticipantTermInSingularTemplate?: string
  image?: { id: string; name: string }
  displayWaitingTimeEstimation?: boolean
}

export interface UpdateLineOrderReq {
  shopId: number | string
  items: {
    lineId: number | string
    order: number
  }[]
}

export type RequestPersonalInfo = {
  firstName?: boolean
  lastName?: boolean
  companyName?: boolean
  phoneNumber?: boolean
  email?: boolean
}

export type WeekdayElement = {
  weekdays: number[]
  nonStopService: boolean
  openingHour_unixtime?: number
  closingHour_unixtime?: number
  serviceWithPause: boolean
  pauseStartHour_unixtime?: number
  pauseEndHour_unixtime?: number
}

export interface LineModel {
  id: string | number
  shopId: string | number
  displayShortName: string
  name: string
  shortName: string
  category: number
  serviceTime?: number
  servicePointSuggestedClientsStrategy: SuggestedClientsStrategy
  generalConditionsAcceptation?: GeneralConditionsAcceptation
  timeToReach: number
  brandId?: string
  positionJoinStrategy: JoinStrategy
  timeToSetFreeStockPlaces?: number
  displayPositionValidate?: boolean
  sendFollowUpEmailBefore?: boolean
  setPositionFinishedAfterArrived?: boolean
  sendFollowUpEmailBeforeDays?: number
  sendFollowUpSmsBefore?: boolean
  sendFollowUpSmsBeforeDays?: number
  removeUnalertedPosition?: number
  requestCommentsOnRemove?: boolean
  maxTimeToReach: number
  checkpointCount?: number
  appointmentsToCancelCount?: number
  manageAppointments: boolean
  maximumCharactersParticipantNameUpdate?: number
  requestParticipantsNumber: boolean
  modes: LineMode[]
  isDiscrete?: boolean
  services: ServiceModel[]
  additionals?: Additional[]
  allowFutureAppointments: boolean
  limitInDays?: number
  asapMode: boolean
  minTimeToFreePlaceAfterEarlyAcceptance?: number
  blindCopyEmails?: string
  attachIcsFile?: boolean
  authoriseAppointmentOnWebPortal?: boolean
  calculateApproachByGPS?: boolean
  nearServicePointDistance?: number
  bluetoothRequired?: boolean
  displayReservationQRCode?: boolean
  sendSMSWhenJoined?: boolean
  sendSMSWhenCalled?: boolean
  smsSenderId: string
  lineCalculationAlgorithmMode: string
  beforeTheCall?: boolean
  beforeTheCallTime?: number
  reservationCodeGenerationStrategy: string
  supportAutoCall?: boolean
  maxSimultaneous: number
  visibleInWebPortal?: boolean
  confirmEmailWithValidationCode?: boolean
  displayServiceSurvey: boolean
  surveyTags?: string
  lineServicesMode?: ServiceMode
  multipleServicesSelection?: boolean
  enableAlertsDistribution?: boolean
  lastUpdate?: LastUpdateModel
  requestPersonalInfo?: RequestPersonalInfo
  sequentiallyPerformingActionsOnPosition?: boolean
  freeTimeslotsProgressively: boolean
  timeslotsFreeAllBeforeDays: number
  limitInDaysForFutureDateAdmin?: number
  retainTimeslotWhenAppointmentCancelled: boolean
  minDurationToFreeTimeslot?: number
  maxDurationToFreeTimeslot?: number
  displayPeopleBeforeMeCount?: boolean
  displayTotalPeopleInLineCount?: boolean
  displayWaitingTimeEstimationMobile?: boolean
  checkCustomerNameUnicity?: boolean
  supportSearchCustomerByPhone?: boolean
  displayPersonalInfoInReadOnlyMode?: boolean
  displayHistory?: boolean
  displayRemoveConfirm?: boolean
  requestCommentsOnPriorityCall?: boolean
  displayApproach?: boolean
  displayCalledServicePoint?: boolean
  displayReservationCode?: boolean
  displayServiceNameForOperator?: boolean
  displayServicePoints?: boolean
  displayServiceDuration?: boolean
  displayWaitingTimeEstimation?: boolean
  allowMultipleAppointmentsOnSameDay?: boolean
  allowMultipleProgressionTags?: boolean
  enableProgressionTags?: boolean
  progressionTags?: string
  allowProgressionPhoto?: boolean
  allowSendProgressionReport?: boolean
  allowSendProgressionReportToParticipant?: boolean
  progressionParticipantEmails?: string
  closeAllServicePointsAtMidnight?: boolean
  suggestOtherPlaces?: boolean
  lineAppointmentLink?: string
  webViewAgentRequestStatisticsLink?: string
  tags: TagModel[]
  acceptTimeslotsOverlapForSameCustomer?: boolean
  displayCallByPhone?: boolean
  displayInPlace?: boolean
  displayConvertToMobile?: boolean
  description: string
  image?: { id: string; name: string }
  showButtonCreatePosition?: boolean
  requestCommentOnClosingServicePoint?: boolean
  lineParticipantsFirstNameCaptionTemplate?: string
  lineParticipantsLastNameCaptionTemplate?: string
  duplicatePositionMenuItem?: boolean
  featureFlags?: string
  servicePointCanAlwaysBeOpen?: boolean
  schedule?: WeekdayElement[]
  lineParticipantTermInPluralTemplate?: string
  lineParticipantTermInSingularTemplate?: string
  lineParticipantsCaptionTemplate?: string
  sendSurveyLinkAfterPositionServed?: boolean
  sendSurveyLinkByEmail?: boolean
  sendSurveyLinkBySms?: boolean
  surveyLink?: string
  surveySilencePeriodInDays?: number
  monitoringPositionMenuItemCaptions?: MonitoringPositionMenuItemCaptions
  displayFilterByParticipantsNumber?: boolean
  displayFilterByParticipantsResetInSeconds?: number
  displayFilterByParticipantsUpperLimit?: number
  displayNormalStartServicePoint?: boolean
  displayDelayStartServicePoint?: boolean
  displaySpecificHoursStartServicePoint?: boolean
  displayAllTimeStartServicePoint?: boolean
  defaultDelayStartServicePointInMinutes?: number
  showSuggestionToFinishDialog?: boolean
  showSuggestionToFinishDialogBeforeInSec?: number
  flags?: {
    positionTransferEnabled?: boolean
    displayCustomDuration?: boolean
    displayDailyScheduler?: boolean
    displayAppointmentsScheduler?: boolean
    displayStatesMonitoring?: boolean
    suggestNextOperationOnPosition?: boolean
  }
  participantTermInSingularTemplate?: string
  participantTermInPluralTemplate?: string
}
