import { FormGroup } from 'shared/ui-kit/form'
import { IconButton } from '@mui/material'
import { ParametersGroup, SchedulerActions } from '../styeld'
import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material'
import { AdvertisementSchedule } from 'shared/models'
import { useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import TimePickerTimestamp from 'features/time/time-picker-timestamp'

type Props = {
  initValue?: AdvertisementSchedule[]
  onChange: (val: AdvertisementSchedule[]) => void
}

function DisplaySchedule(props: Props) {
  const [value, setValue] = useState<AdvertisementSchedule[]>(
    (props.initValue || []).map((el, index) => ({ ...el, id: index }))
  )

  const { tr } = useTranslation()

  function onChange(val: AdvertisementSchedule[]) {
    setValue(val)
    props.onChange(val)
  }

  function handleAdd() {
    onChange([...(value || []), { startHour_unixtime: null, endHour_unixtime: null, id: Date.now() }])
  }

  function handleDelete(id) {
    return () => {
      onChange((value || []).filter((el) => el.id !== id))
    }
  }

  function handleChangeStartHour(i) {
    return (val) => {
      const newValues = [...(value || [])]
      newValues[i].startHour_unixtime = val
      onChange(newValues)
    }
  }

  function handleChangeEndHour(i) {
    return (val) => {
      const newValues = [...(value || [])]
      newValues[i].endHour_unixtime = val
      onChange(newValues)
    }
  }

  return (
    <ParametersGroup>
      {(value || []).map((interval, i) => (
        <FormGroup key={interval.id || i} style={{ display: 'flex', gap: '1rem', alignItems: 'baseline' }}>
          <TimePickerTimestamp
            fullWidth
            label={tr.advertisementEdit.startHour}
            value={interval.startHour_unixtime || undefined}
            onChange={handleChangeStartHour(i)}
            id="displaySchedule_startHour"
            autoComplete="displaySchedule_startHour"
          />
          <TimePickerTimestamp
            fullWidth
            label={tr.advertisementEdit.endHour}
            value={interval.endHour_unixtime || undefined}
            onChange={handleChangeEndHour(i)}
            id="displaySchedule_endHour"
            autoComplete="displaySchedule_endHour"
          />
          <IconButton size="small" onClick={handleDelete(interval.id)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </FormGroup>
      ))}
      <FormGroup>
        <SchedulerActions>
          <IconButton size="small" onClick={handleAdd}>
            <AddIcon fontSize="small" />
          </IconButton>
        </SchedulerActions>
      </FormGroup>
    </ParametersGroup>
  )
}

export { DisplaySchedule }
