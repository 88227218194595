import { useEffect, useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { monitoring as monitoringApi } from 'shared/api'
import { checkpointMonitoring, checkpointHost } from 'pages/nav'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { CheckpointsStatistics } from 'shared/models'
import { CheckpointStatistic } from './checkpoint-statistic'
import { ChipListSelect } from 'shared/ui-kit/selects'
import { SearchInput } from 'features/search-input'
import { AppTitle } from 'shared/hooks/useTitle'
import { useTimeZoneFilter } from 'features/time-zone-filter'
import {
  checkpointStatisticFilters,
  checkpointStatisticFilterAction,
  filterCheckpointsStatisticsByTerm,
  filterLabelExtractor
} from './utils'
import { useParams } from 'react-router'
import { useQuery } from 'react-query'
import { useAppDispatch } from 'store'
import { CenterContainer } from 'shared/ui-kit/center-container'
import { Box, CircularProgress } from '@mui/material'

function LineMonitoringStatistics() {
  const { tr } = useTranslation()
  const dispatch = useAppDispatch()

  const { lineId, placeId } = useParams<{ lineId: string; placeId: string }>()

  const [term, setTerm] = useState<string>('')
  const [checkpointStatisticFilterSelected, setCheckpointStatisticFilterSelected] = useState<string[]>([])

  const { data: res, isLoading } = useQuery(
    ['monitoringLineStatistics', placeId, lineId],
    () => monitoringApi.monitoringLineStatisticsQuery({ lineId, shopId: placeId }),
    { refetchInterval: 5000 }
  )

  useTimeZoneFilter(placeId)

  useEffect(() => {
    if (!!res?.parents) {
      const crumb = getCrumbs(tr.breadcrumbs)
      dispatch(
        setBreadcrumbs([
          crumb.home(),
          crumb.places(),
          crumb.place([res.parents.shop.id], res.parents.shop.name),
          crumb.lines([res.parents.shop.id]),
          crumb.line([res.parents.shop.id, res.parents.line.id], res.parents.line.name),
          crumb.lineStateMonitoring([res.parents.shop.id, res.parents.line.id])
        ])
      )
    }
  }, [res?.parents])

  function handleCheckpointSettingsClick(item: CheckpointsStatistics) {
    return () => {
      window.open(`${location.origin}#${checkpointHost(placeId, lineId, String(item.checkpoint.id))}`, '_blank')
    }
  }

  function handleCheckpointMonitoringClick(item: CheckpointsStatistics) {
    return () => {
      window.open(`${location.origin}#${checkpointMonitoring(placeId, lineId, String(item.checkpoint.id))}`, '_blank')
    }
  }

  function handleClearTerm() {
    setTerm('')
  }

  function handleTermChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setTerm(event.currentTarget.value || '')
  }

  function handleFilterChange(data: string[]) {
    setCheckpointStatisticFilterSelected(data)
  }

  if (isLoading) {
    return (
      <CenterContainer>
        <CircularProgress />
      </CenterContainer>
    )
  }

  if (!res?.data) {
    return null
  }

  const renderCheckpointsStatistics = filterCheckpointsStatisticsByTerm(
    term,
    res.data.checkpointsStatistics || []
  ).filter((cs) => {
    if (!checkpointStatisticFilterSelected.length) {
      return true
    }

    return (checkpointStatisticFilterSelected || [])
      .map((csfs) => checkpointStatisticFilterAction[csfs](cs))
      .filter(Boolean).length
  })

  return (
    <Box boxSizing="border-box" width="100%" padding="1.5rem">
      <AppTitle lineName={res.data.line?.name} />
      <SearchInput
        fullWidth
        size="small"
        placeholder={tr.lineMonitoringStatistics.search}
        value={term}
        onChange={handleTermChange}
        onClear={handleClearTerm}
      />
      <Box margin="1rem 0px 2rem" textAlign="left">
        <ChipListSelect
          multiple
          size="medium"
          label={tr.lineMonitoringStatistics.status}
          list={checkpointStatisticFilters}
          value={checkpointStatisticFilterSelected}
          onChange={handleFilterChange}
          chipStyle={{ height: '32px' }}
          labelExtractor={filterLabelExtractor(tr)}
        />
      </Box>
      <Box display="grid" gap="1rem" gridTemplateColumns="repeat(auto-fill, minmax(250px, 1fr))">
        {renderCheckpointsStatistics.map((item) => (
          <CheckpointStatistic
            key={item.checkpoint.id}
            checkpoint={item.checkpoint}
            position={item.position}
            timeToReach={res.data.line?.timeToReach}
            maxTimeToReach={res.data.line?.maxTimeToReach}
            onCheckpointSettingsClick={handleCheckpointSettingsClick(item)}
            onCheckpointMonitoringClick={handleCheckpointMonitoringClick(item)}
          />
        ))}
      </Box>
    </Box>
  )
}

export default LineMonitoringStatistics
