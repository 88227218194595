import styled from '@emotion/styled'

const AppBar = styled.section`
  background-color: #db214d;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  box-shadow:
    0 1px 6px rgba(0, 0, 0, 0.12),
    0 1px 4px rgba(0, 0, 0, 0.12);
  position: relative;
  z-index: 1200;
  width: 100%;
  display: flex;
  min-height: 64px;
  align-items: center;
`

export { AppBar }
