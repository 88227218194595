import { Translations } from './translation-types'

export enum AppLocale {
  En = 'en',
  Fr = 'fr'
}

const userLanguages = [
  {
    locale: 'en',
    name: 'English'
  },
  {
    locale: 'fr',
    name: 'Français'
  }
]

export default {
  getLanguages: () => userLanguages,

  languageTagToAppLocale(tag: string): AppLocale {
    if (!tag) {
      return AppLocale.En
    }

    tag = tag.toLocaleLowerCase()

    if (tag === 'en' || tag.indexOf('en-') > -1) {
      return AppLocale.En
    }

    if (tag === 'fr' || tag.indexOf('fr-') > -1) {
      return AppLocale.Fr
    }

    return AppLocale.En
  },

  getTranslationAsync(locale: AppLocale): Promise<Translations> {
    if (locale === AppLocale.Fr) {
      return import('./fr').then((data) => data.translations)
    }

    return import('./enGB').then((data) => data.translations)
  },

  getLocaleByBrowserLang(): AppLocale {
    const currentLang = navigator.language

    return this.languageTagToAppLocale(currentLang)
  }
}
