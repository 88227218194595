import { FC, forwardRef } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const CallSmsIcon: FC<SvgIconProps> = forwardRef((props, ref) => {
  return (
    <SvgIcon {...props} ref={ref}>
      <path
        d="M20 2H4C2.9 2 2.01 2.9 2.01 4L2 22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2Z"
        fill={props.color}
      />
      <path
        d="M12 15C12.55 15 13 14.55 13 14H11C11 14.55 11.445 15 12 15ZM15 12V9.5C15 7.965 14.18 6.68 12.75 6.34V6C12.75 5.585 12.415 5.25 12 5.25C11.585 5.25 11.25 5.585 11.25 6V6.34C9.815 6.68 9 7.96 9 9.5V12L8 13V13.5H16V13L15 12Z"
        fill="white"
      />
    </SvgIcon>
  )
})

export { CallSmsIcon }
export default CallSmsIcon
