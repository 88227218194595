import { createElement } from 'react'
import css from './empty-screen.module.scss'
import EmptyIcon from '@mui/icons-material/Storage'

const iconProps = {
  style: { width: 96, height: 96, verticalAlign: 'middle', color: 'rgba(0, 0, 0, .17)' }
}

type EmptyScreenProps = {
  iconClass?: any
  text: string
}

function EmptyScreen(props: EmptyScreenProps) {
  return (
    <div className={css.root}>
      <div className={css.iconWrap}>{createElement(props.iconClass || EmptyIcon, iconProps)}</div>
      <div className={css.text}>{props.text}</div>
    </div>
  )
}

export default EmptyScreen
