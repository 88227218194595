import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { monitoring, line } from 'shared/api'
import produce from 'immer'
import PageLoader from 'shared/ui-kit/page-loader/page-loader'
import { PositionEdit } from 'features/position-edit'
import debounce from 'lodash/debounce'
import { getSupportedPositionTypesFromLine } from 'features/position-edit/supported-position-types-from-line'
import { showMessage, MessageTypes } from 'store/actions/main-layout-action-creators'
import { useHistory, useLocation } from 'react-router-dom'
import * as Nav from 'pages/nav'
import { EditPositionConfigModel, EditPositionModel, PositionType, SearchCustomer, TimeslotModel } from 'shared/models'
import Container from 'shared/ui-kit/container'
import { getDuplicatePosition } from 'features/position-actions/utils/duplicateStorage'
import { useAppDispatch, useAppSelector } from 'store'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { MonitoringPositionEditProps, PositionRequestParams } from './types'

function MonitoringPositionEdit(props: MonitoringPositionEditProps) {
  const [data, setData] = useState<EditPositionModel>({ services: [], customer: {} })
  const [config, setConfig] = useState<EditPositionConfigModel | null>(null)
  const [timeslots, setTimeslots] = useState<TimeslotModel[]>([])
  const [loading, setLoading] = useState(false)
  const [loadingPersons, setLoadingPersons] = useState(false)
  const [availableServices, setAvailableServices] = useState<(string | number)[]>([])
  const [availableCheckpoints, setAvailableCheckpoints] = useState<(string | number)[]>([])
  const [positionTypes, setPositionTypes] = useState<string[]>([])
  const [singleUserAvailable, setSingleUserAvailable] = useState(false)
  const [users, setUsers] = useState<SearchCustomer[]>([])
  const [currentMaxPersonsInGroup, setCurrentMaxPersonsInGroup] = useState<number | undefined>()

  const { tr } = useTranslation()

  const { canAddAndEditPositions } = useAppSelector(getPermissionsSelector)
  const dispatch = useAppDispatch()

  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    fetchData()
  }, [])

  function getIsDuplicate() {
    const duplicateParam = new URLSearchParams(location.search).get('duplicate')
    return duplicateParam !== null && duplicateParam !== 'false'
  }

  function getFromJournal() {
    const fromJournalParam = new URLSearchParams(location.search).get('fromJournal')
    return fromJournalParam !== null && fromJournalParam !== 'false'
  }

  async function fetchData() {
    const { positionId, lineId, placeId, checkpointId } = props

    setLoading(true)

    try {
      const res = await monitoring.getPositionEditForm({ positionId, lineId, placeId, checkpointId })

      const supportedPositionTypes = getSupportedPositionTypesFromLine(res.config.line)

      if (!canAddAndEditPositions && !supportedPositionTypes.find((el) => el === PositionType.Break)) {
        history.push(Nav.index())
        return
      }

      if (res.data.position) {
        props.onBreadcrumbsChange?.(res)
      }

      const storageItem = getDuplicatePosition(props.placeId)
      const storagePosition = storageItem?.position
      const singlePositionModes = supportedPositionTypes?.length === 1
      const isSupportScreenCall = supportedPositionTypes?.includes(PositionType.ScreenCall)

      setConfig(res.config)
      setAvailableServices(res.data.availableServices)
      setAvailableCheckpoints(res.data.availableCheckpoints)
      setPositionTypes(supportedPositionTypes)
      setData(res.data.position)
      setCurrentMaxPersonsInGroup(res.data.currentMaxPersonsInGroup)

      if (!res.data.position?.type && singlePositionModes) {
        setData((curr) => ({ ...curr, type: supportedPositionTypes[0] }))
      }

      if (getIsDuplicate() && storagePosition && isSupportScreenCall) {
        setData((curr) => {
          const customer = curr?.customer || {}

          if (storagePosition.customer?.companyName) {
            customer.companyName = storagePosition.customer.companyName
          }

          if (storagePosition.customer?.email) {
            customer.email = storagePosition.customer.email
          }

          if (storagePosition.customer?.firstName) {
            customer.firstName = storagePosition.customer.firstName
          }

          if (storagePosition.customer?.lastName) {
            customer.lastName = storagePosition.customer.lastName
          }

          if (storagePosition.customer?.language) {
            customer.language = storagePosition.customer.language
          }

          if (storagePosition.customer?.phoneNumber) {
            customer.phoneNumber = storagePosition.customer.phoneNumber
          }

          const earlySelectedServicesNames = (storagePosition.services || [])?.map((service) => service.name) || []

          return {
            ...curr,
            type: PositionType.ScreenCall,
            originalPositionId: storagePosition.id,
            originalPositionLineId: storageItem?.back?.lineId,
            originalPositionPlaceId: storageItem?.back?.placeId,
            deleteOriginalPosition: !getFromJournal(),
            customer,
            additionals: storagePosition.additionals ?? curr.additionals,
            staffComment: storagePosition.staffComment ?? curr.staffComment,
            personsQuantity:
              storagePosition.personsQuantity !== 1 && res?.config?.line?.requestParticipantsNumber
                ? storagePosition.personsQuantity
                : curr.personsQuantity,
            services:
              earlySelectedServicesNames.length && !!res?.config?.line?.services?.length
                ? res.config.line.services.filter((service) => {
                    return earlySelectedServicesNames.some((e) => e === service.name)
                  })
                : curr.services
          }
        })
      }
    } finally {
      setLoading(false)
    }
  }

  function handleSubmitDataChange(params: PositionRequestParams, callback: (positionId: string | number) => void) {
    setLoading(true)

    if (data.id) {
      editPositionRequest(params, callback)
    } else {
      createPositionRequest(params, callback)
    }
  }

  async function createPositionRequest(params: PositionRequestParams, callback: (positionId: string | number) => void) {
    try {
      const res = await monitoring.createPosition({ ...data, ...params, placeId: props.placeId, lineId: props.lineId })

      dispatch(showMessage(tr.lineMonitoring.positionAdded, MessageTypes.Success))
      callback(res)
    } finally {
      setLoading(false)
    }
  }

  async function editPositionRequest(params: PositionRequestParams, callback: (positionId: string | number) => void) {
    try {
      const res = await monitoring.editPosition({ ...data, ...params, placeId: props.placeId, lineId: props.lineId })

      dispatch(showMessage(tr.lineMonitoring.positionUpdated, MessageTypes.Success))
      callback(res)
    } finally {
      setLoading(false)
    }
  }

  function handleSearchCustomer(phoneNumber: string, callback?: () => void) {
    if (!phoneNumber || !phoneNumber.length || phoneNumber.length < 9) {
      callback?.()
      return
    }

    setLoading(true)

    searchCustomerAction(phoneNumber, (isError) => {
      setLoading(false)
      if (!isError) {
        callback?.()
      }
    })
  }

  function handleSearchUser(data, callback) {
    if (data.term?.length < 3) {
      setUsers([])
    } else {
      setLoadingPersons(true)

      const dataWithLineId = produce(data, () => {
        data.lineId = props.lineId
      })

      searchUserAction(dataWithLineId, () => {
        setLoadingPersons(false)
        callback?.()
      })
    }
  }

  async function searchCustomerAction(phoneNumber: string, callback: (isError?: boolean) => void) {
    try {
      const response = await line.getCustomers({ phoneNumber })

      if (response.data && response.data.length) {
        handlePropertyChange('customer', response.data[0], callback)
      } else {
        handlePropertyChange('customer', { phoneNumber }, callback)
      }
    } catch {
      callback(true)
    }
  }

  const searchUserAction = useMemo(
    () =>
      debounce(async (data, callback: () => void) => {
        try {
          const response = await monitoring.getPositionUsers(data)

          setUsers(response)

          if (!data.term && response.length === 1) {
            setSingleUserAvailable(true)
          } else {
            setSingleUserAvailable(false)
          }
        } finally {
          callback()
        }
      }, 300),
    []
  )

  function handlePropertyChange(prop, value, callback) {
    setData((curr) => {
      const newData = { ...curr }
      newData[prop] = value
      return newData
    })

    callback?.()
  }

  function handleSubmit(params?: PositionRequestParams) {
    handleSubmitDataChange(params || {}, (positionId) => {
      const storageItem = getDuplicatePosition(props.placeId)

      if (getIsDuplicate() && storageItem?.back?.link && storageItem?.position?.id) {
        history.push(storageItem.back.link)
        return
      }

      const fullName = [data?.customer?.firstName, data?.customer?.lastName].filter(Boolean).join(' ')

      const term =
        data?.customer?.phoneNumber ||
        data?.customer?.email ||
        [data?.customer?.companyName, fullName].filter(Boolean).join(' - ')

      props.onSubmit(String(positionId) || term)
    })
  }

  function onTimeslotRequest() {
    setTimeslots([])
    fetchTimeslots()
  }

  async function fetchTimeslots() {
    setLoading(true)

    try {
      const response = await monitoring.getTimeslots({
        positionId: props.positionId,
        lineId: props.lineId,
        placeId: props.placeId,
        personsQuantity: data.personsQuantity,
        priority: data.priority,
        positionType: data.type,
        customServiceDuration: data.customServiceDuration ? Math.floor(data.customServiceDuration) : undefined,
        servicesIds: (data.services || []).map((s) => s.id),
        checkpointId: data.customCheckpoint ? data.customCheckpoint.id : null
      })

      setTimeslots(response.data.timeslots)
    } finally {
      setLoading(false)
    }
  }

  function onCleanTimeslots() {
    setTimeslots([])
  }

  if (config === null) {
    return null
  }

  return (
    <Container>
      <PositionEdit
        isEdit={!!props.positionId}
        data={data}
        config={config}
        onPropertyChange={handlePropertyChange}
        onTimeslotRequest={onTimeslotRequest}
        onCleanTimeslots={onCleanTimeslots}
        timeslots={timeslots}
        onSubmit={handleSubmit}
        isFetching={loading || loadingPersons}
        onSearchCustomer={handleSearchCustomer}
        onSearchUser={handleSearchUser}
        availableServices={availableServices}
        availableCheckpoints={availableCheckpoints}
        positionTypes={positionTypes}
        singleUserAvailable={singleUserAvailable}
        users={users}
        checkpointId={props.checkpointId}
        lineId={props.lineId}
        placeId={props.placeId}
        isDuplicate={getIsDuplicate()}
        fromJournal={getFromJournal()}
        currentMaxPersonsInGroup={currentMaxPersonsInGroup}
      />
      {loading || (loadingPersons && <PageLoader style={{ marginTop: '8px' }} />)}
    </Container>
  )
}

export default MonitoringPositionEdit
