import { CSSProperties, useMemo, ReactNode } from 'react'
import css from './text.module.scss'
import { withMods } from 'shared/utils/cssm'
import { injectTestAttr } from 'shared/utils/test-attr'

type Props = {
  type?: 'body-1' | 'body-2' | 'body-3' | 'error-2' | 'caption'
  preLine?: boolean
  className?: string
  size?: 1 | 2 | 3 | 4
  fade?: 0 | 1 | 2
  style?: CSSProperties
  children: ReactNode
}

const mod = withMods(css)

function TextComponent(props: Props) {
  const { type, preLine, className, size, fade, style, children } = props

  const cls = useMemo(() => {
    const classes = mod.root({ type, size, fade, preLine })

    if (className) {
      return `${classes} ${className}`
    }

    return classes
  }, [className, type, size, fade, preLine])

  return (
    <div className={cls} style={style} {...injectTestAttr(props)}>
      {children}
    </div>
  )
}

export default TextComponent
export const Text = TextComponent
