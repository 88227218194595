import { useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { SubBar } from 'shared/ui-kit/sub-bar'
import { QRScanner } from 'features/qr-scanner/qr-scanner'
import { Menu, IconButton, Tooltip, Typography, Button, Badge, InputAdornment } from '@mui/material'
import { MenuItem, ListItemIcon } from '@mui/material'
import { Add as AddIcon, Link as LinkIcon, MoreVert as MoreIcon, AccessTime as TimeIcon } from '@mui/icons-material'
import { ShoppingBasket as BasketIcon } from '@mui/icons-material'
import { QrCodeScanner as QrCodeScannerIcon } from '@mui/icons-material'
import { Stop as StopIcon, FilterList as FilterIcon, Clear as ClearIcon } from '@mui/icons-material'
import { PlayArrow as PlayIcon } from '@mui/icons-material'
import { checkpoints as checkpointsNav, discreteLineMonitoringCreate, checkpointHost } from 'pages/nav'
import { PositionStatusesFilter, PositionQueueType } from 'shared/utils/positions_filters'
import { CheckpointStatus } from 'shared/models'
import { NavLink, useHistory } from 'react-router-dom'
import { PersonsCount } from 'features/position/persons-count'
import { SearchInput } from 'features/search-input'
import { MultipleCheckSelect } from 'shared/ui-kit/multiple-check-select'
import { getQueueTypeTranslations } from '../utils'
import { BarWrapper, BarContent, MenuWrapper, SearchWrapper, PositionCode, StatsWrapper } from '../styled'
import { FilterMenuWrapper, IconWrapper } from '../styled'
import { StatsMin } from '../styled'
import { defaultFilters } from '../utils'
import { useAppSelector } from 'store'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { DiscreteMonitoringBarProps, DiscreteMonitoringFilters } from '../types'

function DiscreteMonitoringBar(props: DiscreteMonitoringBarProps) {
  const { tr } = useTranslation()
  const { canAddAndEditPositions } = useAppSelector(getPermissionsSelector)
  const { monitoring } = props

  const history = useHistory()

  const [filterAnchorEl, setFilterAnchorEl] = useState<any>()
  const [anchorEl, setAnchorEl] = useState<any>()
  const [qrIsActive, setQrIsActive] = useState(false)

  function openFilterMenu(ev) {
    setFilterAnchorEl(ev.currentTarget)
  }

  function closeFilterMenu() {
    setFilterAnchorEl(null)
  }

  function handleCheckpointHost() {
    const checkpointId = props.checkpoints?.[0].id

    if (!checkpointId) {
      return
    }

    history.push(checkpointHost(props.shopId, props.lineId, String(checkpointId)), true)
  }

  function handleFilterChange<T extends keyof DiscreteMonitoringFilters>(key: T) {
    return (value: DiscreteMonitoringFilters[T]) => {
      const newFilters = { ...props.filter }
      newFilters[key] = value
      props.setFilters(newFilters)
    }
  }

  function handleCreatePosition() {
    history.push(discreteLineMonitoringCreate(props.shopId, props.lineId))
  }

  function openMoreMenu(event) {
    setAnchorEl(event.currentTarget)
  }

  function closeMoreMenu() {
    setAnchorEl(null)
  }

  function setQRDialog(qrIsActive: boolean) {
    return () => setQrIsActive(qrIsActive)
  }

  const filterBadgeCount =
    (props.filter.positionStatusesSelected?.length || 0) +
    (props.filter.positionQueueTypeSelected?.length || 0) +
    (props.filter.positionServicesSelected?.length || 0) +
    (props.filter.positionTypeSelected?.length || 0)

  const isTerm = !!props.filter.termValue || !!props.filter.positionId
  const isStart = props.checkpoints?.some?.((c) =>
    [CheckpointStatus.starting, CheckpointStatus.started].includes(c?.status)
  )
  const isSingleCheckpoint = props.checkpoints?.length === 1
  const queueTypeTranslations = getQueueTypeTranslations(tr)
  const registeredPositionsCount = monitoring.registeredPositionsCount || 0
  const min = monitoring.waitingTime
  const finishing = monitoring.lineIsActive === false

  const positionQueueTypeValues = Object.values(PositionQueueType).filter((type) => {
    if (
      (!props.line.displayInPlace && (type === PositionQueueType.inLineHere || type === PositionQueueType.queue)) ||
      (!props.line.displayPositionValidate &&
        (type === PositionQueueType.isValid || type === PositionQueueType.isNotValid))
    ) {
      return false
    }

    return true
  })

  return (
    <>
      <SubBar>
        <BarWrapper>
          <BarContent $isLargeSize={!!props.simplifyMode}>
            {!props.simplifyMode && !!canAddAndEditPositions && (
              <IconButton onClick={handleCreatePosition}>
                <AddIcon />
              </IconButton>
            )}
            {!props.simplifyMode && (
              <IconButton onClick={openMoreMenu}>
                <MoreIcon />
              </IconButton>
            )}
            {!props.simplifyMode && (
              <IconButton disabled={qrIsActive} onClick={setQRDialog(true)}>
                <QrCodeScannerIcon fontSize="small" />
              </IconButton>
            )}
          </BarContent>
          <SearchWrapper>
            {!props.filter.positionId && (
              <SearchInput
                style={{
                  margin: props.simplifyMode ? '0 0.5rem' : '0 1rem',
                  flex: '1 1 auto',
                  zoom: props.simplifyMode ? 1.3 : 1
                }}
                inputRef={props.termRef}
                placeholder={tr.lineMonitoring.search}
                onChange={props.handleTermChangeInput}
                defaultValue={props.filter.termValue}
                fullWidth
                onClear={props.handleClearTerm}
                clearDisabled={!props.filter.termValue}
                onFocus={props.onSearchInputFocus}
                onBlur={props.onSearchInputBlur}
              />
            )}
            {!!props.filter.positionId && (
              <PositionCode>
                <Typography component="div" variant="body1" style={{ flex: 1 }}>
                  {props.filter.positionId}
                </Typography>
                <InputAdornment position="end">
                  <IconButton size="small" onClick={props.handleClearPositionId}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              </PositionCode>
            )}
          </SearchWrapper>
          {!props.simplifyMode && !props.smallScreen && (
            <StatsWrapper>
              {!!registeredPositionsCount && (
                <Tooltip
                  arrow
                  title={tr.getParticipantFormFromTemplates(
                    registeredPositionsCount,
                    props?.line?.lineParticipantTermInSingularTemplate,
                    props?.line?.lineParticipantTermInPluralTemplate
                  )}
                >
                  <div style={{ marginRight: '0.5rem' }}>
                    <PersonsCount>{registeredPositionsCount}</PersonsCount>
                  </div>
                </Tooltip>
              )}
              {!finishing && !!props?.line?.displayWaitingTimeEstimation && (
                <StatsWrapper style={{ gap: 4 }}>
                  <TimeIcon style={{ color: 'rgba(0,0,0,.28)' }} />
                  <StatsMin>{tr.time.hmin(min)}</StatsMin>
                </StatsWrapper>
              )}
            </StatsWrapper>
          )}
          {!props.simplifyMode && !!isSingleCheckpoint && (
            <IconWrapper>
              <Tooltip arrow title={isStart ? tr.checkpointList.stopServicePoint : tr.checkpointList.startServicePoint}>
                <IconButton onClick={handleCheckpointHost} style={{ marginLeft: 'auto' }}>
                  {isStart ? <StopIcon /> : <PlayIcon />}
                </IconButton>
              </Tooltip>
            </IconWrapper>
          )}
          {!props.simplifyMode && (
            <IconWrapper>
              <IconButton onClick={openFilterMenu} disabled={isTerm}>
                <Badge color="secondary" badgeContent={filterBadgeCount} max={100} invisible={isTerm}>
                  <FilterIcon />
                </Badge>
              </IconButton>
            </IconWrapper>
          )}
        </BarWrapper>
        <QRScanner active={qrIsActive} onSelectPosition={props.handleSelectPosition} onClose={setQRDialog(false)} />
      </SubBar>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={closeMoreMenu} elevation={2}>
        <MenuWrapper>
          <NavLink to={checkpointsNav(props.shopId, props.lineId)} style={{ textDecoration: 'none', color: '#000' }}>
            <MenuItem>
              <ListItemIcon style={{ minWidth: '36px' }}>
                <BasketIcon fontSize="small" />
              </ListItemIcon>
              {tr.lineMonitoring.servicePoints}
              <LinkIcon color="disabled" fontSize="small" style={{ marginLeft: '0.5rem' }} />
            </MenuItem>
          </NavLink>
        </MenuWrapper>
      </Menu>
      <Menu anchorEl={filterAnchorEl} open={!!filterAnchorEl} onClose={closeFilterMenu} elevation={2}>
        <FilterMenuWrapper>
          <MultipleCheckSelect
            fullWidth
            variant="outlined"
            style={{ marginBottom: 8 }}
            label={tr.lineMonitoring.status}
            list={PositionStatusesFilter}
            value={props.filter.positionStatusesSelected || []}
            onChange={handleFilterChange('positionStatusesSelected')}
            lableExtractor={(item) => tr.positionState[item]}
          />
          {!!props.line.services?.length && (
            <MultipleCheckSelect
              variant="outlined"
              style={{ marginBottom: 8 }}
              fullWidth
              label={tr.lineMonitoring.service}
              list={props.line.services || []}
              value={props.filter.positionServicesSelected || []}
              onChange={handleFilterChange('positionServicesSelected')}
              lableExtractor={(item) => item?.name}
              valueExtractor={(item) => item?.id || ''}
            />
          )}
          {!!props.modesAvailableForFiltering?.length && (
            <MultipleCheckSelect
              variant="outlined"
              fullWidth
              style={{ marginBottom: 8 }}
              label={tr.lineMonitoring.positionType}
              list={props.modesAvailableForFiltering}
              value={props.filter.positionTypeSelected || []}
              onChange={handleFilterChange('positionTypeSelected')}
              lableExtractor={(item) =>
                tr.lineMonitoring.positionTypes[item] || tr.lineMonitoring.positionTypes.unknown
              }
            />
          )}
          <MultipleCheckSelect
            variant="outlined"
            fullWidth
            label={tr.lineMonitoring.otherCriterias}
            list={positionQueueTypeValues}
            value={props.filter.positionQueueTypeSelected || []}
            onChange={handleFilterChange('positionQueueTypeSelected')}
            lableExtractor={(item) => queueTypeTranslations[item] || queueTypeTranslations.unknown}
          />
          <div style={{ margin: '0.5rem -4px -4px', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              size="small"
              color="primary"
              disabled={!filterBadgeCount}
              onClick={() => props.setFilters(defaultFilters)}
            >
              {tr.lineMonitoring.reset}
            </Button>
          </div>
        </FilterMenuWrapper>
      </Menu>
    </>
  )
}

export { DiscreteMonitoringBar }
