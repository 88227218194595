import Card from 'shared/ui-kit/card'
import { Position } from 'features/position-dumb'
import PositionActionsStatic from 'features/position-actions/position-actions-static'
import { CheckpointModel, LineModel, PositionModel } from 'shared/models'
import { DuplicateLine } from 'shared/models/entities/get-duplicate-form'
import { forwardRef } from 'react'

export type OnDuplicatePositionType = (data: {
  placeId: string | number
  line: DuplicateLine
  position: PositionModel
}) => void

type Props = {
  shopId: string | number
  lineId: string | number
  position: PositionModel
  line: LineModel
  checkpoints: CheckpointModel[]
  onValidatePosition?: () => void
  onClick: () => void
  onRemove?: () => void
  onUpdate: () => void
  onStateChange: (newState) => void
  params: {
    onlyASAP?: boolean
    onlyOneUser?: boolean
    selectedId?: string | number | null
    supportMobile?: boolean
    showButtonCreatePosition?: boolean
    waitingUpdate?: boolean
    displayPeopleInPosition?: boolean
  }
  onDuplicatePosition: OnDuplicatePositionType
  onActionsClose?: () => void
}

export const PositionCard = forwardRef<HTMLDivElement, Props>(
  (
    {
      checkpoints,
      line,
      lineId,
      onClick,
      onRemove,
      onStateChange,
      onUpdate,
      onValidatePosition,
      onDuplicatePosition,
      params,
      position,
      shopId,
      onActionsClose
    },
    ref
  ) => {
    const { onlyASAP, onlyOneUser, showButtonCreatePosition, supportMobile } = params
    const { selectedId, waitingUpdate } = params

    const focused = String(selectedId) === String(position.id)

    return (
      <Card ref={ref} key={position.id} paperStyle={{ overflow: 'hidden' }}>
        <Position
          data={position}
          readonly={position.readonly}
          onClick={onClick}
          onlyASAP={onlyASAP || false}
          onlyOneUser={onlyOneUser || false}
          supportAutoCall={line.supportAutoCall || false}
          timeToReach={line.timeToReach}
          maxTimeToReach={line.maxTimeToReach}
          displayServicePoints={line.displayServicePoints || false}
          lineParticipantTermInPluralTemplate={line?.lineParticipantTermInPluralTemplate}
          lineParticipantTermInSingularTemplate={line?.lineParticipantTermInSingularTemplate}
          monitoringPositionMenuItemCaptions={line.monitoringPositionMenuItemCaptions}
          additionals={line.additionals || []}
          waitingUpdate={waitingUpdate}
          reducedMobile={focused}
          displayPeopleInPosition={params.displayPeopleInPosition}
          displayServiceDuration={line.displayServiceDuration || false}
          displayWaitingTimeEstimation={line.displayWaitingTimeEstimation || false}
          isOnlyOneServicePoint={checkpoints?.length < 2}
        >
          <PositionActionsStatic
            opened={focused}
            onOpen={onClick}
            position={position}
            readonly={position.readonly}
            onValidatePosition={onValidatePosition}
            displayServiceSurvey={line.displayServiceSurvey}
            sendSurveyLinkAfterPositionServed={line.sendSurveyLinkAfterPositionServed}
            sendSurveyLinkByEmail={line.sendSurveyLinkByEmail}
            sendSurveyLinkBySms={line.sendSurveyLinkBySms}
            surveyTags={line.surveyTags}
            style={{ width: '100%', marginTop: '0.5rem' }}
            positionId={position.id}
            customerId={position.customer?.id}
            shopId={shopId}
            lineId={lineId}
            progressionTags={line.progressionTags}
            allowMultipleProgressionTags={line.allowMultipleProgressionTags}
            supportMobile={supportMobile}
            positionState={position.state}
            showButtonCreatePosition={showButtonCreatePosition || false}
            checkpoints={checkpoints}
            customCheckpointId={position.customCheckpoint?.id}
            onRemove={onRemove}
            onStateChange={onStateChange}
            phoneNumber={position?.callPhoneNumber || position.customer?.phoneNumber}
            onUpdate={onUpdate}
            displayInLineHere={line.displayInPlace}
            displayPositionValidate={line.displayPositionValidate}
            displayHistory={line.displayHistory}
            displayHasApproach={line.displayApproach}
            displayRemoveConfirm={line.displayRemoveConfirm}
            sequentiallyPerformingActionsOnPosition={line.sequentiallyPerformingActionsOnPosition}
            requestCommentsOnRemove={line.requestCommentsOnRemove}
            setPositionFinishedAfterArrived={line.setPositionFinishedAfterArrived}
            displayCallByPhone={line.displayCallByPhone}
            displayConvertToMobile={line.displayConvertToMobile}
            requestCommentsOnPriorityCall={line.requestCommentsOnPriorityCall}
            duplicatePositionMenuItem={line.duplicatePositionMenuItem}
            onDuplicatePosition={onDuplicatePosition}
            generalConditionsAcceptation={line.generalConditionsAcceptation}
            monitoringPositionMenuItemCaptions={line.monitoringPositionMenuItemCaptions}
            allowProgressionPhoto={line.allowProgressionPhoto}
            onClose={onActionsClose}
            lineParticipantTermInSingularTemplate={line?.lineParticipantTermInSingularTemplate}
            positionTransferEnabled={line?.flags?.positionTransferEnabled}
            supportAutoCall={line?.supportAutoCall}
            suggestNextOperationOnPosition={line?.flags?.suggestNextOperationOnPosition}
            closeAfterActionIsFinished
          />
        </Position>
      </Card>
    )
  }
)
