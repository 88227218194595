import { useEffect, useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import LoadMore from 'shared/ui-kit/load-more'
import { DailyStatsItem } from './daily-stats-item'
import { getIsolatedBrandList } from 'store/actions/brand-action-creators'
import EmptyScreen from 'shared/ui-kit/empty-screen'
import { dailyStatsCustomers } from 'pages/nav'
import { DatePicker } from 'features/date'
import PageLoader from 'shared/ui-kit/page-loader'
import TodayIcon from '@mui/icons-material/Today'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import Container from 'shared/ui-kit/container'
import { SubBar } from 'shared/ui-kit/sub-bar'
import { useAppDispatch, useAppSelector } from 'store'
import { useHistory, useLocation } from 'react-router'
import { getBrandListSelector } from 'store/selectors/brandListSelectors'
import { useDataProvider } from 'features/isolated-data-provider'
import { BrandFilter, PlaceFilter } from 'features/filters'
import { dailyStats as dailyStatsApi } from 'shared/api'
import { useInfiniteQuery } from 'react-query'
import { Bar } from 'shared/ui-kit/bar'

function DailyStats() {
  const history = useHistory()
  const location = useLocation()
  const { tr } = useTranslation()
  const brands = useAppSelector(getBrandListSelector)
  const dispatch = useAppDispatch()

  const { places } = useDataProvider()

  const [brandId, setBrandId] = useState<string | number | null>(
    new URLSearchParams(location.search).get('brandId') || null
  )
  const [placeId, setPlaceId] = useState<string | number | null>(
    new URLSearchParams(location.search).get('placeId') || null
  )
  const initialDate = new URLSearchParams(location.search).get('date')
  const [upperDate, setUpperDate] = useState<number | null>(
    initialDate ? +initialDate : new Date().setUTCHours(0, 0, 0, 0)
  )

  const {
    data: dailyStatsItems,
    isLoading,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery(
    ['dailyStats', brandId, placeId, upperDate],
    ({ signal, pageParam }) =>
      dailyStatsApi.getDailyStatsList(
        {
          shopId: placeId ? placeId : undefined,
          brandId: brandId ? brandId : undefined,
          upperDate,
          count: 5,
          skip: (pageParam || 0) * 5
        },
        { signal }
      ),
    {
      enabled: true,
      getNextPageParam: (lastPage, pages) => (lastPage?.data?.length ? pages.length : undefined),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false
    }
  )

  useEffect(() => {
    const crumb = getCrumbs(tr.breadcrumbs)
    dispatch(setBreadcrumbs([crumb.home(), crumb.reports(), crumb.dailyStats()]))

    if (!brands) {
      dispatch(getIsolatedBrandList())
    }
  }, [])

  useEffect(() => {
    const params = new URLSearchParams()
    if (upperDate) {
      params.set('date', String(upperDate))
    }
    if (brandId) {
      params.set('brandId', String(brandId))
    }
    if (placeId) {
      params.set('placeId', String(placeId))
    }

    history.replace({ search: params.toString() })
  }, [upperDate, brandId, placeId])

  function handleCustomersClick(id: string | number) {
    const search = new URLSearchParams()
    search.set('dailyStatsId', String(id))
    if (brandId) {
      search.set('brandId', String(brandId))
    } else if (placeId) {
      search.set('placeId', String(placeId))
    }

    history.push(`${dailyStatsCustomers()}?${search.toString()}`)
  }

  function handleDateChange(date) {
    setUpperDate(+date)
  }

  return (
    <>
      {!!places && !!brands && (
        <Bar>
          <div style={{ width: '100%', maxWidth: '640px' }}>
            <SubBar style={{ display: 'flex', gap: '0.5rem', padding: '0.5rem 0' }}>
              {brands?.length > 1 && (
                <BrandFilter
                  brands={brands}
                  brandId={brandId}
                  onChange={(brandId) => {
                    setBrandId(brandId)
                    setPlaceId(null)
                  }}
                />
              )}
              {places && !brandId && (
                <PlaceFilter places={places} placeId={placeId} onChange={(placeId) => setPlaceId(placeId)} />
              )}
              <DatePicker
                onChange={handleDateChange}
                value={upperDate}
                style={{ minWidth: '130px', width: '100%' }}
                label={tr.reportsFilter.upperDate}
              />
            </SubBar>
          </div>
        </Bar>
      )}
      <Container>
        {isLoading && <PageLoader />}
        {dailyStatsItems?.pages?.length === 0 && (
          <EmptyScreen text={tr.dailyStatsList.emptyMessage} iconClass={TodayIcon} />
        )}
        {dailyStatsItems?.pages?.map((page, i) => (
          <div key={i}>
            {page.data.map((item) => (
              <DailyStatsItem key={item.id} data={item} onCustomersClick={handleCustomersClick} />
            ))}
          </div>
        ))}
        {hasNextPage && <LoadMore onClick={fetchNextPage} />}
      </Container>
    </>
  )
}

export default DailyStats
