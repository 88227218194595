import { TextField } from 'shared/ui-kit/text-field'
import { useTranslation } from 'shared/i18n/translate'
import uniqueId from 'lodash/uniqueId'
import { PositionCustomer } from 'shared/models'
import { isMultipleEmail } from 'shared/utils/string-test'

type Params = {
  handleCustomerPropertyChange<T extends keyof PositionCustomer>(propertyName: T, value: PositionCustomer[T]): void
  customer?: PositionCustomer
}

function getEmailField(props: Params) {
  const { tr } = useTranslation()

  const { email } = tr.lineMonitoring
  const emailData = props.customer?.email || ''

  const isValid = () => isMultipleEmail(emailData) || emailData === ''

  const view = (
    <TextField
      autocomplete={uniqueId('autocomplete')}
      label={email}
      placeholder={email}
      value={emailData}
      attention={!isValid()}
      onChange={(val) => props.handleCustomerPropertyChange('email', val)}
    />
  )

  return { view, isValid }
}

export { getEmailField }
