import { Divider } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import PositionActionComponent from '../../components/position-action'
import { ConvertToMobileActionProps } from './types'
import MobileIcon from '@mui/icons-material/AdUnits'
import { useAppSelector } from 'store'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { PositionType } from 'shared/models'

function ConvertToMobile(props: ConvertToMobileActionProps) {
  const { tr } = useTranslation()

  const { canAddAndEditPositions } = useAppSelector(getPermissionsSelector)

  if (!canAddAndEditPositions || !props.supportMobile) {
    return null
  }

  if (props.positionType === PositionType.MobileApp) {
    return null
  }

  return (
    <>
      {!props.isCardView && <Divider />}
      <PositionActionComponent
        isCardView={props.isCardView}
        onSubmit={props.onClick}
        icon={<MobileIcon fontSize="small" />}
      >
        {tr.positionActions.convertToMobile}
      </PositionActionComponent>
    </>
  )
}

export { ConvertToMobile }
