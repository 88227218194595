import { useTranslation } from 'shared/i18n/translate'
import Text from 'shared/ui-kit/text'
import { JournalActionModel } from 'shared/models'
import { Chip, Stack } from '@mui/material'
import { file } from 'shared/api'
import { ProgressionImageLink } from '../styled'

function RenderPositionProgression({ action }: { action: JournalActionModel }) {
  const { tr } = useTranslation()

  function handleUploadFile(id: string) {
    return (ev: React.MouseEvent<HTMLDivElement>) => {
      ev.stopPropagation()
      file.download(id)
    }
  }

  return (
    <div style={{ margin: '0.5rem 0', display: 'flex', gap: '0.5rem', flexDirection: 'column' }}>
      <div style={{ flex: 1 }}>
        <Text type="caption">{tr.journal.progressionTags.title}</Text>
        <Stack spacing={0.5} direction="row">
          {action.progressionTags?.value
            .split(';')
            .map((tag, i) => <Chip label={tag} variant="outlined" key={i} size="small" />)}
        </Stack>
      </div>
      {action.progressionTags?.comment && (
        <div style={{ flex: 1 }}>
          <Text type="caption">{tr.journal.progressionTags.comment}</Text>
          <Text type="body-1">{action.progressionTags.comment}</Text>
        </div>
      )}
      {!!action.progressionTags?.images?.length && (
        <div style={{ flex: 1 }}>
          <Text type="caption">{tr.common.photo}</Text>
          <Text type="body-1">
            {action.progressionTags.images.map((image) => (
              <ProgressionImageLink onClick={handleUploadFile(image.id)} key={image.id}>
                {image.name}
              </ProgressionImageLink>
            ))}
          </Text>
        </div>
      )}
    </div>
  )
}

export { RenderPositionProgression }
