import { LineMode, LineModel } from 'shared/models'
import { PositionType } from 'shared/models'

export function getSupportedPositionTypesFromLine(line: LineModel) {
  const types: PositionType[] = []

  if (!line) {
    return types
  }

  line.modes?.forEach((mode) => {
    if (mode === LineMode.ScreenCall) {
      types.push(PositionType.ScreenCall)
    }
  })

  if (line.services?.some((x) => x.isPrivate)) {
    types.push(PositionType.Break)
  }

  return types
}
