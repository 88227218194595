import { CSSProperties, createElement } from 'react'
import Card from 'shared/ui-kit/card'
import css from './tile.module.scss'
import { injectTestAttr } from 'shared/utils/test-attr'
import { MaybeTileLink } from './maybe-tile-link'

type Props = {
  id?: string
  title?: string
  iconClass?: any
  style?: CSSProperties
  count?: number | string
  onClick?: () => void
  linkTo?: string
}

function renderBadge(count?: number | string) {
  if (!count || count === '0') {
    return null
  }

  return <div className={css.count}>{count}</div>
}

function Tile(props: Props) {
  return (
    <MaybeTileLink
      style={props.style}
      to={props.linkTo}
      className={css.root}
      onClick={() => props.onClick?.()}
      {...injectTestAttr(props)}
    >
      <Card id={props.id} style={{ margin: 0 }}>
        <div className={css.content}>
          <div style={{ display: 'flex' }}>
            <div className={css.icon}>
              {createElement(props.iconClass, {
                style: { width: 64, height: 64, transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms' }
              })}
            </div>
            <div className={css.titleAndCount}>
              <div className={css.countWrap}>{renderBadge(props.count)}</div>
              <div className={css.text}>{props.title}</div>
            </div>
          </div>
        </div>
      </Card>
    </MaybeTileLink>
  )
}

export default Tile
