import Card from 'shared/ui-kit/card'
import { Button } from '@mui/material'
import { ChipListSelect } from 'shared/ui-kit/selects'
import { useTranslation } from 'shared/i18n/translate'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { checkpoint as checkpointApi } from 'shared/api'
import { MessageTypes } from 'store/reducers/main-layout-reducer'
import { useAppDispatch } from 'store'
import { showMessage } from 'store/actions/main-layout-action-creators'
import { ServiceModel } from 'shared/models'
import { CheckpintPauseActionProps } from '../types'

function CheckpintPauseAction(props: CheckpintPauseActionProps) {
  const { tr } = useTranslation()
  const { checkpointId, lineId, placeId } = useParams<{ lineId: string; placeId: string; checkpointId: string }>()
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)
  const [service, setService] = useState<ServiceModel>()

  function handlePauseService() {
    setLoading(true)

    checkpointApi
      .createServicePosition({ checkpointId, lineId, shopId: placeId, service })
      .then(() => {
        dispatch(showMessage(tr.checkpointMonitoring.breakCreateSuccess, MessageTypes.Success, { duration: 1000 }))
      })
      .catch(() => {
        dispatch(showMessage(tr.checkpointMonitoring.breakCreateError, MessageTypes.Error, { duration: 1000 }))
      })
      .finally(() => {
        setLoading(false)
        props.onClose()
      })
  }

  return (
    <Card>
      <div style={{ padding: 16 }}>
        <ChipListSelect
          label={tr.checkpointMonitoring.breakReason}
          chipStyle={{ height: 'auto' }}
          size="medium"
          list={props.services.filter?.((s) => s.isPrivate) || []}
          value={service}
          compare={(valueItem, listItem) => valueItem.id === listItem.id}
          ensureValue
          labelExtractor={(item) => (
            <div style={{ textAlign: 'center', margin: '4px 0' }}>
              <div style={{ fontSize: '16px' }}>{!!item && item.name}</div>
              <div style={{ fontSize: '12px' }}>{!!item && tr.time.hmin(item.duration / 60)}</div>
            </div>
          )}
          onChange={(value) => setService(value)}
        />
        <div style={{ display: 'flex', gap: '0.5rem', marginTop: '2rem' }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={!service || loading}
            onClick={handlePauseService}
          >
            {tr.checkpointMonitoring.okCreateBreak}
          </Button>
          <Button variant="text" size="small" onClick={props.onClose} disabled={loading}>
            {tr.checkpointMonitoring.cancelCreateBreak}
          </Button>
        </div>
      </div>
    </Card>
  )
}

export { CheckpintPauseAction }
