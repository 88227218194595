import { CSSProperties, ReactNode } from 'react'
import css from './form-action-group.module.scss'

type FormActionGroupProps = {
  style?: CSSProperties
  children: ReactNode
}

function FormActionGroup(props: FormActionGroupProps) {
  return (
    <section className={css.root} style={props.style}>
      {props.children}
    </section>
  )
}

export default FormActionGroup
