import { SVGProps } from 'react'

function LineCongirmationRequest(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="24" {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 10C13.176 10 12.48 10.496 12.168 11.2H9.832C9.52 10.496 8.824 10 8 10C7.176 10 6.48 10.496 6.168 11.2H3.832C3.52 10.496 2.824 10 2 10C0.896 10 0 10.896 0 12C0 13.104 0.896 14 2 14C2.824 14 3.52 13.504 3.832 12.8H6.168C6.48 13.504 7.176 14 8 14C8.824 14 9.52 13.504 9.832 12.8H12.168C12.48 13.504 13.176 14 14 14C15.104 14 16 13.104 16 12C16 10.896 15.104 10 14 10Z"
        fill={props.color}
      />
      <path
        d="M7.65897 5.62895C7.94096 5.11684 8.48296 4.81474 8.79791 4.36158C9.13117 3.88632 8.9444 2.99842 7.99955 2.99842C7.38065 2.99842 7.07668 3.47 6.94851 3.86053L6 3.45895C6.26002 2.67421 6.96682 2 7.99589 2C8.85651 2 9.44612 2.39421 9.74642 2.88789C10.0028 3.31158 10.1529 4.10368 9.75741 4.69316C9.31794 5.34526 8.89679 5.54421 8.66974 5.96421C8.57818 6.13368 8.54156 6.24421 8.54156 6.78947H7.48319C7.47952 6.50211 7.43558 6.03421 7.65897 5.62895ZM8.73199 8.26316C8.73199 8.66842 8.4024 9 7.99955 9C7.59671 9 7.26712 8.66842 7.26712 8.26316C7.26712 7.85789 7.59671 7.52632 7.99955 7.52632C8.4024 7.52632 8.73199 7.85789 8.73199 8.26316Z"
        fill={props.color}
        stroke={props.color}
        strokeWidth="0.4"
      />
    </svg>
  )
}

export { LineCongirmationRequest }
export default LineCongirmationRequest
