import { user } from 'shared/api'

const AUTH_COOKIE_PATH = 'SID'

function login(email: string, password: string, captchaId?: string, userEnteredCaptchaCode?: string) {
  return user.login(email, password, captchaId, userEnteredCaptchaCode)
}

function loginByTemporaryCode(code: string) {
  return user.loginByTemporaryCode({ code })
}

function loginQR(code: string) {
  return user.loginQR(code)
}

function logout() {
  return user.logout()
}

function isAuthorized() {
  return getCookie(AUTH_COOKIE_PATH)
}

function generateAuthorizeCookie() {
  return setCookie(AUTH_COOKIE_PATH, Math.random())
}

function getCookie(cname: string) {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')

  for (let c of ca) {
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }

    if (c.indexOf(name) === 0) return c.substring(name.length, c.length)
  }

  return ''
}

function setCookie(cname: string, cvalue: any, exdays = 100) {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)

  const expires = 'expires=' + d.toUTCString()

  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

export { login, loginByTemporaryCode, loginQR, logout, isAuthorized, generateAuthorizeCookie }
