import axios from 'axios'
import { TagAPI } from 'shared/models'

const CancelToken = axios.CancelToken

const tag: TagAPI = {
  createTag(data, config) {
    return axios.post('createTag', data, config)
  },
  updateTag(data, config) {
    return axios.post('updateTag', data, config)
  },
  getTagItemsList(config) {
    return axios.get('getTagItemsList', config)
  },
  getTagEditForm(id, config) {
    return axios.get('getTagEditForm', { params: { id }, ...config })
  },
  removeTag(id, config) {
    return axios.delete('removeTag', { params: { id }, ...config })
  },
  tagSearch(term, setCanceler, config) {
    return axios.get('tagSearch', {
      params: { term: term },
      cancelToken: new CancelToken((canceler) => setCanceler?.(canceler)),
      ...config
    })
  }
}

export default tag
