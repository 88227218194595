import { ReactNode } from 'react'
import MenuItem from '@mui/material/MenuItem'

type Props = {
  children: ReactNode
  isFocused?: boolean
  isSelected?: boolean
  innerProps?: any
}

export default function Option(props: Props) {
  const { isFocused, innerProps, isSelected, children } = props

  return (
    <MenuItem
      selected={isFocused}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
        zIndex: 999999,
        display: 'block',
        overflow: 'hidden',
        width: '100%',
        wordBreak: 'break-word',
        whiteSpace: 'break-spaces'
      }}
      {...innerProps}
    >
      {children}
    </MenuItem>
  )
}
