import { Optional } from 'shared/types'

export enum TerminalType {
  Kiosk = 'Kiosk',
  WebKiosk = 'WebKiosk',
  InfoPad = 'InfoPad',
  AcceptationScreen = 'AcceptationScreen'
}

export enum CallMode {
  None = 'None',
  Bell = 'Bell',
  Speech = 'Speech'
}

export interface SmallShopInfo {
  id: number | string
  name: string
}

export interface SmallLineInfo {
  id: number | string
  name: string
  shopId: number | string
}

export interface TerminalSmallModel {
  enabled: boolean
  id: string | number
  name: string
  shopIds: (number | string)[]
  type?: keyof typeof TerminalType
}

export interface TerminalModel {
  id: number | string
  guid?: string
  name: string
  shortName?: string
  hasThermalPrinter: boolean
  maxTickets: number
  minTicketsLevelNotification: number
  tickets: number
  shopIds?: (number | string)[]
  lineIds?: (number | string)[]
  shopId?: number | string
  lineId?: number | string
  type: TerminalType
  displayName?: string
  enabled: boolean
  reservationsStartHour_unixtime?: number
  lastAlertSent?: number
  shops?: SmallShopInfo[]
  lines?: SmallLineInfo[]
  combineAllPositions?: boolean
  combineGroupName?: string
  tvLink?: string
  tvPocketLink: string
  tvWithDirectionsLink?: string
  webTerminalLink?: string
  acceptationScreenLink?: string
  repeatAlarm: number
  repeatAlarmSec: number
  waitingPositionsCount: number
  waitingTimeFilter: number
  displayFullName: boolean
  displayReservationCodeOnLastName: boolean
  socket: boolean
  zoomFactor: number
  callMode?: CallMode
  canDeclarePresent: boolean
  displayLineStateAndCallInfo: boolean
  themeName: string
  languages: string
  layoutServices: string
  displayWelcomeScreen: boolean
  forcedRefreshTime_unixtime?: number
  displayAds?: boolean
  displayPositions?: boolean
  minimizePinCode?: string
  showGeneralUseConditions?: boolean
  isLastNameRequired?: boolean
  onlyAsap?: boolean
  isPhoneNumberRequired?: boolean
  qrCodeLink?: string
  logoFile?: { id: string; name: string }
}

export type TerminalModelEdit = Optional<TerminalModel, 'id'>
