import { TitleStyled } from './styled'
import { TitleProps } from './types'

function Title(props: TitleProps) {
  const { children, collapsed, style } = props

  if (collapsed) {
    return null
  }

  return <TitleStyled style={style}>{children}</TitleStyled>
}

export { Title }
