import { AdditionalValues, ServiceModel } from '.'

export enum AppointmentState {
  Deleted = 'deleted',
  JoinTheLine = 'joinTheLine'
}

export enum AppointmentStatus {
  Idle = 'idle',
  Confirmed = 'confirmed',
  ToBeCancelled = 'toBeCancelled',
  InProgress = 'inProgress',
  TransformedToPosition = 'transformedToPosition',
  Completed = 'completed',
  NoShow = 'noShow',
  Cancelled = 'cancelled'
}

export interface AppointmentForm {
  id: string | number
  lineId: string | number
  placeId: string | number
  participantsNumber?: number
  comments?: string
  progressionTags?: string
  staffComments?: string
  date: number
  time: { id: string; startTime: number; endTime: number }
  serviceIds: (string | number)[]
  canPrint?: boolean
  canUpdate: boolean
  additionals?: AdditionalValues
  code?: string
  customer: {
    id: number
    email: string
    firstName: string
    lastName: string
    phoneNumber: string
    language: string
    companyName?: string
  }
  status: AppointmentStatus
  serviceDuration?: number
  customCheckpointId?: string | number
}

export interface AppointmentModel {
  id: string
  positionId: number
  appointmentState?: AppointmentState | null
  lineId: string | number
  lineName?: string
  placeId: string | number
  placeName?: string
  participantsNumber?: number
  comments?: string
  progressionTags?: string
  staffComments?: string
  enableProgressionTags?: boolean
  lineProgressionTags?: string
  date: number
  time: {
    id: string
    startTime: number
    endTime: number
  }
  services: ServiceModel[]
  canPrint?: boolean
  canUpdate: boolean
  additionals?: AdditionalValues
  additionalLabels?: { label: string; shortName: string }[]
  code?: string
  customer: {
    id: number
    email: string
    firstName: string
    lastName: string
    phoneNumber: string
    language: string
    companyName?: string
  }
  status: AppointmentStatus
  showButtonCreatePosition?: boolean
  lineParticipantsCaptionTemplate?: string
  lineParticipantTermInPluralTemplate?: string
  lineParticipantTermInSingularTemplate?: string
  serviceDuration?: number
  customCheckpointId?: string | number
  progressionTagsComment?: string
  allowMultipleProgressionTags?: boolean
  allowProgressionPhoto?: boolean
  progressionImages?: { id: string; name: string }[]
}

export type AppointmentFromService = {
  id: any
  name: string
  lineName: string
  requestParticipantsNumber: boolean
  maxParticipantsNumber: number
  placeIds: any[]
  lineIds: any[]
}

export interface ServiceLineGroup {
  lineName: string
  services: AppointmentFromService[]
}

export interface PosiblePlaceLine {
  shop: { id: any; name: string }
  line: { id: any; name: string }
  services: { id: any; name: string }[]
  nearestDate: { id: any; startTime: number; endTime: number }
  participantsNumber: number
  bookingUnavailable: boolean
  bookingUnavailableReason?: string
}
