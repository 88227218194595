import styled from '@emotion/styled'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'

const BarContainer = styled.div<{ $isLargeSize?: boolean }>`
  display: flex;
  width: 100%;
  height: ${(props) => (!props.$isLargeSize ? 64 : 72)}px;
  align-items: center;
`

const InactiveIndicator = styled(PriorityHighIcon)`
  color: #f44336;
  width: 18px !important;
  height: 18px !important;
  position: absolute;
  top: -2px;
  right: -2px;
`

const StatsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  align-items: center;
`
const StatsItem = styled.div`
  display: flex;
  gap: 0.5rem;
`

const StatsIcon = styled.div<{ $background: string }>`
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: ${(props) => props.$background};
`

const StatsContainerMobile = styled(StatsContainer)`
  padding: 1rem;
  flex: 1;
`

const LineStatMobile = styled.div`
  flex: 1;
  display: flex;
  gap: 0.5rem;
  align-items: center;
`

const WaitingLine = styled.div`
  color: rgba(0, 0, 0, 0.54);
  width: max-content;
`

const PersonsText = styled.div`
  color: rgba(0, 0, 0, 0.54);
`

const QuantityContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-left: 4px;
`

const NotActiveContainer = styled.div`
  font-size: 1.7rem;
  color: #00000052;
  text-align: center;
  margin-top: 2rem;
`

const NoPositionFound = styled.div`
  margin: 24px 24px 16px 24px;
`

const FiltersWrapper = styled.div`
  margin: 4px 12px;
  display: flex;
  flex-direction: column;
  min-width: 170px;
  max-width: 200px;
`

const MonitoringRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
`

const MonitoringBarContainer = styled.div<{ $smallScreen?: boolean }>`
  min-width: 640px;

  ${(props) => props.$smallScreen && { minWidth: 0, flex: 1 }}
`

const CenterProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`

const MonitoringBar = styled.div<{ $smallScreen?: boolean; $top?: number }>`
  position: sticky;
  width: 100%;
  z-index: 1100;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);
  display: flex;
  min-height: 64px;
  align-items: center;
  justify-content: center;
  background: #fff;
  top: ${({ $top }) => `${$top || 64}px`};
  left: 0;
  white-space: nowrap;
  transition: all 0.14s ease-in-out;

  ${(props) => props.$smallScreen && { padding: '0 0.5rem' }}
`

const MonitoringPositions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const InactiveWrapper = styled.div`
  margin-top: 1rem;
`

const ClearButtonWrapper = styled.div`
  margin: 0 0.5rem;
  display: flex;
  justify-content: flex-end;
`

const StatsWrapper = styled.div`
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
`

const RightButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
`

const JournalTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`

const PositionsJounal = styled.div`
  margin-top: 2rem;
`

const FilterByParticipantsChipContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  min-width: 21px;
  justify-content: center;
`

const FilterByParticipantsContent = styled.div<{ $filterIsSelected: boolean }>`
  display: flex;
  gap: 1rem;
  transition: all 0.16s ease-in-out;
  margin-left: ${({ $filterIsSelected }) => ($filterIsSelected ? '3rem' : '2rem')};
`

const FilterByParticipantsClearIconWrapper = styled.div`
  width: 8px;
  height: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const FilterByParticipantsPersonIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 18px;
  height: 32px;
`

const FilterByParticipantsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-left: 0.5rem;
  transition: all 0.3s ease-in-out;
`

const LineStatWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const ConfirmationWrapper = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
`

const ConfirmationTitle = styled.div`
  flex: 1;
  min-width: 200px;
`

const ConfirmationActions = styled.div`
  display: flex;
  gap: 0.5rem;
`

export {
  BarContainer,
  InactiveIndicator,
  StatsContainer,
  StatsIcon,
  StatsItem,
  StatsContainerMobile,
  NotActiveContainer,
  QuantityContainer,
  PersonsText,
  WaitingLine,
  LineStatMobile,
  NoPositionFound,
  FiltersWrapper,
  MonitoringRoot,
  MonitoringBarContainer,
  MonitoringBar,
  MonitoringPositions,
  InactiveWrapper,
  ClearButtonWrapper,
  StatsWrapper,
  CenterProgressWrapper,
  RightButtonWrapper,
  JournalTitleWrapper,
  PositionsJounal,
  FilterByParticipantsChipContent,
  FilterByParticipantsContent,
  FilterByParticipantsClearIconWrapper,
  FilterByParticipantsPersonIconWrapper,
  FilterByParticipantsWrapper,
  LineStatWrapper,
  ConfirmationWrapper,
  ConfirmationTitle,
  ConfirmationActions
}
