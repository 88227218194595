import { Link } from '@mui/material'
import UILink from 'shared/ui-kit/link'
import styled from '@emotion/styled'

const LogoImage = styled.div<{ $url: string }>`
  min-width: 56px;
  width: 56px;
  height: 56px;
  border-radius: 1rem;
  background-image: url(${(props) => props.$url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 0px 4px,
    rgba(0, 0, 0, 0.16) 0px 0px 4px;
  margin-right: 0.5rem;
  background-color: #f1f1f1;
`

const LargeLayoutRoot = styled.div`
  padding: 2rem 1rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
`

const MobileLayoutBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  box-sizing: border-box;
  gap: 1rem;
`

const MobileBarContent = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
`

const MobileBarActions = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const MobileBarLabel = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
`

const LargeLayoutSideBar = styled.div`
  position: sticky;
  top: 6rem;
  margin-right: 1rem;
  min-width: 250px;
  max-width: 320px;
  height: min-content;
`

const LineName = styled.div`
  overflow: hidden;
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
`

const BackLink = styled(UILink)<{ size?: 'small' | 'standart' }>`
  font-size: ${(props) => (props.size === 'standart' ? '14px' : '12px')};
  color: #db214d !important;
  display: flex;
  gap: 0.25rem;
  align-items: center;
`

const LineImage = styled(LogoImage)`
  width: 42px;
  height: 42px;
  min-width: 42px;
`

const LineNameWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
  overflow: hidden;
`

const LineInfoWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 1rem;
`

const LeftMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const GroupsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1rem;
`

const Group = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  cursor: pointer;
`

const GroupLabel = styled.div<{ $isActive?: boolean }>`
  font-size: 14px;
  color: ${(props) => (props.$isActive ? '#db214d' : 'unset')};
  flex: 1;
`

const SmallIcon = styled.div`
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
`

const ActionsWrapper = styled.div`
  margin: 1rem 0 1rem;
`

const LoadingContainer = styled.div`
  background: #fafafa;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
`

const MoreInfoBtn = styled(Link)`
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
`

export {
  LogoImage,
  LargeLayoutRoot,
  LargeLayoutSideBar,
  LineName,
  BackLink,
  LineImage,
  LineNameWrapper,
  LineInfoWrapper,
  LeftMenuWrapper,
  GroupsWrapper,
  SmallIcon,
  GroupLabel,
  Group,
  ActionsWrapper,
  MobileLayoutBar,
  MobileBarContent,
  MobileBarLabel,
  MobileBarActions,
  LoadingContainer,
  MoreInfoBtn
}
