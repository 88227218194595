import styled from '@emotion/styled'

const EmptyMessage = styled.div`
  padding: 1rem;
  text-align: center;
  color: #00000026;
  font-size: 21px;
`

export { EmptyMessage }
