import { useState, useEffect, ReactNode } from 'react'
import css from './form.module.scss'
import { withMods } from 'shared/utils/cssm'
import Text from '../text'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import AttentionPoint from 'shared/ui-kit/attention-point'
import { WithHelper } from 'features/element-with-helper'
import { injectTestAttr } from 'shared/utils/test-attr'

const mod = withMods(css)

type FormProps = {
  attention?: boolean
  children: ReactNode
  title?: string
  expandable?: boolean
  expanded?: boolean
  headerContent?: () => ReactNode
  headerClassName?: string
  onToggle?: (value: boolean) => void
  headerProps?: any
  tag?: string
  tooltipKey?: string
}

function Form(props: FormProps) {
  const {
    attention,
    children,
    title,
    expandable = false,
    expanded = false,
    headerContent,
    headerClassName,
    onToggle,
    headerProps
  } = props

  const [displayContent, setDisplayContent] = useState(expanded || !expandable)

  const hasHeader = Boolean(headerContent || title)

  useEffect(() => {
    setDisplayContent(expanded || !expandable)
  }, [expanded])

  function handleToggle() {
    const nVal = !displayContent
    setDisplayContent(nVal)
    onToggle?.(nVal)
  }

  function tryRenderHeader() {
    return expandable ? renderWithMark() : renderStaticHeader()
  }

  function renderWithMark() {
    const icon = displayContent ? <ExpandLess /> : <ExpandMore />
    return (
      <div className={mod.header().add(headerClassName)} onClick={handleToggle} {...headerProps}>
        <div className={css.title}>{renderHeaderContent()}</div>
        <div>{icon}</div>
      </div>
    )
  }

  function renderStaticHeader() {
    return (
      <div className={css.staticHeader} {...headerProps}>
        <div className={css.title}>{renderHeaderContent()}</div>
      </div>
    )
  }

  function renderHeaderContent() {
    if (headerContent) {
      return headerContent()
    }

    if (props.tag && props.tooltipKey) {
      return (
        <WithHelper tooltipKey={props.tooltipKey} tag={props.tag}>
          <Text type="body-2">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {title}
              {attention && <AttentionPoint />}
            </div>
          </Text>
        </WithHelper>
      )
    }

    return (
      <Text type="body-2">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {title}
          {attention && <AttentionPoint />}
        </div>
      </Text>
    )
  }

  return (
    <section className={mod.root({ noHeader: !hasHeader })} {...injectTestAttr(props)}>
      {hasHeader && tryRenderHeader()}
      {displayContent && <div className={mod.content({ expanded: displayContent })}>{children}</div>}
    </section>
  )
}

export default Form
export { Form }
