import { useEffect, useState, FC } from 'react'
import { user } from 'shared/api'
import { storeUserProfile } from 'store/actions/user-action-creators'
import CircularProgress from '@mui/material/CircularProgress'
import { useLocation } from 'react-router-dom'
import { storeUrl } from 'shared/utils/history'
import { isAuthorized } from 'shared/auth'
import { CenterContainer } from 'shared/ui-kit/center-container'
import { useAppDispatch } from 'store'

function StoreUser<T extends object>(WrappedComponent: FC<T>) {
  return function StoreUserComponent(props: T) {
    const [loading, setLoading] = useState(true)
    const dispatch = useAppDispatch()
    const { search, pathname } = useLocation()

    useEffect(() => {
      const tkn = new URLSearchParams(search).get('tkn') || undefined

      storeUrl(pathname)

      if (!isAuthorized() && !tkn) {
        setLoading(false)
      } else {
        user
          .storeProfile(tkn)
          .then((profile) => {
            dispatch(storeUserProfile(profile))
          })
          .finally(() => {
            setLoading(false)
          })
      }
    }, [])

    if (loading) {
      return (
        <CenterContainer>
          <CircularProgress />
        </CenterContainer>
      )
    }

    return <WrappedComponent {...props} />
  }
}

export default StoreUser
