import Dialog from '@mui/material/Dialog'
import { createRef, useEffect, useState } from 'react'
import { user as userAPI, line } from 'shared/api'
import Loader from 'shared/ui-kit/page-loader'
import { FormGroup, Form, FormActionGroup } from 'shared/ui-kit/form'
import { LineModelSimplified } from 'shared/models'
import { Button, Divider } from '@mui/material'
import QRCode from 'qrcode.react'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print'
import { useTranslation } from 'shared/i18n/translate'
import Autocomplete from 'shared/ui-kit/autocomplete'
import { QrGenerageDialogProps } from '../types'

const pageStyle = `
@page {
  margin: 20mm 10mm;
}
`

export function QrGenerageDialog({ open, userId, onClose, shops, firstName, secondName }: QrGenerageDialogProps) {
  const { tr } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [lineLoading, setLineLoading] = useState(false)
  const [token, setToken] = useState('')
  const [shopId, setShopId] = useState<string | number | null>(null)
  const [lineId, setLineId] = useState<string | number | null>(null)
  const [lines, setLines] = useState<LineModelSimplified[]>([])

  const printRef = createRef<HTMLDivElement>()

  useEffect(() => {
    setToken('')

    if (open && userId) {
      getUserQrToken()
    }
  }, [userId, open])

  useEffect(() => {
    if (shopId) {
      getLineList(String(shopId))
    }
  }, [shopId])

  async function getUserQrToken() {
    setLoading(true)

    try {
      const data = await userAPI.getUserQRToken(userId)
      setToken(data.token)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      onClose()
    }
  }

  async function getLineList(shopId: string) {
    setLineLoading(true)
    setLineId('')

    try {
      const { data } = await line.getLineListSimplified(shopId)
      setLines(data || [])
    } catch (err) {
      console.log(err)
    } finally {
      setLineLoading(false)
    }
  }

  function handleChangeShopId(shopId: string | number | null) {
    setShopId(shopId)
  }

  function handleChangeLineId(lineId: string | number | null) {
    setLineId(lineId)
  }

  const shopParam = shopId ? `&shopId=${shopId}` : null
  const lineParam = (!!lineId && lineId) !== 'all' ? `&lineId=${lineId}` : null

  const qrLink = [`${location.origin}#/login?code=${token}`, shopParam, lineParam].filter(Boolean).join('')

  return (
    <Dialog open={open} onClose={onClose} fullWidth PaperProps={{ style: { overflow: 'visible' } }}>
      {loading && <Loader style={{ margin: '2rem 0' }} />}
      {!loading && (
        <Form>
          <FormGroup>
            <Autocomplete
              title={tr.userEdit.place}
              fullWidth
              list={shops}
              value={shopId}
              autoFocus
              dataConverter={(item) => ({ value: item.id as string, text: item.name })}
              onChange={handleChangeShopId}
            />
          </FormGroup>
          <FormGroup>
            <Autocomplete
              title={tr.userEdit.line}
              fullWidth
              list={[{ id: 'all', name: tr.userEdit.allLines }, ...lines]}
              value={lineId}
              dataConverter={(item) => ({ value: item.id, text: item.name })}
              disabled={lineLoading}
              onChange={handleChangeLineId}
            />
          </FormGroup>
          {Boolean(shopId && lineId && token) && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: '1rem',
                flexDirection: 'column',
                gap: '0.5rem',
                boxSizing: 'border-box'
              }}
              ref={printRef}
            >
              <div>
                {firstName} {secondName}
              </div>
              <QRCode value={qrLink} size={256} />
              <div>
                {[shops.find((el) => el.id === shopId)?.name, lines.find((el) => el.id === lineId)?.name]
                  .filter(Boolean)
                  .join(' - ')}
              </div>
            </div>
          )}
          <Divider />
          <FormActionGroup>
            <ReactToPrint content={() => printRef.current} pageStyle={pageStyle}>
              <PrintContextConsumer>
                {({ handlePrint }) => (
                  <Button onClick={handlePrint} disabled={!(shopId && lineId && token)}>
                    {tr.userEdit.print}
                  </Button>
                )}
              </PrintContextConsumer>
            </ReactToPrint>
          </FormActionGroup>
        </Form>
      )}
    </Dialog>
  )
}
