import axios from 'axios'
import { JournalAPI } from 'shared/models/types/journal-api-type'

const CancelToken = axios.CancelToken

let cancel = () => {
  console.debug('Cancel journal')
}

const journal: JournalAPI = {
  getJournal(data, config) {
    cancel()

    return axios.get('reports/getJournal', {
      params: data,
      cancelToken: new CancelToken((canceler) => (cancel = canceler)),
      ...config
    })
  },

  getEventsFlow(data, config) {
    const paramsToTuple = Object.entries(data).reduce((acc: any, curr: [any, any]) => {
      const key = curr[0]
      const value = curr[1]

      if (value && typeof value !== 'object') {
        return [...acc, [key, value]]
      } else if (value && typeof value === 'object') {
        return [...acc, ...value.map((el) => [curr[0], el])]
      } else {
        return acc
      }
    }, [])

    const params = new URLSearchParams(paramsToTuple)

    return axios.get('reports/getEventsFlow', { params, ...config })
  }
}

export default journal
