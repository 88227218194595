import { useState } from 'react'
import Button from '@mui/material/Button'
import { FormGroup } from '../../shared/ui-kit/form'
import { TextField } from '../../shared/ui-kit/text-field'
import Text from 'shared/ui-kit/text'
import Link from '../../shared/ui-kit/link'
import { useTranslation } from 'shared/i18n/translate'
import { PositionCustomer } from 'shared/models'
import { CustomerWithEditLinkProps } from './types'

export function getCustomerName(customer: PositionCustomer): string {
  return [customer?.firstName, customer?.lastName].filter(Boolean).join(' ')
}

function CustomerWithEditLink(props: CustomerWithEditLinkProps) {
  const { tr } = useTranslation()

  const [wantToEdit, setWantToEdit] = useState(false)
  const [reason, setReason] = useState('')

  return (
    <div style={{ marginBottom: '1.5rem' }}>
      <FormGroup style={{ display: 'flex', alignItems: 'center', height: 26 }}>
        <Text type="body-1" style={{ fontSize: props.isSingleContent ? 19 : 16 }}>
          {getCustomerName(props.data)}
        </Text>
        {!wantToEdit && !!props.canEditPersonalInfo && (
          <>
            <div style={{ padding: '0 8px' }}>·</div>
            <Link onClick={() => setWantToEdit(true)} style={{ fontSize: 12 }}>
              {tr.lineMonitoring.updatePersonalInformationLink}
            </Link>
          </>
        )}
      </FormGroup>
      {wantToEdit && (
        <>
          <FormGroup>
            <Text type="body-1" style={{ color: '#ff9800', fontWeight: 300 }}>
              {tr.lineMonitoring.updatePersonalInformationWarning}
            </Text>
          </FormGroup>
          <FormGroup>
            <TextField
              autoFocus
              label={tr.lineMonitoring.updateReason}
              placeholder={tr.lineMonitoring.updateReasonPlaceholder}
              value={reason}
              onChange={setReason}
              attention={reason.length < 3}
            />
          </FormGroup>
          <FormGroup style={{ marginBottom: 0 }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={reason.length < 3}
              onClick={() => props.onEditRequest(reason)}
            >
              {tr.lineMonitoring.updatePersonalInformation}
            </Button>
            <Button size="small" style={{ marginLeft: 8 }} onClick={() => setWantToEdit(false)}>
              {tr.lineMonitoring.updatePersonalInformationCancel}
            </Button>
          </FormGroup>
        </>
      )}
    </div>
  )
}

export default CustomerWithEditLink
