import { Form, FormGroup } from 'shared/ui-kit/form'
import Toggle from 'shared/ui-kit/toggle-wrap'
import { useTranslation } from 'shared/i18n/translate'
import { Controller, ControllerRenderProps } from 'react-hook-form'
import { GroupProps } from '../types'
import { LineModel, ServiceMode, ServiceModel } from 'shared/models'
import { WithHelper } from 'features/element-with-helper'
import Dropdown from 'shared/ui-kit/dropdown'
import { ServiceListDnd } from 'features/service-list-dnd'
import { Card } from '@mui/material'

const newSerivce = { id: -Date.now(), name: '', duration: 0, shortName: '', description: '', priority: 0 }

function ServicesGroup({ control, trigger, watch, brandId, shopId, setValue }: GroupProps) {
  const { tr } = useTranslation()

  function handleServiceChanged(f: ControllerRenderProps<LineModel, 'services'>) {
    return (services: ServiceModel[]) => {
      f.onChange(services)

      const additionals = (watch('additionals') || []).map((additional) => {
        if (!additional.availableForServices || !additional.availableForServices?.length) {
          return additional
        }

        const availableForServices = additional.availableForServices.filter(
          (serviceId) => !!services.find((service) => String(service.id) === String(serviceId))
        )

        return { ...additional, availableForServices }
      })

      setValue('additionals', additionals)
    }
  }

  const { servicesDisplayMode, servicesModes, multipleServicesSelection } = tr.lineEdit

  return (
    <Card>
      <Form>
        <FormGroup>
          <WithHelper tooltipKey="lineServicesMode" tag="line">
            <Controller
              name="lineServicesMode"
              control={control}
              render={({ field: f }) => (
                <Dropdown
                  fullWidth
                  ensureValue
                  label={servicesDisplayMode}
                  value={f.value || ServiceMode.None}
                  data={[ServiceMode.None, ServiceMode.SimpleList]}
                  dataConverter={(item) => ({ value: item, text: servicesModes[item] })}
                  onChange={(val) => {
                    f.onChange(val)
                    trigger()
                  }}
                />
              )}
            />
          </WithHelper>
        </FormGroup>
        {watch('lineServicesMode') === ServiceMode.SimpleList && (
          <FormGroup>
            <Controller
              name="multipleServicesSelection"
              control={control}
              render={({ field: f }) => (
                <Toggle
                  toggled={f.value}
                  label={multipleServicesSelection}
                  onToggle={(_, val) => f.onChange(val)}
                  withHelper={true}
                  tag="line"
                  tooltipKey="multipleServicesSelection"
                />
              )}
            />
          </FormGroup>
        )}
        {watch('lineServicesMode') === ServiceMode.SimpleList && (
          <Controller
            name="services"
            control={control}
            render={({ field: f }) => (
              <ServiceListDnd
                services={f.value || []}
                freeTimeslotsProgressively={watch('freeTimeslotsProgressively')}
                brandId={brandId}
                onServicesChanged={handleServiceChanged(f)}
                onAddService={() => f.onChange([...(f.value || []), { ...newSerivce, id: -Date.now() }])}
                requestParticipantsNumber={watch('requestParticipantsNumber')}
                maxSimultaneous={watch('maxSimultaneous')}
                shopId={shopId}
              />
            )}
          />
        )}
      </Form>
    </Card>
  )
}

export { ServicesGroup }
