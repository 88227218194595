import { AccordionDetails, AccordionActions, Typography, Button } from '@mui/material'
import Toggle from 'shared/ui-kit/toggle-wrap'
import { DateCalendar } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { PickersDay } from '@mui/x-date-pickers'
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useTranslation } from 'shared/i18n/translate'
import { DuplicateForm } from './types'
import { CallendarWrapper } from './styled'
import { offsetToStamp } from 'shared/i18n/date-formatter'

function StaffManagementDuplicateForm({
  onSubmit,
  disabled,
  sourceDate
}: {
  disabled?: boolean
  onSubmit: (data: DuplicateForm) => void
  sourceDate: number
}) {
  const { tr } = useTranslation()

  const { control, handleSubmit } = useForm<DuplicateForm>({
    resolver: yupResolver(
      Yup.object().shape({
        selectedDaysForCopy: Yup.array().min(1, tr.staffManagementEdit.minItemsInArrayError)
      }) as any
    ),
    defaultValues: {
      deletePreviousItemsAfterCopying: true,
      targetDates: [],
      sourceDate
    }
  })

  function handleDaysChange(f: ControllerRenderProps<DuplicateForm, 'targetDates'>) {
    return (date: dayjs.Dayjs | null) => {
      if (!date) {
        return
      }

      const newDate = offsetToStamp(date.toDate().setHours(0, 0, 0, 0), 0)
      const isSelected = f.value.find((x) => x === newDate)

      f.onChange(isSelected ? f.value.filter((x) => x !== newDate) : [...f.value, newDate])
    }
  }

  return (
    <>
      <AccordionDetails>
        <Typography variant="h6">{tr.staffManagementEdit.duplicateTitle}</Typography>
        <Controller
          name="targetDates"
          control={control}
          render={({ field: f, fieldState }) => (
            <>
              <Typography>{tr.staffManagementEdit.duplicateCalendarTitle}</Typography>
              <CallendarWrapper>
                <DateCalendar
                  disablePast
                  onChange={handleDaysChange(f)}
                  disabled={disabled}
                  slots={{
                    day: (props) => (
                      <PickersDay
                        {...props}
                        selected={f.value.includes(offsetToStamp(props.day.toDate().setHours(0, 0, 0, 0), 0))}
                      />
                    )
                  }}
                />
              </CallendarWrapper>
              {!!fieldState.error && (
                <Typography variant="caption" color="#F00">
                  {fieldState.error?.message}
                </Typography>
              )}
            </>
          )}
        />
        <Controller
          name="deletePreviousItemsAfterCopying"
          control={control}
          render={({ field: f }) => (
            <Toggle
              toggled={f.value || false}
              label={tr.staffManagementEdit.deletePreviousItemsAfterCopy}
              onToggle={() => f.onChange(!f.value)}
              disabled={disabled}
            />
          )}
        />
      </AccordionDetails>
      <AccordionActions>
        <Button
          variant="contained"
          size="small"
          disableElevation
          color="primary"
          disabled={disabled}
          onClick={handleSubmit(onSubmit)}
        >
          {tr.staffManagementEdit.duplicateButton}
        </Button>
      </AccordionActions>
    </>
  )
}

export { StaffManagementDuplicateForm }
