const addZero = (n: number | string) => (n < 10 ? '0' : '') + n

export function msToTime(s: number) {
  s = Math.abs(s)

  const time = s
  const ms = s % 1000
  s = (s - ms) / 1000
  const secs = s % 60
  s = (s - secs) / 60
  const mins = s % 60
  const hrs = (s - mins) / 60

  return {
    h: hrs,
    m: mins,
    hh: addZero(hrs),
    mm: addZero(mins),
    ss: addZero(secs),
    seconds: Math.floor(time / 1000),
    minutes: Math.floor(time / 60000)
  }
}

export function compareDates(value1: any, value2: any) {
  const date1 = removeTime(value1)
  const date2 = removeTime(value2)

  if (date1 < date2) {
    return -1
  }

  if (date1 > date2) {
    return 1
  }

  return 0
}

export function removeTime(value: number | string) {
  return new Date(value).setHours(0, 0, 0, 0)
}

export function compareTimes(value1: any, value2: any) {
  if (+value1.hours < +value2.hours) {
    return -1
  }

  if (+value1.hours > +value2.hours) {
    return 1
  }

  if (+value1.minutes < +value2.minutes) {
    return -1
  }

  if (+value1.minutes > +value2.minutes) {
    return 1
  }

  return 0
}

export function getTimeZoneShortName(offset: number) {
  const time = msToTime(offset)

  return `UTC${offset < 0 ? '-' : '+'}${time.hh}:${time.mm}`
}

const timeZoneOffset = -(new Date().getTimezoneOffset() * 1000 * 60)
const timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone

let localTimeZone = {
  id: timeZoneId,
  offset: timeZoneOffset,
  name: timeZoneId ? `(${getTimeZoneShortName(timeZoneOffset)}) ${timeZoneId}` : getTimeZoneShortName(timeZoneOffset)
}

export function getLocalTimeZone() {
  return localTimeZone
}

export function setLocalTimeZone(timeZone: any) {
  localTimeZone = timeZone
}

let ensuredLocalTimeZoneId: any = null

export function ensureLocalTimeZoneId(timeZones: any) {
  if (ensuredLocalTimeZoneId) {
    return ensuredLocalTimeZoneId
  }

  const timeZoneById = timeZones.filter(Boolean).find((z: any) => z?.id === localTimeZone.id)

  if (timeZoneById) {
    return (ensuredLocalTimeZoneId = timeZoneById.id)
  }

  const timeZoneByOffset = timeZones.filter(Boolean).find((z: any) => z?.offset === localTimeZone.offset)

  if (timeZoneByOffset) {
    return (ensuredLocalTimeZoneId = timeZoneByOffset.id)
  }

  return (ensuredLocalTimeZoneId = timeZones.filter(Boolean).find((z: any) => z?.offset === 0).id)
}
