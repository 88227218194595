import styled from '@emotion/styled'

const Bar = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1200;
`

const Content = styled.div<{ $hideTopBar: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: ${(props) => (props.$hideTopBar ? '100vh' : 'calc(100vh - 64px)')};
  box-sizing: border-box;
  background: #fafafa;
  width: 100%;
`

const WarningNotification = styled.div`
  background-color: #ffd495;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 4rem;
  z-index: 1200;
`

const WarningCenter = styled.div`
  max-width: 640px;
  width: 100%;
  padding: 0.5rem;
`

const VersionInfo = styled.div<{ $isNew?: boolean }>`
  font-size: 9px;
  border-radius: 4px;
  font-weight: 200;
  opacity: 0.64;
  margin-top: 0.25rem;

  @media (max-width: 768px) {
    font-size: 8px;
    opacity: 0.48;
  }

  ${(props) =>
    props.$isNew && {
      color: props.theme.palette.primary.main,
      backgroundColor: '#ffffff',
      padding: '4px 6px',
      fontSize: '10px',
      cursor: 'pointer',
      fontWeight: 900,
      opacity: 1
    }}
`

const SatisfactionSurveyInfo = styled.div`
  margin: 0 2rem;
  cursor: pointer;

  @media (max-width: 992px) {
    margin: 0 1rem 0 0.5rem;
  }
`

const SatisfactionDialogTitle = styled.div`
  width: 300px;
`

const SatisfactionDialogActions = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
`

export {
  Bar,
  Content,
  WarningNotification,
  WarningCenter,
  VersionInfo,
  SatisfactionSurveyInfo,
  SatisfactionDialogTitle,
  SatisfactionDialogActions
}
