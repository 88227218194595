import { ReactNode, CSSProperties } from 'react'
import css from './tile-container.module.scss'
import { withMods } from 'shared/utils/cssm'

const mod: any = withMods(css)

type Props = {
  children?: ReactNode
  inline?: boolean
  style?: CSSProperties
}

function TileContainer({ style, children }: Props) {
  return (
    <div className={mod.root()} style={style}>
      {children}
    </div>
  )
}

export default TileContainer
