import { CheckListItem, ServiceModel } from 'shared/models'
import { TextField } from 'shared/ui-kit/text-field'
import { InputTranslation } from 'features/input-translation'
import { IconButton } from '@mui/material'
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Delete as RemoveIcon
} from '@mui/icons-material'
import { isPositiveNumber } from 'shared/utils/string-test'
import { DndProvider, DragSourceMonitor, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useRef, useState } from 'react'
import type { Identifier, XYCoord } from 'dnd-core'
import { useTranslation } from 'shared/i18n/translate'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import Toggle from 'shared/ui-kit/toggle-wrap'
import { Button } from '@mui/material'
import {
  Accordion,
  AccordionTitle,
  CustomDurationContainer,
  CustomDurationTitle,
  ItemContainer,
  MoreSettingsContainer,
  ServiceActions,
  ServiceTitle
} from './styled'
import { Checklist } from './checklist'
import { WithHelper } from 'features/element-with-helper'
import TextFieldMUI from '@mui/material/TextField'

type Props = {
  index: number
  id: string | number
  service: ServiceModel
  brandId?: string
  moveCard: (dragIndex: number, hoverIndex: number) => void
  handleServiceChange: (service: ServiceModel) => void
  handleRemoveService: () => void
  freeTimeslotsProgressively?: boolean
  requestParticipantsNumber?: boolean
  maxSimultaneous?: number
  shopId?: string
}

interface DragItem {
  index: number
  id: string
  type: string
}

export function ServiceDndItem({
  index,
  service,
  id,
  handleServiceChange,
  handleRemoveService,
  moveCard,
  brandId,
  maxSimultaneous,
  requestParticipantsNumber,
  shopId
}: Props) {
  const { tr } = useTranslation()
  const [moreSettingsDialog, setMoreSettingsDialog] = useState(false)

  const {
    serviceName,
    serviceDuration,
    secondsHint,
    shortName,
    description,
    privateService,
    progressivelyBookingService,
    supportCustomParticipantsNumber,
    customParticipantsNumber,
    supportCustomParticipantsQuestion,
    customParticipantsQuestion,
    supportCustomDurationPerParticipantNumber,
    supportCheckList,
    durationsPerParticipantTitle,
    participants,
    checklistItemsTitle,
    priority
  } = tr.lineEdit

  const { chars, deleteButton } = tr.entityEdit

  const dragRef = useRef<HTMLDivElement>(null)

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'services',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover(item: DragItem, monitor) {
      if (!dragRef.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = dragRef.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveCard(dragIndex, hoverIndex)

      item.index = hoverIndex
    }
  })

  function handleMoreSettings() {
    setMoreSettingsDialog((curr) => !curr)
  }

  function handleShortNameChange(value: string) {
    const newValue = { ...service }
    newValue.shortName = value
    handleServiceChange(newValue)
  }

  function handleNameChange(value: string) {
    const newValue = { ...service }
    newValue.name = value
    newValue.displayName = undefined
    handleServiceChange(newValue)
  }

  function handleDurationChange(value: number) {
    const newValue = { ...service }
    newValue.duration = value
    handleServiceChange(newValue)
  }

  function handleSupportCustomParticipantsNumber() {
    const newValue = { ...service }
    newValue.supportCustomParticipantsNumber = !newValue.supportCustomParticipantsNumber
    handleServiceChange(newValue)
  }

  function handleCustomParticipantsNumber(value: number) {
    const newValue = { ...service }
    newValue.customParticipantsNumber = value
    handleServiceChange(newValue)
  }

  function handleDescriptionChange(value: string) {
    const newValue = { ...service }
    newValue.description = value
    handleServiceChange(newValue)
  }

  function handlePrivateChange() {
    const newValue = { ...service }
    newValue.isPrivate = !newValue.isPrivate
    handleServiceChange(newValue)
  }

  function handleProgressiveChange() {
    const newValue = { ...service }
    newValue.supportProgressivelyBooking = !newValue.supportProgressivelyBooking
    handleServiceChange(newValue)
  }

  function handleSupportCustomParticipantsQuestion() {
    const newValue = { ...service }
    newValue.supportCustomParticipantsQuestion = !newValue.supportCustomParticipantsQuestion
    handleServiceChange(newValue)
  }

  function handleCustomParticipantsQuestion(value: string) {
    const newValue = { ...service }
    newValue.customParticipantsQuestion = value
    handleServiceChange(newValue)
  }

  function handleSupportCustomDurationPerParticipantNumber() {
    const newValue = { ...service }
    newValue.supportCustomDurationPerParticipantNumber = !newValue.supportCustomDurationPerParticipantNumber
    handleServiceChange(newValue)
  }

  function handleCustomDurationChange(participants: number) {
    return (value: number) => {
      const newValue = { ...service }
      const newCustomDurations = [...(newValue.customDurationPerParticipantNumber || [])]
      const foundIndex = newCustomDurations.findIndex((el) => el.participantNumber === participants)

      if (String(value) === '') {
        if (foundIndex !== -1) {
          newCustomDurations.splice(foundIndex, 1)
        }
      } else if (foundIndex !== -1) {
        newCustomDurations[foundIndex] = { participantNumber: participants, duration: Number(value) }
      } else {
        newCustomDurations.push({ participantNumber: participants, duration: Number(value) })
      }

      newValue.customDurationPerParticipantNumber = newCustomDurations

      handleServiceChange(newValue)
    }
  }

  function handleSupportCheckList() {
    const newValue = { ...service }
    newValue.supportCheckList = !newValue.supportCheckList
    handleServiceChange(newValue)
  }

  function handleCheckList(checkList: CheckListItem[]) {
    const newValue = { ...service }
    newValue.checkList = checkList
    handleServiceChange(newValue)
  }

  function handlePriorityChange(value: number) {
    const newValue = { ...service }
    newValue.priority = Number(value)
    handleServiceChange(newValue)
  }

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'services',
    item: () => ({ id, index }),
    collect: (monitor: DragSourceMonitor) => ({ isDragging: monitor.isDragging() })
  })

  preview(drop(dragRef))

  return (
    <>
      <ItemContainer style={{ opacity: isDragging ? 0 : 1 }} ref={dragRef}>
        <ServiceTitle>
          <InputTranslation
            inputValue={service.name}
            setInputValue={handleNameChange}
            brandId={brandId}
            placeId={shopId}
          >
            <TextField
              style={{ marginTop: '1rem', marginRight: '4px' }}
              placeholder={serviceName}
              value={service.name}
              onChange={handleNameChange}
              message={`${service.name ? service.name.length : 0}/100 ${chars}`}
              test={(v) => v.length < 101}
              invalid={!service.name}
            />
          </InputTranslation>
          <TextField
            style={{ maxWidth: '150px' }}
            placeholder={serviceDuration}
            value={service.duration}
            onChange={handleDurationChange}
            test={isPositiveNumber}
            hint={secondsHint}
            invalid={service.duration < 9}
          />
        </ServiceTitle>
        <ServiceActions>
          <div ref={drag} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <DragIndicatorIcon style={{ opacity: '0.2', cursor: 'move' }} data-handler-id={handlerId} />
          </div>
          <IconButton size="small" onClick={handleMoreSettings}>
            {moreSettingsDialog ? <KeyboardArrowUpIcon fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small" />}
          </IconButton>
        </ServiceActions>
      </ItemContainer>
      {moreSettingsDialog && !isDragging && (
        <MoreSettingsContainer>
          <TextField
            placeholder={shortName}
            label={shortName}
            value={service.shortName}
            onChange={handleShortNameChange}
            message={`${service.shortName ? service.shortName.length : 0}/100 ${chars}`}
            test={(v) => v.length < 101}
          />
          <InputTranslation
            inputValue={service.description || ''}
            setInputValue={handleDescriptionChange}
            brandId={brandId}
            placeId={shopId}
          >
            <TextFieldMUI
              label={description}
              placeholder={description}
              value={service.description}
              onChange={(ev) => handleDescriptionChange(ev.target.value)}
              multiline
              fullWidth
              InputLabelProps={{ shrink: true }}
              helperText={`${service.description ? service.description.length : 0}/1000 ${chars}`}
              margin="dense"
              maxRows={15}
            />
          </InputTranslation>
          <WithHelper tooltipKey="servicesPriority" tag="line">
            <TextField
              placeholder={priority}
              label={priority}
              value={service.priority || 0}
              onChange={handlePriorityChange}
              type="number"
              fullWidth
              test={(v) => Number(v) >= 0 && Number(v) <= 100}
            />
          </WithHelper>
          <Toggle
            toggled={service.isPrivate}
            label={privateService}
            onToggle={handlePrivateChange}
            withHelper={true}
            tag="line"
            tooltipKey="isPrivate"
          />
          <Toggle
            toggled={service.supportProgressivelyBooking}
            label={progressivelyBookingService}
            onToggle={handleProgressiveChange}
            withHelper={true}
            tag="line"
            tooltipKey="supportProgressivelyBooking"
          />
          {!!requestParticipantsNumber && (
            <Toggle
              toggled={service.supportCustomParticipantsQuestion}
              label={supportCustomParticipantsQuestion}
              onToggle={handleSupportCustomParticipantsQuestion}
              withHelper={true}
              tag="line"
              tooltipKey="supportCustomParticipantsQuestion"
            />
          )}
          {!!service.supportCustomParticipantsQuestion && !!requestParticipantsNumber && (
            <InputTranslation
              inputValue={service.customParticipantsQuestion || ''}
              setInputValue={handleCustomParticipantsQuestion}
              brandId={brandId}
              placeId={shopId}
            >
              <TextField
                placeholder={customParticipantsQuestion}
                label={customParticipantsQuestion}
                value={service.customParticipantsQuestion}
                onChange={handleCustomParticipantsQuestion}
                message={`${
                  service.customParticipantsQuestion ? service.customParticipantsQuestion.length : 0
                }/100 ${chars}`}
                test={(v) => v.length < 101}
              />
            </InputTranslation>
          )}
          {!!requestParticipantsNumber && (
            <Toggle
              toggled={service.supportCustomParticipantsNumber}
              label={supportCustomParticipantsNumber}
              onToggle={handleSupportCustomParticipantsNumber}
              withHelper={true}
              tag="line"
              tooltipKey="supportCustomParticipantsNumber"
            />
          )}
          {!!service.supportCustomParticipantsNumber && !!requestParticipantsNumber && (
            <TextField
              placeholder={customParticipantsNumber}
              label={customParticipantsNumber}
              value={service.customParticipantsNumber}
              onChange={handleCustomParticipantsNumber}
              test={isPositiveNumber}
            />
          )}
          {!!requestParticipantsNumber && (
            <Toggle
              toggled={service.supportCustomDurationPerParticipantNumber}
              label={supportCustomDurationPerParticipantNumber}
              onToggle={handleSupportCustomDurationPerParticipantNumber}
              withHelper={true}
              tag="line"
              tooltipKey="supportCustomDurationPerParticipantNumber"
            />
          )}
          {!!service.supportCustomDurationPerParticipantNumber && !!requestParticipantsNumber && (
            <CustomDurationContainer>
              <CustomDurationTitle>{durationsPerParticipantTitle}</CustomDurationTitle>
              {new Array(Number(service.customParticipantsNumber) || Number(maxSimultaneous) || 10)
                .fill(true)
                .map((_, i) => i + 1)
                .map((el) => (
                  <TextField
                    key={el}
                    label={participants(el)}
                    style={{ maxWidth: '150px' }}
                    placeholder={serviceDuration}
                    value={
                      service.customDurationPerParticipantNumber?.find((p) => p.participantNumber === el)?.duration ||
                      undefined
                    }
                    onChange={handleCustomDurationChange(el)}
                    test={isPositiveNumber}
                    hint={secondsHint}
                    invalid={!!service.duration && service.duration < 9}
                  />
                ))}
            </CustomDurationContainer>
          )}
          <Toggle
            toggled={service.supportCheckList}
            label={supportCheckList}
            onToggle={handleSupportCheckList}
            withHelper={true}
            tag="line"
            tooltipKey="handleSupportCheckList"
          />
          {service.supportCheckList && (
            <Accordion>
              <AccordionTitle>{checklistItemsTitle}</AccordionTitle>
              <DndProvider backend={HTML5Backend} key={'check-list'}>
                <Checklist
                  checkList={service.checkList || []}
                  setChecklist={handleCheckList}
                  brandId={brandId}
                  shopId={shopId}
                />
              </DndProvider>
            </Accordion>
          )}
          <div style={{ marginTop: '1rem' }}>
            <Button
              variant="outlined"
              size="small"
              startIcon={<RemoveIcon fontSize="small" />}
              onClick={handleRemoveService}
            >
              {deleteButton}
            </Button>
          </div>
        </MoreSettingsContainer>
      )}
    </>
  )
}
