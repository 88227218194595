import { ServiceSelect } from '..'
import { CheckpointModel } from 'shared/models'
import { useState } from 'react'
import { CircularProgress, Typography } from '@mui/material'
import { monitoring } from 'shared/api'
import { TransferModeProps } from './types'
import { FlexCenterWrapper } from '../../styled'
import { useTranslation } from 'shared/i18n/translate'

function TransferForm({ checkpoints, lineId, onFinish, placeId, positionId, onBack, isCardView }: TransferModeProps) {
  const { tr } = useTranslation()

  const [loading, setLoading] = useState(false)

  async function handleCheckpointSelect(checkpoint: CheckpointModel) {
    setLoading(true)

    try {
      await monitoring.transferPosition({ placeId, lineId, positionId, targetServicePointId: checkpoint.id })
    } finally {
      onFinish?.()
    }
  }

  return (
    <>
      {!!loading && (
        <FlexCenterWrapper>
          <CircularProgress />
        </FlexCenterWrapper>
      )}
      {!loading && (
        <>
          <Typography variant="body1" fontWeight={500} fontSize="18px">
            {tr.positionActions.transfer}
          </Typography>
          <ServiceSelect
            checkpoints={checkpoints || []}
            onСheckpointSelect={handleCheckpointSelect}
            isCardView={isCardView}
            onBack={onBack}
          />
        </>
      )}
    </>
  )
}

export { TransferForm }
