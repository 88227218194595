import { TextField } from 'shared/ui-kit/text-field'
import styled from '@emotion/styled'
import WarningIcon from '@mui/icons-material/Warning'

const InventoryText = styled.div`
  display: flex;
  align-items: center;
`

const InventoryButtons = styled.div`
  margin: 8px;
  display: flex;
  align-items: flex-end;
`

const InventoryButtonsText = styled.div`
  height: 35px;
  line-height: 35px;
  margin: 0 8px 0 24px;
`

const InventoryInput = styled(TextField)`
  width: 60px;
  margin-right: 8px;
`

const InventoryWarningIcon = styled(WarningIcon)`
  margin-left: 8px;
  width: 18;
  height: 18;
  color: #db214d;
`

export { InventoryText, InventoryButtons, InventoryButtonsText, InventoryInput, InventoryWarningIcon }
