export enum AdjustmentApplyMode {
  None = 'none',
  Entries = 'entries',
  Exits = 'exits',
  Difference = 'difference'
}
export enum ActionOnRegistrationPlateReading {
  None = 'none',
  ForceInPlace = 'forceInPlace',
  StartService = 'startService',
  FinishService = 'finishService'
}
export interface CounterModel {
  id: string | number
  name: string
  serialNumber: string
  type: string
  supply: string
  dataTransmission: string
  installDate: number
  lastChargeDate: number
  lastSignalDate: number
  adjustmentRatio: number
  comments: string
  startHour_unixtime?: number
  endHour_unixtime?: number
  noDataAlertTime: number
  macAddress: string
  isActive: boolean
  readonly?: boolean
  displayOrder: number
  realTimeCount?: boolean
  entryCount?: boolean
  exitCount?: boolean
  adjustmentApplyMode?: AdjustmentApplyMode
  actionOnRegistrationPlateReading?: ActionOnRegistrationPlateReading
  sendOpenGateSignalInCaseOfPositiveIssue?: boolean
  alarmOutputName?: string
  alertOperatorInCaseOfError?: boolean
}
