import { unixtimeToObject } from 'shared/i18n/date-formatter'
import isNumber from 'lodash/isNumber'
import { Translations } from 'shared/i18n/translation-types'
import { PositionModel, PositionState } from 'shared/models'

export function getPositionStatusText(
  position: PositionModel,
  translation: Translations,
  timeZoneOffset: number,
  supportAutoCall = true,
  displayServicePoints = true,
  displayServiceDuration = true,
  isOnlyOneServicePoint = false
) {
  const { monitoring } = translation
  const { assignedCheckpoint, operator, state } = position
  const { name: checkpointName = '' } = assignedCheckpoint || {}
  const { firstName = '', lastName = '' } = operator || {}

  const assignedCheckpointName = !isOnlyOneServicePoint ? checkpointName : ''

  switch (state) {
    case PositionState.InService: {
      const { serviceStartedTime_unixtime: startTime = 0, expectedServiceFinishTime_unixtime: expectedTime } = position

      return monitoring.positionStatusInService(
        unixtimeToObject(startTime, timeZoneOffset),
        assignedCheckpointName,
        expectedTime && displayServiceDuration ? unixtimeToObject(expectedTime || 0, timeZoneOffset) : '',
        firstName,
        lastName
      )
    }

    case PositionState.CalledToCheckpoint: {
      const { callTime_unixtime: callTime = 0, expectedServiceStartTime_unixtime: expectedTime } = position

      return monitoring.positionStatusCalled(
        unixtimeToObject(callTime, timeZoneOffset),
        assignedCheckpointName,
        expectedTime && displayServiceDuration ? unixtimeToObject(expectedTime || 0, timeZoneOffset) : '',
        firstName,
        lastName
      )
    }

    case PositionState.Joined: {
      const { joinTime_unixtime: joinTime = 0, expectedCallTime_unixtime: expectedTime } = position

      return monitoring.positionStatusJoined(
        unixtimeToObject(joinTime, timeZoneOffset),
        expectedTime && displayServiceDuration ? unixtimeToObject(expectedTime, timeZoneOffset) : '',
        assignedCheckpointName,
        supportAutoCall,
        displayServicePoints,
        firstName,
        lastName
      )
    }

    case PositionState.InLineHere: {
      const { inPlaceSince, waitingToCallTime } = position
      const expectedCallTime = isNumber(waitingToCallTime)
        ? unixtimeToObject(new Date().getTime() + waitingToCallTime, timeZoneOffset)
        : ''
      return monitoring.positionStatusInLineHere(
        inPlaceSince ? unixtimeToObject(+inPlaceSince, timeZoneOffset) : '',
        expectedCallTime && displayServiceDuration ? expectedCallTime : '',
        assignedCheckpointName,
        supportAutoCall,
        displayServicePoints,
        firstName,
        lastName
      )
    }

    case PositionState.ValidForService: {
      const { validForServiceSince, waitingToCallTime } = position
      const expectedCallTime = isNumber(waitingToCallTime)
        ? unixtimeToObject(new Date().getTime() + waitingToCallTime, timeZoneOffset)
        : ''

      return monitoring.positionStatusValidForService(
        validForServiceSince ? unixtimeToObject(+validForServiceSince, timeZoneOffset) : '',
        expectedCallTime && displayServiceDuration ? expectedCallTime : '',
        assignedCheckpointName,
        supportAutoCall,
        displayServicePoints,
        firstName,
        lastName
      )
    }

    case PositionState.MovingToCheckpoint: {
      const { movingTime_unixtime: movingTime = 0 } = position
      return monitoring.positionStatusMoving(
        unixtimeToObject(movingTime, timeZoneOffset),
        assignedCheckpointName,
        firstName,
        lastName
      )
    }
    case PositionState.NearCheckpoint: {
      const { checkpointZoneEnterTime, expectedServiceStartTime_unixtime: expectedTime = 0 } = position

      return monitoring.positionStatusNearCheckpoint(
        checkpointZoneEnterTime ? unixtimeToObject(checkpointZoneEnterTime, timeZoneOffset) : undefined,
        assignedCheckpointName,
        unixtimeToObject(expectedTime, timeZoneOffset),
        firstName,
        lastName
      )
    }
    default:
      return ''
  }
}
