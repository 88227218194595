import { useMemo, useState } from 'react'
import Text from 'shared/ui-kit/text'
import { Form, FormGroup } from 'shared/ui-kit/form'
import { useTranslation } from 'shared/i18n/translate'
import { unixtimeToObject } from 'shared/i18n/date-formatter'
import { TerminalType } from 'shared/models'
import {
  List as MaterialList,
  ListItemText,
  ListSubheader,
  ListItemSecondaryAction,
  ListItemAvatar,
  Avatar,
  IconButton,
  Divider,
  useTheme,
  ListItemButton
} from '@mui/material'
import { Domain as ShopIcon, LinearScale as LineIcon, ExpandLess, ExpandMore } from '@mui/icons-material'
import { NavLink } from 'react-router-dom'
import { place as placeNav, line as lineNav } from 'pages/nav'
import { TerminalViewProps, ShopWithLines } from '../types'

function TerminalView({ data }: TerminalViewProps) {
  const { tr, dateFormatter, timeZoneOffset } = useTranslation()
  const theme = useTheme()

  const [shopOpen, setShopOpen] = useState<{ [id: number]: boolean | undefined }>({})

  const shopLines: ShopWithLines[] = useMemo(() => {
    return (data.shops || []).reduce(
      (prev: ShopWithLines[], shop) => [
        ...prev,
        { shop, lines: (data.lines || []).filter((l) => l.shopId === shop.id) }
      ],
      []
    )
  }, [data.shops, data.lines])

  return (
    <Form>
      <FormGroup>
        <Text type="caption">{tr.terminalEdit.name}</Text>
        <Text type="body-1">{data.name}</Text>
      </FormGroup>
      {data.shortName && (
        <FormGroup>
          <Text type="caption">{tr.terminalEdit.shortName}</Text>
          <Text type="body-1">{data.shortName}</Text>
        </FormGroup>
      )}
      {data.type === TerminalType.Kiosk && !!data.displayName && (
        <FormGroup>
          <Text type="caption">{tr.terminalEdit.displayName}</Text>
          <Text type="body-1">{data.displayName}</Text>
        </FormGroup>
      )}
      <FormGroup>
        <Text type="caption">{tr.terminalView.guid}</Text>
        <Text type="body-1">{data.guid}</Text>
      </FormGroup>
      <FormGroup>
        <Text type="caption">{tr.terminalEdit.type}</Text>
        <Text type="body-1">
          {data.type === TerminalType.Kiosk && tr.terminalView.kiosk}
          {data.type === TerminalType.InfoPad && tr.terminalView.infopad}
          {data.type === TerminalType.WebKiosk && tr.terminalView.webKiosk}
          {data.type === TerminalType.AcceptationScreen && tr.terminalView.acceptationScreen}
        </Text>
      </FormGroup>
      {data.type === TerminalType.InfoPad && (
        <>
          {data.zoomFactor && (
            <FormGroup>
              <Text type="caption">{tr.terminalEdit.zoomFactor}</Text>
              <Text type="body-1">{data.zoomFactor}</Text>
            </FormGroup>
          )}
          {data.forcedRefreshTime_unixtime && (
            <FormGroup>
              <Text type="caption">{tr.terminalEdit.forcedRefreshInHours}</Text>
              <Text type="body-1">{unixtimeToObject(data.forcedRefreshTime_unixtime, timeZoneOffset)}</Text>
            </FormGroup>
          )}
          <FormGroup>
            <Text type="caption">{tr.terminalEdit.socket}</Text>
            <Text type="body-1">{tr.common.booleanValue(data.socket)}</Text>
          </FormGroup>
          <FormGroup>
            <Text type="caption">{tr.terminalEdit.displayAds}</Text>
            <Text type="body-1">{tr.common.booleanValue(data.displayAds)}</Text>
          </FormGroup>
          <FormGroup>
            <Text type="caption">{tr.terminalEdit.displayPositions}</Text>
            <Text type="body-1">{tr.common.booleanValue(data.displayPositions)}</Text>
          </FormGroup>
          {!!data.displayPositions && (
            <>
              {!!data.callMode && (
                <FormGroup>
                  <Text type="caption">{tr.terminalEdit.callMode}</Text>
                  <Text type="body-1">{tr.terminalEdit.callModes[data.callMode]}</Text>
                </FormGroup>
              )}
              {(data.repeatAlarm || data.repeatAlarm >= 0) && (
                <FormGroup>
                  <Text type="caption">{tr.terminalEdit.repeatAlarm}</Text>
                  <Text type="body-1">{data.repeatAlarm}</Text>
                </FormGroup>
              )}
              {!!data.repeatAlarmSec && (
                <FormGroup>
                  <Text type="caption">{tr.terminalEdit.repeatAlarmSec}</Text>
                  <Text type="body-1">{tr.terminalView.seconds(data.repeatAlarmSec)}</Text>
                </FormGroup>
              )}
              {!!data.waitingPositionsCount && (
                <FormGroup>
                  <Text type="caption">{tr.terminalEdit.waitingPositionsCount}</Text>
                  <Text type="body-1">{data.waitingPositionsCount}</Text>
                </FormGroup>
              )}
              {!!data.waitingTimeFilter && (
                <FormGroup>
                  <Text type="caption">{tr.terminalEdit.waitingTimeFilter}</Text>
                  <Text type="body-1">{tr.terminalView.seconds(data.waitingTimeFilter)}</Text>
                </FormGroup>
              )}
              <FormGroup>
                <Text type="caption">{tr.terminalEdit.displayFullName}</Text>
                <Text type="body-1">{tr.common.booleanValue(data.displayFullName)}</Text>
              </FormGroup>
              <FormGroup>
                <Text type="caption">{tr.terminalEdit.displayReservationCodeOnLastName}</Text>
                <Text type="body-1">{tr.common.booleanValue(data.displayReservationCodeOnLastName)}</Text>
              </FormGroup>
              <FormGroup>
                <Text type="caption">{tr.terminalEdit.combinePositions}</Text>
                <Text type="body-1">{tr.common.booleanValue(data.combineAllPositions)}</Text>
              </FormGroup>
              {!!data.combineAllPositions && !!data.combineGroupName && (
                <FormGroup>
                  <Text type="caption">{tr.terminalEdit.combinedGroupName}</Text>
                  <Text type="body-1">{data.combineGroupName}</Text>
                </FormGroup>
              )}
            </>
          )}
        </>
      )}
      {(data.type === TerminalType.Kiosk || data.type === TerminalType.WebKiosk) &&
        !!data.reservationsStartHour_unixtime && (
          <FormGroup>
            <Text type="caption">{tr.terminalEdit.reservationsStartHour}</Text>
            <Text type="body-1">{unixtimeToObject(data.reservationsStartHour_unixtime, timeZoneOffset)}</Text>
          </FormGroup>
        )}
      {data.lastAlertSent && (
        <FormGroup>
          <Text type="caption">{tr.terminalView.lastAlertSent}</Text>
          <Text type="body-1">{dateFormatter(data.lastAlertSent, 'dateTime')}</Text>
        </FormGroup>
      )}
      {data.type === TerminalType.WebKiosk && (
        <>
          <FormGroup>
            <Text type="caption">{tr.terminalEdit.canDeclarePresent}</Text>
            <Text type="body-1">{tr.common.booleanValue(data.canDeclarePresent)}</Text>
          </FormGroup>
          <FormGroup>
            <Text type="caption">{tr.terminalEdit.displayLineStateAndCallInfo}</Text>
            <Text type="body-1">{tr.common.booleanValue(data.displayLineStateAndCallInfo)}</Text>
          </FormGroup>
          <FormGroup>
            <Text type="caption">{tr.terminalEdit.displayWelcomeScreen}</Text>
            <Text type="body-1">{tr.common.booleanValue(data.displayWelcomeScreen)}</Text>
          </FormGroup>
          <FormGroup>
            <Text type="caption">{tr.terminalEdit.showGeneralUseConditions}</Text>
            <Text type="body-1">{tr.common.booleanValue(data.showGeneralUseConditions)}</Text>
          </FormGroup>
          <FormGroup>
            <Text type="caption">{tr.terminalEdit.isLastNameRequired}</Text>
            <Text type="body-1">{tr.common.booleanValue(data.isLastNameRequired)}</Text>
          </FormGroup>
          <FormGroup>
            <Text type="caption">{tr.terminalEdit.isPhoneNumberRequired}</Text>
            <Text type="body-1">{tr.common.booleanValue(data.isPhoneNumberRequired)}</Text>
          </FormGroup>
          <FormGroup>
            <Text type="caption">{tr.terminalEdit.onlyAsap}</Text>
            <Text type="body-1">{tr.common.booleanValue(data.onlyAsap)}</Text>
          </FormGroup>
          {!!data.minimizePinCode && (
            <FormGroup>
              <Text type="caption">{tr.terminalEdit.minimizePinCode}</Text>
              <Text type="body-1">{data.minimizePinCode}</Text>
            </FormGroup>
          )}
          {!!data.qrCodeLink && (
            <FormGroup>
              <Text type="caption">{tr.terminalEdit.qrCodeLink}</Text>
              <Text type="body-1">{data.qrCodeLink}</Text>
            </FormGroup>
          )}
        </>
      )}
      {[TerminalType.WebKiosk, TerminalType.AcceptationScreen].includes(data.type) && (
        <>
          {!!data.themeName && (
            <FormGroup>
              <Text type="caption">{tr.terminalEdit.themeName}</Text>
              <Text type="body-1">{data.themeName}</Text>
            </FormGroup>
          )}
          {!!data.languages && (
            <FormGroup>
              <Text type="caption">{tr.terminalEdit.languages}</Text>
              <Text type="body-1">{data.languages}</Text>
            </FormGroup>
          )}
        </>
      )}
      {data.type === TerminalType.WebKiosk && !!data.layoutServices && (
        <FormGroup>
          <Text type="caption">{tr.terminalEdit.layoutServices}</Text>
          <Text type="body-1">{tr.terminalEdit.layoutServicesMode[data.layoutServices]}</Text>
        </FormGroup>
      )}
      {shopLines.length > 0 && (
        <>
          <Divider />
          <Form title={tr.terminalView.line} expandable>
            <MaterialList subheader={<ListSubheader>{tr.terminalEdit.shops}</ListSubheader>}>
              {shopLines.map(({ lines, shop }, idx, arr) => (
                <div key={shop.id}>
                  <ListItemButton component={NavLink} to={placeNav(`${shop.id}`)}>
                    <ListItemAvatar>
                      <Avatar>
                        <ShopIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={shop.name}
                      secondary={data.type === TerminalType.InfoPad ? tr.terminalView.lines(lines.length) : null}
                    />
                    {!!lines.length && (
                      <ListItemSecondaryAction>
                        <IconButton onClick={() => setShopOpen({ ...shopOpen, [shop.id]: !shopOpen[shop.id] })}>
                          {shopOpen[shop.id] ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                      </ListItemSecondaryAction>
                    )}
                  </ListItemButton>
                  {!!shopOpen[shop.id] && (
                    <MaterialList
                      component="div"
                      disablePadding
                      style={{ borderLeft: `4px solid ${theme.palette.primary.main}` }}
                    >
                      {lines.map((line) => (
                        <ListItemButton
                          key={line.id}
                          component={NavLink}
                          to={lineNav(`${line.shopId}`, `${line.id}`)}
                          style={{ width: 'auto', marginLeft: '-4px' }}
                        >
                          <ListItemAvatar>
                            <Avatar style={{ color: theme.palette.grey[400], backgroundColor: 'transparent' }}>
                              <LineIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={line.name} />
                        </ListItemButton>
                      ))}
                    </MaterialList>
                  )}
                  {idx !== arr.length - 1 && <Divider />}
                </div>
              ))}
            </MaterialList>
          </Form>
          <Divider />
        </>
      )}
      {data.type === TerminalType.AcceptationScreen && data.acceptationScreenLink && (
        <>
          <Form title={tr.terminalView.links} expandable>
            <FormGroup>
              <Text type="caption">{tr.terminalView.acceptationScreenLink}</Text>
              <a href={data.acceptationScreenLink} style={{ wordBreak: 'break-all', fontSize: '13px' }}>
                {data.acceptationScreenLink}
              </a>
            </FormGroup>
          </Form>
          <Divider />
        </>
      )}
      {data.type === TerminalType.WebKiosk && data.webTerminalLink && (
        <>
          <Form title={tr.terminalView.links} expandable>
            <FormGroup>
              <Text type="caption">{tr.terminalView.webTerminalLink}</Text>
              <a href={data.webTerminalLink} style={{ wordBreak: 'break-all', fontSize: '13px' }}>
                {data.webTerminalLink}
              </a>
            </FormGroup>
          </Form>
          <Divider />
        </>
      )}
      {data.type === TerminalType.InfoPad && (data.tvLink || data.tvPocketLink || data.tvWithDirectionsLink) && (
        <>
          <Form title={tr.terminalView.links} expandable>
            {data.tvLink && (
              <FormGroup>
                <Text type="caption">{tr.terminalView.tvLink}</Text>
                <a href={data.tvLink} style={{ wordBreak: 'break-all', fontSize: '13px' }}>
                  {data.tvLink}
                </a>
              </FormGroup>
            )}
            {data.tvPocketLink && (
              <FormGroup>
                <Text type="caption">{tr.terminalView.tvPocketLink}</Text>
                <a href={data.tvPocketLink} style={{ wordBreak: 'break-all', fontSize: '13px' }}>
                  {data.tvPocketLink}
                </a>
              </FormGroup>
            )}
            {data.tvWithDirectionsLink && (
              <FormGroup>
                <Text type="caption">{tr.terminalView.tvWithDirectionsLink}</Text>
                <a href={data.tvWithDirectionsLink} style={{ wordBreak: 'break-all', fontSize: '13px' }}>
                  {data.tvWithDirectionsLink}
                </a>
              </FormGroup>
            )}
          </Form>
          <Divider />
        </>
      )}
    </Form>
  )
}

export { TerminalView }
