import styled from '@emotion/styled'

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`

const Icons = styled.div`
  display: flex;
  gap: 1rem;
`

const BarContent = styled.div`
  display: flex;
  justify-content: space-between;
`

const ParametersGroup = styled.div`
  margin-left: 1rem;
  border-left: solid 2px #db214d;
`

const SchedulerActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
`

export { IconsContainer, BarContent, Icons, ParametersGroup, SchedulerActions }
