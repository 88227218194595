import { user } from 'shared/api'
import { login } from 'pages/nav'
import ResetPasswordForm, { ResetForm } from './dumb/reset-password'
import { showMessage, MessageTypes } from 'store/actions/main-layout-action-creators'
import { useTranslation } from 'shared/i18n/translate'
import { ResetPasswordWrapper } from './styled'
import { useAppDispatch } from 'store'
import { useHistory } from 'react-router'

function ResetPassword() {
  const dispatch = useAppDispatch()
  const { tr } = useTranslation()
  const history = useHistory()

  async function handleSubmit({ email }: ResetForm) {
    try {
      await user.resetPassword(email)
    } catch (err) {
      console.error(err)
    }

    history.push(login() + `?email=${email}`)

    dispatch(showMessage(tr.login.resetMessage, MessageTypes.Default))
  }

  function handleCancel() {
    history.push(login())
  }

  return (
    <ResetPasswordWrapper>
      <ResetPasswordForm onSubmit={handleSubmit} onCancel={handleCancel} />
    </ResetPasswordWrapper>
  )
}

export default ResetPassword
