import { useEffect, useRef, useState } from 'react'
import * as SuggestionData from '../suggestion-dialog-data'
import { PositionModel, TimeLeftOrOverState } from 'shared/models'
import { openWindowOnCenter } from '../suggestion-blank-dialog'
import { checkpointMonitoringFinishDialog } from 'pages/nav'

function useSuggestionFinishDialog(
  active: boolean,
  beforeInSec: number,
  shopId: string,
  lineId: string,
  checkpointId: string,
  positions?: PositionModel[]
) {
  const [toFinishPosition, setToFinishPosition] = useState<PositionModel | null>(null)
  const positionsDisplayedFinishDialog = useRef<(string | number)[]>([])
  const lastFinishedPostion = useRef<PositionModel | null>(null)
  const finishDialogRef = useRef<any>()
  const [finishDialodIsBlocked, setFinishDialogIsBlocked] = useState(false)

  function positionWillFinished(position: PositionModel) {
    if (!position.timeLeftOrOverState) {
      return false
    }

    if (position.timeLeftOrOverState === TimeLeftOrOverState.Over) {
      return true
    }

    return position.timeLeftOrOver && position.timeLeftOrOver < beforeInSec * 1000
  }

  useEffect(() => {
    if (!!active && !toFinishPosition && (!finishDialogRef.current || finishDialogRef.current.closed)) {
      const position = positions?.find(positionWillFinished)

      if (position) {
        openSuggestFinishDialog(position)
      }
    } else if ((!!finishDialogRef?.current && !finishDialogRef?.current.closed) || !!toFinishPosition) {
      const toFinishPosition = positions?.find(positionWillFinished)

      if (!toFinishPosition || lastFinishedPostion.current?.id !== toFinishPosition?.id) {
        finishDialogRef?.current.close()
        setToFinishPosition(null)
      }
    }
  }, [positions, active])

  useEffect(() => {
    return () => {
      finishDialogRef.current?.close?.()
    }
  }, [])

  function openSuggestFinishDialog(position: PositionModel) {
    const alreadyIsDisplayed = positionsDisplayedFinishDialog.current.find((el) => el === position.id)

    if (!alreadyIsDisplayed) {
      positionsDisplayedFinishDialog.current = [...positionsDisplayedFinishDialog.current, position.id]

      SuggestionData.putPosition({ position })
      lastFinishedPostion.current = position

      finishDialogRef.current?.close()

      const finishDialog = openWindowOnCenter({
        url: '#' + checkpointMonitoringFinishDialog(shopId, lineId, checkpointId),
        w: 650,
        h: 448
      })

      if (!finishDialog) {
        setFinishDialogIsBlocked(true)
        setToFinishPosition(position)
      } else {
        finishDialogRef.current = finishDialog
      }
    }
  }

  function closeToFinishPosition() {
    setToFinishPosition(null)
  }

  return { finishDialodIsBlocked, toFinishPosition, closeToFinishPosition }
}

export { useSuggestionFinishDialog }
