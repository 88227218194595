import { ErrorOutlineOutlined as ErrorOutlineOutlinedIcon } from '@mui/icons-material'
import { ReactNode } from 'react'
import { ErrorMessageWrapper } from './styled'

function ErrorMessage({ children }: { children: ReactNode }) {
  return (
    <ErrorMessageWrapper>
      <ErrorOutlineOutlinedIcon style={{ display: 'flex', margin: '0px 8px' }} />
      {children}
    </ErrorMessageWrapper>
  )
}

export { ErrorMessage }
