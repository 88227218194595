import Card from 'shared/ui-kit/card'
import { Divider, Button } from '@mui/material'
import { LineLayoutProps } from './types'
import {
  ActionsWrapper,
  BackLink,
  Group,
  GroupLabel,
  GroupsWrapper,
  LargeLayoutRoot,
  LargeLayoutSideBar,
  LeftMenuWrapper,
  LineImage,
  LineInfoWrapper,
  LineName,
  LineNameWrapper,
  SmallIcon
} from '../styled'
import { getUrl } from 'shared/api/helpers'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import InvalidIcon from '@mui/icons-material/Error'
import Text from 'shared/ui-kit/text'
import { useTranslation } from 'shared/i18n/translate'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { addTestAttr } from 'shared/utils/test-attr'

function LineDesktopLayout({
  data,
  formGroups,
  onBackToLine,
  onChangeActiveGroup,
  activeGroup,
  formIsValid,
  isEdit,
  lastUpdateText,
  children,
  isLoading,
  lineName
}: LineLayoutProps) {
  const { tr } = useTranslation()

  return (
    <LargeLayoutRoot>
      <div style={{ display: 'flex', maxWidth: '1100px', width: '100%', justifyContent: 'center' }}>
        <LargeLayoutSideBar>
          <Card>
            <LeftMenuWrapper>
              {data.id && (
                <>
                  <LineInfoWrapper>
                    {data.image?.id && <LineImage $url={getUrl('downloadFile', { id: data.image.id })} />}
                    <LineNameWrapper>
                      <LineName>{lineName || data.name}</LineName>
                      <BackLink onClick={onBackToLine}>
                        <ArrowBackIcon style={{ fontSize: '14px' }} />
                        {tr.lineEdit.backToLine}
                      </BackLink>
                    </LineNameWrapper>
                  </LineInfoWrapper>
                  <Divider />
                </>
              )}
              <GroupsWrapper>
                {formGroups.map((group) => (
                  <Group key={group.key} onClick={onChangeActiveGroup(group.key)}>
                    <GroupLabel $isActive={group.key === activeGroup}>{group.label}</GroupLabel>
                    {!group.invalid && <SmallIcon />}
                    <SmallIcon>
                      {group.key === activeGroup && <ChevronRightIcon fontSize="small" color="primary" />}
                    </SmallIcon>
                    {group.invalid && (
                      <SmallIcon>
                        <InvalidIcon style={{ fontSize: '16px' }} color="primary" />
                      </SmallIcon>
                    )}
                  </Group>
                ))}
              </GroupsWrapper>
            </LeftMenuWrapper>
          </Card>
          <ActionsWrapper>
            <Button
              {...addTestAttr('LineEdit-UpdateButton')}
              variant="contained"
              color="primary"
              size="small"
              disabled={!formIsValid || isLoading}
              type="submit"
            >
              {isEdit ? tr.entityEdit.updateButton : tr.entityEdit.createButton}
            </Button>
          </ActionsWrapper>
          {!!lastUpdateText && (
            <Text type="caption" style={{ maxWidth: '260px' }}>
              {lastUpdateText}
            </Text>
          )}
        </LargeLayoutSideBar>
        <div style={{ width: '100%', minWidth: '500px', maxWidth: '600px' }}>{children}</div>
      </div>
    </LargeLayoutRoot>
  )
}

export { LineDesktopLayout }
