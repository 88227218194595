import { useState } from 'react'
import { getTimeZoneShortName } from 'shared/utils/date'
import { Popover, MenuItem } from '@mui/material'
import { TimezoneName, TimezoneZone } from './styled'
import { TimeZoneProps } from './types'

function TimeZone({ timeZone, onChange, timeZones }: TimeZoneProps) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  function handleTouchTap(ev: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    ev.preventDefault()
    setAnchorEl(ev.currentTarget)
  }

  function handleRequestClose() {
    setAnchorEl(null)
  }

  function menuItemClick(x) {
    return () => {
      onChange(x)
      setAnchorEl(null)
    }
  }

  return (
    <div>
      <TimezoneZone onClick={handleTouchTap}>
        <TimezoneName>{getTimeZoneShortName(timeZone?.offset || 0)}</TimezoneName>
      </TimezoneZone>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClose={handleRequestClose}
        elevation={1}
      >
        {(timeZones || []).map((x) => (
          <MenuItem key={x.id} style={{ minHeight: '3rem' }} onClick={menuItemClick(x)}>
            {x.name}
          </MenuItem>
        ))}
      </Popover>
    </div>
  )
}

export { TimeZone }
