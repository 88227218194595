import { broadcastAlerts } from 'shared/api'
import { Dispatch } from '@reduxjs/toolkit'
import {
  resetList as resetBroadcastAlertsConfig,
  setListRequest,
  setListSuccess
} from '../reducers/broadcast-alerts-reducer'

export function getBroadcastAlertsConfig(data: { lineId: string | number }) {
  return async (dispatch: Dispatch) => {
    dispatch(setListRequest())

    const res = await broadcastAlerts.getBroadcastAlertsConfig(data)

    dispatch(setListSuccess(res))
  }
}

export { resetBroadcastAlertsConfig }
