import { useEffect, useRef, useState } from 'react'
import MuiPhoneNumber, { MuiPhoneNumberProps } from '../material-ui-phoneinput'
import { useTranslation } from 'shared/i18n/translate'
import AttentionPoint from 'shared/ui-kit/attention-point'

type Props = {
  value?: string | null
  attention?: boolean
}

export function IntlPhoneInput(props: MuiPhoneNumberProps & Props) {
  const [localState, setLocalState] = useState('')

  useEffect(() => {
    if (!props.value && !!props.defaultCountry) {
      setLocalState(`+${props.defaultCountry}` || '')
    }
  }, [])

  useEffect(() => {
    if (props.value && props.value.slice(0, 1) === '0' && props.value.length >= 10) {
      props.onChange?.('+33' + props.value.slice(1, props.value.length))
    } else if (props.value && props.value !== '+' && props.value !== props.defaultCountry) {
      setLocalState(props.value || '')
    }
  }, [props.value])

  const initValue = localState
  const value = initValue.replace(/\D+/g, '')
  const replacedValue = `${value.slice(0, 2).replace('89', '79')}${value.slice(2, value.length)}`

  const inputRef = useRef('')

  const { locale } = useTranslation()

  const defaultCodeMap = {
    en: '44',
    fr: '33',
    ru: '7'
  }

  const validedValue = replacedValue ? `+${replacedValue}` : `+${defaultCodeMap[locale]}`

  const plusOrValue = initValue === '+' ? '+' : validedValue

  useEffect(() => {
    return () => {
      if (inputRef.current === '+') {
        props.onChange?.('')
      }
    }
  }, [])

  function handleChange(value: any) {
    let trimValue = value.replace(/[\\(\\)\-\s]+/g, '')

    if (trimValue.slice(0, 4) === '+330' && trimValue.length > 4) {
      trimValue = '+33' + trimValue.slice(4, trimValue.length)
    }

    if (trimValue.slice(0, 2) === '+0' && trimValue.length > 10) {
      trimValue = '+33' + trimValue.slice(2, trimValue.length)
    }

    if (trimValue.slice(0, 1) === '0' && trimValue.length >= 10) {
      trimValue = '+33' + trimValue.slice(1, trimValue.length)
    }

    inputRef.current = trimValue

    setLocalState(trimValue)

    if (trimValue === '+' || trimValue === `+${defaultCodeMap[locale]}`) {
      props.onChange('')
    } else {
      props.onChange(trimValue)
    }
  }

  return (
    <MuiPhoneNumber
      {...{ ...props, attention: undefined }}
      label={
        <span style={{ display: 'flex' }}>
          {props.label} {props.attention && <AttentionPoint />}
        </span>
      }
      preferredCountries={['fr', 'ru', 'pf', 'de', 'ro', 'pl', 'it', 'es', 'pt']}
      onChange={handleChange}
      defaultCountry={locale}
      countryCodeEditable={true}
      value={plusOrValue}
    />
  )
}
