import { useEffect } from 'react'
import Chip from '@mui/material/Chip'
import AttentionPoint from '../../attention-point'
import { ControlLabel } from '../../control-label'
import { ChipListSelectBaseProps } from '../types'

type Props<T, V> = ChipListSelectBaseProps<T, V, true>

function ChipListMultipleSelectComponent<T, V>(props: Props<T, V>) {
  const {
    list = [],
    value = [],
    compare = (valueItem, listItem) => valueItem === listItem,
    isDisabled = () => false,
    disabled = false,
    dataConverter = (item) => item,
    autoSelect,
    ensureValue = false,
    disableClickable,
    labelExtractor = (item) => item,
    label,
    attention,
    style,
    chipStyle = { height: 'unset' },
    size = 'medium',
    onChange
  } = props

  const badValues: (T | V)[] = []

  value.forEach((v) => {
    if (!list.find((li) => compare(v, li))) {
      badValues.push(v)
    }
  })

  const isSingleElement = list.length === 1

  const isHasValue = !!(value && value.length)

  const isHasList = !!(list && list.length)

  const isNeedSelectAction = badValues.length
    ? true
    : autoSelect && (isSingleElement || ensureValue) && !isHasValue && isHasList

  function isSelected(value = [] as (T | V)[], item) {
    const findValue = value.find((valueItem) => {
      return compare(valueItem, item)
    })

    if (typeof findValue === 'number') {
      return findValue === 0 || !!findValue
    }

    return !!findValue
  }

  function onItemClickChangeHandler(item) {
    const isItemSelected = isSelected(value, item)

    if (ensureValue && value.length === 1 && isItemSelected) {
      return
    }

    const newValue = isItemSelected ? value.filter((v) => !compare(v, item)) : [...value, dataConverter(item)]

    onChangeHandler(newValue)
  }

  function onChangeHandler(value) {
    onChange?.(value)
  }

  useEffect(() => {
    if (isNeedSelectAction) {
      const possibleItem = list.find((item) => !isDisabled(item))
      possibleItem && onItemClickChangeHandler(possibleItem)
    }
  }, [list])

  return (
    <div style={style}>
      {label && (
        <div style={{ display: 'flex' }}>
          <ControlLabel style={{ marginBottom: 12 }}>{label}</ControlLabel>
          {attention && <AttentionPoint />}
        </div>
      )}
      <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: -8 }}>
        {list.map((item, index, array) => {
          const isItemSelected = isSelected(value, item)
          const isDisable = disabled || isDisabled(item)

          const disableProps =
            isDisable && disableClickable
              ? { onClick: () => onItemClickChangeHandler(item), style: { cursor: 'pointer' } }
              : {}

          return (
            <div key={index} {...disableProps}>
              <Chip
                style={{ marginTop: 8, marginRight: array.length - 1 === index ? 'unset' : 8, ...chipStyle }}
                disabled={isDisable}
                color={isItemSelected ? 'primary' : 'default'}
                variant="outlined"
                size={size}
                onClick={() => onItemClickChangeHandler(item)}
                label={labelExtractor(item)}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ChipListMultipleSelectComponent
export const ChipListMultipleSelect = ChipListMultipleSelectComponent
