import fakeLines from './fake-simplified-lines'
import { LineModelSimplified, LinesStatisticsModel } from 'shared/models'

const timeStates = ['onTime', 'late', 'closed']
function createStatistic(line: LineModelSimplified, state: string): LinesStatisticsModel {
  return {
    lineId: line.id,
    lineName: line.name,
    persons: Math.round(Math.random() * 1000),
    time: Math.round(Math.random() * 60 * 24 * 1.5),
    stateTime: Math.round(Math.random() * 1000),
    state: state
  }
}

const data: LinesStatisticsModel[] = [
  createStatistic(fakeLines[0], timeStates[0]),
  createStatistic(fakeLines[1], timeStates[1]),
  createStatistic(fakeLines[2], timeStates[2])
]

export function createData() {
  return [
    createStatistic(fakeLines[0], timeStates[0]),
    createStatistic(fakeLines[1], timeStates[1]),
    createStatistic(fakeLines[2], timeStates[2])
  ]
}

export default data
