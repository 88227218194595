import { createContext } from 'react'
import { Translations } from './translation-types'
import { AppLocale } from '.'

export type TimeFormats = 'time' | 'date' | 'dateTime'

export type DateFormatter = (
  date: string | number | Date | null,
  format?: TimeFormats | Intl.DateTimeFormatOptions,
  timeZone?: string
) => string

export interface ITranslationContextProps {
  locale: AppLocale
  tr: Translations
  dateFormatter: DateFormatter
  timeZoneId: string
  timeZoneOffset: number
}

export const TranslateContext = createContext<ITranslationContextProps | null>(null)
