import styled from '@emotion/styled'

const CommonInfo = styled.div`
  display: flex;
  margin-bottom: -0.5rem;

  @media print {
    & {
      display: block;
      margin: 0;
    }
  }
`

const CommonSection = styled.div`
  margin-bottom: 1rem;
`

const UserInfo = styled.div`
  margin: 0 1rem;
  flex: 1;
`

const SmallInfo = styled.div`
  display: flex;
  word-break: break-word;
  margin-bottom: 16px;
`

const DesktopRoot = styled.div`
  display: block;
  padding-bottom: 1rem;

  @media screen and (max-width: 480px) {
    & {
      display: none;
    }
  }
`

const MobileRoot = styled.div`
  display: none;
  padding-bottom: 1rem;

  @media screen and (max-width: 480px) {
    & {
      display: block;
    }
  }
`

const AppointmentInfo = styled.div`
  padding: 1rem;
  font-weight: 500;
  display: flex;
`

const AppointmentStateInfo = styled.div<{ $isDeleted?: boolean }>`
  font-size: 14px;
  margin-bottom: 0.5rem;
  color: ${(props) => (props.$isDeleted ? '#db214c' : '#0000008a')};
`

const LeftInfoBlock = styled.div`
  flex: 1;
`

const CustomerInfo = styled.div`
  font-size: 17px;
  margin-bottom: 0.25rem;
`

const ContactsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const ContactInfo = styled.div`
  margin-right: 1rem;
  display: flex;
  align-items: center;
`

const Contact = styled.a<{ $disabled?: boolean }>`
  color: ${(props) => (props.$disabled ? '#00000061' : '#0052b4')};
  font-size: 13px;
  text-decoration: none;
  margin-left: 0.5rem;
`

const Root = styled.div<{ $disabled?: boolean }>`
  color: ${(props) => (props.$disabled ? '#00000061' : undefined)};
`

const AppointmentFiltersBar = styled.div`
  display: block;
  padding: 0 16px;
  position: fixed;
  width: 100%;
  z-index: 1100;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  box-shadow:
    0 1px 6px rgba(0, 0, 0, 0.12),
    0 1px 4px rgba(0, 0, 0, 0.12);
  min-height: 64px;
  align-items: center;
  justify-content: center;
  background: #fff;
  top: 64px;
  left: 0;
  white-space: nowrap;
`

const AppointmentFiltersSideBar = styled.div`
  position: sticky;
  top: 6rem;
  margin-right: 1rem;
  width: 290px;
`

const AppointmentFiltersRoot = styled.div`
  padding: 2rem 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
`

export {
  Root,
  CommonInfo,
  CommonSection,
  UserInfo,
  SmallInfo,
  DesktopRoot,
  MobileRoot,
  AppointmentInfo,
  AppointmentStateInfo,
  LeftInfoBlock,
  CustomerInfo,
  ContactsWrapper,
  ContactInfo,
  Contact,
  AppointmentFiltersBar,
  AppointmentFiltersSideBar,
  AppointmentFiltersRoot
}
