import axios from 'axios'
import { TerminalAPI } from 'shared/models'

const terminal: TerminalAPI = {
  getTerminalList(params, config) {
    return axios.get('getTerminalList', { params, ...config })
  },
  getTerminal(placeId, id, config) {
    return axios.get('getTerminal', { params: { id, placeId }, ...config })
  },
  setTerminalTicketsQuantity(data, config) {
    return axios.put('setTerminalTicketsQuantity', data, config)
  },
  createTerminal(data, config) {
    return axios.post('createTerminal', data, config)
  },
  updateTerminal(data, config) {
    return axios.put('updateTerminal', data, config)
  },
  deleteTerminal(id, config) {
    return axios.delete('deleteTerminal', { data: id, headers: { 'Content-Type': 'application/json' }, ...config })
  },
  disableTerminal(id, config) {
    return axios.post('disableTerminal', id, { headers: { 'Content-Type': 'application/json' }, ...config })
  },
  enableTerminal(id, config) {
    return axios.post('enableTerminal', id, { headers: { 'Content-Type': 'application/json' }, ...config })
  },
  reloadTerminal(terminalId, config) {
    return axios.post('reloadTerminal', { terminalId }, config)
  }
}

export default terminal
