import { CSSProperties, ReactNode } from 'react'
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material'

type Props<T, V> = {
  list: T[]
  onChange: (value: V[]) => void
  value: V[]
  variant?: 'standard' | 'outlined' | 'filled'
  fullWidth?: boolean
  style?: CSSProperties
  label?: string
  lableExtractor: (item: T) => string | number
  valueExtractor?: (item: T) => V
  menuExtractor?: (item: T) => ReactNode
}

export default function MultipleCheckSelect<T, V>(props: Props<T, V>) {
  const { onChange, variant, fullWidth, style, value, label, lableExtractor, list, valueExtractor, menuExtractor } =
    props

  function handleChange(event) {
    if (event.target.value) {
      const val = event.target.value.filter(Boolean)
      onChange?.(val)
    }
  }

  return (
    <FormControl variant={variant} fullWidth={fullWidth} style={style} size="small">
      <InputLabel shrink htmlFor="outlined-age-native-simple" id="mutiple-checkbox-label" variant="standard">
        {label}
      </InputLabel>
      <Select
        labelId="mutiple-checkbox-label"
        id="mutiple-checkbox"
        multiple
        value={value}
        onChange={handleChange}
        renderValue={(selected) =>
          selected.map((s) => lableExtractor(list.find((l) => (valueExtractor?.(l) || l) === s) as T)).join(', ')
        }
      >
        {list.map((item, index) => {
          const itemAsValue = valueExtractor?.(item) || item

          return (
            <MenuItem key={index} value={itemAsValue as any}>
              <Checkbox checked={!!value.find((v) => v === itemAsValue)} />
              <ListItemText primary={menuExtractor ? menuExtractor(item) : lableExtractor(item)} />
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
