import { useEffect, useState } from 'react'
import * as Nav from 'pages/nav'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { useTranslation } from 'shared/i18n/translate'
import { appointments } from 'shared/api'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import Container from 'shared/ui-kit/container'
import Appointment from './appointment'
import { Button, Card, CircularProgress } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useHistory, useLocation, useParams } from 'react-router'
import { AppointmentModel, ParentPlaceAndLineModel } from 'shared/models'
import { CenterContainer } from 'shared/ui-kit/center-container'
import { useAppDispatch } from 'store'
import { useTimeZoneFilter } from 'features/time-zone-filter'

function AppointmentView() {
  const history = useHistory()
  const { appointmentId, placeId, lineId } = useParams<{ appointmentId: string; placeId: string; lineId: string }>()
  const location = useLocation()
  const { tr } = useTranslation()
  const dispatch = useAppDispatch()

  useTimeZoneFilter(placeId)

  const [data, setData] = useState<AppointmentModel>()
  const [fetching, setFetching] = useState(false)

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData() {
    const params = new URLSearchParams(location.search)

    try {
      setFetching(true)
      const res = await appointments.getAppointment({
        id: appointmentId,
        shopId: placeId || params.get('shopId'),
        lineId: lineId || params.get('lineId')
      })
      setData(res.data)
      initBreadcrumbs(res.parents)
    } finally {
      setFetching(false)
    }
  }

  function initBreadcrumbs(parents?: Partial<ParentPlaceAndLineModel>) {
    const crumb = getCrumbs(tr.breadcrumbs)

    const mainCrumbs = [crumb.home(), crumb.appointments(), crumb.appointment([appointmentId])]

    const { shop, line } = parents || {}
    const shopName = shop?.name || ''
    const lineName = line?.name || ''

    const lineCrumbs = [
      crumb.home(),
      crumb.places(),
      crumb.place([placeId], shopName),
      crumb.lines([placeId]),
      crumb.line([placeId, lineId], lineName),
      crumb.appointmentsLine([placeId, lineId]),
      crumb.appointment([appointmentId])
    ]

    dispatch(setBreadcrumbs(lineId && lineId ? lineCrumbs : mainCrumbs))
  }

  async function handleConfirm(id) {
    setFetching(true)

    try {
      await appointments.confirmAppointment(id)
      fetchData()
    } finally {
      setFetching(false)
    }
  }

  async function handleRemove() {
    setFetching(true)

    try {
      await appointments.deleteAppointment(appointmentId)

      history.push(lineId ? Nav.appointmentsLine(placeId, lineId) : Nav.appointments())
    } catch (err) {
      setFetching(false)
    }
  }

  function handleEdit() {
    const params = new URLSearchParams(location.search)
    const query = new URLSearchParams()

    query.set('shopId', placeId || params.get('shopId') || '')
    query.set('lineId', placeId || params.get('lineId') || '')

    history.push(`${Nav.editAppointment(appointmentId)}?${query.toString()}`)
  }

  function handleCreateAppointment() {
    if (!placeId || !lineId) {
      return history.push(Nav.createAppointment())
    }

    return history.push(Nav.createAppointmentLine(placeId, lineId))
  }

  if (fetching) {
    return (
      <CenterContainer>
        <CircularProgress />
      </CenterContainer>
    )
  }

  if (!data) {
    return null
  }

  return (
    <Container>
      <Button
        startIcon={<AddIcon />}
        onClick={handleCreateAppointment}
        variant="contained"
        disableElevation
        fullWidth
        style={{ marginBottom: '1rem' }}
      >
        {tr.appointments.add}
      </Button>
      <Card>
        <Appointment
          data={data}
          onEdit={handleEdit}
          onRemove={handleRemove}
          disabled={fetching}
          onConfirm={handleConfirm}
        />
      </Card>
    </Container>
  )
}

export default AppointmentView
