import { CSSProperties, ReactElement } from 'react'
import { NavLink } from 'react-router-dom'

type Props = {
  to?: string
  children: ReactElement
  style?: CSSProperties
  className: string
  onClick: () => void
}

function MaybeTileLinkComponent(props: Props) {
  const { to, children } = props

  if (to) {
    return (
      <NavLink to={to} {...props}>
        {children}
      </NavLink>
    )
  }

  return <div {...props}>{children}</div>
}

export const MaybeTileLink = MaybeTileLinkComponent
