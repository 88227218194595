import { Translations } from 'shared/i18n/translation-types'

const KB = 1024
const MB = KB * 1024
const GB = MB * 1024

function toFloor(value: number) {
  return Math.floor(value) ? value : 0
}

function withPostfix(value: number, postfix: string) {
  return `${value} ${postfix}`
}

function toNormalizedValue(value: number, fractionDigits: number) {
  const floorValue = toFloor(value)
  const orderNumber = 10 ** fractionDigits
  return Math.floor(floorValue * orderNumber) / orderNumber
}

function convertToSizeName(value: number, fractionDigits = 2) {
  const norm = toNormalizedValue(value, fractionDigits)
  return (postfix: string) => (norm ? withPostfix(norm, postfix) : 0)
}

export function convertFileSize(bytes: number, tr: Translations) {
  const gbs = bytes / GB
  const mbs = bytes / MB
  const kbs = bytes / KB

  return (
    convertToSizeName(gbs)(tr.fileUploader.gb) ||
    convertToSizeName(mbs)(tr.fileUploader.mb) ||
    convertToSizeName(kbs)(tr.fileUploader.kb) ||
    convertToSizeName(bytes)(tr.fileUploader.b) ||
    ''
  )
}
