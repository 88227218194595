import { Suspense, lazy, useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import Text from 'shared/ui-kit/text'
import { Form } from 'shared/ui-kit/form'
import {
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  IconButton,
  Dialog,
  Typography,
  TextField
} from '@mui/material'
import { validatePhone } from 'features/material-ui-phoneinput'
import { IntlPhoneInput } from 'features/intl-phone-input'
import { isEmail } from 'shared/utils/string-test'
import { QrCodeScanner as QrCodeScannerIcon } from '@mui/icons-material'
import { QRDescription, QRReaderWrapper, QRTextInfo, QRTitle } from 'features/position-edit/styled'
import { videoContainerStyle } from 'features/position-edit/utils'
import { OnResultFunction } from 'shared/utils/qr-reader/types'
import { FinishServiceFormProps, FinishServiceFormValue } from './types'
import { PositionState } from 'shared/models'
import { ActionFormActions } from '../styled'

const QrReader = lazy(() => import('shared/utils/qr-reader'))

function FinishServiceForm({
  tags = '',
  onConfirm,
  isBreak,
  defaultPhoneCountryCode,
  monitoringPositionMenuItemCaptions,
  positionState,
  canSetPositionFinishedAfterArrived,
  displayServiceSurvey,
  sendSurveyLinkByEmail,
  sendSurveyLinkBySms,
  isCardView,
  onBack,
  ...props
}: FinishServiceFormProps) {
  const [qrScanerIsOpened, setQrScanerIsOpened] = useState(false)

  const { tr } = useTranslation()
  const tagsList = tags ? tags.split(';') : []

  const [data, setData] = useState<FinishServiceFormValue>({
    satisfactionLevel: null,
    surveyTags: [],
    comment: '',
    email: props.email || '',
    phoneNumber: props.phoneNumber || ''
  })

  const handleChangeData = (key: keyof FinishServiceFormValue) => (value) =>
    setData((prevState) => ({ ...prevState, [key]: value }))

  const handleTagClick = (tag) => {
    const tagToRemove = data.surveyTags.find((dataTag) => dataTag === tag)

    handleChangeData('surveyTags')(
      tagToRemove ? data.surveyTags.filter((dataTag) => dataTag !== tagToRemove) : [...data.surveyTags, tag]
    )
  }

  function handleOpenQRScanner() {
    setQrScanerIsOpened(true)
  }

  function handleCloseQRScanner() {
    setQrScanerIsOpened(false)
  }

  const handleQRResult: OnResultFunction = (text) => {
    if (text) {
      handleChangeData('comment')(text)
      handleCloseQRScanner()
    }
  }

  const isTakeInCharge =
    !!canSetPositionFinishedAfterArrived &&
    ![PositionState.ServiceStarted, PositionState.InService].includes(positionState)

  const isValidEmail = (email) => !isBreak || !sendSurveyLinkByEmail || !email || isEmail(email)
  const isValidPhone = (phone) => !isBreak || !sendSurveyLinkBySms || !phone || !validatePhone(phone, true)

  return (
    <>
      <Typography variant="body1" fontWeight={500} fontSize="18px">
        {isTakeInCharge
          ? monitoringPositionMenuItemCaptions?.takeInCharge || tr.positionActions.takeInCharge
          : monitoringPositionMenuItemCaptions?.endService || tr.positionActions.finishService}
      </Typography>
      <Form>
        {!!displayServiceSurvey && (
          <>
            {!isBreak && !!tagsList.length && (
              <div style={{ marginTop: '0.5rem' }}>
                <Text type="caption" style={{ margin: '0 0 4px' }}>
                  {tr.finishServiceForm.tags}
                </Text>
                {tagsList.map((tag, index, array) => (
                  <Chip
                    label={tag}
                    key={index}
                    style={{ marginRight: index === array.length - 1 ? '' : '0.5rem', marginBottom: '0.5rem' }}
                    color={data.surveyTags.find((dataTag) => dataTag === tag) ? 'primary' : 'default'}
                    onClick={() => handleTagClick(tag)}
                  />
                ))}
              </div>
            )}
            {!isBreak && (
              <div style={{ marginTop: '0.5rem' }}>
                <Text type="caption" style={{ marginBottom: '-0.5rem' }}>
                  {tr.finishServiceForm.customerSatisfactionLevel}
                </Text>
                <RadioGroup
                  value={data.satisfactionLevel || 0}
                  onChange={(event) => handleChangeData('satisfactionLevel')(event.target.value)}
                  row
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio color="primary" />}
                    label={tr.customerSatisfactionLevel.veryUnhappy}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio color="primary" />}
                    label={tr.customerSatisfactionLevel.unhappy}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio color="primary" />}
                    label={tr.customerSatisfactionLevel.happy}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="4"
                    control={<Radio color="primary" />}
                    label={tr.customerSatisfactionLevel.veryHappy}
                    labelPlacement="end"
                  />
                </RadioGroup>
              </div>
            )}
          </>
        )}
        {!!sendSurveyLinkByEmail && (
          <div style={{ marginTop: '0.5rem' }}>
            <TextField
              label={tr.lineMonitoring.email}
              value={data.email}
              placeholder={tr.lineMonitoring.email}
              onChange={(ev) => handleChangeData('email')(ev.target.value)}
              error={!isValidEmail(data.email)}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </div>
        )}
        {!!sendSurveyLinkBySms && (
          <div style={{ marginTop: '1rem' }}>
            <IntlPhoneInput
              label={tr.lineMonitoring.phoneNumber}
              placeholder={tr.lineMonitoring.phoneNumber}
              type="tel"
              attention={!validatePhone(data.phoneNumber, true)}
              value={data.phoneNumber}
              defaultCountry={defaultPhoneCountryCode}
              fullWidth
              onChange={(value) => handleChangeData('phoneNumber')(value)}
            />
          </div>
        )}
        <div style={{ marginTop: '1rem', display: 'flex', alignItems: 'center' }}>
          <TextField
            label={tr.finishServiceForm.comments}
            value={data.comment}
            fullWidth
            placeholder={tr.finishServiceForm.comments}
            onChange={(ev) => handleChangeData('comment')(ev.target.value)}
            multiline
            InputLabelProps={{ shrink: true }}
            maxRows={15}
          />
          <IconButton onClick={handleOpenQRScanner}>
            <QrCodeScannerIcon fontSize="small" />
          </IconButton>
        </div>
        <ActionFormActions $marginTop={isCardView ? '1.5rem' : '0.5rem'}>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={() => onConfirm?.(data)}
            disabled={!isValidPhone(data.phoneNumber) || !isValidEmail(data.email)}
          >
            {tr.finishServiceForm.confirm}
          </Button>
          <Button onClick={onBack} size="small">
            {tr.positionActions.backButton}
          </Button>
        </ActionFormActions>
      </Form>
      <Dialog open={qrScanerIsOpened} onClose={handleCloseQRScanner}>
        <QRReaderWrapper>
          <Suspense fallback={null}>
            <QrReader onResult={handleQRResult} videoContainerStyle={videoContainerStyle} />
          </Suspense>
        </QRReaderWrapper>
        <QRTextInfo>
          <QRTitle>{tr.lineMonitoring.qrTitle}</QRTitle>
          <QRDescription>{tr.lineMonitoring.qrDescription}</QRDescription>
        </QRTextInfo>
      </Dialog>
    </>
  )
}

export { FinishServiceForm }
