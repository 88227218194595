import { Suspense, lazy, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import { Info } from '@mui/icons-material'
import LanguageSelect from 'shared/ui-kit/language-select'
import { Form, FormGroup } from 'shared/ui-kit/form'
import PositionTypeSelect, { getPositionTypeMap } from './position-type-select'
import { TextField } from 'shared/ui-kit/text-field'
import { StepContent, StepLabel, Stepper, Button, Step, Paper, Typography } from '@mui/material'
import { validatePhone } from 'features/material-ui-phoneinput'
import { ChipListSelect } from 'shared/ui-kit/selects'
import TimeslotSelect from './timeslot-select'
import CheckpointSelect from './checkpoint-select'
import ServiceSelect from './service-select-array-always'
import { AdditionalValues, PositionCustomer, PositionState } from 'shared/models'
import { PositionType } from 'shared/models'
import { ServiceMode } from 'shared/models'
import Toggle from 'shared/ui-kit/toggle-wrap'
import isBoolean from 'lodash/isBoolean'
import Text from 'shared/ui-kit/text'
import { UserSearch, getUserName } from './user-search'
import CustomerWithEditLink, { getCustomerName } from './customer-with-edit-link'
import uniqueId from 'lodash/uniqueId'
import { IntlPhoneInput } from 'features/intl-phone-input'
import DurationPicker from 'features/time/duration-picker-timestamp'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import { Checklist } from 'features/checklist'
import { Dialog, IconButton } from '@mui/material'
import { AdditionalsForm } from 'features/additionals-form'
import { canEditWithType, defaultRequestPersonalInfo, getSupportedLanguages, videoContainerStyle } from './utils'
import { ErrorMessage } from './error-message'
import { QRScanPersonalInfoModal } from 'features/qr-scanner/qr-scan-personal-modal'
import { ToggleOnOutlined as ToggleOnIcon, ToggleOffOutlined as ToggleOffIcon } from '@mui/icons-material'
import {
  Additionals,
  AdditionalsQRWrapper,
  AdditionalsWrapper,
  AnonumousPositionSwitchContainer,
  FormWrapper,
  QRDescription,
  QRIconButton,
  QRReaderWrapper,
  QRTextInfo,
  QRTitle,
  QRWrapper,
  StepLabelText
} from './styled'
import { useAppSelector } from 'store'
import { PositionEditProps } from './types'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { getCompanyName, getFirstNameField, getLastNameField, getEmailField, getPhoneField } from './fields'
import { OnResultFunction } from 'shared/utils/qr-reader/types'

const QrReader = lazy(() => import('shared/utils/qr-reader'))

const getAnonumousStorageKey = (lineId) => `anonymousPosition_${lineId}`

function PositionEdit(props: PositionEditProps) {
  const { tr, locale } = useTranslation()
  const { canAssignCustomServicePoints, canViewPersonalInfo, canAddAndEditPositions } =
    useAppSelector(getPermissionsSelector)

  const isServicePosition = props.data.type === PositionType.Break
  const isSearchUserAvailable = !props.singleUserAvailable && isServicePosition
  const isDuplicate = Boolean(props.isDuplicate)
  const fromJournal = Boolean(props.fromJournal)
  const canSelectTimeslot = props.data.state === PositionState.Joined || !props.data.state
  const isCheckpointChoiceAvailable = !!canAssignCustomServicePoints || isServicePosition
  const isTimeslotsAvailable = canSelectTimeslot && (props.config.line.manageAppointments || isServicePosition)

  const [activeStep, setActiveStep] = useState((props.isEdit || isDuplicate) && isSearchUserAvailable ? 1 : 0)
  const [additionalsValidations, setAdditionalsValidations] = useState(false)
  const [qrDialog, setQrDialog] = useState(false)
  const [personalQrDialog, setPersonalQrDialog] = useState(false)
  const [searchPhoneNumber, setSearchPhoneNumber] = useState('')

  const [positionIsAnonymous, setPositionIsAnonymous] = useState(
    !!props.config?.options?.canCreateAnonymousPosition &&
      localStorage.getItem(getAnonumousStorageKey(props.lineId)) === 'true'
  )

  const initialData = useRef<any>()
  const initialCustomerFullName = useRef<string>('')

  const [asapSelected, setAsapSelected] = useState(!!props.config.line.asapMode)

  const shopSupportedLanguages = props.config?.shop?.supportedLanguages

  useEffect(() => {
    if (!props.data.personsQuantity) {
      props.onPropertyChange('personsQuantity', 1)
    }

    if (props.data?.customer?.lastName || props.data?.customer?.firstName) {
      const nameArray = [props.data.customer.firstName, props.data.customer.lastName]
      initialCustomerFullName.current = nameArray.filter(Boolean).join(' ')
    }
  }, [])

  useEffect(() => {
    if (props.isEdit && !initialData.current) {
      initialData.current = { ...props.data }
    }
  }, [props.isEdit])

  useEffect(() => {
    if (isServicePosition && props.data.customer) {
      props.onPropertyChange('customer', null)
    }

    if (isServicePosition && props.checkpointId && !props.data.customCheckpoint) {
      const checkpoints = props.config?.checkpoints || []
      const customCheckpoint = checkpoints.find((checkpoint) => String(checkpoint.id) === String(props.checkpointId))

      if (customCheckpoint) {
        props.onPropertyChange('customCheckpoint', customCheckpoint)
      }
    }

    if (!props.isEdit && !isServicePosition && !!props.data.customCheckpoint) {
      props.onPropertyChange('customCheckpoint', null)
    }
  }, [props.data.type])

  function isServicesDirty() {
    const initedServices = initialData.current?.services || []
    const currentServices = props.data?.services || []

    if (initedServices.length !== currentServices.length) {
      return true
    }

    for (let i = 0; i < currentServices.length; i++) {
      if (!initedServices.find((initS) => initS.id === currentServices[i].id)) {
        return true
      }
    }

    return false
  }

  function disabledServicesSelectedDiff() {
    if (!isServicesDirty()) {
      return []
    }

    const initedServices = initialData.current?.services || []
    const currentServices = props.data?.services || []
    const availableServices = props.availableServices || []
    const initedServicesDisabled = initedServices.filter((is) => !availableServices.find((avIds) => avIds === is.id))
    const currentServicesDisabled = currentServices.filter((cs) => !availableServices.find((avIds) => avIds === cs.id))
    const diff = currentServicesDisabled.filter((cs) => !initedServicesDisabled.find((is) => is.id === cs.id))

    return diff
  }

  const changeServicesCommentRequered =
    !props.isEdit && !!disabledServicesSelectedDiff().length && !props.data.staffComment?.length

  const hasSelectedServices = Boolean(props.data.services?.length)

  function getServices() {
    if (!props.config?.line?.services?.length) {
      return []
    }

    return props.config.line.services.filter((x) => (isServicePosition ? x.isPrivate : !x.isPrivate))
  }

  const isNoneServiceMode = props.config?.line?.lineServicesMode === ServiceMode.None || getServices().length === 0
  const staffCommentRequired = Boolean(props.data?.priority && !props.data?.staffComment?.length)

  function handleCustomerPropertyChange<T extends keyof PositionCustomer>(propertyName: T, value: PositionCustomer[T]) {
    const customer = { ...(props.data?.customer || {}) }
    customer[propertyName] = value

    props.onPropertyChange('customer', customer)
  }

  function getModeSrc() {
    const requestPersonalInfo = props.config?.line?.requestPersonalInfo || defaultRequestPersonalInfo

    const emailField = getEmailField({ handleCustomerPropertyChange, customer: props.data?.customer })
    const phoneField = getPhoneField({
      handleCustomerPropertyChange,
      customer: props.data?.customer,
      defaultPhoneCountryCode: props.config.shop.defaultPhoneCountryCode,
      type: props.data?.type
    })

    const localized = {
      default: () =>
        [
          requestPersonalInfo.companyName &&
            getCompanyName({ handleCustomerPropertyChange, customer: props.data?.customer }),
          requestPersonalInfo.firstName &&
            getFirstNameField(
              {
                handleCustomerPropertyChange,
                customer: props.data?.customer,
                initialCustomerFullName: initialCustomerFullName.current,
                maximumCharactersParticipantNameUpdate: props.config?.line?.maximumCharactersParticipantNameUpdate,
                lineParticipantsFirstNameCaptionTemplate: props.config?.line?.lineParticipantsFirstNameCaptionTemplate
              },
              requestPersonalInfo.companyName ? false : true
            ),
          requestPersonalInfo.lastName &&
            getLastNameField({
              handleCustomerPropertyChange,
              customer: props.data?.customer,
              initialCustomerFullName: initialCustomerFullName.current,
              maximumCharactersParticipantNameUpdate: props.config?.line?.maximumCharactersParticipantNameUpdate,
              lastNameIsMandatory: true,
              lineParticipantsLastNameCaptionTemplate: props.config?.line?.lineParticipantsLastNameCaptionTemplate
            })
        ].filter(Boolean)
    }

    const notViewedPersonalInfo = (!canViewPersonalInfo && props.isEdit) || positionIsAnonymous
    const localizedFieldsObject = (localized[locale] || localized.default)()
    const servicesNotReady = !hasSelectedServices && !isNoneServiceMode
    const isDisplayCheckpoints = props.config?.options?.displayServicePoints ?? true
    const checkpointNotSelected = !props.data.customCheckpoint && isDisplayCheckpoints
    const isLocalizedFields =
      requestPersonalInfo.companyName || requestPersonalInfo.firstName || requestPersonalInfo.lastName

    const localizedFieldsValid = () => localizedFieldsObject.every((x) => x.isValid()) || notViewedPersonalInfo
    const localizedFieldsViews = () => localizedFieldsObject.map((x) => x.view)

    const views = [
      ...(isLocalizedFields ? localizedFieldsViews() : []),
      requestPersonalInfo.email && emailField.view,
      requestPersonalInfo.phoneNumber && phoneField.view
    ].filter(Boolean)

    const notReady = () =>
      (!notViewedPersonalInfo && isLocalizedFields && !localizedFieldsValid()) ||
      (!notViewedPersonalInfo && requestPersonalInfo.phoneNumber && !phoneField.isValid()) ||
      (!notViewedPersonalInfo && requestPersonalInfo.email && !emailField.isValid()) ||
      staffCommentRequired ||
      servicesNotReady ||
      changeServicesCommentRequered

    return {
      [PositionType.ScreenCall]: { views, notReady },
      [PositionType.Break]: { views: [], notReady: () => servicesNotReady || checkpointNotSelected }
    }
  }

  function hangleOpenQRCode() {
    setQrDialog(true)
  }

  function hangleCloseQRCode() {
    setQrDialog(false)
  }

  function hangleOpenPersonalQRCode() {
    setPersonalQrDialog(true)
  }

  function hangleClosePersonalQRCode() {
    setPersonalQrDialog(false)
  }

  function handlePositionAnonumousTrigger() {
    setPositionIsAnonymous((curr) => {
      localStorage.setItem(getAnonumousStorageKey(props.lineId), String(!curr))
      return !curr
    })
  }

  function handlePersonScanSuccess(data: PositionCustomer) {
    hangleClosePersonalQRCode()
    const customer = { ...(props.data?.customer || {}) }

    if (data?.lastName) {
      customer.lastName = data.lastName
    }

    if (data?.firstName) {
      customer.firstName = data.firstName
    }

    if (data?.companyName) {
      customer.companyName = data.companyName
    }

    if (data?.phoneNumber) {
      customer.phoneNumber = data.phoneNumber
    }

    if (data?.email) {
      customer.email = data.email
    }

    props.onPropertyChange('customer', customer)
  }

  function onSubmitAndStartService() {
    const checkpoints = props.config?.checkpoints || []
    const customCheckpoint = checkpoints?.find((checkpoint) => String(checkpoint.id) === String(props.checkpointId))
    props.onSubmit?.({ startServiceAfterCreation: true, customCheckpoint })
  }

  const handleQRResult: OnResultFunction = (text) => {
    if (text) {
      const qrResult = String(text || '')
      const additionals: AdditionalValues = { ...props.data.additionals }

      props.config.line.additionals?.forEach((el) => {
        if (!el?.qrRegexp || typeof el.qrRegexp !== 'string') {
          return
        }

        if (el.qrRegexpGroupNumber === null || el.qrRegexpGroupNumber === undefined) {
          try {
            if (el.shortName) {
              ;(additionals[el.shortName] as any) = qrResult.match(new RegExp(el.qrRegexp, 'g'))?.join('')
            }
          } catch (error) {
            console.log(error)
          }

          return
        }

        try {
          const qrRegexp = qrResult.matchAll(new RegExp(el.qrRegexp, 'g'))
          const qrValues: any[] = []

          for (const match of qrRegexp) {
            if (match[1]) {
              qrValues.push(match[1])
            }
          }

          additionals[el.shortName] = qrValues[el.qrRegexpGroupNumber]
        } catch (error) {
          console.log(error)
        }
      })

      setQrDialog(false)
      props.onPropertyChange('additionals', additionals)
    }
  }

  function handleNext() {
    setActiveStep((curr) => curr + 1)
  }
  function handleBack() {
    setActiveStep((curr) => curr - 1)
  }

  function renderBackButton(callback?: () => void) {
    if (activeStep !== 0) {
      return (
        <Button
          variant="text"
          size="small"
          style={{ marginLeft: '8px' }}
          onClick={() => {
            handleBack()
            callback?.()
          }}
          disabled={props.isFetching}
        >
          {tr.lineMonitoring.back}
        </Button>
      )
    }

    return null
  }

  function renderAdvanced() {
    const localExpandedPath = 'monitoring-advanced-open'
    const isOpenByLocal = localStorage.getItem(localExpandedPath) === 'true'
    const lineServiceTime = props.config?.line?.serviceTime ?? 0
    const services = props.data?.services ?? []
    const checkListSelectedItems = props.data?.checkListSelectedItems ?? []

    const servicesDuration = services.length
      ? services.reduce((acc, curr) => {
          const personsQuantity = props.data.personsQuantity || 1

          const timeImpact =
            curr.checkList?.reduce((acc, item) => {
              if (
                !item.shortName ||
                item.impactOnServiceType === 'None' ||
                !checkListSelectedItems.includes(item.shortName)
              ) {
                return acc
              }

              if (item.impactOnServiceType === 'Value') {
                return acc + (item.impactOnServiceValue || 0) * personsQuantity
              }

              if (item.impactOnServiceType === 'Precentage') {
                return acc + (curr.duration / 100) * (item.impactOnServiceValue || 0) * personsQuantity
              }

              return acc
            }, 0) || 0

          const custom = curr.customDurationPerParticipantNumber || []
          const foundCustomDuration = custom.filter((el) => el.participantNumber === personsQuantity)

          if (!foundCustomDuration.length) {
            return acc + curr.duration * personsQuantity + timeImpact
          }

          return acc + foundCustomDuration[0].duration + timeImpact
        }, 0)
      : lineServiceTime

    const durationServiceAndPersons = servicesDuration
    const isDisplayCheckpoints = props.config?.options?.displayServicePoints ?? true
    const customServiceDurationValue = new Date(0).setUTCSeconds(
      props.data.customServiceDuration === 0
        ? props.data.customServiceDuration
        : props.data.customServiceDuration || durationServiceAndPersons
    )

    const displayCustomDuration = props?.config?.line?.flags?.displayCustomDuration !== false

    return (
      <Form
        expandable
        expanded={isServicePosition || isOpenByLocal}
        title={tr.lineMonitoring.advancedParameters}
        onToggle={(value) => localStorage.setItem(localExpandedPath, String(value))}
      >
        {!isServicePosition && (
          <FormGroup>
            <Toggle
              toggled={props.data.priority}
              label={tr.lineMonitoring.priority}
              onToggle={() => {
                const newValue = !props.data.priority

                if (!newValue) {
                  props.onPropertyChange('services', [], () => props.onPropertyChange('priority', newValue))
                } else {
                  props.onPropertyChange('priority', newValue)
                }
              }}
            />
          </FormGroup>
        )}
        <Checklist
          services={services}
          checkListSelectedItems={props.data.checkListSelectedItems || []}
          onCheckElement={(key, value) => {
            let newValues = [...(props.data.checkListSelectedItems || [])]

            if (!value) {
              newValues = newValues.filter((x) => x !== key)
            } else if (value && !newValues.includes(key)) {
              newValues.push(key)
            }

            props.onPropertyChange('checkListSelectedItems', newValues)
          }}
        />
        {displayCustomDuration && (
          <FormGroup style={{ display: 'flex' }}>
            <DurationPicker
              disabled
              style={{ flex: '0 0 50%', margin: '0 4px 0 0' }}
              label={tr.lineMonitoring.standardServiceDuration}
              value={new Date(0).setUTCSeconds(durationServiceAndPersons)}
            />
            <DurationPicker
              attention={props.data.customServiceDuration === 0}
              style={{ flex: '0 0 50%', margin: '0 0 0 4px' }}
              label={tr.lineMonitoring.customServiceDuration}
              value={customServiceDurationValue}
              onChange={(valMs) => {
                if (valMs) {
                  props.onPropertyChange('customServiceDuration', new Date(valMs).setUTCFullYear(1970, 0, 1) / 1000)
                } else {
                  props.onPropertyChange('customServiceDuration', null)
                }
              }}
            />
          </FormGroup>
        )}
        {isDisplayCheckpoints && !!isCheckpointChoiceAvailable && (
          <FormGroup>
            <CheckpointSelect
              strict={isServicePosition}
              attention={isServicePosition && !props.data.customCheckpoint}
              label={tr.lineMonitoring.servicePoint}
              anyLabel={tr.lineMonitoring.any}
              isDisabledItem={(data) => {
                if (data.id === null && props.availableCheckpoints?.length && props.availableCheckpoints.length > 0) {
                  return false
                }

                if (data.id === null && props.availableCheckpoints?.length && props.availableCheckpoints.length < 1) {
                  return true
                }

                return !props.availableCheckpoints?.find((el) => el === data.id)
              }}
              list={props.config.checkpoints}
              value={props.data.customCheckpoint || null}
              positionStatus={props.data.state}
              onChange={(value) => props.onPropertyChange('customCheckpoint', value)}
            />
          </FormGroup>
        )}
        <FormGroup>
          <TextField
            autocomplete={uniqueId('autocomplete')}
            label={tr.lineMonitoring.staffComments}
            placeholder={tr.lineMonitoring.staffComments}
            value={props.data.staffComment}
            onChange={(val) => props.onPropertyChange('staffComment', val)}
            attention={staffCommentRequired || changeServicesCommentRequered}
          />
          {staffCommentRequired && <Text type="error-2">{tr.lineMonitoring.addPriorityServiceReason}</Text>}
          {changeServicesCommentRequered && (
            <Text type="error-2">{tr.lineMonitoring.addChooseOverloadServiceReason}</Text>
          )}
        </FormGroup>
      </Form>
    )
  }

  if (props.data.type && !canEditWithType(props.data.type)) {
    return <ErrorMessage>{tr.lineMonitoring.cannotBeEdited}</ErrorMessage>
  }

  const stepper = [
    !props.isEdit &&
      !isDuplicate &&
      props.positionTypes?.length &&
      props.positionTypes.length > 1 && {
        label: tr.lineMonitoring.mode,
        renderContent: () => (
          <Form>
            <FormGroup style={{ marginBottom: 0 }}>
              <PositionTypeSelect
                onlyBreak={!canAddAndEditPositions}
                types={props.positionTypes || []}
                value={props.data.type}
                onChange={(v) => {
                  props.onPropertyChange('type', v)
                  if (v === PositionType.Break && !isBoolean(props.singleUserAvailable)) {
                    props.onSearchUser?.({}, handleNext)
                  } else {
                    handleNext()
                  }
                }}
              />
            </FormGroup>
          </Form>
        ),
        renderPassedContent: () => (
          <Text type="body-1" style={{ margin: '0 1rem', fontWeight: 300 }}>
            {getPositionTypeMap(tr)[props.data.type || PositionType.ScreenCall]?.name}
          </Text>
        )
      },
    !props.isEdit &&
      !isDuplicate &&
      !isServicePosition &&
      props.config.options.supportSearchCustomerByPhone && {
        label: tr.lineMonitoring.search,
        renderContent: () => (
          <div>
            <FormGroup>
              <IntlPhoneInput
                autoComplete={uniqueId('autocomplete')}
                autoFocus
                label={tr.lineMonitoring.phoneNumber}
                placeholder={tr.lineMonitoring.phoneNumber}
                value={searchPhoneNumber}
                defaultCountry={props.config.shop.defaultPhoneCountryCode}
                type="tel"
                onChange={(searchPhoneNumber) => setSearchPhoneNumber(searchPhoneNumber)}
                fullWidth
                attention={!validatePhone(searchPhoneNumber, true)}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    props.onSearchCustomer(searchPhoneNumber, handleNext)
                  }
                }}
              />
            </FormGroup>
            <FormGroup style={{ marginBottom: 0 }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                disabled={props.isFetching || !validatePhone(searchPhoneNumber, true)}
                onClick={() => props.onSearchCustomer(searchPhoneNumber, handleNext)}
              >
                {tr.lineMonitoring.next}
              </Button>
              <Button
                variant="text"
                color="primary"
                size="small"
                disabled={props.isFetching}
                style={{ marginLeft: '1rem' }}
                onClick={handleNext}
              >
                {tr.lineMonitoring.ignore}
              </Button>
              {renderBackButton()}
            </FormGroup>
          </div>
        )
      },
    isSearchUserAvailable && {
      label: tr.lineMonitoring.operatorSearch,
      renderContent: () => (
        <div>
          <UserSearch
            searchAvailable={isBoolean(props.singleUserAvailable)}
            value={props.data.customer}
            users={props.users || []}
            onUserSearch={(term) => props.onSearchUser?.({ term })}
            onUserSelect={(user) => {
              props.onPropertyChange('customer', user)
              if (user) {
                handleNext()
              }
            }}
          />
          {activeStep !== 0 && (
            <FormGroup style={{ marginBottom: 0 }}>
              <Button size="small" variant="contained" color="primary" disabled={props.isFetching} onClick={handleBack}>
                {tr.lineMonitoring.back}
              </Button>
            </FormGroup>
          )}
        </div>
      ),
      passedLabel: tr.lineMonitoring.operator,
      renderPassedContent: () => (
        <Text type="body-1" style={{ margin: '0 1rem', fontWeight: 300 }}>
          {getUserName(props.data.customer)}
        </Text>
      )
    },
    {
      label: tr.lineMonitoring.contactInfo,
      renderContent: (opt?: { isSingleContent?: boolean }) => {
        const type = props.data?.type
        const srcType = type !== PositionType.Break ? PositionType.ScreenCall : PositionType.Break
        const src = getModeSrc()[srcType]

        if (!src) {
          return null
        }

        const notRequestLink = props.config.line.displayPersonalInfoInReadOnlyMode === false
        const selectedServices = props.data.services || []
        const isSingleService = selectedServices.length === 1
        const customParticipantsNumber = isSingleService ? selectedServices[0].customParticipantsNumber : undefined
        const customParticipantsQuestion = isSingleService ? selectedServices[0].customParticipantsQuestion : undefined

        const additionalFields = (props.config.line.additionals || []).filter(
          (el) => el.type !== 'Instruction' && el.type !== 'VideoConferenceLink'
        )
        const validateAdditionals = Boolean(!additionalFields?.length || additionalsValidations)

        const isBreak = type === PositionType.Break
        const viewMode =
          !isServicePosition && props.isEdit && !props.data.updatePersonalInfoReason?.length && !notRequestLink
        const canCreateAnonymousPosition = !!props?.config?.options?.canCreateAnonymousPosition
        const displayAddAndStartServiceButton = !!props?.config?.options?.displayAddAndStartServiceButton

        return (
          <>
            {!positionIsAnonymous && !isBreak && !!props.config?.line?.personalDataReadFromQRCode && (
              <QRWrapper>
                <QRIconButton size="small" onClick={hangleOpenPersonalQRCode}>
                  <QrCodeScannerIcon fontSize="small" />
                </QRIconButton>
                <QRScanPersonalInfoModal
                  open={personalQrDialog}
                  onClose={hangleClosePersonalQRCode}
                  onSuccess={handlePersonScanSuccess}
                  lineId={Number(props.lineId)}
                  placeId={Number(props.placeId)}
                />
              </QRWrapper>
            )}
            {!!canCreateAnonymousPosition && (
              <AnonumousPositionSwitchContainer>
                <Typography variant="body2">{tr.lineMonitoring.positionIsAnonymous}</Typography>
                <IconButton
                  disabled={!!props.isEdit}
                  onClick={handlePositionAnonumousTrigger}
                  style={{ marginLeft: 4 }}
                  size="small"
                >
                  {positionIsAnonymous ? <ToggleOnIcon /> : <ToggleOffIcon />}
                </IconButton>
              </AnonumousPositionSwitchContainer>
            )}
            {!positionIsAnonymous && (canViewPersonalInfo || !props.isEdit) && (
              <>
                {viewMode ? (
                  <CustomerWithEditLink
                    isSingleContent={opt?.isSingleContent || false}
                    data={props.data.customer}
                    canEditPersonalInfo={canViewPersonalInfo}
                    onEditRequest={(reason) => props.onPropertyChange('updatePersonalInfoReason', reason)}
                  />
                ) : (
                  src.views.map((view, index) => <FormGroup key={index}>{view}</FormGroup>)
                )}
              </>
            )}
            {!isServicePosition && getSupportedLanguages(shopSupportedLanguages) && (
              <FormGroup>
                <LanguageSelect
                  ensureValue
                  hideSingle
                  title={tr.lineMonitoring.language}
                  languages={getSupportedLanguages(shopSupportedLanguages)}
                  value={props.data.customer?.language}
                  onChange={(lang) => {
                    if (typeof lang === 'string') {
                      handleCustomerPropertyChange('language', lang)
                    }
                  }}
                />
              </FormGroup>
            )}
            {!!getServices()?.length && !isNoneServiceMode && (
              <ServiceSelect
                disableClickable={!props.isEdit}
                ignoreDisable={props.data.priority}
                attention={!hasSelectedServices}
                isDisabledItem={(data) => !props.availableServices?.find((el) => el === data.id)}
                multiple={props.config?.line?.multipleServicesSelection ?? false}
                title={
                  (props.config?.line?.multipleServicesSelection ?? false)
                    ? tr.lineMonitoring.services
                    : tr.lineMonitoring.service
                }
                value={props.data.services}
                list={getServices()}
                onChange={(value) => {
                  props.onPropertyChange('services', value, () => props.onPropertyChange('customServiceDuration', null))
                }}
              />
            )}
            {!isServicePosition &&
              props.config.line.maxSimultaneous > 1 &&
              props.config.line.requestParticipantsNumber && (
                <FormGroup>
                  <ChipListSelect
                    label={
                      customParticipantsQuestion ||
                      props.config.line.lineParticipantsCaptionTemplate ||
                      tr.lineMonitoring.groupSize
                    }
                    ensureValue
                    value={props.data.personsQuantity}
                    isDisabled={(item) =>
                      props.currentMaxPersonsInGroup !== undefined && item > props.currentMaxPersonsInGroup
                    }
                    list={Array.from(
                      Array(customParticipantsNumber || props.config.line.maxSimultaneous),
                      (_, index) => index + 1
                    )}
                    labelExtractor={(item) => <div style={{ fontSize: '16px' }}>{item}</div>}
                    onChange={(value) => props.onPropertyChange('personsQuantity', value)}
                  />
                </FormGroup>
              )}
            {!isServicePosition && !!additionalFields?.length && (
              <AdditionalsWrapper>
                <AdditionalsQRWrapper>
                  <IconButton size="small" onClick={hangleOpenQRCode} style={{ position: 'absolute' }}>
                    <QrCodeScannerIcon fontSize="small" />
                  </IconButton>
                </AdditionalsQRWrapper>
                <Dialog open={qrDialog} onClose={hangleCloseQRCode}>
                  <QRReaderWrapper>
                    <Suspense fallback={null}>
                      <QrReader onResult={handleQRResult} videoContainerStyle={videoContainerStyle} />
                    </Suspense>
                  </QRReaderWrapper>
                  <QRTextInfo>
                    <QRTitle>{tr.lineMonitoring.qrTitle}</QRTitle>
                    <QRDescription>{tr.lineMonitoring.qrDescription}</QRDescription>
                  </QRTextInfo>
                </Dialog>
                <Additionals>
                  <AdditionalsForm
                    fields={additionalFields}
                    values={props.data.additionals || {}}
                    onChange={(values) => props.onPropertyChange('additionals', values)}
                    onValidChange={(valid) => setAdditionalsValidations(valid)}
                    lineId={props.lineId}
                    placeId={props.placeId}
                    defaultPhoneCountryCode={props.config.shop.defaultPhoneCountryCode}
                    personsQuantity={props.data.personsQuantity}
                    selectedServiceIds={(props.data.services || []).map((x) => x.id) as string[]}
                    syncValue
                  />
                </Additionals>
              </AdditionalsWrapper>
            )}
            {renderAdvanced()}
            {!fromJournal && isDuplicate && !isTimeslotsAvailable && (
              <FormGroup>
                <Toggle
                  toggled={props.data.deleteOriginalPosition}
                  label={tr.lineMonitoring.deleteOriginalPositon}
                  onToggle={(_, val) => {
                    props.onPropertyChange('deleteOriginalPosition', val)
                  }}
                />
              </FormGroup>
            )}
            <FormGroup style={{ display: 'flex', gap: '0.5rem' }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  if (isTimeslotsAvailable) {
                    handleNext()
                  } else {
                    props.onSubmit?.()
                  }
                }}
                disabled={
                  (!validateAdditionals && !isBreak) ||
                  (!viewMode && src.notReady()) ||
                  props.isFetching ||
                  props.data.customServiceDuration === 0
                }
              >
                {isTimeslotsAvailable
                  ? tr.lineMonitoring.next
                  : props.isEdit
                    ? tr.lineMonitoring.update
                    : tr.lineMonitoring.add}
              </Button>
              {!!displayAddAndStartServiceButton && !isTimeslotsAvailable && !props.isEdit && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={onSubmitAndStartService}
                  disabled={
                    (!validateAdditionals && !isBreak) ||
                    (!viewMode && src.notReady()) ||
                    props.isFetching ||
                    props.data.customServiceDuration === 0
                  }
                >
                  {tr.lineMonitoring.addAndStartServiceButton}
                </Button>
              )}
              {renderBackButton()}
            </FormGroup>
          </>
        )
      },
      renderPassedContent: () => (
        <div style={{ margin: '0 1rem', fontWeight: 300 }}>
          {!isServicePosition && Boolean(props.data.customer) && (
            <div style={{ marginBottom: '1rem' }}>
              <Text type="body-1">{getCustomerName(props.data.customer)}</Text>
              <Text type="body-2">{props.data.customer.email}</Text>
              <Text type="body-2">{props.data.customer.phoneNumber}</Text>
              {props.config.line.requestParticipantsNumber && (
                <Text type="body-2">
                  {tr.lineMonitoring.groupSizeView} {props.data.personsQuantity}
                </Text>
              )}
              {props.data.priority && (
                <div style={{ display: 'flex', alignItems: 'center', margin: '0.5rem 0' }}>
                  {tr.lineMonitoring.priority}
                  <Info style={{ marginLeft: 4 }} color="primary" />
                </div>
              )}
            </div>
          )}
          <Text type="body-1">{props.data.services.map((x) => x.name).join(', ')}</Text>
          <Text type="body-2">{props.data.customCheckpoint?.name}</Text>
          {props.data.customServiceDuration && (
            <Text type="body-2">
              <span style={{ color: '#000', marginRight: '0.5rem' }}>{tr.lineMonitoring.customServiceDuration}:</span>
              {tr.time.hmin(new Date(0).setUTCSeconds(props.data.customServiceDuration) / 60000)}
            </Text>
          )}
        </div>
      )
    },
    isTimeslotsAvailable && {
      label: tr.lineMonitoring.serviceAndTimeslot,
      renderContent: () => {
        const displayAddAndStartServiceButton = !!props?.config?.options?.displayAddAndStartServiceButton

        return (
          <>
            <FormGroup>
              <TimeslotSelect
                asapNotPreferred={isServicePosition && props.config?.line?.manageAppointments === true}
                config={{ ...props.config.line, timeslots: props.timeslots || [] }}
                value={(props.data.timeSlot && props.data.timeSlot.id) || null}
                onChange={(value) => {
                  props.onPropertyChange(
                    'timeSlot',
                    props.timeslots?.find((t) => t.id === value)
                  )
                }}
                onTimeslotRequest={props.onTimeslotRequest}
                isFetching={props.isFetching}
                setAsapSelected={setAsapSelected}
                asapSelected={asapSelected}
              />
            </FormGroup>
            {!fromJournal && isDuplicate && (
              <FormGroup>
                <Toggle
                  toggled={props.data.deleteOriginalPosition}
                  label={tr.lineMonitoring.deleteOriginalPositon}
                  onToggle={(_, val) => {
                    props.onPropertyChange('deleteOriginalPosition', val)
                  }}
                />
              </FormGroup>
            )}
            <FormGroup style={{ marginBottom: 0 }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => props.onSubmit?.()}
                disabled={
                  props.isFetching ||
                  (!asapSelected && !(props.data.timeSlot && props.data.timeSlot.id)) ||
                  !props.timeslots?.length
                }
              >
                {props.isEdit ? tr.lineMonitoring.ok : tr.lineMonitoring.add}
              </Button>
              {!!displayAddAndStartServiceButton && !!asapSelected && !props.isEdit && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={onSubmitAndStartService}
                  disabled={props.isFetching}
                  style={{ marginLeft: '0.5rem' }}
                >
                  {tr.lineMonitoring.addAndStartServiceButton}
                </Button>
              )}
              {renderBackButton(() => {
                if (!props.isEdit) {
                  props.onCleanTimeslots?.()
                  props.onPropertyChange('timeSlot', undefined)
                }
              })}
            </FormGroup>
          </>
        )
      }
    }
  ].filter(Boolean)

  return (
    <Paper>
      <FormWrapper>
        {stepper.length === 1 && !!stepper[0] && stepper[0]?.renderContent({ isSingleContent: true })}
        {stepper.length > 1 && (
          <Stepper activeStep={activeStep} orientation="vertical">
            {stepper.map((item, i) => {
              if (!item) {
                return null
              }

              return (
                <Step key={i}>
                  <StepLabel>
                    <StepLabelText>
                      {!!item?.passedLabel && activeStep > i ? item.passedLabel : item?.label}
                    </StepLabelText>
                  </StepLabel>
                  <StepContent>
                    {!!item?.renderPassedContent && activeStep > i ? item.renderPassedContent() : item.renderContent()}
                  </StepContent>
                </Step>
              )
            })}
          </Stepper>
        )}
      </FormWrapper>
    </Paper>
  )
}

export default PositionEdit
