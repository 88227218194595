import { CSSProperties, useState, ReactNode } from 'react'

type Props = {
  style?: CSSProperties
  renderButton: (setShow: (val: boolean) => void) => ReactNode
  children?: ReactNode
}

function ShowChildren(props: Props) {
  const { renderButton, children, style } = props
  const [show, setShow] = useState(false)

  if (!show) {
    return <div style={style}>{renderButton(setShow)}</div>
  }

  return <div style={style}>{children}</div>
}

export { ShowChildren }
