import { PositionType } from 'shared/models'
import { ISOlanguagesSupported } from 'shared/utils/iso-lang-supported'

const positionTypesForEditing = [
  PositionType.Appointment,
  PositionType.MobileApp,
  PositionType.ScreenCall,
  PositionType.Break,
  PositionType.PaperTicket,
  PositionType.WebFrame
]

const canEditWithType = (type: PositionType) => positionTypesForEditing.includes(type)

const defaultRequestPersonalInfo = {
  companyName: false,
  email: false,
  firstName: true,
  lastName: true,
  phoneNumber: true
}

const videoContainerStyle = {
  width: '100%',
  overflow: 'hidden',
  position: 'relative',
  paddingTop: 0
}

function getSupportedLanguages(shopSupportedLanguages) {
  if (!shopSupportedLanguages) {
    return ['en', 'fr']
  }

  const languages = shopSupportedLanguages
    .toLowerCase()
    .split(';')
    .filter((sl) => ISOlanguagesSupported.includes(sl))

  return languages.length ? languages : ['en', 'fr']
}

export { canEditWithType, defaultRequestPersonalInfo, videoContainerStyle, getSupportedLanguages }
