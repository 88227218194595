import { useMemo } from 'react'
import { groupByTimePeriod } from 'shared/utils/time'
import { StaffManagementItem } from './staff-management-item'
import { StaffManagmentListProps } from './types'

function StaffManagmentList(props: StaffManagmentListProps) {
  const { collection, onItemClick, autofocusId } = props

  const groupedByDay = useMemo(() => groupByTimePeriod(collection, 'startDate', 'day'), [collection])

  return (
    <>
      {Object.keys(groupedByDay).map((dayMs) => (
        <StaffManagementItem
          key={dayMs}
          items={groupedByDay[dayMs]}
          dayMs={Number(dayMs)}
          autofocusId={autofocusId}
          onItemClick={onItemClick}
          lineId={props.lineId}
          shopId={props.shopId}
          onRefresh={props.onRefresh}
        />
      ))}
    </>
  )
}

export { StaffManagmentList }
