import { CSSProperties } from 'react'

type Props = {
  width?: number
  height?: number
  className?: string
  style?: CSSProperties
}

function JeFileLogoFr(props: Props) {
  const { width = 256, height = 256, className, style } = props

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 750.25 406.84"
      width={width}
      height={height}
      className={className}
      style={style}
    >
      <g>
        <g>
          <path
            fill="#ED1652"
            d="M128.51,229.59H75.17v111.54c0.04,2.02,0.04,4.37-0.04,7.11c-0.12,2.71-0.48,5.42-1.21,8.04
		c-0.69,2.63-1.94,4.85-3.76,6.59c-1.82,1.78-4.4,2.67-7.84,2.75c-3.56-0.04-6.3-0.73-8.28-2.14c-1.98-1.42-3.4-3.31-4.24-5.7
		c-0.85-2.42-1.37-5.17-1.54-8.2c-0.2-3.07-0.28-6.3-0.24-9.66v-9.94H0v14.55c0,9.5,1.25,18.07,3.76,25.7
		c2.51,7.64,6.35,14.19,11.56,19.64c5.17,5.46,11.76,9.62,19.76,12.57c8,2.91,17.5,4.37,28.45,4.41
		c14.47,0.08,26.51-2.14,36.13-6.63c9.62-4.45,16.85-11.6,21.66-21.34c4.81-9.78,7.19-22.63,7.19-38.47V229.59L128.51,229.59z
		 M195.92,325.61c1.09-6.83,3.68-12.04,7.84-15.64c4.12-3.6,9.74-5.42,16.89-5.46c4.16,0.04,7.8,1.01,10.95,2.87
		c3.19,1.9,5.66,4.41,7.44,7.6c1.78,3.15,2.67,6.71,2.71,10.63H195.92L195.92,325.61z M287.82,350.59
		c0.04-16.37-2.43-30.27-7.44-41.71c-5.01-11.44-12.81-20.13-23.36-26.11c-10.55-5.98-24.13-9.01-40.74-9.05
		c-9.98,0.04-19.03,1.82-27.16,5.21c-8.12,3.44-15.11,8.2-20.97,14.27c-5.86,6.1-10.34,13.13-13.5,21.14
		c-3.19,8-4.77,16.61-4.81,25.82c0.04,10.51,1.86,19.8,5.38,27.97c3.52,8.12,8.45,14.99,14.83,20.61
		c6.34,5.62,13.78,9.86,22.35,12.77c8.57,2.91,17.9,4.36,28.01,4.36c7.19,0,14.14-0.81,20.81-2.38c6.71-1.62,12.93-4.04,18.67-7.32
		c5.74-3.27,10.75-7.4,15.07-12.41c4.33-4.97,7.72-10.87,10.19-17.66h-44.86c-1.33,2.02-3.07,3.68-5.21,5.01
		c-2.14,1.33-4.53,2.34-7.11,2.99c-2.59,0.65-5.17,0.97-7.8,0.97c-5.01-0.04-9.25-1.09-12.77-3.15c-3.48-2.1-6.22-4.97-8.16-8.65
		c-1.98-3.64-3.15-7.88-3.56-12.69H287.82z"
          />
          <path
            fill="#003C69"
            d="M312.82,405.87h53.34v-69.11h69.11v-41.22h-69.11v-21.58h80.5v-44.37H312.82V405.87L312.82,405.87z
		 M465.82,405.87h48.01v-129h-48.01V405.87L465.82,405.87z M513.83,229.59h-48.01v33.7h48.01V229.59L513.83,229.59z M542.2,405.87
		h48.01V229.59H542.2V405.87L542.2,405.87z M658.35,325.61c1.09-6.83,3.68-12.04,7.84-15.64c4.12-3.6,9.74-5.42,16.89-5.46
		c4.16,0.04,7.8,1.01,10.95,2.87c3.19,1.9,5.66,4.41,7.44,7.6c1.78,3.15,2.67,6.71,2.71,10.63H658.35L658.35,325.61z M750.25,350.59
		c0.04-16.37-2.43-30.27-7.44-41.71c-5.01-11.44-12.81-20.13-23.36-26.11c-10.55-5.98-24.13-9.01-40.74-9.05
		c-9.98,0.04-19.03,1.82-27.16,5.21c-8.12,3.44-15.11,8.2-20.97,14.27c-5.86,6.1-10.34,13.13-13.5,21.14
		c-3.19,8-4.77,16.61-4.81,25.82c0.04,10.51,1.86,19.8,5.38,27.97c3.52,8.12,8.45,14.99,14.83,20.61
		c6.34,5.62,13.78,9.86,22.35,12.77c8.57,2.91,17.9,4.36,28.01,4.36c7.19,0,14.14-0.81,20.81-2.38c6.71-1.62,12.93-4.04,18.67-7.32
		c5.74-3.27,10.75-7.4,15.07-12.41c4.33-4.97,7.72-10.87,10.19-17.66h-44.86c-1.33,2.02-3.07,3.68-5.21,5.01
		c-2.14,1.33-4.53,2.34-7.11,2.99c-2.59,0.65-5.17,0.97-7.8,0.97c-5.01-0.04-9.25-1.09-12.77-3.15c-3.48-2.1-6.22-4.97-8.16-8.65
		c-1.98-3.64-3.15-7.88-3.56-12.69H750.25z"
          />
        </g>
        <g>
          <path
            fill="#003C69"
            d="M178.14,175.88l64.73,0c-3.07-37.17,2.68-69.88-5.82-91.78c-16.99-43.76-49.19-57.71-56.92-76.42
			c-6.35-10.39-12.27-8.07-17.26-5.49c-28.4,14.71-20.49,15.06-43.1,27.54c-5.83,6.25-17.82,14.37-30.07,21.46
			c-8.29,4.8-16.71,9.13-23.4,12.08c1.03,11.67,7.13,10.45,18.54,5.83c6.95-2.81,12.87-6.59,18.27-7.65
			c9.52-1.87,16.27-4.98,20.36-8.91c20.91-2.13,18.52-20.7,21.13-20.81c1.17-0.04-2.69,10.54-2.45,14.92
			c0.1,1.83,9.43-10.23,10.08-10.26c1.14-0.05-7.32,12.21-7.57,13.11c-0.19,0.66,15.37-10.37,14.84-9.69
			c-12.5,16.21-29.81,20.54-45.47,20.81c-6.36,0.17-30.66,8.2-33.93,14.05c-0.66,1.36-6.5,16.8,19.63,8.75
			c2.24-0.56-0.37-9.3-3.08-11.31c3.71,0.17,5.97,7.25,5.51,11.3c4.3-0.27,8.05,0.19,12.44,0.43c4.89,0.27,16.96-7.48,32.29-1.41
			c9.29,3.68,19.87,12.15,33.32,14.76c11.3,2.2,13.62-4.01,24.05-4.69c9.91-0.64,13.39,10.23,17.96,15.75
			c-0.54,6.1-10.29-25.33-31.2-6.07C190.93,126.11,181.64,163.72,178.14,175.88z"
          />
          <path
            fill="#003C69"
            d="M373.61,175.88l64.73,0c-3.07-37.17,2.68-69.88-5.82-91.78c-16.99-43.76-49.19-57.71-56.92-76.42
			c-6.35-10.39-12.27-8.07-17.26-5.49c-28.4,14.71-20.49,15.06-43.1,27.54c-5.83,6.25-17.82,14.37-30.07,21.46
			c-8.29,4.8-16.71,9.13-23.4,12.08c1.03,11.67,7.13,10.45,18.54,5.83c6.95-2.81,12.87-6.59,18.27-7.65
			c9.52-1.87,16.27-4.98,20.36-8.91c20.91-2.13,18.52-20.7,21.13-20.81c1.17-0.04-2.69,10.54-2.45,14.92
			c0.1,1.83,9.43-10.23,10.08-10.26c1.14-0.05-7.32,12.21-7.57,13.11c-0.19,0.66,15.37-10.37,14.84-9.69
			c-12.5,16.21-29.81,20.54-45.47,20.81c-6.36,0.17-30.66,8.2-33.93,14.05c-0.66,1.36-6.5,16.8,19.63,8.75
			c2.24-0.56-0.37-9.3-3.08-11.31c3.71,0.17,5.97,7.25,5.51,11.3c4.3-0.27,8.05,0.19,12.44,0.43c4.89,0.27,16.96-7.48,32.29-1.41
			c9.29,3.68,19.87,12.15,33.32,14.76c11.3,2.2,13.62-4.01,24.05-4.69c9.91-0.64,13.39,10.23,17.96,15.75
			c-0.54,6.1-10.29-25.33-31.2-6.07C386.4,126.11,377.11,163.72,373.61,175.88z"
          />
          <path
            fill="#003C69"
            d="M569.08,175.88l64.73,0c-3.07-37.17,2.68-69.88-5.82-91.78C611,40.35,578.8,26.4,571.06,7.68
			C564.71-2.7,558.79-0.39,553.8,2.2c-28.4,14.71-20.49,15.06-43.1,27.54c-5.83,6.25-17.82,14.37-30.07,21.46
			c-8.29,4.8-16.71,9.13-23.4,12.08c1.03,11.67,7.13,10.45,18.54,5.83c6.95-2.81,12.87-6.59,18.27-7.65
			c9.52-1.87,16.27-4.98,20.36-8.91c20.91-2.13,18.52-20.7,21.13-20.81c1.17-0.04-2.69,10.54-2.45,14.92
			c0.1,1.83,9.43-10.23,10.08-10.26c1.14-0.05-7.32,12.21-7.57,13.11c-0.19,0.66,15.37-10.37,14.84-9.69
			c-12.5,16.21-29.81,20.54-45.47,20.81c-6.36,0.17-30.66,8.2-33.93,14.05c-0.66,1.36-6.5,16.8,19.63,8.75
			c2.24-0.56-0.37-9.3-3.08-11.31c3.71,0.17,5.97,7.25,5.51,11.3c4.3-0.27,8.05,0.19,12.44,0.43c4.89,0.27,16.96-7.48,32.29-1.41
			c9.29,3.68,19.87,12.15,33.32,14.76c11.3,2.2,13.62-4.01,24.05-4.69c9.91-0.64,13.39,10.23,17.96,15.75
			c-0.54,6.1-10.29-25.33-31.2-6.07C581.87,126.11,572.58,163.72,569.08,175.88z"
          />
        </g>
      </g>
    </svg>
  )
}

export default JeFileLogoFr
