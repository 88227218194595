import { useTranslation } from 'shared/i18n/translate'
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material'
import { CheckpointDisableDialogProps } from '../types'

function CheckpointDisableDialog({
  onDecline,
  onSubmit,
  open = false,
  enabledCount = 0
}: CheckpointDisableDialogProps) {
  const { tr } = useTranslation()

  const { shutDownServicePoints, servicePointsCountShutDown, close, cancel } = tr.checkpointDisableDialog

  return (
    <Dialog open={open} onClose={onDecline}>
      <DialogTitle>{shutDownServicePoints}</DialogTitle>
      <DialogContent>
        <DialogContentText>{servicePointsCountShutDown(enabledCount)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDecline} color="primary" autoFocus>
          {cancel}
        </Button>
        <Button onClick={onSubmit} color="primary">
          {close}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { CheckpointDisableDialog }
