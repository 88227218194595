import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { logout } from 'shared/auth'
import { meta } from 'shared/api'
import { Title, User, AppBar } from 'shared/ui-kit/app-bar'
import { editUser } from 'pages/nav'
import { useTranslation } from 'shared/i18n/translate'
import { changeTimeZone } from 'store/actions/user-action-creators'
import { removeUser } from 'store/actions/user-action-creators'
import { VersionItem } from './version-item'
import { VersionDialog } from './version-dialog'
import TIME from 'shared/utils/time'
import { SmartTimeZones } from './smart-time-zones'
import { Bar, Content } from './styled'
import { Breadcrumbs } from 'features/breadcrumbs'
import { useAppDispatch, useAppSelector } from 'store'
import { getUserSelector, getUserStoreSelector } from 'store/selectors/userSelectors'
import { TimeZoneModel } from 'shared/models'
import { getHideTopBatSelector } from 'store/selectors/hideTopBarSelector'
import { MainLayoutProps } from './types'
import { SatisfactionSurvey } from './satisfaction-survey'
import { useMediaQuery } from 'react-responsive'
import dataProvider from 'features/isolated-data-provider'

function MainLayout(props: MainLayoutProps) {
  const userProfile = useAppSelector(getUserSelector)
  const user = useAppSelector(getUserStoreSelector)
  const hideTopBar = useAppSelector(getHideTopBatSelector)

  const dispatch = useAppDispatch()

  const { tr } = useTranslation()

  const history = useHistory()

  const [showNewVersionDialog, setShowNewVersionDialog] = useState(false)
  const [appVersion, setAppVersion] = useState(__APP_VERSION__)
  const [isWhatsNewOpened, setIsWhatsNewOpened] = useState(false)

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  useEffect(() => {
    setTitle()
    fetchAppVersion()

    const interval = setInterval(fetchAppVersion, TIME.minMS * 30)

    return () => {
      clearInterval(interval)
    }
  }, [])

  function fetchAppVersion() {
    meta.getAppVersion().then((response) => {
      if (!response.version) {
        return
      }

      if (response.version !== appVersion) {
        setShowNewVersionDialog(true)
      }

      setAppVersion(response.version)
    })
  }

  function setTitle(nexTitle?: string) {
    const currentTitle = tr.common.title
    const isDiff = nexTitle !== currentTitle

    if (nexTitle && isDiff) {
      window.document.title = nexTitle
    } else if (!nexTitle) {
      window.document.title = tr.common.title
    }
  }

  function handleChangeTimeZone(data: TimeZoneModel) {
    dispatch(changeTimeZone(data))
  }

  function onSettings() {
    history.push(editUser(String(userProfile?.id)))
  }

  function onExit() {
    logout().finally(() => {
      dispatch(removeUser())
    })
  }

  function versionSubmit() {
    window.location.reload()
  }

  function versionDecline() {
    setShowNewVersionDialog(false)
    setTimeout(() => setShowNewVersionDialog(true), TIME.hourMS)
  }

  const open = showNewVersionDialog
  const version = appVersion

  return (
    <>
      <Bar hidden={hideTopBar}>
        <AppBar>
          {!isMobile && (
            <Title>
              {tr.common.appName}
              <VersionItem
                version={version}
                onWhatsNewDialogChanged={(opened) => {
                  setIsWhatsNewOpened(opened)
                }}
              />
            </Title>
          )}
          {!!isMobile && (
            <VersionItem
              version={version}
              onWhatsNewDialogChanged={(opened) => {
                setIsWhatsNewOpened(opened)
              }}
            />
          )}
          <Breadcrumbs />
          {!!userProfile && <SatisfactionSurvey blockDialogOpening={isWhatsNewOpened} />}
          <SmartTimeZones user={user} onChange={handleChangeTimeZone} />
          {!!userProfile && <User profile={userProfile} onSettingsTap={onSettings} onExitTap={onExit} />}
        </AppBar>
      </Bar>
      <VersionDialog open={open} onSubmit={versionSubmit} onDecline={versionDecline} version={version} />
      <Content $hideTopBar={hideTopBar}>{props.children}</Content>
    </>
  )
}

export default dataProvider(MainLayout)
