import { ReactNode, CSSProperties } from 'react'
import css from './style.module.scss'

type Props = {
  children?: ReactNode
  style?: CSSProperties
  containerStyle?: CSSProperties
  className?: string
}

function Container(props: Props) {
  return (
    <div className={css.root} style={props.style}>
      <div className={`${css.container} ${props.className}`} style={props.containerStyle}>
        {props.children}
      </div>
    </div>
  )
}

export { Container }
export default Container
