import { ValidationType } from '../types'

const validationByType: { id: ValidationType; regexp: string }[] = [
  { id: 'none', regexp: '' },
  { id: 'regexp', regexp: '' },
  { id: 'email', regexp: "^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@([a-zA-Z0-9_\\-.]+)\\.([a-zA-Z]{2,5})$" },
  {
    id: 'url',
    regexp:
      'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\\\+.~#?&//=]*)'
  }
]

export { validationByType }
