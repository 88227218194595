import tail from 'lodash/tail'
import head from 'lodash/head'
import reduce from 'lodash/reduce'

import { allCountries } from '../country_data'

export function formatNumberByPattern(text: string, pattern = '+...............') {
  if (!text || !text.length) {
    return ''
  }

  if ((text && text.length < 2) || !pattern) {
    return text
  }

  const formattedObject = reduce(
    pattern,
    (acc, character) => {
      if (acc.remainingText.length === 0) {
        return acc
      }

      if (character !== '.') {
        return {
          formattedText: acc.formattedText + character,
          remainingText: acc.remainingText
        }
      }

      return {
        formattedText: acc.formattedText + head(acc.remainingText),
        remainingText: tail(acc.remainingText)
      }
    },
    {
      formattedText: '',
      remainingText: text.split('')
    }
  )

  return formattedObject.formattedText
}

export function formatNumber(phoneNumber?: string) {
  const rawNumber = phoneNumber?.replace(/[^*\d]/g, '')

  if (!rawNumber?.length) return ''

  let findCountries: any[] = []

  const findFourSym = allCountries.filter((el) => el.dialCode === rawNumber.slice(0, 4))
  const findThreeSym = allCountries.filter((el) => el.dialCode === rawNumber.slice(0, 3))
  const findTwoSym = allCountries.filter((el) => el.dialCode === rawNumber.slice(0, 2))
  const findOneSym = allCountries.filter((el) => el.dialCode === rawNumber.slice(0, 1))

  if (findFourSym.length) findCountries = findFourSym
  else if (findThreeSym.length) findCountries = findThreeSym
  else if (findTwoSym.length) findCountries = findTwoSym
  else if (findOneSym.length) findCountries = findOneSym

  if (!findCountries.length) return phoneNumber

  return formatNumberByPattern(String(rawNumber), findCountries[0]?.format)
}

export function getHiddenNumber(phone?: string) {
  if (!phone) return undefined

  const prefixNumber = phone?.slice(0, 3)

  const countAfterPrefix = (phone?.length || 0) - 5

  const bodyNumber = phone && new Array(countAfterPrefix > 0 ? countAfterPrefix : 0).fill('*').join('')
  const suffixNumber = phone?.slice(phone.length - 2, phone.length)

  if (phone?.length < 8) return ''

  return formatNumber(`${prefixNumber}${bodyNumber}${suffixNumber}`)
}
