import { CSSProperties } from 'react'

type Props = {
  width?: number
  height?: number
  className?: string
  style?: CSSProperties
}

function JeFileLogoEn(props: Props) {
  const { width = 256, height = 256, className, style } = props

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 3120 1010"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ ...style, padding: '4px', boxSizing: 'border-box' }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1011.53 552.983H1215.03C1205.36 436.123 1223.44 333.28 1196.72 264.441C1143.31 126.863 1042.07 82.9984 1017.76 24.1598C997.799 -8.4912 979.174 -1.22557 963.486 6.90464C874.195 53.1703 899.07 54.2432 827.965 93.4776C809.622 113.123 771.924 138.66 733.429 160.941C707.356 176.03 680.908 189.639 659.856 198.915C663.085 235.613 682.262 231.759 718.153 217.254C739.997 208.415 758.611 196.545 775.601 193.207C805.523 187.327 826.741 177.54 839.601 165.207C905.34 158.504 897.825 100.118 906.038 99.7901C909.705 99.6494 897.58 132.925 898.34 146.681C898.653 152.425 927.991 114.53 930.033 114.436C933.616 114.269 907.023 152.821 906.226 155.67C905.648 157.748 954.554 123.056 952.892 125.207C913.606 176.186 859.158 189.79 809.945 190.618C789.934 191.139 713.538 216.41 703.283 234.785C701.215 239.077 682.84 287.597 764.997 262.29C772.054 260.54 763.825 233.045 755.304 226.743C766.976 227.28 774.085 249.54 772.648 262.274C786.158 261.415 797.955 262.863 811.757 263.629C827.142 264.478 865.08 240.108 913.283 259.191C942.486 270.748 975.757 297.389 1018.04 305.608C1053.59 312.519 1060.87 292.988 1093.65 290.873C1124.81 288.863 1135.76 323.019 1150.12 340.399C1148.43 359.577 1117.78 260.764 1052.03 321.316C1051.74 396.483 1022.52 514.733 1011.53 552.983Z"
        fill="#093660"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1626.09 552.983H1829.59C1819.92 436.123 1838.01 333.28 1811.29 264.441C1757.88 126.863 1656.64 82.9984 1632.32 24.1598C1612.36 -8.4912 1593.74 -1.22557 1578.05 6.90464C1488.76 53.1703 1513.63 54.2432 1442.53 93.4776C1424.18 113.123 1386.49 138.66 1347.99 160.941C1321.92 176.03 1295.47 189.639 1274.42 198.915C1277.65 235.613 1296.83 231.759 1332.72 217.254C1354.56 208.415 1373.18 196.545 1390.16 193.207C1420.09 187.327 1441.31 177.54 1454.16 165.207C1519.9 158.504 1512.38 100.118 1520.59 99.7901C1524.27 99.6494 1512.14 132.925 1512.9 146.681C1513.22 152.425 1542.55 114.53 1544.6 114.436C1548.18 114.269 1521.59 152.821 1520.79 155.67C1520.21 157.748 1569.12 123.056 1567.46 125.207C1528.17 176.186 1473.72 189.79 1424.51 190.618C1404.5 191.139 1328.1 216.41 1317.85 234.785C1315.78 239.077 1297.4 287.597 1379.56 262.29C1386.61 260.54 1378.39 233.045 1369.87 226.743C1381.54 227.28 1388.65 249.54 1387.21 262.274C1400.73 261.415 1412.51 262.863 1426.31 263.629C1441.7 264.478 1479.64 240.108 1527.85 259.191C1557.05 270.748 1590.32 297.389 1632.61 305.608C1668.14 312.519 1675.43 292.988 1708.22 290.873C1739.37 288.863 1750.32 323.019 1764.68 340.399C1763 359.577 1732.35 260.764 1666.59 321.316C1666.3 396.483 1637.09 514.733 1626.09 552.983Z"
        fill="#093660"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2240.66 552.983H2444.15C2434.49 436.123 2452.57 333.28 2425.85 264.441C2372.44 126.863 2271.21 82.9984 2246.89 24.1598C2226.92 -8.4912 2208.3 -1.22557 2192.62 6.90464C2103.32 53.1703 2128.2 54.2432 2057.1 93.4776C2038.75 113.123 2001.05 138.66 1962.55 160.941C1936.49 176.03 1910.03 189.639 1888.99 198.915C1892.22 235.613 1911.39 231.759 1947.28 217.254C1969.13 208.415 1987.74 196.545 2004.73 193.207C2034.65 187.327 2055.87 177.54 2068.73 165.207C2134.47 158.504 2126.96 100.118 2135.16 99.7901C2138.84 99.6494 2126.71 132.925 2127.47 146.681C2127.78 152.425 2157.12 114.53 2159.16 114.436C2162.75 114.269 2136.15 152.821 2135.35 155.67C2134.77 157.748 2183.68 123.056 2182.02 125.207C2142.73 176.186 2088.29 189.79 2039.07 190.618C2019.06 191.139 1942.66 216.41 1932.41 234.785C1930.35 239.077 1911.97 287.597 1994.13 262.29C2001.18 260.54 1992.96 233.045 1984.43 226.743C1996.11 227.28 2003.21 249.54 2001.77 262.274C2015.29 261.415 2027.08 262.863 2040.88 263.629C2056.27 264.478 2094.21 240.108 2142.41 259.191C2171.62 270.748 2204.88 297.389 2247.17 305.608C2282.72 312.519 2290 292.988 2322.78 290.873C2353.93 288.863 2364.89 323.019 2379.25 340.399C2377.56 359.577 2346.91 260.764 2281.16 321.316C2280.87 396.483 2251.65 514.733 2240.66 552.983Z"
        fill="#093660"
      />
      <path
        d="M104.062 759.035H123.917C152.427 759.035 172.417 764.921 183.875 776.681C195.344 788.431 201.083 810.483 201.083 842.827C201.083 872.218 194.76 893.822 182.125 907.64C169.484 921.463 150.818 928.369 126.125 928.369H104.062V759.035ZM136.708 1002.45C162.859 1002.45 186.229 999.369 206.812 993.202C227.391 987.025 245.031 977.322 259.729 964.098C274.432 950.864 285.677 933.739 293.458 912.723C301.245 891.697 305.146 866.483 305.146 837.077C305.146 785.926 290.661 747.785 261.708 722.66C232.76 697.525 191.974 684.952 139.354 684.952H0V1002.45H136.708Z"
        fill="#ED114F"
      />
      <path
        d="M586.936 771.369H495.207V882.515C495.207 901.322 492.556 915.291 487.27 924.411C481.978 933.525 473.009 938.077 460.374 938.077C448.915 938.077 440.535 934.921 435.249 928.598C429.957 922.27 427.311 911.463 427.311 896.181V771.369H335.582V912.056C335.582 944.39 342.926 968.65 357.624 984.827C372.327 1000.99 392.613 1009.08 418.478 1009.08C438.181 1009.08 454.212 1006.13 466.561 1000.26C478.905 994.379 490.665 983.786 501.832 968.494V1002.45H586.936V771.369Z"
        fill="#ED114F"
      />
      <path
        d="M881.069 859.577C879.595 843.124 875.106 828.796 867.611 816.598C860.122 804.395 850.575 794.468 838.965 786.827C827.351 779.176 814.335 773.588 799.924 770.056C785.517 766.53 770.377 764.765 754.507 764.765C735.392 764.765 717.752 767.562 701.59 773.14C685.424 778.723 671.455 786.884 659.694 797.619C647.944 808.358 638.83 821.442 632.361 836.869C625.887 852.301 622.653 869.718 622.653 889.119C622.653 908.233 625.955 925.291 632.569 940.285C639.189 955.275 648.455 967.838 660.361 977.973C672.262 988.114 686.226 995.838 702.257 1001.14C718.283 1006.43 735.7 1009.08 754.507 1009.08C770.976 1009.08 786.486 1007.3 801.028 1003.76C815.58 1000.24 828.45 994.801 839.632 987.452C851.668 979.525 861.444 969.317 868.944 956.827C876.439 944.332 881.075 929.697 882.84 912.931H790.236C788.762 923.223 785.231 931.53 779.653 937.848C774.069 944.171 765.684 947.327 754.507 947.327C741.283 947.327 731.288 942.181 724.528 931.89C717.762 921.603 714.382 907.489 714.382 889.556C714.382 881.629 714.892 874.134 715.924 867.077C716.95 860.025 718.934 853.41 721.882 847.244C724.825 841.067 728.944 836.067 734.236 832.244C739.523 828.416 746.283 826.494 754.507 826.494C764.793 826.494 772.736 829.588 778.319 835.765C784.497 841.64 787.736 849.582 788.028 859.577H881.069Z"
        fill="#ED114F"
      />
      <path
        d="M1034.97 920.431L1084.37 1002.45H1194.62L1098.04 860.89L1183.58 771.369H1076.43L1017.79 841.931V684.952H926.057V1002.45H1017.79V937.619L1034.97 920.431Z"
        fill="#ED114F"
      />
      <path d="M1210.48 768.285H1303.98V1002.45H1408.04V768.285H1501.52V684.952H1210.48V768.285Z" fill="#093660" />
      <path
        d="M1536.37 1002.45H1628.1V884.702C1628.1 868.832 1630.74 856.926 1636.04 848.994C1641.32 841.051 1650.58 837.077 1663.81 837.077C1675.86 837.077 1684.23 840.978 1688.93 848.765C1693.63 856.556 1695.99 867.072 1695.99 880.306V1002.45H1787.72V863.994C1787.72 831.65 1780.3 807.025 1765.45 790.119C1750.6 773.218 1728.19 764.765 1698.2 764.765C1683.21 764.765 1670.21 767.713 1659.18 773.598C1648.15 779.473 1637.79 788.442 1628.1 800.494V684.952H1536.37V1002.45Z"
        fill="#093660"
      />
      <path
        d="M1914.73 860.89C1914.73 848.254 1918.47 838.405 1925.96 831.348C1933.46 824.296 1943.97 820.765 1957.5 820.765C1968.37 820.765 1977.86 824.811 1985.94 832.89C1994.02 840.973 1998.06 850.311 1998.06 860.89H1914.73ZM2089.79 907.64C2089.79 886.478 2086.7 867.072 2080.52 849.431C2074.36 831.796 2065.32 816.655 2053.42 804.015C2041.51 791.379 2027.18 781.676 2010.42 774.91C1993.67 768.15 1974.85 764.765 1953.98 764.765C1935.45 764.765 1918.25 767.634 1902.38 773.369C1886.5 779.093 1872.61 787.468 1860.71 798.494C1848.8 809.525 1839.54 822.759 1832.92 838.202C1826.3 853.634 1823 870.608 1823 889.119C1823 907.942 1826.3 924.853 1832.92 939.848C1839.54 954.838 1848.8 967.478 1860.71 977.765C1872.61 988.046 1886.57 995.838 1902.59 1001.14C1918.61 1006.43 1935.89 1009.08 1954.42 1009.08C1968.53 1009.08 1982.27 1007.67 1995.65 1004.87C2009.02 1002.08 2021.59 997.744 2033.36 991.869C2045.12 985.999 2055.26 978.134 2063.77 968.285C2072.3 958.426 2078.47 946.593 2082.29 932.785H1994.54C1991.3 939.535 1986.67 944.608 1980.65 947.994C1974.62 951.369 1967.78 953.056 1960.15 953.056C1947.8 953.056 1937.14 948.801 1928.17 940.285C1919.21 931.759 1914.73 920.879 1914.73 907.64H2089.79Z"
        fill="#093660"
      />
      <path d="M2383.93 1002.45V928.369H2241.05V684.952H2136.99V1002.45H2383.93Z" fill="#093660" />
      <path
        d="M2516.66 1002.45V771.369H2424.94V1002.45H2516.66ZM2424.94 741.827H2516.66V676.577H2424.94V741.827Z"
        fill="#093660"
      />
      <path
        d="M2565.62 1002.45H2657.34V884.702C2657.34 868.832 2659.99 856.926 2665.28 848.994C2670.57 841.051 2679.83 837.077 2693.05 837.077C2705.1 837.077 2713.48 840.978 2718.18 848.765C2722.88 856.556 2725.24 867.072 2725.24 880.306V1002.45H2816.97V863.994C2816.97 831.65 2809.54 807.025 2794.7 790.119C2779.85 773.218 2757.43 764.765 2727.45 764.765C2710.7 764.765 2696.21 768.296 2684.01 775.348C2671.81 782.405 2660.28 793.134 2649.41 807.535V771.369H2565.62V1002.45Z"
        fill="#093660"
      />
      <path
        d="M2943.98 860.89C2943.98 848.254 2947.72 838.405 2955.21 831.348C2962.7 824.296 2973.22 820.765 2986.75 820.765C2997.62 820.765 3007.1 824.811 3015.19 832.89C3023.26 840.973 3027.31 850.311 3027.31 860.89H2943.98ZM3119.04 907.64C3119.04 886.478 3115.95 867.072 3109.77 849.431C3103.6 831.796 3094.57 816.655 3082.67 804.015C3070.76 791.379 3056.43 781.676 3039.67 774.91C3022.92 768.15 3004.1 764.765 2983.23 764.765C2964.7 764.765 2947.49 767.634 2931.62 773.369C2915.75 779.093 2901.86 787.468 2889.96 798.494C2878.05 809.525 2868.78 822.759 2862.17 838.202C2855.55 853.634 2852.25 870.608 2852.25 889.119C2852.25 907.942 2855.55 924.853 2862.17 939.848C2868.78 954.838 2878.05 967.478 2889.96 977.765C2901.86 988.046 2915.82 995.838 2931.83 1001.14C2947.86 1006.43 2965.13 1009.08 2983.67 1009.08C2997.77 1009.08 3011.52 1007.67 3024.89 1004.87C3038.26 1002.08 3050.84 997.744 3062.6 991.869C3074.36 985.999 3084.5 978.134 3093.02 968.285C3101.55 958.426 3107.72 946.593 3111.54 932.785H3023.79C3020.55 939.535 3015.92 944.608 3009.89 947.994C3003.86 951.369 2997.03 953.056 2989.39 953.056C2977.05 953.056 2966.38 948.801 2957.42 940.285C2948.46 931.759 2943.98 920.879 2943.98 907.64H3119.04Z"
        fill="#093660"
      />
    </svg>
  )
}

export default JeFileLogoEn
