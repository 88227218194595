import { TextField } from 'shared/ui-kit/text-field'
import { IconButton } from '@mui/material'
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Delete as RemoveIcon
} from '@mui/icons-material'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { ChecklistAction, ChecklistContainer, ChecklistTitle, ImpactContainer } from './styled'
import { useRef, useState } from 'react'
import { useDrag, useDrop, DragSourceMonitor } from 'react-dnd'
import type { Identifier, XYCoord } from 'dnd-core'
import Toggle from 'shared/ui-kit/toggle-wrap'
import Dropdown from 'shared/ui-kit/dropdown'
import { useTranslation } from 'shared/i18n/translate'
import { Button } from '@mui/material'
import { CheckListItem, ImpactOnServiceType } from 'shared/models'
import { InputTranslation } from 'features/input-translation'

interface DragItem {
  index: number
  id: string
  type: string
}

const ImpactOnServiceValues: ImpactOnServiceType[] = ['None', 'Value', 'Precentage']

type Props = {
  moveCard: (dragIndex: number, hoverIndex: number) => void
  index: number
  id: string | number
  checkListItem: CheckListItem
  setChecklistItem: (item: CheckListItem) => void
  onDeleteItem: () => void
  brandId?: string
  shopId?: string
}

export default function ChecklistItem({
  id,
  index,
  moveCard,
  checkListItem,
  setChecklistItem,
  onDeleteItem,
  brandId,
  shopId
}: Props) {
  const { tr } = useTranslation()
  const [moreSettingsDialog, setMoreSettingsDialog] = useState(false)
  const dragRef = useRef<HTMLDivElement>(null)

  const {
    secondsHint,
    name,
    shortName,
    mandatory,
    impactOnServiceTime,
    impactMode,
    impactPrecentage,
    impactValue,
    impactNone
  } = tr.lineEdit
  const { deleteButton } = tr.entityEdit

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'checklist',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover(item: DragItem, monitor) {
      if (!dragRef.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = dragRef.current?.getBoundingClientRect()

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      const clientOffset = monitor.getClientOffset()

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveCard(dragIndex, hoverIndex)
      item.index = hoverIndex
    }
  })

  function handleMoreSettings() {
    setMoreSettingsDialog((curr) => !curr)
  }

  function handleNameChange(value: string) {
    const newCheckItem = { ...checkListItem }
    newCheckItem.name = value
    setChecklistItem(newCheckItem)
  }

  function handleShortNameChange(value: string) {
    const newCheckItem = { ...checkListItem }
    newCheckItem.shortName = value
    setChecklistItem(newCheckItem)
  }

  function handleMandatoryChange() {
    const newCheckItem = { ...checkListItem }
    newCheckItem.mandatory = !newCheckItem.mandatory
    setChecklistItem(newCheckItem)
  }

  function handleImpactOnServiceType(value: ImpactOnServiceType) {
    const newCheckItem = { ...checkListItem }
    newCheckItem.impactOnServiceType = value
    newCheckItem.impactOnServiceValue = undefined
    setChecklistItem(newCheckItem)
  }

  function handleImpactOnServiceValue(value: string) {
    const newCheckItem = { ...checkListItem }
    newCheckItem.impactOnServiceValue = String(value) === '' ? undefined : Number(value)
    setChecklistItem(newCheckItem)
  }

  function handleDeleteItem() {
    onDeleteItem()
  }

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'checklist',
    item: () => ({ id, index }),
    collect: (monitor: DragSourceMonitor) => ({ isDragging: monitor.isDragging() })
  })

  preview(drop(dragRef))

  const modeTextMap: Record<ImpactOnServiceType, string> = {
    Precentage: impactPrecentage,
    Value: impactValue,
    None: impactNone
  }

  const opacity = isDragging ? 0 : 1

  return (
    <ChecklistContainer
      style={{
        background: moreSettingsDialog && !isDragging ? '#f9f8f8' : '#FFF',
        margin: moreSettingsDialog && !isDragging ? '1rem 0' : 'unset'
      }}
    >
      <ChecklistTitle style={{ opacity }} ref={dragRef}>
        <InputTranslation
          inputValue={checkListItem.name}
          setInputValue={handleNameChange}
          brandId={brandId}
          placeId={shopId}
        >
          <TextField
            style={{ flex: 1 }}
            label={name}
            placeholder={name}
            value={checkListItem.name}
            onChange={handleNameChange}
          />
        </InputTranslation>
        <TextField
          label={shortName}
          placeholder={shortName}
          value={checkListItem.shortName}
          onChange={handleShortNameChange}
        />
        <ChecklistAction>
          <div ref={drag} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <DragIndicatorIcon style={{ opacity: '0.2', cursor: 'move' }} data-handler-id={handlerId} />
          </div>
          <IconButton size="small" onClick={handleMoreSettings}>
            {moreSettingsDialog ? <KeyboardArrowUpIcon fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small" />}
          </IconButton>
        </ChecklistAction>
      </ChecklistTitle>
      {moreSettingsDialog && !isDragging && (
        <div style={{ padding: '0.5rem' }}>
          <Toggle
            toggled={checkListItem.mandatory}
            label={mandatory}
            onToggle={handleMandatoryChange}
            withHelper={true}
            tag="line"
            tooltipKey="checkListItem_Mandatory"
          />
          <ImpactContainer>
            <div style={{ flex: 1 }}>{impactOnServiceTime}</div>
            <div style={{ display: 'flex', gap: '1rem' }}>
              <Dropdown
                style={{ minWidth: 'auto', width: '120px' }}
                ensureValue
                label={impactMode}
                value={checkListItem.impactOnServiceType || 'None'}
                data={ImpactOnServiceValues}
                dataConverter={(item) => ({
                  value: item,
                  text: modeTextMap[item]
                })}
                onChange={handleImpactOnServiceType}
              />
              {checkListItem.impactOnServiceType === 'Precentage' && (
                <TextField
                  style={{ width: '100px' }}
                  label={impactPrecentage}
                  placeholder={impactPrecentage}
                  value={checkListItem.impactOnServiceValue ? String(checkListItem.impactOnServiceValue) : ''}
                  onChange={handleImpactOnServiceValue}
                  hint={() => '%'}
                  type="number"
                  test={(v) => Number(v) < 101 && Number(v) > -101}
                />
              )}
              {checkListItem.impactOnServiceType === 'Value' && (
                <TextField
                  style={{ width: '100px' }}
                  label={secondsHint(100)}
                  placeholder={secondsHint(100)}
                  value={checkListItem.impactOnServiceValue ? String(checkListItem.impactOnServiceValue) : ''}
                  type="number"
                  onChange={handleImpactOnServiceValue}
                  hint={secondsHint}
                />
              )}
            </div>
          </ImpactContainer>
          <Button
            variant="outlined"
            size="small"
            onClick={handleDeleteItem}
            startIcon={<RemoveIcon fontSize="small" />}
          >
            {deleteButton}
          </Button>
        </div>
      )}
    </ChecklistContainer>
  )
}
