import { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'shared/i18n/translate'
import Appointment from './appointment'
import { createAppointment, createAppointmentLine, editAppointment, createAppointmentFromService } from 'pages/nav'
import { appointments as appointmentsApi, line as lineApi } from 'shared/api'
import { Divider, Accordion, Typography, AccordionSummary, AccordionDetails } from '@mui/material'
import { Domain as NoPlaceIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { setBreadcrumbs, showMessage, MessageTypes } from 'store/actions/main-layout-action-creators'
import AppointmentsFilterLargeLayout from './appointments-filter-large-layout'
import AppointmentsFilterSmallLayout from './appointments-filter-small-layout'
import EmptyScreen from 'shared/ui-kit/empty-screen/empty-screen'
import { groupByTimePeriod } from 'shared/utils/time'
import { AppointmentsSkeleton } from './components/appointments-skeleton'
import { AppointmentModel, AppointmentState } from 'shared/models'
import TimerIcon from '@mui/icons-material/Timer'
import { AppointmentStatus } from 'shared/models'
import { useTimeZoneFilter } from 'features/time-zone-filter'
import { useMediaQuery } from 'react-responsive'
import { useDataProvider } from 'features/isolated-data-provider'
import { useAppDispatch } from 'store'
import { useQuery } from 'react-query'
import { AppointmentFilters } from './types'
import { defaultFilters, filtersStorageKey } from './utils'

function Appointments({ cancelMode }: { cancelMode?: boolean }) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const history = useHistory()
  const params = useParams<{ placeId?: string; lineId?: string }>()
  const { tr, timeZoneOffset, dateFormatter } = useTranslation()
  const dispatch = useAppDispatch()

  useTimeZoneFilter(params.placeId)

  const { places } = useDataProvider()

  const [firstFetch, setFirstFetch] = useState(true)
  const [fetching, setFetching] = useState(false)
  const [appointments, setAppointments] = useState<AppointmentModel[]>([])
  const [busyAppointments, setBusyAppointments] = useState<Record<string | number, boolean>>({})

  const [filter, setFilter] = useState<AppointmentFilters>(
    JSON.parse(sessionStorage.getItem(filtersStorageKey) || 'null') || defaultFilters
  )

  const { data: linesRes, isLoading: linesFetching } = useQuery(
    ['lines', filter.shopId],
    () => lineApi.getLineListSimplified(filter.shopId as string),
    {
      enabled: !!filter.shopId,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false
    }
  )

  useEffect(() => {
    const { placeId, lineId } = params

    if (placeId && lineId) {
      const place = places && places.find((x) => String(x.id) === placeId)
      const line = linesRes?.data && linesRes.data.find((x) => String(x.id) === String(lineId))

      if (place && line) {
        dispatch(setBreadcrumbs(getBreadcrumbs()))
      }
    } else {
      dispatch(setBreadcrumbs(getBreadcrumbs()))
    }
  }, [linesRes, tr])

  useEffect(() => {
    sessionStorage.setItem(filtersStorageKey, JSON.stringify(filter))
  }, [filter])

  useEffect(() => {
    if (params.placeId && params.lineId) {
      setFilter((curr) => ({ ...curr, shopId: params.placeId as string, lineId: params.lineId as string }))
    }
  }, [])

  function editFilterState(key: string, value: any) {
    setFilter((draft) => {
      const newFilter = { ...draft }

      newFilter[key] = value

      if (key === 'startDate' && value > filter.endDate) {
        newFilter.endDate = value
      }

      if (key === 'endDate' && value < filter.startDate) {
        newFilter.startDate = value
      }

      return newFilter
    })
  }

  function getBreadcrumbs() {
    const crumb = getCrumbs(tr.breadcrumbs)

    const { placeId, lineId } = params

    if (placeId && lineId && places && linesRes?.data) {
      const place = places.find((x) => String(x.id) === placeId)
      const line = linesRes.data.find((x) => String(x.id) === String(lineId))

      const placeName = place ? place.name : ''
      const lineName = line ? line.name : ''

      return [
        crumb.home(),
        crumb.places(),
        crumb.place([placeId], placeName),
        crumb.lines([placeId]),
        crumb.line([placeId, lineId], lineName),
        cancelMode ? crumb.appointmentsToCancel([placeId, lineId]) : crumb.appointments()
      ]
    }

    return [crumb.home(), crumb.appointments()]
  }

  function fetchData(soft: boolean = false) {
    if (filter.shopId && filter.lineId) {
      if (!soft) {
        setFetching(true)
      }

      appointmentsApi
        .getAppointments({
          shopId: filter.shopId,
          lineId: filter.lineId,
          startDate: filter.startDate,
          endDate: filter.endDate,
          term: filter.term,
          status: cancelMode ? AppointmentStatus.ToBeCancelled : filter.status
        })
        .then((resp) => {
          setAppointments(resp.data)
          setFetching(false)
          setFirstFetch(false)
        })
        .catch(() => {
          setFetching(false)
        })
    } else {
      setFetching(false)
    }
  }

  function softRefetch() {
    fetchData(true)
  }

  function handleSearch() {
    fetchData()
  }

  function handleDownload() {
    const { startDate, endDate, term, shopId, lineId, onlyActive } = filter

    appointmentsApi.downloadAppointments({ shopId, lineId, startDate, endDate, term, onlyActive })
  }

  function handleCreateFromService() {
    if (params.placeId && params.lineId) {
      const q = new URLSearchParams({ shopId: params.placeId, lineId: params.lineId })
      history.push(`${createAppointmentFromService()}?${q}`)
    } else {
      history.push(createAppointmentFromService())
    }
  }

  function handleCreateClick() {
    if (params.placeId && params.lineId) {
      history.push(createAppointmentLine(params.placeId, params.lineId))
    } else {
      history.push(createAppointment())
    }
  }

  function handleRemove(id: string | number) {
    appointmentsApi
      .deleteAppointment(id)
      .then(() => {
        dispatch(showMessage(tr.appointmentOperationMessages.deleteSuccess, MessageTypes.Success))
        setAppointments((a) => a.filter((a) => a.id !== id))
      })
      .catch(() => {
        dispatch(showMessage(tr.appointmentOperationMessages.deleteError, MessageTypes.Error))
      })
  }

  function handleEdit(id: string | number, placeId: string | number, lineId: string | number) {
    history.push(
      `${editAppointment(id as string)}?${new URLSearchParams({ shopId: placeId as string, lineId: lineId as string })}`
    )
  }

  function handleConfirm(id: string | number, cb?: () => void) {
    appointmentsApi
      .confirmAppointment(id)
      .then(() => softRefetch())
      .finally(() => cb?.())
  }

  if (!places.length) {
    return <EmptyScreen text={tr.appointments.noPlaceAvailable} iconClass={NoPlaceIcon} />
  }

  const AppointmentsLayout = !isMobile ? AppointmentsFilterLargeLayout : AppointmentsFilterSmallLayout

  const groupEl = groupByTimePeriod(
    (appointments || []).filter(
      (el) => !el.appointmentState || el.appointmentState !== AppointmentState.Deleted || !filter.onlyActive
    ),
    'time.startTime',
    'day',
    timeZoneOffset || 0
  )

  const availableLines = useMemo(() => {
    return (linesRes?.data || []).filter((x) => x.allowFutureAppointments)
  }, [linesRes])

  return (
    <AppointmentsLayout
      shopId={params.placeId}
      lineId={params.lineId}
      filter={filter}
      lines={availableLines}
      shops={places}
      onDownload={handleDownload}
      onFilterChange={editFilterState}
      onCreate={handleCreateClick}
      onCreateFromService={handleCreateFromService}
      shopFetching={false}
      lineFetching={linesFetching}
      onSearch={handleSearch}
      cancelMode={cancelMode}
    >
      {(fetching || !appointments) && <AppointmentsSkeleton />}
      {!fetching && !!appointments && Object.keys(groupEl).length === 0 && (
        <EmptyScreen
          text={firstFetch ? tr.appointments.emptyTermMessage : tr.appointments.emptyMessage}
          iconClass={TimerIcon}
        />
      )}
      {!fetching &&
        !!appointments &&
        Object.keys(groupEl)
          .sort()
          .map((date) => (
            <Accordion key={date}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ height: '4rem' }}>
                <Typography>
                  {`${dateFormatter(
                    date,
                    { weekday: 'short', day: 'numeric', month: 'long' },
                    'UTC'
                  )} - ${tr.appointments.appointment(groupEl[date].length)}`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ flexDirection: 'column', padding: '0' }}>
                {groupEl[date].map((item, index) => (
                  <div style={{ width: '100%' }} key={index}>
                    <Divider />
                    <Appointment
                      data={item}
                      onEdit={handleEdit}
                      onConfirm={(id) => {
                        setBusyAppointments((curr) => ({ ...curr, [id]: true }))
                        handleConfirm(id, () => {
                          setBusyAppointments((curr) => ({ ...curr, [id]: false }))
                        })
                      }}
                      onRemove={(id) => {
                        setBusyAppointments((curr) => ({ ...curr, [id]: true }))
                        handleRemove(id)
                      }}
                      hidePlaceName={!!filter.shopId}
                      hideLineName={!!filter.lineId}
                      disabled={!!busyAppointments[item.id]}
                      softRefetch={softRefetch}
                    />
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
    </AppointmentsLayout>
  )
}

export default Appointments
