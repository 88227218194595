import { useMemo, ReactNode } from 'react'
import OfflineIcon from '@mui/icons-material/PortableWifiOff'
import { DistanceIcon } from 'shared/ui-kit/icons'
import { useTranslation } from 'shared/i18n/translate'
import { PositionModel } from 'shared/models'
import { InfoWithIcon } from '../styled'

type Props = {
  data: PositionModel
  children?: ReactNode
}

function MobileInfoStatsComponent({ data, children }: Props) {
  const { tr, dateFormatter } = useTranslation()

  const { distance, offlineTime_unixtime } = data

  const distanceAdapt = useMemo(() => {
    if (!distance) {
      return null
    }

    const { m, km } = tr.shortUnits
    const kmValue = +distance / 1000

    if (kmValue < 1) {
      return `${distance}${m}`
    }

    if (kmValue < 10) {
      return `${kmValue.toFixed(1)}${km}`
    }

    return `${Math.round(kmValue)}${km}`
  }, [distance])

  const offlineTimeAdapt = useMemo(() => {
    if (!offlineTime_unixtime) {
      return null
    }

    return dateFormatter(offlineTime_unixtime, { hour: 'numeric', minute: '2-digit', hour12: false })
  }, [offlineTime_unixtime])

  return (
    <>
      {!!distanceAdapt && (
        <InfoWithIcon tooltipContent={tr.position.tooltips.distanceToPlace} icon={DistanceIcon}>
          {distanceAdapt}
        </InfoWithIcon>
      )}
      {!!offlineTimeAdapt && (
        <InfoWithIcon tooltipContent={tr.position.tooltips.mobileClientOffline} icon={OfflineIcon}>
          {tr.monitoring.offlineTime(offlineTimeAdapt)}
        </InfoWithIcon>
      )}
      {children}
    </>
  )
}

export default MobileInfoStatsComponent
export const MobileInfoStats = MobileInfoStatsComponent
