import axios from 'axios'
import { NotificationsAPI } from '../models'

const notifications: NotificationsAPI = {
  notificationSubscribe(data, config) {
    return axios.post('notificationSubscribe', data, config)
  },
  notificationUnsubscribe(data, config) {
    return axios.post('notificationUnsubscribe', data, config)
  },
  updatePushToken(data, config) {
    return axios.post('updatePushToken', data, config)
  }
}

export default notifications
