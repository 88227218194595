import Avatar from '@mui/material/Avatar'
import { lineCategory } from '../../line-category'
import { LineModelSimplified } from 'shared/models'
import { LineListMonitoring } from './line-list-monitoring'
import { LineListControls } from './line-list-controls'
import TimeIcon from '@mui/icons-material/AccessTime'
import DonutLargeIcon from '@mui/icons-material/DonutLarge'
import PersonIcon from '@mui/icons-material/Person'
import { ListAvatarImage, StatElement, StatWrapper } from '../styled'
import { LineDataConvertorParams } from '../../types'
import { getUrl } from 'shared/api/helpers'

function lineDataConvertor({
  shopId,
  statistic,
  tr,
  monitoringOpenedIds,
  showControls,
  handleMonitoringClick
}: LineDataConvertorParams) {
  const statisticById = statistic.reduce((accumulator, currentValue) => {
    accumulator[currentValue.lineId] = currentValue
    return accumulator
  }, {})

  return (item: LineModelSimplified) => {
    const { hmin } = tr.time
    const name = item.name || ' '
    const color = lineCategory.getColor(item.category)

    const avatar = item.image?.id ? (
      <ListAvatarImage $url={getUrl('downloadFile', { id: item.image.id })} />
    ) : (
      <Avatar style={{ backgroundColor: color, borderRadius: '1rem' }}>{name[0].toUpperCase()}</Avatar>
    )

    const statItem = statisticById[item.id] || {}
    const minutesText = statItem.time / 60 >= 24 ? null : hmin(statItem.time)

    const persons = `${statItem.persons || 0} ${tr.getParticipantFormFromTemplates(
      statItem.persons || 0,
      item.lineParticipantTermInSingularTemplate,
      item.lineParticipantTermInPluralTemplate
    )}`

    const controlsIsAllow = showControls && item.lineCalculationAlgorithmMode === 'normal'
    const lineNotActive = statItem?.state === 'closed' || !statItem.state
    const lineStateText = tr.monitoring.lineState[statItem.state]?.(statItem.stateTime)

    const statistics = (
      <StatWrapper>
        {persons && (
          <StatElement>
            <PersonIcon style={{ fontSize: '16px' }} />
            {persons}
          </StatElement>
        )}
        {!lineNotActive && item.displayWaitingTimeEstimation !== false && (
          <StatElement>
            <TimeIcon style={{ fontSize: '16px' }} /> {minutesText ?? tr.lineMonitoring.lineFull}
          </StatElement>
        )}
        {lineStateText && (
          <StatElement>
            <DonutLargeIcon style={{ fontSize: '16px' }} /> {lineStateText}
          </StatElement>
        )}
      </StatWrapper>
    )

    const secondaryText = statisticById[item.id] ? statistics : null

    return {
      id: item.id,
      primaryText: name,
      secondaryText,
      avatar,
      controls: controlsIsAllow && [
        <LineListControls
          handleMonitoringClick={handleMonitoringClick?.(item.id || '')}
          lineId={String(item.id)}
          shopId={String(shopId)}
          monitoringIsOpened={!!monitoringOpenedIds?.find((el) => el === item.id)}
          lineNotActive={lineNotActive}
          peopleCount={statItem.persons}
        />
      ],
      showBottomParent: !!monitoringOpenedIds?.find((el) => el === item.id),
      bottomParent: <LineListMonitoring placeId={shopId} lineId={item.id} />
    }
  }
}

export { lineDataConvertor }
