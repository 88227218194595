import { JournalActionModel } from 'shared/models'

function formatDateTimeline(dateTime: number, locale: string, timeZone: string) {
  const yearRecord = new Date(dateTime).getFullYear()
  const todayYear = new Date().getFullYear()
  const year = yearRecord !== todayYear ? 'numeric' : undefined

  return Intl.DateTimeFormat(locale, { timeZone, day: '2-digit', month: 'short', year }).format(dateTime)
}

function getTimelineDate(
  action: JournalActionModel,
  prevAction: JournalActionModel | undefined,
  locale: string,
  timeZone: string
) {
  const d1 = !!prevAction && new Date(prevAction.dateTime)
  const d2 = !!action && new Date(action.dateTime)
  const isSameDay =
    !!d1 &&
    !!d2 &&
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()

  return !isSameDay ? formatDateTimeline(action.dateTime, locale, timeZone) : ''
}
export { getTimelineDate }
