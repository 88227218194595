import { useTranslation } from 'shared/i18n/translate'
import { Form, FormGroup, SubGroup } from 'shared/ui-kit/form'
import { TextField } from 'shared/ui-kit/text-field'
import TextFieldMUI from '@mui/material/TextField'
import Toggle from 'shared/ui-kit/toggle-wrap'
import ColorPicker from 'shared/ui-kit/color-picker'
import { lineCategory } from '../../line-category'
import { isPositiveInteger, isPositiveNumber } from 'shared/utils/string-test'
import Dropdown from 'shared/ui-kit/dropdown'
import { addTestAttr } from 'shared/utils/test-attr'
import { WithHelper } from 'features/element-with-helper'
import { TagSearch } from '../../components/tag-search'
import { InputTranslation } from 'features/input-translation'
import FileUploader from 'features/file-uploader'
import { Controller } from 'react-hook-form'
import { GroupProps } from '../types'
import Card from 'shared/ui-kit/card'

function ParametersGroup({ control, brandId, watch, setValue, trigger, shopId }: GroupProps) {
  const { tr } = useTranslation()

  const {
    positionJoinStrategyLabel,
    positionJoinStrategyVariants,
    name,
    namePlaceholder,
    shortName,
    shortNamePlaceholder,
    category,
    manageAppointments,
    maxSimultaneous,
    maxSimultaneousHint,
    maxSimultaneousPlaceholder,
    requestParticipantsNumber,
    asapMode,
    sendSMSWhenJoined,
    sendSMSWhenCalled,
    smsSenderId,
    lineCalculationAlgorithm,
    lineCalculationAlgorithmModes,
    reservationCodeGenerationStrategy,
    reservationCodeGenerationStrategies,
    autoCall,
    tags,
    searchTags,
    tagsNotFound,
    noTermTags,
    displayShortName,
    description,
    descriptionPlaceholder,
    image,
    sendSurveyLinkAfterPositionServed,
    sendSurveyLinkByEmail,
    sendSurveyLinkBySms,
    surveyLink,
    surveySilencePeriod
  } = tr.lineEdit

  return (
    <Card>
      <Form>
        <FormGroup>
          <WithHelper tooltipKey="name" tag="line">
            <Controller
              name="name"
              control={control}
              render={({ field: f, fieldState }) => (
                <InputTranslation inputValue={f.value} setInputValue={f.onChange} brandId={brandId} placeId={shopId}>
                  <TextField
                    label={name}
                    placeholder={namePlaceholder}
                    value={f.value}
                    onChange={f.onChange}
                    test={(v) => v.length < 101}
                    message={`${f.value?.length || 0}/100 ${tr.entityEdit.chars}`}
                    attention={!!fieldState.error}
                    fullWidth
                    {...addTestAttr('LineEdit-Name')}
                  />
                </InputTranslation>
              )}
            />
          </WithHelper>
        </FormGroup>
        <FormGroup>
          <Controller
            name="description"
            control={control}
            render={({ field: f, fieldState }) => (
              <InputTranslation inputValue={f.value} setInputValue={f.onChange} brandId={brandId} placeId={shopId}>
                <TextFieldMUI
                  {...addTestAttr('Line-Description')}
                  label={description}
                  placeholder={descriptionPlaceholder}
                  value={f.value}
                  fullWidth
                  onChange={f.onChange}
                  InputLabelProps={{ shrink: true }}
                  helperText={`${f.value?.length || 0}/2000 ${tr.entityEdit.chars}`}
                  error={!!fieldState.error}
                  multiline
                  maxRows={15}
                />
              </InputTranslation>
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="image"
            control={control}
            render={({ field: f }) => <FileUploader id="image" label={image} file={f.value} onChange={f.onChange} />}
          />
        </FormGroup>
        <FormGroup>
          <WithHelper tooltipKey="displayShortName" tag="line">
            <Controller
              name="displayShortName"
              control={control}
              render={({ field: f, fieldState }) => (
                <InputTranslation inputValue={f.value} setInputValue={f.onChange} brandId={brandId} placeId={shopId}>
                  <TextField
                    label={displayShortName}
                    placeholder={displayShortName}
                    value={f.value}
                    onChange={f.onChange}
                    test={(v) => v.length < 101}
                    attention={!!fieldState.error}
                    message={`${f.value?.length || 0}/100 ${tr.entityEdit.chars}`}
                    fullWidth
                  />
                </InputTranslation>
              )}
            />
          </WithHelper>
        </FormGroup>
        <FormGroup>
          <WithHelper tooltipKey="shortName" tag="line">
            <Controller
              name="shortName"
              control={control}
              render={({ field: f, fieldState }) => (
                <TextField
                  label={shortName}
                  placeholder={shortNamePlaceholder}
                  value={f.value}
                  onChange={f.onChange}
                  test={(v) => v.length < 101}
                  attention={!!fieldState.error}
                  message={`${f.value?.length || 0}/100 ${tr.entityEdit.chars}`}
                  fullWidth
                />
              )}
            />
          </WithHelper>
        </FormGroup>
        <FormGroup>
          <WithHelper tooltipKey="category" tag="line">
            <Controller
              name="category"
              control={control}
              render={({ field: f }) => (
                <ColorPicker
                  title={category}
                  colors={lineCategory.getCategoryList()}
                  color={(f.value && lineCategory.getColor(f.value)) || ''}
                  onChange={(color) => f.onChange(color.category)}
                />
              )}
            />
          </WithHelper>
        </FormGroup>
        <FormGroup>
          <WithHelper tooltipKey="tags" tag="line">
            <Controller
              name="tags"
              control={control}
              render={({ field: f }) => (
                <TagSearch
                  fullWidth
                  label={tags}
                  value={f.value}
                  onChange={f.onChange}
                  noOptionsText={tagsNotFound}
                  noTermText={noTermTags}
                  placeholder={searchTags}
                />
              )}
            />
          </WithHelper>
        </FormGroup>
        <FormGroup>
          <WithHelper tooltipKey="lineCalculationAlgorithmMode" tag="line">
            <Controller
              name="lineCalculationAlgorithmMode"
              control={control}
              render={({ field: f }) => (
                <Dropdown
                  ensureValue
                  label={lineCalculationAlgorithm}
                  value={f.value}
                  data={['normal', 'discrete']}
                  dataConverter={(item) => ({ value: item, text: lineCalculationAlgorithmModes[item] })}
                  fullWidth
                  onChange={(val) => {
                    if (f.value !== val) {
                      f.onChange(val)

                      setValue('supportAutoCall', true)

                      if (val !== 'discrete') {
                        setValue('timeToSetFreeStockPlaces', 0)
                      }

                      trigger()
                    }
                  }}
                />
              )}
            />
          </WithHelper>
        </FormGroup>
        <FormGroup>
          <WithHelper tooltipKey="positionJoinStrategy" tag="line">
            <Controller
              name="positionJoinStrategy"
              control={control}
              render={({ field: f }) => (
                <Dropdown
                  ensureValue
                  label={positionJoinStrategyLabel}
                  value={f.value}
                  data={['onlyOnePosition', 'onlyOnePositionPerService', 'multiplePositions']}
                  dataConverter={(item) => ({ value: item, text: positionJoinStrategyVariants[item] })}
                  onChange={f.onChange}
                  fullWidth
                />
              )}
            />
          </WithHelper>
        </FormGroup>
        <FormGroup>
          <WithHelper tooltipKey="reservationCodeGenerationStrategy" tag="line">
            <Controller
              name="reservationCodeGenerationStrategy"
              control={control}
              render={({ field: f }) => (
                <Dropdown
                  ensureValue
                  label={reservationCodeGenerationStrategy}
                  value={f.value}
                  data={['random', 'hourAndMinute', 'sequence']}
                  dataConverter={(item) => ({ value: item, text: reservationCodeGenerationStrategies[item] })}
                  onChange={f.onChange}
                  fullWidth
                />
              )}
            />
          </WithHelper>
        </FormGroup>
        <FormGroup>
          <Controller
            name="asapMode"
            control={control}
            render={({ field: f }) => (
              <Toggle
                fullWidth
                toggled={f.value}
                label={asapMode}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="asapMode"
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="manageAppointments"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={manageAppointments}
                onToggle={(_, val) => {
                  f.onChange(val)
                  trigger()
                }}
                withHelper={true}
                tag="line"
                tooltipKey="manageAppointments"
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="requestParticipantsNumber"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={requestParticipantsNumber}
                onToggle={(_, val) => {
                  f.onChange(val)
                  setValue('maxSimultaneous', 1)
                  trigger()
                }}
                withHelper={true}
                tag="line"
                tooltipKey="requestParticipantsNumber"
              />
            )}
          />
        </FormGroup>
        {watch('requestParticipantsNumber') && (
          <FormGroup>
            <WithHelper tooltipKey="maxSimultaneous" tag="line">
              <Controller
                name="maxSimultaneous"
                control={control}
                render={({ field: f, fieldState }) => (
                  <TextField
                    label={maxSimultaneous}
                    placeholder={maxSimultaneousPlaceholder}
                    value={f.value || ''}
                    onChange={f.onChange}
                    test={isPositiveInteger}
                    hint={maxSimultaneousHint}
                    attention={!!fieldState.error}
                    fullWidth
                  />
                )}
              />
            </WithHelper>
          </FormGroup>
        )}
        {watch('lineCalculationAlgorithmMode') !== 'discrete' && (
          <FormGroup>
            <Controller
              name="supportAutoCall"
              control={control}
              render={({ field: f }) => (
                <Toggle
                  toggled={f.value}
                  label={autoCall}
                  onToggle={(_, val) => {
                    f.onChange(val)
                    trigger()
                  }}
                  withHelper={true}
                  tag="line"
                  tooltipKey="supportAutoCall"
                />
              )}
            />
          </FormGroup>
        )}
        <FormGroup>
          <Controller
            name="sendSMSWhenJoined"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={sendSMSWhenJoined}
                onToggle={(_, val) => {
                  f.onChange(val)
                  trigger()
                }}
                withHelper={true}
                tag="line"
                tooltipKey="sendSMSWhenJoined"
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="sendSMSWhenCalled"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={sendSMSWhenCalled}
                onToggle={(_, val) => {
                  f.onChange(val)
                  trigger()
                }}
                withHelper={true}
                tag="line"
                tooltipKey="sendSMSWhenCalled"
              />
            )}
          />
        </FormGroup>
        {(watch('sendSMSWhenJoined') || watch('sendSMSWhenCalled')) && (
          <FormGroup>
            <WithHelper tooltipKey="smsSenderId" tag="line">
              <Controller
                name="smsSenderId"
                control={control}
                render={({ field: f, fieldState }) => (
                  <TextField
                    label={smsSenderId}
                    placeholder={smsSenderId}
                    value={f.value}
                    onChange={f.onChange}
                    attention={!!fieldState.error}
                    fullWidth
                    message={`${f.value?.length || 0}/100 ${tr.entityEdit.chars}`}
                    test={(v) => v.length < 101}
                  />
                )}
              />
            </WithHelper>
          </FormGroup>
        )}
        <FormGroup>
          <Controller
            name="sendSurveyLinkAfterPositionServed"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={sendSurveyLinkAfterPositionServed}
                onToggle={(_, val) => {
                  f.onChange(val)
                  trigger()
                }}
                withHelper={true}
                tag="line"
                tooltipKey="sendSurveyLinkAfterPositionServed"
              />
            )}
          />
        </FormGroup>
        {watch('sendSurveyLinkAfterPositionServed') && (
          <SubGroup>
            <FormGroup>
              <Controller
                name="sendSurveyLinkByEmail"
                control={control}
                render={({ field: f }) => (
                  <Toggle
                    toggled={f.value}
                    label={sendSurveyLinkByEmail}
                    onToggle={(_, val) => {
                      f.onChange(val)
                      trigger()
                    }}
                    withHelper={true}
                    tag="line"
                    tooltipKey="sendSurveyLinkByEmail"
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="sendSurveyLinkBySms"
                control={control}
                render={({ field: f }) => (
                  <Toggle
                    toggled={f.value}
                    label={sendSurveyLinkBySms}
                    onToggle={(_, val) => {
                      f.onChange(val)
                      trigger()
                    }}
                    withHelper={true}
                    tag="line"
                    tooltipKey="sendSurveyLinkBySms"
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <WithHelper tooltipKey="surveyLink" tag="line">
                <Controller
                  name="surveyLink"
                  control={control}
                  render={({ field: f, fieldState }) => (
                    <TextField
                      label={surveyLink}
                      placeholder={surveyLink}
                      value={f.value}
                      onChange={f.onChange}
                      attention={!!fieldState.error}
                      fullWidth
                      message={`${f.value?.length || 0}/2000 ${tr.entityEdit.chars}`}
                      test={(v) => v.length < 2001}
                    />
                  )}
                />
              </WithHelper>
            </FormGroup>
            <FormGroup>
              <WithHelper tooltipKey="surveySilencePeriodInDays" tag="line">
                <Controller
                  name="surveySilencePeriodInDays"
                  control={control}
                  defaultValue={0}
                  render={({ field: f, fieldState }) => (
                    <TextField
                      label={surveySilencePeriod}
                      placeholder={surveySilencePeriod}
                      value={f.value || '0'}
                      onChange={(val) => f.onChange(val ? Number(val) : undefined)}
                      test={isPositiveNumber}
                      hint={tr.time.daysHint}
                      attention={!!fieldState.error}
                      fullWidth
                    />
                  )}
                />
              </WithHelper>
            </FormGroup>
          </SubGroup>
        )}
      </Form>
    </Card>
  )
}

export { ParametersGroup }
