import { CSSProperties, useState, ReactNode } from 'react'
import Dropdown from 'shared/ui-kit/dropdown'
import Autocomplete from 'shared/ui-kit/autocomplete'
import { ControlLabel } from 'shared/ui-kit/control-label'
import AttentionPoint from 'shared/ui-kit/attention-point'
import { RemoveCircleOutline as RemoveIcon, Add as AddIcon } from '@mui/icons-material'
import { IconButton, Button } from '@mui/material'
import { EmptyMessage } from 'shared/ui-kit/empty-message'

type Id = string | number

type Props<T> = {
  selectedIds?: Id[]
  source?: T[]
  listDataConverter?: (item: T) => { id: Id; primaryText: ReactNode }
  dropdownDataConverter?: (item: T) => { value: Id; text: string }
  onChange: (items: Id[]) => void
  addButtonText: string
  useAutocomplete?: boolean
  attention?: boolean
  label?: string
  style?: CSSProperties
  renderSelectedItem?: (item: T) => ReactNode
  emptyMessage?: string
}

function AddListComponent<T>(props: Props<T>) {
  const {
    selectedIds = [],
    source = [],
    listDataConverter = (item: any | T) => {
      return item?.id ? { id: item.id, primaryText: item.name } : { id: -1, primaryText: 'NO_TEXT_DATA_ERROR' }
    },
    dropdownDataConverter = (item: any | T) => ({ value: item.id, text: item.name }),
    addButtonText,
    onChange,
    label,
    attention = false,
    useAutocomplete = false,
    style,
    renderSelectedItem
  } = props

  if (!source.length) {
    return null
  }

  const [selectedItem, setSelectedItem] = useState<any>()

  const unselectedSource = source.filter((x) => !selectedIds.includes(listDataConverter(x).id))

  const selectedSource = selectedIds
    .map((sid) => source.find((s) => String(listDataConverter(s).id) === String(sid)))
    .filter(Boolean) as T[]

  function handleAddItem() {
    if (!selectedItem) {
      return
    }

    onChange([...selectedIds, selectedItem])
    setSelectedItem(undefined)
  }

  function handleRemoveItem(id) {
    return () => onChange(selectedIds.filter((x) => String(x) !== String(id)))
  }

  function renderSelectedItems() {
    return selectedSource.map((item) => {
      if (renderSelectedItem && typeof renderSelectedItem === 'function') {
        return renderSelectedItem(item)
      }

      const { id, primaryText } = listDataConverter(item)

      return (
        <div key={id} style={{ display: 'flex', alignItems: 'center', padding: '8px 0' }}>
          <div style={{ flex: '1 1 auto' }}>{primaryText}</div>
          <div>
            <IconButton onClick={handleRemoveItem(id)}>
              <RemoveIcon />
            </IconButton>
          </div>
        </div>
      )
    })
  }

  return (
    <section style={{ ...style }}>
      {Boolean(label || attention) && (
        <div style={{ display: 'flex' }}>
          {label && <ControlLabel>{label}</ControlLabel>}
          {attention && <AttentionPoint />}
        </div>
      )}
      {!!selectedSource.length && renderSelectedItems()}
      {Boolean(!selectedSource.length && !!props.emptyMessage) && <EmptyMessage>{props.emptyMessage}</EmptyMessage>}
      {!!unselectedSource.length && renderAddControl()}
    </section>
  )

  function renderAddControl() {
    return (
      <div style={{ display: 'flex', marginTop: 8 }}>
        {useAutocomplete && (
          <Autocomplete
            title=""
            ensureValue
            fullWidth
            value={selectedItem}
            list={unselectedSource}
            onChange={(id) => setSelectedItem(id)}
            dataConverter={(item) => dropdownDataConverter(item)}
            styles={{ marginRight: 16, flex: '1 1 auto' }}
          />
        )}
        {!useAutocomplete && (
          <Dropdown
            ensureValue
            fullWidth
            value={selectedItem}
            data={unselectedSource}
            onChange={(id) => setSelectedItem(id)}
            dataConverter={(item) => dropdownDataConverter(item)}
            style={{ marginRight: 16, flex: '1 1 auto' }}
          />
        )}
        <Button startIcon={<AddIcon />} id="addToList" onClick={handleAddItem}>
          {addButtonText}
        </Button>
      </div>
    )
  }
}

export default AddListComponent
export const AddList = AddListComponent
