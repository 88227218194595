import { useState, useEffect } from 'react'
import { PlaceView } from 'pages/place/dumb/view'
import { useTranslation } from 'shared/i18n/translate'
import Card from 'shared/ui-kit/card'
import { Button, CircularProgress } from '@mui/material'
import EntityActions from 'shared/ui-kit/entity-actions'
import ModeEditIcon from '@mui/icons-material/Edit'
import { editPlace } from 'pages/nav'
import PlaceTiles from 'pages/place/place-tiles'
import { HomeTiles } from './home-tiles'
import { TileContainer } from 'features/tile'
import { useHistory } from 'react-router-dom'
import { place as placeApi } from 'shared/api'
import { useDataProvider } from 'features/isolated-data-provider'
import { Title } from 'shared/ui-kit/title'
import { CenterContainer } from 'shared/ui-kit/center-container'
import { PlaceModel } from 'shared/models'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { getBrandListSelector } from 'store/selectors/brandListSelectors'
import { getIsolatedBrandList } from 'store/actions/brand-action-creators'
import { useAppDispatch, useAppSelector } from 'store'

function HomePlace() {
  const { tr } = useTranslation()
  const { timeZones, places } = useDataProvider()
  const brands = useAppSelector(getBrandListSelector)
  const { canUpdateShops, displayReducedInterface } = useAppSelector(getPermissionsSelector)
  const dispatch = useAppDispatch()

  const history = useHistory()

  const [place, setPlace] = useState<PlaceModel | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (!brands) {
      dispatch(getIsolatedBrandList())
    }

    fetchPlace()
  }, [places])

  async function fetchPlace() {
    if (!places[0]?.id) {
      return
    }

    setLoading(true)

    try {
      const response = await placeApi.getPlace(places[0]?.id)
      setPlace(response)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  function renderButtons() {
    return (
      <Button onClick={handleEdit} startIcon={<ModeEditIcon />}>
        {tr.entityView.editButton}
      </Button>
    )
  }

  function handleEdit() {
    history.push(editPlace(String(place?.id || '')))
  }

  if (loading) {
    return (
      <CenterContainer>
        <CircularProgress />
      </CenterContainer>
    )
  }

  if (place === null) {
    return null
  }

  return (
    <>
      {displayReducedInterface && <Title title={place.name} />}
      {!displayReducedInterface && (
        <Card paperStyle={{ overflow: 'hidden' }}>
          <PlaceView data={place} brands={brands} timeZones={timeZones} canUpdateShops={canUpdateShops} />
          {canUpdateShops && (
            <EntityActions showButtons buttons={renderButtons()} updateData={place?.lastUpdate || undefined} />
          )}
        </Card>
      )}
      <TileContainer style={{ display: 'block' }}>
        <PlaceTiles place={place} />
        <div style={{ marginTop: '2rem' }}>
          <HomeTiles />
        </div>
      </TileContainer>
    </>
  )
}

export { HomePlace }
