import { Box } from '@mui/material'
import { CheckpointContentProps } from './types'

function CheckpointContent(props: CheckpointContentProps) {
  return (
    <Box
      border={props.accent ? `2px solid ${props.accentColor || '#FF3B30'}` : undefined}
      borderRadius="0.5rem"
      style={{ backgroundColor: '#F3F3F3', ...props.style }}
    >
      {props.children}
    </Box>
  )
}

export { CheckpointContent }
