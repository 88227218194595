import { useEffect, useState } from 'react'
import { AdvertisementList } from './dumb/advertisement-list'
import { editAdvertisement, createAdvertisement } from 'pages/nav'
import { useTranslation } from 'shared/i18n/translate'
import { useHistory, useParams } from 'react-router'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { advertisement as advertisementApi } from 'shared/api'
import { AppBar, Divider, IconButton, ListItemIcon, Menu, MenuItem, Toolbar } from '@mui/material'
import { Add } from '@mui/icons-material'
import PageLoader from 'shared/ui-kit/page-loader'
import Card from 'shared/ui-kit/card'
import Container from 'shared/ui-kit/container'
import { AdvertisementModel } from 'shared/models'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { MoreVert as MoreIcon } from '@mui/icons-material'
import EditListIcon from '@mui/icons-material/List'
import CloseIcon from '@mui/icons-material/Close'
import SuccessIcon from '@mui/icons-material/Done'
import { BarContent, Icons, IconsContainer } from './styeld'
import { useAppDispatch, useAppSelector } from 'store'
import { useQuery, useQueryClient } from 'react-query'

function SmartAdvertisementList() {
  const dispatch = useAppDispatch()
  const { manageCampaigns } = useAppSelector(getPermissionsSelector)
  const { campaignId } = useParams<{ campaignId: string }>()
  const { tr, locale } = useTranslation()
  const { push } = useHistory()
  const queryClient = useQueryClient()

  const [otherButtonEl, setOtherButtonEl] = useState<null | HTMLElement>(null)

  const [data, setData] = useState<AdvertisementModel[] | null>(null)
  const [listIsEditMode, setListIsEditMode] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [loading, setLoading] = useState(false)

  const { data: res, isLoading } = useQuery(
    ['advertisements', campaignId],
    ({ signal }) => advertisementApi.getAdvertisementList(campaignId, { signal }),
    {
      onSuccess: (data) => {
        setData(data?.data || null)
        setIsDirty(false)
      }
    }
  )

  useEffect(() => {
    setCrumbs()
  }, [locale, res?.parents])

  function setCrumbs() {
    if (!res?.parents?.campaign) {
      return
    }

    const crumb = getCrumbs(tr.breadcrumbs)
    dispatch(
      setBreadcrumbs([
        crumb.home(),
        crumb.campaigns(),
        crumb.campaign([String(res.parents.campaign.id)], res.parents.campaign.name),
        crumb.advertisements([String(res.parents.campaign.id)])
      ])
    )
  }

  function setListMode(value: boolean) {
    return () => {
      setListIsEditMode(value)
      setIsDirty(value)
    }
  }

  function handleClick(data: AdvertisementModel) {
    if (manageCampaigns) {
      push(editAdvertisement(campaignId, String(data.id)))
    }
  }

  function handleDropEnd(list: AdvertisementModel[]) {
    setIsDirty(true)
    setData(list)
  }

  async function saveListOrder() {
    if (!isDirty) {
      setListIsEditMode(false)
      return
    }

    const items = (data || []).map((ad) => ({ advertisementId: ad.id }))

    try {
      setLoading(true)
      await advertisementApi.updateAdvertisementsOrder({ campaignId, items })
      queryClient.invalidateQueries(['advertisements', campaignId])
      setListIsEditMode(false)
    } finally {
      setLoading(false)
    }
  }

  function closeMoreMenu() {
    setOtherButtonEl(null)
  }

  function handleChangeListOrder() {
    setListIsEditMode(true)
    setIsDirty(false)
    closeMoreMenu()
  }

  function handleMoreIconClick(event: React.MouseEvent<HTMLButtonElement>) {
    setOtherButtonEl(event.currentTarget)
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <>
      {manageCampaigns && (
        <AppBar position="sticky" color="inherit" elevation={2} style={{ top: '4rem', borderRadius: 0 }}>
          <Toolbar>
            <Container style={{ padding: 'unset' }}>
              <BarContent>
                <IconsContainer>
                  <IconButton onClick={() => push(createAdvertisement(campaignId))}>
                    <Add />
                  </IconButton>
                </IconsContainer>
                {!listIsEditMode && (
                  <IconButton onClick={handleMoreIconClick}>
                    <MoreIcon />
                  </IconButton>
                )}
                <Menu anchorEl={otherButtonEl} open={!!otherButtonEl} onClose={closeMoreMenu} elevation={2}>
                  <MenuItem onClick={handleChangeListOrder} disabled={(data || []).length < 2}>
                    <ListItemIcon>
                      <EditListIcon fontSize="small" />
                    </ListItemIcon>
                    {tr.common.cahngeListOrder}
                  </MenuItem>
                </Menu>
                {!!listIsEditMode && (
                  <Icons>
                    <Divider orientation="vertical" style={{ height: '48px' }} />
                    <IconsContainer>
                      {listIsEditMode && (
                        <>
                          <IconButton onClick={setListMode(false)} disabled={loading}>
                            <CloseIcon />
                          </IconButton>
                          <IconButton onClick={saveListOrder} disabled={loading}>
                            <SuccessIcon />
                          </IconButton>
                        </>
                      )}
                    </IconsContainer>
                  </Icons>
                )}
              </BarContent>
            </Container>
          </Toolbar>
        </AppBar>
      )}
      <Container>
        <Card style={{ maxWidth: 'inherit' }} paperStyle={{ overflow: 'hidden' }}>
          <AdvertisementList
            data={(isDirty ? data : res?.data) || []}
            onSelect={handleClick}
            editMode={listIsEditMode}
            onDropEnd={handleDropEnd}
          />
        </Card>
      </Container>
    </>
  )
}

export default SmartAdvertisementList
