import { CheckpointHostEditModel, CheckpointOpenState } from 'shared/models'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Form, FormGroup } from 'shared/ui-kit/form'
import { useTranslation } from 'shared/i18n/translate'
import { monitoring } from 'shared/api'
import { TextField } from 'shared/ui-kit/text-field'
import TimePickerTimestamp from 'features/time/time-picker-timestamp'
import Toggle from 'shared/ui-kit/toggle-wrap'
import { ToggleList } from 'shared/ui-kit/toggle-list'
import debounce from 'lodash/debounce'
import uniqueId from 'lodash/uniqueId'
import { AttentionMessage } from 'shared/ui-kit/attention-message/attention-message'
import { Button, Chip, Typography } from '@mui/material'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { useAppSelector } from 'store'
import HostInfo from './components/host-info'
import Card from 'shared/ui-kit/card'
import { getIsChangeReasonRequired, getHoursWithToday, delayTimes, getErrorToText } from './utils'
import { HostEditProps, PermissionError } from './types'
import { StateCard } from './components/state-card'
import AttentionPoint from 'shared/ui-kit/attention-point'
import {
  CustomDelayWrapper,
  DelayChips,
  DelayControl,
  DelayTitleWrap,
  FormGroupLine,
  GridCards,
  HostActions,
  UserItem
} from './styled'
import { addTestAttr } from 'shared/utils/test-attr'
import { offsetToStamp } from 'shared/i18n/date-formatter'

function HostEdit({ data: propsData, line, ...props }: HostEditProps) {
  const { canOpenCheckpoints, canCloseCheckpoints } = useAppSelector(getPermissionsSelector)

  const canGiveUpdateServicePointStartAndEndHours =
    (!propsData.active && canOpenCheckpoints) || (canOpenCheckpoints && canCloseCheckpoints)

  const { tr, timeZoneOffset } = useTranslation()

  const params = useParams<{ lineId?: string }>()

  const initOpenState =
    propsData.isAnyHourOpened && propsData.servicePointCanAlwaysBeOpen
      ? 'Always'
      : propsData.active
        ? 'AtHours'
        : undefined

  const [data, setData] = useState(propsData)
  const [searchUsers, setSearchUsers] = useState<any[]>([])
  const [isChangeReasonRequired, setIsChangeReasonRequired] = useState(true)
  const [servicesChangeReason, setServicesChangeReason] = useState('')
  const [activeChangeReason, setActiveChangeReason] = useState('')
  const [openState, setOpenState] = useState<CheckpointOpenState | undefined>(initOpenState)
  const [delayTime, setDelayTime] = useState(5 * 60000)
  const [isCustomDelay, setIsCustomDelay] = useState<boolean>(false)
  const [willBeClosed, setWillBeClosed] = useState(props.preferToStop && !!data.active)

  const lineScheduleToday = (line?.schedule || []).find((unit) => unit.weekdays.includes(new Date().getDay()))

  const availableModes: Record<CheckpointOpenState, boolean> = {
    LineHours:
      !!lineScheduleToday && !lineScheduleToday.nonStopService && line?.displayNormalStartServicePoint !== false,
    WithDelay: line?.displayDelayStartServicePoint !== false,
    AtHours: line?.displaySpecificHoursStartServicePoint !== false,
    Always: !!data.servicePointCanAlwaysBeOpen && line?.displayAllTimeStartServicePoint !== false
  }

  useEffect(() => {
    if (line.defaultDelayStartServicePointInMinutes) {
      if (!delayTimes.includes(line.defaultDelayStartServicePointInMinutes)) {
        setIsCustomDelay(true)
      }

      setDelayTime(line.defaultDelayStartServicePointInMinutes * 60000)
    }
  }, [line])

  useEffect(() => {
    const modes = Object.entries(availableModes).filter((el) => el[1] === true) as [CheckpointOpenState, boolean][]

    if (modes.length === 1 && openState !== modes[0][0] && !propsData.active) {
      handleChangeOpenState(modes[0][0])()
    }
  }, [availableModes])

  const debounceRef = useRef(
    debounce(async (data: { term: string; lineId?: number | string }) => {
      try {
        const result = await monitoring.getPositionUsers(data)
        setSearchUsers(result)
      } catch (err) {
        setSearchUsers([])
      }
    }, 300)
  )

  const initDataRef = useRef<CheckpointHostEditModel>()

  useEffect(() => {
    initDataRef.current = propsData
    setIsChangeReasonRequired(getIsChangeReasonRequired(initDataRef.current))
  }, [])

  useEffect(() => {
    if (!data.servicePointCanAlwaysBeOpen && !!data.isAnyHourOpened) {
      setData({ ...data, isAnyHourOpened: false, startTime_unixtime: undefined, closingTime_unixtime: undefined })
    }
  }, [data])

  const activeChangeCommentRequired = useMemo(() => {
    if (!isChangeReasonRequired) {
      return false
    }

    return willBeClosed
  }, [isChangeReasonRequired, willBeClosed])

  const servicesChangeCommentRequired = useMemo(() => {
    if (!isChangeReasonRequired) {
      return false
    }

    if (initDataRef.current) {
      const initedEnabledServices = initDataRef.current.isAllServicesEnabled
        ? props.services?.map((s) => s.id) || []
        : initDataRef.current.enabledServiceIds || []
      const currentEnabledServices = data.isAllServicesEnabled
        ? props.services?.map((s) => s.id) || []
        : data.enabledServiceIds || []
      const lossServices = initedEnabledServices.filter((ies) => !currentEnabledServices.find((es) => es === ies))
      const newServices = currentEnabledServices.filter((ies) => !initedEnabledServices.find((es) => es === ies))

      if (lossServices.length === 0 && newServices.length === 0) {
        return false
      } else {
        return true
      }
    }

    return false
  }, [isChangeReasonRequired, data])

  const permissionError: PermissionError | null = useMemo(() => {
    if (canCloseCheckpoints && canOpenCheckpoints) {
      return null
    }

    if (initDataRef.current?.active !== data.active) {
      if (canCloseCheckpoints && data.active) {
        return PermissionError.CantOpen
      }
      if (canOpenCheckpoints && !data.active) {
        return PermissionError.CantClose
      }
    }

    return null
  }, [data.active, initDataRef.current])

  function handleApply() {
    const reason =
      (activeChangeCommentRequired && activeChangeReason) ||
      (servicesChangeCommentRequired && servicesChangeReason) ||
      null

    setData({ ...data, reason })

    if (willBeClosed) {
      props.onHostChange({ ...propsData, active: false, reason })
    } else if (openState === 'WithDelay') {
      props.onHostChange({ ...data, active: true, reason, startTime_unixtime: Date.now() + delayTime })
    } else {
      props.onHostChange({ ...data, active: true, reason })
    }
  }

  function handleHostNameChange(val: string) {
    setData({ ...data, hostName: val })

    if (val.length > 2) {
      debounceRef.current({ term: val, lineId: params.lineId })
    }
  }

  function handleSearchedUser(user) {
    return () => {
      setData({ ...data, hostName: `${user.firstName || ''} ${user.lastName || ''}` })
      setSearchUsers([])
    }
  }

  function handleChangeOpenState(newState: CheckpointOpenState) {
    return () => {
      setOpenState(newState)

      const endOfTheDay = offsetToStamp(new Date().setHours(23, 59, 0, 0), timeZoneOffset)

      if (newState === 'LineHours') {
        if (!lineScheduleToday) {
          return
        }

        setData({
          ...data,
          isAnyHourOpened: false,
          startTime_unixtime: lineScheduleToday.openingHour_unixtime,
          closingTime_unixtime: lineScheduleToday.closingHour_unixtime
        })
      }

      if (newState === 'WithDelay') {
        setDelayTime((delayTime) => {
          setData((curr) => ({
            ...curr,
            isAnyHourOpened: false,
            startTime_unixtime: Date.now() + delayTime,
            closingTime_unixtime: lineScheduleToday?.closingHour_unixtime || endOfTheDay
          }))
          return delayTime
        })
      }

      if (newState === 'AtHours') {
        setData({ ...data, isAnyHourOpened: false })
      }

      if (newState === 'Always') {
        setData({ ...data, isAnyHourOpened: true, startTime_unixtime: undefined, closingTime_unixtime: undefined })
      }
    }
  }

  function handleDelayClick(delay: number) {
    return () => {
      setDelayTime(delay * 60000)
      setIsCustomDelay(false)
      setData({ ...data, startTime_unixtime: Date.now() + delay * 60000 })
    }
  }

  function handleCustomDelay() {
    setIsCustomDelay(true)
    setData({ ...data, startTime_unixtime: Date.now() + 5 * 60000 })
  }

  function delayChange(diff: number) {
    return () => {
      const delay = Math.floor((delayTime || 0) / 60000) + diff

      if (delay >= 5) {
        setDelayTime(delay * 60000)
        setData({ ...data, startTime_unixtime: Date.now() + delay * 60000 })
      }
    }
  }

  function handleStartTimeChange(val: number | null) {
    setData({ ...data, startTime_unixtime: getHoursWithToday(val) })
  }

  function handleCloseTimeChange(val: number | null) {
    setData({ ...data, closingTime_unixtime: getHoursWithToday(val) })
  }

  function handlePauseStartChange(val: number | null) {
    setData({ ...data, pauseStartHour_unixtime: getHoursWithToday(val) })
  }

  function handlePauseEndChange(val: number | null) {
    setData({ ...data, pauseEndHour_unixtime: getHoursWithToday(val) })
  }

  function handleChangeReason(val: string) {
    setServicesChangeReason(val.trimStart())
  }

  const disabledTimePikers = !canGiveUpdateServicePointStartAndEndHours

  const { pauseStartHour_unixtime, pauseEndHour_unixtime, startTime_unixtime, closingTime_unixtime } = data

  const isServiseWithPauseInvalid = data.serviceWithPause && (!pauseStartHour_unixtime || !pauseEndHour_unixtime)
  const isAnyHourOpenedInvalid = !startTime_unixtime || !closingTime_unixtime
  const isSelectedServicesInvalid =
    !data.isAllServicesEnabled && !data.enabledServiceIds?.length && !!props.services?.length
  const servicesChangeCommentInvalid = servicesChangeCommentRequired && !servicesChangeReason
  const activeChangeCommentInvalid = activeChangeCommentRequired && !activeChangeReason

  const isReasonInvalid = activeChangeCommentRequired ? activeChangeCommentInvalid : servicesChangeCommentInvalid

  const localOffset = new Date().getTimezoneOffset() * 60000

  const startWithoutDate = new Date((data.startTime_unixtime || 0) + timeZoneOffset + localOffset).setFullYear(
    1970,
    0,
    1
  )
  const endWithoutDate = new Date((data.closingTime_unixtime || 0) + timeZoneOffset + localOffset).setFullYear(
    1970,
    0,
    1
  )
  const pauseStartWithoutDate = new Date(
    (data.pauseStartHour_unixtime || 0) + timeZoneOffset + localOffset
  ).setFullYear(1970, 0, 1)
  const pauseEndWithoutDate = new Date((data.pauseEndHour_unixtime || 0) + timeZoneOffset + localOffset).setFullYear(
    1970,
    0,
    1
  )

  const minServiceDurationForOpeningServicePoint = propsData?.minServiceDurationForOpeningServicePoint || 0
  const maxBreakDuration = propsData?.maxBreakDuration || 1440
  const minServiceValid =
    !minServiceDurationForOpeningServicePoint ||
    Math.floor((endWithoutDate - startWithoutDate) / 60000) >= minServiceDurationForOpeningServicePoint
  const maxBreakValid =
    !!maxBreakDuration && Math.floor((pauseEndWithoutDate - pauseStartWithoutDate) / 60000) <= maxBreakDuration

  const isValid =
    !(
      !openState ||
      isServiseWithPauseInvalid ||
      (!data.isAnyHourOpened && isAnyHourOpenedInvalid) ||
      isSelectedServicesInvalid ||
      (!data.isAnyHourOpened && startWithoutDate >= endWithoutDate) ||
      (!data.isAnyHourOpened && !minServiceValid) ||
      (data.serviceWithPause && (!maxBreakValid || pauseStartWithoutDate >= pauseEndWithoutDate)) ||
      permissionError ||
      (openState === 'WithDelay' && !delayTime)
    ) && Boolean(!willBeClosed || !isReasonInvalid)

  const isShowSettings = Boolean(!!openState || propsData.active) && !willBeClosed

  return (
    <Form>
      <Card>
        <FormGroup>
          <HostInfo data={data} displayTime={!!openState} />
        </FormGroup>
        <FormGroup>
          <TextField
            label={tr.checkpointMonitoring.hostName}
            autocomplete={uniqueId('host-name')}
            placeholder={tr.checkpointMonitoring.hostNamePlaceholder}
            value={data.hostName}
            disabled={!!willBeClosed}
            onChange={handleHostNameChange}
          />
          {searchUsers.map((user) => (
            <UserItem key={user.id} onClick={handleSearchedUser(user)}>
              {user.firstName} {user.lastName}
            </UserItem>
          ))}
        </FormGroup>
      </Card>
      {Boolean(canOpenCheckpoints && !propsData.active) &&
        Object.values(availableModes).filter((el) => !!el)?.length > 1 && (
          <GridCards>
            {availableModes.LineHours && (
              <StateCard
                title={tr.checkpointMonitoring.openStateTitle.LineHours}
                description={tr.checkpointMonitoring.openStateDescription.LineHours()}
                isActive={openState === 'LineHours'}
                onClick={handleChangeOpenState('LineHours')}
                {...addTestAttr('CheckpointHost-Mode-LineHours')}
              />
            )}
            {availableModes.WithDelay && (
              <StateCard
                title={tr.checkpointMonitoring.openStateTitle.WithDelay}
                description={tr.checkpointMonitoring.openStateDescription.WithDelay(
                  line?.lineParticipantTermInSingularTemplate
                )}
                isActive={openState === 'WithDelay'}
                onClick={handleChangeOpenState('WithDelay')}
                {...addTestAttr('CheckpointHost-Mode-WithDelay')}
              />
            )}
            {availableModes.AtHours && (
              <StateCard
                title={tr.checkpointMonitoring.openStateTitle.AtHours}
                description={tr.checkpointMonitoring.openStateDescription.AtHours()}
                isActive={openState === 'AtHours'}
                onClick={handleChangeOpenState('AtHours')}
                {...addTestAttr('CheckpointHost-Mode-AtHours')}
              />
            )}
            {availableModes.Always && (
              <StateCard
                title={tr.checkpointMonitoring.openStateTitle.Always}
                description={tr.checkpointMonitoring.openStateDescription.Always()}
                isActive={openState === 'Always'}
                onClick={handleChangeOpenState('Always')}
                {...addTestAttr('CheckpointHost-Mode-Always')}
              />
            )}
          </GridCards>
        )}
      {Boolean(canCloseCheckpoints && !!propsData.active) && (
        <GridCards>
          <StateCard
            title={tr.checkpointMonitoring.changeSettingsTitle}
            description={tr.checkpointMonitoring.changeSettingsDescription}
            isActive={!willBeClosed}
            onClick={() => setWillBeClosed(false)}
            {...addTestAttr('CheckpointHost-Mode-ChangeSettings')}
          />
          <StateCard
            title={tr.checkpointMonitoring.endServicePointTitle}
            description={tr.checkpointMonitoring.endServicePointDescription}
            isActive={willBeClosed}
            onClick={() => setWillBeClosed(true)}
            {...addTestAttr('CheckpointHost-Mode-Stop')}
          />
        </GridCards>
      )}
      {activeChangeCommentRequired && !permissionError && (
        <Card paperStyle={{ overflow: 'hidden' }}>
          <FormGroup>
            <TextField
              attention={isReasonInvalid}
              label={tr.checkpointMonitoring.reasonCloseServicePoint}
              value={activeChangeReason}
              onChange={(val) => setActiveChangeReason(val.trimStart())}
            />
          </FormGroup>
        </Card>
      )}
      {!!permissionError && (
        <FormGroup style={{ margin: '0.5rem 1rem' }}>
          <AttentionMessage message={getErrorToText(tr)[permissionError]} />
        </FormGroup>
      )}
      {isShowSettings && (
        <Card>
          {openState && openState !== 'Always' && (
            <>
              {openState === 'WithDelay' && (
                <FormGroup>
                  <DelayTitleWrap>
                    <Typography variant="caption" color="GrayText" display="block" marginBottom="0.5rem">
                      {tr.checkpointMonitoring.delay}
                    </Typography>
                    {!delayTime && !isCustomDelay && <AttentionPoint />}
                  </DelayTitleWrap>
                  <DelayChips>
                    {delayTimes.map((delay) => (
                      <Chip
                        key={delay}
                        label={tr.time.min(delay)}
                        color={!isCustomDelay && delayTime === delay * 60000 ? 'primary' : 'default'}
                        onClick={handleDelayClick(delay)}
                      />
                    ))}
                    <Chip
                      label={tr.lineDelay.custom}
                      color={isCustomDelay ? 'primary' : 'default'}
                      onClick={handleCustomDelay}
                    />
                    {isCustomDelay && (
                      <CustomDelayWrapper>
                        <DelayControl disabled={Math.floor((delayTime || 0) / 60000) - 5 < 5} onClick={delayChange(-5)}>
                          -
                        </DelayControl>
                        <Typography variant="body2">{tr.time.min(Math.floor(delayTime || 0) / 60000)}</Typography>
                        <DelayControl style={{ fontSize: '14px' }} onClick={delayChange(5)}>
                          +
                        </DelayControl>
                      </CustomDelayWrapper>
                    )}
                  </DelayChips>
                </FormGroup>
              )}
              {openState !== 'LineHours' && (
                <FormGroupLine>
                  {openState === 'AtHours' && (
                    <>
                      <TimePickerTimestamp
                        disabled={disabledTimePikers}
                        fullWidth
                        label={tr.checkpointMonitoring.startTime}
                        value={data.startTime_unixtime}
                        attention={!data.startTime_unixtime}
                        onChange={handleStartTimeChange}
                        offset
                        {...addTestAttr('CheckpointHost-StartTime')}
                      />
                      <Typography variant="h4">-</Typography>
                    </>
                  )}
                  <TimePickerTimestamp
                    disabled={disabledTimePikers}
                    fullWidth
                    label={tr.checkpointMonitoring.closingTime}
                    value={data.closingTime_unixtime}
                    onChange={handleCloseTimeChange}
                    attention={!data.closingTime_unixtime || startWithoutDate >= endWithoutDate || !minServiceValid}
                    offset
                    {...addTestAttr('CheckpointHost-ClosingTime')}
                  />
                </FormGroupLine>
              )}
            </>
          )}
          <FormGroup style={{ margin: '0.5rem 1rem' }}>
            <Toggle
              toggled={data.serviceWithPause}
              label={tr.checkpointMonitoring.serviceWithPause}
              onToggle={(_, val) => setData({ ...data, serviceWithPause: val })}
              {...addTestAttr('CheckpointHost-ServiceWithPause')}
            />
          </FormGroup>
          {data.serviceWithPause && (
            <FormGroupLine>
              <TimePickerTimestamp
                fullWidth
                disabled={disabledTimePikers}
                label={tr.checkpointMonitoring.pauseStartHour}
                value={data.pauseStartHour_unixtime}
                onChange={handlePauseStartChange}
                attention={!data.pauseStartHour_unixtime}
                offset
                {...addTestAttr('CheckpointHost-PauseStartHour')}
              />
              <Typography variant="h4">-</Typography>
              <TimePickerTimestamp
                fullWidth
                disabled={disabledTimePikers}
                label={tr.checkpointMonitoring.pauseEndHour}
                value={data.pauseEndHour_unixtime}
                onChange={handlePauseEndChange}
                attention={
                  !data.pauseEndHour_unixtime || pauseStartWithoutDate >= pauseEndWithoutDate || !maxBreakValid
                }
                offset
                {...addTestAttr('CheckpointHost-PauseEndHour')}
              />
            </FormGroupLine>
          )}
          {((!maxBreakValid && data.serviceWithPause) || (!minServiceValid && !data.isAnyHourOpened)) && (
            <FormGroup>
              <Typography variant="body2" color="error">
                {tr.checkpointMonitoring.errorBreakOrService}
              </Typography>
            </FormGroup>
          )}
        </Card>
      )}
      {isShowSettings && props.services && !!props.services.length && (
        <Card paperStyle={{ overflow: 'hidden' }}>
          <Form expandable title={tr.checkpointMonitoring.services} expanded attention={isSelectedServicesInvalid}>
            <FormGroup style={{ margin: '0.5rem 1rem' }}>
              <Toggle
                toggled={data.isAllServicesEnabled}
                label={tr.checkpointMonitoring.allServices}
                onToggle={(_, val) => {
                  setData({
                    ...data,
                    isAllServicesEnabled: val,
                    enabledServiceIds: val
                      ? []
                      : (props.services?.map((el) => el.id).filter(Boolean) as (string | number)[])
                  })
                }}
                {...addTestAttr('CheckpointHost-IsAllServicesEnabled')}
              />
              {!data.isAllServicesEnabled && (
                <ToggleList
                  attention={isSelectedServicesInvalid}
                  label={tr.checkpointMonitoring.services}
                  list={props.services}
                  value={data.enabledServiceIds}
                  labelExtractor={(item) => (item ? item.name : '')}
                  onChange={(val) => setData({ ...data, enabledServiceIds: val })}
                  compare={(valueItem, listItem) => valueItem === listItem.id}
                  valueExtractor={(item) => item.id as number | string}
                />
              )}
            </FormGroup>
            {servicesChangeCommentRequired && !activeChangeCommentRequired && (
              <FormGroup>
                <TextField
                  attention={isReasonInvalid}
                  label={tr.checkpointMonitoring.reasonChangeServicesSelection}
                  value={servicesChangeReason}
                  onChange={handleChangeReason}
                  {...addTestAttr('CheckpointHost-ReasonChangeServicesSelection')}
                />
              </FormGroup>
            )}
          </Form>
        </Card>
      )}
      <HostActions>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleApply}
          disabled={!isValid}
          {...addTestAttr('CheckpointHost-ApplyButton')}
        >
          {tr.checkpointMonitoring.apply}
        </Button>
      </HostActions>
    </Form>
  )
}

export { HostEdit }
