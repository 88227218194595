import styled from '@emotion/styled'

const MessageContainer = styled.div`
  text-align: center;
  margin: 6rem 0 0;
`

const IconWrap = styled.div`
  display: inline-block;
  text-align: center;
  margin: 0 0 24px;
`

const MessageText = styled.div`
  font-size: 24px;
  color: rgba(0, 0, 0, 0.28);
  font-style: normal;
  font-family: Roboto, sans-serif;
  white-space: pre-line;
`

export { MessageContainer, MessageText, IconWrap }
