import { ReactNode } from 'react'
import Paper from '@mui/material/Paper'

type Props = {
  children: ReactNode
  innerProps?: any
}

export default function Menu({ children, innerProps }: Props) {
  return (
    <Paper
      square
      {...innerProps}
      style={{ position: 'absolute', left: 0, right: 0, top: '100%', zIndex: 1000000, width: '100%' }}
      {...innerProps}
    >
      {children}
    </Paper>
  )
}
