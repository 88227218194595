import { CircularProgress } from '@mui/material'
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import { monitoring as monitoringApi, line as lineApi, checkpoint as checkpointApi } from 'shared/api'
import {
  CheckpointModel,
  InternalService,
  LineInactiveState,
  LineModel,
  LineMonitoringModel,
  PositionModel,
  PositionState,
  PositionType,
  TimeslotModel
} from 'shared/models'
import { Position } from 'features/position-dumb'
import PositionActionsStatic from 'features/position-actions/position-actions-static'
import { lineMonitoringCreate, lineMonitoringEdit, discreteLineMonitoringCreate, checkpointHost } from 'pages/nav'
import { useHistory, useRouteMatch } from 'react-router'
import { useTranslation } from 'shared/i18n/translate'
import { InactiveReasonStyled, InactiveText, MonitoringContainer, SearchContainer } from '../styled'
import produce from 'immer'
import { filterPositionsByTerm } from 'shared/utils/positions_filters'
import { simplifyPositionToFullPositionConvert } from 'features/position/position-converter'
import { SearchInput } from 'features/search-input'
import { OnDuplicatePositionType } from 'features/monitoring/position-card'
import { setDuplicatePosition } from 'features/position-actions/utils/duplicateStorage'
import { LineListMonitoringProps } from '../../types'

function LineListMonitoring({ lineId, placeId }: LineListMonitoringProps) {
  if (!placeId || !lineId) {
    return null
  }

  const [line, setLine] = useState<LineModel>()
  const [checkpoints, setCheckpoints] = useState<CheckpointModel[]>([])
  const [waitingPositionIds, setWaitingPositionIds] = useState<(string | number)[]>([])
  const [selectedId, setSelectedId] = useState<string | number | null>(null)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [monitoring, setMonitoring] = useState<LineMonitoringModel | null>(null)
  const history = useHistory()
  const match = useRouteMatch()
  const { tr } = useTranslation()

  const connect = useRef<{ stop: () => Promise<unknown> }>({ stop: () => Promise.resolve() })

  function handleSearchChange(ev: ChangeEvent<HTMLInputElement>) {
    setSearch(ev.target.value)
  }

  useEffect(() => {
    fetchData(connectDataUpdate)

    return () => {
      connect.current.stop()
    }
  }, [])

  function fetchData(callback?: () => void) {
    setLoading(true)

    Promise.all([
      lineApi.getLine(lineId, placeId),
      checkpointApi.getCheckpointList(placeId, lineId),
      monitoringApi.getLineMonitoring({ placeId, lineId })
    ])
      .then(([line, checkpoints, monitoring]) => {
        setMonitoring(monitoring.data)
        setLine(line.data)
        setCheckpoints(checkpoints.data)
      })
      .finally(() => {
        setLoading(false)
        callback?.()
      })
  }

  function connectDataUpdate() {
    connect.current = monitoringApi.monitoringPositionsChanged(
      { placeId, lineId },
      (srData) => {
        setMonitoring(srData)
        setWaitingPositionIds([])
      },
      () => {
        Promise.all([lineApi.getLine(lineId, placeId), checkpointApi.getCheckpointList(placeId, lineId)]).then(
          ([line, checkpoints]) => {
            setLine(line.data)
            setCheckpoints(checkpoints.data || [])
          }
        )
      }
    )
  }

  function handleRemove(positionId: string | number | undefined) {
    setMonitoring(
      produce((draft) => {
        if (draft === null) {
          return draft
        }

        draft.positions = draft.positions?.filter((dataPosition) => dataPosition.id !== positionId)

        return draft
      })
    )
  }

  function handleUpdate(position: PositionModel) {
    return () => {
      if (position.services?.find((s) => String(s.id) === String(InternalService.Break))) {
        history.push(
          checkpointHost(
            String(placeId),
            String(lineId),
            String(position.customCheckpoint?.id || position.assignedCheckpoint?.id)
          )
        )
      } else {
        history.push(lineMonitoringEdit(String(placeId), String(lineId), String(position.id)))
      }
    }
  }

  function onValidatePosition(positionId: string | number) {
    return () => {
      setSelectedId(null)
      setWaitingPositionIds((curr) => [...curr, positionId])
    }
  }

  function onStateChange(positionId?: string | number) {
    return (newState: PositionState) => {
      setMonitoring(
        produce((draft) => {
          if (draft === null) {
            return draft
          }

          const positionToChange = draft.positions.find((p) => p.id === positionId)

          if (positionToChange) {
            positionToChange.state = newState
          }

          return draft
        })
      )
    }
  }

  const handleDuplicatePosition: OnDuplicatePositionType = (params) => {
    const data = { position: params.position, back: { placeId, lineId: lineId, link: match.url } }

    setDuplicatePosition(params.placeId, data)

    if (params?.line?.isDiscrete) {
      history.push(`${discreteLineMonitoringCreate(String(params.placeId), String(params.line.id))}?duplicate`)
    } else {
      history.push(`${lineMonitoringCreate(String(params.placeId), String(params.line.id))}?duplicate`)
    }
  }

  function handlePositionClick(position: PositionModel) {
    setSelectedId((curr) => (curr === position.id ? null : position.id))
  }

  function closeActions() {
    setSelectedId(null)
  }

  const supportedTypes = useMemo(() => {
    if (!line) {
      return []
    }

    const { allowFutureAppointments } = line

    const defaultTypes = [allowFutureAppointments && PositionType.Appointment, PositionType.Break].filter(Boolean)

    const { modes = [] } = line

    if (modes.length === 0) {
      return defaultTypes
    }

    const positionTypesValues = Object.values(PositionType)

    const modesConverted = modes.map((m) => positionTypesValues.find((pt) => pt.toLowerCase() === m.toLowerCase()))

    return [...modesConverted, ...defaultTypes]
  }, [monitoring])

  const convertedPositions =
    !monitoring || !line
      ? []
      : monitoring.positions.map((position) => {
          const timeSlot: TimeslotModel | undefined = position.timeSlot_from
            ? { id: position.timeSlot_from, from_unixtime: position.timeSlot_from, to_unixtime: position.timeSlot_from }
            : undefined

          return simplifyPositionToFullPositionConvert(
            position,
            line.services,
            monitoring.serverNow,
            tr,
            timeSlot,
            checkpoints
          )
        })

  const filteredPositions = filterPositionsByTerm(convertedPositions, search)
  const supportMobile = !!supportedTypes.find((el) => el === PositionType.MobileApp)

  return (
    <MonitoringContainer key={`${placeId}-${lineId}`}>
      <SearchContainer>
        <SearchInput
          value={search}
          placeholder={tr.common.search}
          onClear={() => setSearch('')}
          onChange={handleSearchChange}
          fullWidth
        />
      </SearchContainer>
      {loading && (
        <div style={{ margin: '0 auto' }}>
          <CircularProgress />
        </div>
      )}
      {!loading && monitoring && line && (
        <>
          {!(monitoring?.positions || []).length && monitoring.lineIsActive === false && (
            <InactiveText>
              <div>{tr.lineMonitoring.close}</div>

              {monitoring.inactiveReason && (
                <InactiveReasonStyled
                  lineId={String(lineId)}
                  shopId={String(placeId)}
                  reason={monitoring.inactiveReason as LineInactiveState}
                />
              )}
            </InactiveText>
          )}
          {filteredPositions.map((position) => (
            <div key={position.id}>
              <Position
                data={position}
                readonly={position.readonly}
                onClick={handlePositionClick}
                onlyASAP={line.asapMode && !line.manageAppointments}
                onlyOneUser={!line.requestParticipantsNumber}
                supportAutoCall={line.supportAutoCall || false}
                timeToReach={line.timeToReach}
                maxTimeToReach={line.maxTimeToReach}
                displayServicePoints={line.displayServicePoints || false}
                lineParticipantTermInPluralTemplate={line?.lineParticipantTermInPluralTemplate}
                lineParticipantTermInSingularTemplate={line?.lineParticipantTermInSingularTemplate}
                additionals={line.additionals || []}
                waitingUpdate={!!waitingPositionIds.find((el) => el === position.id)}
                reducedMobile={selectedId === position.id}
                displayServiceDuration={line.displayServiceDuration || false}
                displayWaitingTimeEstimation={line.displayWaitingTimeEstimation || false}
                monitoringPositionMenuItemCaptions={line.monitoringPositionMenuItemCaptions}
                isOnlyOneServicePoint={checkpoints?.length < 2}
              >
                <PositionActionsStatic
                  opened={selectedId === position.id}
                  onOpen={() => handlePositionClick(position)}
                  position={position}
                  readonly={position.readonly}
                  progressionTags={line.progressionTags || ''}
                  allowMultipleProgressionTags={line.allowMultipleProgressionTags}
                  onValidatePosition={onValidatePosition(position.id)}
                  displayServiceSurvey={line.displayServiceSurvey}
                  sendSurveyLinkAfterPositionServed={line.sendSurveyLinkAfterPositionServed}
                  sendSurveyLinkByEmail={line.sendSurveyLinkByEmail}
                  sendSurveyLinkBySms={line.sendSurveyLinkBySms}
                  surveyTags={line.surveyTags || ''}
                  style={{ width: '100%', marginTop: '0.5rem' }}
                  positionId={position.id}
                  customerId={position.customer?.id}
                  shopId={placeId}
                  lineId={lineId}
                  supportMobile={supportMobile}
                  positionState={position.state}
                  checkpoints={checkpoints}
                  customCheckpointId={position.customCheckpoint?.id}
                  onRemove={handleRemove}
                  onStateChange={onStateChange(position.id)}
                  phoneNumber={position?.callPhoneNumber || position.customer?.phoneNumber}
                  onUpdate={handleUpdate(position)}
                  showButtonCreatePosition={line.showButtonCreatePosition || false}
                  displayInLineHere={line.displayInPlace}
                  displayPositionValidate={line?.displayPositionValidate}
                  displayHistory={line.displayHistory}
                  displayHasApproach={line.displayApproach}
                  displayRemoveConfirm={line.displayRemoveConfirm}
                  sequentiallyPerformingActionsOnPosition={line.sequentiallyPerformingActionsOnPosition}
                  requestCommentsOnRemove={line.requestCommentsOnRemove}
                  setPositionFinishedAfterArrived={line?.setPositionFinishedAfterArrived}
                  displayCallByPhone={line.displayCallByPhone}
                  displayConvertToMobile={line.displayConvertToMobile}
                  requestCommentsOnPriorityCall={line.requestCommentsOnPriorityCall}
                  duplicatePositionMenuItem={line.duplicatePositionMenuItem}
                  onDuplicatePosition={handleDuplicatePosition}
                  generalConditionsAcceptation={line.generalConditionsAcceptation}
                  monitoringPositionMenuItemCaptions={line.monitoringPositionMenuItemCaptions}
                  allowProgressionPhoto={line.allowProgressionPhoto}
                  onClose={closeActions}
                  lineParticipantTermInSingularTemplate={line?.lineParticipantTermInSingularTemplate}
                  positionTransferEnabled={line?.flags?.positionTransferEnabled}
                  supportAutoCall={line?.supportAutoCall}
                  suggestNextOperationOnPosition={line?.flags?.suggestNextOperationOnPosition}
                  closeAfterActionIsFinished
                />
              </Position>
            </div>
          ))}
        </>
      )}
    </MonitoringContainer>
  )
}

export { LineListMonitoring }
