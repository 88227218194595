import { useState } from 'react'
import { useTranslation } from 'shared/i18n/translate'
import Autocomplete from 'shared/ui-kit/autocomplete'
import { DatePicker } from 'features/date'
import css from './journal-filter.module.scss'
import { IconButton } from '@mui/material'
import { Close as CloseIcon, FilterList as FilterIcon } from '@mui/icons-material'
import { Button } from '@mui/material'
import { SearchInput } from 'features/search-input'
import { LayoutProps } from './types'
import { useTimeZoneFilter } from 'features/time-zone-filter'

function AppointmentsFilterLargeLayout(props: LayoutProps) {
  const [open, setOpen] = useState(false)

  const { tr } = useTranslation()

  const { shopId, lineId } = props
  const hasShopId = shopId || shopId === 0
  const hasLineId = lineId || lineId === 0
  const isToday = !!props.isToday
  const isAnyActions = !isToday || !hasLineId || !hasShopId

  useTimeZoneFilter(shopId)

  function onSearch(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    props.onSearch()
  }

  return (
    <form onSubmit={onSearch} style={{ padding: '4.5rem 0 0.5rem', width: '100%', boxSizing: 'border-box' }}>
      <div className={css.bar} style={{ position: open ? 'absolute' : 'fixed' }}>
        <div style={{ display: 'flex', height: 64, alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
          <SearchInput
            value={props.filter.term}
            fullWidth
            placeholder={tr.reportsFilter.search}
            onChange={(ev) => props.onFilterChange('term', ev.target.value)}
            onClear={() => props.onFilterChange('term', '')}
          />
          <div style={{ padding: '0.5rem' }}>
            <Button variant="contained" size="small" color="primary" type="submit">
              {tr.journal.search}
            </Button>
          </div>
          {isAnyActions && (
            <IconButton
              onClick={() => {
                window.scrollTo(0, 0)
                setOpen(!open)
              }}
            >
              {open ? <CloseIcon /> : <FilterIcon />}
            </IconButton>
          )}
        </div>
        {open && (
          <>
            {!hasShopId && (
              <div style={{ padding: '0.5rem' }}>
                <Autocomplete
                  fullWidth
                  ensureValue={!props.filter.shopId}
                  title={tr.common.shop}
                  value={props.filter.shopId}
                  list={props.shops}
                  style={{ textOverflow: 'ellipsis' }}
                  dataConverter={(el) => ({ text: el.name, value: el.id })}
                  onChange={(shopId) => props.onFilterChange('shopId', shopId)}
                />
              </div>
            )}
            {!hasLineId && (
              <div style={{ padding: '0.5rem' }}>
                <Autocomplete
                  fullWidth
                  ensureValue={!props.filter.lineId}
                  title={tr.common.line}
                  value={props.filter.lineId}
                  list={props.lines}
                  style={{ textOverflow: 'ellipsis' }}
                  dataConverter={(el) => ({ text: el.name, value: el.id })}
                  onChange={(lineId) => props.onFilterChange('lineId', lineId)}
                />
              </div>
            )}
            {!isToday && (
              <>
                <div style={{ padding: '0.5rem' }}>
                  <DatePicker
                    label={tr.reportsFilter.startDate}
                    style={{ marginBottom: 0, width: '100%' }}
                    onChange={(date) => {
                      const isStartDateMoreEndDate = date > props.filter.endDate

                      if (isStartDateMoreEndDate) {
                        props.onFilterChange('startDate', date, () => props.onFilterChange('endDate', date))
                      } else {
                        props.onFilterChange('startDate', date)
                      }
                    }}
                    value={props.filter.startDate}
                  />
                </div>
                <div style={{ padding: '0.5rem' }}>
                  <DatePicker
                    label={tr.reportsFilter.endDate}
                    style={{ marginBottom: 0, width: '100%' }}
                    onChange={(date) => {
                      const isEndDateLessStartDate = date < props.filter.startDate

                      if (isEndDateLessStartDate) {
                        props.onFilterChange('endDate', date, () => props.onFilterChange('startDate', date))
                      } else {
                        props.onFilterChange('endDate', date)
                      }
                    }}
                    value={props.filter.endDate}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
      <div> {props.children}</div>
    </form>
  )
}

export default AppointmentsFilterLargeLayout
