import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useTranslation } from 'shared/i18n/translate'
import { advertisements } from 'pages/nav'
import { advertisement as advertisementApi, translations as translationsApi } from 'shared/api'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { setBreadcrumbs, showMessage, MessageTypes } from 'store/actions/main-layout-action-creators'
import { AdvertisementModel, AdvertisementModelEdit } from 'shared/models'
import { Container } from 'shared/ui-kit/container'
import PageLoader from 'shared/ui-kit/page-loader'
import { AdvertisementEdit } from './dumb/advertisement-edit'
import { useAppDispatch } from 'store'
import { useQuery } from 'react-query'

function SmartAdvertisementEdit() {
  const history = useHistory()
  const { advertisementId, campaignId } = useParams<{ campaignId: string; advertisementId: string }>()
  const dispatch = useAppDispatch()
  const { tr, locale } = useTranslation()

  const [processing, setProcessing] = useState(false)

  const { data: res, isFetching } = useQuery(['advertisement', advertisementId], ({ signal }) =>
    advertisementApi.getAdvertisement(advertisementId, campaignId, { signal })
  )

  const { data: configRes, isLoading: configIsLoading } = useQuery(['translationConfig', campaignId], ({ signal }) =>
    translationsApi.getTranslationConfig({ campaignId }, { signal })
  )

  function createAdv(data: AdvertisementModelEdit) {
    setProcessing(true)

    advertisementApi
      .createAdvertisement(data)
      .then(() => {
        dispatch(showMessage(tr.advertisementOperationMessages.createSuccess, MessageTypes.Success))
        history.push(advertisements(campaignId))
      })
      .catch(() => {
        dispatch(showMessage(tr.advertisementOperationMessages.createError, MessageTypes.Error))
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  function updateAdv(data: AdvertisementModel) {
    setProcessing(true)

    advertisementApi
      .updateAdvertisement(data)
      .then(() => {
        dispatch(showMessage(tr.advertisementOperationMessages.updateSuccess, MessageTypes.Success))
        history.push(advertisements(campaignId))
      })
      .catch(() => {
        dispatch(showMessage(tr.advertisementOperationMessages.updateError, MessageTypes.Error))
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  function deleteAdv(id: string | number) {
    setProcessing(true)

    advertisementApi
      .deleteAdvertisement(id)
      .then(() => {
        dispatch(showMessage(tr.advertisementOperationMessages.deleteSuccess, MessageTypes.Success))
        history.push(advertisements(campaignId))
      })
      .catch(() => {
        dispatch(showMessage(tr.advertisementOperationMessages.deleteError, MessageTypes.Error))
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  function handleSave(data: AdvertisementModelEdit) {
    if (!advertisementId) {
      createAdv({ ...data, campaignId, id: undefined })
    } else {
      updateAdv(data as AdvertisementModel)
    }
  }

  useEffect(() => {
    if (!res?.parents) {
      return
    }

    dispatch(setBreadcrumbs(getBreadcrumbs()))
  }, [locale, res])

  function getBreadcrumbs() {
    if (!res?.parents) {
      return []
    }

    const { campaign } = res?.parents || {}
    const crumb = getCrumbs(tr.breadcrumbs)

    const updateTail = res?.data
      ? [crumb.editAdvertisement([res?.data.campaignId as string, res?.data.id as string], res?.data.name)]
      : []
    const createTail = [crumb.newAdvertisement([campaignId])]

    return [
      crumb.home(),
      crumb.campaigns(),
      crumb.campaign([campaign.id as string], campaign.name),
      crumb.advertisements([campaign.id as string]),
      ...(advertisementId ? updateTail : createTail)
    ]
  }

  if (isFetching || configIsLoading) {
    return <PageLoader />
  }

  if (!res?.data || !configRes?.data) {
    return null
  }

  return (
    <Container>
      <AdvertisementEdit
        languages={configRes.data.languages || []}
        data={res.data}
        onSave={(data) => handleSave(data)}
        onDelete={(data) => deleteAdv(data)}
        processing={processing}
      />
    </Container>
  )
}

export default SmartAdvertisementEdit
