import { AppointmentSkeletonContent, AppointmentSkeletonElement } from './styled'

export function AppointmentsSkeleton() {
  return (
    <div>
      <AppointmentSkeletonElement>
        <AppointmentSkeletonContent style={{ width: 200 }} />
        <AppointmentSkeletonContent style={{ width: 16 }} />
      </AppointmentSkeletonElement>
      <AppointmentSkeletonElement>
        <AppointmentSkeletonContent style={{ width: 200 }} />
        <AppointmentSkeletonContent style={{ width: 16 }} />
      </AppointmentSkeletonElement>
      <AppointmentSkeletonElement>
        <AppointmentSkeletonContent style={{ width: 200 }} />
        <AppointmentSkeletonContent style={{ width: 16 }} />
      </AppointmentSkeletonElement>
    </div>
  )
}
