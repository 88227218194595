import { Button, TextField, Typography } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import { RemoveConfirmFormProps } from './types'
import { FormEvent, useState } from 'react'
import { ActionFormActions } from '../styled'

function RemoveConfirmForm({ onBack, isCardView, onSubmit }: RemoveConfirmFormProps) {
  const { tr } = useTranslation()
  const [removeComment, setRemoveComment] = useState('')

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
    onSubmit(removeComment)
  }

  return (
    <>
      <Typography variant="body1" fontWeight={500} fontSize="18px">
        {tr.positionActions.remove}
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          style={{ marginTop: '1rem' }}
          fullWidth
          value={removeComment}
          label={tr.positionActions.removeForm.removeReason}
          onChange={(ev) => setRemoveComment(ev.target.value)}
          onKeyDown={(event) => event.stopPropagation()}
          autoFocus
          InputLabelProps={{ shrink: true }}
        />
        <ActionFormActions $marginTop={isCardView ? '1rem' : '0.5rem'}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!removeComment || removeComment.length < 3}
            size="small"
          >
            {tr.positionActions.removeForm.remove}
          </Button>
          <Button onClick={onBack} size="small">
            {tr.positionActions.removeForm.declineRemove}
          </Button>
        </ActionFormActions>
      </form>
    </>
  )
}

export { RemoveConfirmForm }
