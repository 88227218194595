import styled from '@emotion/styled'

const ActionsWrapper = styled.div`
  margin: 1rem 0 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
`

const ActionText = styled.div`
  margin: 0.5rem 0;
  flex: 1;
`

const ConfirmationActions = styled.div`
  display: flex;
  gap: 0.5rem;
`

const ButtonWithTextWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`

export { ActionsWrapper, ActionText, ConfirmationActions, ButtonWithTextWrapper }
