import { useState } from 'react'
import { withMods } from 'shared/utils/cssm'
import css from './timeline.module.scss'
import ExpandMoreIcon from '@mui/icons-material/Add'
import ExpandLessIcon from '@mui/icons-material/Remove'
import { TimelineComponentProps } from './types'

const mod = withMods(css)

function TimelineComponent<T>(props: TimelineComponentProps<T>) {
  const { data: steps, itemConverter } = props
  const [openedSteps, setOpenedSteps] = useState<Record<number, boolean>>({})

  function handleStepClick(index: number) {
    setOpenedSteps({ ...openedSteps, [index]: !openedSteps[index] })
  }
  return (
    <div className={css.root}>
      {steps.map((step, i) => {
        const item = itemConverter(step, steps[i - 1])
        const cls = mod.step({ last: i === steps.length - 1, isdescription: Boolean(item.description), first: i === 0 })

        return (
          <div
            key={i}
            className={cls}
            style={{ cursor: item.description && 'pointer' }}
            onClick={() => handleStepClick(i)}
          >
            <div className={css.mainInfo}>
              <div className={css.time}>
                {item.date && (
                  <div
                    style={{ fontSize: '13px', fontWeight: 500, opacity: '0.86', marginTop: '0.5rem', width: '68px' }}
                  >
                    {item.date}
                  </div>
                )}
                <div style={{ marginTop: item.date ? 8 : 0, opacity: '0.56', fontSize: '13px' }}>{item.time}</div>
              </div>
              {Boolean(!item.description) && <div className={css.point} />}
              {Boolean(item.description) && (
                <div className={mod.arrow({ isopened: Boolean(openedSteps[i]) })}>
                  {Boolean(!openedSteps[i]) && (
                    <ExpandMoreIcon fontSize="small" style={{ color: '#FFF', width: '14px', height: '14px' }} />
                  )}
                  {Boolean(openedSteps[i]) && (
                    <ExpandLessIcon fontSize="small" style={{ color: '#FFF', width: '14px', height: '14px' }} />
                  )}
                </div>
              )}
              <div className={css.text}>
                {item.text}
                {Boolean(item.renderUnderText) && <div>{item.renderUnderText}</div>}
              </div>
            </div>
            {Boolean(openedSteps[i] && item.description) && <div className={css.description}>{item.description}</div>}
          </div>
        )
      })}
    </div>
  )
}

export default TimelineComponent
export const Timeline = TimelineComponent
