import { ReactNode } from 'react'
import Typography from '@mui/material/Typography'

type Props = {
  children: ReactNode
}

export default function Placeholder({ children }: Props) {
  return <Typography color="textSecondary"> {children} </Typography>
}
