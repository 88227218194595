import { useRef, useEffect } from 'react'
import { Typography } from '@mui/material'
import { CheckpointItem } from './components/checkpoint-item'
import { CheckpointListComponentProps } from '../types'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { ListDnd } from 'shared/ui-kit/list'
import { timeText } from './components/time-text'
import { useTranslation } from 'shared/i18n/translate'
import { CheckpointModel } from 'shared/models'

function CheckpointListComponent(props: CheckpointListComponentProps) {
  const { tr } = useTranslation()

  const itemsRefs = useRef<{ [id: number]: HTMLDivElement | null }>({})

  useEffect(() => {
    if (props.checkpointId) {
      itemsRefs.current[props.checkpointId]?.scrollIntoView({ block: 'center' })
    }
  }, [props.data])

  function dndListDataConverter(el: CheckpointModel) {
    const primaryText = [
      !props.showLine && el.lineName && `${el.lineName}, `,
      el.name,
      el.isAnyHourOpened && ` — ${tr.checkpointList.allTime}`,
      !el.isAnyHourOpened && timeText(el.operatingFrom, el.operatingTo, el.dinnerStartTime, el.dinnerStopTime),
      !!el.hostName && ` — ${el.hostName}`
    ]
      .filter(Boolean)
      .join('')

    return {
      id: el.id,
      primaryText: (
        <Typography fontWeight="medium" variant="body2">
          {primaryText}
        </Typography>
      )
    }
  }

  function handleDropEnd(items: CheckpointModel[]) {
    props.onDropEnd?.(items)
  }

  return (
    <DndProvider backend={HTML5Backend} key="checkpoint-list">
      {!props.orderChangeMode && (
        <>
          {props.data.map((data) => (
            <CheckpointItem
              data={data}
              refDep={(ref) => (itemsRefs.current[data.id] = ref)}
              checkpointId={props.checkpointId}
              isDiscreteLine={props.isDiscrete}
              showEditHostIcon={props.showEditHostIcon}
              showLine={props.showLine}
              key={data.id}
            />
          ))}
        </>
      )}
      {!!props.orderChangeMode && (
        <ListDnd data={props.data} onDropEnd={handleDropEnd} dataConverter={dndListDataConverter} />
      )}
    </DndProvider>
  )
}

export default CheckpointListComponent
