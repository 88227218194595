import { useEffect, useRef, useState } from 'react'
import { users, index, createUser } from 'pages/nav'
import { UserEdit } from './edit-form'
import { WarningNotification } from 'shared/ui-kit/warning-notification'
import { getAndStoreUserProfile } from 'store/actions/user-action-creators'
import { useTranslation } from 'shared/i18n/translate'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { showMessage, MessageTypes } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import CircularProgress from '@mui/material/CircularProgress'
import { CenterContainer } from 'shared/ui-kit/center-container'
import Container from 'shared/ui-kit/container'
import { prepareUserEntity, userCopyStorageKey } from './utils'
import { LineModelSimplified, NewUserModel, PlaceModel, UserCopyModel, UserModel } from 'shared/models'
import { useHistory, useLocation, useParams } from 'react-router'
import { getUserSelector } from 'store/selectors/userSelectors'
import { useDataProvider } from 'features/isolated-data-provider'
import { user as userApi, line as lineApi } from 'shared/api'
import i18n from 'shared/i18n'
import { useAppDispatch, useAppSelector } from 'store'
import { setInsecurePassword } from 'store/reducers/user-reducer'

function SmartUserEdit() {
  const [data, setData] = useState<UserModel | NewUserModel>()
  const [loading, setLoading] = useState(true)
  const params = useParams<{ userId?: string }>()
  const history = useHistory()
  const { tr, locale } = useTranslation()
  const profile = useAppSelector(getUserSelector)
  const dispatch = useAppDispatch()
  const { places } = useDataProvider()

  const location = useLocation()
  const isDuplicate = new URLSearchParams(location.search).get('duplicate') !== null
  const duplicateDataString = sessionStorage.getItem(userCopyStorageKey)

  const [lines, setLines] = useState<{ [key: PlaceModel['id']]: LineModelSimplified[] }>({})

  const spikeRef = useRef<{ [key: PlaceModel['id']]: boolean }>({})

  function fetchLineList(shopId: PlaceModel['id'], callback?: () => void) {
    lineApi.getLineListSimplified(shopId).then((response) => {
      setLines((prev) => ({ ...prev, [shopId]: response.data }))
      callback?.()
    })
  }

  const isNew = !params.userId
  const isSelf = params.userId === profile?.id

  useEffect(() => {
    if (!isSelf && !profile?.permissions.canManageUserAccounts) {
      history.push(index())
      return
    }

    fetchUser()
  }, [])

  useEffect(() => {
    if (isSelf && (data as UserModel)?.insecurePassword && !profile?.insecurePassword) {
      history.push(index())
    }
  }, [profile?.insecurePassword])

  async function fetchUser() {
    setLoading(true)

    try {
      const res = await userApi.getUser(params.userId)

      if (isDuplicate && isNew && duplicateDataString) {
        try {
          const duplicateData = JSON.parse(duplicateDataString)
          const data = { ...res.data, ...duplicateData }
          setData(data)
        } catch (err) {
          console.log('[ERROR] Unable to extract user data')
          setData(res.data)
        }
      } else {
        setData(res.data)
      }

      initBreadcrumbs(res.data as UserModel)
    } catch (err) {
      history.push(index())
    } finally {
      setLoading(false)
    }
  }

  function initBreadcrumbs(data: UserModel) {
    const crumb = getCrumbs(tr.breadcrumbs)

    if (!isSelf) {
      dispatch(
        setBreadcrumbs([crumb.home(), crumb.users(), data.id ? crumb.editUser([String(data.id)]) : crumb.newUser()])
      )
    } else {
      dispatch(setBreadcrumbs([crumb.home(), crumb.userSettings()]))
    }
  }

  async function handleCreate(data: UserModel) {
    try {
      await userApi.createUser(prepareUserEntity(data))
      dispatch(showMessage(tr.userOperationMessages.createSuccess, MessageTypes.Success))
      history.push(users())
    } catch (err) {
      dispatch(showMessage(tr.userOperationMessages.createError, MessageTypes.Error))
    }
  }

  async function handleDelete(id: string | number) {
    try {
      await userApi.deleteUser(id)
      dispatch(showMessage(tr.userOperationMessages.deleteSuccess, MessageTypes.Success))
      history.push(users())
    } catch (err) {
      dispatch(showMessage(tr.userOperationMessages.deleteError, MessageTypes.Error))
    }
  }

  async function handleUpdate(data: UserModel) {
    try {
      await userApi.updateUser(prepareUserEntity(data))
      dispatch(showMessage(tr.userOperationMessages.updateSuccess, MessageTypes.Success))

      if (isSelf && data.insecurePassword) {
        dispatch(setInsecurePassword(false))
      }

      if (isSelf) {
        setTimeout(() => {
          history.push(index())
          dispatch(getAndStoreUserProfile())
        }, 100)
      } else {
        history.push(users())
      }
    } catch (err) {
      dispatch(showMessage(tr.userOperationMessages.updateError, MessageTypes.Error))
    }
  }

  function handleUserCopy(data: UserCopyModel) {
    sessionStorage.setItem(userCopyStorageKey, JSON.stringify(data))
    window.open('#' + createUser() + '?duplicate', '_blank')
  }

  const languages = i18n.getLanguages()

  if (loading) {
    return (
      <CenterContainer>
        <CircularProgress color="secondary" />
      </CenterContainer>
    )
  }

  if (!data) {
    return null
  }

  return (
    <Container>
      {!isNew && (
        <WarningNotification
          style={{ marginBottom: '1rem' }}
          isDisplay={(data as UserModel)?.insecurePassword || false}
        >
          {tr.userOperationMessages.passwordNotSecure}
        </WarningNotification>
      )}
      <UserEdit
        data={
          {
            interfaceLocale: isNew && languages?.find((l) => l.locale === locale) ? locale : undefined,
            ...data
          } as UserModel
        }
        shops={places}
        languages={languages}
        canEditShops={!isSelf}
        canEditRights={
          !isSelf && !!(profile?.permissions.canPromoteToAdmin || profile?.permissions.canManageUserAccounts)
        }
        canDelete={profile?.permissions.canManageUserAccounts || false}
        canPromoteToAdmin={profile?.permissions.canPromoteToAdmin || false}
        canManageUserAccounts={profile?.permissions.canManageUserAccounts || false}
        insecurePassword={(data as UserModel)?.insecurePassword || false}
        isNew={isNew}
        isSelf={isSelf}
        onSave={isNew ? handleCreate : handleUpdate}
        onDelete={isNew ? undefined : handleDelete}
        lines={lines}
        onRequestLines={(shopId) => {
          if (!spikeRef.current[shopId]) {
            fetchLineList(shopId, () => {
              spikeRef.current[shopId] = false
            })
            spikeRef.current[shopId] = true
          }
        }}
        onCopy={handleUserCopy}
      />
    </Container>
  )
}

export default SmartUserEdit
