import Tile, { TileContainer } from 'features/tile'
import * as Nav from 'pages/nav'
import { useTranslation } from 'shared/i18n/translate'
import ExportIcon from '@mui/icons-material/CloudDownload'
import TemplateIcon from '@mui/icons-material/Message'
import BluetoothIcon from '@mui/icons-material/Bluetooth'
import PlaceIcon from '@mui/icons-material/Place'
import LinearIcon from '@mui/icons-material/LinearScale'
import BasketIcon from '@mui/icons-material/ShoppingBasket'
import DaysOffIcon from '@mui/icons-material/Event'
import { addTestAttr } from 'shared/utils/test-attr'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { LaptopChromebook as TerminalIcon, ControlPointDuplicate as CounterIcon } from '@mui/icons-material'
import { useAppSelector } from 'store'
import { PlaceTilesProps } from './types'

function PlaceTiles({ place, inline, children }: PlaceTilesProps) {
  const permissions = useAppSelector(getPermissionsSelector)
  const {
    manageMessagesTemplates,
    manageReports,
    accessBeacons,
    accessCounters,
    accessDaysOff,
    accessZones,
    manageTerminals
  } = permissions

  const { tr } = useTranslation()
  const placeId = String(place.id)

  const { zones, beacons, lines, checkpoints, daysOff, exportData, counters, messageTemplates, terminals } =
    tr.placeView

  return (
    <TileContainer inline={inline}>
      <Tile
        {...addTestAttr('PlaceTiles-Lines')}
        title={lines(place.lineCount)}
        count={place.lineCount}
        iconClass={LinearIcon}
        linkTo={Nav.lines(placeId)}
      />
      <Tile
        {...addTestAttr('PlaceTiles-Checkpoints')}
        title={checkpoints(place.checkpointCount)}
        count={place.checkpointCount}
        iconClass={BasketIcon}
        linkTo={Nav.checkpoints(placeId)}
      />
      {manageReports && place.showDataExport && (
        <Tile
          {...addTestAttr('PlaceTiles-ExportData')}
          title={exportData}
          iconClass={ExportIcon}
          linkTo={Nav.exportData(placeId)}
        />
      )}
      {manageTerminals && (
        <Tile
          {...addTestAttr('PlaceTiles-Terminals')}
          title={terminals(place.terminalCount)}
          count={place.terminalCount}
          iconClass={TerminalIcon}
          linkTo={Nav.terminals(placeId)}
        />
      )}
      {manageMessagesTemplates && (
        <Tile
          {...addTestAttr('PlaceTiles-MessageTemplates')}
          title={messageTemplates}
          iconClass={TemplateIcon}
          linkTo={Nav.placeMessageTemplates(placeId)}
        />
      )}
      {accessCounters && place.showCounters && (
        <Tile
          {...addTestAttr('PlaceTiles-Counters')}
          title={counters}
          iconClass={CounterIcon}
          count={place.counterCount}
          linkTo={Nav.counters(placeId)}
        />
      )}
      {accessZones && place.showZones && (
        <Tile
          {...addTestAttr('PlaceTiles-ZoneEdit')}
          title={zones(place.zoneCount)}
          count={place.zoneCount}
          iconClass={PlaceIcon}
          linkTo={Nav.zones(placeId)}
        />
      )}
      {accessDaysOff && place.showDaysOff && (
        <Tile
          {...addTestAttr('PlaceTiles-DaysOff')}
          count={place.daysOffCount}
          title={daysOff}
          iconClass={DaysOffIcon}
          linkTo={Nav.daysOff(placeId)}
        />
      )}
      {accessBeacons && place.showBeacons && (
        <Tile
          {...addTestAttr('PlaceTiles-Beacons')}
          title={beacons(place.beaconCount)}
          count={place.beaconCount}
          iconClass={BluetoothIcon}
          linkTo={Nav.beacons(placeId)}
        />
      )}
      {children}
    </TileContainer>
  )
}

export default PlaceTiles
