import { useEffect, useState } from 'react'
import { storeUrl } from 'shared/utils/history'
import axios from 'axios'
import { Route, RouteProps } from 'react-router-dom'

function RenderingComponent({ Component, action, props, render }: any) {
  const [path, setPath] = useState<string>('')

  useEffect(() => {
    axios.defaults.headers.get['X-Form-Action'] = action
    storeUrl(path)
    setPath(props.location.pathname)
  }, [props.location.pathname])

  if (path !== props.location.pathname) {
    return null
  }

  if (render) {
    return render()
  }

  return <Component {...props} />
}

type FormActions = 'View' | 'Edit' | 'None'
type WRouteProps = { action?: FormActions } & RouteProps

function WRoute({ component, action = 'None', render, ...rest }: WRouteProps) {
  return (
    <Route
      {...rest}
      render={(props) => <RenderingComponent render={render} props={props} Component={component} action={action} />}
    />
  )
}

export { RenderingComponent, WRoute }
