import Text from 'shared/ui-kit/text'
import { Form, FormGroup } from 'shared/ui-kit/form'
import { useTranslation } from 'shared/i18n/translate'
import { List } from 'shared/ui-kit/list'
import Divider from '@mui/material/Divider'
import { CampaignStatuses } from 'shared/models'
import { CampaignViewProps } from '../types'

function CampaignView({ data, onShopSelect, shops }: CampaignViewProps) {
  const { tr, dateFormatter } = useTranslation()

  const statusText = {
    [CampaignStatuses.PASSED]: tr.campaignView.passed,
    [CampaignStatuses.WAITING]: tr.campaignView.waiting,
    [CampaignStatuses.RUNNING]: tr.campaignView.running
  }

  return (
    <Form>
      <FormGroup>
        <Text type="caption">{tr.campaignView.name}</Text>
        <Text type="body-1">{data.name}</Text>
      </FormGroup>
      <FormGroup>
        <Text type="caption">{tr.campaignView.period}</Text>
        <Text type="body-1">
          {dateFormatter(data.startDate)} - {dateFormatter(data.endDate)}
        </Text>
      </FormGroup>
      {Boolean(data.displayDuration) && (
        <FormGroup>
          <Text type="caption">{tr.campaignView.advertisementsDisplayDuration}</Text>
          <Text type="body-1">{tr.campaignView.seconds(data.displayDuration || 0)}</Text>
        </FormGroup>
      )}
      <FormGroup>
        <Text type="caption">{tr.campaignView.status}</Text>
        <Text type="body-1">{statusText[data.status]}</Text>
      </FormGroup>
      <Divider />
      {data.shopIds?.length > 0 && (
        <Form title={tr.campaignView.shops} expandable>
          <List
            onSelect={onShopSelect}
            data={data.shopIds}
            dataConverter={(id) => ({
              id,
              primaryText: shops.find((shop) => String(shop.id) === String(id))?.name || ''
            })}
          />
        </Form>
      )}
      <Divider />
    </Form>
  )
}

export { CampaignView }
