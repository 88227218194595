import { useEffect, useState } from 'react'
import { CounterView } from './dumb/counter-view'
import { editCounter } from 'pages/nav'
import { useTranslation } from 'shared/i18n/translate'
import { useTimeZoneFilter } from 'features/time-zone-filter'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { Button, Divider } from '@mui/material'
import Card from 'shared/ui-kit/card'
import EntityActions from 'shared/ui-kit/entity-actions'
import ModeEdit from '@mui/icons-material/Edit'
import Container from 'shared/ui-kit/container'
import { useHistory, useParams } from 'react-router'
import PageLoader from 'shared/ui-kit/page-loader/page-loader'
import { counter } from 'shared/api'
import { CounterModel, GetResponseType } from 'shared/models'
import { useDataProvider } from 'features/isolated-data-provider'
import { useTitle } from 'shared/hooks/useTitle'
import { useAppDispatch } from 'store'

function SmartCounterView() {
  const dispatch = useAppDispatch()
  const { counterId, placeId } = useParams<{ placeId: string; counterId: string }>()
  const history = useHistory()
  const { tr, locale } = useTranslation()

  const [entity, setData] = useState<GetResponseType<CounterModel | undefined>>()
  const [loading, setLoading] = useState<boolean>(false)

  const { getPlace } = useDataProvider()
  useTitle({ placeName: getPlace(placeId)?.name })

  useTimeZoneFilter(placeId)

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (!entity) {
      return
    }

    const { data, parents } = entity

    if (!data || !parents) {
      return
    }

    const crumb = getCrumbs(tr.breadcrumbs)
    dispatch(
      setBreadcrumbs([
        crumb.home(),
        crumb.places(),
        crumb.place([placeId], parents.shop?.name),
        crumb.counters([placeId]),
        crumb.counter([placeId, counterId], data?.name)
      ])
    )
  }, [entity, locale])

  async function fetchData() {
    setLoading(true)

    try {
      const data = await counter.getCounter(counterId, placeId)
      setData(data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  if (loading) {
    return <PageLoader />
  }

  if (!entity || !entity.data) {
    return null
  }

  return (
    <Container>
      <Card>
        <CounterView data={entity.data} />
        <Divider />
        <EntityActions
          showButtons
          buttons={
            <Button onClick={() => history.push(editCounter(placeId, counterId))} startIcon={<ModeEdit />}>
              {tr.entityView.editButton}
            </Button>
          }
        />
      </Card>
    </Container>
  )
}

export default SmartCounterView
