import Text from 'shared/ui-kit/text'
import { Color } from 'shared/ui-kit/color-picker'
import { Form, FormGroup } from 'shared/ui-kit/form'
import { useTranslation } from 'shared/i18n/translate'
import { lineCategory } from '../line-category'
import { getModName } from './utils/translation'
import { ServiceMode } from 'shared/models'
import { SuggestedClientsStrategy } from 'shared/models'
import { Tooltip, Divider, Chip } from '@mui/material'
import { Progressions } from './styled'
import { ScheduleView } from 'features/schedule-view'
import { LineViewProps } from '../types'

function LineView({ data: d }: LineViewProps) {
  const { tr } = useTranslation()

  const {
    name,
    category,
    serviceTime,
    timeToReach,
    maxTimeToReach,
    time,
    seconds,
    hours,
    day,
    manageAppointments,
    manageAppointmentsValue,
    requestParticipantsNumber,
    requestParticipantsNumberValue,
    maxSimultaneous,
    maxSimultaneousValue,
    modes,
    allowFutureAppointments,
    progressivelyBooking,
    limitInDays,
    asapMode,
    minTimeToFreePlaceAfterEarlyAcceptance,
    attachIcsFile,
    authoriseAppointmentOnWebPortal,
    blindCopyEmails,
    timeToSetFreeStockPlaces,
    displayReservationQRCode,
    sendSMSWhenJoined,
    sendSMSWhenCalled,
    smsSenderId,
    lineCalculationAlgorithm,
    lineCalculationAlgorithmModes,
    bluetoothRequired,
    calculateApproachByGPS,
    nearServicePointDistance,
    nearServicePointDistanceValue,
    parameters,
    appointmentForNextDay,
    openingHours,
    durations,
    servicesCollection,
    reservationCodeGenerationStrategy,
    reservationCodeGenerationStrategies,
    autoCall,
    visibleInWebPortal,
    confirmEmailWithValidationCode,
    displayEndServiceSurveyOperator,
    surveyTags,
    privateService,
    removeUnalertedPositionAfter,
    daysLimitForAllTimeslotsAvailables,
    allowMultipleAppointmentsOnSameDay,
    closeAllServicePointsAtMidnight,
    requestCommentOnClosingServicePoint,
    closeAllServicePointsAtMidnightValue,
    checkCustomerNameUnicity,
    limitInDaysForFutureDateAdmin,
    retainTimeslotWhenAppointmentCancelled,
    minDurationToFreeTimeslot,
    maxDurationToFreeTimeslot,
    monitoringGroup,
    mobileAppGroup,
    sequentiallyPerformingActionsOnPosition,
    displayWaitingTimeEstimationMonitoring,
    displayServiceDuration,
    displayServicePoints,
    displayServiceNameForOperator,
    displayReservationCode,
    displayCalledServicePoint,
    displayApproach,
    operationsMenu,
    displayDuplicate,
    displayCallByPhone,
    displayConvertToMobile,
    displayInPlace,
    setArrivedAfterCallFinished,
    displayRemoveConfirm,
    requestCommentsOnPriorityCall,
    requestCommentsOnRemove,
    displayHistory,
    supportSearchCustomerByPhone,
    displayPersonalInfoInReadOnlyMode,
    displayWaitingTimeEstimationMobile,
    displayTotalPeopleInLineCount,
    displayPeopleBeforeMeCount,
    suggestOtherPlaces,
    lineAppointmentLink,
    tags,
    additionalFields,
    webViewAgentRequestStatisticsLink,
    sendMesageSms,
    sendMesage,
    messageSmsDaysBefore,
    acceptTimeslotsOverlapForSameCustomer,
    displayPositionValidate,
    messageDaysBefore,
    progressionTags,
    description
  } = tr.lineView

  const {
    positionJoinStrategyLabel,
    positionJoinStrategyVariants,
    suggestSamePositionsForAllServicePoints,
    suggestedClientsStrategy,
    generalConditionsAcceptation,
    servicePointCanAlwaysBeOpen,
    shortName,
    displayShortName
  } = tr.lineEdit

  const { booleanValue } = tr.common

  function getServiceDurationTime(timeValue: number) {
    const hoursValue = Math.floor(timeValue / 60 / 60)
    const minutesValue = Math.floor(timeValue / 60 - hoursValue * 60)
    const secondsValue = timeValue % 60

    const result: string[] = []
    if (hoursValue) {
      result.push(hours(hoursValue))
    }

    if (minutesValue) {
      result.push(time(minutesValue))
    }

    if (secondsValue) {
      result.push(seconds(secondsValue))
    }

    return result.join(' ')
  }

  return (
    <Form>
      <Form title={parameters} expandable>
        <FormGroup>
          <Text type="caption">{name}</Text>
          <Text type="body-1">{d.name}</Text>
        </FormGroup>
        {!!d.description && (
          <FormGroup>
            <Text type="caption">{description}</Text>
            <Text type="body-1">{`${d.description.split('\n')[0]}...`}</Text>
          </FormGroup>
        )}
        {!!d.displayShortName && (
          <FormGroup>
            <Text type="caption">{displayShortName}</Text>
            <Text type="body-1">{d.displayShortName}</Text>
          </FormGroup>
        )}
        {!!d.shortName && (
          <FormGroup>
            <Text type="caption">{shortName}</Text>
            <Text type="body-1">{d.shortName}</Text>
          </FormGroup>
        )}
        {!!d.category && (
          <FormGroup>
            <Color color={lineCategory.getColor(d.category)} title={category} />
          </FormGroup>
        )}
        {!!d.webViewAgentRequestStatisticsLink && (
          <FormGroup>
            <Text type="caption">{webViewAgentRequestStatisticsLink}</Text>
            <a href={d.webViewAgentRequestStatisticsLink} style={{ wordBreak: 'break-all', fontSize: '13px' }}>
              {d.webViewAgentRequestStatisticsLink}
            </a>
          </FormGroup>
        )}
        {!!d.tags?.length && (
          <FormGroup>
            <Text type="caption">{tags}</Text>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
              {d.tags.map((t) => (
                <Tooltip arrow key={t.id} title={[t.name.translated, t.name.base].filter(Boolean).join('/')}>
                  <Chip variant="outlined" label={t.name.translated || t.name.base} />
                </Tooltip>
              ))}
            </div>
          </FormGroup>
        )}
        {!!d.lineCalculationAlgorithmMode && (
          <FormGroup>
            <Text type="caption">{lineCalculationAlgorithm}</Text>
            <Text type="body-1">{lineCalculationAlgorithmModes[d.lineCalculationAlgorithmMode]}</Text>
          </FormGroup>
        )}
        {!!d.positionJoinStrategy && (
          <FormGroup>
            <Text type="caption">{positionJoinStrategyLabel}</Text>
            <Text type="body-1">{positionJoinStrategyVariants[d.positionJoinStrategy]}</Text>
          </FormGroup>
        )}
        {!!d.reservationCodeGenerationStrategy && (
          <FormGroup>
            <Text type="caption">{reservationCodeGenerationStrategy}</Text>
            <Text type="body-1">{reservationCodeGenerationStrategies[d.reservationCodeGenerationStrategy]}</Text>
          </FormGroup>
        )}
        <FormGroup>
          <Text type="caption">{asapMode}</Text>
          <Text type="body-1">{manageAppointmentsValue(d.asapMode)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{manageAppointments}</Text>
          <Text type="body-1">{manageAppointmentsValue(d.manageAppointments)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{requestParticipantsNumber}</Text>
          <Text type="body-1">{requestParticipantsNumberValue(d.requestParticipantsNumber)}</Text>
        </FormGroup>
        {Boolean(d.requestParticipantsNumber) && (
          <FormGroup>
            <Text type="caption">{maxSimultaneous}</Text>
            <Text type="body-1">{maxSimultaneousValue(d.maxSimultaneous)}</Text>
          </FormGroup>
        )}
        {d.lineCalculationAlgorithmMode !== 'discrete' && (
          <>
            <FormGroup>
              <Text type="caption">{autoCall}</Text>
              <Text type="body-1">{manageAppointmentsValue(d.supportAutoCall)}</Text>
            </FormGroup>
          </>
        )}
        <FormGroup>
          <Text type="caption">{sendSMSWhenJoined}</Text>
          <Text type="body-1">{manageAppointmentsValue(d.sendSMSWhenJoined)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{sendSMSWhenCalled}</Text>
          <Text type="body-1">{manageAppointmentsValue(d.sendSMSWhenCalled)}</Text>
        </FormGroup>
        {Boolean(d.sendSMSWhenJoined || d.sendSMSWhenCalled) && (
          <FormGroup>
            <Text type="caption">{smsSenderId}</Text>
            <Text type="body-1">{d.smsSenderId}</Text>
          </FormGroup>
        )}
      </Form>
      <Divider />
      {!!d.modes?.length && (
        <FormGroup>
          <Text type="caption">{modes}</Text>
          <Text type="body-1">{d.modes.map((x) => getModName(x, tr.lineModes)).join(', ')}</Text>
        </FormGroup>
      )}
      <Divider />
      <Form title={mobileAppGroup} expandable>
        <FormGroup>
          <Text type="caption">{displayWaitingTimeEstimationMobile}</Text>
          <Text type="body-1">{booleanValue(d.displayWaitingTimeEstimationMobile)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{displayTotalPeopleInLineCount}</Text>
          <Text type="body-1">{booleanValue(d.displayTotalPeopleInLineCount)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{tr.lineEdit.beforeTheCall}</Text>
          <Text type="body-1">{booleanValue(d.beforeTheCall)}</Text>
        </FormGroup>
        {Boolean(d.beforeTheCall && d.beforeTheCallTime) && (
          <FormGroup>
            <Text type="caption">{tr.lineEdit.beforeTheCallTime}</Text>
            <Text type="body-1">{time(d.beforeTheCallTime || 0)}</Text>
          </FormGroup>
        )}
        <FormGroup>
          <Text type="caption">{bluetoothRequired}</Text>
          <Text type="body-1">{booleanValue(d.bluetoothRequired)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{suggestOtherPlaces}</Text>
          <Text type="body-1">{booleanValue(d.suggestOtherPlaces)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{displayPeopleBeforeMeCount}</Text>
          <Text type="body-1">{booleanValue(d.displayPeopleBeforeMeCount)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{displayReservationQRCode}</Text>
          <Text type="body-1">{booleanValue(d.displayReservationQRCode)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{calculateApproachByGPS}</Text>
          <Text type="body-1">{manageAppointmentsValue(d.calculateApproachByGPS)}</Text>
        </FormGroup>
        {Boolean(d.calculateApproachByGPS) && (
          <FormGroup>
            <Text type="caption">{nearServicePointDistance}</Text>
            <Text type="body-1">{`${d.nearServicePointDistance} ${nearServicePointDistanceValue(
              d.nearServicePointDistance || 0
            )}`}</Text>
          </FormGroup>
        )}
      </Form>
      <Divider />
      <Form title={servicesCollection} expandable>
        {!!d.lineServicesMode && (
          <FormGroup>
            <Text type="caption">{tr.lineEdit.servicesDisplayMode}</Text>
            <Text type="body-1">{tr.lineEdit.servicesModes[d.lineServicesMode]}</Text>
          </FormGroup>
        )}
        {d.lineServicesMode === ServiceMode.SimpleList && (
          <FormGroup>
            <Text type="caption">{tr.lineEdit.multipleServicesSelection}</Text>
            <Text type="body-1">{booleanValue(d.multipleServicesSelection)}</Text>
          </FormGroup>
        )}
        {d.lineServicesMode === ServiceMode.SimpleList && d.services?.length >= 1 && (
          <FormGroup>
            {d.services.map((service, i) => (
              <div style={{ display: 'flex', margin: '0.5rem 0' }} key={i}>
                <Text>
                  {service.name} / {getServiceDurationTime(service.duration)}
                  {service.isPrivate && (
                    <Chip variant="outlined" size="small" label={privateService} style={{ marginLeft: 12 }} />
                  )}
                </Text>
              </div>
            ))}
          </FormGroup>
        )}
      </Form>
      <Divider />
      {Boolean(d.additionals && d.additionals.length) && (
        <Form title={additionalFields} expandable>
          <FormGroup>
            {d.additionals?.map((additional, i) => (
              <div style={{ display: 'flex', margin: '0.5rem 0', alignItems: 'center' }} key={i}>
                <Text>{additional.label}</Text>
                <Chip variant="outlined" size="small" label={additional.shortName} style={{ marginLeft: 12 }} />
              </div>
            ))}
          </FormGroup>
        </Form>
      )}
      {Boolean(d.additionals && d.additionals.length) && <Divider />}
      <Form title={appointmentForNextDay} expandable>
        <FormGroup>
          <Text type="caption">{allowFutureAppointments}</Text>
          <Text type="body-1">{manageAppointmentsValue(d.allowFutureAppointments)}</Text>
        </FormGroup>
        {Boolean(d.allowFutureAppointments) && (
          <FormGroup>
            <Text type="caption">{progressivelyBooking}</Text>
            <Text type="body-1">{booleanValue(d.freeTimeslotsProgressively)}</Text>
          </FormGroup>
        )}
        {Boolean(d.allowFutureAppointments && d.freeTimeslotsProgressively) && (
          <FormGroup>
            <Text type="caption">{daysLimitForAllTimeslotsAvailables}</Text>
            <Text type="body-1">{d.timeslotsFreeAllBeforeDays}</Text>
          </FormGroup>
        )}
        {Boolean(d.allowFutureAppointments) && (
          <FormGroup>
            <Text type="caption">{allowMultipleAppointmentsOnSameDay}</Text>
            <Text type="body-1">{booleanValue(d.allowMultipleAppointmentsOnSameDay)}</Text>
          </FormGroup>
        )}
        {Boolean(d.allowFutureAppointments) && (
          <FormGroup>
            <Text type="caption">{acceptTimeslotsOverlapForSameCustomer}</Text>
            <Text type="body-1">{booleanValue(d.acceptTimeslotsOverlapForSameCustomer)}</Text>
          </FormGroup>
        )}
        {Boolean(d.allowFutureAppointments) && (
          <FormGroup>
            <Text type="caption">{visibleInWebPortal}</Text>
            <Text type="body-1">{booleanValue(d.visibleInWebPortal)}</Text>
          </FormGroup>
        )}
        {Boolean(d.allowFutureAppointments) && (
          <FormGroup>
            <Text type="caption">{confirmEmailWithValidationCode}</Text>
            <Text type="body-1">{booleanValue(d.confirmEmailWithValidationCode)}</Text>
          </FormGroup>
        )}
        {Boolean(d.allowFutureAppointments) && (
          <FormGroup>
            <Text type="caption">{sendMesage}</Text>
            <Text type="body-1">{booleanValue(d.sendFollowUpEmailBefore)}</Text>
          </FormGroup>
        )}
        {Boolean(d.allowFutureAppointments && d.sendFollowUpEmailBefore) && (
          <>
            {d.sendFollowUpEmailBeforeDays && (
              <FormGroup>
                <Text type="caption">{messageDaysBefore}</Text>
                <Text type="body-1">{day(d.sendFollowUpEmailBeforeDays)}</Text>
              </FormGroup>
            )}
          </>
        )}
        {Boolean(d.allowFutureAppointments) && (
          <FormGroup>
            <Text type="caption">{sendMesageSms}</Text>
            <Text type="body-1">{booleanValue(d.sendFollowUpSmsBefore)}</Text>
          </FormGroup>
        )}
        {Boolean(d.allowFutureAppointments && d.sendFollowUpSmsBefore) && (
          <>
            {d.sendFollowUpSmsBeforeDays && (
              <FormGroup>
                <Text type="caption">{messageSmsDaysBefore}</Text>
                <Text type="body-1">{day(d.sendFollowUpSmsBeforeDays)}</Text>
              </FormGroup>
            )}
          </>
        )}
        {Boolean(d.allowFutureAppointments) && (
          <FormGroup>
            <Text type="caption">{attachIcsFile}</Text>
            <Text type="body-1">{booleanValue(d.attachIcsFile)}</Text>
          </FormGroup>
        )}
        {Boolean(d.allowFutureAppointments) && (
          <FormGroup>
            <Text type="caption">{authoriseAppointmentOnWebPortal}</Text>
            <Text type="body-1">{booleanValue(d.authoriseAppointmentOnWebPortal)}</Text>
          </FormGroup>
        )}
        {Boolean(d.allowFutureAppointments) && Boolean(d.limitInDaysForFutureDateAdmin) && (
          <FormGroup>
            <Text type="caption">{limitInDaysForFutureDateAdmin}</Text>
            <Text type="body-1">{d.limitInDaysForFutureDateAdmin}</Text>
          </FormGroup>
        )}
        {Boolean(d.allowFutureAppointments) && Boolean(d.limitInDays) && (
          <FormGroup>
            <Text type="caption">{limitInDays}</Text>
            <Text type="body-1">{d.limitInDays}</Text>
          </FormGroup>
        )}
        {Boolean(d.allowFutureAppointments) && Boolean(d.blindCopyEmails && d.blindCopyEmails.length) && (
          <FormGroup>
            <Text type="caption">{blindCopyEmails}</Text>
            <Text type="body-1">{d.blindCopyEmails}</Text>
          </FormGroup>
        )}
        {Boolean(d.allowFutureAppointments) && (
          <FormGroup>
            <Text type="caption">{retainTimeslotWhenAppointmentCancelled}</Text>
            <Text type="body-1">{booleanValue(d.retainTimeslotWhenAppointmentCancelled)}</Text>
          </FormGroup>
        )}
        {Boolean(d.allowFutureAppointments && d.retainTimeslotWhenAppointmentCancelled) && (
          <>
            {d.minDurationToFreeTimeslot && (
              <FormGroup>
                <Text type="caption">{minDurationToFreeTimeslot}</Text>
                <Text type="body-1">{time(d.minDurationToFreeTimeslot)}</Text>
              </FormGroup>
            )}
            {d.maxDurationToFreeTimeslot && (
              <FormGroup>
                <Text type="caption">{maxDurationToFreeTimeslot}</Text>
                <Text type="body-1">{time(d.maxDurationToFreeTimeslot)}</Text>
              </FormGroup>
            )}
          </>
        )}
        {Boolean(d.allowFutureAppointments && d.lineAppointmentLink) && (
          <FormGroup>
            <Text type="caption">{lineAppointmentLink}</Text>
            <a href={d.lineAppointmentLink} style={{ wordBreak: 'break-all', fontSize: '13px' }}>
              {d.lineAppointmentLink}
            </a>
          </FormGroup>
        )}
      </Form>
      <Divider />
      <Form title={openingHours} expandable>
        <FormGroup>
          <Text type="caption">{servicePointCanAlwaysBeOpen}</Text>
          <Text type="body-1">{booleanValue(d.servicePointCanAlwaysBeOpen)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{requestCommentOnClosingServicePoint}</Text>
          <Text type="body-1">{booleanValue(d.requestCommentOnClosingServicePoint)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{closeAllServicePointsAtMidnight}</Text>
          <Text type="body-1">{closeAllServicePointsAtMidnightValue(d.closeAllServicePointsAtMidnight)}</Text>
        </FormGroup>
        <ScheduleView schedule={d.schedule || []} />
      </Form>
      <Divider />
      <Form title={progressionTags.title} expandable>
        <FormGroup>
          <Text type="caption">{progressionTags.enable}</Text>
          <Text type="body-1">{booleanValue(d.enableProgressionTags)}</Text>
        </FormGroup>
        {Boolean(d.enableProgressionTags) && (
          <>
            <FormGroup>
              <Text type="caption">{progressionTags.multiple}</Text>
              <Text type="body-1">{booleanValue(d.allowMultipleProgressionTags)}</Text>
            </FormGroup>
            {!!d.progressionTags && (
              <FormGroup>
                <Text type="caption">{progressionTags.title}</Text>
                <Progressions>
                  {d.progressionTags.split(';').map((tag, i) => (
                    <Chip label={tag} key={i} size="small" variant="outlined" />
                  ))}
                </Progressions>
              </FormGroup>
            )}
            <FormGroup>
              <Text type="caption">{progressionTags.withPhoto}</Text>
              <Text type="body-1">{booleanValue(d.allowProgressionPhoto)}</Text>
            </FormGroup>
            <FormGroup>
              <Text type="caption">{progressionTags.sendReport}</Text>
              <Text type="body-1">{booleanValue(d.allowSendProgressionReport)}</Text>
            </FormGroup>
            <FormGroup>
              <Text type="caption">{progressionTags.sendReportToParticipant}</Text>
              <Text type="body-1">{booleanValue(d.allowSendProgressionReportToParticipant)}</Text>
            </FormGroup>
            {Boolean(d.allowSendProgressionReport && d.progressionParticipantEmails?.length) && (
              <FormGroup>
                <Text type="caption">{progressionTags.participantEmails}</Text>
                <Text type="body-1">{d.progressionParticipantEmails}</Text>
              </FormGroup>
            )}
          </>
        )}
      </Form>
      <Divider />
      <Form title={durations} expandable>
        {!!d.serviceTime && (
          <FormGroup>
            <Text type="caption">{serviceTime}</Text>
            <Text type="body-1">{seconds(d.serviceTime)}</Text>
          </FormGroup>
        )}
        {!!d.timeToReach && (
          <FormGroup>
            <Text type="caption">{timeToReach}</Text>
            <Text type="body-1">{seconds(d.timeToReach)}</Text>
          </FormGroup>
        )}
        {!!d.maxTimeToReach && (
          <FormGroup>
            <Text type="caption">{maxTimeToReach}</Text>
            <Text type="body-1">{seconds(d.maxTimeToReach)}</Text>
          </FormGroup>
        )}
        {!!d.removeUnalertedPosition && (
          <FormGroup>
            <Text type="caption">{removeUnalertedPositionAfter}</Text>
            <Text type="body-1">{time(d.removeUnalertedPosition)}</Text>
          </FormGroup>
        )}
        {Boolean(d.minTimeToFreePlaceAfterEarlyAcceptance && d.lineCalculationAlgorithmMode !== 'normal') && (
          <FormGroup>
            <Text type="caption">{minTimeToFreePlaceAfterEarlyAcceptance}</Text>
            <Text type="body-1">{seconds(d.minTimeToFreePlaceAfterEarlyAcceptance || 0)}</Text>
          </FormGroup>
        )}
        {Boolean(d.timeToSetFreeStockPlaces && d.lineCalculationAlgorithmMode !== 'normal') && (
          <FormGroup>
            <Text type="caption">{timeToSetFreeStockPlaces}</Text>
            <Text type="body-1">{time(d.timeToSetFreeStockPlaces || 0)}</Text>
          </FormGroup>
        )}
      </Form>
      <Divider />
      <Form title={monitoringGroup} expandable>
        <FormGroup>
          <Text type="caption">{suggestSamePositionsForAllServicePoints}</Text>
          <Text type="body-1">
            {
              suggestedClientsStrategy[
                d.servicePointSuggestedClientsStrategy || SuggestedClientsStrategy.DifferentPositions
              ]
            }
          </Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{sequentiallyPerformingActionsOnPosition}</Text>
          <Text type="body-1">{booleanValue(d.sequentiallyPerformingActionsOnPosition)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{displayWaitingTimeEstimationMonitoring}</Text>
          <Text type="body-1">{booleanValue(d.displayWaitingTimeEstimation)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{displayServiceDuration}</Text>
          <Text type="body-1">{booleanValue(d.displayServiceDuration)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{displayServicePoints}</Text>
          <Text type="body-1">{booleanValue(d.displayServicePoints)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{displayServiceNameForOperator}</Text>
          <Text type="body-1">{booleanValue(d.displayServiceNameForOperator)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{displayReservationCode}</Text>
          <Text type="body-1">{booleanValue(d.displayReservationCode)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{displayCalledServicePoint}</Text>
          <Text type="body-1">{booleanValue(d.displayCalledServicePoint)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{displayEndServiceSurveyOperator}</Text>
          <Text type="body-1">{manageAppointmentsValue(d.displayServiceSurvey)}</Text>
        </FormGroup>
        {!!d.displayServiceSurvey && !!d.surveyTags && (
          <FormGroup>
            <Text type="caption">{surveyTags}</Text>
            <Text type="body-1">{d.surveyTags}</Text>
          </FormGroup>
        )}
        <FormGroup>
          <Text type="caption">{requestCommentsOnPriorityCall}</Text>
          <Text type="body-1">{booleanValue(d.requestCommentsOnPriorityCall)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{displayRemoveConfirm}</Text>
          <Text type="body-1">{booleanValue(d.displayRemoveConfirm)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{requestCommentsOnRemove}</Text>
          <Text type="body-1">{booleanValue(d.requestCommentsOnRemove)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{supportSearchCustomerByPhone}</Text>
          <Text type="body-1">{booleanValue(d.supportSearchCustomerByPhone)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{displayPersonalInfoInReadOnlyMode}</Text>
          <Text type="body-1">{booleanValue(d.displayPersonalInfoInReadOnlyMode)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{checkCustomerNameUnicity}</Text>
          <Text type="body-1">{booleanValue(d.checkCustomerNameUnicity)}</Text>
        </FormGroup>
      </Form>
      <Divider />
      <Form title={operationsMenu} expandable>
        <FormGroup>
          <Text type="caption">{displayInPlace}</Text>
          <Text type="body-1">{booleanValue(d.displayInPlace)}</Text>
        </FormGroup>
        {!!d.generalConditionsAcceptation && (
          <FormGroup>
            <Text type="caption">
              {d.monitoringPositionMenuItemCaptions?.generalConditionsAcceptation ||
                tr.lineEdit.generalConditionsAcceptationTemplateIsNotFilled}
            </Text>
            <Text type="body-1">{generalConditionsAcceptation[d.generalConditionsAcceptation]}</Text>
          </FormGroup>
        )}
        <FormGroup>
          <Text type="caption">{displayPositionValidate}</Text>
          <Text type="body-1">{booleanValue(d.displayPositionValidate)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{displayApproach}</Text>
          <Text type="body-1">{booleanValue(d.displayApproach)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{setArrivedAfterCallFinished}</Text>
          <Text type="body-1">{booleanValue(d.setPositionFinishedAfterArrived)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{displayHistory}</Text>
          <Text type="body-1">{booleanValue(d.displayHistory)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{displayCallByPhone}</Text>
          <Text type="body-1">{booleanValue(d.displayCallByPhone)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{displayConvertToMobile}</Text>
          <Text type="body-1">{booleanValue(d.displayConvertToMobile)}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{displayDuplicate}</Text>
          <Text type="body-1">{booleanValue(d.duplicatePositionMenuItem)}</Text>
        </FormGroup>
      </Form>
      <Divider />
    </Form>
  )
}

export { LineView }
