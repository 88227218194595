import { DeviceType, PositionState } from 'shared/models'
import { Translations } from 'shared/i18n/translation-types'
import { PositionModel } from 'shared/models'
import TimeHelper from 'shared/utils/time'
import { formatNumber } from 'features/material-ui-phoneinput/components/format-number'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import TvIcon from '@mui/icons-material/Tv'
import AppleIcon from '@mui/icons-material/Apple'
import AndroidIcon from '@mui/icons-material/Android'
import LanguageIcon from '@mui/icons-material/Language'

export function getNameOrNumber(position: PositionModel, translation: Translations) {
  const customerFirstName = position.customer?.firstName || ''
  const customerLastName = position.customer?.lastName || ''
  const customerFullName = `${customerFirstName} ${customerLastName}`.trim()
  const customerNumber = position.customer?.phoneNumber
  const customerId = position.customer?.id

  return customerFullName || (customerNumber && `${translation.common.customer} ${customerNumber}`) || customerId
}

export function getNameOrNumberWithPhone(position: PositionModel, translation: Translations) {
  const companyName = position.customer?.companyName
  const customerPhone = formatNumber(position.customer?.phoneNumber)
  const name = getNameOrNumber(position, translation)
  const nameAndPhone = [name, customerPhone].filter(Boolean).join(', ')

  return [companyName, nameAndPhone].filter(Boolean).join(' - ')
}

export function isPositionInLineHere(position: PositionModel) {
  const { state, inPlaceSince } = position
  return state === PositionState.InLineHere || !!inPlaceSince
}

export function isValidPosition(position: PositionModel) {
  const { isValid } = position
  return isValid === true
}

export function isUnreachPosition(position: PositionModel) {
  const {
    expectedCallTime_unixtime = null,
    expectedServiceStartTime_unixtime = null,
    asap,
    denialOfServiceReason
  } = position

  if (denialOfServiceReason) {
    return true
  }

  if (!asap) {
    if (expectedServiceStartTime_unixtime === null) {
      return false
    }

    return expectedServiceStartTime_unixtime - Date.now() > TimeHelper.yearMS * 5
  }

  if (expectedCallTime_unixtime === null) {
    return false
  }

  return expectedCallTime_unixtime - Date.now() > TimeHelper.yearMS * 5
}

export function handleClickStopEvents(evt) {
  if (!evt) {
    return
  }

  if (evt.stopPropagation) {
    evt.stopPropagation()
  }

  if (evt.cancelBubble !== null) {
    evt.cancelBubble = true
  }
}

export function getDeviceTypeIcon(type: DeviceType | undefined) {
  if (type) {
    if (type === 'Terminal') {
      return <TvIcon />
    }

    if (type === 'Admin') {
      return <ManageAccountsIcon />
    }

    if (type === 'iOS') {
      return <AppleIcon />
    }

    if (type === 'Android') {
      return <AndroidIcon />
    }

    if (type === 'Rdv') {
      return <LanguageIcon />
    }
  }

  return
}
