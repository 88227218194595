import { Divider } from '@mui/material'
import { useTranslation } from 'shared/i18n/translate'
import { PositionAction, PositionState, PositionType } from 'shared/models'
import PositionActionComponent from '../../components/position-action'
import { StartServiceActionProps } from './types'
import StartSeviceIcon from '@mui/icons-material/PlayArrow'

export function StartService(props: StartServiceActionProps) {
  const { tr } = useTranslation()

  const isBreak = props.position.type === PositionType.Break

  const breakStarted =
    props.position.state === PositionState.InService || props.position.state === PositionState.ServiceStarted

  const impossibleNext =
    !props.posiblesStates.includes(PositionAction.StartService) &&
    !props.posiblesStates.includes(PositionAction.SetNearCheckpoint) &&
    !isBreak

  function handleSubmit() {
    props.handleChangeState(PositionState.ServiceStarted)
  }

  return (
    <>
      {!props.isCardView && <Divider />}
      <PositionActionComponent
        isCardView={props.isCardView}
        onSubmit={handleSubmit}
        disabled={breakStarted || impossibleNext || !!props.disabled}
        icon={<StartSeviceIcon fontSize="small" />}
      >
        {props.monitoringPositionMenuItemCaptions?.startService || tr.positionActions.startService}
      </PositionActionComponent>
    </>
  )
}
