import { Form, FormGroup } from 'shared/ui-kit/form'
import { TextField } from 'shared/ui-kit/text-field'
import Toggle from 'shared/ui-kit/toggle-wrap'
import { useTranslation } from 'shared/i18n/translate'
import { Controller } from 'react-hook-form'
import { GroupProps } from '../types'
import { WithHelper } from 'features/element-with-helper'
import { SuggestedClientsStrategy } from 'shared/models'
import Dropdown from 'shared/ui-kit/dropdown'
import { Card } from '@mui/material'

function MonitoringGroup({ control, watch }: GroupProps) {
  const { tr } = useTranslation()

  const {
    supportSearchCustomerByPhone,
    displayPersonalInfoInReadOnlyMode,
    suggestedClientsStrategy,
    displayRemoveConfirm,
    requestCommentsOnPriorityCall,
    requestCommentsOnRemove,
    checkCustomerNameUnicity,
    sequentiallyPerformingActionsOnPosition,
    suggestSamePositionsForAllServicePoints,
    displayWaitingTimeEstimationMonitoring,
    displayServiceDuration,
    displayServicePoints,
    displayServiceNameForOperator,
    displayReservationCode,
    displayCalledServicePoint,
    displayEndServiceSurveyOperator,
    surveyTags
  } = tr.lineEdit

  return (
    <Card>
      <Form>
        <FormGroup>
          <WithHelper tooltipKey="servicePointSuggestedClientsStrategy" tag="line">
            <Controller
              name="servicePointSuggestedClientsStrategy"
              control={control}
              render={({ field: f }) => (
                <Dropdown
                  ensureValue
                  label={suggestSamePositionsForAllServicePoints}
                  value={f.value}
                  data={[SuggestedClientsStrategy.DifferentPositions, SuggestedClientsStrategy.SamePositions]}
                  dataConverter={(item) => ({ value: item, text: suggestedClientsStrategy[item] })}
                  onChange={f.onChange}
                  fullWidth
                />
              )}
            />
          </WithHelper>
        </FormGroup>
        <FormGroup>
          <Controller
            name="sequentiallyPerformingActionsOnPosition"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={sequentiallyPerformingActionsOnPosition}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="sequentiallyPerformingActionsOnPosition"
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="displayWaitingTimeEstimation"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={displayWaitingTimeEstimationMonitoring}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="displayWaitingTimeEstimation"
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="displayServiceDuration"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={displayServiceDuration}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="displayServiceDuration"
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="displayServicePoints"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={displayServicePoints}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="displayServicePoints"
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="displayServiceNameForOperator"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={displayServiceNameForOperator}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="displayServiceNameForOperator"
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="displayReservationCode"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={displayReservationCode}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="displayReservationCode"
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="displayCalledServicePoint"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={displayCalledServicePoint}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="displayCalledServicePoint"
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="displayServiceSurvey"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={displayEndServiceSurveyOperator}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="displayServiceSurvey"
              />
            )}
          />
        </FormGroup>
        {watch('displayServiceSurvey') && (
          <FormGroup>
            <WithHelper tag="line" tooltipKey="surveyTags">
              <Controller
                name="surveyTags"
                control={control}
                render={({ field: f }) => (
                  <TextField
                    label={surveyTags}
                    placeholder={surveyTags}
                    value={f.value}
                    onChange={f.onChange}
                    message={`${f.value?.length || 0}/500 ${tr.entityEdit.chars}`}
                    test={(v) => v.length < 501}
                    fullWidth
                  />
                )}
              />
            </WithHelper>
          </FormGroup>
        )}
        <FormGroup>
          <Controller
            name="requestCommentsOnPriorityCall"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={requestCommentsOnPriorityCall}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="requestCommentsOnPriorityCall"
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="displayRemoveConfirm"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={displayRemoveConfirm}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="displayRemoveConfirm"
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="requestCommentsOnRemove"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={requestCommentsOnRemove}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="requestCommentsOnRemove"
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="supportSearchCustomerByPhone"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={supportSearchCustomerByPhone}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="supportSearchCustomerByPhone"
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="displayPersonalInfoInReadOnlyMode"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={displayPersonalInfoInReadOnlyMode}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="displayPersonalInfoInReadOnlyMode"
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="checkCustomerNameUnicity"
            control={control}
            render={({ field: f }) => (
              <Toggle
                toggled={f.value}
                label={checkCustomerNameUnicity}
                onToggle={(_, val) => f.onChange(val)}
                withHelper={true}
                tag="line"
                tooltipKey="checkCustomerNameUnicity"
              />
            )}
          />
        </FormGroup>
      </Form>
    </Card>
  )
}

export { MonitoringGroup }
