import { Additional, ExpressionValidations } from 'shared/models'

function filterAdditionalFields(
  fields: Additional[],
  personsQuantity?: number,
  selectedServiceIds?: (number | string)[]
) {
  return (
    fields?.filter((additional) => {
      if (
        additional.displayByParticipantNumber &&
        ((!!additional.minParticipantNumber &&
          (!personsQuantity || additional.minParticipantNumber > personsQuantity)) ||
          (!!additional.maxParticipantNumber &&
            (!personsQuantity || additional.maxParticipantNumber < personsQuantity)))
      ) {
        return false
      }

      if (!additional.availableForServices?.length) {
        return true
      }

      return (selectedServiceIds || []).filter?.((sId) => {
        return (additional.availableForServices || []).map?.((el) => String(el)).includes(String(sId))
      }).length
    }) || []
  )
}

function expressionValidationsFilter(expressionValidations: ExpressionValidations) {
  return (el: Additional) => {
    return (
      !el.visibilityAndValidationExpression || (el.shortName && expressionValidations[el.shortName]?.visible !== false)
    )
  }
}

export { filterAdditionalFields, expressionValidationsFilter }
