import { useState, useEffect } from 'react'
import { editPlace } from 'pages/nav'
import { PlaceView } from './dumb/view'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { useTranslation } from 'shared/i18n/translate'
import PlaceTiles from './place-tiles'
import { Button, CircularProgress } from '@mui/material'
import { useDataProvider } from 'features/isolated-data-provider'
import { useTimeZoneFilter } from 'features/time-zone-filter'
import Container from 'shared/ui-kit/container'
import Card from 'shared/ui-kit/card'
import EntityActions from 'shared/ui-kit/entity-actions/entity-actions'
import { getCrumbs } from 'shared/utils/@breadcrumbs/breadcrumbs-helper'
import { place as placeApi, brand } from 'shared/api'
import {
  Edit as ModeEdit,
  KeyboardArrowUp as ArrowUpIcon,
  KeyboardArrowDown as ArrowDownIcon
} from '@mui/icons-material'
import { addTestAttr } from 'shared/utils/test-attr'
import { Title } from 'shared/ui-kit/title'
import { CenterContainer } from 'shared/ui-kit/center-container'
import { useHistory, useParams } from 'react-router'
import { countries } from 'shared/countries'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { useTitle } from 'shared/hooks/useTitle'
import { useAppDispatch, useAppSelector } from 'store'
import { getUrl } from 'shared/api/helpers'
import { LogoImage, SettingsLink, TitleContainer } from './styled'
import { useQuery } from 'react-query'

function PlaceViewPage() {
  const { timeZones } = useDataProvider()
  const { displayReducedInterface, canUpdateShops } = useAppSelector(getPermissionsSelector)
  const dispatch = useAppDispatch()
  const { tr, locale } = useTranslation()
  const history = useHistory()
  const { placeId } = useParams<{ placeId: string }>()

  const [settingsOpened, setSettingsOpened] = useState(false)

  const { data: place, isLoading } = useQuery(['shop', placeId], ({ signal }) => placeApi.getPlace(placeId, { signal }))
  const { data: brands } = useQuery(['brands'], ({ signal }) => brand.getBrandList({ signal }))
  const { data: countriesValue } = useQuery(['countries', locale], () => countries.getCountries(locale))

  useTitle({ placeName: place?.name })
  useTimeZoneFilter(placeId)
  useEffect(setCrumbs, [place, locale])

  function setCrumbs() {
    const crumb = getCrumbs(tr.breadcrumbs)
    const crumbs = [crumb.home(), crumb.places(), crumb.place([placeId], place?.name || tr.breadcrumbs.place)]

    dispatch(setBreadcrumbs(crumbs))
  }

  function handleEdit() {
    history.push(editPlace(placeId))
  }

  function handleSettings() {
    setSettingsOpened((curr) => !curr)
  }

  if (isLoading) {
    return (
      <CenterContainer>
        <CircularProgress color="secondary" />
      </CenterContainer>
    )
  }

  if (!place) {
    return null
  }

  return (
    <Container>
      <TitleContainer>
        {place.image?.id && <LogoImage $url={getUrl('downloadFile', { id: place.image.id })} />}
        <Title title={place.name} style={{ flex: 1, marginBottom: 0 }} />
        {!displayReducedInterface && (
          <SettingsLink {...addTestAttr('Placeinfo-Link')} onClick={handleSettings}>
            {tr.entityView.settings}
            {settingsOpened ? <ArrowUpIcon fontSize="small" /> : <ArrowDownIcon fontSize="small" />}
          </SettingsLink>
        )}
      </TitleContainer>
      {settingsOpened && (
        <Card>
          <PlaceView
            data={place}
            brands={brands?.data || []}
            timeZones={timeZones}
            canUpdateShops={canUpdateShops}
            countryName={countries.getCountryName(countriesValue || [], place.country)}
          />
          {canUpdateShops && (
            <EntityActions
              showButtons
              buttons={
                <Button onClick={handleEdit} startIcon={<ModeEdit />} {...addTestAttr('PlaceView-EditButton')}>
                  {tr.entityView.editButton}
                </Button>
              }
              updateData={place.lastUpdate}
            />
          )}
        </Card>
      )}
      <PlaceTiles place={place} />
    </Container>
  )
}

export default PlaceViewPage
