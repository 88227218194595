import styled from '@emotion/styled'
import { JefileLogoMiltilanguage } from 'features/jefile-logo-miltilanguage'
import { Link } from 'react-router-dom'

const LoginWrapper = styled.section`
  background: #fafafa;
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const LoginFormStyled = styled.div`
  padding: 16px;
  width: 296px;
  box-sizing: border-box;
`

const LoginField = styled.div`
  margin: 0 0 16px;
`

const LoginActions = styled.div`
  margin: 24px 0 16px;
  text-align: center;
`

const LoginLogo = styled(JefileLogoMiltilanguage)`
  height: 90px;
  width: 100%;
  margin: 32px auto 32px auto;
`

const LinkWrapper = styled.div`
  margin: 1rem 0;
  text-align: center;
`

const LinkStyled = styled(Link)`
  font-size: 14px;
  color: #003c6a;
  font-weight: 300;
  cursor: pointer;
  transition: opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  &:hover {
    opacity: 0.8;
  }
`

const CaptchContainer = styled.div`
  width: 240px;
  height: 50px;
  border: solid 1px rgb(148, 148, 148);
  border-radius: 4px;
  margin-top: 1rem;
  transform-origin: left center;
  overflow: hidden;
`

export { LoginWrapper, LoginActions, LoginFormStyled, LoginField, LoginLogo, LinkWrapper, LinkStyled, CaptchContainer }
