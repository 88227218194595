import { ReactNode, CSSProperties } from 'react'
import css from './control-label.module.scss'

type LabelProps = {
  children?: ReactNode
  style?: CSSProperties
  className?: string
}

function Label(props: LabelProps) {
  if (!props.children) {
    return null
  }

  return (
    <section className={props.className ? `${css.root} ${props.className}` : css.root} style={props.style}>
      {props.children}
    </section>
  )
}

export default Label
