import { Form, FormGroup } from 'shared/ui-kit/form'
import Toggle from 'shared/ui-kit/toggle-wrap'
import { useTranslation } from 'shared/i18n/translate'
import { Controller } from 'react-hook-form'
import { GroupProps } from '../types'
import { WithHelper } from 'features/element-with-helper'
import Card from 'shared/ui-kit/card'
import { TextField } from '@mui/material'

function ProgressionTagsGroup({ control, setValue, trigger, watch }: GroupProps) {
  const { tr } = useTranslation()

  const { progressionTags } = tr.lineEdit

  return (
    <Card>
      <Form>
        <FormGroup>
          <Controller
            name="enableProgressionTags"
            control={control}
            render={({ field: f }) => (
              <Toggle
                label={progressionTags.enable}
                toggled={f.value}
                tag="line"
                onToggle={(_, val) => {
                  f.onChange(val)
                  setValue('progressionTags', '')
                  trigger()
                }}
              />
            )}
          />
        </FormGroup>
        {watch('enableProgressionTags') && (
          <FormGroup>
            <Controller
              name="progressionTags"
              control={control}
              render={({ field: f, formState }) => (
                <TextField
                  label={progressionTags.separated}
                  placeholder={progressionTags.separated}
                  value={f.value}
                  onChange={f.onChange}
                  error={!!formState.errors.progressionTags}
                  helperText={`${f.value?.length || 0}/500 ${tr.entityEdit.chars}`}
                  InputLabelProps={{ shrink: true }}
                  multiline
                  fullWidth
                  maxRows={15}
                />
              )}
            />
          </FormGroup>
        )}
        {watch('enableProgressionTags') && (
          <FormGroup>
            <Controller
              name="allowMultipleProgressionTags"
              control={control}
              render={({ field: f }) => (
                <Toggle
                  label={progressionTags.multiple}
                  toggled={f.value}
                  tag="line"
                  onToggle={(_, val) => f.onChange(val)}
                />
              )}
            />
          </FormGroup>
        )}
        {watch('enableProgressionTags') && (
          <FormGroup>
            <Controller
              name="allowProgressionPhoto"
              control={control}
              render={({ field: f }) => (
                <Toggle
                  label={progressionTags.withPhoto}
                  toggled={f.value}
                  tag="line"
                  onToggle={(_, val) => f.onChange(val)}
                />
              )}
            />
          </FormGroup>
        )}
        {watch('enableProgressionTags') && (
          <FormGroup>
            <Controller
              name="allowSendProgressionReport"
              control={control}
              render={({ field: f }) => (
                <Toggle
                  label={progressionTags.sendReport}
                  toggled={f.value}
                  tag="line"
                  onToggle={(_, val) => {
                    f.onChange(val)
                    trigger()
                  }}
                />
              )}
            />
          </FormGroup>
        )}
        {watch('enableProgressionTags') && watch('allowSendProgressionReport') && (
          <FormGroup>
            <Controller
              name="progressionParticipantEmails"
              control={control}
              render={({ field: f, fieldState }) => (
                <WithHelper tooltipKey="progressionTags.participantEmails" tag="line">
                  <TextField
                    label={progressionTags.participantEmails}
                    placeholder={progressionTags.participantEmailsPlaceholder}
                    value={f.value}
                    onChange={f.onChange}
                    error={!!fieldState.error}
                    InputLabelProps={{ shrink: true }}
                    multiline
                    fullWidth
                    maxRows={15}
                  />
                </WithHelper>
              )}
            />
          </FormGroup>
        )}
        {watch('enableProgressionTags') && watch('allowSendProgressionReport') && (
          <FormGroup>
            <Controller
              name="allowSendProgressionReportToParticipant"
              control={control}
              render={({ field: f }) => (
                <Toggle
                  label={progressionTags.sendReportToParticipant}
                  toggled={f.value}
                  tag="line"
                  onToggle={(_, val) => f.onChange(val)}
                />
              )}
            />
          </FormGroup>
        )}
      </Form>
    </Card>
  )
}

export { ProgressionTagsGroup }
