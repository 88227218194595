import { terminal as terminalNav } from 'pages/nav'
import { useTranslation } from 'shared/i18n/translate'
import { TerminalIcon } from '../components/terminal-icon'
import Toggle from 'shared/ui-kit/toggle-wrap'
import { List, Avatar, ListItem, ListItemText, ListItemAvatar, ListItemButton } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { addTestAttr } from 'shared/utils/test-attr'
import { TerminalListProps } from '../types'
import { TerminalSmallModel } from 'shared/models'

function TerminalList(props: TerminalListProps) {
  const { tr } = useTranslation()

  function handleToggle(terminal: TerminalSmallModel) {
    return () => {
      if (terminal.enabled) {
        props.onDisable(terminal)
      } else {
        props.onEnable(terminal)
      }
    }
  }

  return (
    <List>
      {props.data.map((terminal) => (
        <ListItem
          key={terminal.id}
          disablePadding
          secondaryAction={
            <Toggle
              onToggle={handleToggle(terminal)}
              disabled={props.isTerminalDisabled ? props.isTerminalDisabled(terminal) : false}
              toggled={terminal.enabled}
              {...addTestAttr('ListItem-Toggle-Id-' + terminal.id)}
            />
          }
        >
          <ListItemButton
            component={NavLink}
            to={terminalNav(String(terminal.id), props.shopId)}
            {...addTestAttr('ListItem-Id-' + terminal.id)}
          >
            <ListItemAvatar>
              <Avatar>
                <TerminalIcon type={terminal.type} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={terminal.name} secondary={tr.terminalList.shopsCount(terminal.shopIds.length)} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}

export { TerminalList }
