import { brand } from 'shared/api'
import { Dispatch } from '@reduxjs/toolkit'
import { setListRequest, setListSuccess } from '../reducers/brands-reducer'

export function getIsolatedBrandList() {
  return async (dispatch: Dispatch) => {
    dispatch(setListRequest())

    const res = await brand.getBrandList()

    dispatch(setListSuccess(res.data))
  }
}
