import { Fragment, useState } from 'react'
import { CheckpointModel, CheckpointStatus } from 'shared/models'
import { useTranslation } from 'shared/i18n/translate'
import { ServiceSelectProps } from './types'
import { statusByColorMap } from '../../utils/status-by-color-map'
import PositionActionComponent from '../../components/position-action'
import { Button, Divider } from '@mui/material'
import { ActionList } from '../styled'

function ServiceSelect(props: ServiceSelectProps) {
  const { tr } = useTranslation()

  const [showNotActiveCheckpoints, setShowNotActiveCheckpoints] = useState(false)

  const activeCheckpoints = props.checkpoints.filter((chp) => chp.status !== CheckpointStatus.finished)
  const notActiveCheckpoints = props.checkpoints.filter((chp) => chp.status === CheckpointStatus.finished)

  function handleСheckpointSelect(checkpoint: CheckpointModel) {
    return () => {
      props.onСheckpointSelect(checkpoint)
    }
  }

  function openUnactiveCheckpoints() {
    setShowNotActiveCheckpoints(true)
  }

  return (
    <>
      <ActionList $isCardView={props.isCardView}>
        {activeCheckpoints.map((checkpoint) => (
          <Fragment key={checkpoint.id}>
            {!props.isCardView && <Divider />}
            <PositionActionComponent onSubmit={handleСheckpointSelect(checkpoint)} isCardView={props.isCardView}>
              <div style={{ color: checkpoint.status ? statusByColorMap[checkpoint.status] : undefined }}>
                {checkpoint.name}
              </div>
            </PositionActionComponent>
          </Fragment>
        ))}
        {notActiveCheckpoints.length > 1 && !showNotActiveCheckpoints && (
          <>
            {!props.isCardView && <Divider />}
            <PositionActionComponent onSubmit={openUnactiveCheckpoints} isCardView={props.isCardView}>
              <div style={{ color: '#00000069 ' }}>{tr.positionActions.showNotActiveCheckpoints}</div>
            </PositionActionComponent>
          </>
        )}
        {(notActiveCheckpoints.length < 2 || showNotActiveCheckpoints) &&
          notActiveCheckpoints.map((checkpoint) => (
            <Fragment key={checkpoint.id}>
              {!props.isCardView && <Divider />}
              <PositionActionComponent onSubmit={handleСheckpointSelect(checkpoint)} isCardView={props.isCardView}>
                <div style={{ color: checkpoint.status ? statusByColorMap[checkpoint.status] : undefined }}>
                  {checkpoint.name} ({tr.positionActions.closed})
                </div>
              </PositionActionComponent>
            </Fragment>
          ))}
        {!props.isCardView && <Divider />}
      </ActionList>
      {!!props.onBack && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{ marginTop: props.isCardView ? '1.5rem' : '1rem' }}
          onClick={props.onBack}
        >
          {tr.positionActions.backButton}
        </Button>
      )}
    </>
  )
}

export { ServiceSelect }
