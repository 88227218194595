import { CSSProperties } from 'react'
import { PositionTypeIcon } from 'features/position-dumb'
import { useTranslation } from 'shared/i18n/translate'
import { PositionModel, PositionState } from 'shared/models'

type Props = {
  position: PositionModel
  style?: CSSProperties
}

function cut(fname = '') {
  return fname
    .trim()
    .split(' ')
    .filter(Boolean)
    .map((part) => `${part[0].toUpperCase()}.`)
    .join(' ')
}

function getPositionName(position: PositionModel) {
  const { customer, id } = position

  if (!customer) {
    return `Position ${id}. No customer ERROR`
  }

  const { firstName, lastName, id: customerId } = customer

  if (!firstName && !lastName) {
    return `Position ${id}. Customer ${customerId}. No customer name ERROR`
  }

  return lastName ? `${lastName} ${cut(firstName)}` : firstName
}

function PositionShortInfo({ position, style }: Props) {
  const { dateFormatter, tr } = useTranslation()

  if (!position) {
    return null
  }

  const posStateText = {
    [PositionState.CalledToCheckpoint]: (position) => {
      return tr.lineMonitoringStatistics.callTime(
        dateFormatter(position.callTime_unixtime, 'time'),
        dateFormatter(position.callTime_unixtime + 1000 * 60 * 5, 'time')
      )
    },
    [PositionState.InService]: (position) => {
      const maped = position.services.reduce(
        (prev, curr) => {
          const { duration, name } = curr

          return {
            name: prev.name + prev.name ? `,${name}` : name,
            duration: prev.duration + duration * position.personsQuantity
          }
        },
        { name: '', duration: 0 }
      )

      return tr.lineMonitoringStatistics.serviceTime(
        dateFormatter(position.serviceStartedTime_unixtime, 'time'),
        dateFormatter(position.serviceStartedTime_unixtime + maped.duration * 1000, 'time')
      )
    },
    unknown: () => console.debug('Status unknown')
  }

  return (
    <div style={{ display: 'flex', ...style }}>
      <PositionTypeIcon style={{ opacity: 0.24, margin: 'auto', padding: '0px 8px 0px 0px' }} type={position.type} />
      <div style={{ flex: '1', fontSize: '14px', lineHeight: '20px', overflow: 'hidden', whiteSpace: 'nowrap' }}>
        <div style={{ fontWeight: 'bold', textOverflow: 'ellipsis', overflow: 'hidden', textTransform: 'capitalize' }}>
          {getPositionName(position)}
        </div>
        <div style={{ fontSize: '12px', opacity: 0.54 }}>
          {(posStateText[position.state] || posStateText.unknown)(position)}
        </div>
      </div>
    </div>
  )
}

export default PositionShortInfo
