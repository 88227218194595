import { UserModel, UserCopyModel } from 'shared/models'

//NOTE: Remove disabled permissions because false is the default value, we only care about enabled permissions
function prepareUserEntity(data: UserModel) {
  const dataToSave = { ...data }

  Object.keys(data)
    .filter((key) => data[key] === false)
    .forEach((key) => delete dataToSave[key])

  return dataToSave
}

function userDuplicationDataConverter(values: UserModel): UserCopyModel {
  return {
    description: values.description,
    interfaceLocale: values.interfaceLocale,
    shops: values.shops,
    isAdmin: values.isAdmin,
    isActive: values.isActive,
    profileId: values.profileId,
    manageAppointments: values.manageAppointments,
    manageCampaigns: values.manageCampaigns,
    manageTerminals: values.manageTerminals,
    manageMessagesTemplates: values.manageMessagesTemplates,
    manageReports: values.manageReports,
    manageTranslations: values.manageTranslations,
    canManageUserAccounts: values.canManageUserAccounts,
    canAddAndDeleteShops: values.canAddAndDeleteShops,
    canUpdateParticipantsName: values.canUpdateParticipantsName,
    canOpenCheckpoints: values.canOpenCheckpoints,
    canCloseCheckpoints: values.canCloseCheckpoints,
    canDeletePositions: values.canDeletePositions,
    canUpdateShops: values.canUpdateShops,
    canValidatePositions: values.canValidatePositions,
    canCallInvalidPositions: values.canCallInvalidPositions,
    canAddAndEditPositions: values.canAddAndEditPositions,
    canAccessPlaceLineMonitoring: values.canAccessPlaceLineMonitoring,
    canAssignCustomServicePoints: values.canAssignCustomServicePoints,
    viewPositions: values.viewPositions,
    canViewPersonalInfo: values.canViewPersonalInfo,
    canCreateTemporaryAuthCode: values.canCreateTemporaryAuthCode,
    canLoginFromMultipleDevices: values.canLoginFromMultipleDevices
  }
}

const userCopyStorageKey = 'jefile_userCopyData'

export { prepareUserEntity, userCopyStorageKey, userDuplicationDataConverter }
