import { useState } from 'react'
import { StaffManagementListItemModel } from 'shared/models'
import { useTranslation } from 'shared/i18n/translate'
import { unixtimeToObject } from 'shared/i18n/date-formatter'
import CopyIcon from '@mui/icons-material/FileCopy'
import { FormGroup, Form } from 'shared/ui-kit/form'
import Text from 'shared/ui-kit/text'
import { ExpandMore as ExpandMoreIcon, ShoppingBasket as BasketIcon } from '@mui/icons-material'
import { Divider, Accordion, AccordionDetails, AccordionSummary, IconButton, ListItemButton } from '@mui/material'
import { ItemStats, LastUpdate } from './styled'
import { DuplicateForm, StaffManagementItemProps } from './types'
import { StaffManagementDuplicateForm } from './duplicate-form'
import { staffManagement as staffManagementApi } from 'shared/api'
import { useAppDispatch } from 'store'
import { MessageTypes } from 'store/reducers/main-layout-reducer'
import { showMessage } from 'store/actions/main-layout-action-creators'

function StaffManagementItem({
  items,
  dayMs,
  autofocusId,
  onItemClick,
  lineId,
  shopId,
  onRefresh
}: StaffManagementItemProps) {
  const { dateFormatter, tr, timeZoneOffset } = useTranslation()
  const dispatch = useAppDispatch()

  const [withFocus, setWithFocus] = useState(true)

  const elementToFocus = items.find((x: StaffManagementListItemModel) => x.id == autofocusId)

  const [expanded, setExpanded] = useState(!!elementToFocus || items?.length < 2)
  const [editMode, setEditMode] = useState(false)

  const [isFetching, setIsFetching] = useState(false)

  async function handleSubmit(data: DuplicateForm) {
    setIsFetching(true)

    try {
      await staffManagementApi.duplicateStaffManagements({
        shopId,
        lineId,
        sourceDate: data.sourceDate,
        targetDates: data.targetDates,
        deletePreviousItemsAfterCopying: data.deletePreviousItemsAfterCopying
      })
      setIsFetching(false)
      setEditMode(false)
      dispatch(showMessage(tr.staffManagementOperationMessages.duplicateSuccess, MessageTypes.Success))
      onRefresh?.()
    } catch (e) {
      setIsFetching(false)
    }
  }

  return (
    <Accordion
      expanded={expanded}
      onChange={(_, expanded) => {
        setExpanded(expanded)
        if (expanded) {
          setEditMode(false)
        }
      }}
      key={dayMs}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '1rem',
            width: '100%',
            marginRight: '0.5rem'
          }}
        >
          <Text type="body-1">
            {dateFormatter(dayMs, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }, 'UTC')}
          </Text>
          <IconButton
            size="small"
            onClick={(ev) => {
              ev.stopPropagation()
              setExpanded(true)
              setEditMode(true)
            }}
          >
            <CopyIcon style={{ color: '#5e5e5e' }} fontSize="small" />
          </IconButton>
        </div>
      </AccordionSummary>
      <Divider />
      {!editMode && (
        <AccordionDetails style={{ padding: 0 }}>
          <div style={{ flex: 1 }}>
            {items.map((x) => {
              const forceFocus = withFocus && x.id === autofocusId
              const { servicePointsSelected = [], servicesSelected = [], isAllServicePoints, isAllServices } = x
              const updateData = x.lastUpdate
              const { date, user } = updateData || {}

              return (
                <ListItemButton
                  key={x.id}
                  selected={forceFocus}
                  autoFocus={forceFocus}
                  onBlur={() => withFocus && setWithFocus(false)}
                  divider
                  style={{ width: '100%', padding: '1rem' }}
                  onClick={() => onItemClick?.(x)}
                >
                  <Form>
                    <FormGroup style={{ display: 'flex', margin: 0, gap: '2rem', flexWrap: 'wrap' }}>
                      <div>
                        <Text type="caption">{tr.staffManagement.startHour}</Text>
                        <Text type="body-1">{unixtimeToObject(x.startHour_unixtime, timeZoneOffset)}</Text>
                      </div>
                      <div>
                        <Text type="caption">{tr.staffManagement.endHour}</Text>
                        <Text type="body-1">{unixtimeToObject(x.endHour_unixtime, timeZoneOffset)}</Text>
                      </div>
                      {x.serviceWithPause && x.pauseStartHour_unixtime && (
                        <div>
                          <Text type="caption">{tr.staffManagement.pauseStart}</Text>
                          <Text type="body-1">{unixtimeToObject(x.pauseStartHour_unixtime, timeZoneOffset)}</Text>
                        </div>
                      )}
                      {x.serviceWithPause && x.pauseEndHour_unixtime && (
                        <div>
                          <Text type="caption">{tr.staffManagement.pauseEnd}</Text>
                          <Text type="body-1">{unixtimeToObject(x.pauseEndHour_unixtime, timeZoneOffset)}</Text>
                        </div>
                      )}
                      <div>
                        <Text type="caption">{tr.staffManagement.numberOfStaff}</Text>
                        <Text type="body-1">{x.numberOfStaff}</Text>
                      </div>
                    </FormGroup>
                    {!!x.comments?.length && (
                      <FormGroup style={{ margin: '0.5rem 0' }}>
                        <Text type="body-1">{x.comments}</Text>
                      </FormGroup>
                    )}
                    {!!servicesSelected.length && !isAllServices && (
                      <ItemStats>
                        <div style={{ alignItems: 'center', display: 'flex', margin: '4px 0' }}>
                          <BasketIcon style={{ opacity: 0.28, marginRight: '1rem' }} />
                          <div>
                            <Text type="caption">{servicesSelected.map((s) => s.name).join(', ')}</Text>
                          </div>
                        </div>
                      </ItemStats>
                    )}
                    {!!servicePointsSelected.length && !isAllServicePoints && (
                      <ItemStats>
                        <div style={{ alignItems: 'center', display: 'flex', margin: '4px 0' }}>
                          <BasketIcon style={{ opacity: 0.28, marginRight: '1rem' }} />
                          <div>
                            <Text type="caption">{servicePointsSelected.map((s) => s.name).join(', ')}</Text>
                          </div>
                        </div>
                      </ItemStats>
                    )}
                    {updateData && user && date && (
                      <LastUpdate>
                        <Text type="body-3">
                          {tr.staffManagement.lastUpdate(
                            user?.firstName,
                            user?.secondName,
                            dateFormatter(date, 'dateTime')
                          )}
                        </Text>
                      </LastUpdate>
                    )}
                  </Form>
                </ListItemButton>
              )
            })}
          </div>
        </AccordionDetails>
      )}
      {!!editMode && <StaffManagementDuplicateForm onSubmit={handleSubmit} disabled={isFetching} sourceDate={dayMs} />}
    </Accordion>
  )
}

export { StaffManagementItem }
