import Dropdown from 'shared/ui-kit/dropdown'
import { useTranslation } from 'shared/i18n/translate'
import produce from 'immer'

type PlaceFilterProps = {
  places: any[]
  onChange?: (placeId: number | string) => void
  placeId?: number | string | null
}

function PlaceFilter({ places, onChange, placeId }: PlaceFilterProps) {
  const { tr } = useTranslation()

  const dropdownShops =
    places?.length > 1
      ? produce(places, (draft) => {
          draft.unshift({ id: '-1', name: tr.reportsFilter.allShops })
        })
      : places

  function handleShopChange(id?: number | string) {
    const placeId = id === '-1' ? (null as any) : id

    onChange?.(placeId)
  }

  return (
    <>
      {dropdownShops.length > 1 && (
        <Dropdown
          style={{ maxWidth: '150px', width: '100%', minWidth: 0 }}
          value={placeId || '-1'}
          data={dropdownShops}
          dataConverter={(item) => ({ value: item.id, text: item.name })}
          onChange={handleShopChange}
          label={tr.common.shop}
        />
      )}
    </>
  )
}

export { PlaceFilter }
