import { useState } from 'react'
import ConfirmItem from './confirm-item'
import { AppointmentActionProps } from './types'
import { AppointmentActionIcon, AppointmentActionRoot } from './styled'

export function AppointmentAction(props: AppointmentActionProps) {
  const [confirmMode, setConfirmMode] = useState(false)

  function handleClick() {
    if (props.confirmation) {
      setConfirmMode(!confirmMode)
    } else {
      props.onSubmit?.()
    }
  }

  return (
    <AppointmentActionRoot $disabled={props.disabled} onClick={handleClick} style={props.style}>
      <ConfirmItem
        open={confirmMode}
        onSubmit={props.onSubmit}
        onCancel={() => setConfirmMode(!confirmMode)}
        message={props.confirmationMessage || ''}
      >
        {!!props.icon && <AppointmentActionIcon>{props.icon}</AppointmentActionIcon>} {props.children}
      </ConfirmItem>
    </AppointmentActionRoot>
  )
}
