import { useRef } from 'react'
import { AdditionalActions, AdditionalTitle, ItemContainer } from './styled'
import { InputTranslation } from 'features/input-translation'
import { TextField } from 'shared/ui-kit/text-field'
import { useTranslation } from 'shared/i18n/translate'
import { IconButton } from '@mui/material'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { Delete as RemoveIcon } from '@mui/icons-material'
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd'
import type { Identifier, XYCoord } from 'dnd-core'
import { ComboListItemProps, DragItem } from './types'

export function ComboListItem({
  item,
  onChange,
  onRemove,
  brandId,
  index,
  moveCard,
  additionalId,
  id,
  placeId
}: ComboListItemProps) {
  const { tr } = useTranslation()
  const { chars } = tr.entityEdit
  const { title, value } = tr.lineEdit

  const dragRef = useRef<HTMLDivElement>(null)

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: `combo-${additionalId}`,
    collect(monitor) {
      return { handlerId: monitor.getHandlerId() }
    },
    hover(item: DragItem, monitor) {
      if (!dragRef.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = dragRef.current?.getBoundingClientRect()

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      const clientOffset = monitor.getClientOffset()

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveCard(dragIndex, hoverIndex)
      item.index = hoverIndex
    }
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: `combo-${additionalId}`,
    item: () => ({ index, id }),
    collect: (monitor: DragSourceMonitor) => ({ isDragging: monitor.isDragging() })
  })

  preview(drop(dragRef))

  function handleTitleChange(title: string) {
    onChange({ ...item, title })
  }

  function handleValueChange(value: string) {
    onChange({ ...item, value })
  }

  return (
    <ItemContainer style={{ opacity: isDragging ? 0 : 1 }} ref={dragRef}>
      <AdditionalTitle>
        <InputTranslation inputValue={item.title} setInputValue={handleTitleChange} brandId={brandId} placeId={placeId}>
          <TextField
            placeholder={title}
            label={title}
            value={item.title}
            onChange={handleTitleChange}
            message={`${item.title ? item.title.length : 0}/100 ${chars}`}
            test={(v) => v.length < 101}
            invalid={!item.title}
          />
        </InputTranslation>
        <TextField
          placeholder={value}
          label={value}
          value={item.value}
          onChange={handleValueChange}
          message={`${item.value ? item.value.length : 0}/100 ${chars}`}
          test={(v) => v.length < 101}
          invalid={!item.value}
        />
      </AdditionalTitle>
      <AdditionalActions>
        <div ref={drag} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <DragIndicatorIcon style={{ opacity: '0.2', cursor: 'move' }} data-handler-id={handlerId} />
        </div>
        <IconButton size="small" onClick={onRemove}>
          <RemoveIcon fontSize="small" />
        </IconButton>
      </AdditionalActions>
    </ItemContainer>
  )
}
