import { HttpTransportType, HubConnectionBuilder } from '@microsoft/signalr'
import axios from 'axios'
import { LineAPI } from 'shared/models'
import { countRetryPolicy } from 'shared/utils/signal-r'
import { createData } from '../mocks/fake-data/fake-lines-statistics'
import { monitoringHubErrorHandler } from '.'

const line: LineAPI = {
  getLineListSimplified(placeId, config) {
    return axios.get('getLineListSimplified', { params: { shopId: placeId }, ...config })
  },
  getLineList(placeId, config) {
    return axios.get('getLineList', { params: { shopId: placeId }, ...config })
  },
  getLinesStatistics(data, config) {
    return axios.post('getLinesStatistics', data, config)
  },
  linesStatisticsChanged(subscription, onDataChange) {
    if (process.env.APP_FAKES === 'true') {
      onDataChange(createData())
      const interval = setInterval(() => onDataChange(createData()), 5000)

      return {
        stop: () => {
          clearInterval(interval)
        }
      }
    }

    const { placeId } = subscription

    const connection = new HubConnectionBuilder()
      .withUrl('/MonitoringHub', { transport: HttpTransportType.WebSockets | HttpTransportType.LongPolling })
      .withAutomaticReconnect(countRetryPolicy())
      .build()

    connection
      .start()
      .then(() => {
        connection.on('MonitoringError', monitoringHubErrorHandler(connection))
        connection.on('LineStatisticsUpdate', onDataChange)
        connection.invoke('LineStatisticsSubscribe', placeId)
      })
      .catch((error) => console.warn(error))

    connection.onreconnected(() => {
      connection.invoke('LineStatisticsSubscribe', placeId)
    })

    return {
      stop: () => {
        connection.off('MonitoringError', monitoringHubErrorHandler(connection))
        connection.off('LineStatisticsUpdate', onDataChange)
        return connection.stop()
      }
    }
  },
  getLine(id, placeId, config) {
    return axios.get('getLine', { params: { id, shopId: placeId }, ...config })
  },
  createLine(data, config) {
    return axios.post('createLine', data, config)
  },
  updateLine(data, config) {
    return axios.put('updateLine', data, config)
  },
  deleteLine(id, config) {
    return axios.delete('deleteLine', { data: id, headers: { 'Content-Type': 'application/json' }, ...config })
  },
  getServiceDateTimes(data, config) {
    return axios.get('getServiceDateTimes', { params: data, ...config })
  },
  getCustomers(data, config) {
    return axios.get('getCustomers', { params: data, ...config })
  },
  updateLinesOrder(data, config) {
    return axios.post('updateLinesOrder', data, config)
  },
  duplicateLine(data, config) {
    return axios.post('duplicateLine', data, config)
  }
}

export default line
