export function getUrl(path: string, queries: NodeJS.Dict<any> | undefined = undefined) {
  const url = `${process.env.APP_API}/${path}`

  if (!queries) {
    return url
  }

  const params = new URLSearchParams(queries)

  return `${url}?${params}`
}

/**
 * [Axios] Global handlers config
 */
export const config = {
  errorHandle: true
}
