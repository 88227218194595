import axios from 'axios'
import { ZoneAPI } from 'shared/models'

const zone: ZoneAPI = {
  getZoneList(placeId, config) {
    return axios.get('getZoneList', { params: { shopId: placeId }, ...config })
  },
  getZone(id, placeId, config) {
    return axios.get('getZone', { params: { id, shopId: placeId }, ...config })
  },
  createZone(data, config) {
    return axios.post('createZone', data, config)
  },
  updateZone(data, config) {
    return axios.put('updateZone', data, config)
  },
  deleteZone(id, config) {
    return axios.delete('deleteZone', {
      data: JSON.stringify(id),
      headers: { 'Content-Type': 'application/json' },
      ...config
    })
  }
}

export default zone
